<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" *ngIf="isCredit" routerLink="/financial/payable/invoices/credit-memo">Credit Memo </span>
               <span class="color-black pl-1 titlePanels" *ngIf="!isCredit" routerLink="/financial/{{invoiceUrl}}/invoices">Invoice </span>
                <button class="btn btn-danger buttonclass" (click)="reset()">Cancel</button>
            </div>



            <div class=" bg-white color-grey">
                <div>
                    <div class="container-fluid">
                        <div class="row">


                            <ng-container>
                                <div class="w3-card-4 classCard" style="width: 100%;height: 55%;">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs" style="width: 102%!important; margin-left: -1% !important;">
                                            <h4 class=" common-styling" style="    margin-left: 1%;">
                                                Create {{invoiceUrl ==='payable' ? 'Purchase':'Sales'}} Invoice
                                            </h4>

                                        </div>
                                    </header>
                                    <div class="row">
                                            <div class="col-md-6" style="padding-right: 0;margin-top: 15px;">
                                                <div class="w3-card-4 classCardB">
                                                    <div class="container-fluid">
                                                      <ul class="tabbing-section tabbing-accordians" style="background:rgba(204,224,233,.3686274509803922)!important;
                                                      margin-top: 0%;">
                            
                                                        <li class="aSize">
                                                          Product</li>
                                                      </ul>
                                                    </div>
                                                    <div>
                                                        <form class="w-100">

                                                            <div>
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <form [formGroup]="createInvoiceForm">
                                                                        <div class="row">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div class="col-lg-7 form-group classInput">
                                                                                        <label for="exampleInputEmail1">Invoice Type<span
                                                                  class="text-danger">*</span>
                                                          </label>
                                                                                    </div>

                                                                                    <div class="col-lg-5">
                                                                                        <p-dropdown filter="true" name="party" placeholder="Select Invoice Type" [options]="invoiceType" formControlName="invoiceTypeId" optionlabel="label">
                                                                                        </p-dropdown>
                                                                                        <small class="validate-field" *ngIf="!!createInvoiceForm.controls.invoiceTypeId.invalid && !!createInvoiceForm.controls.invoiceTypeId.touched">
                                                                  Invoice Type is required!</small>
                                                                                    </div>




                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div class="col-lg-7 form-group classInput">
                                                                                        <label for="exampleInputEmail1">Organization Party
                                                                  ID </label>
                                                                                    </div>
                                                                                    <div class="col-lg-5">
                                                                                        <p-dropdown filter="true" name="party" [options]="array" formControlName="partyId" optionlabel="label" placeholder="select">
                                                                                        </p-dropdown>
                                                                                    </div>



                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div class="col-lg-7 form-group classInput">
                                                                                        <label for="exampleInputEmail1">{{invoiceUrl ==='payable' ? 'Supplier':'Customer'}} Id
                                                              <span style="color:red">*</span>
                                                          </label>
                                                                                    </div>

                                                                                    <div class="col-lg-5">
                                                                                        <p-dropdown filter="true" name="party" [options]="supplierArray" formControlName="partyIdFrom" optionlabel="label" placeholder="Select PartyID">
                                                                                        </p-dropdown>
                                                                                        <small class="validate-field" *ngIf="!!createInvoiceForm.controls.partyIdFrom.invalid && !!createInvoiceForm.controls.partyIdFrom.touched">
                                                                  Party ID is required!</small>
                                                                                    </div>


                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </form>
                                                                </div>
                                                                <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -21%;padding-bottom: 29px;">
                                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="submitInvoice()">
                                                                        Continue
                                                                    </button>

                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    
                                                  
                                                  
                                                  </div>
                                            </div>
                                            <div class="col-md-6" style="padding-left: 0;margin-top: 15px;">
                                                <div class="w3-card-4 classCardB">
                                                    <div class="container-fluid">
                                                      <ul class="tabbing-section tabbing-accordians" style="background:rgba(204,224,233,.3686274509803922)!important;
                                                      margin-top: 0%;">
                            
                                                        <li class="aSize">
                                                          Details</li>
                                                      </ul>
                                                    </div>
                                                    <div>
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <form [formGroup]="updateSalesInvoiceForm">
                                                                <div class="row">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
        
                                                                            <div class="col-lg-3 form-group classInputNew">
                                                                                <label for="exampleInputEmail1">{{invoiceUrl ==='payable' ? 'Supplier':'Customer'}} Name : </label>
                                                                            </div>
        
                                                                            <div class="col-lg-3">
                                                                                <label for="exampleInputEmail1">{{this.customerName}}</label>
                                                                            </div>
        
        
        
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
        
                                                                            <div class="col-lg-3 form-group classInputNew">
                                                                                <label for="exampleInputEmail1">Address :</label>
                                                                            </div>
        
                                                                            <div class="col-lg-3">
                                                                                <label for="exampleInputEmail1"></label>
                                                                            </div>
        
        
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
        
                                                                            <div class="col-lg-3 form-group classInputNew">
                                                                                <label for="exampleInputEmail1">Invoice Date :</label>
                                                                            </div>
        
                                                                            <div class="col-lg-3">
                                                                                <label for="exampleInputEmail1"></label>
                                                                            </div>
        
        
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
        
                                                                            <div class="col-lg-3 form-group classInputNew">
                                                                                <label for="exampleInputEmail1">Invoice Due Date :</label>
                                                                            </div>
        
                                                                            <div class="col-lg-3">
                                                                                <label for="exampleInputEmail1"></label>
                                                                            </div>
        
        
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
        
                                                                            <div class="col-lg-3 form-group classInputNew">
                                                                                <label for="exampleInputEmail1">Invoice Total :</label>
                                                                            </div>
        
                                                                            <div class="col-lg-3">
                                                                                <label for="exampleInputEmail1"></label>
                                                                            </div>
        
        
                                                                        </div>
                                                                    </div>
        
        
        
        
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                  
                                                  
                                                  </div>
                                            </div>

                                    </div>
                                    <!-- <div>
                                        <div class="cardWidthA">

                                            <div>
                                                <div class="w-100">
                                                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                        <div class="panel panel-default">


                                                            <div>
                                                                <div class="w-100">
                                                                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                                        <div class="panel panel-default">


                                                                            <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                                                                                <div>
                                                                                    <div class="container-fluid">
                                                                                        <div class="row">
                                                                                            <form class="w-100">

                                                                                                <div>
                                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                        <form [formGroup]="createInvoiceForm">
                                                                                                            <div class="row">
                                                                                                                <div class="col-lg-12">
                                                                                                                    <div class="row">

                                                                                                                        <div class="col-lg-7 form-group classInput">
                                                                                                                            <label for="exampleInputEmail1">Invoice Type<span
                                                                                                      class="text-danger">*</span>
                                                                                              </label>
                                                                                                                        </div>

                                                                                                                        <div class="col-lg-5">
                                                                                                                            <p-dropdown filter="true" name="party" placeholder="Select Invoice Type" [options]="invoiceType" formControlName="invoiceTypeId" optionlabel="label">
                                                                                                                            </p-dropdown>
                                                                                                                            <small class="validate-field" *ngIf="!!createInvoiceForm.controls.invoiceTypeId.invalid && !!createInvoiceForm.controls.invoiceTypeId.touched">
                                                                                                      Invoice Type is required!</small>
                                                                                                                        </div>




                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div class="col-lg-12">
                                                                                                                    <div class="row">

                                                                                                                        <div class="col-lg-7 form-group classInput">
                                                                                                                            <label for="exampleInputEmail1">Organization Party
                                                                                                      ID </label>
                                                                                                                        </div>
                                                                                                                        <div class="col-lg-5">
                                                                                                                            <p-dropdown filter="true" name="party" [options]="array" formControlName="partyId" optionlabel="label" placeholder="select">
                                                                                                                            </p-dropdown>
                                                                                                                        </div>



                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div class="col-lg-12">
                                                                                                                    <div class="row">

                                                                                                                        <div class="col-lg-7 form-group classInput">
                                                                                                                            <label for="exampleInputEmail1">{{invoiceUrl ==='payable' ? 'Supplier':'Customer'}} Id
                                                                                                  <span style="color:red">*</span>
                                                                                              </label>
                                                                                                                        </div>

                                                                                                                        <div class="col-lg-5">
                                                                                                                            <p-dropdown filter="true" name="party" [options]="supplierArray" formControlName="partyIdFrom" optionlabel="label" placeholder="Select PartyID">
                                                                                                                            </p-dropdown>
                                                                                                                            <small class="validate-field" *ngIf="!!createInvoiceForm.controls.partyIdFrom.invalid && !!createInvoiceForm.controls.partyIdFrom.touched">
                                                                                                      Party ID is required!</small>
                                                                                                                        </div>


                                                                                                                    </div>
                                                                                                                </div>


                                                                                                            </div>
                                                                                                        </form>
                                                                                                    </div>
                                                                                                    <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: 26%;
                                                                          margin-top: -28%;"><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="submitInvoice()">Continue</button>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </form>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>



                                        </div>



                                        <div class="cardWidthB">
                                            <div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <form [formGroup]="updateSalesInvoiceForm">
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputNew">
                                                                        <label for="exampleInputEmail1">{{invoiceUrl ==='payable' ? 'Supplier':'Customer'}} Name : </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <label for="exampleInputEmail1">{{this.customerName}}</label>
                                                                    </div>



                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputNew">
                                                                        <label for="exampleInputEmail1">Address :</label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <label for="exampleInputEmail1"></label>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputNew">
                                                                        <label for="exampleInputEmail1">Invoice Date :</label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <label for="exampleInputEmail1"></label>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputNew">
                                                                        <label for="exampleInputEmail1">Invoice Due Date :</label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <label for="exampleInputEmail1"></label>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputNew">
                                                                        <label for="exampleInputEmail1">Invoice Total :</label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <label for="exampleInputEmail1"></label>
                                                                    </div>


                                                                </div>
                                                            </div>




                                                        </div>
                                                    </form>
                                                </div>

                                            </div>

                                        </div>
                                    </div> -->
                                    <br>
                                    <div class="w3-card-4 classCard" style="width:100%;">

                                        <div class="container-fluid">
                                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103%;margin-left: -1.5%;">
                                                <li [ngClass]="activeCategoryView==0?'active':''" (click)="changeactiveCategoryFunctionView(0)"><a>
                                             Items</a></li>
                                                <!-- <li [ngClass]="activeCategoryView==2?'active':''" (click)="changeactiveCategoryFunctionView(2)"><a>
                                                  Terms</a></li>
                                                <li [ngClass]="activeCategoryView==3?'active':''" (click)="changeactiveCategoryFunctionView(3)"><a>
                                                 Role
                                              </a></li> -->




                                            </ul>
                                        </div>
                                        <div>
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <form class="w-100">
                                                        <div class="">
                                                            <div class="w-100">
                                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                                    <div *ngIf="activeCategoryView==0">



                                                                        <div class="panel-body panelB">
                                                                            <form class="w-100">
                                                                                <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group table-responsive" style="margin-top: -27px;">
                                                                                            <div class="create-new-leade mt-2" style="min-height: calc(100vh - 388px)!important;">
                                                                                                <table class="table align-middle">
                                                                                                    <thead>
                                                                                                        <tr style="color: grey;">
                                                                                                            <th style="font-size: 12px;    padding: 14px 20px !important;">
                                                                                                                S.No

                                                                                                            </th>
                                                                                                            <th style="font-size: 12px; height: 44px;min-width: 155px; padding: 14px 20px !important;">
                                                                                                                Invoice Item Type

                                                                                                            </th>
                                                                                                            <th style="font-size: 12px;min-width: 155px;   padding: 14px 20px !important;">
                                                                                                                Description

                                                                                                            </th>

                                                                                                            <th style="font-size: 12px;    min-width: 155px;    padding: 14px 20px !important;">
                                                                                                                Override Gl Account ID

                                                                                                            </th>
                                                                                                            <th style="font-size: 12px;min-width: 155px;    padding: 14px 20px !important;">
                                                                                                                Inventory Item ID

                                                                                                            </th>


                                                                                                            <th style="font-size: 12px; min-width: 155px;   padding: 14px 20px !important;">
                                                                                                                Product ID

                                                                                                            </th>
                                                                                                            <th style="font-size: 12px;min-width: 155px;    padding: 14px 20px !important;">
                                                                                                                Product Feature Id

                                                                                                            </th>
                                                                                                            <th style="font-size: 12px; min-width: 155px;   padding: 14px 20px !important;">
                                                                                                                Quantity

                                                                                                            </th>


                                                                                                            <th style="font-size: 12px;min-width: 155px;   padding: 14px 20px !important;">
                                                                                                                Uom


                                                                                                            </th>
                                                                                                            <th style="font-size: 12px;min-width: 155px;   padding: 14px 20px !important;">
                                                                                                                Unit Price


                                                                                                            </th>
                                                                                                            <th style="font-size: 12px;min-width: 155px;     padding: 14px 20px !important;">
                                                                                                                Taxable Flag

                                                                                                            </th>
                                                                                                            <th style="font-size: 12px;   min-width: 155px;    padding: 14px 20px !important;">
                                                                                                                Action


                                                                                                            </th>



                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>

                                                                                                        <tr class="align-bottom" *ngFor="let product of invoiceItems;let i = index;">
                                                                                                            <td style="    color: black;
                                                                                              font-weight: 100;
                                                                                              font-size: 12px;
                                                                                             ">

                                                                                                                {{i+1}}

                                                                                                            </td>

                                                                                                            <td style="    color: black;
                                                                                                font-weight: 100;
                                                                                                font-size: 12px;
                                                                                                min-width: 155px;
                                                                                               ">

                                                                                                                {{product.invoiceItemType}}

                                                                                                            </td>
                                                                                                            <td style="    color: black;
                                                                                                font-weight: 100;
                                                                                                font-size: 12px;
                                                                                                min-width: 155px;
                                                                                               ">

                                                                                                                {{product.description}}

                                                                                                            </td>


                                                                                                            <td style="    color: black;
                                                                                                font-weight: 100;
                                                                                                font-size: 12px;
                                                                                                min-width: 155px;
                                                                                               ">

                                                                                                                {{product.overrideGlAccountId}}

                                                                                                            </td>

                                                                                                            <td style="    color: black;
                                                                                                font-weight: 100;
                                                                                                font-size: 12px;
                                                                                                min-width: 155px;
                                                                                               ">

                                                                                                                {{product.inventoryItemId}}



                                                                                                            </td>
                                                                                                            <td style="    color: black;
                                                                                                font-weight: 100;
                                                                                                font-size: 12px;
                                                                                                min-width: 155px;
                                                                                               ">

                                                                                                                {{product.productId}}


                                                                                                            </td>

                                                                                                            <td style="    color: black;
                                                                                                font-weight: 100;
                                                                                                font-size: 12px;
                                                                                                min-width: 155px;
                                                                                                ">

                                                                                                                {{product.productFeatureId}}


                                                                                                            </td>
                                                                                                            <td style="    color: black;
                                                                                                font-weight: 100;
                                                                                                font-size: 12px;
                                                                                                min-width: 155px;
                                                                                               ">

                                                                                                                {{product.quantity}}

                                                                                                            </td>

                                                                                                            <td style="    color: black;
                                                                                                font-weight: 100;
                                                                                                font-size: 12px;
                                                                                                min-width: 155px;
                                                                                               ">

                                                                                                                {{product.uomId}}


                                                                                                            </td>
                                                                                                            <td style="    color: black;
                                                                                                font-weight: 100;
                                                                                                font-size: 12px;
                                                                                                min-width: 155px;
                                                                                               ">

                                                                                                                {{product.amount}}


                                                                                                            </td>

                                                                                                            <td style="    color: black;
                                                                                                font-weight: 100;
                                                                                                font-size: 12px;
                                                                                                min-width: 155px;
                                                                                               ">

                                                                                                                {{product.taxableFlag=='0'?'No':'Yes'}}

                                                                                                            </td>


                                                                                                            <td style="    color: black;
                                                                                                    font-weight: 100;
                                                                                                    font-size: 12px;
                                                                                                    min-width: 155px;
                                                                                                   ">

                                                                                                                <fa-icon *ngIf="isEditable" (click)="editItem(product.invoiceItemSeqId);"  class="account-button"  [icon]="faEdit"></fa-icon>
                                                                                                                <fa-icon *ngIf="isEditable" (click)="deleteInvoiceItem(product.invoiceItemSeqId)" class="text-danger cursor-pointer" [icon]="faTrash"></fa-icon>


                                                                                                            </td>

                                                                                                        </tr>

                                                                                                        <tr class="align-bottom" [formGroup]="invoiceItemForm">


                                                                                                            <td>


                                                                                                            </td>
                                                                                                            <td style="min-width: 155px;">

                                                                                                                <p-dropdown filter="true" [options]="invoiceItemTypes" optionlabel="label" formControlName="invoiceItemTypeId" placeholder="Select Invoice Type">
                                                                                                                </p-dropdown>

                                                                                                            </td>
                                                                                                            <td style="min-width: 155px;">

                                                                                                                <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Description" formControlName="description">

                                                                                                            </td>


                                                                                                            <td style="min-width: 155px;">

                                                                                                                <p-dropdown filter="true" [options]="GIAccounts" optionlabel="label" formControlName="overrideGlAccountId" placeholder="Select Account Id">
                                                                                                                </p-dropdown>


                                                                                                            </td>
                                                                                                            <td style="min-width: 155px;">

                                                                                                                <p-dropdown filter="true" [options]="inventoryItems" optionlabel="label" formControlName="inventoryItemId" placeholder="Select Inventory Item">
                                                                                                                </p-dropdown>

                                                                                                            </td>
                                                                                                            <td style="min-width: 155px;">

                                                                                                                <p-dropdown filter="true" [options]="productIds" optionlabel="label" formControlName="productId" placeholder="Select Product Id">
                                                                                                                </p-dropdown>


                                                                                                            </td>
                                                                                                            <td style="min-width: 155px;">

                                                                                                                <p-dropdown filter="true" [options]="featureIds" optionlabel="label" formControlName="productFeatureId" placeholder="Select Product Id">
                                                                                                                </p-dropdown>


                                                                                                            </td>
                                                                                                            <td style="min-width: 155px;">

                                                                                                                <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Quantity" formControlName="quantity">


                                                                                                            </td>
                                                                                                            <td style="min-width: 155px;">

                                                                                                                <p-dropdown filter="true" [options]="uomIds" optionlabel="label" formControlName="uomId" placeholder="Select Uom">
                                                                                                                </p-dropdown>


                                                                                                            </td>
                                                                                                            <td style="min-width: 155px;">

                                                                                                                <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Unit Price" formControlName="amount">


                                                                                                            </td>
                                                                                                            <td style="min-width: 155px;">

                                                                                                                <p-dropdown filter="true" placeholder="Taxable Flag" formControlName="taxableFlag" [options]="decisionArray" optionlabel="label">
                                                                                                                </p-dropdown>


                                                                                                            </td>

                                                                                                            <td style="min-width: 155px;">

                                                                                                                <button type="submit" style="margin-left: 17px;" class="btn btn-secondary submit-btn" (click)="submitItem()">{{editMode ?'Update':'Add Item'}}</button>

                                                                                                            </td>

                                                                                                        </tr>


                                                                                                    </tbody>
                                                                                                </table>

                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="activeCategoryView==2">
                                                                        <div class="panel-body panelB">
                                                                            <form class="w-100">
                                                                                <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group table-responsive" style="margin-top: -15px;">
                                                                                            <div class="create-new-leade mt-2" style="min-height: calc(100vh - 388px)!important;">
                                                                                                <table class="table align-middle">
                                                                                                    <thead>
                                                                                                        <tr style="background: #0d3769;">
                                                                                                            <th style="color: white;font-size: 12px;    padding: 14px 20px !important;">
                                                                                                                S.No

                                                                                                            </th>
                                                                                                            <th style="color: white;font-size: 12px;    padding: 14px 20px !important;
                                                                              min-width: 155px;">


                                                                                                                Invoice Term ID


                                                                                                            </th>
                                                                                                            <th style="color: white;font-size: 12px; min-width: 155px;    padding: 14px 20px !important;">
                                                                                                                Term Type ID

                                                                                                            </th>

                                                                                                            <th style="color: white;font-size: 12px;  min-width: 155px;padding: 14px 20px !important;">
                                                                                                                Item No

                                                                                                            </th>
                                                                                                            <th style="color: white;font-size: 12px;  min-width: 155px;padding: 14px 20px !important;">
                                                                                                                Term Value

                                                                                                            </th>


                                                                                                            <th style="color: white;font-size: 12px;  min-width: 155px; padding: 14px 20px !important;">
                                                                                                                Term Days


                                                                                                            </th>
                                                                                                            <th style="color: white;font-size: 12px; min-width: 155px; height: 44px;    padding: 14px 20px !important;">
                                                                                                                Text Value


                                                                                                            </th>
                                                                                                            <th style="color: white;font-size: 12px;  min-width: 155px;  padding: 14px 20px !important;">
                                                                                                                Description

                                                                                                            </th>


                                                                                                            <th style="color: white;font-size: 12px;  min-width: 155px;   padding: 14px 20px !important;">
                                                                                                                Uom


                                                                                                            </th>
                                                                                                            <th style="color: white;font-size: 12px; min-width: 155px; padding: 14px 20px !important;">
                                                                                                                Action


                                                                                                            </th>



                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>

                                                                                                        <tr class="align-bottom" *ngFor="let product of invoiceTerms;let i = index;">
                                                                                                            <td style="    color: black;
                                                                              font-weight: 100;
                                                                              font-size: 12px;
                                                                              ">

                                                                                                                {{i+1}}


                                                                                                            </td>
                                                                                                            <td style="    color: black;
                                                                                font-weight: 100;
                                                                                font-size: 12px;
                                                                                min-width: 155px;
                                                                                ">


                                                                                                                {{product.invoiceTermId}}



                                                                                                            </td>
                                                                                                            <td style="    color: black;
                                                                                font-weight: 100;
                                                                                font-size: 12px;
                                                                                min-width: 155px;
                                                                                ">

                                                                                                                {{product.termTypeId}}


                                                                                                            </td>


                                                                                                            <td style="    color: black;
                                                                                font-weight: 100;
                                                                                font-size: 12px;
                                                                                min-width: 155px;">

                                                                                                                {{product.itemNo}}


                                                                                                            </td>

                                                                                                            <td style="    color: black;
                                                                                font-weight: 100;
                                                                                font-size: 12px;
                                                                                min-width: 155px;">

                                                                                                                {{product.termValue}}



                                                                                                            </td>
                                                                                                            <td style="    color: black;
                                                                                font-weight: 100;
                                                                                font-size: 12px;
                                                                                min-width: 155px; ">

                                                                                                                {{product.termDays}}


                                                                                                            </td>

                                                                                                            <td style="    color: black;
                                                                                font-weight: 100;
                                                                                font-size: 12px;
                                                                                min-width: 155px;">

                                                                                                                {{product.textValue}}



                                                                                                            </td>
                                                                                                            <td style="    color: black;
                                                                                font-weight: 100;
                                                                                font-size: 12px;
                                                                                min-width: 155px;">

                                                                                                                {{product.description}}


                                                                                                            </td>

                                                                                                            <td style="    color: black;
                                                                                font-weight: 100;
                                                                                font-size: 12px;
                                                                                min-width: 155px;">

                                                                                                                {{product.uom}}

                                                                                                            </td>


                                                                                                            <td style="    color: black;
                                                                                    font-weight: 100;
                                                                                    font-size: 12px;
                                                                                    min-width: 155px;">

                                                                                                                <fa-icon *ngIf="isEditable" (click)="editItemTerm(product.invoiceTermId)"  class="account-button"  [icon]="faEdit"></fa-icon>
                                                                                                                <fa-icon *ngIf="isEditable" (click)="deleteInvoiceTerm(product.invoiceTermId)" class="text-danger cursor-pointer" [icon]="faTrash"></fa-icon>


                                                                                                            </td>

                                                                                                        </tr>

                                                                                                        <tr class="align-bottom" [formGroup]="invoiceTermForm">
                                                                                                            <td>


                                                                                                            </td>
                                                                                                            <td style=" min-width: 155px;">

                                                                                                                <input type="text" class="form-control" id="exampleInputEmail1" formControlName="invoiceTermId" placeholder="Invoice Term Id">

                                                                                                            </td>
                                                                                                            <td style=" min-width: 155px;">

                                                                                                                <p-dropdown filter="true" [options]="invoiceTypeIds" formControlName="termTypeId" optionlabel="label" placeholder="Select Term Type Id">
                                                                                                                </p-dropdown>


                                                                                                            </td>


                                                                                                            <td style=" min-width: 155px;">

                                                                                                                <input type="text" class="form-control" placeholder="Item No" id="exampleInputEmail1" formControlName="invoiceItemSeqId">


                                                                                                            </td>
                                                                                                            <td style=" min-width: 155px;">

                                                                                                                <input type="text" class="form-control" placeholder="Term Value" id="exampleInputEmail1" formControlName="termValue">


                                                                                                            </td>
                                                                                                            <td style=" min-width: 155px;">

                                                                                                                <input type="text" class="form-control" placeholder="Term Days" id="exampleInputEmail1" formControlName="termDays">


                                                                                                            </td>
                                                                                                            <td style=" min-width: 155px;">

                                                                                                                <input type="text" class="form-control" placeholder="Text Value" id="exampleInputEmail1" formControlName="textValue">


                                                                                                            </td>
                                                                                                            <td style=" min-width: 155px;">

                                                                                                                <input type="text" class="form-control" placeholder="Description" id="exampleInputEmail1" formControlName="description">


                                                                                                            </td>
                                                                                                            <td style=" min-width: 155px;">

                                                                                                                <p-dropdown filter="true" [options]="uomIdsNewArray" optionlabel="label" formControlName="uomId" placeholder="Select Term Type Id">
                                                                                                                </p-dropdown>

                                                                                                            </td>


                                                                                                            <td style=" min-width: 155px;">

                                                                                                                <button type="submit" style="margin-right: 28px;" class="btn btn-secondary submit-btn" (click)="submitTerm()">{{editMode ?'Update':'Add Term'}}</button>


                                                                                                            </td>

                                                                                                        </tr>


                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>



                                                                    </div>
                                                                    <div *ngIf="activeCategoryView==3">
                                                                        <div class="panel-body panelB">
                                                                            <form class="w-100">
                                                                                <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group table-responsive" style="margin-top: -17px;">
                                                                                            <div class="create-new-leade mt-2" style="min-height: calc(100vh - 388px)!important;">
                                                                                                <table class="table align-middle">
                                                                                                    <thead>
                                                                                                        <tr style="background: #0d3769;">
                                                                                                            <th style="color: white;font-size: 12px;    height: 44px;    padding: 14px 20px !important;">
                                                                                                                S.No

                                                                                                            </th>
                                                                                                            <th style="color: white;font-size: 12px; min-width: 155px;   height: 44px;    padding: 14px 20px !important;">
                                                                                                                <span>Party ID</span>

                                                                                                            </th>
                                                                                                            <th style="color: white;font-size: 12px;    height: 44px; min-width: 155px;   padding: 14px 20px !important;">
                                                                                                                Role Type ID

                                                                                                            </th>

                                                                                                            <th style="color: white;font-size: 12px;    height: 44px;min-width: 155px;  padding: 14px 20px !important;">
                                                                                                                Date Time Performed

                                                                                                            </th>
                                                                                                            <th style="color: white;font-size: 12px;    height: 44px;min-width: 155px;  padding: 14px 20px !important;">
                                                                                                                Percentage

                                                                                                            </th>


                                                                                                            <th style="color: white;font-size: 12px;    height: 44px;min-width: 155px;   padding: 14px 20px !important;">
                                                                                                                Action

                                                                                                            </th>



                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>

                                                                                                        <tr class="align-bottom" *ngFor="let product of invoiceRoles;let i = index;">
                                                                                                            <td style="    color: black;
                                                                              font-weight: 100;
                                                                              font-size: 12px;
                                                                              ">
                                                                                                                <div>
                                                                                                                    {{i+1}}
                                                                                                                </div>

                                                                                                            </td>

                                                                                                            <td style="    color: black;
                                                                                font-weight: 100;
                                                                                font-size: 12px;
                                                                                min-width: 155px; ">
                                                                                                                <div>
                                                                                                                    {{product.partyId}}
                                                                                                                </div>



                                                                                                            </td>
                                                                                                            <td style="    color: black;
                                                                                font-weight: 100;
                                                                                font-size: 12px;
                                                                                min-width: 155px; ">
                                                                                                                <div>
                                                                                                                    {{product.roleTypeId}}
                                                                                                                </div>

                                                                                                            </td>


                                                                                                            <td style="    color: black;
                                                                                font-weight: 100;
                                                                                font-size: 12px;
                                                                                min-width: 155px; ">
                                                                                                                <div>
                                                                                                                    {{product.datetimePerformed |date:'yyyy-MM-d hh:mm:ss'}}
                                                                                                                </div>

                                                                                                            </td>

                                                                                                            <td style="    color: black;
                                                                                font-weight: 100;
                                                                                font-size: 12px;
                                                                                min-width: 155px; ">
                                                                                                                <div>
                                                                                                                    {{product.percentage}}
                                                                                                                </div>


                                                                                                            </td>


                                                                                                            <td style="    color: black;
                                                                                    font-weight: 100;
                                                                                    font-size: 12px;
                                                                                    min-width: 155px; ">
                                                                                                                <div>
                                                                                                                    <fa-icon *ngIf="isEditable" (click)="deleteInvoiceRole(product.partyId,product.roleTypeId)" class="text-danger cursor-pointer" [icon]="faTrash"></fa-icon>
                                                                                                                </div>

                                                                                                            </td>

                                                                                                        </tr>

                                                                                                        <tr class="align-bottom" [formGroup]="createRoleForm">
                                                                                                            <td>


                                                                                                            </td>
                                                                                                            <td style="min-width: 155px; ">

                                                                                                                <p-dropdown filter="true" [options]="partyIds" optionlabel="label" placeholder="select PartyId" formControlName="partyId">
                                                                                                                </p-dropdown>

                                                                                                            </td>
                                                                                                            <td style="min-width: 155px; ">

                                                                                                                <p-dropdown filter="true" [options]="roleTypeIds" optionlabel="label" placeholder="select Role Type Id" formControlName="roleTypeId">
                                                                                                                </p-dropdown>


                                                                                                            </td>


                                                                                                            <td style="min-width: 155px; ">

                                                                                                                <p-calendar formControlName="datetimePerformed">
                                                                                                                </p-calendar>


                                                                                                            </td>
                                                                                                            <td style="min-width: 155px; ">

                                                                                                                <input type="text" class="form-control" placeholder="Percentage" formControlName="percentage">


                                                                                                            </td>


                                                                                                            <td style="min-width: 155px; ">

                                                                                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="submitInvoiceRole()">Add Role</button>


                                                                                                            </td>

                                                                                                        </tr>


                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>


                                                                    </div>



                                                                </div>




                                                            </div>
                                                        </div>


                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-md-6" style="padding-right: 0;">
                                            <div class="w3-card-4 classCardB">
                                                <div class="container-fluid">
                                                  <ul class="tabbing-section tabbing-accordians" style="background:rgba(204,224,233,.3686274509803922)!important;
                                                  margin-top: 0%;">
                        
                                                    <li [ngClass]="activeCategoryView==2?'active':''" (click)="changeactiveCategoryFunctionView(2)"><a>
                                                        Terms</a></li>
                                                  </ul>
                                                </div>
                                                <div class="panel-body panelB">
                                                    <form class="w-100">
                                                        <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group table-responsive" style="margin-top: -15px;">
                                                                    <div class="create-new-leade mt-2" style="min-height: calc(100vh - 388px)!important;">
                                                                        <table class="table align-middle">
                                                                            <thead>
                                                                                <tr style="color: grey;">
                                                                                    <th style="font-size: 12px;    padding: 14px 20px !important;">
                                                                                        S.No

                                                                                    </th>
                                                                                    <th style="font-size: 12px;    padding: 14px 20px !important;
                                                      min-width: 155px;">


                                                                                        Invoice Term ID


                                                                                    </th>
                                                                                    <th style="font-size: 12px; min-width: 155px;    padding: 14px 20px !important;">
                                                                                        Term Type ID

                                                                                    </th>

                                                                                    <th style="font-size: 12px;  min-width: 155px;padding: 14px 20px !important;">
                                                                                        Item No

                                                                                    </th>
                                                                                    <th style="font-size: 12px;  min-width: 155px;padding: 14px 20px !important;">
                                                                                        Term Value

                                                                                    </th>


                                                                                    <th style="font-size: 12px;  min-width: 155px; padding: 14px 20px !important;">
                                                                                        Term Days


                                                                                    </th>
                                                                                    <th style="font-size: 12px; min-width: 155px; height: 44px;    padding: 14px 20px !important;">
                                                                                        Text Value


                                                                                    </th>
                                                                                    <th style="font-size: 12px;  min-width: 155px;  padding: 14px 20px !important;">
                                                                                        Description

                                                                                    </th>


                                                                                    <th style="font-size: 12px;  min-width: 155px;   padding: 14px 20px !important;">
                                                                                        Uom


                                                                                    </th>
                                                                                    <th style="font-size: 12px; min-width: 155px; padding: 14px 20px !important;">
                                                                                        Action


                                                                                    </th>



                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>

                                                                                <tr class="align-bottom" *ngFor="let product of invoiceTerms;let i = index;">
                                                                                    <td style="    color: black;
                                                      font-weight: 100;
                                                      font-size: 12px;
                                                      ">

                                                                                        {{i+1}}


                                                                                    </td>
                                                                                    <td style="    color: black;
                                                        font-weight: 100;
                                                        font-size: 12px;
                                                        min-width: 155px;
                                                        ">


                                                                                        {{product.invoiceTermId}}



                                                                                    </td>
                                                                                    <td style="    color: black;
                                                        font-weight: 100;
                                                        font-size: 12px;
                                                        min-width: 155px;
                                                        ">

                                                                                        {{product.termTypeId}}


                                                                                    </td>


                                                                                    <td style="    color: black;
                                                        font-weight: 100;
                                                        font-size: 12px;
                                                        min-width: 155px;">

                                                                                        {{product.itemNo}}


                                                                                    </td>

                                                                                    <td style="    color: black;
                                                        font-weight: 100;
                                                        font-size: 12px;
                                                        min-width: 155px;">

                                                                                        {{product.termValue}}



                                                                                    </td>
                                                                                    <td style="    color: black;
                                                        font-weight: 100;
                                                        font-size: 12px;
                                                        min-width: 155px; ">

                                                                                        {{product.termDays}}


                                                                                    </td>

                                                                                    <td style="    color: black;
                                                        font-weight: 100;
                                                        font-size: 12px;
                                                        min-width: 155px;">

                                                                                        {{product.textValue}}



                                                                                    </td>
                                                                                    <td style="    color: black;
                                                        font-weight: 100;
                                                        font-size: 12px;
                                                        min-width: 155px;">

                                                                                        {{product.description}}


                                                                                    </td>

                                                                                    <td style="    color: black;
                                                        font-weight: 100;
                                                        font-size: 12px;
                                                        min-width: 155px;">

                                                                                        {{product.uom}}

                                                                                    </td>


                                                                                    <td style="    color: black;
                                                            font-weight: 100;
                                                            font-size: 12px;
                                                            min-width: 155px;">

                                                                                        <fa-icon *ngIf="isEditable" (click)="editItemTerm(product.invoiceTermId)"  class="account-button"  [icon]="faEdit"></fa-icon>
                                                                                        <fa-icon *ngIf="isEditable" (click)="deleteInvoiceTerm(product.invoiceTermId)" class="text-danger cursor-pointer" [icon]="faTrash"></fa-icon>


                                                                                    </td>

                                                                                </tr>

                                                                                <tr class="align-bottom" [formGroup]="invoiceTermForm">
                                                                                    <td>


                                                                                    </td>
                                                                                    <td style=" min-width: 155px;">

                                                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="invoiceTermId" placeholder="Invoice Term Id">

                                                                                    </td>
                                                                                    <td style=" min-width: 155px;">

                                                                                        <p-dropdown filter="true" [options]="invoiceTypeIds" formControlName="termTypeId" optionlabel="label" placeholder="Select Term Type Id">
                                                                                        </p-dropdown>


                                                                                    </td>


                                                                                    <td style=" min-width: 155px;">

                                                                                        <input type="text" class="form-control" placeholder="Item No" id="exampleInputEmail1" formControlName="invoiceItemSeqId">


                                                                                    </td>
                                                                                    <td style=" min-width: 155px;">

                                                                                        <input type="text" class="form-control" placeholder="Term Value" id="exampleInputEmail1" formControlName="termValue">


                                                                                    </td>
                                                                                    <td style=" min-width: 155px;">

                                                                                        <input type="text" class="form-control" placeholder="Term Days" id="exampleInputEmail1" formControlName="termDays">


                                                                                    </td>
                                                                                    <td style=" min-width: 155px;">

                                                                                        <input type="text" class="form-control" placeholder="Text Value" id="exampleInputEmail1" formControlName="textValue">


                                                                                    </td>
                                                                                    <td style=" min-width: 155px;">

                                                                                        <input type="text" class="form-control" placeholder="Description" id="exampleInputEmail1" formControlName="description">


                                                                                    </td>
                                                                                    <td style=" min-width: 155px;">

                                                                                        <p-dropdown filter="true" [options]="uomIdsNewArray" optionlabel="label" formControlName="uomId" placeholder="Select Term Type Id">
                                                                                        </p-dropdown>

                                                                                    </td>


                                                                                    <td style=" min-width: 155px;">

                                                                                        <button type="submit" style="margin-right: 28px;" class="btn btn-secondary submit-btn" (click)="submitTerm()">{{editMode ?'Update':'Add Term'}}</button>


                                                                                    </td>

                                                                                </tr>


                                                                            </tbody>
                                                                        </table>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                
                                              
                                              
                                              </div>
                                        </div>
                                        <div class="col-md-6" style="padding-left: 0;">
                                            <div class="w3-card-4 classCardB">
                                                <div class="container-fluid">
                                                  <ul class="tabbing-section tabbing-accordians" style="background:rgba(204,224,233,.3686274509803922)!important;
                                                  margin-top: 0%;">
                        
                                                    <li [ngClass]="activeCategoryView==3?'active':''" (click)="changeactiveCategoryFunctionView(3)"><a>
                                                        Role
                                                    </a></li>
                                                  </ul>
                                                </div>
                                                <div class="panel-body panelB">
                                                    <form class="w-100">
                                                        <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group table-responsive" style="margin-top: -17px;">
                                                                    <div class="create-new-leade mt-2" style="min-height: calc(100vh - 388px)!important;">
                                                                        <table class="table align-middle">
                                                                            <thead>
                                                                                <tr style="color: grey;">
                                                                                    <th style="font-size: 12px;    height: 44px;    padding: 14px 20px !important;">
                                                                                        S.No

                                                                                    </th>
                                                                                    <th style="font-size: 12px; min-width: 155px;   height: 44px;    padding: 14px 20px !important;">
                                                                                        <span>Party ID</span>

                                                                                    </th>
                                                                                    <th style="font-size: 12px;    height: 44px; min-width: 155px;   padding: 14px 20px !important;">
                                                                                        Role Type ID

                                                                                    </th>

                                                                                    <th style="font-size: 12px;    height: 44px;min-width: 155px;  padding: 14px 20px !important;">
                                                                                        Date Time Performed

                                                                                    </th>
                                                                                    <th style="font-size: 12px;    height: 44px;min-width: 155px;  padding: 14px 20px !important;">
                                                                                        Percentage

                                                                                    </th>


                                                                                    <th style="font-size: 12px;    height: 44px;min-width: 155px;   padding: 14px 20px !important;">
                                                                                        Action

                                                                                    </th>



                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>

                                                                                <tr class="align-bottom" *ngFor="let product of invoiceRoles;let i = index;">
                                                                                    <td style="    color: black;
                                                      font-weight: 100;
                                                      font-size: 12px;
                                                      ">
                                                                                        <div>
                                                                                            {{i+1}}
                                                                                        </div>

                                                                                    </td>

                                                                                    <td style="    color: black;
                                                        font-weight: 100;
                                                        font-size: 12px;
                                                        min-width: 155px; ">
                                                                                        <div>
                                                                                            {{product.partyId}}
                                                                                        </div>



                                                                                    </td>
                                                                                    <td style="    color: black;
                                                        font-weight: 100;
                                                        font-size: 12px;
                                                        min-width: 155px; ">
                                                                                        <div>
                                                                                            {{product.roleTypeId}}
                                                                                        </div>

                                                                                    </td>


                                                                                    <td style="    color: black;
                                                        font-weight: 100;
                                                        font-size: 12px;
                                                        min-width: 155px; ">
                                                                                        <div>
                                                                                            {{product.datetimePerformed |date:'yyyy-MM-d hh:mm:ss'}}
                                                                                        </div>

                                                                                    </td>

                                                                                    <td style="    color: black;
                                                        font-weight: 100;
                                                        font-size: 12px;
                                                        min-width: 155px; ">
                                                                                        <div>
                                                                                            {{product.percentage}}
                                                                                        </div>


                                                                                    </td>


                                                                                    <td style="    color: black;
                                                            font-weight: 100;
                                                            font-size: 12px;
                                                            min-width: 155px; ">
                                                                                        <div>
                                                                                            <fa-icon *ngIf="isEditable" (click)="deleteInvoiceRole(product.partyId,product.roleTypeId)" class="text-danger cursor-pointer" [icon]="faTrash"></fa-icon>
                                                                                        </div>

                                                                                    </td>

                                                                                </tr>

                                                                                <tr class="align-bottom" [formGroup]="createRoleForm">
                                                                                    <td>


                                                                                    </td>
                                                                                    <td style="min-width: 155px; ">

                                                                                        <p-dropdown filter="true" [options]="partyIds" optionlabel="label" placeholder="select PartyId" formControlName="partyId">
                                                                                        </p-dropdown>

                                                                                    </td>
                                                                                    <td style="min-width: 155px; ">

                                                                                        <p-dropdown filter="true" [options]="roleTypeIds" optionlabel="label" placeholder="select Role Type Id" formControlName="roleTypeId">
                                                                                        </p-dropdown>


                                                                                    </td>


                                                                                    <td style="min-width: 155px; ">

                                                                                        <p-calendar formControlName="datetimePerformed">
                                                                                        </p-calendar>


                                                                                    </td>
                                                                                    <td style="min-width: 155px; ">

                                                                                        <input type="text" class="form-control" placeholder="Percentage" formControlName="percentage">


                                                                                    </td>


                                                                                    <td style="min-width: 155px; ">

                                                                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="submitInvoiceRole()">Add Role</button>


                                                                                    </td>

                                                                                </tr>


                                                                            </tbody>
                                                                        </table>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                
                                              
                                              
                                              </div>
                                        </div>

                                    </div>


                                    <div class="w3-card-4 classCardB mt-4" *ngIf="this.invoiceUrl=='payable'" style="width: 100%;margin-top: 2%;margin-left: 0px;">
                                        <header class="w3-container w3-blue">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling h4Margin">
                                                    Invoice List
                                                </h4>
    
                                            </div>
                                        </header>
                                        <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">
    
                                                    <div class="card own-account-table">
                                                        <p-table [value]="invoices" [paginator]="false" [rows]="3" scrollHeight="150px"
                                                        scrollWidth="100%"   [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [responsive]="true" [totalRecords]="total" [scrollable]="true" styleClass="p-datatable-customers" (sortFunction)="customSort($event)"
                                                            [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="    color: gray;">
                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'190px'}">
                                                                        <div class="checkbox-align" >
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            Invoice ID
                                                                            <p-sortIcon field="code">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                        <div > Invoice Type
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                        <div > Invoice Date
                                                                            <p-sortIcon field="category"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="quantity">
                                                                        <div > Status
                                                                            <p-sortIcon field="quantity"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                        <div > Description
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                        <div > Customer Id
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                        <div > Supplier Id</div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
    
                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                        <div > Total</div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                        <div > Outstanding amount</div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                        <div > Created Date</div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                        <div> Action</div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span routerLink="/financial/{{invoiceType}}/invoices/detailed-summary" [queryParams]="{invoiceId:product.invoiceId}"  class="account-button">{{product.invoiceId}}</span>
                                                                        </div>
                                                                    </td>
    
    
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.invoiceTypeId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.invoiceDate |date :'yyyy-MM-d hh:mm:ss'}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">{{product.status }}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.description}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">{{product.partyFrom}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">{{product.partyTo}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.total |currency :'USD'}}</td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.outstanding |currency :'USD'}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.createdStamp |date:'yyyy-MM-d hh:mm:ss'}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        <div>
                                                                            <svg class="hover" xmlns="http://www.w3.org/2000/svg" width="16" *ngIf="product.statusId==='INVOICE_IN_PROCESS'" (click)="editData(product.invoiceId)" height="16" viewBox="0 0 21.223 21.222">
                                                                        <g transform="translate(0 -0.004)">
                                                                            <g transform="translate(0 1.52)">
                                                                                <path
                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                    transform="translate(0 -34.137)" />
                                                                            </g>
                                                                            <g transform="translate(4.548 0.004)">
                                                                                <g transform="translate(0 0)">
                                                                                    <path
                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
    
                                                                        </div>
                                                                    </td>
    
                                                                </tr>
    
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                                    <h4 class="ml-2">No Record Found</h4>
                                                                </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
    
                                                        <!-- <p class="paginate_data">
                                                            View per page </p> -->
    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div *ngIf="this.invoiceUrl=='receiveable'">
                                        <div class="w3-card-4 classCard" style="width: 100%;margin-top: 2%;">
                                            <header class="w3-container w3-blue">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling h4Margin">
                                                        Invoice List
                                                    </h4>
    
                                                </div>
                                            </header>
                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
    
                                                        <div class="card own-account-table">
                                                            <p-table [value]="invoices" [paginator]="false" [rows]="3" scrollHeight="150px"
                                                            scrollWidth="100%"   [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [responsive]="true" [totalRecords]="total" [scrollable]="true" styleClass="p-datatable-customers" (sortFunction)="customSort($event)"
                                                                [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="color: gray;">
                                                                        <th pSortableColumn="code" [ngStyle]="{'width':'190px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                Invoice ID
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                            <div> Invoice Type
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div> Invoice Date
                                                                                <p-sortIcon field="category"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="quantity">
                                                                            <div> Status
                                                                                <p-sortIcon field="quantity"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                            <div> Description
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                            <div> Supplier Id
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                            <div > Customer Id</div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                            <div > Total</div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                            <div > Outstanding amount</div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                            <div> Created Date</div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                            <div> Action</div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                <span routerLink="/financial/{{invoiceType}}/invoices/detailed-summary" [queryParams]="{invoiceId:product.invoiceId}"  class="account-button">{{product.invoiceId}}</span>
                                                                            </div>
                                                                        </td>
    
    
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.invoiceTypeId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.invoiceDate |date :'yyyy-MM-d hh:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">{{product.status }}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.description}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">{{product.partyFrom}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">{{product.partyTo}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.total |currency :'USD'}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.outstanding |currency :'USD'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.createdStamp |date:'yyyy-MM-d hh:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            <div>
                                                                                <svg class="hover" xmlns="http://www.w3.org/2000/svg" width="16" *ngIf="product.statusId==='INVOICE_IN_PROCESS'" (click)="editData(product.invoiceId)" height="16" viewBox="0 0 21.223 21.222">
                                                                        <g transform="translate(0 -0.004)">
                                                                            <g transform="translate(0 1.52)">
                                                                                <path
                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                    transform="translate(0 -34.137)" />
                                                                            </g>
                                                                            <g transform="translate(4.548 0.004)">
                                                                                <g transform="translate(0 0)">
                                                                                    <path
                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
    
                                                                            </div>
                                                                        </td>
    
                                                                    </tr>
    
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                    <h4 class="ml-2">No Record Found</h4>
                                                                </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
    
                                                            <!-- <p class="paginate_data">
                                                                View per page </p> -->
    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                </div>


                                <br>
                              



                                <br> <br> <br>


                            </ng-container>


                            <br> <br> <br>

                        </div>

                    </div>
                </div>
            </div>
            <br> <br> <br>
        </div>
        <br> <br> <br>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle1" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Invoice Term</span>

                <button type="button" style="    margin-top: -4.3%;
            margin-right: -3.2%;" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp" >
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                  background: rgba(204,224,233,.3686274509803922)!important;">

                                <li [ngClass]="activeCategoryMain==2?'active':''"><a>
                        {{editMode ? 'Update':'Create'}} Invoice Term</a></li>



                            </ul>
                        </div>
                        <div>
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 text-white" data-toggle="collapse" href="#collapsetwo">
                                            <div class="w-100">
                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                    <div class="panel panel-default">

                                                        <div role="tabpanel" aria-labelledby="headingOne">
                                                            <div class="panel-body">
                                                                <form [formGroup]="invoiceItemForm">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -7%;
                                                                        font-size: 13px;">
                                                                                    <label for="invoiceItem">Invoice Item
                                                                                Type</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="invoiceItemTypes" optionlabel="label" formControlName="invoiceItemTypeId" placeholder="Select Invoice Type">
                                                                                    </p-dropdown>

                                                                                </div>
                                                                                <div class="col-lg-3 form-group" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                      
                                                                        font-size: 13px;">
                                                                                    <label for="description">Description</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control" id="exampleInputEmail1" formControlName="description">
                                                                                </div>



                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -7%;
                                                                        font-size: 13px;">
                                                                                    <label for="accountId">Override Gl Account
                                                                                ID</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="GIAccounts" optionlabel="label" formControlName="overrideGlAccountId" placeholder="Select Account Id">
                                                                                    </p-dropdown>

                                                                                </div>
                                                                                <div class="col-lg-3 form-group" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                       
                                                                        font-size: 13px;">
                                                                                    <label for="itemId">Inventory Item
                                                                                ID</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="inventoryItems" optionlabel="label" formControlName="inventoryItemId" placeholder="Select Inventory Item">
                                                                                    </p-dropdown>
                                                                                </div>



                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -7%;
                                                                        font-size: 13px;">
                                                                                    <label for="productId">Product ID</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="productIds" optionlabel="label" formControlName="productId" placeholder="Select Product Id">
                                                                                    </p-dropdown>

                                                                                </div>
                                                                                <div class="col-lg-3 form-group" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                      
                                                                        font-size: 13px;">
                                                                                    <label for="featureId">Product Feature
                                                                                Id</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="featureIds" optionlabel="label" formControlName="productFeatureId" placeholder="Select Product Id">
                                                                                    </p-dropdown>
                                                                                </div>



                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -7%;
                                                                        font-size: 13px;">
                                                                                    <label for="quantity">Quantity</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control" id="exampleInputEmail1" formControlName="quantity">

                                                                                </div>
                                                                                <div class="col-lg-3 form-group" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                       
                                                                        font-size: 13px;">
                                                                                    <label for="uom">Uom</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="uomIds" optionlabel="label" formControlName="uomId" placeholder="Select Uom">
                                                                                    </p-dropdown>
                                                                                </div>



                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -7%;
                                                                        font-size: 13px;">
                                                                                    <label for="unitPrice">Unit Price</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control" id="exampleInputEmail1" formControlName="amount">

                                                                                </div>
                                                                                <div class="col-lg-3 form-group" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                      
                                                                        font-size: 13px;">
                                                                                    <label for="flag">Taxable Flag</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" formControlName="taxableFlag" [options]="decisionArray" optionlabel="label">
                                                                                    </p-dropdown>
                                                                                </div>



                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                    <div class="main-submit-button" style="    margin-right: 25%;">
                                                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="submitItem()">{{editMode ?'Update':'Submit'}}</button>

                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Invoice Item</span>

                <button style="    margin-top: -4.3%;
            margin-right: -3.2%;" type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton1>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp" >
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                  background: rgba(204,224,233,.3686274509803922)!important;">

                                <li [ngClass]="activeCategoryMain==2?'active':''"><a>
                        {{editMode ? 'Update':'Create'}} Invoice Term</a></li>



                            </ul>
                        </div>
                        <div>
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 text-white" data-toggle="collapse" href="#collapsetwo">
                                            <div class="w-100">
                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                    <div class="panel panel-default">

                                                        <div role="tabpanel" aria-labelledby="headingOne">
                                                            <div class="panel-body">
                                                                <form [formGroup]="invoiceTermForm">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -11%;
                                                                        font-size: 13px;">
                                                                                    <label for="invoiceTermId">Invoice Term
                                                                                ID</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control" id="exampleInputEmail1" formControlName="invoiceTermId">

                                                                                </div>
                                                                                <div class="col-lg-3 form-group" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                       
                                                                        font-size: 13px;">
                                                                                    <label for="partyId">Term Type ID</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="invoiceTypeIds" formControlName="termTypeId" optionlabel="label" placeholder="Select Term Type Id">
                                                                                    </p-dropdown>
                                                                                </div>



                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -11%;
                                                                        font-size: 13px;">
                                                                                    <label for="itemNo">Item No</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control" id="exampleInputEmail1" formControlName="invoiceItemSeqId">

                                                                                </div>
                                                                                <div class="col-lg-3 form-group" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                      
                                                                        font-size: 13px;">
                                                                                    <label for="termValue">Term Value</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control" id="exampleInputEmail1" formControlName="termValue">
                                                                                </div>



                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -11%;
                                                                        font-size: 13px;">
                                                                                    <label for="termDays">Term Days</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control" id="exampleInputEmail1" formControlName="termDays">

                                                                                </div>
                                                                                <div class="col-lg-3 form-group" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                       
                                                                        font-size: 13px;">
                                                                                    <label for="termValue">Text Value</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control" id="exampleInputEmail1" formControlName="textValue">
                                                                                </div>



                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -11%;
                                                                        font-size: 13px;">
                                                                                    <label for="termValue">Description</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control" id="exampleInputEmail1" formControlName="description">

                                                                                </div>
                                                                                <div class="col-lg-3 form-group" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                      
                                                                        font-size: 13px;">
                                                                                    <label for="partyId">Uom</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="uomIds" optionlabel="label" formControlName="uomId" placeholder="Select Term Type Id">
                                                                                    </p-dropdown>
                                                                                </div>



                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                    <div class="main-submit-button" style="
                                                            margin-right: 29%;
                                                        ">
                                                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="submitTerm()">{{editMode ?'Update':'Submit'}}</button>

                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle2" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Invoice Role</span>

                <button style="    margin-top: -4.3%;
            margin-right: -3.2%;" type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton2>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp" >
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                  background: rgba(204,224,233,.3686274509803922)!important;">

                                <li [ngClass]="activeCategoryRole==2?'active':''"><a>
                        Add New Party Role</a></li>



                            </ul>
                        </div>
                        <div>
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 text-white" data-toggle="collapse" href="#collapsetwo">
                                            <div class="w-100">




                                                <div class="panel panel-default">

                                                    <div>
                                                        <div class="panel-body">
                                                            <form [formGroup]="createRoleForm">
                                                                <div class="row">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group" style="
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -8%;
                                                                        font-size: 13px;
                                                                    ">
                                                                                <label for="partyId">Party ID</label>
                                                                            </div>

                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" [options]="partyIds" optionlabel="label" placeholder="select PartyId" formControlName="partyId">
                                                                                </p-dropdown>

                                                                            </div>
                                                                            <div class="col-lg-3 form-group" style="
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                       
                                                                        font-size: 13px;
                                                                    ">
                                                                                <label for="partyId">Role Type ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" [options]="roleTypeIds" optionlabel="label" placeholder="select Role Type Id" formControlName="roleTypeId">
                                                                                </p-dropdown>
                                                                            </div>



                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group" style="
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -8%;
                                                                        font-size: 13px;
                                                                    ">
                                                                                <label for="date">Date Time
                                                                                Performed</label>
                                                                            </div>

                                                                            <div class="col-lg-2">
                                                                                <p-calendar formControlName="datetimePerformed">
                                                                                </p-calendar>

                                                                            </div>
                                                                            <div class="col-lg-3 form-group" style="
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                      
                                                                        font-size: 13px;
                                                                    ">
                                                                                <label for="percentage">Percentage</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control" formControlName="percentage">
                                                                            </div>



                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div class="main-submit-button" style="
                                                            margin-right: 26%;
                                                        ">
                                                                    <button type="submit" class="btn btn-secondary submit-btn" (click)="submitInvoiceRole()">Submit</button>
                                                                    <button class="btn btn-danger cursor-pointer ml-2" (click)="reset()">Cancel</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>