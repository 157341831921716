<div class="container-fluid main-container-wrapper">

    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span class="color-grey fontheader">Catalog</span>

                <span class="d-flex">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="mb-5">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left: 9px; width: calc(103% - 5px); flex-wrap: wrap; display: flex; margin-bottom: 8px !important;display: none !important;">
                            <li [ngClass]="activeCat==1?'active':''" (click)="mainOpen()">
                                <a>Main</a>
                            </li>
                            <li [ngClass]="activeCat==2?'active':''" (click)="catalogOpen()">
                                <a>Catalogs</a>
                            </li>
                            <li [ngClass]="activeCat==3?'active':''" (click)="categoriesOpen()">
                                <a>Categories</a>
                            </li>
                            <li [ngClass]="activeCat==4?'active':''" (click)="productsOpen()">
                                <a>Products</a>
                            </li>
                            <li [ngClass]="activeCat==5?'active':''" (click)="featuresOpen()">
                                <a>Features</a>
                            </li>
                            <li [ngClass]="activeCat==6?'active':''" (click)="promosOpen()">
                                <a>Promos</a>
                            </li>
                            <li [ngClass]="activeCat==7?'active':''" (click)="priceRulesOpen()">
                                <a>Price Rules</a>
                            </li>
                            <li [ngClass]="activeCat==8?'active':''" (click)="storesOpen()">
                                <a>Stores</a>
                            </li>
                            <li [ngClass]="activeCat==9?'active':''" (click)="productStoreGroupsOpen()">
                                <a>Product Store Groups</a>
                            </li>
                            <li [ngClass]="activeCat==10?'active':''" (click)="thesaurusOpen()">
                                <a>Thesaurus</a>
                            </li>
                            <li [ngClass]="activeCat==11?'active':''" (click)="reviewsOpen()">
                                <a>Reviews</a>
                            </li>
                            <li [ngClass]="activeCat==12?'active':''" (click)="configurationsOpen()">
                                <a>Configurations</a>
                            </li>
                            <li [ngClass]="activeCat==13?'active':''" (click)="subscriptionsOpen()">
                                <a>Subscriptions</a>
                            </li>
                            <li [ngClass]="activeCat==14?'active':''" (click)="shippingOpen()">
                                <a>Shipping</a>
                            </li>
                            <li [ngClass]="activeCat==15?'active':''" (click)="imageManagementOpen()">
                                <a>Image Management</a>
                            </li>
                        </ul>
                    </div>
                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                        <div class="create-new-leade mt-2">
                            <div class="">
                                <div class="">
                                    <form class="w-100" [formGroup]="searchCatalogForm">

                                        <div class="w3-card-4  w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                            <!-- <span class="headingAccords"> Search Option</span> -->
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-outline-secondary active marginHeader" data-toggle="modal" data-target="#exampleModalCenter">Create New Catalog</button>

                                                            <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 77%!important; padding-top: 11px !important;
                                                                padding-bottom: 11px !important;">
                                                            </button>									
                
                                                        </div>
                    
                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                        <div class="card-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Prod Catalog Id</label>
                                                                        </div>
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown filter="true" formControlName="prodCatalogIdSearchType" [options]="opportunityIdArray" optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control w-100" formControlName="prodCatalogId" placeholder="Enter Catalog ID">
                                                                        </div>
                
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Catalog Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown filter="true" formControlName="catalogNameSearchType" [options]="opportunityIdArray" optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control w-100" formControlName="catalogName" placeholder="Enter Catalog Name">
                                                                        </div>
                                                                    </div>
                                                                </div>
                
                
                
                
                                                            </div>
    
                                                            <div class="col-lg-12 col-12 main-submit-button " style="
                                                            margin-left: -31%;"><button type="submit" class="btn btn-secondary submit-btn" (click)="getCatalogList()">Find</button>&nbsp;
                                                                    <button type="button" class="btn btn-danger ml-2" (click)="resetForm()">Reset</button>
                                                                </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <!-- <div class="w3-card-4 classCard">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <button type="button" class="btn btn-outline-secondary active" data-toggle="modal" data-target="#exampleModalCenter">Create New Catalog</button>
                                            </div><br>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Prod Catalog Id</label>
                                                        </div>
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown filter="true" formControlName="prodCatalogIdSearchType" [options]="opportunityIdArray" optionlabel="label">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control w-100" formControlName="prodCatalogId" placeholder="Enter Catalog ID">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Catalog Name</label>
                                                        </div>
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown filter="true" formControlName="catalogNameSearchType" [options]="opportunityIdArray" optionlabel="label">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control w-100" formControlName="catalogName" placeholder="Enter Catalog Name">
                                                        </div>
                                                    </div>
                                                </div>




                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button " style="
                                        margin-left: -31%;"><button type="submit" class="btn btn-secondary submit-btn" (click)="getCatalogList()">Find</button>&nbsp;
                                                <button type="button" class="btn btn-danger ml-2" (click)="resetForm()">Reset</button>
                                            </div>
                                        </div> -->
                                        <br>
                                        
                                        <div class="w3-card-4 classCard">
                                            <header class="w3-container w3-blue">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling h4Margin">
                                                        Catalog List
                                                    </h4>

                                                </div>
                                            </header>
                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">

                                                        <div class="card own-account-table">
                                                            <p-table [value]="getCatalogLists" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th pSortableColumn="productCategoryId">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                Catalog ID
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="categoryName">
                                                                            <div style="color: white;"> Catalog Name
                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th pSortableColumn="category">
                                                                            <div style="color: white;"> Use Quick Add
                                                                                <p-sortIcon field="category"></p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>

                                                                        <th pSortableColumn="price">
                                                                            <div style="color: white;"> Created Date
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td>
                                                                            <a (click)="detailPage(product.prodCatalogId)"  class="account-button">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    {{product.prodCatalogId}}
                                                                                </div>
                                                                            </a>
                                                                        </td>
                                                                        <td>{{product.catalogName}}</td>
                                                                        <td>{{product.useQuickAdd ? product.useQuickAdd:'N/A' }}
                                                                        </td>
                                                                        <td>{{product.createdStamp | date :'medium'}}</td>


                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>

                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Product Catalog</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                        New Product Catalog</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="catalogForm" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12 mb-20">If you don't populate a Catalog with Categories, it will not be shown in the Browse Catalogs/Categories tree</div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Catalog [ID]</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="prodCatalogId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter CatalogID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Catalog Name 
                                                            <span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="catalogName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Catalog Name">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Use Quick Add
                                              
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="catalogArray" formControlName="useQuickAdd" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Style Sheet
                                              
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="styleSheet" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enetr Style Sheet">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Header Logo </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="headerLogo" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Header Logo">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Content Path Prefix </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="contentPathPrefix" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enetr Content Path Prefix ">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Template Path Prefix</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="templatePathPrefix" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Template Path Prefix">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Add product to "ViewAllow" category</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="catalogArray" formControlName="viewAllowPermReqd" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Add product to "PurchaseAllow"
                                                        category</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="catalogArray" formControlName="purchaseAllowPermReqd" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>


                                        <div class="main-submit-button" style="margin-right: 7%;">
                                            <button type="submit" (click)="onSubmit()" class="btn btn-secondary submit-btn">{{editMode? 'Update':'Submit'}}</button>

                                        </div>
                                    </form>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>