<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">
    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">
            <div class="container-fluid" style="padding: 0px 0px;">
            
                <div class="col-md-12 p-0" >
                    <div class="w3-card-4 classCard  w-100">
                
                         <div class=" mt-3"> 
                            <label style="font-weight:15px !important ; font-weight: bold; 
                            margin-left: 1%;">Quick Books Integration</label>

                        </div>
                    
                        <div class="col-lg-12">
                            <div class="row">
                                <p>
                                    You can choose to integrate with QuickBooks below which will allow you to:
                                </p>

                            </div>

                        </div> 

                         <div class="col-lg-12">
                            <div class="row  mt-2">
                                <ul class="font-12">
                                    <li>Sync Customer </li>
                                    <li>Sync Items </li>
                                    <li>Export Invoices  </li>
                                    <li>Export Payments </li>
                                    <li>Export Techs </li>
                                    <li>Export TimeSheet </li>

                                </ul>

                            </div>

                        </div>


                        <div class="row " style="width: 100%; margin-top: 1%;">
                            <div class="col-md-12">
                                <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px; margin-bottom: 1%; height: 300px;">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                        style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px !important;">
                                        <p class="h-text color-grey"
                                            style="margin: 0px 15px -1px -2px !important; color: black;">
                                            QuickBooks Integration Settings </p>
                                        
                                    </div>
                                    <div class="">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <form class="w-100">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row d-flex font-12B mt-2">
                                                                <div class="col-lg-4  classInput">
                                                                    <label>Do you want to Integrate with
                                                                        QuickBooks?
                                                                    </label>

                                                                </div>

                                                                <div class="col-lg-2">
                                                                    <label>Yes</label>

                                                                </div>

                                                                <div class="col-lg-1">
                                                                    <a class="" href="" style=" margin-left: -15px !important;">Edit</a>

                                                                </div>


                                                            </div>

                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row d-flex font-12B mt-1">
                                                                <div class=" classInput col-lg-4">
                                                                    <label>Integration Client Authentication Code
                                                                    </label>

                                                                </div>

                                                                <div class="col-lg-2">
                                                                    <label>............</label>

                                                                </div>

                                                                <div class="1">
                                                                    <a class="" href="" style="">Edit</a>

                                                                </div>

                                                                <div class="col-lg-5">
                                                                    <label style="color:blue;">Choose a authentication code you can remember and center it here. You will
                                                                        also have to enter this is in the QuickBooks intergation client in order to 
                                                                    run the Sync. </label>
                                                                </div>


                                                            </div>

                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row mt-1">
                                                                <p>
                                                                    You must download and install our client in order to integrate to QuickBooks
                                                                </p>
    
                                                            </div>

                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row mt-3">
                                                                <button type="submit" class="btn btn-secondary submit-btn" style="margin-left:15px !important;">
                                                                    Install .... QuickBooks Integration Client
                                                                </button>
    
                                                            </div>

                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <hr style="width: 90%; justify-content: center;">
    
                                                            </div>

                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row font-12">
                                                                <p style="font-weight: bold !important;">Recommended Best Practice Workflow for Invoices<br></p>
                                                                <p style="font-weight: normal !important;">Not Prepared ->Prepared -> Approved -> Exported<br> </p>
                                                                <p style="font-weight: normal !important;">Note: Invoices will get to Exported state automatically
                                                                    when export happens.
                                                                </p>
    
                                                            </div>
                                                        </div>

                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                


                            </div>

                            
                        </div> 
                    </div>
                </div>
       
        </div>
    </div>

</div>

