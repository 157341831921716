<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
          ]
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Events</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class=" bg-white color-grey">
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="w-100">
                            <div class="w3-card-4 classCard" >
                                <div  class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                         <button type="button" class="btn btn-outline-secondary active" routerLinkActive="active"
                                            routerLink="/crm/events/create-events">Create New Event</button>
                                        <button type="button" class="btn btn-outline-secondary active" (click)="onFirstChange()"
                                            [routerLinkActive]="'is-active'" routerLink="/crm/events">Assigned
                                            To Me</button>
                                        <button type="button" class="btn btn-outline-secondary active" [routerLinkActive]="'is-active'"
                                            routerLink="/crm/events" (click)="onChange()">Assigned By Me</button>
                                        <button type="button" class="btn btn-outline-secondary active" routerLinkActive="active"
                                            routerLink="/crm/events/all-events">All Events</button>
                                      
                                  
                                </div>
                            <div class="all-users-infomation font-13 font-medium own-user-select" style="    padding: 15px 15px!important;">
                                <div class="card own-account-table">
                                    <p-table [value]="allEventsData" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                        [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                    <div class="checkbox-align" style="color: white;">
                                                        <p-checkbox styleclass="custom-checkbox" binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined"
                                                                        value="undefined"></div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>
                                                        WorkEffort ID <p-sortIcon field="code">
                                                        </p-sortIcon>
                                                    </div>
                                                </th>
                                                <th pSortableColumn="name" [ngStyle]="{'width':'170px'}"><div style="color: white;">Event Name
                                                    <p-sortIcon field="name"></p-sortIcon></div>
                                                </th>
                                                <th pSortableColumn="name" [ngStyle]="{'width':'170px'}"><div style="color: white;">Purpose
                                                    <p-sortIcon field="name"></p-sortIcon></div>
                                                </th>
                                                <th pSortableColumn="category" [ngStyle]="{'width':'170px'}">
                                                    <div style="color: white;"> Description
                                                    <p-sortIcon field="category"></p-sortIcon></div>
                                                </th>
                                                <th pSortableColumn="quantity" [ngStyle]="{'width':'170px'}"><div style="color: white;"> Priority
                                                    <p-sortIcon field="quantity"></p-sortIcon></div>
                                                </th>
                                                <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Estimated
                                                    Start Date
                                                    <p-sortIcon field="price"></p-sortIcon></div>
                                                </th>
                                                <th pSortableColumn="price" [ngStyle]="{'width':'180px'}"><div style="color: white;">Estimated
                                                    Completion Date
                                                    <p-sortIcon field="price"></p-sortIcon></div>
                                                </th>
                                                <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Actual Start
                                                    Date
                                                    <p-sortIcon field="price"></p-sortIcon></div>
                                                </th>
                                                <th pSortableColumn="price" [ngStyle]="{'width':'180px'}"><div style="color: white;">Actual
                                                    Completion Date
                                                    <p-sortIcon field="price"></p-sortIcon></div>
                                                </th>
                                                <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Assigned by
                                                    <p-sortIcon field="price"></p-sortIcon></div>
                                                </th>
                                                <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Created At
                                                    <p-sortIcon field="price"></p-sortIcon></div>
                                                </th>

                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td [ngStyle]="{'width':'170px'}">
                                                    <div class="checkbox-align">
                                                        <p-checkbox styleclass="custom-checkbox" binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined"
                                                                        value="undefined"></div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                        </p-checkbox>
                                                        {{product.workEffortId}}
                                                    </div>
                                                </td>
                                                <td [ngStyle]="{'width':'170px'}">{{product.workEffortName}}</td>
                                                <td [ngStyle]="{'width':'170px'}">{{product.workEffortPurposeType}}</td>
                                                <td [ngStyle]="{'width':'170px'}">{{product.description}}</td>
                                                <td [ngStyle]="{'width':'170px'}">{{product.priority}}</td>
                                                <td [ngStyle]="{'width':'170px'}">
                                                    {{product.estimatedStartDate  | date:'yyyy-MM-dd  HH:mm:ss'}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">
                                                    {{product.estimatedCompletionDate  | date:'yyyy-MM-dd  HH:mm:ss'}}
                                                </td>
                                             
                                                <td [ngStyle]="{'width':'170px'}">
                                                    {{product.estimatedStartDate | date:'yyyy-MM-dd  HH:mm:ss'}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">
                                                    {{product.estimatedCompletionDate | date:'yyyy-MM-dd  HH:mm:ss'}}
                                                </td>
                                                <td [ngStyle]="{'width':'170px'}">{{product.assignedBy}}
                                                </td>
                                                <td [ngStyle]="{'width':'170px'}">
                                                    {{product.createdStamp | date:"yyyy-MM-dd HH:mm:ss"}}
                                                </td>

                                            </tr>
                                        </ng-template>
                                    </p-table>
                                    <p class="paginate_data">
                                        View per page </p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner></ngx-spinner> 