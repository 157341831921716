import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-gl-account-category-list',
  templateUrl: './gl-account-category-list.component.html',
  styleUrls: ['./gl-account-category-list.component.css']
})
export class GlAccountCategoryListComponent implements OnInit {
  show:boolean;
  @ViewChild('closebutton') closebutton;
  createAccountCategory:FormGroup;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  advanceSearch :{
    glAccountCategoryId:string,
    glAccountCategoryIdSearchType: string,
    glAccountCategoryTypeId:string
  
  };
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  GlAccountCategories: any;
  glAccountCategoryType: any;
  glAccountCategoryTypeArray: any[]=[];
  activeCategory=8;
  constructor(  
    readonly toastr: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly accountsService: AccountsService,
    readonly _FormBuilder: FormBuilder,
   ) 
   {
    this.createAccountCategory = this._FormBuilder.group({
      description: [''],
      glAccountCategoryId:  [''],
      glAccountCategoryTypeId:  ['',[Validators.required]],

    });
      this.advanceSearch = {
        glAccountCategoryId: "",
        glAccountCategoryIdSearchType: "Contains",
        glAccountCategoryTypeId: ""
      
      };
      this.opportunityIdArray = [
        {
          label: 'Contains',
          value: 'Contains'
        },
        {
          label: 'Equals',
          value: 'Equals'
        },
        {
          label: 'Begins With',
          text: 'Not Equal'
        },
        {
          label: 'Is Empty',
          value: 'Is Empty'
        },
        {
          label: 'Begins With',
          value: 'Begins With'
        },
      ];
    }
    getChild=[{
      value:'6011'
    }]
    // ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.spinner.hide();
    this.activeCategory=8;
    this.getGlAccountCategories();
    this.getGlAccountCategoriesInitialData();

    this.getGlAccountCategoriesInitialDataNew();
  }
  getGlAccountCategoriesInitialDataNew() {
    this.spinner.show();
    this.accountsService.getGlAccountCategoriesInitialData().subscribe(res => {
      
      this.glAccountCategoryType = res.data.glAccountCategoryType;
      this.spinner.hide();
      for (const value of this.glAccountCategoryType) {
        this.glAccountCategoryTypeArray.push({
          label: value.description,
          value: value.glAccountCategoryTypeId
        })
      }
    })
  
  }
  onSubmit() {
    this.spinner.show();
    const requestData = {
      description:this.createAccountCategory.value.description,
      glAccountCategoryId:"",
      glAccountCategoryTypeId:  this.createAccountCategory.value.glAccountCategoryTypeId,
      
     
    }

    this.accountsService.postGlAccountCategories(requestData).subscribe((res: any) => {
    
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.createAccountCategory.reset();
        this.getGlAccountCategories();
        this.closebutton.nativeElement.click();
        // this.router.navigate(["financial/gl-account-category-list"])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  chartAccount(){
    this.router.navigate(["/financial/global-gl-setting-list"])
  }
  timePeriod(){
    this.router.navigate(["/financial/gl-custom-time-period-list"])
  }
  cost(){
    this.router.navigate(["/financial/global-gl-cost"])
  }
  paymentMethod(){
    this.router.navigate(["/financial/gl-payment-method-type"])
  }
  invoiceMethod(){
    this.router.navigate(["/financial/gl-invoice-type-list"])
  }
  rate(){
    this.router.navigate(["/financial/global-gl-rate-list"])
  }
  foreignExc(){
    this.router.navigate(["/financial/gl-foreign-exchange-rate"])
  }
  accountCat(){
    this.router.navigate(["/financial/gl-account-category-list"])
  }
  costCenter(){
    this.router.navigate(["/financial/gl-cost-center"])
  } 
  getGlAccountCategoriesInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountCategoriesInitialData().subscribe(res => {
      
      this.glAccountCategoryType = res.data.glAccountCategoryType;
      
      for (const value of this.glAccountCategoryType) {
        this.glAccountCategoryTypeArray.push({
          label: value.description,
          value: value.glAccountCategoryTypeId
        })
      }
    })
    this.spinner.hide();
  }
  
  getGlAccountCategories() {
    this.spinner.show();
    const req = {
      "glAccountCategoryId": "",
      "glAccountCategoryIdSearchType": "Contains",
      "glAccountCategoryTypeId": ""
     

    }
    this.accountsService.getGlAccountCategories(this.finSize, req).subscribe((res: any) => {

      this.GlAccountCategories = res.data;
      this.spinner.hide();


    })
   
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  // createAccountCategory(){
  //   this.router.navigate(["financial/create-gl-account-category"])
   
  // }
  summaryDetail(eglAccountCategoryId,eglAccountCategoryTypeId,edescription){
    this.router.navigate(["financial/detail-account-category-summary"], { queryParams: { glAccountCategoryId: eglAccountCategoryId,glAccountCategoryTypeId:eglAccountCategoryTypeId,description:edescription } })
  }
  
  updateAccountCategory(eglAccountCategoryId,eglAccountCategoryTypeId,edescription){
    this.router.navigate(["financial/create-gl-account-category"], { queryParams: { glAccountCategoryId: eglAccountCategoryId,glAccountCategoryTypeId:eglAccountCategoryTypeId,description:edescription } })
   
  }
  reset(){
    this.spinner.show();
    this.advanceSearch.glAccountCategoryId="";
    this.advanceSearch.glAccountCategoryTypeId="";
    const req = {
      "glAccountCategoryId": "",
      "glAccountCategoryIdSearchType": "Contains",
      "glAccountCategoryTypeId": ""
    }
    this.accountsService.getGlAccountCategories(this.finSize, req).subscribe((res: any) => {

      this.GlAccountCategories = res.data;
      this.spinner.hide();


    })
   
  }
  advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    this.GlAccountCategories = [];
    const req = {
      "glAccountCategoryId": this.advanceSearch.glAccountCategoryId ? this.advanceSearch.glAccountCategoryId : '',
      "glAccountCategoryIdSearchType": this.advanceSearch.glAccountCategoryIdSearchType ? this.advanceSearch.glAccountCategoryIdSearchType : '',
      "glAccountCategoryTypeId":this.advanceSearch.glAccountCategoryTypeId ? this.advanceSearch.glAccountCategoryTypeId : '',

     
    }
    this.accountsService.getGlAccountCategories(this.finSize, req)
      .subscribe(res => {
        if (res.success) {
          this.spinner.hide();
          this.GlAccountCategories = res.data;
        }
      });
     
  }
  // ngOnDestroy(): void {
  //   this.spinner.hide();
  //     }
}
