import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2';
import { SortEvent } from 'primeng/api';
@Component({
  selector: 'app-work-effort-requests',
  templateUrl: './work-effort-requests.component.html',
  styleUrls: ['./work-effort-requests.component.css']
})
export class WorkEffortRequestsComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 8;
  addReq: FormGroup;
  addReqItem: any;
  CustRequestIds: any;
  CustRequestIdsArray: any[]=[];
  CustRequestItem: any;
  CustRequestItemArray: any[]=[];
  workEffortId: any;
  WorkEffortRequestListByIddata: any;
  total=0;
  rowNumber=0;
  pageSize= 500;
  rows = 50;
  pageNo=1;
  search="";
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly createEventServices: CreateEventServices,
    private _location: Location,
    
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly toastr: ToastrService,
    readonly myContactsService: MyContactsService,
    readonly addSkillService: AddSkillService,
        readonly _FormBuilder: FormBuilder,
    private _Router : Router

  ) {
    this.addReq = this._FormBuilder.group({
      custRequestID: [''],
    });
    this.addReqItem = this._FormBuilder.group({
      requestID: [''],
      requestItemID: [''],
    })
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params["workEffortId"];
    });
    this.getCustRequestIds();
    this.getCustRequestItem();
    this.getWorkEffortRequestListById();
    }
    customSort(event: SortEvent) {
      event.data.sort((data1, data2) => {
          const value1 = data1[event.field];
          const value2 = data2[event.field];
          let result = null;
  
          if (value1 == null && value2 != null){
            result = -1;
          }
          else if (value1 != null && value2 == null){
            result = 1;
          }
          else if (value1 == null && value2 == null) {
              result = 0;
           }
          else if (typeof value1 === 'string' && typeof value2 === 'string') {
              result = value1.localeCompare(value2);
          }
          else {
              result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          }
          return (event.order * result);
      });
    }
    homeButton(){
       this._Router.navigate(['/psa/Dashboard'])
     
     }
     cancelSubmit(){
       this._location.back();
     }
     
    deleteWorkEffortRequest(product) {
    
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          const req={
            "custRequestId": product.custRequestId,
  "workEffortId": this.workEffortId
          }
          this.myContactsService.deleteWorkEffortRequest(req).subscribe(res => {
            this.getWorkEffortRequestListById(); 
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    
  
    }
    createWorkEffortRequest(): void {
      this.spinner.show();
      
      
       const formData ={
        "custRequestId": this.addReq.value.custRequestID,
  "workEffortId": this.workEffortId
    
         
           
       }
       this.myContactsService.createWorkEffortRequest(formData).subscribe((res: any) => {
        
         if (res.success) {
           this.toastr.success("Successfully Created");
           this.spinner.hide();
           this.closebutton.nativeElement.click();
           this.addReq.reset();
           this.getWorkEffortRequestListById();
           
          
         }
       }, (err) => {
        this.spinner.hide();
         for (const value of err.error.errors) {
           this.toastr.error(value.fieldName + ' ' + value.fieldError);
         }
       });
      
     }
  getWorkEffortRequestListById(){
    this.spinner.show();
    this.myContactsService.getWorkEffortRequestListById(this.workEffortId).subscribe((res:any)=> {
      this.WorkEffortRequestListByIddata = res.data;
      this.spinner.hide();
    })   
   }
  addWorkEffort(){
    this._Router.navigate(["/psa/work-effort/add-work-effort"])
  }
  editWorkEffort1(){
    this._Router.navigate(["/psa/work-effort/task-list-view/editWorkEffort"]),{ queryParams: { workEffortId: this.workEffortId} }
  }
  summary(){
    this._Router.navigate(["/psa/work-effort/task-list-view/summary"],{ queryParams: { workEffortId: this.workEffortId} })
  }

  childWorkEfforts(){
    this._Router.navigate(["/psa/work-effort/task-list-view/childWorkEfforts"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  partyAssigns(){
    this._Router.navigate(["/psa/work-effort/task-list-view/PartyAssigns"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  rates(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Rates"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  commEvents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/CommEvents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  shopLists(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ShopLists"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requests(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requests"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requirements(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requirements"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  quotes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Quotes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  orderHeaders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/OrderHeaders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  timeEntries(){
    this._Router.navigate(["/psa/work-effort/task-list-view/TimeEntries"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  notes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Notes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Contents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  products(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Products"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  reviews(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Reviews"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  keywords(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Keywords"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contactMechs(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ContactMechs"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  agreementApplics(){
    this._Router.navigate(["/psa/work-effort/task-list-view/AgreementApplics"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  fixedAssets(){
    this._Router.navigate(["/psa/work-effort/task-list-view/FixedAssets"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  attributes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Attributes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  eventReminders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/EventReminders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  getCustRequestIds() {
    this.spinner.show();
    this.addSkillService.getCustRequestIds().subscribe(res => {
      this.CustRequestIds = res.data[0].list;
      this.spinner.hide();
      for (const value of this.CustRequestIds) {
        this.CustRequestIdsArray.push({
          label: value.custRequestId,
          value: value.custRequestId 
        })
      }
    })
  }
  getCustRequestItem() {
    this.spinner.show();
    this.addSkillService.getCustRequestItem().subscribe(res => {
      this.CustRequestItem = res.data[0].list;
      this.spinner.hide();
      for (const value of this.CustRequestItem) {
        this.CustRequestItemArray.push({
          label: value.custRequestItemSeqId,
          value: value.custRequestId
        })
      }
    })
  }

}
