import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'
import { Location } from '@angular/common';
import { ProductionService } from '../production.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
@Component({
  selector: 'app-manufacturing-rules',
  templateUrl: './manufacturing-rules.component.html',
  styleUrls: ['./manufacturing-rules.component.css']
})
export class ManufacturingRulesComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  products3: any;
  total=0;
  activeCategoryMain=2;
  rowNumber=0;
  pageSize= 500;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  financialList=[{
    "product":"1"
  }];
  FinAccountTypeList: any;
  FinAccountTypeListArray: any[] = [];
  smallerArray: { label: string; value: string; }[];
  greaterArray: { label: string; value: string; }[];
  ManufacturingRule: any;
  createManRule: FormGroup;
  activeCategory = 2;
  productListArray: any[] = [];
  ProductFeatureIds: any;
  ProductFeatureIdsArray: any[] = [];
  Rule = [{
    "label": "OR",
    "value": "OR"
  },
  {
    "label": "AND",
    "value": "AND"
  }]
  fromDate: string;
  ThroughDate: string;
  ruleId: any;
  show: boolean;
  getEstimateFrom: string;
  estimatedCompletionDate: string;
  productInIds: any;
  productInIdsArray: any[]=[];
  date: Date;
  constructor(readonly spinner: NgxSpinnerService,
    readonly productionService:ProductionService, readonly toastr: ToastrService,
    readonly router: Router,
    readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,
    private _location: Location,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService
   ) {
      this.createManRule = this._FormBuilder.group({
        ProductID: [''],
        ProductIDFor: [''],
        ProductIDIn: [''],
        FromDate: [''],
        ProductIDInSubst: [''],
        ProductFeature: [''],
        RuleOperator: [''],
        Quantity: [''],
        ThroughDate: [''],
  
      });
  
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getManufacturingRule();
    this.date=new Date();
    let latest_date =this.datePipe.transform(this.date, 'yyyy-MM-dd');
    const formValue = this.createManRule;
    formValue.patchValue({
      FromDate:latest_date
    })
    this.getProducts();
    this.getProductFeatureIds();
    this.getproductInIds();
  }
    
 
  getProductFeatureIds() {
    this.spinner.show();

    this.productionService.getProductFeatureIds().subscribe(res => {
      this.ProductFeatureIds = res.data;
      this.spinner.hide();
      for (const value of this.ProductFeatureIds) {
        this.ProductFeatureIdsArray.push({
          label: value.description,
          value: value.productFeatureId
        })
      }
    })

  }
  getproductInIds() {
    this.spinner.show();

    this.productionService.getproductInIds().subscribe(res => {
      this.productInIds = res.data;
      this.spinner.hide();
      for (const value of this.productInIds) {
        this.productInIdsArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })

  }
  
  getProducts(): void {
    this.spinner.show();
    const data = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains",
    };
    this.accountsService.getProductsList(data).subscribe((res) => {
      const productList = res.data;
      this.spinner.hide();
      this.productListArray = res.data.map(value => {
        return {
          label: value.productId,
          value: value.productId
        };
      });

    });

  }
  homeButton(){
    this.router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  onSubmit() {
    this.spinner.show();
    const FromDate = this.createManRule.get('FromDate').value;
    this.fromDate = this.datePipe.transform(FromDate, "yyyy-MM-dd hh:mm:ss");
    const ThroughDate = this.createManRule.get('ThroughDate').value;
    this.ThroughDate = this.datePipe.transform(ThroughDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = { 
      "fromDate":this.fromDate,
      "productFeature": this.createManRule.value.ProductFeature,
      "productId": this.createManRule.value.ProductID,
      "productIdFor":this.createManRule.value.ProductIDFor,
      "productIdIn": this.createManRule.value.ProductIDIn,
      "productIdInSubst":this.createManRule.value.ProductIDInSubst,
      "quantity":this.createManRule.value.Quantity,"ruleId":"",
      "ruleOperator":this.createManRule.value.RuleOperator,
      "thruDate":  this.ThroughDate
    }
    this.productionService.postManufacturingRule(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this.closebutton.nativeElement.click();
        this.getManufacturingRule();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    }); }
  deleteManufacturingRule(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.productionService.deleteManufacturingRule(id).subscribe((res: any) => {  
          this.getManufacturingRule();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }})}
  getManufacturingRule() {
    this.spinner.show();
  
    this.productionService.getManufacturingRule(this.finSize).subscribe((res: any) => {
      this.ManufacturingRule = res.data;
     
      this.spinner.hide();
    })
  
  }
  createSimulation() {
    this._Router.navigate(["production/bom-simulation-list"]);
  }
  
  updateManufacturing(id) {
    this._Router.navigate(["production/create-manufacturing-rules"],{ queryParams: { ruleId: id } })
  }
  BomList() {
    this._Router.navigate(["/production/bill-of-material-list"])

  }
  DetailManufacturingRules(id) {
    this._Router.navigate(["/production/detail-manufacturing-rule"],{ queryParams: { ruleId: id } })

  }
  ManufacturingRules() {
    this._Router.navigate(["/production/manufacturing-rules"])

  }
  createManufacture() {
    this._Router.navigate(["production/manufacturing-rules"])
  }
  createBOM() {
    this._Router.navigate(["production/create-product-bom"])

  }
  createManufacturing() {
    this._Router.navigate(["production/create-manufacturing-rules"])

  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
 
  ngOnDestroy(): void {
    this.spinner.hide();
      }

}
