<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Job Posting </span>
                <button type="submit"  class="btn btn-danger buttonclass"  (click)="cancel();">Cancel</button>
                <!-- <span class="color-black pl-1"> > Job Requisition</span> -->
            </div>
            <div class="w3-card-4 classCard" >
            <div class=" bg-white color-grey">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        New Internal Job Posting </a></li>
                        
                     
                    </ul>
                  </div>
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form *ngIf="addFormService.add_job_form" [formGroup]="addFormService.add_job_form" class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Application Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" formControlName="applicationDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Application Date">
                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Approver Party <span style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" formControlName="approverPartyId" [options]="approveData"
                                                optionlabel="label" placeholder="Enter Approver Party">
                                                
                                            </p-dropdown> 
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                         
                                            <div class="col-lg-3 form-group classInput"  *ngIf="show">
                                                <label for="exampleInputEmail1">Application ID</label>
                                            </div>
                                            <div class="col-lg-2"  *ngIf="show">
                                                <input type="email" disabled formControlName="applicationId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled placeholder="Application ID">
                
                                            </div>
                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Job Requisition ID	
                                                    <span style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown formControlName="jobRequisitionId" [options]="getJobRequistionArray" filter="true"
                                                optionlabel="label" placeholder="Enter Job Requisition ID">
                                                
                                            </p-dropdown> 
                                        </div> 
                                        </div>
                                    </div>
                                 
                                 
                                   
                                 
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <!-- <label for="exampleInputEmail1">Application ID</label> -->
                                            <input type="hidden" disabled formControlName="statusId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled placeholder="Application ID">
                                        </div>
                                    </div>
                                </div>
                                <div class="main-submit-button" style="    margin-right: 28%;">
                                    <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Create</button>
                                    <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                   
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>
            </div>
          
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
