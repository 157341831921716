<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Content</span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)"><a>
                                List own created Templates</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)" ><a>
                                Find</a></li>
                            
                            <button type="submit" class="btn btn-secondary submit-btn ml-2 mt-2" style="margin: 0;" data-toggle="modal" data-target="#createTemplatePopup">Create</button>

                        </ul>
                    </div>
                    <div class="create-new-leade ">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        
                    
                                        <div *ngIf="activeCategory==1">
                                            <div class="create-new-leade">
                                                <div class="container-fluid">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="bg-white color-grey w-100 ">
                                                                <div class="">
                                                                    <div class="row">
                                                                        <form class="w-100">
                                                                            <div class="w3-card-4 classCardView mt-2 w-100">
                                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                                    List Layout



                                                
                                                                                    <!-- <button type="submit" class="btn btn-secondary submit-btn ml-2"  style="margin-left:72%!important;"
                                                                                    data-toggle="modal" data-target="#addEditContentTypePopup">




                                                                                    </button> -->
                                                                                   
                                                                                </div>
                                                                               
                                                                                
                                                
                                                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group">
                                                
                                                                                            <div class="card own-account-table">
                                                                                                <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                    <ng-template pTemplate="header">
                                                                                                        <tr style="background: #0d3769;">
                                                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </p-checkbox>
                                                
                                                                                                                    Content ID		

                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Content Name						
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Description						
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Template File			
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Action		
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            
                                                
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                        <tr>
                                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                                <div class="checkbox-align">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </p-checkbox>
                                                                                                                    <span  class="account-button"  
                                                                                                                 >
                                                                                                                  {{product.ruleName}}
                                                                                                                    </span>
                                                
                                                                                                                </div>
                                                                                                            </td>
                                                
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.isSale}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                <button type="submit" class="btn btn-secondary submit-btn ml-2">Update</button>
                                                                                                                <button type="submit" class="btn btn-danger  ml-2">Delete</button>
                
                                                
                                                                                                            </td>
                                                
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                
                                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span class="text-center">
                                                                                                            <h4
                                                                                                                class="ml-2">
                                                                                                                No Record
                                                                                                                Found</h4>
                                                                                                        </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>
                                                
                                                                                                <p class="paginate_data">
                                                                                                    View per page </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>  
                                                                            </div>
                                                                            
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                    
                                            </div>
                                            
                                            
                                                
                                               
                                            
                                        </div>


                                        <!-- <div *ngIf="activeCategory==2">

                                            <div class="create-new-leade mt-2">
                                                <div class="container-fluid">
                                                    <div class="row">
                                                        <form class="w-100">
                                                            <div class="w3-card-4 classCard">
                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                    <button type="button" class="btn btn-outline-secondary active" data-toggle="modal" 
                                                                    data-target="#createTemplatePopup">Create New Template</button>
                                                                </div><br>
                                                            <form class="w-100" [formGroup]="findLayoutForm">

                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                    
                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                <label for="exampleInputEmail1">
                                                                                    Content ID</label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2" >
                                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp" placeholder="Content ID"
                                                                                formControlName="contentID">

                                                                                
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Content Name			
                                                                                </label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2" >
                                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp" placeholder="Content Name"
                                                                                formControlName="contentName">
                                                                            
                                                                            </div>
                                                    
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                    
                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                <label for="accountLimit">Description</label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp" placeholder="Description"
                                                                                formControlName="description">
                                                                            
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Created By User	
                                                                                </label>
                                                                            </div>
                                                                            
                                                                            
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" placeholder="Created By User"
                                                                                optionlabel="label"  formControlName="createdByUser">
                                                                                </p-dropdown>

                                                                            </div>
                                                    
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                    
                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                <label for="exampleInputEmail1">Created Date	
                                                                                </label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2">
                                                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp" placeholder="Created Date"
                                                                                formControlName="createdDate">
                                                                            </div>

                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Last Modified By User	
    
                                                                                </label>
                                                                            </div>
                                                                            
                                                                            
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" placeholder="Last Modified By User"
                                                                                optionlabel="label"  formControlName="lastModifiedByUser">
                                                                                </p-dropdown>
                                                                            </div>
                                                                            
                                                    
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                    
                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                <label for="exampleInputEmail1">Last Modified Date		
                                                                                </label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2">
                                                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp" placeholder="Last Modified Date"
                                                                                formControlName="lastModifiedDate">

                                                                                
                                                                            </div>

                                                                            
                                                                            
                                                    
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    
                                                                    
                                                                </div>
                                                            </form>
                                                                <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -25%;">
                                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" >Find</button>&nbsp;
                                                                    <button type="button" class="btn btn-danger ml-2">Reset</button>
                                                                </div>
                                                            </div><br>
                    
                                                            <div class="w3-card-4 classCardB" style="    width: 100%;">
                                                                <header class="w3-container w3-blue">
                                                                    <div class="header-tabs">
                                                                    <h4 class=" common-styling h4M">
                                                                        Template
                                                                    </h4>
                                                                    
                                                                    </div>
                                                                </header>
                                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select" style="display: block!important;">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">
                                                                        
                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="" [paginator]="true" [rows]="rows"
                                                                                scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                [totalRecords]="total" [scrollable]="true"
                                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input
                                                                                                                type="checkbox" name="undefined"
                                                                                                                value="undefined"></div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    
                                                                                                </p-checkbox>
                                                                                                
                                                                                                Edit	 <p-sortIcon field="code"></p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="name" [ngStyle]="{'width':'170px'}"><div style="color: white;">Clip<p-sortIcon field="name">
                                                                                            </p-sortIcon></div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="category" [ngStyle]="{'width':'170px'}"><div style="color: white;"> Content Name<p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon></div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Description</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Template File

                                                                                        </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        
                                                                                        
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input
                                                                                                                type="checkbox" name="undefined"
                                                                                                                value="undefined"></div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    
                                                                                                </p-checkbox>
                                                                                                <span  class="account-button"  (click)="viewPayment(product.paymentGatewayResponseId);"
                                                                                                    >{{product.paymentGatewayResponseId}}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.paymentServiceTypeEnumId}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.orderPaymentPreferenceId}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.paymentMethodTypeId}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.paymentMethodId}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.transCodeEnum}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.amount}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.currencyUomId}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            
                                                                                            
                    
                                                                                        </td>
                
                                                                                        
                                                                                    </tr>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                                    <h4 class="ml-2">No Record Found</h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </ng-template>
                                                                            </p-table>
                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </form>
                    
                                                    </div>
                                                </div>
                                            </div>

                                            
                                            
                                            
                                                
                                                
                                            
                                        </div> -->
                                            
                                            
                                           
                                        
                    
                                        

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 
 
 
 
 

 <!-- <div class="modal fade" id="createTemplatePopup" tabindex="-1" role="dialog" aria-labelledby="createTemplatePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Edit Layout Sub Content
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Edit Layout Sub Content
                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createTemplateForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Content ID	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="contentID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Content ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Content Type</label>
                                                    </div>

                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="contentType" 
                                                        placeholder="Choose Content Type">

                                                        </p-dropdown>
                                                        
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Data Resource ID		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="dataResourceID" 
                                                        placeholder="Data Resource ID">

                                                        </p-dropdown>
                                                        
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Content Name		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="contentName" 
                                                        placeholder="Content Name">

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="description" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Description">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Content ID To	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="contentIDTo" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Content ID To">
                                               
                                                        
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Map Key		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="mapKey" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Map Key">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Dr Data Resource Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="drDataResourceTypeID" 
                                                        placeholder="Dr Data Resource Type ID">

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Dr Data Template Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="drDataTemplateTypeID" 
                                                        placeholder="Dr Data Template Type ID">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Dr Mime Type ID	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="drMimeTypeID" 
                                                        placeholder="Dr Mime Type ID">

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">File Path</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="filePath" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="File Path	">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Text</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="text" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Text">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Created By User</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="createdByUser" 
                                                        placeholder="Created By User">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Created Date		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="createdDate" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Created Date">
                                           
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Last Modified By User</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="lastModifiedByUser" 
                                                        placeholder="Last Modified By User">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Last Modified Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="lastModifiedDate" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Last Modified Date">
                                           
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                           
                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->



 <ngx-spinner></ngx-spinner> 

