import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-communication-dashboard',
  templateUrl: './communication-dashboard.component.html',
  styleUrls: ['./communication-dashboard.component.css']
})
export class CommunicationDashboardComponent implements OnInit {

  constructor(
    private _location: Location,
    readonly router: Router, 

  ) { }

  ngOnInit(): void {
  }
  homeButton(){
    this.router.navigate(['/commerce/communication-overview/Dashboard'])

  }
  cancelSubmit(){
    this._location.back();
  }

}
