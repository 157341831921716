<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
           
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Information System</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            
            <div class="create-new-leade ">
            <div class="container-fluid">
                <div class="row">
                    <form class="w-100">
                        <div class="port-header-contact create-lead font-medium mb-0 w-100">

                                <div class="w3-card-4 classCardView w-100">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                        style="justify-content: space-between;">
                                        <p class="h-text">Summary</p>
                                        <span>
                                            <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                style="margin: 0;" data-toggle="modal"
                                                data-target="#softwarePopup">Update</button>
                                            <!--<button type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#newpopup">New Product Promo</button> -->
                                        </span>
                                    </div>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        
                                        <div class="color-black container">
                                            <div class="row" *ngIf="InstalledSoftwareList">
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Fixed Asset ID	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareList.fixedAssetId}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Fixed Asset Parent ID	
                                                            </p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareList.parentFixedAssetId}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Instance Of Product ID	
                                                            </p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareList.instanceOfProductId}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Class Enum ID	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareList.classEnumId}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Fixed Asset Name		</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareList.fixedAssetName}}
                                                             </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Acquire Order ID	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareList.acquireOrderId}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Acquire Order Item Seq ID	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareList.acquireOrderItemSeqId}}  </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Date Acquired		</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareData.dateAcquired}}  </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Date Last Serviced	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareData.dateLastServiced}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Date Next Service	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareData.dateNextService}}  </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Expected End Of Life	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareData.expectedEndOfLife}}  </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Actual End Of Life		</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareData.actualEndOfLife}} </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Production Capacity		</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareList.productionCapacity}}  </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">UOM	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareList.uomId}} </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Calendar	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareList.calendarId}} </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Serial Number		</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareList.serialNumber}} </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Located At Facility ID	
                                                            </p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareList.locatedAtFacilityId}} </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Located At Location Seq ID	
                                                            </p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareList.locatedAtLocationSeqId}}   </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Salvage Value		</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareList.salvageValue}}    </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Depreciation		</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareList.depreciation}}   </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Purchase Cost		</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareList.purchaseCost}} </span>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Purchase Cost Uom ID		</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.InstalledSoftwareList.purchaseCostUomId}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </form>
                </div>
            </div>
            </div>
            
        </div>
    </div>
</div>
<div class="modal fade" id="softwarePopup" tabindex="-1" role="dialog" aria-labelledby="softwarePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create New Software</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create New Software</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateSoftwareForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Fixed Asset ID	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                       {{this.fixedAssetId}}
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Fixed Asset Parent ID	
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="fixedAssetParentID" [options]="parentFixedAssetIdArray"
                                                        placeholder="Select Fixed Asset Parent ID">

                                                        </p-dropdown>
                                                        
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Instance Of Product ID	
		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="instanceOfProductID"  [options]="ProductIdArray"
                                                        placeholder="Select Instance Of Product ID">

                                                        </p-dropdown>
                                                        
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Class Enum ID			
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="classEnumID"  [options]="classEnumIdArray"
                                                        placeholder="Select Class Enum ID">

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Fixed Asset Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="fixedAssetName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Fixed Asset Name">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Acquire Order ID			
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="acquireOrderID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Acquire Order ID">
                                               
                                                       
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Acquire Order Item Seq ID		
	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="acquireOrderItemSeqID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Acquire Order Item Seq ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Date Acquired</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="dateAcquired" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" >
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Date Last Serviced	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="dateLastServiced" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" >
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Date Next Service</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="dateNextService" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" >
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Expected End Of Life</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="expectedEndOfLife" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" >
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Actual End Of Life	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="actualEndOfLife" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" >
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Production Capacity	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="productionCapacity" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Production Capacity">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">UOM		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="UOMId"   [options]="umoIdArray"
                                                        placeholder="Select UOM">
                                                        </p-dropdown>
                                                        
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Calendar</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="calendar"  [options]="calendarArray"
                                                        placeholder="Select Calendar">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Serial Number</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="serialNumber"
                                                         class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Serial Number">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Located At Facility ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="locatedAtFacilityID" 
                                                        [options]="FacilityIDArray"
                                                        placeholder="Select Located At Facility ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Located At Location Seq ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="locatedAtLocationSeqID"    [options]="LocationSeqIdArray"
                                                        placeholder="Select Located At Location Seq ID">
                                                        </p-dropdown>
                                                    </div>

                                                   

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Salvage Value</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="salvageValue" 
                                                        class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Salvage Value">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Depreciation				
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="depreciation" 
                                                        class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Depreciation">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Purchase Cost	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="purchaseCost" 
                                                        class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Purchase Cost">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Purchase Cost Uom ID					
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="purchaseCostUomID" 
                                                        class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Purchase Cost Uom ID">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                 (click)="updateComputerSoftware()" >Update</button>
                                                 
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


 <ngx-spinner></ngx-spinner> 

