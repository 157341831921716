import { Component, OnInit } from '@angular/core';
import {ActivatedRoute,Router} from "@angular/router";
import { FormGroup, FormBuilder } from '@angular/forms';
import {MyContactsService} from "../../crm/contacts/my-contacts/my-contacts.service";
import { ToastrService } from 'ngx-toastr';

import { NgxSpinnerService } from 'ngx-spinner';



@Component({
  selector: 'app-account-notes',
  templateUrl: './account-notes.component.html',
  styleUrls: ['./account-notes.component.css']
})
export class AccountNotesComponent implements OnInit {
 
  fileUploadForm: FormGroup;
  partyId: any;
  activeCategory=1;
  partyIdForCreate:any;
  noteId:any;
  showUpdateBtn=false;
  showCreateBtn=false;
  noteName='';
  note='';

  constructor(readonly activatedRoute:ActivatedRoute,
    readonly spinner: NgxSpinnerService,readonly router:Router,
    readonly toastr:ToastrService,readonly formBuilder:FormBuilder,
     readonly myContactsService:MyContactsService) { }

  ngOnInit(): void {
    this.fileUploadForm = this.formBuilder.group({
      noteName: [''],
      note:['']
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.partyIdForCreate = params["partyId"];
      const partyId=this.partyIdForCreate
      if(partyId){
        this.showCreateBtn=true;   
      }
    });
  

    this.activatedRoute.queryParams.subscribe(params => {
      this.noteId = params["noteId"]; 
      this.partyId = params["leadId"]; 
      this.noteName = params["noteName"];
      this.note= params["note"];
      if(this.noteId && this.partyId){
        this.showUpdateBtn=true;
      }
    });
  }

  navigate() {
    this.router.navigate(["/crm/accounts/account-details"], { queryParams: { partyId: this.partyId  } });
  }

  navigateFromCreate(){
    this.router.navigate(["/crm/accounts/account-details"], { queryParams: { partyId: this.partyIdForCreate  } });
  }
  onSubmit(){
    this.spinner.show();
    const form = {...this.fileUploadForm.value}
    this.myContactsService.createContactNote(form,this.partyIdForCreate).subscribe(res=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(['/crm/accounts/account-details'],{queryParams:{partyId:this.partyIdForCreate}})

      }   
     },(err) => {
      this.spinner.hide();
       for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
          }     }
      )
    
  }

  onUpdate() {
    this.spinner.show();
    const form = { ...this.fileUploadForm.value}
    this.myContactsService.updateContactNote(form,this.noteId).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(['/crm/accounts/account-details'], { queryParams: { partyId: this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }
  changeactiveCategoryFunction(tab: number){
    this.activeCategory =tab;
  }
}
