import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';

@Component({
  selector: 'app-new-vendor-create',
  templateUrl: './new-vendor-create.component.html',
  styleUrls: ['./new-vendor-create.component.css']
})
export class NewVendorCreateComponent implements OnInit {
  show: boolean;
  listVendorsIntial: any;
  listVendorsIntialArray: any[] = [];
  createVendor: FormGroup;
  partyId: any;
  activeCategory = 2;
  dataParty: any;
  firstName: any;
  lastName: any;
  resdataVendor: any;
  addressVendor: string;
  emailAddress: any;
  phoneNumber: any;
  faxNumber: any;

  constructor(readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,

  ) {
    this.createVendor = this._FormBuilder.group({
      manifestCompanyName: [''],
      manifestCompanyTitle: [''],
      manifestLogoUrl: [''],
      manifestPolicies: [''],
      accountNo:[''],
      billingRateLevel:[''],
      creditLimit:[''],
      eligibleFor:[''],
      printCheckAs:[''],
      taxId:[''],
      terms:[''],
      type:[''],
      partyId: ['',[Validators.required]],



    });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
     this._ActivatedRoute.queryParams.subscribe(params=> {
      this.partyId = params["partyId"];
     
     
    });
    if( this.partyId){
      this.show=true;
     this.editVendorsData();
    
    }
    else{
      this.show=false;
    }
    this.getVendors();
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  onSubmit() {
  this.spinner.show();
    const requestData = {
      "manifestCompanyName":  this.createVendor.value.manifestCompanyName,
      "manifestCompanyTitle": this.createVendor.value.manifestCompanyTitle,
      "manifestLogoUrl":  this.createVendor.value.manifestLogoUrl,
      "manifestPolicies": this.createVendor.value.manifestPolicies,
      "partyId": this.createVendor.value.partyId,
      "accountNo":this.createVendor.value.accountNo,
      "billingRateLevel":this.createVendor.value.billingRateLevel,
      "creditLimit":this.createVendor.value.creditLimit,
      "eligibleFor":this.createVendor.value.eligibleFor,
      "printCheckAs":this.createVendor.value.printCheckAs,
      "taxId":this.createVendor.value.taxId,
      "terms":this.createVendor.value.terms,
      "type":this.createVendor.value.type,
    }
    this.accountsService.postVendors(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Created Successfully");
        this.router.navigate(["/financial/payable/list-vendor"])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "manifestCompanyName":  this.createVendor.value.manifestCompanyName,
      "manifestCompanyTitle": this.createVendor.value.manifestCompanyTitle,
      "manifestLogoUrl":  this.createVendor.value.manifestLogoUrl,
      "manifestPolicies": this.createVendor.value.manifestPolicies,
      "partyId": this.createVendor.value.partyId,
      "accountNo":this.createVendor.value.accountNo,
      "billingRateLevel":this.createVendor.value.billingRateLevel,
      "creditLimit":this.createVendor.value.creditLimit,
      "eligibleFor":this.createVendor.value.eligibleFor,
      "printCheckAs":this.createVendor.value.printCheckAs,
      "taxId":this.createVendor.value.taxId,
      "terms":this.createVendor.value.terms,
      "type":this.createVendor.value.type,
    }
    this.accountsService.updateVendors(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.router.navigate(["/financial/payable/list-vendor"])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
 
  editVendorsData() {
    this.spinner.show();
    this.accountsService.getVendorListById( this.partyId).subscribe((res: any) => {
      this.getVendorPartyIdListById(res.data.partyId); 
      setTimeout(() => {
        const formValue = this.createVendor;
        formValue.patchValue({
          manifestCompanyName: res.data.manifestCompanyName,
          manifestCompanyTitle: res.data.manifestCompanyTitle,
          manifestLogoUrl: res.data.manifestLogoUrl,
          manifestPolicies: res.data.manifestPolicies,
          partyId:res.data.partyId,
          accountNo:res.data.additionInformation==null?'':res.data.additionInformation.accountNo,
          billingRateLevel:res.data.additionInformation==null?'':res.data.additionInformation.billingRateLevel,
          creditLimit:res.data.additionInformation==null?'':res.data.additionInformation.creditLimit,
          eligibleFor:res.data.additionInformation==null?'':res.data.additionInformation.eligibleFor,
          printCheckAs:res.data.additionInformation==null?'':res.data.additionInformation.printCheckAs,
          taxId:res.data.additionInformation==null?'':res.data.additionInformation.taxId,
          terms:res.data.additionInformation==null?'':res.data.additionInformation.terms,
          type:res.data.additionInformation==null?'':res.data.additionInformation.type,
        })
      
      }, 3000);
      this.spinner.hide();
    })
  }
  getVendors() {
    this.spinner.show();
    this.accountsService.getVendorsInitialdata().subscribe((res: any) => {
      this.listVendorsIntial = res.data.partyPersons;
      this.spinner.hide();
      for (const value of this.listVendorsIntial) {
        this.listVendorsIntialArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    

    })
  }

  cancelSubmit() {
    this.router.navigate(["/financial/payable/list-vendor"])

  }
  getVendorPartyIdListById(id) {
    this.spinner.show();
    this.addressVendor="";
    this.lastName="";
    this.firstName="";
    this.emailAddress="";
    this.phoneNumber="";
    this.faxNumber="";
    this.accountsService.getVendorPartyIdListById(id).subscribe((res: any) => {
      this.resdataVendor=res.data.contactInformation;
      this.firstName=res.data.firstName;
      this.spinner.hide();
      this.lastName=res.data.lastName;
      for(const v of this.resdataVendor){
        if(v.contactMech.contactMechTypeId==="POSTAL_ADDRESS"){
          this.addressVendor=v.postalAddress.address1+','+v.postalAddress.stateProvinceGeoId+','+v.postalAddress.city+','+v.postalAddress.postalCode+','+v.postalAddress.countryDesc
        }
        else if(v.contactMech.contactMechTypeId==="EMAIL_ADDRESS"){
          this.emailAddress=v.contactMech.infoString
        }
        else if(v.partyContactMechPurposes.length){
         if(v.partyContactMechPurposes[0].contactMechPurposeTypeId==="PRIMARY_PHONE"){
          this.phoneNumber=v.telecomNumber.contactNumber
        }
        else if(v.partyContactMechPurposes[0].contactMechPurposeTypeId==="FAX_NUMBER"){
          this.faxNumber=v.telecomNumber.contactNumber
        }}
      }
    })  
  }
  
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
