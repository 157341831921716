<div class="container-fluid main-container-wrapper" style="padding: 0;">


    <div class=" bg-white color-grey">
        <div class="port-header-contact create-lead font-medium mb-0">
           <span class="color-black pl-1 titlePanels">Inventory Item Labels
            </span>
            <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
        </div>
        <div class="w3-card-4 classCard" style="border-left-width: 0;">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left: 9px; width: calc(103% - 5px); flex-wrap: wrap; display: flex; margin-bottom: 8px !important;">
                    <li [ngClass]="activeCat==0?'active':''" (click)="dashboardOpen()">
                        <a>Warehouse Dashboard</a>
                    </li>
                    <li [ngClass]="activeCat==1?'active':''" (click)="mainOpen()">
                        <a>Facility</a>
                    </li>
                    <li [ngClass]="activeCat==2?'active':''" (click)="facilityGroupOpen()">
                        <a>Facility Group</a>
                    </li>
                    <li [ngClass]="activeCat==3?'active':''" (click)="inventoryItemOpen()">
                        <a>Inventory Item</a>
                    </li>
                    <li [ngClass]="activeCat==4?'active':''" (click)="ShippingOpen()">
                        <a>Shipping</a>
                    </li>
                </ul>
            </div>
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <div class="w3-card-4 classCard classCardType">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                            <li [ngClass]="activeCategoryMain==1?'active':''" (click)="InventoryItemLabels()"><a>
                                                                    Inventory Item Labels</a></li>
                            <li [ngClass]="activeCategoryMain==2?'active':''" (click)="Label()"><a>
                                                                    Label Types</a></li>



                        </ul>
                    </div>

                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row" style="    padding: 9px;">

                                <div #target></div>

                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Inventory Item Label List
                                            </h4>
                                            <span class="edit-buttons hCreate">
                                     
                                                    <a  data-toggle="modal"
                                                    data-target="#exampleModalCenter">Create New Inventory Item Label</a>
                                                  </span>
                                        </div>
                                    </header>
                                    <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">

                                                <div class="card own-account-table">
                                                    <p-table [value]="inventoryItemLabelList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'270'}" pSortableColumn="inventoryItemLabelId">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        Inventory Item Label ID
                                                                        <p-sortIcon field="inventoryItemLabelId"></p-sortIcon>
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'270'}" pSortableColumn="inventoryItemLabelTypeId">
                                                                    <div style="color: white;"> Inventory Item Label Type ID
                                                                        <p-sortIcon field="inventoryItemLabelTypeId"></p-sortIcon>
                                                                    </div>

                                                                </th>
                                                                <th pSortableColumn="description">
                                                                    <div>
                                                                        <div style="color: white;"> Description
                                                                            <p-sortIcon field="description">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'270'}" pSortableColumn="createdStamp">
                                                                    <div>
                                                                        <div style="color: white;"> Created Date
                                                                            <p-sortIcon field="createdStamp">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                                <th width="10%" pSortableColumn="price">
                                                                    <div style="color: white;"> Action</div>
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'270'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        <span  class="account-button"   (click)="detailPage(product.inventoryItemLabelId,
                                                                            product.inventoryItemLabelTypeId,product.description,product.createdStamp)">
                                                                                {{product.inventoryItemLabelId}}</span>

                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'270'}">
                                                                    {{product.inventoryItemLabelTypeId}}</td>
                                                                <td>{{product.description}}</td>
                                                                <td [ngStyle]="{'width':'270'}">
                                                                    {{product.createdStamp | date:'short'}}</td>
                                                                <td width="10%">
                                                                    <div>

                                                                        <svg (click)="delete(product)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                <defs>
                                                                                    <style>
                                                                                        .a {
                                                                                            fill: #f44336;
                                                                                        }
        
                                                                                        .b {
                                                                                            fill: #fafafa;
                                                                                            margin-left: 20px;
                                                                                        }
                                                                                    </style>
                                                                                </defs>
                                                                                <path class="a fill-color"
                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                <path class="b"
                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                    transform="translate(-147.576 -147.576)" />
                                                                            </svg>
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        </ng-template>
                                                    </p-table>

                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </div>

</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Create Inventory Item Labels</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <form class="w-100" [formGroup]="inventoryLabel">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!editMode" [ngClass]="activeCategory==3?'active':''"><a>
                            Create Inventory Item Labels</a></li>
                                <li *ngIf="editMode" [ngClass]="activeCategory==3?'active':''"><a>
                                Update Inventory Item Labels</a></li>

                            </ul>
                        </div>
                        <div class="all-users-infomation font-13 font-medium own-user-select">
                            <div class="col-lg-12">
                                <div class="row">

                                    <div class="col-lg-3 form-group classInput">
                                        <label for="exampleInputEmail1">Inventory Item Label ID</label>
                                    </div>
                                    <div class="col-lg-3">
                                        <input type="text" *ngIf="!editMode" class="form-control" formControlName="inventoryItemLabelId" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Inventory Item Label ID">
                                        <input type="email" *ngIf="editMode" class="form-control" formControlName="inventoryItemLabelId" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Inventory Item Label ID" readonly>
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">Inventory Item Label Type ID</label>
                                    </div>
                                    <div class="col-lg-3">
                                        <p-dropdown [options]="inventoryItemLabelTypeIdArray" placeholder="Inventory Item Label Type ID" filter="true" formControlName="inventoryItemLabelTypeId"></p-dropdown>


                                    </div>
                                </div>
                            </div>


                            <div class="col-lg-12">
                                <div class="row">

                                    <div class="col-lg-3 form-group classInput">
                                        <label for="exampleInputEmail1">Description</label>
                                    </div>
                                    <div class="col-lg-3">
                                        <input type="email" class="form-control" formControlName="description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Description">
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -7%;">
                            <button _ngcontent-cta-c128="" (click)="onSubmit()" type="submit" class="btn btn-secondary submit-btn update-button">{{editMode? 'Update':'Submit'}}</button>

                        </div>
                    </div><br></form><br>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>