<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
           
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Leads</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">

                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="w3-card-4 classCard" style="width: 100%;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="white-space: nowrap;">
                                    <p class="h-text">Find Leads</p>
                                    <button class="btn btn-outline-secondary" routerLink="/crm/leads" routerLinkActive="active">All Leads</button>
                                    <button routerLink="/crm/leads/my-lead" type="button" class="btn btn-outline-secondary" routerLinkActive="active"> My
                                        Leads</button>

                                    <button type="button" class="btn btn-outline-secondary" class="btn btn-secondary submit-btn" data-toggle="modal" data-target="#exampleModalCenter">Create New Lead</button>
                                </div>
                                <br>
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightFormBA">
                                                <label for="exampleInputEmail1"> Lead ID</label>
                                            </div>
                                            <div class="col-lg-2" style="display: none;">
                                                <p-dropdown styleClass="small-dropdown" filter="true" (ngModelChange)="onPartyId(partyIdSearchType)" [(ngModel)]="partyIdSearchType" [ngModelOptions]="{standlone:true}" [options]="opportunityIdArray" optionlabel="label">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-2">
                                                <input class="form-control" type="email" [(ngModel)]="partyId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter  Lead ID">
                                            </div>

                                            <div class="col-lg-3 form-group rightFormAB">
                                                <label for="exampleInputEmail1">First Name</label>
                                            </div>
                                            <div class="col-lg-2" style="display: none;">
                                                <p-dropdown styleClass="small-dropdown" filter="true" (ngModelChange)="onFirstName(firstNameSearchType)" [(ngModel)]="firstNameSearchType" [ngModelOptions]="{standlone:true}" [options]="opportunityIdArray" optionlabel="label">

                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-2">
                                                <input class="form-control" type="email" [(ngModel)]="firstName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group rightFormBA">
                                                <label for="exampleInputEmail1">Last Name</label>
                                            </div>
                                            <div class="col-lg-2" style="display: none;">
                                                <p-dropdown styleClass="small-dropdown" (ngModelChange)="onLastName(lastNameSearchType)" [(ngModel)]="lastNameSearchType" [ngModelOptions]="{standlone:true}" filter="true" [options]="opportunityIdArray" optionlabel="label">

                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-2">
                                                <input class="form-control" type="email" [(ngModel)]="lastName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name">
                                            </div>

                                        </div>
                                    </div>



                                </div>

                                <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -34%;"><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="getLeadList()">Find</button>
                                    <button type="submit" class="btn btn-danger ml-2" (click)="resetFilter()">Reset</button>
                                </div>
                            </div>
                            <br>
                            <div class="w3-card-4 classCard" style="margin-top: 2%;">
                                <header class="w3-container w3-blue">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling h4Margin">
                                            Lead List
                                        </h4>

                                    </div>
                                </header>
                                <div class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group">


                                            <div class="card own-account-table">
                                                <p-table [value]="leadsLists" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr style="background: #0d3769;">
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                <div class="checkbox-align" style="color: white;">
                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                            </div>
                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                    </p-checkbox>
                                                                    Lead ID
                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                </div>

                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">Name
                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                <div style="color: white;">Customer Name
                                                                    <p-sortIcon field="quantity"></p-sortIcon>
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                <div style="color: white;">Email Address
                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                <div style="color: white;">Phone Number
                                                                    <p-sortIcon field="category"></p-sortIcon>
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">City
                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">Country
                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">Created At
                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                </div>
                                                            </th>

                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-product>
                                                        <tr>
                                                            <td [ngStyle]="{'width':'170px'}" (click)="leadDetail(product.partyId)">
                                                                <div class="checkbox-align">
                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                            </div>
                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                    </p-checkbox>
                                                                   <span class="account-button"> {{product.partyId}} </span>
                                                                </div>
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">{{product.firstName}} {{product.lastName}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.GroupInformation[0] == null ? '' : product.GroupInformation[0].company !==null ? product.GroupInformation[0].company:''}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">{{product.email}}</td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.telenumber?.countryCode}} {{product.telenumber?.areaCode}} {{product.telenumber?.contactNumber}}
                                                            </td>

                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.address !==null?product.address.city:''}}</td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.address !==null?product.address.countryGeoId:''}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.createdStamp | date:'yyyy-MM-dd HH:mm:ss'}}
                                                            </td>

                                                            <td *ngIf="product.isMyLead==true" [ngStyle]="{'width':'170px'}">
                                                                <button class="bnt btn-secondary submit-btn" (click)="assignLead(product.GroupInformation[0].partyIdFrom,product.partyId)">Assign to Me</button>
                                                            </td>

                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                                <p class="paginate_data">
                                                    View per page </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>





                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span *ngIf="!this.show" class="color-black pl-1"> Create-Lead</span>
                <span *ngIf="this.show" class="color-black pl-1"> Update-Lead</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)">
                                    <a>
                                        General Lead Information</a>
                                </li>
                                <li [ngClass]="activeCategory==3?'active':''" (click)="addressInfo(leadService.lead_form.value.firstName,leadService.lead_form.value.lastName,
                      leadService.lead_form.value.groupName,leadService.lead_form.value.numberOfEmployees)"><a>
                                        Address</a></li>

                                <li [ngClass]="activeCategory==4?'active':''" (click)="phoneInfo(leadService.lead_form.value.address1,leadService.lead_form.value.city,
                      leadService.lead_form.value.country,leadService.lead_form.value.state,
                      leadService.lead_form.value.postalCode)"><a>
                                        Phone information </a></li>
                                <li [ngClass]="activeCategory==5?'active':''" (click)="emailInfo(leadService.lead_form.value.phoneNumber)"><a>
                                        E-Mail Address Information </a></li>
                                <li [ngClass]="activeCategory==6?'active':''" (click)="leadInfo(leadService.lead_form.value.emailId)"><a>
                                        Lead Source </a></li>
                                <li [ngClass]="activeCategory==8?'active':''" (click)="contactInfo(leadService.lead_form.value.leadSource)"><a>
                                        Contact List </a></li>



                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="leadService.lead_form" [formGroup]="leadService.lead_form" class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                            <div class="w-100">
                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                    <div *ngIf="activeCategory==2">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                General Lead Information
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">First
                                                                                Name<span
                                                                                    style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" [(ngModel)]="firstName" formControlName="firstName" [ngModelOptions]="{standalone: true}" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="First name">


                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Last
                                                                                Name<span
                                                                                    style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" [(ngModel)]="lastName" formControlName="lastName" [ngModelOptions]="{standalone: true}" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Last name">


                                                                        </div>



                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Suffix</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" [(ngModel)]="suffix" formControlName="suffix" [ngModelOptions]="{standalone: true}" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="suffix name">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Customer
                                                                                Name<span
                                                                                    style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" [(ngModel)]="groupName" formControlName="groupName" [ngModelOptions]="{standalone: true}" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Customer Name">

                                                                        </div>




                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Title</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" [(ngModel)]="title" formControlName="title" [ngModelOptions]="{standalone: true}" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Title">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label style="font-size: 10px;" for="exampleInputEmail1">Number of
                                                                                Employee<span
                                                                                    style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" [(ngModel)]="numberOfEmployees" formControlName="numberOfEmployees" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="No of employess">
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Office Site
                                                                                Name</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" [(ngModel)]="officeSiteName" formControlName="officeSiteName" [ngModelOptions]="{standalone: true}" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter office site name">
                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Comments</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" formControlName="comments" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12" *ngIf="show">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Gender</label>
                                                                        </div>
                                                                        <div class="col-lg-3 dropdowns">
                                                                            <p-dropdown styleClass="small-dropdown" filter="true" formControlName="gender" appendTo="body" optionlabel="label" placeholder="Select Gender">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">External
                                                                                Id</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" formControlName="externalId" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter External Id">
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12" *ngIf="show">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Status
                                                                                Id</label>
                                                                        </div>
                                                                        <div class="col-lg-3 dropdowns">
                                                                            <p-dropdown styleClass="small-dropdown" formControlName="statusId" [options]="crmArray" placeholder="Enter Status Id" optionlabel="label"></p-dropdown>
                                                                        </div>


                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div class="main-submit-button" style="    margin-right: 15%;">
                                                                <button type="submit" (click)="generalInformation(leadService.lead_form.value.firstName,leadService.lead_form.value.lastName,
                                                leadService.lead_form.value.groupName,leadService.lead_form.value.numberOfEmployees)" class="btn btn-secondary submit-btn">Continue</button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div *ngIf="activeCategory==3">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                Address
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Address 1
                                                                                <span style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" [(ngModel)]="address1" formControlName="address1" [ngModelOptions]="{standalone: true}" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Address 1">


                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Address
                                                                                2</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" [(ngModel)]="address2" formControlName="address2" [ngModelOptions]="{standalone: true}" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Address 2">
                                                                        </div>



                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">City <span
                                                                                    style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" [(ngModel)]="city" formControlName="city" [ngModelOptions]="{standalone: true}" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter City">


                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Country
                                                                                <span style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-3 dropdowns">
                                                                            <p-dropdown styleClass="small-dropdown" [options]="array" [(ngModel)]="countryGeoId" (ngModelChange)="onCountryChange($event)" formControlName="country" [ngModelOptions]="{standalone: true}" placeholder="Select Country" filter="true" optionlabel="label">

                                                                            </p-dropdown>


                                                                        </div>


                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">State <span
                                                                                    style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-3 dropdowns">
                                                                            <p-dropdown styleClass="small-dropdown" [(ngModel)]="state" [options] formControlName="state" placeholder="Select State" [ngModelOptions]="{standalone: true}" filter="true" [options]="stateArray" optionlabel="label">

                                                                            </p-dropdown>


                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Zip/Postal
                                                                                Code
                                                                                <span style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" [(ngModel)]="postalCode" formControlName="postalCode" [ngModelOptions]="{standalone: true}" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Zip Postal Code">


                                                                        </div>



                                                                    </div>
                                                                </div>




                                                            </div>
                                                            <div class="main-submit-button" style="margin-right:15%;">
                                                                <button type="submit" (click)="addressInformation(leadService.lead_form.value.address1,leadService.lead_form.value.city,
                                                leadService.lead_form.value.country,leadService.lead_form.value.state,
                                                leadService.lead_form.value.postalCode)" class="btn btn-secondary submit-btn">Continue</button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div *ngIf="activeCategory==4">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                Phone Information
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Country
                                                                                Code</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" [(ngModel)]="countryCode" formControlName="countryCode" [ngModelOptions]="{standalone: true}" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Area
                                                                                Code</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" [(ngModel)]="areaCode" formControlName="areaCode" [ngModelOptions]="{standalone: true}" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                                        </div>



                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Phone
                                                                                Number<span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" [(ngModel)]="phoneNumber" formControlName="phoneNumber" [ngModelOptions]="{standalone: true}" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" >


                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Extension</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" [(ngModel)]="ext" formControlName="ext" [ngModelOptions]="{standalone: true}" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                                        </div>


                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div class="main-submit-button" style="    margin-right:15%;">
                                                                <button type="submit" (click)="phoneInformation(leadService.lead_form.value.phoneNumber)" class="btn btn-secondary submit-btn">Continue</button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div *ngIf="activeCategory==5">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                E-Mail Address Information
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormD">
                                                                            <label for="exampleInputEmail1">Email <span
                                                                                    style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" [(ngModel)]="emailId" formControlName="emailId" [ngModelOptions]="{standalone: true}" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Email Address">


                                                                        </div>



                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div class="main-submit-button" style="    margin-right: 65%;">
                                                                <button type="submit" (click)="emailInformation(leadService.lead_form.value.emailId)" class="btn btn-secondary submit-btn">Continue</button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div *ngIf="activeCategory==6">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                Lead Source
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormD">
                                                                            <label for="exampleInputEmail1">Lead
                                                                                Source</label>
                                                                        </div>
                                                                        <div class="col-lg-3 dropdowns">
                                                                            <p-dropdown styleClass="small-dropdown" filter="true" [options]="leadArray" [(ngModel)]="leadSource" formControlName="leadSource" placeholder="Select Lead Source" [ngModelOptions]="{standalone: true}" optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>



                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="main-submit-button" style=" margin-right: 64%;">
                                                                <button type="submit" (click)="leadInformation(leadService.lead_form.value.leadSource)" class="btn btn-secondary submit-btn">Continue</button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div *ngIf="activeCategory==8">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                Contact List
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormD">
                                                                            <label for="exampleInputEmail1">Contact
                                                                                List</label>
                                                                        </div>
                                                                        <div class="col-lg-3 dropdowns">
                                                                            <p-dropdown styleClass="small-dropdown" [(ngModel)]="contactList" formControlName="contactList" [ngModelOptions]="{standalone: true}" filter="true" [options]="contactListArray" placeholder="Select Contact List" optionlabel="label">

                                                                            </p-dropdown>
                                                                        </div>



                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div class="main-submit-button" style=" margin-right: 64%;">
                                                                <button type="submit" *ngIf="show" (click)="updateLead()" class="btn btn-secondary submit-btn">Update</button>

                                                                <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>


                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>


















                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<ngx-spinner></ngx-spinner>