import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/app.constant';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class Responsibilities{

  constructor(readonly http:HttpClient) { }
  getResponsibilities(size):Observable<any>{
    const getResponsibilities = URLS.ValidResponsibility + "?pageSize=" +size.pageSize + "&pageNo=" + size.pageNo;
    return this.http.get(getResponsibilities);
  }
  deleteValidResponsibility(responsibilityTypeId,emplPositionTypeId,fromDate){
    const deleteResponsibility = URLS.delValidResponsibility.replace(":responsibilityTypeId",responsibilityTypeId).replace(":emplPositionTypeId",emplPositionTypeId).replace(":fromDate",fromDate);
    return this.http.delete(deleteResponsibility);
  }
  createResponsibility(formData):Observable<any>{
    const createResponsibility = URLS.createResponsibility;
    return this.http.post(createResponsibility,formData);
  }
  updateResponsibility(formData):Observable<any>{
    const updateResponsibility = URLS.updateResponsibility;
    return this.http.put(updateResponsibility,formData);
  }


  
}
