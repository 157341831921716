import { Component, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { InternalJobService } from "./internal-job-posting.service";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { JobRequistionService } from '../job-requisition/job-requisition.service';
import { NewJobRequistionsService } from '../new-job-requisition/new-job-requisition.service';
import { CreateInternalService } from '../create-internal-job-posting/create-internal-job.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2'
import { AddFormService } from '../create-internal-job-posting/create-internal-job-service';

import { Location } from '@angular/common';

declare var $: any;
@Component({
  selector: 'app-internal-job-posting',
  templateUrl: './internal-job-posting.component.html',
  styleUrls: ['./internal-job-posting.component.css']
})
export class InternalJobPostingComponent implements OnInit {
  activeCategory=2;
  show: boolean;
  @ViewChild('closebutton') closebutton;
  products3: any;
  total=0;
  rowNumber=0;
  activeCategoryMain=2;
  pageNo = 1;
  pageSize= 100;
  rows = 50;
  search: any = "";
  public jobPost = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
    search: this.search

  }
  advanceSearch: {
    applicationDateFrom: string,
    applicationDateFromSearchType: string,
    applicationDateTo: string,
    applicationDateToSearchType: string,
    applicationId: string,
    applyingPartyId: string,

    approverPartyId: string,

    jobRequisitionId: string,
    statusId: string,


  };
  jobRequestion: any;
  rIdArray: any = [];
  internalJobPostList: any;
  approveParty: any;
  approveData: any = [];
  status: any = [];
  dateIdArray: { label: string; value: string; }[];
  appIdArray: any = [];
  approveStatus: any = [];
  updateestifromdate: string;
  getJobRequistionArray: any[]=[];
  updateId: any;
  constructor(
    readonly activatedRoute:ActivatedRoute,
    public addFormService:AddFormService,
    readonly accountsService: AccountsService,
    readonly internalJobService: InternalJobService,
      private _location: Location,
      readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly jobRequistionService: JobRequistionService,
    readonly newJobRequistionsService: NewJobRequistionsService,
    readonly createInternalService: CreateInternalService,
    readonly datePipe: DatePipe,
    readonly toastr: ToastrService) {
    this.advanceSearch = {
      applicationDateFrom: "",
      applicationDateFromSearchType: "Equals",
      applicationDateTo: "",
      applicationDateToSearchType: "Equals",
      applicationId: "",
      applyingPartyId: "",

      approverPartyId: "",

      jobRequisitionId: "",
      statusId: "",


    };
    this.dateIdArray = [
      {
        label: 'Less Than',
        value: 'LessThan'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },

      {
        label: 'Greater Than',
        value: 'GreaterThan'
      },
      {
        label: 'Greater Than Equal To',
        value: 'GreaterThanEqualTo'
      },
      {
        label: 'Less Than Equal To',
        value: 'LessThanEqualTo'
      },
      {
        label: 'Is Empty',
        value: 'IsEmpty'
      }

    ];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.spinner.show();
    this.getStatus();
    this.getJobRequistionListDropdown();
    this.getInternalJobDropdwon();
   
    this.getInternalJob();
    $('.right-arrow').click(function () {
      $(this).toggleClass('rotate-up');
    });
    this.getApproverParty();

    this.addFormService.add_job_form=null;
    this.addFormService.addJobForm(this.addFormService.add_job_form);
    this.getApproverPartyNew();
    this.getJobRequistionList();
    this.getJobRequistionId();
    

  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  onSubmit(){
    this.spinner.show();
    let fromdate=this.addFormService.add_job_form.get('applicationDate').value;
    this.updateestifromdate=this.datePipe.transform(fromdate,"yyyy-MM-dd hh:mm:ss");
    const formData={
  "applicationDate":this.updateestifromdate,
  "approverPartyId": this.addFormService.add_job_form.value.approverPartyId,
  "jobRequisitionId": this.addFormService.add_job_form.value.jobRequisitionId,
  "statusId": ""
    }
 
    this.createInternalService.createForm(formData).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.addFormService.add_job_form.reset();
        this.getInternalJob();
        this.closebutton.nativeElement.click();
        this.router.navigate(['/hr/job-requisition/internal-job-posting'])
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
  
  }
  getApproverPartyNew(){
    this.spinner.show();
    this.createInternalService.getApproverParty().subscribe(res=> {
      this.approveParty = res.data.PersonsGroups;
      this.spinner.hide();
      this.approveParty.forEach(data=> {
        this.approveData.push({
          label:data.partyId,
          value:data.partyId
        })
        
      })
    })
   
  }
  getJobRequistionList() {
    this.spinner.show();
    const jobRequistion = {
      examTypeEnumId: "",
      experienceMonthSearchType: "Contains",
      experienceMonthSecond: "",
      experienceMonthSecondSearchType: "Contains",
      experienceMonths: "",
      experienceYearSecond: "",
      experienceYearSecondSearchType: "Contains",
      experienceYears: "",
      experienceYearsSearchType: "Contains",
      jobLocation: "",
      jobLocationSearchType: "Contains",
      jobPostingTypeEnumId: "",
      jobRequisitionId: "",
      skillTypeId: ""
    }
    this.createInternalService.getJobRequistionList(jobRequistion).subscribe(res=> {
      this.jobRequestion=res.data;
      this.spinner.hide();
     for(const value of this.jobRequestion){
       this.getJobRequistionArray.push({
         label:value.jobRequisitionId,
         value:value.jobRequisitionId
       })
     }
    
    })
   
  }

  getJobRequistionId() {
    this.spinner.show();
    this.createInternalService.getDataById(this.updateId).subscribe(res=> {
      setTimeout(() => {
        const form = this.addFormService.add_job_form;
        form.patchValue({
          
          applicationDate  :this.datePipe.transform(res.data.applicationDate,"yyyy-MM-dd"),
          approverPartyId  :res.data.approverPartyId,
          jobRequisitionId :res.data.jobRequisitionId,
          applicationId:res.data.applicationId
  
        
  
        })
      
      }, 3000);
      this.spinner.hide();
     
    })
      
    
  }
  getApproverParty() {
    this.spinner.show();
    this.createInternalService.getApproverParty().subscribe(res => {
      this.approveParty = res.data.PersonsGroups;
      this.spinner.hide();
      this.approveParty.forEach(data => {
        this.approveData.push({
          label: data.partyId,
          value: data.partyId
        })

      })
    })
  
  }
  JobRequisition() {
    this.router.navigate(["/hr/job-requisition"])
  }
  InternalJobPostingApplication() {
    this.router.navigate(["/hr/job-requisition/internal-job-posting"])
  }
  jobInterview() {
    this.router.navigate(["/hr/job-requisition/job-interview"])
  }
  approval() {
    this.router.navigate(["/hr/job-requisition/approval"])
  }
  Relocation() {
    this.router.navigate(["/hr/job-requisition/reAllocations"])
  }
  getStatus() {
    this.spinner.show();
    this.internalJobService.getStatus().subscribe((res) => {
      this.approveStatus = res.data;
      this.spinner.hide();
      this.approveStatus.forEach(data => {
        this.status.push({
          label: data.description,
          value: data.statusId
        })

      })
    })
   
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  jobPostingDetail(id) {

  }
  getJobRequistionListDropdown() {
    this.spinner.show();
    const jobRequistion = {
      examTypeEnumId: "",
      experienceMonthSearchType: "Equals",
      experienceMonthSecond: "",
      experienceMonthSecondSearchType: "Equals",
      experienceMonths: "",
      experienceYearSecond: "",
      experienceYearSecondSearchType: "Equals",
      experienceYears: "",
      experienceYearsSearchType: "Equals",
      jobLocation: "",
      jobLocationSearchType: "Contains",
      jobPostingTypeEnumId: "",
      jobRequisitionId: "",
      skillTypeId: ""
    }
    this.jobRequistionService.getJobRequistionList(jobRequistion).subscribe(res => {
      this.jobRequestion = res.data;
      this.spinner.hide();
      for (const value of this.jobRequestion) {
        this.rIdArray.push({
          label: value.jobRequisitionId,
          value: value.jobRequisitionId
        })
      }
    });
   
  }
  getInternalJob() {
    this.spinner.show();
    const jobList = {
      applicationDateFrom: "",
      applicationDateTo: "",
      applicationId: "",
      applyingPartyId: "",
      approverPartyId: "",
      jobRequisitionId: "",
      statusId: "",
      applicationDateFromSearchType: "Equals",
      applicationDateToSearchType: "Equals"
    }
    this.internalJobService.getInternalJobList(this.jobPost, jobList).subscribe((res: any) => {
      this.internalJobPostList = res.data;
      this.spinner.hide();
    })
   
  }

  getInternalJobDropdwon() {
    this.spinner.show();
    const jobList = {
      applicationDateFrom: "",
      applicationDateTo: "",
      applicationId: "",
      applyingPartyId: "",
      approverPartyId: "",
      jobRequisitionId: "",
      statusId: "",
      applicationDateFromSearchType: "Equals",
      applicationDateToSearchType: "Equals"
    }
    this.internalJobService.getInternalJobList(this.jobPost, jobList).subscribe((res: any) => {
      this.internalJobPostList = res.data;
      this.spinner.hide();
      for (const value of this.internalJobPostList) {
        this.appIdArray.push({
          label: value.applicationId,
          value: value.applicationId
        })
      }
    })
   
  }
  onUpdate(id) {
    this.router.navigate(["./hr/job-requisition/create-internal-posting"], { queryParams: { updateId: id } })
  }
  detailPage(id) {
    this.router.navigate(["./hr/job-requisition/detail-internal-job-summary"], { queryParams: { updateId: id } })
  }
  onDelete(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.internalJobService.deleteInternalJob(id).subscribe((res: any) => {
          this.getInternalJob();
     
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })}
  advanceSearchContacts(isPopUp: boolean): void {
    this.internalJobPostList = [];
    this.spinner.show();
    
    const req = {
      "applicationDateFrom": this.datePipe.transform(this.advanceSearch.applicationDateFrom, "yyyy-MM-dd HH:mm:ss") ? this.datePipe.transform(this.advanceSearch.applicationDateFrom, "yyyy-MM-dd HH:mm:ss") : '',
      "applicationDateTo": this.datePipe.transform(this.advanceSearch.applicationDateTo, "yyyy-MM-dd HH:mm:ss") ? this.datePipe.transform(this.advanceSearch.applicationDateTo, "yyyy-MM-dd HH:mm:ss") : '',
      "applicationDateFromSearchType": this.advanceSearch.applicationDateFromSearchType ? this.advanceSearch.applicationDateFromSearchType : '',
      "applicationDateToSearchType": this.advanceSearch.applicationDateToSearchType ? this.advanceSearch.applicationDateToSearchType : '',
      "applicationId": this.advanceSearch.applicationId ? this.advanceSearch.applicationId : '',
      "applyingPartyId": this.advanceSearch.applyingPartyId ? this.advanceSearch.applyingPartyId : '',
      "approverPartyId": this.advanceSearch.approverPartyId ? this.advanceSearch.approverPartyId : '',
      "jobRequisitionId": this.advanceSearch.jobRequisitionId ? this.advanceSearch.jobRequisitionId : '',
      "statusId": this.advanceSearch.statusId ? this.advanceSearch.statusId : '',


    }
    this.internalJobService.getInternalJobList(this.jobPost, req).subscribe((res: any) => {

      if (res.success) {
        this.spinner.hide();
        this.internalJobPostList = res.data;

      } else {
      
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
       
      }
    });
   
  }
  reset() {
    this.spinner.show();
    this.advanceSearch.statusId = "";
    this.advanceSearch.applicationDateFrom = "";
    this.advanceSearch.applicationDateTo = "";
    this.advanceSearch.applicationId = "";
    this.advanceSearch.applyingPartyId = "";
    this.advanceSearch.approverPartyId = "";
    this.advanceSearch.jobRequisitionId = "";
    const jobList = {
      applicationDateFrom: "",
      applicationDateTo: "",
      applicationId: "",
      applyingPartyId: "",
      approverPartyId: "",
      jobRequisitionId: "",
      statusId: "",
      applicationDateFromSearchType: "Equals",
      applicationDateToSearchType: "Equals"
    }
    this.internalJobService.getInternalJobList(this.jobPost, jobList).subscribe((res: any) => {
      this.internalJobPostList = res.data;
      this.spinner.hide();
    })
  
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
