import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Responsibilities {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        comments:(data && data.comments) ? data.comments : null,
        emplPositionTypeId:(data && data.emplPositionTypeId) ? data.emplPositionTypeId : null,
        fromDate:(data && data.fromDate) ? data.fromDate : null,
        responsibilityTypeId:(data && data.responsibilityTypeId) ? data.responsibilityTypeId : null,
        thruDate:(data && data.thruDate) ? data.thruDate : null,
    });
    return this.getFormGroup(form_data);
  }
  getFormGroup(data:any): any {
   return ({
    comments: new FormControl(data.comments),
    emplPositionTypeId: new FormControl(data.emplPositionTypeId),
    fromDate: new FormControl(data.fromDate),
    responsibilityTypeId: new FormControl(data.responsibilityTypeId),
    thruDate: new FormControl(data.thruDate),
    
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
