import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-financial-new-withdraw-payment',
  templateUrl: './financial-new-withdraw-payment.component.html',
  styleUrls: ['./financial-new-withdraw-payment.component.css']
})
export class FinancialNewWithdrawPaymentComponent implements OnInit {
  show: boolean;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  private finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  createRecon: FormGroup;
  activeCategory = 2;
  personId: any;
  partyIdArray: any[] = [];
  cardTypeArray: any[] = [];
  depositPaymentTypeArray: any[] = [];
  paymentMethodIdArray: any[] = [];
  currencyArray: any[] = [];
  giAccountArray: any[] = [];
  finAccountId: any;
  actualfromdate: string;
  isDepositWithDrawPayment: string;
  constructor(
    readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly addSkillService: AddSkillService,
  ) {
    this.createRecon = this._FormBuilder.group({


      actualCurrencyAmount: [''],
      actualCurrencyUomId: [''],
      amount: ['',[Validators.required]],
      comments: [''],
      currencyUomId: [''],
      effectiveDate: [''],
      finAccountTransId: [''],
      finAccountTransTypeId: [''],
      finAccountTypeId: [''],
      isDepositWithDrawPayment: [''],
      overrideGlAccountId: [''],
      partyIdFrom: ['',[Validators.required]],
      partyIdTo: ['',[Validators.required]],
      paymentGroupTypeId: [''],
      paymentId: [''],
      paymentMethodId: ['',[Validators.required]],
      paymentRefNum: [''],
      paymentTypeId: ['',[Validators.required]],
      /*  "statusId": "string" */
    });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.finAccountId = params["finAccountId"];

    });
    this.getPartyId();
    this.depositOrWithdrawInitailData();
    this.getGiAccountId();
    this.getCurrency();
  }

  depositOrWithdrawInitailData() {
    this.spinner.show();
    this.accountsService.depositOrWithdrawInitailData().subscribe(res => {
      const depositPaymentType =res.data.widthdrawPaymentType;
      const paymentMethodId = res.data.paymentMethodId;
      this.spinner.hide();
      for (const value of depositPaymentType) {
        this.depositPaymentTypeArray.push({
          label: value.description,
          value: value.paymentTypeId
        })
      }
      for (const value of paymentMethodId) {
        this.paymentMethodIdArray.push({
          label: value.paymentMethodId,
          value: value.paymentMethodId
        })
      }
    })
    
  }
  getGiAccountId() {
    this.spinner.show();
    this.accountsService.getGioAccountId().subscribe(res => {
      const giAccount = res.data.glAccounts;
      this.spinner.hide();
      for (const value of giAccount) {
        this.giAccountArray.push({
          label: value.glAccountId,
          value: value.glAccountId
        })
      }
    })
  
  }

  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
   
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  
  }
  onUpdate() {

  }
  selectDeposit(e){
    if(e.target.ariaChecked==="true"){
      this.isDepositWithDrawPayment="Y"
    }
    else{
      this.isDepositWithDrawPayment="N"
    }
  }
  onSubmit(): void {
    this.spinner.show();
    const fromActualdate = this.createRecon.get('effectiveDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "actualCurrencyAmount": this.createRecon.value.actualCurrencyAmount,
      "actualCurrencyUomId":this.createRecon.value.actualCurrencyUomId,
      "amount":  this.createRecon.value.amount,
      "comments": this.createRecon.value.comments,
      "currencyUomId": "USD",
      "effectiveDate":  this.actualfromdate,
      "finAccountTransId":  this.createRecon.value.finAccountTransId,
      "finAccountTransTypeId": "WITHDRAWAL","finAccountTypeId": "BANK_ACCOUNT",
      "isDepositWithDrawPayment":this.isDepositWithDrawPayment,
      "overrideGlAccountId":  this.createRecon.value.overrideGlAccountId,
      "partyIdFrom":  this.createRecon.value.partyIdFrom,
      "partyIdTo":  this.createRecon.value.partyIdTo,"paymentId": "",
      "paymentMethodId":  this.createRecon.value.paymentMethodId,
      "paymentRefNum":  this.createRecon.value.paymentRefNum,
      "paymentTypeId":  this.createRecon.value.paymentTypeId, "statusId": "PMNT_SENT"
    }
    this.accountsService.postDepositOrWithdraw(requestData, this.finAccountId).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(["financial/detail-financial-summary"], { queryParams: { finAccountId: this.finAccountId } })
      } }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      } }); }

  cancelSubmit() {
    this.router.navigate(["financial/detail-financial-summary"],{ queryParams: { finAccountId: this.finAccountId } })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}