import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-create-new-item-term',
    templateUrl: './create-new-item-term.component.html'
})
export class CreateNewItemTermComponent implements OnInit {
    itemTermForm: FormGroup;

    agreement: {
        agreementId: string,
        agreementItemSeqId: string
    };
    editMode: boolean;
    activeCategory=1;
    itemTypeIds: any[];
    agreementType: string;
    invoiceItemTypeIds: any[];
    itemTerm: any;
    agreementTermId: string;
    agreementItemTerm: any;
    constructor(
        readonly _FormBuilder: FormBuilder,
        readonly spinner: NgxSpinnerService,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly  _Router: Router,
        readonly _ToastrService: ToastrService,
        readonly _AccountingApService: AccountingApService
    ) {

        this.itemTermForm = this._FormBuilder.group({
            agreementId: [''],
            agreementItemSeqId: [''],
            description: [''],
            fromDate: [''],
            invoiceItemTypeId: [''],
            maxQuantity: [''],
            minQuantity: [''],
            termDays: [''],
            termTypeId: [''],
            termValue: [''],
            textValue: [''],
            thruDate: ['']
        });
        this.editMode = false;
        this.itemTypeIds = [];
        this.agreementType = '';
        this.invoiceItemTypeIds = [];
        this.agreementTermId = '';
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
        this._ActivatedRoute.queryParams.subscribe(data => {
            if (data) {
                this.agreement = {
                    agreementId: data.agreementId,
                    agreementItemSeqId: data.agreementItemSeqId
                };
                this.agreementTermId = data.agreementTermId;
                this.editMode = data.agreementTermId ? true : false;
                if (this.agreementTermId) {
                    this._AccountingApService.getAgreementItemTermById(this.agreement.agreementId, this.agreement.agreementItemSeqId, this.agreementTermId)
                        .then(value => {
                            this.agreementItemTerm = value.data;
                            this.itemTermForm.patchValue({
                                ...value.data, ...{
                                    fromDate: value.data.fromDate ? new Date(value.data.fromDate) : '',
                                    thruDate: value.data.thruDate ? new Date(value.data.thruDate) : ''
                                }
                            });
                        });
                }
            }
        });
        this.getItemTypeIds();
        this.getInvoiceItemTypeIds();
    }

    getItemTypeIds(): void {
        this.spinner.show();
        this._AccountingApService.getTermTypeIds()
            .then(data => {
                this.spinner.hide();
                this.itemTypeIds = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.termTypeId
                    };
                });
            });
            
    }
    getInvoiceItemTypeIds(): void {
        this.spinner.show();
        this._AccountingApService.getInvoiceItemTypeIds()
            .then(data => {
                this.spinner.hide();
                this.invoiceItemTypeIds = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.invoiceItemTypeId
                    };
                });
            });
          
    }
    update(): void {
        this.spinner.show();
        this._AccountingApService.updateAgreementItemTerm({
            ...this.itemTerm, ...this.itemTermForm.value, ...{
                agreementId: this.agreement.agreementId,
                agreementItemSeqId: this.agreement.agreementItemSeqId,
                fromDate: this.itemTermForm.value.fromDate ? moment(this.itemTermForm.value.fromDate).format('YYYY-HH-DD HH:mm:ss') : '',
                thruDate: this.itemTermForm.value.thruDate ? moment(this.itemTermForm.value.thruDate).format('YYYY-HH-DD HH:mm:ss') : ''
            }
        }, this.agreementTermId)
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.spinner.hide();
                    this.itemTermForm.reset();
                    this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/agreement-summary-item`], { queryParams: { agreementId: this.agreement.agreementId, agreementItemSeqId: this.agreement.agreementItemSeqId } });
                }
            });
    
    }
    submit(): void {
        this.spinner.show();
        if (this.editMode) {
            this.update();
        } else {
            this._AccountingApService.createAgreementItemTerm({
                ...this.itemTermForm.value, ...{
                    agreementId: this.agreement.agreementId,
                    agreementItemSeqId: this.agreement.agreementItemSeqId,
                    fromDate: this.itemTermForm.value.fromDate ? moment(this.itemTermForm.value.fromDate).format('YYYY-HH-DD HH:mm:ss') : '',
                    thruDate: this.itemTermForm.value.thruDate ? moment(this.itemTermForm.value.thruDate).format('YYYY-HH-DD HH:mm:ss') : ''
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.itemTermForm.reset();
                        this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/agreement-summary-item`], { queryParams: { agreementId: this.agreement.agreementId, agreementItemSeqId: this.agreement.agreementItemSeqId } });
                    }
                });
        }
       
    }
    reset(): void {
        this.itemTermForm.reset();
        this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/agreement-summary-item`], { queryParams: { agreementId: this.agreement.agreementId, agreementItemSeqId: this.agreement.agreementItemSeqId } });
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}