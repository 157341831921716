<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Payment Method Type</span>

                <span class="color-black pl-1" > >Update Payment Method Type</span>
                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Update Payment Method Type</a></li>
                       
                    </ul>
                  </div>
               
              
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100"  [formGroup]="updatePayment">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Default Gl Account ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Default Gl Account ID" filter="true"
                                                formControlName="defaultGlAccountId"  [options]="parentGlAccountArray" optionlabel="label">
                                                                
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA" >
                                                <label for="exampleInputEmail1">
                                                    Payment Method Type</label>
                                            </div>
                                           
                                          
                                            <div class="col-lg-2" >
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Description"
                                                formControlName="paymentMethodTypeId" readonly>
                                            </div>
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Description
                                               
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Description"
                                                formControlName="description" readonly>
                                            </div>
                                           
                                        </div>
                                    </div>
                                  
                                   
                               
                              
                                
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 65%;">
                               
                                <button type="submit" (click)="onUpdate();"    class="btn btn-secondary submit-btn">Update</button>
                                
                               
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
