import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

    
    import { Location } from '@angular/common';

@Component({
  selector: 'app-portal-communication',
  templateUrl: './portal-communication.component.html',
  styleUrls: ['./portal-communication.component.css']
})
export class PortalCommunicationComponent implements OnInit {
  products3: any;
  total=0;
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton') closebutton;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  readonly req = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  search = '';
  accountsList:any[]=[]
  rows = 50;
  getOverviewList: any;
  commEventIdPartyId: string;
 
  relatedContact:any[]=[]
  CustRequestTypeIDArray: any[]=[];
  ProjectAndPhaseName: any;
  CustRequestTypeID: any;
  ParentCustRequestId: any;
  ParentCustRequestIdArray: any[]=[];
  ProjectAndPhaseNameArray: any[]=[];
  activeCategory=2;
  activeCategoryValue=2;
  createCommEvent:FormGroup;
  opportunityIdArray: { label: string; value: string; }[];
  getInitailData: any;
  stausIdArray: any[]=[];
  comtypeIdArray: any[]=[];
  contentMMArray: any[]=[];
  newrequestForm: FormGroup;
  personId: any;
  partyIdArray: any[]=[];
  roleTypeId: any;
  yesNOArray: { label: string; value: string; }[];

  roleTypeIdArray: any[]=[];
  contactListId: any;
  contactListIdArray: any[]=[];
  datetimeEnded: string;
  datetimeStarted: string;
  parentCommEventArray: any[]=[];
  reasonenumArray: any[]=[];
  contactMech: any;
  contactMechArray: any[]=[];
  contactMechTypeArray: any[]=[];
  contactMechTo: any;
  contactMechToArray: any[]=[];
  custRequestStatusList: any;
  RequestDate: any;
  ResponseRequiredDate: any; PriorityListArray: any[]=[];
  PriorityList: any;
  PartyId: any;
  PartyIdArray: any[]=[];
  constructor(
    readonly accountsService: AccountsService,
    readonly router: Router,
    readonly datePipe: DatePipe,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
      private _location: Location,
      readonly addSkillService: AddSkillService,
    readonly spinner: NgxSpinnerService,
  ) { 
    this.newrequestForm = this._FormBuilder.group({
      RequestingParty: [''],
      CustRequestName: [''],
      CustRequestTypeID: [''],
      Description: [''],
      ShortDescription: [''],
      Priority: [''],
      ProjectandPhaseName: [''],
      Party: [''],
      RequestDate: [''],
      ResponseRequiredDate: [''],
      CustEstimatedMilliSeconds: [''],
      CustSequenceNum: [''],
      ParentCustRequestID: [''],
      Billed: [''],
      Story: [''],
      WebSiteID: [''],
     

    });

    this.createCommEvent = this._FormBuilder.group({
      commEventId: [''],
      parCommEventID: [''],
      commTypeId: [''],
      status:[''],
      partyFrom:[''],
      partyTo: [''],
      conMechId: [''],
      fromContactMechId: [''],
      toContactMechId:[''],
      roleTypeFrom:[''],
      roleTypeTo: [''],
      contactListId: [''],
      startdate: [''],
      finishDate:[''],
      subject:[''],
      reasonEnumId: [''],
      contentMimeTypeId: [''],
      content: [''],
      note:[''],
 
    });
    this.yesNOArray = [

      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];
    this.accountsList=[{
      'product':'admin'
    }]
  }
 ngAfterViewInit(): void { this.spinner.show(); } 
  ngOnInit(): void {
    const partyId: string = localStorage.getItem('partyId');
    this.commEventIdPartyId=partyId
    this.getCrmListDetail();
    this.getDropdownList();
    this.getPartyId();
    this.getRoleId();
    this.getContactList();
    this.getCustRequestTypeID();
    this.setCustRequestStatusList();
    this.getPartyIdList();
    this.getProjectAndPhaseName();
    this.getParentCustRequestId();
    this.getPriorityList();
    this.getPriorityList();
  }
  homeButton(){
    this.router.navigate(['/myPortal/my-portal-my-profile'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  getPriorityList() {
    this.spinner.show();
    this.myContactsService.getPriorityList().subscribe(res => {
      this.PriorityList = res.data;
      this.spinner.hide();
      for (const value of this.PriorityList) {
        this.PriorityListArray.push({
          label: value.description,
          value: value.priorityId
        })
      }
    })
  }
  getParentCustRequestId() {
    this.spinner.show();
    this.myContactsService.getParentCustRequestId().subscribe(res => {
      this.ParentCustRequestId = res.data;
      this.spinner.hide();
      for (const value of this.ParentCustRequestId) {
        this.ParentCustRequestIdArray.push({
          label: value.data.custRequestName,
          value: value.data.custRequestId
        })
      }
    })
  }
  getProjectAndPhaseName() {
    this.spinner.show();
    this.myContactsService.getProjectAndPhaseName().subscribe(res => {
      this.ProjectAndPhaseName = res.data;
      this.spinner.hide();
      for (const value of this.ProjectAndPhaseName) {
        this.ProjectAndPhaseNameArray.push({
          label: value.data.phaseName + "[" + value.data.projectName + "]",
          value: value.data.projectId
        })
      }
    })
  }
  getPartyIdList() {
    this.spinner.show();
    this.myContactsService.getPartyIdList().subscribe(res => {
      this.PartyId = res.data;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.PartyIdArray.push({
          label: value.description,
          value: value.partyId
        })
      }
    })
  }
  getCustRequestTypeID() {
    this.spinner.show();
    this.myContactsService.getCustRequestTypeID().subscribe(res => {
      this.CustRequestTypeID = res.data;
      this.spinner.hide();
      for (const value of this.CustRequestTypeID) {
        this.CustRequestTypeIDArray.push({
          label: value.data.description,
          value: value.data.custRequestTypeId
        })
      }
    })
  }
  createRequest() {
    this.spinner.show();
    const requestDate = this.newrequestForm.get('RequestDate').value;
    this.RequestDate = this.datePipe.transform(requestDate, "yyyy-MM-dd hh:mm:ss");
    
    const responseRequiredDate = this.newrequestForm.get('ResponseRequiredDate').value;
    this.ResponseRequiredDate = this.datePipe.transform(responseRequiredDate, "yyyy-MM-dd");
    const requestData = {
      "billed": this.newrequestForm.value.Billed ,
      "communicationEventId": "",
      "createdByUserLogin": "",
      "createdDate": "",
      "currentStatusId": "PTS_CREATED",
      "custEstimatedMilliSeconds": this.newrequestForm.value.CustEstimatedMilliSeconds ,
      "custRequestDate":this.RequestDate ,
      "custRequestId": "",
      "custRequestName": this.newrequestForm.value.CustRequestName ,
      "custRequestTypeId":this.newrequestForm.value.CustRequestTypeID ,
      "custSequenceNum": this.newrequestForm.value.CustSequenceNum ,
      "description":this.newrequestForm.value.Description ,
      "form": "list",
      "fromPartyId": this.newrequestForm.value.RequestingParty ,
      "lastModifiedByUserLogin": "",
      "lastModifiedDate": "",
      "parentCustRequestId": this.newrequestForm.value.ParentCustRequestID ,
      "partyId": this.newrequestForm.value.Party,
      "portalPageId": "",
      "priority":this.newrequestForm.value.Priority ,
      "responseRequiredDate": this.ResponseRequiredDate ,
      "roleTypeId": "PROJECT_TEAM" ,
      "statusId": "CRQ_SUBMITTED",
      "story":this.newrequestForm.value.Story ,
      "webSiteId": this.newrequestForm.value.WebSiteID ,
      "workEffortName": "",
      "workEffortParentId": this.newrequestForm.value.ProjectandPhaseName ,
      "workEffortTypeId": "TASK"
    } 

    this.myContactsService.createRequest(requestData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.newrequestForm.reset();
        this.router.navigate(["/myPortal/main-my-portal"]);
        this.spinner.hide();
        

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  deleteCommunicationEvent(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
       const req = {
          "_useRowSubmit": "Y",
          "communicationEventId": product.communicationEventId ,
          "communicationEventTypeId":  product.communicationEventTypeId ,
          "datetimeStarted":  product.datetimeStarted ,
          "entryDate":  product.entryDate ,
          "partyId":  product.partyId ,
          "portalPageId":  "10190" ,
          "roleStatusId":  product.roleStatusId ,
          "roleTypeId":  product.roleTypeId ,
          "statusId":  product.statusId ,
          "subject":  product.subject ,
          "viewIndex_4":  "0" ,
          "viewSize_4": "20"
        }
        this.addSkillService.deleteCommunicationEvent(req).subscribe(res => {
          this.getCrmListDetail();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  setCustRequestStatusList() {
    this.spinner.show();
    this.addSkillService.setCustRequestStatusList().subscribe(res => {
      this.custRequestStatusList = res.data[0].Data;
      this.spinner.hide();
    })
  }
  myProfilePage(fromPartyId) {
    this.router.navigate(["/psa/my-profile"], { queryParams: { partyId:fromPartyId} })
  }
  summaryPage(id,custRequestName) {
    this.router.navigate(["/psa/project/detail-new-request"], 
    { queryParams: { custRequestId:id, custRequestName:custRequestName} })
  }
  setCustRequestStatus(product) {
    this.spinner.show();
    const fromData = {
      "custRequestId": product.custRequestId,
      "statusId": "CRQ_REJECTED",
      "view_INDEX_1": "0",
      "view_SIZE_1": "20"
    }
    // this.router.navigate(["/order/detail-requests-order"], 
    // { queryParams: { custRequestId:product.custRequest.custRequestId, custRequestName:product.custRequest.custRequestName} })
    this.addSkillService.setCustRequestStatus(fromData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  GoToNewNote(commEventId){
    this.router.navigate(['/myPortal/my-portal-new-internal-note'],{ queryParams: { commEventId:commEventId}});
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  getDropdownList() {
    this.spinner.show();
   
    this.accountsService.getCrmInitialCommData().subscribe((res: any) => {
    const stausId = res.data.commEventstatus;
    const comtypeId=res.data.commEventTypes;
    const contentMM=res.data.mimeType;
    const parentCommEvent=res.data.parentCommEvent;
    const reasonenum=res.data.reasonEnum;
    const contactMechType=res.data.contactMechType;
    this.spinner.hide();
    for (const value of contactMechType) {
      this.contactMechTypeArray.push({
        label: value.description,
        value: value.contactMechTypeId
      })
    } 
    for (const value of reasonenum) {
      this.reasonenumArray.push({
        label: value.description,
        value: value.enumId
      })
    } 
    for (const value of parentCommEvent) {
      this.parentCommEventArray.push({
        label: value.communicationEventId,
        value: value.communicationEventId
      })
    } 
      for (const value of stausId) {
        this.stausIdArray.push({
          label: value.description,
          value: value.statusId
        })
      } 
      for (const value of comtypeId) {
        this.comtypeIdArray.push({
          label: value.description,
          value: value.communicationEventTypeId
        })
      } 
      for (const value of contentMM) {
        this.contentMMArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      } 
    })
     

    
   
  }
  
  getRoleId() {
    this.spinner.show();
    this.accountsService.getParty().subscribe(res => {
      this.roleTypeId = res.data.roleType;
      this.spinner.hide();
      for (const value of this.roleTypeId) {
        this.roleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  
  }
  contactEventMech(id) {
    this.spinner.show();
    this.accountsService.contactEventMech(id).subscribe(res => {
      this.contactMech = res.data;
      this.spinner.hide();
      for (const value of this.contactMech) {
        this.contactMechArray.push({
          label: value.contactMechId,
          value: value.contactMechId
        })
      }
    })
   
  }
  contactEventMechTo(id) {
    this.spinner.show();
    this.accountsService.contactEventMech(id).subscribe(res => {
      this.contactMechTo = res.data;
      this.spinner.hide();
      for (const value of this.contactMechTo) {
        this.contactMechToArray.push({
          label: value.contactMechId,
          value: value.contactMechId
        })
      }
    })
  
  }
  
  getContactList() {
    this.spinner.show();
    this.accountsService.getContactList().subscribe(res => {
      this.contactListId = res.data;
      this.spinner.hide();
      for (const value of this.contactListId) {
        this.contactListIdArray.push({
          label: value.description,
          value: value.contactListId
        })
      }
    })
  
  }
  
  onSubmit() {
    this.spinner.show();
    const fromActualdate=this.createCommEvent.get('startdate').value;
    this.datetimeStarted=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createCommEvent.get('finishDate').value;
    this.datetimeEnded=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "communicationEventId": '',
      "communicationEventTypeId": this.createCommEvent.value.commTypeId,
      "contactListId": this.createCommEvent.value.contactListId,
      "contactMechIdFrom": this.createCommEvent.value.fromContactMechId,
      "contactMechIdTo": this.createCommEvent.value.toContactMechId,
      "contactMechTypeId": this.createCommEvent.value.conMechId,
      "content": this.createCommEvent.value.content,
      "contentMimeTypeId": this.createCommEvent.value.contentMimeTypeId,
      "datetimeEnded": this.datetimeEnded,
      "datetimeStarted": this.datetimeStarted,
      "messageId": '',
      "note": this.createCommEvent.value.note,
      "parentCommEventId": this.createCommEvent.value.parCommEventID,
      "partyIdFrom": this.createCommEvent.value.partyFrom,
      "partyIdTo": this.createCommEvent.value.partyTo,
      "reasonEnumId": this.createCommEvent.value.reasonEnumId,
      "roleTypeIdFrom": this.createCommEvent.value.roleTypeFrom,
      "roleTypeIdTo":this.createCommEvent.value.roleTypeTo,
      "statusId":this.createCommEvent.value.status,
      "subject": this.createCommEvent.value.subject,
    }

    this.accountsService.postCommunicationNew(requestData).subscribe((res: any) => {
   
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getCrmListDetail();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    
  }
  getCrmListDetail() {
    this.spinner.show();
    const data={
      "communicationEventId": "",
      "communicationEventIdSearchType": "Contains",
      "communicationEventTypeId": "",
      "parentCommEventId": "",
      "parentCommEventIdSearchType": "Contains",
      "partyId": "",
      "partyIdFrom": "",
      "partyIdTo":  this.commEventIdPartyId,
      "roleTypeId": "",
      "statusId": "",
      "subject": "",
      "subjectSearchType": ""
    }
   
    this.accountsService.crmMainPageData(data, this.req).subscribe((res: any) => {
      this.getOverviewList = res.data;
      this.spinner.hide();

    })
   
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
 
      
      accountDetails(id){
        this.router.navigate(['/crm/main-detail-summary'], { queryParams: { commEventId: id} });

      }
      ngOnDestroy(): void {
        this.spinner.hide();
          } 
          replyMail(){
            this.router.navigate(['/crm/new-communication-crm']);
          }
}
