import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe, Location } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { HeaderService } from 'src/app/header/header.service';
@Component({
  selector: 'app-create-simple-text',
  templateUrl: './create-simple-text.component.html',
  styleUrls: ['./create-simple-text.component.css']
})
export class CreateSimpleTextComponent implements OnInit {
  TextContentForm: FormGroup;
  configItemId: any;
  confItemContentTypeId: any;
  @ViewChild('closebutton1') closebutton1;
  contentId: any;
  editProductConfigItem: any;
  fromDate: any;
  show: boolean;

  constructor(readonly router: Router,
    private _location: Location,
    readonly _FormBuilder: FormBuilder,
    readonly activatedRoute: ActivatedRoute,
    readonly MyContactsService: MyContactsService,
    readonly accountsService: AccountsService,
    readonly spinner: NgxSpinnerService,
    readonly toastr: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly addSkillService: AddSkillService,
    readonly datePipe: DatePipe,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly _Router: Router,
    public bsModalRef: BsModalRef) {
    this.TextContentForm = this._FormBuilder.group({
      FromDate: [''],
      ThroughDate: [''],
      ProductDescription: [''],
      LocaleString: [''],
      Text: [''],
      ContentID: [''],
    })
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.configItemId = params.configItemId;
      this.confItemContentTypeId = params.confItemContentTypeId;
    })
    this.show = false;
    this.update();
    setTimeout(() => {
      const formValue = this.TextContentForm;
      formValue.patchValue({
        ContentID:  this.contentId 
      })
    }, 2000);
  }
  updateSimpleTextContentForProductConfigItem() {
    this.spinner.show();
    const formData = {
      "confItemContentTypeId":  this.confItemContentTypeId,
      "configItemId": this.configItemId,
      "contentId": this.contentId,
      "description":  this.TextContentForm.value.ProductDescription,
      "fromDate":  this.fromDate,
      "localeString":  this.TextContentForm.value.LocaleString,
      "text":  this.TextContentForm.value.Text,
      "textDataResourceId": "",
      "thruDate": this.datePipe.transform(this.TextContentForm.value.ThroughDate,"yyyy-dd-mm hh:mm:ss") 
      }
    this.myContactsService.updateSimpleTextContentForProductConfigItem(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.TextContentForm.reset();
        this.closebutton1.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  update(){
    if(this.contentId){
      this.show = true;
      this.EditProductConfigItemContentContent();
    }
    
  }
  EditProductConfigItemContentContent() {
    this.spinner.show();
    this.myContactsService.EditProductConfigItemContentContent(this.confItemContentTypeId, this.configItemId, this.contentId, this.fromDate).subscribe(res => {
      this.editProductConfigItem = res.data;
      setTimeout(() => {
        const formValue = this.TextContentForm;
        formValue.patchValue({
          ProductDescription: this.editProductConfigItem.content.description,
          LocaleString: this.editProductConfigItem.content.localeString,
          Text: this.editProductConfigItem.textData.text,
          ContentID:  this.contentId 
        })
      }, 2000);
      this.spinner.hide();
    })
  }
  createSimpleTextContentForProductConfigItem() {
    this.spinner.show();
    const formData = {
      "confItemContentTypeId": this.confItemContentTypeId,
      "configItemId": this.configItemId,
      "description": this.TextContentForm.value.ProductDescription,
      "fromDate": this.datePipe.transform(this.TextContentForm.value.FromDate, "yyyy-dd-mm hh:mm:ss"),
      "localeString": this.TextContentForm.value.LocaleString,
      "text": this.TextContentForm.value.Text,
      "textDataResourceId": "",
      "thruDate": this.datePipe.transform(this.TextContentForm.value.ThroughDate, "yyyy-dd-mm hh:mm:ss")
    }
    this.myContactsService.createSimpleTextContentForProductConfigItem(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.TextContentForm.reset();
        this.closebutton1.nativeElement.click();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
}
