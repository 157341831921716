import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { WarehouseService } from '../warehouse/warehouse.service';

@Component({
  selector: 'app-create-new-facilities',
  templateUrl: './create-new-facilities.component.html',
  styleUrls: ['./create-new-facilities.component.css']
})
export class CreateNewFacilitiesComponent implements OnInit {
  facilityForm: FormGroup;
  activeCategory = 2;
  inventoryData: any[];
  facilityType: any[];
  weightData: any[];
  areaData: any[];
  facilityIds: any[];
  partyIds: any[];
  facilityData: any;
  facilityId: string;
  editMode: boolean;
  url: string;
  constructor(
    readonly _FormBuilder: FormBuilder,
    readonly _WarehouseService: WarehouseService,
    readonly _ToastrService: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly router: Router,
    readonly _ActivatedRoute: ActivatedRoute
  ) {
    this.url='/facilities/warehouse/'
    this.inventoryData = [];
    this.facilityType = [];
    this.weightData = [];
    this.areaData = [];
    this.facilityForm = this._FormBuilder.group({
      defaultDaysToShip: [''],
      defaultInventoryItemTypeId: [null],
      defaultWeightUomId: [null],
      description: [''],
      facilityName: ['', [Validators.required]],
      facilitySize: [''],
      facilitySizeUomId: [null],
      facilityTypeId: [null, [Validators.required]],
      ownerPartyId: [null, [Validators.required]],
      parentFacilityId: [null]
    });
    this.facilityIds = [];
    this.partyIds = [];
    this.facilityId = '';
    this.editMode = false;
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.facilityId = this._ActivatedRoute.snapshot.queryParams.facilityId;
    if (this.facilityId) {
    
      this.editMode = true;
      this._ActivatedRoute.data.subscribe(data => {
        this.facilityData = data.facility;
        this.facilityForm.patchValue(data.facility);
      });
    }
    this.getFacilityType();
    this.getInventory();
    this.getWeight();
    this.getArea();
    this.getFacility();
    this.getPartyGroup();
  }
  getFacility(): void {
    this.spinner.show();
    this._WarehouseService.getFacility()
      .then(data => {
        this.facilityIds = data.data;
        this.spinner.hide();
      });
     
  }
  getPartyGroup(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        this.partyIds = data.data.PersonsGroups;
        this.spinner.hide();
      });
     
  }
  getFacilityType(): void {
    this.spinner.show();
    this._WarehouseService.getFacilityType()
      .then(data => {
        this.facilityType = data.data;
        this.spinner.hide();
      });
     
  }
  getInventory(): void {
    this.spinner.show();
    this._WarehouseService.defaultInventory()
      .then(data => {
        this.inventoryData = data.data;
        this.spinner.hide();
      });
     
  }
  getWeight(): void {
    this.spinner.show();
    this._WarehouseService.defaultWeight()
      .then(data => {
        this.weightData = data.data;
        this.spinner.hide();
      });
      
  }
  getArea(): void {
    this.spinner.show();
    this._WarehouseService.areaUnit()
      .then(data => {
        this.areaData = data.data;
        this.spinner.hide();
      });
     
  }
  update(): void {
    this.spinner.show();

    if (this.facilityForm.valid) {
      this._WarehouseService.updateFacility(this.facilityId, {
        ...this.facilityData, ...this.facilityForm.value
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.facilityForm.reset();
          }
        });
    }
  }

  submit(): void {
    this.spinner.show();
    if (this.editMode) {
      this.update();
    } else {
      if (this.facilityForm.valid) {
        this._WarehouseService.createFacility(this.facilityForm.value)
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Created');
              this.spinner.hide();
              this.facilityForm.reset();
              this.router.navigate([this.url]);
            }
          });
      }
    }
 
  }

  onCancel(){
    this.facilityForm.reset();
    this.router.navigate([this.url]);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
