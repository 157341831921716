<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Carrier Shipment Method  of {{ this.id}} </span>
               
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <header class="w3-container w3-blue">
                            <div class="header-tabs" style="margin-left: -15px;
                        margin-right: -14px;">
                                <h4 class=" common-styling h4Margin">
                                    Carrier Shipment Methods Information
                                </h4>
                                <span *ngIf="!this.Hidebtn" class="edit-buttons hCreate">

                              <a   data-toggle="modal" (click)="edit();"
                              data-target="#exampleModalCenter">Update</a>
                            </span>
                            </div>
                        </header>

                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group">
                                                <div *ngIf="activeCategory==1">

                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">
                                                                <div class="row">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Shipment Method Type Id</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.id}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Party ID </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.pid}}</span>

                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Role Type ID </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.rid}}</span>

                                                                        </div>




                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Seq. Num</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.snum}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Carrier Service Code</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.code}}</span>
                                                                        </div>





                                                                    </div>




                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Update Shipment Methods</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategorytab==2?'active':''"><a>
                        Update Carrier Shipment Methods</a></li>


                        </ul>
                    </div>

                    <div>
                        <div class="all-users-infomation font-13 font-medium own-user-select">
                            <form [formGroup]="carrierShipmentForm">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Shipment Method Type ID</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <ng-select [items]="shipmentTypeIds" bindLabel="description" bindValue="shipmentMethodTypeId" formControlName="shipmentMethodTypeId" class="ng-select-width"></ng-select>
                                            </div>
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Party ID
                                        </label>
                                            </div>
                                            <div class="col-lg-3">
                                                <ng-select [items]="partyIds" bindLabel="partyId" bindValue="partyId" class="ng-select-width" formControlName="partyId">
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Role Type ID</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <ng-select [items]="roleIds" bindLabel="description" class="ng-select-width" bindValue="roleTypeId" formControlName="roleTypeId"></ng-select>
                                            </div>
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Seq. Num</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control" formControlName="sequenceNumber" placeholder="Enter Seq. Num">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Carrier Service Code</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control" formControlName="carrierServiceCode" placeholder="Enter Carrier Service Code">
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -7%;"><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="submit()">Update</button>

                                </div>
                            </form>


                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>