<div class="container-fluid main-container-wrapper">
  <br>
  <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
    
    <button type="submit"  class="btn btn-danger buttonclassA"  (click)="onCancel()">Cancel</button>
</div>
  <div class="w3-card-4 classCard" >
  <div class="container-fluid" >
    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
      <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
        Import Employee Data</a></li>
    </ul>
  </div>
<div class="panel-group">
    <div *ngIf="activeCategory==1">
     
          <div class="panel-body">
              <form>
            <div class="all-users-infomation font-13 font-medium own-user-select">
              <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-2 form-group classInput">
                      <label for="exampleInputEmail1">Attach Content</label>
                    </div>
                    <div class="col-lg-2">
                        
                      <input type="file"  (change)="fileProgress($event)" class="classFile" id="exampleInputEmail1" aria-describedby="emailHelp"  accept="">
                           
                    </div>
                    <div class="col-lg-2"></div>
                    <div class="col-lg-2 form-group">
                      <button type="button"  (click)="onSubmit()"  class="btn btn-secondary submit-btn">Submit</button>
                       
                    </div>
                   
                </div>
            </div>

              
            </div>
      </form>
        </div>
      </div>
      </div>
      </div>
    </div>
    <ngx-spinner></ngx-spinner> 