<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Product: {{this.internalName + "[" + this.productId + "]"}}</span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>

            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="w3-card-4 classCardA ">

                                            <div class="container-fluid">
                                                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                                    <li *ngIf="!this.Hidebtn" [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)"><a>
                                                            Product Overview
                                                        </a></li>
                                                    <li *ngIf="!this.Hidebtn" [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryUpdate()">
                                                        <a>
                                                            Product Update
                                                        </a>
                                                    </li>
                                                    <li *ngIf="!this.Hidebtn" [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)">
                                                        <a>
                                                            Product Member</a>
                                                    </li>
                                                    <li *ngIf="!this.Hidebtn" [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)">
                                                        <a>
                                                            Total Backlog
                                                        </a>
                                                    </li>
                                                    <li *ngIf="!this.Hidebtn" [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategory(5)">
                                                        <a>
                                                            Edit Backlog
                                                        </a>
                                                    </li>
                                                    <li  [ngClass]="activeCategory==16?'active':''" (click)="changeactiveCategory(16)" *ngIf="showUnplannedTab || !this.Hidebtn">
                                                        <a>
                                                            Unplanned Sprint
                                                        </a>
                                                    </li>
                                                    <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategory(6)">
                                                        <a>
                                                            Billing
                                                        </a>
                                                    </li>
                                                    <li *ngIf="!this.Hidebtn" [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategory(7)">
                                                        <a>
                                                            Default Tasks
                                                        </a>
                                                    </li>
                                                    <li *ngIf="!this.Hidebtn" [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategory(8)"><a>
                                                            Categories
                                                        </a></li>
                                                    <li *ngIf="!this.Hidebtn" [ngClass]="activeCategory==9?'active':''" (click)="changeactiveCategory(9)">
                                                        <a>
                                                            Content
                                                        </a>
                                                    </li>
                                                    <li *ngIf="!this.Hidebtn" [ngClass]="activeCategory==10?'active':''" (click)="changeactiveCategory(10)"><a>
                                                            Communications
                                                        </a></li>
                                                    <li *ngIf="!this.Hidebtn" [ngClass]="activeCategory==11?'':''" (click)="changeactiveCategory(11)" hidden><a>
                                                            Detailed Edit Page
                                                        </a></li>
                                                    <li *ngIf="!this.Hidebtn" [ngClass]="activeCategory==12?'':''" (click)="changeactiveCategory(12)" hidden><a>
                                                            Edit Default Task
                                                        </a></li>
                                                    <li *ngIf="!this.Hidebtn" [ngClass]="activeCategory==13?'':''" (click)="changeactiveCategory(13)" hidden><a>
                                                            Edit Backlog Category
                                                        </a></li>
                                                </ul>
                                            </div>
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                <div class="create-new-leade ">
                                                    <div class="container-fluid">


                                                        <div class="panel-group">
                                                            <div *ngIf="activeCategory==1">
                                                                <div class="container-fluid">
                                                                    <ul class=" tabbing-accordians " style="width: 103% !important;
                                                    flex-wrap: wrap !important;
                                                    display: flex !important;
                                                    padding: 10px !important;">
                                                                        <li>
                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalNewProject">
                                                                                New
                                                                                Project
                                                                            </button>

                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="closeProduct()"> Close
                                                                                Product
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div class="divA">
                                                                    <div class="w3-card-4 classCardView">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Product Information
                                                                            </h4>
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select"
                                                                            *ngIf="productList">
                                                                                <div class="color-black container">
                                                                                    <div class="row">
                                                                                        <div class="article-container" >
                                                                                            <div class="article">
                                                                                                <p class="col-lg-7 col-12">
                                                                                                    Company and Product Owner </p>
                                                                                                <span class="color-grey pl-20 col-lg-10 col-12 font-weight-bold">{{this.productList.fromid.groupName +" "+this.productList.productRolePartyIds.partyId}}</span>
                                                                                            </div>
                                                                                            <div class="article">
                                                                                                <p class="col-lg-7 col-12">
                                                                                                    Status</p>
                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.productList.Status}}
                                                                                                </span>
                                                                                            </div>

                                                                                            <div class="article">
                                                                                                <p class="col-lg-7 col-12">
                                                                                                    Product Name</p>
                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.productList.ProductNames.internalName}}</span>
                                                                                            </div>

                                                                                            <div class="article">
                                                                                                <p class="col-lg-7 col-12">
                                                                                                    Description </p>
                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.productList.ProductNames.longDescription}}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <br>
                                                                    <div class="w3-card-4 classCardView">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Backlogs Status Chart

                                                                            </h4>
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table borderTable">
                                                                                        <div class="col-lg-12">
                                                                                            <div class="chartjs-container">
                                                                                                <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels"
                                                                                                    [chartType]="pieChartType" [options]="pieChartOptions"
                                                                                                    [plugins]="pieChartPlugins" [colors]="pieChartColors"
                                                                                                    [legend]="pieChartLegend">
                                                                                                </canvas>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <br>
                                                                    <div class="w3-card-4 classCardView">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Revisions
                                                                            </h4>
                                                                        </div>
                                                                        <div class="panel-body allStyleUser">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table borderTable">


                                                                                        <p-table [value]="" [rows]="3">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769;">
                                                                                                    <th [ngStyle]="{'width':'120px'}" pSortableColumn="code">
                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
                                                                                                            Contact Type
                                                                                                            <p-sortIcon field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>

                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'120px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;">
                                                                                                            Contact Information
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>

                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'120px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;">
                                                                                                            Soliciting OK
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>

                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'110px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;">
                                                                                                            Action
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>

                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'120px'}">

                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'120px'}">
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'120px'}">
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'120px'}">
                                                                                                    </td>

                                                                                                </tr>


                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span class="text-center">
                                                                                                            <h4
                                                                                                                class="ml-2">
                                                                                                                No
                                                                                                                Record
                                                                                                                Found
                                                                                                            </h4>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>

                                                                                        <p class="paginate_data">

                                                                                        </p>


                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>

                                                                <div class="divB">
                                                                    <div class="w3-card-4 classCardView">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Product Members List
                                                                            </h4>
                                                                        </div>

                                                                        <div class="panel-body allStyleUser">
                                                                            <div class="card own-account-table borderTable">
                                                                                <p-table [value]="ProductMembersList" [paginator]="false" [rows]="3" scrollHeight="150px"  scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>

                                                                                                    Member
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Role Type ID
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    From Date</div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>




                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    {{product.partyId}}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.roleTypeId}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.fromDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                            </td>


                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                                    <h4 class="ml-2">No
                                                                                                        Record Found
                                                                                                    </h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                               
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                    <br>
                                                                    <div class="w3-card-4 classCardView ">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Emails
                                                                            </h4>

                                                                        </div>
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                                                <div class="card own-account-table borderTable">
                                                                                    <p-table [value]="EmailsProductBacklogs"  [paginator]="false" [rows]="3" scrollHeight="150px"  [scrollable]="true" [customSort]="true">
                                                                                        <ng-template pTemplate="header">
                                                                                            <tr style="background: #0d3769;">
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                </div>
                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <!---->
                                                                                                        </p-checkbox>

                                                                                                    </div>
                                                                                                    Subject
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                    <div style="color: white;">
                                                                                                        Status ID
                                                                                                    </div>

                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                    <div style="color: white;">
                                                                                                        From
                                                                                                    </div>

                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                                    <div style="color: white;">
                                                                                                        To
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">
                                                                                                        Created At

                                                                                                    </div>
                                                                                                </th>


                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="body" let-product>
                                                                                            <tr>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    <div class="checkbox-align">
                                                                                                        {{product.subject}}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    {{product.statusId}}
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    {{product.partyIdFrom}}

                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    {{product.partyIdTo}}

                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    {{product.createdStamp | date:'yyyy-MM-d hh:mm:ss'}}
                                                                                                </td>

                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="text-center">
                                                                                                        <h4
                                                                                                            class="ml-2">
                                                                                                            No Record
                                                                                                            Found</h4>
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                    </p-table>
                                                                                    <p class="paginate_dataa">
                                                                                        <!--  <ng-container *ngIf="openNoRecordFoundNote">
                                      <span style="margin-left: 450px;">No Record Found!....</span>
                                    </ng-container> -->
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="w3-card-4 classCardView mt-2">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Product Content List
                                                                            </h4>

                                                                        </div>
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                                                <div class="card own-account-table borderTable">
                                                                                    <p-table [value]="ProductContentList"  [paginator]="false" [rows]="3" scrollHeight="100px"  [scrollable]="true" [customSort]="true">
                                                                                        <ng-template pTemplate="header">
                                                                                            <tr style="background: #0d3769;">
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                </div>
                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <!---->
                                                                                                        </p-checkbox>
                                                                                                        Content Type ID
                                                                                                    </div>

                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                    <div style="color: white;">
                                                                                                        Content Name
                                                                                                    </div>
                                                                                                    <div>

                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                                    <div style="color: white;">
                                                                                                        From Date
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">
                                                                                                    </div>
                                                                                                </th>


                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="body" let-product>
                                                                                            <tr>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    <div *ngFor="let r of product.productContent" class="checkbox-align">
                                                                                                        <span>{{r.contentId}}</span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    {{product.ContentName}}
                                                                                                </td>
                                                                                                <td *ngFor="let r of product.productContent ;" [ngStyle]="{'width':'170px'}">
                                                                                                    <span>{{r.fromDate
                                                                                                        |
                                                                                                        date:'yyyy-MM-d
                                                                                                        hh:mm:ss' }}
                                                                                                    </span>


                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2">Download</button>
                                                                                                </td>

                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="text-center">
                                                                                                        <h4
                                                                                                            class="ml-2">
                                                                                                            No Record
                                                                                                            Found</h4>
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                    </p-table>
                                                                                  
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="w3-card-4 classCardView mt-2">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Project and Sprint List
                                                                            </h4>

                                                                        </div>
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                                                <div class="card own-account-table borderTable">
                                                                                    <p-table [value]="ProjectAndSprintList"  [paginator]="false" [rows]="3" scrollHeight="100px"  [scrollable]="true" [customSort]="true">
                                                                                        <ng-template pTemplate="header">
                                                                                            <tr style="background: #0d3769;">
                                                                                                <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                                                    <div class="checkbox-align" style="color: white; ">
                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                </div>
                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <!---->
                                                                                                        </p-checkbox>
                                                                                                        Project
                                                                                                    </div>

                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                    <div style="color: white;">
                                                                                                    </div>
                                                                                                    <div>

                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                                    <div style="color: white;">

                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">
                                                                                                    </div>
                                                                                                </th>

                                                                                            </tr>

                                                                                            <tr style="background: #0d3769;">
                                                                                                <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                                                    <div class="checkbox-align" style="color: white !important;">
                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                </div>
                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <!---->
                                                                                                        </p-checkbox>
                                                                                                        Sprint
                                                                                                    </div>

                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                    <div style="color: white;">
                                                                                                        Status
                                                                                                    </div>

                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                                    <div style="color: white;">
                                                                                                        Sprint Length Weeks
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">
                                                                                                        Planned Hours
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">
                                                                                                        Actual Hours
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">
                                                                                                        Act Hrs Not Bill
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">
                                                                                                        Start Date
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">
                                                                                                        End date
                                                                                                    </div>
                                                                                                </th>

                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="body" let-product>
                                                                                            <tr>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    <div class="checkbox-align">
                                                                                                        {{product.workEffortId}}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    {{product.currentStatusId}}
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'170px'}">

                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    {{product.actualStartDate | date:'yyyy-MM-d hh:mm:ss' }}

                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    {{product.actualCompletionDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                                </td>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="text-center">
                                                                                                        <h4
                                                                                                            class="ml-2">
                                                                                                            No Record
                                                                                                            Found</h4>
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                    </p-table>
                                                                                    <p class="paginate_dataa">
                                                                                        <!--  <ng-container *ngIf="openNoRecordFoundNote">
                                      <span style="margin-left: 450px;">No Record Found!....</span>
                                    </ng-container> -->
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br>
                                                                </div>
                                                            </div>

                                                            <div *ngIf="activeCategory==2">
                                                                <div class=" bg-white color-grey">
                                                                    <div class="create-new-leade">
                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                <form class="w-100">
                                                                                    <div class="container-fluid">
                                                                                        <ul class=" tabbing-accordians " style="width: 103% !important;
                                                                        flex-wrap: wrap !important;
                                                                        display: flex !important;
                                                                        padding: 10px !important;">

                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalNewProject">
                                                                                                New Project
                                                                                            </button>

                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="closeProduct()">
                                                                                                Close Product
                                                                                            </button>

                                                                                        </ul>
                                                                                    </div>

                                                                                    <div class="port-header-contact create-lead font-medium mb-0 pt-0 mt-0 text-white">
                                                                                        <div class="w-100">
                                                                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                                                                <div>


                                                                                                    <form [formGroup]="productUpdateForm">
                                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                                                                                            <div class="col-lg-12">
                                                                                                                <div class="row">
                                                                                                                    <div class="col-lg-3 form-group classInputB">
                                                                                                                        <label for="exampleInputEmail1">Company
                                                                                                                            and
                                                                                                                            Product
                                                                                                                            Owner
                                                                                                                        </label>
                                                                                                                    </div>
                                                                                                                    <div class="col-lg-2">
                                                                                                                        <p-dropdown filter="true" [options]="CompanyandProductOwnerArray" formControlName="CompanyandProductOwner" 
                                                                                                                       optionlabel="label" placeholder="Choose Company and Product Owner">
                                                                                                                        </p-dropdown>
                                                                                                                    </div>

                                                                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                                                                        <label for="exampleInputEmail1">Status
                                                                                                                        </label>
                                                                                                                    </div>
                                                                                                                    <div class="col-lg-2">
                                                                                                                        <p-dropdown filter="true" [options]="statusArray" formControlName="Status" optionlabel="label" placeholder="Enter Status">
                                                                                                                        </p-dropdown>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>



                                                                                                            

                                                                                                            <div class="col-lg-12">
                                                                                                                <div class="row">
                                                                                                                    <div class="col-lg-3 form-group classInputB">
                                                                                                                        <label for="exampleInputEmail1">Product
                                                                                                                            Name
                                                                                                                        </label>
                                                                                                                    </div>
                                                                                                                    <div class="col-lg-2">

                                                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="ProductName" aria-describedby="emailHelp" placeholder="Enter Product Name">
                                                                                                                    </div>
                                                                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                                                                        <label for="exampleInputEmail1">Description
                                                                                                                        </label>
                                                                                                                    </div>
                                                                                                                    <div class="col-lg-2">

                                                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Description" aria-describedby="emailHelp" placeholder="Enter Description">
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>

                    

                                                                                                        </div>
                                                                                                    </form>
                                                                                                    <div class="col-lg-12  main-submit-button" style="
                                                                                                               margin-left: -18%;">
                                                                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn"
                                                                                                         (click)="productUpdate()">Update</button>&nbsp;



                                                                                                    </div>


                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div *ngIf="activeCategory==3">

                                                                <div class=" bg-white color-grey">
                                                                    <div class="create-new-leade mt-2">
                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                <form class="w-100">
                                                                                    <div class="container-fluid">
                                                                                        <ul class="tabbing-accordians" style="width: 103% !important;
                                                                        flex-wrap: wrap !important;
                                                                        display: flex !important;
                                                                        padding: 10px !important;">

                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalNewProject">
                                                                                                New
                                                                                                Project
                                                                                            </button>

                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="closeProduct()">Close
                                                                                                Product
                                                                                            </button>

                                                                                        </ul>
                                                                                    </div>
                                                                                    <div class="w3-card-4 classCard">
                                                                                        <div class="container-fluid">
                                                                                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                                                            flex-wrap: wrap !important;
                                                                            display: flex !important;">
                                                                                                <li><a>
                                                                                                        Add Company and
                                                                                                        Product Owner



                                                                                                    </a>
                                                                                                </li>

                                                                                            </ul>
                                                                                        </div>
                                                                                        <form [formGroup]="CompanyandProductOwnerForm">


                                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">
                                                                                                        <div class="col-lg-3 form-group classInputA" style="margin-left: -6%;">
                                                                                                            <label for="exampleInputEmail1">Company
                                                                                                                and
                                                                                                                Person
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-2">

                                                                                                            <p-dropdown filter="true" [options]="CompanyandProductOwnerArray"
                                                                                                             formControlName="CompanyandPerson" optionlabel="label" 
                                                                                                             placeholder="Enter Company and Person">
                                                                                                            </p-dropdown>
                                                                                                        </div>

                                                                                                        
                                                                                                            
        
                                                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                                                            <label for="exampleInputEmail1">Party
                                                                                                                        ID
                                                                                                            </label>
        
                                                                                                        </div>
        
                                                                                                        <div class="col-lg-2">
        
                                                                                                            <p-dropdown filter="true" [options]="partyIdArray" formControlName="partyId" optionlabel="label" placeholder="Enter Party ID">
                                                                                                                </p-dropdown>
                                                                                                        </div>
        
                                                                                                            
                                                                                                        

                                                                                                    </div>
                                                                                                </div>

                                                                                                


                                                                                            </div>
                                                                                        </form>
                                                                                        <div class="col-lg-12  main-submit-button" style="
                                                                                                                       margin-left: -18%;">
                                                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="addCompanyAndProductOwner()">Assign</button>&nbsp;


                                                                                        </div>


                                                                                    </div>

                                                                                    <div class="w3-card-4 classCard mt-2">
                                                                                        <div class="container-fluid">
                                                                                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                                                            flex-wrap: wrap !important;
                                                                            display: flex !important;">
                                                                                                <li><a>
                                                                                                        Add Product
                                                                                                        Member
                                                                                                    </a>
                                                                                                </li>

                                                                                            </ul>
                                                                                        </div>
                                                                                        <form [formGroup]="addProductMemberForm">
                                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">
                                                                                                        <div class="col-lg-3 form-group classInputD" style="margin-left: -5%;">
                                                                                                            <label for="exampleInputEmail1">Person
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-2">

                                                                                                            <p-dropdown [options]="partyIdArray" filter="true" formControlName="Person" optionlabel="label" placeholder="Choose Person">
                                                                                                            </p-dropdown>
                                                                                                        </div>

                                                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                                                            <label for="exampleInputEmail1">Role
                                                                                                                Type ID
                                                                                                            </label>
                                                                                                        </div>

                                                                                                        <div class="col-lg-2">

                                                                                                            <p-dropdown [options]="roleTypeIdArray" filter="true" formControlName="RoleType" optionlabel="label" placeholder="Choose Role Type ID">
                                                                                                            </p-dropdown>

                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>

                                                                                                

                                                                                            </div>
                                                                                            <div class="col-lg-12  main-submit-button" style="
                                                                                                                       margin-left: -17%;">
                                                                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="addProductMember()">Assign</button>&nbsp;


                                                                                            </div>
                                                                                        </form>
                                                                                    </div>


                                                                                    <div class="w3-card-4 classCard mt-2">
                                                                                        <header class="w3-container w3-blue">
                                                                                            <div class="header-tabs">
                                                                                                <h4 class=" common-styling h4Margin">
                                                                                                    Product Members List

                                                                                                </h4>

                                                                                            </div>
                                                                                        </header>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="col-lg-12 col-12">
                                                                                                <div class="form-group">

                                                                                                    <div class="card own-account-table">
                                                                                                        <p-table [value]="ProductMembersList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                            <ng-template pTemplate="header">
                                                                                                                <tr style="background: #0d3769;">
                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                    </div>
                                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <!---->
                                                                                                                            </p-checkbox>
                                                                                                                            Member
                                                                                                                            <p-sortIcon field="code">
                                                                                                                            </p-sortIcon>
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Role Type ID
                                                                                                                            <p-sortIcon field="name">
                                                                                                                            </p-sortIcon>
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            From Date
                                                                                                                            <p-sortIcon field="name">
                                                                                                                            </p-sortIcon>
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Remove
                                                                                                                            <p-sortIcon field="name">
                                                                                                                            </p-sortIcon>
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>




                                                                                                                </tr>
                                                                                                            </ng-template>
                                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                                <tr>
                                                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                                                        <div class="checkbox-align">
                                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                    </div>
                                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <!---->
                                                                                                                            </p-checkbox>
                                                                                                                            <span  class="account-button"  >
                                                                                                                                {{product.partyId}}</span>

                                                                                                                        </div>
                                                                                                                    </td>

                                                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                                                        {{product.roleTypeId}}
                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                                                        {{product.fromDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'180px'}">

                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteProductMember()" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                                                            <defs>
                                                                                                                                <style>
                                                                                                                                    .a {
                                                                                                                                        fill: #f44336;
                                                                                                                                    }

                                                                                                                                    .b {
                                                                                                                                        fill: #fafafa;
                                                                                                                                        margin-left: 20px;
                                                                                                                                    }
                                                                                                                                </style>
                                                                                                                            </defs>
                                                                                                                            <path
                                                                                                                                class="a fill-color"
                                                                                                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                            <path
                                                                                                                                class="b"
                                                                                                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                                transform="translate(-147.576 -147.576)" />
                                                                                                                        </svg>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </ng-template>
                                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                <tr>
                                                                                                                    <td>
                                                                                                                        <span class="text-center">
                                                                                                                            <h4
                                                                                                                                class="ml-2">
                                                                                                                                No
                                                                                                                                Record
                                                                                                                                Found
                                                                                                                            </h4>
                                                                                                                        </span>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </ng-template>
                                                                                                        </p-table>

                                                                                                        <p class="paginate_data">
                                                                                                            View per page </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </form>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div *ngIf="activeCategory==4">

                                                                <div class=" bg-white color-grey">
                                                                    <div class="create-new-leade mt-2">
                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                <form class="w-100">
                                                                                    <div class="container-fluid">
                                                                                        <ul class=" tabbing-accordians " style="width: 103% !important;
                                                                        flex-wrap: wrap !important;
                                                                        display: flex !important;
                                                                        padding: 10px !important;">


                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalNewProject">
                                                                                                New
                                                                                                Project
                                                                                            </button>

                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="closeProduct()">
                                                                                                Close
                                                                                                Product
                                                                                            </button>



                                                                                        </ul>
                                                                                    </div>

                                                                                    <div class="w3-card-4 classCard">
                                                                                        <div class="container-fluid">
                                                                                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                                                            flex-wrap: wrap !important;
                                                                            display: flex !important;">
                                                                                                <li><a>
                                                                                                        Find Product
                                                                                                        Backlog Item

                                                                                                    </a>
                                                                                                </li>

                                                                                            </ul>
                                                                                        </div>

                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                                        <label for="exampleInputEmail1">Product
                                                                                                            Backlog ID
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">

                                                                                                        <input type="email" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ProductBacklogID" class="form-control" id="exampleInputEmail1" name="ProductBacklogID" aria-describedby="emailHelp" placeholder="Enter Product Backlog ID">
                                                                                                    </div>


                                                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                            Requester
                                                                                                            Name
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown filter="true" [options]="RequesterNameArray" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.RequesterName" name="RequesterName" optionlabel="label" placeholder="Choose Requester Name">
                                                                                                        </p-dropdown>
                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>

                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                                        <label for="exampleInputEmail1">Product
                                                                                                            Backlogs
                                                                                                            Name
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ProductBacklogsName" name="ProductBacklogsName" aria-describedby="emailHelp" placeholder="Enter Product Backlogs Name ">

                                                                                                    </div>

                                                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                                                        <label for="exampleInputEmail1">Request
                                                                                                            Date
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">

                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.RequestDate" name="RequestDate" aria-describedby="emailHelp" placeholder="Enter Request Date">
                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>

                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                                        <label for="exampleInputEmail1">Backlog
                                                                                                            Item
                                                                                                            Category
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.BacklogItemCategory" name="BacklogItemCategory" aria-describedby="emailHelp" placeholder="Enter Backlog Item Category ">

                                                                                                    </div>

                                                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                                                        <label for="exampleInputEmail1">Billed
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown [options]="yesNOArray" filter="true" name="Billed" optionlabel="label" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.Billed" placeholder="Choose Billed">
                                                                                                        </p-dropdown>
                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>

                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                                        <label for="exampleInputEmail1">Planned
                                                                                                            Hours
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.Planned" name="Planned" aria-describedby="emailHelp" placeholder="Enter Planned Hours ">

                                                                                                        <!-- <p-dropdown [options]="yesNOArray"
                                                                                                            filter="true"
                                                                                                            [ngModelOptions]="{standalone:true}" 
                                                                                                            [(ngModel)]="advanceSearch.Planned"
                                                                                                            name="Planned"
                                                                                                            optionlabel="label"
                                                                                                            placeholder="Choose Planned">
                                                                                                        </p-dropdown> -->
                                                                                                    </div>


                                                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                                                        <label for="exampleInputEmail1">Status
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown [options]="backlogStatus" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.Status" filter="true" name="Status" optionlabel="label" placeholder="Choose Status">
                                                                                                        </p-dropdown>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div class="col-lg-12  main-submit-button" style="
                                                                                                                       margin-left: -13%;">
                                                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="getFindTotalBacklog()">Find</button>&nbsp;

                                                                                            <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>

                                                                                        </div>

                                                                                    </div>


                                                                                    <div class="w3-card-4 classCard mt-2">
                                                                                        <header class="w3-container w3-blue">
                                                                                            <div class="header-tabs">
                                                                                                <h4 class=" common-styling h4Margin">
                                                                                                    Product Backlogs Item List

                                                                                                </h4>

                                                                                            </div>
                                                                                        </header>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="col-lg-12 col-12">
                                                                                                <div class="form-group">

                                                                                                    <div class="card own-account-table">
                                                                                                        <p-table [value]="TotalBacklogList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                            <ng-template pTemplate="header">
                                                                                                                <tr style="background: #0d3769;">
                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                    </div>
                                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                            </p-checkbox>
                                                                                                                            Category

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'400px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>


                                                                                                                </tr>

                                                                                                                <tr style="background: #0d3769;">
                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                                        <div class="checkbox-align" style="color: white;padding-left: 25px !important;">
                                                                                                                            <!-- <p-checkbox _ngcontent-egj-c329=""
                                                                                                                                styleclass="custom-checkbox" binary="true"
                                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                <div
                                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                    <div
                                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                                        <input type="checkbox"
                                                                                                                                            name="undefined"
                                                                                                                                            value="undefined">
                                                                                                                                    </div>
                                                                                                                                    <div role="checkbox"
                                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                        <span
                                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                              
                                                                                                                            </p-checkbox> -->
                                                                                                                            Seq

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'400px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Product Backlog Item
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Status
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Category
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Planned Hours
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Billed
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Request Date
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Requester Name
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>


                                                                                                                </tr>
                                                                                                            </ng-template>
                                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                                <tr>
                                                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                                                        <div class="checkbox-align">
                                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                    </div>
                                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </p-checkbox>
                                                                                                                            <span  class="account-button"  >
                                                                                                                                {{product.totalBacklogs.custRequestId}}</span>

                                                                                                                        </div>
                                                                                                                    </td>

                                                                                                                    <td [ngStyle]="{'width':'400px'}">
                                                                                                                        {{product.totalBacklogs.description}}
                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                                                        {{product.totalBacklogStatus.statusId}}
                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                                                        {{product.totalBacklogs.parentCustRequestId}}
                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                                                        {{product.totalBacklogs.custEstimatedMilliSeconds}}
                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                                                        {{product.totalBacklogs.billed}}
                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                                        {{product.totalBacklogs.custRequestDate | date:'yyyy-MM-d hh:mm:ss'}}
                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                                        {{product.totalBacklogs.fromPartyId}}
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </ng-template>
                                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                <tr>
                                                                                                                    <td>
                                                                                                                        <span class="text-center">
                                                                                                                            <h4
                                                                                                                                class="ml-2">
                                                                                                                                No
                                                                                                                                Record
                                                                                                                                Found
                                                                                                                            </h4>
                                                                                                                        </span>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </ng-template>
                                                                                                        </p-table>

                                                                                                        <p class="paginate_data">
                                                                                                            View per page </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>





                                                                                </form>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div *ngIf="activeCategory==5">

                                                                <div class=" bg-white color-grey">
                                                                    <div class="create-new-leade mt-2">
                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                <form class="w-100">
                                                                                    <div class="container-fluid">
                                                                                        <ul class=" tabbing-accordians " style="width: 103% !important;
                                                                        flex-wrap: wrap !important;
                                                                        display: flex !important;
                                                                        padding: 10px !important;">

                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalNewProject">
                                                                                                New
                                                                                                Project
                                                                                            </button>

                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="closeProduct()">
                                                                                                Close
                                                                                                Product
                                                                                            </button>

                                                                                        </ul>
                                                                                    </div>

                                                                                    <div class="w3-card-4 classCard">
                                                                                        <div class="container-fluid">
                                                                                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                                                            flex-wrap: wrap !important;
                                                                            display: flex !important;">
                                                                                                <li><a>
                                                                                                        Add New Product
                                                                                                        Backlog Item

                                                                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#editBacklogPopUP">Create New New Product Backlog Item</button>



                                                                                                    </a>
                                                                                                </li>

                                                                                            </ul>
                                                                                        </div>




                                                                                        <div class="w3-card-4 classCard mt-2" *ngIf="showUnplanned">
                                                                                            <header class="w3-container w3-blue">
                                                                                                <div class="header-tabs">
                                                                                                    <h4 class=" common-styling h4Margin">
                                                                                                        Unplanned Sprint

                                                                                                    </h4>

                                                                                                </div>
                                                                                            </header>
                                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                <div class="col-lg-12 col-12">

                                                                                                    <div class="form-group table-responsive">
                                                                                                        <table class="table align-middle">
                                                                                                            <thead>
                                                                                                                <tr class="table-rows" style="background: #0d3769;height: 12px !important;">
                                                                                                                    <th style="color: white;font-size: 12px;min-width: 185px;padding: 14px 20px !important;">
                                                                                                                        Seq

                                                                                                                    </th>
                                                                                                                    <th style="color: white;font-size: 12px;min-width: 200px;padding: 14px 20px !important;">
                                                                                                                        Backlog ID

                                                                                                                    </th>

                                                                                                                    <th style="color: white;font-size: 12px;min-width: 170px;padding: 14px 20px !important;">
                                                                                                                        Product Backlog Item

                                                                                                                    </th>

                                                                                                                    <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                                        New Sequence
                                                                                                                    </th>

                                                                                                                    <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                                        Planned Hours

                                                                                                                    </th>

                                                                                                                    <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                                        Category

                                                                                                                    </th>

                                                                                                                    <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                                        Move to unplanned sprint

                                                                                                                    </th>
                                                                                                                    <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">


                                                                                                                    </th>
                                                                                                                    <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                                        Delete

                                                                                                                    </th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>

                                                                                                                <tr class="align-bottom" *ngFor="let pro of EditBacklogsProductItemList;let i = index;" style="height: 11px !important;">
                                                                                                                    <td style="    color: black;
                                                                                            font-weight: 100;
                                                                                            font-size: 12px;
                                                                                          
                                                                                          ">

                                                                                                                        {{i+1}}

                                                                                                                    </td>
                                                                                                                    <td class="dropdown-table" style="    color: black;
                                                                                                  font-weight: 100;
                                                                                                  font-size: 12px;
                                                                                                  min-width: 155px;">
                                                                                                                        {{pro.custRequestId}}

                                                                                                                    </td>

                                                                                                                    <td class="dropdown-table" style="min-width: 170px;">
                                                                                                                        {{pro.description}}


                                                                                                                    </td>
                                                                                                                    <td class="dropdown-table" style="min-width: 155px;">


                                                                                                                    </td>

                                                                                                                    <td style="min-width: 155px;">

                                                                                                                        <input [(ngModel)]="pro.custEstimatedMilliSeconds" type="text" [ngModelOptions]="{standalone:true}" class="form-control">
                                                                                                                    </td>


                                                                                                                    <td class="dropdown-table" style="min-width: 155px;">
                                                                                                                        <p-dropdown filter="true" [options]="BacklogItemCategoryArray" formControlName="BacklogItemCategory" optionlabel="label" placeholder="Enter Backlog Item Category">
                                                                                                                        </p-dropdown>

                                                                                                                    </td>

                                                                                                                    <td style="color: white;font-size: 12px;min-width: 155px;">
                                                                                                                        <button type="button" class="btn btn-secondary ml-2" (click)="moveToUnplannedSprint()">Move to unplanned sprint</button>
                                                                                                                    </td>

                                                                                                                    <td style="color: white;font-size: 12px;min-width: 76px;">
                                                                                                                        <button type="button" class="btn btn-secondary ml-2" (click)="productBackLogsItemSubmit(pro)">Update</button>
                                                                                                                    </td>


                                                                                                                    <td style="color: white;font-size: 12px;min-width: 155px;">
                                                                                                                        <button type="button" class="btn btn-danger ml-2" (click)="detailEditBacklogPage(pro.custRequestId,this.productId)">Delete</button>
                                                                                                                    </td>

                                                                                                                </tr>

                                                                                                            </tbody>
                                                                                                        </table>


                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>



                                                                                        <div class="w3-card-4 classCardC mt-2">
                                                                                            <header class="w3-container w3-blue">
                                                                                                <div class="header-tabs">
                                                                                                    <h4 class=" common-styling h4Margin">
                                                                                                        Product Backlogs Item List

                                                                                                    </h4>

                                                                                                </div>
                                                                                            </header>
                                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                <div class="col-lg-12 col-12">

                                                                                                    <div class="form-group table-responsive">
                                                                                                        <table class="table align-middle">
                                                                                                            <thead>
                                                                                                                <tr class="table-rows" style="background: #0d3769;height: 12px !important;">
                                                                                                                    <th style="color: white;font-size: 12px;min-width: 185px;padding: 14px 20px !important;">
                                                                                                                        Seq

                                                                                                                    </th>
                                                                                                                    <th style="color: white;font-size: 12px;min-width: 200px;padding: 14px 20px !important;">
                                                                                                                        Backlog ID

                                                                                                                    </th>

                                                                                                                    <th style="color: white;font-size: 12px;min-width: 170px;padding: 14px 20px !important;">
                                                                                                                        Product Backlog Item

                                                                                                                    </th>

                                                                                                                    <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                                        New Sequence
                                                                                                                    </th>

                                                                                                                    <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                                        Planned Hours

                                                                                                                    </th>

                                                                                                                    <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                                        Category

                                                                                                                    </th>

                                                                                                                    <td style="color: white;font-size: 12px;min-width: 76px;">

                                                                                                                    </td>

                                                                                                                    <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                                        Delete
                                                                                                                    </th>


                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>

                                                                                                                <tr class="align-bottom" *ngFor="let pro of EditBacklogsProductItemList;let i = index;" style="height: 11px !important;">
                                                                                                                    <td style="    color: black;
                                                                                            font-weight: 100;
                                                                                            font-size: 12px;
                                                                                          ">
                                                                                                                        {{i+1}}

                                                                                                                    </td>
                                                                                                                    <td class="dropdown-table" style="    color: black;
                                                                                                  font-weight: 100;
                                                                                                  font-size: 12px;
                                                                                                  min-width: 155px;">
                                                                                                                        {{pro.custRequestId}}

                                                                                                                    </td>

                                                                                                                    <td class="dropdown-table" style="min-width: 170px;">
                                                                                                                        {{pro.description}}


                                                                                                                    </td>
                                                                                                                    <td class="dropdown-table" style="min-width: 155px;">


                                                                                                                    </td>

                                                                                                                    <td style="min-width: 155px;">

                                                                                                                        <input [(ngModel)]="pro.custEstimatedMilliSeconds" type="text" [ngModelOptions]="{standalone:true}" class="form-control">
                                                                                                                    </td>


                                                                                                                    <td class="dropdown-table" style="min-width: 155px;">
                                                                                                                        <p-dropdown filter="true" [options]="BacklogItemCategoryArray" formControlName="BacklogItemCategory" optionlabel="label" placeholder="Enter Backlog Item Category">
                                                                                                                        </p-dropdown>

                                                                                                                    </td>

                                                                                                                    <td style="color: white;font-size: 12px;min-width: 76px;">
                                                                                                                        <button type="button" class="btn btn-secondary ml-2" (click)="productBackLogsItemSubmit(pro)">Update</button>
                                                                                                                    </td>


                                                                                                                    <td style="color: white;font-size: 12px;min-width: 76px;">
                                                                                                                        <button type="button" class="btn btn-danger ml-2" (click)="detailEditBacklogPage(pro.custRequestId,this.productId)">Delete</button>
                                                                                                                    </td>


                                                                                                                </tr>

                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>





                                                                                </form>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div *ngIf="activeCategory==6">

                                                                <div class=" bg-white color-grey">
                                                                    <div class="create-new-leade mt-2">
                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                <form class="w-100">
                                                                                    <div class="container-fluid">
                                                                                        <ul class=" tabbing-accordians " style="width: 103% !important;
                                                                        flex-wrap: wrap !important;
                                                                        display: flex !important;
                                                                        padding: 10px !important;">

                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalNewProject">
                                                                                                New
                                                                                                Project
                                                                                            </button>

                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="closeProduct()">
                                                                                                Close
                                                                                                Product
                                                                                            </button>


                                                                                        </ul>
                                                                                    </div>
                                                                                    <div class="w3-card-4 classCard">
                                                                                        <div class="container-fluid">
                                                                                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                                                            flex-wrap: wrap !important;
                                                                            display: flex !important;">
                                                                                                <li><a>
                                                                                                        Find Time Entry
                                                                                                        Not yet Billed



                                                                                                    </a>
                                                                                                </li>

                                                                                            </ul>
                                                                                        </div>


                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                    <div class="col-lg-3 form-group classInputAB">
                                                                                                        <label for="exampleInputEmail1">From
                                                                                                            Date
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-3">
                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchA.FromDate" name="FromDate" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                                                                    </div>

                                                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                                                        <label for="exampleInputEmail1">Through
                                                                                                            Date
                                                                                                        </label>
                                                                                                    </div>

                                                                                                    <div class="col-lg-3">
                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" name="ThroughDate" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearchA.ThroughDate" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>

                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                    
                                                                                                    

                                                                                                    <div class="col-lg-3 form-group classInputAB"> 
                                                                                                        <label for="exampleInputEmail1">Include
                                                                                                            Meeting
                                                                                                        </label>
                                                                                                    </div>

                                                                                                    <div class="col-lg-3">

                                                                                                        <p-dropdown filter="true" [options]="yesNOArray" name="IncludeMeeting" optionlabel="label" placeholder="Choose Include Meeting">
                                                                                                        </p-dropdown>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>

         
                                                                                        </div>
                                                                                        <div class="col-lg-12  main-submit-button" style="margin-left: -7%;">
                                                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="getFindBilling()">Find</button>&nbsp;

                                                                                            <button type="button" class="btn btn-danger ml-2" (click)="resetBilling()">Reset</button>

                                                                                        </div>

                                                                                    </div>

                                                                                    <div class="w3-card-4 classCard mt-2">
                                                                                        <header class="w3-container w3-blue">
                                                                                            <div class="header-tabs">
                                                                                                <h4 class=" common-styling h4Margin">

                                                                                                    Hours not yet billed Information :
                                                                                                    <span></span> Bill From Party
                                                                                                    <span></span> Bill To Party
                                                                                                    <span></span> FromDate
                                                                                                    <span></span> Through Date
                                                                                                    <span></span>



                                                                                                </h4>

                                                                                            </div>
                                                                                        </header>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="col-lg-12 col-12">
                                                                                                <div class="form-group">

                                                                                                    <div class="card own-account-table">
                                                                                                        <p-table [value]="billingList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                            <ng-template pTemplate="header">
                                                                                                                <tr style="background: #0d3769;">
                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                    </div>
                                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </p-checkbox>
                                                                                                                            Project

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Sprint
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                    <th [ngStyle]="{'width':'250px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Backlog Item
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Billed

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                </tr>

                                                                                                                <tr style="background: #0d3769;">
                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                    </div>
                                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </p-checkbox>
                                                                                                                            Task Name

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Hours
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                    <th [ngStyle]="{'width':'250px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Timesheet Party ID
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Timesheet ID
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Rate Type ID
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Date
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                </tr>
                                                                                                            </ng-template>
                                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                                <tr>
                                                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                                                        <div class="checkbox-align">
                                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                    </div>
                                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </p-checkbox>
                                                                                                                            <span  class="account-button"  >
                                                                                                                                {{product.timeEntryBilled.workEffortId}}</span>

                                                                                                                        </div>
                                                                                                                    </td>

                                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                                        {{product.timeEntryBilled.hours}}
                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                                        {{product.timeEntryBilled.partyId}}
                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'170px'}">

                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                                                        {{product.timeEntryBilled.timesheetId}}
                                                                                                                    </td>

                                                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                                                        {{product.timeEntryBilled.rateTypeId}}
                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                                                        {{product.fromDate}}
                                                                                                                    </td>

                                                                                                                </tr>
                                                                                                            </ng-template>
                                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                <tr>
                                                                                                                    <td>
                                                                                                                        <span class="text-center">
                                                                                                                            <h4
                                                                                                                                class="ml-2">
                                                                                                                                No
                                                                                                                                Record
                                                                                                                                Found
                                                                                                                            </h4>
                                                                                                                        </span>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </ng-template>
                                                                                                        </p-table>

                                                                                                        <p class="paginate_data">
                                                                                                            View per page </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="w3-card-4 classCard mt-2">
                                                                                        <div class="container-fluid">
                                                                                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                                                            flex-wrap: wrap !important;
                                                                            display: flex !important;">
                                                                                                <li><a>
                                                                                                        Add all
                                                                                                        reported/approved
                                                                                                        task time to a
                                                                                                        new invoice
                                                                                                    </a>
                                                                                                </li>

                                                                                            </ul>
                                                                                        </div>
                                                                                        <form [formGroup]="billingForm">


                                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">
                                                                                                        <div class="col-lg-3 form-group classInputA">
                                                                                                            <label for="exampleInputEmail1">Bill
                                                                                                                From
                                                                                                                Party
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-2">
                                                                                                            <span>{{this.billingInvoiceList.billFromParty}}</span>


                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>

                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">
                                                                                                        <div class="col-lg-3 form-group classInputA">
                                                                                                            <label for="exampleInputEmail1">Bill
                                                                                                                To Party
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-2">
                                                                                                            <span>{{this.billingInvoiceList.billToParty}}</span>


                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">
                                                                                                        <div class="col-lg-3 form-group classInputA">
                                                                                                            <label for="exampleInputEmail1">From
                                                                                                                Date
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-2">
                                                                                                            <span>{{this.billingInvoiceList.productRoles.fromDate|date:'yyyy-MM-dd
                                                                                                                hh:mm:ss'}}</span>



                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>

                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">
                                                                                                        <div class="col-lg-3 form-group classInputA">
                                                                                                            <label for="exampleInputEmail1">Through
                                                                                                                Date
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-2">
                                                                                                            <span>{{this.billingInvoiceList.productRoles.thruDate|date:'yyyy-MM-dd           hh:mm:ss'}}</span>


                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>

                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">
                                                                                                        <div class="col-lg-3 form-group classInputA">
                                                                                                            <label for="exampleInputEmail1">Include
                                                                                                                Meeting
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-2">
                                                                                                            <p-dropdown [options]="yesNOArray" [(ngModel)]="this.billingInvoiceList.IncludeMeeting" [ngModelOptions]="{standalone:true}" filter="true" formControlName="IncludeMeeting" optionlabel="label" >
                                                                                                            </p-dropdown>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>




                                                                                            </div>
                                                                                            <div class="col-lg-12  main-submit-button" style="
                                                                                                                       margin-left: -65%;">
                                                                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="addProductTimeToNewInvoice()">Add
                                                                                                    all
                                                                                                    reported/approved
                                                                                                    task time to
                                                                                                    a new
                                                                                                    invoice</button>&nbsp;


                                                                                            </div>
                                                                                        </form>

                                                                                    </div>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div *ngIf="activeCategory==7">
                                                                <div class=" bg-white color-grey">
                                                                    <div class="create-new-leade mt-2">
                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                <form class="w-100">
                                                                                    <div class="w3-card-4 ">
                                                                                        <button type="submit" data-toggle="modal" data-target="#exampleModalNewProject" class="btn btn-secondary submit-btn">New
                                                                                            project</button>
                                                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalCreateDefaultTask">Create
                                                                                            New Default Task
                                                                                        </button>
                                                                                        <button type="submit" (click)="closeProduct()" class="btn btn-secondary submit-btn ml-2">Close
                                                                                            Product</button>
                                                                                    </div>

                                                                                    <br>
                                                                                    <div class="create-new-leade mt-2">
                                                                                        <div class="container-fluid">
                                                                                            <div class="row">

                                                                                                <form class="w-100">

                                                                                                    <div>
                                                                                                        <div class="w3-card-4 classCard">
                                                                                                            <header class="w3-container w3-blue">
                                                                                                                <div class="header-tabs">
                                                                                                                    <h4 class=" common-styling h4Margin">
                                                                                                                        Default Tasks List
                                                                                                                    </h4>

                                                                                                                </div>
                                                                                                            </header>
                                                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                                <div class="col-lg-12 col-12">
                                                                                                                    <div class="form-group">

                                                                                                                        <div class="card own-account-table">
                                                                                                                            <p-table [value]="TaskList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">

                                                                                                                                <ng-template pTemplate="header">
                                                                                                                                    <tr style="background: #0d3769;">
                                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                                        </div>
                                                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </p-checkbox>

                                                                                                                                                Task Name
                                                                                                                                            </div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </th>
                                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                            <div style="color: white;">
                                                                                                                                                Type

                                                                                                                                            </div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </th>
                                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                            <div style="color: white;">
                                                                                                                                                Created Date

                                                                                                                                            </div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </th>
                                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                            <div style="color: white;">
                                                                                                                                                Assigned To
                                                                                                                                            </div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </th>
                                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                            <div style="color: white;">
                                                                                                                                                Remove
                                                                                                                                            </div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </th>






                                                                                                                                    </tr>

                                                                                                                                </ng-template>
                                                                                                                                <ng-template pTemplate="body" let-product>
                                                                                                                                    <tr>
                                                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                                                            <div class="checkbox-align">
                                                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                                        </div>
                                                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </p-checkbox>
                                                                                                                                                <span (click)="detailEditPage(product.workEffortId,this.productId)" style="color: #0d3769;">{{product.workEffortName}}</span>

                                                                                                                                            </div>
                                                                                                                                        </td>
                                                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                                                            {{product.workEffortTypeId}}
                                                                                                                                        </td>
                                                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                                                            {{product.createdDate|date:'yyyy-MM-d hh:mm:ss'}}
                                                                                                                                        </td>
                                                                                                                                        <td [ngStyle]="{'width':'170px'}">

                                                                                                                                        </td>
                                                                                                                                        <td [ngStyle]="{'width':'180px'}">

                                                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteDefaultTask(product.custRequestId,this.productId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                                                                                <defs>
                                                                                                                                                    <style>
                                                                                                                                                        .a {
                                                                                                                                                            fill: #f44336;
                                                                                                                                                        }

                                                                                                                                                        .b {
                                                                                                                                                            fill: #fafafa;
                                                                                                                                                            margin-left: 20px;
                                                                                                                                                        }
                                                                                                                                                    </style>
                                                                                                                                                </defs>
                                                                                                                                                <path
                                                                                                                                                    class="a fill-color"
                                                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                                                <path
                                                                                                                                                    class="b"
                                                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                                                            </svg>
                                                                                                                                        </td>




                                                                                                                                    </tr>
                                                                                                                                </ng-template>
                                                                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                                    <tr>
                                                                                                                                        <td>
                                                                                                                                            <span class="text-center">
                                                                                                                                                <h4
                                                                                                                                                    class="ml-2">
                                                                                                                                                    No
                                                                                                                                                    Record
                                                                                                                                                    Found
                                                                                                                                                </h4>
                                                                                                                                            </span>
                                                                                                                                        </td>
                                                                                                                                    </tr>
                                                                                                                                </ng-template>
                                                                                                                            </p-table>

                                                                                                                            <p class="paginate_data">
                                                                                                                                View per page
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </form>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div *ngIf="activeCategory==8">
                                                                <div class=" bg-white color-grey">

                                                                    <div class="create-new-leade mt-2">
                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                <form class="w-100">
                                                                                    <div class="">
                                                                                        <button type="submit" data-toggle="modal" data-target="#exampleModalNewProject" class="btn btn-secondary submit-btn">New
                                                                                            project</button>
                                                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalCategory">Create
                                                                                            New Category
                                                                                        </button>
                                                                                        <button type="submit" (click)="closeProduct()" class="btn btn-secondary submit-btn ml-2">Close
                                                                                            Product</button>
                                                                                    </div>

                                                                                    <br>
                                                                                    <div class="create-new-leade mt-2">
                                                                                        <div class="container-fluid">
                                                                                            <div class="row">

                                                                                                <form class="w-100">

                                                                                                    <div>
                                                                                                        <div class="w3-card-4 classCard">
                                                                                                            <header class="w3-container w3-blue">
                                                                                                                <div class="header-tabs">
                                                                                                                    <h4 class=" common-styling h4Margin">
                                                                                                                        Product Backlog Categories
                                                                                                                    </h4>

                                                                                                                </div>
                                                                                                            </header>
                                                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                                <div class="col-lg-12 col-12">
                                                                                                                    <div class="form-group">

                                                                                                                        <div class="card own-account-table">
                                                                                                                            <p-table [value]="CategoryList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                                                <ng-template pTemplate="header">
                                                                                                                                    <tr style="background: #0d3769;">
                                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                                        </div>
                                                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </p-checkbox>
                                                                                                                                                Category Task Name
                                                                                                                                            </div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </th>
                                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                            <div style="color: white;">
                                                                                                                                                Open

                                                                                                                                            </div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </th>
                                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                            <div style="color: white;">
                                                                                                                                                Being Implemented

                                                                                                                                            </div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </th>
                                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                            <div style="color: white;">
                                                                                                                                                Finished
                                                                                                                                            </div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </th>



                                                                                                                                    </tr>


                                                                                                                                </ng-template>
                                                                                                                                <ng-template pTemplate="body" let-product>
                                                                                                                                    <tr>
                                                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                                                            <div class="checkbox-align">
                                                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                                        </div>
                                                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </p-checkbox>
                                                                                                                                                <span (click)="detailCategoryEditPage(product.custRequestId,this.productId)">{{product.custRequestName}}</span>

                                                                                                                                            </div>
                                                                                                                                        </td>
                                                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                                                            {{product.open}}
                                                                                                                                        </td>
                                                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                                                            {{product.Being_Implemented}}
                                                                                                                                        </td>
                                                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                                                            {{product.Finishaed}}
                                                                                                                                        </td>



                                                                                                                                    </tr>
                                                                                                                                </ng-template>
                                                                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                                    <tr>
                                                                                                                                        <td>
                                                                                                                                            <span class="text-center">
                                                                                                                                                <h4
                                                                                                                                                    class="ml-2">
                                                                                                                                                    No
                                                                                                                                                    Record
                                                                                                                                                    Found
                                                                                                                                                </h4>
                                                                                                                                            </span>
                                                                                                                                        </td>
                                                                                                                                    </tr>
                                                                                                                                </ng-template>
                                                                                                                            </p-table>

                                                                                                                            <p class="paginate_data">
                                                                                                                                View per page
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </form>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div *ngIf="activeCategory==9">
                                                                <ul class=" tabbing-accordians " style="width: 103% !important;
                                                                flex-wrap: wrap !important;
                                                                display: flex !important;
                                                                padding: 10px !important;">
                                                                    <li>

                                                                        <button type="submit" data-toggle="modal" data-target="#exampleModalNewProject" class="btn btn-secondary submit-btn">New
                                                                            project</button>

                                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="closeProduct()">
                                                                            Close
                                                                            Product
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                                <div class=" bg-white color-grey">
                                                                    <div class="create-new-leade mt-2">
                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                <form class="w-100">
                                                                                    <div class="w3-card-4 classCard mt-2">
                                                                                        <header class="w3-container w3-blue">
                                                                                            <div class="header-tabs">
                                                                                                <h4 class=" common-styling h4Margin">
                                                                                                    Product Content List
                                                                                                    <button type="submit" data-toggle="modal" data-target="#contentPopUP" class="btn btn-secondary submit-btn">
                                                                                                        Create New Contant</button>
                                                                                                </h4>

                                                                                            </div>
                                                                                        </header>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="col-lg-12 col-12">
                                                                                                <div class="form-group">

                                                                                                    <div class="card own-account-table">
                                                                                                        <p-table [value]="ContentList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                            <ng-template pTemplate="header">
                                                                                                                <tr style="background: #0d3769;">
                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                    </div>
                                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </p-checkbox>
                                                                                                                            Content Type ID

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Content Name
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            From Date
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>




                                                                                                                </tr>
                                                                                                            </ng-template>
                                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                                <tr>
                                                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                                                        <div class="checkbox-align">
                                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                    </div>
                                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </p-checkbox>
                                                                                                                            <span  class="account-button"  >
                                                                                                                                {{product.contentTypeId}}</span>

                                                                                                                        </div>
                                                                                                                    </td>

                                                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                                                        {{product.imageURL}}
                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                                                        {{product.createdStamp |date:'yyyy-MM-dd hh:mm:ss'}}

                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                                                        <button (click)="deleteScrumProductContent(product.contentId)" type="submit" class="btn btn-secondary submit-btn ml-2">
                                                                                                                            Delete
                                                                                                                        </button>


                                                                                                                        <button (click)="DownloadImageProductContent(product.imageURL)" type="submit" class="btn btn-secondary submit-btn ml-2">
                                                                                                                            Download
                                                                                                                        </button>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </ng-template>
                                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                <tr>
                                                                                                                    <td>
                                                                                                                        <span class="text-center">
                                                                                                                            <h4
                                                                                                                                class="ml-2">
                                                                                                                                No
                                                                                                                                Record
                                                                                                                                Found
                                                                                                                            </h4>
                                                                                                                        </span>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </ng-template>
                                                                                                        </p-table>

                                                                                                        <p class="paginate_data">
                                                                                                            View per page </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    
                                                                                </form>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div *ngIf="activeCategory==10">
                                                                <div class=" bg-white color-grey">

                                                                    <div class="create-new-leade mt-2">
                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                <form class="w-100">
                                                                                    <div>
                                                                                        <button type="submit" data-toggle="modal" data-target="#exampleModalNewProject" class="btn btn-secondary submit-btn">New
                                                                                            project</button>
                                                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="closeProduct()">Close
                                                                                            Product
                                                                                        </button>
                                                                                        <button type="submit" (click)="newEmailPage()" class="btn btn-secondary submit-btn ml-2">New
                                                                                            Email</button>
                                                                                    </div>

                                                                                    <br>
                                                                                    <div class="create-new-leade mt-2">
                                                                                        <div class="container-fluid">
                                                                                            <div class="row">

                                                                                                <form class="w-100">

                                                                                                    <div>
                                                                                                        <div class="w3-card-4 classCard">
                                                                                                            <header class="w3-container w3-blue">
                                                                                                                <div class="header-tabs">
                                                                                                                    <h4 class=" common-styling h4Margin">
                                                                                                                        Emails
                                                                                                                    </h4>

                                                                                                                </div>
                                                                                                            </header>
                                                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                                <div class="col-lg-12 col-12">
                                                                                                                    <div class="form-group">

                                                                                                                        <div class="card own-account-table">
                                                                                                                            <p-table [value]="CommunicationList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                                                <ng-template pTemplate="header">
                                                                                                                                    <tr style="background: #0d3769;">
                                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                                        </div>
                                                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </p-checkbox>
                                                                                                                                                Subject Task Name
                                                                                                                                            </div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </th>
                                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                            <div style="color: white;">
                                                                                                                                                Status ID

                                                                                                                                            </div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </th>
                                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                            <div style="color: white;">
                                                                                                                                                From

                                                                                                                                            </div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </th>
                                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                            <div style="color: white;">
                                                                                                                                                To
                                                                                                                                            </div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </th>

                                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                            <div style="color: white;">
                                                                                                                                                Created
                                                                                                                                            </div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </th>

                                                                                                                                    </tr>


                                                                                                                                </ng-template>
                                                                                                                                <ng-template pTemplate="body" let-product>
                                                                                                                                    <tr>
                                                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                                                            <div class="checkbox-align">
                                                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                                        </div>
                                                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <!---->
                                                                                                                                                </p-checkbox>
                                                                                                                                                <span (click)="detailedCommunicationPage(this.productId,product.communicationEventId)" style="color: #0d3769;">{{product.subject}}</span>

                                                                                                                                            </div>
                                                                                                                                        </td>
                                                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                                                            {{product.statusId}}
                                                                                                                                        </td>
                                                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                                                            {{product.partyIdFrom}}
                                                                                                                                        </td>
                                                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                                                            {{product.partyIdTo}}
                                                                                                                                        </td>
                                                                                                                                        <td [ngStyle]="{'width':'170px'}">

                                                                                                                                            {{product.entryDate | date: 'yyyy-MM-dd hh:mm:ss'}}
                                                                                                                                        </td>
                                                                                                                                        <!-- <td [ngStyle]="{'width':'180px'}">
                                                
                                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                                            class="ml-20" width="16" height="16"
                                                                                                                                            viewBox="0 0 26 26">
                                                                                                                                            <defs>
                                                                                                                                                <style>
                                                                                                                                                    .a {
                                                                                                                                                        fill: #f44336;
                                                                                                                                                    }
                                                                    
                                                                                                                                                    .b {
                                                                                                                                                        fill: #fafafa;
                                                                                                                                                        margin-left: 20px;
                                                                                                                                                    }
                                                                                                                                                </style>
                                                                                                                                            </defs>
                                                                                                                                            <path class="a fill-color"
                                                                                                                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                                            <path class="b"
                                                                                                                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                                                transform="translate(-147.576 -147.576)" />
                                                                                                                                        </svg>
                                                                                                                                    </td> -->

                                                                                                                                        <!-- <td [ngStyle]="{'width':'170px'}">
                                                                                                                                        <svg class="hover"
                                                                                                                                            (click)="updateEvent(product.workEffortId)"
                                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                                            width="16" height="16"
                                                                                                                                            viewBox="0 0 21.223 21.222">
                                                                                                                                            <g
                                                                                                                                                transform="translate(0 -0.004)">
                                                                                                                                                <g
                                                                                                                                                    transform="translate(0 1.52)">
                                                                                                                                                    <path
                                                                                                                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                                                        transform="translate(0 -34.137)" />
                                                                                                                                                </g>
                                                                                                                                                <g
                                                                                                                                                    transform="translate(4.548 0.004)">
                                                                                                                                                    <g
                                                                                                                                                        transform="translate(0 0)">
                                                                                                                                                        <path
                                                                                                                                                            d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                                            transform="translate(-102.409 -0.004)" />
                                                                                                                                                    </g>
                                                                                                                                                </g>
                                                                                                                                            </g>
                                                                                                                                        </svg>
                                                                                                                                    </td> -->
                                                                                                                                        <!-- <svg (click)="onDelete(product.workEffortId)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26"><defs><style>.a{fill:#f44336;}.b{fill:#fafafa; margin-left: 20px;}</style></defs><path class="a fill-color" d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0"/><path class="b" d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0" transform="translate(-147.576 -147.576)"/></svg> -->


                                                                                                                                    </tr>
                                                                                                                                </ng-template>
                                                                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                                    <tr>
                                                                                                                                        <td>
                                                                                                                                            <span class="text-center">
                                                                                                                                                <h4
                                                                                                                                                    class="ml-2">
                                                                                                                                                    No
                                                                                                                                                    Record
                                                                                                                                                    Found
                                                                                                                                                </h4>
                                                                                                                                            </span>
                                                                                                                                        </td>
                                                                                                                                    </tr>
                                                                                                                                </ng-template>
                                                                                                                            </p-table>

                                                                                                                            <p class="paginate_data">
                                                                                                                                View per page
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </form>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div *ngIf="activeCategory==11">

                                                                <div class=" bg-white color-grey">
                                                                    <div class="create-new-leade mt-2">
                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                <form class="w-100">
                                                                                    <div class="container-fluid">
                                                                                        <ul class=" tabbing-accordians " style="width: 103% !important;
                                                                        flex-wrap: wrap !important;
                                                                        display: flex !important;
                                                                        padding: 10px !important;">


                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalNewProject">
                                                                                                New
                                                                                                Project
                                                                                            </button>

                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="closeProduct()">
                                                                                                Close
                                                                                                Product
                                                                                            </button>


                                                                                        </ul>
                                                                                    </div>

                                                                                    <div class="w3-card-4 classCard mt-2">
                                                                                        <div class="container-fluid">
                                                                                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                                                        flex-wrap: wrap !important;
                                                                        display: flex !important;">
                                                                                                <li><a>
                                                                                                        Edit/Delete
                                                                                                        Backlog Item
                                                                                                    </a>
                                                                                                </li>

                                                                                            </ul>
                                                                                        </div>
                                                                                        <form [formGroup]="editDetailForm">


                                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">
                                                                                                        <div class="col-lg-3 form-group classInput" style="margin-left: -15%;">
                                                                                                            <label for="exampleInputEmail1">Reason

                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-2">

                                                                                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Reason" aria-describedby="emailHelp" placeholder="Enter Reason">
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>



                                                                                            </div>
                                                                                            <div class="col-lg-12  main-submit-button" style="
                                                                                                                   margin-left: -74%;">
                                                                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="deleteBacklogItem()">Save
                                                                                                </button>


                                                                                            </div>
                                                                                        </form>

                                                                                    </div>


                                                                                    <div class="w3-card-4 classCard mt-2">
                                                                                        <div class="container-fluid">
                                                                                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                                                            flex-wrap: wrap !important;
                                                                            display: flex !important;">
                                                                                                <li><a>
                                                                                                        Product Backlog
                                                                                                        Item Info
                                                                                                    </a>
                                                                                                </li>

                                                                                            </ul>
                                                                                        </div>


                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                                        <label for="exampleInputEmail1">Product
                                                                                                            Name

                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input disabled type="email" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="this.EditDetailBacklogs.productName" name="FromDate" aria-describedby="emailHelp" placeholder="Enter Product Name">
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>

                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                                        <label for="exampleInputEmail1">Company

                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input disabled type="email" class="form-control" id="exampleInputEmail1" name="ThroughDate" [ngModelOptions]="{standalone:true}" [(ngModel)]="this.EditDetailBacklogs.Company" aria-describedby="emailHelp" placeholder="Enter Company">
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>

                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                                        <label for="exampleInputEmail1">Product
                                                                                                            Owner

                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input disabled type="email" class="form-control" id="exampleInputEmail1" name="ThroughDate" [ngModelOptions]="{standalone:true}" [(ngModel)]="this.EditDetailBacklogs.productOwner" aria-describedby="emailHelp" placeholder="Enter Product Owner">
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                                        <label for="exampleInputEmail1">Request
                                                                                                            name

                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input disabled type="email" class="form-control" id="exampleInputEmail1" name="ThroughDate" aria-describedby="emailHelp" placeholder="Enter Request name">
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                                        <label for="exampleInputEmail1">Product
                                                                                                            Backlog Item

                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input disabled type="email" class="form-control" id="exampleInputEmail1" name="ThroughDate" aria-describedby="emailHelp" placeholder="Enter Product Backlog Item">
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                                        <label for="exampleInputEmail1">Backlog
                                                                                                            Item
                                                                                                            Category

                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input disabled type="email" class="form-control" id="exampleInputEmail1" name="ThroughDate" aria-describedby="emailHelp" >
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                                        <label for="exampleInputEmail1">Plan
                                                                                                            Time

                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" name="ThroughDate" disabled [ngModelOptions]="{standalone:true}" [(ngModel)]="this.EditDetailBacklogs.hours" aria-describedby="emailHelp" placeholder="Enter Plan Time">
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                                        <label for="exampleInputEmail1">Actual
                                                                                                            Hours

                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" name="ThroughDate" disabled [ngModelOptions]="{standalone:true}" [(ngModel)]="this.EditDetailBacklogs.ActualHours" aria-describedby="emailHelp" placeholder="Enter Actual Hours">
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                                        <label for="exampleInputEmail1">Cust
                                                                                                            Sequence Num

                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" name="ThroughDate" disabled aria-describedby="emailHelp" >
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                                        <label for="exampleInputEmail1">Billed

                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" name="ThroughDate" disabled aria-describedby="emailHelp" >
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>

                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                                        <label for="exampleInputEmail1">Unplanned
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" name="ThroughDate" disabled [ngModelOptions]="{standalone:true}" [(ngModel)]="this.EditDetailBacklogs.Unplanned" aria-describedby="emailHelp" placeholder="Enter Unplanned">
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>

                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                                        <label for="exampleInputEmail1">Status

                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" name="ThroughDate" disabled aria-describedby="emailHelp" >
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                                        <label for="exampleInputEmail1">Story

                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" name="ThroughDate" disabled [ngModelOptions]="{standalone:true}" [(ngModel)]="this.EditDetailBacklogs.Story" aria-describedby="emailHelp" placeholder="Enter Story">
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                                        <label for="exampleInputEmail1">How
                                                                                                            to test

                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" name="ThroughDate" disabled [ngModelOptions]="{standalone:true}" [(ngModel)]="this.EditDetailBacklogs.Howtotest" aria-describedby="emailHelp" placeholder="Enter How to test">
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>


                                                                                    </div>




                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div *ngIf="activeCategory==12">

                                                                <div class=" bg-white color-grey">
                                                                    <div class="create-new-leade mt-2">
                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                <form class="w-100">
                                                                                    <div class="container-fluid">
                                                                                        <ul class=" tabbing-accordians " style="width: 103% !important;
                                                                        flex-wrap: wrap !important;
                                                                        display: flex !important;
                                                                        padding: 10px !important;">

                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalNewProject">
                                                                                               Create New
                                                                                                Project
                                                                                            </button>

                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalCreateDefaultTask">
                                                                                                Create A Default Task


                                                                                            </button>

                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="closeProduct()">
                                                                                                Close
                                                                                                Product
                                                                                            </button>


                                                                                        </ul>
                                                                                    </div>



                                                                                    <div class="w3-card-4 classCard mt-2">
                                                                                        <div class="container-fluid">
                                                                                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                                                            flex-wrap: wrap !important;
                                                                            display: flex !important;">
                                                                                                <li><a>
                                                                                                        Edit Default
                                                                                                        Task
                                                                                                        DEFAULT-TASK-1
                                                                                                    </a>
                                                                                                </li>

                                                                                            </ul>
                                                                                        </div>
                                                                                        <form [formGroup]="defaultTaskEditForm">



                                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">
                                                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                                                            <label for="exampleInputEmail1">Task
                                                                                                                Name

                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-2">

                                                                                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="TaskName" aria-describedby="emailHelp" placeholder="Enter Task Name">
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>


                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">
                                                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                                                            <label for="exampleInputEmail1">Product


                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-2">
                                                                                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Product" aria-describedby="emailHelp" placeholder="Enter Product ">
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>


                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">


                                                                                                        <div class="col-lg-3 form-group classInputA">
                                                                                                            <label for="exampleInputEmail1">Type


                                                                                                            </label>

                                                                                                        </div>

                                                                                                        <div class="col-lg-2">

                                                                                                            <p-dropdown filter="true" [options]="partyIdArray" formControlName="Type" optionlabel="label" placeholder="Enter Type">
                                                                                                            </p-dropdown>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">
                                                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                                                            <label for="exampleInputEmail1">Initial
                                                                                                                Planned
                                                                                                                Hours


                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-2">
                                                                                                            <input type="email" class="form-control" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" formControlName="InitialPlannedHours" aria-describedby="emailHelp" placeholder="Enter Initial Planned Hours">
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">
                                                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                                                            <label for="exampleInputEmail1">Description


                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-2">
                                                                                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Description" aria-describedby="emailHelp" placeholder="Enter Description">
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>


                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">


                                                                                                        <div class="col-lg-3 form-group classInputA">
                                                                                                            <label for="exampleInputEmail1">Assigned
                                                                                                                To

                                                                                                            </label>

                                                                                                        </div>

                                                                                                        <div class="col-lg-2">

                                                                                                            <p-dropdown filter="true" [options]="partyIdArray" formControlName="AssignedTo" optionlabel="label" [ngModelOptions]="{standalone:true}" placeholder="Enter Assigned To ">
                                                                                                            </p-dropdown>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>



                                                                                            </div>

                                                                                            <div class="col-lg-12  main-submit-button" style="
                                                                                                           margin-left: -67%;">
                                                                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="onUpdateDefaultTask()">Update
                                                                                                </button>
                                                                                            </div>
                                                                                        </form>


                                                                                    </div>




                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div *ngIf="activeCategory==13">

                                                                <div class=" bg-white color-grey">
                                                                    <div class="create-new-leade mt-2">
                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                <form class="w-100">
                                                                                    <div class="container-fluid">
                                                                                        <ul class=" tabbing-accordians " style="width: 103% !important;
                                                                        flex-wrap: wrap !important;
                                                                        display: flex !important;
                                                                        padding: 10px !important;">

                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalNewProject">
                                                                                                Create New
                                                                                                Project
                                                                                            </button>

                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalCategory">
                                                                                                Create New Category


                                                                                            </button>

                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="closeProduct()">
                                                                                                Close
                                                                                                Product
                                                                                            </button>


                                                                                        </ul>
                                                                                    </div>



                                                                                    <div class="w3-card-4 classCard mt-2">
                                                                                        <div class="container-fluid">
                                                                                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                                                            flex-wrap: wrap !important;
                                                                            display: flex !important;">
                                                                                                <li><a>
                                                                                                        Edit Default
                                                                                                        Task
                                                                                                        DEFAULT-TASK-1
                                                                                                    </a>
                                                                                                </li>

                                                                                            </ul>
                                                                                        </div>
                                                                                        <form [formGroup]="categoryUpdateForm">



                                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">
                                                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                                                            <label for="exampleInputEmail1">Category


                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-2">

                                                                                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Category" aria-describedby="emailHelp" placeholder="Enter Category">
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>



                                                                                            </div>

                                                                                            <div class="col-lg-12  main-submit-button" style="
                                                                                                           margin-left: -67%;">
                                                                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="onUpdateCategory()">Update
                                                                                                </button>
                                                                                            </div>
                                                                                        </form>


                                                                                    </div>




                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div *ngIf="activeCategory==14">
                                                                <div class="container-fluid">
                                                                    <ul class=" tabbing-accordians " style="width: 103% !important;
                                                    flex-wrap: wrap !important;
                                                    display: flex !important;
                                                    padding: 10px !important;">
                                                                        <li>
                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalNewProject">
                                                                                New
                                                                                Project
                                                                            </button>

                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="closeProduct()"> Close
                                                                                Product
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div class="divA">
                                                                    <div style="color: #0d3769; font-size: 15px;"> Communication Event ID <span>{{this.communicationEventId}}</span></div>
                                                                    <br>
                                                                    <div class="w3-card-4 classCardView">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Communication Event
                                                                            </h4>
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="color-black container">
                                                                                    <div class="row">
                                                                                        <div class="article-container">
                                                                                            <div class="article">
                                                                                                <p class="col-lg-7 col-12">
                                                                                                    Communication Event ID </p>
                                                                                                <span class="color-grey pl-20 col-lg-10 col-12 font-weight-bold">{{this.communicationEventId}}</span>
                                                                                            </div>
                                                                                            <div class="article">
                                                                                                <p class="col-lg-7 col-12">
                                                                                                    Comm.Type ID</p>
                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.CommunicationOverviewEvent.eventRoles[0].communicationEventTypeId}}
                                                                                                </span>
                                                                                            </div>

                                                                                            <div class="article">
                                                                                                <p class="col-lg-7 col-12">
                                                                                                    Parent Comm Event ID
                                                                                                </p>
                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.CommunicationOverviewEvent.eventRoles[0].parentCommEventId}}</span>
                                                                                            </div>

                                                                                            <div class="article">
                                                                                                <p class="col-lg-7 col-12">
                                                                                                    From Party ID </p>
                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.CommunicationOverviewEvent.FromPartyID}}</span>
                                                                                            </div>
                                                                                            <div class="article">
                                                                                                <p class="col-lg-7 col-12">
                                                                                                    To Party ID</p>
                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.CommunicationOverviewEvent.ToPartyID}}</span>
                                                                                            </div>
                                                                                            <div class="article">
                                                                                                <p class="col-lg-7 col-12">
                                                                                                    Status ID</p>
                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.CommunicationOverviewEvent.eventRoles[0].statusId}}</span>
                                                                                            </div>
                                                                                            <div class="article">
                                                                                                <p class="col-lg-7 col-12">
                                                                                                    Last Modified Date
                                                                                                </p>
                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.CommunicationOverviewEvent.eventRoles[0].lastmodifyDate}}</span>
                                                                                            </div>
                                                                                            <div class="article">
                                                                                                <p class="col-lg-7 col-12">
                                                                                                    Send Date </p>
                                                                                                <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                            </div>
                                                                                            <div class="article">
                                                                                                <p class="col-lg-7 col-12">
                                                                                                    End date</p>
                                                                                                <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                            </div>
                                                                                            <div class="article">
                                                                                                <p class="col-lg-7 col-12">
                                                                                                    Subject </p>
                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.CommunicationOverviewEvent.eventRoles[0].subject}}</span>
                                                                                            </div>
                                                                                            <div class="article">
                                                                                                <p class="col-lg-7 col-12">
                                                                                                    Content </p>
                                                                                                <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                            </div>
                                                                                            <div class="article">
                                                                                                <p class="col-lg-7 col-12">
                                                                                                    Content Note </p>
                                                                                                <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <br>

                                                                </div>

                                                                <div class="divB">
                                                                    <div>
                                                                        <h3 style="color: #207EBB;">Related Information
                                                                        </h3>
                                                                    </div>

                                                                    <div class="w3-card-4 classCardView">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Communication Event Roles
                                                                            </h4>
                                                                        </div>

                                                                        <div class="panel-body allStyleUser">
                                                                            <div class="card own-account-table borderTable">
                                                                                <p-table [value]="CommunicationOverviewEventRoles" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>

                                                                                                    Party ID
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Contact Mech ID
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    Role Type ID</div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    Status ID</div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>


                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    {{product.communicationeventRoles.partyId}}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.communicationeventRoles.contactMechId}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.communicationeventRoles.roleTypeId}}
                                                                                            </td>

                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.communicationeventRoles.statusId}}
                                                                                            </td>


                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                                    <h4 class="ml-2">No
                                                                                                        Record Found
                                                                                                    </h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                                <p class="paginate_data">
                                                                                    View per page

                                                                                </p>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                    <br>
                                                                    <div class="w3-card-4 classCardView mt-2">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Communication Content
                                                                            </h4>

                                                                        </div>
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                                                <div class="card own-account-table borderTable">
                                                                                    <p-table [value]="ContentDataList" [rows]="rows" scrollWidth="100%" [scrollable]="true" [customSort]="true">
                                                                                        <ng-template pTemplate="header">
                                                                                            <tr style="background: #0d3769;">
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                </div>
                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <!---->
                                                                                                        </p-checkbox>
                                                                                                        Content Name
                                                                                                    </div>

                                                                                                </th>
                                                                                                <!-- <th [ngStyle]="{'width':'170px'}"
                                                                                                    pSortableColumn="price">
                                                                                                    <div
                                                                                                        style="color: white;">
                                                                                                        Delete
                                                                                                    </div>

                                                                                                </th> -->
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="body" let-product>
                                                                                            <tr>
                                                                                                <td style="color: #0d3769;" (click)="EmailContentDownload(product.imageURL)" [ngStyle]="{'width':'280px'}">
                                                                                                    <div style="color: #0d3769;cursor: pointer;" class="checkbox-align">
                                                                                                        {{product.imageURL}}
                                                                                                    </div>
                                                                                                </td>



                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="text-center">
                                                                                                        <h4
                                                                                                            class="ml-2">
                                                                                                            No Record
                                                                                                            Found</h4>
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                    </p-table>
                                                                                    <p class="paginate_dataa">
                                                                                        <!--  <ng-container *ngIf="openNoRecordFoundNote">
                                      <span style="margin-left: 450px;">No Record Found!....</span>
                                    </ng-container> -->
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="w3-card-4 classCardView mt-2">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Child Communication Events
                                                                            </h4>

                                                                        </div>
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                                                <div class="card own-account-table borderTable">
                                                                                    <p-table [value]="ChildCommunicationOverviewEvent" [rows]="rows" scrollWidth="100%" [scrollable]="true" [customSort]="true">
                                                                                        <ng-template pTemplate="header">
                                                                                            <tr style="background: #0d3769;">
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                </div>
                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <!---->
                                                                                                        </p-checkbox>
                                                                                                        Subject

                                                                                                    </div>

                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'100px'}" pSortableColumn="price">
                                                                                                    <div style="color: white;">
                                                                                                        Type

                                                                                                    </div>

                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="quantity">
                                                                                                    <div style="color: white;">
                                                                                                        Status Id

                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                                    <div style="color: white;">
                                                                                                        Party Id

                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                                    <div style="color: white;">
                                                                                                        Role Status Id

                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                                    <div style="color: white;">
                                                                                                        Created

                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                                    <div style="color: white;">
                                                                                                        Sent

                                                                                                    </div>
                                                                                                </th>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="body" let-product>
                                                                                            <tr>
                                                                                                <td *ngFor="let r of product.eventRoles" [ngStyle]="{'width':'170px'}">
                                                                                                    <div class="checkbox-align ml-3">
                                                                                                        <span>{{r.subject}}</span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'100px'}">

                                                                                                </td>
                                                                                                <td *ngFor="let r of product.eventRoles" [ngStyle]="{'width':'150px'}">
                                                                                                    <span>
                                                                                                        {{r.statusId}}
                                                                                                    </span>


                                                                                                </td>
                                                                                                <td *ngFor="let r of product.eventRoles" [ngStyle]="{'width':'170px'}">
                                                                                                    <div class="checkbox-align">
                                                                                                        <span>{{r.partyIdTo}}</span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td *ngFor="let r of product.eventRoles" [ngStyle]="{'width':'170px'}">
                                                                                                    <div class="checkbox-align">
                                                                                                        <span>{{r.roleTypeIdTo}}</span>
                                                                                                    </div>
                                                                                                </td>

                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    <div *ngFor="let r of product.eventRoles" class="checkbox-align">
                                                                                                        <span>{{r.createdStamp |
                                                                                            date:'yyyy-MM-d
                                                                                            hh:mm:ss' }}</span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    <div class="checkbox-align">
                                                                                                        <span></span>
                                                                                                    </div>
                                                                                                </td>


                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="text-center">
                                                                                                        <h4
                                                                                                            class="ml-2">
                                                                                                            No Record
                                                                                                            Found</h4>
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                    </p-table>
                                                                                    <p class="paginate_dataa">
                                                                                        <!--  <ng-container *ngIf="openNoRecordFoundNote">
                                      <span style="margin-left: 450px;">No Record Found!....</span>
                                    </ng-container> -->
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div *ngIf="activeCategory==15">
                                                                <div class="container-fluid">
                                                                    <ul class=" tabbing-accordians " style="width: 103% !important;
                                                    flex-wrap: wrap !important;
                                                    display: flex !important;
                                                    padding: 10px !important;">
                                                                        <li>
                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalNewProject">
                                                                                New
                                                                                Project
                                                                            </button>

                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="closeProduct()"> Close
                                                                                Product
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div class="divA">
                                                                    <div class="w3-card-4 classCardView">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                New Email
                                                                            </h4>
                                                                        </div>

                                                                        <div class="port-header-contact create-lead font-medium mb-0 pt-0 mt-0 text-white">
                                                                            <div class="w-100">
                                                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                                                    <div>


                                                                                        <form [formGroup]="NewEmailForm">
                                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">


                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">
                                                                                                        <div class="col-lg-3 form-group classInputB">
                                                                                                            <label for="exampleInputEmail1">From

                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-9">
                                                                                                            <input type="email" [(ngModel)]="this.ProductUpdateEmail.ItemList[0].fromString" [ngModelOptions]="{standalone:true}" class="form-control" id="exampleInputEmail1" formControlName="From" aria-describedby="emailHelp" placeholder="Enter From">
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>



                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">
                                                                                                        <div class="col-lg-3 form-group classInputB">
                                                                                                            <label for="exampleInputEmail1">To
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-9">

                                                                                                            <input type="email" disabled class="form-control" [(ngModel)]="this.ProductUpdateEmail.ItemList[0].toString" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" formControlName="To" aria-describedby="emailHelp" >
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">
                                                                                                        <div class="col-lg-3 form-group classInputB">
                                                                                                            <label for="exampleInputEmail1">Subject

                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-9">

                                                                                                            <input type="email" class="form-control" [(ngModel)]="this.ProductUpdateEmail.ItemList[0].subject" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" formControlName="Subject" aria-describedby="emailHelp" placeholder="Enter Subject">

                                                                                                            <div style="color: #0d3769;">Do not remove the <span>{{this.ProductUpdateEmail.ItemList[0].subject}}</span></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">
                                                                                                        <div class="col-lg-3 form-group classInputB">
                                                                                                            <label for="exampleInputEmail1">Content
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div class="col-lg-9">

                                                                                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Content" aria-describedby="emailHelp" placeholder="Enter Content">

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </form>
                                                                                        <div class="col-lg-12  main-submit-button" style="
                                                                                               margin-left: -6%;">
                                                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="updateEmailForProduct() ">Send</button>&nbsp;



                                                                                        </div>


                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <br>

                                                                </div>

                                                                <div class="divB">
                                                                    <h3>Communication Event ID 11282</h3>
                                                                    <div class="w3-card-4 classCardView ">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Email Contents
                                                                            </h4>

                                                                        </div>
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                                                <div class="card own-account-table borderTable">
                                                                                    <p-table [value]="ContentDataList" [rows]="rows" scrollWidth="100%" [scrollable]="true" [customSort]="true">
                                                                                        <ng-template pTemplate="header">
                                                                                            <tr style="background: #0d3769;">
                                                                                                <th [ngStyle]="{'width':'280px'}" pSortableColumn="code">
                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                </div>
                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <!---->
                                                                                                        </p-checkbox>
                                                                                                        Content Name
                                                                                                    </div>

                                                                                                </th>
                                                                                                <!-- <th [ngStyle]="{'width':'100px'}"
                                                                                                    pSortableColumn="price">
                                                                                                    <div
                                                                                                        style="color: white;">
                                                                                                        Delete
                                                                                                    </div>

                                                                                                </th> -->
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="body" let-product>
                                                                                            <tr>
                                                                                                <td style="color: #0d3769;" (click)="EmailContentDownload(product.imageURL)" [ngStyle]="{'width':'280px'}">
                                                                                                    <div style="color: #0d3769;cursor: pointer;" class="checkbox-align">
                                                                                                        {{product.imageURL}}
                                                                                                    </div>
                                                                                                </td>

                                                                                                <!-- 
                                                                                                <td
                                                                                                    [ngStyle]="{'width':'100px'}">


                                                                                                </td> -->

                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="text-center">
                                                                                                        <h4
                                                                                                            class="ml-2">
                                                                                                            No Record
                                                                                                            Found</h4>
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                    </p-table>
                                                                                    <p class="paginate_dataa">

                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            <form [formGroup]="UploadedFileForm">
                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-3 form-group rightFormA" style="margin-left: -5%;">
                                                                                                <label for="exampleInputEmail1">
                                                                                                    Uploaded File

                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-4">

                                                                                                <input type="file" class="form-control" id="exampleInputEmail1" (change)="fileProgressCom($event)" formControlName="UploadedFile" aria-describedby="emailHelp" placeholder="Enter Category">
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </form>

                                                                            <div class="col-lg-12  main-submit-button" style="
                                                                                           margin-left: -47%;">
                                                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="uploadEmailContent()">Upload
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>


                                                            <div *ngIf="activeCategory==16">

                                                                <div class=" bg-white color-grey">
                                                                    <div class="create-new-leade mt-2">
                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                <form class="w-100">
                                                                                    <div class="container-fluid">
                                                                                        <ul class=" tabbing-accordians " style="width: 103% !important;
                                                                        flex-wrap: wrap !important;
                                                                        display: flex !important;
                                                                        padding: 10px !important;">

                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalNewProject">
                                                                                                New
                                                                                                Project
                                                                                            </button>

                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="closeProduct()">
                                                                                                Close
                                                                                                Product
                                                                                            </button>

                                                                                        </ul>
                                                                                    </div>

                                                                                    <div class="w3-card-4 classCard mt-2">
                                                                                        <header class="w3-container w3-blue">
                                                                                            <div class="header-tabs">
                                                                                                <h4 class=" common-styling h4Margin">

                                                                                                    Unplan Backlog List
                                                                                                    <span style="float: right !important;margin-right: -443%;">
                                                                                                        All Backlog
                                                                                                        Items</span>
                                                                                                </h4>
                                                                                            </div>
                                                                                        </header>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="col-lg-12 col-12">
                                                                                                <div class="form-group">

                                                                                                    <div class="card own-account-table">
                                                                                                        <p-table [value]="UnplanBacklogList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                            <ng-template pTemplate="header">
                                                                                                                <tr style="background: #0d3769;">
                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                    </div>
                                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                            </p-checkbox>

                                                                                                                            Sprint Backlog

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Status Backlog
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>


                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">


                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Initial Plan / Total Plan

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                    <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                    <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                    <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                    <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                </tr>

                                                                                                                <tr style="background: #0d3769;">
                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                    </div>
                                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                            </p-checkbox>
                                                                                                                            Task


                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Task Type
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Status
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Actual Hours

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Planned Hours
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>

                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">
                                                                                                                            Assigned To
                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                    <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                    <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                    <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                    <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                                                                        <div style="color: white;">

                                                                                                                        </div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </th>
                                                                                                                </tr>
                                                                                                            </ng-template>
                                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                                <tr>
                                                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                                                        <div class="checkbox-align">
                                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                    </div>
                                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                            </p-checkbox>
                                                                                                                            <span *ngIf="product.SprintBacklog[0].custRequestId"  class="account-button"  >
                                                                                                                                {{product.SprintBacklog[0].custRequestId}}</span>

                                                                                                                        </div>
                                                                                                                    </td>

                                                                                                                    <td [ngStyle]="{'width':'150px'}">

                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'150px'}">

                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'150px'}">

                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'150px'}">

                                                                                                                    </td>

                                                                                                                    <td [ngStyle]="{'width':'180px'}">


                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'150px'}">


                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'100px'}">
                                                                                                                        <div><span></span></div>

                                                                                                                    </td>



                                                                                                                    <td [ngStyle]="{'width':'100px'}">
                                                                                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="DeleteUnplanBacklog(product)">
                                                                                                                            Delete
                                                                                                                        </button>
                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'100px'}">
                                                                                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addTaskPopUp">
                                                                                                                            Add
                                                                                                                            Task
                                                                                                                        </button>
                                                                                                                    </td>

                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                                                        <div class="checkbox-align">
                                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                    </div>
                                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                            </p-checkbox>
                                                                                                                            <span *ngIf="product.tasks.workEffortId"  class="account-button"  >
                                                                                                                                {{product.tasks.workEffortId}}</span>

                                                                                                                        </div>
                                                                                                                    </td>

                                                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                                                        <div *ngIf="product.tasks"><span>{{product.tasks.workEffortTypeId}}</span></div>

                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                                                        <div *ngIf="product.tasks"><span> {{product.tasks.currentStatusId}}</span></div>

                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                                                        <div><span> {{product.ActualHours}}</span></div>

                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                                                        <div *ngIf="product.PlannedHours[0]"><span> {{product.PlannedHours[0].planHours}}</span></div>

                                                                                                                    </td>

                                                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                                                        <div *ngIf="product.InitialPlan/TotalPlan"><span> {{product.InitialPlan/TotalPlan}}</span></div>

                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                                                        <div *ngIf="product.AssignedTo[0]"><span>{{product.AssignedTo[0].partyId}}</span></div>

                                                                                                                    </td>
                                                                                                                    <td [ngStyle]="{'width':'100px'}">

                                                                                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="editPage(product.AssignedTo[0].partyId,product.tasks.workEffortName,product.tasks.workEffortId,product.SprintBacklog[0].custRequestId)">
                                                                                                                            Edit
                                                                                                                        </button>
                                                                                                                    </td>


                                                                                                                    <td [ngStyle]="{'width':'100px'}">
                                                                                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="DeleteUnplanBacklog(product)">
                                                                                                                            Delete
                                                                                                                        </button>
                                                                                                                    </td>

                                                                                                                    <td [ngStyle]="{'width':'100px'}">
                                                                                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="setCompleteFromUnplanBacklog(product)">
                                                                                                                            Complete
                                                                                                                        </button>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </ng-template>
                                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                <tr>
                                                                                                                    <td>
                                                                                                                        <span class="text-center">
                                                                                                                            <h4
                                                                                                                                class="ml-2">
                                                                                                                                No
                                                                                                                                Record
                                                                                                                                Found
                                                                                                                            </h4>
                                                                                                                        </span>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </ng-template>
                                                                                                        </p-table>

                                                                                                        <p class="paginate_data">
                                                                                                            View per page </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>


                                                                                </form>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCreateDefaultTask" tabindex="-1" role="dialog" aria-labelledby="exampleModalCreateDefaultTaskTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Edit Default Task
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Edit Default Task
                                    </a></li>
                            </ul>
                        </div>


                        <form [formGroup]="defaultTaskCreateForm">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Task
                                                Name
                                            </label>
                                        </div>
                                        <div class="col-lg-3">

                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="TaskName" aria-describedby="emailHelp" placeholder="Enter Task Name">
                                        </div>

                                        <div class="col-lg-3 form-group classInput__new_changes">
                                            <label for="exampleInputEmail1">Product


                                            </label>
                                        </div>

                                        <div class="col-lg-3">
                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Product" aria-describedby="emailHelp" placeholder="Enter Product ">
                                        </div>

                                    </div>
                                </div>


                              


                                <div class="col-lg-12">
                                    <div class="row">


                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Type


                                            </label>

                                        </div>

                                        <div class="col-lg-3">

                                            <p-dropdown filter="true" [options]="DefaultTaskArray" formControlName="Type" optionlabel="label" placeholder="Enter Type">
                                            </p-dropdown>
                                        </div>

                                        <div class="col-lg-3 form-group classInput__new_changes">
                                            <label for="exampleInputEmail1">Initial
                                                Planned
                                                Hours


                                            </label>
                                        </div>

                                        <div class="col-lg-3">
                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="InitialPlannedHours" aria-describedby="emailHelp" placeholder="Enter Initial Planned Hours">
                                        </div>

                                    </div>
                                </div>
                                
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Description
                                            </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Description" aria-describedby="emailHelp" placeholder="Enter Description">
                                        </div>

                                        <div class="col-lg-3 form-group classInput__new_changes">
                                            <label for="exampleInputEmail1">Assigned
                                                To
                                            </label>
                                        </div>

                                        <div class="col-lg-3">

                                            <p-dropdown filter="true" [options]="AssignToArray" formControlName="AssignedTo" optionlabel="label" placeholder="Enter Assigned To">
                                            </p-dropdown>
                                        </div>

                                    </div>
                                </div>


                                



                            </div>
                        </form>

                        <div class="col-lg-12  main-submit-button" style="
                                       margin-left: -8%;">
                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="createDefaultTask()">Create
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCategory" tabindex="-1" role="dialog" aria-labelledby="exampleModalCategoryTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Edit Product Backlog Category
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Edit Product Backlog Category
                                    </a></li>
                            </ul>
                        </div>


                        <form [formGroup]="categoryCreateForm">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Category

                                            </label>
                                        </div>
                                        <div class="col-lg-2">

                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Category" aria-describedby="emailHelp" placeholder="Enter Category">
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </form>

                        <div class="col-lg-12  main-submit-button" style="
                                       margin-left: -71%;">
                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="onCreateCategory()">Create
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalNewProject" tabindex="-1" role="dialog" aria-labelledby="exampleModalNewProjectTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Edit Project
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Edit Project
                                    </a></li>
                            </ul>
                        </div>


                        <form [formGroup]="newProjectForm">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightFormG">
                                            <label for="exampleInputEmail1">Product
                                                Name
                                            </label>
                                        </div>
                                        <div class="col-lg-2">

                                            <input type="email" disabled [(ngModel)]="this.internalName" class="form-control" id="exampleInputEmail1" formControlName="ProductName" aria-describedby="emailHelp" placeholder="Enter Product Name">
                                        </div>
                                        <div class="col-lg-3 form-group rightFormH">
                                            <label for="exampleInputEmail1">Project Name


                                            </label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="email" class="form-control" id="exampleInputEmail2" formControlName="ProjectName" aria-describedby="emailHelp" placeholder="Enter Project Name ">
                                        </div>

                                    </div>
                                </div>


                                


                                <div class="col-lg-12">
                                    <div class="row">


                                        <div class="col-lg-3 form-group rightFormG">
                                            <label for="exampleInputEmail1">Billed


                                            </label>

                                        </div>

                                        <div class="col-lg-2">

                                            <p-dropdown filter="true" [options]="yesNOArray" formControlName="Billed" optionlabel="label" placeholder="Enter Billed">
                                            </p-dropdown>

                                        </div>
                                        <div class="col-lg-3 form-group rightFormH">
                                            <label for="exampleInputEmail1">Project Goal
                                            </label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="ProjectGoal" aria-describedby="emailHelp" placeholder="Enter Project Goal">
                                        </div>

                                    </div>
                                </div>
                               

                            </div>
                        </form>

                        <div class="col-lg-12  main-submit-button" style="
                                       margin-left: -13%;">
                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="createScrumProject()">Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addTaskPopUp" tabindex="-1" role="dialog" aria-labelledby="addTaskPopUpTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Edit Task Information</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        Edit Task # Information</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100 " [formGroup]="addNewTaskForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="productId">Task Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="TaskName" placeholder="Enter Task Name">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Task Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" optionlabel="label" formControlName="TaskType" [options]="RoleTypeArray" placeholder="Enter Task Type">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Plan Hours</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="PlanHours" aria-describedby="emailHelp" placeholder="Enter Plan Hours">
                                                    </div>


                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Description" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>


                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Uploaded File
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="file" class="form-control" id="exampleInputEmail1" formControlName="Uploaded" aria-describedby="emailHelp" placeholder="Choose File">
                                                    </div>


                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Assign To
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" optionlabel="label" [options]="AssignToArray" placeholder="Enter Assign To" formControlName="AssignTo">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -62%;">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="addTask()">Create</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="editBacklogPopUP" tabindex="-1" role="dialog" aria-labelledby="editBacklogPopUPTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Edit Backlog  </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton10>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                   Create Backlog</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="editBacklogForm">


                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="productId">Product
                                                            Backlog
                                                            Item
                                                            <span
                                                                style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="ProductBacklogItem" placeholder="Enter Product Backlog Item">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">Backlog
                                                            Item
                                                            Category
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="BacklogItemCategoryArray" formControlName="BacklogItemCategory" optionlabel="label" placeholder="Enter Backlog Item Category">
                                                        </p-dropdown>



                                                    </div>

                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="productId">Plan
                                                            Time
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="PlanTime" placeholder="Enter PlanTime">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="productId">Sequence
                                                            Num
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="SequenceNum" placeholder="Enter SequenceNum">

                                                    </div>

                                                </div>
                                            </div>
                                            

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Billed
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" [options]="yesNOArray" formControlName="Billed" optionlabel="label" placeholder="Choose Billed">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">Unplanned
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" [options]="yesNOArray" formControlName="Unplanned" optionlabel="label" placeholder="Enter Unplanned">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="productId">Story
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="Story" placeholder="Enter Story">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="productId">How
                                                            to test
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="Howtotest" placeholder="How to test">

                                                    </div>

                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="col-lg-12  main-submit-button" style="
                                                                   margin-left: -13%;">
                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="addNewProductBacklogsItem()">Submit</button>&nbsp;


                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="contentPopUP"   tabindex="-1" role="dialog" aria-labelledby="contentPopUP" aria-hidden="true">
    <div class="modal-dialog ht50" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Content  </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton10>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                   Create Backlog</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="contentForm">


                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">
                                                            Content
                                                            Type
                                                            ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" [options]="contentTypeIdArray"
                                                         formControlName="ContentTypeID" optionlabel="label"
                                                          placeholder="Choose Content Type ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Mime
                                                            Type ID
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" [options]="mimeTypeIdArray" formControlName="MimeTypeID" optionlabel="label" placeholder="Choose Mime Type ID">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Upload
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input (change)="fileProgress($event)" type="file" class="form-control" id="exampleInputEmail1" formControlName="Upload" aria-describedby="emailHelp" >
                                                    </div>

                                                </div>
                                            </div>




                                        </div>
                                        <div class="col-lg-12  main-submit-button" style="
                                                                   margin-left: -5%; margin-top: -1%;">
                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="oncreateScrumProductContent()">Create</button>&nbsp;


                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>