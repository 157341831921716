import { Component, OnInit, ViewChild } from '@angular/core';

declare var $: any;
import { SortEvent } from 'primeng/api';
import { ShippingService } from '../shipping.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';  import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-carrier-shipment',
  templateUrl: './carrier-shipment.component.html',
  styleUrls: ['./carrier-shipment.component.css']
})
export class CarrierShipmentComponent implements OnInit {
  products3: any;
  total=0;
  @ViewChild('closebutton') closebutton;
  activeCategory=4;
  activeCategorytab = 2;
  rowNumber=0;
  activeCat=14;
  pageSize = 10;
  rows = 50;
  roleIds: any[];
  shipmentTypeIds: any[];
  carrierShipments: any[];
  carrierShipmentForm: FormGroup;
  editMode: boolean;
  editShipment: { partyId: string, roleTypeId: string, shipmentMethodTypeId: string };
  partyIds: any[];
  isShown=false;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    readonly _ShippingService: ShippingService,
    readonly _FormBuilder: FormBuilder,
    readonly  _Router: Router,
    readonly spinner: NgxSpinnerService,    private _location: Location,
    readonly router: Router,
    readonly _ToastrService: ToastrService
  ) {
    this.shipmentTypeIds = [];
    this.carrierShipments = [];
    this.carrierShipmentForm = this._FormBuilder.group({
      carrierServiceCode: [''],
      partyId: [''],
      roleTypeId: [''],
      sequenceNumber: [''],
      shipmentMethodTypeId: ['']
    });
    this.carrierShipments = [];
    this.shipmentTypeIds = [];
    this.roleIds = [];
    this.editShipment = { partyId: '', roleTypeId: '', shipmentMethodTypeId: '' };
    this.partyIds = [];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
    $('.right-arrow').click(function () {
      $(this).toggleClass('rotate-up');
    });
    this.getRoleId();
    this.getShipmentTypeId();
    this.getPartyId();
    this.getCarrierShipment();
  }
  getRoleId(): void {
    this.spinner.show();
    this._ShippingService.getRoleType()
      .then(data => {
        this.roleIds = data.data;
      });
      this.spinner.hide();
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
  getPartyId(): void {
    this.spinner.show();
    this._ShippingService.getPartyId()
      .then(data => {
        if (data.success) {
          this.partyIds = data.data.PersonsGroups;
          this.spinner.hide();
        }
      });
     
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  getShipmentTypeId(): void {
    this.spinner.show();
    this._ShippingService.shipmentMethodType()
      .then(data => {
        this.shipmentTypeIds = data.data;
        this.spinner.hide();
      });
    
  }
  getCarrierShipment(): void {
    this.spinner.show();
    this._ShippingService.getCarrierShipment({ pageNo: 1, pageSize: 200 })
      .then(data => {
        this.carrierShipments = data.data;
        this.spinner.hide();
      });
    
  }
  edit(partyId: string, roleTypeId: string, shipmentMethodTypeId: string, sequenceNumber: string, carrierServiceCode: string): void {
    this.editMode = true;
    this.editShipment = { partyId, roleTypeId, shipmentMethodTypeId };
    this.carrierShipmentForm.patchValue({
      partyId, roleTypeId, shipmentMethodTypeId, sequenceNumber, carrierServiceCode
    });
    this.carrierShipmentForm.controls.partyId.disable();
    this.carrierShipmentForm.controls.partyId.updateValueAndValidity();
    this.carrierShipmentForm.controls.roleTypeId.disable();
    this.carrierShipmentForm.controls.roleTypeId.updateValueAndValidity();
    this.carrierShipmentForm.controls.shipmentMethodTypeId.disable();
    this.carrierShipmentForm.controls.shipmentMethodTypeId.updateValueAndValidity();
  }
  delete(partyId: string, roleTypeId: string, shipmentMethodTypeId: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._ShippingService.deleteCarrierShipment(partyId, roleTypeId, shipmentMethodTypeId)
      .then(data => {
        if (data.success) {
          this.getCarrierShipment();
        }
      });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
  submit(): void {
    this.spinner.show();
    if (this.editMode) {
      this._ShippingService.updateCarrierShipment({
        ...this.editShipment, ...this.carrierShipmentForm.value
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.getCarrierShipment();
            this.carrierShipmentForm.controls.partyId.enable();
            this.carrierShipmentForm.controls.partyId.updateValueAndValidity();
            this.carrierShipmentForm.controls.roleTypeId.enable();
            this.carrierShipmentForm.controls.roleTypeId.updateValueAndValidity();
            this.carrierShipmentForm.controls.shipmentMethodTypeId.enable();
            this.carrierShipmentForm.controls.shipmentMethodTypeId.updateValueAndValidity();
            this.closebutton.nativeElement.click();
          }
        });
    } else {
      this._ShippingService.createCarrierShipment(this.carrierShipmentForm.value)
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.getCarrierShipment();
            this.closebutton.nativeElement.click();
          }
        });
    }
   
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  mainOpen(){
    this.router.navigate(['/facilities/catalog/main-catalog']);
  }
  catalogOpen(){
    this.router.navigate(['/facilities/catalog/catalogs']);
  }
  categoriesOpen(){
    this.router.navigate(['/facilities/catalog/catagories']);
  }
  productsOpen(){
    this.router.navigate(['/facilities/catalog/products']);
  }
  featuresOpen(){
    this.router.navigate(['/facilities/catalog/feature-type']);
  }
  promosOpen(){
    this.router.navigate(['/facilities/catalog/promos']);
  }
  priceRulesOpen(){
    this.router.navigate(['/facilities/catalog/price-rules']);
  }
  storesOpen(){
    this.router.navigate(['/facilities/catalog/stores']);
  }
  productStoreGroupsOpen(){
    this.router.navigate(['/facilities/catalog/product-store-groups']);
  }
  thesaurusOpen(){
    this.router.navigate(['/facilities/catalog/thesaurus']);
  }
  reviewsOpen(){
    this.router.navigate(['/facilities/catalog/reviews']);
  }
  configurationsOpen(){
    this.router.navigate(['/facilities/catalog/configurations']);
  }
  subscriptionsOpen(){
    this.router.navigate(['/facilities/catalog/subscriptions']);
  }
  shippingOpen(){
    this.router.navigate(['/facilities/catalog/shipment']);
  }
  imageManagementOpen(){
    this.router.navigate(['/facilities/catalog/image-management']);
  }
  ship(){
    this._Router.navigate(['/facilities/catalog/shipment']);
  }
  feautureValue(){
    this.isShown = !this.isShown;
  }
  reset(): void {
    this.carrierShipmentForm.reset();
    document.getElementById('editForm').scrollIntoView({ behavior: 'smooth' });
  }
  detailPage(eid,epid,erid,esnum,ecode) {
    this._Router.navigate(["/facilities/catalog/shipment/detail-carrier-summary"], { queryParams: { id:eid,pid:epid,rid:erid,snum:esnum,code:ecode} })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
