import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';   import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { Color, Label } from 'ng2-charts';
import { ChartType } from 'chart.js';
import { ChartDataSets, ChartOptions } from 'chart.js';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-unplanned-sprint',
  templateUrl: './unplanned-sprint.component.html',
  styleUrls: ['./unplanned-sprint.component.css']
})
export class UnplannedSprintComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  activeCategory = 14;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  productId: any;
  billFromParty: any;
  UpdateProductBacklogForm: FormGroup;
  RequesterName: any;
  RequesterNameArray : any[]=[];
  yesNOArray: { label: string; value: string; }[];
  UnplanBacklogList: any;
  addNewTaskForm: FormGroup;
  RoleTypeList: any;
  RoleTypeArray: any[]=[];
  AssignTo: any;
  AssignToArray: any[]=[];
  custRequestId= "";
  constructor(public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,    
    private _location: Location,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router) { 
      this.yesNOArray = [
        {
          label: 'Y',
          value: 'Y'
        },
        {
          label: 'N',
          value: 'N'
        },
      ];
      this.UpdateProductBacklogForm = this._FormBuilder.group({
        ProductBacklogItem: [''],
        Company: [''],
        ProductOwner: [''],
        BacklogItemCategory: [''],
        PlanTime: [''],
        SequenceNum: [''],
        Billed: [''],
        Status: [''],
        Unplanned: [''],
        Requestname: [''],
        Story: [''],
        Howtotest: [''],
      });

      this.addNewTaskForm = this._FormBuilder.group({
        ProjectID: [''],
        TaskType: [''],
        TaskName: [''],
        SprintID: [''],
        SprintBacklog: [''],
        PlanHours: [''],
        Uploaded: [''],
        Description: [''],
        AssignTo: [''],
      });
    }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.productId = params.productId;
      this.billFromParty = params.billFromParty;
  })
  this.getFromPartyIdRequesterName();
  this.GetUnplanBacklogList();
  this.getRoleTypeList();
  this.getAssignToList();
}
homeButton(){
  this._Router.navigate(['/psa/Dashboard'])

}
cancelSubmit(){
  this._location.back();
}
getAssignToList() {
  this.spinner.show();
  this.accountsService.getAssignToList().subscribe(res => {
    this.AssignTo = res.data.data;
    this.spinner.hide();
    for (const value of this.AssignTo) {
      this.AssignToArray.push({
        label: value.scrumTeamValue,
        value: value.scrumTeamName
      })
    }
  })
}
getRoleTypeList() {
  this.spinner.show();
  this.accountsService.getRoleTypeList().subscribe(res => {
    this.RoleTypeList = res.data.data;
    this.spinner.hide();
    for (const value of this.RoleTypeList) {
      this.RoleTypeArray.push({
        label: value.roleTypeDesc,
        value: value.roleTypeValue
      })
    }
  })
}
setCompleteFromUnplanBacklog(product) {
  this.spinner.show();

  const formData = {
    "productId":  this.productId ,
  "view_INDEX_1": "0",
  "view_SIZE_1": "20",
  "workEffortId": product.tasks.workEffortId
  }

  this.accountsService.setCompleteFromUnplanBacklog(formData).subscribe((res: any) => {
    if (res.success) {
      this.toastr.success("Success");
      this.spinner.hide();



    }
  }, (err) => {
    this.spinner.hide();
    for (const value of err.error.errors) {
      this.toastr.error(value.fieldName + ' ' + value.fieldError);
    }
  });
  this.spinner.hide();

}

addTask() {
  this.spinner.show();

  const formData = {
    "actualHours": "",
    "contentTypeId": "DOCUMENT",
    "currentStatusId": "STS_CREATED",
    "custRequestId": this.custRequestId ,
    "description":  this.addNewTaskForm.value.Description  ,
    "mimeTypeId": "application/octet-stream",
    "partyId": this.addNewTaskForm.value.AssignTo,
    "planHours": this.addNewTaskForm.value.PlanHours  ,
    "resourceStatusId": "CTNT_PUBLISHED",
    "uploadedFile":  this.addNewTaskForm.value.Uploaded  ,
    "workEffortContentTypeId": "CREATED_MEDIA",
    "workEffortId": "",
    "workEffortName":  this.addNewTaskForm.value.TaskName  ,
    "workEffortTypeId":this.addNewTaskForm.value.TaskType  ,
  }

  this.accountsService.addTask(formData).subscribe((res: any) => {
    if (res.success) {
      this.toastr.success("Success");
      this.spinner.hide();
      this.closebutton2.nativeElement.click();

    }
  }, (err) => {
    this.spinner.hide();
    for (const value of err.error.errors) {
      this.toastr.error(value.fieldName + ' ' + value.fieldError);
    }
  });
  this.spinner.hide();

}

DeleteUnplanBacklog(product) {

  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to recover this data.!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      const formData = {
        "custRequestId": product.SprintBacklog[0].custRequestId ,
        "productId":  this.productId  ,
        "view_INDEX_1": "0",
        "view_SIZE_1": "20",
        "workEffortId": product.tasks.workEffortId
      }
      this.accountsService.DeleteUnplanBacklog(formData).subscribe((res: any) => {
        this.spinner.hide();
        this.GetUnplanBacklogList();

      }, (err) => {
        this.toastr.error(err.error.message);
      })
      Swal.fire(
        'Deleted!',
        'Deleted Successfully.',
        'success'
      )
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your data is safe :)',
        'error'
      )
    }
  })
}

GetUnplanBacklogList() {
  this.spinner.show();
  this.accountsService.GetUnplanBacklogList().subscribe(res => {
    this.UnplanBacklogList = res.data;
    this.spinner.hide();
  })
}

getFromPartyIdRequesterName() {
  this.spinner.show();
  this.accountsService.getFromPartyIdRequesterName().subscribe(res => {
    this.RequesterName = res.data[0].requesterName;
    this.spinner.hide();
    for (const value of this.RequesterName) {
      this.RequesterNameArray.push({
        label: value.fromPartyId,
        value: value.fromPartyId
      })
    }
  })
}

closeProduct() {
  this.spinner.show();

  const formData = {
    "companyId": this.billFromParty ,
    "isCloseBtn": "Y",
    "partyId": this.billFromParty ,
    "productId":  this.productId ,
    "statusId": "PRODUCT_CLOSED"
  }

  this.accountsService.closeProduct(formData).subscribe((res: any) => {
    if (res.success) {
      this.toastr.success("Success");
      this.spinner.hide();


    }
  }, (err) => {
    this.spinner.hide();
    for (const value of err.error.errors) {
      this.toastr.error(value.fieldName + ' ' + value.fieldError);
    }
  });
  this.spinner.hide();

}
editPage(partyId,workEffortName, workEffortId, custRequestId) {
  this._Router.navigate(["/psa/detail-tasks"] ,{ queryParams: { partyId,workEffortName, workEffortId: workEffortId, custRequestId: custRequestId} })
}
unplannedSprint() {
  this._Router.navigate(["/psa/unplanned-sprint"])
}
ProductOverview() {
  this._Router.navigate(["/psa/product-overview"])
}
ProductUpdate() {
  this._Router.navigate(["/psa/product-update"])
}
ProductMember() {
  this._Router.navigate(["/psa/product-member"])
}
TotalBacklog() {
  this._Router.navigate(["/psa/product-total-backlog"])
}
EditBacklog() {
  this._Router.navigate(["/psa/product-edit-backlog"])
}
Billing() {
  this._Router.navigate(["/psa/product-billing"])
}
DefaultTasks() {
  this._Router.navigate(["/psa/product-default-tasks"])
}
Categories() {
  this._Router.navigate(["/psa/product-categories"])
}
Content() {
  this._Router.navigate(["/psa/product-content"])
}
Communications() {
  this._Router.navigate(["/psa/product-communications"])
}

customSort(event: SortEvent) {
  event.data.sort((data1, data2) => {
    const value1 = data1[event.field];
    const value2 = data2[event.field];
    let result = null;

    if (value1 == null && value2 != null) {
      result = -1;
    }
    else if (value1 != null && value2 == null) {
      result = 1;
    }
    else if (value1 == null && value2 == null) {
      result = 0;
    }
    else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    }
    else {
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }
    return (event.order * result);
  });
}

changeactiveCategory(tab: number) {
  this.activeCategory = tab;
}
ngOnDestroy(): void {
  this.spinner.hide();
}
}
