<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Inactive Customer Summary</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey mt-20">
                <div class="w3-card-4 classCard">
                    <header class="w3-container w3-blue">
                        <div class="header-tabs justify-content-between" >
                            <h4 class="common-styling h4Margin">
                                Timesheet Summary
                            </h4>
                           
                        </div>
                    </header>
                    <div class="create-new-leade">
                        <div class="container-fluid">
                            <div>
                                <!-- <header class="w3-container w3-blue">
                                    <div class="header-tabs">
                                        <h4 class="common-styling h4Margin">
                                            Summary
                                        </h4>
                                        <span style="margin-right: 10px;">
                                                    <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#updatepopup"> 
                                                        Update</button>
                                                </span>
                                    </div>
                                </header> -->
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="color-black container">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="article-container">
                                                    <div class="article">
                                                        <p class="col-lg-6">Technician Id</p>
                                                        <span class="color-grey pl-20 font-weight-bold">
                                                            {{this.fsmTimeCardData.technicianId?this.fsmTimeCardData.technicianId:'N/A'}}
                                                            
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="article-container">
                                                    <div class="article">
                                                        <p class="col-lg-6">Technician Name</p>
                                                        <span class="color-grey pl-20 font-weight-bold">
                                                            {{this.fsmTimeCardData.technicianName?this.fsmTimeCardData.technicianName:'N/A'}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 mt-4">
                                                <div class="article-container">
                                                    <div class="article">
                                                        <p class="col-lg-6">Check In</p>
                                                        <span class="color-grey pl-20 font-weight-bold">
                                                            {{this.fsmTimeCardData.checkIn?this.fsmTimeCardData.checkIn:'N/A'}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 mt-4">
                                                <div class="article-container">
                                                    <div class="article">
                                                        <p class="col-lg-6">Check Out</p>
                                                        <span class="color-grey pl-20 font-weight-bold">
                                                            {{this.fsmTimeCardData.checkOut?this.fsmTimeCardData.checkOut:'N/A'}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

