<div class="container-fluid main-container-wrapper">
    <div class="row mb-5">
        <div class="col-12">
              
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Main</span>
                <span>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                    <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                </span> 
            </div>
            <div class="bg-white color-grey w-100 mt-20">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100">
                            <div class="w3-card-4 classCardView mt-2 w-100 mb-5">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0">
                                    <span> WebSite List</span>

                                    <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                    #closebutton1  data-toggle="modal" data-target="#createWebSitePopup">Create New WebSite
                                    </button>
                                   
                                </div>
                               
                                

                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group">

                                            <div class="card own-account-table">
                                                <p-table [value]="findWebSiteAll" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr style="background: #0d3769;">
                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                <div class="checkbox-align" style="color: white;">
                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                            </div>
                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                    </p-checkbox>

                                                                    ID
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                            </th>

                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                <div style="color: white;">
                                                                    Name
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                <div style="color: white;">
                                                                    Http Host
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                            </th>
                                                            

                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-product>
                                                        <tr>
                                                            <td [ngStyle]="{'width':'150px'}">
                                                                <div class="checkbox-align">
                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                            </div>
                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                    </p-checkbox>
                                                                    <span class="account-button"
                                                                (click)="detailPage(product.webSiteId)" >
                                                                  {{product.webSiteId}}
                                                                    </span>

                                                                </div>
                                                            </td>

                                                            <td [ngStyle]="{'width':'180px'}">
                                                                {{product.siteName}}
                                                            </td>
                                                            
                                                            <td [ngStyle]="{'width':'180px'}">
                                                                {{product.httpsHost}}
                                                            </td>

                                                        </tr>
                                                    </ng-template>

                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                        <tr>
                                                            <td>
                                                                <span class="text-center">
                                                            <h4
                                                                class="ml-2">
                                                                No Record
                                                                Found</h4>
                                                        </span>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>

                                                <p class="paginate_data">
                                                    View per page </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                            
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createWebSitePopup" tabindex="-1" role="dialog" aria-labelledby="createWebSitePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create WebSite</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create WebSite</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createWebSiteForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Web Site ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="websiteID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter WebSite ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Site Name</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="siteName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Site Name">
                                               
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Http Host</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="httpHost" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Http Host">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Http Port</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="httpPort" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Http Port">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Https Host</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="httpsHost" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Https Host">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Https Port</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="httpsPort" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Https Port">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Enable Https</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="yesNo" [options]="yesNoArray"
                                                        placeholder="Choose Option">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Webapp Path</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="webAppPath" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Webapp Path">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Standard Content Prefix</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="standardContentPrefix" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Standard Content Prefix">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Secure Content Prefix</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="secureContentPrefix" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Secure Content Prefix">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Domain Cookie</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="domainCookie" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Domain Cookie">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Visual Theme Set</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                       [options]="VisualSetArray" formControlName="visualThemeSet" 
                                                        placeholder="Choose Visual Theme">

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                  

                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Allow Product Store Change</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        [options]="yesNoArray" formControlName="allowProductStoreChange" 
                                                        placeholder="Choose Product Store">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Product Store ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        [options]="ProductStoreListArray"  formControlName="productStoreID" 
                                                        placeholder="Choose Product Store ID">

                                                        </p-dropdown>
                                                    </div>
                                                 
                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Hosted Path Alias</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="hostedPathAlias" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Hosted Path Alias">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Is Default</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        [options]="yesNoArray" formControlName="isDefault" 
                                                        placeholder="Choose Is Default">

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Display Maintenance Page</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        [options]="yesNoArray"  formControlName="displayMaintenancPage" 
                                                        placeholder="Choose Display Maintenance Page">

                                                        </p-dropdown>
                                                    </div>

                                                   

                                                  
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="createWebSite()">Create</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- <ngx-spinner></ngx-spinner> -->
