import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CRMAccountService } from 'src/app/services/crm-account.service'
import { GlobalResourceService } from 'src/app/services/global-resource.service'
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { SortEvent } from 'primeng/api';
import {
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';


import { FullCalendarComponent, CalendarOptions } from '@fullcalendar/angular';

import dayGridPlugin from '@fullcalendar/daygrid';
import { TrainingApprovalService } from 'src/app/human-resource/training/training-approvals/training-approval.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';

declare const $: any;

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};
@Component({
  selector: 'app-fsm-technician',
  templateUrl: './fsm-technician.component.html',
  styleUrls: ['./fsm-technician.component.css']
})
export class FsmTechnicianComponent implements OnInit {


  show = false;
  createPage: FormGroup
  activeCategory = 2;
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  total = 0;
  rowNumber = 0;
  pageSize = 10;
  rows = 50;
  userLoginIdArray: any[] = [];
  partyId: string;
  SystemInfoNote: any;
  SystemInfoStatus: any;
  id: string;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  activeTabbing = 1;

  options: any;


  trainingApprovalForm: FormGroup;

  calendarValue: any;
  trainingApproval: any;
  trainingApprovalList: any[];
  trainingClassTypes: any[];
  isForm: boolean;
  isUpdate: boolean;
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  calendarEventForm: FormGroup;
  partyIds: any[];
  workEffortId: string;

  date: {
    startDate: string,
    endDate: string
  };
  assignList: any[];
  addCustomer: FormGroup;
  fsmRole: { label: string; value: string; }[];
  updateTechnicianStatus: FormGroup;
  fsmStatus: { label: string; value: string; }[];
  addNewCustomer: FormGroup;
  yesNoArray: { label: string; value: string; }[];
  technicianId: string;
  detailsByTechnicianId: any;
  FSMCountryGeoId: any;
  FSMStateProvId: any;
  FSMStateProvIdArray: any[]=[];
  FSMCountryGeoIdArray: any[]=[];
  fsmRoleArray: { label: string; value: string; }[];
  userTitleArray: { label: string; value: string; }[];
  jobStatus:any;
  ParticipantsList: any;
  isButton: boolean;
  calendarEventForm1: any;
  customerId: any;
  showInvoiceBtn: boolean;
  isUpdateTechStatus :boolean;
  constructor(readonly _CategoryService: CategoryService, readonly _Router: Router,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly trainingApprovalService: TrainingApprovalService,
    readonly accountsService: AccountsService) {
    this.date = {
      startDate: '',
      endDate: ''
    };
    this.calendarEventForm = this._FormBuilder.group({
      description: ['', [Validators.required]],
      estimatedCompletionDate: [''],
      estimatedStartDate: [''],
      workEffortName: ['']
    });

    this.fsmRoleArray = [{
      label: 'Customer Commercial',
      value: 'FMS_CUSTOMER_COM',
    },
    {
      label: 'Customer Residential',
      value: 'FMS_CUSTOMER_RES',
    }

    ]
    this.fsmStatus = [{
      label: 'Pending',
      value: 'Pending',
    },
    {
      label: 'OnHold',
      value: 'OnHold',
    },
    {
      label: 'Completed',
      value: 'Completed',
    }
    ],
    this.userTitleArray = [{
      label: 'Mr.',
      value: 'Mr',
    },
    {
      label: 'Mrs.',
      value: 'Mrs',
    },
    {
      label: 'Ms.',
      value: 'Ms',
    }
    ],
    this.yesNoArray = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      },

    ];

    this.updateTechnicianStatus = this._FormBuilder.group({
      status: [''],
      Description: [''],
      TechnicianName: [''],
    });
    this.addNewCustomer = this._FormBuilder.group({
      techCreatedBy: [''],  /// technician id
      userFirstName: [''],
      userMiddleName: [''],
      userLastName: [''],
      customerEmail: [''],
      customerCountry: [''],
      customerAddress1: [''],
      customerAddress2: [''],
      customerCity: [''],
      customerPostalCode: [''],
      customerState: [''],
      username: [''],
      password: [''],
      confirmPassword: [''],
      passwordHint: [''],
      customerAddressAllowSql: [''],
      customerHomeCountry: [''],
      customerHomeArea: [''],
      customerHomeContact: [''],
      customerHomeExt: [''],
      customerHomeAllowSql: [''],
      customerWorkCountry: [''],
      customerWorkArea: [''],
      customerWorkContact: [''],
      customerWorkExt: [''],
      customerWorkAllowSql: [''],
      customerFaxCountry: [''],
      customerFaxArea: [''],
      customerFaxContact: [''],
      customerFaxAllowSql: [''],
      customerMobileCountry: [''],
      customerMobileArea: [''],
      customerMobileContact: [''],
      customerMobileAllowSql: [''],
      customerEmailAllowSql: [''],
      fmsRole: [''],
      userTitle:['']

    });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.technicianId = localStorage.getItem('partyId');
    this._ActivatedRoute.params.subscribe(params=>{
      this.customerId = params.customerId;
     // this.technicianId = params.technicianId;
    })
    this.showInvoiceBtn = false;
    this.isUpdateTechStatus = false
    
    this.options = {

      defaultDate: '2017-02-01',
      plugins: [dayGridPlugin],
      initialView: 'dayGridMonth',

      header: {
        left: 'prev,next',
        center: 'title',
        right: 'timeGridMonth,timeGridWeek,timeGridDay'
      }
    }

    this.getEvents();
    this.getStateProvId();
    this.getCountryGeoId();
  }
  techJobStatus(){
    this.isUpdateTechStatus = true;
  }
  invoicePage(){
    this._Router.navigate(["fsm/fsm-tech-invoice"],
    {queryParams:{techId:this.technicianId,custId:this.customerId}});

  }
  getStateProvId() {
    this.spinner.show();
    this.accountsService.getStateProvId().subscribe(res => {
      this.FSMStateProvId = res.data[0].getStateProvId;
      this.spinner.hide();
      for (const value of this.FSMStateProvId) {
        this.FSMStateProvIdArray.push({
          label: value.geoName,
          value: value.geoId
        })
      }
    })
  }
  getCountryGeoId() {
    this.spinner.show();
    this.accountsService.getCountryGeoId().subscribe(res => {
      this.FSMCountryGeoId = res.data[0].getCountryGeoId;
      this.spinner.hide();
      for (const value of this.FSMCountryGeoId) {
        this.FSMCountryGeoIdArray.push({
          label: value.geoName,
          value: value.geoId
        })
      }
    })
  }
 
  updateTechnicianPersonal() {
    this.spinner.show();
    const requestData = 
    {
      "technicianId": this.technicianId,
      "technicianName":  this.detailsByTechnicianId.dataTechnician.technicianName,
      "technicianStatus":  this.updateTechnicianStatus.value.status,
      "technicianStatusDescription":  this.updateTechnicianStatus.value.Description,
      "technicianType":  "FMS_TECHNICIAN",
    }
   
    this.myContactsService.updateTechnicianPersonal(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Technician Status Updated Successfully");
        this.spinner.hide();
        if(this.updateTechnicianStatus.value.status=="Completed"){
          setTimeout(() => {
            this.showInvoiceBtn = true;
          }, 1000);
       
        }
        else{
          this.showInvoiceBtn = false;
        }

        this.updateTechnicianStatus.reset();
        this.closebutton2.nativeElement.click();
        this.getEvents();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  technicianCreateCustomer() {
    this.spinner.show();
    const requestData =
    {
      "confirmPassword": this.addNewCustomer.value.confirmPassword,
      "customerAddress1": this.addNewCustomer.value.customerAddress1,
      "customerAddress2": this.addNewCustomer.value.customerAddress2,
      "customerAddressAllowSql": this.addNewCustomer.value.customerAddressAllowSql,
      "customerCity": this.addNewCustomer.value.customerCity,
      "customerCountry": this.addNewCustomer.value.customerCountry,
      "customerEmail": this.addNewCustomer.value.customerEmail,
      "customerEmailAllowSql": this.addNewCustomer.value.customerEmailAllowSql,
      "customerFaxAllowSql": this.addNewCustomer.value.customerFaxAllowSql,
      "customerFaxArea": this.addNewCustomer.value.customerFaxArea,
      "customerFaxContact": this.addNewCustomer.value.customerFaxContact,
      "customerFaxCountry": this.addNewCustomer.value.customerFaxCountry,
      "customerHomeAllowSql": this.addNewCustomer.value.customerHomeAllowSql,
      "customerHomeArea": this.addNewCustomer.value.customerHomeArea,
      "customerHomeContact": this.addNewCustomer.value.customerHomeContact ,
      "customerHomeCountry": this.addNewCustomer.value.customerHomeCountry ,
      "customerHomeExt": this.addNewCustomer.value.customerHomeExt ,
      "customerMobileAllowSql": this.addNewCustomer.value.customerMobileAllowSql ,
      "customerMobileArea": this.addNewCustomer.value.customerMobileArea ,
      "customerMobileContact": this.addNewCustomer.value.customerMobileContact ,
      "customerMobileCountry": this.addNewCustomer.value.customerMobileCountry ,
      "customerPostalCode": this.addNewCustomer.value.customerPostalCode ,
      "customerState": this.addNewCustomer.value.customerState ,
      "customerWorkAllowSql": this.addNewCustomer.value.customerWorkAllowSql ,
      "customerWorkArea": this.addNewCustomer.value.customerWorkArea ,
      "customerWorkContact": this.addNewCustomer.value.customerWorkContact ,
      "customerWorkCountry": this.addNewCustomer.value.customerWorkCountry ,
      "customerWorkExt": this.addNewCustomer.value.customerWorkExt ,
      "fmsRole": this.addNewCustomer.value.fmsRole ,
      "password": this.addNewCustomer.value.password ,
      "passwordHint": this.addNewCustomer.value.passwordHint ,
      "techCreatedBy": "FMS_TECHNICIAN" ,
      "userFirstName": this.addNewCustomer.value.userFirstName ,
      "userLastName": this.addNewCustomer.value.userLastName ,
      "userMiddleName": this.addNewCustomer.value.userMiddleName ,
      "userTitle": this.addNewCustomer.value.userTitle ,
      "username": this.addNewCustomer.value.username ,

    }
    this.myContactsService.technicianCreateCustomer(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.addNewCustomer.reset();
        this.getEvents();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  getAllDetailsByInvoiceId() {
     this.spinner.show();
     this.myContactsService.getAllDetailsByInvoiceId(this.customerId,this.technicianId).subscribe(res => {
      this.detailsByTechnicianId = res.data[0];
       this.spinner.hide();
      
   //   console.log(this.datePipe.transform(1656338823328,'yyyy-MM-dd hh:mm:ss'))
     })
   }
  
  getEvents(): void {
    this.spinner.show();
    
    switch (this.view) {
      case 'month': this.date = {
        startDate: moment().startOf('month').format('yyyy-MM-dd HH:mm:ss'),
        endDate: moment().endOf('month').format('yyyy-MM-dd HH:mm:ss')
      };
        break;
      case 'day': this.date = {
        startDate: moment().startOf('day').format('yyyy-MM-dd HH:mm:ss'),
        endDate: moment().endOf('day').format('yyyy-MM-dd HH:mm:ss')
      };
        break;
      case 'week': this.date = {
        startDate: moment().startOf('week').format('yyyy-MM-dd HH:mm:ss'),
        endDate: moment().endOf('week').format('yyyy-MM-dd HH:mm:ss')
      };
        break;
    }
    this.trainingApprovalService.getAllDetailsByTechnicianId(this.technicianId)
    
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.events = data.data.map(value => {
            for (let index = 0; index <  data.length; index++) {
              title: value.dataCustomer.customerId
            }
            return {
              ...value, ...{
                start: new Date(value.TechnicianDate), 
                end: new Date(value.TechnicianLastDate) ,
                title: value.dataProblem.problemId,
                color: colors.red,
                actions: this.actions,
                allDay: false,
                resizable: {
                  beforeStart: true,
                  afterEnd: true,
                },
                draggable: true,
              }
            }
          });
        }
      });
     
  } 
 
  cancel(): void {
    this.calendarEventForm.reset();
    this.isForm = false;
    this.isUpdate = false;
  }

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt" (click)="update()"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    }
   
  ];

  refresh: Subject<any> = new Subject();
  events: CalendarEvent[];
  
  activeDayIsOpen: boolean = true;

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: any): void {
    
    
    this.technicianId = event.dataTechnician.technicianId
    this.customerId = event.dataCustomer.customerId
    console.log("tech id",event.dataTechnician.technicianId)
    this.calendarValue = {
      eventName: event.dataCustomer.customerId ,
    
       startDate:  event.TechnicianDate? moment(event.TechnicianDate).format('yyyy-MM-dd HH:mm:ss') : '',
       compDate:  event.TechnicianLastDate? moment(event.TechnicianLastDate).format('yyyy-MM-dd HH:mm:ss') : '',
    };
    this.isForm = true;
    this.isUpdate = true;
   
    this.getAllDetailsByInvoiceId();
   
  }
  getParticipants() {
    this.spinner.show();
    this.myContactsService.getParticipants(this.workEffortId).subscribe(res => {
      this.ParticipantsList = res.data[0].data;
      this.spinner.hide();
  
    })
  }
 /*  getEventById(): void {
    this.spinner.show();
    this.trainingApprovalService.getCalendarEventById(this.workEffortId)
      .then(data => {
        if (data.success) {
          this.assignList = data.data.participantsList;
        }
      });
      this.spinner.hide();
  } */
  addEvent(): void {
    this.isForm = true;
    this.isButton = false;
  }
  addNew(): void {
    this.isForm = true;
    this.isButton = false;
    this.isUpdate = false;
  }
  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
 
  addNewLeaves(): void {
    this._Router.navigateByUrl('/add-new-leaves');
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

}

