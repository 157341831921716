import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { FinancialService } from 'src/app/services/financial.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { WarehouseService } from 'src/app/warehouse/warehouse/warehouse.service';

@Component({
  selector: 'app-create-fix-assest',
  templateUrl: './create-fix-assest.component.html',
  styleUrls: ['./create-fix-assest.component.css']
})
export class CreateFixAssestComponent implements OnInit {
  show: boolean;
  createFixAssetForm: FormGroup;
  fixedAssetTypes: any[];
  classEnumId: any[];
  calendar: any[];
  partyIds: any[];
  activeCategory = 2;
  productIds: any[];
  fixAssetIds: any[];
  uomIds: any[];
  roleTypeIds: any[];
  facilityId: any[];
  facilityLocation: any[];
  fixAssetId: string;
  fixAsset: any;
  editMode: boolean;
  constructor(
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _MainCatalogService: MainCatalogService,
    readonly _FinancialService: FinancialService,
    readonly spinner: NgxSpinnerService,
    readonly  _Router: Router,
    readonly _ToastrService: ToastrService,
   
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly _AccountingApService: AccountingApService,
    readonly _WarehouseService: WarehouseService
  ) {
    this.createFixAssetForm = this._FormBuilder.group({
      acquireOrderId: [''], acquireOrderItemSeqId: [''],actualEndOfLife: [''],
      calendarId: [''],classEnumId: [''], dateAcquired: [''],dateLastServiced: [''],dateNextService: [''],
      depreciation: [''],expectedEndOfLife: [''],fixedAssetId: [''],fixedAssetName: [''],
      fixedAssetTypeId: ['COMPUTER_HARDWARE'],
      instanceOfProductId: [''],locatedAtFacilityId: [''],locatedAtLocationSeqId: [''],
      parentFixedAssetId: [''],partyId: [''],productionCapacity: [''],purchaseCost: [''],
      roleTypeId: [''],
      salvageValue: [''],
      serialNumber: [''],
      uomId: ['']
    });
    this.fixedAssetTypes = [];
    this.classEnumId = [];
    this.calendar = [];
    this.partyIds = [];
    this.productIds = [];
    this.fixAssetIds = [];
    this.uomIds = [];
    this.roleTypeIds = [];
    this.editMode = false;
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.fixAssetId = this._ActivatedRoute.snapshot.queryParams.fixedAssetId;
    if (this.fixAssetId) {
      this._FinancialService.getFixAssetById(this.fixAssetId)
        .then(data => {
          if (data.success) {
            this.fixAsset = data.data;
            this.createFixAssetForm.patchValue({
              ...data.data, ...{
                dateAcquired: data.data.dateAcquired ? moment(data.data.dateAcquired).format('YYYY-MM-DD') : '',
                dateLastServiced: data.data.dateLastServiced ? moment(data.data.dateLastServiced).format('YYYY-MM-DD') : '',
                dateNextService: data.data.dateNextService ? moment(data.data.dateNextService).format('YYYY-MM-DD') : '',
                actualEndOfLife: data.data.actualEndOfLife ? moment(data.data.actualEndOfLife).format('YYYY-MM-DD') : '',
                expectedEndOfLife: data.data.expectedEndOfLife ? moment(data.data.expectedEndOfLife).format('YYYY-MM-DD') : '',
              }
            });
            this.createFixAssetForm.controls.fixedAssetId.disable();
            this.createFixAssetForm.controls.fixedAssetId.updateValueAndValidity();this.editMode = true;
            this.getFacilityLocations(data.data.locatedAtFacilityId);
          }
        });
    }
    this.getInitialData();this.getProductIds(); this.getPartyIds();this.getFixAssetIds();this.getUomIds();
    this.getRoleTypeIds();this.getFacilityIds();
    this.createFixAssetForm.controls.locatedAtFacilityId.valueChanges.subscribe(data => {
      if (data) {
        this.getFacilityLocations(data);
      }
    });
  }
  getFacilityLocations(facilityId: string): void {
    this.spinner.show();
    this._WarehouseService.getFacilityLocations(facilityId, { pageNo: 1, pageSize: 200 }, {
      aisle: '',
      area: '',
      level: '',
      locationSeqId: '',
      position: '',
      section: ''
    })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.facilityLocation = data.data.map(value => {
            return {
              label: value.locationSeqId,
              value: value.locationSeqId
            };
          });
        }
      });
     
  }
  getFacilityIds(): void {
    this.spinner.show();
    this._WarehouseService.getFacility()
      .then(data => {
        this.spinner.hide();
        this.facilityId = data.data.map(value => {
          return {
            label: value.facilityName,
            value: value.facilityId
          };
        });
      });
     
  }
  getUomIds(): void {
    this.spinner.show();
    this._AccountingApService.getTermType()
      .then(data => {
        this.spinner.hide();
        this.uomIds = data.data.uom.map(value => {
          return {
            label: value.description,
            value: value.uomId
          };
        });
      });
     
  }
  getRoleTypeIds(): void {
    this.spinner.show();
    this._FinancialService.getRoleTypeIds('all')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.roleTypeIds = data.data.map(value => {
            return {
              label: value.description,
              value: value.roleTypeId
            };
          });
        }
      });
     
  }
  getFixAssetIds(): void {
    this.spinner.show();
    this._FinancialService.getAssets({ pageNo: 1, pageSize: 200 }, { assetType: '', fixedAssetId: '', fixedAssetIdSearchType: 'Contains', fixedAssetName: '', fixedAssetNameSearchType: 'Contains' })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.fixAssetIds = data.data.map(value => {
            return {
              label: value.fixedAssetName,
              value: value.fixedAssetId
            }
          });
        }
      });
     
  }
  getInitialData(): void {
    this.spinner.show();
    this._FinancialService.getInitialAssetData()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.calendar = data.data.calendar.map(value => {
            return {
              label: value.description,
              value: value.calendarId
            };
          });
          this.classEnumId = data.data.classEnumId.map(value => {
            return {
              label: value.description,
              value: value.enumId
            };
          });
          this.fixedAssetTypes = data.data.fixedAssetTypes.map(value => {
            return {
              label: value.description,
              value: value.fixedAssetTypeId
            };
          });
        }
      });
     
  }
  getProductIds(): void {
    this.spinner.show();
    this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 200 }, { internalName: '', internalNameSearchType: 'Contains', productId: '', productIdSearchType: 'Contains' })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.productIds = data.data.map(value => {
            return {
              label: value.productId,
              value: value.productId
            };
          });
        }
      });
     
  }
  getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.partyIds = data.data.PersonsGroups.map(value => {
            return {
              label: value.partyId,
              value: value.partyId
            };
          });
        }
      });
     
  }
  onUpdate() {
    this.spinner.show();
    this._FinancialService.updateFixAsset({
      ...this.fixAsset, ...this.createFixAssetForm.value, ...{
        dateAcquired: this.createFixAssetForm.value.dateAcquired ? moment(this.createFixAssetForm.value.dateAcquired).format('YYYY-MM-DD HH:mm:ss') : '',
        dateLastServiced: this.createFixAssetForm.value.dateLastServiced ? moment(this.createFixAssetForm.value.dateLastServiced).format('YYYY-MM-DD HH:mm:ss') : '',
        dateNextService: this.createFixAssetForm.value.dateNextService ? moment(this.createFixAssetForm.value.dateNextService).format('YYYY-MM-DD HH:mm:ss') : '',
        actualEndOfLife: this.createFixAssetForm.value.actualEndOfLife ? moment(this.createFixAssetForm.value.actualEndOfLife).format('YYYY-MM-DD HH:mm:ss') : '',
        expectedEndOfLife: this.createFixAssetForm.value.expectedEndOfLife ? moment(this.createFixAssetForm.value.expectedEndOfLife).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Updated');
          this.spinner.hide();
          this.createFixAssetForm.controls.fixedAssetId.enable();
          this.createFixAssetForm.controls.fixedAssetId.updateValueAndValidity();
          this.cancelSubmit();
        }
      });
 
  }
  onSubmit() {
    this.spinner.show();
    this._FinancialService.createFixAsset({
      ...this.createFixAssetForm.value, ...{
        dateAcquired: this.createFixAssetForm.value.dateAcquired ? moment(this.createFixAssetForm.value.dateAcquired).format('YYYY-MM-DD HH:mm:ss') : '',
        dateLastServiced: this.createFixAssetForm.value.dateLastServiced ? moment(this.createFixAssetForm.value.dateLastServiced).format('YYYY-MM-DD HH:mm:ss') : '',
        dateNextService: this.createFixAssetForm.value.dateNextService ? moment(this.createFixAssetForm.value.dateNextService).format('YYYY-MM-DD HH:mm:ss') : '',
        actualEndOfLife: this.createFixAssetForm.value.actualEndOfLife ? moment(this.createFixAssetForm.value.actualEndOfLife).format('YYYY-MM-DD HH:mm:ss') : '',
        expectedEndOfLife: this.createFixAssetForm.value.expectedEndOfLife ? moment(this.createFixAssetForm.value.expectedEndOfLife).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Created');
          this.spinner.hide();
          this.cancelSubmit();
        }
      });
     
  }
  cancelSubmit(): void {
    this.createFixAssetForm.reset();
    this._Router.navigate(['financial/fix-assest-list']);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
