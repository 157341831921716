import { Component, OnInit,Inject } from '@angular/core';
import { SortEvent } from 'primeng/api';
import {Responsibilities} from "./responsibilities.service";
import {ResponsibilitiesService} from "./responsibilities-service";
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";
import { DOCUMENT } from '@angular/common';
import { TrainingTypeModel } from 'src/app/setting/training-type-model';
import {Settings} from "../../setting/settings.service";
import { NgxSpinnerService } from 'ngx-spinner';





@Component({
  selector: 'app-responsibilities',
  templateUrl: './responsibilities.component.html',
  styleUrls: ['./responsibilities.component.css'],
  providers:[DatePipe]
})
export class ResponsibilitiesComponent implements OnInit {
  total=0;
  activeCategoryMain=2;
  rowNumber=0;
  pageNo=1;
  pageSize = 10;
  rows = 50;
  public settingData = {
    pageNo:this.pageNo,
    pageSize:this.pageSize
  }
  responsibilitiesList: any;
  button: boolean=false;
  responsibilitytype: any;
  responsibilityArray: any=[];
  positionData: any;
  positionArray: any=[];
  updateefromdateStatus: string;
  updateethrdateStatus: string;
  updateefromdate: string;
  updateethrdate: string;
  constructor(readonly responsibilities:Responsibilities,
    public responsibilitiesService:ResponsibilitiesService,
    readonly toastr:ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly router:Router,
    readonly settings:Settings,
    @Inject(DOCUMENT) readonly document: Document,
    readonly datePipe: DatePipe) { }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getPositionType();
    this.getResponsibilityType();
    this.responsibilitiesService.responsibilities_form=null;
    this.responsibilitiesService.responsibilitiesForm(this.responsibilitiesService.responsibilities_form);
    this.getAllResponsibilities();
  }
  getPositionType(){
    this.spinner.show();
    this.settings.getPositionType().subscribe((res:any)=>{
      this.positionData = res.data.leaveTypeId;
      this.spinner.hide();
      for(const value of this.positionData){
        this.positionArray.push({
          label:value.description,
          value:value.emplPositionTypeId
        })
      }
    })
    
  }
  
  fillData(emplPositionTypeId,responsibilityTypeId,fromDate,thruDate,comments){
    let fromDates = this.datePipe.transform(fromDate,'yyyy-MM-dd');
    let thruDates = this.datePipe.transform(thruDate,"yyyy-MM-dd")
    const employeeApplication = this.responsibilitiesService.responsibilities_form;
    employeeApplication.patchValue({
      emplPositionTypeId:emplPositionTypeId,
      responsibilityTypeId:responsibilityTypeId,
      comments:comments,
      fromDate:fromDates,
      thruDate:thruDates
    });
    if(emplPositionTypeId){
      this.button = true;
    }
    else {
      this.button=false;
    }
  }
  onUpdate(){
    this.spinner.show();
    const fromdate=this.responsibilitiesService.responsibilities_form.get('fromDate').value;
    this.updateefromdate=this.datePipe.transform(fromdate,"yyyy-MM-dd hh:mm:ss");
    const thrudate=this.responsibilitiesService.responsibilities_form.get('thruDate').value;
    this.updateethrdate=this.datePipe.transform(thrudate,"yyyy-MM-dd hh:mm:ss");
    const formValue={
      comments:this.responsibilitiesService.responsibilities_form.value.comments,
      emplPositionTypeId: this.responsibilitiesService.responsibilities_form.value.emplPositionTypeId,
      fromDate:  this.updateefromdate,
      responsibilityTypeId: this.responsibilitiesService.responsibilities_form.value.responsibilityTypeId,
      thruDate:  this.updateethrdate
    }
    this.responsibilities.updateResponsibility(formValue).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.getAllResponsibilities();
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   }) }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  getAllResponsibilities(){
    this.spinner.show();
    this.responsibilities.getResponsibilities(this.settingData).subscribe((res:any)=> {
      this.responsibilitiesList = res.data
      this.spinner.hide();
    })
   
  }
  onCancel(){
    this.button=false;
    this.responsibilitiesService.responsibilities_form.reset();
  }

  getResponsibilityType(){
    this.spinner.show();
    this.settings.getResponsibility().subscribe(res=>{
      this.responsibilitytype = res.data.leaveTypeId;
      this.spinner.hide();
      for(const value of  this.responsibilitytype){
        this.responsibilityArray.push({
          label:value.description,
          value:value.responsibilityTypeId
        })
      }
    })
   
  }
  onSubmit(){
    this.spinner.show();
    const fromdate=this.responsibilitiesService.responsibilities_form.get('fromDate').value;
    this.updateefromdateStatus=this.datePipe.transform(fromdate,"yyyy-MM-dd hh:mm:ss");
    const thrudate=this.responsibilitiesService.responsibilities_form.get('thruDate').value;
    this.updateethrdateStatus=this.datePipe.transform(thrudate,"yyyy-MM-dd hh:mm:ss");
    const formValue={
      comments:this.responsibilitiesService.responsibilities_form.value.comments,
      emplPositionTypeId: this.responsibilitiesService.responsibilities_form.value.emplPositionTypeId,
      fromDate:  this.updateefromdateStatus,
      responsibilityTypeId: this.responsibilitiesService.responsibilities_form.value.responsibilityTypeId,
      thruDate:  this.updateethrdateStatus
    } 
    this.responsibilities.createResponsibility(formValue).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.getAllResponsibilities();
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
   }

  onDelete(responsibilityTypeId,emplPositionTypeId,fromDate){
    this.spinner.show();
    let date = this.datePipe.transform(fromDate,'yyyy-MM-dd');
     this.responsibilities.deleteValidResponsibility(responsibilityTypeId,emplPositionTypeId,date).subscribe((res:any)=> { 
      if(res.success) {
        this.spinner.hide();
        this.toastr.success("Deleted Successfully");
       
        this.getAllResponsibilities();

      }

      else  {
        this.toastr.error("Error");
        this.spinner.hide();
		

      }     })
    
  }
  detailPage(eempId,eresId,ecomment,efDate,etDate){
    this.router.navigate(["/hr/detail-res-summary"], { queryParams: { empId:eempId, res:eresId,comment:ecomment,fDate:efDate,tDate:etDate} })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
