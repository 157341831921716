import { Component, OnInit } from '@angular/core';
import { CreateOppourtunityFormService } from './create-oppourtunity-service';
import { OppourtunitiesService } from '../oppourtunities.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common'
import { NgxSpinnerService } from 'ngx-spinner';



declare var $: any;
@Component({
  selector: 'app-create-opportunity',
  templateUrl: './create-opportunity.component.html',
  styleUrls: ['./create-opportunity.component.css']
})
export class CreateOpportunityComponent implements OnInit {
  currencyArray: any = [];
  getCurrencyList: any;
  oppourtunityId: any;
  activeCategory = 2;
  show = false;
  test = [new Date(), new Date()];
  mainData: any;
  initialArray: any = [];
  initialTypeArray: any = [];
  marketCompaignArray: any = [];
  sourceArray: any = [];
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  search = ''
  rows = 50;
  accountsList: any;
  contactMechId = '';
  firstNameSearchType = "Contains";
  firstName = "";
  lastName = "";
  lastNameSearchType = "Contains";
  public groupName = "";
  public groupNameSearchType = "Contains";
  public partyId= "";
  public partyIdSearchType = "Contains";
  contactMechTypeId = "";
  initialAccount: any;
  initialAccountArray: any = [];
  leadsLists: any;
  leadPartyIdd: any = [];
  nextStepDate;
  closeDate;


  constructor(public createOppourtunityFormService: CreateOppourtunityFormService,
    public oppourtunitiesService: OppourtunitiesService,
    readonly activatedRoute: ActivatedRoute,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly spinner:NgxSpinnerService,
    public datepipe: DatePipe
  ) { }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getCurrency();
    this.getDropdown();
    this.getLeadList();
    this.getAccountList();
    this.activatedRoute.queryParams.subscribe(params => {
      this.oppourtunityId = params['oppourtunityId'];
    })

    this.createOppourtunityFormService.create_oppourtunity_form = null;
    this.createOppourtunityFormService.prepareOppourtunityForm(this.createOppourtunityFormService.create_oppourtunity_form);
    $('.right-arrow').click(function () {
      $(this).toggleClass('rotate-up');
    });
    if (this.oppourtunityId) {
      this.show = true;
      this.getOpportunityDetails();
    }
    else {
      this.show = false;
      throw "invalid Id"
    }
  }

  getCurrency() {
    this.spinner.show();
    this.oppourtunitiesService.getCurrency().subscribe(res => {
      this.getCurrencyList = res.data.currencyList;
      this.getCurrencyList.forEach(currencyList => {
        this.currencyArray.push({
          label: currencyList.description + "-" + currencyList.uomId,
          value: currencyList.uomId


        })
      })
    })
    this.spinner.hide();
  }
  getDropdown() {
    this.spinner.show();
    this.oppourtunitiesService.getInitialStage().subscribe(res => {
      this.mainData = res.data;
      for (const value of this.mainData.initialStage) {
        this.initialArray.push({
          label: value.description,
          value: value.opportunityStageId
        })
      }

      for (const value of this.mainData.type) {
        this.initialTypeArray.push({
          label: value.description,
          value: value.enumId
        })
      }
      for (const value of this.mainData.marketingCampaign) {
        this.marketCompaignArray.push({
          label: value.campaignName,
          value: value.marketingCampaignId
        })
      }
      for (const value of this.mainData.source) {
        this.sourceArray.push({
          label: value.description,
          value: value.dataSourceId
        })
      }
    })
    this.spinner.hide();
  }
  navigate() {
    this.router.navigate(["/crm/opportunities"]);
  }
  getOpportunityDetails() {
    this.spinner.show();
    this.oppourtunitiesService.getOppourtunityDetails(this.oppourtunityId).subscribe(res => {

      this.nextStepDate = res.data.nextStepDate;
      this.closeDate = res.data.estimatedCloseDate;
    
      const formValue = this.createOppourtunityFormService.create_oppourtunity_form;
      formValue.patchValue({
        oppurtunityId: res.data.salesOpportunityId,
        opportunityName: res.data.opportunityName,
        accountPartyId: res.data.initialAccount.partyId.trim(),
        leadPartyId: res.data.leadName.partyId.trim(),
        estimatedamount: res.data.estimatedAmount,
        probablity: res.data.estimatedProbability,
        initialStage: res.data.opportunityStageId,
        nextStep: res.data.nextStep,
        type: res.data.typeEnumId,
        marketing_campaign: res.data.marketingCampaignId,
        currencyId: res.data.currencyUomId,
        source: res.data.dataSourceId,
        description: res.data.description,
      })
      this.spinner.hide();
    })
   
  }

  public getLeadList() {
    this.spinner.show();
    const leadAdvancedSearch = {
      contactMechId: this.contactMechId,
      firstName: this.firstName,
      firstNameSearchType: this.firstNameSearchType,
      lastName: this.lastName,
      lastNameSearchType: this.lastNameSearchType,
      partyId: this.partyId,
      partyIdSearchType: this.partyIdSearchType
    }
    const contacts = {
      pageNo: this.pageNo,
      pageSize: this.pageSize,
      isMyLead: "false"
    }
    this.oppourtunitiesService.getLeadsList(contacts, leadAdvancedSearch).subscribe(res => {
      this.leadsLists = res.data;
      this.spinner.hide();
      for (const value of this.leadsLists) {
        this.leadPartyIdd.push({
          label: value.partyId.trim(),
          value: value.partyId.trim()
        })
      }
    })
    
  }

  getAccountList() {
    this.spinner.show();
    const allReq = {
      "pageNo": this.pageNo,
      "pageSize": this.pageSize,
      "all": "true"
    }
    const searchAccountForm = {
      contactMechTypeId: this.contactMechTypeId,
      groupName: this.groupName,
      groupNameSearchType: this.groupNameSearchType,
      partyId: this.partyId,
      partyIdSearchType: this.partyIdSearchType
    }
    this.oppourtunitiesService.getAccountList(allReq, searchAccountForm).subscribe(res => {
     
      this.initialAccount = res.data;
      this.spinner.hide();
      for (const value of this.initialAccount) {
        this.initialAccountArray.push({
          label: value.partyId.trim(),
          value: value.partyId.trim()
        })
      }
    })
   
  }

  onSubmit() {
    this.spinner.show();
    const formValues = { ...this.createOppourtunityFormService.create_oppourtunity_form.value }
    this.oppourtunitiesService.createOppourtunity(formValues).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data);
        this.spinner.hide();
        this.router.navigate(['/crm/opportunities'])
      }
      else if (res.success == false) {
        this.spinner.hide();
        this.toastr.error(res.message);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
   
  }

  onUpdate() {
    this.spinner.show();
    let formValues = { ...this.createOppourtunityFormService.create_oppourtunity_form.value }

    this.oppourtunitiesService.updateOppourtunity(formValues, this.oppourtunityId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data);
        this.spinner.hide();
        this.router.navigate(['/crm/opportunities'])
      }
      else if (res.success == false) {
        this.spinner.hide();
        this.toastr.error(res.message);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })
   
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}


