<div class="container-fluid main-container-wrapper">
    <div class="row">
      <div class="col-12">
        <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
          <span>
             
          </span>
          <span>
           
          
          </span>
      </div>
        <div class=" bg-white color-grey create-new-table">
          <div>
            <div class="container-fluid">
              <div class="row">
  
                <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                  <div class="w-100">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                      <div class="panel panel-default">
                        <div class="w3-card-4 classCard" >
                        <div class="container-fluid">
                          <ul class="tabbing-section tabbing-accordians tabClass">
                        <li [ngClass]="activeCategory==1?'active':''" ><a>
                          Return Items </a></li>
                       
                        </ul>
                        </div>
                       
                        <div id="collapsetwo" class="panel-collapse collapse show">
                            <div class="panel-body a">
                              <div class="mt-2">
                                <div class="">
                                  <div class="row m-0">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                      <div class="col-lg-12 col-12 d-flex details-offer">
                                        <p  class="common-regular-properties" style="margin-right:10px !important;">Return Item(s) From Order
                                        </p>
                                        <a  class="account-button" >{{ this.orderId}}</a> 
                                      </div>
                                      <div class="col-lg-12 col-12  d-flex details-offer">
                                        <span class="common-bold-properties" style="margin-right:10px;">Order Total</span> 
                                        <p class="common-regular-properties">${{ this.orderTotal}}
                                        </p>
                                      </div>
                                      <div class="col-lg-12 col-12 details-offer d-flex">
                                        <span class="common-bold-properties" style="margin-right:10px;">Amount Already Credited</span> 
                                        <p class="common-regular-properties">${{this.amountAlreadyCredited}}
                                        </p>
                                      </div>
                                      <div class="col-lg-12 col-12 d-flex details-offer">
                                        <span class="common-bold-properties" style="margin-right:10px;">Amount Already Refunded</span> 
                                        <p class="common-regular-properties">${{this.amountAlreadyRefunded}}
                                        </p>
                                      </div>
                                      <div class="col-lg-12 col-12 d-flex details-offer">
                                        <span class="common-bold-properties" style="margin-right:10px;">Select All</span> 
                                        <p class="common-regular-properties"> <p-checkbox (click)="selectAll($event);" styleclass="custom-checkbox" binary="true"></p-checkbox>
                                        </p>
                                      </div>
                                   </div>
                                    <div class="card return-items-table own-account-tables">
                                    <p-table [resizableColumns]="true" columnResizeMode="expand" scrollWidth="100%" [paginator]="false" [rows]="rows"
                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [lazy]="true" [totalRecords]="total" [value]="newArray"
                                    [scrollable]="true" styleClass="tags-table   offerShowing-table offerShowingLine-table custom-dataTable table-striped">
                                    <ng-template pTemplate="colgroup" let-columns>
                                        <colgroup>
                                            <col *ngFor="let col of offerShowingLineColoumns">
                                        </colgroup>
                                    </ng-template>
                                    <ng-template pTemplate="header">
                                      <tr class="activity-rows">
                                        <th id="tableHead" class="tabTh" *ngFor="let col of offerShowingLineColoumns">
                                          <ng-container style="color: white!important;">
                                              {{col.header}}
                                          </ng-container>
                                      </th>
                                      </tr>
                                  </ng-template>
                                  <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-activity  let-rowIndex="rowIndex">
                                      <tr class="activity-data" >
                                          <td *ngFor="let col of offerShowingLineColoumns">
                                            <ng-container *ngIf="col.field === 'des'" >
                                              <span *ngIf="activity.productId">{{activity.productId}} {{activity.itemDescription}}</span> 
                                              <span> {{activity.description}}</span> 
                                            </ng-container>  
                                            <ng-container *ngIf="col.field === 'order_qty'">
                                              <span class="d-flex red-border align-items-center">
                                              <div class="name_text">
                                                <a>
                                                  {{activity.quantity}}
                                                </a>
                                              </div>
                                            </span>
                                            </ng-container>
                                            <ng-container *ngIf="col.field === 'return_qty'">
                                              <span  class="d-block align-items-center"  *ngIf="activity.returnQuantity">
                                               <div  class="name_text">
                                                <input type="text" style="width: 100%;"
                                                                                        id="col{{rowIndex+1}}"
                                                                                        [(ngModel)]="activity.returnQuantity"
                                                                                        [ngModelOptions]="{standalone: true}" />
                                             </div>
                                           </span>
                                             </ng-container>  
                                            <ng-container *ngIf="col.field === 'unit_price'"><span>
                                               {{activity.returnPrice}}</span>
                                              
                                            </ng-container>
                                            <ng-container *ngIf="col.field === 'return_price'">
                                              <span  class="d-block align-items-center" *ngIf="activity.returnPrice">
                                                <div  class="name_text">
                                                    <input type="text" style="width: 100%;"
                                                    id="returnPrice{{rowIndex+1}}"
                                                    [(ngModel)]="activity.returnPrice"
                                                    [ngModelOptions]="{standalone: true}" />
                                              </div>
                                            </span>
                                            <span  class="d-block align-items-center">
                                              <div  class="name_text">
                                                 {{activity.amount}}
                                            </div>
                                          </span>
                                           </ng-container>
                                           <ng-container *ngIf="col.field === 'reason'">
                                            <span *ngIf="activity.productId">
                                              <select style="width: 134px;"  
                                              id="returnReasonId{{rowIndex+1}}"
                                                    [(ngModel)]="activity.returnReasonId"
                                                    [ngModelOptions]="{standalone: true}">
                                     <option *ngFor="let type of reasonType" [value]="type.returnReasonId" >{{type.description}} </option>
                                        </select>
                                            
                                            </span>
                                          </ng-container>
                                          <ng-container *ngIf="col.field === 'type'">
                                            <span>
                                              <select style="width: 134px;"
                                              id="returnTypeId{{rowIndex+1}}"
                                                    [(ngModel)]="activity.returnTypeId"
                                                    [ngModelOptions]="{standalone: true}">
                                     <option *ngFor="let type of returnTypes" [value]="type.returnTypeId" >{{type.description}} </option>
                                        </select>
                                            
                                            </span>
                                          </ng-container>
                                          <ng-container *ngIf="col.field === 'item_status'">
                                            <span *ngIf="activity.productId"> 
                     
                                              <select style="width: 134px;"
                                              id="expectedItemStatus{{rowIndex+1}}"
                                              [(ngModel)]="activity.expectedItemStatus"
                                              [ngModelOptions]="{standalone: true}">
                                     <option *ngFor="let mission of itemStts" [value]="mission.statusId" >{{mission.description}} </option>
                                        </select>
                                          </span>
                                          </ng-container>
                                          <ng-container *ngIf="col.field === 'includes'">
                                            <span>
                                              <p-checkbox (click)=productSelect(activity); styleclass="custom-checkbox" binary="true"></p-checkbox>
                                            </span>
                                          </ng-container>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                               
                                </div>
                               
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                  <div class="col-lg-12 col-12 d-flex details-offer">
                                    <p  class="common-regular-properties" style="margin-right:10px !important;">Return Order Adjustment(s) From Order Nbr
                                    </p>
                                    <a  class="account-button" >{{this.orderId }}</a> 
                                  </div>
                                  <div class="col-lg-12 col-12  d-flex details-offer">
                                    <div class="card return-items-table own-account-tables">
                                    <p-table [resizableColumns]="true" style="width: 60%;"
                                     columnResizeMode="expand" scrollWidth="100%" [paginator]="false" [rows]="rows"
                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [lazy]="true" [totalRecords]="total" [value]="orderHeaderAdjustmentMap"
                                    [scrollable]="true" styleClass="tags-table   offerShowing-table offerShowingLine-table custom-dataTable table-striped">
                                    <ng-template pTemplate="colgroup" let-columns>
                                        <colgroup>
                                            <col *ngFor="let col of offerShowingLine">
                                        </colgroup>
                                    </ng-template>
                                    <ng-template pTemplate="header">
                                      <tr class="activity-rows">
                                        <th id="tableHead" class="tabTh" *ngFor="let col of offerShowingLine">
                                          <ng-container style="color: white!important;">
                                              {{col.header}}
                                          </ng-container>
                                      </th>
                                      </tr>
                                  </ng-template>
                                  <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-activity  let-rowIndex="rowIndex">
                                      <tr class="activity-data" >
                                          <td *ngFor="let col of offerShowingLine">
                                            <ng-container *ngIf="col.field === 'des'" >
                                             
                                              <span> {{activity.description}}</span> 
                                            </ng-container>  
                                           
                                            <ng-container *ngIf="col.field === 'order_qty'">
                                              <span  class="d-block align-items-center">
                                               <div  class="name_text">
                                                <input type="text" style="width: 100%;"
                                                                                        id="col{{rowIndex+1}}"
                                                                                        [(ngModel)]="activity.amount"
                                                                                        [ngModelOptions]="{standalone: true}" />
                                             </div>
                                           </span>
                                             </ng-container>  
                                          
                                          
                                          
                                          <ng-container *ngIf="col.field === 'return_qty'">
                                            <span>
                                              <select style="width: 134px;"
                                              id="returnTypeId{{rowIndex+1}}"
                                                    [(ngModel)]="activity.returnTypeId"
                                                    [ngModelOptions]="{standalone: true}">
                                     <option *ngFor="let type of returnTypes" [value]="type.returnTypeId" >{{type.description}} </option>
                                        </select>
                                            
                                            </span>
                                          </ng-container>
                                       
                                          <ng-container *ngIf="col.field === 'unit_price'">
                                            <span>
                                              <p-checkbox (click)=adjustSelect(activity); styleclass="custom-checkbox" binary="true"></p-checkbox>
                                            </span>
                                          </ng-container>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                </div>
                       
                                  </div>
    
                                  <div class="col-lg-12 col-12 d-flex details-offer">
                                    <p  class="common-regular-properties" style="margin-right:10px !important;">Manual Return Adjustment For Order Nbr
                                    </p>
                                    <a  class="account-button" >{{this.orderId }}</a> 
                                  </div>
    
                                  <div  class="all-users-infomation return-items-infos font-13 font-medium own-user-select">
                                  <div class="col-lg-3 col-12">
                                    <div class="form-group">
                                      <input type="text"  class="form-control"
                                      id="orderAdjustmentId"
                                      [(ngModel)]="morderAdjustmentId"
                                      [ngModelOptions]="{standalone: true}"
                                        placeholder="Enter Order Id">
                                    </div>
                                  </div>
                                  <div class="col-lg-3 col-12">
                                    <div class="form-group">
                                      <input type="text"  class="form-control"
                                      id="amount"
                                      [(ngModel)]="mamount"
                                      [ngModelOptions]="{standalone: true}"
                                        placeholder="Enter">
                                    </div>
                                  </div>
                                  <div class="col-lg-3 col-12">
                                    <div class="form-group">
                                      <p-dropdown [options]="returnTypes"  id="returnTypeId"
                                      [(ngModel)]="mreturnTypeId"
                                      [ngModelOptions]="{standalone: true}"
                                       placeholder="Select" optionLabel="description"></p-dropdown>
                                    </div>
                                  </div>
                                  <div class="col-lg-3 col-12">
                                    <div class="form-group">
                                      <p-checkbox  styleclass="custom-checkbox" binary="true" ></p-checkbox>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-12 col-12 main-submit-button"><button style="width: 16%!important;" (click)="onSubmit();"  type="submit" class="btn btn-secondary submit-btn">Return Selected Item</button></div>
                               </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          </div>
    
    
    
    
    
                      </div>
                    </div>
                  </div>
                </div>
             
                 </div>
              </div>
            </div>
          </div>
        </div> 
      </div>
      <ngx-spinner></ngx-spinner> 
     
  