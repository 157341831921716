import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { ProductionService } from '../production.service';
@Component({
  selector: 'app-create-run-mrp',
  templateUrl: './create-run-mrp.component.html',
  styleUrls: ['./create-run-mrp.component.css']
})
export class CreateRunMrpComponent implements OnInit {
  createRunMrp:FormGroup;
  activeCategory=2;
  activeCategoryMain=1;
  getFacilityGroupArray: any[]=[];
  getFacilityGroup: any;
  MrpFacilities: any;
  MrpFacilitiesArray: any[]=[];

  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
   
    readonly productionService:ProductionService,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    private _location: Location,
    readonly _ToastrService: ToastrService,) { 
      this.createRunMrp = this._FormBuilder.group({
        MrpName: [''],
        FacilityGroup:[''],
        Facility: [''],
        DefaultYearsOffset:['1']
      
       
       
      });
    
  }
 
  ngOnInit(): void {
    this.getparentGroupList();
    this.getMrpFacilities();
  }
  RunMRP() {
    this.router.navigate(["/production/create-run-mrp"])

  }

  MRPLog() {
    this.router.navigate(["/production/mrp-log-list"])

  }
  getMrpFacilities() {
    this.spinner.show();
    this.productionService.getMrpFacilities().subscribe(res => {
      this.MrpFacilities = res.data;
      this.spinner.hide();
      for (const value of this.MrpFacilities) {
        this.MrpFacilitiesArray.push({
          label: value.facilityName,
          value: value.facilityId
        })
      }
    })
   
  }
  homeButton(){
    this.router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  getparentGroupList() {
    this.spinner.show();
    this.productionService.getparentGroupList().subscribe(res => {
      this.getFacilityGroup = res.data;
      this.spinner.hide();
      for (const value of this.getFacilityGroup) {
        this.getFacilityGroupArray.push({
          label: value.facilityGroupName,
          value: value.facilityGroupId
        })
      }
    })
   
  }
  onSubmit() {
    this.spinner.show();
      const requestData = {
      "defaultYearsOffset":this.createRunMrp.value.DefaultYearsOffset,
      "facilityGroupId":this.createRunMrp.value.FacilityGroup,
      "facilityId": this.createRunMrp.value.Facility,
      "mrpName": this.createRunMrp.value.MrpName,
    }
    this.productionService.postProdMrp(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully"); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }

  
  
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}

