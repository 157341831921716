import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
export class OrderListComponent implements OnInit {
  @ViewChild('closebutton')closebutton;
  @ViewChild('closebutton2')closebutton2;
  @ViewChild('closebutton3')closebutton3;
  @ViewChild('closebutton4')closebutton4;
  activeCategory = 2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  advanceOrder: { Status: string; Type: string; Filter: string; purchaseorders: string; };
  OrderList: any[];
  typeId: { label: string; value: string; }[];
  statusId: { label: string; value: string; }[];
  purchaseOrder: { label: string; value: string; }[];
  problemsArray: { label: string; value: string; }[];
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) {
    this.advanceOrder = {
      Status: "",
      Type: "",
      Filter: "",
      purchaseorders: "",
     
    };
    this.statusId = [
      {
        label : 'All',
        value : ''
      },
      {
        label : 'Created',
        value : 'ORDER_CREATED'
      },
      {
        label : 'Processing',
        value : 'ORDER_PROCESSING'
      },
      {
        label : 'Approved',
        value : 'ORDER_APPROVED'
      },
      {
        label : 'Held',
        value : 'ORDER_HOLD'
      },
      {
        label : 'Completed',
        value : 'ORDER_COMPLETED'
      },
      {
        label : 'Rejected',
        value :'ORDER_REJECTED'
      },
      {
        label : 'Cancelled',
        value :'ORDER_CANCELLED'
      },
    ]
    this.typeId = [
      {
        label : 'Sales Order',
        value : 'SALES_ORDER'
      },
      {
        label : 'Purchase Order',
        value : 'PURCHASE_ORDER'
      },
      
     
     
    ]
    this.problemsArray = [
      {
        label : ' Inventory problems',
        value : 'filterInventoryProblems'
      },
      {
        label : ' Authorisation Problems',
        value : 'filterAuthProblems'
      },
    ]
    this.purchaseOrder = [
      {
        label : 'partially received',
        value : 'filterPartiallyReceivedPOs'
      },
      {
        label : ' open past their ETA',
        value : 'filterPOsOpenPastTheirETA'
      },
      {
        label : ' with rejected items',
        value : 'filterPOsWithRejectedItems'
      }           
    ]
   }

  ngOnInit(): void {
   this.getfindOrderList();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/order/main-order']);
  }
  
  findOrderPage(id){
    this._Router.navigate(['order/sales-order-view-page'],{ queryParams: { orderId:id} })
  }
  getfindOrderList() {
    this.spinner.show();
    let req = {
      "status": "",
      "type": "",
      "filter": "",
      "filterPurchaseOrder": "",
    }
    this.myContactsService.findOrderList(req).subscribe((res: any) => {
      this.OrderList = res.data;
      this.spinner.hide();
    })
  }

  reset() {
    this.spinner.show();
    this.advanceOrder.Status = "";
    this.advanceOrder.Type = "";
    this.advanceOrder.Filter = "";
    this.advanceOrder.purchaseorders = "";
    const req = {
      "status": "",
      "type": "",
      "filter": "",
      "filterPurchaseOrder": "",
    }
    this.myContactsService.findOrderList(req).subscribe((res: any) => {
      this.OrderList = res.data;
      this.spinner.hide();
    })
   
  }
  findOrderList(): void {
    this.spinner.show();
    this.OrderList = [];
    const req = {
      "status":   this.advanceOrder.Status ?  this.advanceOrder.Status : '',
      "type":  this.advanceOrder.Type ?  this.advanceOrder.Type : '',
      "filter":  this.advanceOrder.Filter ?  this.advanceOrder.Filter : '',
  "filterPurchaseOrder":  this.advanceOrder.purchaseorders ?  this.advanceOrder.purchaseorders : ''

    }
    this.myContactsService.findOrderList( req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.OrderList = resp.data;
        } 
      });
     
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
