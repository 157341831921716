import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AddFormService } from '../recuitment/create-internal-job-posting/create-internal-job-service';
import { CreateInternalService } from '../recuitment/create-internal-job-posting/create-internal-job.service';

declare var $: any;
@Component({
  selector: 'app-detail-internal-job-summary',
  templateUrl: './detail-internal-job-summary.component.html',
  styleUrls: ['./detail-internal-job-summary.component.css']
})
export class DetailInternalJobSummaryComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  updateId: any;
  activeCategory=1;
  posting: any;
  show: boolean;
  jobRequistionId: any;
  approveParty: any;
  approveData: any[]=[];
  updateestifromdateUpdate: string;
  jobRequestion: any;
  getJobRequistionArray: any[]=[];
  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly createInternalService:CreateInternalService,
    public addFormService:AddFormService,
    readonly spinner:NgxSpinnerService,
    readonly toastr:ToastrService,
    readonly router:Router,
    readonly datePipe: DatePipe,
  ) { }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.updateId=params["updateId"];
    })
    this.getJobRequistionId();

    this.activatedRoute.queryParams.subscribe(params=> {
      this.jobRequistionId=params["jobRequistionId"];
    })
    this.activatedRoute.queryParams.subscribe(params=> {
      this.updateId=params["updateId"];
      if(this.updateId) {
        this.show=true;
      }
      else 
      {
        this.show=false;
      }
    })
    this.addFormService.add_job_form=null;
    this.addFormService.addJobForm(this.addFormService.add_job_form);
    const form = this.addFormService.add_job_form;
    setTimeout(() => {
      const formValue = this.addFormService.add_job_form;
    formValue.patchValue({
      jobRequisitionId:this.jobRequistionId 
    })
    }, 3000);
  
    this.getApproverParty();
    this.getJobRequistionList();
    this.getJobRequistionIdNew();
    this.activatedRoute.queryParams.subscribe(params=> {
      this.updateId = params["updateId"];
    })
    $('.right-arrow').click(function(){
      $(this).toggleClass('rotate-up');
    });
   
  }
  getApproverParty(){
    this.spinner.show();
    this.createInternalService.getApproverParty().subscribe(res=> {
      this.approveParty = res.data.PersonsGroups;
      this.spinner.hide();
      this.approveParty.forEach(data=> {
        this.approveData.push({
          label:data.partyId,
          value:data.partyId
        })
        
      })
    })
   
  }
  onUpdate() {
    this.spinner.show();
    let fromdate=this.addFormService.add_job_form.get('applicationDate').value;
    this.updateestifromdateUpdate=this.datePipe.transform(fromdate,"yyyy-MM-dd hh:mm:ss");
    const formData={
  "applicationDate":this.updateestifromdateUpdate,
  "approverPartyId": this.addFormService.add_job_form.value.approverPartyId,
  "jobRequisitionId": this.addFormService.add_job_form.value.jobRequisitionId,
  "statusId": ""
    }
  
    this.createInternalService.updateForm(formData, this.updateId).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.router.navigate(['/hr/job-requisition/internal-job-posting'])
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
  
  }
  getJobRequistionList() {
    this.spinner.show();
    const jobRequistion = {
      examTypeEnumId: "",
      experienceMonthSearchType: "Contains",
      experienceMonthSecond: "",
      experienceMonthSecondSearchType: "Contains",
      experienceMonths: "",
      experienceYearSecond: "",
      experienceYearSecondSearchType: "Contains",
      experienceYears: "",
      experienceYearsSearchType: "Contains",
      jobLocation: "",
      jobLocationSearchType: "Contains",
      jobPostingTypeEnumId: "",
      jobRequisitionId: "",
      skillTypeId: ""
    }
    this.createInternalService.getJobRequistionList(jobRequistion).subscribe(res=> {
      this.jobRequestion=res.data;
      this.spinner.hide();
     for(const value of this.jobRequestion){
       this.getJobRequistionArray.push({
         label:value.jobRequisitionId,
         value:value.jobRequisitionId
       })
     }
    
    })
   
  }

  getJobRequistionIdNew() {
    this.spinner.show();
    this.createInternalService.getDataById(this.updateId).subscribe(res=> {
      setTimeout(() => {
        const form = this.addFormService.add_job_form;
        form.patchValue({
          
          applicationDate  :this.datePipe.transform(res.data.applicationDate,"yyyy-MM-dd"),
          approverPartyId  :res.data.approverPartyId,
          jobRequisitionId :res.data.jobRequisitionId,
          applicationId:res.data.applicationId
  
        
  
        })
      
      }, 3000);
      this.spinner.hide();
     
    })
      
    
  }
  cancelSubmit(){
    this.router.navigate(["/hr/job-requisition/internal-job-posting"])
  }
  getJobRequistionId() {
    this.spinner.show();
    this.createInternalService.getDataById(this.updateId).subscribe(res=> {
     this.posting=res.data
      this.spinner.hide();
     
    })
      
    
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
