import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GlobalResourceService {

    constructor(
        private _HttpClient: HttpClient
    ) { }


    getPartyGroups(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/resources/personsPartyGroups`).toPromise();
    }
    getFeatureIds(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/catalog/productFeatureCategory/feature/productFeatureIds`).toPromise();
    }
    getCurrency(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/resources/currency`).toPromise();
    }
    getRoleType(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/resources/contentPurposeRoleType`).toPromise();
    }
    getCountryList(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/resources/countries`).toPromise();
    }
    getStateList(countryId: string): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/resources/states/${countryId}`).toPromise();
    }
    getSupplierList(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/inventory/suppliers`).toPromise();
    }
    getUomByType(type: string): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/resources/uomByType?uomTypes=${type}`).toPromise();
    }
    getProductVariant(productId:any): Promise<any>{
        return this._HttpClient.get<any>(`/phyCorp/control/api/v1/catalog/product/variants?productId=${productId}`).toPromise();
    }
    getRoleTypeId():Promise<any>{
        return this._HttpClient.get<any>(`/phyCorp/control/api/v1/partyRoles/roleTypes?roleType=MAIN`).toPromise();
    }
    getUserLoginIds():Promise<any>{
        return this._HttpClient.get<any>(`/phyCorp/control/api/v1/resources/userloginIds`).toPromise();
    }
}