<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
           <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
              <span class="color-black pl-1 titlePanels">Vendors</span>
               <span class="color-grey search-icons">
                   <div class="search-option">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20.361" height="20.361" viewBox="0 0 20.361 20.361"><defs><style>.a{fill:#65787e;}</style></defs><g transform="translate(-4.02 -4.02)"><g transform="translate(4.02 4.02)"><path class="a" d="M24.179,23,18.83,17.652a8.337,8.337,0,1,0-1.178,1.178L23,24.179A.836.836,0,0,0,24.179,23ZM7.631,17.092a6.645,6.645,0,1,1,4.726,1.974,6.686,6.686,0,0,1-4.726-1.974Z" transform="translate(-4.02 -4.02)"/></g></g></svg>
                    <input class="form-control" type="text" placeholder="Search"> 
                   </div>
                   <div class="search-option filter-bar">
                    <i class="fa fa-filter" aria-hidden="true"></i>
                    <input class="form-control" type="text" placeholder="Filters">
                   </div>
                   <div class="search-option group-by">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18.4" height="16.866" viewBox="0 0 18.4 16.866"><defs><style>.a{fill:#65787e;}</style></defs><g transform="translate(0 -17.067)"><g transform="translate(0 17.067)"><g transform="translate(0 0)"><path class="a" d="M17.633,17.067H.767a.767.767,0,1,0,0,1.533H17.633a.767.767,0,1,0,0-1.533Z" transform="translate(0 -17.067)"/></g></g><g transform="translate(0 24.734)"><g transform="translate(0 0)"><path class="a" d="M17.633,187.733H.767a.767.767,0,1,0,0,1.533H17.633a.767.767,0,1,0,0-1.533Z" transform="translate(0 -187.733)"/></g></g><g transform="translate(0 32.4)"><g transform="translate(0 0)"><path class="a" d="M17.633,358.4H.767a.767.767,0,1,0,0,1.533H17.633a.767.767,0,1,0,0-1.533Z" transform="translate(0 -358.4)"/></g></g></g></svg>
                    <input class="form-control" type="text" placeholder="Group By">
                   </div>
                   <div class="search-option Favourites-bar">
                    <svg xmlns="http://www.w3.org/2000/svg" width="23.588" height="22.416" viewBox="0 0 23.588 22.416"><g transform="translate(-9.8 -11.3)"><g transform="translate(9.8 11.3)"><path d="M25.213,18.684,21.579,11.3l-3.633,7.384L9.8,19.856,15.69,25.6l-1.377,8.117,7.267-3.839,7.3,3.839L27.5,25.6l5.89-5.743Zm2.08,12.893-5.714-3.018-5.714,3.018,1.084-6.388-4.63-4.513,6.388-.938,2.872-5.773,2.872,5.8,6.388.938-4.63,4.483Z" transform="translate(-9.8 -11.3)"/></g></g></svg>
                    <input class="form-control" type="text" placeholder="Favourites">
                   </div>
                </span>
              
           </div>
           <div class=" bg-white color-grey">
            <div class="port-header-contact create-lead font-medium mb-0">
              <div class="suppliers-wrapper">
                  <button type="button" class="btn btn-outline-secondary active" routerLinkActive="active" routerLink="/payable/vendors/credit-memos/create-credit-memos">Create memos</button>
                  <button type="button" class="btn btn-outline-secondary text-white bg-brown">Import</button>
              </div>
          </div>
          <div class="create-new-leade mt-2">
            <div class="container-fluid">
                <div class="row">
                    <form class="w-100">
                        <div class="all-users-infomation font-13 font-medium own-user-select">
                            <div class="col-lg-4 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Invoice ID</label>
                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                </div>
                            </div>  
                            <div class="col-lg-4 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">From Party</label>
                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                </div>
                            </div> 
                            <div class="col-lg-4 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Status</label>
                                    <div class="">
                                        <p-dropdown filter="true"
                                            optionLabel="label">
                                            <ng-template let-item pTemplate="selectedItem">
                                                <span class="label-info"
                                                    style="margin-left: .5em">{{item.label}}</span>
                                            </ng-template>
                                            <ng-template let-car pTemplate="item">
                                                <div class="ui-helper-clearfix"
                                                    style="position: relative;height: 25px;">
                                                    <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Processing Status</label>
                                    <div class="">
                                        <p-dropdown filter="true"
                                            optionLabel="label">
                                            <ng-template let-item pTemplate="selectedItem">
                                                <span class="label-info"
                                                    style="margin-left: .5em">{{item.label}}</span>
                                            </ng-template>
                                            <ng-template let-car pTemplate="item">
                                                <div class="ui-helper-clearfix"
                                                    style="position: relative;height: 25px;">
                                                    <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </div>
                                </div>
                            </div>   
                            <div class="col-lg-4 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Invoice Date</label>
                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                </div>
                            </div>  
                            <div class="col-lg-4 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Due Date</label>
                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                </div>
                            </div> 
                            <div class="col-lg-4 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Amount</label>
                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                </div>
                            </div> 
                            <div class="col-lg-4 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Ref Num</label>
                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                </div>
                            </div> 
                            <div class="col-lg-4 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Message</label>
                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                </div>
                            </div> 
                            <div class="col-lg-4 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Paid Date</label>
                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                </div>
                            </div> 
                            <div class="col-lg-4 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Open Amount</label>
                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                </div>
                            </div> 
                            <div class="col-lg-4 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Related Order ID</label>
                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                </div>
                            </div> 

                            <div class="col-lg-4 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Item Description</label>
                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                </div>
                            </div>  
                        </div>
                        <div class="col-lg-12 col-12 main-submit-button "><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn">Find</button></div>
                         <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                            <div class="col-lg-12 col-12">
                                <div class="form-group">
                                  <label for="exampleInputEmail1"> Credit Memos List</label>
  
                                  <div class="card own-account-table">
                                    <p-table [value]="products3"  [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"  [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th pSortableColumn="code">
                                                    <div class="checkbox-align">
                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted"><div class="custom-checkbox ui-chkbox ui-widget"><div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div><div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div></div><!----></p-checkbox>
                                                       Party ID <p-sortIcon field="code"></p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                  </th>
                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">	Manifest Company Name<p-sortIcon field="name"></p-sortIcon>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">	Manifest Company Title <p-sortIcon field="category"></p-sortIcon>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th pSortableColumn="quantity">	Manifest Logo Url <p-sortIcon field="quantity"></p-sortIcon>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th pSortableColumn="price">Manifest Policies <p-sortIcon field="price"></p-sortIcon>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th pSortableColumn="price">DEscription <p-sortIcon field="price"></p-sortIcon>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th pSortableColumn="price">Action
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td ><div class="checkbox-align">
                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted"><div class="custom-checkbox ui-chkbox ui-widget"><div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div><div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div></div><!----></p-checkbox>
                                                    {{product.code}}
                                                </div>
                                                   </td>
                                                <td [ngStyle]="{'width':'190px'}">{{product.name}}</td>
                                                <td [ngStyle]="{'width':'190px'}">{{product.category}}</td>
                                                <td>{{product.quantity}}</td>
                                                <td>{{product.price | currency: 'USD'}}</td>
                                                <td>{{product.category}}</td>
                                                <td><div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222"><g transform="translate(0 -0.004)"><g transform="translate(0 1.52)"><path d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z" transform="translate(0 -34.137)"/></g><g transform="translate(4.548 0.004)"><g transform="translate(0 0)"><path d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z" transform="translate(-102.409 -0.004)"/></g></g></g></svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26"><defs><style>.a{fill:#f44336;}.b{fill:#fafafa; margin-left: 20px;}</style></defs><path class="a fill-color" d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0"/><path class="b" d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0" transform="translate(-147.576 -147.576)"/></svg>
                                                </div></td>
                                               
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                    
                                        <p class="paginate_data">
                                            View per page   </p>
                                </div>
                                </div>
                              </div>
                        </div>
                     </form>
  
                </div>
            </div>
        </div>
           </div>
           <!-- <div class="main-submit-button">
            <button type="submit" class="btn btn-secondary submit-btn">Submit</button>
            <button type="submit" class="btn btn-degault cncl-btn">Cancel</button>
           </div> -->
  
        </div>
    </div>
  </div>
  
  
  