<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">
    <div class="create-new-leade mt-2">

        <div class="container-fluid" style="padding: 0px 0px;">
            <div class="col-md-12 p-0" >
                <div class="w3-card-4 classCard  w-100">

            <div class="col-lg-12">
                <div class="row d-flex mt-3 justify-content-between">
                    <div style="margin-left:7px !important">
                        <label style="font-size:15px !important; font-weight:bold">Alert Setting</label>
                    </div>

                    <div style="margin-right: 17px !important;">
                        <button class="btn btn-secondary submit-btn">Save</button>
                    </div>

                </div>
            </div>

            <div class="row " style="width: 100%; margin-top: 1%;">
                <div class="col-md-12">
                    <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                            style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                            <p class="h-text color-grey"
                                style="margin: 0px 15px -1px -2px !important; color:black;">
                                Terminology for Work Zones</p>
                            
                        </div>

                        <form [formGroup]="terminologyWorkZonesForm">
                                <div class="col-lg-12 ">
                                    <div class="row font-12 ml-2 mt-1">
                                        <label>
                                            ...... Field Service Management ES can send alerts to email addresses in three 
                                            formats.
                                        </label>
                                    </div>
                
                                </div>
                
                                <div class="col-lg-12">
                                    <div class="row ml-2 font-12">
                                        <label>
                                            If you elect to recieve alerts by email you should choose the format best
                                            matches the kind of email you use.
                                        </label>
                                    </div>
                
                                </div>
                
                                <div class="col-lg-12">
                                    <div class="row d-flex ml-2 font-twelve">
                                        <div class="col-lg-1 form-group classInputC">
                                            <input type="radio" class="" formControlName="plainText">
                                        </div>
                
                                        <div class="col-lg-4">
                                            <label>
                                                Plain Text
                                            </label>
                                        </div>
                
                                        <div class="col-lg-7">
                                            <label>Best if people who receive email alerts are using mobile
                                                devices for email.
                                            </label>
                                        </div>
                                    </div>
                
                                </div>
                
                                <div class="col-lg-12">
                                    <div class="row d-flex ml-2 font-twelve">
                                        <div class="col-lg-1 form-group classInputC">
                                            <input type="radio" formControlName="plainTextPrinterFriendly">
                                        </div>
                
                                        <div class="col-lg-4">
                                            <label>
                                                Plain Text with printer friendly attachment.
                                            </label>
                                        </div>
                
                                        <div class="col-lg-7 font-12">
                                            <label>Best if desktop user use a variety of email tools and often wish
                                                to print a copy of the Work Order.
                                                
                                            </label>
                                        </div>
                                    </div>
                
                                </div>
                
                                <div class="col-lg-12">
                                    <div class="row d-flex ml-2 font-twelve">
                                        
                                        <div class="col-lg-2 form-group classInputC">
                                            <label>
                                                Tech Copy
                                            </label>
                                        </div>
                
                                        <div class="col-lg-1">
                                            <a class="" style="">Preview</a>
                                        </div>
                
                                        <div class="col-lg-1">
                                            <a class="" style="">Change</a>
                                        </div>
                                    </div>
                
                                </div>
                
                                <div class="col-lg-12">
                                    <div class="row d-flex ml-2 font-twelve">
                                        <div class="col-lg-1 form-group classInputC">
                                            <input type="radio" formControlName="htmlEmail" >
                                        </div>
                
                                        <div class="col-lg-4 ">
                                            <label for="exampleInputEmail1">HTML email</label>
                                        </div>
                
                                        <div class="col-lg-7 font-12">
                                            <label>Best if everyone is using an email system that can show HTML. Examples
                                                of HTML capable email system includes Microsoft Outlook, Hotmail, Yahoo!,
                                                Email, and  Outlook Express.
                                                
                                            </label>
                                        </div>
                                    </div>
                
                                </div>
                
                                <div class="col-lg-12">
                                    <div class="row d-flex ml-2 ">
                                        <div class="font-12">
                                            <label for="exampleInputEmail1">Enter the eamil address that you would like to have appear in the 
                                                From or Sender line of the email alert messages below 
                                        </label>
                                        </div>
                                    </div>
                                </div>
                
                                <div class="col-lg-12">
                                    <div class="row ml-2">
                                        
                                        <div class="col-lg-3 form-group classInput">
                                            <label for="exampleInputEmail1">The From an email address is
                                        </label>
                                        </div>
                
                                        <div class="col-lg-2">
                                            <input type="text" class="form-control" formControlName="emailAddress" id="exampleInputEmail1" placeholder="alerts@123.com">
                
                                        </div>
                                    </div>
                                </div>
                        </form>
                    
                            </div>

                    


                </div>

               
            </div>

            <div class="row " style="width: 100%; margin-top: 1%;">
                <form [formGroup]="emergencyAlertsForm">

                    <div class="col-md-12">
                        <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                <p class="h-text color-grey"
                                    style="margin: 0px 15px -1px -2px !important; color:black;">
                                    Emergency Alerts</p>
                            </div>


                    <div class="col-lg-12 ">
                        <div class="row font-12 ml-2 mt-3">
                            <label>
                                Emergency Alerts are special alerts sent when a new  emergency Work Order is created.
                                The alerts are send as long as Work Order has not picked up. They are first
                                send to assigned Tech and then associated to others if the assigned Tech
                                fails to respond.
                            </label>
                        </div>

                    </div>
                            <div class="col-lg-12">
                                <div class="row ml-2 font-twelve d-flex">
                                    
                                    <div class="">
                                        <label>Do not send more than</label> &nbsp;&nbsp;

                                    </div>
                                    <div class=" ">
                                        <p-dropdown filter="true"
                                        [options]="" optionlabel="label"
                                        
                                        formControlName="singleIndivdual">
                                        </p-dropdown> 
                                    </div>&nbsp; &nbsp;

                                    <div class=""> 
                                        <label>  alert to any single individual</label>
                                    </div>

                                    
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="row ml-2 font-twelve">
                                    <div class="">
                                        <label>Resend alert every</label> &nbsp;&nbsp;

                                    </div>
                                    
                                    <div class=" ">
                                        <p-dropdown filter="true"
                                        [options]="" optionlabel="label"
                                        
                                        formControlName="everyMinutes">
                                        </p-dropdown> 
                                    </div>&nbsp; &nbsp;

                                    <div class="">
                                        <label>minutes</label>
                                    </div>

                                    
                                </div>
                            </div>
                                </div>
                            </div>
                
                </form>
            </div>

            <div class="row " style="width: 100%; margin-top: 1%;">
                <form [formGroup]="restrictNonEmergencyAlertsFrom">
                    <div class="col-md-12">
                        <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                <p class="h-text color-grey"
                                    style="margin: 0px 15px -1px -2px !important; color:black;">
                                    Restricting When Non-Emergency Alerts are Sent</p>
                            </div>



                    <div class="col-lg-12 ">
                        <div class="row  ml-2 mt-3 font-twelve">
                            <label class="font-12">
                                All other alerts are considered non-emergency alerts Unlike emergency alerts 
                                we sometimes don't 
                                want to bother people with these alerts and needs to control when they
                                are sent.
                            </label>
                        </div>

                    </div>

                        <div class="col-lg-12">
                            <div class="row  d-flex ml-2 ">
                                
                                    <div class="checkbox-align" style="color: black; font-size: 12px; font-weight: bold;">
                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined" value="undefined" formControlName="alertofficeHours">
                                                </div>
                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                        
                                        </p-checkbox>
            
                                        &nbsp; Send non-emergency alerts only during office hours
                                    </div>
                                

                                

                                
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="row ml-2 font-twelve mt-2">
                                
                                <div class=" form-group">
                                <a> Where do I set Office hours ?</a>
                                </div>



                                
                            </div>
                        </div>

                        <div class="col-lg-12 ">
                            <div class="row  d-flex ml-2">

                                <div class="checkbox-align" style="color:black; font-size: 12px; font-weight: bold;">
                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"  value="undefined" formControlName="alertloggedusers">
                                                </div>
                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            
                                        </p-checkbox>
            
                                        &nbsp;   Send non-emergency alerts only to logged in users.
                                </div>
                                
                            </div>
                            

                        </div>

                        <div class="col-lg-12 ">
                            <div class="row  mt-2" style="margin-left:17px !important">
                                <label class="font-12">Users can be logged in either via their mobile device or their
                                    desktop computer.
                                </label>
                            </div>

                        </div>
                            </div>
                        </div>
                </form>
            </div>
       
       
            
            <div class="row " style="width: 100%; margin-top: 1%;">
                <form [formGroup]="reminderAlertsForm">   
                    <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Reminder Alerts</p>
                                </div>

                                <div class="col-lg-12">
                                    <div class="row d-flex ml-2 mt-3 font-12">
                                        <div>
                                            <label>Send an appointment reminder </label>&nbsp; &nbsp;
                                        </div>
                
                                        <div>
                                            <p-dropdown filter="true"
                                            [options]="" optionlabel="label"
                                            
                                            formControlName="appointmentReminder"
                                            >
                                            </p-dropdown> 
                                        </div>&nbsp; &nbsp;
                
                                        <div>
                                            <label>minutes before scheduled appointment.</label>
                                        </div>
                                    </div>
                                </div>
                
                                <div class="col-lg-12">
                                    <div class="row d-flex ml-2 font-12">
                                        <div>
                                            <label>Send an overdue reminder </label>&nbsp; &nbsp;
                                        </div>
                
                                        <div>
                                            <p-dropdown filter="true"
                                            [options]="" optionlabel="label"
                                            
                                            formControlName="overdueReminder">
                                            </p-dropdown> 
                
                                        </div>&nbsp; &nbsp;
                
                                        <div>
                                            <label>minutes before a Work Order becomes overdue.</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </form>
            </div>

                </div>
            </div>
        </div>
    </div>

    
</div>