<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Payments </span>
                <span class="color-black pl-1"> > Create New</span>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="port-header-contact create-lead font-medium mb-0">
                    New Incoming payment
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="incomingPaymentForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Organization Party ID
                                                </label>
                                            </div>
                                            <div class="col-lg-3">
                                                <p-dropdown filter="true" name="party" 
                                                [options]="OrganizationPartyIdArray"
                                                 formControlName="partyIdFrom" optionlabel="label"
                                                  placeholder="Select Party ID">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">To Party ID	 <span
                                                        class="text-danger">*</span></label>
                                            </div>
                                            <div class="col-lg-3">
                                                <p-dropdown filter="true" name="party" [options]="allPartyIdList" 
                                                optionlabel="label" placeholder="Select PartyID" formControlName="partyIdTo">
                                                </p-dropdown>
                                                <small class="text-danger" 
                                                *ngIf="!!payablePaymentForm.controls.partyIdFrom.invalid && 
                                                !!payablePaymentForm.controls.partyIdFrom.touched">From
                                                    Party ID is required</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Payment Type</label>
                                            </div>
                                            <div class="col-lg-3">
                                                 <p-dropdown filter="true" name="party" [options]="allPaymentTypeList" optionlabel="label" placeholder="Select Payment Type" formControlName="paymentTypeId">
                                                 </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Payment Method ID
                                                  
                                                </label>
                                            </div>
                                            <div class="col-lg-3">
                                                <p-dropdown filter="true" name="party" optionlabel="label" 
                                                [options]="paymentMethodIdrray"
                                                placeholder="Select Payment Method ID" formControlName="paymentMethodId">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1"> Override Gl Account ID</label>
                                            </div>

                                            <div class="col-lg-3">
                                                <p-dropdown filter="true" name="party" [options]="GIAccounts" optionlabel="label" formControlName="overrideGlAccountId" placeholder="Select Override Gl Account ID">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Amount <span
                                                        class="text-danger">*</span></label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="email" class="form-control" id="exampleInputEmail1" formControlName="amount" aria-describedby="emailHelp" placeholder="Enter Amount">
                                                <small class="text-danger" *ngIf="!!payablePaymentForm.controls.amount.invalid && !!payablePaymentForm.controls.amount.touched">Amount
                                                    is required</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Comments</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="email" class="form-control" id="exampleInputEmail1" formControlName="comments" aria-describedby="emailHelp" placeholder="Enter Comments">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Reference No</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                 formControlName="paymentRefNum" aria-describedby="emailHelp" placeholder="Enter Reference No">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12" >
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Financial Account ID</label>
                                            </div>

                                            <div class="col-lg-3">
                                                <p-dropdown filter="true" name="party" 
                                                [options]="allFinAccountIdList" optionlabel="label" 
                                                formControlName="finAccountId" placeholder="Select Financial Account">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -12%;">
                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn " 
                                    (click)="createPaymentIncoming()">Create</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <div class=" bg-white color-grey mt-2">
                <div class="w3-card-4 classCard">
                <div class="port-header-contact create-lead font-medium mb-0">
                    New outgoing payment
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="payablePaymentForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Organization Party ID
                                                    <span
                                                        class="text-danger">*</span>
                                                </label>
                                            </div>
                                            <div class="col-lg-3">
                                                <p-dropdown filter="true" name="party" 
                                                [options]="OrganizationPartyIdArray"
                                                 formControlName="partyIdTo" optionlabel="label" placeholder="Select Party ID">
                                                </p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">From Party ID </label>
                                            </div>

                                            <div class="col-lg-3">
                                                <p-dropdown filter="true" name="party" [options]="allPartyIdList" optionlabel="label" placeholder="Select PartyID" formControlName="partyIdFrom">
                                                </p-dropdown>
                                                <small class="text-danger" *ngIf="!!payablePaymentForm.controls.partyIdFrom.invalid && !!payablePaymentForm.controls.partyIdFrom.touched">From
                                                    Party ID is required</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Payment Type</label>
                                            </div>

                                            <div class="col-lg-3">
                                                 <p-dropdown filter="true" name="party" [options]="PaymentTypeOutgoingList" optionlabel="label" placeholder="Select Payment Type" formControlName="paymentTypeId">
                                                 </p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Payment Method ID
                                                  
                                                </label>
                                            </div>

                                            <div class="col-lg-3">
                                                <p-dropdown filter="true" name="party" optionlabel="label" 
                                                [options]="paymentMethodIdrray" placeholder="Select Payment Method ID"
                                                 formControlName="paymentMethodId">
                                                </p-dropdown>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1"> Override Gl Account ID</label>
                                            </div>

                                            <div class="col-lg-3">
                                                <p-dropdown filter="true" name="party" [options]="GIAccounts" optionlabel="label" formControlName="overrideGlAccountId" placeholder="Select Override Gl Account ID">
                                                </p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Amount <span
                                                        class="text-danger">*</span></label>
                                            </div>

                                            <div class="col-lg-3">
                                                <input type="email" class="form-control" id="exampleInputEmail1" formControlName="amount" aria-describedby="emailHelp" placeholder="Enter Amount">

                                                <small class="text-danger" *ngIf="!!payablePaymentForm.controls.amount.invalid && !!payablePaymentForm.controls.amount.touched">Amount
                                                    is required</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Comments</label>
                                            </div>

                                            <div class="col-lg-3">
                                                <input type="email" class="form-control" id="exampleInputEmail1" formControlName="comments" aria-describedby="emailHelp" placeholder="Enter Comments">
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Reference No</label>
                                            </div>

                                            <div class="col-lg-3">
                                                <input type="email" class="form-control" id="exampleInputEmail1" formControlName="paymentRefNum" aria-describedby="emailHelp" placeholder="Enter Reference No">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -12%;">
                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn " 
                                    (click)="createOutgoingPayment()">Create</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 