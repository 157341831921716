import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { ProductionService } from '../production.service';
@Component({
  selector: 'app-create-new-calender',
  templateUrl: './create-new-calender.component.html',
  styleUrls: ['./create-new-calender.component.css']
})
export class CreateNewCalenderComponent implements OnInit {
  createCalender:FormGroup;
  activeCategory=2;
  weekListArray: any[]=[];
  weekList: any;
  calendarWeekId: any;
  calendarId: any;
  description: any;
  show: boolean;

  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly productionService:ProductionService,
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
   
    readonly _ToastrService: ToastrService,) { 
      this.createCalender = this._FormBuilder.group({
        CalendarWeekID: [''],
        CalendarID:[''],
        Description:[''],
  
      });
    
  }
 
  ngOnInit(): void {
    this.getProdCalenderWeekId();
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.calendarWeekId = params["calendarWeekId"];
      this.calendarId = params["calendarId"];
      this.description = params["description"];

    });
    if(this.calendarId) {
      this.show=true;
      setTimeout(() => {
        const formValue = this.createCalender;
      formValue.patchValue({
        CalendarWeekID:this.calendarWeekId,
        CalendarID: this.calendarId,
        Description: this.description,
       
       
      })
      }, 3000);
     
    }
    else {
      this.show = false;
    }
  }
 
  getProdCalenderWeekId() {
    this.spinner.show();
   
    this.productionService.getProdCalenderWeekId().subscribe(res => {
      this.weekList = res.data;
    
      this.spinner.hide();
      for (const value of this.weekList) {
        this.weekListArray.push({
          label: value.description,
          value: value.calendarWeekId
        })
      }
    
    })
    
  }
  
  onSubmit() {
    this.spinner.show();
    
    const requestData = {
      "calendarId":  this.createCalender.value.CalendarID,
      "calendarWeekId":  this.createCalender.value.CalendarWeekID,
      "description":  this.createCalender.value.Description,

    }

    this.productionService.postProdCalender(requestData).subscribe((res: any) => {
     
      if (res.success) {
      
        this._ToastrService.success("Created Successfully");
        this._Router.navigate(['/production/calender-list']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  onUpdate() {
    this.spinner.show();
    
    const requestData = {
      "calendarId":  this.createCalender.value.CalendarID,
      "calendarWeekId":  this.createCalender.value.CalendarWeekID,
      "description":  this.createCalender.value.Description,

    }

    this.productionService.updateProdCalender(requestData).subscribe((res: any) => {
     
      if (res.success) {
      
        this._ToastrService.success("Updated Successfully");
        this._Router.navigate(['/production/calender-list']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }

  
  cancelSubmit(){
    this._Router.navigate(['/production/calender-list']);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}


