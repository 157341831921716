import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { PayablePaymentService } from 'src/app/accounting/accounting-ap/payables-payment/payables-payment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import * as moment from 'moment';

@Component({
    selector: 'app-summary-incoming-payment',
    templateUrl: './summary-incoming-payment.component.html',
    styleUrls: ['./summary-incoming-payment.component.css']
})

export class SummaryIncomingPaymentComponent implements OnInit {
    @ViewChild('closebutton') closebutton;
    paymentStatus: any[];
    statusId: string;
    paymentId: string;
    //activeCategory=1;
    total=0;
    rowNumber=0;
    pageSize = 10;
    rows = 50;
    paymentValue: any;
    faEdit = faEdit;
    faTrash = faTrash;
    financialAccountTransaction: any;
    paymentApplicationList: any[];
    transactions: any[];
    activeCategory = 2;
    array = [
        {
            label: 'Your Company Name',
            value: 'Company'
        }
    ];
    finAccountIds: any[];
    partyIds: any[];
    paymentTypeList: any[];
    payablePaymentForm: FormGroup;
    editMode: boolean;
    paymentMethods: any[];
    GIAccounts: any[];
    currencyUomID: any[];
    constructor(
        private _location: Location,
        readonly _PayablePaymentService: PayablePaymentService,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly spinner: NgxSpinnerService,
        readonly  _Router: Router,
        readonly _ToastrService: ToastrService,
        readonly _GlobalResourceService: GlobalResourceService,
        readonly _FormBuilder: FormBuilder
    ) {
        this.paymentId = '';
        this.paymentStatus = [];
        this.statusId = '';
        this.paymentApplicationList = [];
        this.transactions = [];
        this.partyIds = [];
        this.paymentTypeList = [];
        this.payablePaymentForm = this._FormBuilder.group({
            amount: ['', [Validators.required]],
            comments: [''],
            overrideGlAccountId: [''],
            partyIdFrom: ['', [Validators.required]],
            partyIdTo: ['Company'],
            paymentMethodId: [''],
            paymentRefNum: [''],
            paymentTypeId: [''],
            actualCurrencyAmount: [''],
            actualCurrencyUomId: [''],
            currencyUomId: [''],
            effectiveDate: [''],
            finAccountTransId: [''],
            statusId: [''],
            finAccountId: ['']
        });
        this.finAccountIds = [];
        this.paymentMethods = [];
        this.GIAccounts = [];
        this.paymentId = '';
        this.editMode = false;
        this.currencyUomID = [];
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.paymentId = this._ActivatedRoute.snapshot.queryParams.paymentId;
        this.getPaymentById();
        this.getStatus();
    }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/financial/main']);
  }
    cancelSubmit(){
        this._location.back();
      }
    getPaymentById(): void {
        this.spinner.show();
        this._PayablePaymentService.getPaymentById(this.paymentId)
            .then(data => {
                if (data) {
                    this.paymentValue = data.data[0];
                    this.financialAccountTransaction = data.data[0].financialAccountTransaction;
                    this.paymentApplicationList = data.data[0].paymentApplicationList;
                    this.transactions = data.data[0].transactionList;
                    this.spinner.hide();
                }
            });
            this.paymentId = this._ActivatedRoute.snapshot.queryParams.paymentId;
            if (this.paymentId) {
                this.editMode = true;
                this._PayablePaymentService.getPaymentById(this.paymentId)
                    .then(data => {
                        if (data.data.length) {
                            this.paymentValue = data.data[0];
                            this.payablePaymentForm.patchValue({
                                ...data.data[0], ...{
                                    effectiveDate: new Date(data.data[0].effectiveDate),
                                    finAccountTransId: data.data[0].financialAccountTransaction.finAccountTransId
                                }
                            });
                        }
                    });
            }
            this.getPartyIds();
            this.getPaymentType();
            this.getGIAccountsAndPaymentMethodIds();
            this.getCurrency();
            this.getMethodIds();        
         
    }
    setStatus(event: any): void {
        this.spinner.show();
        this._PayablePaymentService.setPaymentStatus(this.paymentId, this.statusId)
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Status Updated');
                    this.spinner.hide();
                    this.getPaymentById();
                }
            });
           
    }
    setStatusToVoid(): void {
        this.spinner.show();
        this._PayablePaymentService.setPaymentStatusToVoid(this.paymentId)
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Status Updated');
                    this.spinner.hide();
                    this.getPaymentById();
                }
            });
          
    }
    getStatus(): void {
        this.spinner.show();
        this._PayablePaymentService.getStatus()
            .then(data => {
                this.paymentStatus = data.data.status.map(value => {
                    return {
                        label: value.description,
                        value: value.statusId
                    };
                });
            });
            this.spinner.hide();
    }
    setNewStatus(event){
        console.log("ev",event.target.name);
        this.spinner.show();
        this.statusId=event.target.name
        this._PayablePaymentService.setPaymentStatus(this.paymentId, this.statusId)
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Status Updated');
                    this.spinner.hide();
                    this.getPaymentById();
                }
        });


    }
    customSort(event: SortEvent): void {
        event.data.sort((data1, data2) => {
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            }
            else if (value1 != null && value2 == null) {
                result = 1;
            }
            else if (value1 == null && value2 == null) {
                result = 0;
            }
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            }
            else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (event.order * result);
        });
    }
    changeactiveCategoryFunction(tab: number){
        this.activeCategory =tab;
      }
      getCurrency(): void {
        this.spinner.show();
        this._GlobalResourceService.getCurrency()
            .then(data => {
                this.spinner.hide();
                this.currencyUomID = data.data.currencyList.map(value => {
                    return {
                        label: value.description,
                        value: value.uomId
                    };
                });
            });
           
    }
    getMethodIds(): void {
        this.spinner.show();
        this._PayablePaymentService.getIncomingMethodType('Company')
            .then(data => {
                this.spinner.hide();
                this.paymentMethods = data.data.paymentMethodId.map(value => {
                    return {
                        label: value.description,
                        value: value.paymentMethodId
                    };
                });
            });
           
    }
    getGIAccountsAndPaymentMethodIds(): void {
        this.spinner.show();
        this._PayablePaymentService.getGIAccountAndPaymentMethod()
            .then(data => {
                if (data) {
                    this.spinner.hide();
                    this.GIAccounts = data.data.glAccounts.map(value => {
                        return {
                            label: value.glAccountId,
                            value: value.glAccountId
                        };
                    });
                }
            });
           
    }
    getPartyIds(): void {
        this.spinner.show();
        this._GlobalResourceService.getPartyGroups()
            .then(data => {
                this.spinner.hide();
                this.partyIds = data.data.PersonsGroups.map(value => {
                    return {
                        label: value.partyId,
                        value: value.partyId
                    };
                });
            });
           
    }
    getPaymentType(): void {
        this.spinner.show();
        this._PayablePaymentService.getIncomingPaymentType()
            .then(data => {
                if (data) {
                    this.spinner.hide();
                    this.paymentTypeList = data.data.paymentType.map(value => {
                        return {
                            label: value.description,
                            value: value.paymentTypeId
                        };
                    });
                    this.finAccountIds = data.data.finAccountId.map(value => {
                        return {
                            label: value.finAccountName,
                            value: value.finAccountId
                        };
                    });
                }
            });
           
    }
    reset(): void {
        this.payablePaymentForm.reset();
        this._Router.navigate(['/financial/receiveable/payments']);
    }
    update(): void {
        this.spinner.show();
        if (this.payablePaymentForm.valid) {
            const {
                actualCurrencyAmount,actualCurrencyUomId,amount,comments,currencyUomId,effectiveDate,finAccountTransId,
                overrideGlAccountId,partyIdFrom,partyIdTo,paymentMethodId,paymentRefNum,paymentTypeId,
                statusId
            } = this.paymentValue;
            this._PayablePaymentService.updatePayment(this.paymentId, {
                ...{
                    actualCurrencyAmount,actualCurrencyUomId,amount,comments,currencyUomId,effectiveDate,finAccountTransId,
                    overrideGlAccountId,partyIdFrom,partyIdTo,
                    paymentMethodId,paymentRefNum,paymentTypeId,
                    statusId
                }, ...this.payablePaymentForm.value, ...{
                    effectiveDate: moment(this.payablePaymentForm.value.effectiveDate).format('YYYY-MM-DD HH:mm:ss')
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Updated');
                        this.spinner.hide();
                        this.payablePaymentForm.reset();
                        this.closebutton.nativeElement.click();
                        this.getPaymentById();
                       // this._Router.navigate(['/financial/receiveable/payments']);
                    }
                });
        }
      
    }
    submit(): void {
        this.spinner.show();
        if (this.editMode) {
            this.update();
        } else {
            if (this.payablePaymentForm.valid) {
                const { amount, comments,
                    finAccountId, overrideGlAccountId,
                    partyIdFrom, partyIdTo,
                    paymentMethodId, paymentRefNum,
                    paymentTypeId
                } = this.payablePaymentForm.value;
                this._PayablePaymentService.createIncomingPayment({
                    amount, comments, finAccountId,
                    overrideGlAccountId, partyIdFrom,
                    partyIdTo, paymentMethodId,
                    paymentRefNum, paymentTypeId
                })
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Created');
                            this.payablePaymentForm.reset();
                            this.spinner.hide();
                            this.closebutton.nativeElement.click();
                            this.getPaymentById();
                            //this._Router.navigate(['/financial/receiveable/payments']);
                        }
                    });
            }
        }
     
    }
      ngOnDestroy(): void {
        this.spinner.hide();
          }
}