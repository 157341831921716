import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService} from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { HeaderComponent } from 'src/app/header/header.component';
import { HeaderService } from 'src/app/header/header.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2'
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

import { Location } from '@angular/common';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  products3: any;
  @ViewChild('closebutton') closebutton;
  total=0;
  createUser: FormGroup;
  rowNumber=0;
  activeCategory = 2;
  pageSize= 500;
  rows = 50;
  pageNo=1;
  search="";
  public user = {
    pageNo  :this.pageNo,
    pageSize:this.pageSize,
    search  :this.search
  }
  advanceSearch :{
       
    firstName: string,
    firstNameSearchType:string,
    lastName: string,
    lastNameSearchType:string,
    partyId: string,
    partyIdSearchType: string,
    userId: string,
    userIdSearchType: string

  };
  userList: any;
  loginIdArray: any[]=[];
  partIdArray: any[]=[];
  status: string;
  adminSecurity: string;
  userEmail: string;
  personId: any;
  partyIdArray: any[]=[];
  catalogId: string;
  url: string;
  securityGroup: any;
  arraySecurity: any[]=[];
  show: boolean;
  constructor(private accountsService: AccountsService,
    private headerService : HeaderService,
    private toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    private addSkillService: AddSkillService,
       private _location: Location,
       readonly router: Router,
    readonly _FormBuilder: FormBuilder) {
      this.advanceSearch = {
       
        firstName: "",
        firstNameSearchType: "Contains",
        lastName: "",
        lastNameSearchType: "Contains",
        partyId: "",
        partyIdSearchType: "Contains",
        userId: "",
        userIdSearchType: "Contains"

      };
      this.url='/users/user-list'
      this.createUser = this._FormBuilder.group({
        firstName: ['',[Validators.required]],
        lastName: ['',[Validators.required]],
        email: ['',[Validators.required]],
        password: ['',[Validators.required]],
        groupId: ['',[Validators.required]],
       
        
      });
     }
     ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
      const security: string = localStorage.getItem('adminSecurity');
      this.adminSecurity=security;
      const userEmal: string = localStorage.getItem('email');
      this.userEmail=userEmal
      this.products3 = this.accountsService.customerDetails()
    
      this.getUserDetails();
      this.getPartyId();
      this.findGroupId();
      this.show=false
    //  this.addToCompare();
    }
    homeButton(){
      this.router.navigate(['/users/company-information-import'])
    
    }
    cancelSubmit(){
     this._location.back();
    }
    
    getPartyId() {
      this.spinner.show();
      this.addSkillService.getPartyId().subscribe(res => {
        this.personId = res.data.PersonsGroups;
        for (const value of this.personId) {
          this.partyIdArray.push({
            label: value.partyId,
            value: value.partyId
          })
        }
      })
      this.spinner.hide();
    }


    

    customSort(event: SortEvent) {
      event.data.sort((data1, data2) => {
          const value1 = data1[event.field];
          const value2 = data2[event.field];
          let result = null;
  
          if (value1 == null && value2 != null){
            result = -1;
          }
          else if (value1 != null && value2 == null){
            result = 1;
          }
          else if (value1 == null && value2 == null) {
              result = 0;
           }
          else if (typeof value1 === 'string' && typeof value2 === 'string') {
              result = value1.localeCompare(value2);
          }
          else {
              result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          }
          return (event.order * result);
      });
    }
    getUserDetails(){
      this.spinner.show();
      const req={
        "firstName": "",
        "firstNameSearchType": "Contains",
        "lastName": "",
        "lastNameSearchType": "Contains",
        "partyId": "",
        "partyIdSearchType": "Contains",
        "userId": "",
        "userIdSearchType": "Contains"
      }
      this.accountsService.getUserDetails(this.user,req).subscribe((res:any)=> {
        this.userList = res.data;
        this.spinner.hide();
       for(const value of this.userList){
        this.partIdArray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
      for(const value of this.userList){
        this.loginIdArray.push({
          label:value.userLoginId,
          value:value.userLoginId
        })
      }
      })
     
     }
    /*  createUser(){
      this.router.navigate(["/users/create-user"])
     } */
     importUser(){
       this.router.navigate(["/users/import-user"])
     }
   
     toggleVisibility(e, user) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to change status.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          var marked = e.target.checked;
      if(marked==true){
        this.status='Y'
      }else{
        this.status='N'
      }
      
      this.accountsService.userEnableDisable(this.status,user.userLoginId).subscribe((res:any)=> {
       if (res) {
        this.toastr.success("Updated Successfully");
          this.getUserDetails();
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
         
    
          }
          else{
            this.toastr.error(err.error.message);
          }
      });
          Swal.fire(
            'Changed!',
            'Changed Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.getUserDetails();
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }

      })
    }
   
     advanceSearchContacts(isPopUp: boolean): void {
      this.spinner.show();
      this.userList=[];
      const req = {
        "firstName": this.advanceSearch.firstName ? this.advanceSearch.firstName : '',
        "firstNameSearchType": "Contains",
        "lastName": this.advanceSearch.lastName ? this.advanceSearch.lastName : '',
        "lastNameSearchType": "Contains",
        "partyId": this.advanceSearch.partyId ? this.advanceSearch.partyId : '',
        "partyIdSearchType": "Contains",
        "userId": this.advanceSearch.userId ? this.advanceSearch.userId : '',
        "userIdSearchType": "Contains"
       
     
      }
      this.accountsService.getUserDetails(this.user,req)
        .subscribe(resp => {
          if (resp.success) {
           
            this.userList = resp.data;
            this.spinner.hide();
          } 
        }); } 
    reset(){
      this.spinner.show();
      this.advanceSearch.firstName="";this.advanceSearch.lastName="";this.advanceSearch.partyId="";
      this.advanceSearch.userId=""
      const req={
        "firstName": "",
        "firstNameSearchType": "Contains",
        "lastName": "",
        "lastNameSearchType": "Contains",
        "partyId": "",
        "partyIdSearchType": "Contains",
        "userId": "",
        "userIdSearchType": "Contains"
      }
      this.accountsService.getUserDetails(this.user,req).subscribe((res:any)=> {
        this.userList = res.data;
        this.spinner.hide();
       for(const value of this.userList){
        this.partIdArray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
      for(const value of this.userList){
        this.loginIdArray.push({
          label:value.userLoginId,
          value:value.userLoginId
        })
      }
      })
    }
    detailsummary(partyId,login) {
      this.router.navigate(['/users/user-detail-summary'], { queryParams: { partyId: partyId ,login:login} });
    }
    exportUser() {
      this.spinner.show();
      this.accountsService.getExportUser().subscribe(res => {
        const blob = new Blob([res], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        this.spinner.hide();
        link.download = 'user.xlsx';
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(function () {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      }) 
    }
    submit(): void {
      this.spinner.show();
  if(this.createUser.value.password.length>=5){
      const requestData ={
        
          "email": this.createUser.value.email,
          "firstName": this.createUser.value.firstName,
          "groupId":this.createUser.value.groupId,
          "lastName":this.createUser.value.lastName,
          "password":this.createUser.value.password,
        }
       
      this.accountsService.createUser(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Created Successfully");
          this.spinner.hide();
          this.getUserDetails();
          this.createUser.reset();
          this.closebutton.nativeElement.click();
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
         
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); }else{
      this.spinner.hide();
      this.toastr.error("Password must be at least 5 characters long.");
    } }
 
    findGroupId(){
      this.spinner.show();
     let  req={
        
          "description": "",
          "descriptionSearchType": "Contains",
          "groupId": "",
          "groupIdSearchType": "Contains"
        
       }
      this.accountsService.findGroupId(this.user,req).subscribe((res:any)=> {
        this.securityGroup = res.data;
        this.spinner.hide();
        for(const value of this.securityGroup){
          this.arraySecurity.push({
            label:value.groupId,
            value:value.groupId
          })
        }
  
      })
     
     }
    ngOnDestroy(): void {
      this.spinner.hide();
        }
}
