<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
           
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <span class="color-black pl-1 titlePanels">     Find Portal Page</span>
                    <span class="d-flex">

                        <button type="submit" (click)="homeButton();"
                            class=" btn btn-outline-secondary mr-2">Home</button>
                
                        <button type="submit" (click)="cancelSubmit();"
                            class="btn btn-danger buttonclass ">Back</button>
                    </span>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                                    Find Portal Page</a></li>
                        </ul>
                    </div>
                    <div >
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">
                                                <div *ngIf="activeCategory==1">
                                                    <div class="panel-body">
                                                     
                                                        <form>
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text"   [(ngModel)]="advanceSearch.portalPageId"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="portalPageId"
                                                                                    class="form-control"
                                                                                   
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter ID">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Original page</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" [(ngModel)]="advanceSearch.originalPortalPageId"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="originalPortalPageId" 
                                                                                    class="form-control"
                                                                                   
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Original Page">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" [(ngModel)]="advanceSearch.portalPageName"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="portalPageName"
                                                                                class="form-control"
                                                                              
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Name">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Parent page</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text"  [(ngModel)]="advanceSearch.parentPortalPageId"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="parentPortalPageId"
                                                                                    class="form-control"
                                                                                   
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Parent page">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Description</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text"  [(ngModel)]="advanceSearch.description"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="description"
                                                                                    class="form-control"
                                                                                    
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Description">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Security Group ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" [(ngModel)]="advanceSearch.securityGroupId"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="securityGroupId"
                                                                                    class="form-control"
                                                                                   
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Security Group ID">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                              
                                                            </div>
                                                            <div style="margin-left: -33%;" class="col-lg-12 col-12 main-submit-button"><button _ngcontent-cta-c128=""
                                                                type="submit" class="btn btn-secondary submit-btn"
                                                                (click)="advanceSearchContacts(false)">Find</button>&nbsp;
                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger ml-2"
                                                                (click)="reset();">Reset</button></div><br>
                                                        </form>
                                                        <div>
                                                            <header class="w3-container">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling">
                                                                        Search Results
                                                                    </h4>

                                                                </div>
                                                            </header>
                                                            <div
                                                                class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="PortalPage"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total"
                                                                            [scrollable]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'300px'}"
                                                                                            pSortableColumn="code">
                                                                                            <div class="checkbox-align"
                                                                                                style="color: white;">
                                                                                                <p-checkbox
                                                                                                    _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox"
                                                                                                    binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div
                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name="undefined"
                                                                                                                value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                ID <p-sortIcon
                                                                                                    field="code">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'300px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Name
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'300px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Description<p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                ParentPage<p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Sequence Num<p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Original Page<p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Owner User Login ID<p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Security Group ID<p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body"
                                                                                    let-product>
                                                                                    <tr>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'300px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox
                                                                                                    _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox"
                                                                                                    binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div
                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name="undefined"
                                                                                                                value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    
                                                                                                </p-checkbox>
                                                                                                <span class="account-button"
                                                                                                 (click)="detailMainPage(product.data.portalPageId,product.data.portalPageName,product.data.description,product.data.parentPortalPageId,product.data.sequenceNum,product.data.originalPortalPageId,product.data.ownerUserLoginId,product.data.securityGroupId);"
                                                                                                   >{{product.data.portalPageId}}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'300px'}">
                                                                                            {{product.data.portalPageName}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'300px'}">
                                                                                            {{product.data.description}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                            {{product.data.parentPortalPageId}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                            {{product.data.sequenceNum}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                            {{product.data.originalPortalPageId}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                            {{product.data.ownerUserLoginId}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                            {{product.data.securityGroupId}}
                                                                                        </td>
                                                                                        
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage"
                                                                                    let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                                <h4 class="ml-2">No
                                                                                                    Record Found</h4>
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>