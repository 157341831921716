<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">
    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">
            <div class="col-md-12 p-0" >
                <div class="w3-card-4 classCard  w-100">
                    <div class="col-lg-12">
                        <div class="row d-flex mt-2" >
                            <div>
                                <label style="font-size: 15px; font-weight: bold; margin-left: 20px;">Parts</label>
                            </div>
        
        
                        </div>
        
                    </div>

                    <div class="col-lg-12">
                        <div class="row " style="margin-top:0px !important; margin-bottom: -1% !important;margin-left: 4px !important; ">
                            <a class="btn btn-link"  (click)="reset()"   data-target="#addServicePartPopup" data-toggle="modal"
                             style=" margin-left: -12px !important;">
                                Add Parts</a>
                        </div>
            
                    </div>
        
                    
                    <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: -1%;">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="border: 1px solid lightcyan !important;">
                                    <label style="background-color:rgb(206, 214, 218);color: black !important; border:1px solid lightcyan !important;
                                     margin: 0px !important ; padding: 3px !important; font-size: 15px ; font-weight: bold;
                                     padding-left:7px !important;">Parts </label>
                                    <p-table [value]="fsmEquipmentPartList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr style="background-color:rgb(206, 214, 218); border: 1px solid rgb(206, 214, 218) !important;">
                                                <th [ngStyle]="{'width':'180px'}" pSortableColumn="code">
                                                    <div class="checkbox-align" style="color: black;">
                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                </div>
                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>
        
                                                        Equipment Parts Id  
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Track Name 
                                                    </div>
                                                    <div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </div>
                                                </th>
                                                <th [ngStyle]="{'width':'180px'}" pSortableColumn="category">
                                                    <div style="color: black;">
                                                        Part Name	</div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th [ngStyle]="{'width':'180px'}" pSortableColumn="category">
                                                    <div style="color: black;">
                                                        Priority</div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th [ngStyle]="{'width':'180px'}" pSortableColumn="category">
                                                    <div style="color: black;">
                                                        Description</div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
        
                                                <th [ngStyle]="{'width':'180px'}" pSortableColumn="category">
                                                    <div style="color: black;">
                                                        Action</div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                            
                                            
        
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td [ngStyle]="{'width':'180px'}">
                                                    <div class="checkbox-align">
                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                </div>
                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                        </p-checkbox>
                                                        <span  class="account-button"  >
                                                            {{product.equipmentPartsId}}</span>
        
                                                    </div>
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">{{product.trackName}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">{{product.partName}}
                                                </td>

                                               
                                                <td [ngStyle]="{'width':'180px'}">{{product.priority}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">{{product.description}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">

                                                    <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer; " 
                                                    (click)="update(product)" data-toggle="modal"
                                                    data-target="#addServicePartPopup">Update</small>
                                                    <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor:
                                                     pointer; "        
                                                       (click)="deleteFsmEquipmentPart(product)" >Delete</small>

                                                </td>
        
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage" let-columns>
                                            <tr>
                                                <td>
                                                    <span class="text-center">
                                                        <h4 class="ml-2">No
                                                            Record Found
                                                        </h4>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                    <p class="paginate_data">
                                        View per page
        
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="addServicePartPopup" tabindex="-1" role="dialog" aria-labelledby="addServicePartPopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add a Part</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-black color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add a Part</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="partForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Track Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                             aria-describedby="" placeholder="Enter Track Name"
                                                            formControlName="trackName">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA ">
                                                        <label for="exampleInputEmail1">Part Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="" placeholder="Enter Part Name"
                                                       formControlName="partName">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Priority</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" placeholder="Choose Priority"
                                                          [options]="priorityArray"  formControlName="priority">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Equipment Id</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" placeholder="Choose Equipment Id"
                                                        [options]="fsmEquipmentIdArray"    formControlName="equipmentId">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="" placeholder="Enter Description"
                                                       formControlName="description">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 48%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                             *ngIf="!this.showTrack"   (click)="createFsmEquipmentPart()">Create</button>
                                                <button type="submit" *ngIf="this.showTrack"   
                                                 (click)="updateFsmEquipmentPart()"   class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <ngx-spinner></ngx-spinner> -->