<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Fixed Asset</span>
                <span class="color-black pl-1"> >{{editMode ? 'Update':'Create'}} Assignment</span>
                <button type="submit" (click)="cancelSubmit()" 
                class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        {{editMode ? 'Update':'Create'}} Assignment</a></li>
                      
            
                    </ul>
                  </div>
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="assignmentForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInputNN">
                                                <label for="exampleInputEmail1">Customer ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown placeholder="Enter Customer ID" filter="true" [options]="partyIds"
                                                formControlName="partyId">
                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Role Type ID </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown placeholder="Enter Role Type ID" filter="true"
                                                    [options]="roleTypeIds" formControlName="roleTypeId">
                                                </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInputNN">
                                                <label for="exampleInputEmail1">From Date
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" formControlName="fromDate"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Fin Account Name">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Through Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                formControlName="thruDate" aria-describedby="emailHelp"
                                                placeholder="Enter Fin Account Code">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInputNN">
                                                <label for="exampleInputEmail1">Allocated Date</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                formControlName="allocatedDate" aria-describedby="emailHelp"
                                                placeholder="Enter Fin Account Code">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Comments</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                formControlName="comments" aria-describedby="emailHelp"
                                                placeholder="Enter Comments">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInputNN">
                                                <label for="exampleInputEmail1">Status ID
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown placeholder="Enter Status ID" filter="true"
                                                [options]="statusIds" formControlName="statusId">
                                            </p-dropdown>
                                            </div>
                                        
                                        </div>
                                    </div>
                                 
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-submit-button" style="margin-top: -3%;margin-right: 30%;">
                <button type="submit" *ngIf="editMode" (click)="onUpdate()"
                    class="btn btn-secondary submit-btn">Update</button>
                <button type="submit" *ngIf="!editMode" (click)="onSubmit()"
                    class="btn btn-secondary submit-btn">Submit</button>
              
            </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>