import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmpPositionFullfillmentModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        comments:(data && data.comments) ? data.comments : null,
        emplPositionId:(data && data.emplPositionId) ? data.emplPositionId : null,

        fromDate:(data && data.fromDate) ? data.fromDate : null,
    
        partyId:(data && data.partyId) ? data.partyId : null,
        thruDate:(data && data.thruDate) ? data.thruDate : null,
       
     
    });
   
   
    return this.getFormGroup(form_data);
  }

  
  getFormGroup(data:any): any {
   return ({
    thruDate: new FormControl(data.thruDate),
    partyId: new FormControl(data.partyId),
  
    fromDate: new FormControl(data.fromDate),
    emplPositionId:new FormControl(data.emplPositionId),
    comments: new FormControl(data.comments),
   
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
