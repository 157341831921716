<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Facility Group  of {{ this.id}} </span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Back</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <header class="w3-container w3-blue">
                            <div class="header-tabs" style="background: #cce0e95e;    margin-left: -15px;
                        margin-right: -14px;">
                                <h4 class=" common-styling h4Margin">
                                    Facility Group Information
                                </h4>
                                <span class="edit-buttons hCreate">

                              <a   data-toggle="modal" (click)="getData();"
                              data-target="#exampleModalCenter">Update</a>
                            </span>
                            </div>
                        </header>


                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group">
                                                <div *ngIf="activeCategory==1">

                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">
                                                                <div class="row">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Facility Group ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.facid}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Facility Group Type ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.groupid}}</span>

                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Primary Parent Group ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{ this.parentId}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Facility Group Name</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.fGroupName}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Description</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.des}}</span>
                                                                        </div>





                                                                    </div>




                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Update Facility Group</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <form class="w-100" [formGroup]="facilityGroup">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!editMode" [ngClass]="activeCategory==1?'active':''"><a>
                        Create Facilty Group</a></li>
                                <li *ngIf="editMode" [ngClass]="activeCategory==1?'active':''"><a>
                            Update Facilty Group</a></li>

                            </ul>
                        </div>
                        <div class="all-users-infomation font-13 font-medium own-user-select">
                            <div class="col-lg-12">
                                <div class="row">


                                    <div class="col-lg-3 form-group classInput">
                                        <label for="exampleInputEmail1">Facility Group Type ID<span
                                style="color:red">*</span></label>
                                    </div>
                                    <div class="col-lg-3">
                                        <p-dropdown [options]="facilityGroupTypeListArray" placeholder="Facility Group Type ID" filter="true" formControlName="facilityGroupTypeId"></p-dropdown>

                                        <small class="text-danger" *ngIf="!!facilityGroup.controls.facilityGroupTypeId.invalid && !!facilityGroup.controls.facilityGroupTypeId.touched">  Facility Group ID is required!</small>


                                    </div>
                                    <div class="col-lg-3 form-group classInputA" *ngIf="editMode">
                                        <label for="exampleInputEmail1">Facility Group ID</label>
                                    </div>
                                    <div class="col-lg-3" *ngIf="editMode">
                                        <input type="email" class="form-control" formControlName="facilityGroupId" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Facility Group ID" readonly>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="row">

                                    <div class="col-lg-3 form-group classInput">
                                        <label for="exampleInputEmail1">Primary Parent Group ID</label>
                                    </div>
                                    <div class="col-lg-3">
                                        <p-dropdown [options]="parentGroupListDropdownArray" placeholder="Primary Parent Group ID" filter="true" formControlName="primaryParentGroupId"></p-dropdown>

                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">Facility Group Name<span
                                style="color:red">*</span></label>
                                    </div>
                                    <div class="col-lg-3">
                                        <input type="email" class="form-control" formControlName="facilityGroupName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Facility Group Name">
                                        <small class="text-danger" *ngIf="!!facilityGroup.controls.facilityGroupName.invalid && !!facilityGroup.controls.facilityGroupName.touched">  Facility Name is required!</small>


                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="row">

                                    <div class="col-lg-3 form-group classInput">
                                        <label for="exampleInputEmail1">Description</label>
                                    </div>
                                    <div class="col-lg-3">
                                        <input type="email" formControlName="description" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Description">
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -7%;">
                            <button (click)="onSubmit()" type="submit" class="btn btn-secondary submit-btn update-button">{{editMode? 'Update':'Submit'}}</button>

                        </div>
                    </div>
                </form><br>
            </div>

        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>