<div class="container-fluid main-container-wrapper">

    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">PIM | Product Information Management</span>

               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>

            </div>
            <div class=" bg-white color-grey">
                <div class="mb-5">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left: 9px; width: calc(103% - 5px); flex-wrap: wrap; display: flex; margin-bottom: 8px !important;    display: none !important;">
                            <li [ngClass]="activeCat==1?'active':''" (click)="mainOpen()">
                                <a>Main</a>
                            </li>
                            <li [ngClass]="activeCat==2?'active':''" (click)="catalogOpen()">
                                <a>Catalogs</a>
                            </li>
                            <li [ngClass]="activeCat==3?'active':''" (click)="categoriesOpen()">
                                <a>Categories</a>
                            </li>
                            <li [ngClass]="activeCat==4?'active':''" (click)="productsOpen()">
                                <a>Products</a>
                            </li>
                            <li [ngClass]="activeCat==5?'active':''" (click)="featuresOpen()">
                                <a>Features</a>
                            </li>
                            <li [ngClass]="activeCat==6?'active':''" (click)="promosOpen()">
                                <a>Promos</a>
                            </li>
                            <li [ngClass]="activeCat==7?'active':''" (click)="priceRulesOpen()">
                                <a>Price Rules</a>
                            </li>
                            <li [ngClass]="activeCat==8?'active':''" (click)="storesOpen()">
                                <a>Stores</a>
                            </li>
                            <li [ngClass]="activeCat==9?'active':''" (click)="productStoreGroupsOpen()">
                                <a>Product Store Groups</a>
                            </li>
                            <li [ngClass]="activeCat==10?'active':''" (click)="thesaurusOpen()">
                                <a>Thesaurus</a>
                            </li>
                            <li [ngClass]="activeCat==11?'active':''" (click)="reviewsOpen()">
                                <a>Reviews</a>
                            </li>
                            <li [ngClass]="activeCat==12?'active':''" (click)="configurationsOpen()">
                                <a>Configurations</a>
                            </li>
                            <li [ngClass]="activeCat==13?'active':''" (click)="subscriptionsOpen()">
                                <a>Subscriptions</a>
                            </li>
                            <li [ngClass]="activeCat==14?'active':''" (click)="shippingOpen()">
                                <a>Shipping</a>
                            </li>
                            <li [ngClass]="activeCat==15?'active':''" (click)="imageManagementOpen()">
                                <a>Image Management</a>
                            </li>
                        </ul>
                    </div>
                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                        <div class="">
                            <div class="">
                                <form class="w-100">

                                    <div class="w-100">
                                    
                                            <div class="w3-card-4 classCssShadow" style="border:1px solid;
                                            border-color:#0d3769 !important;">
                                                <header class="w3-container w3-blue">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling" style="margin-left: 7px;">
                                                            Search
                                                        </h4>

                                                    </div>
                                                </header>

                                                <div class="w3-container">
                                                    <div class="panel-body" style="background: white;">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="keyword">Keywords</label>
                                                                    </div>

                                                                    <div class="col-lg-4">
                                                                        <input type="text" class="form-control" [(ngModel)]="searchFilter.searchString" [ngModelOptions]="{standalone:true}" placeholder="Enter Keywords">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="categoryId">Category Id</label>
                                                                    </div>

                                                                    <div class="col-lg-4">
                                                                        <p-dropdown filter="true" [options]="categoryArray" optionlabel="label" placeholder="Select Category ID" [(ngModel)]="searchFilter.searchCategoryId" [ngModelOptions]="{standalone:true}">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="d-flex d-column">
                                                                <div class="form-check custom-form d-flex">
                                                                    <input class="form-check-input" type="checkbox" (change)="setContains($event)" id="defaultCheck1">
                                                                    <label class="form-check-label" for="defaultCheck1">
                                                                    No Contains
                                                                </label>
                                                                </div>
                                                                <div class="form-check custom-form d-flex">
                                                                    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" (change)="setOperator('Any')">
                                                                    <label class="form-check-label" for="exampleRadios1">
                                                                    Any
                                                                </label>
                                                                </div>
                                                                <div class="form-check custom-form d-flex">
                                                                    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option1" (change)="setOperator('All')">
                                                                    <label class="form-check-label" for="exampleRadios2">
                                                                    All
                                                                </label>
                                                                </div>
                                                                <div class="button-toolbar">
                                                                    <button class="btn btn-primary cursor-pointer ml-4 margin-top" (click)="advanceSearch()">Find</button>
                                                                </div>
                                                            </div>
                                                            <ng-container *ngIf="advanceSearchData.length">



                                                                <div class="card own-account-table mt-2" style="    margin-left: 2%!important;">
                                                                    <p-table [value]="advanceSearchData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr>
                                                                                <th width="25%" [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Product ID
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th width="10%" pSortableColumn="price">
                                                                                    Product Name
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product let-i="rowIndex">
                                                                            <tr>
                                                                                <td width="15%" [ngStyle]="{'width':'190px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" (change)="inputChecked($event,i)" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  routerLink="/facilities/catalog/products/detail-product-summary/{{product.productId}}">{{product.productId}}</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td width="10%">
                                                                                    {{product.internalName}}
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page - {{(pageSize > total) ? total : pageSize}} of {{total}} </p>
                                                                    <p class="paginate_data">
                                                                        View per page </p>

                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                            <br>
                                            <div class="w3-card-4 classCssShadow" style="border:1px solid;
                                            border-color:#0d3769 !important;">
                                                <header class="w3-container w3-blue">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling" style="margin-left: 7px;">
                                                            Catalog Administration Main Page
                                                        </h4>

                                                    </div>
                                                </header>

                                                <div class="w3-container">
                                                    <div class="panel-body" style="background: white;">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="row">
                                                                <form class="w-100">
                                                                    <div style="    margin-left: 2%;" class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group">
                                                                                    <label for="exampleInputEmail1">Edit Catalog With
                                                                                    Catalog Id</label>
                                                                                </div>

                                                                                <div class="col-lg-3">
                                                                                    <p-dropdown filter="true" [options]="catalogArray" optionlabel="label" [(ngModel)]="updateCatalog" [ngModelOptions]="{standalone:true}" placeholder="Select Catalog ID">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <button *ngIf="!this.Hidebtn" type="button" routerLink="/facilities/catalog/catalogs/new-product-catalog" routerLinkActive="active" [queryParams]="{ prodCatalogId: updateCatalog }" 
                                                                                    class="btn btn-outline-secondary active mr-0">Edit Catalog</button>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <button *ngIf="!this.Hidebtn" type="button" routerLink="/facilities/catalog/catalogs/new-product-catalog" routerLinkActive="active" class="btn btn-outline-secondary active">Create
                                                                                </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group">
                                                                                    <label for="exampleInputEmail1">Edit Category with
                                                                                    Category ID:</label>
                                                                                </div>

                                                                                <div class="col-lg-3">
                                                                                    <p-dropdown filter="true" [options]="categoryArray" optionlabel="label" [(ngModel)]="updateCategory" [ngModelOptions]="{standalone:true}" placeholder="Select Category ID">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <button *ngIf="!this.Hidebtn" type="button" style="    width: 111%!important;" 
                                                                                    routerLink="/facilities/catalog/catagories/new-product-catagory" routerLinkActive="active" [queryParams]="{ categoryId: updateCategory }" class="btn btn-outline-secondary active mr-0">Edit
                                                                                Category</button>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <button *ngIf="!this.Hidebtn" type="button" style="    width: 100%!important;" routerLink="/facilities/catalog/catagories/new-product-catagory" routerLinkActive="active" class="btn btn-outline-secondary active">Create
                                                                                        </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group">
                                                                                    <label for="exampleInputEmail1">Edit Product With
                                                                                    Product Id</label>
                                                                                </div>

                                                                                <div class="col-lg-3">
                                                                                    <p-dropdown filter="true" [options]="products" optionlabel="label" [(ngModel)]="productId" [ngModelOptions]="{standalone:true}" placeholder="Select Product Id">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <button *ngIf="!this.Hidebtn" type="button" class="btn btn-outline-secondary active mr-0" routerLink="/facilities/catalog/products/new-products" [queryParams]="{productId:productId}">Edit
                                                                                Product</button>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <button *ngIf="!this.Hidebtn" type="button" routerLink="/facilities/catalog/products/new-products" class="btn btn-outline-secondary active mr-0">Create
                                                                                        </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group">
                                                                                    <label for="exampleInputEmail1">Find Product With ID
                                                                                    Value</label>
                                                                                </div>

                                                                                <div class="col-lg-3">
                                                                                    <p-dropdown filter="true" [options]="products" optionlabel="label" [(ngModel)]="productIdData" [ngModelOptions]="{standalone:true}" placeholder="Select ID Value">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <button type="button" (click)="navigateToProduct()" class="btn btn-outline-secondary active mr-0">Find
                                                                                        Product</button>
                                                                                </div>

                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-6 col-12">
                                                                            <div class="form-group own-catalog-wrap">
                                                                                <div class="">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>