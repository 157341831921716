<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Assign GL Account 
                </span>
              
            </div>
            <div class=" bg-white color-grey">
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="w3-card-4 classCard">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <button type="button" class="btn btn-outline-secondary" routerLinkActive="active"
                                        (click)="createAssign()" data-target="#assignnewgl_Popup" data-toggle="modal" >Create New Assign GL Account </button>
                                    
                                 </div>
                                        <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Assign GL Account  List</label>

                                            <div class="card own-account-table">
                                                <p-table [value]="globalGLSettingAccounts" [paginator]="true"
                                                    [rows]="rows" scrollWidth="100%"
                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                                    [scrollable]="true" (sortFunction)="customSort($event)"
                                                    [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr style="background: #0d3769;">
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                <div class="checkbox-align">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox" binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined"
                                                                                    value="undefined"></div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>
                                                                 <div style="color: white;">   Code<p-sortIcon field="code"></p-sortIcon>
                                                           </div>     </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                <div style="color: white;">    Account Name <p-sortIcon field="name"></p-sortIcon></div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="quantity"><div style="color: white;">  Parent Gl Account ID
                                                                <p-sortIcon field="quantity"></p-sortIcon></div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">   Gl Account Type ID <p-sortIcon field="price">
                                                                </p-sortIcon></div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">   Gl Account Class ID<p-sortIcon field="price">
                                                                </p-sortIcon></div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">   Gl Resource Type ID<p-sortIcon field="price">
                                                                </p-sortIcon></div>
                                                                <div>
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">   Gl Xbrl Class ID<p-sortIcon field="price"></p-sortIcon></div>
                                                                <div>
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">    Parent Gl Xbrl Class ID<p-sortIcon field="price">
                                                                </p-sortIcon></div>
                                                                <div>
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">    Description<p-sortIcon field="price"></p-sortIcon>
                                                                </div> <div>
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">    Product ID<p-sortIcon field="price"></p-sortIcon>
                                                                </div> <div>
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">    External ID<p-sortIcon field="price"></p-sortIcon>
                                                                </div>   <div>
                                                                </div>
                                                            </th>


                                                        
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-product>
                                                        <tr>
                                                            <td [ngStyle]="{'width':'170px'}"
                                                                (click)="accountDetails()">
                                                                <div class="checkbox-align">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox" binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined"
                                                                                    value="undefined"></div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>
                                                                    {{product.glAccountId}}
                                                                </div>
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.accountName}}
                                                            </td>


                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.parentGlAccountId}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.glAccountType}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.glAccountClass}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.glResourceType}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.glXbrlClass}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">

                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.description}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.productId}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.externalId}}
                                                            </td>
                                                           
                                                    </ng-template>
                                                </p-table>
                                                <p class="paginate_data">
                                                    View per page </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
          

        </div>
    </div>
</div>

<div class="modal fade" id="assignnewgl_Popup" tabindex="-1" role="dialog" aria-labelledby="assignnewgl_Popup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Assign New GL Account</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebuttonN>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <br>


                <div class="w3-card-4 classCardPopUp" style="width: 100%;margin-top:0%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                                  background: #F5F7FA!important;">

                            <li class="aSize" [ngClass]="activeCategoryInv==2?'active':''">
                                Assign New GL Account</li>


                        </ul>
                    </div>
                    <div>
                        <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                            <div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100">

                                            <div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <form  class="w-100" [formGroup]="assignGlAccount">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">
                                                                            Gl Account ID</label>
                                                                    </div>
                                                                 
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown  placeholder="Enter Gl Account ID" filter="true"
                                                                    formControlName="glAccountId" 
                                                                    [options]="assignGLPartyIdArray" optionlabel="label">
                                                                                    
                                                                    </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Company ID</label>
                                                                    </div>
                                                                 
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown  placeholder="Enter Company ID" filter="true"
                                                                    formControlName="organizationPartyId" 
                                                                    [options]="partyIdArray" optionlabel="label" >
                                                                                    
                                                                    </p-dropdown>
                                                                   
                                                                    </div>
                                
                                                                   
                                
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">From Date</label>
                                                                    </div>
                                                                 
                                                                    <div class="col-lg-2">
                                                                        <input type="date"   class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="Enter Account Name"
                                                                        formControlName="fromDate" >
                                                                    </div>
                                                                   
                                
                                                                   
                                
                                                                </div>
                                                            </div>
                                                         
                                                          
                                                         
                                                        </div>
                                                        
                        
                                                    </form>
                                                </div>
                                                
                                            </div>
                                        </form>
                                        <div class="main-submit-button" style="margin-left: 61%;">
                              
                                            <button type="submit"    (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>
                                            
                                         
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>


            </div>

        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner> 