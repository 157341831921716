<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Accounting Preferences For: Your Company Name Here [{{this.partyId}}] </span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            

            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                           
                            <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategoryFunction(7)"><a>
                                    Check </a></li>



                        </ul>
                    </div>
                    <div>
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">

                                              
                                                <div *ngIf="activeCategory==7">
                                                    <br>
                                                    <div class="w3-card-4 classCard">
                                                        <div class="container-fluid">
                                                            <ul class="tabbing-section tabbing-accordians tabClass"
                                                                style="
                                                    flex-wrap: wrap !important;
                                                    display: flex !important;
                                                   ">

                                                                <li [ngClass]="activeCategoryCheck==2?'active':''"
                                                                    (click)="changeactiveCategoryFunctionSubMenuCheck(2)">
                                                                    <a>
                                                                        Checks to Print</a>
                                                                </li>
                                                                <li [ngClass]="activeCategoryCheck==3?'active':''"
                                                                    (click)="changeactiveCategoryFunctionSubMenuCheck(3)">
                                                                    <a>
                                                                        Mark Check Sent
                                                                    </a>
                                                                </li>






                                                            </ul>
                                                        </div>
                                                        <br>

                                                        <div *ngIf="activeCategoryCheck==2">

                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling HLeft">
                                                                    Checks to Print
                                                                </h4>
                                                                <span class="edit-buttons HRight">

                                                                    <a (click)="generatePdfmarkChecksSent()">Print PDF


                                                                    </a></span>


                                                            </div>

                                                            <div class="panel-body">
                                                                <div>
                                                                    <div class="container-fluid">

                                                                        <div>

                                                                            <div>
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">



                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="getCheckDetailsValue"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>





                                                                                                                Payment
                                                                                                                ID
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Party To
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Amount
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Effective
                                                                                                                Date
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    (click)="markCheckPrintData($event)"
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>







                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Select
                                                                                                                </div>
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>

                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>









                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                <span class="account-button">{{product.paymentId}}</span>
                                                                                                                

                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.partyIdToDesc}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.amount}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.effectiveDate|date:'yyyy-MM-dd
                                                                                                            hh:mm:ss'}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <p-checkbox
                                                                                                                (click)="paymentData(product.paymentId)"
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                        </td>







                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br>

                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div *ngIf="activeCategoryCheck==3">

                                                            <div class="panel-body">
                                                                <div>
                                                                    <div class="container-fluid">

                                                                        <div>

                                                                            <div>

                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">


                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="getCheckDetailsValue"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>





                                                                                                                Payment
                                                                                                                ID
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Party To
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Amount
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Effective
                                                                                                                Date
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Reference
                                                                                                                Number
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    (click)="markCheckPrint($event)"
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>





                                                                                                                Select
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>

                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>









                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                <span class="account-button">{{product.paymentId}}</span>
                                                                                                                

                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.partyIdToDesc}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.amount}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.effectiveDate|date:'yyyy-MM-dd
                                                                                                            hh:mm:ss'}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <input
                                                                                                                type="email"
                                                                                                                [(ngModel)]="product.paymentRefNum"
                                                                                                                [ngModelOptions]="{standalone: true}">
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <p-checkbox
                                                                                                                (click)="payment(product.paymentId)"
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                        </td>







                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <button _ngcontent-cta-c128=""
                                                                                        style="font-size: 12px!important;margin-left: 80%;"
                                                                                        type="submit"
                                                                                        class="btn btn-secondary submit-btn"
                                                                                        (click)="sendmarkChecksSent();">Send</button>&nbsp;
                                                                                </div>
                                                                            </div>
                                                                            <br>

                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>






                                                </div>




                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>