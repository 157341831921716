import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {FormGroup, FormBuilder} from '@angular/forms';
import { CRMAccountService } from 'src/app/services/crm-account.service'
import { GlobalResourceService } from 'src/app/services/global-resource.service'
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { NgxSpinnerService } from 'ngx-spinner';
      import { DatePipe } from '@angular/common'; import { Location } from '@angular/common';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import Swal from 'sweetalert2'
import { AddLeaveService } from 'src/app/human-resource/leave/add-new-leaves/add-new-leaves-service';
import { AddLeaveEmpService } from 'src/app/human-resource/leave/add-new-leaves/add-new-leaves.service';
import { EmployeeLeaveService } from 'src/app/human-resource/leave/employee-leave/employee-leave.service';


@Component({
  selector: 'app-my-portal-my-leave',
  templateUrl: './my-portal-my-leave.component.html',
  styleUrls: ['./my-portal-my-leave.component.css']
})
export class MyPortalMyLeaveComponent implements OnInit {

  activeCategory=2;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  @ViewChild('closebutton') closebutton;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  userLoginIdArray:any[]=[];
  partyId: string;
  payment: any;
  MyLeave: any;
  isApproval: boolean;
  partyIdData: string;
  leaveStatusReoort: any;
  leaveStatusReoortArray: any[]=[];
  updateefromdate: string;
  updateetodate: string;
  leaveTypeIds: any;
  leaveId: any[]=[];
  leaveReason: any;
  leaveReasonArray: any[]=[];
  partyIdArray: any[]=[];
  partyIdvalue: any;
  show: boolean;
  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    public addLeaveService: AddLeaveService,
    readonly addLeaveEmpService: AddLeaveEmpService,
      private _location: Location,
      readonly toastr: ToastrService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly employeeLeaveService: EmployeeLeaveService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService){
      this.isApproval = false;
      this.partyIdData=localStorage.getItem("partyId")
      this.partyId=localStorage.getItem("partyId")
    }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.addLeaveService.add_leave_form = null;
    this.addLeaveService.addLeaveForm(this.addLeaveService.add_leave_form);
    this.getMyLeave();
    this.show=false;
    this.getLeaveReasonId();
    this.getPartyId();
    this.leavereason();
    this.leaveStatus();
  }
  leaveStatus() {
    this.spinner.show();
    this.employeeLeaveService.getLeaveStatus().subscribe(res => {
      this.leaveStatusReoort = res.data;
      this.spinner.hide();
      for (const value of this.leaveStatusReoort) {
        this.leaveStatusReoortArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
   
  }
  homeButton(){
    this._Router.navigate(['/myPortal/my-portal-my-profile'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  onSubmit() {
    this.spinner.show();
    const fromdate = this.addLeaveService.add_leave_form.get('fromDate').value;
    this.updateefromdate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");

    const todate = this.addLeaveService.add_leave_form.get('thruDate').value;
    this.updateetodate = this.datePipe.transform(todate, "yyyy-MM-dd hh:mm:ss");

    const formDate = {
      approverPartyId: this.addLeaveService.add_leave_form.value.approverPartyId,
      description: this.addLeaveService.add_leave_form.value.description,
      emplLeaveReasonTypeId: this.addLeaveService.add_leave_form.value.emplLeaveReasonTypeId,
      fromDate: this.updateefromdate,
      leaveTypeId: this.addLeaveService.add_leave_form.value.leaveTypeId,
      partyId: this.partyIdData,
      thruDate: this.updateetodate,
      leaveStatus:'LEAVE_CREATED',
      
    }
    this.addLeaveEmpService.AddLeave(formDate).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.addLeaveService.add_leave_form.reset();
        this.getMyLeave();
        
      
      }
      else if (res.success == false) {
        this.spinner.hide();
        this.toastr.error(res.message.debugMessage);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
      
    })
   
   
  }

 
  getLeaveReasonId() {
    this.spinner.show();
    this.addLeaveEmpService.getLeaveReasonType().subscribe(res => {
      this.leaveTypeIds = res.data.leaveTypeId;
      this.spinner.hide();
      for (const value of this.leaveTypeIds) {
        this.leaveId.push({
          label: value.description,
          value: value.leaveTypeId
        })
      }
    })
 
  }
  leavereason() {
    this.spinner.show();
    this.addLeaveEmpService.getLeaveReason().subscribe(res => {
      this.leaveReason = res.data.leaveTypeId;
      this.spinner.hide();
      for (const value of this.leaveReason) {
        this.leaveReasonArray.push({
          label: value.description,
          value: value.emplLeaveReasonTypeId
        })
      }
    })
 
  }

  getPartyId() {
    this.spinner.show();
    this.addLeaveEmpService.getPartyId().subscribe(res => {
      this.partyIdvalue = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.partyIdvalue) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  onUpdate(epartyId, eleaveTypeId, efromDate, ethruDate, eAppId, eDescription, eReasonid) {


    this._Router.navigate(['/myPortal/portal-leave-summary'], { queryParams: { partyId: epartyId, leaveTypeId: eleaveTypeId, fromDate: efromDate, thruDate: ethruDate, AppId: eAppId, Description: eDescription, Reasonid: eReasonid, approval: false } })
  }

  GoToQuickLink(){
    this._Router.navigate(['/myPortal/create-leave']);
  } 
  getMyLeave() {
    this.spinner.show();
    this.accountsService.getMyLeave(this.finSize).subscribe(res => {
      this.MyLeave = res.data;
      this.spinner.hide();
    })
  
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
