import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

import { NgxSpinnerService } from 'ngx-spinner';
import { ProductionService } from '../production.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
@Component({
  selector: 'app-detail-calc-cost',
  templateUrl: './detail-calc-cost.component.html',
  styleUrls: ['./detail-calc-cost.component.css']
})
export class DetailCalcCostComponent implements OnInit {
  createCost: FormGroup;
  @ViewChild('closebutton') closebutton;
  activeCategory = 2;
  partyId: any;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[] = [];
  varianceReasonId: any;
  varianceReasonIdArray: any[] = [];
  glAccountid: any;
  varianceReasonIdData: any;
  show: boolean;
  budgetId: any;
  personId: any;
  partyIdArray: any[] = [];
  ReviewResultTypes: any;
  ReviewResultTypesArray: any[] = [];
  reviewDate: string;
  currencyArray: any[]=[];
  costCustomMethodID: any;
  costCustomMethodIDArray: any[]=[];
  CalcId: any;

  calcInfo: any;
  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
private _location: Location,
readonly _Router: Router,
    readonly productionService: ProductionService,
    readonly router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly accountsService: AccountsService,
    readonly _ToastrService: ToastrService) { 
      this.createCost = this._FormBuilder.group({
        Description: [''],
        FixedCost: [''],
        VariableCost: [''],
        PerMilliSecond: [''],
        Currency: [''],
        CostCustomMethodID: [''],
  
  
      });
    }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.CalcId = params["CalcId"];
    });
    this.show=true;
    this.getProdCostById();
    this.getCurrency();
    this.getInitialDataCost();
  }
  getProdCostByIdData() {
    this.spinner.show();
   
    this.productionService.getProdCostById(this.CalcId).subscribe((res: any) => {
      this.spinner.hide();
      setTimeout(() => {
      
        const formValue = this.createCost;

        formValue.patchValue({
         
        Description: res.data.description,
        FixedCost: res.data.fixedCost,
        VariableCost: res.data.variableCost,
        PerMilliSecond: res.data.perMilliSecond,
        Currency: res.data.currencyUomId,
        CostCustomMethodID: res.data.costCustomMethodId,
        })
      
      }, 3000);
    })
  
  }
  
  getInitialDataCost() {
    this.spinner.show();
    this.accountsService.getInitialDataCost().subscribe(res => {
      
      this.costCustomMethodID = res.data.costCustomMethodID;
    
      this.spinner.hide();
     
      for (const value of this.costCustomMethodID) {
        this.costCustomMethodIDArray.push({
          label: value.description,
          value: value.customMethodId
        })
      }
     
    })
   
  }
  homeButton(){
    this.router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
 
  onUpdate() {
    this.spinner.show();
    
    const requestData = {
      "costComponentCalcId":this.CalcId,
      "costCustomMethodId": this.createCost.value.CostCustomMethodID,
      "costGlAccountTypeId":"",
      "currencyUomId": this.createCost.value.Currency,
      "description":this.createCost.value.Description,
      "fixedCost":this.createCost.value.FixedCost,
      "offsettingGlAccountTypeId": "",
      "perMilliSecond": this.createCost.value.PerMilliSecond,
      "variableCost": this.createCost.value.VariableCost,
    }

    this.productionService.updateProdCost(requestData).subscribe((res: any) => {
     
      if (res.success) {
      
        this._ToastrService.success("Updated Successfully");
        this.closebutton.nativeElement.click();
        this._Router.navigate(['/production/cost-cacls-list']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
   
  }

  getProdCostById() {
    this.spinner.show();

    this.productionService.getProdCostById(this.CalcId).subscribe((res: any) => {
      this.spinner.hide();
      this.calcInfo = res.data

    })

  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
