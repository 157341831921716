import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {HeaderService} from './../../../header/header.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { AccountsService} from '../../../crm/accounts/accounts.service';
import {DropdownModule} from 'primeng/dropdown';

@Component({
  selector: 'app-return-history',
  templateUrl: './return-history.component.html',
  styleUrls: ['./return-history.component.css']
})
export class ReturnHistoryComponent implements OnInit {
  products3: any;
  activeCategory = 1;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  total=0;
  partyId: any = [];
  public cols: any[];
  getContacts: any = [];
  @Input() orderHistory: any[];
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: ""
  }
  constructor(
    readonly accountsService: AccountsService,
    readonly  HeaderService: HeaderService,
    readonly router: Router  ) {

     }
     offerShowingLineColoumns = [
      { field: 'status', header: 'Return ID' },
      { field: 'name', header: 'Return Item Seq ID' },
      { field: 'email', header: 'Status ID' },
      { field: 'phone', header: 'Date' },
      { field: 'type', header: 'Modified By User Login ID' },
    ];
    							
    offerShowingLineData=[{
      status:'10061	',
      name:'00001',
      email:'Requested',
      phone:'2020-11-30 19:59:15.084	',
      type:'admin',
    }  ,
  
 ];
 orderItemsColumns = [
  { field: 'status', header:'Product' },
  { field: 'name', header: 'Quantity' },
  { field: 'email', header: 'Unit Price' },
  { field: 'phone', header: 'Adjustments' },
  { field: 'type', header: 'Sub Total' },
];
              
orderItemsData=[{
  status:'GZ-1000 - Tiny Gizmo',
  name:'1',
  email:'US$15.99',
  phone:'US$0.00',
  type:'US$15.99  ',
}  ,

]

  ngOnInit(): void {
    this.products3 = this.accountsService.customerDetails()
  
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
}
