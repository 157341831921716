import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/app.constant';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AddSkillService {

  constructor(readonly http:HttpClient) { }

 getSkillTypeList():Observable<any> {
   const skillList = URLS.getSkillTypeList;
   return this.http.get(skillList);
 }
 deleteCommunicationEvent(formData): Observable<any> {
  const deleteCommunicationEvent = URLS.deleteCommunicationEvent ;
  return this.http.post(deleteCommunicationEvent, formData);
}
 setCustRequestStatusList():Observable<any> {
  const setCustRequestStatusList = URLS.setCustRequestStatusList;
  return this.http.get(setCustRequestStatusList);
}
 setCustRequestStatus(formData): Observable<any> {
  const setCustRequestStatus = URLS.setCustRequestStatus ;
  return this.http.post(setCustRequestStatus, formData);
}
 getPartyId():Observable<any> {
  const getPartyId = URLS.getPersonsPartyId;
  return this.http.get(getPartyId);
}
getPartyIdRole():Observable<any> {
  const getPartyId = URLS.getPersonsPartyId;
  return this.http.get(getPartyId);
}
updateSkills(formData,id):Observable<any> {
  const updateSkill = URLS.updateSkill.replace("updateSkill",id);
  return this.http.put(updateSkill,formData);
}
 createSkills(formData):Observable<any> {
   const addSkills = URLS.createSkills;
   return this.http.post(addSkills,formData);
 }
 getType():Observable<any> {
  const getType = URLS.getType;
  return this.http.get(getType);
}
getPurpose():Observable<any> {
  const getPurpose = URLS.getPurpose;
  return this.http.get(getPurpose);
}

getCurrencyList():Observable<any> {
  const getCurrencyList = URLS.getCurrencyList;
  return this.http.get(getCurrencyList);
}
getTemporalExpression():Observable<any> {
  const getTemporalExpression = URLS.getTemporalExpression;
  return this.http.get(getTemporalExpression);
}
getFacility():Observable<any> {
  const getFacility = URLS.getFacility;
  return this.http.get(getFacility);
}
getFixedAssetList():Observable<any> {
  const getFixedAssetList = URLS.getFixedAssetList;
  return this.http.get(getFixedAssetList);
}
getMyRates():Observable<any> {
  const getMyRates = URLS.getMyRates;
  return this.http.get(getMyRates);
}
getWorkEffortAssocType():Observable<any> {
  const getWorkEffortAssocType = URLS.getWorkEffortAssocType;
  return this.http.get(getWorkEffortAssocType);
}
getRoleTypeId():Observable<any> {
  const getRoleTypeId = URLS.getRoleTypeId;
  return this.http.get(getRoleTypeId);
}
getStatusNewWorkEffort():Observable<any> {
  const getStatusNewWorkEffort = URLS.getStatusNewWorkEffort;
  return this.http.get(getStatusNewWorkEffort);
}
getCommunicationEventId():Observable<any> {
  const getCommunicationEventId = URLS.getCommunicationEventId;
  return this.http.get(getCommunicationEventId);
}
getFacilityIdList():Observable<any> {
  const getFacilityIdList = URLS.getFacilityIdList;
  return this.http.get(getFacilityIdList);
}
getRequirementList():Observable<any> {
  const getRequirementList = URLS.getRequirementList;
  return this.http.get(getRequirementList);
}
getStatusList():Observable<any> {
  const getStatusList = URLS.getStatusList;
  return this.http.get(getStatusList);
}
getAvailabilityList():Observable<any> {
  const getAvailabilityList = URLS.getAvailabilityList;
  return this.http.get(getAvailabilityList);
}
getExpectationList():Observable<any> {
  const getExpectationList = URLS.getExpectationList;
  return this.http.get(getExpectationList);
}
getDelegateReasonList():Observable<any> {
  const getDelegateReasonList = URLS.getDelegateReasonList;
  return this.http.get(getDelegateReasonList);
}
getWorkEffortRate():Observable<any> {
  const getWorkEffortRate = URLS.getWorkEffortRate;
  return this.http.get(getWorkEffortRate);
}
getPeriodTypeId():Observable<any> {
  const getPeriodTypeId = URLS.getPeriodTypeId;
  return this.http.get(getPeriodTypeId);
}
getCurrencyDataList(): Observable<any> {
  const getCurrencyDataList = URLS.getCurrencyDataList;
  return this.http.get(getCurrencyDataList);
}
getCommunicationTypeId(): Observable<any> {
  const getCommunicationTypeId = URLS.getCommunicationTypeId;
  return this.http.get(getCommunicationTypeId);
}
geContactMechType(): Observable<any> {
  const geContactMechType = URLS.geContactMechType;
  return this.http.get(geContactMechType);
}
getRoleTypeIds(): Observable<any> {
  const getRoleTypeIds = URLS.getRoleTypeIds;
  return this.http.get(getRoleTypeIds);
}
getShoppingListId(): Observable<any> {
  const getShoppingListId = URLS.getShoppingListId;
  return this.http.get(getShoppingListId);
}
getCustRequestIds(): Observable<any> {
  const getCustRequestIds = URLS.getCustRequestIds;
  return this.http.get(getCustRequestIds);
}
getCustRequestItem(): Observable<any> {
  const getCustRequestItem = URLS.getCustRequestItem;
  return this.http.get(getCustRequestItem);
}

getWorkReqFulfTypeId(): Observable<any> {
  const getWorkReqFulfTypeId = URLS.getWorkReqFulfTypeId;
  return this.http.get(getWorkReqFulfTypeId);
}
getRequirementTypeList(): Observable<any> {
  const getRequirementTypeList = URLS.getRequirementTypeList;
  return this.http.get(getRequirementTypeList);
}
getProductId(): Observable<any> {
  const getProductId = URLS.getProductId;
  return this.http.get(getProductId);
}
getQuoteIdList(): Observable<any> {
  const getQuoteIdList = URLS.getQuoteIdList;
  return this.http.get(getQuoteIdList);
}
getQuoteItemList(): Observable<any> {
  const getQuoteItemList = URLS.getQuoteItemList;
  return this.http.get(getQuoteItemList);
}
getOrderId(): Observable<any> {
  const getOrderId = URLS.getOrderId;
  return this.http.get(getOrderId);
}
getTimeSheetId(): Observable<any> {
  const getTimeSheetId = URLS.getTimeSheetId;
  return this.http.get(getTimeSheetId);
}
getInvoiceId(): Observable<any> {
  const getInvoiceId = URLS.getInvoiceId;
  return this.http.get(getInvoiceId);
}
getContentIdList(): Observable<any> {
  const getContentIdList = URLS.getContentIdList;
  return this.http.get(getContentIdList);
}
addWorkEffortTimeToInvoiceFormSTATUS():Observable<any>{
  const addWorkEffortTimeToInvoiceFormSTATUS = URLS.addWorkEffortTimeToInvoiceFormSTATUS;
  return this.http.get(addWorkEffortTimeToInvoiceFormSTATUS);
}
getContentTypeIds(): Observable<any> {
  const getContentTypeIds = URLS.getContentTypeIds;
  return this.http.get(getContentTypeIds);
}
getProductIdsList(): Observable<any> {
  const getProductIdsList = URLS.getProductIdsList;
  return this.http.get(getProductIdsList);
}
getWorkEffortId(): Observable<any> {
  const getWorkEffortId = URLS.getWorkEffortId;
  return this.http.get(getWorkEffortId);
}
getMimeTypeIds(): Observable<any> {
  const getMimeTypeIds = URLS.getMimeTypeIds;
  return this.http.get(getMimeTypeIds);
}
getContactListId(): Observable<any> {
  const getContactListId = URLS.getContactListId;
  return this.http.get(getContactListId);
}
getEventType(): Observable<any> {
  const getEventType = URLS.getEventType;
  return this.http.get(getEventType);
}
getQuoteItemSeqId(): Observable<any> {
  const getQuoteItemSeqId = URLS.getQuoteItemSeqId;
  return this.http.get(getQuoteItemSeqId);
}
getContentId(): Observable<any> {
  const getContentId = URLS.getContentId;
  return this.http.get(getContentId);
}
getStatusListsss(): Observable<any> {
  const getStatusListsss = URLS.getStatusListsss;
  return this.http.get(getStatusListsss);
}
getAttachDocumentByPartyId(partyId): Observable<any> {
  const getAttachDocumentByPartyId = URLS.getAttachDocumentByPartyId + "?partyId=" + partyId;
  return this.http.get(getAttachDocumentByPartyId);
}
attachDocument(formData,partyId): Observable<any> {
  const attachDocument = URLS.attachDocument + "?partyId=" + partyId;
  return this.http.post(attachDocument, formData);
}
updateAgreements(data): Observable<any> {
  const updateAgreements = URLS.updateAgreements;
  return this.http.put(updateAgreements,data);
}
getAgreements(id): Observable<any> {
  const getAgreements = URLS.getAgreements + "?agreementId=" + id;
  return this.http.get(getAgreements);
}
getAgreementByPartyId(id): Observable<any> {
  const getAgreementByPartyId = URLS.getAgreementByPartyId + "?partyId=" + id;
  return this.http.get(getAgreementByPartyId);
}
createAgreements(form): Observable<any> {
  const createAgreements = URLS.createAgreements ;
  return this.http.post(createAgreements,form);
}
cancelAgreements(id): Observable<any> {
  const cancelAgreements = URLS.cancelAgreements + "?agreementId=" + id;
  return this.http.post(cancelAgreements,"");
}
downloadDocument(attachDocumentId): Observable<any> {
  const downloadDocument = URLS.downloadDocument + "?attachDocumentId=" + attachDocumentId;
  return this.http.get(downloadDocument, { responseType: "blob" });
}
}
