<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">My Payments</span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span> 
        
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategories==1?'active':''" (click)="changeactiveCategory(1)"><a>
                                    Find</a></li>
                            <li [ngClass]="activeCategories==2?'active':''" (click)="changeactiveCategory(2)"><a>
                                    Overview </a></li>
                            <li [ngClass]="activeCategories==3?'active':''" (click)="changeactiveCategory(3)"
                            *ngIf="this.invoiceDetailsStatus=='INVOICE_IN_PROCESS'||this.invoiceDetailsStatus=='INVOICE_APPROVED'||this.invoiceDetailsStatus=='INVOICE_RECEIVED'"><a>
                                    Header </a></li>

              <li [ngClass]="activeCategories==4?'active':''" (click)="changeactiveCategory(4)"
              *ngIf="this.invoiceDetailsStatus=='INVOICE_IN_PROCESS'||this.invoiceDetailsStatus=='INVOICE_RECEIVED'"><a>
                                    Items</a></li>

                            <li [ngClass]="activeCategories==5?'active':''" (click)="changeactiveCategory(5)"
                            *ngIf="this.invoiceDetailsStatus=='INVOICE_IN_PROCESS'||this.invoiceDetailsStatus=='INVOICE_RECEIVED'"><a>
                                    Time Entries</a></li>

                            <li [ngClass]="activeCategories==6?'active':''" (click)="changeactiveCategory(6)"
                            *ngIf="this.invoiceDetailsStatus=='INVOICE_IN_PROCESS'||this.invoiceDetailsStatus=='INVOICE_RECEIVED'"><a>
                                    Roles</a></li>

                            <li [ngClass]="activeCategories==7?'active':''" (click)="changeactiveCategory(7)"
                            *ngIf="this.invoiceDetailsStatus=='INVOICE_IN_PROCESS'||this.invoiceDetailsStatus=='INVOICE_RECEIVED'"><a>
                                    Terms </a></li> 


                            <li [ngClass]="activeCategories==8?'active':''" (click)="changeactiveCategory(8)"
                            *ngIf="this.invoiceDetailsStatus=='INVOICE_IN_PROCESS'||this.invoiceDetailsStatus=='INVOICE_RECEIVED'"><a>
                                    Applications </a></li>

                           <li [ngClass]="activeCategories==9?'active':''" (click)="changeactiveCategory(9)"
                           *ngIf="this.invoiceDetailsStatus=='INVOICE_IN_PROCESS'||this.invoiceDetailsStatus=='INVOICE_APPROVED'||this.invoiceDetailsStatus=='INVOICE_READY'||this.invoiceDetailsStatus=='INVOICE_WRITEOFF'||this.invoiceDetailsStatus=='INVOICE_RECEIVED'||
                           this.invoiceDetailsStatus=='INVOICE_PAID'||this.invoiceDetailsStatus=='INVOICE_CANCELLED'"><a>
                                    Send per Email</a></li>

                            <li [ngClass]="activeCategories==10?'active':''" (click)="changeactiveCategory(10)"
                            *ngIf="this.invoiceDetailsStatus=='INVOICE_IN_PROCESS'||this.invoiceDetailsStatus=='INVOICE_APPROVED'||this.invoiceDetailsStatus=='INVOICE_WRITEOFF'||
                            this.invoiceDetailsStatus=='INVOICE_READY'||this.invoiceDetailsStatus=='INVOICE_PAID'||this.invoiceDetailsStatus=='INVOICE_CANCELLED'||this.invoiceDetailsStatus=='INVOICE_RECEIVED'"><a>
                                    Commission Run</a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                    <div class="submenu d-flex ml-3 mt-3"
                                        style="width: 100%!important; margin-bottom: 10px; margin-left: -5px !important;">
                                        <div>
                                            <button _ngcontent-cta-c128="" type="submit"
                                            class="btn btn-secondary submit-btn ml-2 fontMenu"
                                              (click)="createPayment()">Create</button>
                                        </div>
                                        <div>
                                            <button _ngcontent-cta-c128="" type="submit"
                                            *ngIf="this.invoiceDetailsStatus=='INVOICE_IN_PROCESS'||this.invoiceDetailsStatus=='INVOICE_APPROVED'
                                            ||this.invoiceDetailsStatus=='INVOICE_CANCELLED'||this.invoiceDetailsStatus=='INVOICE_WRITEOFF'
                                            ||this.invoiceDetailsStatus=='INVOICE_RECEIVED'||this.invoiceDetailsStatus=='INVOICE_READY'||this.invoiceDetailsStatus=='INVOICE_PAID'"
                                                class="btn btn-secondary submit-btn fontMenu" 
                                                (click)="copyInvoice()">Copy</button>
                                        </div>
                                        <div>
                                            <button _ngcontent-cta-c128="" type="submit"
                                            *ngIf="this.invoiceDetailsStatus=='INVOICE_IN_PROCESS'||this.invoiceDetailsStatus=='INVOICE_APPROVED'
                                            ||this.invoiceDetailsStatus=='INVOICE_CANCELLED'||this.invoiceDetailsStatus=='INVOICE_RECEIVED'
                                            ||this.invoiceDetailsStatus=='INVOICE_READY'||this.invoiceDetailsStatus=='INVOICE_WRITEOFF'||this.invoiceDetailsStatus=='INVOICE_PAID'"
                                                class="btn btn-secondary submit-btn ml-2 fontMenu" 
                                                (click)="generatePdfMyPortal()">PDF
                                            </button>
                                        </div>
                                        <div>
                                            <button _ngcontent-cta-c128="" type="submit"
                                            *ngIf="this.invoiceDetailsStatus=='INVOICE_IN_PROCESS'||this.invoiceDetailsStatus=='INVOICE_APPROVED'
                                            ||this.invoiceDetailsStatus=='INVOICE_CANCELLED'||this.invoiceDetailsStatus=='INVOICE_RECEIVED'||
                                            this.invoiceDetailsStatus=='INVOICE_READY'||this.invoiceDetailsStatus=='INVOICE_WRITEOFF'"
                                                class="btn btn-secondary submit-btn ml-2 fontMenu" 
                                                (click)="generatePdfMyPortal()" >PDF default Currency(USD)</button>
                                        </div>
                                        <div>
                                            <button _ngcontent-cta-c128="" type="submit"
                                            *ngIf="this.invoiceDetailsStatus=='INVOICE_IN_PROCESS'||this.invoiceDetailsStatus=='INVOICE_RECEIVED'"
                                                class="btn btn-secondary submit-btn ml-2 fontMenu" 
                                                (click)="setPaymentStatusApprove()">
                                                Status to 'Approved'

                                            </button>
                                        </div>
                                        <div>
                                            <button _ngcontent-cta-c128="" type="submit" (click)="setPaymentStatusReady()"
                                            *ngIf="this.invoiceDetailsStatus=='INVOICE_IN_PROCESS'||this.invoiceDetailsStatus=='INVOICE_RECEIVED'
                                            "
                                                class="btn btn-secondary submit-btn ml-2 fontMenu" >Status to 'Ready'</button>
                                        </div>

                                         
                                        <div>
                                            <button _ngcontent-cta-c128="" type="submit"
                                            *ngIf="this.invoiceDetailsStatus=='INVOICE_IN_PROCESS'"
                                                class="btn btn-secondary submit-btn ml-2 fontMenu" 
                                                (click)="changeactiveCategory(2)">Add Tax</button>
                                        </div>
                                        
                                        <div>
                                            <button _ngcontent-cta-c128="" type="submit"
                                                class="btn btn-secondary submit-btn ml-2 fontMenu"
                                                *ngIf="this.invoiceDetailsStatus=='INVOICE_IN_PROCESS'||this.invoiceDetailsStatus=='INVOICE_APPROVED'||
                                                this.invoiceDetailsStatus=='INVOICE_RECEIVED'||this.invoiceDetailsStatus=='INVOICE_READY'"
                                                (click)="setPaymentStatusCancel()"> Status to 'Cancelled'</button>
                                        </div>


                                        <!-- <div>
                                            <button _ngcontent-cta-c128="" type="submit"
                                            *ngIf="this.invoiceDetailsStatus==''||this.invoiceDetailsStatus==''"
                                                class="btn btn-secondary submit-btn ml-2 fontMenu" 
                                                (click)="setPaymentStatusSent()">Status to 'Sent'
                                            </button>
                                        </div> -->
                                        <div>
                                            <button _ngcontent-cta-c128="" type="submit"
                                            *ngIf="this.invoiceDetailsStatus=='INVOICE_IN_PROCESS'"
                                                class="btn btn-secondary submit-btn ml-2 fontMenu"
                                                (click)="setPaymentStatusReceived()"> Status to 'Received'</button>
                                        </div>
                                        
                                        <div>
                                            <button _ngcontent-cta-c128="" type="submit"
                                            *ngIf="this.invoiceDetailsStatus=='INVOICE_CANCELLED'||this.invoiceDetailsStatus=='INVOICE_RECEIVED'||this.invoiceDetailsStatus=='INVOICE_PAID'"
                                                class="btn btn-secondary submit-btn ml-2 fontMenu" 
                                               (click)="copyInvoiceToTemplate()">
                                                Save Invoice As Template
                                            </button>
                                        </div>
                                        <div>
                                            <button _ngcontent-cta-c128="" type="submit"
                                            *ngIf="this.invoiceDetailsStatus=='INVOICE_APPROVED'||this.invoiceDetailsStatus=='INVOICE_READY'||this.invoiceDetailsStatus=='INVOICE_PAID'"
                                                class="btn btn-secondary submit-btn ml-2 fontMenu"
                                                 (click)="InvoiceAcctgTransEntriesPdf()">
                                                 Acctg Trans Entries PDF   
                                            </button>
                                        </div>
                                       
                                        
                                        <div>
                                            <button _ngcontent-cta-c128="" type="submit"
                                            *ngIf="this.invoiceDetailsStatus=='INVOICE_APPROVED'||this.invoiceDetailsStatus=='INVOICE_READY'"
                                                class="btn btn-secondary submit-btn ml-2 fontMenu"
                                                (click)="setPaymentStatusPaid()"> Status to 'Paid'</button>
                                        </div>
                                        <div>
                                            <button _ngcontent-cta-c128="" type="submit"
                                                class="btn btn-secondary submit-btn ml-2 fontMenu"
                                                *ngIf="this.invoiceDetailsStatus=='INVOICE_RECEIVED'||this.invoiceDetailsStatus=='INVOICE_READY'"
                                                (click)="setPaymentStatusInProcess()"> Status to 'In Process'</button>
                                        </div>
                                        <div>
                                            <button _ngcontent-cta-c128="" type="submit"
                                                class="btn btn-secondary submit-btn ml-2 fontMenu"
                                                *ngIf="this.invoiceDetailsStatus=='INVOICE_APPROVED'||this.invoiceDetailsStatus=='INVOICE_READY'"
                                                (click)="setPaymentStatusWriteoff()"> Status to 'Writeoff'</button>
                                        </div>
                                      
                                    </div>
                                    <div *ngIf="activeCategories==1">
                                        <div class="w3-card-4 classCardView">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling" style="margin-left: 7px;">
                                                    Find Invoice
                                                </h4>
                                            </div>
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="form-group">
                                                        <div class="row">
                                                            <form class="w-100" [formGroup]="searchInvoiceForm">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                    
                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                <label for="exampleInputEmail1">Invoice ID</label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2" >
                                                                                <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                
                                                                                aria-describedby="emailHelp" placeholder="Enter Invoice Id"
                                                                                    formControlName="invoiceId">

                                                                              
                                                                            </div>
                                                                      
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Description</label>
                                                                            </div>
                                                                          
                                                                            <div class="col-lg-2">
                                                                                <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                
                                                                                    formControlName="description" aria-describedby="emailHelp"
                                                                                placeholder="Description">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                <label for="exampleInputEmail1">Invoice Type</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" name="party" [options]="invoiceTypes"
                                                                              placeholder="Choose Invoice Type"  formControlName="invoiceTypeId" optionlabel="label">
                                                                            </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Status ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" name="party" [options]="statusIds"
                                                                                formControlName="statusId" optionlabel="label"
                                                                                    placeholder="Select Status">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                    
                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                <label for="exampleInputEmail1">From Party ID	</label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2" >
                                                                                <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                
                                                                                aria-describedby="emailHelp" placeholder="Enter From Party ID	"
                                                                                    formControlName="fromPartyId">
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">To Party ID	</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                
                                                                                    formControlName="toPartyId" aria-describedby="emailHelp"
                                                                                placeholder="To Party ID	">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                    
                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                <label for="exampleInputEmail1">Billing Account ID	</label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2" >
                                                                                <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                
                                                                                aria-describedby="emailHelp" placeholder="Enter Billing Account ID	"
                                                                                    formControlName="billingAccountId">

                                                                              
                                                                            </div>
                                                                           
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Invoice Date	</label>
                                                                            </div>
                                                                          
                                                                            <div class="col-lg-2">
                                                                                <input type="date" id="exampleInputEmail1" class="form-control"
                                                                                
                                                                                    formControlName="invoiceDate" aria-describedby="emailHelp"
                                                                                placeholder="Invoice Date">
                                                                            </div>
                                                    
                                                                            
                                                    
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                    
                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                <label for="exampleInputEmail1">Reference Number</label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2" >
                                                                                <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                
                                                                                aria-describedby="emailHelp" placeholder="Enter Reference Number"
                                                                                    formControlName="referenceNumber">
                                                                            </div>
                                                    
                                                                        </div>
                                                                    </div>
                                
                                                                </div>

                                                                <div style=" margin-left: -29%;" class="col-lg-12 col-12 main-submit-button "><button _ngcontent-cta-c128=""
                                                                    type="submit" class="btn btn-secondary submit-btn"
                                                                    (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                    <button type="button" class="btn btn-danger ml-2" (click)="resetPerInvoice()">Reset</button>
                                                                </div>


                                                            </form>
                                                           
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="w3-card-4 classCard w-100 mt-3">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Search Results
                                                </p>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="getPurchaseInvoicesData"
                                                                [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [responsive]="true" [totalRecords]="total"
                                                                [scrollable]="true" styleClass="p-datatable-customers"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th pSortableColumn="code"
                                                                            [ngStyle]="{'width':'190px'}">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                Invoice ID	 <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;"> 
                                                                                Invoice Type

                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">  Invoice Date
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="quantity">
                                                                            <div style="color: white;"> Status
                                                                                <p-sortIcon field="quantity">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="price"
                                                                            [ngStyle]="{'width':'190px'}">
                                                                            <div style="color: white;"> Description
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="price"
                                                                            [ngStyle]="{'width':'190px'}">
                                                                            <div style="color: white;"> From Party Id
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="price"
                                                                            [ngStyle]="{'width':'190px'}">
                                                                            <div style="color: white;"> To Party Id
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <!-- <th pSortableColumn="price"
                                                                            [ngStyle]="{'width':'190px'}">
                                                                            <div style="color: white;"> Total</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="price"
                                                                            [ngStyle]="{'width':'190px'}">
                                                                            <div style="color: white;"> Outstanding
                                                                                Amount</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th> -->


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                <!--  <span
                                                                                    routerLink="/financial/{{invoiceType}}/invoices/detailed-summary"
                                                                                    [queryParams]="{invoiceId:product.invoiceId}"
                                                                                     class="account-button">{{product.invoiceId}}</span> -->
                                                                                    <span>{{product.invoice.invoiceId}}</span>
                                                                            </div>
                                                                        </td>


                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.invoice.invoiceTypeId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.invoice.hfh}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.invoice.statusId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.invoice.description}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.invoice.partyIdFrom}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.invoice.partyId}}
                                                                        </td>
                                                                     



                                                                    </tr>

                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No Record Found</h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="activeCategories==2">
               
                                        <div class="w3-card-4 classCardView w-100">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                style="justify-content: space-between;">
                                                <p class="h-text">Summary</p>
                                                <span>
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                        style="margin: 0;" data-toggle="modal"
                                                        data-target="#updateHeaderOverviewPopup">Update</button>
                                                </span>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">

                                                <div class="color-black container">
                                                    <div class="row" class="row" *ngIf="invoiceDetailsById">
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Invoice Type</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.invoiceType}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Status </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.statusId}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Description</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.description}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Invoice Message </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.invoiceMessage}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">From Party ID </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.partyIdFrom}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">To Party ID </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.partyId}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Role Type ID </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.roleTypeId}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Billing Account ID</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.billingAccountId}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Invoice Date </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.invoiceDate | date : "yyyy-MM- dd HH:mm:ss"}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Due Date </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.dueDate | date : "yyyy-MM- dd HH:mm:ss"}}     </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Total </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.total}} </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Date Paid </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.paidDate | date : "yyyy-MM- dd HH:mm:ss"}} </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Reference Num</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.referenceNumber}}   </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="w3-card-4 classCard w-100 mt-3">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text" *ngIf="invoiceDetailsById.appliedPayments">Applied Payments
                                                     {{invoiceDetailsById.appliedPayments.appliedAmount}} Open {{invoiceDetailsById.appliedPayments.notAppliedAmount}}
                                                </p>

                                                <span>
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                        style="margin: 0;"  (click)="changeactiveCategory(8)">Update</button>
                                                </span>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="invoiceDetailsapplyPayment" [paginator]="true" [rows]="rows"
                                                                scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>

                                                                                Item No
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Product ID			
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Description </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Total</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Payment ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Amount Applied

                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>



                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span
                                                                                     class="account-button"  >
                                                                                    {{product.invoiceItemSeqId}}</span>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                           
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.total}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.paymentId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.amountApplied}}
                                                                        </td>
                    

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No
                                                                                    Record Found
                                                                                </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="w3-card-4 classCard w-100 mt-3">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Status </p>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="this.transactionListStatusTable" [paginator]="true" [rows]="rows"
                                                                scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>

                                                                                Status Date
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Status
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Change By User Login ID </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span
                                                                                     class="account-button"  >
                                                                                    {{product.statusDate | date :"yyyy-MM-dd HH:mm:ss"}}</span>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.status}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.changeByUserLoginId}}
                                                                        </td>
                                                                   
                                                                       

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No
                                                                                    Record Found
                                                                                </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    
                                        <div class="w3-card-4 classCardView w-100 mt-3 mb-3">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Roles
                                                </p>
                                                <span class="edit-buttons" style="cursor: pointer;"
                                                (click)="changeactiveCategory(6)">Update</span>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="invoiceDetailsRole" [paginator]="true" [rows]="rows"
                                                                scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>

                                                                                Party ID		
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Name
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Role Type ID </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Percentage</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Date Time Performed
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                            


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span
                                                                                     class="account-button"  >
                                                                                    {{product.partyId}}</span>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.party}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.roleType}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.percentage}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.datetimePerformed | date :"yyyy-MM-dd HH:mm:ss"}}
                                                                        </td>
                                                                     

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No
                                                                                    Record Found
                                                                                </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="w3-card-4 classCard w-100 mt-3">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Terms </p>
                                                <span class="edit-buttons hCreate"
                                                (click)="changeactiveCategory(7)" >Update</span>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="invoiceTerms" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color:white">
                                                                                Invoice Term ID
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="name" style="width: 1rem;">
                                                                            <div style="color: white;"> Term Type ID
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Item No
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Item Value
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Term Days
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Text Value
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Description
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Uom
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Created Date
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Action
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <ng-container >
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <div class="checkbox-align">
                                                                                    {{product.invoiceTermId}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.termTypeId}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.itemNo}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.termValue}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.termDays}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.textValue}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.description}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.uom}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.createdStamp | date:'yyyy-MM-d hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn " 
                                                                                data-toggle="modal"  data-target="#termsPopup" (click)="updateTerm(product)">Update</button>
                                                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn " 
                                                                                (click)="deleteInvoiceTerm(product.invoiceTermId)">Delete</button>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>

                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                       <h4 class="ml-2">No Record Found</h4>
                                                                                   </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                        </div>
                                        <div class="w3-card-4 classCard w-100 mt-3">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Terms </p>
                                                <span class="edit-buttons hCreate"
                                                (click)="changeactiveCategory(7)" >Update</span>
                                            </div>
                                         
                                            <div class="all-users-infomation font-13 font-medium own-user-select">

                                                <div class="color-black container">
                                                    <div class="row" class="row" *ngIf="invoiceDetailsById">
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Term Type Id </p>

                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                       
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Due Date </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.dueDate | date:"yyyy-MM-dd HH:mm:ss"}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Amount</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.appliedPayments.invoiceAmount}}   
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Paid Amount </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.appliedPayments.appliedAmount}}   
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Outstanding Amount
                                                                       </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.appliedPayments.notAppliedAmount}}   
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                      
                                                     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="w3-card-4 classCardView w-100 mt-3 mb-3">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Items
                                                </p>
                                                <span class="edit-buttons hCreate"
                                                (click)="changeactiveCategory(4)" >Update</span>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="itemsList" [paginator]="true" [rows]="rows"
                                                                scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>

                                                                                Item No
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Invoice Item Type
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Override GI Account ID </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Override Org Party ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Inventory Item ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Product ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Product Feature ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Product Invoice ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Parent Invoice Item Seq ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                UOM
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Taxable Flag
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Quantity
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Unit Price
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Description
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Tax Authority Party
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Tax Auth Geo ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Tax Authority Rate Seq ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Sales Opportunity ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Order ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Order Item Seq ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Total
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>



                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span
                                                                                     class="account-button"  >
                                                                                    {{product.invoiceItemSeqId}}</span>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.invoiceItemType}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.overrideGlAccountId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.overrideOrgPartyId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.inventoryItemId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.productId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.productFeatureId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.parentInvoiceId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.parentInvoiceItemSeqId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.uomId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.taxableFlag}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.quantity}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.amount}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.description}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.taxAuthPartyId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.taxAuthGeoId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.taxAuthorityRateSeqId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.salesOpportunityId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                          
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                          
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.total}}
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No
                                                                                    Record Found
                                                                                </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div class="w3-card-4 classCardView">
                                            <div class="header-tabs">

                                                <h4 class=" common-styling hCard">
                                                    Time Entries
                                                </h4>
                                                <span class="edit-buttons hCreate"
                                                (click)="changeactiveCategory(5)" >Update</span>
                                            </div>

                                            <div class="panel-body allUserCardDetail">
                                                <div class="card own-account-table borderTable">
                                                    <p-table [value]="invoiceTimeEntries" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                    <div class="checkbox-align ml-3" style="color: white;">
                                                                        Time Entry ID
                                                                        <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="name" style="width: 1rem;">
                                                                    <div style="color: white;"> Party ID
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                    <div style="color: white;">Fromdate
                                                                        <p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                    <div style="color: white;"> Through Date
                                                                        <p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                    <div style="color: white;"> Rate Type Id
                                                                        <p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>

                                                               
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                    <div style="color: white;"> Timesheet Id
                                                                        <p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                    <div style="color: white;"> Item No
                                                                        <p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                    <div style="color: white;"> Hours
                                                                        <p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                    <div style="color: white;"> Comments
                                                                        <p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                    <div style="color: white;">Plan Hours
                                                                        <p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>

                                                                </th>
                      
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <ng-container >
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        <div class="checkbox-align ml-3">

                                                                            {{product.timeEntryId}}
                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.partyId}}</td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.fromDate|date:'yyyy-MM-dd HH:mm:ss'}}</td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.thruDate|date:'yyyy-MM-dd HH:mm:ss'}}</td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.rateTypeId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.workEffortId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.timesheetId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.invoiceItemSeqId }}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.hours}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.comments}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.planHours}}
                                                                    </td>
                                                                    
                                                                </tr>
                                                            </ng-container>

                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                            <tr>
                                                                <td>
                                                                    <span class="text-center">
                                                                           <h4 class="ml-2">No Record Found</h4>
                                                                       </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                    <!-- <p class="paginate_data">
                                                                                                                                                                   View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="w3-card-4 classCardView w-100 mt-3 mb-3">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Transactions
                                                </p>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="transactionList" [paginator]="true" [rows]="rows"
                                                                scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>

                                                                                Acctg Trans ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Acctg Trans Entry Seq ID
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Is Posted </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Fiscal Gl Type ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Acctg Trans Type ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Transaction Date
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Posted Date
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Gl Journal ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Trans Type Description
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Invoice ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Fixed Asset ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Description
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Gl Account ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Product ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Debit Credit Flag
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Amount
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Orig Amount
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Organization Party ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Gl Account Type
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Account Code
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Account Name
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                GL Account Class
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Party
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Reconcile Status ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Acctg Trans Entry Type ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span
                                                                                     class="account-button"  >
                                                                                    {{product.acctgTransId}}</span>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.acctgTransEntrySeqId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.isPosted}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.glFiscalTypeId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.transTypeDescription}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.transactionDate | date:"yyyy-MM-dd HH:mm:ss"}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.postedDate| date:"yyyy-MM-dd HH:mm:ss"}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.glJournalId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.transTypeDescription}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.invoiceId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.fixedAssetId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.description}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.glAccountId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.productId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.debitCreditFlag}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.amount}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.origAmount}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.organizationPartyId}}
                                                                        </td>     
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.glAccountTypeDescription}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.accountCode}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.accountName}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.glAccountClassDescription}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.partyName}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.reconcileStatusDescription}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.acctgTransEntryTypeId}}
                                                                        </td>
                                                                      

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No
                                                                                    Record Found
                                                                                </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div *ngIf="activeCategories==3">

                                        <div class="w3-card-4 classCard ">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                style="justify-content: space-between;">
                                                <p class="h-text">Summary</p>
                                                <span class="edit-buttons" style="cursor: pointer;"
                                                    data-toggle="modal" data-target="#updateHeaderOverviewPopup">Update</span>

                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="color-black container">
                                                    <div class="row" class="row" *ngIf="invoiceDetailsById">
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Invoice Date </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.invoiceDate | date : "yyyy-MM- dd HH:mm:ss"}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Due Date </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.dueDate | date : "yyyy-MM- dd HH:mm:ss"}}     </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Invoice Type</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.invoiceType}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Status </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.statusId}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Description</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.description}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Invoice Message </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.invoiceMessage}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">From Party ID </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.partyIdFrom}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">To Party ID </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.partyId}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Role Type ID </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.roleTypeId}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Billing Account ID</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.billingAccountId}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                      
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Total </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.total}} </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Date Paid </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.paidDate | date : "yyyy-MM- dd HH:mm:ss"}} </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Reference Num</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{invoiceDetailsById.referenceNumber}}   </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>


                                    <div *ngIf="activeCategories==4">

                                        <div class="w3-card-4 classCard w-100 mt-3">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Items </p>
                                                <span class="edit-buttons hCreate"  data-target="#itemsPopup"
                                             (click)="resetItem()"
                                                 data-toggle="modal">Create</span>

                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="invoiceItems" [paginator]="true" [rows]="rows"
                                                                scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>

                                                                                Item No				
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Quantity
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Invoice Item Type </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Product ID	</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Description
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Override Gl Account ID	                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Unit Price	
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Total
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="category">
                                                                        <div style="color: white;">
                                                                            Action
                                                                        </div>
                                                                        <input
                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span
                                                                                     class="account-button"  >
                                                                                    {{product.invoiceItemSeqId}}</span>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.quantity}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.invoiceItemType}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.productId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.description}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.overrideGlAccountId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.amount}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.total}}
                                                                        </td>
                                                                      
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn " 
                                                                            data-target="#itemsPopup"
                                                                            data-toggle="modal" (click)="updateItem(product)">Update</button>

                                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn " 
                                                                            (click)="deleteInvoiceItem(product)">Delete</button>
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No
                                                                                    Record Found
                                                                                </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div *ngIf="activeCategories==5">
                                        <div class="w3-card-4 classCardView">
                                            <div class="header-tabs">

                                                <h4 class=" common-styling hCard">
                                                    Time Entries
                                                </h4>
                                                
                                            </div>

                                            <div class="panel-body allUserCardDetail">
                                                <div class="card own-account-table borderTable">
                                                    <p-table [value]="invoiceTimeEntries" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                    <div class="checkbox-align ml-3" style="color: white;">
                                                                        Time Entry ID
                                                                        <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="name" style="width: 1rem;">
                                                                    <div style="color: white;"> Party ID
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                    <div style="color: white;">Fromdate
                                                                        <p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                    <div style="color: white;"> Through Date
                                                                        <p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                    <div style="color: white;"> Rate Type Id
                                                                        <p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>

                                                               
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                    <div style="color: white;"> Timesheet Id
                                                                        <p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                    <div style="color: white;"> Item No
                                                                        <p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                    <div style="color: white;"> Hours
                                                                        <p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                    <div style="color: white;"> Comments
                                                                        <p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                    <div style="color: white;">Plan Hours
                                                                        <p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                    <div style="color: white;"> Action
                                                                        <p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>

                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <ng-container >
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        <div class="checkbox-align ml-3">

                                                                            {{product.timeEntryId}}
                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.partyId}}</td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.fromDate|date:'yyyy-MM-dd HH:mm:ss'}}</td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.thruDate|date:'yyyy-MM-dd HH:mm:ss'}}</td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.rateTypeId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.workEffortId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.timesheetId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.invoiceItemSeqId }}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.hours}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.comments}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.planHours}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                     
                                                                        <button _ngcontent-cta-c128="" type="submit" 
                                                                        class="btn btn-secondary submit-btn " 
                                                                        (click)="unlinkInvoiceFromTimeEntry(product)">
                                                                        Delete</button>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>

                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                            <tr>
                                                                <td>
                                                                    <span class="text-center">
                                                                           <h4 class="ml-2">No Record Found</h4>
                                                                       </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                    <!-- <p class="paginate_data">
                                                                                                                                                                   View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>

                                        </div>


                                    </div>

                                    <div *ngIf="activeCategories==6">
                                        <div class="w3-card-4 classCardView">
                                            <div class="header-tabs">

                                                <h4 class=" common-styling hCard">
                                                    Roles
                                                </h4>
                                                <span class="edit-buttons hCreate" data-target="#rolesPopup" data-toggle="modal">Create New Roles </span>
                                            </div>

                                            <div class="panel-body allUserCardDetail">
                                                <div class="card own-account-table borderTable">
                                                    <p-table [value]="invoiceRoles" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                    <div class="checkbox-align ml-3" style="color: white;">
                                                                        Party ID
                                                                        <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="name" style="width: 1rem;">
                                                                    <div style="color: white;"> Name
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                    <div style="color: white;"> Role Type ID
                                                                        <p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                    <div style="color: white;"> Percentage
                                                                        <p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                    <div style="color: white;"> Date Time Performed
                                                                        <p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>

                                                               
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                    <div style="color: white;"> Action
                                                                        <p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>

                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <ng-container >
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        <div class="checkbox-align ml-3">

                                                                            {{product.partyId}}
                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.party}}</td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.roleTypeId}}</td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.percentage}}</td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.datetimePerformed |date:'yyyy-MM-dd HH:mm:ss'}}
                                                                    </td>
               
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                     
                                                                        <button _ngcontent-cta-c128="" type="submit" 
                                                                        class="btn btn-secondary submit-btn " 
                                                                        (click)="deleteInvoiceRole(product.partyId,product.roleTypeId)">
                                                                        Delete</button>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>

                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                            <tr>
                                                                <td>
                                                                    <span class="text-center">
                                                                           <h4 class="ml-2">No Record Found</h4>
                                                                       </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                    <!-- <p class="paginate_data">
                                                                                                                                                                   View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>

                                        </div>


                                    </div>

                                    <div *ngIf="activeCategories==7">

                                        <div class="w3-card-4 classCard w-100 mt-3">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Terms </p>
                                                <button _ngcontent-cta-c128="" type="submit"
                                                class="btn btn-secondary submit-btn ml-2 fontMenu" data-toggle="modal" 
                                              (click)="resetTerm()"   data-target="#termsPopup">Create
                                            </button>

                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="invoiceTerms" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color:white">
                                                                                Invoice Term ID
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="name" style="width: 1rem;">
                                                                            <div style="color: white;"> Term Type ID
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Item No
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Item Value
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Term Days
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Text Value
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Description
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Uom
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Created Date
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Action
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <ng-container >
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <div class="checkbox-align">
                                                                                    {{product.invoiceTermId}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.termTypeId}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.itemNo}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.termValue}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.termDays}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.textValue}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.description}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.uom}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.createdStamp | date:'yyyy-MM-d hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn " 
                                                                                data-toggle="modal"  data-target="#termsPopup" (click)="updateTerm(product)">Update</button>
                                                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn " 
                                                                                (click)="deleteInvoiceTerm(product.invoiceTermId)">Delete</button>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>

                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                       <h4 class="ml-2">No Record Found</h4>
                                                                                   </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div *ngIf="activeCategories==8">
                                      

                                        <div class="w3-card-4 classCard w-100 mt-3">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Payments Applied
                                                     </p>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="appliedApplications" [paginator]="true" [rows]="rows"
                                                                scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>

                                                                                Item No	                                                                       </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                              Product Id
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Description	 </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                 


                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Total
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="category">
                                                                        <div style="color: white;">
                                                                            Payment ID	
                                                                        </div>
                                                                        <input
                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="category">
                                                                    <div style="color: white;">
                                                                        Outstanding amount	
                                                                    </div>
                                                                    <input
                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="category">
                                                                <div style="color: white;">
                                                                    Action
                                                                </div>
                                                                <input
                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span
                                                                                     class="account-button"  >
                                                                                    </span>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                       
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                       
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.total}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.paymentId}}
                                                                            <!-- <div>
                                                                                <p-dropdown filter="true" 
                                                                                [options]="PaymentIDArray"
                                                                                [(ngModel)]="product.paymentId"
                                                                                 optionlabel="label"
                                                                                  placeholder="Select Payment ID">
                                                                                </p-dropdown>
                                                                               
                                                                            </div> -->
                                                                         
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.amountApplied}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <button _ngcontent-cta-c128="" type="submit" 
                                                                            class="btn btn-secondary submit-btn "   data-toggle="modal" 
                                                                             data-target="#ApplicationPopup" 
                                                                            (click)="updateInvoice(product)">
                                                                            Update</button>
                                                                            <button _ngcontent-cta-c128="" type="submit" 
                                                                            class="btn btn-secondary submit-btn " 
                                                                            (click)="deletePaymentToInvoice(product)">
                                                                            Remove</button>
                                                                        </td>
                                                                       

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No
                                                                                    Record Found
                                                                                </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="w3-card-4 classCard w-100 mt-3">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Possible payments to apply [Company] sent from [AcctBuyer]
                                                </p>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="PaymentApplicationList" [paginator]="true" [rows]="rows"
                                                                scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>

                                                                                To payment ID	                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Billing Account ID	
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Tax Auth Geo ID	 </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                    


                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="category">
                                                                        <div style="color: white;">
                                                                            Amount Applied
                                                                        </div>
                                                                        <input
                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Action
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span
                                                                                     class="account-button"  >
                                                                                    {{product.toPaymentId}}</span>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.billingAccountId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.taxAuthGeoId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.amountApplied}}
                                                                        </td>
                                                                      
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <button _ngcontent-cta-c128="" type="submit"
                                                                             class="btn btn-secondary submit-btn " 
                                                                             (click)="removePaymentApplication(product)">Remove</button>
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No
                                                                                    Record Found
                                                                                </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->

                                        <div class="w3-card-4 classCardView mt-2">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling" style="margin-left: 7px;">
                                                    Assign Payment to This Invoice
                                                </h4>


                                            </div>
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="form-group">
                                                        <div class="row">
                                                            <form class="w-100" [formGroup]="applicationsForm">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group rightForm">
                                                                                <label for="exampleInputEmail1">
                                                                                    Payment ID

                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <p-dropdown optionlabel="label"
                                                                                    placeholder="Select Payment ID"
                                                                                    filter="true" [options]="PaymentIDArray"
                                                                                    formControlName="PaymentID">

                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group rightForm">
                                                                                <label for="exampleInputEmail1">

                                                                                    Outstanding amount </label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <input type="email" class="form-control"
                                                                                    id="exampleInputEmail1"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Outstanding amount"
                                                                                    formControlName="outstandingAmount">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </form>
                                                            <div class="main-submit-button" style="margin-left: -2%;">
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn" 
                                                                    (click)="applyPaymentToInvoice()">Apply</button>


                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="w3-card-4 classCard w-100 mt-3">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Poosilbe Payments to Apply </p>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="" [paginator]="true" [rows]="rows"
                                                                scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>

                                                                                To Payment ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Effective Date
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </div>
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Amount</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Amount Applied
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Outstanding Amount
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>



                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Action
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span
                                                                                     class="account-button"  >
                                                                                    {{product.data.custRequestId}}</span>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.data.custRequestTypeId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.data.productStoreId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.data.custRequestName}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.data.priority}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.data.priority}}
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No
                                                                                    Record Found
                                                                                </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->

                                    </div>

                                    <div *ngIf="activeCategories==9">

                                        <div class="w3-card-4 classCardView">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling" style="margin-left: 7px;">
                                                    Send per Email

                                                </h4>


                                            </div>
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="form-group">
                                                        <div class="row">
                                                            <form class="w-100" [formGroup]="sendPerEmailForm">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group rightForm">
                                                                                <label for="exampleInputEmail1">
                                                                                    From Email Address

                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control"
                                                                                    id="exampleInputEmail1"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter From Email Address"
                                                                                    formControlName="fromEmailAddress">

                                                                            </div>
                                                                            <div class="col-lg-3 form-group rightFormA">
                                                                                <label for="exampleInputEmail1">

                                                                                    To Email Address</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control"
                                                                                    id="exampleInputEmail1"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter To Email Address"
                                                                                    formControlName="toEmailAddress">

                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group rightForm">
                                                                                <label for="exampleInputEmail1">

                                                                                    Copy Email Address </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control"
                                                                                    id="exampleInputEmail1"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Copy Email Address"
                                                                                    formControlName="copyEmailAddress">


                                                                            </div>

                                                                            <div class="col-lg-3 form-group rightFormA">
                                                                                <label for="exampleInputEmail1">

                                                                                    Subject </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control"
                                                                                    id="exampleInputEmail1"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Subject"
                                                                                    formControlName="subject">


                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group rightForm">
                                                                                <label for="exampleInputEmail1">

                                                                                    Other Currency </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-checkbox _ngcontent-egj-c329=""  formControlName="otherCurrency"
                                                                                 styleclass="custom-checkbox" binary="true"
                                                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                   
                                                                                </p-checkbox>

                                                                            </div>

                                                                            <div class="col-lg-3 form-group rightFormA">
                                                                                <label for="exampleInputEmail1">

                                                                                    Email Body </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" 
                                                                                    class="form-control"
                                                                                    id="exampleInputEmail1"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Email Body"
                                                                                    formControlName="emailBody">

                                                                            </div>


                                                                        </div>
                                                                    </div>


                                                                </div>


                                                            </form>
                                                            <div class="main-submit-button" style="margin-left: -26%;">
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn" (click)="sendInvoicePerMail()">Submit</button>


                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div *ngIf="activeCategories==10">



                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="profileCreateBillingAcc" tabindex="-1" role="dialog" aria-labelledby="createBillingAcc" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Profile </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closeButton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Edit Billing Account</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createBillingAccount">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Account Limit</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Account Limit" id="exampleInputEmail1" formControlName="accountLimit">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Account Currency Uom ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Account Currency Uom ID"
                                                         filter="true" id="exampleInputEmail1" 
                                                        [options]="currencyUomIds" formControlName="accountCurrencyUomId">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Contact Mech ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Contact Mech ID" id="exampleInputEmail1" formControlName="contactMechId">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" placeholder="From Date" id="exampleInputEmail1" formControlName="fromDate">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Through Date </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" placeholder="Through Date" formControlName="thruDate">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Description" id="exampleInputEmail1" formControlName="description">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">External Account ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="External Account ID" id="exampleInputEmail1" formControlName="externalAccountId">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Party ID <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Party ID" filter="true"
                                                       [options]="partyIdArray"    id="exampleInputEmail1" formControlName="partyId">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Available Balance</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="External Account ID" id="exampleInputEmail1" formControlName="AvailableBalance">
                                                    </div>



                                                </div>
                                            </div>


                                        </div>
                                        <div class="col-lg-12 main-submit-button5">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="updateBillingAccount()">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="updateHeaderOverviewPopup" tabindex="-1" role="dialog"
    aria-labelledby="updateHeaderOverviewPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Header
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton9>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Update Header
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateHeadeOverViewForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Invoice Type</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="invoiceType"
                                                        [options]="salesInvoiceTypeArray"     
                                                           optionlabel="label" placeholder="Select Invoice Type">
                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Status</label>
                                                    </div>

                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" formControlName="status"
                                                        [options]="invoiceTypeAndStatusArray"   
                                                         optionlabel="label" placeholder="Select Status">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Description </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="description"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Description">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Invoice Message</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="invoiceMessage"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Invoice Message">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            From Party ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="fromPartyID"
                                                        [options]="partyIdArray" optionlabel="label" 
                                                        placeholder="Select From Party ID">
                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">To Party ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="toPartyID"
                                                          [options]="partyIdArray"  (onChange)="onChange($event.value)"
                                                            optionlabel="label" placeholder="Select To Party ID">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Role Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="roleTypeID"
                                                        [options]="PartyRolesArray"   optionlabel="label" placeholder="Select Role Type ID">
                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Billing Account ID </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="billingAccountID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Billing Account ID">


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Invoice Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="invoiceDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Due Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="dueDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter">


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Reference Number	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="ReferenceNumber" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Reference Number	">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Recurrence Info ID	</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="RecurrenceInfoID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Recurrence Info ID	">


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Reference Num</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="referenceNum"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Reference Num">

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                            Currency   </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="currencyUomId"
                                                         [options]="uomIds"  optionlabel="label" placeholder="Select Currency">
                                                        </p-dropdown>

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 31%;">
                                                <button type="submit"
                                                    class="btn btn-secondary submit-btn"
                                                     (click)="updateSalesInvoiceHeader()">Update</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="financialAccountTransPopup" tabindex="-1" role="dialog"
    aria-labelledby="financialAccountTransPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Financial Account Transaction
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Update Financial Account Transaction
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateFinancialAccountTransForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Fin Account Trans ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="finAccountTransID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Fin Account Trans ID">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Fin Account Trans Type</label>
                                                    </div>

                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" formControlName="finAccountTransType"
                                                            optionlabel="label"
                                                            placeholder="Select Fin Account Trans Type">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Amount </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="amount"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Amount">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">GI Reconciliation ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="GIReconciliationID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter GI Reconciliation ID">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Transaction Date </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="transactionDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter ">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Status</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="status"
                                                            optionlabel="label" placeholder="Select Status">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit"
                                                    class="btn btn-secondary submit-btn">Update</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="updateBillingAccountPopup" tabindex="-1" role="dialog"
    aria-labelledby="updateBillingAccountPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Billing Account
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Update Billing Account
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateBillingAccountForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Billing Account ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="billingAccountID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Billing Account ID">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Account Limit</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="accountLimit"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Account Limit">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Account Currency Uom ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="accountCurrencyUomID"
                                                            optionlabel="label"
                                                            placeholder="Select Account Currency Uom ID">
                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Contact Mech ID </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="contactMechID"
                                                            optionlabel="label" placeholder="Select Contact Mech ID">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Through Date">


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Description </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="description"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Description">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">External Account ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="externalAccountID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter External Account ID">


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Party ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="partyID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Party ID">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Available Balance</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="availableBalance"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Available Balance">


                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Save</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="itemsPopup" tabindex="-1" role="dialog" aria-labelledby="itemsPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Items
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton8>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Items
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateItemsForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Invoice Item Type</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="invoiceItemType"
                                                          [options]="invoiceItemTypeArray"  optionlabel="label" placeholder="Select Invoice Item Type">
                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Description </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="description"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Description">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Override Gl Account ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="overrideGlAccountID"
                                                            optionlabel="label"
                                                            [options]="GIAccounts"   placeholder="Select Override Gl Account ID">
                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Inventory Item ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="inventoryItemID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Inventory Item ID">


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Product ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="productID"
                                                        [options]="ProductIdArray"     optionlabel="label" placeholder="Select Product ID">
                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Product Feature ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="productFeatureID"
                                                        [options]="getProductFeatureIdArray"    optionlabel="label" placeholder="Select Product Feature ID">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Quantity</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="quantity"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Quantity">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">UOM</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="UOMs"
                                                        [options]="uomIds"     optionlabel="label" placeholder="Select UOM">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Unit Price </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="unitPrice"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Unit Price">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Taxable Flag</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="taxableFlag"
                                                        [options]="yesNoArray"  optionlabel="label" placeholder="Select Taxable Flag">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-5 main-submit-button" style="margin-left: 32%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" 
                                                (click)="createInvoiceItem()" 
                                                *ngIf="!showItem">Add</button>
                                                <button type="submit" class="btn btn-secondary submit-btn" 
                                                (click)="updateInvoiceItem()" *ngIf="showItem">Update</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="rolesPopup" tabindex="-1" role="dialog" aria-labelledby="rolesPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Roles
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Roles
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="createRoleForm">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group classInput" style="
                                                    text-align: right!important;
                                                    padding-right: 17px!important;
                                                    margin-left: -8%;
                                                    font-size: 10px;
                                                ">
                                                        <label for="partyId">Party ID</label>
                                                    </div>
                                                 
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                        (onChange)="onChange($event.value)"
                                                         [options]="partyIdArray"optionlabel="label"
                                                        placeholder="select PartyId"
                                                        formControlName="partyId">
                                                    </p-dropdown>
        
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput" style="
                                                    text-align: right!important;
                                                    padding-right: 17px!important;
                                                   
                                                    font-size: 10px;
                                                ">
                                                        <label for="partyId">Role Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                            [options]="PartyRolesArray"
                                                            optionlabel="label"
                                                            placeholder="select Role Type Id"
                                                            formControlName="roleTypeId">
                                                        </p-dropdown>
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group classInput" style="
                                                    text-align: right!important;
                                                    padding-right: 17px!important;
                                                    margin-left: -8%;
                                                    font-size: 10px;
                                                ">
                                                        <label for="date">Date Time
                                                            Performed</label>
                                                    </div>
                                                 
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control"
                                                        placeholder="Enter Date Time Performed" formControlName="datetimePerformed">
                                                      
        
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput" style="
                                                    text-align: right!important;
                                                    padding-right: 17px!important;
                                                  
                                                    font-size: 10px;
                                                ">
                                                        <label for="percentage"> Percentage </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control"
                                                        placeholder="Enter Percentage"   formControlName="percentage">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>

                                        </div>
                                        <div class="main-submit-button" style="
                                        margin-right: 26%;
                                    ">
                                            <button type="submit"
                                                class="btn btn-secondary submit-btn"
                                                (click)="submitInvoiceRole()">Submit</button>
                                         
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="termsPopup" tabindex="-1" role="dialog" aria-labelledby="termsPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Terms
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Terms
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addTermsForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Invoice Term ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="invoiceTermID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Invoice Term ID">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Term Type ID</label>
                                                    </div>

                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" formControlName="termTypeID"
                                                          [options]="itemTypeIds"  optionlabel="label" placeholder="Select Term Type ID">
                                                        </p-dropdown>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                            <label for="exampleInputEmail1">
                                                                Item No</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" formControlName="itemNo"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Item No">


                                                        </div>

                                                        <div class="col-lg-3 form-group rightFormA">
                                                            <label for="exampleInputEmail1">Term Value</label>
                                                        </div>

                                                        <div class="col-lg-2">
                                                            <input type="email" formControlName="termValue"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Term Value">


                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                            <label for="exampleInputEmail1">
                                                                Term Days</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" formControlName="termDays"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Term Days">


                                                        </div>

                                                        <div class="col-lg-3 form-group rightFormA">
                                                            <label for="exampleInputEmail1">Text Value </label>
                                                        </div>

                                                        <div class="col-lg-2">
                                                            <input type="email" formControlName="textValue"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Text Value">


                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                            <label for="exampleInputEmail1">
                                                                Description </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" formControlName="description"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Description">


                                                        </div>

                                                        <div class="col-lg-3 form-group rightFormA">
                                                            <label for="exampleInputEmail1">Uom</label>
                                                        </div>

                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" formControlName="Uom"
                                                            [options]="uomIds"    optionlabel="label" placeholder="Select Uom">
                                                            </p-dropdown>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                    <button type="submit" *ngIf="!showTerm" (click)="createInvoiceTerm()"
                                                        class="btn btn-secondary submit-btn">Submit</button>
                                                        <button type="submit" *ngIf="showTerm" (click)="updateInvoiceTerm()"
                                                        class="btn btn-secondary submit-btn">Update</button>

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="salesInvoicePopup" tabindex="-1" role="dialog" aria-labelledby="salesInvoicePopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">New Sales Invoice
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>New Sales Invoice
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="newSalesInvoiceForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Invoice Type </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="invoiceType"
                                                            optionlabel="label" placeholder="Select Invoice Type">
                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Organization Party ID</label>
                                                    </div>

                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" formControlName="organizationPartyID"
                                                            optionlabel="label"
                                                            placeholder="Select Organization Party ID">
                                                        </p-dropdown>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                            <label for="exampleInputEmail1">
                                                                To Party ID</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" formControlName="toPartyID"
                                                                optionlabel="label" placeholder="Select To Party ID">
                                                            </p-dropdown>

                                                        </div>


                                                    </div>
                                                </div>

                                                <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                    <button type="submit"
                                                        class="btn btn-secondary submit-btn">Submit</button>

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="ApplicationPopup" tabindex="-1" role="dialog" aria-labelledby="ApplicationPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Application
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Update Application
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="applicationForm">
                                        <div
                                            class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Payment ID

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown optionlabel="label"
                                                            placeholder="Select Payment ID"
                                                            filter="true" [options]="PaymentIDArray"
                                                            formControlName="PaymentID">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">

                                                            Outstanding amount </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control"
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Outstanding amount"
                                                            formControlName="outstandingAmount">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                            <button type="submit"
                                                class="btn btn-secondary submit-btn"
                                                 (click)="updatePaymentToInvoice()">Submit</button>

                                        </div>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>