<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-black pl-1 titlePanels"> Invoice
                </span>

            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="port-header-contact create-lead font-medium mb-0">
                        New Sales Invoice
                    </div>
                    <div class=" mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" [formGroup]="newSalesInvoiveForm">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Invoice Type</label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <p-dropdown filter="true" name="party"
                                                        [options]="salesInvoiceTypeArray" optionlabel="label"
                                                        placeholder="Select Invoice Type" formControlName="InvoiceType">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Organization Party ID
                                                    </label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <p-dropdown filter="true" [options]="OrganizationPartyIdArray"
                                                        formControlName="partyIdFrom" optionlabel="label"
                                                        placeholder="Select Party ID">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">To Party ID <span
                                                            class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <p-dropdown filter="true" name="party" [options]="allPartyIdList"
                                                        optionlabel="label" placeholder="Select PartyID"
                                                        formControlName="partyId">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -23%;">
                                        <button _ngcontent-cta-c128="" type="submit"
                                            class="btn btn-secondary submit-btn "
                                            (click)="createSalesInvoice()">Create</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" bg-white color-grey mt-2">
                <div class="w3-card-4 classCard">
                    <div class="port-header-contact create-lead font-medium mb-0">
                        New Purchase Invoice
                    </div>
                    <div class=" mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" [formGroup]="newSalesInvoiveForm">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Invoice Type</label>
                                                </div>

                                                <div class="col-lg-3">
                                                    <p-dropdown filter="true" name="party" [options]="invoiceTypeArray"
                                                        optionlabel="label" placeholder="Select Invoice Type"
                                                        formControlName="InvoiceType">
                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Organization Party ID
                                                    </label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <p-dropdown filter="true" [options]="OrganizationPartyIdArray"
                                                        formControlName="partyIdFrom" optionlabel="label"
                                                        placeholder="Select Party ID">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">From Party ID <span
                                                            class="text-danger">*</span></label>
                                                </div>

                                                <div class="col-lg-3">
                                                    <p-dropdown filter="true" name="party" [options]="allPartyIdList"
                                                        optionlabel="label" placeholder="Select PartyID"
                                                        formControlName="partyId">
                                                    </p-dropdown>

                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -23%;">
                                        <button _ngcontent-cta-c128="" type="submit"
                                            class="btn btn-secondary submit-btn "
                                            (click)="createSalesInvoice()">Create</button>


                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>