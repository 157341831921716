import { Component, OnInit, ViewChild } from '@angular/core';

import { SortEvent } from 'primeng/api';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-list-vendors',
  templateUrl: './list-vendors.component.html',
  styleUrls: ['./list-vendors.component.css']
})
export class ListVendorsComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;
  products3: any;
  isShown = false;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  rows = 50;
  search = "";
  show: boolean;
  listVendorsIntial: any;
  listVendorsIntialArray: any[] = [];
  createVendor: FormGroup;
  partyId: any;
  activeCategory = 2;
  dataParty: any;
  firstName: any;
  lastName: any;
  resdataVendor: any;
  addressVendor: string;
  emailAddress: any;
  phoneNumber: any;
  faxNumber: any;
  advanceSearch: {

    manifestCompanyName: string,
    manifestCompanyNameSearchType: string,
    manifestCompanyTitle: string,
    manifestCompanyTitleSearchType: string,
    manifestLogoUrl: string,
    manifestLogoUrlSearchType: string,
    manifestPolicies: "",
    manifestPoliciesSearchType: string,
    partyId: string,
    partyIdSearchType: string


  };
  personId: any;
  partyIdArray: any[] = []
  readonly employessSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  opportunityIdArray: { label: string; value: string; }[];
  listVendors: any;
  agreementType: string;
  constructor(readonly spinner: NgxSpinnerService, readonly datePipe: DatePipe, readonly accountsService: AccountsService,
    readonly router: Router,
    readonly addSkillService: AddSkillService,
    readonly toastr: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    private _location: Location,
    ) {
    this.advanceSearch = {
      manifestCompanyName: "",
      manifestCompanyNameSearchType: "Contains",
      manifestCompanyTitle: "",
      manifestCompanyTitleSearchType: "Contains",
      manifestLogoUrl: "",
      manifestLogoUrlSearchType: "Contains",
      manifestPolicies: "",
      manifestPoliciesSearchType: "Contains",
      partyId: "",
      partyIdSearchType: "Contains"
    };
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      }, {
        label: 'Equals',
        value: 'Equals'
      }, {
        label: 'Is Empty',
        value: 'Is Empty'
      }, {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];

    this.createVendor = this._FormBuilder.group({
      accountNo:  [''],
      billingRateLevel:  [''],
      company:  [''],
      companyWebsite:  [''],
      contactMechPurposeTypeId:  [''],
      creditLimit:  [''],
      eligibleFor:  [''],
      federalTaxId:  [''],
      generalAddress1:  [''],
      generalAddress2:  [''],
      generalAttnName:  [''],
      generalCity:  [''],
      generalCountryGeoId:  [''],
      generalPostalCode:  [''],
      generalStateProvinceGeoId:  [''],
      generalToName:  [''],
      groupName:  [''],
      individual:  [''],
      info :  [''],
      isActive:  [''],
      isIncorporated:  [''],
      manifestCompanyName:  [''],
      manifestCompanyTitle:  [''],
      manifestLogoUrl:  [''],
      manifestPolicies:  [''],
      map:  [''],
      name:  [''],
      partyId:  [''],
      phone:  [''],
      primaryEmail:  [''],
      primaryFaxAreaCode:  [''],
      primaryFaxCountryCode:  [''],
      primaryFaxExtension:  [''],
      primaryFaxNumber:  [''],
      primaryPhoneAreaCode:  [''],
      primaryPhoneCountryCode:  [''],
      primaryPhoneExtension:  [''],
      primaryPhoneNumber:  [''],
      primaryWebUrl:  [''],
      printCheckAs:  [''],
      requires1099:  [''],
      taxId:  [''],
      terms:  [''],
      type:  [''],
      typeVendor:  [''],
      vendorEmail:  [''],
      vendorFax:  [''],
      vendorForms:  [''],
      vendorName:  [''],
      vendorPhone:  [''],
      webUrlId:  [''],
      PrimaryWebUrl:['']

    });
  }
  financialList = [{
    value: '1'
  }]
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
    this.getVendors();

    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];


    });
    if (this.partyId) {
      this.show = true;
      this.editVendorsData();

    }
    else {
      this.show = false;
    }
    this.getVendors();
    this.getPartyId();
  }

  backWindow(){
      this._location.back();
    }
    toDashboard()
  {
    this.router.navigate(['/vendor/Dashboard']);
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }

  invoiceType() {
    this.isShown = !this.isShown;
  }
  getVendors() {
    this.spinner.show();
    const req = {
      "manifestCompanyName": "",
      "manifestCompanyNameSearchType": "Contains",
      "manifestCompanyTitle": "",
      "manifestCompanyTitleSearchType": "Contains",
      "manifestLogoUrl": "",
      "manifestLogoUrlSearchType": "Contains",
      "manifestPolicies": "",
      "manifestPoliciesSearchType": "Contains",
      "partyId": "",
      "partyIdSearchType": "Contains"
    }
    this.accountsService.getVendors(this.employessSize, req).subscribe((res: any) => {
      this.listVendors = res.data;
      this.listVendorsIntial = res.data.partyPersons;
      this.spinner.hide();

      for (const value of this.listVendorsIntial) {
        this.listVendorsIntialArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }


    

    })

  }
  reset() {
    this.spinner.show();
    this.advanceSearch.manifestCompanyName = "";
    this.advanceSearch.manifestCompanyTitle = "";
    this.advanceSearch.manifestLogoUrl = "";
    this.advanceSearch.manifestPolicies = "";
    this.advanceSearch.partyId = "";
    const req = {
      "manifestCompanyName": "",
      "manifestCompanyNameSearchType": "Contains",
      "manifestCompanyTitle": "",
      "manifestCompanyTitleSearchType": "Contains",
      "manifestLogoUrl": "",
      "manifestLogoUrlSearchType": "Contains",
      "manifestPolicies": "",
      "manifestPoliciesSearchType": "Contains",
      "partyId": "",
      "partyIdSearchType": "Contains"
    }
    this.accountsService.getVendors(this.employessSize, req).subscribe((res: any) => {
      this.listVendors = res.data;

      this.spinner.hide();

    })

  }
  advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    this.financialList = [];
    const req = {
      "manifestCompanyName": this.advanceSearch.manifestCompanyName ? this.advanceSearch.manifestCompanyName : '',
      "manifestCompanyNameSearchType": this.advanceSearch.manifestCompanyNameSearchType ? this.advanceSearch.manifestCompanyNameSearchType : '',
      "manifestCompanyTitle": this.advanceSearch.manifestCompanyTitle ? this.advanceSearch.manifestCompanyTitle : '',
      "manifestCompanyTitleSearchType": this.advanceSearch.manifestCompanyTitleSearchType ? this.advanceSearch.manifestCompanyTitleSearchType : '',
      "manifestLogoUrl": this.advanceSearch.manifestLogoUrl ? this.advanceSearch.manifestLogoUrl : '',
      "manifestLogoUrlSearchType": this.advanceSearch.manifestLogoUrlSearchType ? this.advanceSearch.manifestLogoUrlSearchType : '',
      "manifestPolicies": this.advanceSearch.manifestPolicies ? this.advanceSearch.manifestPolicies : '',
      "manifestPoliciesSearchType": this.advanceSearch.manifestPoliciesSearchType ? this.advanceSearch.manifestPoliciesSearchType : '',
      "partyId": this.advanceSearch.partyId ? this.advanceSearch.partyId : '',
      "partyIdSearchType": this.advanceSearch.partyIdSearchType ? this.advanceSearch.partyIdSearchType : '',
    }
    this.accountsService.getVendors(this.employessSize, req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.listVendors = resp.data;
        }
      });
  }
  newVendor() {
    this.router.navigate(["/financial/payable/list-vendor/new-vendor-create"])

  }
  update(id) {
    this.router.navigate(["/financial/payable/list-vendor/new-vendor-create"], { queryParams: { partyId: id } })

  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  detailPage(updateId) {
    this.router.navigate(["/vendor/detail-vendor-summary"], { queryParams: { id: updateId } })
  }
 changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "manifestCompanyName": this.createVendor.value.manifestCompanyName,
      "manifestCompanyTitle": this.createVendor.value.manifestCompanyTitle,
      "manifestLogoUrl": this.createVendor.value.manifestLogoUrl,
      "manifestPolicies": this.createVendor.value.manifestPolicies,
      "partyId": this.createVendor.value.partyId,
      "accountNo": this.createVendor.value.accountNo,
      "billingRateLevel": this.createVendor.value.billingRateLevel,
      "creditLimit": this.createVendor.value.creditLimit,
      "eligibleFor": this.createVendor.value.eligibleFor,
      "printCheckAs": this.createVendor.value.printCheckAs,
      "taxId": this.createVendor.value.taxId,
      "terms": this.createVendor.value.terms,
      "type": this.createVendor.value.type,
      "company": this.createVendor.value.company,
      "companyWebsite": this.createVendor.value.companyWebsite,
      "individual": this.createVendor.value.individual,
      "isActive": this.createVendor.value.isActive,
      "map": this.createVendor.value.map,
      "name": this.createVendor.value.name,
      "phone": this.createVendor.value.phone,
      "typeVendor": "distributor",
      "vendorEmail": this.createVendor.value.vendorEmail,
      "vendorFax": this.createVendor.value.vendorFax,
      "vendorForms": this.createVendor.value.vendorForms,
      "vendorName": this.createVendor.value.vendorName,
      "vendorPhone": this.createVendor.value.vendorPhone,
      "contactMechPurposeTypeId": this.createVendor.value.contactMechPurposeTypeId,
      "federalTaxId": this.createVendor.value.federalTaxId,
      "generalAddress1": this.createVendor.value.generalAddress1,
      "generalAddress2": this.createVendor.value.generalAddress2,
      "generalAttnName": this.createVendor.value.generalAttnName,
      "generalCity": this.createVendor.value.generalCity,
      "generalCountryGeoId": this.createVendor.value.generalCountryGeoId,
      "generalPostalCode": this.createVendor.value.generalPostalCode,
      "generalStateProvinceGeoId": this.createVendor.value.generalStateProvinceGeoId,
      "generalToName": this.createVendor.value.generalToName,
      "groupName": this.createVendor.value.groupName,
      "infoString": this.createVendor.value.infoString,
      "isIncorporated": this.createVendor.value.isIncorporated,
      "primaryEmail": this.createVendor.value.primaryEmail,
      "primaryFaxAreaCode": this.createVendor.value.primaryFaxAreaCode,
      "primaryFaxCountryCode": this.createVendor.value.primaryFaxCountryCode,
      "primaryFaxExtension": this.createVendor.value.primaryFaxExtension,
      "primaryFaxNumber": this.createVendor.value.primaryFaxNumber,
      "primaryPhoneAreaCode": this.createVendor.value.primaryPhoneAreaCode,
      "primaryPhoneCountryCode": this.createVendor.value.primaryPhoneCountryCode,
      "primaryPhoneExtension": this.createVendor.value.primaryPhoneExtension,
      "primaryPhoneNumber": this.createVendor.value.primaryPhoneNumber,
      "primaryWebUrl": this.createVendor.value.primaryWebUrl,
      "requires1099": this.createVendor.value.requires1099,
      "webUrlId":this.createVendor.value.webUrlId,

      
    }
    this.accountsService.postVendors(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Created Successfully");
        this.closebutton1.nativeElement.click();
        this.createVendor.reset();
        this.getVendors();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "manifestCompanyName": this.createVendor.value.manifestCompanyName,
      "manifestCompanyTitle": this.createVendor.value.manifestCompanyTitle,
      "manifestLogoUrl": this.createVendor.value.manifestLogoUrl,
      "manifestPolicies": this.createVendor.value.manifestPolicies,
      "partyId": this.createVendor.value.partyId,
      "accountNo": this.createVendor.value.accountNo,
      "billingRateLevel": this.createVendor.value.billingRateLevel,
      "creditLimit": this.createVendor.value.creditLimit,
      "eligibleFor": this.createVendor.value.eligibleFor,
      "printCheckAs": this.createVendor.value.printCheckAs,
      "taxId": this.createVendor.value.taxId,
      "terms": this.createVendor.value.terms,
      "type": this.createVendor.value.type,
    }
    this.accountsService.updateVendors(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.router.navigate(["/financial/payable/list-vendor"])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }

  editVendorsData() {
    this.spinner.show();
    this.accountsService.getVendorListById(this.partyId).subscribe((res: any) => {
      this.getVendorPartyIdListById(res.data.partyId);
      setTimeout(() => {
        const formValue = this.createVendor;
        formValue.patchValue({
          manifestCompanyName: res.data.manifestCompanyName,
          manifestCompanyTitle: res.data.manifestCompanyTitle,
          manifestLogoUrl: res.data.manifestLogoUrl,
          manifestPolicies: res.data.manifestPolicies,
          partyId: res.data.partyId,
          accountNo: res.data.additionInformation == null ? '' : res.data.additionInformation.accountNo,
          billingRateLevel: res.data.additionInformation == null ? '' : res.data.additionInformation.billingRateLevel,
          creditLimit: res.data.additionInformation == null ? '' : res.data.additionInformation.creditLimit,
          eligibleFor: res.data.additionInformation == null ? '' : res.data.additionInformation.eligibleFor,
          printCheckAs: res.data.additionInformation == null ? '' : res.data.additionInformation.printCheckAs,
          taxId: res.data.additionInformation == null ? '' : res.data.additionInformation.taxId,
          terms: res.data.additionInformation == null ? '' : res.data.additionInformation.terms,
          type: res.data.additionInformation == null ? '' : res.data.additionInformation.type,
        })

      }, 3000);
      this.spinner.hide();
    })
  }


  cancelSubmit() {
    this.router.navigate(["/financial/payable/list-vendor"])

  }
  getVendorPartyIdListById(id) {
    this.spinner.show();
    this.addressVendor = "";
    this.lastName = "";
    this.firstName = "";
    this.emailAddress = "";
    this.phoneNumber = "";
    this.faxNumber = "";
    this.accountsService.getVendorPartyIdListById(id).subscribe((res: any) => {
      this.resdataVendor = res.data.contactInformation;
      this.firstName = res.data.firstName;
      this.spinner.hide();
      this.lastName = res.data.lastName;
      for (const v of this.resdataVendor) {
        if (v.contactMech.contactMechTypeId === "POSTAL_ADDRESS") {
          this.addressVendor = v.postalAddress.address1 + ',' + v.postalAddress.stateProvinceGeoId + ',' + v.postalAddress.city + ',' + v.postalAddress.postalCode + ',' + v.postalAddress.countryDesc
        }
        else if (v.contactMech.contactMechTypeId === "EMAIL_ADDRESS") {
          this.emailAddress = v.contactMech.infoString
        }
        else if (v.partyContactMechPurposes.length) {
          if (v.partyContactMechPurposes[0].contactMechPurposeTypeId === "PRIMARY_PHONE") {
            this.phoneNumber = v.telecomNumber.contactNumber
          }
          else if (v.partyContactMechPurposes[0].contactMechPurposeTypeId === "FAX_NUMBER") {
            this.faxNumber = v.telecomNumber.contactNumber
          }
        }
      }
    })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}