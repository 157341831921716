<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">GL Account Category Member
                </span>
                <span class="color-black pl-1" *ngIf="!show"> >Add GL Account Category Member
                </span>
                <span class="color-black pl-1" *ngIf="show"> >Update GL Account Category Member
                </span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger" style="margin-left: 45%;" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians">
          
                      <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                        Add GL Account Category Member</a></li>
                        <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                            Update GL Account Category Member</a></li>
                   
          
                    </ul>
                  </div>
              
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createMember">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Gl Account ID<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2" >
                                                <p-dropdown  placeholder="Enter Gl Account ID" filter="true"
                                            formControlName="glAccountId"    [options]="parentGlAccountArray" optionlabel="label" >
                                                            
                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">From Date
                                                    <span
                                                    style="color:red">*</span>
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter From Date"
                                                formControlName="fromDate" >
                                           
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Through Date
                                               
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2" >
                                                <input type="date"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Through Date"
                                                formControlName="thruDate"  >
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Amount Percentage
                                               
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Amount Percentage"
                                                formControlName="amountPercentage"  >
                                           
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>

                                
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 60%;">
                                <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit"  *ngIf="!show"  (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>
                                
                             
                            </div>
                        </div>
                    </div>

                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
