import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/app.constant';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';
@Injectable({
  providedIn: 'root'
})
export class JobRequistionService {

  constructor(private http: HttpClient, private _ApiService: ApiService) { }

  getJobRequistionList(job): Observable<any> {
    const jobRequistionList = URLS.getJobRequisitions;
    return this.http.post(jobRequistionList, job);
  }
  onDelete(id) {
    const deleteJob = URLS.deleteJobRequisition.replace(":jobRequisitionId", id);
    return this.http.delete(deleteJob);
  }
  getJobRequisitionById(jobRequisitionId: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/hr/recruitment/${jobRequisitionId}`);
  }

}
