import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-my-task',
  templateUrl: './detail-my-task.component.html',
  styleUrls: ['./detail-my-task.component.css']
})
export class DetailMyTaskComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 1;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  sprintTable:any;
  roleForm:FormGroup;
  AddTaskForm: FormGroup;
  projectForm: FormGroup;
  ratesForm: FormGroup;
  ProjectName: any;
  ProjectNameArray: any[]=[];
  TaskInformationById: any;
  workEffortId: any;
  projectId: any;
  DependencyToList: any;
  DependencyFromList: any;
  TaskNoteList: any;
  TaskTeam: any;
  RequestList: any;
  internalNote: string;
  SurveyData: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  HidebtnRoleView: boolean=false;
  HidebtnTaskCrud: boolean=false;
  HidebtnTimeCurd: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
        private _location: Location,
        readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
        readonly _FormBuilder: FormBuilder,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    readonly router: Router
  ) { }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.PROJECTMGR_ADMIN=="PROJECTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PROJECTMGR_VIEW=="PROJECTMGR_VIEW"){
      this.Hidebtn=true;
    }
      else if(this.userPermissions.PROJECTMGR_ROLE_VIEW=="PROJECTMGR_ROLE_VIEW"){
      this.HidebtnRoleView=true;
    }
     else if(this.userPermissions.PROJECTMGR_ROLE_TASK_CREATE=="PROJECTMGR_ROLE_TASK_CREATE"){
      this.HidebtnTaskCrud=true;
    }
    else if(this.userPermissions.PROJECTMGR_ROLE_TIMESHEET_CREATE=="PROJECTMGR_ROLE_TIMESHEET_CREATE"){
      this.HidebtnTimeCurd=true;
    }
    this.activatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params.workEffortId;
      this.projectId = params.projectId;
      this.internalNote = "Y";

    })
    this.getProjectName();
    this.getFindTaskInformationById();
    this.getTaskNoteList();
    this.getTaskTeam();
    this.getDependencyTo();
    this.getSurveyData();
    this.getDependencyFrom();
    this.getDependencyFrom();
    this.getRequestList();
  }
  homeButton(){
    this.router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  getRequestList() {
    this.spinner.show();
    this.myContactsService.getRequestList(this.workEffortId).subscribe(res => {
      this.RequestList = res.data;
      this.spinner.hide();
    })
  }
  makePrivateN(product) {
    this.spinner.show();
    const requestData = {
      "internalNote": "N",
      "noteId": product.list.noteId,
      "view_INDEX_1": "20",
      "view_SIZE_1": "0",
      "workEffortId": this.workEffortId
    }
    this.myContactsService.makePrivate(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.getTaskNoteList();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  myProfile(partyId) {
    this.router.navigate(["/psa/my-profile"], { queryParams: { partyId: partyId } })
  }
  getSurveyData() {
    this.spinner.show();
    this.myContactsService.getSurveyData(this.workEffortId).subscribe(res => {
      this.SurveyData = res.data;
      this.spinner.hide();
    })
  }
  makePrivate(product) {
    this.spinner.show();
    const requestData = {
      "internalNote": "Y",
      "noteId": product.list.noteId,
      "view_INDEX_1": "20",
      "view_SIZE_1": "0",
      "workEffortId": this.workEffortId
    }
    this.myContactsService.makePrivate(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.getTaskNoteList();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  getDependencyFrom() {
    this.spinner.show();
    this.myContactsService.getDependencyFrom(this.workEffortId).subscribe(res => {
      this.DependencyFromList = res.data;
      this.spinner.hide();
    })
  }
  getDependencyTo() {
    this.spinner.show();
    this.myContactsService.getDependencyTo(this.workEffortId).subscribe(res => {
      this.DependencyToList = res.data;
      this.spinner.hide();
    })
  }
  getTaskTeam() {
    this.spinner.show();
    this.myContactsService.getTaskTeams(this.workEffortId).subscribe(res => {
      this.TaskTeam = res.data;
      this.spinner.hide();
    })
  }

  getTaskNoteList() {
    this.spinner.show();
    this.myContactsService.getTaskNoteList().subscribe(res => {
      this.TaskNoteList = res.data;
      this.spinner.hide();
    })
  }
  getFindTaskInformationById() {
    this.spinner.show();
    this.myContactsService.getFindTaskInformation(this.workEffortId).subscribe(res => {
      this.TaskInformationById = res.data[0];
      this.spinner.hide();
    })
  }
  
  getProjectName() {
    this.spinner.show();
    this.myContactsService.getProjectName().subscribe(res => {
      this.ProjectName = res.data.projectName;
      this.spinner.hide();
      for (const value of this.ProjectName) {
        this.ProjectNameArray.push({
          label: value.workEffortName,
          value: value.workEffortId
        })
      }
    })
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
