import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CRMAccountService } from 'src/app/services/crm-account.service';
import { DatePipe,Location } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
    selector: 'app-create-eft-account',
    templateUrl: './create-eft-account.component.html',
    styles: [`
                .border-card{
                    border: 1px solid;
                    border-color: #0d3769 !important;
                    /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */
                }
                .ml-54{
                    margin-left:54px !important;
                }
    `]
})
export class CreateEFTAccountComponent implements OnInit {
    partyId: string;

    eftAccountForm: FormGroup;
    editMode: boolean;
    accountData: any;
    contactMechId: string;
    isEftAccount: boolean;
    eftAccount: any;
    paymentMethodId: string;
    accountTypes = [
        {
            label: 'Checking',
            value: 'Checking'
        },
        {
            label: 'Savings',
            value: 'Savings'
        }];
    @Output() closeEftModal: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('closebutton') closebutton;
    
    constructor(
        readonly _ToastrService: ToastrService,
        readonly _FormBuilder: FormBuilder,private _location: Location,
        readonly _CRMAccountService: CRMAccountService,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly spinner:NgxSpinnerService,
        readonly  _Router: Router,
        public bsModalRef: BsModalRef
    ) {
        this.partyId = '';
        this.eftAccountForm = this._FormBuilder.group({
            accountNumber: ['', [Validators.required]],
            accountType: ['', [Validators.required]],
            bankName: ['', [Validators.required]],
            companyNameOnAccount: [''],
            contactMechId: [''],
            description: [''],
            nameOnAccount: ['', [Validators.required]],
            routingNumber: ['', [Validators.required]]
        });
        this.editMode = false;
        this.contactMechId = '';
        this.isEftAccount = false;
    }
    ngOnInit(): void {
        this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
        this.isEftAccount = this._ActivatedRoute.snapshot.queryParams.isEft === 'false' ? false : true;
        this.paymentMethodId = this._ActivatedRoute.snapshot.queryParams.paymentMethodId;
        this.getAccountDetails();
        if (this.paymentMethodId) {
            this.getPaymentMethod();
        }
    }
    getPaymentMethod(): void {
        this.spinner.show();
        this._CRMAccountService.getPaymentMethodById(this.partyId, this.paymentMethodId, 'EFT_ACCOUNT')
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.eftAccount = data.data.eftAccountData;
                    this.contactMechId = data.data.eftAccountData.postalAddressList[0].contactMech.contactMechId;
                    this.eftAccountForm.patchValue({
                        ...data.data.eftAccountData, ...{
                            description: data.data.paymentMethodData.description
                        }
                    });
                    this.editMode = true;
                   
                }
            });
           
    }
    getAccountDetails(): void {
        this.spinner.show();
        this._CRMAccountService.getAccountById(this.partyId)
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.accountData = data.data;
                }
            });
           
    }
    getAddress(event: any, contactMechId: string): void {
        this.contactMechId = (event.target.checked) ? contactMechId : '';
    }
    submit(): void {
        this.spinner.show();
        if (this.isEftAccount) {
            if (this.eftAccountForm.valid) {
                this._CRMAccountService.createEftAccount(this.partyId, this.eftAccountForm.value)
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Created');
                            this.modalClose();
                            this.spinner.hide();
                            this.cancelSubmit();
                        }
                    });
            }
        } else {
            if (this.eftAccountForm.valid) {
                this._CRMAccountService.createCheckAccount(this.partyId, this.eftAccountForm.value)
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Created');
                            this.spinner.hide();
                            this.cancelSubmit();
                        }
                    });
            }
        }
      
    }
    update(): void {
        this.spinner.show();
        if (this.eftAccountForm.valid) {
            this._CRMAccountService.updateEftAccount(this.partyId, this.paymentMethodId, {
                ...this.eftAccount, ...this.eftAccountForm.value, contactMechId: this.contactMechId
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Updated');
                        this.modalClose();
                        this.spinner.hide();
                        this.editMode = false;
                        this.cancelSubmit();
                    }
                })
        }
       
    }
    cancelSubmit(): void {
        this.eftAccountForm.reset();
        this._location.back();
    }
    modalClose(): void {
        this.closebutton.nativeElement.click();
        this.closeEftModal.emit();
    }
}