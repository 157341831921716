<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
           
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Data Resource</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="findOpen()"><a>
                                Find</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="navigateOpen()" ><a>
                               Navigate </a></li>
                            

                        </ul>
                    </div>
                                        
                        <div *ngIf="activeCategory==2">

                                <div class="create-new-leade">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <form class="w-100">

                                                <div class="w3-card-4 classCard  mt-3" style="width: 98%; margin-left: 1% !important;">
        
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        Navigate Content
                                                        
                                                    </div>
                                                    <form [formGroup]="navigateForm">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                        
                                                                    
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Navigate Content	
                                                                        </label>
                                                                    </div>
                                                                    
                                                                    <div class="col-lg-2">
                    
                                                                        <p-dropdown filter="true" optionlabel="label"
                                                                        [options]="navigateDataArray"  formControlName="navigate"  placeholder="Select Navigate Content"></p-dropdown> 
                    
                                                                    </div>
                                                        
                                                                </div>
                                                            </div>
                                                           
                                                            
                                                        </div>
                                                    </form>
                                                <div style=" margin-left: -67%;" class="col-lg-12 col-12 main-submit-button ">
                                                     <button type="submit" class="btn btn-secondary submit-btn"
                                                   (click)="navigateDataResourceC()" >Apply</button>&nbsp;
                                                    
                                                </div>
                                                </div><br>
                                                  
                                                <div class="w3-card-4 classCardB" style="width: 98%; margin-left: 1%; margin-top: 0% !important;">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                        <h4 class=" common-styling h4M">
                                                            Data Resource
                                                        </h4>
                                                        
                                                        </div>
                                                    </header>
                                                <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select" style="display: block!important;">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            
                                                            <div class="card own-account-table">
                                                                <p-table [value]="DataResourceC" [paginator]="true" [rows]="rows"
                                                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox" binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible"><input
                                                                                                    type="checkbox" name="undefined"
                                                                                                    value="undefined"></div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                    </p-checkbox>
                                                                                    
                                                                                    Data Resource Name	 <p-sortIcon field="code"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="name" [ngStyle]="{'width':'170px'}"><div style="color: white;">Data Resource Type ID<p-sortIcon field="name">
                                                                                </p-sortIcon></div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="category" [ngStyle]="{'width':'170px'}"><div style="color: white;"> Mime Type ID<p-sortIcon
                                                                                    field="category">
                                                                                </p-sortIcon></div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Status ID</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Locale String

                                                                            </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Created By User

                                                                            </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Data Category ID

                                                                            </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            
                                                                            
                                                                            
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox" binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible"><input
                                                                                                    type="checkbox" name="undefined"
                                                                                                    value="undefined"></div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                    </p-checkbox>
                                                                                    <span class="account-button">{{product.dataResourceName}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">{{product.dataResourceTypeId}}</td>
                                                                            <td [ngStyle]="{'width':'170px'}">{{product.mimeTypeId}}</td>
                                                                            <td [ngStyle]="{'width':'170px'}">{{product.statusId}}</td>
                                                                            <td [ngStyle]="{'width':'170px'}">{{product.localeString}}</td>
                                                                            <td [ngStyle]="{'width':'170px'}">{{product.createdByUserLogin}}</td>
                                                                            <td [ngStyle]="{'width':'170px'}">{{product.dataCategoryId}}</td>
                                                                            
                                                                        </tr>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </form>
        
                                        </div>
                                    </div>
                                </div>

                            </div>
                                            
                        </div>
            </div>
        </div>
    </div>
</div>
 
 
 <ngx-spinner></ngx-spinner> 

