import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class BehaviourSubjectService {
 readonly messageSource =new BehaviorSubject<string>("");
 currentMessage = this.messageSource.asObservable();
  constructor() { }
  transferData(message:any) {
    this.messageSource.next(message);
  }
}
