
<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Order Statistics Page</span>
               
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategories==1?'active':''" (click)="mainorder()"><a>
                                Order</a></li>
                            <li [ngClass]="activeCategories==2?'active':''" (click)="stats()" ><a>
                                Stats</a></li>
                        </ul>
                    </div>

                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                    <div *ngIf="activeCategories==2">

                                        <div class="row">
                                            <!-- <div class="col-12">
                                                <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                                   <span class="color-black pl-1 titlePanels">Order Statistics Page</span>
                                                </div>
                                            </div> -->
                                    
                                            <div class="col-md-12 mt-2">
                                                <div class="w3-card-4 classCard w-100" style="height: 320px;">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text">Orders Totals</p>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <div class="table-responsive">
                                                                        <table class="table table-striped" style="overflow: hidden;white-space: nowrap;">
                                                                       
                                                                            <tbody>
                                                                              <tr style="background: #0d3769;color:#FFFFFF;font-size: 12px;font-weight: 700;">
                                                                                <td colspan="2" style="width: 61%;">Orders Totals</td>
                                                                                <td>Today</td>
                                                                                <td>WTD</td>
                                                                                <td>MTD</td>
                                                                                <td>YTD</td>
                                                                              </tr>
                                                                              <tr *ngIf="this.OrderStatsList">
                                                                          
                                                                               
                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Gross Dollar Amounts (includes adjustments and pending orders)
                                                                                </td>
                                                                            
                                                                                <td>
                                                                                    {{OrderStatsList.dayItemTotal}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekItemTotal}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthItemTotal}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.yearItemTotal}}
                                                                                </td>
                                                                              </tr>
                                                                              <tr *ngIf="this.OrderStatsList">
                                                                               
                                                                                <td colspan="2" style="width: 61%;">Paid Dollar Amounts (includes adjustments)</td>
                                                                                <td>
                                                                                    {{OrderStatsList.dayItemTotalPaid}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekItemTotalPaid}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthItemTotalPaid}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.yearItemTotalPaid}}
                                    
                                                                                </td>
                                                                              </tr>
                                                                              <tr *ngIf="this.OrderStatsList">
                                                                               
                                                                                <td colspan="2" style="width: 61%;">Pending Payment Dollar Amounts (includes adjustments)</td>
                                                                                <td>
                                                                                    {{OrderStatsList.dayItemTotalPending}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekItemTotalPending}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthItemTotalPending}}
                                    
                                                                                </td>
                                                                                <td >
                                                                                    {{OrderStatsList.yearItemTotalPending}}
                                    
                                                                                </td>
                                                                              </tr>
                                                                            </tbody>
                                                                          </table>
                                                                    </div>
                                                              
                                                                  
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                <div class="w3-card-4 classCard  w-100" style="height: 320px;">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text">Orders Item Counts</p>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <div class="table-responsive">
                                                                        <table class="table table-striped" style="overflow: hidden;white-space: nowrap;">
                                                                       
                                                                            <tbody>
                                                                              <tr style="background: #0d3769;color:#FFFFFF;font-size: 12px;font-weight: 700;">
                                                                                <td colspan="2" style="width: 61%;">Orders Item Counts</td>
                                                                                <td>Today</td>
                                                                                <td>WTD</td>
                                                                                <td>MTD</td>
                                                                                <td>YTD</td>
                                                                              </tr>
                                                                              <tr *ngIf="this.OrderStatsList">
                                                                          
                                                                               
                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Gross Items Sold (includes promotions and pending orders)
                                                                                </td>
                                                                            
                                                                                <td>
                                                                                    {{OrderStatsList.dayItemCount}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekItemCount}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthItemCount}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.yearItemCount}}
                                                                                </td>
                                                                              </tr>
                                                                              <tr *ngIf="this.OrderStatsList">
                                                                               
                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Paid Items Sold (includes promotions)
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.dayItemCountPaid}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekItemCountPaid}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthItemCountPaid}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.yearItemCountPaid}}
                                    
                                                                                </td>
                                                                              </tr>
                                                                              <tr *ngIf="this.OrderStatsList">
                                                                               
                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Pending Payment Items Sold (includes promotions)
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.dayItemCountPending}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekItemCountPending}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthItemCountPending}}
                                    
                                                                                </td>
                                                                                <td >
                                                                                    {{OrderStatsList.yearItemCountPending}}
                                    
                                                                                </td>
                                                                              </tr>
                                                                            </tbody>
                                                                          </table>
                                                                    </div>
                                                                  
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                             <div class="col-md-12 mt-2">
                                                <div class="w3-card-4 classCard w-100" style="height: 400px;">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text">Orders Pending</p>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <div class="table-responsive">
                                                                        <table class="table table-striped" style="overflow: hidden;white-space: nowrap;">
                                                                       
                                                                            <tbody>
                                                                              <tr style="background: #0d3769;color:#FFFFFF;font-size: 12px;font-weight: 700;">
                                                                                <td colspan="2" style="width: 61%;">Orders Pending</td>
                                                                                <td>Today</td>
                                                                                <td>WTD</td>
                                                                                <td>MTD</td>
                                                                                <td>YTD</td>
                                                                              </tr>
                                                                              <tr *ngIf="this.OrderStatsList">
                                                                          
                                                                               
                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Waiting Payment
                                                                                </td>
                                                                            
                                                                                <td>
                                                                                    {{OrderStatsList.waitingPayment}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.waitingWeekPayment}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.waitingMonthPayment}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.waitingYearPayment}}
                                                                                </td>
                                                                              </tr>
                                                                              <tr *ngIf="this.OrderStatsList">
                                                                               
                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Waiting Approval
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.waitingApproval}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.waitingWeekApproval}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.waitingMonthApproval}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.waitingYearApproval}}
                                    
                                                                                </td>
                                                                              </tr>
                                                                              <tr *ngIf="this.OrderStatsList">
                                                                               
                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Waiting Completion
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.waitingComplete}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.waitingWeekComplete}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.waitingMonthComplete}}
                                    
                                                                                </td>
                                                                                <td >
                                                                                    {{OrderStatsList.waitingYearComplete}}
                                    
                                                                                </td>
                                                                              </tr>
                                                                            </tbody>
                                                                          </table>
                                                                    </div>
                                                                  
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2 mb-4" style="margin-bottom:7%!important;">
                                                <div class="w3-card-4 classCard  w-100" style="height: 400px;">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text">Status Changes</p>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <div class="table-responsive">
                                                                        <table class="table table-striped" style="overflow: hidden;white-space: nowrap;">
                                                                       
                                                                            <tbody>
                                                                              <tr style="background: #0d3769;color:#FFFFFF;font-size: 12px;font-weight: 700;">
                                                                                <td colspan="2" style="width: 61%;">Status Changes</td>
                                                                                <td>Today</td>
                                                                                <td>WTD</td>
                                                                                <td>MTD</td>
                                                                                <td>YTD</td>
                                                                              </tr>
                                                                              <tr *ngIf="this.OrderStatsList">
                                                                          
                                                                               
                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Created
                                                                                </td>
                                                                            
                                                                                <td>
                                                                                    {{OrderStatsList.dayOrder}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekOrder}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthOrder}}
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.yearOrder}}
                                                                                </td>
                                                                              </tr>
                                                                              <tr *ngIf="this.OrderStatsList">
                                                                               
                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Approved
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.dayApprove}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekApprove}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthApprove}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.yearApprove}}
                                    
                                                                                </td>
                                                                              </tr>
                                                                              <tr *ngIf="this.OrderStatsList">
                                                                               
                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Completed
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.dayComplete}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekComplete}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthCancelled}}
                                    
                                                                                </td>
                                                                                <td >
                                                                                    {{OrderStatsList.yearCancelled}}
                                    
                                                                                </td>
                                                                              </tr>
                                                                              <tr *ngIf="this.OrderStatsList">
                                                                               
                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Cancelled
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.dayCancelled}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekCancelled}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthCancelled}}
                                    
                                                                                </td>
                                                                                <td >
                                                                                    {{OrderStatsList.yearCancelled}}
                                    
                                                                                </td>
                                                                              </tr>
                                                                              <tr *ngIf="this.OrderStatsList">
                                                                               
                                                                                <td colspan="2" style="width: 61%;">
                                                                                    Rejected
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.dayRejected}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.weekRejected}}
                                    
                                                                                </td>
                                                                                <td>
                                                                                    {{OrderStatsList.monthRejected}}
                                    
                                                                                </td>
                                                                                <td >
                                                                                    {{OrderStatsList.yearRejected}}
                                    
                                                                                </td>
                                                                              </tr>
                                                                            </tbody>
                                                                          </table>
                                                                    </div>
                                                                  
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Order Statistics Page</span>
            </div>
        </div>

        <div class="col-md-12 mt-2">
            <div class="w3-card-4 classCard w-100" style="height: 320px;">
                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                    <p class="h-text">Orders Totals</p>
                </div>
                <div class="all-users-infomation font-13 font-medium own-user-select">
                    <div class="col-lg-12 col-12">
                        <div class="form-group">
                            <div class="card own-account-table">
                                <div class="table-responsive">
                                    <table class="table table-striped" style="overflow: hidden;white-space: nowrap;">
                                   
                                        <tbody>
                                          <tr style="background: #0d3769;color:#FFFFFF;font-size: 12px;font-weight: 700;">
                                            <td colspan="2" style="width: 61%;">Orders Totals</td>
                                            <td>Today</td>
                                            <td>WTD</td>
                                            <td>MTD</td>
                                            <td>YTD</td>
                                          </tr>
                                          <tr *ngIf="this.OrderStatsList">
                                      
                                           
                                            <td colspan="2" style="width: 61%;">
                                                Gross Dollar Amounts (includes adjustments and pending orders)
                                            </td>
                                        
                                            <td>
                                                {{OrderStatsList.dayItemTotal}}
                                            </td>
                                            <td>
                                                {{OrderStatsList.weekItemTotal}}
                                            </td>
                                            <td>
                                                {{OrderStatsList.monthItemTotal}}
                                            </td>
                                            <td>
                                                {{OrderStatsList.yearItemTotal}}
                                            </td>
                                          </tr>
                                          <tr *ngIf="this.OrderStatsList">
                                           
                                            <td colspan="2" style="width: 61%;">Paid Dollar Amounts (includes adjustments)</td>
                                            <td>
                                                {{OrderStatsList.dayItemTotalPaid}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.weekItemTotalPaid}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.monthItemTotalPaid}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.yearItemTotalPaid}}

                                            </td>
                                          </tr>
                                          <tr *ngIf="this.OrderStatsList">
                                           
                                            <td colspan="2" style="width: 61%;">Pending Payment Dollar Amounts (includes adjustments)</td>
                                            <td>
                                                {{OrderStatsList.dayItemTotalPending}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.weekItemTotalPending}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.monthItemTotalPending}}

                                            </td>
                                            <td >
                                                {{OrderStatsList.yearItemTotalPending}}

                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                </div>
                          
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 mt-2">
            <div class="w3-card-4 classCard  w-100" style="height: 320px;">
                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                    <p class="h-text">Orders Item Counts</p>
                </div>
                <div class="all-users-infomation font-13 font-medium own-user-select">
                    <div class="col-lg-12 col-12">
                        <div class="form-group">
                            <div class="card own-account-table">
                                <div class="table-responsive">
                                    <table class="table table-striped" style="overflow: hidden;white-space: nowrap;">
                                   
                                        <tbody>
                                          <tr style="background: #0d3769;color:#FFFFFF;font-size: 12px;font-weight: 700;">
                                            <td colspan="2" style="width: 61%;">Orders Item Counts</td>
                                            <td>Today</td>
                                            <td>WTD</td>
                                            <td>MTD</td>
                                            <td>YTD</td>
                                          </tr>
                                          <tr *ngIf="this.OrderStatsList">
                                      
                                           
                                            <td colspan="2" style="width: 61%;">
                                                Gross Items Sold (includes promotions and pending orders)
                                            </td>
                                        
                                            <td>
                                                {{OrderStatsList.dayItemCount}}
                                            </td>
                                            <td>
                                                {{OrderStatsList.weekItemCount}}
                                            </td>
                                            <td>
                                                {{OrderStatsList.monthItemCount}}
                                            </td>
                                            <td>
                                                {{OrderStatsList.yearItemCount}}
                                            </td>
                                          </tr>
                                          <tr *ngIf="this.OrderStatsList">
                                           
                                            <td colspan="2" style="width: 61%;">
                                                Paid Items Sold (includes promotions)
                                            </td>
                                            <td>
                                                {{OrderStatsList.dayItemCountPaid}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.weekItemCountPaid}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.monthItemCountPaid}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.yearItemCountPaid}}

                                            </td>
                                          </tr>
                                          <tr *ngIf="this.OrderStatsList">
                                           
                                            <td colspan="2" style="width: 61%;">
                                                Pending Payment Items Sold (includes promotions)
                                            </td>
                                            <td>
                                                {{OrderStatsList.dayItemCountPending}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.weekItemCountPending}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.monthItemCountPending}}

                                            </td>
                                            <td >
                                                {{OrderStatsList.yearItemCountPending}}

                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <div class="col-md-12 mt-2">
            <div class="w3-card-4 classCard w-100" style="height: 400px;">
                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                    <p class="h-text">Orders Pending</p>
                </div>
                <div class="all-users-infomation font-13 font-medium own-user-select">
                    <div class="col-lg-12 col-12">
                        <div class="form-group">
                            <div class="card own-account-table">
                                <div class="table-responsive">
                                    <table class="table table-striped" style="overflow: hidden;white-space: nowrap;">
                                   
                                        <tbody>
                                          <tr style="background: #0d3769;color:#FFFFFF;font-size: 12px;font-weight: 700;">
                                            <td colspan="2" style="width: 61%;">Orders Pending</td>
                                            <td>Today</td>
                                            <td>WTD</td>
                                            <td>MTD</td>
                                            <td>YTD</td>
                                          </tr>
                                          <tr *ngIf="this.OrderStatsList">
                                      
                                           
                                            <td colspan="2" style="width: 61%;">
                                                Waiting Payment
                                            </td>
                                        
                                            <td>
                                                {{OrderStatsList.waitingPayment}}
                                            </td>
                                            <td>
                                                {{OrderStatsList.waitingWeekPayment}}
                                            </td>
                                            <td>
                                                {{OrderStatsList.waitingMonthPayment}}
                                            </td>
                                            <td>
                                                {{OrderStatsList.waitingYearPayment}}
                                            </td>
                                          </tr>
                                          <tr *ngIf="this.OrderStatsList">
                                           
                                            <td colspan="2" style="width: 61%;">
                                                Waiting Approval
                                            </td>
                                            <td>
                                                {{OrderStatsList.waitingApproval}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.waitingWeekApproval}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.waitingMonthApproval}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.waitingYearApproval}}

                                            </td>
                                          </tr>
                                          <tr *ngIf="this.OrderStatsList">
                                           
                                            <td colspan="2" style="width: 61%;">
                                                Waiting Completion
                                            </td>
                                            <td>
                                                {{OrderStatsList.waitingComplete}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.waitingWeekComplete}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.waitingMonthComplete}}

                                            </td>
                                            <td >
                                                {{OrderStatsList.waitingYearComplete}}

                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 mt-2 mb-4" style="margin-bottom:7%!important;">
            <div class="w3-card-4 classCard  w-100" style="height: 400px;">
                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                    <p class="h-text">Status Changes</p>
                </div>
                <div class="all-users-infomation font-13 font-medium own-user-select">
                    <div class="col-lg-12 col-12">
                        <div class="form-group">
                            <div class="card own-account-table">
                                <div class="table-responsive">
                                    <table class="table table-striped" style="overflow: hidden;white-space: nowrap;">
                                   
                                        <tbody>
                                          <tr style="background: #0d3769;color:#FFFFFF;font-size: 12px;font-weight: 700;">
                                            <td colspan="2" style="width: 61%;">Status Changes</td>
                                            <td>Today</td>
                                            <td>WTD</td>
                                            <td>MTD</td>
                                            <td>YTD</td>
                                          </tr>
                                          <tr *ngIf="this.OrderStatsList">
                                      
                                           
                                            <td colspan="2" style="width: 61%;">
                                                Created
                                            </td>
                                        
                                            <td>
                                                {{OrderStatsList.dayOrder}}
                                            </td>
                                            <td>
                                                {{OrderStatsList.weekOrder}}
                                            </td>
                                            <td>
                                                {{OrderStatsList.monthOrder}}
                                            </td>
                                            <td>
                                                {{OrderStatsList.yearOrder}}
                                            </td>
                                          </tr>
                                          <tr *ngIf="this.OrderStatsList">
                                           
                                            <td colspan="2" style="width: 61%;">
                                                Approved
                                            </td>
                                            <td>
                                                {{OrderStatsList.dayApprove}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.weekApprove}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.monthApprove}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.yearApprove}}

                                            </td>
                                          </tr>
                                          <tr *ngIf="this.OrderStatsList">
                                           
                                            <td colspan="2" style="width: 61%;">
                                                Completed
                                            </td>
                                            <td>
                                                {{OrderStatsList.dayComplete}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.weekComplete}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.monthCancelled}}

                                            </td>
                                            <td >
                                                {{OrderStatsList.yearCancelled}}

                                            </td>
                                          </tr>
                                          <tr *ngIf="this.OrderStatsList">
                                           
                                            <td colspan="2" style="width: 61%;">
                                                Cancelled
                                            </td>
                                            <td>
                                                {{OrderStatsList.dayCancelled}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.weekCancelled}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.monthCancelled}}

                                            </td>
                                            <td >
                                                {{OrderStatsList.yearCancelled}}

                                            </td>
                                          </tr>
                                          <tr *ngIf="this.OrderStatsList">
                                           
                                            <td colspan="2" style="width: 61%;">
                                                Rejected
                                            </td>
                                            <td>
                                                {{OrderStatsList.dayRejected}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.weekRejected}}

                                            </td>
                                            <td>
                                                {{OrderStatsList.monthRejected}}

                                            </td>
                                            <td >
                                                {{OrderStatsList.yearRejected}}

                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>     -->

<ngx-spinner></ngx-spinner>