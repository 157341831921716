<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class=" bg-white color-grey create-new-table">
        <div style="margin: 4%;font-size: 11px;">
          <ng-wizard [config]="config">

            <ng-wizard-step [title]="'Step 1'" [description]="'Sales Order'"> 
            </ng-wizard-step>

            <ng-wizard-step [title]="'Step 2'" [description]="' Enter Order Currency, Agreements, and Ship Dates '">  
            </ng-wizard-step>
           
          <ng-wizard-step [title]="'Step 3'" [description]="'Create Sales Order'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 4'" [description]="'Shipping'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 5'" [description]="'Option'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 6'" [description]="'Order Term'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 7'" [description]="'Payment'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 8'" [description]="'Parties'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 9'" [description]="'Review Order'">     
            </ng-wizard-step>
           
          
         
          </ng-wizard>
        </div>
        <div>
          <div class="container-fluid">
            <div class="container-fluid">
              <ul class="tabbing-section  tabbing-accordians" style="padding-left: 9px;">
                <li [ngClass]="activeCategory==1?'active':''" ><a>
                  Sales Order : Order Entry Order Terms</a></li>
   
               
              
              </ul>
            </div>
            <div class="row">
              <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                <div class="w-100">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                      <div *ngIf="activeCategory==1">
                        <div class="header-tabs">
                          <h4 class=" common-styling">
                            Sales Order : Order Entry Order Terms
                          </h4>
                          <span class="arrow-toggle toggle-arrow detail-created" data-toggle="collapse"
                          href="#seo-collapse">
                        </span>
                        <span style="margin-left: 22%!important" class="edit-buttons" routerLinkActive="active"
                          *ngIf="isSearchPage" (click)="isSearchPage=false"> Create</span>
                        <span class="edit-buttons" routerLinkActive="active"
                          routerLink="/order/create-sales-continue"> Order Items</span>
                        <span class="edit-buttons" routerLinkActive="active"
                          routerLink="/order/sales-finalize-order">Shipping</span>
                        <span class="edit-buttons">Options</span>
                        <span class="edit-buttons">Order Terms</span>
                        <span class="edit-buttons">Payment </span>
                        <span class="edit-buttons">Parties</span>
                        <span class="edit-buttons">Review Order</span>
                        <span class="edit-buttons" routerLinkActive="active"
                          (click)="navigateToPayment()"> Continue</span>

                        
                         
                        </div>
                        <div class="panel-body">
                          <div class="mt-2">
                            <div class="">
                              <div class="row">
                                <ng-container *ngIf="!isSearchPage;else searchPage">
                                  <form class="w-100" [formGroup]="orderTermForm">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label class="common-labels">Term Type
                                          </label>
                                          <p-dropdown [options]="orderTermType" placeholder="Select Term Type"
                                            name="term" formControlName="termTypeId"></p-dropdown>
                                        </div>
                                      </div>
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label class="common-labels">Term Value
                                          </label>
                                          <input type="text" class="form-control" formControlName="termValue"
                                            placeholder="Enter Name">
                                        </div>
                                      </div>
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label class="common-labels">Term Days
                                          </label>
                                          <input type="text" class="form-control" formControlName="termDays"
                                            placeholder="Enter Term Days">
                                        </div>
                                      </div>
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label class="common-labels">Text Value
                                          </label>
                                          <input type="text" class="form-control" formControlName="textValue"
                                            placeholder="Enter Text Value">
                                        </div>
                                      </div>
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label style="color: #65787E !important;"
                                            for="exampleInputEmail1">Description</label>
                                          <div>
                                            <textarea name="" id="exampleInputEmail1" class="form-control" cols="30"
                                              rows="10" formControlName="description"></textarea>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-lg-12 col-12 main-submit-button">
                                        <button type="submit" class="btn btn-secondary submit-btn"
                                          (click)="submit()">{{editMode ? 'Update' :'Add'}}</button>
                                      </div>
                                    </div>
                                  </form>
                                </ng-container>
                                <ng-template #searchPage>
                                  <div class="card own-account-table">
                                    <p-table [value]="orderTerms" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                      [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true"
                                      (sortFunction)="customSort($event)" [customSort]="true">
                                      <ng-template pTemplate="header">
                                        <tr>
                                          <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                            <div class="checkbox-align">
                                              <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                binary="true"
                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                  <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                      name="undefined" value="undefined"></div>
                                                  <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                  </div>
                                                </div>
                                                <!---->
                                              </p-checkbox>
                                              Term Type <p-sortIcon field="code"></p-sortIcon>
                                            </div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </th>
                                          <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">Term Value
                                            <p-sortIcon field="name">
                                            </p-sortIcon>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </th>
                                          <th [ngStyle]="{'width':'190px'}" pSortableColumn="category"> Term Days
                                            <p-sortIcon field="category">
                                            </p-sortIcon>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </th>
                                          <th [ngStyle]="{'width':'190px'}" pSortableColumn="quantity">
                                            Text Value <p-sortIcon field="quantity">
                                            </p-sortIcon>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </th>
                                          <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">Action
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </th>
                                        </tr>
                                      </ng-template>
                                      <ng-template pTemplate="body" let-product>
                                        <tr>
                                          <td [ngStyle]="{'width':'190px'}">
                                            <div class="checkbox-align">
                                              <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                binary="true"
                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                  <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                      name="undefined" value="undefined"></div>
                                                  <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                  </div>
                                                </div>
                                              </p-checkbox>
                                              <span  class="account-button" >{{product.termTypeId}} </span>
                                            </div>
                                          </td>
                                          <td [ngStyle]="{'width':'190px'}">{{product.termValue}}</td>
                                          <td [ngStyle]="{'width':'190px'}">{{product.termDays}}</td>
                                          <td [ngStyle]="{'width':'190px'}">{{product.textValue}}</td>
                                          <td [ngStyle]="{'width':'190px'}">{{product.description}}</td>
                                          <td [ngStyle]="{'width':'190px'}">
                                            <fa-icon [icon]="faEdit"  class="account-button" 
                                              (click)="makeOrderTermEditable(product.termIndex)"></fa-icon>
                                            <fa-icon [icon]="faTrash" class="text-danger cursor-pointer"
                                              (click)="deleteOrderTerm(product.termIndex)"></fa-icon>
                                          </td>
                                        </tr>
                                      </ng-template>
                                      <ng-template pTemplate="emptymessage" let-columns>
                                        <tr>
                                          <td>
                                            <span class="text-center">
                                              <h4 class="ml-2">No Record Found</h4>
                                            </span>
                                          </td>
                                        </tr>
                                      </ng-template>
                                    </p-table>
                                    <p class="paginate_data">
                                      View per page </p>
                                  </div>
                                </ng-template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner> 