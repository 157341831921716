<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Detail Overview</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Content</span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12 list_item_mobile">
                                <div class="list_item_mobile_div">
                                    <ul class="tabbing-section tabbing-accordians tabClass"
                                    style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                                    <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)"><a>
                                            WebSite</a></li>
                                    <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)"><a>
                                            Content</a></li>
                                    <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)"><a>
                                            Path Alias</a></li>
                                    <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)"><a>
                                            Parties</a></li>
        
                                    <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategory(5)"><a>
                                            CMS</a></li>
        
                                    <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategory(6)"><a>
                                            SEO</a></li>
        
                                    <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategory(7)"><a>
                                            Contact Lists</a></li>
        
                                    <li [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategory(8)"><a>
                                            Web Analytics</a></li>
        
                                    <li>
                                        <button type="submit" class="btn btn-secondary submit-btn ml-2 mt-2" style="margin: 0;"
                                        data-toggle="modal" data-target="#createWebSitePopup">Create New WebSite</button>
                                    </li>        
        
                                </ul>
                                </div>
                            </div>
                        </div>
                   
                    </div>
                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                    <div *ngIf="activeCategory==1">
                                        <div class="w3-card-4 classCard w-100">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                style="justify-content: space-between;">
                                                <p class="h-text">Summary</p>
                                                <span>
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" 
                                                    (click)="generatePDFWebsite()">Download</button> 

                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                        style="margin: 0;" data-toggle="modal"
                                                        #closebutton10  data-target="#updateWebSitePopup">Update</button>

                                                </span>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">

                                                <div class="color-black container">
                                                    <div class="row" *ngIf="this.findWebSite">
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Web Site ID </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.findWebSite.webSiteId}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Site Name </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.findWebSite.siteName}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Http Host</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.findWebSite.httpHost}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Http Port </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.findWebSite.httpPort}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Https Host </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.findWebSite.httpsHost}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Https Port </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.findWebSite.httpsPort}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Enable Https </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.findWebSite.enableHttps}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Webapp Path
                                                                    </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.findWebSite.webappPath}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Standard Content Prefix </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.findWebSite.standardContentPrefix}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Secure Content Prefix </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.findWebSite.secureContentPrefix}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Domain Cookie </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.findWebSite.cookieDomain}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Visual Theme Set </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.findWebSite.visualThemeSetId}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Product Store ID </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.findWebSite.productStoreId}}
                                                                        <button class="btn btn-secondary submit-btn"
                                                                            (click)="detailPage()">Edit Product
                                                                            Store</button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Allow Product Store Change </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.findWebSite.allowProductStoreChange}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Hosted Path Alias </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.findWebSite.hostedPathAlias}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Is Default </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.findWebSite.isDefault}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Display Maintenance Page </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.findWebSite.displayMaintenancePage}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div *ngIf="activeCategory==2">
                                        <div class="w3-card-4 classCardView" style="width: 100%;">
                                            <header class="w3-container w3-blue">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling h4M">
                                                        WebSite Content
                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                         (click)="resetContent()"   data-target="#createWebSiteContentPopup">Create Web Site
                                                            Content</button>


                                                    </h4>

                                                </div>
                                            </header>
                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select"
                                                style="display: block!important;">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">

                                                        <div class="card own-account-table">
                                                            <p-table [value]="webSiteContentList" [paginator]="true" [rows]="rows"
                                                                scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th pSortableColumn="code"
                                                                            [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined"></div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>

                                                                                </p-checkbox>

                                                                                Web Site ID<p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="name"
                                                                            [ngStyle]="{'width':'170px'}">
                                                                            <div style="color: white;">Content ID
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="category"
                                                                            [ngStyle]="{'width':'170px'}">
                                                                            <div style="color: white;"> Web Site Content
                                                                                Type ID <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="price"
                                                                            [ngStyle]="{'width':'170px'}">
                                                                            <div style="color: white;">From Date </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="price"
                                                                            [ngStyle]="{'width':'170px'}">
                                                                            <div style="color: white;">Through Date
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="price"
                                                                            [ngStyle]="{'width':'170px'}">
                                                                            <div style="color: white;">Action
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined"></div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>

                                                                                </p-checkbox>
                                                                                <span
                                                                                   
                                                                                 >{{product.ListWebSiteContent.webSiteId}}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}" >
                                                                         <span  class="account-button" 
                                                                         (click)="contentPage(product.ListWebSiteContent.contentId)"> {{product.ListWebSiteContent.contentId}}</span>  </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.ListWebSiteContent.webSiteContentTypeId}}</td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.fromDate}}</td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.thruDate}}</td>
                                                                        
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <button type="submit"
                                                                              (click)="updateContent(product)"  data-toggle="modal"
                                                                              data-target="#createWebSiteContentPopup"
                                                                               class="btn btn-secondary submit-btn ml-2">Update</button>
                                                                            <button type="submit"
                                                                              (click)="RemoveWebSiteContent(product)" 
                                                                               class="btn btn-danger  ml-2">Delete</button>

                                                                        </td>

                                                                    </tr>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No Record Found
                                                                                    </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="w3-card-4 classCardView mt-3">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                WebSite Content

                                            </div>
                                            <form>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <form [formGroup]="autoCreateContentPublishPointsForm">


                                                        <div class="col-lg-12">
                                                            <div class="row ">
                                                                <div class="col-lg-5 form-group classInputII">
                                                                    <label for="exampleInputEmail1">Web Site Content
                                                                        Type ID
                                                                    </label>
                                                                </div>

                                                            
                                                                <div class="col-lg-6 ">

                                                                    <p-dropdown filter="true" optionlabel="label"
                                                                        [options]="WebSiteContentArray"
                                                                        formControlName="WebSiteContentTypeID"
                                                                        placeholder="Choose WebSite Content Type ID ">

                                                                    </p-dropdown>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </form>
                                            <div class="col-lg-9 main-submit-button"
                                                style="margin-left: -43% !important;">
                                                <button type="submit"
                                                    class="btn btn-secondary submit-btn" (click)="autoCreateWebSiteContent()">Add</button>&nbsp;
                                            </div>
                                        </div>

                                    </div>

                                    <div *ngIf="activeCategory==3">
                                        <div class="">

                                            <div class="w3-card-4  w-100">
                                                <div class="accordion classCard" id="myAccordion">
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" id="headingOne">
                                                            <div class="d-flex justify-content-between bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 headingAccordion" >
                                                                <span class="headingAccords">Path Alias</span>
                                                                <span>
                                                                    <button type="button" class="btn btn-outline-secondary active"
                                                                    style="margin-left:1% !important ;" (click)="resetPath()"
                                                                    data-target="#createNewPathAlisPopup" data-toggle="modal">Create New Path Alias</button>
                                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                                                                    </button>	
                                                                </span>
                                                               								
                    
                                                            </div>
                        
                                                        </h2>
                                                        <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                            <div class="card-body">
                                                                <form [formGroup]="pathAliasForm">
                                                                    <div
                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">
                                                                                        Path Alias </label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" class="form-control"
                                                                                        id="exampleInputEmail1"
                                                                                        aria-describedby="emailHelp"
                                                                                        placeholder="Enter  Path Alias"
                                                                                        formControlName="pathAlias">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
            
                                                                <div class="col-lg-10 main-submit-button"
                                                                    style="margin-left: -51% !important;">
                                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                                        (click)="FindWebSitePathAlias()">Find</button>&nbsp;
                                                                    <button type="button" class="btn btn-danger ml-2"
                                                                        (click)="reset()">Reset</button>&nbsp;
                                                                </div>
            
            
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>

                                            <!-- <div class="w3-card-4 classCardView">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    Path Alias
                                                    <button type="button" class="btn btn-outline-secondary active"
                                                        style="margin-left:77% !important ;" (click)="resetPath()"
                                                        data-target="#createNewPathAlisPopup" data-toggle="modal">Create
                                                        New Path Alias</button>

                                                </div>

                                                <form [formGroup]="pathAliasForm">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">
                                                                        Path Alias </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter  Path Alias"
                                                                        formControlName="pathAlias">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div class="col-lg-10 main-submit-button"
                                                    style="margin-left: -51% !important;">
                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                        (click)="FindWebSitePathAlias()">Find</button>&nbsp;
                                                    <button type="button" class="btn btn-danger ml-2"
                                                        (click)="reset()">Reset</button>&nbsp;
                                                </div>
                                            </div> -->

                                            <div class="w3-card-4 classCardB" style="width: 100%;">
                                                <header class="w3-container w3-blue">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling h4M">
                                                            Path Alias List
                                                        </h4>

                                                    </div>
                                                </header>
                                                <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select"
                                                    style="display: block!important;">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="pathAliasList" [paginator]="true"
                                                                    [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th pSortableColumn="code"
                                                                                [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>

                                                                                    Path Alias <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="name"
                                                                                [ngStyle]="{'width':'170px'}">
                                                                                <div style="color: white;">Alias To
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="category"
                                                                                [ngStyle]="{'width':'170px'}">
                                                                                <div style="color: white;"> Content ID
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="price"
                                                                                [ngStyle]="{'width':'170px'}">
                                                                                <div style="color: white;">Map Key</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th pSortableColumn="price"
                                                                                [ngStyle]="{'width':'170px'}">
                                                                                <div style="color: white;">From Date
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th pSortableColumn="price"
                                                                                [ngStyle]="{'width':'170px'}">
                                                                                <div style="color: white;">Through Date
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th pSortableColumn="price"
                                                                                [ngStyle]="{'width':'170px'}">
                                                                                <div style="color: white;">Action</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>


                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>
                                                                                    <span data-target="#createNewPathAlisPopup"
                                                                                    data-toggle="modal"
                                                                                    (click)="updatePathAlias(product)"
                                                                                         class="account-button" >{{product.WebSitePathAliasData.pathAlias}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.WebSitePathAliasData.aliasTo}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.WebSitePathAliasData.contentId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.WebSitePathAliasData.mapKey}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.fromDate}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.thruDate}}
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'170px'}">

                                                                                <button type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    data-target="#createNewPathAlisPopup"
                                                                                    data-toggle="modal"
                                                                                    (click)="updatePathAlias(product)">Update</button>
                                                                                <button type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="removeWebSitePathAlias(product)">Delete</button>


                                                                            </td>


                                                                        </tr>
                                                                        <ng-template pTemplate="emptymessage"
                                                                            let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <div class="w3-card-4 classCard w-100 mt-4">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                        style="justify-content: space-between;">
                                                        <p class="h-text">Summary</p>
                                                        <span>
                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                                style="margin: 0;" data-toggle="modal"
                                                                data-target="#createNewPathAlisPopup">Update</button>
                                                            
                                                        </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        
                                                        <div class="color-black container">
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Web Site ID	</p>
                                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Path Alias</p>
                                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
    
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">From Date	</p>
                                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
    
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Through Date		</p>
                                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
    
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Alias To		</p>
                                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
    
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Content ID	
                                                                            </p>
                                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
    
    
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Map Key				</p>
                                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
    
                                                                
    
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->

                                        </div>

                                    </div>

                                    <div *ngIf="activeCategory==4">



                                        <div class="w3-card-4 classCardView mt-2 w-100">
                                            <div
                                                class="d-flex justify-content-between bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0" style="white-space: nowrap;">
                                                WebSite Parties

                                                <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                     data-toggle="modal"
                                                    data-target="#createWebistePartiesPopup"
                                                    (click)=" resetParty()">Create New WebSite Parties
                                                </button>

                                            </div>



                                            <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">

                                                        <div class="card own-account-table">
                                                            <p-table [value]="WebSiteRoleList" [paginator]="true"
                                                                [rows]="rows" scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>

                                                                                Party ID

                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Role Type ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                From Date
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Through Date
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Sequence Num
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Action
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>



                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span (click)="partyPage(product.WebSiteRoleAll.partyId)"
                                                                                     class="account-button"  >
                                                                                    {{product.WebSiteRoleAll.partyId}}
                                                                                </span>

                                                                            </div>
                                                                        </td>

                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.WebSiteRoleAll.roleTypeId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.fromDate}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.thruDate}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.WebSiteRoleAll.sequenceNum}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            <button type="submit" class="btn btn-secondary 
                                                                                submit-btn ml-2"
                                                                                (click)="updateParty(product)"
                                                                                data-toggle="modal"
                                                                                data-target="#createWebistePartiesPopup">Update</button>
                                                                            <button type="submit" class="btn btn-secondary 
                                                                                submit-btn ml-2"
                                                                                (click)="removeWebSiteRole(product)">Delete</button>

                                                                        </td>

                                                                    </tr>
                                                                </ng-template>

                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>

                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div *ngIf="activeCategory==5">
                                        <div class="w3-card-4 classCardView">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                WebSite CMS Content
                                                <!-- <button type="button" class="btn btn-outline-secondary active"routerLinkActive="active"
                                                        data-target="#createSurveyPopup" data-toggle="modal">WebSite CMS Content</button>
                                                    -->
                                            </div>
                                            <form [formGroup]="webSiteCmsContentForm">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">
                                                                    Data Type</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label"
                                                                    formControlName="DataType"
                                                                    [options]="dataResourceIdArray"
                                                                    placeholder="Select Data Type">

                                                                </p-dropdown>
                                                            </div>

                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">New SUBSITE</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email" formControlName="NewSUBSITE"
                                                                    class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter New SUBSITE">

                                                            </div>


                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">New SubSuite</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email"  formControlName="newSubSuite" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter New SubSuite">
                                                        
                                                                </div>
                        
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Name</label>
                                                                </div>
                        
                                                                <div class="col-lg-2">
                                                                    <input type="email"  formControlName="name" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter Name">
                                                        
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                        
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Description</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email"  formControlName="description" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter Description">
                                                        
                                                                </div>
                        
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">MapKey</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email"  formControlName="mapKey" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter MapKey">
                                                        
                                                                </div>
                        
                                                            
                                                            </div>
                                                        </div>
                        
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Purpose</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" optionlabel="label" 
                                                                    formControlName="purpose" 
                                                                    placeholder="Select Purpose">
                        
                                                                    </p-dropdown>
                                                                </div>
                        
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Sequence Num</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email"  formControlName="sequenceNum" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                        
                                                                </div>
                        
                                                            
                                                            </div>
                                                        </div>
                        
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Data Type</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" optionlabel="label" 
                                                                    formControlName="dataType" 
                                                                    placeholder="Select Data Type">
                        
                                                                    </p-dropdown>
                                                                </div>
                        
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Decorator</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" optionlabel="label" 
                                                                    formControlName="decorator" 
                                                                    placeholder="Select Decorator">
                        
                                                                    </p-dropdown>
                                                                </div>
                        
                                                            
                                                            </div>
                                                        </div>
                        
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Template</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" optionlabel="label" 
                                                                    formControlName="template" 
                                                                    placeholder="Select Template">
                        
                                                                    </p-dropdown>
                                                                </div>
                        
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Status	
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" optionlabel="label" 
                                                                    formControlName="status" 
                                                                    placeholder="Select Status">
                        
                                                                    </p-dropdown>
                                                                </div>
                        
                                                            
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Is Public</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" optionlabel="label" 
                                                                    formControlName="isPublic" 
                                                                    placeholder="Select Is Public">
                        
                                                                    </p-dropdown>
                                                                </div>
                        
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Url	
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email"  formControlName="url" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter Url">
                                                        
                                                                </div>
                        
                                                            
                                                            </div>
                                                        </div> -->



                                                </div>
                                            </form>
                                            <div class="col-lg-10 main-submit-button"
                                                style="margin-left: -9% !important;">
                                                <button type="submit"
                                                    class="btn btn-secondary submit-btn">Continue</button>&nbsp;
                                                
                                            </div>
                                        </div>



                                    </div>

                                    <div *ngIf="activeCategory==6">
                                        <div class="w3-card-4 classCardView">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                SEO
                                                <!-- <button type="button" class="btn btn-outline-secondary active"routerLinkActive="active"
                                                    data-target="#createSurveyPopup" data-toggle="modal">WebSite CMS Content</button>
                                                -->
                                            </div>
                                            <form class="w-100" [formGroup]="seoForm">

                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">
                                                                    Catalog</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label"
                                                                    [options]="catalogArray" formControlName="catalog"
                                                                    placeholder="Select Catalog">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">
                                                                    Generate Type</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label"
                                                                    [options]="generalTypesArray"
                                                                    formControlName="GenerateType"
                                                                    placeholder="Select Generate Type">
                                                                </p-dropdown>
                                                            </div>




                                                        </div>
                                                    </div>


                                                </div>
                                            </form>

                                            <div class="col-lg-12 col-12 main-submit-button "
                                                style="margin-left: -35%;">
                                                <button _ngcontent-cta-c128="" type="submit"
                                                    class="btn btn-secondary submit-btn">Submit</button>&nbsp;
                                            
                                            </div>
                                            <br>

                                        </div>


                                    </div>

                                    <div *ngIf="activeCategory==7">



                                        <div class="w3-card-4 classCardView mt-2 w-100">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                Contact Lists View

                                                <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                    style="margin-left:73%!important;" (click)="resetContact()"
                                                    data-toggle="modal" data-target="#createContanctListPopup">Create
                                                   New Contact List


                                                </button>

                                            </div>



                                            <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">

                                                        <div class="card own-account-table">
                                                            <p-table [value]="webSiteContactList" [paginator]="true"
                                                                [rows]="rows" scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>

                                                                                Contact List Name
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                From Date
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Through Date
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Action
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span
                                                                                     class="account-button"  >
                                                                                    {{product.contactListData[0].contactListId}}
                                                                                </span>

                                                                            </div>
                                                                        </td>

                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.fromDate}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.thruDate}}
                                                                        </td>

                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            <button type="submit"
                                                                                class="btn btn-secondary submit-btn ml-2"
                                                                                data-toggle="modal"
                                                                                data-target="#createContanctListPopup"
                                                                                (click)="updateContact(product)">Update</button>
                                                                            <button type="submit"
                                                                                class="btn btn-secondary submit-btn ml-2"
                                                                                (click)="deleteWebSiteContactList(product)">Delete</button>

                                                                        </td>

                                                                    </tr>
                                                                </ng-template>

                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>

                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div *ngIf="activeCategory==8">
                                        <div class="w3-card-4 classCardView mt-2 w-100">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                Web Analytics


                                                <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                    style="margin-left:68%!important;" (click)="resetAnalytics()"
                                                    data-toggle="modal" data-target="#createWebAnalyticsPopup">Create
                                                   New Web Analytics Configuration



                                                </button>

                                            </div>



                                            <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">

                                                        <div class="card own-account-table">
                                                            <p-table [value]="webAnalytics" [paginator]="true"
                                                                [rows]="rows" scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>

                                                                                Type
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Web Analytics Code
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>


                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Action

                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span
                                                                                     class="account-button"  >
                                                                                    {{product.webAnalyticsTypeId}}
                                                                                </span>

                                                                            </div>
                                                                        </td>

                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.webAnalyticsCode}}
                                                                        </td>

                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            <button type="submit" class="btn btn-secondary
                                                                                 submit-btn ml-2"
                                                                                (click)="updateWebAnalytics(product)"
                                                                                data-toggle="modal"
                                                                                data-target="#createWebAnalyticsPopup">Update</button>
                                                                            <button type="submit" class="btn btn-secondary
                                                                                 submit-btn ml-2"
                                                                                (click)="deleteWebAnalyticsConfig(product)">Remove</button>
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>

                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>

                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>





                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="createWebSitePopup" tabindex="-1" role="dialog" aria-labelledby="createWebSitePopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create WebSite</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create WebSite</a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createWebSiteForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Web Site ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="websiteID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter WebSite ID">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Site Name</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="siteName"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Site Name">


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Http Host</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="httpHost"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Http Host">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Http Port</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="httpPort"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Http Port">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Https Host</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="httpsHost"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Https Host">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Https Port</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="httpsPort"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Https Port">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Enable Https</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="yesNo" [options]="yesNoArray"
                                                            placeholder="Choose Option">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Webapp Path</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="webAppPath"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Webapp Path">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Standard Content Prefix</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="standardContentPrefix"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Standard Content Prefix">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Secure Content Prefix</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="secureContentPrefix"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Secure Content Prefix">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Domain Cookie</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="domainCookie"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Domain Cookie">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Visual Theme Set</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="VisualSetArray" formControlName="visualThemeSet"
                                                            placeholder="Choose Visual Theme">

                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Product Store ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="ProductStoreIDArray"
                                                            formControlName="productStoreID"
                                                            placeholder="Choose Product Store ID">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Allow Product Store
                                                            Change</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="yesNoArray"
                                                            formControlName="allowProductStoreChange"
                                                            placeholder="Choose Product Store">

                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Hosted Path Alias</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="hostedPathAlias"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Hosted Path Alias">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Is Default</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="yesNoArray" formControlName="isDefault"
                                                            placeholder="Choose Is Default">

                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Display Maintenance Page</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="yesNoArray"
                                                            formControlName="displayMaintenancPage"
                                                            placeholder="Choose Display Maintenance Page">

                                                        </p-dropdown>
                                                    </div>




                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="createWebSite()">Create</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="updateWebSitePopup" tabindex="-1" role="dialog" aria-labelledby="updateWebSitePopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update WebSite</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Update WebSite</a>
                                </li>
                            </ul>
                        </div>

                        <div class="mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateWebSiteForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Web Site ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="websiteID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter WebSite ID">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Site Name</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="siteName"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Site Name">


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Http Host</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="httpHost"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Http Host">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Http Port</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="httpPort"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Http Port">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Https Host</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="httpsHost"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Https Host">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Https Port</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="httpsPort"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Https Port">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Enable Https</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="yesNo" [options]="yesNoArray"
                                                            placeholder="Choose Option">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Webapp Path</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="webAppPath"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Webapp Path">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Standard Content Prefix</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="standardContentPrefix"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Standard Content Prefix">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Secure Content Prefix</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="secureContentPrefix"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Secure Content Prefix">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Domain Cookie</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="domainCookie"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Domain Cookie">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Visual Theme Set</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="VisualSetArray" formControlName="visualThemeSet"
                                                            placeholder="Choose Visual Theme">

                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                             

                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Allow Product Store
                                                            Change</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="yesNoArray"
                                                            formControlName="allowProductStoreChange"
                                                            placeholder="Choose Product Store">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Product Store ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        [options]="ProductStoreListArray"  formControlName="productStoreID" 
                                                        placeholder="Choose Product Store ID">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 form-group ">
                                                        <button type="submit" class="btn btn-secondary submit-btn"
                                                      (click)="editProStore()"  *ngIf="showbtn">Edit Product Store Id</button>
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Hosted Path Alias</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="hostedPathAlias"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Hosted Path Alias">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Is Default</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="yesNoArray" formControlName="isDefault"
                                                            placeholder="Choose Is Default">

                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Display Maintenance Page</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="yesNoArray"
                                                            formControlName="displayMaintenancPage"
                                                            placeholder="Choose Display Maintenance Page">

                                                        </p-dropdown>
                                                    </div>




                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="updateWebSite()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createWebSiteContentPopup" tabindex="-1" role="dialog"
    aria-labelledby="createWebSiteContentPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Web Site Content

                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton7>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Web Site Content
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class=" mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createWebSiteContentForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Web Site ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                       {{webSiteId}}

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Content ID <span
                                                                style="color:red">*</span>
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                          [options]="ContentIdArray"  *ngIf="!showContent"  
                                                          formControlName="contentID" placeholder="Select Content ID">

                                                        </p-dropdown>
                                                        <input type="text" *ngIf="showContent" disabled formControlName="contentID"
                                                        class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" >

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Web Site Content Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                       [options]="WebSiteContentArray"   *ngIf="!showContent"   formControlName="webSiteContentTypeID"
                                                            placeholder="Select Web Site Content Type ID">

                                                        </p-dropdown>
                                                        <input type="text" *ngIf="showContent" disabled formControlName="webSiteContentTypeID"
                                                        class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" >
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">From Date
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate"
                                                            class="form-control"  *ngIf="!showContent"  id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Choose Date">
                                                            <input type="text" *ngIf="showContent" disabled formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" >
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Through Date
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" >

                                                    </div>
                                                </div>
                                            </div>




                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit"
                                                    class="btn btn-secondary submit-btn" 
                                                    *ngIf="!showContent" (click)="createWebSiteContent()">Create</button>
                                                <button type="submit" *ngIf="showContent" 
                                                    class="btn btn-secondary submit-btn" (click)="UpdateWebSiteContent()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createContanctListPopup" tabindex="-1" role="dialog"
    aria-labelledby="createContanctListPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Contact List


                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Contact List

                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createContactListForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Web Site ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" disabled formControlName="webSiteID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Web Site ID">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Site Name
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" disabled formControlName="siteName"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Site Name">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Contact List ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            *ngIf="!contactShow" formControlName="contactListID"
                                                            [options]="contactWebsiteArray"
                                                            placeholder="Select Contact List ID">
                                                        </p-dropdown>
                                                        <input type="text" *ngIf="contactShow" disabled
                                                            formControlName="contactListID" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Contact List ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA" *ngIf="contactShow">
                                                        <label for="exampleInputEmail1">Through Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="contactShow">
                                                        <input type="date" formControlName="throughDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Through Date">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="!contactShow"
                                                    (click)="createWebSiteContact()">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="contactShow"
                                                    (click)="updateWebSiteContactList()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createWebAnalyticsPopup" tabindex="-1" role="dialog"
    aria-labelledby="createWebAnalyticsPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Web Analytics


                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Web Analytics

                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="webAnalyticsConfigurationForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Web Analytics Types</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            *ngIf="!showAnalytics" formControlName="webAnalyticsTypes"
                                                            [options]="WebAnalyticsTypesArray"
                                                            placeholder="Select Web Analytics Types">

                                                        </p-dropdown>
                                                        <input type="email" disabled *ngIf="showAnalytics"
                                                            formControlName="webAnalyticsTypes" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Web Analytics Types">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Web Analytics Code
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="webAnalyticsCode"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Web Analytics Code">


                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="createWebAnalyticsConfig()"
                                                    *ngIf="!showAnalytics">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="updateWebAnalyticsConfig()"
                                                    *ngIf="showAnalytics">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createWebistePartiesPopup" tabindex="-1" role="dialog"
    aria-labelledby="createWebistePartiesPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create WebSite Parties


                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create WebSite Parties

                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class=" mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createWebSitePartiesForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Party ID<span style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" *ngIf="!showParty" optionlabel="label"
                                                            formControlName="partyID" [options]="partyIdArray"
                                                            placeholder="Select Party ID">

                                                        </p-dropdown>
                                                        <input type="text" disabled *ngIf="showParty"
                                                            formControlName="partyID" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter party ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Role Type ID
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="roleTypesArray" formControlName="roleTypeID"
                                                            *ngIf="!showParty" placeholder="Select Role Type ID">

                                                        </p-dropdown>
                                                        <input type="text" disabled *ngIf="showParty"
                                                            formControlName="roleTypeID" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter  Role Type ID">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" >

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                            Sequence Num </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="sequenceNum"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Sequence Num">

                                                    </div>



                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            From Date </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" *ngIf="!showParty"  placeholder="Enter From Date">

                                                        <input type="text" disabled *ngIf="showParty" formControlName="fromDate" 
                                                            class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter From Date"> 

                                                    </div>





                                                </div>
                                            </div>



                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="!showParty" (click)=" createWebSiteRole()">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="showParty" (click)=" updateWebSiteRole()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createNewPathAlisPopup" tabindex="-1" role="dialog" aria-labelledby="createNewPathAlisPopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Web Site Path Alias
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton6>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Web Site Path Alias

                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class=" mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createWebSitePathAliasForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Web Site ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        {{this.webSiteId}}
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Path Alias<span
                                                                style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="pathAlias"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Path Alias">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            From Date </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate"
                                                            class="form-control" *ngIf="!showPath"  id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" >
                                                            <input type="text" *ngIf="showPath" disabled formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" >
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                            Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" >
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Alias To</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="aliasTo"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Alias To">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                            Content ID </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="ContentIdArray" formControlName="contentID"
                                                            placeholder="Enter Content ID">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Map Key</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="mapKey"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Map Key">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="!showPath" (click)="createWebSitePathAlias()">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="showPath" (click)="updateWebSitePathAlias()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>