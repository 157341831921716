<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">

            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Notes</span>
                <span>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2"
                        (click)="toDashboard()">Home</button>
                    <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">

                            <li [ngClass]="activeCategories==8?'active':''" (click)="toLead()"><a>
                                Leads</a></li>
                        <li [ngClass]="activeCategories==2?'active':''" (click)="toContact()"><a>
                                Contact</a></li>
                        <li [ngClass]="activeCategories==1?'active':''" (click)="toAccount()"><a>
                                Account</a></li>
                        <li [ngClass]="activeCategories==3?'active':''" (click)="toOpportunity()"><a>
                                Opportunity</a></li>
                        <li [ngClass]="activeCategories==9?'active':''" (click)="toTasks()"><a>
                                Tasks</a></li>
                        <li [ngClass]="activeCategories==7?'active':''" (click)="toCases()"><a>
                                Cases</a></li>



                        <li [ngClass]="activeCategories==4?'active':''" (click)="toActivities()"><a>
                                Activities Campaign</a></li>

                        <li [ngClass]="activeCategories==10?'active':''" (click)="toForecast()"><a>
                                Forecast </a></li>
                        <li [ngClass]="activeCategories==11?'active':''" (click)="toDocuments()"><a>
                                Documents </a></li>
                        <li [ngClass]="activeCategories==12?'active':''" (click)="toNotes()"><a>
                                Notes </a></li>
                        <li [ngClass]="activeCategories==13?'active':''" (click)="toCalendar()"><a>
                                Calendar </a></li>
                                <li [ngClass]="activeCategories==14?'active':''" (click)="toEmails()"><a>
                                    Emails </a></li>

                        <li [ngClass]="activeCategories==5?'active':''" (click)="toQuotes()"><a>
                                Quotes </a></li>

                        <li [ngClass]="activeCategories==6?'active':''" (click)="toSalesOrder()"><a>
                                Sales order</a></li> 

                        </ul>
                    </div>

                    <div class="">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="w3-card-4 classCardView  mt-2">
                                        <div class="header-tabs pl-2" >

                                            <h4 class=" common-styling hCard">
                                                Notes
                                            </h4>
                                            <span class="edit-buttons hCreate" data-toggle="modal" data-target="#templateModal">Create New Notes</span>
                                        </div>
                                        <div class="panel-body allUserInfoDetail">
                                            <div
                                            class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="noteList" [paginator]="true"
                                                            [rows]="rows" scrollWidth="100%"
                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                            [totalRecords]="total" [scrollable]="true"
                                                            (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                        pSortableColumn="code">
                                                                        <div class="checkbox-align"
                                                                            style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox"
                                                                                binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            Note Id
                                                                            <p-sortIcon field="code">
                                                                            </p-sortIcon>
                                                                        </div>

                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;"> Note
                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;"> Party Id
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="price">
                                                                        <div style="color: white;">Action
                                                                        </div>
                                                                    </th>

                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'190px'}"
                                                                       >
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox"
                                                                                binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                            </p-checkbox>
                                                                            <span class="account-button">
                                                                                {{product.noteId}}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.note}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.partyId}}
                                                                    </td>

                                                                   
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        <div>
                                                                            <button _ngcontent-cta-c128=""
                                                                            data-toggle="modal" data-target="#templateModal"
                                                                            type="submit"
                                                                                class="btn btn-secondary submit-btn mr-2"
                                                                           (click)="editNotes(product)"   >Update</button>

                                                                 
                                                                            <button _ngcontent-cta-c128=""
                                                                            (click)="deleteNotes(product)"   type="submit"
                                                                                class="btn btn-secondary submit-btn"
                                                                              >Delete</button>

                                
                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>          </div>

                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>
<div class="modal fade" id="templateModal" tabindex="-1" role="dialog" aria-labelledby="templateModalTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create New Note</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                <li [ngClass]="activeCategory==1?'active':''" ><a>
                                    Create New Note</a></li>
                            </ul>
                        </div>
                        <div class="panel-group">

                            <div *ngIf="activeCategory==1">

                                <div class="panel-body">
                                    <form [formGroup]="createNotesForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Party Id</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" *ngIf="!show"
                                                        optionlabel="label" [options]="partyIdArray"
                                                        formControlName="partyId"  placeholder="Choose Party Id">
                                                        </p-dropdown>

                                                        <input type="text" class="form-control"  *ngIf="show"
                                                        id="exampleInputEmail1"
                                                         aria-describedby="emailHelp" formControlName="name" 
                                                         placeholder=" ">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Note</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <textarea id="w3review" style="height: 100%!important;padding-left:12% !important;"
                                                         placeholder="Enter Note" 
                                                          formControlName="note" rows="10" cols="40">
                                </textarea>
                                                    </div>


                                                </div>
                                            </div>


                                        </div>

                                        <div class="proper">
                                            <div class="col-lg-12 col-12 main-submit-button"  style="margin-left: -4% !important;">
                                                <button type="submit" class="btn btn-secondary submit-btn mr-2" *ngIf="!show" (click)="createNote()">Create</button>
                                                <button type="submit" class="btn btn-danger " *ngIf="show" (click)="UpdateNote()">Update</button>
                                            </div>
                                            
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>