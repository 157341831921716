import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';

import { Location } from '@angular/common';
@Component({
  selector: 'app-fiscal-year-import',
  templateUrl: './fiscal-year-import.component.html',
  styleUrls: ['./fiscal-year-import.component.css']
})
export class FiscalYearImportComponent implements OnInit {

  activeCategory=1;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  userLoginIdArray:any[]=[];
  stateArray: any[]=[];
  state: any;
  roleTypeId: any;
  roleTypeIdArray: any[]=[];
  userLogin: any;
  findPartyArray: any[]=[];
  identification: any;
  identificationArray: any[]=[];
  fileData: File;
  fiscalForm: FormGroup;
  fiscalMonth: any[]=[];
  currencyArray: any[] = [];
  constructor(readonly router: Router,
    readonly _FormBuilder: FormBuilder,
private _location: Location,
readonly spinner:NgxSpinnerService,
    readonly accountsService: AccountsService,
    readonly toastr:ToastrService,) { }

  ngOnInit(): void {
    this.fiscalForm = this._FormBuilder.group({
      fiscalYear: ['',Validators.required],
      firstFiscalMonth: ['',Validators.required],
      numerationPrefix: [''],
      baseCurrency: ['',Validators.required],
      });
      this.getFiscalMonthList();
      this.getCurrencyRate();
  }
  homeButton(){
    this.router.navigate(['/users/company-information-import'])

  }
  cancelSubmit(){
    this._location.back();
  }
  

  getFiscalMonthList(){
    this.spinner.show();
    
    this.accountsService.getFiscalMonthsDetailsList().subscribe((res:any)=>{
      let _fiscalMonth = res.data;
      Object.entries(_fiscalMonth.Data).forEach(([key, value]) => this.fiscalMonth.push( {label:value, value:value} ) );
      this.spinner.hide();
    })
  }

  getCurrencyRate() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })

  }

  saveFiscalYearImportDetail(){
    let fiscalFormData = { ...this.fiscalForm.value };
    this.spinner.show();
    this.accountsService.createFiscalYearDetailsImport(fiscalFormData).subscribe((res:any)=> {  
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.getFiscalMonthList()
        this.spinner.hide();
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      console.warn("error",err);
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    
  }
  goToCompanyInfo(){
    this.router.navigate(['/users/company-information-import']);
  }
  goToEmailConfiguration(){
    this.saveFiscalYearImportDetail()
    this.router.navigate(['/users/issue-created-import']);
  }

}
