import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ProductService } from '../new-products/product.service';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { SortEvent } from 'primeng/api';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import Swal from 'sweetalert2'
import { CategoryService } from '../new-catagory/category.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { MainCatalogService } from '../main-catalog-mgmt/catalog.service';
import { CustomerAgreementsService } from 'src/app/accounting/accounting-ar/customer-agreements/customer-agreements.service';
import { NewWarehouseShipmentService } from 'src/app/warehouse/new-warehouse-shipment/new-warehouse-shipment.service';
import { ThirdPartyDraggable } from '@fullcalendar/interaction';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-product-summary',
  templateUrl: './detail-product-summary.component.html',
  styleUrls: ['./detail-product-summary.component.css']
})
export class DetailProductSummaryComponent implements OnInit {
  faEdit = faEdit;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  @ViewChild('closebutton9') closebutton9;
  @ViewChild('closebutton10') closebutton10;
  @ViewChild('closebutton11') closebutton11;
  @ViewChild('closebutton12') closebutton12;
  @ViewChild('closebutton13') closebutton13;
  @ViewChild('closebutton14') closebutton14;
  @ViewChild('closebutton15') closebutton15;
  @ViewChild('closebutton16') closebutton16;
  @ViewChild('closebutton17') closebutton17;
  @ViewChild('closebutton18') closebutton18;
  @ViewChild('closebutton19') closebutton19;
  @ViewChild('closebutton20') closebutton20;
  @ViewChild('closebutton21') closebutton21;
  @ViewChild('closebutton22') closebutton22;
  @ViewChild('closebutton23') closebutton23;
  @ViewChild('closebutton24') closebutton24;
  @ViewChild('closebutton25') closebutton25;
  @ViewChild('closebutton26') closebutton26;
  @ViewChild('closebutton27') closebutton27;
  @ViewChild('closebutton28') closebutton28;
  @ViewChild('closebutton29') closebutton29;
  @ViewChild('closebutton30') closebutton30;
  @ViewChild('closebutton31') closebutton31;
  @ViewChild('closebutton32') closebutton32;
  @ViewChild('closebutton33') closebutton33;
  @ViewChild('closebutton34') closebutton34;
  @ViewChild('closebutton35') closebutton35;
  @ViewChild('closebutton36') closebutton36;
  @ViewChild('closebutton37') closebutton37;
  @ViewChild('closebutton38') closebutton38;
  @ViewChild('closebutton39') closebutton39;
  @ViewChild('closebutton40') closebutton40;
  @ViewChild('closebutton41') closebutton41;
  @ViewChild('closebutton42') closebutton42;
  @ViewChild('closebutton43') closebutton43;
  @ViewChild('closebutton44') closebutton44;
  @ViewChild('closebutton45') closebutton45;
  @ViewChild('closebutton46') closebutton46;
  @ViewChild('closebutton47') closebutton47;
  @ViewChild('closebutton48') closebutton48;
  @ViewChild('closebutton49') closebutton49;
  @ViewChild('closebutton50') closebutton50;
  @ViewChild('closebutton51') closebutton51;
  @ViewChild('closebutton52') closebutton52;
  @ViewChild('closebutton53') closebutton53;
  @ViewChild('closebutton54') closebutton54;
  @ViewChild('closebutton55') closebutton55;
  @ViewChild('closebutton56') closebutton56;
  @ViewChild('closebutton57') closebutton57;
  @ViewChild('closebutton58') closebutton58;
  @ViewChild('closebutton59') closebutton59;
  @ViewChild('closebutton60') closebutton60;
  @ViewChild('closebutton61') closebutton61;
  @ViewChild('closebutton62') closebutton62;
  @ViewChild('closebutton63') closebutton63;
  @ViewChild('closebutton64') closebutton64;






  faTrash = faTrash;
  product: any;
  defaultShipmentBoxTypeIDArray: any[]=[];
  quantityUomIDArray: any[]=[];
  weightUomIDArray: any[]=[];
  measuresUOMIDArray: any[]=[];
  productTypeIdArray: any[]=[];
  virtualVariantMethodArray: any[]=[];
  requirementMethodEnumIDArray: any[]=[];
  inventoryItemTypeIDArray: any[]=[];
  ratingTypeEnumArray: any[]=[];
  amountUomTypeIDArray: any[]=[];
  products3: any;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  rows = 50;
  search = ""
  readonly employessSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  show: boolean;
  SupplierByProductId: any;
  productPriceList: any[];
  contentTypeId: string;
  contentTypeIds: any[];
  contentToProductForm: FormGroup;
  contentIds: any[];
  productContents: any[];
  simpleFieldsForm: FormGroup;
  contentImage: FormData;
  imageType: string;
  additionalImage: FormData;
  inventorySummary: any[];
  purchaseOrders: any[];
  inventoryItems: any[];
  active: number;
  inventoryActive: number;
  userRoles: any[];
  useTimeIds: any[];
  productArray: any[]=[];
  url: string;
  activeCategory = 2;
  productMenu: {
    productType: any[],
    virtualVariantMethod: any[],
    requirementMethodEnumID: any[],
    inventoryItemTypeID: any[],
    ratingTypeEnum: any[],
    amountUomTypeID: any[],
    measuresUOMID: any[],
    weightUomID: any[],
    quantityUomID: any[],
    defaultShipmentBoxTypeID: any[],
  };
  createEditProductForm: FormGroup;
  interrogativeArray: { label: string, value: string }[];
  commandingArray: { label: string, value: string }[];
  ratingArray: { label: string, value: string }[];
  editMode: boolean;
 
  categories: any[];
  showSupplierModal: boolean = false;
  showPriceModal: boolean = false;
  showVariantModal: boolean = false;
  CreateProductGeo: any;
  addGeo: FormGroup;
  ProductGeoEnumId: any;
  ProductGeoEnumIdArray: any[]=[];
  VatTaxAuthGeoId: any;
  VatTaxAuthGeoIdArray: any[]=[];
  updateGeoProduct: any;
  addGeo1: FormGroup;
  CreateProductGeoById: any;
  addGoodID: FormGroup;
  GoodIdentificationById: any;
  GoodIdentificationTypeId: any;
  GoodIdentificationTypeIdArray: any[]=[];
  patchupdateGoodIdenti: any;
  addGoodID1: FormGroup;
  supplierById: any;
  addCategory: FormGroup;
  AddProductToCategoryById: any;
  fromDateCate: any;
  throughDateCate: string;
  ProductCategoryForStore: any;
  ProductCategoryForStoreArray: any[]=[];
  updateProductToCate: any;
  fromDateCatePatch: string;
  throughDateCatePatch: string;
  fromDateCatePatchDel: string;
  addKeyword: FormGroup;
  productsKeywordById: any;
  getkeywordTypeIds: any;
  getkeywordTypeIdsArray: any[]=[];
  getstatusIds: any;
  getstatusIdsArray: any[]=[];
  updateProductKeywPatc: any;
  addKeyword1: FormGroup;
  addAssoc: FormGroup;
  ProductAssocById: any;
  CatalogProductId: any;
  CatalogProductIdArray: any[]=[];
  ProductAssocType: any;
  ProductAssocTypeArray: any[]=[];
  updateCataGoodIdentificationPatc: any;
  updateProductAssocPatc: any;
  addAssoc1: FormGroup;
  addAttr: FormGroup;
  ProductAttributeById: any;
  updateProductAttributePatc: any;
  addAttr1: FormGroup;
  addFacility: FormGroup;
  ProductFacilityById: any;
  ProductFacilityId: any;
  ProductFacilityIdArray: any[]=[];
  updateProductFacilityPatc: any;
  addFacility1: FormGroup;
  addLocation: FormGroup;
  ProductFacilityLocationById: any;
  LocationSeqId: any;
  LocationSeqIdArray: any[]=[];
  updateProductFacilityLocationPatc: any;
  addLocation1: FormGroup;
  addGL: FormGroup;
  addPaymentType: FormGroup;
  ProductGlAccountDetail: any;
  AccountType: any;
  AccountTypeArray: any[]=[];
  OrganizationId: any;
  OrganizationIdArray: any[]=[];
  GLAccountId: any;
  GLAccountIdArray: any[]=[];
  addGL1: FormGroup;
  updateGlProductAccountPatc: any;
  ProductPaymentMethodAll: any;
  getpaymentMethodType: any;
  getpaymentMethodTypeArray: any[]=[];
  productPricePurposeId: any;
  productPricePurposeIdArray: any[]=[];
  addPaymentType1: FormGroup;
  updateProductPaymentMethodTypePatc: any;
  addMaintenance: FormGroup;
  addMeter: FormGroup;
  EditProductMetersByID: any;
  ProductMeterTypeId: any;
  ProductMeterTypeIdArray: any[]=[];
  addMeter1: FormGroup;
  updateProductMeterPatc: any;
  currencyArray: any[]=[];
  VendorProductByID: any;
  ProductStoreGroupID: any;
  ProductStoreGroupIDArray: any[]=[];
  addVendor: FormGroup;
  addSubsResource: FormGroup;
  addPublish: FormGroup;
  addConfig: FormGroup;
  addWE: FormGroup;
  addAssocParty: FormGroup;
  addComm: FormGroup;
  addGroupOrder: FormGroup;
  personId: any;
  partyIdArray: any[]=[];
  PartyToProductById: any;
  RoleTypeId: any;
  RoleTypeIdArray: any[]=[];
  addAssocParty1: FormGroup;
  updatePartyToProductPtac: any;
  EditProductWorkEfforts: any;
  ProWorkEffortId: any;
  ProWorkEffortIdArray: any[]=[];
  WorkEffortGoodStdTypeIdDrop: any;
  WorkEffortGoodStdTypeIdDropArray: any[]=[];
  solicitationArray: { label: string; value: string; }[];
  addWE1: FormGroup;
  updateProWorkEffortGoodStandardPatc: any;
  ProductGroupOrderById: any;
  addGroupOrder1: FormGroup;
  updateProductGroupOrderPatc: any;
  CreateCommunicationEventById: any;
  CatalogCommunicationEventId: any;
  CatalogCommunicationEventIdArray: any[]=[];
  CataCommEventStatus: any;
  CataCommEventStatusArray: any[]=[];
  CommTypeID: any;
  CommTypeIDArray: any[]=[];
  ContactMechTypeID: any;
  ContactMechTypeIDArray: any[]=[];
  FromContactMechList: any;
  FromContactMechListArray: any[]=[];
  RoleTypeIdType: any;
  RoleTypeIdTypeArray: any[]=[];
  CatalogContentMimeTypeId: any;
  CatalogContentMimeTypeIdArray: any[]=[];
  ContactListId: any;
  ContactListIdArray: any[]=[];
  ReasonEnumIds: any;
  ReasonEnumIdsArray: any[]=[];
  ProductOrderById: any;
  SubscriptionProductListBYId: any;
  UseTimeUomId: any;
  UseTimeUomIdArray: any[]=[];
  updateResourcePatch: any;
  addSubsResource1: FormGroup;
  subscriptionResourceId: any;
  subscriptionResourceIdArray: any;
  catalogSubscriptionArray: { label: string; value: string; }[];
  WorkEffortNameValue: any;
  WorkEffortNameValueArray: any[]=[];
  ProductMeterType: any;
  ProductMeterTypeArray: any[]=[];
  ProductMaintType: any;
  ProductMaintTypeArray: any[]=[];
  addMaintenance1: FormGroup;
  updateProductDataValuePatch: any;
  ProductMaintById: any;
  CatalogProductMaintById: any;
  EditProductMaints: any;
  SubscriptionResourceIdNew: any;
  SubscriptionResourceIdNewArray: any[]=[];
  virtualProduct: FormGroup;
  addCost: FormGroup;
  downloading: boolean;
  products: any;
  productForm: FormGroup;
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  addAgreement: FormGroup;
  addSalesAgreement: FormGroup;
  getcommissionAgreements: any;
  getgetSalesAgreements: any;
  getSalesAgreementsById: any;
  productAgreementsById: any;
  SupplierProductById: any;
  productList: any;
  productListArray: any[]=[];
  PersonsAndPartyGroupsListArray: any[]=[];
  AgreementTypeListArray: any[]=[];
  agreementDate: string;
  thruDate: string;
  fromDate: string;
  hazmatArray: { label: string; value: string; }[];
  currencyUomIdsArray: any[]=[];
  getAgreementTypeListId: any;
  getAgreementTypeListIdArray: any[]=[];
  getProductId: any;
  getProductIdArray: any[]=[];
  RoutingsBYId: any;
  RoutingsBYIdArray: any[]=[];
  RoutingsById: any;
  ViewProductComponentsById: any;
  ViewProductParentById: any;
  CostComponentByProductId: any;
  ProductCostComponentCalcById: any;
  ComponentTypeIdFirst: any;
  ComponentTypeIdFirstArray: any[]=[];
  ComponentTypeIdComponentCalc: any;
  ComponentTypeIdComponentCalcArray: any[]=[];
  getProductFeatureId: any;
  getProductFeatureIdArray: any[]=[];
  CatalogGeoId: any;
  CatalogGeoIdArray: any[]=[];
  WorkEffortIds: any;
  WorkEffortIdsArray: any[]=[];
  FixedAssetIds: any;
  FixedAssetIdsArray: any[]=[];
  CostComponentCalcId: any;
  CostComponentCalcIdArray: any[]=[];
  addCostCalc: FormGroup;
  addAutoCalc: FormGroup;
  TypePrefixArray: { label: string; value: string; }[];
  addRental: FormGroup;
  addRental1: FormGroup;
  FixedAssetProductById: any;
  FixedAssetId: any;
  FixedAssetIdArray: any[]=[];
  assetProductTypeArray: { label: string; value: string; }[];
  updateFixedAssetProductPat: any;
  EditProductQuickAdmin: any;
  SubscriptionResource: any;
  SubscriptionResourceArray: any[]=[];
  fromDateCatePatch1: string;
  throughDateCatePatch1: string;
  urlid: any;
  productAssUsage: FormGroup;
  KeywordTypeIdsKeyword: any;
  KeywordTypeIdsTag: any;
  configRedirect: any;
  ProductConfigsById: any;
  ConfigItemId: any;
  ConfigItemIdArray: any[]=[];
  configTypeIDArray: { label: string; value: string; }[];
  addConfig1: FormGroup;
  updateProductConfigPat: any;
  ProductAssocByIdSec: any;
  urlidcheck: any;
  addFeatureGroup: FormGroup;
  addFeatureTypeID: FormGroup;
  addFeatureID: FormGroup;
  addFeatureInteraction: FormGroup;
  addProductFeatureAttr: FormGroup;
  ProductFeatureIactn: any;
  addProductFeatureAttr1: FormGroup;
  updateProductFeatureApplAttrPat: any;
  ProductFeatures: any;
  ProductFeaturesArray: any[]=[];
  InteractionArray: { label: string; value: string; }[];
  EditProductFeaturesById: any;
  EditProductFeatures: any;
  ProductFeatureId: any;
  ProductFeatureIdArray: any[]=[];
  ProductFeatureCategoryId: any;
  ProductFeatureCategoryIdArray: any[]=[];
  getproductFeatureGroupId: any;
  getproductFeatureGroupIdArray: any[]=[];
  getproductFeatureApplTypeId: any;
  getproductFeatureApplTypeIdArray: any[]=[];
  getproductFeatureTypeIds: any;
  getproductFeatureTypeIdsArray: any[]=[];
  addFeatureID1: FormGroup;
  updateFeatureToProductApplicationPat: any;
  ProductFeatureTypeID: any;
  ProductFeatureTypeIDArray: any[]=[];
  productPriceForm: FormGroup;
  priceTypesArray: any[]=[];
  purposesArray: any[]=[];
  taxAuthPartyIdsArray: any[]=[];
  itemUomIdsArray: any[]=[];
  taxAuthGeoIdsArray: any[]=[];
  customPriceArray: any[]=[];
  productStoresArray: any[]=[];
  getEditProductPrices: any;
  customPriceCalcServiceId: any;
  customPriceCalcServiceIdArray: any[]=[];
  productPriceForm1: FormGroup;
  updateProductPricePat: any;
  editProductQuickAdmin: FormGroup;
  updateProductQuickAdminNamePat: any;
  addFeatureType: FormGroup;
  EditProductQuickAdminFeatureType: any;
  EditProductQuickAdminFeatureTypeArray: any[]=[];
  editCategories: FormGroup;
  EditProductQuickAdminCategories: any;
  EditProductQuickAdminCategoriesArray: any[]=[];
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  Products: any;
  ProductsArray: any[]=[];
  roleTypeId: string;
  supplierArray: any[]=[];
  supplierPrefOrder: any;
  supplierPrefOrderArray: any[]=[];
  drop: { label: string; value: string; }[];
  createSupplierForm: FormGroup;
  actualfromdate: string;
  actualTodate: string;
  createSupplierForm1: FormGroup;
  onUpdatePat: any;
  CategoryById: any;
  imageForm: FormGroup;
  StandardFeaturesId: any;
  addFeatureTypeId: FormGroup;
  StandardFeaturesIdArray: any[]=[];
  todayDate: number;
  StandardFeaturesSecondId: any;
  shippingDimen_Weights: FormGroup;
  EditProductTagAll: any;
  editProductTag: FormGroup;
  updateProductTagPat: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(readonly _ActivatedRoute: ActivatedRoute,
    readonly _ProductService: ProductService,
    readonly spinner: NgxSpinnerService,
private _location: Location,
readonly toastr: ToastrService,
    readonly router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _CategoryService: CategoryService,
    readonly accountsService: AccountsService,
    readonly datePipe: DatePipe,
    readonly myContactsService: MyContactsService,
    readonly addSkillService: AddSkillService,
    readonly _MainCatalogService: MainCatalogService,
    readonly CustomerAgreementsService: CustomerAgreementsService,
    readonly NewWarehouseShipmentService: NewWarehouseShipmentService,


  ) {
    this.solicitationArray = [
      {
        label: 'Cancelled',
        value: 'WEGS_CANCELLED'
      },
      {
        label: 'Completed',
        value: 'WEGS_COMPLETED'
      },
      {
        label: 'Created',
        value: 'WEGS_CREATED'
      }

    ];
    this.catalogSubscriptionArray = [{
      label: 'Y',
      value: 'Y'
    },
    {
      label: 'N',
      value: 'N'
    }];
    this.InteractionArray = [{
      label: 'Dependent Feature',
      value: 'FEATURE_IACTN_DEPEND'
    },
    {
      label: 'Incompatible Feature',
      value: 'FEATURE_IACTN_INCOMP'
    }];
    this.configTypeIDArray = [{
      label: 'Standard',
      value: 'STANDARD'
    },
    {
      label: 'Question',
      value: 'QUESTION'
    }];
    this.TypePrefixArray = [{
      label: 'Estimated Standard Costs',
      value: 'EST_STD'
    }];
    this.hazmatArray = [{
      label: 'Hazmat',
      value: '1000'
    },
    {
      label: 'Non-Hazmat',
      value: '1001'
    }];
    this.assetProductTypeArray = [{
      label: 'Sale of Asset',
      value: 'FAPT_SALE'
    },
    {
      label: 'Use of Asset',
      value: 'FAPT_USE'
    }];
    this.opportunityIdArray = [
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Begins With',
        text: 'Begins With'
      },
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      }
    ];
    this.drop = [{
      label: 'Y',
      value: 'Y'
    },
    {
      label: 'N',
      value: 'N'
    }]
    this.editProductTag =this._FormBuilder.group({
      productId:[{value: '', disabled: true}],
      keyword:[{value: '', disabled: true}],
      statusId:['']
    });
    this.shippingDimen_Weights = this._FormBuilder.group({
      productHeight:[''],
      productWidth:[''],
      productDepth:[''],
      weight:['']
    });
    this.imageForm = this._FormBuilder.group({
      productVariant: ['']
    });
    this.createSupplierForm = this._FormBuilder.group({
      agreementId: [''],
      agreementItemSeqId: [''],
      availableFromDate: ['',[Validators.required]],
      availableThruDate: [''],
      canDropShip: [''],
      currencyUomId: ['',[Validators.required]],
      lastPrice: ['',[Validators.required]],
      minimumOrderQuantity: ['',[Validators.required]],
      orderQtyIncrements: [''],
      partyId: ['',[Validators.required]],
      quantityUomId: [''],
      shippingPrice: [''],
      standardLeadTimeDays: [''],
      supplierPrefOrderId: [''],
      supplierProductId: ['',[Validators.required]],
      supplierProductName: [''],
      supplierRatingTypeId: [''], unitsIncluded: [''], comments: ['']
    });
    this.createSupplierForm1 = this._FormBuilder.group({
      agreementId: [''],
      agreementItemSeqId: [''],
      availableFromDate: ['',[Validators.required]],
      availableThruDate: [''],
      canDropShip: [''],
      currencyUomId: ['',[Validators.required]],
      lastPrice: ['',[Validators.required]],
      minimumOrderQuantity: ['',[Validators.required]],
      orderQtyIncrements: [''],
      partyId: ['',[Validators.required]],
      quantityUomId: [''],
      shippingPrice: [''],
      standardLeadTimeDays: [''],
      supplierPrefOrderId: [''],
      supplierProductId: ['',[Validators.required]],
      supplierProductName: [''],
      supplierRatingTypeId: [''], unitsIncluded: [''], comments: ['']
    });
    this.editCategories =this._FormBuilder.group({
      categories:['']
    });
    this.addFeatureType =this._FormBuilder.group({
      featuretype:['']
    });
    this.addFeatureTypeId =this._FormBuilder.group({
      featuretypeId:['']
    });
    this.editProductQuickAdmin = this._FormBuilder.group({
      productId:[{value: '', disabled: true}],
      productName:['']
    });
    this.productPriceForm = this._FormBuilder.group({
      currencyUomId: [''],
      customPriceCalcService: [''],
      fromDate: ['', [Validators.required]],
      price: ['', [Validators.required]],
      productPricePurposeId: [''],
      productPriceTypeId: [''],
      productStoreGroupId: [''],
      taxAuthGeoId: [''],
      taxAuthPartyId: [''],
      taxInPrice: [''],
      taxPercentage: [''], 
      termUomId: [''],  
      thruDate: ['']
  });
  this.productPriceForm1 = this._FormBuilder.group({
    currencyUomId: [''],
    customPriceCalcService: [''],
    fromDate: ['', [Validators.required]],
    price: ['', [Validators.required]],
    productPricePurposeId: [''],
    productPriceTypeId: [''],
    productStoreGroupId: [''],
    taxAuthGeoId: [''],
    taxAuthPartyId: [''],
    taxInPrice: [''],
    taxPercentage: [''], 
    termUomId: [''],  
    thruDate: ['']
});
    this.addFeatureInteraction = this._FormBuilder.group({
      featureID: [''],
      featureIdTo: [''],
      interactionType: [''],
    });
    this.addProductFeatureAttr = this._FormBuilder.group({
      featureID: [''],
      fromDate: [''],
      attrName: [''],
      attrValue: [''],
    });
    this.addProductFeatureAttr1 = this._FormBuilder.group({
      featureID: [''],
      fromDate: [''],
      attrName: [''],
      attrValue: [''],
    });
    this.addFeatureGroup = this._FormBuilder.group({
      category: [''],
      group: [''],
      appType: [''],
    });
    this.addFeatureTypeID = this._FormBuilder.group({
      featureType: [''],
      idCode: [''],
      appType: [''],
      fromDate: [''],
      throughDate: [''],
      seq: [''],
    });
    this.addFeatureID = this._FormBuilder.group({
      ID: [''],
      appType: [''],
      fromDate: [''],
      throughDate: [''],
      seq: [''],
    });
    this.addFeatureID1 = this._FormBuilder.group({
      ID: [''],
      appType: [''],
      fromDate: [''],
      throughDate: [''],
      seq: [''],
    });
    this.productAssUsage = this._FormBuilder.group({
      MaxpersonsForRental:[''],
      secPricePercentage:[''],
      nthPricePercentage:['']
    })
    this.addRental =this._FormBuilder.group({
      fixedAssId:[''],
      fixedProdId:[''],
      fromDate:[''],
      throughDate:[''],
      cmnt:[''],
      seqnence:[''],
      Quty:[],
      UOM:[]
    })
    this.addRental1 =this._FormBuilder.group({
      fixedAssId:[''],
      fixedProdId:[''],
      fromDate:[''],
      throughDate:[''],
      cmnt:[''],
      seqnence:[''],
      Quty:[],
      UOM:[]
    })
    this.addCostCalc = this._FormBuilder.group({
      typeID: [''],
      fromDate: [''],
      calcID: [''],
      seqNum: [''],
      throughDate: [''],
    });
    this.addAutoCalc = this._FormBuilder.group({
      currency: [''],
      prefix: [''],
    });
    this.addAgreement = this._FormBuilder.group({
      agreementTypeId: ['', [Validators.required]],
      agreementDate: [''],
      description: [''],
      fromDate: [''],
      partyIdFrom: [''],
      partyIdTo: [''],
      productId: [''],
      roleTypeIdFrom: [''],
      roleTypeIdTo: [''],
      textData: [''],
      thruDate: ['']
    });
    this.addSalesAgreement = this._FormBuilder.group({
      partyIDFrom: [''],
      partyIDTo: [''],
      fromDate: [''],
      throughDate: [''],
      desc: [''],
      price: [''],
      currency: [''],
    });
    
    this.productForm = this._FormBuilder.group({
      internalName: [''],
      internalNameSearchType: ['Contains'],
      productId: [''],
      productIdSearchType: ['Contains']
    });
    
    this.addCost = this._FormBuilder.group({
      typeID: [''],
      featureID: [''],
      partyID: [''],
      geoID: [''],
      workEffortID: [''],
      fixedAssetID: [''],
      calcID: [''],
      fromDate: [''],
      throughDate: [''],
      cost: [''],
      currency: [''],
    });
    this.virtualProduct = this._FormBuilder.group({
      variantProId:[''],
      Hazmat:['']
    })
    this.addConfig = this._FormBuilder.group({
      configItem: [''],
      seqNum: [''],
      fromDate: [''],
      desc: [''],
      longDesc: [''],
      configTypeID: [''],
      optionID: [''],
      throughDate: [''],
      isMandatory: [''],
    });
    this.addConfig1 = this._FormBuilder.group({
      configItem: [''],
      seqNum: [''],
      fromDate: [''],
      desc: [''],
      longDesc: [''],
      configTypeID: [''],
      optionID: [''],
      throughDate: [''],
      isMandatory: [''],
    });
    this.addWE = this._FormBuilder.group({
      workEffortID: [''],
      weGoodID: [''],
      fromDate: [''],
      throughDate: [''],
      status: [''],
      estimatedQty: [''],
      estimatedCost: [''],
    });
    this.addWE1 = this._FormBuilder.group({
      workEffortID: [''],
      weGoodID: [''],
      fromDate: [''],
      throughDate: [''],
      status: [''],
      estimatedQty: [''],
      estimatedCost: [''],
    });
    this.addAssocParty = this._FormBuilder.group({
      partyID: [''],
      roleTypeID: [''],
      fromDate: [''],
      throughDate: [''],
      seqNum: [''],
      comments: [''],
    });
    this.addAssocParty1 = this._FormBuilder.group({
      partyID: [''],
      roleTypeID: [''],
      fromDate: [''],
      throughDate: [''],
      seqNum: [''],
      comments: [''],
    });
    this.addComm = this._FormBuilder.group({
      commEventID: [''],
      commTypeID: [''],
      status: [''],
      partyFrom: [''],
      roleTypeID: [''],
      mechTypeID: [''],
      fromMech: [''],
      toMech: [''],
      roleTypeIDFrom: [''],
      roleTypeIDTo: [''],
      contactListID: [''],
      startDate: [''],
      finishDate: [''],
      subject: [''],
      reasonEnumID: [''],
      contentMimeTypeID: [''],
      content: [''],
      note: [''],
      msgID: [''],
    });
    this.addGroupOrder = this._FormBuilder.group({
      dealQty: [''],
      dealStartDate: [''],
      dealEndDate: [''],
    });
    this.addGroupOrder1 = this._FormBuilder.group({
      dealQty: [''],
      dealStartDate: [''],
      dealEndDate: [''],
    });
    this.addSubsResource = this._FormBuilder.group({
      susResourceID: [''],
      fromDate: [''],
      throughDate: [''],
      purchaseFromDate: [''],
      purchaseThroughDate: [''],
      maxLifeTime: [''],
      lifeTimeUomID: [''],
      availTime: [''],
      availUomID: [''],
      countLimit: [''],
      useTime: [''],
      useUomID: [''],
      useRoleTypeID: [''],
      extendPeriod: [''],
      autoExtension: [''],
      measureUnit: [''],
      graceExpiry: [''],
      graceExpiryUomID: [''],
    });
    this.addSubsResource1 = this._FormBuilder.group({
      susResourceID: [''],
      fromDate: [''],
      throughDate: [''],
      purchaseFromDate: [''],
      purchaseThroughDate: [''],
      maxLifeTime: [''],
      lifeTimeUomID: [''],
      availTime: [''],
      availUomID: [''],
      countLimit: [''],
      useTime: [''],
      useUomID: [''],
      useRoleTypeID: [''],
      extendPeriod: [''],
      autoExtension: [''],
      measureUnit: [''],
      graceExpiry: [''],
      graceExpiryUomID: [''],
    });
    this.addPublish = this._FormBuilder.group({
      fromDate: [''],
    });


    this.addVendor = this._FormBuilder.group({
      partyID: [''],
      groupID: [''],
    });
    this.addMaintenance = this._FormBuilder.group({
      typeID: [''],
      name: [''],
      workEffortID: [''],
      intQty: [''],
      intUomID: [''],
      meterTypeID: [''],
      repeatCount: [''],
    });
    this.addMaintenance1 = this._FormBuilder.group({
      typeID: [''],
      name: [''],
      workEffortID: [''],
      intQty: [''],
      intUomID: [''],
      meterTypeID: [''],
      repeatCount: [''],
    });
    this.addMeter1 = this._FormBuilder.group({
      meterTypeID: [''],
      meterUomID: [''],
      meterName: [''],
    });
    this.addMeter = this._FormBuilder.group({
      meterTypeID: [''],
      meterUomID: [''],
      meterName: [''],
    });
    this.addGL1 = this._FormBuilder.group({
      accType: [''],
      organization: [''],
      glAcc: [''],
    });
    this.addGL = this._FormBuilder.group({
      accType: [''],
      organization: [''],
      glAcc: [''],
    });
    this.addPaymentType1 = this._FormBuilder.group({
      paymentType: [''],
      purpose: [''],
      fromDate: [''],
      throughDate: [''],
      seqNum: [''],
    });
    this.addPaymentType = this._FormBuilder.group({
      paymentType: [''],
      purpose: [''],
      fromDate: [''],
      throughDate: [''],
      seqNum: [''],
    });
    this.addLocation1 = this._FormBuilder.group({
      facilityID: [''],
      locSeqID: [''],
      minStock: [''],
      moveQty: [''],
    });
    this.addLocation = this._FormBuilder.group({
      facilityID: [''],
      locSeqID: [''],
      minStock: [''],
      moveQty: [''],
    });
    this.addFacility1 = this._FormBuilder.group({
      facilityID: [''],
      minStock: [''],
      reorderQuantity: [''],
      dayToShip: [''],
      replenishEnumID: [''],
      reqEnumID: [''],
    });
    this.addFacility = this._FormBuilder.group({
      facilityID: [''],
      minStock: [''],
      reorderQuantity: [''],
      dayToShip: [''],
      replenishEnumID: [''],
      reqEnumID: [''],
    });
    this.addAttr1 = this._FormBuilder.group({
      name: [''],
      value: [''],
      type: [''],
      desc: [''],
    });
    this.addAttr = this._FormBuilder.group({
      name: [''],
      value: [''],
      type: [''],
      desc: [''],
    });
    this.addAssoc = this._FormBuilder.group({
      proID: [''],
      proIdTo: [''],
      assocType: [''],
      fromDate: [''],
      throughDate: [''],
      seqNum: [''],
      reason: [''],
      instruction: [''],
      qty: [''],
    });
    this.addAssoc1 = this._FormBuilder.group({
      proID: [''],
      proIdTo: [''],
      assocType: [''],
      fromDate: [''],
      throughDate: [''],
      seqNum: [''],
      reason: [''],
      instruction: [''],
      qty: [''],
    });
    this.addKeyword1 = this._FormBuilder.group({
      keyword: [''],
      typeID: [''],
      relWeight: [''],
      statusID: [''],
    });
    this.addKeyword = this._FormBuilder.group({
      keyword: [''],
      typeID: [''],
      relWeight: [''],
      statusID: [''],
    });
    this.addCategory = this._FormBuilder.group({
      categoryID: [''],
      fromDate: [''],
      throughDate: [''],
      comments: [''],
      seqNum: [''],
      qty: [''],
    });
    this.addGoodID = this._FormBuilder.group({
      idType: [''],
      idValue: [''],
    });
    this.addGoodID1 = this._FormBuilder.group({
      idType: [''],
      idValue: [''],
    });
    this.addGeo = this._FormBuilder.group({
      geoID: [''],
      enumID: [''],
      desc: [''],
    });
    this.addGeo1 = this._FormBuilder.group({
      geoID: [''],
      enumID: [''],
      desc: [''],
    });

    this.url='/facilities/catalog/products/create-supplier'
    this.useTimeIds = [];
    this.userRoles = [];
    this.inventoryActive = 1;
    this.active = 1;
    this.contentImage = new FormData();
    this.imageType = '';
    this.additionalImage = new FormData();
    this.contentTypeId = '';
    this.contentTypeIds = [];
    this.contentToProductForm = this._FormBuilder.group({
      contentId: [''],
      fromDate: ['', [Validators.required]],
      productContentTypeId: ['', [Validators.required]],
      purchaseFromDate: [''],
      purchaseThruDate: [''],
      sequenceNum: [''],
      thruDate: [''],
      useCountLimit: [''],
      useRoleTypeId: [''],
      useTime: [''],
      useTimeUomId: ['']
    });
    this.inventorySummary = [];
    this.purchaseOrders = [];
    this.inventoryItems = [];
    this.contentIds = [];
    this.productContents = [];
    this.simpleFieldsForm = this._FormBuilder.group({
      description: [''],
      detailImageUrl: [''],
      detailScreen: [''],
      largeImageUrl: [''],
      longDescription: [''],
      mediumImageUrl: [''],
      originalImageUrl: [''],
      productName: [''],
      smallImageUrl: ['']
    });
    this.createEditProductForm = this._FormBuilder.group({
      amountUomTypeId: [null], 
      autoCreateKeywords: ['Y'], 
      brandName: [''], 
      chargeShipping: ['N'], 
      comments: [''], 
      defaultShipmentBoxTypeId: [null],
      depthUomId: [null], 
      diameterUomId: [null], 
      heightUomId: [null], 
      inShippingBox: ['N'], 
      includeInPromotions: ['Y'], 
      internalName: ['', [Validators.required]],
      introductionDate: [new Date()], 
      inventoryItemTypeId: [null], 
      inventoryMessage: [''], 
      isVariant: ['N'], 
      isVirtual: ['N'], 
      lotIdFilledIn: [null],
      orderDecimalQuantity: ['N'], 
      piecesIncluded: [0], 
      primaryProductCategoryId: [''], 
      productDepth: [0], 
      productDiameter: [0], 
      productHeight: [0],
      productId: [''], 
      productName: [''], 
      productRating: [0], 
      productTypeId: ['', [Validators.required]], 
      productWeight: [0],
      productWidth: [0], 
      quantityIncluded: [0],
      quantityUomId: [null], 
      ratingTypeEnum: [null], 
      releaseDate: [new Date()], 
      requireAmount: ['Y'],
      requireInventory: ['Y'], 
      requirementMethodEnumId: [null], 
      returnable: ['Y'], 
      salesDiscWhenNotAvail: ['Y'], 
      salesDiscontinuationDate: [new Date()], 
      shippingDepth: [0],
      shippingHeight: [0], 
      shippingWeight: [0], 
      shippingWidth: [0], 
      supportDiscontinuationDate: [new Date()], 
      taxable: ['Y'], 
      virtualVariantMethodEnum: [null],
      weightUomId: [null], 
      widthUomId: [null]
    });
    this.constructorExt();
  }
  constructorExt(): void {
    this.categories = [];
    this.interrogativeArray = [
      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      }
    ];
    this.commandingArray = [
      {
        label: 'Allowed',
        value: 'Allowed'
      },
      {
        label: 'Mandatory',
        value: 'Mandatory'
      },
      {
        label: 'Forbidden',
        value: 'Forbidden'
      }
    ];
    this.editMode = false;
  
  }
  AccountCategoryMemberSetting = [{
    value: 1
  }]

  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
    this.getProductDropown();
    this._ActivatedRoute.data.subscribe(data => {
      this.product = data.product;
      console.log("newcheck",this.product )
    });
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.configRedirect = params["configRedirect"];
    });

    if(this.configRedirect==="true"){
      this.activeCategory = 33;
    }

   
    this.getContentTypeIds();
    this.getSupplierByProductId();
    this.getProductPriceList();
    this.getContentIds();
    this.getInventory();
    this.getUserRole();
    this.getUseTimeIds();
    this.getContentIdForDropDown();
    this.getProductVariants();
    this.getCategories();

    this.getCreateProductGeo();
    this.getProductGeoEnumId();
    this.getVatTaxAuthGeoId();
    this.getCreateProductGeoById();
    this.getGoodIdentificationById();
    this.getGoodIdentificationTypeId();
    this.getAddProductToCategoryById();
    this.getProductCategoryForStore();
    this.getproductsKeywordById();
    this.keywordTypeIds();
    this.statusIds();
    this.getProductAssocById();
    this.getCatalogProductId();
    this.getProductAssocType();
    this.getProductAttributeById();
    this.getProductFacilityById();
    this.getProductFacilityId();
    this.getProductFacilityLocationById();
    this.getLocationSeqId();
    this.getProductGlAccountDetail();
    this.getAccountType();
    this.getOrganization();
    this.getGLAccount();
    this.getProductPaymentMethodAll();
    this.paymentMethodType();
    this.productPricePurpose();
    this.getEditProductMetersByID();
    this.getProductMeterTypeId();
    this.getCurrencyRate();
    this.getVendorProductByID();
    this.getProductStoreGroupID();
    this.getPartyId();
    this.getPartyToProductById();
    this.getRoleTypeId();
    this.getEditProductWorkEfforts();
    this.getProWorkEffortId();
    this.getWorkEffortGoodStdTypeIdDrop();
    this.getProductGroupOrderById();
    this.getCreateCommunicationEventById();
    this.getCatalogCommunicationEventId();
    this.getCommTypeID();
    this.getCataCommEventStatus();
    this.getContactMechTypeID();
    this.getFromContactMechList();
    this.getRoleTypeIdType();
    this.getCatalogContentMimeTypeId();
    this.getContactListId();
    this.getReasonEnumIds();
    this.getProductOrderById();
    this.getSubscriptionProductListBYId();
    this.getUseRoleTypeId();
    this.getUseTimeUomId();
    this.getSubscriptionResourceId();
    this.getWorkEffortNameValue();
    this.getProductMeterType();
    this.getProductMaintType();
    this.getEditProductMaints();
    this.commissionAgreements();
    this.getSalesAgreements();
    this.productAgreements();
    this.SupplierProduct();
    this.getPersonsAndPartyGroups();
    this.getAgreementTypeList();
    this.getCurrency();
    this.getProductIds();
    this.getViewProductParentById();
    this.getViewProductComponentsById();
    this.getRoutingsById();
    this.getProductCostComponentCalcById();
    this.getCostComponentByProductId();
    this.costComponentTypeIdFirst();
    this.costComponentTypeIdComponentCalc();
    this.productFeatureId();
    this.getCatalogGeoId();
    this.getWorkEffortIds();
    this.getFixedAssetIds();
    this.getCostComponentCalcId();
    this.getFixedAssetProductById();
    this.getFixedAssetId();
    this.getEditProductQuickAdmin();
    this.getSubscriptionResourceIdNew();
    this.getSubscriptionResource();
    this.getKeywordTypeIdsKeyword();
    this.getKeywordTypeIdsTag();
    this.getProductConfigsById();
    this.getConfigItemId();
    this.getProductAssocByIdSec();
    this.getProductFeatureIactn();
    this.getProductFeatures();
    this.getEditProductFeaturesById();
    this.getEditProductFeatures();
    this.getProductFeatureIdNew();
    this.ProductFeatureCategory();
    this.productFeatureGroupId();
    this.productFeatureApplTypeId();
    this.productFeatureTypeIds();
    this.getProductFeatureTypeID();
    this.getPriceType();
    this.getPurposes();
    this.getPartyIds();
    this.getGeoIdAndUomId();
    this.getCustomPrice();
    this.getProductStoreGroup();
    this.EditProductPrices();
    this.customPriceCalcService();
    this.getEditProductQuickAdminFeatureType();
    this.getEditProductQuickAdminCategories();
    this.getSupplierProductInitialData();
    this.getCurrencySupp();
    this.getCustomerList();
    this.quantitySuppierUomId();
    this.getProductsSupp();
    this.getCategoryById();
    this.getStandardFeaturesSecond();
    this.EditProductTag();
    this.show=true;
  }
  updateProductTag(): void {
    this.spinner.show();
    const requestData = {
      "keyword": this.updateProductTagPat.Data.keyword,
      "keywordTypeId": this.updateProductTagPat.Data.keywordTypeId,
      "productId": this.updateProductTagPat.Data.productId,
      "statusId": this.editProductTag.value.statusId
    }
    this.accountsService.updateProductTag(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.EditProductTag();
        this.closebutton64.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateProductTagPatch(product) {
    this.updateProductTagPat = product;
    this.spinner.show();
    setTimeout(() => {
      const formValue = this.editProductTag;
      formValue.patchValue({
        productId: this.updateProductTagPat.Data.productId,
        keyword: this.updateProductTagPat.Data.keyword,
        statusId: this.updateProductTagPat.Data.statusId
      })

    }, 2000);
    this.spinner.hide();
  }
  EditProductTag() {
    this.spinner.show();
    this.accountsService.EditProductTag().subscribe(res => {
      this.EditProductTagAll = res.data;
      this.spinner.hide();
    })
  }
  updateProductQuickAdminShipping(): void {
    this.spinner.show();
    const requestData = {
      "depthUomId": this.EditProductQuickAdmin[0].product.depthUomId,
      "heightUomId": this.EditProductQuickAdmin[0].product.heightUomId,
      "productDepth": this.shippingDimen_Weights.value.productDepth,
      "productHeight": this.shippingDimen_Weights.value.productHeight,
      "productId": this.product.productId,
      "productWidth": this.shippingDimen_Weights.value.productWidth,
      "weight": this.shippingDimen_Weights.value.weight,
      "weightUomId": this.EditProductQuickAdmin[0].product.weightUomId,
      "widthUomId": this.EditProductQuickAdmin[0].product.widthUomId
    }
    this.accountsService.updateProductQuickAdminShipping(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getEditProductQuickAdmin();
        this.closebutton63.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateProductQuickAdminShippingPatch() {
    this.spinner.show();
    setTimeout(() => {
      const formValue = this.shippingDimen_Weights;
      formValue.patchValue({
          productHeight: this.EditProductQuickAdmin[0].product.productHeight,
          productWidth: this.EditProductQuickAdmin[0].product.productWidth,
          productDepth: this.EditProductQuickAdmin[0].product.productDepth,
          weight: this.EditProductQuickAdmin[0].product.productWeight
      })

    }, 2000);
    this.spinner.hide();
  }
  removeProductFromCategoryQuick(product) {
    const fromDateCatePatchDel=product.fromDate;
    this.fromDateCatePatchDel=this.datePipe.transform(fromDateCatePatchDel,"yyyy-MM-dd");
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "productCategoryId": product.productCategoryId,
          "productId": this.product.productId
        }
        this.accountsService.removeProductFromCategoryNew(req).subscribe(res => {
          this.getCategoryById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deleteFeatureNew(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "productFeatureId": product.dataNode.productFeatureId,
          "productId":this.product.productId
        }
        this.accountsService.deleteFeature(req).subscribe((res: any) => {
          if (res.success) {
            this.getStandardFeaturesSecond()
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getStandardFeaturesSecond() {
    this.spinner.show();
    this.accountsService.getStandardFeaturesSecond(this.product.productId).subscribe(res => {
      this.StandardFeaturesSecondId = res.data;
      this.spinner.hide();
    })
  }
  applyFeatureToProductNew(): void {
    this.spinner.show();
    this.todayDate = Date.now();
    const requestData = {
      "fromDate": this.datePipe.transform(this.todayDate, "yyyy-MM-dd HH:mm:ss"),
      "productFeatureApplTypeId": "STANDARD_FEATURE",
      "productFeatureId": this.addFeatureTypeId.value.featuretypeId,
      "productId": this.product.productId,
      "sequenceNum": '',
      "thruDate": '',
    }

    this.accountsService.applyFeatureToProduct(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getStandardFeaturesSecond();
        this.addFeatureTypeId.reset();
        this.closebutton62.nativeElement.click();
        this.show=true;
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getStandardFeatures() {
    this.StandardFeaturesIdArray = [];
    this.spinner.show();
    this.accountsService.getStandardFeatures(this.addFeatureType.value.featuretype).subscribe(res => {
      this.StandardFeaturesId = res.data[0].getStandardFeatures;
      console.log("featureres",res);
      this.spinner.hide();
      if(res.success){
      this.show=false;
      for (const value of this.StandardFeaturesId) {
        this.StandardFeaturesIdArray.push({
          label: value.description,
          value: value.productFeatureId
        })
      }

      }
      
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  onSubmitVariantCall(): void {
    this.spinner.show(); 
    const requestData = {
      "productId": this.product.productId,
      "variantProductIdsBag":this.imageForm.value.productVariant
    }
    this.accountsService.addVaraints(requestData.productId,requestData.variantProductIdsBag).subscribe((res: any) => { 
      this.spinner.hide();
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.closebutton61.nativeElement.click();
        this.getProductVariants();
        this.imageForm.reset();
        this.activeCategory = 21;
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }

  quickAdminRemoveProductFromCategory(product) {
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {

          "fromDate": this.fromDateCatePatch,
          "productCategoryId": product.productCategoryId,
          "productId": this.product.productId
        }
        this.accountsService.quickAdminRemoveProductFromCategory(req).subscribe((res: any) => {
          if (res.success) {
            this.getCategoryById();
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getCategoryById() {
    this.spinner.show();
    this.accountsService.getCategoryById(this.product.productId).subscribe(res => {
      this.CategoryById = res.data[0].getCategoryById;
      this.spinner.hide();
    })
  }
  addCategoryQuickAdmin(): void {
    this.spinner.show();
    const requestData ={
      "comments": "",
      "fromDate": this.EditProductQuickAdmin[0].nowTimestampString,
      "productCategoryId": this.editCategories.value.categories,
      "productId":  this.product.productId,
      "quantity": "",
      "sequenceNum": "",
      "thruDate": ""
      }
     
    this.accountsService.addProductToCategory(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.editCategories.reset();
        this.getAddProductToCategoryById();
        this.closebutton60.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getProductsSupp() {
    this.spinner.show();
    const req = {
      "internalName": "",
      "internalNameSearchType": "Contains",
      "productId": "",
      "productIdSearchType": "Contains"
     

    }
    this.accountsService.getProducts(this.finSize, req).subscribe((res: any) => {

      this.Products = res.data;
      this.spinner.hide();
      for (const value of this.Products) {
        this.ProductsArray.push({
          label: value.productName,
          value: value.productId
        })
      }


    })
   
   
  }
  quantitySuppierUomId() {
    this.spinner.show();
    this._ProductService.quantitySuppierUomId().subscribe(res => {
      const quantityUomID = res.data.quantityUomID;
      this.spinner.hide();
      for (const value of quantityUomID) {
        this.quantityUomIDArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
   
  }
  getCustomerList() {
    this.spinner.show();
    this.roleTypeId = "SUPPLIER"
    this._ProductService.getCustomerList(this.roleTypeId).subscribe(res => {
      const supplier = res.data;
      this.spinner.hide();
      for (const value of supplier) {
        this.supplierArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }

    })
   
  }
  getCurrencySupp() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
   
  }
  getSupplierProductInitialData() {
    this.spinner.show();
    this._ProductService.getSupplierProductInitialData().subscribe((res: any) => {
      this.supplierPrefOrder = res.data.supplierPrefOrder;
      this.spinner.hide();
      for (const value of this.supplierPrefOrder) {
        this.supplierPrefOrderArray.push({
          label: value.description,
          value: value.supplierPrefOrderId
        })
      }
   
    })
   
  }

  onSubmit() {
    this.spinner.show();
    const fromActualdate = this.createSupplierForm.get('availableFromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const toActualdate = this.createSupplierForm.get('availableThruDate').value;
    this.actualTodate = this.datePipe.transform(toActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
    
      "agreementId": this.createSupplierForm.value.agreementId,
      "agreementItemSeqId":this.createSupplierForm.value.agreementItemSeqId,
      "availableFromDate": this.actualfromdate ,
      "availableThruDate": this.actualTodate ,
      "canDropShip": this.createSupplierForm.value.canDropShip,
      "currencyUomId": this.createSupplierForm.value.currencyUomId,
      "lastPrice": this.createSupplierForm.value.lastPrice,
      "minimumOrderQuantity": this.createSupplierForm.value.minimumOrderQuantity,
      "orderQtyIncrements":this.createSupplierForm.value.orderQtyIncrements,
      "partyId": this.createSupplierForm.value.partyId,
      "quantityUomId": this.createSupplierForm.value.quantityUomId,
      "shippingPrice": this.createSupplierForm.value.shippingPrice,
      "standardLeadTimeDays": this.createSupplierForm.value.standardLeadTimeDays,
      "supplierPrefOrderId": this.createSupplierForm.value.supplierPrefOrderId,
      "supplierProductId": this.createSupplierForm.value.supplierProductId,
      "supplierProductName": this.createSupplierForm.value.supplierProductName,
      "supplierRatingTypeId": this.createSupplierForm.value.supplierRatingTypeId,
      "unitsIncluded":this.createSupplierForm.value.unitsIncluded,
      "comments":this.createSupplierForm.value.comments

    }

    this._ProductService.postSupplierByProductId(this.product.productId, requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getSupplierByProductId();
        this.createSupplierForm.reset();
        this.closebutton58.nativeElement.click();
       
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
   
  }
  onUpdate() {
    this.spinner.show();
    const fromActualdate = this.createSupplierForm1.get('availableFromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const toActualdate = this.createSupplierForm1.get('availableThruDate').value;
    this.actualTodate = this.datePipe.transform(toActualdate, "yyyy-MM-dd hh:mm:ss");
   
    const requestData = {
    
      "agreementId": this.createSupplierForm1.value.agreementId,
      "agreementItemSeqId":this.createSupplierForm1.value.agreementItemSeqId,
      "availableFromDate": this.actualfromdate ,
      "availableThruDate": this.actualTodate ,
      "canDropShip": this.createSupplierForm1.value.canDropShip,
      "currencyUomId": this.createSupplierForm1.value.currencyUomId,
      "lastPrice": this.createSupplierForm1.value.lastPrice,
      "minimumOrderQuantity": this.createSupplierForm1.value.minimumOrderQuantity,
      "orderQtyIncrements":this.createSupplierForm1.value.orderQtyIncrements,
      "partyId": this.createSupplierForm1.value.partyId,
      "quantityUomId": this.createSupplierForm1.value.quantityUomId,
      "shippingPrice": this.createSupplierForm1.value.shippingPrice,
      "standardLeadTimeDays": this.createSupplierForm1.value.standardLeadTimeDays,
      "supplierPrefOrderId": this.createSupplierForm1.value.supplierPrefOrderId,
      "supplierProductId": this.createSupplierForm1.value.supplierProductId,
      "supplierProductName": this.createSupplierForm1.value.supplierProductName,
      "supplierRatingTypeId": this.createSupplierForm1.value.supplierRatingTypeId,
      "unitsIncluded":this.createSupplierForm1.value.unitsIncluded,
      "comments":this.createSupplierForm1.value.comments

    }

    this._ProductService.updateSupplierByProductId(this.product.productId, requestData).subscribe((res: any) => {
    
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getSupplierByProductId();
        this.closebutton59.nativeElement.click();    
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
   
  }
  onUpdatePatch(product) {
    this.onUpdatePat = product;
    const fromActualdate = product.availableFromDate;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd");
    const toActualdate = product.availableThruDate;
    this.actualTodate = this.datePipe.transform(toActualdate, "yyyy-MM-dd");
    this.spinner.show();
    setTimeout(() => {
      const formValue = this.createSupplierForm1;
      formValue.patchValue({
          agreementId: this.onUpdatePat.agreementId,
          agreementItemSeqId: this.onUpdatePat.agreementItemSeqId,
          availableFromDate: this.actualfromdate,
          availableThruDate: this.actualTodate,
          canDropShip: this.onUpdatePat.canDropShip,
          currencyUomId: this.onUpdatePat.currencyUomId,
          lastPrice: this.onUpdatePat.lastPrice,
          minimumOrderQuantity: this.onUpdatePat.minimumOrderQuantity,
          orderQtyIncrements: this.onUpdatePat.orderQtyIncrements,
          partyId: this.onUpdatePat.partyId,
          quantityUomId: this.onUpdatePat.quantityUomId,
          shippingPrice: this.onUpdatePat.shippingPrice,
          standardLeadTimeDays: this.onUpdatePat.standardLeadTimeDays,
          supplierPrefOrderId: this.onUpdatePat.supplierPrefOrderId,
          supplierProductId: this.onUpdatePat.supplierProductId,
          supplierProductName: this.onUpdatePat.supplierProductName,
          supplierRatingTypeId: this.onUpdatePat.supplierRatingTypeId, 
          unitsIncluded: this.onUpdatePat.unitsIncluded, 
          comments: this.onUpdatePat.comments
      
      })

    }, 2000);
    this.spinner.hide();
  }
  quickAdminAddCategories(): void {
    this.spinner.show();
    const requestData = {
      "fromDate": this.addPublish.value.fromDate,
      "productId": this.product.productId
    }
    this.accountsService.quickAdminAddCategories(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Remove Successfully");
        this.spinner.hide();
        this.addPublish.reset();
        this.closebutton57.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateProductQuickAdminName(): void {
    this.spinner.show();
    const requestData = {
      "productId": this.product.productId,
      "productName": this.editProductQuickAdmin.value.productName
    }
    this.accountsService.updateProductQuickAdminName(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getEditProductQuickAdmin();
        this.closebutton56.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateProductQuickAdminNamePatch() {

    this.spinner.show();

    setTimeout(() => {
      const formValue = this.editProductQuickAdmin;
      formValue.patchValue({
          productId: this.EditProductQuickAdmin[0].product.productId,
          productName: this.EditProductQuickAdmin[0].product.productName
      })

    }, 2000);
    this.spinner.hide();
  }
  deleteProductPriceNew(product) {
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "currencyUomId": product.currencyUomId,
          "fromDate": this.fromDateCatePatch,
          "productId": this.product.productId,
          "productPricePurposeId": product.productPricePurposeId,
          "productPriceTypeId": product.productPriceTypeId,
          "productStoreGroupId": product.productStoreGroupId,
          "view_INDEX_1": "",
          "view_SIZE_1": ""
        }
        this.accountsService.deleteProductPrice(req).subscribe((res: any) => {
          if (res.success) {
            this.EditProductPrices()
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  updateProductPrice(): void {
    this.spinner.show();
    const requestData = {
      "currencyUomId": this.productPriceForm1.value.currencyUomId,
      "customPriceCalcService": this.productPriceForm1.value.customPriceCalcService,
      "fromDate": this.datePipe.transform(this.productPriceForm1.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "price": this.productPriceForm1.value.price,
      "productId": this.product.productId,
      "productPricePurposeId": this.productPriceForm1.value.productPricePurposeId,
      "productPriceTypeId": this.productPriceForm1.value.productPriceTypeId,
      "productStoreGroupId": this.productPriceForm1.value.productStoreGroupId,
      "taxAuthGeoId": this.productPriceForm1.value.taxAuthGeoId,
      "taxAuthPartyId": this.productPriceForm1.value.taxAuthPartyId,
      "taxInPrice": this.productPriceForm1.value.taxInPrice,
      "taxPercentage": this.productPriceForm1.value.taxPercentage,
      "termUomId": this.productPriceForm1.value.termUomId,
      "thruDate": this.datePipe.transform(this.productPriceForm1.value.thruDate, "yyyy-MM-dd HH:mm:ss"),
    }
    this.accountsService.updateProductPrice(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.EditProductPrices();
        this.closebutton55.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateProductPricePatch(product) {
    this.updateProductPricePat = product ;
    this.spinner.show();
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    const throughDateCatePatch=product.thruDate;
    this.throughDateCatePatch=this.datePipe.transform(throughDateCatePatch,"yyyy-MM-dd");
    setTimeout(() => {
      const formValue = this.productPriceForm1;
      formValue.patchValue({
          currencyUomId: this.updateProductPricePat.currencyUomId,
          customPriceCalcService: this.updateProductPricePat.customPriceCalcService,
          fromDate: this.fromDateCatePatch,
          price: this.updateProductPricePat.price,
          productPricePurposeId: this.updateProductPricePat.productPricePurposeId,
          productPriceTypeId: this.updateProductPricePat.productPriceTypeId,
          productStoreGroupId: this.updateProductPricePat.productStoreGroupId,
          taxAuthGeoId: this.updateProductPricePat.taxAuthGeoId,
          taxAuthPartyId: this.updateProductPricePat.taxAuthPartyId,
          taxInPrice: this.updateProductPricePat.taxInPrice,
          taxPercentage: this.updateProductPricePat.taxPercentage, 
          termUomId: this.updateProductPricePat.termUomId,  
          thruDate: this.throughDateCatePatch
      })

    }, 2000);
    this.spinner.hide();
  }
  createProductPrice(): void {
    this.spinner.show();
    const requestData = {
      "currencyUomId": this.productPriceForm.value.currencyUomId,
      "customPriceCalcService": this.productPriceForm.value.customPriceCalcService,
      "fromDate": this.datePipe.transform(this.productPriceForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "price": this.productPriceForm.value.price,
      "productId": this.product.productId,
      "productPricePurposeId": this.productPriceForm.value.productPricePurposeId,
      "productPriceTypeId": this.productPriceForm.value.productPriceTypeId,
      "productStoreGroupId": this.productPriceForm.value.productStoreGroupId,
      "taxAuthGeoId": this.productPriceForm.value.taxAuthGeoId,
      "taxAuthPartyId": this.productPriceForm.value.taxAuthPartyId,
      "taxInPrice": this.productPriceForm.value.taxInPrice,
      "taxPercentage": this.productPriceForm.value.taxPercentage,
      "termUomId": this.productPriceForm.value.termUomId,
      "thruDate": this.datePipe.transform(this.productPriceForm.value.thruDate, "yyyy-MM-dd HH:mm:ss"),
    }

    this.accountsService.createProductPrice(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.EditProductPrices();
        this.productPriceForm.reset();
        this.closebutton54.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  EditProductPrices() {
    this.spinner.show();
    this.accountsService.EditProductPrices(this.product.productId).subscribe(res => {
      this.getEditProductPrices = res.data[0].EditProductPrices;
      this.spinner.hide();
    })
  }
  customPriceCalcService() {
    this.spinner.show();
    this.accountsService.customPriceCalcService(this.product.productId).subscribe(res => {
      this.customPriceCalcServiceId = res.data[0].customPriceCalcService;
      this.spinner.hide();
      for (const value of this.customPriceCalcServiceId) {
        this.customPriceCalcServiceIdArray.push({
          label: value.customPriceCalcService,
          value: value.customPriceCalcService
        })
      }
    })
  }
    getGeoIdAndUomId(): void {
      this.spinner.show();
      this._ProductService.getTaxGeoAndTermUomID()
          .then(data => {
              if (data.success) {
                  this.spinner.hide();
                  this.itemUomIdsArray = data.data.termUomId.map(value => {
                      return {
                          label: value.description,
                          value: value.uomId
                      };
                  });
                  this.taxAuthGeoIdsArray = data.data.taxGeoId.map(value => {
                      return {
                          label: value.geoName,
                          value: value.geoId
                      };
                  });
              }
          });
        
  }
  getCustomPrice(): void {
      this.spinner.show();
      this._ProductService.getCustomPrice()
          .then(data => {
              if (data.success) {
                  this.spinner.hide();
                  this.customPriceArray = data.data.map(value => {
                      return {
                          label: value.description,
                          value: value.customMethodId
                      };
                  });
              }
          });
          
  }
  getProductStoreGroup(): void {
      this.spinner.show();
      this._ProductService.getProductStoreGroup()
          .then(data => {
              if (data.success) {
                  this.spinner.hide();
                  this.productStoresArray = data.data.map(value => {
                      return {
                          label: value.productStoreGroupName,
                          value: value.productStoreGroupId
                      };
                  });
              }
          });
        
  }


  getPriceType(): void {
    this.spinner.show();
    this._ProductService.getPriceType()
        .then(data => {
            if (data.success) {
                this.spinner.hide();
                this.priceTypesArray = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.productPriceTypeId
                    };
                });
            }
        });
       
  }
  getPurposes(): void {
      this.spinner.show();
      this._ProductService.getPricePurpose()
          .then(data => {
              if (data.success) {
                  this.spinner.hide();
                  this.purposesArray = data.data.map(value => {
                      return {
                          label: value.description,
                          value: value.productPricePurposeId
                      };
                  });
              }
          });
        
  }
  getPartyIds(): void {
      this.spinner.show();
      this._GlobalResourceService.getPartyGroups()
          .then(data => {
              this.spinner.hide();
              this.taxAuthPartyIdsArray = data.data.PersonsGroups.map(value => {
                  return {
                      label: value.partyId,
                      value: value.partyId
                  };
              });
          });
        
  }

  getProductFeatureTypeID() {
    this.spinner.show();
    this.accountsService.getProductFeatureTypeID().subscribe(res => {
      this.ProductFeatureTypeID = res.data.getProductFeatureTypeID;
      this.spinner.hide();
      for (const value of this.ProductFeatureTypeID) {
        this.ProductFeatureTypeIDArray.push({
          label: value.productFeatureId,
          value: value.productFeatureId
        })
      }
    })
  }
  RemoveFeatureFromProduct(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "productFeatureId": product.productFeatureId,
          "productId":this.product.productId
        }
        this.accountsService.deleteFeature(req).subscribe((res: any) => {
          if (res.success) {
            this.getEditProductFeatures()
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  updateFeatureToProductApplication(): void {
    this.spinner.show();
    const requestData = {
      "_checkGlobalScope": "",
      "_rowCount": "",
      "_useRowSubmit": "",
      "amount_o_0": "",
      "fromDate_o_0": this.datePipe.transform(this.addFeatureID1.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "productFeatureApplTypeId_o_0": this.addFeatureID1.value.appType,
      "productFeatureId_o_0":  this.addFeatureID1.value.ID,
      "productId": this.product.productId,
      "productId_o_0": "",
      "sequenceNum_o_0":  this.addFeatureID1.value.seq,
      "thruDate_o_0": this.datePipe.transform(this.addFeatureID1.value.throughDate, "yyyy-MM-dd HH:mm:ss"),
      "thruDate_o_0_i18n": ""
    }
    this.accountsService.updateFeatureToProductApplication(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getEditProductFeatures();
        this.closebutton53.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateFeatureToProductApplicationPatch(product) {
    this.updateFeatureToProductApplicationPat = product ;
    this.spinner.show();
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    const throughDateCatePatch=product.thruDate;
    this.throughDateCatePatch=this.datePipe.transform(throughDateCatePatch,"yyyy-MM-dd");
    setTimeout(() => {
      const formValue = this.addFeatureID1;
      formValue.patchValue({
          ID: this.updateFeatureToProductApplicationPat.productFeatureId,
          appType: this.updateFeatureToProductApplicationPat.productFeatureApplTypeId,
          fromDate: this.fromDateCatePatch,
          throughDate: this.throughDateCatePatch,
          seq: this.updateFeatureToProductApplicationPat.sequenceNum,
      })

    }, 2000);
    this.spinner.hide();
  }
  applyFeatureToProduct(): void {
    this.spinner.show();
    const requestData = {
      "fromDate": this.datePipe.transform(this.addFeatureID.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "productFeatureApplTypeId": this.addFeatureID.value.appType,
      "productFeatureId": this.addFeatureID.value.ID,
      "productId": this.product.productId,
      "sequenceNum": this.addFeatureID.value.seq,
      "thruDate":this.datePipe.transform(this.addFeatureID.value.throughDate, "yyyy-MM-dd HH:mm:ss"),
    }

    this.accountsService.applyFeatureToProduct(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getEditProductFeatures();
        this.addFeatureID.reset();
        this.closebutton52.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getEditProductFeatures() {
    this.spinner.show();
    this.accountsService.getEditProductFeatures(this.product.productId).subscribe(res => {
      this.EditProductFeatures = res.data.productFeatureAndAppls;
      this.spinner.hide();
    })
  }
  applyFeatureToProductFromTypeAndCode(): void {
    this.spinner.show();
    const requestData = {
      "fromDate": this.datePipe.transform(this.addFeatureTypeID.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "idCode": this.addFeatureTypeID.value.idCode,
      "productFeatureApplTypeId": this.addFeatureTypeID.value.appType,
      "productFeatureTypeId": this.addFeatureTypeID.value.featureType,
      "productId": this.product.productId,
      "sequenceNum": this.addFeatureTypeID.value.seq,
      "thruDate": this.datePipe.transform(this.addFeatureTypeID.value.throughDate, "yyyy-MM-dd HH:mm:ss"),
    }

    this.accountsService.applyFeatureToProductFromTypeAndCode(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.addFeatureTypeID.reset();
        this.closebutton50.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  productFeatureTypeIds() {
    this.spinner.show();
    this.accountsService.productFeatureTypeIds().subscribe(res => {
      this.getproductFeatureTypeIds = res.data[0].productFeatureTypeIds;
      this.spinner.hide();
      for (const value of this.getproductFeatureTypeIds) {
        this.getproductFeatureTypeIdsArray.push({
          label: value.description,
          value: value.productFeatureTypeId
        })
      }
    })
  }
  productFeatureApplTypeId() {
    this.spinner.show();
    this.accountsService.productFeatureApplTypeId().subscribe(res => {
      this.getproductFeatureApplTypeId = res.data[0].productFeatureApplTypeId;
      this.spinner.hide();
      for (const value of this.getproductFeatureApplTypeId) {
        this.getproductFeatureApplTypeIdArray.push({
          label: value.description,
          value: value.productFeatureApplTypeId
        })
      }
    })
  }
  productFeatureGroupId() {
    this.spinner.show();
    this.accountsService.productFeatureGroupId().subscribe(res => {
      this.getproductFeatureGroupId = res.data[0].productFeatureGroupId;
      this.spinner.hide();
      for (const value of this.getproductFeatureGroupId) {
        this.getproductFeatureGroupIdArray.push({
          label: value.description,
          value: value.productFeatureGroupId
        })
      }
    })
  }
  ProductFeatureCategory() {
    this.spinner.show();
    this.accountsService.ProductFeatureCategory().subscribe(res => {
      this.ProductFeatureCategoryId = res.data[0].ProductFeatureCategory;
      this.spinner.hide();
      for (const value of this.ProductFeatureCategoryId) {
        this.ProductFeatureCategoryIdArray.push({
          label: value.description+"["+value.productFeatureCategoryId+"]",
          value: value.productFeatureCategoryId
        })
      }
    })
  }

  getProductFeatureIdNew() {
    this.spinner.show();
    this.accountsService.getProductFeatureId(this.product.productId).subscribe(res => {
      this.ProductFeatureId = res.data[0].getProductFeatureId;
      this.spinner.hide();
      for (const value of this.ProductFeatureId) {
        this.ProductFeatureIdArray.push({
          label: value.productFeatureId,
          value: value.productFeatureId
        })
      }
    })
  }
  

  deleteProductFeatureApplAttr(product) {
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "attrName": product.attrName,
          "fromDate": this.fromDateCatePatch,
          "productFeatureId": product.productFeatureId,
          "productId": this.product.productId,
          "view_INDEX_2": "",
          "view_SIZE_2": ""
        }
        this.accountsService.deleteProductFeatureApplAttr(req).subscribe((res: any) => {
          if (res.success) {
            this.getEditProductFeaturesById();

          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  createProductFeatureApplAttr(): void {
    this.spinner.show();
    const requestData = {
      "attrName": this.addProductFeatureAttr.value.attrName,
      "attrValue": this.addProductFeatureAttr.value.attrValue,
      "fromDate":  this.datePipe.transform(this.addProductFeatureAttr.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "productFeatureId": this.addProductFeatureAttr.value.featureID,
      "productId": this.product.productId
    }

    this.accountsService.createProductFeatureApplAttr(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getEditProductFeaturesById();
        this.addProductFeatureAttr.reset();
        this.closebutton51.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getEditProductFeaturesById() {
    this.spinner.show();
    this.accountsService.getEditProductFeaturesById(this.product.productId).subscribe(res => {
      this.EditProductFeaturesById = res.data.getEditProductFeaturesById;
      this.spinner.hide();
    })
  }

  removeProductFeatureIactn(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "productFeatureId": product.productFeatureId,
          "productFeatureIdTo":product.productFeatureIdTo,
          "productId": this.product.productId,
          "view_INDEX_1": "",
          "view_SIZE_1": ""
        }
        this.accountsService.removeProductFeatureIactn(req).subscribe((res: any) => {
          if (res.success) {
            this.getProductFeatureIactn()
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  AddProductFeatureIactn(): void {
    this.spinner.show();
    const requestData = {
      "productFeatureIactnTypeId": this.addFeatureInteraction.value.interactionType,
      "productFeatureId": this.addFeatureInteraction.value.featureID,
      "productFeatureIdTo": this.addFeatureInteraction.value.featureIdTo,
      "productId": this.product.productId
    }

    this.accountsService.AddProductFeatureIactn(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getProductFeatureIactn();
        this.addFeatureInteraction.reset();
        this.closebutton49.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getProductFeatureIactn() {
    this.spinner.show();
    this.accountsService.getProductFeatureIactn(this.product.productId).subscribe(res => {
      this.ProductFeatureIactn = res.data.getProductFeatureIactn;
      this.spinner.hide();
    })
  }
  getProductFeatures() {
    this.spinner.show();
    this.myContactsService.getProductFeature().subscribe(res => {
      this.ProductFeatures = res.data[0].node;
      this.spinner.hide();
      for (const value of this.ProductFeatures) {
        this.ProductFeaturesArray.push({
          label: value.description,
          value: value.contentAssocTypeId
        })
      }
    })
  }



  deleteProductConfig(product) {
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "configItemId": product.configItemId,
          "fromDate": this.fromDateCatePatch,
          "productId": this.product.productId,
          "sequenceNum": product.sequenceNum,
          "view_INDEX_1": "",
          "view_SIZE_1": ""
        }
        this.accountsService.deleteProductConfig(req).subscribe((res: any) => {
          if (res.success) {
            this.getProductConfigsById()
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  updateProductConfig(): void {
    this.spinner.show();
    const requestData = {
      "configItemId": this.addConfig1.value.configItem,
      "configTypeId": this.addConfig1.value.configTypeID,
      "defaultConfigOptionId": this.addConfig1.value.optionID,
      "description": this.addConfig1.value.desc,
      "fromDate": this.datePipe.transform(this.addConfig1.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "isMandatory": this.addConfig1.value.isMandatory,
      "longDescription": this.addConfig1.value.longDesc,
      "productId": this.product.productId,
      "sequenceNum": this.addConfig1.value.seqNum,
      "thruDate": this.datePipe.transform(this.addConfig1.value.throughDate, "yyyy-MM-dd HH:mm:ss")
    }
    this.accountsService.updateProductConfig(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getProductConfigsById();
        this.closebutton48.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateProductConfigPatch(product) {
    this.updateProductConfigPat = product ;
    this.spinner.show();
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    const throughDateCatePatch=product.thruDate;
    this.throughDateCatePatch=this.datePipe.transform(throughDateCatePatch,"yyyy-MM-dd");
    setTimeout(() => {
      const formValue = this.addConfig1;
      formValue.patchValue({
        configItem: this.updateProductConfigPat.configItemId,
        seqNum: this.updateProductConfigPat.sequenceNum,
        fromDate: this.fromDateCatePatch,
        desc: this.updateProductConfigPat.description,
        longDesc: this.updateProductConfigPat.longDescription,
        configTypeID: this.updateProductConfigPat.configTypeId,
        optionID: this.updateProductConfigPat.defaultConfigOptionId,
        throughDate: this.throughDateCatePatch,
        isMandatory: this.updateProductConfigPat.isMandatory,
      })

    }, 2000);
    this.spinner.hide();
  }
  createProductConfig(): void {
    this.spinner.show();
    const requestData = {
      "configItemId": this.addConfig.value.configItem,
      "configTypeId": this.addConfig.value.configTypeID,
      "defaultConfigOptionId": this.addConfig.value.optionID,
      "description": this.addConfig.value.desc,
      "fromDate": this.datePipe.transform(this.addConfig.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "isMandatory": this.addConfig.value.isMandatory,
      "longDescription": this.addConfig.value.longDesc,
      "productId": this.product.productId,
      "sequenceNum": this.addConfig.value.seqNum,
      "thruDate": this.datePipe.transform(this.addConfig.value.throughDate, "yyyy-MM-dd HH:mm:ss")
    }

    this.accountsService.createProductConfig(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getProductConfigsById();
        this.addConfig.reset();
        this.closebutton47.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getProductConfigsById() {
    this.spinner.show();
    this.accountsService.getProductConfigsById(this.product.productId).subscribe(res => {
      this.ProductConfigsById = res.data[0].getProductConfigsById;
      this.spinner.hide();
    })
  }
  getConfigItemId() {
    this.spinner.show();
    this.accountsService.getConfigItemId().subscribe(res => {
      this.ConfigItemId = res.data[0].getConfigItemId;
      this.spinner.hide();
      for (const value of this.ConfigItemId) {
        this.ConfigItemIdArray.push({
          label: value.configItemName+"["+value.configItemId+"]",
          value: value.configItemId
        })
      }
    })
  }
  getSubscriptionResource() {
    this.spinner.show();
    this.accountsService.getSubscriptionResource().subscribe(res => {
      this.SubscriptionResource = res.data[0].data;
      this.spinner.hide();
      for (const value of this.SubscriptionResource) {
        this.SubscriptionResourceArray.push({
          label: value.description,
          value: value.subscriptionResourceId
        })
      }
    })
  }
  getEditProductQuickAdminFeatureType() {
    this.spinner.show();
    this.accountsService.getEditProductQuickAdmin(this.product.productId).subscribe(res => {
      this.EditProductQuickAdminFeatureType = res.data[0].featureTypes;
      this.spinner.hide();
      for (const value of this.EditProductQuickAdminFeatureType) {
        this.EditProductQuickAdminFeatureTypeArray.push({
          label: value.description,
          value: value.productFeatureTypeId
        })
      }
    })
  }
  getEditProductQuickAdminCategories() {
    this.spinner.show();
    this.accountsService.getEditProductQuickAdmin(this.product.productId).subscribe(res => {
      this.EditProductQuickAdminCategories = res.data[0].allCategories;
      this.spinner.hide();
      for (const value of this.EditProductQuickAdminCategories) {
        this.EditProductQuickAdminCategoriesArray.push({
          label: value.description+"["+value.productCategoryId+"]",
          value: value.productCategoryId
        })
      }
    })
  }
  getEditProductQuickAdmin() {
    this.spinner.show();
    this.accountsService.getEditProductQuickAdmin(this.product.productId).subscribe(res => {
      this.EditProductQuickAdmin = res.data;
      this.spinner.hide();
    })
  }

  removeFixedAssetProduct(product) {
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "fixedAssetId": product.fixedAssetId,
          "fixedAssetProductTypeId": product.fixedAssetProductTypeId,
          "fromDate": this.fromDateCatePatch,
          "productId": this.product.productId
        }
        this.accountsService.removeFixedAssetProduct(req).subscribe((res: any) => {
          if (res.success) {
            this.getFixedAssetProductById()
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateProductAssetUsage(): void {
    this.spinner.show();
    const requestData = {
      "productId": this.product.productId,
      "reserv2ndPPPerc": this.productAssUsage.value.secPricePercentage,
      "reservMaxPersons": this.productAssUsage.value.MaxpersonsForRental,
      "reservNthPPPerc": this.productAssUsage.value.nthPricePercentage 
    }
    this.accountsService.updateProductAssetUsage(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton46.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateFixedAssetProduct(): void {
    this.spinner.show();
    const requestData = {
      "comments": this.addRental1.value.cmnt,
      "fixedAssetId": this.addRental1.value.fixedAssId,
      "fixedAssetProductTypeId": this.addRental1.value.fixedProdId,
      "fromDate": this.datePipe.transform(this.addRental1.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "productId": this.product.productId,
      "quantity": this.addRental1.value.Quty,
      "quantityUomId": this.addRental1.value.UOM,
      "sequenceNum": this.addRental1.value.seqnence,
      "thruDate":this.datePipe.transform(this.addRental1.value.throughDate, "yyyy-MM-dd HH:mm:ss"),
    }
    this.accountsService.updateFixedAssetProduct(requestData,this.addRental1.value.fixedAssId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getFixedAssetProductById();
        this.closebutton45.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateFixedAssetProductPatch(product) {
    this.updateFixedAssetProductPat = product ;
    this.spinner.show();
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    const throughDateCatePatch=product.thruDate;
    this.throughDateCatePatch=this.datePipe.transform(throughDateCatePatch,"yyyy-MM-dd");
    setTimeout(() => {
      const formValue = this.addRental1;
      formValue.patchValue({
        fixedAssId: this.updateFixedAssetProductPat.fixedAssetId,
        fixedProdId: this.updateFixedAssetProductPat.fixedAssetProductTypeId,
        fromDate: this.fromDateCatePatch,
        throughDate: this.throughDateCatePatch,
        cmnt: this.updateFixedAssetProductPat.comments,
        seqnence: this.updateFixedAssetProductPat.sequenceNum,
        Quty: this.updateFixedAssetProductPat.quantity,
        UOM: this.updateFixedAssetProductPat.quantityUomId
      })

    }, 2000);
    this.spinner.hide();
  }
  addFixedAssetProduct(): void {
    this.spinner.show();
    const requestData = {
      "comments": this.addRental.value.cmnt,
      "fixedAssetId": this.addRental.value.fixedAssId,
      "fixedAssetProductTypeId": this.addRental.value.fixedProdId,
      "fromDate": this.datePipe.transform(this.addRental.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "productId": this.product.productId,
      "quantity": this.addRental.value.Quty,
      "quantityUomId": this.addRental.value.UOM,
      "sequenceNum": this.addRental.value.seqnence,
      "thruDate": this.datePipe.transform(this.addRental.value.throughDate, "yyyy-MM-dd HH:mm:ss"),
    }

    this.accountsService.addFixedAssetProduct(requestData,this.addRental.value.fixedAssId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getFixedAssetProductById();
        this.addRental.reset();
        this.closebutton44.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getFixedAssetProductById() {
    this.spinner.show();
    this.accountsService.getFixedAssetProductById(this.product.productId).subscribe(res => {
      this.FixedAssetProductById = res.data.getFixedAssetProductById;
      this.spinner.hide();
    })
  }
  getFixedAssetId() {
    this.spinner.show();
    this.accountsService.getFixedAssetId().subscribe(res => {
      this.FixedAssetId = res.data[0].getFixedAssetId;
      this.spinner.hide();
      for (const value of this.FixedAssetId) {
        this.FixedAssetIdArray.push({
          label: value.fixedAssetId,
          value: value.fixedAssetId
        })
      }
    })
  }
  calculateProductCosts(): void {
    this.spinner.show();
    const requestData = {
      "costComponentTypePrefix": this.addAutoCalc.value.prefix,
      "currencyUomId": this.addAutoCalc.value.currency,
      "productId": this.product.productId,
    }

    this.accountsService.calculateProductCosts(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.addAutoCalc.reset();
        this.closebutton43.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deleteProductCostComponentCalc(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "costComponentTypeId": product.costComponentTypeId,
          "fromDate": this.datePipe.transform(product.fromDate,"yyyy-MM-dd HH:mm:ss"), 
          "productId": this.product.productId,
          "view_INDEX_2": "",
          "view_SIZE_2": ""
        }
        this.accountsService.deleteProductCostComponentCalc(req).subscribe((res: any) => {
          if (res.success) {
            this.createProductCostComponentCalc()
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deleteCostComponent(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "costComponentId": product.costComponentId,
          "productId": this.product.productId,
          "view_INDEX_1": "",
          "view_SIZE_1": ""
        }
        this.accountsService.deleteCostComponent(req).subscribe((res: any) => {
          if (res.success) {
            this.getCostComponentByProductId()
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  createProductCostComponentCalc(): void {
    this.spinner.show();
    const requestData = {
      "costComponentCalcId": this.addCostCalc.value.calcID,
      "costComponentTypeId": this.addCostCalc.value.typeID,
      "fromDate":  this.datePipe.transform(this.addCostCalc.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "productId": this.product.productId,
      "sequenceNum":this.addCostCalc.value.seqNum,
      "thruDate":  this.datePipe.transform(this.addCostCalc.value.throughDate, "yyyy-MM-dd HH:mm:ss"),
    }

    this.accountsService.createProductCostComponentCalc(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getProductCostComponentCalcById();
        this.addCostCalc.reset();
        this.closebutton42.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createCostComponent(): void {
    this.spinner.show();
    const requestData = {
      "cost": this.addCost.value.cost,
      "costComponentCalcId": this.addCost.value.calcID,
      "costComponentId": "",
      "costComponentTypeId": this.addCost.value.typeID,
      "costUomId": this.addCost.value.currency,
      "fixedAssetId": this.addCost.value.fixedAssetID,
      "fromDate": this.datePipe.transform(this.addCost.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "geoId": this.addCost.value.geoID,
      "partyId": this.addCost.value.partyID,
      "productFeatureId": this.addCost.value.featureID,
      "productId": this.product.productId,
      "thruDate":  this.datePipe.transform(this.addCost.value.throughDate, "yyyy-MM-dd HH:mm:ss"),
      "workEffortId": this.addCost.value.workEffortID,
    }

    this.accountsService.createCostComponent(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getCostComponentByProductId();
        this.addCost.reset();
        this.closebutton41.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getCostComponentCalcId() {
    this.spinner.show();
    this.accountsService.getCostComponentCalcId().subscribe(res => {
      this.CostComponentCalcId = res.data.getCostComponentCalcId;
      this.spinner.hide();
      for (const value of this.CostComponentCalcId) {
        this.CostComponentCalcIdArray.push({
          label: value.costComponentCalcId,
          value: value.costComponentCalcId
        })
      }
    })
  }
  getFixedAssetIds() {
    this.spinner.show();
    this.accountsService.getFixedAssetIds().subscribe(res => {
      this.FixedAssetIds = res.data[0].getFixedAssetId;
      this.spinner.hide();
      for (const value of this.FixedAssetIds) {
        this.FixedAssetIdsArray.push({
          label: value.fixedAssetId,
          value: value.fixedAssetId
        })
      }
    })
  }
  getWorkEffortIds() {
    this.spinner.show();
    this.accountsService.getWorkEffortIds().subscribe(res => {
      this.WorkEffortIds = res.data[0].getWorkEffortId;
      this.spinner.hide();
      for (const value of this.WorkEffortIds) {
        this.WorkEffortIdsArray.push({
          label: value.workEffortId,
          value: value.workEffortId
        })
      }
    })
  }
  getCatalogGeoId() {
    this.spinner.show();
    this.accountsService.getCatalogGeoId().subscribe(res => {
      this.CatalogGeoId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.CatalogGeoId) {
        this.CatalogGeoIdArray.push({
          label: value.geoId,
          value: value.geoId
        })
      }
    })
  }
  productFeatureId() {
    this.spinner.show();
    this.accountsService.productFeatureId().subscribe(res => {
      this.getProductFeatureId = res.data.productFeatureId;
      this.spinner.hide();
      for (const value of this.getProductFeatureId) {
        this.getProductFeatureIdArray.push({
          label: value.productFeatureId,
          value: value.productFeatureId
        })
      }
    })
  }
  costComponentTypeIdComponentCalc() {
    this.spinner.show();
    this.accountsService.costComponentTypeIdComponentCalc().subscribe(res => {
      this.ComponentTypeIdComponentCalc = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ComponentTypeIdComponentCalc) {
        this.ComponentTypeIdComponentCalcArray.push({
          label: value.description,
          value: value.costComponentTypeId
        })
      }
    })
  }
  costComponentTypeIdFirst() {
    this.spinner.show();
    this.accountsService.costComponentTypeIdFirst().subscribe(res => {
      this.ComponentTypeIdFirst = res.data.costComponentTypeIdFirst;
      this.spinner.hide();
      for (const value of this.ComponentTypeIdFirst) {
        this.ComponentTypeIdFirstArray.push({
          label: value.description,
          value: value.costComponentTypeId
        })
      }
    })
  }
  getProductCostComponentCalcById() {
    this.spinner.show();
    this.accountsService.getProductCostComponentCalcById(this.product.productId).subscribe(res => {
      this.ProductCostComponentCalcById = res.data.getProductCostComponentCalcAll;
      this.spinner.hide();
    })
  }
  getCostComponentByProductId() {
    this.spinner.show();
    this.accountsService.getCostComponentByProductId(this.product.productId).subscribe(res => {
      this.CostComponentByProductId = res.data.getCostComponentByProductId;
      this.spinner.hide();
    })
  }


  getViewProductParentById() {
    this.spinner.show();
    this.accountsService.getViewProductParentById(this.product.productId).subscribe(res => {
      this.ViewProductParentById = res.data;
      this.spinner.hide();
    })
  }
  getViewProductComponentsById() {
    this.spinner.show();
    this.accountsService.getViewProductComponentsById(this.product.productId).subscribe(res => {
      this.ViewProductComponentsById = res.data;
      this.spinner.hide();
    })
  }
  getRoutingsById() {
    this.spinner.show();
    this.accountsService.getRoutingsById(this.product.productId).subscribe(res => {
      this.RoutingsById = res.data;
      console.log("RoutingsById", this.RoutingsById);
      this.spinner.hide();
    })
  }
  getProductIds() {
    this.spinner.show();
    this.accountsService.getProductIds().subscribe(res => {
      this.getProductId = res.data[0].getProductId;
      this.spinner.hide();
      for (const value of this.getProductId) {
        this.getProductIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })
  }
  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency().then(data => {
        this.spinner.hide();
        this.currencyUomIdsArray = data.data.currencyList.map(value => {
          return {
            label: value.description,
            value: value.uomId
          };
        });
      });
  }
  addProductVariants(): void {
    this.spinner.show();
    this.accountsService.addProductVariants(this.virtualProduct.value.variantProId,this.virtualProduct.value.Hazmat).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton40.nativeElement.click();
        this.router.navigate(["/facilities/catalog/products/detail-product-summary/",this.virtualProduct.value.variantProId]);
        this.virtualProduct.reset();
       
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  submitData(): void {
    this.spinner.show();
    if (this.addAgreement.valid) {
      const agreementDate = this.addAgreement.get('agreementDate').value;
      this.agreementDate = this.datePipe.transform(agreementDate, "yyyy-MM-dd hh:mm:ss");
      const thruDate = this.addAgreement.get('thruDate').value;
      this.thruDate = this.datePipe.transform(thruDate, "yyyy-MM-dd hh:mm:ss");
      const fromDate = this.addAgreement.get('fromDate').value;
      this.fromDate = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");
      const requestData = {
        agreementDate: this.agreementDate,
        agreementTypeId: this.addAgreement.value.agreementTypeId.agreementTypeId,
        description: this.addAgreement.value.description,
        fromDate: this.fromDate,
        partyIdFrom: this.addAgreement.value.partyIdFrom.partyId,
        partyIdTo: this.addAgreement.value.partyIdTo.partyId,
        productId: this.addAgreement.value.productId.productId,
        roleTypeIdFrom: this.addAgreement.value.roleTypeIdFrom.roleTypeId,
        roleTypeIdTo: this.addAgreement.value.roleTypeIdTo.roleTypeId,
        textData: this.addAgreement.value.textData,
        thruDate: this.thruDate
      };
      this.CustomerAgreementsService.createAgreementAccnAR(requestData).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.closebutton.nativeElement.click();
          this.getAgreementTypeList();
         // this.router.navigate([`/financial/${this.agreementType}/customer-agreeement`]);
        } }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          } } });
    } else {
      this.spinner.hide();
      this.toastr.error('Please fill required field');
    }}
  getAgreementTypeList() {
    this.spinner.show();
    this.CustomerAgreementsService.getAgreementTypeList().subscribe((responce) => {
      this.getAgreementTypeListId = responce.data;
      this.spinner.hide();
      for (const value of this.getAgreementTypeListId) {
        this.getAgreementTypeListIdArray.push({
          label: value.description,
          value: value.agreementTypeId
        })
      }
      
    });
    this.spinner.hide();
  }
  getPersonsAndPartyGroups() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getPersonsAndPartyGroups().subscribe((responce) => {
      this.PersonsAndPartyGroupsListArray = responce.data.PersonsGroups.map(value => {
        return {
          label: value.partyId,
          value: value.partyId
        };
      });
    });
    this.spinner.hide();
  }

  getProducts() {
    this.spinner.show();
    const requestData = {
      internalName: '',
      internalNameSearchType: 'Contains',
      productId: '',
      productIdSearchType: 'Contains'
    };
    this.CustomerAgreementsService.getProducts(requestData).subscribe((responce) => {

      this.productListArray = responce.data.map(value => {
        return {
          label: value.productId,
          value: value.productId
        };
      });
    });
    this.spinner.hide();
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  


  createSalesAgreement(): void {
    this.spinner.show();
    const requestData = {
      "agreementItemTypeId": "",
      "agreementTypeId": "",
      "currencyUomId": this.addSalesAgreement.value.currency,
      "description": this.addSalesAgreement.value.desc,
      "fromDate": this.datePipe.transform(this.addSalesAgreement.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "partyIdFrom": this.addSalesAgreement.value.partyIDFrom,
      "partyIdTo": this.addSalesAgreement.value.partyIDTo,
      "price": this.addSalesAgreement.value.price,
      "productId": this.product.productId,
      "roleTypeIdFrom": "",
      "roleTypeIdTo": "",
      "thruDate": this.datePipe.transform(this.addSalesAgreement.value.throughDate, "yyyy-MM-dd HH:mm:ss"),
    }

    this.accountsService.createSalesAgreement(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getSalesAgreements();
        this.addSalesAgreement.reset();
        this.closebutton39.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  SupplierProduct() {
    this.spinner.show();
    this.accountsService.SupplierProduct(this.product.productId).subscribe(res => {
      this.SupplierProductById = res.data[0].SupplierProduct;
      this.spinner.hide();

    })
  }
  productAgreements() {
    this.spinner.show();
    this.accountsService.productAgreements(this.product.productId).subscribe(res => {
      this.productAgreementsById = res.data[0].getSalesAgreements;
      this.spinner.hide();

    })
  }
  getSalesAgreements() {
    this.spinner.show();
    this.accountsService.getSalesAgreements(this.product.productId).subscribe(res => {
      this.getSalesAgreementsById = res.data[0].getSalesAgreements;
      this.spinner.hide();

    })
  }
  commissionAgreements() {
    this.spinner.show();
    this.accountsService.commissionAgreements(this.product.productId).subscribe(res => {
      this.getcommissionAgreements = res.data[0].commissionAgreements;
      this.spinner.hide();

    })
  }
  getProductList(): void {
    this.spinner.show();
    this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 500 }, this.productForm.value)
      .then(data => {
        if (data.success) {
          this.products = data.data;
          this.spinner.hide();
        }
      });
     
  }
  updateNew(): void {
    this.spinner.show();
    if (this.createEditProductForm.valid) {
      this._ProductService.updateProduct({
        ...this.product,
        ...this.createEditProductForm.value, ...{
          salesDiscontinuationDate: moment(this.createEditProductForm.value.salesDiscontinuationDate).format('YYYY-MM-DD HH:MM:ss'),
          releaseDate: moment(this.createEditProductForm.value.releaseDate).format('YYYY-MM-DD HH:MM:ss'),
          introductionDate: moment(this.createEditProductForm.value.introductionDate).format('YYYY-MM-DD HH:MM:ss'),
          supportDiscontinuationDate: moment(this.createEditProductForm.value.supportDiscontinuationDate).format('YYYY-MM-DD HH:MM:ss')
        }
      })
        .then(data => {
          if (data.success) {
            this.toastr.success('Updated successfully');
            this.spinner.hide();
          }
        });
    }
  
  }
  submitProductNew(): void {
    this.spinner.show();
    if (this.editMode) {
      this.updateNew();
    } else {
      if (this.createEditProductForm.valid) {
        this._ProductService.createProduct({
          ...this.createEditProductForm.value, ...{
            salesDiscontinuationDate: moment(this.createEditProductForm.value.salesDiscontinuationDate).format('YYYY-MM-DD HH:MM:ss'),
            releaseDate: moment(this.createEditProductForm.value.releaseDate).format('YYYY-MM-DD HH:MM:ss'),
            introductionDate: moment(this.createEditProductForm.value.introductionDate).format('YYYY-MM-DD HH:MM:ss'),
            supportDiscontinuationDate: moment(this.createEditProductForm.value.supportDiscontinuationDate).format('YYYY-MM-DD HH:MM:ss')
          }
        })
          .then(data => {
            if (data.success) {
              this.toastr.success('Created successfully');
              this.spinner.hide();
              this.getProductList();
              this.closebutton37.nativeElement.click();
           
            }
          });
      }
    }
   
  }

  downloadBarCode(): void {
    this.spinner.show();
    this.downloading = true;
    
    this.accountsService.productBarCode(this.product.productId)
        .subscribe(value => {
            const blob = new Blob([value], { type: 'application/application/pdf' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;
            }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;
            this.spinner.hide();
           link.download = `${this.product.productId}.pdf`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            this.downloading = false;
        });
      
}

 
  deleteProductMaint(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "fixedAssetId": "",
          "productId": this.product.productId,
          "productMaintSeqId": product.productMaintSeqId,
          "view_INDEX_1": "",
          "view_SIZE_1": ""

        }
        this.accountsService.deleteProductMaint(req).subscribe(res => {
          this.getEditProductMaints();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateProductMaint(): void {
    this.spinner.show();
    const requestData = {
      "fixedAssetId": "",
      "intervalMeterTypeId": this.addMaintenance1.value.meterTypeID,
      "intervalQuantity": this.addMaintenance1.value.intQty,
      "intervalUomId": this.addMaintenance1.value.intUomID,
      "maintName": this.addMaintenance1.value.name,
      "maintTemplateWorkEffortId": this.addMaintenance1.value.workEffortID,
      "productId": this.product.productId,
      "productMaintSeqId": this.updateProductDataValuePatch.productMaintSeqId,
      "productMaintTypeId": this.addMaintenance1.value.typeID,
      "repeatCount": this.addMaintenance1.value.repeatCount,

    }

    this.accountsService.updateProductMaint(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getEditProductMaints();
        this.addMaintenance1.reset();
        this.closebutton36.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateProductDataValue(product) {
    this.updateProductDataValuePatch = product ;
    this.spinner.show();
    setTimeout(() => {

      const formValue = this.addMaintenance1;
      formValue.patchValue({
        typeID: this.updateProductDataValuePatch.productMaintTypeId,
        name: this.updateProductDataValuePatch.maintName,
        workEffortID: this.updateProductDataValuePatch.maintTemplateWorkEffortId,
        intQty: this.updateProductDataValuePatch.intervalQuantity,
        intUomID: this.updateProductDataValuePatch.intervalUomId,
        meterTypeID: this.updateProductDataValuePatch.intervalMeterTypeId,
        repeatCount: this.updateProductDataValuePatch.repeatCount,
      })

    }, 2000);
    this.spinner.hide();

  }
  createProductMaint(): void {
    this.spinner.show();
    const requestData = {
      "fixedAssetId": "",
      "intervalMeterTypeId": this.addMaintenance.value.meterTypeID,
      "intervalQuantity": this.addMaintenance.value.intQty,
      "intervalUomId": this.addMaintenance.value.intUomID,
      "maintName": this.addMaintenance.value.name,
      "maintTemplateWorkEffortId": this.addMaintenance.value.workEffortID,
      "productId": this.product.productId,
      "productMaintTypeId": this.addMaintenance.value.typeID,
      "repeatCount": this.addMaintenance.value.repeatCount,
    }

    this.accountsService.createProductMaint(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getEditProductMaints();
        this.addMaintenance.reset();
        this.closebutton35.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getEditProductMaints() {
    this.spinner.show();
    this.accountsService.getEditProductMaints(this.product.productId).subscribe(res => {
      this.EditProductMaints = res.data[0].getEditProductMaints;
      this.spinner.hide();

    })
  }
  getSubscriptionResourceIdNew() {
    this.spinner.show();
    this.accountsService.getSubscriptionResourceIdNew().subscribe(res => {
      this.SubscriptionResourceIdNew = res.data[0].data;
      this.spinner.hide();
      for (const value of this.SubscriptionResourceIdNew) {
        this.SubscriptionResourceIdNewArray.push({
          label: value.description,
          value: value.subscriptionResourceId
        })
      }
    })

  }
  getWorkEffortNameValue() {
    this.spinner.show();
    this.accountsService.getWorkEffortNameValue().subscribe(res => {
      this.WorkEffortNameValue = res.data[0].name;
      this.spinner.hide();
      for (const value of this.WorkEffortNameValue) {
        this.WorkEffortNameValueArray.push({
          label: value.workEffortName,
          value: value.workEffortId
        })
      }
    })

  }
  getProductMeterType() {
    this.spinner.show();
    this.accountsService.getProductMeterType().subscribe(res => {
      this.ProductMeterType = res.data[0].ProductMeterType;
      this.spinner.hide();
      for (const value of this.ProductMeterType) {
        this.ProductMeterTypeArray.push({
          label: value.description,
          value: value.productMeterTypeId
        })
      }
    })

  }
  getProductMaintType() {
    this.spinner.show();
    this.accountsService.getProductMaintType().subscribe(res => {
      this.ProductMaintType = res.data[0].ProductMaintType;
      this.spinner.hide();
      for (const value of this.ProductMaintType) {
        this.ProductMaintTypeArray.push({
          label: value.description,
          value: value.productMaintTypeId
        })
      }
    })

  }
  deleteProductSubscriptionResource(product) {
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "fromDate": this.fromDateCatePatch, 
          "productId": this.product.productId,
          "subscriptionResourceId": product.subscriptionResourceId,
          "view_INDEX_1": "",
          "view_SIZE_1": ""
        }
        this.myContactsService.deleteProductSubscriptionResource(req).subscribe((res: any) => {
          if (res.success) {
            this.getSubscriptionProductListBYId()
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateProductSubscriptionResource(): void {
    this.spinner.show();
    const requestData = {
      "automaticExtend": this.addSubsResource1.value.extendPeriod,
      "availableTime": this.addSubsResource1.value.availTime,
      "availableTimeUomId": this.addSubsResource1.value.availUomID,
      "canclAutmExtTime": this.addSubsResource1.value.autoExtension,
      "canclAutmExtTimeUomId": this.addSubsResource1.value.measureUnit,
      "fromDate": this.datePipe.transform(this.addSubsResource1.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "gracePeriodOnExpiry": this.addSubsResource1.value.graceExpiry,
      "gracePeriodOnExpiryUomId": this.addSubsResource1.value.graceExpiryUomID,
      "maxLifeTime": this.addSubsResource1.value.maxLifeTime,
      "maxLifeTimeUomId": this.addSubsResource1.value.lifeTimeUomID,
      "productId": this.product.productId,
      "purchaseFromDate": this.datePipe.transform(this.addSubsResource1.value.purchaseFromDate, "yyyy-MM-dd HH:mm:ss"),
      "purchaseThruDate": this.datePipe.transform(this.addSubsResource1.value.purchaseThroughDate, "yyyy-MM-dd HH:mm:ss"),
      "subscriptionResourceId": this.addSubsResource1.value.susResourceID,
      "thruDate": this.datePipe.transform(this.addSubsResource1.value.throughDate, "yyyy-MM-dd HH:mm:ss"),
      "useCountLimit": this.addSubsResource1.value.countLimit,
      "useRoleTypeId": this.addSubsResource1.value.useRoleTypeID,
      "useTime": this.addSubsResource1.value.useTime,
      "useTimeUomId": this.addSubsResource1.value.useUomID, 
    }
    this.myContactsService.updateProductSubscriptionResource(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getSubscriptionProductListBYId();
        this.closebutton34.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateResource(product) {
    this.updateResourcePatch = product ;
    this.spinner.show();
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    const throughDateCatePatch=product.thruDate;
    this.throughDateCatePatch=this.datePipe.transform(throughDateCatePatch,"yyyy-MM-dd");

    const fromDateCatePatch1=product.purchaseFromDate;
    this.fromDateCatePatch1=this.datePipe.transform(fromDateCatePatch1,"yyyy-MM-dd");
    const throughDateCatePatch1=product.purchaseThruDate;
    this.throughDateCatePatch1=this.datePipe.transform(throughDateCatePatch1,"yyyy-MM-dd");
    setTimeout(() => {
      const formValue = this.addSubsResource1;
      formValue.patchValue({
        susResourceID: this.updateResourcePatch.subscriptionResourceId,
        fromDate: this.fromDateCatePatch,
        throughDate: this.throughDateCatePatch,
        purchaseFromDate: this.fromDateCatePatch1,
        purchaseThroughDate: this.throughDateCatePatch1,
        maxLifeTime: this.updateResourcePatch.maxLifeTime,
        lifeTimeUomID: this.updateResourcePatch.maxLifeTimeUomId,
        availTime: this.updateResourcePatch.availableTime,
        availUomID: this.updateResourcePatch.availableTimeUomId,
        countLimit: this.updateResourcePatch.useCountLimit,
        useTime: this.updateResourcePatch.useTime,
        useUomID: this.updateResourcePatch.useTimeUomId,
        useRoleTypeID: this.updateResourcePatch.useRoleTypeId,
        extendPeriod: this.updateResourcePatch.automaticExtend,
        autoExtension: this.updateResourcePatch.canclAutmExtTime,
        measureUnit: this.updateResourcePatch.canclAutmExtTimeUomId,
        graceExpiry: this.updateResourcePatch.gracePeriodOnExpiry,
        graceExpiryUomID: this.updateResourcePatch.gracePeriodOnExpiryUomId,
      })

    }, 2000);
    this.spinner.hide();
  }
  createProductSubscriptionResourceSr(): void {
    this.spinner.show();

    const requestData = {
      "automaticExtend": this.addSubsResource.value.extendPeriod,
      "availableTime": this.addSubsResource.value.availTime,
      "availableTimeUomId": this.addSubsResource.value.availUomID,
      "canclAutmExtTime": this.addSubsResource.value.autoExtension,
      "canclAutmExtTimeUomId": this.addSubsResource.value.measureUnit,
      "fromDate": this.datePipe.transform(this.addSubsResource.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "gracePeriodOnExpiry": this.addSubsResource.value.graceExpiry,
      "gracePeriodOnExpiryUomId": this.addSubsResource.value.graceExpiryUomID,
      "maxLifeTime": this.addSubsResource.value.maxLifeTime,
      "maxLifeTimeUomId": this.addSubsResource.value.lifeTimeUomID,
      "productId": this.product.productId,
      "purchaseFromDate": this.datePipe.transform(this.addSubsResource.value.purchaseFromDate, "yyyy-MM-dd HH:mm:ss"),
      "purchaseThruDate": this.datePipe.transform(this.addSubsResource.value.purchaseThroughDate, "yyyy-MM-dd HH:mm:ss"),
      "subscriptionResourceId": this.addSubsResource.value.susResourceID,
      "thruDate": this.datePipe.transform(this.addSubsResource.value.throughDate, "yyyy-MM-dd HH:mm:ss"),
      "useCountLimit": this.addSubsResource.value.countLimit,
      "useRoleTypeId": this.addSubsResource.value.useRoleTypeID,
      "useTime": this.addSubsResource.value.useTime,
      "useTimeUomId": this.addSubsResource.value.useUomID,
    
    }
    this.myContactsService.createProductSubscriptionResourceSr(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getSubscriptionProductListBYId();
        this.addSubsResource.reset();
        this.closebutton33.nativeElement.click();
       
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getSubscriptionProductListBYId() {
    this.spinner.show();
    this.accountsService.getSubscriptionProductListBYId(this.product.productId).subscribe(res => {
      this.SubscriptionProductListBYId = res.data[0].data;
      this.spinner.hide();
    })
  }
  getSubscriptionResourceId() {
    this.spinner.show();
    this.myContactsService.getSubscriptionResourceId().subscribe(res => {
      this.subscriptionResourceId = res.data;
      this.spinner.hide();
      for (const value of this.subscriptionResourceId) {
        this.subscriptionResourceIdArray.push({
          label: value.description,
          value: value.subscriptionResourceId
        })
      }
    })

  }
  getUseRoleTypeId() {
    this.spinner.show();
    this.myContactsService.getUseRoleTypeId().subscribe(res => {
      this.RoleTypeId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.RoleTypeId) {
        this.RoleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
  getUseTimeUomId() {
    this.spinner.show();
    this.myContactsService.getUseTimeUomId().subscribe(res => {
      this.UseTimeUomId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.UseTimeUomId) {
        this.UseTimeUomIdArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
  }
  getProductOrderById() {
    this.spinner.show();
    this.accountsService.getProductOrderById(this.product.productId).subscribe(res => {
      this.ProductOrderById = res.data.getProductOrderById;
      this.spinner.hide();
    })
  }
  createCatalogCommunicationEvent(): void {
    this.spinner.show();
    const fromDateCate=this.addComm.get('startDate').value;
    this.fromDateCate=this.datePipe.transform(fromDateCate,"yyyy-MM-dd hh:mm:ss");
    const throughDateCate=this.addComm.get('finishDate').value;
    this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "action": "",
      "communicationEventTypeId": this.addComm.value.commTypeID,
      "contactListId": this.addComm.value.contactListID,
      "contactMechIdFrom": this.addComm.value.fromMech,
      "contactMechIdTo": this.addComm.value.toMech,
      "contactMechTypeId": this.addComm.value.mechTypeID,
      "content": this.addComm.value.content,
      "contentMimeTypeId": this.addComm.value.contentMimeTypeID,
      "datetimeEnded": this.throughDateCate,
      "datetimeStarted": this.fromDateCate,
      "messageId": this.addComm.value.msgID,
      "my": "",
      "note": this.addComm.value.note,
      "parentCommEventId": this.addComm.value.commEventID,
      "partyIdFrom": this.addComm.value.partyFrom,
      "partyIdTo": this.addComm.value.roleTypeID,
      "productId": this.product.productId,
      "reasonEnumId": this.addComm.value.reasonEnumID,
      "roleTypeIdFrom": this.addComm.value.roleTypeIDFrom,
      "roleTypeIdTo": this.addComm.value.roleTypeIDTo,
      "statusId": this.addComm.value.status,
      "subject": this.addComm.value.subject,
      "submitButton": ""
      }
     
    this.accountsService.createCatalogCommunicationEvent(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getCreateCommunicationEventById();
        this.addGroupOrder.reset();
        this.closebutton32.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getCreateCommunicationEventById() {
    this.spinner.show();
    this.accountsService.getCreateCommunicationEventById(this.product.productId).subscribe(res => {
      this.CreateCommunicationEventById = res.data.getCreateCommunication;
      this.spinner.hide();
    })
  }
  getReasonEnumIds() {
    this.spinner.show();
    this.accountsService.getReasonEnumIds().subscribe(res => {
      this.ReasonEnumIds = res.data;
      this.spinner.hide();
      for (const value of this.ReasonEnumIds) {
        this.ReasonEnumIdsArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  
  }
  getContactListId() {
    this.spinner.show();
    this.addSkillService.getContactListId().subscribe(res => {
      this.ContactListId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ContactListId) {
        this.ContactListIdArray.push({
          label: value.contactListId,
          value: value.contactListName
        })
      }
    })
  }
  getCatalogContentMimeTypeId() {
    this.spinner.show();
    this.accountsService.getCatalogContentMimeTypeId().subscribe(res => {
      this.CatalogContentMimeTypeId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.CatalogContentMimeTypeId) {
        this.CatalogContentMimeTypeIdArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      }
    })
  
  }
  getRoleTypeIdType() {
    this.spinner.show();
    this.accountsService.getRoleTypeIdType().subscribe(res => {
      this.RoleTypeIdType = res.data[0].node;
      this.spinner.hide();
      for (const value of this.RoleTypeIdType) {
        this.RoleTypeIdTypeArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  
  }
  getFromContactMechList() {
    this.spinner.show();
    this.accountsService.getFromContactMechList().subscribe(res => {
      this.FromContactMechList = res.data[0].contactMechData;
      this.spinner.hide();
      for (const value of this.FromContactMechList) {
        this.FromContactMechListArray.push({
          label: value.contactMechId,
          value: value.contactMechId
        })
      }
    })
  
  }
  getContactMechTypeID() {
    this.spinner.show();
    this.accountsService.getContactMechTypeID().subscribe(res => {
      this.ContactMechTypeID = res.data[0].getContactMechTypeID;
      this.spinner.hide();
      for (const value of this.ContactMechTypeID) {
        this.ContactMechTypeIDArray.push({
          label: value.description,
          value: value.contactMechTypeId
        })
      }
    })
  
  }
  getCommTypeID() {
    this.spinner.show();
    this.accountsService.getCommTypeID().subscribe(res => {
      this.CommTypeID = res.data[0].node;
      this.spinner.hide();
      for (const value of this.CommTypeID) {
        this.CommTypeIDArray.push({
          label: value.description,
          value: value.communicationEventTypeId
        })
      }
    })
  
  }
  getCataCommEventStatus() {
    this.spinner.show();
    this.accountsService.getCataCommEventStatus().subscribe(res => {
      this.CataCommEventStatus = res.data[0].node;
      this.spinner.hide();
      for (const value of this.CataCommEventStatus) {
        this.CataCommEventStatusArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
  
  }
  getCatalogCommunicationEventId() {
    this.spinner.show();
    this.accountsService.getCatalogCommunicationEventId().subscribe(res => {
      this.CatalogCommunicationEventId = res.data[0].node1;
      this.spinner.hide();
      for (const value of this.CatalogCommunicationEventId) {
        this.CatalogCommunicationEventIdArray.push({
          label: value.communicationEventId,
          value: value.communicationEventId
        })
      }
    })
  
  }
  deleteProductGroupOrder(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "groupOrderId": product.groupOrderId,
          "productId": product.partyId,
          "view_INDEX_1": "",
          "view_SIZE_1": ""

        }
        this.accountsService.deleteProductGroupOrder(req).subscribe(res => {
          this.getProductGroupOrderById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateProductGroupOrder(): void {
    this.spinner.show();
    const fromDateCate=this.addGroupOrder1.get('dealStartDate').value;
    this.fromDateCate=this.datePipe.transform(fromDateCate,"yyyy-MM-dd hh:mm:ss");
    const throughDateCate=this.addGroupOrder1.get('dealEndDate').value;
    this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "fromDate": this.fromDateCate,
      "productId": this.product.productId,
      "reqOrderQty": this.addGroupOrder1.value.dealQty,
      "soldOrderQty": "",
      "statusId":  "",
      "thruDate":  this.throughDateCate
      }
     
    this.accountsService.updateProductGroupOrder(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getProductGroupOrderById();
        this.closebutton31.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateProductGroupOrderPatch(product) {
    this.updateProductGroupOrderPatc = product ;
    this.spinner.show();
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    const throughDateCatePatch=product.thruDate;
    this.throughDateCatePatch=this.datePipe.transform(throughDateCatePatch,"yyyy-MM-dd");
      setTimeout(() => {
        const formValue = this.addGroupOrder1;
        formValue.patchValue({
            dealQty: this.updateProductGroupOrderPatc.reqOrderQty,
            dealStartDate: this.fromDateCatePatch,
            dealEndDate: this.throughDateCatePatch,
        })
      }, 2000);
      this.spinner.hide();
  }
  createProductGroupOrder(): void {
    this.spinner.show();
    const fromDateCate=this.addGroupOrder.get('dealStartDate').value;
    this.fromDateCate=this.datePipe.transform(fromDateCate,"yyyy-MM-dd hh:mm:ss");
    const throughDateCate=this.addGroupOrder.get('dealEndDate').value;
    this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "fromDate": this.fromDateCate,
      "productId": this.product.productId,
      "reqOrderQty": this.addGroupOrder.value.dealQty,
      "soldOrderQty": "",
      "statusId":  "",
      "thruDate":  this.throughDateCate
      }
     
    this.accountsService.createProductGroupOrder(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getProductGroupOrderById();
        this.addGroupOrder.reset();
        this.closebutton30.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getProductGroupOrderById() {
    this.spinner.show();
    this.accountsService.getProductGroupOrderById(this.product.productId).subscribe(res => {
      this.ProductGroupOrderById = res.data.getProductGroupOrderById;
      this.spinner.hide();
    })
  }
  removeProWorkEffortGoodStandard(product) {
    const fromDateCatePatchDel=product.Data.fromDate;
    this.fromDateCatePatchDel=this.datePipe.transform(fromDateCatePatchDel,"yyyy-MM-dd");
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "fromDate": this.fromDateCatePatchDel,
          "productId": product.partyId,
          "view_INDEX_1": "",
          "view_SIZE_1": "",
          "workEffortGoodStdTypeId": product.Data.workEffortGoodStdTypeId,
          "workEffortId": product.Data.workEffortId
        }
        this.accountsService.removeProWorkEffortGoodStandard(req).subscribe(res => {
          this.getEditProductWorkEfforts();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateProWorkEffortGoodStandard(): void {
    this.spinner.show();
    const fromDateCate=this.addWE1.get('fromDate').value;
    this.fromDateCate=this.datePipe.transform(fromDateCate,"yyyy-MM-dd hh:mm:ss");
    const throughDateCate=this.addWE1.get('throughDate').value;
    this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "estimatedCost": this.addWE1.value.estimatedCost,
      "estimatedQuantity":  this.addWE1.value.estimatedQty,
      "fromDate": this.fromDateCate,
      "productId": this.product.productId,
      "statusId": this.addWE1.value.status,
      "thruDate": this.throughDateCate,
      "workEffortGoodStdTypeId": this.addWE1.value.weGoodID,
      "workEffortId": this.addWE1.value.workEffortID
      }
     
    this.accountsService.updateProWorkEffortGoodStandard(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getEditProductWorkEfforts();
        this.closebutton29.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateProWorkEffortGoodStandardPtach(product) {
    this.updateProWorkEffortGoodStandardPatc = product ;
    this.spinner.show();
    const fromDateCatePatch=product.Data.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    const throughDateCatePatch=product.Data.thruDate;
    this.throughDateCatePatch=this.datePipe.transform(throughDateCatePatch,"yyyy-MM-dd");
      setTimeout(() => {
        const formValue = this.addWE1;
        formValue.patchValue({
              workEffortID: this.updateProWorkEffortGoodStandardPatc.Data.workEffortId,
              weGoodID: this.updateProWorkEffortGoodStandardPatc.Data.workEffortGoodStdTypeId,
              fromDate: this.fromDateCatePatch,
              throughDate: this.throughDateCatePatch,
              status: this.updateProWorkEffortGoodStandardPatc.Data.statusId,
              estimatedQty: this.updateProWorkEffortGoodStandardPatc.Data.estimatedQuantity,
              estimatedCost: this.updateProWorkEffortGoodStandardPatc.Data.estimatedCost,
        })
      }, 2000);
      this.spinner.hide();
  }
  createProWorkEffortGoodStandard(): void {
    this.spinner.show();
    const fromDateCate=this.addWE.get('fromDate').value;
    this.fromDateCate=this.datePipe.transform(fromDateCate,"yyyy-MM-dd hh:mm:ss");
    const throughDateCate=this.addWE.get('throughDate').value;
    this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "estimatedCost": this.addWE.value.estimatedCost,
      "estimatedQuantity":  this.addWE.value.estimatedQty,
      "fromDate": this.fromDateCate,
      "productId": this.product.productId,
      "statusId": this.addWE.value.status,
      "thruDate": this.throughDateCate,
      "workEffortGoodStdTypeId": this.addWE.value.weGoodID,
      "workEffortId": this.addWE.value.workEffortID
      }
     
    this.accountsService.createProWorkEffortGoodStandard(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getEditProductWorkEfforts();
        this.addWE.reset();
        this.closebutton28.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getEditProductWorkEfforts() {
    this.spinner.show();
    this.accountsService.getEditProductWorkEfforts(this.product.productId).subscribe(res => {
      this.EditProductWorkEfforts = res.data;
      this.spinner.hide();
    })
  }
  getProWorkEffortId() {
    this.spinner.show();
    this.accountsService.getProWorkEffortId().subscribe(res => {
      this.ProWorkEffortId = res.data[0].getWorkEffortId;
      this.spinner.hide();
      for (const value of this.ProWorkEffortId) {
        this.ProWorkEffortIdArray.push({
          label: value.workEffortId,
          value: value.workEffortId
        })
      }
    })
  
  }
  getWorkEffortGoodStdTypeIdDrop() {
    this.spinner.show();
    this.accountsService.getWorkEffortGoodStdTypeIdDrop().subscribe(res => {
      this.WorkEffortGoodStdTypeIdDrop = res.data;
      this.spinner.hide();
      for (const value of this.WorkEffortGoodStdTypeIdDrop) {
        this.WorkEffortGoodStdTypeIdDropArray.push({
          label: value.Data.description,
          value: value.Data.workEffortGoodStdTypeId
        })
      }
    })
  
  }
  removePartyFromProduct(product) {
    const fromDateCatePatchDel=product.fromDate;
    this.fromDateCatePatchDel=this.datePipe.transform(fromDateCatePatchDel,"yyyy-MM-dd");
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "fromDate":  this.fromDateCatePatchDel,
          "partyId": product.partyId,
          "productId": this.product.productId,
          "roleTypeId": product.roleTypeId,
          "view_INDEX_1": "",
          "view_SIZE_1": ""
        }
        this.accountsService.removePartyFromProduct(req).subscribe(res => {
          this.getPartyToProductById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updatePartyToProduct(): void {
    this.spinner.show();
    const fromDateCate=this.addAssocParty1.get('fromDate').value;
    this.fromDateCate=this.datePipe.transform(fromDateCate,"yyyy-MM-dd hh:mm:ss");
    const throughDateCate=this.addAssocParty1.get('throughDate').value;
    this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "comments": this.addAssocParty1.value.comments,
      "fromDate": this.fromDateCate,
      "partyId": this.addAssocParty1.value.partyID,
      "productId": this.product.productId,
      "roleTypeId": this.addAssocParty1.value.roleTypeID,
      "sequenceNum": this.addAssocParty1.value.seqNum,
      "thruDate":  this.throughDateCate
      }
     
    this.accountsService.updatePartyToProduct(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getPartyToProductById();
        this.closebutton27.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updatePartyToProductPtach(product) {
    this.updatePartyToProductPtac = product ;
    this.spinner.show();
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    const throughDateCatePatch=product.thruDate;
    this.throughDateCatePatch=this.datePipe.transform(throughDateCatePatch,"yyyy-MM-dd");
      setTimeout(() => {
        const formValue = this.addAssocParty1;
        formValue.patchValue({
            partyID: this.updatePartyToProductPtac.partyId,
            roleTypeID: this.updatePartyToProductPtac.roleTypeId,
            fromDate: this.fromDateCatePatch,
            throughDate: this.throughDateCatePatch,
            seqNum: this.updatePartyToProductPtac.sequenceNum,
            comments: this.updatePartyToProductPtac.comments,
        })
      }, 2000);
      this.spinner.hide();
  }
  addPartyToProduct(): void {
    this.spinner.show();
    const fromDateCate=this.addAssocParty.get('fromDate').value;
    this.fromDateCate=this.datePipe.transform(fromDateCate,"yyyy-MM-dd hh:mm:ss");
    const throughDateCate=this.addAssocParty.get('throughDate').value;
    this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "comments": this.addAssocParty.value.comments,
      "fromDate": this.fromDateCate,
      "partyId": this.addAssocParty.value.partyID,
      "productId": this.product.productId,
      "roleTypeId": this.addAssocParty.value.roleTypeID,
      "sequenceNum": this.addAssocParty.value.seqNum,
      "thruDate":  this.throughDateCate
      }
     
    this.accountsService.addPartyToProduct(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getPartyToProductById();
        this.addAssocParty.reset();
        this.closebutton26.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getPartyToProductById() {
    this.spinner.show();
    this.accountsService.getPartyToProductById(this.product.productId).subscribe(res => {
      this.PartyToProductById = res.data.getPartyToProductById;
      this.spinner.hide();
    })
  }
  getRoleTypeId() {
    this.spinner.show();
    this.accountsService.getRoleTypeId().subscribe(res => {
      this.RoleTypeId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.RoleTypeId) {
        this.RoleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  
  }
  deleteVendorProduct(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "productId": this.product.productId,
          "productStoreGroupId": product.productStoreGroupId,
          "vendorPartyId": product.vendorPartyId
        }
        this.accountsService.deleteVendorProduct(req).subscribe(res => {
          this.getVendorProductByID();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  createVendorProduct(): void {
    this.spinner.show();
    const requestData ={
      "productId": this.product.productId,
      "productStoreGroupId": this.addVendor.value.groupID,
      "vendorPartyId": this.addVendor.value.partyID
      }
     
    this.accountsService.createVendorProduct(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getVendorProductByID();
        this.addVendor.reset();
        this.closebutton25.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getVendorProductByID() {
    this.spinner.show();
    this.accountsService.getVendorProductByID(this.product.productId).subscribe(res => {
      this.VendorProductByID = res.data.getVendorProductByID;
      this.spinner.hide();
    })
  }
  getProductStoreGroupID() {
    this.spinner.show();
    this.accountsService.getProductStoreGroupID().subscribe(res => {
      this.ProductStoreGroupID = res.data.getProductStoreGroupID;
      this.spinner.hide();
      for (const value of this.ProductStoreGroupID) {
        this.ProductStoreGroupIDArray.push({
          label: value.description,
          value: value.productStoreGroupId
        })
      }
    })
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  
  }
 
  deleteProductMeter(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "productId": this.product.productId,
          "productMeterTypeId": product.productMeterTypeId,
          "view_INDEX_1": "",
          "view_SIZE_1": ""
        }
        this.accountsService.deleteProductMeter(req).subscribe(res => {
          this.getEditProductMetersByID();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateProductMeter(): void {
    this.spinner.show();
    const requestData ={
      "meterName": this.addMeter1.value.meterName,
      "meterUomId": this.addMeter1.value.meterUomID,
      "productId": this.product.productId,
      "productMeterTypeId": this.addMeter1.value.meterTypeID
      }
     
    this.accountsService.updateProductMeter(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getEditProductMetersByID();
        this.closebutton24.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateProductMeterPatch(product) {
    this.updateProductMeterPatc = product ;
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.addMeter1;
        formValue.patchValue({
            meterTypeID: this.updateProductMeterPatc.productMeterTypeId,
            meterUomID: this.updateProductMeterPatc.meterUomId,
            meterName: this.updateProductMeterPatc.meterName,
        })
      }, 2000);
      this.spinner.hide();
  }
  createProductMeter(): void {
    this.spinner.show();
    const requestData ={
      "meterName": this.addMeter.value.meterName,
      "meterUomId": this.addMeter.value.meterUomID,
      "productId": this.product.productId,
      "productMeterTypeId": this.addMeter.value.meterTypeID
      }
     
    this.accountsService.createProductMeter(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getEditProductMetersByID();
        this.addMeter.reset();
        this.closebutton23.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getEditProductMetersByID() {
    this.spinner.show();
    this.accountsService.getEditProductMetersByID(this.product.productId).subscribe(res => {
      this.EditProductMetersByID = res.data.getVendorProductByID;
      this.spinner.hide();
    })
  }
  getCurrencyRate() {
    this.spinner.show();
    this.myContactsService.getCurrencyDataList().subscribe(res => {
      const currencyList = res.data.currencyList;
      this.spinner.hide();
      for (const value of currencyList) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })

  }
  getProductMeterTypeId() {
    this.spinner.show();
    this.accountsService.getProductMeterTypeId().subscribe(res => {
      this.ProductMeterTypeId = res.data.getProductMeterTypeId;
      this.spinner.hide();
      for (const value of this.ProductMeterTypeId) {
        this.ProductMeterTypeIdArray.push({
          label: value.description,
          value: value.productMeterTypeId
        })
      }
    })
  }
  deleteProductPaymentMethodType(product) {
    const fromDateCatePatchDel=product.fromDate;
    this.fromDateCatePatchDel=this.datePipe.transform(fromDateCatePatchDel,"yyyy-MM-dd");
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "paymentMethodTypeId": product.paymentMethodTypeId,
          "productId": this.product.productId,
          "productPricePurposeId": product.productPricePurposeId
        }
        this.accountsService.deleteProductPaymentMethodType(req).subscribe(res => {
          this.getProductPaymentMethodAll();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateProductPaymentMethodType(): void {
    this.spinner.show();
    const fromDateCate=this.addPaymentType1.get('fromDate').value;
    this.fromDateCate=this.datePipe.transform(fromDateCate,"yyyy-MM-dd hh:mm:ss");
    const throughDateCate=this.addPaymentType1.get('throughDate').value;
    this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "fromDate": this.fromDateCate,
      "paymentMethodTypeId": this.addPaymentType1.value.paymentType,
      "productId": this.product.productId,
      "productPricePurposeId": this.addPaymentType1.value.purpose,
      "sequenceNum": this.addPaymentType1.value.seqNum,
      "thruDate": this.throughDateCate
      }
     
    this.accountsService.updateProductPaymentMethodType(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getProductPaymentMethodAll();
        this.closebutton20.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateProductPaymentMethodTypePatch(product) {
    this.updateProductPaymentMethodTypePatc = product ;
    this.spinner.show();
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    const throughDateCatePatch=product.thruDate;
    this.throughDateCatePatch=this.datePipe.transform(throughDateCatePatch,"yyyy-MM-dd");
      setTimeout(() => {
        const formValue = this.addPaymentType1;
        formValue.patchValue({
          paymentType: this.updateProductPaymentMethodTypePatc.paymentMethodTypeId,
          purpose: this.updateProductPaymentMethodTypePatc.productPricePurposeId,
          fromDate: this.fromDateCatePatch,
          throughDate: this.throughDateCatePatch,
          seqNum: this.updateProductPaymentMethodTypePatc.sequenceNum,
        })
      }, 2000);
      this.spinner.hide();
  }
  createProductPaymentMethodType(): void {
    this.spinner.show();
    const fromDateCate=this.addPaymentType.get('fromDate').value;
    this.fromDateCate=this.datePipe.transform(fromDateCate,"yyyy-MM-dd hh:mm:ss");
    const throughDateCate=this.addPaymentType.get('throughDate').value;
    this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "fromDate": this.fromDateCate,
      "paymentMethodTypeId": this.addPaymentType.value.paymentType,
      "productId": this.product.productId,
      "productPricePurposeId": this.addPaymentType.value.purpose,
      "sequenceNum": this.addPaymentType.value.seqNum,
      "thruDate": this.throughDateCate
      }
     
    this.accountsService.createProductPaymentMethodType(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getProductPaymentMethodAll();
        this.addPaymentType.reset();
        this.closebutton19.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getProductPaymentMethodAll() {
    this.spinner.show();
    this.accountsService.getProductPaymentMethodAll(this.product.productId).subscribe(res => {
      this.ProductPaymentMethodAll = res.data.getProductAttributeById;
      this.spinner.hide();
    })
  }
  paymentMethodType() {
    this.spinner.show();
    this.accountsService.paymentMethodType().subscribe(res => {
      this.getpaymentMethodType = res.data;
      this.spinner.hide();
      for (const value of this.getpaymentMethodType) {
        this.getpaymentMethodTypeArray.push({
          label: value.description,
          value: value.paymentMethodTypeId
        })
      }
    })
  }
  productPricePurpose() {
    this.spinner.show();
    this.accountsService.productPricePurpose().subscribe(res => {
      this.productPricePurposeId = res.data;
      this.spinner.hide();
      for (const value of this.productPricePurposeId) {
        this.productPricePurposeIdArray.push({
          label: value.description,
          value: value.productPricePurposeId
        })
      }
    })
  }
  removeGlProductAccount(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "glAccountId": product.glAccountId,
          "glAccountTypeId": product.glAccountTypeId,
          "organizationPartyId": product.organizationPartyId,
          "productId": this.product.productId,
          
        }
        this.accountsService.removeGlProductAccount(req).subscribe(res => {
          this.getProductGlAccountDetail();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateGlProductAccount(): void {
    this.spinner.show();
    const requestData ={
      "glAccountId": this.addGL1.value.glAcc,
      "glAccountTypeId": this.addGL1.value.accType,
      "organizationPartyId": this.addGL1.value.organization,
      "productId": this.product.productId
      }
     
    this.accountsService.updateGlProductAccount(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getProductGlAccountDetail();
        this.closebutton18.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateGlProductAccountPatch(product) {
    this.updateGlProductAccountPatc = product ;
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.addGL1;
        formValue.patchValue({
          accType: this.updateGlProductAccountPatc.glAccountTypeId,
          organization: this.updateGlProductAccountPatc.organizationPartyId,
          glAcc: this.updateGlProductAccountPatc.glAccountId,
        })
      }, 2000);
      this.spinner.hide();
  }
  createProductGlAccount(): void {
    this.spinner.show();
    const requestData ={
      "glAccountId": this.addGL.value.glAcc,
      "glAccountTypeId": this.addGL.value.accType,
      "organizationPartyId": this.addGL.value.organization,
      "productId": this.product.productId
      }
     
    this.accountsService.createProductGlAccount(requestData).subscribe((res: any) => { 
      console.log("ProductGlAccountDetail_reponse",res);
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getProductGlAccountDetail();
        this.addGL.reset();
        this.closebutton17.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getProductGlAccountDetail() {
    this.spinner.show();
    this.accountsService.getProductGlAccountDetail(this.product.productId).subscribe(res => {
      this.ProductGlAccountDetail = res.data.productGlAccountDetail;
      console.log("ProductGlAccountDetail",this.ProductGlAccountDetail);
      this.spinner.hide();
    })
  }
  getAccountType() {
    this.spinner.show();
    this.accountsService.getAccountType().subscribe(res => {
      this.AccountType = res.data.getAccountType;
      this.spinner.hide();
      for (const value of this.AccountType) {
        this.AccountTypeArray.push({
          label: value.description,
          value: value.glAccountTypeId
        })
      }
    })
  }
  getOrganization() {
    this.spinner.show();
    this.accountsService.getOrganization().subscribe(res => {
      this.OrganizationId = res.data.getOrganization;
      this.spinner.hide();
      for (const value of this.OrganizationId) {
        this.OrganizationIdArray.push({
          label: value.groupName+"["+value.partyId+"]",
          value: value.partyId
        })
      }
    })
  }
  getGLAccount() {
    this.spinner.show();
    this.accountsService.getGLAccount().subscribe(res => {
      this.GLAccountId = res.data.getOrganization;
      this.spinner.hide();
      for (const value of this.GLAccountId) {
        this.GLAccountIdArray.push({
          label: value.accountCode+" "+value.accountName+" ",
          value: value.accountCode
        })
      }
    })
  }
  deleteProductFacilityLocation(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "facilityId": product.facilityId,
          "locationSeqId": product.locationSeqId,
          "productId": this.product.productId,
          "view_INDEX_1": "",
          "view_SIZE_1": ""
        }
        this.accountsService.deleteProductFacilityLocation(req).subscribe(res => {
          this.getProductFacilityLocationById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateProductFacilityLocation(): void {
    this.spinner.show();
    const requestData ={
      "facilityId": this.addLocation1.value.facilityID,
      "locationSeqId": this.addLocation1.value.locSeqID,
      "productId": this.product.productId,
      }
     
    this.accountsService.updateProductFacilityLocation(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getProductFacilityLocationById();
        this.closebutton16.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateProductFacilityLocationPatch(product) {
    this.updateProductFacilityLocationPatc = product ;
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.addLocation1;
        formValue.patchValue({
            facilityID: this.updateProductFacilityLocationPatc.facilityId,
            locSeqID: this.updateProductFacilityLocationPatc.locationSeqId,
            minStock: this.updateProductFacilityLocationPatc.minimumStock,
            moveQty: this.updateProductFacilityLocationPatc.moveQuantity,
        })
      }, 2000);
      this.spinner.hide();
  }
  createProductFacilityLocation(): void {
    this.spinner.show();
    const requestData ={
      "facilityId": this.addLocation.value.facilityID,
      "locationSeqId": this.addLocation.value.locSeqID,
      "minimumStock": this.addLocation.value.minStock,
      "moveQuantity": this.addLocation.value.moveQty,
      "productId": this.product.productId
      }
     
    this.accountsService.createProductFacilityLocation(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getProductFacilityLocationById();
        this.addLocation.reset();
        this.closebutton15.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getProductFacilityLocationById() {
    this.spinner.show();
    this.accountsService.getProductFacilityLocationById(this.product.productId).subscribe(res => {
      this.ProductFacilityLocationById = res.data.getProductFacilityLocationById;
      this.spinner.hide();
    })
  }
  getLocationSeqId() {
    this.spinner.show();
    this.accountsService.getLocationSeqId().subscribe(res => {
      this.LocationSeqId = res.data.getFacilityId;
      this.spinner.hide();
      for (const value of this.LocationSeqId) {
        this.LocationSeqIdArray.push({
          label: value.locationSeqId,
          value: value.facilityId
        })
      }
    })
  }
  deleteProductFacility(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "facilityId": product.facilityId,
          "productId": this.product.productId,
          "view_INDEX_1": "",
          "view_SIZE_1": ""
        }
        this.accountsService.deleteProductFacility(req).subscribe(res => {
          this.getProductFacilityById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateProductFacility(): void {
    this.spinner.show();
    const requestData ={
      "daysToShip": this.addFacility1.value.dayToShip,
      "facilityId": this.addFacility1.value.facilityID,
      "lastInventoryCount": "",
      "minimumStock": this.addFacility1.value.minStock,
      "productId": this.product.productId,
      "reorderQuantity": this.addFacility1.value.reorderQuantity,
      "replenishMethodEnumId": this.addFacility1.value.replenishEnumID,
      "requirementMethodEnumId": this.addFacility1.value.reqEnumID
      }
     
    this.accountsService.updateProductFacility(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getProductFacilityById();
        this.closebutton14.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateProductFacilityPatch(product) {
    this.updateProductFacilityPatc = product ;
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.addFacility1;
        formValue.patchValue({
            facilityID: this.updateProductFacilityPatc.facilityId,
            minStock: this.updateProductFacilityPatc.minimumStock,
            reorderQuantity: this.updateProductFacilityPatc.reorderQuantity,
            dayToShip: this.updateProductFacilityPatc.daysToShip,
            replenishEnumID: this.updateProductFacilityPatc.replenishMethodEnumId,
            reqEnumID: this.updateProductFacilityPatc.requirementMethodEnumId,
        })
      }, 2000);
      this.spinner.hide();
  }
  createProductFacility(): void {
    this.spinner.show();
    const requestData ={
      "daysToShip": this.addFacility.value.dayToShip,
      "facilityId": this.addFacility.value.facilityID,
      "lastInventoryCount": "",
      "minimumStock": this.addFacility.value.minStock,
      "productId": this.product.productId,
      "reorderQuantity": this.addFacility.value.reorderQuantity,
      "replenishMethodEnumId": this.addFacility.value.replenishEnumID,
      "requirementMethodEnumId": this.addFacility.value.reqEnumID
      }
     
    this.accountsService.createProductFacility(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getProductFacilityById();
        this.addFacility.reset();
        this.closebutton13.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getProductFacilityById() {
    this.spinner.show();
    this.accountsService.getProductFacilityById(this.product.productId).subscribe(res => {
      this.ProductFacilityById = res.data.getProductAttributeById;
      this.spinner.hide();
    })
  }
  getProductFacilityId() {
    this.spinner.show();
    this.accountsService.getProductFacilityId().subscribe(res => {
      this.ProductFacilityId = res.data.getFacilityId;
      this.spinner.hide();
      for (const value of this.ProductFacilityId) {
        this.ProductFacilityIdArray.push({
          label: value.facilityName,
          value: value.facilityId
        })
      }
    })
  }
  deleteProductAttribute(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "attrName":product.attrName,
          "productId": this.product.productId,
          "view_INDEX_1": "",
          "view_SIZE_1": ""
        }
        this.accountsService.deleteProductAttribute(req).subscribe(res => {
          this.getProductAttributeById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateProductAttribute(): void {
    this.spinner.show();
    const requestData ={
      "attrDescription": this.addAttr1.value.desc,
      "attrName": this.addAttr1.value.name,
      "attrType": this.addAttr1.value.type,
      "attrValue": this.addAttr1.value.value,
      "productId": this.product.productId
      }
     
    this.accountsService.updateProductAttribute(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getProductAttributeById();
        this.closebutton12.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateProductAttributePatch(product) {
    this.updateProductAttributePatc = product ;
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.addAttr1;
        formValue.patchValue({
          name: this.updateProductAttributePatc.attrName,
          value: this.updateProductAttributePatc.attrValue,
          type: this.updateProductAttributePatc.attrType,
          desc: this.updateProductAttributePatc.attrDescription,
        })
      }, 2000);
      this.spinner.hide();
  }
  createProductAttribute(): void {
    this.spinner.show();
    const requestData ={
      "attrDescription": this.addAttr.value.desc,
      "attrName": this.addAttr.value.name,
      "attrType": this.addAttr.value.type,
      "attrValue": this.addAttr.value.value,
      "productId": this.product.productId
      }
     
    this.accountsService.createProductAttribute(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getProductAttributeById();
        this.addAttr.reset();
        this.closebutton11.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getProductAttributeById() {
    this.spinner.show();
    this.accountsService.getProductAttributeById(this.product.productId).subscribe(res => {
      this.ProductAttributeById = res.data.getProductAttributeById;
      this.spinner.hide();
    })
  }
  deleteProductAssoc(product) {
    const fromDateCatePatchDel=product.fromDate;
    this.fromDateCatePatchDel=this.datePipe.transform(fromDateCatePatchDel,"yyyy-MM-dd");
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "fromDate": this.fromDateCatePatchDel,
          "lastProductId": this.product.productId,
          "productAssocTypeId": product.productAssocTypeId,
          "productId": product.productId,
          "productIdTo": product.productIdTo,
          "view_INDEX_2": "",
          "view_SIZE_2": ""
        }
        this.accountsService.deleteProductAssoc(req).subscribe(res => {
          this.getProductAssocById();
          this.getProductAssocByIdSec();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateProductAssoc(): void {
    this.spinner.show();
    const fromDateCate=this.addAssoc1.get('fromDate').value;
    this.fromDateCate=this.datePipe.transform(fromDateCate,"yyyy-MM-dd hh:mm:ss");
    const throughDateCate=this.addAssoc1.get('throughDate').value;
    this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "_useRowSubmit": "",
      "estimateCalcMethod": "",
      "fromDate": this.fromDateCate,
      "instruction": this.addAssoc1.value.instruction,
      "productAssocTypeId": this.addAssoc1.value.assocType,
      "productId": this.addAssoc1.value.proID,
      "productIdTo": this.addAssoc1.value.proIdTo,
      "quantity": this.addAssoc1.value.qty,
      "reason": this.addAssoc1.value.reason,
      "recurrenceInfoId": "",
      "routingWorkEffortId": "",
      "scrapFactor": "",
      "sequenceNum": this.addAssoc1.value.seqNum,
      "thruDate": this.throughDateCate,
      "viewIndex_0": "",
      "viewSize_0": ""
      }
     
    this.accountsService.updateProductAssoc(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getProductAssocById();
        this.getProductAssocByIdSec();
        this.closebutton10.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateProductAssocPatch(product) {
    this.updateProductAssocPatc = product ;
    this.spinner.show();
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    const throughDateCatePatch=product.thruDate;
    this.throughDateCatePatch=this.datePipe.transform(throughDateCatePatch,"yyyy-MM-dd");
      setTimeout(() => {
        const formValue = this.addAssoc1;
        formValue.patchValue({
          proID: this.updateProductAssocPatc.productId,
          proIdTo: this.updateProductAssocPatc.productIdTo,
          assocType: this.updateProductAssocPatc.productAssocTypeId,
          fromDate: this.fromDateCatePatch,
          throughDate: this.throughDateCatePatch,
          seqNum: this.updateProductAssocPatc.sequenceNum,
          reason: this.updateProductAssocPatc.reason,
          instruction: this.updateProductAssocPatc.instruction,
          qty:this.updateProductAssocPatc.quantity,
        })
      }, 2000);
      this.spinner.hide();
  }
  createProductAssoc(): void {
    this.spinner.show();
    const fromDateCate=this.addAssoc.get('fromDate').value;
    this.fromDateCate=this.datePipe.transform(fromDateCate,"yyyy-MM-dd hh:mm:ss");
    const throughDateCate=this.addAssoc.get('throughDate').value;
    this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "_useRowSubmit": "",
      "estimateCalcMethod": "",
      "fromDate": this.fromDateCate,
      "instruction": this.addAssoc.value.instruction,
      "productAssocTypeId": this.addAssoc.value.assocType,
      "productId": this.addAssoc.value.proID,
      "productIdTo": this.addAssoc.value.proIdTo,
      "quantity": this.addAssoc.value.qty,
      "reason": this.addAssoc.value.reason,
      "recurrenceInfoId": "",
      "routingWorkEffortId": "",
      "scrapFactor": "",
      "sequenceNum": this.addAssoc.value.seqNum,
      "thruDate": this.throughDateCate,
      "viewIndex_0": "",
      "viewSize_0": ""
      }
     
    this.accountsService.createProductAssoc(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getProductAssocById();
        this.getProductAssocByIdSec();
        this.closebutton9.nativeElement.click();
        this.router.navigate(["/facilities/catalog/products/detail-product-summary/",this.addAssoc.value.proID]);
        this.addAssoc.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getProductAssocById() {
    this.spinner.show();
    this.accountsService.getProductAssocById(this.product.productId).subscribe(res => {
      this.ProductAssocById = res.data.getProductAssocById;
      this.spinner.hide();
    })
  }
  getProductAssocByIdSec() {
    this.spinner.show();
    this.accountsService.getProductAssocByIdSec(this.product.productId).subscribe(res => {
      this.ProductAssocByIdSec = res.data.getProductAssocById;
      this.spinner.hide();
    })
  }
  getCatalogProductId() {
    this.spinner.show();
    this.accountsService.getCatalogProductId().subscribe(res => {
      this.CatalogProductId = res.data[0].Product;
      this.spinner.hide();
      for (const value of this.CatalogProductId) {
        this.CatalogProductIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })
  }
  getProductAssocType() {
    this.spinner.show();
    this.accountsService.getProductAssocType().subscribe(res => {
      this.ProductAssocType = res.data.getProductAssocType;
      this.spinner.hide();
      for (const value of this.ProductAssocType) {
        this.ProductAssocTypeArray.push({
          label: value.description,
          value: value.productAssocTypeId
        })
      }
    })
  }
  deleteProductKeyword(productId,keywordTypeId,keyword): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteProductKeyword(productId,keywordTypeId,keyword).subscribe(res => {
          if (res['data'].responseMessage === 'success') {
          }
          this.getKeywordTypeIdsKeyword();
          this.getKeywordTypeIdsTag();
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateProductKeyword(): void {
    this.spinner.show();
    const requestData ={
      "keyword": this.addKeyword1.value.keyword,
      "keywordTypeId": this.addKeyword1.value.typeID,
      "productId": this.product.productId,
      "relevancyWeight":this.addKeyword1.value.relWeight,
      "statusId": this.addKeyword1.value.statusID
      }
     
    this.accountsService.updateProductKeyword(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getKeywordTypeIdsKeyword();
        this.getKeywordTypeIdsTag();
        this.closebutton8.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateProductKeywPatch(product) {
    this.updateProductKeywPatc = product ;
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.addKeyword1;
        formValue.patchValue({
          keyword: this.updateProductKeywPatc.keyword,
          typeID: this.updateProductKeywPatc.keywordTypeId,
          relWeight:  this.updateProductKeywPatc.relevancyWeight,
          statusID:  this.updateProductKeywPatc.statusId,
        })
      }, 2000);
      this.spinner.hide();
  }
  createProductKeyword(): void {
    this.spinner.show();
    const requestData ={
      "keyword": this.addKeyword.value.keyword,
      "keywordTypeId": this.addKeyword.value.typeID,
      "productId": this.product.productId,
      "relevancyWeight":this.addKeyword.value.relWeight,
      "statusId": this.addKeyword.value.statusID
      }
     
    this.accountsService.createProductKeyword(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getKeywordTypeIdsKeyword();
        this.getKeywordTypeIdsTag();
        this.addKeyword.reset();
        this.closebutton7.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  keywordTypeIds() {
    this.spinner.show();
    this.accountsService.keywordTypeIds().subscribe(res => {
      this.getkeywordTypeIds = res.data;
      this.spinner.hide();
      for (const value of this.getkeywordTypeIds) {
        this.getkeywordTypeIdsArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }
  statusIds() {
    this.spinner.show();
    this.accountsService.statusIds().subscribe(res => {
      this.getstatusIds = res.data;
      this.spinner.hide();
      for (const value of this.getstatusIds) {
        this.getstatusIdsArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
  }
  getKeywordTypeIdsTag() {
    this.spinner.show();
    this.accountsService.getKeywordTypeIdsTag(this.product.productId).subscribe(res => {
      this.KeywordTypeIdsTag = res.data[0].getKeywordTypeIdsTag;
      this.spinner.hide();
    })
  }
  getKeywordTypeIdsKeyword() {
    this.spinner.show();
    this.accountsService.getKeywordTypeIdsKeyword(this.product.productId).subscribe(res => {
      this.KeywordTypeIdsKeyword = res.data[0].getKeywordTypeIdsKeyword;
      this.spinner.hide();
    })
  }
  getproductsKeywordById() {
    this.spinner.show();
    this.accountsService.getproductsKeywordById(this.product.productId).subscribe(res => {
      this.productsKeywordById = res.data;
      this.spinner.hide();
    })
  }
  updateProductToCategory(): void {
    this.spinner.show();
    const fromDateCate=this.addCategory.get('fromDate').value;
    this.fromDateCate=this.datePipe.transform(fromDateCate,"yyyy-MM-dd hh:mm:ss");
    const throughDateCate=this.addCategory.get('throughDate').value;
    this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "comments": this.addCategory.value.comments,
      "fromDate": this.fromDateCate,
      "productCategoryId": this.addCategory.value.categoryID,
      "productId":  this.product.productId,
      "quantity": this.addCategory.value.qty,
      "sequenceNum": this.addCategory.value.seqNum,
      "thruDate": this.throughDateCate
      }
     
    this.accountsService.updateProductToCategory(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getAddProductToCategoryById();
        this.closebutton6.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateProductToCat(product) {
    this.spinner.show();
    this.updateProductToCate = product ;
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    const throughDateCatePatch=product.thruDate;
    this.throughDateCatePatch=this.datePipe.transform(throughDateCatePatch,"yyyy-MM-dd");
   
      setTimeout(() => {
        const formValue = this.addCategory;
        formValue.patchValue({
          categoryID: this.updateProductToCate.productCategoryId,
          fromDate: this.fromDateCatePatch,
          throughDate: this.throughDateCatePatch,
          comments: this.updateProductToCate.comments,
          seqNum: this.updateProductToCate.sequenceNum,
          qty: this.updateProductToCate.quantity,
        })
      }, 2000);
      this.spinner.hide();
  }
  removeProductFromCategoryNew(product) {
    const fromDateCatePatchDel=product.fromDate;
    this.fromDateCatePatchDel=this.datePipe.transform(fromDateCatePatchDel,"yyyy-MM-dd");
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "productCategoryId": product.productCategoryId,
          "productId": this.product.productId
        }
        this.accountsService.removeProductFromCategoryNew(req).subscribe(res => {
          this.getAddProductToCategoryById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deleteProductGeo(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "geoId":product.geoId,
          "productId": this.product.productId,
          "view_INDEX_1": "",
          "view_SIZE_1": ""
        }
        this.accountsService.deleteProductGeo(req).subscribe(res => {
          this.getCreateProductGeoById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deleteGoodIdentification(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "goodIdentificationTypeId":product.goodIdentificationTypeId,
          "productId": this.product.productId,
          "view_INDEX_1": "",
          "view_SIZE_1": ""
        }
        this.accountsService.deleteGoodIdentification(req).subscribe(res => {
          this.getGoodIdentificationById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateGoodIdenti(product) {
    this.spinner.show();
    this.patchupdateGoodIdenti = product ;
   
      setTimeout(() => {
        const formValue = this.addGoodID1;
        formValue.patchValue({
          idType: this.patchupdateGoodIdenti.goodIdentificationTypeId,
          idValue: this.patchupdateGoodIdenti.idValue,
        })
      }, 2000);
      this.spinner.hide();
  }
  updateGoodIdentification(): void {
    this.spinner.show();
    const requestData ={
      "goodIdentificationTypeId": this.addGoodID1.value.idType,
      "idValue": this.addGoodID1.value.idValue,
      "productId":  this.product.productId
      }
     
    this.accountsService.updateGoodIdentification(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getGoodIdentificationById();
        this.addGoodID1.reset();
        this.closebutton4.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateProductGeo(): void {
    this.spinner.show();
    const requestData ={
      "description": this.addGeo.value.desc,
      "geoId":  this.addGeo.value.geoID,
      "productGeoEnumId":  this.addGeo.value.enumID,
      "productId":  this.product.productId
      }
     
    this.accountsService.updateProductGeo(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getCreateProductGeoById();
        this.addGeo.reset();
        this.closebutton2.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateGeo(product) {
    this.spinner.show();
    this.updateGeoProduct = product ;
   
      setTimeout(() => {
        const formValue = this.addGeo;
        formValue.patchValue({
          geoID: this.updateGeoProduct.geoId,
          enumID: this.updateGeoProduct.productGeoEnumId,
          desc: this.updateGeoProduct.description,
        })
      }, 2000);
      this.spinner.hide();
  }
  createProductGeo(): void {
    this.spinner.show();
    const requestData ={
      "description": this.addGeo.value.desc,
      "geoId":  this.addGeo.value.geoID,
      "productGeoEnumId":  this.addGeo.value.enumID,
      "productId":  this.product.productId
      }
     
    this.accountsService.createProductGeo(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getCreateProductGeoById();
        this.addGeo.reset();
        this.closebutton1.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getVatTaxAuthGeoId() {
    this.spinner.show();
    this.accountsService.getVatTaxAuthGeoId().subscribe(res => {
      this.VatTaxAuthGeoId = res.data[0].vatTaxAuth;
      this.spinner.hide();
      for (const value of this.VatTaxAuthGeoId) {
        this.VatTaxAuthGeoIdArray.push({
          label: value.geoName,
          value: value.geoId
        })
      }
    })
  }
  getProductGeoEnumId() {
    this.spinner.show();
    this.accountsService.getProductGeoEnumId().subscribe(res => {
      this.ProductGeoEnumId = res.data.getProductGeoEnumId;
      this.spinner.hide();
      for (const value of this.ProductGeoEnumId) {
        this.ProductGeoEnumIdArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }
  createGoodIdentification(): void {
    this.spinner.show();
    const requestData ={
      "goodIdentificationTypeId": this.addGoodID.value.idType,
      "idValue": this.addGoodID.value.idValue,
      "productId":  this.product.productId
      }
     
    this.accountsService.createGoodIdentification(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.addGoodID.reset();
        this.getGoodIdentificationById();
        this.addGoodID.reset();
        this.closebutton3.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getGoodIdentificationTypeId() {
    this.spinner.show();
    this.accountsService.getGoodIdentificationTypeId().subscribe(res => {
      this.GoodIdentificationTypeId = res.data.getGoodIdentificationTypeId;
      this.spinner.hide();
      for (const value of this.GoodIdentificationTypeId) {
        this.GoodIdentificationTypeIdArray.push({
          label: value.description,
          value: value.goodIdentificationTypeId
        })
      }
    })
  }
  getProductCategoryForStore() {
    this.spinner.show();
    this.accountsService.getProductCategoryForStore().subscribe(res => {
      this.ProductCategoryForStore = res.data.getProductCategory;
      this.spinner.hide();
      for (const value of this.ProductCategoryForStore) {
        this.ProductCategoryForStoreArray.push({
          label: value.productCategoryId,
          value: value.productCategoryId
        })
      }
    })
  }
  getGoodIdentificationById() {
    this.spinner.show();
    this.accountsService.getGoodIdentificationById(this.product.productId).subscribe(res => {
      this.GoodIdentificationById = res.data.getGoodIdentificationById;
      this.spinner.hide();
    })
  }
  addProductToCategory(): void {
    this.spinner.show();
    const fromDateCate=this.addCategory.get('fromDate').value;
    this.fromDateCate=this.datePipe.transform(fromDateCate,"yyyy-MM-dd hh:mm:ss");
    const throughDateCate=this.addCategory.get('throughDate').value;
    this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "comments": this.addCategory.value.comments,
      "fromDate": this.fromDateCate,
      "productCategoryId": this.addCategory.value.categoryID,
      "productId":  this.product.productId,
      "quantity": this.addCategory.value.qty,
      "sequenceNum": this.addCategory.value.seqNum,
      "thruDate": this.throughDateCate
      }
     
    this.accountsService.addProductToCategory(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.addCategory.reset();
        this.getAddProductToCategoryById();
        this.closebutton5.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getAddProductToCategoryById() {
    this.spinner.show();
    this.accountsService.getAddProductToCategoryById(this.product.productId).subscribe(res => {
      this.AddProductToCategoryById = res.data.getAddProductToCategoryById;
      this.spinner.hide();
    })
  }
  getCreateProductGeoById() {
    this.spinner.show();
    this.accountsService.getCreateProductGeoById(this.product.productId).subscribe(res => {
      this.CreateProductGeoById = res.data.getCreateProductGeoById;
      this.spinner.hide();
    })
  }
  getCreateProductGeo() {
    this.spinner.show();
    this.accountsService.getCreateProductGeo().subscribe(res => {
      this.CreateProductGeo = res.data.getCreateProductGeo;
      this.spinner.hide();
    })

  }


  edit(){
    if (this.product) {
      this.editMode = true;
      this.createEditProductForm.controls.productId.disable();
      this.createEditProductForm.controls.productId.updateValueAndValidity();
      this.createEditProductForm.patchValue({
        ...this.product, ...{
          salesDiscontinuationDate: new Date(this.product.salesDiscontinuationDate),
          releaseDate: new Date(this.product.releaseDate),
          introductionDate: new Date(this.product.introductionDate),
          supportDiscontinuationDate: new Date(this.product.supportDiscontinuationDate)
        }
      });  }
  }
  getProductDropown(): void {
    this.spinner.show();
    this._ProductService.getProductMenu().then((res) => {
      let productTypeIdData = res.data.productType;
      let virtualVariantMethod = res.data.virtualVariantMethod;
      let requirementMethodEnumID = res.data.requirementMethodEnumID;
      let inventoryItemTypeID = res.data.inventoryItemTypeID;
      let ratingTypeEnum = res.data.ratingTypeEnum;
      let amountUomTypeID = res.data.amountUomTypeID;
      let measuresUOMID = res.data.measuresUOMID;
      let weightUomID = res.data.weightUomID;
      let quantityUomID = res.data.quantityUomID;
      let defaultShipmentBoxTypeID = res.data.defaultShipmentBoxTypeID;

      this.spinner.hide();
      for (const value of defaultShipmentBoxTypeID) {
        this.defaultShipmentBoxTypeIDArray.push({
          label: value.description,
          value: value.shipmentBoxTypeId,
        });
      }
      for (const value of quantityUomID) {
        this.quantityUomIDArray.push({
          label: value.description,
          value: value.uomId,
        });
      }
      for (const value of weightUomID) {
        this.weightUomIDArray.push({
          label: value.description,
          value: value.uomId,
        });
      }
      for (const value of measuresUOMID) {
        this.measuresUOMIDArray.push({
          label: value.description,
          value: value.uomId,
        });
      }
      for (const value of productTypeIdData) {
        this.productTypeIdArray.push({
          label: value.description,
          value: value.productTypeId,
        });
      }
      for (const value of virtualVariantMethod) {
        this.virtualVariantMethodArray.push({
          label: value.description,
          value: value.enumId,
        });
      }
      for (const value of requirementMethodEnumID) {
        this.requirementMethodEnumIDArray.push({
          label: value.description,
          value: value.enumId,
        });
      }
      for (const value of inventoryItemTypeID) {
        this.inventoryItemTypeIDArray.push({
          label: value.description,
          value: value.inventoryItemTypeId,
        });
      }
      for (const value of ratingTypeEnum) {
        this.ratingTypeEnumArray.push({
          label: value.description,
          value: value.enumId,
        });
      }
      for (const value of amountUomTypeID) {
        this.amountUomTypeIDArray.push({
          label: value.description,
          value: value.uomTypeId,
        });
      }
    });
   
  }
  getCategories(): void {
    this.spinner.show();
    this._CategoryService.getCategories({ pageNo: 1, pageSize: 200 }, { categoryId: '', categoryIdSearchType: 'Contains', categoryName: '', categoryNameSearchType: 'Contains' })
      .then(data => {
        this.spinner.hide();
        this.categories = data.data.map(value => {
          return {
            label: value.categoryName,
            value: value.productCategoryId
          };
        });
      });
      
  }
  updateProduct(): void {
    this.spinner.show();
    if (this.createEditProductForm.valid) {
      this._ProductService.updateProduct({
        ...this.product,
        ...this.createEditProductForm.value, ...{
          salesDiscontinuationDate: moment(this.createEditProductForm.value.salesDiscontinuationDate).format('YYYY-MM-DD HH:MM:ss'),
          releaseDate: moment(this.createEditProductForm.value.releaseDate).format('YYYY-MM-DD HH:MM:ss'),
          introductionDate: moment(this.createEditProductForm.value.introductionDate).format('YYYY-MM-DD HH:MM:ss'),
          supportDiscontinuationDate: moment(this.createEditProductForm.value.supportDiscontinuationDate).format('YYYY-MM-DD HH:MM:ss')
        }
      })
        .then(data => {
          if (data.success) {
            this.toastr.success('Updated successfully');
            this.spinner.hide();
            this.router.navigate(['/facilities/catalog/products']);
            this.closebutton.nativeElement.click();
          }
        });
    }
  
  }
  submitProduct(): void {
    this.spinner.show();
    if (this.editMode) {
      this.updateProduct();
    } else {
      if (this.createEditProductForm.valid) {
        this._ProductService.createProduct({
          ...this.createEditProductForm.value, ...{
            salesDiscontinuationDate: moment(this.createEditProductForm.value.salesDiscontinuationDate).format('YYYY-MM-DD HH:MM:ss'),
            releaseDate: moment(this.createEditProductForm.value.releaseDate).format('YYYY-MM-DD HH:MM:ss'),
            introductionDate: moment(this.createEditProductForm.value.introductionDate).format('YYYY-MM-DD HH:MM:ss'),
            supportDiscontinuationDate: moment(this.createEditProductForm.value.supportDiscontinuationDate).format('YYYY-MM-DD HH:MM:ss')
          }
        })
          .then(data => {
            if (data.success) {
              this.toastr.success('Created successfully');
              this.spinner.hide();
              this.closebutton.nativeElement.click();
              this.router.navigate(['/facilities/catalog/products']);
            
            }
          });
      }
    }
   
  }
  selectProduct(id) {
    
    if (id) {
      this.activeCategory = 3;
     

    }
    else {
      this.toastr.error("Product Type is required");
    }
  }
  selectworking(name) {
    if (name) {
      this.activeCategory = 4;
    }
    else {
      this.toastr.error("Internal Name is required");
    }
  }
  selectvirtual(vname) {
    if (vname) {
      this.activeCategory = 5;
    }
    else {
      this.toastr.error("Virtual Variant Method is required");
    }
  }
  selectPrimary(pc) {
    if (pc) {
      this.activeCategory = 6;
    }
    else {
      this.toastr.error("Primary Category is required");
    }
  }
  selectDate() {
    this.activeCategory = 7;
  }
  selecInventory(id) {
    if (id) {
      this.activeCategory = 8;
    }
    else {
      this.toastr.error("Inventory Item Type Id is required");
    }
   
  }
  selectRating(id) {
    if (id) {
      this.activeCategory = 9;
    }
    else {
      this.toastr.error("Rating Type Enum is required");
    }
    
  }
  selectAmount(id) {
    if (id) {
      this.activeCategory = 10;
    }
    else {
      this.toastr.error("Amount Uom Type ID is required");
    }
    

  }
  selectMeasure(width,height) {
    if (width && height) {
      this.activeCategory = 11;
    }
    else {
      this.toastr.error("Height and width Uom ID is required");
    }
   
  }
  selectShiping(id) {
    if (id) {
      this.activeCategory = 12;
    }
    else {
      this.toastr.error("Default Shipment Box Type ID is required");
    }
   
  }
  selectShopingCart() {
    this.activeCategory = 13;
  }
 
  wordingValid(id) {
    
    if (id) {
      this.activeCategory = 3;
     

    }
    else {
      this.toastr.error("Fill Product Form.");
    }
  }
  virtualValid(name) {
    if (name) {
      this.activeCategory = 4;
    }
    else {
      this.toastr.error("Fill Wording Form.");
    }
  }
  primaryValid(vname) {
    if (vname) {
      this.activeCategory = 5;
    }
    else {
      this.toastr.error("Fill Virtual Product Form.");
    }
  }
  dateValid(pc) {
    if (pc) {
      this.activeCategory = 6;
    }
    else {
      this.toastr.error("Fill Primary Category Form.");
    }
  }
  inventoryValid(pc) {
    if (pc) {
      this.activeCategory = 7;
    }
    else {
      this.toastr.error("Fill Primary Category Form.");
    }
   
  }
  ratingValid(id) {
    if (id) {
      this.activeCategory = 8;
    }
    else {
      this.toastr.error("Fill Inventory Form.");
    }
   
  }
  amountValid(id) {
    if (id) {
      this.activeCategory = 9;
    }
    else {
      this.toastr.error("Fill Rating Form.");
    }
    
  }
  measureValid(id) {
    if (id) {
      this.activeCategory = 10;
    }
    else {
      this.toastr.error("Fill Amount Form.");
    }
    

  }
  shipingValid(width,height) {
    if (width && height) {
      this.activeCategory = 11;
    }
    else {
      this.toastr.error("Fill Measure Form.");
    }
   
  }
  shopingValid(id) {
    if (id) {
      this.activeCategory = 12;
    }
    else {
      this.toastr.error("Fill Shipping Form.");
    }
   
  }
  shopingCartValid(id) {
    if (id) {
      this.activeCategory = 13;
    }
    else {
      this.toastr.error("Fill Shipping Cart Form.");
    }
   
  }
  cancelSubmitA(){
    this.router.navigate(["/facilities/catalog/products"])
  }
  getUserRole(): void {
    this.spinner.show();
    this._ProductService.getUserRole('All')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.userRoles = data.data.map(value => {
            return {
              label: value.description,
              value: value.roleTypeId
            };
          });
        }
      });

  }

  getContentIdForDropDown(): void {
    this.spinner.show();
    this._ProductService.getContentIds()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.contentIds = data.data.map(value => {
            return {
              label: value.contentId,
              value: value.contentId
            };
          });
        }
      });

  }
  getContentIds(): void {
    this.spinner.show();
    this._ProductService.getContents(this.product.productId)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.productContents = data.data.productContents;

        }
      });

  }
  getUseTimeIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getUomByType('TIME_FREQ_MEASURE')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.useTimeIds = data.data.map(value => {
            return {
              label: value.description,
              value: value.uomId
            };
          });
        }
      });

  }

  deleteContent(fromDate: string, productContentTypeId: string, contentId: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._ProductService.deleteContent(this.product.productId, fromDate, productContentTypeId, contentId)
          .then(data => {
            if (data.success) {
              this.getContentIds();
            }
          });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getContentTypeIds(): void {
    this.spinner.show();
    this._ProductService.getContentTypeId()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.contentTypeIds = data.data.map(value => {
            return {
              label: value.description,
              value: value.productContentTypeId
            };
          });
        }
      });

  }

  getInventory(): void {
    this.spinner.show();
    this._ProductService.getProductInventory(this.product.productId)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.inventorySummary = data.data.quantitySummaryByFacility;
          this.purchaseOrders = data.data.purchaseOrders;
          this.inventoryItems = data.data.productInventoryItems;
        }
      });

  }

  resetPrepareContent(): void {
    this.contentTypeId = '';
  }


  submitAddToProduct(): void {
    this.spinner.show();
    if (this.contentToProductForm.valid) {
      this._ProductService.addProductToContent(this.product.productId, {
        ...this.contentToProductForm.value, ...{
          fromDate: this.contentToProductForm.value.fromDate ? moment(this.contentToProductForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
          purchaseFromDate: this.contentToProductForm.value.purchaseFromDate ? moment(this.contentToProductForm.value.purchaseFromDate).format('YYYY-MM-DD HH:mm:ss') : '',
          purchaseThruDate: this.contentToProductForm.value.purchaseThruDate ? moment(this.contentToProductForm.value.purchaseThruDate).format('YYYY-MM-DD HH:mm:ss') : '',
          thruDate: this.contentToProductForm.value.thruDate ? moment(this.contentToProductForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
        }
      })
        .then(data => {
          if (data.success) {
            this.toastr.success('Added');
            this.spinner.hide();
            this.getContentIds();
            this.cancelSubmit();
          }
        });
    }

  }
  resetSimpleFields(): void {
    this.simpleFieldsForm.reset();
  }
  updateSimpleFields(): void {
    this.spinner.show();
    this._ProductService.updateSimpleFields(this.product.productId, this.simpleFieldsForm.value)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.toastr.success('Updated');
          this.resetSimpleFields();
        }
      });

  }
  setImage(event: any): void {
    this.contentImage.append('file', event.target.files[0]);
  }
  setUploadImageType(event: string): void {
    this.imageType = event;
  }
  uploadImage(): void {
    this.spinner.show();
    if (this.imageType) {
      this._ProductService.uploadImage(this.product.productId, this.imageType, this.contentImage)
        .then(data => {
          if (data.success) {
            this.toastr.success('Image Uploaded');
            this.spinner.hide();
            this.simpleFieldsForm.patchValue({
              largeImageUrl: this.imageType === 'large' ? data.data.url : '',
              mediumImageUrl: this.imageType === 'medium' ? data.data.url : '',
              originalImageUrl: this.imageType === 'original' ? data.data.url : '',
              smallImageUrl: this.imageType === 'small' ? data.data.url : '',
              detailImageUrl: this.imageType === 'detail' ? data.data.url : ''
            });
            this.imageType = '';
            this.contentImage.delete('file');
          }
        });
    } else {
      this.spinner.hide();
      this.toastr.error('Please select the type');
    }

  }
  setAdditionalImage(event: any, type: string): void {
    switch (type) {
      case 'one':
        this.additionalImage.append('additionalImageOne', event.target.files[0]);
        break;
      case 'two':
        this.additionalImage.append('additionalImageTwo', event.target.files[0]);
        break;
      case 'three':
        this.additionalImage.append('additionalImageThree', event.target.files[0]);
        break;
      case 'four':
        this.additionalImage.append('additionalImageFour', event.target.files[0]);
        break;
    }
  }
  uploadAdditionalImage(): void {
    this.spinner.show();
    this._ProductService.uploadAdditionalImage(this.product.productId, this.additionalImage)
      .then(data => {
        if (data.success) {
          this.toastr.success('Uploaded');
          this.spinner.hide();
          this.additionalImage.delete('additionalImageOne');
          this.additionalImage.delete('additionalImageTwo');
          this.additionalImage.delete('additionalImageThree');
          this.additionalImage.delete('additionalImageFour');
        }
      });

  }
  prepareContent(): void {
    this.spinner.show();
    if (this.contentTypeId) {
      this._ProductService.prepareAddContent(this.contentTypeId, this.product.productId)
        .then(data => {
          if (data.success) {
            this.toastr.success('Prepared');
            this.spinner.hide();
            this.router.navigate(['/facilities/catalog/products/additional-add-content'], { queryParams: { productId: this.product.productId, productContentTypeId: this.contentTypeId } });
            this.contentTypeId = '';
          }
        });
    } else {
      this.spinner.hide();
      this.toastr.error('Please select content type');
    }

  }
  getProductPriceList(): void {
    this.spinner.show();
    this._ProductService.getProductPriceList({ pageNo: 1, pageSize: 200 }, this.product.productId)
      .then(data => {
        this.productPriceList = data.data;
        this.spinner.hide();
      });

  }
  getSupplierByProductId() {
    this.spinner.show();
    this._ProductService.getSupplierByProductId(this.product.productId, this.employessSize).subscribe((res: any) => {
      this.SupplierByProductId = res.data;
      this.spinner.hide();
    })

  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  createSupplier() {
    this.showSupplierModal = true;
    // this.router.navigate(["/facilities/catalog/products/create-supplier"], { queryParams: { product: this.product.productId } })

  }
  update(productValue) {
    this.router.navigate(["/facilities/catalog/products/create-supplier"], { queryParams: { product: this.product.productId, availableFromDate: productValue.availableFromDate, currencyUomId: productValue.currencyUomId, minimumOrderQuantity: productValue.minimumOrderQuantity, partyId: productValue.partyId, productId: productValue.productId } })
    this.router.navigate([this.url], { queryParams: { product: this.product.productId, availableFromDate: productValue.availableFromDate, currencyUomId: productValue.currencyUomId, minimumOrderQuantity: productValue.minimumOrderQuantity, partyId: productValue.partyId, productId: productValue.productId } })
  }
  deleteProductPrice(currencyUomId: string, fromDate: string, productPricePurposeId: string, productPriceTypeId: string, productStoreGroupId: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._ProductService.deleteProductPrice({
          currencyUomId, fromDate: moment(fromDate).format('YYYY-MM-DD HH:mm:ss'), productId: this.product.productId, productPricePurposeId, productPriceTypeId,
          productStoreGroupId
        })
          .then(data => {
            if (data.success) {
              this.getProductPriceList();
            }
          });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  removeProductSupplier(data) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const requestData = {

          "availableFromDate": data.availableFromDate,
          "currencyUomId": data.currencyUomId,
          "minimumOrderQuantity": data.minimumOrderQuantity,
          "partyId": data.partyId,
          "productId": this.product.productId

        }

        this._ProductService.removeProductSupplier(requestData).subscribe((res: any) => {

          if (res.success) {

            this.getSupplierByProductId();
          }
        }, (err) => {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  getProductVariants() {
    this._GlobalResourceService.getProductVariant(this.product.productId)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.productArray = data.data.data;
        }
      });
  }
  changeActive(tab: number): void {
    this.active = tab;
  }
  inventoryActiveTab(tab: number): void {
    this.inventoryActive = tab;
  }
  supplierModalClosed(): void{
    this.showSupplierModal = false;
  }
  openPriceModal(){
    this.showPriceModal = true;
  }
  closePriceModal(){
    this.showPriceModal = false;
  }
  openVariantsModal(){
    this.showVariantModal = true;
  }

  closeVariantsModal(){
    this.showVariantModal = false;
  }
  ProductCategories(id) {
    this.router.navigate(["/facilities/catalog/catagories/detail-category-summary/",id])
  }
  detailCost(id) {
    this.router.navigate(["production/detail-calc-cost"],{ queryParams: { CalcId : id}})

  }
  routingDetails(id) {
    this.router.navigate(["production/detail-routing-task-summary"],{ queryParams: { routingTaskId : id}})
  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
