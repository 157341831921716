import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { AccountsService } from '../accounts/accounts.service';

@Component({
  selector: 'app-create-event-role',
  templateUrl: './create-event-role.component.html',
  styleUrls: ['./create-event-role.component.css']
})
export class CreateEventRoleComponent implements OnInit {
  activeCategory=2;
  createEventPurpose:FormGroup
  personId: any;
  partyIdArray: any[]=[];
  roleId: any;
  roleIdArray: any[]=[];
  commEventId: any;
  constructor(readonly router: Router,
    readonly datePipe: DatePipe,
    readonly toastr: ToastrService,
    readonly addSkillService: AddSkillService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly accountsService: AccountsService,
    readonly spinner: NgxSpinnerService,) { 
    this.createEventPurpose = this._FormBuilder.group({
      partyId: [''],
      roleTypeId:['']
     
 
    });
  }
  ngAfterViewInit(): void { this.spinner.show(); } 
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.commEventId = params['commEventId'];
     
    });
    this.getPartyId();
    this.getCrmEventRoleTypeList();
  }
  cancelSubmit(){
    this.router.navigate(['/crm/crm-overview'], { queryParams: { commEventId:this.commEventId} });
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  getCrmEventRoleTypeList() {
    this.spinner.show();
    this.accountsService.getCrmEventRoleTypeList().subscribe(res => {
      this.roleId = res.data;
      this.spinner.hide();
      for (const value of this.roleId) {
        this.roleIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
   
  }
  
  
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "partyId": this.createEventPurpose.value.partyId,
      "roleTypeId":this.createEventPurpose.value.roleTypeId,
    }

    this.accountsService.postCrmEventRole(this.commEventId,requestData).subscribe((res: any) => {
   
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(['/crm/crm-overview'], { queryParams: { commEventId:this.commEventId} });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    
  }
  ngOnDestroy(): void {

    this.spinner.hide();
      } 
}

