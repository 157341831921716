import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {Router} from "@angular/router";
import {AccountsService} from "../../crm/accounts/accounts.service";
import {MyContactsService} from "../../crm/contacts/my-contacts/my-contacts.service";
import {ActivatedRoute} from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { EmployeeService } from '../employees/employess.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-emp-related-account',
  templateUrl: './emp-related-account.component.html',
  styleUrls: ['./emp-related-account.component.css']
})
export class EmpRelatedAccountComponent implements OnInit {
  fileUploadForm: FormGroup;
  accountData: any;
  activeCategory=1;
  accountArray: any=[];
  partyId: any;
  groupNameSearchType:any='Contains'
  contactMechTypeId:any='';
  groupName:any='';
  partyIds: any='';
  partyIdSearchType: any='Contains';
  crmAccount: any=[];
  model: any = {};
  
  
  constructor(
    readonly formBuilder:FormBuilder,
    readonly router:Router,
    readonly activatedRoute:ActivatedRoute,
    readonly MyContactsService:MyContactsService,
    readonly accountsService:AccountsService,
    readonly toastr:ToastrService,
    readonly spinner:NgxSpinnerService,
    readonly employeeService:EmployeeService
  ) { }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.partyId = params["personId"];
    });
   
    this.fileUploadForm = this.formBuilder.group({
      accountPartyId: [''],
      comments:[''],
      contactPartyId:[''],
      partyId:['']
    });
    this.getEmpAccountRelated();
  }
  getEmpAccountRelated(){
    this.spinner.show();
    const req={
      
        "contactMechTypeId": this.contactMechTypeId,
        "groupName":this.groupName,
        "groupNameSearchType": this.groupNameSearchType,
        "partyId":this.partyIds,
        "partyIdSearchType":  this.partyIdSearchType
      
    }
    const all = "true"
    this.employeeService.getMyAccountRelatedStatus(req,all).subscribe(res=> {
      this.crmAccount = res.data;
      this.spinner.hide();
      for(const value of this.crmAccount){
        this.accountArray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
    })
   
  }
  onSubmit(){
    this.spinner.show();
    this.fileUploadForm.get('contactPartyId').patchValue(this.partyId);
    this.fileUploadForm.get('partyId').patchValue(this.partyId);
 
    this.employeeService.postMyAccountRelatedStatus(this.fileUploadForm.value).subscribe((res:any)=> {
      if(res.success) {
        this.spinner.hide();
        this.toastr.success(res.data.responseMessage);
        this.router.navigate(["/hr/employees/employee-details"],{queryParams:{employeeDetailId:this.partyId}});
      }      }
      ,(err) => {
        for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }}
      )
      this.spinner.hide();
  }
  navigate(){
    this.router.navigate(["/hr/employees/employee-details"],{queryParams:{employeeDetailId:this.partyId}});
  }

 changeactiveCategoryFunction(tab: number){
  this.activeCategory =tab;
}
ngOnDestroy(): void {
  this.spinner.hide();
    }
}
