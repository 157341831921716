import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
@Component({
  selector: 'app-merge-suppliers',
  templateUrl: './merge-suppliers.component.html',
  styleUrls: ['./merge-suppliers.component.css']
})
export class MergeSuppliersComponent implements OnInit {
  selectedCar2: string = null;
  cars: SelectItem[];
  constructor() {
    
   }

  ngOnInit(): void {
    this.cars = [
      {label: 'Demo Suppliers 1', value: 'Demo Suppliers 1'},
      {label: 'Demo Suppliers 1', value: 'Demo Suppliers 1'},
      {label: 'Demo Suppliers 1', value: 'Demo Suppliers 1'},
    ];
    
  }

}
