<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class=" bg-white color-grey create-new-table">
        <div class="container-fluid">
          <ul class="tabbing-section  tabbing-accordians" style="padding-left: 9px;">
            <li [ngClass]="activeCategory==1?'active':''"><a>
              Sales Order : Order Entry Ship-To Settings</a></li>
           
          
          </ul>
        </div>
        <div>
          <div class="container-fluid">
            <div class="row">
              <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                <div class="w-100">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                      <div *ngIf="activeCategory==1">
                        <div class="header-tabs">
                          <h4 class=" common-styling">
                            Sales Order : Order Entry Ship-To Settings
                          </h4>
                          <span class="arrow-toggle toggle-arrow detail-created" data-toggle="collapse"
                          href="#seo-collapse">
                        </span>
                        <span style="margin-left: 23%!important" class="edit-buttons" routerLinkActive="active"
                          routerLink="/order/create-sales-continue"> Order Items</span>
                        <span class="edit-buttons" routerLinkActive="active"
                          routerLink="/order/sales-finalize-order">Shipping</span>
                        <span class="edit-buttons">Options</span>
                        <span class="edit-buttons">Order Terms</span>
                        <span class="edit-buttons">Payment </span>
                        <span class="edit-buttons">Parties</span>
                        <span class="edit-buttons">Review Order</span>
                        <span class="edit-buttons" routerLinkActive="active"
                          routerLink="/order/sales-order-options"> Continue</span>

                        
                        </div>
                        <div class="panel-body">
                          <div class="mt-2">
                            <div class="">
                              <div class="row">
                                <form class="w-100" [formGroup]="createShippingAddressForm">
                                  <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label class="common-labels">To Name
                                        </label>
                                        <input type="text" class="form-control" placeholder="Enter Name"
                                          formControlName="toName">
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label class="common-labels">Attention Name
                                        </label>
                                        <input type="text" class="form-control" placeholder="Enter Attention Name"
                                          formControlName="attnName">
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label class="common-labels">Address Line 1
                                        </label>
                                        <input type="text" class="form-control" placeholder="Enter Address Line 1"
                                          formControlName="address1">
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label class="common-labels">Address Line 2
                                        </label>
                                        <input type="text" class="form-control" placeholder="Enter Address Line 2"
                                          formControlName="address2">
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label class="common-labels">City
                                        </label>
                                        <input type="text" class="form-control" placeholder="Enter City"
                                          formControlName="city">
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label class="common-labels">State/Province
                                        </label>
                                        <p-dropdown [options]="stateList" placeholder="Select State/Province"
                                          formControlName="stateProvinceGeoId" name="asdas"></p-dropdown>
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label class="common-labels">Zip/Postal Code
                                        </label>
                                        <input type="text" class="form-control" placeholder="Enter Zip/Postal Code"
                                          formControlName="postalCode">
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label class="common-labels">Country
                                        </label>
                                        <p-dropdown [options]="countryList" placeholder="Select Country" name="asdas"
                                          formControlName="countryGeoId">
                                        </p-dropdown>
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label class="common-labels">Allow Solitication?
                                        </label>
                                        <p-dropdown [options]="array" placeholder="Select" name="asdas"
                                          formControlName="allowSolicitation"></p-dropdown>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                                <div class="col-md-12 col-12">
                                  <button class="btn btn-primary cursor-pointer float-right"
                                    (click)="submit()">Submit</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner> 