<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span>
                    <span class="color-gry cursor-pointer" routerLink="/financial/receiveable/payments">Payment
                    </span>
                    <span class="color-black pl-1"> > Incoming Payment Summary</span>
                </span>
                <span style="margin-left: 43%;display: none;">
                    <p-dropdown filter="true" [options]="paymentStatus" optionlabel="label" [(ngModel)]="statusId"
                        (ngModelChange)="setStatus($event)" placeholder="Select Status">
                    </p-dropdown>
                    <!-- <button class="btn btn-outline-secondary ml-2" (click)="setStatusToVoid()">Status To Void</button> -->
                </span>
                <span>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2"
                        (click)="toDashboard()">Home</button>
                    <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                </span>
            </div>

            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">
                        <button type="button" class="btn btn-outline-secondary" routerLinkActive="active"
                            name="PMNT_NOT_PAID" (click)="setNewStatus($event)">Not Paid</button>
                        <button type="button" class="btn btn-outline-secondary" routerLinkActive="active"
                            name="PMNT_SENT" (click)="setNewStatus($event)">Sent</button>
                        <button type="button" class="btn btn-outline-secondary" routerLinkActive="active"
                            name="PMNT_RECEIVED" (click)="setNewStatus($event)">Received</button>
                        <button type="button" class="btn btn-outline-secondary" routerLinkActive="active"
                            name="PMNT_CONFIRMED" (click)="setNewStatus($event)">Confirmed</button>
                        <button type="button" class="btn btn-outline-secondary" routerLinkActive="active"
                            name="PMNT_CANCELLED" (click)="setNewStatus($event)">Cancelled</button>
                        <button type="button" class="btn btn-outline-secondary" routerLinkActive="active"
                            (click)="setStatusToVoid()">Status To Void</button>
                    </div>
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group">

                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">

                                                            <h4 class=" common-styling hCard">
                                                                Payment Information
                                                            </h4>
                                                            <span class="edit-buttons  hRight"
                                                                data-toggle="modal"
                                                                data-target="#exampleModalCenter">Update</span>

                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div *ngIf="paymentValue" class="color-black container">

                                                                    <div class="row">
                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Payment
                                                                                        Type
                                                                                        ID
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{paymentValue.invoiceTypeId}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Status
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{paymentValue.statusId}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Payment
                                                                                        Method
                                                                                        Type</p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{paymentValue.paymentMethodType}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Payment
                                                                                        Method
                                                                                        Type ID
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{paymentValue.paymentMethodTypeId}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">From
                                                                                        Party ID</p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{paymentValue.fromParty}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">To Party
                                                                                        ID
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{paymentValue.toParty}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Reference
                                                                                        No</p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{paymentValue.paymentRefNum}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Amount
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{paymentValue.amount
                                                                                        }}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Effective
                                                                                        Date
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{paymentValue.effectiveDate
                                                                                        |date :'medium'}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Comments
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{paymentValue.comments
                                                                                        }}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Override
                                                                                        GI
                                                                                        Account ID
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{paymentValue.overrideGlAccountId}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">

                                                            <h4 class=" common-styling hCard">
                                                                Payments Applied US$10.00 Open US$1,101.00
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body allUserCardDetail">
                                                            <div class="card own-account-table borderTable">
                                                                <p-table [value]="paymentApplicationList"
                                                                    scrollHeight="150px" [paginator]="false"
                                                                    [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    Invoice ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="name"
                                                                                style="width: 1rem;">
                                                                                <div style="color: white;"> Item No
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Override Gl
                                                                                    Account ID
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Billing
                                                                                    Account ID
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> To Payment
                                                                                    ID
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Tax Auth Geo
                                                                                    ID
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Amount
                                                                                    Applied
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">

                                                                                </div>
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <ng-container
                                                                            *ngIf="paymentValue.paymentApplicationList.length; else noData">
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    <div
                                                                                        class="checkbox-align account-button">
                                                                                        {{product.invoiceId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.invoiceItemSeqId}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.overrideGlAccountId}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.billingAccountId}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.toPaymentId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.taxAuthGeoId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.amountApplied}}
                                                                                </td>

                                                                            </tr>
                                                                        </ng-container>
                                                                        <ng-template #noData>
                                                                            <h4 class="text-center">No Records Found
                                                                            </h4>
                                                                        </ng-template>
                                                                    </ng-template>
                                                                </p-table>
                                                                <!-- <p class="paginate_data">
                                                                                                                                                                                                                                                                        - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                <p class="paginate_data">
                                                                </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <br>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-lg-6 pr-0">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">

                                                            <h4 class=" common-styling hCard">
                                                                Financial Account Transaction
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div *ngIf="financialAccountTransaction"
                                                                    class="color-black container">
                                                                    <div class="row">
                                                                        <div class="article-container">
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12"> Fin Account
                                                                                    Trans ID</p>
                                                                                <span
                                                                                    class="color-grey pl-20 col-lg-10 col-12">{{financialAccountTransaction?.finAccountTransId}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Fin Account
                                                                                    Trans Type</p>
                                                                                <span
                                                                                    class="color-grey pl-20 col-lg-10 col-12">{{financialAccountTransaction?.finAccountTransType}}</span>
                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Amount</p>
                                                                                <span
                                                                                    class="color-grey pl-20 col-lg-10 col-12">{{financialAccountTransaction?.amount
                                                                                    |currency :'USD'}}</span>
                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Transaction
                                                                                    Date
                                                                                </p>
                                                                                <span
                                                                                    class="color-grey pl-20 col-lg-10 col-12">{{financialAccountTransaction?.transactionDate
                                                                                    |date:'yyyy-MM-d hh:mm:ss'}}</span>
                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Status</p>
                                                                                <span
                                                                                    class="color-grey pl-20 col-lg-10 col-12">{{financialAccountTransaction?.status}}</span>
                                                                            </div>




                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>



                                                <div class="col-lg-6">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">

                                                            <h4 class=" common-styling hCard">
                                                                Transactions
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body allUserCardDetail">
                                                            <div class="card own-account-table borderTable">
                                                                <p-table [value]="transactions" scrollHeight="150px"
                                                                    [paginator]="false" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">

                                                                                    Acctg Trans ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="name"
                                                                                style="width: 1rem;">
                                                                                <div style="color: white;"> Acctg Trans
                                                                                    Entry Seq ID
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Is Posted
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Fiscal Gl
                                                                                    Type ID
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Acctg Trans
                                                                                    Type ID
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Transaction
                                                                                    Date
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Posted Date
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Transaction
                                                                                    Date
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Gl Journal
                                                                                    ID
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Trans Type
                                                                                    Description
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Invoice ID
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Fixed Asset
                                                                                    ID
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Description
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Gl Account
                                                                                    ID
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Product ID
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Debit Credit
                                                                                    Flag
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Amount
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Orig Amount
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Organization
                                                                                    Party ID
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Gl Account
                                                                                    Type
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Account Code
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Account Name
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> GL Account
                                                                                    Class
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Party
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Reconcile
                                                                                    Status ID
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Acctg Trans
                                                                                    Entry Type ID
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>


                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <div
                                                                                    class="checkbox-align account-button">
                                                                                    {{product.acctgTransId}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.acctgTransEntrySeqId}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.isPosted}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.glFiscalTypeId}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.transTypeDescription}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.transactionDate
                                                                                |date:'yyyy-MM-d hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.postedDate | date :'medium'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.glJournalId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.transTypeDescription}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.invoiceId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.fixedAssetId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.transDescription}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.glAccountId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.productId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.debitCreditFlag}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.amount}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.origAmount}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.organizationPartyId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.glAccountTypeId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.accountCode}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.accountName}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.glAccountClassDescription}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.partyId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.reconcileStatusId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.acctgTransEntryTypeId}}
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No Record Found
                                                                                    </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <!-- <p class="paginate_data">
                                                                                                                                                                                                                            - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                <p class="paginate_data">
                                                                </p>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <br>


                                                </div>
                                            </div>

                                            <br>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update-Incoming-Payment</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        {{editMode ? 'Update ':' Create'}} Incoming Payment</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="payablePaymentForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Organization Party ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" name="party" [options]="array"
                                                            formControlName="partyIdTo" optionlabel="label"
                                                            placeholder="Select Party ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">From Party ID <span
                                                                class="text-danger">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" name="party" [options]="partyIds"
                                                            optionlabel="label" placeholder="Select PartyID"
                                                            formControlName="partyIdFrom">
                                                        </p-dropdown>
                                                        <small class="text-danger"
                                                            *ngIf="!!payablePaymentForm.controls.partyIdFrom.invalid && !!payablePaymentForm.controls.partyIdFrom.touched">From
                                                            Party ID is required</small>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Payment Type</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" name="party"
                                                            [options]="paymentTypeList" optionlabel="label"
                                                            placeholder="Select Payment Type"
                                                            formControlName="paymentTypeId">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Payment Method ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" name="party"
                                                            [options]="paymentMethods" optionlabel="label"
                                                            placeholder="Select Payment Method ID"
                                                            formControlName="paymentMethodId">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Override Gl Account ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" name="party" [options]="GIAccounts"
                                                            optionlabel="label" formControlName="overrideGlAccountId"
                                                            placeholder="Select Override Gl Account ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Amount <span
                                                                class="text-danger">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="amount" aria-describedby="emailHelp"
                                                            placeholder="Enter Amount">

                                                        <small class="text-danger"
                                                            *ngIf="!!payablePaymentForm.controls.amount.invalid && !!payablePaymentForm.controls.amount.touched">Amount
                                                            is required</small>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Comments</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="comments" aria-describedby="emailHelp"
                                                            placeholder="Enter Comments">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Reference No</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="paymentRefNum" aria-describedby="emailHelp"
                                                            placeholder="Enter Reference No">
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12" *ngIf="editMode">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="finAccountId">Financial Account Transaction
                                                            ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            formControlName="finAccountTransId">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1"> Currency</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" name="party" [options]="currencyUomID"
                                                            optionlabel="label" formControlName="currencyUomId"
                                                            placeholder="Select Currency">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12" *ngIf="editMode">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Actual Currency Uom ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" name="party" [options]="currencyUomID"
                                                            optionlabel="label" formControlName="actualCurrencyUomId"
                                                            placeholder="Select Currency">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Actual Currency Amount</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="actualCurrencyAmount"
                                                            aria-describedby="emailHelp" >
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12" *ngIf="editMode">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="effectiveDate">Effective Date</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-calendar formControlName="effectiveDate"></p-calendar>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Status Id</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p>{{payablePaymentForm.get('statusId').value}}</p>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12" *ngIf="!editMode">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Financial Account ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" name="party" [options]="finAccountIds"
                                                            optionlabel="label" formControlName="finAccountId"
                                                            placeholder="Select Financial Account">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>



                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -4%;">
                                            <button _ngcontent-cta-c128="" type="submit"
                                                class="btn btn-secondary submit-btn " (click)="submit()">{{editMode ?
                                                'Update':'Create'}}</button>


                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>