import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountsService } from '../accounts/accounts.service';
import { SortEvent } from 'primeng/api';
@Component({
  selector: 'app-main-crm',
  templateUrl: './main-crm.component.html',
  styleUrls: ['./main-crm.component.css']
})
export class MainCrmComponent implements OnInit {
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  readonly req = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  search = '';
  accountsList:any[]=[]
  rows = 3;
  getOverviewList: any;
  commEventIdPartyId: string;
  constructor(
    readonly accountsService: AccountsService,
    readonly router: Router,
    
    readonly spinner: NgxSpinnerService,
  ) { 
    this.accountsList=[{
      'product':'admin'
    }]
  }
 ngAfterViewInit(): void { this.spinner.show(); } 
  ngOnInit(): void {
    const partyId: string = localStorage.getItem('partyId');
    this.commEventIdPartyId=partyId
    this.getCrmListDetail();
  }
  getCrmListDetail() {
    this.spinner.show();
    const data={
      "communicationEventId": "",
      "communicationEventIdSearchType": "Contains",
      "communicationEventTypeId": "",
      "parentCommEventId": "",
      "parentCommEventIdSearchType": "Contains",
      "partyId": "",
      "partyIdFrom": "",
      "partyIdTo":  this.commEventIdPartyId,
      "roleTypeId": "",
      "statusId": "",
      "subject": "",
      "subjectSearchType": ""
    }
   
    this.accountsService.crmMainPageData(data, this.req).subscribe((res: any) => {
      this.getOverviewList = res.data;
      this.spinner.hide();

    })
   
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
 
      
      accountDetails(id){
        this.router.navigate(['/crm/main-detail-summary'], { queryParams: { commEventId: id} });

      }
      ngOnDestroy(): void {
        this.spinner.hide();
          } 
          replyMail(){
            this.router.navigate(['/crm/new-communication-crm']);
          }
}
