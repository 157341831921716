import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-send-format-through-mail',
  templateUrl: './send-format-through-mail.component.html',
  styleUrls: ['./send-format-through-mail.component.css']
})
export class SendFormatThroughMailComponent implements OnInit {
  activeCategory=2;

  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  sendBIRTreportbymailForm: FormGroup;
  birtContentTypeArray: any[]=[];
  birtContent: any;
  birtexportformatArray: { label: string; value: string; }[];

  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
  ) 
  {
    this.sendBIRTreportbymailForm=this._FormBuilder.group({
      sendFrom:"",
      sendTo:"",
      subject:"",
      birtexportformat:"",
      attachmentName:"",
      birtReportLocation:"",
    })

    this.birtexportformatArray =[
      {
        label:"Word(.doc)",
        value:"Word(.doc)"
      },
      {
        label:"Pdf(.pdf)",
        value:"Pdf(.pdf)"
      },
      {
        label:"Excel(.xlsx)",
        value:"Excel(.xlsx)"
      }
    ]
   }

  ngOnInit(): void {
    this.birtexportformatArray;

  }

  
  // birtContentType() {
  //   this.spinner.show();
  //   this.accountsService.birtContentType().subscribe(res => {
  //     this.birtContent = res.data[0].data;
  //     this.spinner.hide();
  //     for (const value of this.birtContent) {
  //       this.birtContentTypeArray.push({
  //         label: value.description,
  //         value: value.enumId
  //       })
  //     }
  //   })
  // }

  reset()
  {
    this.sendBIRTreportbymailForm.reset();
  }


  sendBirtMail() {
    this.spinner.show();
    const requestData =
    {
      "attachmentName": this.sendBIRTreportbymailForm.value.attachmentName,
      "birtContentType": this.sendBIRTreportbymailForm.value.birtexportformat,
      "birtReportLocation": this.sendBIRTreportbymailForm.value.birtReportLocation,
      "sendFrom": this.sendBIRTreportbymailForm.value.sendFrom,
      "sendTo": this.sendBIRTreportbymailForm.value.sendTo,
      "subject": this.sendBIRTreportbymailForm.value.subject,

    }
    this.accountsService.sendBirtMail(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.sendBIRTreportbymailForm.reset();
        this.closebutton2.nativeElement.click();
        
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.success("Created Successfully");
          this.sendBIRTreportbymailForm.reset();

        }
      }
      else {
        this.toastr.success("Created Successfully");
        this.sendBIRTreportbymailForm.reset();

      }
    });
  }

  chartViewHandler() {
    this.spinner.show();
    this.myContactsService.chartViewHandler().subscribe(res => {
      const blob = new Blob([res], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "ViewHandler.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  } 

  chartPie() {
    this.spinner.show();
    this.myContactsService.chartPie().subscribe(res => {
      const blob = new Blob([res], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "ChartPie.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  } 

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });

    
}

ngOnDestroy(): void {
  this.spinner.hide();
    }

  changeactiveCategory(tab: number)
  {
    this.activeCategory = tab;
  
  }

  openPDF()
  {
    this.router.navigate(['/birt/main/send-format-through-mail']);

  }
  openSendFormatThroughtMail()
  {
    this.router.navigate(['/birt/main/send-format-through-mail']);
  }
  openPDFChartReport()
  {
    this.router.navigate(['/birt/main/send-format-through-mail']);

  }


}
