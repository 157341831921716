import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AccountsService} from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderComponent } from 'src/app/header/header.component';
import { HeaderService } from 'src/app/header/header.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-new-role-type',
  templateUrl: './new-role-type.component.html',
  styleUrls: ['./new-role-type.component.css']
})
export class NewRoleTypeComponent implements OnInit {
  partyId: any;
  activeCategory = 2;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  rows = 50;
  pageNo=1;
  search="";
  public user = {
    pageNo  :this.pageNo,
    pageSize:this.pageSize,
    search  :this.search
  }
  roleList: any;
  id: string;
  mainrole: any;
  MainArrayValue: any[]=[];
  allroleArray: any[]=[];
  allrole: any;
  
  createUser: FormGroup;
  loginId: any;
  url: string;
  @Output() newModalClosed: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closebutton') closebutton;

  constructor(readonly accountsService: AccountsService,
    readonly headerService : HeaderService, readonly spinner: NgxSpinnerService,
    readonly router: Router,  readonly toastr:ToastrService,
    readonly activatedRoute: ActivatedRoute , readonly _FormBuilder: FormBuilder) {
      this.url='/users/user-detail-summary'
      this.createUser = this._FormBuilder.group({
        description: ['',[Validators.required]],
        parentTypeId: [''],
        roleTypeId: ['',[Validators.required]],
       
       
        
      });
     }
     ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params['partyId'];
      this.loginId = params['login'];
    });
  
    this.getRoleAllType();
  }
  
    getRoleAllType(){
      this.spinner.show();
      this.id="ALL"
        this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
          this.allrole = res.data;
          this.spinner.hide();
          for(const value of this.allrole){
            this.allroleArray.push({
              label:value.description,
              value:value.roleTypeId
            })
          }
 
        })
       
       }
       
         
  submit(): void {
   
    this.spinner.show();
        const requestData ={
          "description": this.createUser.value.description,
           "parentTypeId": this.createUser.value.parentTypeId,
           "roleTypeId": this.createUser.value.roleTypeId,
            
          }
         
        this.accountsService.postRoleTypeData(requestData,this.partyId).subscribe((res: any) => {
        
          if (res.success) {
            this.modalClose();
            this.toastr.success("Created Successfully");
            this.spinner.hide();
            this.router.navigate([this.url], { queryParams: { partyId: this.partyId,login:this.loginId } });
          }
        }, (err) => {
          this.spinner.hide();
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        });
        
      }
    
      cancel(){
        this.router.navigate([this.url], { queryParams: { partyId: this.partyId,login:this.loginId } });
      }

      modalClose(): void {
        this.closebutton.nativeElement.click();
        this.newModalClosed.emit();
      }

      ngOnDestroy(): void {
        this.spinner.hide();
          }
}
