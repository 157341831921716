<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">

            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Opportunity</span>
                <span>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2"
                        (click)="toDashboard()">Home</button>
                    <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                </span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">

                            <li [ngClass]="activeCategories==8?'active':''" (click)="toLead()"><a>
                                Leads</a></li>
                        <li [ngClass]="activeCategories==2?'active':''" (click)="toContact()"><a>
                                Contact</a></li>
                        <li [ngClass]="activeCategories==1?'active':''" (click)="toAccount()"><a>
                                Account</a></li>
                        <li [ngClass]="activeCategories==3?'active':''" (click)="toOpportunity()"><a>
                                Opportunity</a></li>
                        <li [ngClass]="activeCategories==9?'active':''" (click)="toTasks()"><a>
                                Tasks</a></li>
                        <li [ngClass]="activeCategories==7?'active':''" (click)="toCases()"><a>
                                Cases</a></li>



                        <li [ngClass]="activeCategories==4?'active':''" (click)="toActivities()"><a>
                                Activities Campaign</a></li>

                        <li [ngClass]="activeCategories==10?'active':''" (click)="toForecast()"><a>
                                Forecast </a></li>
                        <li [ngClass]="activeCategories==11?'active':''" (click)="toDocuments()"><a>
                                Documents </a></li>
                        <li [ngClass]="activeCategories==12?'active':''" (click)="toNotes()"><a>
                                Notes </a></li>
                        <li [ngClass]="activeCategories==13?'active':''" (click)="toCalendar()"><a>
                                Calendar </a></li>
                                <li [ngClass]="activeCategories==14?'active':''" (click)="toEmails()"><a>
                                    Emails </a></li>

                        <li [ngClass]="activeCategories==5?'active':''" (click)="toQuotes()"><a>
                                Quotes </a></li>

                        <li [ngClass]="activeCategories==6?'active':''" (click)="toSalesOrder()"><a>
                                Sales order</a></li> 


                        </ul>
                    </div>



                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                    <div *ngIf="activeCategories==3">

                                        <div class="w3-card-4 w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                            style="background:#F5F7FA!important; padding: 4px !important;">

                                                            <span style="margin-left: 12px!important;"> Find
                                                                Opportunities</span>
                                                            <button type="button"
                                                                class="btn btn-outline-secondary submit-btn ml-2"
                                                                 data-toggle="modal"
                                                                data-target="#exampleModalCenter">Create New
                                                                Opportunities</button>
                                                            <button type="button" class="btn btn-outline-secondary"
                                                                data-toggle="modal" data-target="#exampleModal"
                                                                style="margin-left: 51%;">+ More Filters</button>
                                                            <button type="button" class="accordion-button  collapsed"
                                                                data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                                style="margin-left: 0%!important; padding-top: 11px !important;
                                                            padding-bottom: 11px !important;"></button>

                                                        </div>

                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse"
                                                        data-bs-parent="#myAccordion">
                                                        <div class="card-body">

                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Opportunity
                                                                                ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown styleClass="small-dropdown"
                                                                                filter="true"
                                                                                [options]="opportunityIdArray"
                                                                                [(ngModel)]="idSearchType"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text"
                                                                                class="form-control small-input"
                                                                                placeholder="Enter Opportunity ID"
                                                                                [(ngModel)]="oppurtunityId"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1"> Opportunity
                                                                                Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown styleClass="small-dropdown"
                                                                                filter="true"
                                                                                [options]="opportunityIdArray"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                [(ngModel)]="nameSearchType"
                                                                                optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text"
                                                                                class="form-control small-input"
                                                                                [(ngModel)]="opportunityName"
                                                                                placeholder="Enter Opportunity Name"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Lead</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text"
                                                                                class="form-control small-input"
                                                                                placeholder="Enter Lead"
                                                                                [(ngModel)]="leadPartyId"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Initial
                                                                                Stage</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown styleClass="small-dropdown"
                                                                                filter="true" [options]="initialArray"
                                                                                formControlName="initialStage"
                                                                                optionlabel="label"
                                                                                placeholder="Choose Initial Stage">
                                                                            </p-dropdown>

                                                                            <!-- <ng-select styleClass="small-dropdown"[ngModelOptions]="{standalone: true}" bindLabel="label" bindValue="value" [(ngModel)]="initialStage" [items]="initialStageArray" placeholder="Select Initial Stage" class="select">
                                                                            </ng-select> -->
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Type</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <!-- <ng-select class="customDropDown" styleClass="small-dropdown" [items]="initialTypeArray" [(ngModel)]="type" [ngModelOptions]="{standalone: true}" bindLabel="label" bindValue="value" placeholder="Select Type" class="select">
                                                                            </ng-select> -->
                                                                            <p-dropdown styleClass="small-dropdown"
                                                                                filter="true"
                                                                                [options]="initialTypeArray"
                                                                                formControlName="type"
                                                                                optionlabel="label"
                                                                                placeholder="Choose Type">
                                                                            </p-dropdown>
                                                                        </div>


                                                                    </div>
                                                                </div>



                                                            </div>

                                                            <div class="col-lg-4 col-12 main-submit-button "
                                                                style="    margin-left: 39%;">
                                                                <button _ngcontent-cta-c128="" type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="searchOppourtunity(false)">Find</button>
                                                                <button type="submit" class="btn btn-danger ml-2"
                                                                    (click)="resetSearch()">Reset</button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <!-- <div class="w3-card-4 classCard">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <P class="h-text">Find Opportunities</P>
                                                <button type="button" class="btn btn-outline-secondary submit-btn" data-toggle="modal" data-target="#exampleModalCenter">Create New
                                                    Opportunities</button>
                                                <button type="button" class="btn btn-outline-secondary" data-toggle="modal"
                                                data-target="#exampleModal" style="margin-left: 57%;">+ More Filters</button>
        
        
                                            </div>
                                            <br>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                            <label for="exampleInputEmail1">Opportunity ID</label>
                                                        </div>
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown styleClass="small-dropdown" filter="true" [options]="opportunityIdArray" [(ngModel)]="idSearchType" [ngModelOptions]="{standalone:true}" optionlabel="label">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control small-input" placeholder="Enter Opportunity ID" [(ngModel)]="oppurtunityId" [ngModelOptions]="{standalone:true}">
                                                        </div>
        
                                                        <div class="col-lg-3 form-group rightFormA">
                                                            <label for="exampleInputEmail1"> Opportunity Name</label>
                                                        </div>
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown styleClass="small-dropdown" filter="true" [options]="opportunityIdArray" [ngModelOptions]="{standalone:true}" [(ngModel)]="nameSearchType" optionlabel="label">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control small-input" [(ngModel)]="opportunityName" placeholder="Enter Opportunity Name" [ngModelOptions]="{standalone:true}">
                                                        </div>
        
                                                    </div>
                                                </div>
        
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                            <label for="exampleInputEmail1">Lead</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control small-input" placeholder="Enter Lead" [(ngModel)]="leadPartyId" [ngModelOptions]="{standalone:true}">
                                                        </div>
                                                        <div class="col-lg-3 form-group rightFormA">
                                                            <label for="exampleInputEmail1">Initial Stage</label>
                                                        </div>
                                                        <div class="col-lg-2" >
                                                            <p-dropdown styleClass="small-dropdown"  filter="true" [options]="initialArray" formControlName="initialStage" optionlabel="label" placeholder="Choose Initial Stage">
                                                            </p-dropdown>
                                                            
                                                            <ng-select styleClass="small-dropdown"[ngModelOptions]="{standalone: true}" bindLabel="label" bindValue="value" [(ngModel)]="initialStage" [items]="initialStageArray" placeholder="Select Initial Stage" class="select">
                                                            </ng-select> 
                                                        </div>
        
                                                    </div>
                                                </div>
        
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                            <label for="exampleInputEmail1">Type</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <ng-select class="customDropDown" styleClass="small-dropdown" [items]="initialTypeArray" [(ngModel)]="type" [ngModelOptions]="{standalone: true}" bindLabel="label" bindValue="value" placeholder="Select Type" class="select">
                                                            </ng-select>
                                                            <p-dropdown styleClass="small-dropdown" filter="true" [options]="initialTypeArray" formControlName="type" optionlabel="label" placeholder="Choose Type">
                                                            </p-dropdown>
                                                        </div>
        
        
                                                    </div>
                                                </div>
        
        
        
                                            </div>
                                            <div class="col-lg-4 col-12 main-submit-button " style="    margin-left: 39%;">
                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="searchOppourtunity(false)">Find</button>
                                                <button type="submit" class="btn btn-danger ml-2" (click)="resetSearch()">Reset</button>
                                            </div>
                                        </div> -->
                                        <br>
                                        <div class="w3-card-4 classCard">
                                            <header class="w3-container w3-blue">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling h4Margin">
                                                        Opportunities List
                                                    </h4>

                                                </div>
                                            </header>
                                            <div
                                                class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">

                                                        <div class="card own-account-table">
                                                            <p-table [value]="oppourtunityLists" [paginator]="true"
                                                                [rows]="rows" scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                Opportunity Id
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>

                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">Opportunity Name
                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">Initial Stage
                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">Lead/Account
                                                                                <p-sortIcon
                                                                                    field="category"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">Next Step
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">Estimated Amount
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">Next Step Date
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">Created At
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">Action
                                                                            </div>
                                                                        </th>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'190px'}"
                                                                            (click)="opportunityDetail(product.oppurtunityId)">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span class="account-button">
                                                                                    {{product.oppurtunityId}}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.opportunityName}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.initialStage}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.leadPartyId}}
                                                                        </td>

                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.nextStep}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.estimatedamount}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.nextStepDate | date:'yyyy-MM-dd
                                                                            HH:mm:ss'}}
                                                                        </td>

                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.createdStamp | date:'yyyy-MM-dd
                                                                            HH:mm:ss'}}
                                                                        </td>

                                                                        <td></td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div>
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn mr-2"
                                                                                    (click)="update(product.oppurtunityId)">Update</button>

                                                                                <!-- <svg class="hover" (click)="update(product.oppurtunityId)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                                                    <g transform="translate(0 -0.004)">
                                                                                        <g transform="translate(0 1.52)">
                                                                                            <path
                                                                                                d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                transform="translate(0 -34.137)" />
                                                                                        </g>
                                                                                        <g transform="translate(4.548 0.004)">
                                                                                            <g transform="translate(0 0)">
                                                                                                <path
                                                                                                    d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                    transform="translate(-102.409 -0.004)" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg> -->

                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="onClose(product.oppurtunityId,product.initialStage)">Close</button>

                                                                                <!-- <a> <svg width="4em" id="tick" data-toggle="tooltip" title="Close" (click)="onClose(product.oppurtunityId,product.initialStage)" height="3em" viewBox="0 0 16 16" class="bi bi-check" fill="currentColor"
                                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                                        <path fill-rule="evenodd"
                                                                                            d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
                                                                                    </svg></a> -->

                                                                            </div>
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>

                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Opportunities </span>
                
            </div>
            <div class=" bg-white color-grey">

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="w3-card-4 classCard">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <P class="h-text">Find Opportunities</P>
                                        <button type="button" class="btn btn-outline-secondary submit-btn" data-toggle="modal" data-target="#exampleModalCenter">Create New
                                            Opportunities</button>
                                        <button type="button" class="btn btn-outline-secondary" data-toggle="modal"
                                        data-target="#exampleModal" style="margin-left: 59%;">+ More Filters</button>


                                    </div>
                                    <br>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Opportunity ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown styleClass="small-dropdown" filter="true" [options]="opportunityIdArray" [(ngModel)]="idSearchType" [ngModelOptions]="{standalone:true}" optionlabel="label">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="text" class="form-control small-input" placeholder="Enter Opportunity ID" [(ngModel)]="oppurtunityId" [ngModelOptions]="{standalone:true}">
                                                </div>

                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1"> Opportunity Name</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown styleClass="small-dropdown" filter="true" [options]="opportunityIdArray" [ngModelOptions]="{standalone:true}" [(ngModel)]="nameSearchType" optionlabel="label">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="text" class="form-control small-input" [(ngModel)]="opportunityName" placeholder="Enter Opportunity Name" [ngModelOptions]="{standalone:true}">
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Lead</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="text" class="form-control small-input" placeholder="Enter Lead" [(ngModel)]="leadPartyId" [ngModelOptions]="{standalone:true}">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Initial Stage</label>
                                                </div>
                                                <div class="col-lg-2" >
                                                    <p-dropdown styleClass="small-dropdown"  filter="true" [options]="initialArray" formControlName="initialStage" optionlabel="label" placeholder="Choose Initial Stage">
                                                    </p-dropdown>
                                                    
                                                     <ng-select styleClass="small-dropdown"[ngModelOptions]="{standalone: true}" bindLabel="label" bindValue="value" [(ngModel)]="initialStage" [items]="initialStageArray" placeholder="Select Initial Stage" class="select">
                                                    </ng-select> 
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Type</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <ng-select class="customDropDown" styleClass="small-dropdown" [items]="initialTypeArray" [(ngModel)]="type" [ngModelOptions]="{standalone: true}" bindLabel="label" bindValue="value" placeholder="Select Type" class="select">
                                                    </ng-select>
                                                    <p-dropdown styleClass="small-dropdown" filter="true" [options]="initialTypeArray" formControlName="type" optionlabel="label" placeholder="Choose Type">
                                                    </p-dropdown>
                                                </div>


                                            </div>
                                        </div>



                                    </div>
                                    <div class="col-lg-4 col-12 main-submit-button " style="    margin-left: 39%;">
                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="searchOppourtunity(false)">Find</button>
                                        <button type="submit" class="btn btn-danger ml-2" (click)="resetSearch()">Reset</button>
                                    </div>
                                </div><br>
                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Opportunities List
                                            </h4>

                                        </div>
                                    </header>
                                    <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">

                                                <div class="card own-account-table">
                                                    <p-table [value]="oppourtunityLists" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                </div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                        </p-checkbox>
                                                                        Opportunity Id
                                                                        <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                    <div style="color: white;">Opportunity Name
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                    <div style="color: white;">Initial Stage
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                    <div style="color: white;">Lead/Account
                                                                        <p-sortIcon field="category"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;">Next Step
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;">Estimated Amount
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;">Next Step Date
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;">Created At
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>

                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;">Action
                                                                    </div>
                                                                </th>

                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'190px'}" (click)="opportunityDetail(product.oppurtunityId)">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                </div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                        </p-checkbox>
                                                                        {{product.oppurtunityId}}
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.opportunityName}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.initialStage}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.leadPartyId}}
                                                                </td>

                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.nextStep}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.estimatedamount}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.nextStepDate | date:'yyyy-MM-dd HH:mm:ss'}}
                                                                </td>

                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.createdStamp | date:'yyyy-MM-dd HH:mm:ss'}}
                                                                </td>

                                                                <td></td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    <div>
                                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn mr-2" (click)="update(product.oppurtunityId)">Update</button>

                                                                        <!-- <svg class="hover" (click)="update(product.oppurtunityId)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                                            <g transform="translate(0 -0.004)">
                                                                                <g transform="translate(0 1.52)">
                                                                                    <path
                                                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                        transform="translate(0 -34.137)" />
                                                                                </g>
                                                                                <g transform="translate(4.548 0.004)">
                                                                                    <g transform="translate(0 0)">
                                                                                        <path
                                                                                            d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                            transform="translate(-102.409 -0.004)" />
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg> 

                                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="onClose(product.oppurtunityId,product.initialStage)">Close</button>

                                                                         <a> <svg width="4em" id="tick" data-toggle="tooltip" title="Close" (click)="onClose(product.oppurtunityId,product.initialStage)" height="3em" viewBox="0 0 16 16" class="bi bi-check" fill="currentColor"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <path fill-rule="evenodd"
                                                                                    d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
                                                                            </svg></a> 

                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        </ng-template>
                                                    </p-table>

                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div> -->
<div class="container demo">
    <div class="modal left fade" id="exampleModal" tabindex="" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <div>More Filters</div>
                    <div class="close-modal"><button type="button" class="btn" data-dismiss="modal">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20.487" height="20.488"
                                viewBox="0 0 20.487 20.488">
                                <g transform="translate(306.243 -227.756)">
                                    <g transform="translate(-306.243 227.757)">
                                        <path
                                            d="M11.375,10.244l8.877-8.877A.8.8,0,0,0,19.121.235L10.243,9.113,1.366.235A.8.8,0,0,0,.234,1.367l8.877,8.877L.234,19.122a.8.8,0,0,0,1.132,1.132l8.877-8.877,8.877,8.877a.8.8,0,0,0,1.132-1.132Z"
                                            transform="translate(0 0)" />
                                    </g>
                                </g>
                            </svg>
                        </button></div>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="all-users-infomation font-13 font-medium own-user-select">
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-4 form-group rightFormC">
                                        <label for="exampleInputEmail1">Opportunity ID</label>
                                    </div>

                                    <div class="col-lg-4">
                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                            [(ngModel)]="oppurtunityId" [ngModelOptions]="{standalone: true}"
                                            aria-describedby="emailHelp" placeholder="Opportunity Id">
                                    </div>


                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-4 form-group rightFormC">
                                        <label for="exampleInputEmail1">Opportunity Name</label>
                                    </div>

                                    <div class="col-lg-4">
                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" [(ngModel)]="opportunityName"
                                            [ngModelOptions]="{standalone: true}" placeholder="Opportunity Name">
                                    </div>


                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-4 form-group rightFormC">
                                        <label for="exampleInputEmail1">Initial Stage</label>
                                    </div>
                                    <div class="col-lg-4">
                                        <ng-select [items]="initialStageArray" [(ngModel)]="initialStage"
                                            [ngModelOptions]="{standalone: true}" bindLabel="label" bindValue="value">
                                        </ng-select>
                                    </div>


                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-4 form-group rightFormC">
                                        <label for="exampleInputEmail1">Type</label>
                                    </div>
                                    <div class="col-lg-4">
                                        <ng-select [items]="initialTypeArray" [(ngModel)]="type"
                                            [ngModelOptions]="{standalone: true}" bindLabel="label" bindValue="value">
                                        </ng-select>
                                    </div>


                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-4 form-group rightFormC">
                                        <label for="exampleInputEmail1">Lead</label>
                                    </div>
                                    <div class="col-lg-4">
                                        <input type="text" [(ngModel)]="leadPartyId"
                                            [ngModelOptions]="{standalone: true}">
                                    </div>


                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="suppliers-wrapper modal-footer">
                    <button type="button" (click)="searchOppourtunity(true)"
                        class="btn btn-outline-secondary active">Submit</button>
                    <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-danger ml-2" (click)="resetSearch()">Reset</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span *ngIf="!this.show" class="color-black pl-1"> Create-Opportunities</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="container-fluid main-container-dialog">
                    <div class="row">
                        <div class="col-12">
                            <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                                <div class=" bg-white color-grey" style="width: 100%;">
                                    <div class="container-fluid">
                                        <ul class="tabbing-section tabbing-accordians tabClass">

                                            <li [ngClass]="activeCategory==2?'active':''"><a>
                                                    Create Opportunity</a></li>
                                        </ul>
                                    </div>

                                    <div class="create-new-leade mt-2">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <form *ngIf="createOppourtunityFormService.create_oppourtunity_form"
                                                    [formGroup]="createOppourtunityFormService.create_oppourtunity_form"
                                                    class="w-100">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Sales Opportunity
                                                                        ID</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="text" filter="true"
                                                                        id="exampleInputEmail1"
                                                                        formControlName="oppurtunityId"
                                                                        class="form-control small-input"
                                                                        placeholder="Select Oppourtunity Id"
                                                                        optionlabel="label">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Oppourtuinty
                                                                        Name</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <textarea style="font-size: 11px;"
                                                                        class="form-control small-input"
                                                                        formControlName="opportunityName"
                                                                        id="exampleFormControlTextarea1" rows="1"
                                                                        placeholder="Oppourtuinty Name"></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Initial
                                                                        Customer</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown styleClass="small-dropdown"
                                                                        [options]="initialAccountArray"
                                                                        formControlName="accountPartyId"
                                                                        placeholder="Initial Account" filter="true">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Lead</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown styleClass="small-dropdown"
                                                                        filter="true" [options]="leadPartyIdd"
                                                                        formControlName="leadPartyId"
                                                                        placeholder="Initial Lead">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Esitmated
                                                                        Amount</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="estimatedamount"
                                                                        class="form-control small-input"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Estimated Amount">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Probability</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" formControlName="probablity"
                                                                        class="form-control small-input"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter probability">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Initial Stage <span
                                                                            style="color:red">*</span></label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown styleClass="small-dropdown"
                                                                        filter="true" [options]="initialArray"
                                                                        formControlName="initialStage"
                                                                        optionlabel="label">
                                                                    </p-dropdown>
                                                                    <!--  <ng-container
                                                                            *ngIf="this.createOppourtunityFormService.create_oppourtunity_form.controls['initialStage'].invalid && (this.createOppourtunityFormService.create_oppourtunity_form.controls['initialStage'].dirty || this.createOppourtunityFormService.create_oppourtunity_form.controls['initialStage'].touched)">
                                                                            <p class="validate-field"
                                                                                *ngIf="this.createOppourtunityFormService.create_oppourtunity_form.controls['initialStage'].errors.required">
                                                                                Initial Stage is required!
                                                                            </p>
                                                                        </ng-container> -->
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Next Step</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" formControlName="nextStep"
                                                                        class="form-control small-input"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Next Step">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Next Step
                                                                        Date</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input class="small-input" type="date"
                                                                        id="exampleInputEmail1"
                                                                        [ngModel]="nextStepDate | date:'yyyy-MM-dd'"
                                                                        [value]="nextStepDate"
                                                                        formControlName="nextStepDate">

                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Type</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown styleClass="small-dropdown"
                                                                        filter="true" [options]="initialTypeArray"
                                                                        formControlName="type" optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>


                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Marketing
                                                                        Campaign</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown styleClass="small-dropdown"
                                                                        filter="true" [options]="marketCompaignArray"
                                                                        formControlName="marketing_campaign"
                                                                        optionlabel="label">

                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Currency <span
                                                                            style="color:red">*</span></label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown styleClass="small-dropdown"
                                                                        filter="true" [options]="currencyArray"
                                                                        optionlabel="label" formControlName="currencyId"
                                                                        placeholder="select currency">
                                                                    </p-dropdown>
                                                                    <!--  <ng-container
                                                                            *ngIf="this.createOppourtunityFormService.create_oppourtunity_form.controls['currencyId'].invalid && (this.createOppourtunityFormService.create_oppourtunity_form.controls['currencyId'].dirty || this.createOppourtunityFormService.create_oppourtunity_form.controls['currencyId'].touched)">
                                                                            <p class="validate-field"
                                                                                *ngIf="this.createOppourtunityFormService.create_oppourtunity_form.controls['currencyId'].errors.required">
                                                                                Currency is required!
                                                                            </p>
                                                                        </ng-container> -->
                                                                </div>


                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Source</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown styleClass="small-dropdown"
                                                                        filter="true" [options]="sourceArray"
                                                                        formControlName="source" optionlabel="label">

                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1"> Close Date</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input class="small-input" type="date"
                                                                        id="exampleInputEmail1"
                                                                        [ngModel]="closeDate | date:'yyyy-MM-dd'"
                                                                        [value]="closeDate" formControlName="closeDate">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Description</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <textarea style="font-size: 11px;"
                                                                        formControlName="description"
                                                                        placeholder="Enter Description"
                                                                        class="form-control small-input"
                                                                        id="exampleFormControlTextarea1"
                                                                        rows="1"></textarea>
                                                                </div>


                                                            </div>
                                                        </div>




                                                    </div>
                                                    <div class="main-submit-button" style="margin-right: 12%;">
                                                        <button style="width:159px" type="submit" *ngIf="!show"
                                                            (click)="onSubmit()"
                                                            class="btn btn-secondary submit-btn">Create</button>
                                                        <button type="submit" *ngIf="show" (click)="onUpdate()"
                                                            class="btn btn-secondary submit-btn">Update</button>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>