
                    
                                           <div #target></div>
                                            <div id="collapseSeven" class="panel-collapse collapse show" role="tabpanel" aria-labelledby="headingSeven">
                                              <div class="panel-body">
                                                  <!-- <br>
                                                <div class="w3-card-4 classCard" >
                                                    <div class="container-fluid">
                                                        <ul class="tabbing-section tabbing-accordians tabClass">
                                        
                                                            <li  [ngClass]="activeCategoryMain==2?'active':''"><a>
                                                               Reason Type</a></li>
                                                          
                                        
                                                        </ul>
                                                    </div>  
                                                  <form *ngIf="this.leaveTypeService.reason_type__form" [formGroup]="this.leaveTypeService.reason_type__form">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                           
                                                            <div class="col-lg-3 form-group formRight">
                                                                <label for="exampleInputEmail1">Leave Reason Type</label>
                                                            </div>
                                                            <div class="col-lg-2" *ngIf="!show">
                                                                <input type="email" formControlName="leaveTypeId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Leave Reason Type">
                                                            </div>
                                                            <div class="col-lg-2" *ngIf="show">
                                                                <input type="email" disabled formControlName="leaveTypeId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Leave Reason Type">
                                                            </div>

                                                            <div class="col-lg-3 form-group formRight">
                                                                <label for="exampleInputEmail1">Description<span style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email" formControlName="description" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
     
                                                  
                                                </div>
                                                <div style="    margin-left: -35%;" class="col-lg-12 col-12 main-submit-button "><button _ngcontent-cta-c128="" type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Create</button><button _ngcontent-cta-c128="" type="submit" *ngIf="show" (click)="updateReason()" class="btn btn-secondary submit-btn">Update</button><button _ngcontent-cta-c128="" type="submit" *ngIf="show" (click)="cancelReason()"  class="btn btn-danger">Cancel</button></div>
                                         </form>
                                                </div> -->
                                                <div class="w3-card-4 classCard" style="border-color: transparent !important;">
                                                <div class="all-users-infomation border-top-grey font-13 font-medium own-user-select userClass">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="reasonType"  [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"  [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox" binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined"
                                                                                            value="undefined"></div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                               Leave Reason ID <p-sortIcon field="code"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                          </th>
                                                                        <th pSortableColumn="name"><div style="color: white;">Description <p-sortIcon field="name"></p-sortIcon></div>
                                                                            <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                        </th>
                                                                        <!-- <th pSortableColumn="category">Last Name <p-sortIcon field="category"></p-sortIcon>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="quantity">State/Province <p-sortIcon field="quantity"></p-sortIcon>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="quantity">City <p-sortIcon field="quantity"></p-sortIcon>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="price">Phone Number <p-sortIcon field="price"></p-sortIcon>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th> -->
                                                                        <th pSortableColumn="price"><div style="color: white;">Action</div>
                                                                            <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}"><div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                            <span  class="account-button"   (click)="detailLeaveTypePage(product.emplLeaveReasonTypeId,product.description)">
                                                                                {{product.emplLeaveReasonTypeId}}
                                                                            </span> 
                                                                        </div>
                                                                           </td>
                                                                        <!-- <td>{{product.name}}</td>
                                                                        <td>{{product.category}}</td>
                                                                        <td>{{product.quantity}}</td>
                                                                        <td>{{product.price | currency: 'USD'}}</td> -->
                                                                        <td>{{product.description}}</td>
                                                                        <td><div>
                                                                            <svg class="hover" data-toggle="modal"  data-target="#leaveReasonModal" (click)="onUpdate(product.emplLeaveReasonTypeId,product.description); scroll(target)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222"><g transform="translate(0 -0.004)"><g transform="translate(0 1.52)"><path d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z" transform="translate(0 -34.137)"/></g><g transform="translate(4.548 0.004)"><g transform="translate(0 0)"><path d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z" transform="translate(-102.409 -0.004)"/></g></g></g></svg>
                                                                            <svg (click)="onDelete(product.emplLeaveReasonTypeId)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26"><defs><style>.a{fill:#f44336;}.b{fill:#fafafa; margin-left: 20px;}</style></defs><path class="a fill-color" d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0"/><path class="b" d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0" transform="translate(-147.576 -147.576)"/></svg>
                                                                        </div></td>
                                                                       
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <!-- <p class="paginate_data">
                                                                View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                <p class="paginate_data">
                                                                    View per page   </p>
                                                        </div>
                                                    </div>
                                                 </div>
                                              </div>
                                              </div>
                                            </div>
        
<div #rmodal class="modal fade" id="leaveReasonModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModal Title" aria-hidden="true">
    <div class="modal-dialog ht48" role="document">
        <div class="modal-content cssModelLength modal-cont" >
            <div class="modal-header">
                <span *ngIf="!show" style="color: black;"> Create Reason Type</span>
                <span *ngIf="show" style="color: black;"> Update Reason Type</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close" (click)="updateFalse()">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp card-body" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li class="active">
                                    <a>
                                        Reason Type</a>
                                </li>


                            </ul>
                        </div>
                        <form *ngIf="this.leaveTypeService.reason_type__form" [formGroup]="this.leaveTypeService.reason_type__form">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
                                       
                                        <div class="col-lg-3 form-group formRight">
                                            <label for="exampleInputEmail1">Leave Reason Type</label>
                                        </div>
                                        <div class="col-lg-3" *ngIf="!show">
                                            <input type="email" formControlName="leaveTypeId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Leave Reason Type">
                                        </div>
                                        <div class="col-lg-3" *ngIf="show">
                                            <input type="email" disabled formControlName="leaveTypeId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Leave Reason Type">
                                        </div>

                                        <div class="col-lg-3 form-group formRight">
                                            <label for="exampleInputEmail1">Description<span style="color:red">*</span></label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="email" formControlName="description" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                        </div>
                                       
                                    </div>
                                </div>

                              
                            </div>
                            <div style="    margin-left: -18%;" class="col-lg-12 col-12 main-submit-button "><button _ngcontent-cta-c128="" type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Create</button><button _ngcontent-cta-c128="" type="submit" *ngIf="show" (click)="updateReason()" class="btn btn-secondary submit-btn">Update</button>
                                <!-- <button _ngcontent-cta-c128="" type="submit" *ngIf="show" (click)="cancelReason()"  class="btn btn-danger">Cancel</button> -->
                            </div>
                     </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>