<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Inactive Customers</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey mt-20">
                <div class="w3-card-4 classCard">
                    <header class="w3-container w3-blue">
                        <div class="header-tabs">
                            <h4 class=" common-styling h4Margin">
                                Inactive Customers
                            </h4>
                        </div>
                    </header>
                    <div
                        class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">

                                <div class="card own-account-table">
                                    <p-table [value]="inactiveUserList" [paginator]="true"
                                        [rows]="rows" scrollWidth="100%"
                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                        [totalRecords]="total" [scrollable]="true"
                                        (sortFunction)="customSort($event)" [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                                <th [ngStyle]="{'width':'170px'}"
                                                    pSortableColumn="code">
                                                    <div class="checkbox-align"
                                                        style="color: white;">
                                                        <p-checkbox _ngcontent-egj-c329=""
                                                            styleclass="custom-checkbox"
                                                            binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div
                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                <div
                                                                    class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox"
                                                                        name="undefined"
                                                                        value="undefined">
                                                                </div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span
                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                        </p-checkbox>
                                                        Customer ID
                                                        <p-sortIcon field="code">
                                                        </p-sortIcon>
                                                    </div>
                                                </th>
                                                <th [ngStyle]="{'width':'170px'}"
                                                    pSortableColumn="price">
                                                    <div style="color: white;">Related Contact
                                                        <p-sortIcon field="price"></p-sortIcon>
                                                    </div>
                                                </th>
                                               

                                                <th [ngStyle]="{'width':'170px'}"
                                                    pSortableColumn="quantity">
                                                    <div style="color: white;">Customer Name
                                                        <p-sortIcon field="quantity">
                                                        </p-sortIcon>
                                                    </div>
                                                </th>
                                                <th [ngStyle]="{'width':'170px'}"
                                                pSortableColumn="name">
                                                    <div style="color: white;">Email Address
                                                        <p-sortIcon field="name"></p-sortIcon>
                                                    </div>
                                                </th>
                                                <th [ngStyle]="{'width':'170px'}"
                                                    pSortableColumn="price">
                                                    <div style="color: white;"> No. of Employess
                                                        <p-sortIcon field="price"></p-sortIcon>
                                                    </div>
                                                </th>
                                                <th [ngStyle]="{'width':'170px'}"
                                                    pSortableColumn="price">
                                                    <div style="color: white;"> Phone Number
                                                        <p-sortIcon field="price"></p-sortIcon>
                                                    </div>
                                                </th>
                                                <!-- <th [ngStyle]="{'width':'170px'}"
                                                    pSortableColumn="price">
                                                    <div style="color: white;"> City
                                                        <p-sortIcon field="price"></p-sortIcon>
                                                    </div>

                                                </th>
                                                <th [ngStyle]="{'width':'170px'}"
                                                    pSortableColumn="price">
                                                    <div style="color: white;">Country
                                                        <p-sortIcon field="price"></p-sortIcon>
                                                    </div>

                                                </th> -->
                                                
                                                <th [ngStyle]="{'width':'170px'}"
                                                    pSortableColumn="price">
                                                    <div style="color: white;">Created At
                                                        <p-sortIcon field="price"></p-sortIcon>
                                                    </div>
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td [ngStyle]="{'width':'170px'}"
                                                    >
                                                    <div class="checkbox-align">
                                                        <p-checkbox _ngcontent-egj-c329=""
                                                            styleclass="custom-checkbox"
                                                            binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div
                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                <div
                                                                    class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox"
                                                                        name="undefined"
                                                                        value="undefined">
                                                                </div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span
                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                        </p-checkbox>
                                                        <span class="account-button" (click)="refertToDetail(product.partyId)">{{product.partyId}}</span>  
                                                    </div>
                                                </td>
                                                <td [ngStyle]="{'width':'170px'}">
                                                    {{product.contactMechId?product.contactMechId:'N/A'}}
                                                  </td>
                                               
                                                <td [ngStyle]="{'width':'170px'}">
                                                    {{product.faUsername!==null?product.faUsername:"N/A"}}
                                                </td>
                                                <td [ngStyle]="{'width':'170px'}">
                                                    {{product.infoString
                                                    !==null?product.infoString:"N/A"}}
                                                </td>
                                                <td [ngStyle]="{'width':'170px'}">
                                               
                                                </td>
                                                <td [ngStyle]="{'width':'170px'}">
                                                    {{product.tnContactNumber
                                                    !==null?product.tnContactNumber:"N/A"}}
                                                 
                                                </td>
                                                <!-- <td [ngStyle]="{'width':'170px'}">
                                                    {{product.paCity
                                                    !==null?product.paCity:"N/A"}}
                                                </td>
                                                <td [ngStyle]="{'width':'170px'}">
                                                    {{product.paCountryGeoId
                                                    !==null?product.paCountryGeoId:"N/A"}}
                                                </td> -->
                                             
                                                <td [ngStyle]="{'width':'170px'}">
                                                    {{product.createdDate | date:'yyyy-MM-dd'}}
                                                </td>
                                        </ng-template>
                                    </p-table>
                                    <p class="paginate_data">
                                        View per page </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

