<div class="container-fluid main-container-wrapper">
  
    <div class="row">
      <div class="col-12">
  
        <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
         <span class="color-black pl-1 titlePanels" style="cursor: pointer;">CRM Overview page </span>
          <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Back</button>
  
        </div>
        <div class=" bg-white color-grey">
  
         
          <div class="create-new-leade mt-2">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
             <span class="color-black pl-1 titlePanels">Communications of party: {{this.commEvntPartyId}} </span>
             <span class="color-black pl-1 titlePanels"><a style="cursor: pointer;" (click)="mainPage()">All Communications</a>&nbsp;
                <a style="cursor: pointer;" (click)="newInternalNote();">| New Message</a>&nbsp;
                <a style="cursor: pointer;" (click)="newMail();">| New Email</a></span>
            </div>
            <div class="container-fluid">
              <div class="row">
                <div class="main-submit-button">
                <!-- <button type="submit" (click)="replyMail();"  class="btn btn-secondary submit-btn">Reply</button>
                &nbsp; <button type="submit" (click)="replyMail();"  class="btn btn-secondary submit-btn">Reply All</button> -->
                &nbsp; <button *ngIf="!this.Hidebtn" type="submit"   class="btn btn-secondary submit-btn" data-toggle="modal"
                data-target="#exampleModalCenter" (click)="communicationEventPatch()">Update</button>
                </div>
  
                <div *ngIf="activeCategory==2">
                  <div class="container-fluid">
                  <!--   <ul class="tabbing-section tabbing-accordians">
  
                      <li [ngClass]="activeCategoryValue==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                          Party Group Information </a></li>
                      <li [ngClass]="activeCategoryValue==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                          Contact Information
                        </a></li>
  
                    
  
                      <li [ngClass]="activeCategoryValue==6?'active':''" (click)="changeactiveCategoryFunction(6)"><a>
                          List Related Contacts </a></li>
                     
                      <li [ngClass]="activeCategoryValue==9?'active':''" (click)="changeactiveCategoryFunction(9)"><a>
                          Party Content </a></li>
                      <li [ngClass]="activeCategoryValue==10?'active':''" (click)="changeactiveCategoryFunction(10)"><a>
                          Notes
                        </a></li>
                     
                      <li [ngClass]="activeCategoryValue==15?'active':''" (click)="changeactiveCategoryFunction(15)"><a>
                          Payment Method
  
                        </a></li>
  
  
  
                    </ul> -->
                  </div>
                  <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                      <div class="row" style="filter: brightness(1.16);
                      background: lightgray!important;">
                        <form class="w-100">
                          
                            <div class="w-100">
                              <div class="panel-group">
                                <div *ngIf="activeCategoryValue==2">
                                  <div style="width: 49.5%; float:left">
                                    <div class="w3-card-4 cssClass" style="border:1px solid;
                                  border-color:#0d3769 !important;">
                                    <header class="w3-container w3-blue">
                                      <div class="header-tabs">
                                        <h4 class=" common-styling" style="margin-left: 7px;">
                                         Communication Event
                                        </h4>
                                     
                                      </div>
                                    </header>
                                
                                    <div class="w3-container" *ngIf="this.overviewDetail">
                                      <div class="panel-body" style="background: white;">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                          <div class="color-black container">
                                            <div  class="row">
                                              <div class="article-container">
                                                <div class="article">
                                                  <p class="col-lg-6 col-12">Communication Event Id </p>
                                                  <span class="color-grey pl-20 col-lg-10 col-12" style="cursor: pointer;" (click)="overviewPage(overviewDetail.communicationEventId)">{{overviewDetail.communicationEventId}}</span>
                                                </div>
                                                <div class="article">
                                                  <p class="col-lg-6 col-12">Communication Type Id</p>
                                                  <span class="color-grey pl-20 col-lg-10 col-12">{{overviewDetail.communicationEventTypeId}}</span>
                                                </div>
                                                <div class="article">
                                                  <p class="col-lg-6 col-12">Parent Comm. Event Id</p>
                                              <span class="color-grey pl-20 col-lg-10 col-12">{{overviewDetail.parentCommEventId}}</span>
                                                </div>
                                                <div class="article">
                                                  <p class="col-lg-6 col-12">From Party Id</p>
                                                  <span class="color-grey pl-20 col-lg-10 col-12">{{overviewDetail.partyIdFrom}}</span>
                                                </div>
                                                <div class="article">
                                                  <p class="col-lg-6 col-12">To Party Id</p>
                                              <span class="color-grey pl-20 col-lg-10 col-12">{{overviewDetail.partyIdTo}}</span>
                                                </div>
                
                                                <div class="article">
                                                  <p class="col-lg-6 col-12">Status Id</p>
                                              <span class="color-grey pl-20 col-lg-10 col-12">{{overviewDetail.statusId}}</span>
                                                </div>
                
                                                <div class="article">
                                                  <p class="col-lg-6 col-12">Last Modified Date </p>
                                                  <span class="color-grey pl-20 col-lg-10 col-12">{{overviewDetail.lastUpdatedTxStamp|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                </div>
                                                <div class="article">
                                                  <p class="col-lg-6 col-12">Send Date </p>
                                                  <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                </div>
                                                <div class="article">
                                                  <p class="col-lg-6 col-12">End Date</p>
                                              <span class="color-grey pl-20 col-lg-10 col-12">{{overviewDetail.datetimeEnded|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                </div>
                
                                                <div class="article">
                                                  <p class="col-lg-6 col-12">Subject</p>
                                              <span
                                                class="color-grey pl-20 col-lg-10 col-12">{{overviewDetail.subject}}</span>
                                                </div>
              
      
      
      
                                                
                                              </div>
                                            
                                              
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                
                                   
                                  </div>
                                  
                                  <div class="w3-card-4 cssClass" style="border:1px solid;
                                  border-color: #0d3769 !important;">
                                    <header class="w3-container w3-blue">
                                      <div class="header-tabs">
                                        <h4 class=" common-styling" style="margin-left: 7px;">
                                         Content
                                        </h4>
                                      
                                    </div>
                                    </header>
                                
                                    <div class="w3-container" *ngIf="this.overviewDetail">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select" style="overflow:scroll">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p class="col-lg-2 col-12">Content :</p>
                                                    <span class="color-grey pl-20 col-lg-8 col-8" style="overflow:auto;">{{overviewDetail.content}}</span>
                                                  </div>
                                                  <div class="article">
                                                    <p class="col-lg-2 col-12">Note :</p>
                                                    <span class="color-grey pl-20 col-lg-8 col-8" style="overflow:auto;">{{overviewDetail.note}}</span>
                                                  </div>
                                                
                                              
        
                                                  
                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                
                                  
                                  </div>
                                 
                                  
                                 </div>
                                 
                                 <div style="width: 49.5%; float:right">
                                    <div class="w3-card-4 cssClass" style="border:1px solid;
                                    border-color: #0d3769 !important;">
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                           Communication Event Role
                                          </h4>
                                         
                                        </div>
                                      </header>
                                  
                                     
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select padInfo">
                                            <div class="col-lg-12 col-12">
                                            <div class="form-group">
                                              <div class="card own-account-table borderTable" >
                                                <p-table [value]="communicationEventRole" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                  [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true"
                                                  (sortFunction)="customSort($event)" [customSort]="true">
                                                  <ng-template pTemplate="header">
                                                    <tr style="background: #0d3769;">
                                                      <th pSortableColumn="code">
                                                        <div class="checkbox-align" style="color: white;">
                                                          <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                              <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                  name="undefined" value="undefined"></div>
                                              <div role="checkbox"
                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                  class="ui-chkbox-icon ui-clickable"></span></div>
                                            </div>
                                            <!---->
                                          </p-checkbox>
                                                           Party ID <p-sortIcon field="code"></p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                          name="j_idt727:j_idt728:j_idt731:filter"
                                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                          value="" autocomplete="off"
                                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                          aria-disabled="false" aria-readonly="false">
                                                      </th>
                                                   
                                                      <th pSortableColumn="quantity"><div style="color: white;">Content Mech Id <p-sortIcon field="quantity"></p-sortIcon></div>
                                                        <div>
                                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                            value="" autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                            aria-disabled="false" aria-readonly="false">
                                                        </div>
                                                      </th>
                                                 
                                                      <th pSortableColumn="price"><div style="color: white;">Role Type Id <p-sortIcon field="price"></p-sortIcon></div>
                                                        <div>
                                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                            value="" autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                            aria-disabled="false" aria-readonly="false">
                                                        </div>
                                                      </th>
                                                      <th pSortableColumn="price"><div style="color: white;"> Status Id <p-sortIcon field="price"></p-sortIcon></div>
                                                        <div>
                                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                            value="" autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                            aria-disabled="false" aria-readonly="false">
                                                        </div>
                                                      </th>
                                                  
                                                    </tr>
                                                  </ng-template>
                                                  <ng-template pTemplate="body" let-product>
                                                    <tr>
                                                      <td >
                                                        <div class="checkbox-align">
                                                         {{product.partyId}}
                                                         
                                                        </div>
                                                      </td>
                                                      <td> {{product.contactMechEmail}}</td>
                                                      <td> {{product.roleTypeId}}</td>
                                                      <td> {{product.statusId}}</td>
                                                  
                
                                                    </tr>
                                                  </ng-template>
                                                </p-table>
                                               
                                                <p class="paginate_data">
                                                  View per page </p>
                                              </div>
                                            </div>
                                            </div>
                                          </div>
                                        </div>
                                
                                  
                                    
                                    </div>
                                  
                                  <div class="w3-card-4 cssClass" style="border:1px solid;
                                  border-color: #0d3769 !important;">
                                    <header class="w3-container w3-blue">
                                      <div class="header-tabs">
                                        <h4 class=" common-styling" style="margin-left: 7px;">
                                         Communication Content
                                        </h4>
                                      
                                      </div>
                                    </header>
                                
                                    <div class="w3-container">
                                      <div class="panel-body" style="background: white;">
                                        <div class="all-users-infomation font-13 font-medium own-user-select padInfo">
                                          <div class="col-lg-12 col-12">
                                          <div class="form-group">
                                            <div class="card own-account-table borderTable" >
                                              <p-table [value]="communicationEventContent" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true"
                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                <ng-template pTemplate="header">
                                                  <tr style="background: #0d3769;">
                                                    <th pSortableColumn="code">
                                                      <div class="checkbox-align" style="color: white;">
                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true"
                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                          <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                              name="undefined" value="undefined"></div>
                                                          <div role="checkbox"
                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                              class="ui-chkbox-icon ui-clickable"></span></div>
                                                        </div>
                                                        <!---->
                                                      </p-checkbox>
                                                        Content Name<p-sortIcon field="code"></p-sortIcon>
                                                      </div>
                                                      <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                        aria-disabled="false" aria-readonly="false">
                                                    </th>
                                                 
                                                    <th pSortableColumn="quantity"><div style="color: white;">Delete<p-sortIcon field="quantity"></p-sortIcon></div>
                                                      <div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                          name="j_idt727:j_idt728:j_idt731:filter"
                                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                          value="" autocomplete="off"
                                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                          aria-disabled="false" aria-readonly="false">
                                                      </div>
                                                    </th>
                                               
                                                
                                                  </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-product>
                                                  <tr>
                                                    <td >
                                                      <div class="checkbox-align">
                                                       {{product.contentName}}
                                                       
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <svg *ngIf="!this.Hidebtn" xmlns="http://www.w3.org/2000/svg" (click)="deleteContent(product.communicationEventId,product.contentId,product.fromDate);"
                                                      
                                                      class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                      <defs>
                                                        <style>
                                                          .a {
                                                            fill: #f44336;
                                                          }
            
                                                          .b {
                                                            fill: #fafafa;
                                                            margin-left: 20px;
                                                          }
                                                        </style>
                                                      </defs>
                                                      <path class="a fill-color"
                                                        d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                      <path class="b"
                                                        d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                        transform="translate(-147.576 -147.576)" />
                                                    </svg>
                                                    </td>
                                                  
                                                
              
                                                  </tr>
                                                </ng-template>
                                                <ng-template pTemplate="emptymessage" let-columns>
                                                  <tr>
                                                      <td>
                                                          <span class="text-center">
                                                                      <h4 class="ml-2">No Record Found</h4>
                                                                  </span>
                                                      </td>
                                                  </tr>
                                              </ng-template>
                                              </p-table>
                                             
                                              <p class="paginate_data">
                                                View per page </p>
                                            </div>
                                          </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                
                                  
                                  </div>
                                
                                <div class="w3-card-4 cssClass" style="border:1px solid;
                                    border-color: #0d3769 !important;">
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Child Communication Events
                                          </h4>
                                         
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select padInfo">
                                            <div class="col-lg-12 col-12">
                                            <div class="form-group">
                                              <div class="card own-account-table borderTable" >
                                                <p-table [value]="communicationEventChilds" [paginator]="true" [rows]="rows"
                                                scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                [totalRecords]="total" [scrollable]="true"
                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                <ng-template pTemplate="header">
                                                    <tr style="background: #0d3769;">
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                            <div class="checkbox-align" style="color: white;">
                                                              <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true"
                                                              class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                              <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                                    name="undefined" value="undefined"></div>
                                                                <div role="checkbox"
                                                                  class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                                    class="ui-chkbox-icon ui-clickable"></span></div>
                                                              </div>
                                                              <!---->
                                                            </p-checkbox>
                                                                Subject <p-sortIcon field="code"></p-sortIcon>
                                                            </div>
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                         <div style="color: white;"> Type <p-sortIcon field="name"></p-sortIcon> </div>
    
                                                        </th>
                                                     
                                                        <th [ngStyle]="{'width':'170px'}"
                                                            pSortableColumn="quantity"><div style="color: white;"> Status Id <p-sortIcon
                                                                field="quantity"></p-sortIcon> </div>
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                          <div style="color: white;"> Party Id <p-sortIcon field="price"></p-sortIcon> </div>
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                          <div style="color: white;">  Role Type Id<p-sortIcon field="price"></p-sortIcon> </div>
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                          <div style="color: white;">  Role Status Id<p-sortIcon field="price"></p-sortIcon> </div>
                                                           
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                          <div style="color: white;">   Created<p-sortIcon field="price"></p-sortIcon> </div>
                                                         
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                          <div style="color: white;">   Sent<p-sortIcon field="price"></p-sortIcon> </div>
                                                          
                                                        </th>
                                                       
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-product>
                                                  <tr>
                                                    <td  [ngStyle]="{'width':'170px'}" >
                                                      <div class="checkbox-align">
                                                       
                                                        {{product.subject}}
                                                      </div>
                                                    </td>
                                                    <td [ngStyle]="{'width':'170px'}"> {{product.communicationEventTypeDesc}}</td>
                                                    <td [ngStyle]="{'width':'170px'}"> {{product.statusDesc}}</td>
                                                    <td [ngStyle]="{'width':'170px'}"> {{product.partyIdFrom}}</td>
                                                    <td [ngStyle]="{'width':'170px'}"> {{product.roleTypeIdFrom}}</td>
                                                    <td [ngStyle]="{'width':'170px'}"> </td>
                                                    <td [ngStyle]="{'width':'170px'}"> {{product.datetimeStarted|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                                    <td [ngStyle]="{'width':'170px'}"> {{product.datetimeStarted|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                                    
                                                
              
                                                  </tr>
                                                </ng-template>
                                                <ng-template pTemplate="emptymessage" let-columns>
                                                  <tr>
                                                      <td>
                                                          <span class="text-center">
                                                                      <h4 class="ml-2">No Record Found</h4>
                                                                  </span>
                                                      </td>
                                                  </tr>
                                              </ng-template>
                                            </p-table>
                                          
                                               
                                                <p class="paginate_data">
                                                  View per page </p>
                                              </div>
                                            </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                    
                                    </div>
                                    
                                    <div class="w3-card-4 cssClass" style="border:1px solid;
                                        border-color: #0d3769 !important;">
                                          <header class="w3-container w3-blue">
                                            <div class="header-tabs">
                                              <h4 class=" common-styling" style="margin-left: 7px;">
                                                Customer Request List
                                              </h4>
                                             
                                            </div>
                                          </header>
                                      
                                          <div class="w3-container">
                                            <div class="panel-body" style="background: white;">
                                              <div class="all-users-infomation font-13 font-medium own-user-select padInfo">
                                                <div class="col-lg-12 col-12">
                                                <div class="form-group">
                                                  <div class="card own-account-table borderTable" >
                                                    <p-table [value]="customerRequestList" [paginator]="true" [rows]="rows"
                                                scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                [totalRecords]="total" [scrollable]="true"
                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                <ng-template pTemplate="header">
                                                    <tr style="background: #0d3769;">
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                            <div class="checkbox-align" style="color: white;">
                                                              <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true"
                                                              class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                              <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                                    name="undefined" value="undefined"></div>
                                                                <div role="checkbox"
                                                                  class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                                    class="ui-chkbox-icon ui-clickable"></span></div>
                                                              </div>
                                                              <!---->
                                                            </p-checkbox>
                                                                Cust Request ID <p-sortIcon field="code"></p-sortIcon>
                                                            </div>
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                         <div style="color: white;"> Cust Request Type ID  <p-sortIcon field="name"></p-sortIcon></div>
    
                                                        </th>
                                                     
                                                        <th [ngStyle]="{'width':'170px'}"
                                                            pSortableColumn="quantity">  <div style="color: white;"> Product Store ID<p-sortIcon
                                                                field="quantity"></p-sortIcon></div>
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                          <div style="color: white;"> Cust Request Name <p-sortIcon field="price"></p-sortIcon></div>
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                          <div style="color: white;"> Priority<p-sortIcon field="price"></p-sortIcon></div>
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                          <div style="color: white;">Cust Request Date<p-sortIcon field="price"></p-sortIcon></div>
                                                            <div>
                                                            </div>
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                          <div style="color: white;"> Response Required Date<p-sortIcon field="price"></p-sortIcon></div>
                                                            <div>
                                                            </div>
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                          <div style="color: white;">  From Party ID<p-sortIcon field="price"></p-sortIcon></div>
                                                            <div>
                                                            </div>
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                          <div style="color: white;"> Status ID<p-sortIcon field="price"></p-sortIcon></div>
                                                            <div>
                                                            </div>
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                          <div style="color: white;"> Last Modified Date<p-sortIcon field="price"></p-sortIcon></div>
                                                            <div>
                                                            </div>
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                          <div style="color: white;"> Reject<p-sortIcon field="price"></p-sortIcon></div>
                                                            <div>
                                                            </div>
                                                        </th>
                                                       
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-product>
                                                  <tr>
                                                    <td [ngStyle]="{'width':'170px'}" >
                                                      <div class="checkbox-align">
                                                      
                                                       {{product.custRequestId}}
                                                      </div>
                                                    </td>
                                                    <td [ngStyle]="{'width':'170px'}">{{product.custRequestTypeDesc}}</td>
                                                    <td [ngStyle]="{'width':'170px'}">{{product.productStoreId}}</td>
                                                    <td [ngStyle]="{'width':'170px'}">{{product.custRequestName}}</td>
                                                    <td [ngStyle]="{'width':'170px'}">{{product.priority}}</td>
                                                    <td [ngStyle]="{'width':'170px'}">{{product.custRequestDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                                    <td [ngStyle]="{'width':'170px'}">{{product.responseRequiredDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                                    <td [ngStyle]="{'width':'170px'}">{{product.fromPartyId}}</td>
                                                    <td [ngStyle]="{'width':'170px'}">{{product.statusId}}</td>
                                                    <td [ngStyle]="{'width':'170px'}">{{product.lastModifiedDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                                    <td [ngStyle]="{'width':'170px'}">Reject</td>
                                                    
                                                
              
                                                  </tr>
                                                </ng-template>
                                                <ng-template pTemplate="emptymessage" let-columns>
                                                  <tr>
                                                      <td>
                                                          <span class="text-center">
                                                                      <h4 class="ml-2">No Record Found</h4>
                                                                  </span>
                                                      </td>
                                                  </tr>
                                              </ng-template>
                                            </p-table>
                                                  
                                                  
                                                  
                                                    <p class="paginate_data">
                                                      View per page </p>
                                                  </div>
                                                </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                      
                                        
                                        </div>
                                 
                                
                                 
                                
                                
                                </div>
                                 
                                 
                                
                              
  
                            </div>
                            </div>
                          </div>
  
                        </form>
                      </div>
                    </div>
                  </div>
  
                </div>
            
  
  
              </div>
            </div>
  
          </div>
  
        </div>
  
      </div>
    </div>
  </div>

  <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">  Edit New Communication Event
            </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp">
                    <div class=" bg-white color-grey" style="margin-top: -8px;">


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="container-fluid">
                                    <ul class="tabbing-section tabbing-accordians tabClass" style="    margin-left: -3%;
                                margin-right: -3%;">

                                        <li><a>
                                    Edit Communication Event</a></li>



                                    </ul>
                                </div>
                                <div class="row">
                                    <form class="w-100" [formGroup]="createCommEvent">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
        
                                            Parent Comm Event ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="parentCommEventArray" optionlabel="label" placeholder="Parent Comm Event ID" filter="true" 
                                                        formControlName="parCommEventID">

                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
          
                                            Comm.Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Comm.Type ID" [options]="comtypeIdArray" optionlabel="label" filter="true" formControlName="commTypeId">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
        
                                        Status</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="stausIdArray" optionlabel="label" placeholder="Status" filter="true" formControlName="status">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
          
                                        Party From</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown (onChange)="contactEventMech($event.value)" [options]="partyIdArray" optionlabel="label" placeholder="Party From" filter="true" formControlName="partyFrom">

                                                        </p-dropdown>

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
        
                                    Party To</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown (onChange)="contactEventMechTo($event.value)" [options]="partyIdArray" optionlabel="label" placeholder="Party To" filter="true" formControlName="partyTo">

                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
          
                                    Contact Mech Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="contactMechTypeArray" optionlabel="label" placeholder="Contact Mech Type ID" filter="true" formControlName="conMechId">

                                                        </p-dropdown>


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
        
                                From Contact Mech</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="contactMechArray" optionlabel="label" placeholder="From Contact Mech" filter="true" formControlName="fromContactMechId">

                                                        </p-dropdown>


                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
          
                                To Contact Mech</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="contactMechToArray" optionlabel="label" placeholder="To Contact Mech" filter="true" formControlName="toContactMechId">

                                                        </p-dropdown>


                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
        
                            Role Type Id From</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="roleTypeIdArray" optionlabel="label" placeholder="Role Type Id From" filter="true" formControlName="roleTypeFrom">

                                                        </p-dropdown>


                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
          
                            Role Type Id To</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="roleTypeIdArray" optionlabel="label" placeholder="Role Type Id To" filter="true" formControlName="roleTypeTo">

                                                        </p-dropdown>


                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
        
                        Contact List ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="contactListIdArray" optionlabel="label" placeholder="Contact List ID" filter="true" formControlName="contactListId">

                                                        </p-dropdown>


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Start Date
          
                      </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Start Date" formControlName="startdate">


                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Finish Date/Time
        
                  </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Finish Date/Time" formControlName="finishDate">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Subject
          
                  </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Subject" formControlName="subject">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
        
                  Reason Enum ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="reasonenumArray" optionlabel="label" placeholder="Reason Enum ID" filter="true" formControlName="reasonEnumId">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
        
                  Content Mime Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Content Mime Type ID" filter="true" [options]="contentMMArray" optionlabel="label" formControlName="contentMimeTypeId">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Content
        
                </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Content" formControlName="content">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Note
        
                </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Note" formControlName="note">
                                                    </div>


                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -4%;">
                                        <button (click)="onSubmit();" type="submit" class="btn btn-secondary submit-btn">Update</button>


                                    </div>
                                </div>
                                

                            </div>

                        </div>

                    </div>
                </div>

            </div>
<br>
<br>
<br>
<br>
        </div>
    </div>
</div>
  <ngx-spinner></ngx-spinner>