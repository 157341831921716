import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProductionService } from '../production.service';
@Component({
  selector: 'app-create-routing',
  templateUrl: './create-routing.component.html',
  styleUrls: ['./create-routing.component.css']
})
export class CreateRoutingComponent implements OnInit {
  createroute:FormGroup;
  activeCategory=2;
  id: string;
  statusId: any;
  statusIdArray: any[]=[];
  routingId: any;
  show: boolean;

  constructor( readonly  _Router: Router,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
  
    readonly spinner: NgxSpinnerService,
    readonly productionService:ProductionService,
   
    readonly _ActivatedRoute: ActivatedRoute,
    ) { 
      this.createroute = this._FormBuilder.group({
        RoutingName: [''],
        Description:[''],
        QtyToProduce:[''],
        CurrentStatusID: [''],
      
       
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getstatusByType();
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.routingId = params["routingId"];
    
    
    });
    if(this.routingId){
      this.show=true;
      this.getRoutingListById();
    }else{
      this.show=false;
    }
  }
  
  getRoutingListById() {
    this.spinner.show();
  
    this.productionService.getRoutingListById(this.routingId).subscribe((res: any) => {
     
      this.spinner.hide();
      setTimeout(() => {
       
      
        const formValue = this.createroute;

        formValue.patchValue({
          RoutingName: res.data.routingName,
          Description:res.data.description,
          QtyToProduce:res.data.quantityToProduce,
          CurrentStatusID: res.data.currentStatusId,
        })
      
      }, 2000);
    })
  
  }
  getstatusByType() {
    this.spinner.show();
    this.id="ROUTING_STATUS"
    this.productionService.getstatusByType(this.id).subscribe(res => {
      this.statusId = res.data;
      this.spinner.hide();
      for (const value of this.statusId) {
        this.statusIdArray.push({
          label: value.statusCode,
          value: value.statusId
        })
      }
    })
    
  }
  onSubmit() {
  
    const requestData = {
      "currentStatusId": this.createroute.value.CurrentStatusID,
      "description": this.createroute.value.Description,
      "quantityToProduce": this.createroute.value.QtyToProduce,
      "routingId": "",
      "routingName": this.createroute.value.RoutingName,

    }

    this.productionService.postRouting(requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this._Router.navigate(['/production/routing']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  onUpdate() {
  
    const requestData = {
      "currentStatusId": this.createroute.value.CurrentStatusID,
      "description": this.createroute.value.Description,
      "quantityToProduce": this.createroute.value.QtyToProduce,
      "routingId": this.routingId,
      "routingName": this.createroute.value.RoutingName,

    }

    this.productionService.updateRouting(requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this._Router.navigate(['/production/routing']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  cancelSubmit(){
    this._Router.navigate(['/production/routing']);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
