<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Financial Account</span>
                <span class="color-black pl-1"  *ngIf="!show"> >Add Financial Account Reconciliations </span>
                <span class="color-black pl-1"  *ngIf="show"> >Update Financial Account Reconciliations </span>
                 
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                        Create New Add Financial Account Reconciliations</a></li>
                        <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                            Update Financial Account Reconciliations</a></li>
                     
                     
          
          
                    </ul>
                  </div>
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createRecon">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">
                                                    Gl Reconciliation Name </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email"     formControlName="glReconciliationName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Gl Reconciliation Name ">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Description</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"    formControlName="description"    class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Description">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Created Date</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date"    formControlName="createdDate"    class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Created Date">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Last Modified Date </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date"    formControlName="lastModifiedDate"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Last Modified Date">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Organization Party ID <span
                                                    style="color:red">*</span> </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown     [options]="partyIdArray" optionlabel="label" formControlName="organizationPartyId"  placeholder="Enter Organization Party ID"  filter="true" >
                                                            
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Opening Balance
                                               
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"    formControlName="openingBalance"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Opening Balance">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Reconciliation Date</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date"    formControlName="reconciledDate"    class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Reconciliation Date">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA" *ngIf="show">
                                                <label for="exampleInputEmail1">Status </label>
                                            </div>
                                            <div class="col-lg-2" *ngIf="show">
                                                <p-dropdown     [options]="FinancialAccountIntialDataArray" optionlabel="label" formControlName="statusId"  placeholder="Enter Status Id"  filter="true" >
                                                            
                                                </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" *ngIf="show">
                                                <label for="exampleInputEmail1">Reconciled Balance </label>
                                            </div>
                                            <div class="col-lg-2" *ngIf="show">
                                                <label>{{this.reconciledBalance}}</label>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>

                                  
                                   
                                </div>
                                

                            </form>
                            <div class="main-submit-button"  style="margin-left: 66%;">
                                <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit"  *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>
                               
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
