<div class="container-fluid main-container-wrapper">
    <br>

    <div class=" bg-white color-grey">
        <div class="port-header-contact create-lead font-medium mb-0">
           <span class="color-black pl-1 titlePanels">General Ledger Setting

            </span>
        </div>
        <div class="w3-card-4 classCard classCardType">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                    <li [ngClass]="activeCategory==1?'active':''" (click)="chartAccount()"><a>
                    Charts of Account</a></li>
                    <li [ngClass]="activeCategory==2?'active':''" (click)="timePeriod()"><a>
                    Custom Time Period</a></li>
                    <li [ngClass]="activeCategory==3?'active':''" (click)="cost()"><a>
                        Cost</a></li>
                    <li [ngClass]="activeCategory==4?'active':''" (click)="paymentMethod()"><a>
                        Payment Method Type</a></li>
                    <li [ngClass]="activeCategory==5?'active':''" (click)="invoiceMethod()"><a>
                            Invoice Item Type</a></li>
                    <li [ngClass]="activeCategory==6?'active':''" (click)="rate()"><a>
                            Rates</a></li>
                    <li [ngClass]="activeCategory==7?'active':''" (click)="foreignExc()"><a>
                                Foreign Exchange Rates</a></li>
                    <li [ngClass]="activeCategory==8?'active':''" (click)="accountCat()"><a>
                                Gl Account Category</a></li>
                    <li [ngClass]="activeCategory==9?'active':''" (click)="costCenter()"><a>
                                    Cost Center</a></li>

                </ul>
            </div>

            <div class="create-new-leade mt-2">
                <div class="container-fluid">


                    <div class="panel-group">
                        <div *ngIf="activeCategory==1">


                            <div class="panel-collapse">
                                <br>

                                <div class="create-new-leade mt-2">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <form class="w-100">
                                                <div class="w3-card-4 classCard">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <!--new code-->
                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" data-toggle="modal" data-target="#exampleModalCenter">Create New Charts of Account</button>
                                                        <!--new code end-->
                                                        <!-- <button type="button" class="btn btn-outline-secondary" routerLinkActive="active"
                                                                    (click)="createGlSetting()">Create New Charts of Account</button> -->
                                                        <button type="button" class="btn btn-outline-secondary" routerLinkActive="active" style="width:126px !important" (click)="assignGlAccount()">Assign Gl Account</button>

                                                    </div><br>
                                                    <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">

                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <label for="exampleInputEmail1">List Accounts</label>
                                                                <div class="port-header-contact create-lead font-medium mb-0">
                                                                    <div class="suppliers-wrapper">
                                                                        <button type="button" class="btn btn-outline-secondary" routerLinkActive="active" (click)="export();">Print PDF</button>


                                                                    </div>
                                                                </div>
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="globalGLSettingAccounts" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Code
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Account Name
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                    <div style="color: white;"> Parent Gl Account ID
                                                                                        <p-sortIcon field="quantity"></p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Gl Account Type ID
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Gl Account Class ID
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Gl Resource Type ID
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Gl Xbrl Class ID
                                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Parent Gl Xbrl Class ID
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Description
                                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Product ID
                                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> External ID
                                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                                    </div>
                                                                                </th>



                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}" (click)="accountDetails()">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span (click)="referToAccSummary(product.glAccountId)">
                                                                                            {{product.glAccountId}}
                                                                                        </span>
                                                                                       

                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.accountName}}
                                                                                </td>


                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.parentGlAccountId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.glAccountType}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.glAccountClass}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.glResourceType}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.glXbrlClass}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">

                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.description}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.productId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.externalId}}
                                                                                </td>

                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>


                </div>
            </div>
        </div>
    </div>
</div>


<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1" *ngIf="!show">Add Chart of Accounts </span>
                <span class="color-black pl-1" *ngIf="show">Update Chart of Accounts</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''" *ngIf="!show"><a>
                            Add Chart of Accounts</a></li>
                                <li [ngClass]="activeCategory==1?'active':''" *ngIf="show"><a>
                                Update Chart of Accounts</a></li>

                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createGlobalSettingForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Gl Account Type Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter GL Account Type Id" filter="true" formControlName="glAccountTypeId" [options]="glAccountTypeArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA" *ngIf="show">
                                                        <label for="exampleInputEmail1">Gl Account ID	
                                                   
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3" *ngIf="show">
                                                        <input type="email" [ngModelOptions]="{standalone: true}" [(ngModel)]="this.glAccountId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Gl Account ID" readonly>

                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Gl Account Class Id</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter GL Account Class Id" filter="true" formControlName="glAccountClassId" [options]="glAccountClassArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Gl Resource Type Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Gl Resource Type Id" filter="true" formControlName="glResourceTypeId" [options]="glResourceTypeArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Gl Xbrl Class Id</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Gl Xbrl Class Id" filter="true" formControlName="glXbrlClassId" [options]="glXbrlClassArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Parent Gl Account Id </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Parent Gl Account Id" filter="true" formControlName="parentGlAccountId" [options]="parentGlAccountArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Account Code	
                                                   
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="accountCode" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Code">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Account Name</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="accountName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Name">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="description" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Product Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Product Id" filter="true" formControlName="productId" [options]="ProductsArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">External Id</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="externalId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter External Id">
                                                    </div>


                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 82%;">
                                        <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->
<ngx-spinner></ngx-spinner>