import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { FinancialService } from 'src/app/services/financial.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-fix-assignment',
  templateUrl: './create-fix-assignment.component.html',
  styleUrls: ['./create-fix-assignment.component.css']
})
export class CreateFixAssignmentComponent implements OnInit {
  show: boolean;
  activeCategory = 2;
  fixedAssetId: string;
  assignmentForm: FormGroup;
  editMode: boolean;
  partyIds: any[];
  roleTypeIds: any[];
  statusIds: any[];
  assignment: any;
  constructor(
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _FinancialService: FinancialService,
    readonly spinner: NgxSpinnerService,
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly _ActivatedRoute: ActivatedRoute
  ) {
    this.editMode = false;
    this.fixedAssetId = '';
    this.assignmentForm = this._FormBuilder.group({
      allocatedDate: [''],
      comments: [''],
      fromDate: [''],
      partyId: [''],
      roleTypeId: [''],
      statusId: [''],
      thruDate: ['']
    });
    this.partyIds = [];
    this.roleTypeIds = [];
    this.statusIds = [];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.fixedAssetId = this._ActivatedRoute.snapshot.queryParams.fixedAssetId;
    this._ActivatedRoute.queryParams.subscribe(data => {
      if (data.partyId && data.fromDate) {
        this._FinancialService.getAssignmentById({
          fixedAssetId: this.fixedAssetId, fromDate: data.fromDate ? moment(Number(data.fromDate)).format('YYYY-MM-DD HH:mm:ss') : '',
          partyId: data.partyId, roleTypeId: data.roleTypeId
        }).then(data => {
            if (data.success) {
              this.assignment = data.data[0];
              this.assignmentForm.patchValue({
                ...data.data[0], ...{
                  allocatedDate: data.data[0].allocatedDate ? moment(data.data[0].allocatedDate).format('YYYY-MM-DD') : '',
                  fromDate: data.data[0].fromDate ? moment(data.data[0].fromDate).format('YYYY-MM-DD') : '',
                  thruDate: data.data[0].thruDate ? moment(data.data[0].thruDate).format('YYYY-MM-DD') : ''
                }
              });
            }
          });
        this.editMode = true; this.assignmentForm.controls.fromDate.disable();
        this.assignmentForm.controls.fromDate.updateValueAndValidity();
        this.assignmentForm.controls.partyId.disable();
        this.assignmentForm.controls.partyId.updateValueAndValidity();
        this.assignmentForm.controls.roleTypeId.disable();
        this.assignmentForm.controls.roleTypeId.updateValueAndValidity();
      }
    });
    this.getRoleTypeIds();this.getPartyIds();this.getStatusIds();
  }
  getRoleTypeIds(): void {
    this.spinner.show();
    this._FinancialService.getRoleTypeIds('all')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.roleTypeIds = data.data.map(value => {
            return {
              label: value.description,
              value: value.roleTypeId
            };
          });
        }
      });
     
  }
  getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.partyIds = data.data.PersonsGroups.map(value => {
            return {
              label: value.partyId,
              value: value.partyId
            };
          });
        }
      });
     
  }
  getStatusIds(): void {
    this.spinner.show();
    this._FinancialService.getInitialAssignmentData()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.statusIds = data.data.status.map(value => {
            return {
              label: value.description,
              value: value.statusId
            };
          })
        }
      });
     
  }
  onUpdate() {
    this.spinner.show();
    this._FinancialService.updateFixedAssetAssignment(this.fixedAssetId, {
      ...this.assignment, ...this.assignmentForm.value, ...{
        allocatedDate: this.assignmentForm.value.allocatedDate ? moment(this.assignmentForm.value.allocatedDate).format('YYYY-MM-DD HH:mm:ss') : '',
        fromDate: this.assignment.fromDate ? moment(this.assignment.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
        thruDate: this.assignmentForm.value.thruDate ? moment(this.assignmentForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Updated');
          this.spinner.hide();
          this.assignmentForm.controls.fromDate.disable();
          this.assignmentForm.controls.fromDate.updateValueAndValidity();
          this.assignmentForm.controls.partyId.disable();
          this.assignmentForm.controls.partyId.updateValueAndValidity();
          this.assignmentForm.controls.roleTypeId.disable();
          this.assignmentForm.controls.roleTypeId.updateValueAndValidity();
          this.cancelSubmit();
        }
      });
     
  }
  onSubmit() {
    this.spinner.show();
    this._FinancialService.createFixedAssetAssignment(this.fixedAssetId, {
      ...this.assignmentForm.value, ...{
        allocatedDate: this.assignmentForm.value.allocatedDate ? moment(this.assignmentForm.value.allocatedDate).format('YYYY-MM-DD HH:mm:ss') : '',
        fromDate: this.assignmentForm.value.fromDate ? moment(this.assignmentForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
        thruDate: this.assignmentForm.value.thruDate ? moment(this.assignmentForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Created');
          this.spinner.hide();
          this.cancelSubmit();
        }
      });

  }
  cancelSubmit() {
    this.assignmentForm.reset();
    this._Router.navigate(['/financial/fix-assest-summary-page'], { queryParams: { fixedAssetId: this.fixedAssetId } });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
