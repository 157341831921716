<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12 pad_0">
            
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Work Skill</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>


            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard border_wdth_1">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                flex-wrap: wrap !important;
                display: flex !important;">
                            <li [ngClass]="activeCategory==2?'active':''" routerLink="/fsm/fsm-setting/work-order-priorites">
                                <a>
                                    Work Order Priorites
                                </a>
                            </li>

                            <li [ngClass]="activeCategory==3?'active':''" routerLink="/fsm/fsm-setting/work-order-skill">
                                <a>
                                    Work Order Skill
                                </a>
                            </li>
                            <li [ngClass]="activeCategory==4?'active':''" routerLink="/fsm/fsm-setting/payment-processing">
                                <a>
                                    Payment Processing
                                </a>
                            </li>
                        </ul>
                    </div>                
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                         
                                            <div *ngIf="activeCategory==3">
                                                <div class=" bg-white color-grey">


                                                    <div class="create-new-leade mt-2">
                                                        <div class="container-fluid">
                                                            <div class="row">
                                                                <form class="w-100">
                                                                    <div class="w3-card-4 classCard">
                                                                        <header class="w3-container w3-blue">
                                                                            <div class="header-tabs">
                                                                                <h4 class=" common-styling h4Margin">
                                                                                    Skills List
                                                                                </h4>
                                    
                                                                                <button type="submit" data-target="#addNewSkillPopup" data-toggle="modal" class="btn btn-secondary submit-btn mr-2" >
                                                                                    Add a new Skill
                                                                                 </button>
                                                                               
                                                                                 
                                                                                
                                                                            </div>
                                                                        </header>
                                                                    
                                                                        <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: 0%;">
                                                                            <div class="col-lg-12 col-12">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        
                                                                                        <p-table [value]="FsmSkillAll" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769; border: 1px solid rgb(206, 214, 218) !important;">
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                                        <div class="checkbox-align" style="color:white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                
                                                                                                            </p-checkbox>
                                                            
                                                                                                            Display As 
                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color:white;">
                                                                                                            Action
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
                                                                                                   
                                                                                                
                                                                                                
                                                            
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                            <span class="account-button">
                                                                                                                {{product.skillDisplayAs}}</span>
                                                            
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    
                                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                                        <span>
                                                                                                            <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer; " data-toggle="modal"  data-target="#editSkillPopup" (click)="updateFsmSkillPatch(product)">Update</small>
                                                                                                            <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer; " (click)="deleteFsmSkill(product)" >Delete</small>
                                                                                                            
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    
                                                                        
                                                            
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span class="text-center">
                                                                                                            <h4 class="ml-2">No
                                                                                                                Record Found
                                                                                                            </h4>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <p class="paginate_data">
                                                                                            View per page
                                                            
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                            </div>


                                           

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-grey titlePanels">Work Skill                </span>
            </div>
            <div class=" bg-white color-grey">


                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Skills List
                                            </h4>

                                            <button type="submit" data-target="#addNewSkillPopup" data-toggle="modal" class="btn btn-secondary submit-btn mr-2" >
                                                Add a new Skill
                                             </button>
                                           
                                             
                                            
                                        </div>
                                    </header>
                                
                                    <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: 0%;">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">
                                                <div class="card own-account-table">
                                                    
                                                    <p-table [value]="FsmSkillAll" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769; border: 1px solid rgb(206, 214, 218) !important;">
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color:white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                </div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </p-checkbox>
                        
                                                                        Display As 
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                    <div style="color:white;">
                                                                        Action
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                               
                                                            
                                                            
                        
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                </div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                        </p-checkbox>
                                                                        <span  class="account-button"  >
                                                                            {{product.skillDisplayAs}}</span>
                        
                                                                    </div>
                                                                </td>
                                                                
                                                                <td [ngStyle]="{'width':'180px'}">
                                                                    <span>
                                                                        <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer; " data-toggle="modal"  data-target="#editSkillPopup" (click)="updateFsmSkillPatch(product)">Update</small>
                                                                        <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer; " (click)="deleteFsmSkill(product)" >Delete</small>
                                                                        
                                                                    </span>
                                                                </td>
                                                                
                                    
                        
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                            <tr>
                                                                <td>
                                                                    <span class="text-center">
                                                                        <h4 class="ml-2">No
                                                                            Record Found
                                                                        </h4>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                    <p class="paginate_data">
                                                        View per page
                        
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div> -->

<div class="modal fade" id="addNewSkillPopup" tabindex="-1" role="dialog" aria-labelledby="addNewSkillPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add a New Skill</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add a New Skill</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addNewSkillForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Display As</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" name=""
                                                        aria-describedby="" placeholder="Enter Display As"
                                                        formControlName="displayAs">
                                                    </div>

                                                   
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: -14%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createFsmSkill()" >Add</button>
                                                <button type="submit" class="btn btn-secondary submit-btn" >Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="editSkillPopup" tabindex="-1" role="dialog" aria-labelledby="editSkillPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Edit Skill</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Edit Skill</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="editSkillForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Display As</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" name=""
                                                        aria-describedby="" placeholder="Enter Display As"
                                                        formControlName="displayAs">
                                                    </div>

                                                   
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: -14%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="updateFsmSkill()" >Add</button>
                                                <button type="submit" class="btn btn-secondary submit-btn" >Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>





<ngx-spinner></ngx-spinner>
