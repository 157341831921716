import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-my-work',
  templateUrl: './my-work.component.html',
  styleUrls: ['./my-work.component.css']
})
export class MyWorkComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 3;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  partyId: any;
  pageNo = 1;
  sprintTable: any;
  productBacklogForm: FormGroup;
  productForm: FormGroup;
  rateTypeID: any;
  rateTypeIDArray: any[] = [];
  workEffortName: any;
  workEffortNameArray: any[] = [];
  updateTimesheet: FormGroup;
  addnewtimsheet: FormGroup;
  selectedDate: string;
  CurrentTimesheet: any;
  allTimesheet:any;
  CurrentTimesheetNbr: any;
  CurrentTimesheetNbrHeader:any;
  CurrentTimesheetTable: any;
  CurrentTimesheetHead: any;
  showTable: boolean;
  showStatusBtn:boolean;
  product:any;
  showFirstTable:boolean;
  CurrentTimesheetStatus: any;
  CurrentTimesheetTimesheet:any;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly headerService: HeaderService,    
    private _location: Location,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router
  ) {
    this.productForm = this._FormBuilder.group({
      CompanyandProductOwner: [''],
      ProductName: [''],
      Description: [''],

    });
    this.productBacklogForm = this._FormBuilder.group({
      ProductID: [''],
      ProductName: [''],
      StatusId: [''],

    });
    this.updateTimesheet = this._FormBuilder.group({
      taskName: [''],
      rateTypeID: [''],
      planHrs: [''],
      mon: [''],
      tue: [''],
      wed: [''],
      thu: [''],
      fri: [''],
      sat: [''],
      sun: [''],
      actualHours: [''],
      actTotal: [''],

    });
    this.addnewtimsheet = this._FormBuilder.group({
      selectDate: [''],
    })
  }

  ngOnInit(): void {
    this.partyId = localStorage.getItem('partyId');
    this.showTable = false;
    this.getRateTypeId();
    this.getWorkEffortName();
    this.getCurrentTimesheet();
    this.getAllTimesheet();
  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  getCurrentTimesheet() {
    this.spinner.show();
    this.myContactsService.getCurrentTimesheet(this.partyId).subscribe(res => {
      this.CurrentTimesheetHead = res.data[0].header;
      this.CurrentTimesheetStatus = res.data[0].status;
      this.CurrentTimesheetTimesheet = res.data[0].commonTimeSheetId;
      this.CurrentTimesheetTable = res.data[0].timeEntry;
      this.spinner.hide();
      if(this.CurrentTimesheetHead){
        this.showFirstTable = true;
    }
    })
  }
  getAllTimesheet() {
    this.spinner.show();
    this.myContactsService.getAllTimesheet(this.partyId).subscribe(res => {
      this.allTimesheet = res.data;
      this.spinner.hide();
    })
  }
  updateTimesheetStatus() {
    this.spinner.show();
   
    this.myContactsService.updateTimesheet("TIMESHEET_COMPLETED",this.CurrentTimesheetTimesheet).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Status Updated");
        this.spinner.hide();
        // this.closebutton7.nativeElement.click();
         this.getAllTimesheet();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  updateTimesheets() {
    this.spinner.show();
   
    this.myContactsService.updateTimesheet("TIMESHEET_COMPLETED",this.product.timesheetId).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Status Updated");
        this.spinner.hide();
        // this.closebutton7.nativeElement.click();
         this.getAllTimesheet();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  getCurrentTimesheetNbr(product) {
    this.product = product;
    this.showTable = true;
    if(product.statusId=='TIMESHEET_IN_PROCESS'){
      this.showStatusBtn = true ;
    }
    else{
      this.showStatusBtn = false ;

    }
    
    this.spinner.show();
    this.myContactsService.getCurrentTimesheetNbr(product.timesheetId).subscribe(res => {
      this.CurrentTimesheetNbr = res.data[0].timeEntry;
      this.CurrentTimesheetNbrHeader = res.data[0].header;
      this.spinner.hide();
    })
  }
  Backlog() {
    this._Router.navigate(["/psa/product-backlog"])
  }
  Sprint() {
    this._Router.navigate(["/psa/sprints"])
  }
  MyWork() {
    this._Router.navigate(["/psa/my-work"])
  }
  Tasks() {
    this._Router.navigate(["/psa/tasks"])
  }
  OpenTest() {
    this._Router.navigate(["/psa/open-test"])
  }
  Revision() {
    this._Router.navigate(["/psa/find-task-revision"])
  }
  Administration() {
    this._Router.navigate(["/psa/admin-scrum"])
  }
  myProfile() {
    this._Router.navigate(["/psa/my-profile"], { queryParams: { partyId:  this.partyId  } })
  }
  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  getWorkEffortName() {
    this.spinner.show();
    this.myContactsService.getWorkEffortName().subscribe(res => {
      this.workEffortName = res.data[0].name;
      this.spinner.hide();
      for (const value of this.workEffortName) {
        this.workEffortNameArray.push({
          label: value.workEffortName,
          value: value.workEffortId
        })
      }
    })
  }
  getRateTypeId() {
    this.spinner.show();
    this.myContactsService.getRateTypeId().subscribe(res => {
      this.rateTypeID = res.data;
      this.spinner.hide();
      for (const value of this.rateTypeID) {
        this.rateTypeIDArray.push({
          label: value.description,
          value: value.rateTypeId
        })
      }
    })
  }
  createAddNewWeeklyTimesheetCurrent(){
    this.spinner.show();
    // const requestData =[ {
    //   "checkComplete": product.checkComplete ,
    //   "commanTimeSheetId": product.commanTimeSheetId ,
    //   "fri":  product.fri ,
    //   "fromDate": "",
    //   "hours":  product.hours ,
    //   "mon":  product.mon ,
    //   "rateTypeID":  product.rateTypeID ,
    //   "sat":  product.sat ,
    //   "statusId": product.statusId ,
    //   "sun": product.sun ,
    //   "taskName":  product.taskName ,
    //   "thruDate": "",
    //   "thur": product.thur ,
    //   "tue":  product.tue ,
    //   "wed": product.wed ,

    // }]
    this.myContactsService.createAddNewWeeklyTimesheet(this.CurrentTimesheetTable).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Success");
        this.spinner.hide();
        // this.closebutton7.nativeElement.click();
        // this.getPaymentMethods();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  createAddNewWeeklyTimesheet(){
    this.spinner.show();
    // const requestData =[ {
    //   "checkComplete": product.checkComplete ,
    //   "commanTimeSheetId": product.commanTimeSheetId ,
    //   "fri":  product.fri ,
    //   "fromDate": "",
    //   "hours":  product.hours ,
    //   "mon":  product.mon ,
    //   "rateTypeID":  product.rateTypeID ,
    //   "sat":  product.sat ,
    //   "statusId": product.statusId ,
    //   "sun": product.sun ,
    //   "taskName":  product.taskName ,
    //   "thruDate": "",
    //   "thur": product.thur ,
    //   "tue":  product.tue ,
    //   "wed": product.wed ,

    // }]
    this.myContactsService.createAddNewWeeklyTimesheet(this.CurrentTimesheetNbr).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Success");
        this.spinner.hide();
        // this.closebutton7.nativeElement.click();
        // this.getPaymentMethods();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  createWeeklyTimesheet() {
    this.spinner.show();
    const sdate = this.addnewtimsheet.get('selectDate').value;
    this.selectedDate = this.datePipe.transform(sdate, "yyyy-MM-dd hh:mm:ss");
    const formData = {
      
       "fromDate": this.selectedDate,
       "partyId": this.partyId,
       "thruDate": ""
    }
    this.myContactsService.createWeeklyProTimesheet(formData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        // this.closebutton7.nativeElement.click();
         this.getAllTimesheet();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
 

  projectMain() {
    this._Router.navigate(["/psa/project/project"])
  }
  myTasks() {
    this._Router.navigate(["/psa/project/my-tasks"])
  }
  myTime() {
    this._Router.navigate(["/psa/project/my-time"])
  }
  projects() {
    this._Router.navigate(["/psa/project/projects"])
  }
  tasks() {
    this._Router.navigate(["/psa/project/tasks-project"])
  }
  resources() {
    this._Router.navigate(["/psa/project/resources"])
  }
  timesheet() {
    this._Router.navigate(["/psa/project/timesheet-project"])
  }
  
  skillType() {
    this._Router.navigate(["/psa/project/skill-type"])
  }
  requestList() {
    this._Router.navigate(["/psa/project/request-list"])
  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }
}