import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-assign-list-gl',
  templateUrl: './assign-list-gl.component.html',
  styleUrls: ['./assign-list-gl.component.css']
})
export class AssignListGlComponent implements OnInit {
  
  show: boolean;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[] = [];
  personId: any;
  partyIdArray: any[] = [];
  assignGlAccount: FormGroup;
  actualfromdate: string;
  activeCategoryInv = 2;
  
  
  
  
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  company: string;
  globalGLSettingAccounts: any;
  constructor(  readonly spinner: NgxSpinnerService,
   
    readonly datePipe: DatePipe,
    readonly toastr: ToastrService,
   
    
    
    readonly router: Router,
   
    readonly accountsService: AccountsService,
   
    readonly _FormBuilder: FormBuilder,

   ) 
   {
    this.assignGlAccount = this._FormBuilder.group({
      fromDate: [''],
      glAccountId: [''],
      organizationPartyId: ['']

    });
  }
   
   
   
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {

    this.show = false;
    this.getGlAccountInitialData();
    this.getPartyId();

    this.getAssignedAccountGlSetting();
  }

  getPartyId() {
    this.spinner.show();
    this.accountsService.internalOrganization().subscribe(res => {
      this.personId = res.data;
       for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.groupName,
          value: value.partyId
        })
      } 
    })
    this.spinner.hide();
  }

  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.assignGLPartyId = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.assignGLPartyId) {
        this.assignGLPartyIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }





    })
   
  }
  onSubmit() {
    this.spinner.show();
    const fromActualdate = this.assignGlAccount.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate":  this.actualfromdate,
      "glAccountId": this.assignGlAccount.value.glAccountId,
      "organizationPartyId": this.assignGlAccount.value.organizationPartyId,
    }

    this.accountsService.assignGLAccount(requestData).subscribe((res: any) => {
    
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Assign Successfully");
        this.router.navigate(["financial/gl-assign-list"])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
   
  }
  
  getAssignedAccountGlSetting() {
    this.spinner.show();
    this.company='Company'
    this.accountsService.getAssignedAccountGlSetting(this.company,this.finSize).subscribe(res => {
      this.globalGLSettingAccounts = res.data;
      this.spinner.hide();
    })
   
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  createAssign(){
  this.router.navigate(['financial/assign-gl-account']);
}
ngOnDestroy(): void {
  this.spinner.hide();
    }
}
