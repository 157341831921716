<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Agreement Items </span>
              
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    Edit Agreement Item
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Agreement Item Type ID</label>
                                           <div class="">
                                            <p-dropdown filter="true" optionLabel="label">
                                                <ng-template let-item1 pTemplate="selectedItem1">
                                                    <span class="label-info"
                                                        style="margin-left: .5em">{{item1.label}}</span>
                                                </ng-template>
                                                <ng-template let-car1 pTemplate="item1">
                                                    <div class="ui-helper-clearfix"
                                                        style="position: relative;height: 25px;">
                                                        <div style="font-size:14px;margin-top:4px">{{car1.label}}</div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                           </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Currency</label>
                                           <div class="">
                                            <p-dropdown filter="true" optionLabel="label">
                                                <ng-template let-item1 pTemplate="selectedItem1">
                                                    <span class="label-info"
                                                        style="margin-left: .5em">{{item1.label}}</span>
                                                </ng-template>
                                                <ng-template let-car1 pTemplate="item1">
                                                    <div class="ui-helper-clearfix"
                                                        style="position: relative;height: 25px;">
                                                        <div style="font-size:14px;margin-top:4px">{{car1.label}}</div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                           </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Agreement Text</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>  
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Agreement Image	</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>   
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-submit-button">
                <button type="submit" class="btn btn-secondary submit-btn" (click)="additionalItems()">Submit</button>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 