<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Report Generator</span>
                <span>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toReports()">Home</button>
                    <button _ngcontent-jra-c762="" type="submit" class="btn btn-danger buttonclass" (click)="backSubmit()">Back</button>

                </span>
            </div>
            <div class="create-new-leade">
                <div class="container-fluid" style="padding: 0;">
                    <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                        <div class="w-100">
                            <div class="w3-card-4 classCard" >
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    Business Intelligence

                                </div><br>
                            <form class="w-100">

                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12 mrgin" >
                                        <div class="">
                                            <div>
                                                <h2>Business Intelligence Integrated Framework
                                                </h2>
                                            </div>

                                            <!-- <p style="margin: top -1% !important; ;">This is a proof of concept for an OFBiz integrated framework for business 
                                                intelligence analysis. The idea is to define a set entities for business 
                                                intelligence analysis in a separate (olap) database, accessible, in the same way as
                                                    the main one (OLTP), via the delegator. The new olap database, will contain
                                                    all the entities necessary to datawarehouse analysis: dimensions, facts and 
                                                    star schemas (that are view-entities). The dimensions, facts and star schemas
                                                    are defined according to best practices for datawarehouses and best practices
                                                    for OFBiz: they are defined as standard entities using the xml entity defs;
                                                    clear and consistent naming conventions are used and they are documented 
                                                    by meta data descriptions (in the entity def). The best practices for 
                                                    datawarehouses are mainly taken from the great book "The Data Warehouse
                                                    Toolkit - Second Edition" by Ralph Kimball. The data for dimensions and
                                                    facts are maintained and kept up to date using standard OFBiz services 
                                                    (most of them written in minilang to facilitate customizations) and eca
                                                    rules (easily customizable).

                                            </p>
                                            <h4 class="mt-1">Current Status
                                            </h4>

                                            <p  class="mrgin">The main low level services to post data to the datawarehouse, to map the operational
                                                    keys with the datawarehouse ones, and to manage history of data changes are already
                                                    implemented; they will be improved as the datawarehouse will grow (e.g. performance 
                                                    enhancements). The following dimensions (and data services) are defined: 
                                                    CurrencyDimension, DateDimension, ProductDimension; we will have to complete
                                                    them and design the other ones (the total number of dimension will be probably
                                                    around 20). Just one fact and one star schema are defined: SalesInvoiceItemFact
                                                        and SalesInvoiceItemStarSchema.
                                            </p>

                                            <h4 class="mt-1">Quick Start Guide
                                            </h4>

                                            <p class="mrgin">
                                                
                                                You can quickly initialize the datawarehouse by clicking on the link below: 
                                                The CurrencyDimension, DateDimension, ProductDimension and the 
                                                SalesInvoiceItemFact entities will be automatically populated. 
                                                Then you can run the analysis using the report builder menu. The
                                                    report builder is a very simple tool that allows you to run the
                                                    analysis on all the star schemas defined in the system. However, 
                                                    right now there is just one star schema defined: SalesInvoiceItemStarSchema
                                                    that is a simple "profit and loss" (P&L) analysis. Just select using the 
                                                    checkboxes the fields (columns) you want to see in the report and a simple
                                                    report will be shown: according to the columns selected the data will be 
                                                    aggregated (drill up/down); all the descriptions you see in the report screens 
                                                    are pulled from the entity definitions.
                                            </p> -->

                                            <div class="mt-2">
                                                <button type="submit" class="btn btn-secondary submit-btn">Quick Init DataWarehouse</button>
                                                <!-- <span>
                                                    (From Date 2013-09-01 00:00:00.000 Through Date 2023-09-01 00:00:00.000) 
                                                    The initialization is needed only once, because the product data and sales
                                                        invoice data are kept updated by ecas: if you create/update a product or 
                                                        create a new invoice, the new information will be immediately included in
                                                        the datawarehouse analysis
                                                </span> -->
                                            </div>
                                            

                                        </div>
                                    </div>
                                    
                                </div>
                            </form>
                                
                            </div><br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div>