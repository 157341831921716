import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr'; import { Location } from '@angular/common';
import { FeaturesService } from '../features.service';

@Component({
  selector: 'app-new-feature-category',
  templateUrl: './new-feature-category.component.html',
  styleUrls: ['./new-feature-category.component.css']
})
export class NewFeatureCategoryComponent implements OnInit {
  activeCategory = 2;
  featureCategoryForm: FormGroup;
  featureCategoryId: string;
  editMode: boolean;
  parentCategory: any[];
  featureCategory: any;
  url: string;
  constructor(
    readonly _FormBuilder: FormBuilder,
    readonly _ActivatedRoute: ActivatedRoute,
      private _location: Location,
      readonly _FeaturesService: FeaturesService,
    readonly _ToastrService: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly  _Router: Router
  ) {
    this.url='/facilities/catalog/feature-category'
    this.featureCategoryForm = this._FormBuilder.group({
      description: ['', [Validators.required]],
      parentCategoryId: ['']
    });
    this.featureCategoryId = '';
    this.editMode = false;
    this.parentCategory = [];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.featureCategoryId = this._ActivatedRoute.snapshot.queryParams.featureCategoryId;
    if (this.featureCategoryId) {
      this.editMode = true;
      this._FeaturesService.getFeatureCategoryById(this.featureCategoryId)
        .then(data => {
          if (data.success) {
            this.featureCategory = data.data;
            this.featureCategoryForm.patchValue({
              description: data.data.description,
              parentCategoryId: data.data.parentCategoryId
            });
            this.featureCategoryForm.controls.parentCategoryId.disable();
            this.featureCategoryForm.controls.parentCategoryId.updateValueAndValidity();
          }
        });
      
    }
    this.getParentCategory();
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  getFeatureCategories(): void {
    this.spinner.show();
    const searchFilter = {

      "description": "",
      "descriptionSearchType": "Contains",
      "productFeatureCategoryId": this.featureCategoryId,
      "productFeatureCategoryIdSearchType": "Contains"

    }
    this._FeaturesService.getFeatureCategoriesList({ pageNo: 1, pageSize: 200 }, searchFilter)
      .then(data => {

        if (data.success) {
          setTimeout(() => {
            this.featureCategory = data.data[0];
            const formValue = this.featureCategoryForm;
            formValue.patchValue({
              description: this.featureCategory.description,
              parentCategoryId: this.featureCategory.productFeatureCategoryId,
            })
            this.featureCategoryForm.controls.parentCategoryId.disable();
            this.featureCategoryForm.controls.parentCategoryId.updateValueAndValidity();
          }, 3000);
          this.spinner.hide();
        }
      });
  }
  getParentCategory(): void {
    this.spinner.show();
    this._FeaturesService.getFeatureParentCategory()
      .then(data => {
        this.spinner.hide();
        this.parentCategory = data.data.map(value => {
          return {
            label: value.description,
            value: value.productFeatureCategoryId
          };
        });
      });
     
  }
  submit(): void {
    this.spinner.show();
    if (this.editMode) {
      if (this.featureCategoryForm.valid) {
        this._FeaturesService.updateFeatureCategory({
          ...this.featureCategory, ...this.featureCategoryForm.value
        }, this.featureCategoryId)
          .then(data => {
            if (data.success) {
              this.featureCategoryForm.reset();
              this.editMode = false;
              this._ToastrService.success('Updated');
              this.spinner.hide();
              this.featureCategoryForm.controls.parentCategoryId.disable();
              this.featureCategoryForm.controls.parentCategoryId.updateValueAndValidity();
              this._Router.navigate([this.url]);
            } });
      } } else {
      if (this.featureCategoryForm.valid) {
        this._FeaturesService.createFeatureCategory(this.featureCategoryForm.value)
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Created');
              this.spinner.hide();
              this.featureCategoryForm.reset();
              this._Router.navigate([this.url]);
            }
          });
      }
    } }
  cancel() {
    this._Router.navigate([this.url]);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
