<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span class="color-black pl-1 titlePanels"> Fixed Asset of {{ this.fixedAssetId}} </span> <span></span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Cancel</button>
            </div>
            <div class=" bg-white color-grey">


                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist"
                                            aria-multiselectable="true">
                                            <div class="">
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">


                                                        <h4 class=" common-styling hCard">
                                                            Fixed Asset Information
                                                        </h4>
                                                        <span class="edit-buttons"
                                                            style="margin-right: 7px;">
                                                            <a data-toggle="modal" (click)="edit()"
                                                                data-target="#exampleModalCenter">Update</a>
                                                        </span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                            <div class="row">
                                                               

                                                                    <div class="color-black container">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-6">

                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Fixed
                                                                                            Asset
                                                                                            ID</p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.fixedAssetId
                                                                                            ?
                                                                                            fixAsset?.fixedAssetId:'N/A'}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Fixed
                                                                                            Asset
                                                                                            Type ID</p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.fixedAssetTypeId
                                                                                            ? fixAsset?.fixedAssetTypeId
                                                                                            :'N/A'}}</span>

                                                                                    </div>

                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Fixed
                                                                                            Asset
                                                                                            Parent ID</p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.parentFixedAssetId
                                                                                            ?
                                                                                            fixAsset?.parentFixedAssetId:'N/A'}}</span>
                                                                                    </div>



                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">
                                                                                            Instance Of
                                                                                            Product ID</p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.instanceOfProductId
                                                                                            ?
                                                                                            fixAsset?.instanceOfProductId:'N/A'}}</span>
                                                                                    </div>

                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Class
                                                                                            Enum ID
                                                                                        </p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.classEnumId
                                                                                            ?
                                                                                            fixAsset?.classEnumId:'N/A'}}</span>
                                                                                    </div>

                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">
                                                                                            Customer ID
                                                                                        </p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.partyId
                                                                                            ?
                                                                                            fixAsset?.partyId:'N/A'}}</span>
                                                                                    </div>

                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">
                                                                                            RoleType ID
                                                                                        </p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.roleTypeId
                                                                                            ? fixAsset?.roleTypeId :
                                                                                            'N/A'}}</span>
                                                                                    </div>

                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Fixed
                                                                                            Asset
                                                                                            Name</p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.fixedAssetName
                                                                                            ?
                                                                                            fixAsset?.fixedAssetName:'N/A'}}</span>
                                                                                    </div>

                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">
                                                                                            Acquire Order
                                                                                            ID</p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.acquireOrderId
                                                                                            ? fixAsset?.acquireOrderId :
                                                                                            'N/A'}}</span>
                                                                                    </div>

                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">
                                                                                            Acquire Order
                                                                                            Item Seq ID</p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.acquireOrderItemSeqId?
                                                                                            fixAsset?.acquireOrderItemSeqId:'N/A'}}</span>
                                                                                    </div>

                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Date
                                                                                            Acquired
                                                                                        </p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.dateAcquired
                                                                                            ?
                                                                                            (fixAsset?.dateAcquired|date:'yyyy-MM-d                                                                        hh:mm:ss'):'N/A'}}</span>
                                                                                    </div>

                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Date
                                                                                            Last
                                                                                            Serviced</p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.dateLastServiced
                                                                                            ?
                                                                                            (fixAsset?.dateLastServiced|
                                                                                            date:'yyyy-MM-d    hh:mm:ss'):'N/A'}}</span>
                                                                                    </div>
                                                                                    <div class="article">

                                                                                        <p class="col-lg-6 col-12">Date
                                                                                            Next
                                                                                            Service</p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.dateNextService
                                                                                            ?
                                                                                            (fixAsset?.dateNextService|
                                                                                            date:'yyyy-MM-d  hh:mm:ss'):'N/A'}}</span>
                                                                                    </div>


                                                                                </div>
                                                                                <div class="col-lg-6">


                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">
                                                                                            Expected End
                                                                                            Of Life</p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.expectedEndOfLife
                                                                                            ?
                                                                                            (fixAsset?.expectedEndOfLife|date:'yyyy-MM-d      hh:mm:ss'):'N/A'}}</span>
                                                                                    </div>

                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">
                                                                                            Actual End Of
                                                                                            Life</p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.actualEndOfLife
                                                                                            ?
                                                                                            (fixAsset?.actualEndOfLife|date:'yyyy-MM-d  hh:mm:ss'):'N/A'}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">
                                                                                            Production
                                                                                            Capacity</p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.productionCapacity?fixAsset?.productionCapacity:'N/A'}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">UOM
                                                                                        </p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.uomId
                                                                                            ?
                                                                                            fixAsset?.uomId:'N/A'}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">
                                                                                            Calendar</p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.calendarId
                                                                                            ? fixAsset?.calendarId :
                                                                                            'N/A'}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">
                                                                                            Serial Number
                                                                                        </p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.serialNumber
                                                                                            ?
                                                                                            fixAsset?.serialNumber:'N/A'}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">
                                                                                            Located At
                                                                                            Facility ID</p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.locatedAtFacilityId
                                                                                            ?
                                                                                            fixAsset?.locatedAtFacilityId:'N/A'}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">
                                                                                            Located At
                                                                                            Location Seq ID</p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.locatedAtLocationSeqId
                                                                                            ?
                                                                                            fixAsset?.locatedAtLocationSeqId:'N/A'}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">
                                                                                            Salvage Value
                                                                                        </p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.salvageValue?
                                                                                            fixAsset?.salvageValue:'N/A'}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">
                                                                                            Depreciation
                                                                                        </p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.depreciation?
                                                                                            fixAsset?.depreciation:'N/A'}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">
                                                                                            Purchase Cost
                                                                                        </p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.purchaseCost?
                                                                                            fixAsset?.purchaseCost:'N/A'}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">
                                                                                            Purchase Cost
                                                                                            Uom ID </p>
                                                                                        <span
                                                                                            class="color-grey pl-20 col-lg-10 col-12">{{fixAsset?.purchaseCostUomId?
                                                                                            fixAsset?.purchaseCostUomId:'N/A'}}</span>
                                                                                    </div>

                                                                                </div>
                                                                            </div>






                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br>

                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">

                                                            <h4 class=" common-styling hCard">
                                                                Products
                                                            </h4>
                                                            <span class="edit-buttons hCreate">
                                                                <a data-toggle="modal"
                                                                    data-target="#exampleModalCenterProduct">Create New Products</a>
                                                            </span>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select allUserCardDetail">

                                                                <div class="form-group" style="width: 100%;">
                                                                    <div class="card own-account-table borderTable">
                                                                        <p-table [value]="assetProducts"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Product
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Fixed
                                                                                            Asset Product Type
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;"> From
                                                                                            Date
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Through
                                                                                            Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Created
                                                                                            At
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Action
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            {{product.productId}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.fixedAssetProductType}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.fromDate
                                                                                        |date:'yyyy-MM-d hh:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.thruDate |
                                                                                        date:'yyyy-MM-d hh:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.createdStamp
                                                                                        |date:'yyyy-MM-d hh:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <svg class="hover"
                                                                                            (click)="onUpdateProduct(product.fixedAssetProductTypeId,product.fromDate,product.productId)"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="16" height="16"
                                                                                            viewBox="0 0 21.223 21.222">
                                                                                            <g
                                                                                                transform="translate(0 -0.004)">
                                                                                                <g
                                                                                                    transform="translate(0 1.52)">
                                                                                                    <path
                                                                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                        transform="translate(0 -34.137)" />
                                                                                                </g>
                                                                                                <g
                                                                                                    transform="translate(4.548 0.004)">
                                                                                                    <g
                                                                                                        transform="translate(0 0)">
                                                                                                        <path
                                                                                                            d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                            transform="translate(-102.409 -0.004)" />
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </svg>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                                            (click)="removeProduct(product.fixedAssetProductTypeId,product.fromDate,product.productId)"
                                                                                            class="ml-20" width="16"
                                                                                            height="16"
                                                                                            viewBox="0 0 26 26">
                                                                                            <defs>
                                                                                                <style>
                                                                                                    .a {
                                                                                                        fill: #f44336;
                                                                                                    }

                                                                                                    .b {
                                                                                                        fill: #fafafa;
                                                                                                        margin-left: 20px;
                                                                                                    }
                                                                                                </style>
                                                                                            </defs>
                                                                                            <path class="a fill-color"
                                                                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                            <path class="b"
                                                                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                transform="translate(-147.576 -147.576)" />
                                                                                        </svg>
                                                                                    </td>



                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <br>
                                                <div class="col-lg-6">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">

                                                            <h4 class=" common-styling hCard">
                                                                Assignments
                                                            </h4>
                                                            <span class="edit-buttons hCreate">
                                                                <a data-toggle="modal"
                                                                    data-target="#exampleModalCenterAssignMent">Create New Assignments</a>
                                                            </span>

                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select allUserCardDetail">


                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="assetAssignments"
                                                                        [paginator]="true" [rows]="rows"
                                                                        scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Customer ID
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;"> Role
                                                                                        Type ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;"> From
                                                                                        Date
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;"> Through
                                                                                        Date
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Allocated
                                                                                        Date
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;"> Status
                                                                                        ID
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;"> Comments
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;"> Created
                                                                                        At
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        [ {{product.partyId}}]
                                                                                        {{product.party}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.roleType}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.fromDate | date:'yyyy-MM-d
                                                                                    hh:mm:ss'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.thruDate | date:'yyyy-MM-d
                                                                                    hh:mm:ss'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.allocatedDate
                                                                                    |date:'yyyy-MM-d hh:mm:ss'}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.status}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.comments}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.createdStamp
                                                                                    |date:'yyyy-MM-d hh:mm:ss'}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <svg class="hover"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="16" height="16"
                                                                                        (click)="onUpdateAssignment(product.fromDate ,  product.partyId, product.roleTypeId)"
                                                                                        viewBox="0 0 21.223 21.222">
                                                                                        <g
                                                                                            transform="translate(0 -0.004)">
                                                                                            <g
                                                                                                transform="translate(0 1.52)">
                                                                                                <path
                                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                    transform="translate(0 -34.137)" />
                                                                                            </g>
                                                                                            <g
                                                                                                transform="translate(4.548 0.004)">
                                                                                                <g
                                                                                                    transform="translate(0 0)">
                                                                                                    <path
                                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </svg>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                        (click)="removeAssignment(product.fromDate ,  product.partyId, product.roleTypeId)"
                                                                                        class="ml-20" width="16"
                                                                                        height="16" viewBox="0 0 26 26">
                                                                                        <defs>
                                                                                            <style>
                                                                                                .a {
                                                                                                    fill: #f44336;
                                                                                                }

                                                                                                .b {
                                                                                                    fill: #fafafa;
                                                                                                    margin-left: 20px;
                                                                                                }
                                                                                            </style>
                                                                                        </defs>
                                                                                        <path class="a fill-color"
                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                        <path class="b"
                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                    </svg>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page
                                                                    </p>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>


                                            </div>



                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Fixed Asset</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        {{editMode ? 'Update':'Create'}} Fixed Asset</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createFixAssetForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Fixed Asset ID
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="fixedAssetId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Fixed Asset ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Fixed Asset Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="fixedAssetTypeId"
                                                            [options]="fixedAssetTypes"
                                                            placeholder="Enter Fixed Asset Type ID" filter="true">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Fixed Asset Parent ID </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="parentFixedAssetId"
                                                            [options]="fixAssetIds"
                                                            placeholder="Enter Fixed Asset Parent ID" filter="true">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Instance Of Product ID </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="instanceOfProductId"
                                                            [options]="productIds"
                                                            placeholder="Enter Instance Of Product ID" filter="true">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Class Enum ID
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="classEnumId"
                                                            [options]="classEnumId" placeholder="Enter Class Enum ID"
                                                            filter="true">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Customer ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="partyId"
                                                            placeholder="Enter Customer ID" filter="true"
                                                            [options]="partyIds">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Role Type ID
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="roleTypeIds" formControlName="roleTypeId"
                                                            placeholder="Enter RoleType ID" filter="true">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Fixed Asset Name </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="fixedAssetName"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Fixed Asset Name">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Acquire Order ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="acquireOrderId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Acquire Order ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Acquire Order Item Seq
                                                            ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="acquireOrderItemSeqId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Acquire Order Item Seq ID">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Date Acquired</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="dateAcquired"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Date Acquired">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Date Last Serviced
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="dateLastServiced"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Date Last Serviced">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Date Next Service</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="dateNextService"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Date Next Service">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Expected End Of Life </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="expectedEndOfLife"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Expected End Of Life">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Actual End Of Life</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="actualEndOfLife"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Actual End Of Life">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Production Capacity</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="productionCapacity"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Production Capacity">

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">UOM</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="uomId" [options]="uomIds"
                                                            placeholder="Enter UOM" filter="true">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Calendar</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="calendarId" [options]="calendar"
                                                            placeholder="Enter Calendar" filter="true">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Located At Facility ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="locatedAtFacilityId"
                                                            [options]="facilityId"
                                                            placeholder="Enter Located At Facility ID" filter="true">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Located At Location Seq
                                                            ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="locatedAtLocationSeqId"
                                                            [options]="facilityLocation"
                                                            placeholder="Enter Located At Location Seq ID"
                                                            filter="true">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Salvage Value</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="salvageValue"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Salvage Value">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Depreciation</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="depreciation"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Depreciation">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Purchase Cost</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="purchaseCost"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Purchase Cost">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Purchase Cost Uom ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="partyId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Purchase Cost Uom ID">
                                                    </div>



                                                </div>
                                            </div>


                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 83%;">
                                        <button type="submit" *ngIf="editMode" (click)="onUpdate()"
                                            class="btn btn-secondary submit-btn">Update</button>
                                        <!-- <button type="submit" *ngIf="!editMode" (click)="onSubmit()"
                                            class="btn btn-secondary submit-btn">Submit</button> -->

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenterProduct" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterProduct" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Product</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonA>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryMain==2?'active':''"><a>
                                        {{editModeProduct?'Update':'Create'}} Product</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="productForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputDD">
                                                        <label for="exampleInputEmail1">Product ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Product ID" filter="true"
                                                            [options]="productIds" formControlName="productId">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputAA">
                                                        <label for="exampleInputEmail1">Type of usage </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Type of usage" filter="true"
                                                            [options]="productTypeIds"
                                                            formControlName="fixedAssetProductTypeId">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputDD">
                                                        <label for="exampleInputEmail1">From Date
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            formControlName="fromDate" aria-describedby="emailHelp"
                                                            placeholder="Enter Fin Account Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputAA">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            formControlName="thruDate" aria-describedby="emailHelp"
                                                            placeholder="Enter Fin Account Code">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputDD">
                                                        <label for="exampleInputEmail1">Comments</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="comments" aria-describedby="emailHelp"
                                                            placeholder="Enter Comments">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputAA">
                                                        <label for="exampleInputEmail1">Sequence Num</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="sequenceNum" aria-describedby="emailHelp"
                                                            placeholder="Enter Sequence Num">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputDD">
                                                        <label for="exampleInputEmail1">Quantity</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="quantity" aria-describedby="emailHelp"
                                                            placeholder="Enter Quantity">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputAA">
                                                        <label for="exampleInputEmail1">Quantity Uom ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Quantity Uom ID" filter="true"
                                                            [options]="uomIds" formControlName="quantityUomId">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>


                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="main-submit-button" style="margin-top: -3%;margin-right: 17%;">
                        <button type="submit" *ngIf="editModeProduct" (click)="onUpdateProductData()"
                            class="btn btn-secondary submit-btn">Update</button>
                        <button type="submit" *ngIf="!editModeProduct" (click)="onSubmitProduct()"
                            class="btn btn-secondary submit-btn">Submit</button>

                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenterAssignMent" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterAssignMent" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Assignments</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonB>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryMain==2?'active':''"><a>
                                        {{editAssigment ? 'Update':'Create'}} Assignment</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="assignmentForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNN">
                                                        <label for="exampleInputEmail1">Customer ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Customer ID" filter="true"
                                                            [options]="partyIds" formControlName="partyId">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputAA">
                                                        <label for="exampleInputEmail1">Role Type ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Role Type ID" filter="true"
                                                            [options]="roleTypeIds" formControlName="roleTypeId">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNN">
                                                        <label for="exampleInputEmail1">From Date
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control"
                                                            formControlName="fromDate" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Fin Account Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputAA">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            formControlName="thruDate" aria-describedby="emailHelp"
                                                            placeholder="Enter Fin Account Code">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNN">
                                                        <label for="exampleInputEmail1">Allocated Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            formControlName="allocatedDate" aria-describedby="emailHelp"
                                                            placeholder="Enter Fin Account Code">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputAA">
                                                        <label for="exampleInputEmail1">Comments</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="comments" aria-describedby="emailHelp"
                                                            placeholder="Enter Comments">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNN">
                                                        <label for="exampleInputEmail1">Status ID
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Status ID" filter="true"
                                                            [options]="statusIds" formControlName="statusId">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main-submit-button" style="margin-top: -3%;margin-right: 15%;">
                        <button type="submit" *ngIf="editAssigment" (click)="onUpdateAssignmentValue()"
                            class="btn btn-secondary submit-btn">Update</button>
                        <button type="submit" *ngIf="!editAssigment" (click)="onSubmitAssignment()"
                            class="btn btn-secondary submit-btn">Submit</button>

                    </div>
                </div>


            </div>

        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>