<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" style="cursor: pointer;">Preferences
                </span>
                <span class="color-black pl-1"> >Create New Portal Page
                </span>
                <button type="submit" class="btn btn-danger buttonclass" (click)="GoToMyPortal()">Cancel</button>&nbsp;
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==1?'active':''"><a>
                                    Add Portal Page
                                </a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100"  [formGroup]="portalPage">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">
                                                <div *ngIf="activeCategory==1">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">
                                                                        Portal Page ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Portal Page ID"
                                                                    formControlName="PortalPageID">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">
                                                                        Parent page</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Parent page"
                                                                    formControlName="Parentpage">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">
                                                                        Original Page</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter original page"
                                                                    formControlName="OriginalPage">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">
                                                                        Owner User Login ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Owner User Login ID"
                                                                    formControlName="OwnerUserLoginID">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">
                                                                        Portal Page Name</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Portal page name"
                                                                    formControlName="Portalpagename">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">
                                                                        Sequence Num</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Sequence Num"
                                                                    formControlName="SequenceNum">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">
                                                                        Security Group ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="groupIdIdArray" optionlabel="label"
                                                                    formControlName="SecurityGroupID"
                                                                    placeholder="Enter Security Group ID">
                                                                </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">
                                                                        Description</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Description"
                                                                    formControlName="Description">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    
                                                    </div>
                                                    <div class="main-submit-button" style="margin-right: 29%;">
                                                        <button type="submit" (click)="onSubmit()"
                                                            class="btn btn-secondary submit-btn">Save</button>&nbsp;&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>