import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { ProductionService } from '../production.service';

@Component({
  selector: 'app-create-jobshop',
  templateUrl: './create-jobshop.component.html',
  styleUrls: ['./create-jobshop.component.css']
})
export class CreateJobshopComponent implements OnInit {
  createJob: FormGroup;
  activeCategory = 2;
  partyId: any;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[] = [];
  varianceReasonId: any;
  varianceReasonIdArray: any[] = [];
  glAccountid: any;
  varianceReasonIdData: any;
  show: boolean;
  budgetId: any;
  personId: any;
  partyIdArray: any[] = [];
  ReviewResultTypes: any;
  ReviewResultTypesArray: any[] = [];
  reviewDate: string;
  facilityId: any;
  routingId: any;
  productId: any;
  facilityIdArray: any[]=[];
  routingIdArray: any[]=[];
  productIdArray: any[]=[];
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  createDependent: { label: string; value: string; }[];
  startDate: string;
  workEffortId: any;
  getEstimateFrom: string;
  estimatedCompletionDate: string;
  statusId: any;
  completeDate: any;
  dateComplete: string;
  prodId: any;
  constructor(readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,
   
    readonly spinner: NgxSpinnerService,
    readonly productionService:ProductionService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) {
    this.createJob = this._FormBuilder.group({
      productId: [''],
      Quantity: [''],
      startDate: [''],
      FacilityID: [''],
      RoutingID: [''],
      ProductionRunName: [''],
      Description: [''],
      dependent: [''],
      CalculatedCompletionDate:[''],
      ManufacturerID:[''],
      statusId:['']


    });
    this.createDependent=[{
      "label":"Yes",
      "value":"Y"
    },
    {
      "label":"No",
      "value":"N"
    }]

  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
  this.getProducts();
  this._ActivatedRoute.queryParams.subscribe(params => {
    this.workEffortId = params["workEffortId"];
  
  
  });
  if(this.workEffortId){
    this.show=true;
    this.getJobShopProductionList();
  }else{
    this.show=false;
  }
  }
  getJobShopProductionList() {
    this.spinner.show();
    const req={
      "currentStatusId": [""],"estimatedStartDateFrom": "","estimatedStartDateFromType": "Contains","estimatedStartDateTo": "",
      "estimatedStartDateToType": "Contains", "facilityId": "","productId": "","workEffortId": this.workEffortId,
      "workEffortIdSearchType": "Contains","workEffortName": "","workEffortNameSearchType": "Contains"
    }
    this.productionService.getJobShopProductionList(this.finSize,req).subscribe((res: any) => {
      this.spinner.hide();
      this.completeDate=res.data[0].estimatedCompletionDate;
      setTimeout(() => {
       this.statusId=res.data[0].currentStatusId
        const fromdate=res.data[0].estimatedStartDate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const estimatedCompletionDate=res.data[0].estimatedCompletionDate
        this.estimatedCompletionDate=this.datePipe.transform(estimatedCompletionDate,"yyyy-MM-dd");
        const formValue = this.createJob;
        formValue.patchValue({
          productId: res.data[0].productId,
          Quantity:res.data[0].estimatedQuantity,
          startDate: this.getEstimateFrom,
          FacilityID: res.data[0].facilityId,
          ProductionRunName: res.data[0].workEffortName,
          Description:res.data[0].description,
          CalculatedCompletionDate: this.estimatedCompletionDate,
          statusId:res.data[0].statusDesc,
        })}, 2000);
    })
  }
  

  getProducts(): void {
    this.spinner.show();
    this.productionService.getJobShopInitialData().subscribe((res) => {
      this.facilityId = res.data.facilityId;
      this.routingId = res.data.routingId;
      this.productId = res.data.productId;
      
      this.spinner.hide();
      for (const value of this.facilityId) {
        this.facilityIdArray.push({
          label: value.facilityName,
          value: value.facilityId
        })
      }
      for (const value of this.routingId) {
        this.routingIdArray.push({
          label: value.workEffortId,
          value: value.workEffortId
        })
      }
      for (const value of this.productId) {
        this.productIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    }) }
  onUpdate() {
    this.spinner.show();
    const startDate = this.createJob.get('startDate').value;
    this.startDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const dateComplete =this.completeDate
    this.dateComplete = this.datePipe.transform(dateComplete, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "currentStatusId": this.statusId,
      "estimatedCompletionDate":this.dateComplete,
      "estimatedStartDate": this.startDate,
      "facilityId": this.createJob.value.FacilityID,
      "manufacturerId":"",
      "productId": this.createJob.value.productId,
      "productionRunId": this.workEffortId,
      "quantity":  this.createJob.value.Quantity,
      "workEffortName": this.createJob.value.ProductionRunName,
      "description":this.createJob.value.Description,
    }
    this.productionService.updateProductionRun(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this._Router.navigate(['/production/jobshop-list']);
      } }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  onSubmit() {
    this.spinner.show();
    const startDate = this.createJob.get('startDate').value;
    this.startDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "createDependentProductionRuns":  this.createJob.value.dependent,
      "description": this.createJob.value.Description,
      "facilityId":  this.createJob.value.FacilityID,
      "productId": this.createJob.value.productId,
      "productionRunId":"",
      "quantity":  this.createJob.value.Quantity,
      "routingId":  this.createJob.value.RoutingID,
      "startDate":this.startDate,
      "workEffortName": this.createJob.value.ProductionRunName,
    }
    this.productionService.postProductionRun(requestData).subscribe((res: any) => {
      if (res.success) {
        this.prodId=res.data.productionRunId;
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this._Router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId: this.prodId } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });}
  cancelSubmit() {
    this._Router.navigate(['/production/jobshop-list']);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}

