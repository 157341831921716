<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">BIRT</span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="chartViewHandler()"><a>
                                PDF</a></li>
            
                            <li [ngClass]="activeCategory==2?'active':''"  (click)="openSendFormatThroughtMail()"><a>
                                Send any format through Mail</a></li>
                            
                            <li [ngClass]="activeCategory==3?'active':''"  (click)="chartPie()"><a>
                                PDF chart report</a></li>
                            
                        </ul>
                        
                    </div>

                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                    <div *ngIf="activeCategory==2">
                                            
                                        <div class="w3-card-4 classCardView">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                Send BIRT report by Mail
                                                <!-- <button type="button" class="btn btn-outline-secondary active"routerLinkActive="active"
                                                    data-target="#createSurveyPopup" data-toggle="modal">Create Survey</button>
                                         -->
                                            </div>
                                            <form [formGroup]="sendBIRTreportbymailForm">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <form class="w-100" [formGroup]="sendBIRTreportbymailForm">
                                                    
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                    
                                                            <div class="col-lg-3 form-group classInput" >
                                                                <label for="exampleInputEmail1">
                                                                    Send From</label>
                                                            </div>
                                                            
                                                            <div class="col-lg-2" >
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" placeholder="Enter Send From"
                                                                formControlName="sendFrom">
                                                            </div>
                                                            
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Send To	
                                                                </label>
                                                            </div>
                                                            
                                                            <div class="col-lg-2" >
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" placeholder="Enter Send To"
                                                                formControlName="sendTo">
                                                            </div>
                                    
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Birt export format</label>
                                                            </div>
                                                            
                                                            
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" placeholder="Select Birt export format"
                                                                optionlabel="label"  formControlName="birtexportformat" [options]="birtexportformatArray">
                                                                </p-dropdown>
                                                            </div>
                                    
                                                            <div class="col-lg-3 form-group classInputA" >
                                                                <label for="accountLimit">Subject</label>
                                                            </div>
                                                            
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" placeholder="Enter Subject"
                                                                formControlName="subject">
                                                            </div>
                                                       
                                    
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                    
                                                            <div class="col-lg-3 form-group classInput" >
                                                                <label for="exampleInputEmail1">Attachment Name	<span style="color: red;">*</span></label>
                                                            </div>
                                                            
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" placeholder="Enter Attachment Name"
                                                                formControlName="attachmentName">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInputA" >
                                                                <label for="exampleInputEmail1">Birt Report Location<span style="color: red;">*</span></label>
                                                            </div>
                                                            
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" placeholder="Enter Birt Report Location"
                                                                formControlName="birtReportLocation">
                                                            </div>
                                                            
                                    
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                    </form>
                                                </div>
                                            </form>
                                            <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -26%;">
                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="sendBirtMail()">Send</button>&nbsp;
                                                <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div> 
</div>
<ngx-spinner></ngx-spinner> 

