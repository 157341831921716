<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Suppliers </span>
                <span class="color-black pl-1"> > Merge Suppliers</span>
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                 Merge Supplier
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row"> 
                            <form class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">From Supplier</label>
                                            <div class="">
                                                <p-dropdown filter="true"
                                                    optionLabel="label">
                                                    <ng-template let-item pTemplate="selectedItem">
                                                        <span class="label-info"
                                                            style="margin-left: .5em">{{item.label}}</span>
                                                    </ng-template>
                                                    <ng-template let-car pTemplate="item">
                                                        <div class="ui-helper-clearfix"
                                                            style="position: relative;height: 25px;">
                                                            <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div> 
                                    <div class="col-lg-8 col-12">
                                        <div class="form-group col-lg-4 small-area-pl">
                                            <label for="exampleInputEmail1">To Suppliers</label>
                                            <div class="merge-user-section merge-small-area">
                                                <p-dropdown filter="true" optionLabel="label">
                                                    <ng-template let-item1 pTemplate="selectedItem1">
                                                        <span class="label-info"
                                                            style="margin-left: .5em">{{item1.label}}</span>
                                                    </ng-template>
                                                    <ng-template let-car1 pTemplate="item1">
                                                        <div class="ui-helper-clearfix"
                                                            style="position: relative;height: 25px;">
                                                            <div style="font-size:14px;margin-top:4px">{{car1.label}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                                <div class="suppliers-wrapper own-merge-supplier">
                                                    <button type="button"
                                                        class="btn btn-outline-secondary active">Merge</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>