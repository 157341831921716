import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder ,FormGroup,Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';  import { Location } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';


@Component({
  selector: 'app-sales-order-history-main',
  templateUrl: './sales-order-history-main.component.html',
  styleUrls: ['./sales-order-history-main.component.css']
})
export class SalesOrderHistoryMainComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 7;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  salesOrderHistoryForm: FormGroup;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,    private _location: Location,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly _Router: Router
  ) 
  {
    this.salesOrderHistoryForm=this._FormBuilder.group({
      uploadFile:"",
    })
   }

  ngOnInit(): void {
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  openInventory()
  {
    this._Router.navigate(['/facilities/Planning']);

  }
  openFindRequirements()
  {
    this._Router.navigate(['/facilities/Planning/find-requirements']);

  }
  openRequirements()
  {
    this._Router.navigate(['/facilities/Planning/open-requirements']);

  }
  openProductRequirements()
  {
    this._Router.navigate(['/facilities/Planning/product-requirements']);
    
  }
  openInternalRequirements()
  {
    this._Router.navigate(['/facilities/Planning/internal-requirements']);

  }
  openTransferRequirements()
  {
    this._Router.navigate(['/facilities/Planning/transfer-requirements']);

  }
  openSalesOrderHistory()
  {
    this._Router.navigate(['/facilities/Planning/upload-sales-order-history']);

  }
  openRunMRP()
  {
    this._Router.navigate(['/facilities/Planning/run-mrp']);

  }
  openViewMRP()
  {
    this._Router.navigate(['/facilities/Planning/view-mrp']);

  }
  openConfigureWarehouses()
  {
    this._Router.navigate(['/facilities/Planning/configure-warehouse']);

  }
  
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}
