import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';

@Component({
    selector: 'app-create-agreement-role',
    templateUrl: './create-new-agreement-role.component.html'
})
export class CreateNewAgreementRoleComponent implements OnInit {
    partyIds: any[];
    activeCategory = 2;
    roleTypeIds: any[];
    roleFrom: FormGroup;
    agreementId: string;
    agreementType: string;
    constructor(
        readonly _GlobalResourceService: GlobalResourceService,
        readonly  _Router: Router,
        readonly _FormBuilder: FormBuilder,
        readonly spinner: NgxSpinnerService,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _ToastrService: ToastrService,
        readonly _AccountingApService: AccountingApService
    ) {

        this.agreementId = '';
        this.partyIds = [];
        this.roleTypeIds = [];
        this.agreementType = '';
        this.roleFrom = this._FormBuilder.group({
            agreementId: [''],
            partyId: ['', [Validators.required]],
            roleTypeId: ['', [Validators.required]]
        });
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
        this.agreementId = this._ActivatedRoute.snapshot.queryParams.agreementId;
        this.getPartyIds();
        this.getRoleTypeIds();
    }

    getPartyIds(): void {
        this.spinner.show();
        this._GlobalResourceService.getPartyGroups()
            .then(data => {
                this.spinner.hide();
                this.partyIds = data.data.PersonsGroups.map(value => {
                    return {
                        label: value.partyId,
                        value: value.partyId
                    };
                });
            });
          
    }
    getRoleTypeIds(): void {
        this.spinner.show();
        this._AccountingApService.getRoleType()
            .then(data => {
                this.spinner.hide();
                this.roleTypeIds = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.roleTypeId
                    };
                });
            });
           
    }
    submitRole(): void {
        this.spinner.show();
        if (this.roleFrom.valid) {
            this._AccountingApService.createAgreementRole({
                ...this.roleFrom.value, ...{
                    agreementId: this.agreementId
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.roleFrom.reset();
                        this.spinner.hide();
                        this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/summary-customer-agreeement`], { queryParams: { agreementID: this.agreementId } });
                    }
                });
        }
       
    }
    reset(): void {
        this.roleFrom.reset();
        this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/summary-customer-agreeement`], { queryParams: { agreementID: this.agreementId } });
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}