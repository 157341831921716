<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact bg-white mt-20 mb-20 font-medium">
                <span class="color-black pl-1 titlePanels">Accounting Manager Main Page</span>
                <span>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2"
                        (click)="toDashboard()">Home</button>
                    <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                </span>
            </div>
            <div class=" bg-white color-grey">
               
                <div class="w3-card-4 classCard m-0">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass mt-0"
                        style="padding-left:9px; width: 102%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                        <li [ngClass]="activeCategoriess==1?'active':''" (click)="mainPage()">
                            <a>
                                Main </a>
                        </li>
    
                        <li [ngClass]="activeCategoriess==2?'active':''" (click)="accountingPage()">
                            <a>
                                Accounting </a>
                        </li>
                        <li [ngClass]="activeCategoriess==3?'active':''" (click)="invoicePage()">
                            <a>
                                Invoices</a>
                        </li>
                        <li [ngClass]="activeCategoriess==4?'active':''" (click)="paymentPage()">
                            <a>
                                Payments </a>
                        </li>
                        <li [ngClass]="activeCategoriess==5?'active':''" (click)="paymentGrpPage()">
                            <a>
                                Payment group</a>
                        </li>
                        <li [ngClass]="activeCategoriess==6?'active':''" (click)="billingAccountPage()">
                            <a>
                                Billing Account
                            </a>
                        </li>
                        <li [ngClass]="activeCategoriess==7?'active':''" (click)="agreementPage()">
                            <a>
                                Agreements</a>
                        </li>
                        <li [ngClass]="activeCategoriess==8?'active':''" (click)="commisionReportPage()">
                            <a>
                                Commission Report</a>
                        </li>
                    </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white p-0 m-0">
                                        <div class="w-100">
                                            <div class="panel-group" role="tablist" aria-multiselectable="true">
                                                <div *ngIf="activeCategoriess==1">
                                                    <div class="w3-card-4 classCard">
                                                        <div class="container-fluid">
                                                            <ul class="tabbing-section tabbing-accordians tabClass mt-0"
                                                                style="padding-left:9px; width: 102%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                                                                <li [ngClass]="activeCategory==1?'active':''" (click)="mainPage()">
                                                                    <a>
                                                                        Agreements </a>
                                                                </li>
                                        
                                                                <li [ngClass]="activeCategory==2?'active':''" (click)="mainBillingAccountPage()">
                                                                    <a>
                                                                        Billing Accounts </a>
                                                                </li>
                                                                <li [ngClass]="activeCategory==3?'active':''" (click)="mainFixedAssetPage()">
                                                                    <a> Fixed Assets
                                                                    </a>
                                                                </li>
                                                                <li [ngClass]="activeCategory==4?'active':''" (click)="mainInvoicePage()">
                                                                    <a>
                                                                        Invoices </a>
                                                                </li>
                                                                <li [ngClass]="activeCategory==5?'active':''" (click)="mainPaymentPage()">
                                                                    <a>
                                                                        Payment </a>
                                                                </li>
                                        
                                                            </ul>
                                                        </div>

                                                        <div *ngIf="activeCategory==1">
                                                            <div class="panel-collapse">

                                                                <form class="w-100" [formGroup]="CustomerAgreements">
                                                                    <div class="w3-card-4  w-100">
                                                                        <div class="accordion classCard"
                                                                            id="myAccordion">
                                                                            <div class="accordion-item">
                                                                                <h2 class="accordion-header"
                                                                                    id="headingOne">
                                                                                    <div
                                                                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion">
                                                                                        <button type="button"
                                                                                            class="btn btn-outline-secondary"
                                                                                            data-toggle="modal"
                                                                                            data-target="#exampleModalCenter"
                                                                                            style=" margin-bottom: 4px!important;  margin-top: 4px;">Create
                                                                                            New Agreement</button>

                                                                                        <button type="button"
                                                                                            class="accordion-button  collapsed"
                                                                                            data-bs-toggle="collapse"
                                                                                            data-bs-target="#collapseOne"
                                                                                            style="margin-left:auto!important; padding-top: 11px !important;padding-bottom: 11px !important;">
                                                                                        </button>

                                                                                    </div>

                                                                                </h2>
                                                                                <div id="collapseOne"
                                                                                    class="accordion-collapse collapse"
                                                                                    data-bs-parent="#myAccordion">
                                                                                    <div class="card-body">
                                                                                        <div
                                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div
                                                                                                        class="col-lg-3 form-group classInput">
                                                                                                        <label
                                                                                                            for="exampleInputEmail1">Agreement
                                                                                                            ID</label>
                                                                                                    </div>

                                                                                                    <div class="col-lg-2"
                                                                                                        style="display: none;">
                                                                                                        <p-dropdown
                                                                                                            [options]="opportunityIdArray"
                                                                                                            filter="true"
                                                                                                            formControlName="agreementIdSearchType">
                                                                                                        </p-dropdown>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="col-lg-2">
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            name="partyId"
                                                                                                            formControlName="agreementId"
                                                                                                            class="form-control"
                                                                                                            id="exampleInputEmail1"
                                                                                                            aria-describedby="emailHelp"
                                                                                                            placeholder="Enter Agreement ID">
                                                                                                    </div>

                                                                                                    <div
                                                                                                        class="col-lg-3 form-group classInputA">
                                                                                                        <label
                                                                                                            for="exampleInputEmail1">Product
                                                                                                            ID</label>
                                                                                                    </div>

                                                                                                    <div
                                                                                                        class="col-lg-2">
                                                                                                        <p-dropdown
                                                                                                            [options]="productListArray"
                                                                                                            optionlabel="productId"
                                                                                                            filter="true"
                                                                                                            placeholder="Select"
                                                                                                            formControlName="productId">
                                                                                                        </p-dropdown>
                                                                                                    </div>


                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div
                                                                                                        class="col-lg-3 form-group classInput">
                                                                                                        <label
                                                                                                            for="exampleInputEmail1">Party
                                                                                                            Id
                                                                                                            From</label>
                                                                                                    </div>

                                                                                                    <div
                                                                                                        class="col-lg-2">
                                                                                                        <p-dropdown
                                                                                                            [options]="PersonsAndPartyGroupsListArray"
                                                                                                            optionlabel="partyId"
                                                                                                            filter="true"
                                                                                                            placeholder="Select"
                                                                                                            formControlName="partyIdFrom">
                                                                                                        </p-dropdown>
                                                                                                    </div>

                                                                                                    <div
                                                                                                        class="col-lg-3 form-group classInputA">
                                                                                                        <label
                                                                                                            for="exampleInputEmail1">Party
                                                                                                            Id
                                                                                                            To</label>
                                                                                                    </div>

                                                                                                    <div
                                                                                                        class="col-lg-2">
                                                                                                        <p-dropdown
                                                                                                            [options]="PersonsAndPartyGroupsListArray"
                                                                                                            optionlabel="partyId"
                                                                                                            filter="true"
                                                                                                            placeholder="Select"
                                                                                                            formControlName="partyIdTo">
                                                                                                        </p-dropdown>
                                                                                                    </div>


                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div
                                                                                                        class="col-lg-3 form-group classInput">
                                                                                                        <label
                                                                                                            for="exampleInputEmail1">Agreement
                                                                                                            Type
                                                                                                            ID</label>
                                                                                                    </div>

                                                                                                    <div
                                                                                                        class="col-lg-2">
                                                                                                        <p-dropdown
                                                                                                            [options]="AgreementTypeListArray"
                                                                                                            optionlabel="label"
                                                                                                            filter="true"
                                                                                                            placeholder="Select"
                                                                                                            formControlName="agreementTypeId">
                                                                                                        </p-dropdown>
                                                                                                    </div>

                                                                                                    <div
                                                                                                        class="col-lg-3 form-group classInputA">
                                                                                                        <label
                                                                                                            for="exampleInputEmail1">From
                                                                                                            Date</label>
                                                                                                    </div>

                                                                                                    <div class="col-lg-2"
                                                                                                        style="display: none;">
                                                                                                        <p-dropdown
                                                                                                            [options]="fromDateType"
                                                                                                            filter="true"
                                                                                                            formControlName="fromDateFromSearchType">
                                                                                                        </p-dropdown>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="col-lg-2">
                                                                                                        <p-calendar
                                                                                                            formControlName="fromDateFrom">
                                                                                                        </p-calendar>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div
                                                                                                        class="col-lg-3 form-group classInput">
                                                                                                        <label
                                                                                                            for="exampleInputEmail1">To
                                                                                                            Date</label>
                                                                                                    </div>

                                                                                                    <div class="col-lg-2"
                                                                                                        style="display: none;">
                                                                                                        <p-dropdown
                                                                                                            [options]="toDateType"
                                                                                                            filter="true"
                                                                                                            formControlName="fromDateToSearchType">
                                                                                                        </p-dropdown>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="col-lg-2">
                                                                                                        <p-calendar
                                                                                                            formControlName="fromDateTo"></p-calendar>
                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>


                                                                                        </div>

                                                                                        <div class="col-lg-12 col-12 main-submit-button "
                                                                                            style="    margin-left: -26%;">
                                                                                            <button
                                                                                                _ngcontent-cta-c128=""
                                                                                                (click)="submit()"
                                                                                                type="submit"
                                                                                                class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                                                            <button type="button"
                                                                                                class="btn btn-danger ml-2"
                                                                                                (click)="restForm()">Reset</button>

                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                    <div class="w3-card-4 classCard mt-2">
                                                                        <header class="w3-container w3-blue">
                                                                            <div class="header-tabs">
                                                                                <h4 class=" common-styling h4Margin">
                                                                                    Agreement List
                                                                                </h4>

                                                                            </div>
                                                                        </header>
                                                                        <div
                                                                            class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                            <div class="col-lg-12 col-12">
                                                                                <div class="form-group">

                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="agreementList"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr
                                                                                                    style="background: #0d3769;">
                                                                                                    <th [ngStyle]="{'width':'150px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div class="checkbox-align"
                                                                                                            style="color: white;">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Agreement ID
                                                                                                            <p-sortIcon
                                                                                                                field="code"></p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'150px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Product ID
                                                                                                            <p-sortIcon
                                                                                                                field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'150px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            From Party
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'150px'}"
                                                                                                        pSortableColumn="category">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            To Party ID
                                                                                                            <p-sortIcon
                                                                                                                field="category"></p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'200px'}"
                                                                                                        width="11%"
                                                                                                        pSortableColumn="quantity">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Role Type Id
                                                                                                            To
                                                                                                            <p-sortIcon
                                                                                                                field="quantity"></p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'200px'}"
                                                                                                        width="11%"
                                                                                                        pSortableColumn="price">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Agreement
                                                                                                            Type ID
                                                                                                            <p-sortIcon
                                                                                                                field="price"></p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'150px'}"
                                                                                                        pSortableColumn="price">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            From Date
                                                                                                            <p-sortIcon
                                                                                                                field="price">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'150px'}"
                                                                                                        pSortableColumn="price">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Through Date
                                                                                                            <p-sortIcon
                                                                                                                field="price">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'150px'}"
                                                                                                        pSortableColumn="price">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Description
                                                                                                            <p-sortIcon
                                                                                                                field="price">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'150px'}"
                                                                                                        pSortableColumn="price">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Created Date
                                                                                                            <p-sortIcon
                                                                                                                field="price">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'150px'}"
                                                                                                        width="10%"
                                                                                                        style="text-align: center;"
                                                                                                        pSortableColumn="price">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Action</div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'150px'}">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            <span
                                                                                                                (click)="summary(product.agreementId,product.partyIdTo)"
                                                                                                                class="account-button">{{product.agreementId}}</span>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'150px'}">
                                                                                                        {{product.productId}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'150px'}">
                                                                                                        {{product.partyIdFrom}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'150px'}">
                                                                                                        {{product.partyIdTo}}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'200px'}"
                                                                                                        width="11%">
                                                                                                        {{product.roleTypeIdTo}}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'200px'}"
                                                                                                        width="11%">
                                                                                                        {{product.agreementTypeId}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'150px'}">
                                                                                                        {{product.fromDate
                                                                                                        |
                                                                                                        date:'yyyy-MM-d
                                                                                                        hh:mm:ss'}}</td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'150px'}">
                                                                                                        {{product.thruDate
                                                                                                        |
                                                                                                        date:'yyyy-MM-d
                                                                                                        hh:mm:ss'}}</td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'150px'}">
                                                                                                        {{product.createdStamp
                                                                                                        |
                                                                                                        date:'yyyy-MM-d
                                                                                                        hh:mm:ss'}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'150px'}">
                                                                                                        {{product.description}}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'150px'}"
                                                                                                        width="10%"
                                                                                                        style="text-align: center;">
                                                                                                        <div>

                                                                                                            <svg (click)="cancelAgreement(product.agreementId)"
                                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                                pTooltip="Cancel"
                                                                                                                tooltipPosition="Bottom"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="emptymessage"
                                                                                                let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span
                                                                                                            class="text-center">
                                                                                                            <h4
                                                                                                                class="ml-2">
                                                                                                                No
                                                                                                                Record
                                                                                                                Found
                                                                                                            </h4>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>

                                                                                        <p class="paginate_data">
                                                                                            View per page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>



                                                            </div>
                                                        </div>
                                                        <div *ngIf="activeCategory==2">
                                                            <div class=" bg-white color-grey">

                                                                <div class="create-new-leade mt-2">
                                                                    <div class="">
                                                                        <form class="w-100"
                                                                            [formGroup]="searchBillingAccountForm">
                                                                            <div class="">

                                                                                <div class="w3-card-4 w-100">
                                                                                    <div class="accordion classCard"
                                                                                        id="myAccordion">
                                                                                        <div class="accordion-item">
                                                                                            <h2 class="accordion-header"
                                                                                                id="headingOne">
                                                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                                                                    style="background:#F5F7FA!important; padding: 4px !important;">
                                                                                                    <span
                                                                                                        style="margin-left: 4px!important;">
                                                                                                        Search
                                                                                                        Options</span>

                                                                                                    <button
                                                                                                        data-toggle="modal"
                                                                                                        data-target="#exampleModalCenter"
                                                                                                        type="button"
                                                                                                        class="btn btn-outline-secondary ml-2">Create
                                                                                                        New Billing
                                                                                                        Account
                                                                                                    </button>

                                                                                                    <button
                                                                                                        type="button"
                                                                                                        class="accordion-button  collapsed"
                                                                                                        data-bs-toggle="collapse"
                                                                                                        data-bs-target="#collapseOne"
                                                                                                        style="margin-left: 63%!important; padding-top: 11px !important;    padding-bottom: 11px !important;"></button>

                                                                                                </div>

                                                                                            </h2>
                                                                                            <div id="collapseOne"
                                                                                                class="accordion-collapse collapse"
                                                                                                data-bs-parent="#myAccordion">
                                                                                                <div class="card-body">

                                                                                                    <div
                                                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                        <div
                                                                                                            class="col-lg-12">
                                                                                                            <div
                                                                                                                class="row">

                                                                                                                <div
                                                                                                                    class="col-lg-3 form-group classInput">
                                                                                                                    <label
                                                                                                                        for="exampleInputEmail1">
                                                                                                                        Billing
                                                                                                                        Account
                                                                                                                        ID</label>
                                                                                                                </div>

                                                                                                                <div class="col-lg-2"
                                                                                                                    style="display: none;">
                                                                                                                    <p-dropdown
                                                                                                                        filter="true"
                                                                                                                        [options]="opportunityIdArray"
                                                                                                                        optionlabel="label"
                                                                                                                        formControlName="billingAccountIdSearchType">
                                                                                                                    </p-dropdown>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    class="col-lg-2">
                                                                                                                    <input
                                                                                                                        type="email"
                                                                                                                        class="form-control"
                                                                                                                        id="exampleInputEmail1"
                                                                                                                        aria-describedby="emailHelp"
                                                                                                                        placeholder="Billing Account Id"
                                                                                                                        formControlName="billingAccountId">
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    class="col-lg-3 form-group classInputA">
                                                                                                                    <label
                                                                                                                        for="exampleInputEmail1">Description</label>
                                                                                                                </div>

                                                                                                                <div class="col-lg-2"
                                                                                                                    style="display: none;">
                                                                                                                    <p-dropdown
                                                                                                                        filter="true"
                                                                                                                        [options]="opportunityIdArray"
                                                                                                                        optionlabel="label"
                                                                                                                        formControlName="descriptionSearchType">
                                                                                                                    </p-dropdown>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    class="col-lg-2">
                                                                                                                    <input
                                                                                                                        type="email"
                                                                                                                        class="form-control"
                                                                                                                        id="exampleInputEmail1"
                                                                                                                        aria-describedby="emailHelp"
                                                                                                                        formControlName="description"
                                                                                                                        placeholder="Description">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="col-lg-12">
                                                                                                            <div
                                                                                                                class="row">

                                                                                                                <div
                                                                                                                    class="col-lg-3 form-group classInput">
                                                                                                                    <label
                                                                                                                        for="accountLimit">Account
                                                                                                                        Limit</label>
                                                                                                                </div>


                                                                                                                <div
                                                                                                                    class="col-lg-2">
                                                                                                                    <input
                                                                                                                        type="text"
                                                                                                                        class="form-control"
                                                                                                                        formControlName="accountLimit"
                                                                                                                        placeholder="Account Limit">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div class="col-lg-12 col-12 main-submit-button "
                                                                                                        style="margin-left: -32%;">
                                                                                                        <button
                                                                                                            _ngcontent-cta-c128=""
                                                                                                            type="submit"
                                                                                                            class="btn btn-secondary submit-btn"
                                                                                                            (click)="getBillingAccounts()">Find</button>&nbsp;
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            class="btn btn-danger"
                                                                                                            (click)="resetForm()">Reset</button>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>



                                                                                <div class="w3-card-4 classCardB">
                                                                                    <header
                                                                                        class="w3-container w3-blue">
                                                                                        <div class="header-tabs">
                                                                                            <h4
                                                                                                class=" common-styling hmargin">
                                                                                                Billing Account List
                                                                                            </h4>

                                                                                        </div>
                                                                                    </header>
                                                                                    <div
                                                                                        class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                                        <div class="col-lg-12 col-12">
                                                                                            <div class="form-group">

                                                                                                <div
                                                                                                    class="card own-account-table">
                                                                                                    <p-table
                                                                                                        [value]="billingAccounts"
                                                                                                        [paginator]="true"
                                                                                                        [rows]="rows"
                                                                                                        scrollWidth="100%"
                                                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                        [totalRecords]="total"
                                                                                                        [scrollable]="true"
                                                                                                        (sortFunction)="customSort($event)"
                                                                                                        [customSort]="true">
                                                                                                        <ng-template
                                                                                                            pTemplate="header">
                                                                                                            <tr
                                                                                                                style="background: #0d3769;">
                                                                                                                <th
                                                                                                                    pSortableColumn="code">
                                                                                                                    <div class="checkbox-align"
                                                                                                                        style="color: white;">
                                                                                                                        <p-checkbox
                                                                                                                            _ngcontent-egj-c329=""
                                                                                                                            styleclass="custom-checkbox"
                                                                                                                            binary="true"
                                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div
                                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div
                                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                                    <input
                                                                                                                                        type="checkbox"
                                                                                                                                        name="undefined"
                                                                                                                                        value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox"
                                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span
                                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>
                                                                                                                        Billing
                                                                                                                        Account
                                                                                                                        Id
                                                                                                                        <p-sortIcon
                                                                                                                            field="code"></p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input
                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                        value=""
                                                                                                                        autocomplete="off"
                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox"
                                                                                                                        aria-disabled="false"
                                                                                                                        aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th
                                                                                                                    pSortableColumn="name">
                                                                                                                    <div
                                                                                                                        style="color: white;">
                                                                                                                        Account
                                                                                                                        Limit
                                                                                                                        <p-sortIcon
                                                                                                                            field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input
                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                        value=""
                                                                                                                        autocomplete="off"
                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox"
                                                                                                                        aria-disabled="false"
                                                                                                                        aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th
                                                                                                                    pSortableColumn="category">
                                                                                                                    <div
                                                                                                                        style="color: white;">
                                                                                                                        Description
                                                                                                                        <p-sortIcon
                                                                                                                            field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input
                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                        value=""
                                                                                                                        autocomplete="off"
                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox"
                                                                                                                        aria-disabled="false"
                                                                                                                        aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th
                                                                                                                    pSortableColumn="price">
                                                                                                                    <div
                                                                                                                        style="color: white;">
                                                                                                                        From
                                                                                                                        Date
                                                                                                                    </div>
                                                                                                                    <input
                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                        value=""
                                                                                                                        autocomplete="off"
                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox"
                                                                                                                        aria-disabled="false"
                                                                                                                        aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th
                                                                                                                    pSortableColumn="price">
                                                                                                                    <div
                                                                                                                        style="color: white;">
                                                                                                                        Through
                                                                                                                        Date
                                                                                                                    </div>
                                                                                                                    <input
                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                        value=""
                                                                                                                        autocomplete="off"
                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox"
                                                                                                                        aria-disabled="false"
                                                                                                                        aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th
                                                                                                                    pSortableColumn="price">
                                                                                                                    <div
                                                                                                                        style="color: white;">
                                                                                                                        Created
                                                                                                                        Date
                                                                                                                    </div>
                                                                                                                    <input
                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                        value=""
                                                                                                                        autocomplete="off"
                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox"
                                                                                                                        aria-disabled="false"
                                                                                                                        aria-readonly="false">
                                                                                                                </th>

                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template
                                                                                                            pTemplate="body"
                                                                                                            let-product>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <div
                                                                                                                        class="checkbox-align">
                                                                                                                        <p-checkbox
                                                                                                                            _ngcontent-egj-c329=""
                                                                                                                            styleclass="custom-checkbox"
                                                                                                                            binary="true"
                                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div
                                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div
                                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                                    <input
                                                                                                                                        type="checkbox"
                                                                                                                                        name="undefined"
                                                                                                                                        value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox"
                                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span
                                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>
                                                                                                                        <span
                                                                                                                            class="account-button"
                                                                                                                            routerLink="/financial/payable/billing-account/summary-billing-account"
                                                                                                                            [queryParams]="{billingAccountId:product.billingAccountId}">{{product.billingAccountId}}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td>{{product.accountLimit}}
                                                                                                                </td>
                                                                                                                <td>{{product.description}}
                                                                                                                </td>
                                                                                                                <td>{{product.fromDate
                                                                                                                    |
                                                                                                                    date:'yyyy-MM-d
                                                                                                                    hh:mm:ss'}}
                                                                                                                </td>
                                                                                                                <td>{{product.thruDate
                                                                                                                    |
                                                                                                                    date:'yyyy-MM-d
                                                                                                                    hh:mm:ss'}}
                                                                                                                </td>
                                                                                                                <td>{{product.createdStamp
                                                                                                                    |
                                                                                                                    date:'yyyy-MM-d
                                                                                                                    hh:mm:ss'}}
                                                                                                                </td>

                                                                                                            </tr>
                                                                                                            <ng-template
                                                                                                                pTemplate="emptymessage"
                                                                                                                let-columns>
                                                                                                                <tr>
                                                                                                                    <td>
                                                                                                                        <span
                                                                                                                            class="text-center">
                                                                                                                            <h4
                                                                                                                                class="ml-2">
                                                                                                                                No
                                                                                                                                Record
                                                                                                                                Found
                                                                                                                            </h4>
                                                                                                                        </span>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </ng-template>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p
                                                                                                        class="paginate_data">
                                                                                                        View per page
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div *ngIf="activeCategory==3">

                                                            <div class="panel panel-default">
                                                                <div class="panel-heading" role="tab" id="headingFour">
                                                                    <!-- <h4 class="panel-title">
                                                                        <a class="collapsed" role="button"
                                                                            data-toggle="collapse" data-parent="#accordion"
                                                                            href="#collapseFour" aria-expanded="false"
                                                                            aria-controls="collapseFour">
                                                                            Fixed Assets
                                                                            <span class="detail-created">Create</span>
                                                                        </a>
                                                                    </h4> -->
                                                                </div>

                                                                <div class="panel-body">
                                                                    <div
                                                                        class="all-users-infomation font-13 border-top-grey font-medium own-user-select ">
                                                                        <div class="col-lg-4 col-12">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">Fixed
                                                                                    Asset
                                                                                    ID</label>
                                                                                <div class="">
                                                                                    <p-dropdown filter="true"
                                                                                        optionLabel="label">
                                                                                        <ng-template let-item
                                                                                            pTemplate="selectedItem">
                                                                                            <span class="label-info"
                                                                                                style="margin-left: .5em">{{item.label}}</span>
                                                                                        </ng-template>
                                                                                        <ng-template let-car
                                                                                            pTemplate="item">
                                                                                            <div class="ui-helper-clearfix"
                                                                                                style="position: relative;height: 25px;">
                                                                                                <div
                                                                                                    style="font-size:14px;margin-top:4px">
                                                                                                    {{car.label}}
                                                                                                </div>
                                                                                            </div>
                                                                                        </ng-template>
                                                                                    </p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-4 col-12">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">Asset
                                                                                    Type</label>
                                                                                <div class="">
                                                                                    <p-dropdown filter="true"
                                                                                        optionLabel="label">
                                                                                        <ng-template let-item
                                                                                            pTemplate="selectedItem">
                                                                                            <span class="label-info"
                                                                                                style="margin-left: .5em">{{item.label}}</span>
                                                                                        </ng-template>
                                                                                        <ng-template let-car
                                                                                            pTemplate="item">
                                                                                            <div class="ui-helper-clearfix"
                                                                                                style="position: relative;height: 25px;">
                                                                                                <div
                                                                                                    style="font-size:14px;margin-top:4px">
                                                                                                    {{car.label}}
                                                                                                </div>
                                                                                            </div>
                                                                                        </ng-template>
                                                                                    </p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-4 col-12">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">Fixed
                                                                                    Asset
                                                                                    Name</label>
                                                                                <div class="">
                                                                                    <p-dropdown filter="true"
                                                                                        optionLabel="label">
                                                                                        <ng-template let-item
                                                                                            pTemplate="selectedItem">
                                                                                            <span class="label-info"
                                                                                                style="margin-left: .5em">{{item.label}}</span>
                                                                                        </ng-template>
                                                                                        <ng-template let-car
                                                                                            pTemplate="item">
                                                                                            <div class="ui-helper-clearfix"
                                                                                                style="position: relative;height: 25px;">
                                                                                                <div
                                                                                                    style="font-size:14px;margin-top:4px">
                                                                                                    {{car.label}}
                                                                                                </div>
                                                                                            </div>
                                                                                        </ng-template>
                                                                                    </p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12 col-12 main-submit-button ">
                                                                        <button _ngcontent-cta-c128="" type="submit"
                                                                            class="btn btn-secondary submit-btn">Find</button>
                                                                    </div>
                                                                    <div class="w3-card-4 classCard">
                                                                        <header class="w3-container w3-blue">
                                                                            <div class="header-tabs">
                                                                                <h4 class=" common-styling h4Margin">
                                                                                    Fixed Asset
                                                                                </h4>
                                                                            </div>
                                                                        </header>

                                                                        <div
                                                                            class="all-users-infomation font-13  border-top-grey font-medium own-user-select">
                                                                            <div class="form-group">
                                                                                <div class="card own-account-table">
                                                                                    <p-table [value]="products3"
                                                                                        [paginator]="true" [rows]="rows"
                                                                                        scrollWidth="100%"
                                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                        [totalRecords]="total"
                                                                                        [scrollable]="true"
                                                                                        (sortFunction)="customSort($event)"
                                                                                        [customSort]="true">
                                                                                        <ng-template pTemplate="header">
                                                                                            <tr>
                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                    pSortableColumn="code">
                                                                                                    <div
                                                                                                        class="checkbox-align">
                                                                                                        <p-checkbox
                                                                                                            _ngcontent-egj-c329=""
                                                                                                            styleclass="custom-checkbox"
                                                                                                            binary="true"
                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div
                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div
                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                    <input
                                                                                                                        type="checkbox"
                                                                                                                        name="undefined"
                                                                                                                        value="undefined">
                                                                                                                </div>
                                                                                                                <div role="checkbox"
                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span
                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </p-checkbox>
                                                                                                        Fixed Asset ID
                                                                                                        <p-sortIcon
                                                                                                            field="code"></p-sortIcon>
                                                                                                    </div>
                                                                                                    <input
                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox"
                                                                                                        aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th
                                                                                                    pSortableColumn="name">
                                                                                                    Fixed
                                                                                                    Asset
                                                                                                    Name <p-sortIcon
                                                                                                        field="name">
                                                                                                    </p-sortIcon>
                                                                                                    <input
                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox"
                                                                                                        aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th
                                                                                                    pSortableColumn="category">
                                                                                                    Fixed
                                                                                                    Asset Type Id
                                                                                                    <p-sortIcon
                                                                                                        field="category"></p-sortIcon>
                                                                                                    <input
                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox"
                                                                                                        aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th
                                                                                                    pSortableColumn="quantity">
                                                                                                    Fixed
                                                                                                    Parent Id
                                                                                                    <p-sortIcon
                                                                                                        field="quantity"></p-sortIcon>
                                                                                                    <input
                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox"
                                                                                                        aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th
                                                                                                    pSortableColumn="quantity">
                                                                                                    Date
                                                                                                    Acquired <p-sortIcon
                                                                                                        field="quantity"></p-sortIcon>
                                                                                                    <input
                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox"
                                                                                                        aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th
                                                                                                    pSortableColumn="price">
                                                                                                    Purchase
                                                                                                    Cost <p-sortIcon
                                                                                                        field="price">
                                                                                                    </p-sortIcon>
                                                                                                    <input
                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox"
                                                                                                        aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th
                                                                                                    pSortableColumn="price">
                                                                                                    Action
                                                                                                    <input
                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        style="visibility:hidden;"
                                                                                                        value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox"
                                                                                                        aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="body"
                                                                                            let-product>
                                                                                            <tr>
                                                                                                <td
                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                    <div
                                                                                                        class="checkbox-align">
                                                                                                        <p-checkbox
                                                                                                            _ngcontent-egj-c329=""
                                                                                                            styleclass="custom-checkbox"
                                                                                                            binary="true"
                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div
                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div
                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                    <input
                                                                                                                        type="checkbox"
                                                                                                                        name="undefined"
                                                                                                                        value="undefined">
                                                                                                                </div>
                                                                                                                <div role="checkbox"
                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span
                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </p-checkbox>
                                                                                                        {{product.code}}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>{{product.name}}
                                                                                                </td>
                                                                                                <td>{{product.category}}
                                                                                                </td>
                                                                                                <td>{{product.quantity}}
                                                                                                </td>
                                                                                                <td>{{product.price |
                                                                                                    currency:
                                                                                                    'USD'}}
                                                                                                </td>
                                                                                                <td>{{product.category}}
                                                                                                </td>
                                                                                                <td>
                                                                                                    <div>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                            width="16"
                                                                                                            height="16"
                                                                                                            viewBox="0 0 21.223 21.222">
                                                                                                            <g
                                                                                                                transform="translate(0 -0.004)">
                                                                                                                <g
                                                                                                                    transform="translate(0 1.52)">
                                                                                                                    <path
                                                                                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                        transform="translate(0 -34.137)" />
                                                                                                                </g>
                                                                                                                <g
                                                                                                                    transform="translate(4.548 0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 0)">
                                                                                                                        <path
                                                                                                                            d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                            transform="translate(-102.409 -0.004)" />
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </svg>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                            class="ml-20"
                                                                                                            width="16"
                                                                                                            height="16"
                                                                                                            viewBox="0 0 26 26">
                                                                                                            <defs>
                                                                                                                <style>
                                                                                                                    .a {
                                                                                                                        fill: #f44336;
                                                                                                                    }

                                                                                                                    .b {
                                                                                                                        fill: #fafafa;
                                                                                                                        margin-left: 20px;
                                                                                                                    }
                                                                                                                </style>
                                                                                                            </defs>
                                                                                                            <path
                                                                                                                class="a fill-color"
                                                                                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                            <path
                                                                                                                class="b"
                                                                                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                transform="translate(-147.576 -147.576)" />
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                </td>

                                                                                            </tr>
                                                                                        </ng-template>
                                                                                    </p-table>
                                                                                    <!-- <p class="paginate_data">
                                                                        View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                    <p class="paginate_data">
                                                                                        View per page </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div *ngIf="activeCategory==4">

                                                            <div class="w3-card-4 classCard m-3">
                                                                <div class="container-fluid">

                                                                    <ul class="tabbing-section tabbing-accordians tabClass mt-0"
                                                                        style="padding-left:9px; width: 102%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                                                                        <li [ngClass]="activeCategoryInvoice==1?'active':''"
                                                                            (click)="changeActiveCategoryInvoice(1)">
                                                                            <a>
                                                                                Show All Invoices </a>
                                                                        </li>

                                                                        <li [ngClass]="activeCategoryInvoice==2?'active':''"
                                                                            (click)="changeActiveCategoryPayment(2)">
                                                                            <a>
                                                                                Show Invoices Commission </a>
                                                                        </li>
                                                                        <li [ngClass]="activeCategoryInvoice==3?'active':''"
                                                                            (click)="changeActiveCategoryPayment(3)">
                                                                            <a> Show Invoices Customer Return
                                                                            </a>
                                                                        </li>
                                                                        <li [ngClass]="activeCategoryInvoice==4?'active':''"
                                                                            (click)="changeActiveCategoryPayment(4)">
                                                                            <a> Show Invoices Interest
                                                                            </a>
                                                                        </li>
                                                                        <li [ngClass]="activeCategoryInvoice==5?'active':''"
                                                                            (click)="changeActiveCategoryPayment(5)">
                                                                            <a> Show Invoices Invoice
                                                                            </a>
                                                                        </li>
                                                                        <li [ngClass]="activeCategoryInvoice==6?'active':''"
                                                                            (click)="changeActiveCategoryPayment(6)">
                                                                            <a> Show Invoices Payroll
                                                                            </a>
                                                                        </li>

                                                                        <li [ngClass]="activeCategoryInvoice==7?'active':''"
                                                                            (click)="changeActiveCategoryPayment(7)">
                                                                            <a> Show Invoices Purchase Invoice
                                                                            </a>
                                                                        </li>
                                                                        <li [ngClass]="activeCategoryInvoice==8?'active':''"
                                                                            (click)="changeActiveCategoryPayment(8)">
                                                                            <a> Show Invoices Purchase Return
                                                                            </a>
                                                                        </li>
                                                                        <li [ngClass]="activeCategoryInvoice==9?'active':''"
                                                                            (click)="changeActiveCategoryPayment(9)">
                                                                            <a> Show Invoices Purchase Invoice Template
                                                                            </a>
                                                                        </li>

                                                                        <li [ngClass]="activeCategoryInvoice==10?'active':''"
                                                                            (click)="changeActiveCategoryPayment(10)">
                                                                            <a> Show Invoices Sales Invoice
                                                                            </a>
                                                                        </li>
                                                                        <li [ngClass]="activeCategoryInvoice==11?'active':''"
                                                                            (click)="changeActiveCategoryPayment(11)">
                                                                            <a> Show Invoices Sales Invoice Template
                                                                            </a>
                                                                        </li>
                                                                        <li [ngClass]="activeCategoryInvoice==12?'active':''"
                                                                            (click)="changeActiveCategoryPayment(12)">
                                                                            <a> Show Invoices Invoice Template
                                                                            </a>
                                                                        </li>
                                                                        <li [ngClass]="activeCategoryInvoice==13?'active':''"
                                                                            (click)="changeActiveCategoryPayment(13)">
                                                                            <a> Show Invoices Approved
                                                                            </a>
                                                                        </li>
                                                                        <li [ngClass]="activeCategoryInvoice==14?'active':''"
                                                                            (click)="changeActiveCategoryPayment(14)">
                                                                            <a> Show Invoices Cancelled
                                                                            </a>
                                                                        </li>
                                                                        <li [ngClass]="activeCategoryInvoice==15?'active':''"
                                                                            (click)="changeActiveCategoryPayment(15)">
                                                                            <a> Show Invoices In-Process
                                                                            </a>
                                                                        </li>
                                                                        <li [ngClass]="activeCategoryInvoice==16?'active':''"
                                                                            (click)="changeActiveCategoryPayment(16)">
                                                                            <a> Show Invoices Paid
                                                                            </a>
                                                                        </li>
                                                                        <li [ngClass]="activeCategoryInvoice==17?'active':''"
                                                                            (click)="changeActiveCategoryPayment(17)">
                                                                            <a> Show Invoices Ready for Posting
                                                                            </a>
                                                                        </li>
                                                                        <li [ngClass]="activeCategoryInvoice==18?'active':''"
                                                                            (click)="changeActiveCategoryPayment(18)">
                                                                            <a> Show Invoices Received
                                                                            </a>
                                                                        </li>
                                                                        <li [ngClass]="activeCategoryInvoice==19?'active':''"
                                                                            (click)="changeActiveCategoryPayment(19)">
                                                                            <a> Show Invoices Sent
                                                                            </a>
                                                                        </li>
                                                                        <li [ngClass]="activeCategoryInvoice==20?'active':''"
                                                                            (click)="changeActiveCategoryPayment(20)">
                                                                            <a> Show Invoices Write Off
                                                                            </a>
                                                                        </li>


                                                                    </ul>
                                                                </div>

                                                                <div *ngIf="activeCategoryInvoice==1">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive7">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed"
                                                                                    role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive7"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive7">
                                                                                    Show Invoices Purchase
                                                                                    Invoice
                                                                                  
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Billing
                                                                                            Account
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Date</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Reference
                                                                                            Number</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>

                                                                            <div class="w3-card-4 classCard">
                                                                                <header class="w3-container w3-blue">
                                                                                    <div class="header-tabs">
                                                                                        <h4
                                                                                            class=" common-styling h4Margin">
                                                                                            Invoices

                                                                                        </h4>
                                                                                    </div>
                                                                                </header>

                                                                                <div
                                                                                    class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table [value]="products3"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="code">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </p-checkbox>
                                                                                                                Invoice
                                                                                                                ID
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th
                                                                                                            pSortableColumn="name">
                                                                                                            Invoice
                                                                                                            Type
                                                                                                            <p-sortIcon
                                                                                                                field="name">
                                                                                                            </p-sortIcon>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th
                                                                                                            pSortableColumn="category">
                                                                                                            Invoice
                                                                                                            Date
                                                                                                            <p-sortIcon
                                                                                                                field="category">
                                                                                                            </p-sortIcon>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th
                                                                                                            pSortableColumn="quantity">
                                                                                                            Status
                                                                                                            <p-sortIcon
                                                                                                                field="quantity">
                                                                                                            </p-sortIcon>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th
                                                                                                            pSortableColumn="quantity">
                                                                                                            Description
                                                                                                            <p-sortIcon
                                                                                                                field="quantity">
                                                                                                            </p-sortIcon>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th
                                                                                                            pSortableColumn="price">
                                                                                                            From
                                                                                                            Party
                                                                                                            <p-sortIcon
                                                                                                                field="price">
                                                                                                            </p-sortIcon>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th
                                                                                                            pSortableColumn="price">
                                                                                                            Action
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                style="visibility:hidden;"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </p-checkbox>
                                                                                                                {{product.code}}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td>{{product.name}}
                                                                                                        </td>
                                                                                                        <td>{{product.category}}
                                                                                                        </td>
                                                                                                        <td>{{product.quantity}}
                                                                                                        </td>
                                                                                                        <td>{{product.price
                                                                                                            |
                                                                                                            currency:
                                                                                                            'USD'}}
                                                                                                        </td>
                                                                                                        <td>{{product.category}}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <div>
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                    width="16"
                                                                                                                    height="16"
                                                                                                                    viewBox="0 0 21.223 21.222">
                                                                                                                    <g
                                                                                                                        transform="translate(0 -0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 1.52)">
                                                                                                                            <path
                                                                                                                                d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                                transform="translate(0 -34.137)" />
                                                                                                                        </g>
                                                                                                                        <g
                                                                                                                            transform="translate(4.548 0.004)">
                                                                                                                            <g
                                                                                                                                transform="translate(0 0)">
                                                                                                                                <path
                                                                                                                                    d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                    transform="translate(-102.409 -0.004)" />
                                                                                                                            </g>
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </svg>
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                    class="ml-20"
                                                                                                                    width="16"
                                                                                                                    height="16"
                                                                                                                    viewBox="0 0 26 26">
                                                                                                                    <defs>
                                                                                                                        <style>
                                                                                                                            .a {
                                                                                                                                fill: #f44336;
                                                                                                                            }

                                                                                                                            .b {
                                                                                                                                fill: #fafafa;
                                                                                                                                margin-left: 20px;
                                                                                                                            }
                                                                                                                        </style>
                                                                                                                    </defs>
                                                                                                                    <path
                                                                                                                        class="a fill-color"
                                                                                                                        d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                    <path
                                                                                                                        class="b"
                                                                                                                        d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                        transform="translate(-147.576 -147.576)" />
                                                                                                                </svg>
                                                                                                            </div>
                                                                                                        </td>

                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                            <p class="paginate_data">
                                                                                                View per
                                                                                                page </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryInvoice==2">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive7">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed"
                                                                                    role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive7"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive7">
                                                                                    Show Invoices Purchase
                                                                                    Invoice
                                                                                  
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Billing
                                                                                            Account
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Date</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Reference
                                                                                            Number</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="products3"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Invoice
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="name">
                                                                                                        Invoice
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="name">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="category">
                                                                                                        Invoice
                                                                                                        Date
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Description
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        From
                                                                                                        Party
                                                                                                        <p-sortIcon
                                                                                                            field="price">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        Action
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            {{product.code}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{{product.name}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>{{product.quantity}}
                                                                                                    </td>
                                                                                                    <td>{{product.price
                                                                                                        |
                                                                                                        currency:
                                                                                                        'USD'}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per
                                                                                            page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div *ngIf="activeCategoryInvoice==3">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive7">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed"
                                                                                    role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive7"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive7">
                                                                                    Show Invoices Purchase
                                                                                    Invoice
                                                                                  
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Billing
                                                                                            Account
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Date</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Reference
                                                                                            Number</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="products3"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Invoice
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="name">
                                                                                                        Invoice
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="name">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="category">
                                                                                                        Invoice
                                                                                                        Date
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Description
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        From
                                                                                                        Party
                                                                                                        <p-sortIcon
                                                                                                            field="price">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        Action
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            {{product.code}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{{product.name}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>{{product.quantity}}
                                                                                                    </td>
                                                                                                    <td>{{product.price
                                                                                                        |
                                                                                                        currency:
                                                                                                        'USD'}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per
                                                                                            page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryInvoice==4">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive7">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed"
                                                                                    role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive7"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive7">
                                                                                    Show Invoices Purchase
                                                                                    Invoice
                                                                                  
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Billing
                                                                                            Account
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Date</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Reference
                                                                                            Number</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="products3"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Invoice
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="name">
                                                                                                        Invoice
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="name">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="category">
                                                                                                        Invoice
                                                                                                        Date
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Description
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        From
                                                                                                        Party
                                                                                                        <p-sortIcon
                                                                                                            field="price">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        Action
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            {{product.code}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{{product.name}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>{{product.quantity}}
                                                                                                    </td>
                                                                                                    <td>{{product.price
                                                                                                        |
                                                                                                        currency:
                                                                                                        'USD'}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per
                                                                                            page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryInvoice==5">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive7">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed"
                                                                                    role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive7"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive7">
                                                                                    Show Invoices Purchase
                                                                                    Invoice
                                                                                  
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Billing
                                                                                            Account
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Date</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Reference
                                                                                            Number</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="products3"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Invoice
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="name">
                                                                                                        Invoice
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="name">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="category">
                                                                                                        Invoice
                                                                                                        Date
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Description
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        From
                                                                                                        Party
                                                                                                        <p-sortIcon
                                                                                                            field="price">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        Action
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            {{product.code}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{{product.name}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>{{product.quantity}}
                                                                                                    </td>
                                                                                                    <td>{{product.price
                                                                                                        |
                                                                                                        currency:
                                                                                                        'USD'}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per
                                                                                            page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryInvoice==6">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive7">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed"
                                                                                    role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive7"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive7">
                                                                                    Show Invoices Purchase
                                                                                    Invoice
                                                                                  
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Billing
                                                                                            Account
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Date</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Reference
                                                                                            Number</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="products3"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Invoice
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="name">
                                                                                                        Invoice
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="name">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="category">
                                                                                                        Invoice
                                                                                                        Date
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Description
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        From
                                                                                                        Party
                                                                                                        <p-sortIcon
                                                                                                            field="price">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        Action
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            {{product.code}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{{product.name}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>{{product.quantity}}
                                                                                                    </td>
                                                                                                    <td>{{product.price
                                                                                                        |
                                                                                                        currency:
                                                                                                        'USD'}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per
                                                                                            page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryInvoice==7">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive7">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed"
                                                                                    role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive7"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive7">
                                                                                    Show Invoices Purchase
                                                                                    Invoice
                                                                                  
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Billing
                                                                                            Account
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Date</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Reference
                                                                                            Number</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="products3"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Invoice
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="name">
                                                                                                        Invoice
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="name">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="category">
                                                                                                        Invoice
                                                                                                        Date
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Description
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        From
                                                                                                        Party
                                                                                                        <p-sortIcon
                                                                                                            field="price">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        Action
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            {{product.code}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{{product.name}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>{{product.quantity}}
                                                                                                    </td>
                                                                                                    <td>{{product.price
                                                                                                        |
                                                                                                        currency:
                                                                                                        'USD'}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per
                                                                                            page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryInvoice==8">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive7">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed"
                                                                                    role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive7"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive7">
                                                                                    Show Invoices Purchase
                                                                                    Invoice
                                                                                  
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Billing
                                                                                            Account
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Date</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Reference
                                                                                            Number</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="products3"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Invoice
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="name">
                                                                                                        Invoice
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="name">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="category">
                                                                                                        Invoice
                                                                                                        Date
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Description
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        From
                                                                                                        Party
                                                                                                        <p-sortIcon
                                                                                                            field="price">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        Action
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            {{product.code}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{{product.name}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>{{product.quantity}}
                                                                                                    </td>
                                                                                                    <td>{{product.price
                                                                                                        |
                                                                                                        currency:
                                                                                                        'USD'}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per
                                                                                            page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryInvoice==9">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive7">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed"
                                                                                    role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive7"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive7">
                                                                                    Show Invoices Purchase
                                                                                    Invoice
                                                                                  
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Billing
                                                                                            Account
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Date</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Reference
                                                                                            Number</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="products3"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Invoice
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="name">
                                                                                                        Invoice
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="name">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="category">
                                                                                                        Invoice
                                                                                                        Date
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Description
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        From
                                                                                                        Party
                                                                                                        <p-sortIcon
                                                                                                            field="price">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        Action
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            {{product.code}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{{product.name}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>{{product.quantity}}
                                                                                                    </td>
                                                                                                    <td>{{product.price
                                                                                                        |
                                                                                                        currency:
                                                                                                        'USD'}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per
                                                                                            page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryInvoice==10">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive7">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed"
                                                                                    role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive7"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive7">
                                                                                    Show Invoices Purchase
                                                                                    Invoice
                                                                                  
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Billing
                                                                                            Account
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Date</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Reference
                                                                                            Number</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="products3"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Invoice
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="name">
                                                                                                        Invoice
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="name">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="category">
                                                                                                        Invoice
                                                                                                        Date
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Description
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        From
                                                                                                        Party
                                                                                                        <p-sortIcon
                                                                                                            field="price">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        Action
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            {{product.code}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{{product.name}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>{{product.quantity}}
                                                                                                    </td>
                                                                                                    <td>{{product.price
                                                                                                        |
                                                                                                        currency:
                                                                                                        'USD'}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per
                                                                                            page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryInvoice==11">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive7">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed"
                                                                                    role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive7"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive7">
                                                                                    Show Invoices Purchase
                                                                                    Invoice
                                                                                  
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Billing
                                                                                            Account
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Date</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Reference
                                                                                            Number</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="products3"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Invoice
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="name">
                                                                                                        Invoice
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="name">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="category">
                                                                                                        Invoice
                                                                                                        Date
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Description
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        From
                                                                                                        Party
                                                                                                        <p-sortIcon
                                                                                                            field="price">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        Action
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            {{product.code}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{{product.name}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>{{product.quantity}}
                                                                                                    </td>
                                                                                                    <td>{{product.price
                                                                                                        |
                                                                                                        currency:
                                                                                                        'USD'}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per
                                                                                            page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryInvoice==12">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive7">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed"
                                                                                    role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive7"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive7">
                                                                                    Show Invoices Purchase
                                                                                    Invoice
                                                                                  
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Billing
                                                                                            Account
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Date</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Reference
                                                                                            Number</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="products3"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Invoice
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="name">
                                                                                                        Invoice
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="name">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="category">
                                                                                                        Invoice
                                                                                                        Date
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Description
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        From
                                                                                                        Party
                                                                                                        <p-sortIcon
                                                                                                            field="price">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        Action
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            {{product.code}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{{product.name}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>{{product.quantity}}
                                                                                                    </td>
                                                                                                    <td>{{product.price
                                                                                                        |
                                                                                                        currency:
                                                                                                        'USD'}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per
                                                                                            page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryInvoice==13">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive7">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed"
                                                                                    role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive7"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive7">
                                                                                    Show Invoices Purchase
                                                                                    Invoice
                                                                                  
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Billing
                                                                                            Account
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Date</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Reference
                                                                                            Number</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="products3"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Invoice
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="name">
                                                                                                        Invoice
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="name">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="category">
                                                                                                        Invoice
                                                                                                        Date
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Description
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        From
                                                                                                        Party
                                                                                                        <p-sortIcon
                                                                                                            field="price">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        Action
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            {{product.code}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{{product.name}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>{{product.quantity}}
                                                                                                    </td>
                                                                                                    <td>{{product.price
                                                                                                        |
                                                                                                        currency:
                                                                                                        'USD'}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per
                                                                                            page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryInvoice==14">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive7">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed"
                                                                                    role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive7"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive7">
                                                                                    Show Invoices Purchase
                                                                                    Invoice
                                                                                  
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Billing
                                                                                            Account
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Date</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Reference
                                                                                            Number</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="products3"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Invoice
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="name">
                                                                                                        Invoice
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="name">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="category">
                                                                                                        Invoice
                                                                                                        Date
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Description
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        From
                                                                                                        Party
                                                                                                        <p-sortIcon
                                                                                                            field="price">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        Action
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            {{product.code}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{{product.name}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>{{product.quantity}}
                                                                                                    </td>
                                                                                                    <td>{{product.price
                                                                                                        |
                                                                                                        currency:
                                                                                                        'USD'}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per
                                                                                            page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryInvoice==15">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive7">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed"
                                                                                    role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive7"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive7">
                                                                                    Show Invoices Purchase
                                                                                    Invoice
                                                                                  
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Billing
                                                                                            Account
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Date</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Reference
                                                                                            Number</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="products3"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Invoice
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="name">
                                                                                                        Invoice
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="name">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="category">
                                                                                                        Invoice
                                                                                                        Date
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Description
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        From
                                                                                                        Party
                                                                                                        <p-sortIcon
                                                                                                            field="price">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        Action
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            {{product.code}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{{product.name}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>{{product.quantity}}
                                                                                                    </td>
                                                                                                    <td>{{product.price
                                                                                                        |
                                                                                                        currency:
                                                                                                        'USD'}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per
                                                                                            page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryInvoice==16">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive7">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed"
                                                                                    role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive7"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive7">
                                                                                    Show Invoices Purchase
                                                                                    Invoice
                                                                                  
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Billing
                                                                                            Account
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Date</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Reference
                                                                                            Number</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="products3"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Invoice
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="name">
                                                                                                        Invoice
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="name">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="category">
                                                                                                        Invoice
                                                                                                        Date
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Description
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        From
                                                                                                        Party
                                                                                                        <p-sortIcon
                                                                                                            field="price">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        Action
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            {{product.code}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{{product.name}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>{{product.quantity}}
                                                                                                    </td>
                                                                                                    <td>{{product.price
                                                                                                        |
                                                                                                        currency:
                                                                                                        'USD'}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per
                                                                                            page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryInvoice==17">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive7">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed"
                                                                                    role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive7"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive7">
                                                                                    Show Invoices Purchase
                                                                                    Invoice
                                                                                  
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Billing
                                                                                            Account
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Date</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Reference
                                                                                            Number</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="products3"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Invoice
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="name">
                                                                                                        Invoice
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="name">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="category">
                                                                                                        Invoice
                                                                                                        Date
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Description
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        From
                                                                                                        Party
                                                                                                        <p-sortIcon
                                                                                                            field="price">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        Action
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            {{product.code}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{{product.name}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>{{product.quantity}}
                                                                                                    </td>
                                                                                                    <td>{{product.price
                                                                                                        |
                                                                                                        currency:
                                                                                                        'USD'}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per
                                                                                            page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryInvoice==18">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive7">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed"
                                                                                    role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive7"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive7">
                                                                                    Show Invoices Purchase
                                                                                    Invoice
                                                                                  
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Billing
                                                                                            Account
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Date</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Reference
                                                                                            Number</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="products3"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Invoice
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="name">
                                                                                                        Invoice
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="name">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="category">
                                                                                                        Invoice
                                                                                                        Date
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Description
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        From
                                                                                                        Party
                                                                                                        <p-sortIcon
                                                                                                            field="price">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        Action
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            {{product.code}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{{product.name}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>{{product.quantity}}
                                                                                                    </td>
                                                                                                    <td>{{product.price
                                                                                                        |
                                                                                                        currency:
                                                                                                        'USD'}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per
                                                                                            page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryInvoice==19">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive7">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed"
                                                                                    role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive7"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive7">
                                                                                    Show Invoices Purchase
                                                                                    Invoice
                                                                                  
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Billing
                                                                                            Account
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Date</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Reference
                                                                                            Number</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="products3"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Invoice
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="name">
                                                                                                        Invoice
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="name">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="category">
                                                                                                        Invoice
                                                                                                        Date
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Description
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        From
                                                                                                        Party
                                                                                                        <p-sortIcon
                                                                                                            field="price">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        Action
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            {{product.code}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{{product.name}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>{{product.quantity}}
                                                                                                    </td>
                                                                                                    <td>{{product.price
                                                                                                        |
                                                                                                        currency:
                                                                                                        'USD'}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per
                                                                                            page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryInvoice==20">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive7">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed"
                                                                                    role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive7"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive7">
                                                                                    Show Invoices Purchase
                                                                                    Invoice
                                                                                  
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Description</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Billing
                                                                                            Account
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Invoice
                                                                                            Date</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Reference
                                                                                            Number</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="products3"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Invoice
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="name">
                                                                                                        Invoice
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="name">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="category">
                                                                                                        Invoice
                                                                                                        Date
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Description
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        From
                                                                                                        Party
                                                                                                        <p-sortIcon
                                                                                                            field="price">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        Action
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            {{product.code}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{{product.name}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>{{product.quantity}}
                                                                                                    </td>
                                                                                                    <td>{{product.price
                                                                                                        |
                                                                                                        currency:
                                                                                                        'USD'}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per
                                                                                            page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div *ngIf="activeCategory==5">

                                                            <div class="w3-card-4 classCard m-3">
                                                                <div class="container-fluid">

                                                                    <ul class="tabbing-section tabbing-accordians tabClass mt-0"
                                                                        style="padding-left:9px; width: 102%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                                                                        <li [ngClass]="activeCategoryPay==1?'active':''"
                                                                            (click)="changeActiveCategoryPayment(1)">
                                                                            <a>
                                                                                Show All Payments </a>
                                                                        </li>

                                                                        <li [ngClass]="activeCategoryPay==2?'active':''"
                                                                            (click)="changeActiveCategoryPayment(2)">
                                                                            <a>
                                                                                Show Payments Commission Payment </a>
                                                                        </li>
                                                                        <li [ngClass]="activeCategoryPay==3?'active':''"
                                                                            (click)="changeActiveCategoryPayment(3)">
                                                                            <a> Show Payments Customer Deposit
                                                                            </a>
                                                                        </li>


                                                                    </ul>
                                                                </div>

                                                                <div *ngIf="activeCategoryPay==1">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed" role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive21"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive21">
                                                                                    Show All Payments
                                                                                  
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Payment
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Comments</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Payment
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label for="exampleInputEmail1">
                                                                                            Account </label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Refrence
                                                                                            No</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Payment
                                                                                            Gateway Response
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div class="w3-card-4 classCard">
                                                                                <header class="w3-container w3-blue">
                                                                                    <div class="header-tabs">
                                                                                        <h4
                                                                                            class=" common-styling h4Margin">
                                                                                            Payment
                                                                                        </h4>
                                                                                    </div>
                                                                                </header>

                                                                                <div
                                                                                    class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table [value]="products3"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="code">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </p-checkbox>
                                                                                                                Payment
                                                                                                                ID
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th
                                                                                                            pSortableColumn="name">
                                                                                                            Payment
                                                                                                            Type
                                                                                                            <p-sortIcon
                                                                                                                field="name">
                                                                                                            </p-sortIcon>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th
                                                                                                            pSortableColumn="category">
                                                                                                            Status
                                                                                                            <p-sortIcon
                                                                                                                field="category">
                                                                                                            </p-sortIcon>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th
                                                                                                            pSortableColumn="quantity">
                                                                                                            Comments
                                                                                                            <p-sortIcon
                                                                                                                field="quantity">
                                                                                                            </p-sortIcon>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th
                                                                                                            pSortableColumn="quantity">
                                                                                                            From
                                                                                                            Party
                                                                                                            <p-sortIcon
                                                                                                                field="quantity">
                                                                                                            </p-sortIcon>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th
                                                                                                            pSortableColumn="price">
                                                                                                            To Party
                                                                                                            <p-sortIcon
                                                                                                                field="price">
                                                                                                            </p-sortIcon>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th
                                                                                                            pSortableColumn="price">
                                                                                                            Action
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                style="visibility:hidden;"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </p-checkbox>
                                                                                                                {{product.code}}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td>{{product.name}}
                                                                                                        </td>
                                                                                                        <td>{{product.category}}
                                                                                                        </td>
                                                                                                        <td>{{product.quantity}}
                                                                                                        </td>
                                                                                                        <td>{{product.price
                                                                                                            |
                                                                                                            currency:
                                                                                                            'USD'}}
                                                                                                        </td>
                                                                                                        <td>{{product.category}}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <div>
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                    width="16"
                                                                                                                    height="16"
                                                                                                                    viewBox="0 0 21.223 21.222">
                                                                                                                    <g
                                                                                                                        transform="translate(0 -0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 1.52)">
                                                                                                                            <path
                                                                                                                                d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                                transform="translate(0 -34.137)" />
                                                                                                                        </g>
                                                                                                                        <g
                                                                                                                            transform="translate(4.548 0.004)">
                                                                                                                            <g
                                                                                                                                transform="translate(0 0)">
                                                                                                                                <path
                                                                                                                                    d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                    transform="translate(-102.409 -0.004)" />
                                                                                                                            </g>
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </svg>
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                    class="ml-20"
                                                                                                                    width="16"
                                                                                                                    height="16"
                                                                                                                    viewBox="0 0 26 26">
                                                                                                                    <defs>
                                                                                                                        <style>
                                                                                                                            .a {
                                                                                                                                fill: #f44336;
                                                                                                                            }

                                                                                                                            .b {
                                                                                                                                fill: #fafafa;
                                                                                                                                margin-left: 20px;
                                                                                                                            }
                                                                                                                        </style>
                                                                                                                    </defs>
                                                                                                                    <path
                                                                                                                        class="a fill-color"
                                                                                                                        d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                    <path
                                                                                                                        class="b"
                                                                                                                        d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                        transform="translate(-147.576 -147.576)" />
                                                                                                                </svg>
                                                                                                            </div>
                                                                                                        </td>

                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <!-- <p class="paginate_data">
                                                                           View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                            <p class="paginate_data">
                                                                                                View per page </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryPay==2">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive22">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed" role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive22"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive2">
                                                                                    Show Payments Commission Payment
                                                                                   
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Payment
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Comments</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Payment
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label for="exampleInputEmail1">
                                                                                            Account </label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Refrence
                                                                                            No</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Payment
                                                                                            Gateway Response
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="products3"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Payment
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="name">
                                                                                                        Payment
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="name">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="category">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Comments
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        From
                                                                                                        Party
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        To Party
                                                                                                        <p-sortIcon
                                                                                                            field="price">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        Action
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            {{product.code}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{{product.name}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>{{product.quantity}}
                                                                                                    </td>
                                                                                                    <td>{{product.price
                                                                                                        |
                                                                                                        currency:
                                                                                                        'USD'}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                           View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryPay==3">
                                                                    <div class="panel panel-default">
                                                                        <div class="panel-heading" role="tab"
                                                                            id="headingfive3">
                                                                            <!-- <h4 class="panel-title">
                                                                                <a class="collapsed" role="button"
                                                                                    data-toggle="collapse"
                                                                                    data-parent="#accordion"
                                                                                    href="#collapsefive3"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapsefive3">
                                                                                    Show Payments Customer Deposit
                                                                                 
                                                                                </a>
                                                                            </h4> -->
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select ">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Payment
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Comments</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Payment
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status
                                                                                            ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party ID</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label for="exampleInputEmail1">
                                                                                            Account </label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Refrence
                                                                                            No</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Payment
                                                                                            Gateway Response
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="products3"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            Payment
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="name">
                                                                                                        Payment
                                                                                                        Type
                                                                                                        <p-sortIcon
                                                                                                            field="name">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="category">
                                                                                                        Status
                                                                                                        <p-sortIcon
                                                                                                            field="category">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        Comments
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="quantity">
                                                                                                        From
                                                                                                        Party
                                                                                                        <p-sortIcon
                                                                                                            field="quantity">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        To Party
                                                                                                        <p-sortIcon
                                                                                                            field="price">
                                                                                                        </p-sortIcon>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th
                                                                                                        pSortableColumn="price">
                                                                                                        Action
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </p-checkbox>
                                                                                                            {{product.code}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{{product.name}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>{{product.quantity}}
                                                                                                    </td>
                                                                                                    <td>{{product.price
                                                                                                        |
                                                                                                        currency:
                                                                                                        'USD'}}
                                                                                                    </td>
                                                                                                    <td>{{product.category}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                class="ml-20"
                                                                                                                width="16"
                                                                                                                height="16"
                                                                                                                viewBox="0 0 26 26">
                                                                                                                <defs>
                                                                                                                    <style>
                                                                                                                        .a {
                                                                                                                            fill: #f44336;
                                                                                                                        }

                                                                                                                        .b {
                                                                                                                            fill: #fafafa;
                                                                                                                            margin-left: 20px;
                                                                                                                        }
                                                                                                                    </style>
                                                                                                                </defs>
                                                                                                                <path
                                                                                                                    class="a fill-color"
                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                <path
                                                                                                                    class="b"
                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                           View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>



                                                            </div>


                                                        </div>
                                                    </div>


                                                </div>
                                                <div *ngIf="activeCategoriess==2">

                                                    <div class="panel-body">
                                                        <div class="w3-card-4 classCard">
                                                            <div class="container-fluid">
                                                                <ul class="tabbing-section tabbing-accordians tabClass"
                                                                    style="padding-left:9px">
                                                                    <li [ngClass]="activeCategoryMain==1?'active':''"
                                                                        (click)="AccountingInfo()"><a>
                                                                            Available Internal Accounting
                                                                            Organizations</a></li>
                                                                    <li>
                                                                        <button type="button"
                                                                            class="btn btn-outline-secondary"
                                                                            data-toggle="modal"
                                                                            data-target="#exampleModalCenter">Create new
                                                                            accounting company</button>
                                                                    </li>


                                                                </ul>
                                                            </div>

                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="w3-card-4 classCard" style="margin: 1%;">
                                                                    <div class="container-fluid">
                                                                        <ul class="tabbing-section tabbing-accordians tabClass"
                                                                            style="padding-left:9px">
                                                                            <li
                                                                                [ngClass]="activeCategoryMain==1?'active':''">
                                                                                <a>
                                                                                    Company</a>
                                                                            </li>



                                                                        </ul>
                                                                    </div>
                                                                    <div class="form-group" style="padding: 20px 20px;">
                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="getMainOrg"
                                                                                [paginator]="true" [rows]="rows"
                                                                                scrollWidth="100%"
                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                [totalRecords]="total"
                                                                                [scrollable]="true"
                                                                                (sortFunction)="customSort($event)"
                                                                                [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="code">
                                                                                            <div class="checkbox-align"
                                                                                                style="color: white;">
                                                                                                <p-checkbox
                                                                                                    _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox"
                                                                                                    binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div
                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name="undefined"
                                                                                                                value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </p-checkbox>
                                                                                                Companies
                                                                                                <p-sortIcon
                                                                                                    field="code"></p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>


                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body"
                                                                                    let-activity>
                                                                                    <tr>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox
                                                                                                    _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox"
                                                                                                    binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div
                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name="undefined"
                                                                                                                value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </p-checkbox>
                                                                                                <span>
                                                                                                    {{activity.partyDesc}}
                                                                                                </span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <!-- <td [ngStyle]="{'width':'150px'}"(click)="glOrganizationSummary(activity.partyId)" style="cursor:pointer;text-decoration: underline;color: #007bff!important;"> Configure </td> -->
                                                                                        <td [ngStyle]="{'width':'150px'}"
                                                                                            (click)="glOrganizationAccountingSummary(activity.partyId)"
                                                                                            style="cursor:pointer;text-decoration: underline;color: #007bff!important;">
                                                                                            Account Detail </td>


                                                                                    </tr>
                                                                                </ng-template>


                                                                            </p-table>
                                                                            <p class="paginate_data">
                                                                                View per page
                                                                                <!-- <ng-container >
                                                                                    <span style="margin-left: 400px;">No Record Found!....</span>
                                                                                </ng-container> -->
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategoriess==3">

                                                    <div class=" bg-white color-grey">

                                                        <div class="w3-card-4 classCard classCardType border_wdth_1">
                                                            <div class="container-fluid"
                                                                *ngIf="this.agreementTypeValue=='payable'">
                                                                <ul class="tabbing-section tabbing-accordians tabClass mt-0"
                                                                    style="padding-left:9px">
                                                                    <li [ngClass]="activeCategoryA==4?'active':''"
                                                                        (click)="main()">
                                                                        <a>Payables Summary </a>

                                                                    </li>
                                                                    <li [ngClass]="activeCategoryA==1?'active':''"
                                                                        (click)="enterBills()"><a>
                                                                            Enter Bills </a></li>
                                                                    <li [ngClass]="activeCategoryA==2?'active':''"
                                                                        (click)="payableMakePayment()"><a>
                                                                            Make Payments</a></li>
                                                                    <li [ngClass]="activeCategoryA==3?'active':''"
                                                                        (click)="payableMakeGroupPayment()"><a>
                                                                            Make Group Payments</a></li>
                                                                </ul>
                                                            </div>
                                                            <div class="container-fluid"
                                                                *ngIf="this.agreementTypeValue=='receiveable'">
                                                                <ul class="tabbing-section tabbing-accordians tabClass mt-0"
                                                                    style="padding-left:9px">
                                                                    <li [ngClass]="activeCategory==4?'active':''"
                                                                        (click)="mainReceive()"><a>
                                                                            Receivables Summary</a></li>
                                                                    <li [ngClass]="activeCategory==1?'active':''"
                                                                        (click)="salesInvoice()"><a>
                                                                            Sales Invoice </a></li>
                                                                    <li [ngClass]="activeCategory==2?'active':''"
                                                                        (click)="receivePayment()"><a>
                                                                            Receive Payments</a></li>
                                                                    <li [ngClass]="activeCategory==3?'active':''"
                                                                        (click)="receiveGroupPayments()"><a>
                                                                            Receive Group Payments</a></li>
                                                                </ul>
                                                            </div>

                                                            <div class="create-new-leade mt-2">
                                                                <div class="container-fluid">


                                                                    <div class="panel-group">
                                                                        <div *ngIf="activeCategoryA==1">
                                                                            <div class="panel-collapse">
                                                                                <div class="w3-card-4 classCard classCardType"
                                                                                    *ngIf="this.agreementTypeValue=='payable'">
                                                                                    <div class="container-fluid">
                                                                                        <ul
                                                                                            class="tabbing-section tabbing-accordians tabClass">

                                                                                            <li [ngClass]="activeCategoryMain==2?'active':''"
                                                                                                (click)="changeactiveCategoryFunctionPurchae()">
                                                                                                <a>
                                                                                                    Purchases
                                                                                                    Invoices</a>
                                                                                            </li>
                                                                                            <li [ngClass]="activeCategoryMain==4?'active':''"
                                                                                                (click)="changeactiveCommission()">
                                                                                                <a>
                                                                                                    Commission
                                                                                                    Invoices</a>
                                                                                            </li>

                                                                                            <li [ngClass]="activeCategoryMain==3?'active':''"
                                                                                                (click)="changeactiveCategoryFunctionCredit()">
                                                                                                <a>
                                                                                                    Credit Memo</a>
                                                                                            </li>


                                                                                        </ul>
                                                                                    </div>
                                                                                    <div class="create-new-leade mt-2">
                                                                                        <div class="container-fluid">
                                                                                            <div class="row"
                                                                                                style=" padding: 7px;">
                                                                                                <form class="w-100"
                                                                                                    [formGroup]="searchInvoiceForm">

                                                                                                    <div
                                                                                                        class="w3-card-4 mt-2 w-100">
                                                                                                        <div class="accordion classCard"
                                                                                                            id="myAccordion">
                                                                                                            <div
                                                                                                                class="accordion-item">
                                                                                                                <h2 class="accordion-header"
                                                                                                                    id="headingOne">
                                                                                                                    <div
                                                                                                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion">
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            class="btn btn-outline-secondary active marginHeader"
                                                                                                                            routerLinkActive="active"
                                                                                                                            routerLink="/financial/{{invoiceType}}/invoices/create-invoice">Create
                                                                                                                            New
                                                                                                                            Invoice</button>

                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            class="accordion-button  collapsed"
                                                                                                                            data-bs-toggle="collapse"
                                                                                                                            data-bs-target="#collapseOne"
                                                                                                                            style="margin-left: 78%!important; padding-top: 11px !important;
                                                                                                                                            padding-bottom: 11px !important;">
                                                                                                                        </button>

                                                                                                                    </div>

                                                                                                                </h2>
                                                                                                                <div id="collapseOne"
                                                                                                                    class="accordion-collapse collapse"
                                                                                                                    data-bs-parent="#myAccordion">
                                                                                                                    <div
                                                                                                                        class="card-body">
                                                                                                                        <div
                                                                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                                            <div
                                                                                                                                class="col-lg-12">
                                                                                                                                <div
                                                                                                                                    class="row">

                                                                                                                                    <div
                                                                                                                                        class="col-lg-3 form-group classInput">
                                                                                                                                        <label
                                                                                                                                            for="exampleInputEmail1">Invoice
                                                                                                                                            ID</label>
                                                                                                                                    </div>

                                                                                                                                    <div class="col-lg-2"
                                                                                                                                        style="display: none;">
                                                                                                                                        <p-dropdown
                                                                                                                                            filter="true"
                                                                                                                                            name="party"
                                                                                                                                            [options]="opportunityIdArray"
                                                                                                                                            optionlabel="label"
                                                                                                                                            formControlName="invoiceIdSearchType">
                                                                                                                                        </p-dropdown>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        class="col-lg-2">
                                                                                                                                        <input
                                                                                                                                            type="email"
                                                                                                                                            id="exampleInputEmail1"
                                                                                                                                            class="form-control"
                                                                                                                                            aria-describedby="emailHelp"
                                                                                                                                            placeholder="Invoice Id"
                                                                                                                                            formControlName="invoiceId">
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        class="col-lg-3 form-group classInputA">
                                                                                                                                        <label
                                                                                                                                            for="exampleInputEmail1">Description</label>
                                                                                                                                    </div>
                                                                                                                                    <div class="col-lg-2"
                                                                                                                                        style="display: none;">
                                                                                                                                        <p-dropdown
                                                                                                                                            filter="true"
                                                                                                                                            name="party"
                                                                                                                                            formControlName="descriptionSearchType"
                                                                                                                                            [options]="opportunityIdArray"
                                                                                                                                            optionlabel="label">
                                                                                                                                        </p-dropdown>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        class="col-lg-2">
                                                                                                                                        <input
                                                                                                                                            type="email"
                                                                                                                                            id="exampleInputEmail1"
                                                                                                                                            class="form-control"
                                                                                                                                            formControlName="description"
                                                                                                                                            aria-describedby="emailHelp"
                                                                                                                                            placeholder="Description">
                                                                                                                                    </div>



                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                class="col-lg-12">
                                                                                                                                <div
                                                                                                                                    class="row">

                                                                                                                                    <div
                                                                                                                                        class="col-lg-3 form-group classInput">
                                                                                                                                        <label
                                                                                                                                            for="exampleInputEmail1">Invoice
                                                                                                                                            Type</label>
                                                                                                                                    </div>

                                                                                                                                    <div
                                                                                                                                        class="col-lg-2">
                                                                                                                                        <p-dropdown
                                                                                                                                            filter="true"
                                                                                                                                            name="party"
                                                                                                                                            [options]="invoiceTypes"
                                                                                                                                            formControlName="invoiceType"
                                                                                                                                            optionlabel="label">
                                                                                                                                        </p-dropdown>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        class="col-lg-3 form-group classInputA">
                                                                                                                                        <label
                                                                                                                                            for="exampleInputEmail1">Status
                                                                                                                                            ID</label>
                                                                                                                                    </div>

                                                                                                                                    <div
                                                                                                                                        class="col-lg-2">
                                                                                                                                        <p-dropdown
                                                                                                                                            filter="true"
                                                                                                                                            name="party"
                                                                                                                                            [options]="statusIds"
                                                                                                                                            formControlName="statusId"
                                                                                                                                            optionlabel="label"
                                                                                                                                            placeholder="Select Status">
                                                                                                                                        </p-dropdown>
                                                                                                                                    </div>



                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                class="col-lg-12">
                                                                                                                                <div
                                                                                                                                    class="row">

                                                                                                                                    <div
                                                                                                                                        class="col-lg-3 form-group classInput">
                                                                                                                                        <label
                                                                                                                                            for="exampleInputEmail1">Customer
                                                                                                                                            ID</label>
                                                                                                                                    </div>

                                                                                                                                    <div
                                                                                                                                        class="col-lg-2">
                                                                                                                                        <p-dropdown
                                                                                                                                            filter="true"
                                                                                                                                            name="party"
                                                                                                                                            [options]="partyIds"
                                                                                                                                            formControlName="fromPartyId"
                                                                                                                                            optionlabel="label"
                                                                                                                                            placeholder="Select PartyID">
                                                                                                                                        </p-dropdown>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        class="col-lg-3 form-group classInputA">
                                                                                                                                        <label
                                                                                                                                            for="exampleInputEmail1">Supplier
                                                                                                                                            ID</label>
                                                                                                                                    </div>

                                                                                                                                    <div
                                                                                                                                        class="col-lg-2">
                                                                                                                                        <p-dropdown
                                                                                                                                            filter="true"
                                                                                                                                            name="party"
                                                                                                                                            [options]="partyIds"
                                                                                                                                            formControlName="toPartyId"
                                                                                                                                            optionlabel="label"
                                                                                                                                            placeholder="Select PartyID">
                                                                                                                                        </p-dropdown>
                                                                                                                                    </div>



                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div
                                                                                                                                class="col-lg-12">
                                                                                                                                <div
                                                                                                                                    class="row">

                                                                                                                                    <div
                                                                                                                                        class="col-lg-3 form-group classInput">
                                                                                                                                        <label
                                                                                                                                            for="exampleInputEmail1">Invoice
                                                                                                                                            Date</label>
                                                                                                                                    </div>

                                                                                                                                    <div class="col-lg-2"
                                                                                                                                        style="display: none;">
                                                                                                                                        <p-dropdown
                                                                                                                                            filter="true"
                                                                                                                                            name="party"
                                                                                                                                            [options]="greaterArray"
                                                                                                                                            formControlName="invoiceDateFromSearchType"
                                                                                                                                            optionlabel="label">
                                                                                                                                        </p-dropdown>
                                                                                                                                    </div>
                                                                                                                                    <div class="col-lg-2"
                                                                                                                                        style="    margin-left: -2%;">
                                                                                                                                        <p-calendar
                                                                                                                                            class="ml-2 col-lg-2"
                                                                                                                                            formControlName="invoiceDateFrom"></p-calendar>
                                                                                                                                    </div>

                                                                                                                                    <div class="col-lg-2"
                                                                                                                                        style="display: none;">
                                                                                                                                        <p-dropdown
                                                                                                                                            filter="true"
                                                                                                                                            class="ml-2"
                                                                                                                                            name="party"
                                                                                                                                            formControlName="invoiceDateToSearchType"
                                                                                                                                            [options]="smallerArray"
                                                                                                                                            optionlabel="label">
                                                                                                                                        </p-dropdown>
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        class="col-lg-2">
                                                                                                                                        <p-calendar
                                                                                                                                            class="ml-2 col-lg-2"
                                                                                                                                            formControlName="invoiceDateTo"></p-calendar>
                                                                                                                                    </div>



                                                                                                                                </div>
                                                                                                                            </div>


                                                                                                                        </div>

                                                                                                                        <div style=" margin-left: -29%;"
                                                                                                                            class="col-lg-12 col-12 main-submit-button ">
                                                                                                                            <button
                                                                                                                                _ngcontent-cta-c128=""
                                                                                                                                type="submit"
                                                                                                                                class="btn btn-secondary submit-btn"
                                                                                                                                (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                                                            <button
                                                                                                                                type="button"
                                                                                                                                class="btn btn-danger ml-2"
                                                                                                                                (click)="resetPerInvoice()">Reset</button>
                                                                                                                        </div>


                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>


                                                                                                    <!-- <div class="w3-card-4 classCard">
                                                                                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                                                                           
                                                                                                                                    <button type="button" class="btn btn-outline-secondary active" routerLinkActive="active"
                                                                                                                                        routerLink="/financial/{{invoiceType}}/invoices/create-invoice">Create New Invoice</button>
                                                                                                                            
                                                                                                                        </div>
                                                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                                            <div class="col-lg-12">
                                                                                                                                <div class="row">
                                                                                                            
                                                                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                                                                        <label for="exampleInputEmail1">Invoice ID</label>
                                                                                                                                    </div>
                                                                                                                                    
                                                                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                                                                        <p-dropdown filter="true" name="party"
                                                                                                                                        [options]="opportunityIdArray" optionlabel="label"
                                                                                                                                        formControlName="invoiceIdSearchType">
                                                                                                                                    </p-dropdown>
                                                                                                                                    </div>
                                                                                                                                    <div class="col-lg-2">
                                                                                                                                        <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                                                                        
                                                                                                                                            aria-describedby="emailHelp" placeholder="Invoice Id"
                                                                                                                                                formControlName="invoiceId">
                                                                                                                                    </div>
                                                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                                                        <label for="exampleInputEmail1">Description</label>
                                                                                                                                    </div>
                                                                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                                                                        <p-dropdown filter="true" name="party"
                                                                                                                                        formControlName="descriptionSearchType"
                                                                                                                                        [options]="opportunityIdArray" optionlabel="label">
                                                                                                                                    </p-dropdown>
                                                                                                                                    </div>
                                                                                                                                    <div class="col-lg-2">
                                                                                                                                        <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                                                                        
                                                                                                                                            formControlName="description" aria-describedby="emailHelp"
                                                                                                                                        placeholder="Description">
                                                                                                                                    </div>
                                                                                                            
                                                                                                                                    
                                                                                                            
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div class="col-lg-12">
                                                                                                                                <div class="row">
                                                                                                            
                                                                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                                                                        <label for="exampleInputEmail1">Invoice Type</label>
                                                                                                                                    </div>
                                                                                                                                    
                                                                                                                                    <div class="col-lg-2">
                                                                                                                                        <p-dropdown filter="true" name="party" [options]="invoiceTypes"
                                                                                                                                        
                                                                                                                                        formControlName="invoiceType" optionlabel="label">
                                                                                                                                    </p-dropdown>
                                                                                                                                    </div>
                                                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                                                        <label for="exampleInputEmail1">Status ID</label>
                                                                                                                                    </div>
                                                                                                                                    
                                                                                                                                    <div class="col-lg-2">
                                                                                                                                        <p-dropdown filter="true" name="party" [options]="statusIds"
                                                                                                                                        
                                                                                                                                        formControlName="statusId" optionlabel="label"
                                                                                                                                            placeholder="Select Status">
                                                                                                                                        </p-dropdown>
                                                                                                                                    </div>
                                                                                                            
                                                                                                                                    
                                                                                                            
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div class="col-lg-12">
                                                                                                                                <div class="row">
                                                                                                            
                                                                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                                                                        <label for="exampleInputEmail1">Customer ID</label>
                                                                                                                                    </div>
                                                                                                                                    
                                                                                                                                    <div class="col-lg-2">
                                                                                                                                        <p-dropdown filter="true" name="party" [options]="partyIds"
                                                                                                                                        
                                                                                                                                            formControlName="fromPartyId" optionlabel="label"
                                                                                                                                            placeholder="Select PartyID">
                                                                                                                                        </p-dropdown>
                                                                                                                                    </div>
                                                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                                                        <label for="exampleInputEmail1">Supplier ID</label>
                                                                                                                                    </div>
                                                                                                                                    
                                                                                                                                    <div class="col-lg-2">
                                                                                                                                        <p-dropdown filter="true" name="party" [options]="partyIds"
                                                                                                                                        
                                                                                                                                            formControlName="toPartyId" optionlabel="label"
                                                                                                                                            placeholder="Select PartyID">
                                                                                                                                        </p-dropdown>
                                                                                                                                    </div>
                                                                                                            
                                                                                                                                    
                                                                                                            
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            
                                                                                                                            <div class="col-lg-12">
                                                                                                                                <div class="row">
                                                                                                            
                                                                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                                                                        <label for="exampleInputEmail1">Invoice Date</label>
                                                                                                                                    </div>
                                                                                                                                    
                                                                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                                                                        <p-dropdown filter="true" name="party" [options]="greaterArray"
                                                                                                                                        
                                                                                                                                            formControlName="invoiceDateFromSearchType" optionlabel="label">
                                                                                                                                        </p-dropdown>
                                                                                                                                    </div>
                                                                                                                                    <div class="col-lg-2" style="    margin-left: -2%;">
                                                                                                                                        <p-calendar class="ml-2 col-lg-2"
                                                                                                                                        
                                                                                                                                            formControlName="invoiceDateFrom"></p-calendar>
                                                                                                                                    </div>
                                                                                                                                    
                                                                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                                                                        <p-dropdown filter="true" class="ml-2" name="party"
                                                                                                                                            formControlName="invoiceDateToSearchType" [options]="smallerArray"
                                                                                                                                            optionlabel="label">
                                                                                                                                        </p-dropdown>
                                                                                                                                    </div>
                                                                                                                                    <div class="col-lg-2">
                                                                                                                                        <p-calendar class="ml-2 col-lg-2" 
                                                                                                                                        
                                                                                                                                        formControlName="invoiceDateTo"></p-calendar>
                                                                                                                                    </div>
                                                                                                            
                                                                                                                                    
                                                                                                            
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                    
                                                                                        
                                                                                                                        </div>
                                                                                                                        <div style=" margin-left: -29%;" class="col-lg-12 col-12 main-submit-button "><button _ngcontent-cta-c128=""
                                                                                                                                type="submit" class="btn btn-secondary submit-btn"
                                                                                                                                (click)="searchInvoice()">Find</button>&nbsp;
                                                                                                                            <button type="button" class="btn btn-danger ml-2" (click)="resetPerInvoice()">Reset</button>
                                                                                                                        </div>
                                                                                                                    </div> -->

                                                                                                    <div
                                                                                                        class="w3-card-4 classCard">
                                                                                                        <header
                                                                                                            class="w3-container w3-blue">
                                                                                                            <div class="header-tabs"
                                                                                                                style="background:#F5F7FA!important;">
                                                                                                                <h4
                                                                                                                    class=" common-styling h4Margin">
                                                                                                                    Invoice
                                                                                                                    List
                                                                                                                </h4>

                                                                                                            </div>
                                                                                                        </header>
                                                                                                        <div
                                                                                                            class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                                                            <div
                                                                                                                class="col-lg-12 col-12">
                                                                                                                <div
                                                                                                                    class="form-group">

                                                                                                                    <div
                                                                                                                        class="card own-account-table">
                                                                                                                        <p-table
                                                                                                                            [value]="getPurchaseInvoicesData"
                                                                                                                            [paginator]="true"
                                                                                                                            [rows]="rows"
                                                                                                                            scrollWidth="100%"
                                                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                                            [responsive]="true"
                                                                                                                            [totalRecords]="total"
                                                                                                                            [scrollable]="true"
                                                                                                                            styleClass="p-datatable-customers"
                                                                                                                            (sortFunction)="customSort($event)"
                                                                                                                            [customSort]="true">
                                                                                                                            <ng-template
                                                                                                                                pTemplate="header">
                                                                                                                                <tr
                                                                                                                                    style="background: #0d3769;">
                                                                                                                                    <th pSortableColumn="code"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div class="checkbox-align"
                                                                                                                                            style="color: white;">
                                                                                                                                            <p-checkbox
                                                                                                                                                _ngcontent-egj-c329=""
                                                                                                                                                styleclass="custom-checkbox"
                                                                                                                                                binary="true"
                                                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                <div
                                                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                    <div
                                                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                                                        <input
                                                                                                                                                            type="checkbox"
                                                                                                                                                            name="undefined"
                                                                                                                                                            value="undefined">
                                                                                                                                                    </div>
                                                                                                                                                    <div role="checkbox"
                                                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                        <span
                                                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                    </div>
                                                                                                                                                </div>

                                                                                                                                            </p-checkbox>
                                                                                                                                            Invoice
                                                                                                                                            ID
                                                                                                                                            <p-sortIcon
                                                                                                                                                field="code">
                                                                                                                                            </p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                                                                        pSortableColumn="name">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Invoice
                                                                                                                                            Type<p-sortIcon
                                                                                                                                                field="name">
                                                                                                                                            </p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                                                                        pSortableColumn="category">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Invoice
                                                                                                                                            Date
                                                                                                                                            <p-sortIcon
                                                                                                                                                field="category"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                                                                        pSortableColumn="quantity">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Status
                                                                                                                                            <p-sortIcon
                                                                                                                                                field="quantity"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="price"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Description
                                                                                                                                            <p-sortIcon
                                                                                                                                                field="price"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="price"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Customer
                                                                                                                                            Id<p-sortIcon
                                                                                                                                                field="price"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="price"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Supplier
                                                                                                                                            Id
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            style="visibility:hidden;"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>

                                                                                                                                    <th pSortableColumn="price"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Total
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            style="visibility:hidden;"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="price"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Outstanding
                                                                                                                                            amount
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            style="visibility:hidden;"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="price"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Created
                                                                                                                                            Date
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            style="visibility:hidden;"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="price"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Action
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            style="visibility:hidden;"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                </tr>
                                                                                                                            </ng-template>
                                                                                                                            <ng-template
                                                                                                                                pTemplate="body"
                                                                                                                                let-product>
                                                                                                                                <tr>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'200px'}">
                                                                                                                                        <div
                                                                                                                                            class="checkbox-align">
                                                                                                                                            <p-checkbox
                                                                                                                                                _ngcontent-egj-c329=""
                                                                                                                                                styleclass="custom-checkbox"
                                                                                                                                                binary="true"
                                                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                <div
                                                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                    <div
                                                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                                                        <input
                                                                                                                                                            type="checkbox"
                                                                                                                                                            name="undefined"
                                                                                                                                                            value="undefined">
                                                                                                                                                    </div>
                                                                                                                                                    <div role="checkbox"
                                                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                        <span
                                                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                    </div>
                                                                                                                                                </div>

                                                                                                                                            </p-checkbox>
                                                                                                                                            <span
                                                                                                                                                routerLink="/financial/{{invoiceType}}/invoices/detailed-summary"
                                                                                                                                                [queryParams]="{invoiceId:product.invoiceId}"
                                                                                                                                                class="account-button">{{product.invoice.invoiceId}}</span>
                                                                                                                                        </div>
                                                                                                                                    </td>


                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        {{product.invoice.invoiceTypeId}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        {{product.invoice.invoiceDate
                                                                                                                                        |date
                                                                                                                                        :'yyyy-MM-dd'}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        {{product.invoice.statusId
                                                                                                                                        }}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        {{product.invoice.description}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        {{product.invoice.partyIdFrom}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        {{product.invoice.partyId}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        {{product.invoiceItem[0].amount
                                                                                                                                        |currency
                                                                                                                                        :'USD'}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <!-- {{product.outstanding |currency :'USD'}} -->
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        {{product.invoice.createdStamp
                                                                                                                                        |date:'yyyy-MM-d
                                                                                                                                        hh:mm:ss'}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div>
                                                                                                                                            <svg class="hover"
                                                                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                                                                width="16"
                                                                                                                                                *ngIf="product.statusId==='INVOICE_IN_PROCESS'"
                                                                                                                                                routerLink="/financial/{{invoiceType}}/invoices/create-invoice"
                                                                                                                                                [queryParams]="{invoiceId:product.invoiceId}"
                                                                                                                                                height="16"
                                                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                                                <g
                                                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                                                    <g
                                                                                                                                                        transform="translate(0 1.52)">
                                                                                                                                                        <path
                                                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                                                    </g>
                                                                                                                                                    <g
                                                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                                                        <g
                                                                                                                                                            transform="translate(0 0)">
                                                                                                                                                            <path
                                                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                                                        </g>
                                                                                                                                                    </g>
                                                                                                                                                </g>
                                                                                                                                            </svg>

                                                                                                                                        </div>
                                                                                                                                    </td>

                                                                                                                                </tr>

                                                                                                                            </ng-template>
                                                                                                                            <ng-template
                                                                                                                                pTemplate="emptymessage"
                                                                                                                                let-columns>
                                                                                                                                <tr>
                                                                                                                                    <td>
                                                                                                                                        <span
                                                                                                                                            class="text-center">
                                                                                                                                            <h4
                                                                                                                                                class="ml-2">
                                                                                                                                                No
                                                                                                                                                Record
                                                                                                                                                Found
                                                                                                                                            </h4>
                                                                                                                                        </span>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </ng-template>
                                                                                                                        </p-table>

                                                                                                                        <p
                                                                                                                            class="paginate_data">
                                                                                                                            View
                                                                                                                            per
                                                                                                                            page
                                                                                                                        </p>

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </form>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>

                                                                                <div
                                                                                    *ngIf="this.agreementTypeValue=='receiveable'">

                                                                                    <div class="create-new-leade mt-2">
                                                                                        <div class="container-fluid">
                                                                                            <div class="row">
                                                                                                <form class="w-100"
                                                                                                    [formGroup]="searchInvoiceForm">
                                                                                                    <div
                                                                                                        class="w3-card-4 classCard">
                                                                                                        <div
                                                                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">

                                                                                                            <button
                                                                                                                type="button"
                                                                                                                class="btn btn-outline-secondary active"
                                                                                                                routerLinkActive="active"
                                                                                                                routerLink="/financial/{{invoiceType}}/invoices/create-invoice">Create
                                                                                                                Invoice</button>

                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                            <div
                                                                                                                class="col-lg-12">
                                                                                                                <div
                                                                                                                    class="row">

                                                                                                                    <div
                                                                                                                        class="col-lg-3 form-group classInput">
                                                                                                                        <label
                                                                                                                            for="exampleInputEmail1">Invoice
                                                                                                                            ID</label>
                                                                                                                    </div>

                                                                                                                    <div class="col-lg-2"
                                                                                                                        style="display: none;">
                                                                                                                        <p-dropdown
                                                                                                                            filter="true"
                                                                                                                            name="party"
                                                                                                                            [options]="opportunityIdArray"
                                                                                                                            optionlabel="label"
                                                                                                                            formControlName="invoiceIdSearchType">
                                                                                                                        </p-dropdown>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="col-lg-2">
                                                                                                                        <input
                                                                                                                            type="email"
                                                                                                                            id="exampleInputEmail1"
                                                                                                                            class="form-control"
                                                                                                                            aria-describedby="emailHelp"
                                                                                                                            placeholder="Invoice Id"
                                                                                                                            formControlName="invoiceId">
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="col-lg-3 form-group classInputA">
                                                                                                                        <label
                                                                                                                            for="exampleInputEmail1">Description</label>
                                                                                                                    </div>
                                                                                                                    <div class="col-lg-2"
                                                                                                                        style="display: none;">
                                                                                                                        <p-dropdown
                                                                                                                            filter="true"
                                                                                                                            name="party"
                                                                                                                            formControlName="descriptionSearchType"
                                                                                                                            [options]="opportunityIdArray"
                                                                                                                            optionlabel="label">
                                                                                                                        </p-dropdown>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="col-lg-2">
                                                                                                                        <input
                                                                                                                            type="email"
                                                                                                                            id="exampleInputEmail1"
                                                                                                                            class="form-control"
                                                                                                                            formControlName="description"
                                                                                                                            aria-describedby="emailHelp"
                                                                                                                            placeholder="Description">
                                                                                                                    </div>



                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="col-lg-12">
                                                                                                                <div
                                                                                                                    class="row">

                                                                                                                    <div
                                                                                                                        class="col-lg-3 form-group classInput">
                                                                                                                        <label
                                                                                                                            for="exampleInputEmail1">Invoice
                                                                                                                            Type</label>
                                                                                                                    </div>

                                                                                                                    <div
                                                                                                                        class="col-lg-2">
                                                                                                                        <p-dropdown
                                                                                                                            filter="true"
                                                                                                                            name="party"
                                                                                                                            [options]="invoiceTypes"
                                                                                                                            formControlName="invoiceType"
                                                                                                                            optionlabel="label">
                                                                                                                        </p-dropdown>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="col-lg-3 form-group classInputA">
                                                                                                                        <label
                                                                                                                            for="exampleInputEmail1">Status
                                                                                                                            ID</label>
                                                                                                                    </div>

                                                                                                                    <div
                                                                                                                        class="col-lg-2">
                                                                                                                        <p-dropdown
                                                                                                                            filter="true"
                                                                                                                            name="party"
                                                                                                                            [options]="statusIds"
                                                                                                                            formControlName="statusId"
                                                                                                                            optionlabel="label"
                                                                                                                            placeholder="Select Status">
                                                                                                                        </p-dropdown>
                                                                                                                    </div>



                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="col-lg-12">
                                                                                                                <div
                                                                                                                    class="row">

                                                                                                                    <div
                                                                                                                        class="col-lg-3 form-group classInput">
                                                                                                                        <label
                                                                                                                            for="exampleInputEmail1">Supplier
                                                                                                                            ID</label>
                                                                                                                    </div>

                                                                                                                    <div
                                                                                                                        class="col-lg-2">
                                                                                                                        <p-dropdown
                                                                                                                            filter="true"
                                                                                                                            name="party"
                                                                                                                            [options]="partyIds"
                                                                                                                            formControlName="fromPartyId"
                                                                                                                            optionlabel="label"
                                                                                                                            placeholder="Select PartyID">
                                                                                                                        </p-dropdown>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="col-lg-3 form-group classInputA">
                                                                                                                        <label
                                                                                                                            for="exampleInputEmail1">Customer
                                                                                                                            ID</label>
                                                                                                                    </div>

                                                                                                                    <div
                                                                                                                        class="col-lg-2">
                                                                                                                        <p-dropdown
                                                                                                                            filter="true"
                                                                                                                            name="party"
                                                                                                                            [options]="partyIds"
                                                                                                                            formControlName="toPartyId"
                                                                                                                            optionlabel="label"
                                                                                                                            placeholder="Select PartyID">
                                                                                                                        </p-dropdown>
                                                                                                                    </div>



                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="col-lg-12">
                                                                                                                <div
                                                                                                                    class="row">

                                                                                                                    <div
                                                                                                                        class="col-lg-3 form-group classInput">
                                                                                                                        <label
                                                                                                                            for="exampleInputEmail1">Invoice
                                                                                                                            Date</label>
                                                                                                                    </div>

                                                                                                                    <div class="col-lg-2"
                                                                                                                        style="display: none;">
                                                                                                                        <p-dropdown
                                                                                                                            filter="true"
                                                                                                                            name="party"
                                                                                                                            [options]="greaterArray"
                                                                                                                            formControlName="invoiceDateFromSearchType"
                                                                                                                            optionlabel="label">
                                                                                                                        </p-dropdown>
                                                                                                                    </div>
                                                                                                                    <div class="col-lg-2"
                                                                                                                        style="    margin-left: -2%;">
                                                                                                                        <p-calendar
                                                                                                                            class="ml-2 col-lg-2"
                                                                                                                            formControlName="invoiceDateFrom"></p-calendar>
                                                                                                                    </div>

                                                                                                                    <div class="col-lg-2"
                                                                                                                        style="display: none;">
                                                                                                                        <p-dropdown
                                                                                                                            filter="true"
                                                                                                                            class="ml-2"
                                                                                                                            name="party"
                                                                                                                            formControlName="invoiceDateToSearchType"
                                                                                                                            [options]="smallerArray"
                                                                                                                            optionlabel="label">
                                                                                                                        </p-dropdown>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="col-lg-2">
                                                                                                                        <p-calendar
                                                                                                                            class="ml-2 col-lg-2"
                                                                                                                            formControlName="invoiceDateTo"></p-calendar>
                                                                                                                    </div>



                                                                                                                </div>
                                                                                                            </div>


                                                                                                        </div>
                                                                                                        <div style=" margin-left: -29%;"
                                                                                                            class="col-lg-12 col-12 main-submit-button ">
                                                                                                            <button
                                                                                                                _ngcontent-cta-c128=""
                                                                                                                type="submit"
                                                                                                                class="btn btn-secondary submit-btn"
                                                                                                                (click)="searchInvoice()">Find</button>&nbsp;
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                class="btn btn-danger ml-2"
                                                                                                                (click)="reset()">Reset</button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="w3-card-4 classCard">
                                                                                                        <header
                                                                                                            class="w3-container w3-blue">
                                                                                                            <div class="header-tabs"
                                                                                                                style="background:#F5F7FA!important;">
                                                                                                                <h4
                                                                                                                    class=" common-styling h4Margin">
                                                                                                                    Invoice
                                                                                                                    List
                                                                                                                </h4>

                                                                                                            </div>
                                                                                                        </header>
                                                                                                        <div
                                                                                                            class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                                                            <div
                                                                                                                class="col-lg-12 col-12">
                                                                                                                <div
                                                                                                                    class="form-group">

                                                                                                                    <div
                                                                                                                        class="card own-account-table">
                                                                                                                        <p-table
                                                                                                                            [value]="invoices"
                                                                                                                            [paginator]="true"
                                                                                                                            [rows]="rows"
                                                                                                                            scrollWidth="100%"
                                                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                                            [responsive]="true"
                                                                                                                            [totalRecords]="total"
                                                                                                                            [scrollable]="true"
                                                                                                                            styleClass="p-datatable-customers"
                                                                                                                            (sortFunction)="customSort($event)"
                                                                                                                            [customSort]="true">
                                                                                                                            <ng-template
                                                                                                                                pTemplate="header">
                                                                                                                                <tr
                                                                                                                                    style="background: #0d3769;">
                                                                                                                                    <th pSortableColumn="code"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div class="checkbox-align"
                                                                                                                                            style="color: white;">
                                                                                                                                            <p-checkbox
                                                                                                                                                _ngcontent-egj-c329=""
                                                                                                                                                styleclass="custom-checkbox"
                                                                                                                                                binary="true"
                                                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                <div
                                                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                    <div
                                                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                                                        <input
                                                                                                                                                            type="checkbox"
                                                                                                                                                            name="undefined"
                                                                                                                                                            value="undefined">
                                                                                                                                                    </div>
                                                                                                                                                    <div role="checkbox"
                                                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                        <span
                                                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                    </div>
                                                                                                                                                </div>

                                                                                                                                            </p-checkbox>
                                                                                                                                            Invoice
                                                                                                                                            ID
                                                                                                                                            <p-sortIcon
                                                                                                                                                field="code">
                                                                                                                                            </p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                                                                        pSortableColumn="name">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Invoice
                                                                                                                                            Type<p-sortIcon
                                                                                                                                                field="name">
                                                                                                                                            </p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                                                                        pSortableColumn="category">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Invoice
                                                                                                                                            Date
                                                                                                                                            <p-sortIcon
                                                                                                                                                field="category"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                                                                        pSortableColumn="quantity">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Status
                                                                                                                                            <p-sortIcon
                                                                                                                                                field="quantity"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="price"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Description
                                                                                                                                            <p-sortIcon
                                                                                                                                                field="price"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="price"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Supplier
                                                                                                                                            Id<p-sortIcon
                                                                                                                                                field="price"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="price"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Customer
                                                                                                                                            Id
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            style="visibility:hidden;"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="price"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Total
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            style="visibility:hidden;"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="price"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Outstanding
                                                                                                                                            amount
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            style="visibility:hidden;"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="price"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Created
                                                                                                                                            Date
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            style="visibility:hidden;"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="price"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Action
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            style="visibility:hidden;"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                </tr>

                                                                                                                                <tr
                                                                                                                                    style="background: #FFFFFF;">
                                                                                                                                    <th pSortableColumn="code"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div class="checkbox-align"
                                                                                                                                            style="color: white;height: 25px;">

                                                                                                                                            <span>

                                                                                                                                            </span>
                                                                                                                                        </div>

                                                                                                                                    </th>
                                                                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                                                                        pSortableColumn="name">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                        </div>

                                                                                                                                    </th>
                                                                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                                                                        pSortableColumn="category">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">


                                                                                                                                        </div>

                                                                                                                                    </th>
                                                                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                                                                        pSortableColumn="quantity">
                                                                                                                                        <form
                                                                                                                                            [formGroup]="filterInvoiceStatusForm">
                                                                                                                                            <div class="row"
                                                                                                                                                style="color: white;">
                                                                                                                                                <div class="col-md-12"
                                                                                                                                                    style="position: absolute;">
                                                                                                                                                    <p-dropdown
                                                                                                                                                        style="position: absolute;"
                                                                                                                                                        filter="true"
                                                                                                                                                        name="party"
                                                                                                                                                        [options]="invoiceStatusArray"
                                                                                                                                                        formControlName="statusId"
                                                                                                                                                        optionlabel="label"
                                                                                                                                                        (onChange)="invoiceFilter($event)">
                                                                                                                                                    </p-dropdown>
                                                                                                                                                </div>


                                                                                                                                            </div>
                                                                                                                                        </form>

                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="price"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                        </div>

                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="price"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                        </div>

                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="price"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                        </div>

                                                                                                                                    </th>

                                                                                                                                    <th pSortableColumn="price"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                        </div>

                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="price"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                        </div>

                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="price"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                        </div>

                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="price"
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                        </div>

                                                                                                                                    </th>
                                                                                                                                </tr>
                                                                                                                            </ng-template>
                                                                                                                            <ng-template
                                                                                                                                pTemplate="body"
                                                                                                                                let-product>
                                                                                                                                <tr>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'200px'}">
                                                                                                                                        <div
                                                                                                                                            class="checkbox-align">
                                                                                                                                            <p-checkbox
                                                                                                                                                _ngcontent-egj-c329=""
                                                                                                                                                styleclass="custom-checkbox"
                                                                                                                                                binary="true"
                                                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                <div
                                                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                    <div
                                                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                                                        <input
                                                                                                                                                            type="checkbox"
                                                                                                                                                            name="undefined"
                                                                                                                                                            value="undefined">
                                                                                                                                                    </div>
                                                                                                                                                    <div role="checkbox"
                                                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                        <span
                                                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                    </div>
                                                                                                                                                </div>

                                                                                                                                            </p-checkbox>
                                                                                                                                            <span
                                                                                                                                                routerLink="/financial/{{invoiceType}}/invoices/detailed-summary"
                                                                                                                                                [queryParams]="{invoiceId:product.invoiceId}"
                                                                                                                                                class="account-button">{{product.invoiceId}}</span>
                                                                                                                                        </div>
                                                                                                                                    </td>


                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        {{product.invoiceTypeId}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        {{product.invoiceDate
                                                                                                                                        |date
                                                                                                                                        :'yyyy-MM-d
                                                                                                                                        hh:mm:ss'}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        {{product.status
                                                                                                                                        }}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        {{product.description}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        {{product.partyFrom}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        {{product.partyTo}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        {{product.total
                                                                                                                                        |currency
                                                                                                                                        :'USD'}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        {{product.outstanding
                                                                                                                                        |currency
                                                                                                                                        :'USD'}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        {{product.createdStamp
                                                                                                                                        |date:'yyyy-MM-d
                                                                                                                                        hh:mm:ss'}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div>
                                                                                                                                            <svg class="hover"
                                                                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                                                                width="16"
                                                                                                                                                *ngIf="product.statusId==='INVOICE_IN_PROCESS'"
                                                                                                                                                routerLink="/financial/{{invoiceType}}/invoices/create-invoice"
                                                                                                                                                [queryParams]="{invoiceId:product.invoiceId}"
                                                                                                                                                height="16"
                                                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                                                <g
                                                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                                                    <g
                                                                                                                                                        transform="translate(0 1.52)">
                                                                                                                                                        <path
                                                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                                                    </g>
                                                                                                                                                    <g
                                                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                                                        <g
                                                                                                                                                            transform="translate(0 0)">
                                                                                                                                                            <path
                                                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                                                        </g>
                                                                                                                                                    </g>
                                                                                                                                                </g>
                                                                                                                                            </svg>

                                                                                                                                        </div>
                                                                                                                                    </td>

                                                                                                                                </tr>

                                                                                                                            </ng-template>
                                                                                                                            <ng-template
                                                                                                                                pTemplate="emptymessage"
                                                                                                                                let-columns>
                                                                                                                                <tr>
                                                                                                                                    <td>
                                                                                                                                        <span
                                                                                                                                            class="text-center">
                                                                                                                                            <h4
                                                                                                                                                class="ml-2">
                                                                                                                                                No
                                                                                                                                                Record
                                                                                                                                                Found
                                                                                                                                            </h4>
                                                                                                                                        </span>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </ng-template>
                                                                                                                        </p-table>

                                                                                                                        <p
                                                                                                                            class="paginate_data">
                                                                                                                            View
                                                                                                                            per
                                                                                                                            page
                                                                                                                        </p>

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </form>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>



                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategoriess==4">
                                                    <div class="w3-card-4 classCard">
                                                        <header class="w3-container w3-blue">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling h4Margin">
                                                                    Payments


                                                                </h4>
                                                                <button type="button"
                                                                    class="btn btn-outline-secondary active"
                                                                    routerLinkActive="active"
                                                                    routerLink="/accounting/payments/create-payments">Create
                                                                    New Invoices</button>
                                                                <button type="button" class="btn btn-outline-secondary"
                                                                    routerLinkActive="active"
                                                                    routerLink="/accounting/payments/sale-invoices">Sale
                                                                    Invoices</button>
                                                                <button type="button" class="btn btn-outline-secondary"
                                                                    routerLinkActive="active"
                                                                    routerLink="/accounting/payments/purchase-invoices">Purchase
                                                                    Invoices</button>
                                                            </div>
                                                        </header>


                                                        <div class=" bg-white color-grey">

                                                            <div class="create-new-leade mt-2">
                                                                <div class="container-fluid">
                                                                    <div class="row">
                                                                        <form class="w-100">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Payment
                                                                                            ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Comments</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Payment
                                                                                            Type</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Status</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">From
                                                                                            Party ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">To
                                                                                            Party ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Amount</label>
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Reference
                                                                                            No</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Payment
                                                                                            Gateway Response ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>

                                                                        </form>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="w3-card-4 classCard">
                                                        <header class="w3-container w3-blue">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling h4Margin">
                                                                    Payments List
                                                                </h4>
                                                            </div>
                                                        </header>

                                                        <div
                                                            class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">Payment
                                                                        List</label>

                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="products3" [paginator]="true"
                                                                            [rows]="rows" scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>
                                                                                            Payment ID
                                                                                            <p-sortIcon
                                                                                                field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        Payment Type
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="category">
                                                                                        Status
                                                                                        <p-sortIcon
                                                                                            field="category"></p-sortIcon>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="quantity">
                                                                                        Comments<p-sortIcon
                                                                                            field="quantity"></p-sortIcon>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price">
                                                                                        From Party
                                                                                        <p-sortIcon
                                                                                            field="price"></p-sortIcon>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price">
                                                                                        To
                                                                                        Party<p-sortIcon
                                                                                            field="price"></p-sortIcon>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price">
                                                                                        Action
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td routerLink="/accounting/payments/not-paid"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>
                                                                                            {{product.code}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{product.name}}
                                                                                    </td>
                                                                                    <td>{{product.category}}
                                                                                    </td>
                                                                                    <td>{{product.quantity}}
                                                                                    </td>
                                                                                    <td>{{product.price
                                                                                        | currency:
                                                                                        'USD'}}</td>
                                                                                    <td>{{product.category}}
                                                                                    </td>
                                                                                    <td>
                                                                                        <div>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                width="16" height="16"
                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                <g
                                                                                                    transform="translate(0 -0.004)">
                                                                                                    <g
                                                                                                        transform="translate(0 1.52)">
                                                                                                        <path
                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                            transform="translate(0 -34.137)" />
                                                                                                    </g>
                                                                                                    <g
                                                                                                        transform="translate(4.548 0.004)">
                                                                                                        <g
                                                                                                            transform="translate(0 0)">
                                                                                                            <path
                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </svg>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                class="ml-20" width="16"
                                                                                                height="16"
                                                                                                viewBox="0 0 26 26">
                                                                                                <defs>
                                                                                                    <style>
                                                                                                        .a {
                                                                                                            fill: #f44336;
                                                                                                        }

                                                                                                        .b {
                                                                                                            fill: #fafafa;
                                                                                                            margin-left: 20px;
                                                                                                        }
                                                                                                    </style>
                                                                                                </defs>
                                                                                                <path
                                                                                                    class="a fill-color"
                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                <path class="b"
                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                            </svg>
                                                                                        </div>
                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <!-- <p class="paginate_data">
                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategoriess==5">
                                                    <div class="w3-card-4 classCard">
                                                        <header class="w3-container w3-blue">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling h4Margin">
                                                                    Payment group
                                                                </h4>
                                                                <button type="button"
                                                                    class="btn btn-outline-secondary active"
                                                                    routerLinkActive="active"
                                                                    routerLink="/accounting/payment-group/create-payment-group">Create
                                                                    Payment Group</button>
                                                                <button type="button"
                                                                    class="btn btn-outline-secondary text-white bg-brown">Import</button>
                                                            </div>
                                                        </header>

                                                        <div class=" bg-white color-grey">


                                                            <div class="create-new-leade mt-2">
                                                                <div class="container-fluid">
                                                                    <div class="row">
                                                                        <form class="w-100">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Payment
                                                                                            Group ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Payment
                                                                                            Group Type ID</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 col-12">
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="exampleInputEmail1">Payment
                                                                                            Group Name</label>
                                                                                        <div class="">
                                                                                            <p-dropdown filter="true"
                                                                                                optionLabel="label">
                                                                                                <ng-template let-item
                                                                                                    pTemplate="selectedItem">
                                                                                                    <span
                                                                                                        class="label-info"
                                                                                                        style="margin-left: .5em">{{item.label}}</span>
                                                                                                </ng-template>
                                                                                                <ng-template let-car
                                                                                                    pTemplate="item">
                                                                                                    <div class="ui-helper-clearfix"
                                                                                                        style="position: relative;height: 25px;">
                                                                                                        <div
                                                                                                            style="font-size:14px;margin-top:4px">
                                                                                                            {{car.label}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn">Find</button>
                                                                            </div>

                                                                        </form>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCard">
                                                        <header class="w3-container w3-blue">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling h4Margin">
                                                                    Payment group
                                                                </h4>
                                                            </div>
                                                        </header>

                                                        <div
                                                            class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <label for="exampleInputEmail1">
                                                                        Payment Group List</label>

                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="products3" [paginator]="true"
                                                                            [rows]="rows" scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>
                                                                                            Payment
                                                                                            Group ID
                                                                                            <p-sortIcon
                                                                                                field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'150px'}"
                                                                                        pSortableColumn="name">
                                                                                        Payment Group
                                                                                        Type <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'150px'}"
                                                                                        pSortableColumn="category">
                                                                                        Payment Group
                                                                                        Name<p-sortIcon
                                                                                            field="category"></p-sortIcon>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="quantity">
                                                                                        Amount<p-sortIcon
                                                                                            field="quantity"></p-sortIcon>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price">
                                                                                        Paid Amount
                                                                                        <p-sortIcon
                                                                                            field="price"></p-sortIcon>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        Outstanding
                                                                                        Amount<p-sortIcon
                                                                                            field="price"></p-sortIcon>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price">
                                                                                        Action
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td routerLink="/accounting/payment-group/payment-group-details"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>
                                                                                            {{product.code}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                        {{product.name}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                        {{product.category}}
                                                                                    </td>
                                                                                    <td>{{product.quantity}}
                                                                                    </td>
                                                                                    <td>{{product.price
                                                                                        | currency:
                                                                                        'USD'}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.category}}
                                                                                    </td>
                                                                                    <td>
                                                                                        <div>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                width="16" height="16"
                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                <g
                                                                                                    transform="translate(0 -0.004)">
                                                                                                    <g
                                                                                                        transform="translate(0 1.52)">
                                                                                                        <path
                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                            transform="translate(0 -34.137)" />
                                                                                                    </g>
                                                                                                    <g
                                                                                                        transform="translate(4.548 0.004)">
                                                                                                        <g
                                                                                                            transform="translate(0 0)">
                                                                                                            <path
                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </g>
                                                                                            </svg>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                class="ml-20" width="16"
                                                                                                height="16"
                                                                                                viewBox="0 0 26 26">
                                                                                                <defs>
                                                                                                    <style>
                                                                                                        .a {
                                                                                                            fill: #f44336;
                                                                                                        }

                                                                                                        .b {
                                                                                                            fill: #fafafa;
                                                                                                            margin-left: 20px;
                                                                                                        }
                                                                                                    </style>
                                                                                                </defs>
                                                                                                <path
                                                                                                    class="a fill-color"
                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                <path class="b"
                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                            </svg>
                                                                                        </div>
                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>

                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategoriess==6">
                                                    <div class=" bg-white color-grey">

                                                        <div class="create-new-leade mt-2">
                                                            <div class="">
                                                                <form class="w-100"
                                                                    [formGroup]="searchBillingAccountForm">
                                                                    <div class="">

                                                                        <div class="w3-card-4 w-100">
                                                                            <div class="accordion classCard"
                                                                                id="myAccordion">
                                                                                <div class="accordion-item">
                                                                                    <h2 class="accordion-header"
                                                                                        id="headingOne">
                                                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                                                            style="background:#F5F7FA!important; padding: 4px !important;">
                                                                                            <span
                                                                                                style="margin-left: 4px!important;">
                                                                                                Search Options</span>

                                                                                            <button data-toggle="modal"
                                                                                                data-target="#exampleModalCenter"
                                                                                                type="button"
                                                                                                class="btn btn-outline-secondary ml-2">Create
                                                                                                New Billing Account
                                                                                            </button>

                                                                                            <button type="button"
                                                                                                class="accordion-button  collapsed"
                                                                                                data-bs-toggle="collapse"
                                                                                                data-bs-target="#collapseOne"
                                                                                                style="margin-left: 63%!important; padding-top: 11px !important;    padding-bottom: 11px !important;"></button>

                                                                                        </div>

                                                                                    </h2>
                                                                                    <div id="collapseOne"
                                                                                        class="accordion-collapse collapse"
                                                                                        data-bs-parent="#myAccordion">
                                                                                        <div class="card-body">

                                                                                            <div
                                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">

                                                                                                        <div
                                                                                                            class="col-lg-3 form-group classInput">
                                                                                                            <label
                                                                                                                for="exampleInputEmail1">
                                                                                                                Billing
                                                                                                                Account
                                                                                                                ID</label>
                                                                                                        </div>

                                                                                                        <div class="col-lg-2"
                                                                                                            style="display: none;">
                                                                                                            <p-dropdown
                                                                                                                filter="true"
                                                                                                                [options]="opportunityIdArray"
                                                                                                                optionlabel="label"
                                                                                                                formControlName="billingAccountIdSearchType">
                                                                                                            </p-dropdown>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="col-lg-2">
                                                                                                            <input
                                                                                                                type="email"
                                                                                                                class="form-control"
                                                                                                                id="exampleInputEmail1"
                                                                                                                aria-describedby="emailHelp"
                                                                                                                placeholder="Billing Account Id"
                                                                                                                formControlName="billingAccountId">
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="col-lg-3 form-group classInputA">
                                                                                                            <label
                                                                                                                for="exampleInputEmail1">Description</label>
                                                                                                        </div>

                                                                                                        <div class="col-lg-2"
                                                                                                            style="display: none;">
                                                                                                            <p-dropdown
                                                                                                                filter="true"
                                                                                                                [options]="opportunityIdArray"
                                                                                                                optionlabel="label"
                                                                                                                formControlName="descriptionSearchType">
                                                                                                            </p-dropdown>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="col-lg-2">
                                                                                                            <input
                                                                                                                type="email"
                                                                                                                class="form-control"
                                                                                                                id="exampleInputEmail1"
                                                                                                                aria-describedby="emailHelp"
                                                                                                                formControlName="description"
                                                                                                                placeholder="Description">
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-lg-12">
                                                                                                    <div class="row">

                                                                                                        <div
                                                                                                            class="col-lg-3 form-group classInput">
                                                                                                            <label
                                                                                                                for="accountLimit">Account
                                                                                                                Limit</label>
                                                                                                        </div>


                                                                                                        <div
                                                                                                            class="col-lg-2">
                                                                                                            <input
                                                                                                                type="text"
                                                                                                                class="form-control"
                                                                                                                formControlName="accountLimit"
                                                                                                                placeholder="Account Limit">
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div class="col-lg-12 col-12 main-submit-button "
                                                                                                style="margin-left: -32%;">
                                                                                                <button
                                                                                                    _ngcontent-cta-c128=""
                                                                                                    type="submit"
                                                                                                    class="btn btn-secondary submit-btn"
                                                                                                    (click)="getBillingAccounts()">Find</button>&nbsp;
                                                                                                <button type="button"
                                                                                                    class="btn btn-danger"
                                                                                                    (click)="resetForm()">Reset</button>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>



                                                                        <div class="w3-card-4 classCardB">
                                                                            <header class="w3-container w3-blue">
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling hmargin">
                                                                                        Billing Account List
                                                                                    </h4>

                                                                                </div>
                                                                            </header>
                                                                            <div
                                                                                class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                                <div class="col-lg-12 col-12">
                                                                                    <div class="form-group">

                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="billingAccounts"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th
                                                                                                            pSortableColumn="code">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>
                                                                                                                Billing
                                                                                                                Account
                                                                                                                Id
                                                                                                                <p-sortIcon
                                                                                                                    field="code"></p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Limit
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Description
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th
                                                                                                            pSortableColumn="price">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                From
                                                                                                                Date
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th
                                                                                                            pSortableColumn="price">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Through
                                                                                                                Date
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th
                                                                                                            pSortableColumn="price">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Created
                                                                                                                Date
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>

                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>
                                                                                                                <span
                                                                                                                    class="account-button"
                                                                                                                    routerLink="/financial/payable/billing-account/summary-billing-account"
                                                                                                                    [queryParams]="{billingAccountId:product.billingAccountId}">{{product.billingAccountId}}</span>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td>{{product.accountLimit}}
                                                                                                        </td>
                                                                                                        <td>{{product.description}}
                                                                                                        </td>
                                                                                                        <td>{{product.fromDate
                                                                                                            |
                                                                                                            date:'yyyy-MM-d
                                                                                                            hh:mm:ss'}}
                                                                                                        </td>
                                                                                                        <td>{{product.thruDate
                                                                                                            |
                                                                                                            date:'yyyy-MM-d
                                                                                                            hh:mm:ss'}}
                                                                                                        </td>
                                                                                                        <td>{{product.createdStamp
                                                                                                            |
                                                                                                            date:'yyyy-MM-d
                                                                                                            hh:mm:ss'}}
                                                                                                        </td>

                                                                                                    </tr>
                                                                                                    <ng-template
                                                                                                        pTemplate="emptymessage"
                                                                                                        let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span
                                                                                                                    class="text-center">
                                                                                                                    <h4
                                                                                                                        class="ml-2">
                                                                                                                        No
                                                                                                                        Record
                                                                                                                        Found
                                                                                                                    </h4>
                                                                                                                </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategoriess==7">


                                                    <div class="panel-collapse">

                                                        <form class="w-100" [formGroup]="CustomerAgreements">
                                                            <div class="w3-card-4  w-100">
                                                                <div class="accordion classCard" id="myAccordion">
                                                                    <div class="accordion-item">
                                                                        <h2 class="accordion-header" id="headingOne">
                                                                            <div
                                                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion">
                                                                                <button type="button"
                                                                                    class="btn btn-outline-secondary"
                                                                                    data-toggle="modal"
                                                                                    data-target="#exampleModalCenter"
                                                                                    style=" margin-bottom: 4px!important;  margin-top: 4px;">Create
                                                                                    New Agreement</button>

                                                                                <button type="button"
                                                                                    class="accordion-button  collapsed"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target="#collapseOne"
                                                                                    style="margin-left: 76%!important; padding-top: 11px !important;padding-bottom: 11px !important;">
                                                                                </button>

                                                                            </div>

                                                                        </h2>
                                                                        <div id="collapseOne"
                                                                            class="accordion-collapse collapse"
                                                                            data-bs-parent="#myAccordion">
                                                                            <div class="card-body">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Agreement
                                                                                                    ID</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2"
                                                                                                style="display: none;">
                                                                                                <p-dropdown
                                                                                                    [options]="opportunityIdArray"
                                                                                                    filter="true"
                                                                                                    formControlName="agreementIdSearchType">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <input type="text"
                                                                                                    name="partyId"
                                                                                                    formControlName="agreementId"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Agreement ID">
                                                                                            </div>

                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Product
                                                                                                    ID</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown
                                                                                                    [options]="productListArray"
                                                                                                    optionlabel="productId"
                                                                                                    filter="true"
                                                                                                    placeholder="Select"
                                                                                                    formControlName="productId">
                                                                                                </p-dropdown>
                                                                                            </div>


                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Party
                                                                                                    Id From</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown
                                                                                                    [options]="PersonsAndPartyGroupsListArray"
                                                                                                    optionlabel="partyId"
                                                                                                    filter="true"
                                                                                                    placeholder="Select"
                                                                                                    formControlName="partyIdFrom">
                                                                                                </p-dropdown>
                                                                                            </div>

                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Party
                                                                                                    Id To</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown
                                                                                                    [options]="PersonsAndPartyGroupsListArray"
                                                                                                    optionlabel="partyId"
                                                                                                    filter="true"
                                                                                                    placeholder="Select"
                                                                                                    formControlName="partyIdTo">
                                                                                                </p-dropdown>
                                                                                            </div>


                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Agreement
                                                                                                    Type ID</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown
                                                                                                    [options]="AgreementTypeListArray"
                                                                                                    optionlabel="label"
                                                                                                    filter="true"
                                                                                                    placeholder="Select"
                                                                                                    formControlName="agreementTypeId">
                                                                                                </p-dropdown>
                                                                                            </div>

                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">From
                                                                                                    Date</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2"
                                                                                                style="display: none;">
                                                                                                <p-dropdown
                                                                                                    [options]="fromDateType"
                                                                                                    filter="true"
                                                                                                    formControlName="fromDateFromSearchType">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <p-calendar
                                                                                                    formControlName="fromDateFrom">
                                                                                                </p-calendar>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">To
                                                                                                    Date</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2"
                                                                                                style="display: none;">
                                                                                                <p-dropdown
                                                                                                    [options]="toDateType"
                                                                                                    filter="true"
                                                                                                    formControlName="fromDateToSearchType">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <p-calendar
                                                                                                    formControlName="fromDateTo"></p-calendar>
                                                                                            </div>



                                                                                        </div>
                                                                                    </div>


                                                                                </div>

                                                                                <div class="col-lg-12 col-12 main-submit-button "
                                                                                    style="    margin-left: -26%;">
                                                                                    <button _ngcontent-cta-c128=""
                                                                                        (click)="submit()" type="submit"
                                                                                        class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                                                    <button type="button"
                                                                                        class="btn btn-danger ml-2"
                                                                                        (click)="restForm()">Reset</button>

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>


                                                            <div class="w3-card-4 classCard mt-2">
                                                                <header class="w3-container w3-blue">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling h4Margin">
                                                                            Agreement List
                                                                        </h4>

                                                                    </div>
                                                                </header>
                                                                <div
                                                                    class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                    <div class="col-lg-12 col-12">
                                                                        <div class="form-group">

                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="agreementList"
                                                                                    [paginator]="true" [rows]="rows"
                                                                                    scrollWidth="100%"
                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                    [totalRecords]="total"
                                                                                    [scrollable]="true"
                                                                                    (sortFunction)="customSort($event)"
                                                                                    [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr
                                                                                            style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                pSortableColumn="code">
                                                                                                <div class="checkbox-align"
                                                                                                    style="color: white;">
                                                                                                    <p-checkbox
                                                                                                        _ngcontent-egj-c329=""
                                                                                                        styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </p-checkbox>
                                                                                                    Agreement ID
                                                                                                    <p-sortIcon
                                                                                                        field="code"></p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Product ID
                                                                                                    <p-sortIcon
                                                                                                        field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    From Party ID
                                                                                                    <p-sortIcon
                                                                                                        field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                pSortableColumn="category">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    To Party ID
                                                                                                    <p-sortIcon
                                                                                                        field="category"></p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'200px'}"
                                                                                                width="11%"
                                                                                                pSortableColumn="quantity">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Role Type Id To
                                                                                                    <p-sortIcon
                                                                                                        field="quantity"></p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'200px'}"
                                                                                                width="11%"
                                                                                                pSortableColumn="price">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Agreement Type ID
                                                                                                    <p-sortIcon
                                                                                                        field="price"></p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                pSortableColumn="price">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    From Date
                                                                                                    <p-sortIcon
                                                                                                        field="price">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                pSortableColumn="price">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Through Date
                                                                                                    <p-sortIcon
                                                                                                        field="price">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                pSortableColumn="price">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Description
                                                                                                    <p-sortIcon
                                                                                                        field="price">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                pSortableColumn="price">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Created Date
                                                                                                    <p-sortIcon
                                                                                                        field="price">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                width="10%"
                                                                                                style="text-align: center;"
                                                                                                pSortableColumn="price">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Action</div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    style="visibility:hidden;"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-product>
                                                                                        <tr>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'150px'}">
                                                                                                <div
                                                                                                    class="checkbox-align">
                                                                                                    <p-checkbox
                                                                                                        _ngcontent-egj-c329=""
                                                                                                        styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </p-checkbox>
                                                                                                    <span
                                                                                                        (click)="summary(product.agreementId,product.partyIdTo)"
                                                                                                        class="account-button">{{product.agreementId}}</span>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'150px'}">
                                                                                                {{product.productId}}
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'150px'}">
                                                                                                {{product.partyIdFrom}}
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'150px'}">
                                                                                                {{product.partyIdTo}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'200px'}"
                                                                                                width="11%">
                                                                                                {{product.roleTypeIdTo}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'200px'}"
                                                                                                width="11%">
                                                                                                {{product.agreementTypeId}}
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'150px'}">
                                                                                                {{product.fromDate |
                                                                                                date:'yyyy-MM-d
                                                                                                hh:mm:ss'}}</td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'150px'}">
                                                                                                {{product.thruDate |
                                                                                                date:'yyyy-MM-d
                                                                                                hh:mm:ss'}}</td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'150px'}">
                                                                                                {{product.createdStamp |
                                                                                                date:'yyyy-MM-d
                                                                                                hh:mm:ss'}}
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'150px'}">
                                                                                                {{product.description}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'150px'}"
                                                                                                width="10%"
                                                                                                style="text-align: center;">
                                                                                                <div>

                                                                                                    <svg (click)="cancelAgreement(product.agreementId)"
                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                        pTooltip="Cancel"
                                                                                                        tooltipPosition="Bottom"
                                                                                                        class="ml-20"
                                                                                                        width="16"
                                                                                                        height="16"
                                                                                                        viewBox="0 0 26 26">
                                                                                                        <defs>
                                                                                                            <style>
                                                                                                                .a {
                                                                                                                    fill: #f44336;
                                                                                                                }

                                                                                                                .b {
                                                                                                                    fill: #fafafa;
                                                                                                                    margin-left: 20px;
                                                                                                                }
                                                                                                            </style>
                                                                                                        </defs>
                                                                                                        <path
                                                                                                            class="a fill-color"
                                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                        <path class="b"
                                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                                    </svg>
                                                                                                </div>
                                                                                            </td>

                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage"
                                                                                        let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span
                                                                                                    class="text-center">
                                                                                                    <h4 class="ml-2">No
                                                                                                        Record Found
                                                                                                    </h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>

                                                                                <p class="paginate_data">
                                                                                    View per page </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>



                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategoriess==8">
                                                    <div class="w3-card-4 classCard">
                                                        <header class="w3-container w3-blue">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling h4Margin">
                                                                    Commission Report
                                                                </h4>
                                                            </div>
                                                        </header>




                                                        <div class="row">
                                                            <div class="col-12">

                                                                <div class=" bg-white color-grey">
                                                                    <div
                                                                        class="port-header-contact create-lead font-medium mb-0">
                                                                        Commission Report
                                                                    </div>
                                                                    <div class="create-new-leade mt-2">
                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                <form class="w-100">
                                                                                    <div
                                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="col-lg-4 col-12">
                                                                                            <div class="form-group">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Party
                                                                                                    ID</label>
                                                                                                <div class="">
                                                                                                    <p-dropdown
                                                                                                        filter="true"
                                                                                                        optionLabel="label">
                                                                                                        <ng-template
                                                                                                            let-item
                                                                                                            pTemplate="selectedItem">
                                                                                                            <span
                                                                                                                class="label-info"
                                                                                                                style="margin-left: .5em">{{item.label}}</span>
                                                                                                        </ng-template>
                                                                                                        <ng-template
                                                                                                            let-car
                                                                                                            pTemplate="item">
                                                                                                            <div class="ui-helper-clearfix"
                                                                                                                style="position: relative;height: 25px;">
                                                                                                                <div
                                                                                                                    style="font-size:14px;margin-top:4px">
                                                                                                                    {{car.label}}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </ng-template>
                                                                                                    </p-dropdown>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-4 col-12">
                                                                                            <div class="form-group">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Product
                                                                                                    ID</label>
                                                                                                <input type="email"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp">
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-4 col-12">
                                                                                            <div class="form-group">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">From
                                                                                                    Date</label>
                                                                                                <input type="email"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp">
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-4 col-12">
                                                                                            <div class="form-group">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Through
                                                                                                    Date</label>
                                                                                                <input type="email"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        class="col-lg-12 col-12 main-submit-button ">
                                                                                        <button _ngcontent-cta-c128=""
                                                                                            type="submit"
                                                                                            class="btn btn-secondary submit-btn">Find</button>
                                                                                    </div>

                                                                                </form>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="main-submit-button">
                <button type="submit" class="btn btn-secondary submit-btn">Create Lead</button>
            </div> -->
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create new accounting company</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">

                            <li [ngClass]="activeCategory==2?'active':''"><a>
                                    Create new accounting company
                                </a></li>


                        </ul>
                    </div>

                    <div class="all-users-infomation font-13 font-medium own-user-select">
                        <div class="form-group">
                            <div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100">

                                            <div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium own-user-select allUserPadding">
                                                    <form [formGroup]="createOrganization">
                                                        <div class="row" style="margin-left: 6%;">
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="exampleInputEmail1"
                                                                            style="color: black!important;">
                                                                            Organization Party ID
                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-4">
                                                                        <p-dropdown filter="true"
                                                                            placeholder="Select Organization Party ID"
                                                                            formControlName="organizationPartyId"
                                                                            [options]="orgIdArray" optionlabel="label">
                                                                        </p-dropdown>
                                                                    </div>

                                                                    <div class="col-lg-12" style="margin-left: 46%;">
                                                                        <button _ngcontent-cta-c128=""
                                                                            (click)="onSubmit()" type="submit"
                                                                            class="btn btn-secondary submit-btn">Create</button>
                                                                    </div>



                                                                </div>
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>