import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-attach-content',
  templateUrl: './attach-content.component.html',
  styleUrls: ['./attach-content.component.css']
})
export class AttachContentComponent implements OnInit {

  createJournal:FormGroup;
  activeCategory=2;
  partyId: any;
  show=false;
  selectedFileName: any;
  partyContentData: any=[];
  partyContentArray: any[]=[];
  fileData: File;
  partyContentTypeId: any;
  Editform: FormGroup;
  @Output() closeContentModal: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closebutton') closebutton;
	
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    public bsModalRef: BsModalRef) {
  }
 
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params['party']});
    this.getPartyContent();
    this.Editform = new FormGroup({
      file: new FormControl('', [
        Validators.required
      ]),
      partyContentTypeId: new FormControl('')
    });
  }

  getPartyContent() {
    this.spinner.show();
    this.accountsService.getParty().subscribe(res => {
      this.partyContentData = res.data.contentPurposeType;
      this.spinner.hide();
      for (const value of this.partyContentData) {
        this.partyContentArray.push({
          label: value.description,
          value: value.partyContentTypeId
        });
      }
    });

  }

  onpartyContent(event) {
    this.partyContentTypeId = event;
  }

  fileProgress(fileInput: any){
    this.fileData = <File>fileInput.target.files[0];
  }

  onSubmit() {
    this.spinner.show();
    const formData = new FormData();
    formData.append('file', this.fileData);
    this.partyContentTypeId = this.Editform.get('partyContentTypeId').value;

    this.accountsService.attachPartyContent(formData, this.partyContentTypeId, this.partyId).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success(res.data.successMessage);
        this.modalClose();
        this.spinner.hide();
        this._Router.navigate(['/financial/view-party-profile'],{queryParams:{party:this.partyId}});

      }
    }, (err) => {
      this.spinner.hide();
      this._ToastrService.error(err.message);

    });
    }

  
  cancelSubmit(){
    this._Router.navigate(['/financial/view-party-profile']);
  }

  modalClose(): void {
    this.closebutton.nativeElement.click();
    this.closeContentModal.emit();
  }

}
