<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Current Project: {{this.productPriceRuleId}} </span>
              
               <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard w-100">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                        style="justify-content: space-between;">
                        <p class="h-text">Edit Price Rule {{this.productPriceRuleId}}</p>
                        <span>
 
                            <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;"
                                data-toggle="modal" data-target="#newpopup">Add/Find Rule</button>
                        </span>
                    </div>
                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table">
                                    <p-table [value]="ProductPriceRule" [paginator]="true" [rows]="rows"
                                        scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                        [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)"
                                        [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                    <div class="checkbox-align" style="color: white;">
                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                            binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined"
                                                                        value="undefined">
                                                                </div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>
                                                        Name
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: white;">
                                                        Description
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: white;">
                                                        Sale Rule
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: white;">
                                                        From Date
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: white;">
                                                        Through Date
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: white;">
                                                        Action
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td [ngStyle]="{'width':'150px'}">
                                                    <div class="checkbox-align">
                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                            binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined"
                                                                        value="undefined">
                                                                </div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>
                                                        <span  class="account-button"  >
                                                            {{product.ruleName}}
                                                        </span>

                                                    </div>
                                                </td>

                                                <td [ngStyle]="{'width':'180px'}"> {{product.description}}

                                                </td>
                                                <td [ngStyle]="{'width':'180px'}"> {{product.isSale}}

                                                </td>
                                                <td [ngStyle]="{'width':'180px'}"> {{product.fromDate | date
                                                    :'yyyy-MM-dd hh:mm:ss'}}

                                                </td>
                                                <td [ngStyle]="{'width':'180px'}"> {{product.thruDate | date
                                                    :'yyyy-MM-dd hh:mm:ss'}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">
                                                    <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                        data-toggle="modal" data-target="#updatePriceRule"
                                                        (click)="update(product)">Update
                                                    </button>
                                                    <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                       
                                                        (click)="deleteProductPriceRule(product)">Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage" let-columns>
                                            <tr>
                                                <td>
                                                    <span class="text-center">
                                                        <h4 class="ml-2">
                                                            No Record
                                                            Found</h4>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>

                                    <p class="paginate_data">
                                        View per page </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w3-card-4 classCard mt-2 w-100">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                        style="justify-content: space-between;">
                        <p class="h-text">Conditions</p>
                        <span>
   
                            <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;"
                                data-toggle="modal" data-target="#updateCondition"
                                (click)="resetCondition()">Create New Condition</button>
                        </span>
                    </div>
                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table">
                                    <p-table [value]="priceRuleCond" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                        [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                    <div class="checkbox-align" style="color: white;">
                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                            binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined"
                                                                        value="undefined">
                                                                </div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>

                                                        Input
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: white;">
                                                        Operator
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: white;">
                                                        Value
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: white;">
                                                        Action
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td [ngStyle]="{'width':'150px'}">
                                                    <div class="checkbox-align">
                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                            binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined"
                                                                        value="undefined">
                                                                </div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>
                                                        <span  class="account-button"  >
                                                            {{product.inputParamEnumId}}  </span>

                                                    </div>
                                                </td>

                                                <td [ngStyle]="{'width':'180px'}">{{product.operatorEnumId}}

                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">{{product.condValue}}

                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">
                                                    <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal"
                                                    data-target="#updateCondition" (click)="updateCond(product)">Update
                                                </button>
                                                <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" (click)=" deleteProductPriceCond(product)">Delete
                                            </button>
                                                </td>
                                              
                                            </tr>
                                        </ng-template>

                                        <ng-template pTemplate="emptymessage" let-columns>
                                            <tr>
                                                <td>
                                                    <span class="text-center">
                                                        <h4 class="ml-2">
                                                            No Record
                                                            Found</h4>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>

                                    <p class="paginate_data">
                                        View per page </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w3-card-4 classCard mt-2 w-100">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                        style="justify-content: space-between;">
                        <p class="h-text">Actions</p>
                        <span>

                            <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;"
                               (click)="resetAct()" data-toggle="modal" data-target="#updateAction">New</button>
                        </span>
                    </div>
                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table">
                                    <p-table [value]="actionList" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                        [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                    <div class="checkbox-align" style="color: white;">
                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                            binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined"
                                                                        value="undefined">
                                                                </div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>

                                                        Action Type
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: white;">
                                                        Value
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: white;">
                                                        Action
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td [ngStyle]="{'width':'150px'}">
                                                    <div class="checkbox-align">
                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                            binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined"
                                                                        value="undefined">
                                                                </div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>
                                                        <span  class="account-button"  >
                                                            {{product.productPriceActionTypeId}}
                                                        </span>

                                                    </div>
                                                </td>

                                                <td [ngStyle]="{'width':'180px'}">    {{product.amount}}

                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">  
                                                     <button *ngIf="!this.Hidebtn" (click)="updateAct(product)" type="submit"
                                                     data-toggle="modal" data-target="#updateAction"
                                                    class="btn btn-secondary submit-btn">Update</button>
                                                    <button *ngIf="!this.Hidebtn" (click)="deleteProductPriceAction(product)" type="submit"
                                                    class="btn btn-danger ">Delete</button>

                                                </td>
                                            </tr>
                                        </ng-template>

                                        <ng-template pTemplate="emptymessage" let-columns>
                                            <tr>
                                                <td>
                                                    <span class="text-center">
                                                        <h4 class="ml-2">
                                                            No Record
                                                            Found</h4>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>

                                    <p class="paginate_data">
                                        View per page </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="updatePriceRule" tabindex="-1" role="dialog" aria-labelledby="updatePriceRuleTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Edit Price Rules</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a>Edit Price Rules</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updatePrice">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="name"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Descripiton
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="desc"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Descripiton">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Sale Rule
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="saleRule"
                                                            [options]="yesNoArray" optionlabel="label"
                                                            placeholder="Enter Sale Rule">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" formControlName="throughDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Through Date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="updateProductPriceRule()">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="updateCondition" tabindex="-1" role="dialog" aria-labelledby="updateConditionTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1" >{{showCond ? 'Edit' : 'Add'}}  Conditions</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>{{showCond ? 'Edit' : 'Add'}}  Conditions</a></li>
                             

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateCondition">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Input
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="input"
                                                            [options]="inputListArray" optionlabel="label"
                                                            placeholder="Enter Input">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Operator
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="operator"
                                                        [options]="conditionValuesArray" optionlabel="label" placeholder="Enter Operator">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Value
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="value"
                                                        [options]="ProductIdArray"   optionlabel="label" placeholder="Enter Value">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                *ngIf="!this.showCond" (click)="createProductPriceCond()">Add</button>
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                *ngIf="this.showCond" (click)="updateProductPriceCond()">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="updateAction" tabindex="-1" role="dialog" aria-labelledby="updateActionTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">{{showAction ? 'Edit':'Add'}} Actions</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>{{showAction ? 'Edit':'Add'}} Actions</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateAction">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Action Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="actionType"
                                                          [options]="actionTypePriceRulesArray"  optionlabel="label" placeholder="Enter Action Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Value
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="value"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Value">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn" *ngIf="!this.showAction" (click)="createProductPriceAction()">Add</button>
                                            <button type="submit" class="btn btn-secondary submit-btn" *ngIf="this.showAction" (click)="updateProductPriceAction()">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="newpopup" tabindex="-1" role="dialog" aria-labelledby="newpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Price Rule</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a>Add Price Rule</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addPriceRule">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Name<span
                                                                style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="name"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Name">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 col-5 main-submit-button">
                                            <button (click)="createProductPriceRule()" type="submit"
                                                class="btn btn-secondary submit-btn">Create</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>