import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountsService } from '../accounts/accounts.service';
import { MyContactsService } from '../contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from '../events/create-events/create-event-service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 1;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  findByName: { partnerID: string; partnerName: string; };
  findByPhone: { phone1: string; phone2: string; phone3: string; };
  findByemail: { email: string; };
  createPartner: any;
  EmailPartnerList: any;
  PhonePartnerList: any;
  NamePartnerList: any;
  AdvancePartnerList: any;
  countryId: any;
  stateArray: any=[];
  statesArray: any=[];
  stateData: any;
  array: any = [];
  currencyList: any[];
  state: any;
  industryList: any;
  industryListArray: any[]=[];
  ownerShip: any;
  ownerShipArray: any[]=[];
  classificationBookmarkPartnerArray: any[]=[];
  classificationBookmarkPartner: any;
  findByAdvanced: { classification: string; toName: string; attentionName: string; addressLine1: string; city: string; country: string; state: string; postalCode: string; email: string; phone1: string; partnerID: string; partnerName: string; };
  countryID: any;
  countryArray: any =[];
  statesData: any;
  countryGeoId: any;
  stateProvinceGeoId: any;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly AccountsService: AccountsService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) {
    this.findByName = {
      partnerID: "",
      partnerName: ""
    };
    this.findByPhone = {
      phone1: "",
      phone2: "",
      phone3: ""
    };
    this.findByemail = {
      email: "",
    };
    this.findByAdvanced = {
      classification: "",
      toName: "",
      attentionName: "",
      addressLine1: "",
      city: "",
      country: "",
      state: "",
      postalCode: "",
      email: "",
      phone1: "",
      partnerID: "",
      partnerName: ""

    };
    this.createPartner = this._FormBuilder.group({
      partnerName: [''],
      localName: [''],
      siteName: [''],
      annualRevenue: [''],
      preferredCurrency: [''],
      industry: [''],
      numberOfEmployees: [''],
      ownership: [''],
      sitCode: [''],
      tickerSymbol: [''],
      countryCode: [''],
      areaCode: [''],
      phoneNumber: [''],
      extension: [''],
      personToAskFor: [''],
      emailAddress: [''],
      webURL: [''],
      toName: [''],
      attentionName: [''],
      addressLine1: [''],
      addressLine2: [''],
      city: [''],
      state: [''],
      zipCode: [''],
      country: [''],
      zipExtension: [''],
    })
  }

  ngOnInit(): void {
    this.createPartner.controls.country.valueChanges.subscribe(data => {
      if (data) {
        this.countryId = data;
        this.getStateList();
      }
    });

    this.getCountryList();
    this.getfindByEmailPartner();
    this.getfindByPhonePartner();
    this.getfindByNamePartner();
    this.getIndustry();
    this.getCurrency();
    this.getOwnerShip();
    this.getClassificationBookmarkPartner();
    this.getfindByAdvancePartner();
 

   
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }
  onCountryChange(event) {
    this.stateArray = [];
    this.countryGeoId = event;
    this.countryId = event;
    if (event) {
      this.getStateList();
    }
  }

  onStateChange(event) {
    this.stateProvinceGeoId = event;
  }

  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.currencyList = data.data.currencyList.map(value => {
            return {
              label: value.description,
              value: value.uomId
            };
          });
        }
      });
  }

  
  getCountryList() {
    this.spinner.show();
    this.AccountsService.getCountry().subscribe(res => {
      var Country = res.data.countryList;
      this.spinner.hide();
      Country.forEach(element => {
        this.array.push({
          label: element.split(":")[0],
          value: element.split(":").pop().trim()
        })
      })
    })
  }


  getStateList() {
    this.stateArray = [];
    this.spinner.show();
    this.AccountsService.getState(this.countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })

    })


  }
  getIndustry() {
    this.spinner.show();
    this.myContactsService.getIndustry().subscribe(res => {
      this.industryList = res.data[0].node;
      this.spinner.hide();
      for (const value of this.industryList) {
        this.industryListArray.push({
          label: value.industryDescription,
          value: value.industryValue
        })
      }
    })
  }
  getOwnerShip() {
    this.spinner.show();
    this.myContactsService.getOwnerShip().subscribe(res => {
      this.ownerShip = res.data[0].node;
      this.spinner.hide();
      for (const value of this.ownerShip) {
        this.ownerShipArray.push({
          label: value.ownerShipDescription,
          value: value.ownerShipValue
        })
      }
    })
  }
  getClassificationBookmarkPartner() {
    this.spinner.show();
    this.myContactsService.getClassificationBookmarkPartner().subscribe(res => {
      this.classificationBookmarkPartner = res.data[0].data;
      this.spinner.hide();
      for (const value of this.classificationBookmarkPartner) {
        this.classificationBookmarkPartnerArray.push({
          label: value.description,
          value: value.classificationEnumId
        })
      }
    })
  }

  createPartners(){
    this.spinner.show();
    const requestData = {
      "address1":  this.createPartner.value.addressLine1,
      "address2":  this.createPartner.value.addressLine2,
      "annualRevenue":  this.createPartner.value.annualRevenue,
      "areaCode":  this.createPartner.value.areaCode,
      "attnName":  this.createPartner.value.attentionName,
      "city":  this.createPartner.value.city,
      "country":  this.createPartner.value.country,
      "countryCode":  this.createPartner.value.countryCode,
      "description":  "",
      "emailAddress":  this.createPartner.value.emailAddress,
      "extension":  this.createPartner.value.extension,
      "importantNote":  "",
      "industry":  this.createPartner.value.industry,
      "localName":  this.createPartner.value.localName,
      "numberOfEmployees":  this.createPartner.value.numberOfEmployees,
      "ownership":  this.createPartner.value.ownership,
      "partnerId":  "",
      "partnerName":  this.createPartner.value.partnerName,
      "personToAskFor":  this.createPartner.value.personToAskFor,
      "phoneNumber":  this.createPartner.value.phoneNumber,
      "postalCode":  this.createPartner.value.zipCode,
      "postalCodeExt":  this.createPartner.value.zipExtension,
      "preferredCurrency": this.createPartner.value.preferredCurrency,
      "sicCode":  this.createPartner.value.sitCode,
      "siteName":  this.createPartner.value.siteName,
      "stateProvince":  this.createPartner.value.state,
      "tickerSymbol":  this.createPartner.value.tickerSymbol,
      "toName":  this.createPartner.value.toName,
      "webUrl":  this.createPartner.value.webURL,
    }
    this.myContactsService.createPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.createPartner.reset();
       this.findByAdvancePartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getfindByEmailPartner() {
    this.spinner.show();
    this.myContactsService.getfindByEmailPartner().subscribe(res => {
      this.EmailPartnerList = res.data;
      this.spinner.hide();
  
    })
  }
  getfindByPhonePartner() {
    this.spinner.show();
    this.myContactsService.getfindByPhonePartner().subscribe(res => {
      this.PhonePartnerList = res.data;
      this.spinner.hide();
  
    })
  }
  getfindByNamePartner() {
    this.spinner.show();
    this.myContactsService.getfindByNamePartner().subscribe(res => {
      this.NamePartnerList = res.data;
      this.spinner.hide();
  
    })
  }
  getfindByAdvancePartner() {
    this.spinner.show();
    const formData = {
      "address1": "",
      "attnName": "",
      "city": "",
      "contactNumber": "",
      "countryGeoId": "",
      "emailAddress": "",
      "partnerId": "",
      "partnerName": "",
      "postalCode": "",
      "stateProvinceGeoId": "",
      "toName": ""
    }
    this.myContactsService.findFinalPartner(formData).subscribe(res => {
      this.AdvancePartnerList = res.data;
      this.spinner.hide();
  
    })
  }
 
  detailPage(id){
    this._Router.navigate(["/crm/Partners-Competitors/viewPartner-Competitor"],
    {queryParams : {partyId :id}})
  }
  reset() {
    this.spinner.show();
    this.findByAdvanced.addressLine1 = "";
    this.findByAdvanced.attentionName = "";
    this.findByAdvanced.city = "";
    this.findByAdvanced.country = "";
    this.findByAdvanced.postalCode = "";
    this.findByAdvanced.state = "";
    this.findByAdvanced.email = "";
    this.findByAdvanced.phone1 = "";
    this.findByAdvanced.partnerID = "";
    this.findByAdvanced.partnerName = "";
    this.findByAdvanced.toName = "";
    this.findByAdvanced.classification = "";
  
    const formData = {
     
      "address1": "",
      "attnName": "",
      "city": "",
      "contactNumber": "",
      "countryGeoId": "",
      "emailAddress": "",
      "partnerId": "",
      "partnerName": "",
      "postalCode": "",
      "stateProvinceGeoId": "",
      "toName": ""
    }
    this.myContactsService.findFinalPartner(formData).subscribe(res => {
      this.AdvancePartnerList = res.data;
      this.spinner.hide();
    })
  }

  findByAdvancePartner() {
    this.spinner.show();
    this.AdvancePartnerList = [];
    const formData = {
      "address1": this.findByAdvanced.addressLine1 ? this.findByAdvanced.addressLine1 : '',
      "attnName":  this.findByAdvanced.attentionName ? this.findByAdvanced.attentionName : '',
      "city": this.findByAdvanced.city ? this.findByAdvanced.city : '',
      "countryGeoId": this.findByAdvanced.country ? this.findByAdvanced.country : '',
      "postalCode": this.findByAdvanced.postalCode ? this.findByAdvanced.postalCode : '',
      "toName":  this.findByAdvanced.toName ? this.findByAdvanced.toName : '',
      "contactNumber": this.findByAdvanced.phone1 ? this.findByAdvanced.phone1 : '',
      "emailAddress":  this.findByAdvanced.email ? this.findByAdvanced.email : '',
      "partnerId":  this.findByAdvanced.partnerID ? this.findByAdvanced.partnerID : '',
      "partnerName":  this.findByAdvanced.partnerName ? this.findByAdvanced.partnerName : '',
      "stateProvinceGeoId":  this.findByAdvanced.state ? this.findByAdvanced.state : '',
    }
    this.myContactsService.findFinalPartner(formData).subscribe(res => {
      this.AdvancePartnerList = res.data;
      this.spinner.hide();
        
      })
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
}
