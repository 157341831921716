import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';  import { Location } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { AddRelatedAccountComponent } from 'src/app/accounting/add-related-account/add-related-account.component';
import { CreateProductPromotionCodeComponent } from 'src/app/catalog-mgmt/promos-catalog/create-product-promotion-code/create-product-promotion-code.component';

@Component({
  selector: 'app-promos-catalog',
  templateUrl: './promos-catalog.component.html',
  styleUrls: ['./promos-catalog.component.css']
})
export class PromosCatalogComponent implements OnInit {
  activeCategory = 1;
  activeCat=6;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  newProductPromo: any;
  editPromo: any;
  AssignedTaskList: any;
  useEntered: string;
  arrayValue=[{
    'label':'Y',
    'value':'Y'
  },
  {
    'label':'N',
    'value':'N'
  }]
  personId: any;
  @ViewChild('closebutton') closebutton;
  partyIdArray: any[]=[];
  ActualCompletionDate: string;
  ActualStartDate: string;
  newPromotionCode: any;
  bsModalRef: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,    private _location: Location,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder, 
    public modalService: BsModalService,
    readonly addSkillService: AddSkillService,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router,
    readonly router: Router,
  ) {
    this.newProductPromo = this._FormBuilder.group({
      promoName: [''],
      promoText: [''],
      userEntered: [''],
      promoShow: [''],
      reqCode: [''],
      limitOrder: [''],
      limitCust: [''],
      limitPromo: [''],
      billbackFactor: [''],
      orgPartyID: [''],
      createdDate: [''],
      createdByUser: [''],
      modifiedDate: [''],
      modifiedByUser: [''],
    });
    this.newPromotionCode = this._FormBuilder.group({
      promoCodeID: [''],
      promoID: [''],
      userEntered: [''],
      reqEmailParty: [''],
      limitCode: [''],
      limitCust: [''],
      fromDate: [''],
      thruDate: [''],
      createdDate: [''],
      createdUser: [''],
      modifiyDate: [''],
      modifyUser: [''],
    });
    this.editPromo = this._FormBuilder.group({
      promoCodeID: [''],
    });
   }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
    this.getProductPromotionList();
    this.getPartyId();
  }
  createPromo(){
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this._Router.navigate([],{queryParams:{productPromoCodeId:this.editPromo.value.promoCodeID}});
      this.bsModalRef = this.modalService.show(CreateProductPromotionCodeComponent,{class: 'modal-lg'});
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.toastr.error("Permission Denied");
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.toastr.error("Permission Denied");
    }

  }

  getProductPromotionList() {
    this.spinner.show();
    this.AssignedTaskList=[];
    this.accountsService.getProductPromotionList().subscribe(res => {
      this.AssignedTaskList = res.data;
      this.spinner.hide();
  
    })
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  detailPage(id) {
    this._Router.navigate(["facilities/catalog/promos/editPromo"], { queryParams: { productPromoId: id } })
  }
  createProductPromo(): void {
    this.spinner.show();
    const ActualCompletionDate=this.newProductPromo.get('createdDate').value;
    this.ActualCompletionDate=this.datePipe.transform(ActualCompletionDate,"yyyy-MM-dd hh:mm:ss");
    const ActualStartDate=this.newProductPromo.get('modifiedDate').value;
    this.ActualStartDate=this.datePipe.transform(ActualStartDate,"yyyy-MM-dd hh:mm:ss");
  
    const requestData ={
      "billbackFactor":this.newProductPromo.value.billbackFactor,
      "createdByUserLogin":this.newProductPromo.value.createdByUser,
      "createdDate":  this.ActualCompletionDate,
      "lastModifiedByUserLogin": this.newProductPromo.value.modifiedByUser,
      "lastModifiedDate":  this.ActualStartDate,
      "overrideOrgPartyId": this.newProductPromo.value.orgPartyID,
      "promoName": this.newProductPromo.value.promoName,
      "promoText": this.newProductPromo.value.promoText,
      "requireCode":this.newProductPromo.value.reqCode,
      "showToCustomer": this.newProductPromo.value.promoShow,
      "useLimitPerCustomer":this.newProductPromo.value.limitCust,
      "useLimitPerOrder": this.newProductPromo.value.limitOrder,
      "useLimitPerPromotion": this.newProductPromo.value.limitPromo,
      "userEntered":this.newProductPromo.value.userEntered,
      }
     
    this.accountsService.createProductPromo(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getProductPromotionList(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this._ToastrService.error(err.error.message);
      }
    }); }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  findProductPromo() {
    this.spinner.show();
    this.AssignedTaskList=[];
    this.useEntered='Y'
    this.accountsService.findProductPromo(this.useEntered).subscribe(res => {
      this.AssignedTaskList = res.data;
      this.spinner.hide();
  
    })
  }
  findProductPromoImport() {
    this.spinner.show();
    this.AssignedTaskList=[];
    this.useEntered='N'
    this.accountsService.findProductPromo(this.useEntered).subscribe(res => {
      this.AssignedTaskList = res.data;
      this.spinner.hide();
  
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  mainOpen(){
    this.router.navigate(['/facilities/catalog/main-catalog']);
  }
  catalogOpen(){
    this.router.navigate(['/facilities/catalog/catalogs']);
  }
  categoriesOpen(){
    this.router.navigate(['/facilities/catalog/catagories']);
  }
  productsOpen(){
    this.router.navigate(['/facilities/catalog/products']);
  }
  featuresOpen(){
    this.router.navigate(['/facilities/catalog/feature-type']);
  }
  promosOpen(){
    this.router.navigate(['/facilities/catalog/promos']);
  }
  priceRulesOpen(){
    this.router.navigate(['/facilities/catalog/price-rules']);
  }
  storesOpen(){
    this.router.navigate(['/facilities/catalog/stores']);
  }
  productStoreGroupsOpen(){
    this.router.navigate(['/facilities/catalog/product-store-groups']);
  }
  thesaurusOpen(){
    this.router.navigate(['/facilities/catalog/thesaurus']);
  }
  reviewsOpen(){
    this.router.navigate(['/facilities/catalog/reviews']);
  }
  configurationsOpen(){
    this.router.navigate(['/facilities/catalog/configurations']);
  }
  subscriptionsOpen(){
    this.router.navigate(['/facilities/catalog/subscriptions']);
  }
  shippingOpen(){
    this.router.navigate(['/facilities/catalog/shipment']);
  }
  imageManagementOpen(){
    this.router.navigate(['/facilities/catalog/image-management']);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
