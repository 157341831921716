import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { FeaturesService } from '../features.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner'; import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-feature-interaction',
  templateUrl: './feature-interaction.component.html',
  styleUrls: ['./feature-interaction.component.css']
})
export class FeatureInteractionComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  total=0;
  activeCategorytab=2;
  activeCat=5;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  activeCategoryMain=4;
  addFeatureInteractionForm:FormGroup;
  findFeatureInteractionForm: FormGroup;
  productFeatureIactnAll: any;
  ProductFeatureIdList: any;
  ProductFeatureIdListArray: any[]=[];
  interactionTypeArray: { label: string; value: string; }[];
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    readonly _FeaturesService: FeaturesService,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
      private _location: Location,
      readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,

    ) 
    {
      this.interactionTypeArray=[
        {
        label: 'Dependent Feature',
        value: 'FEATURE_IACTN_DEPEND'
        },
        {
        label: 'Incompatible Feature',
        value: 'FEATURE_IACTN_INCOMP'
        }
      ];
      this.findFeatureInteractionForm = this._FormBuilder.group({
        productFeatureId:"",
        productFeatureIdTo:"",
      });

      this.addFeatureInteractionForm = this._FormBuilder.group({
        productFeatureId:"",
        productFeatureIdTo:"",
        interactionType:"",
      });

    }
    ngOnInit(): void {
      this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
      if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
        this.Hidebtn=false;
      }
      else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
        this.Hidebtn=true;
      }
      else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
        this.HideCrudbtn=true;
      }
      this.spinner.hide();
      this.findProductFeatureIactnByIds();
      this.getProductFeatureIdList();
    }
    reset(){
      this.findFeatureInteractionForm.reset();
    }
    homeButton(){
      this.router.navigate(['/facilities/Dashboard'])
    
    }
    cancelSubmit(){
      this._location.back();
    }
    removeProductFeatureIactn(product) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let req = {
            "productFeatureId": product.productFeatureId,
            "productFeatureIdTo":product.productFeatureIdTo,
            "productId": product.productId?product.productId:"null",
            "view_INDEX_1": "",
            "view_SIZE_1": ""
          }
          this.accountsService.removeProductFeatureIactn(req).subscribe((res: any) => {
            if (res.success) {
              this.findProductFeatureIactnByIds();
            }
            else {
              this.toastr.error("Error");
            }
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    AddProductFeatureIactn(): void {
      this.spinner.show();
      const requestData = {
        "productFeatureIactnTypeId": this.addFeatureInteractionForm.value.interactionType,
        "productFeatureId": this.addFeatureInteractionForm.value.productFeatureId,
        "productFeatureIdTo": this.addFeatureInteractionForm.value.productFeatureIdTo,
        "productId": ''
      }
  
      this.accountsService.AddProductFeatureIactn(requestData).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success("Created Successfully");
          this.spinner.hide();
          this.findProductFeatureIactnByIds();
          this.addFeatureInteractionForm.reset();
          this.closebutton.nativeElement.click();
        }
      }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        }
        else {
          this.toastr.error(err.error.message);
        }
      });
    }
    getProductFeatureIdList() {
      this.spinner.show();
      this.accountsService.getProductFeatureIdList().subscribe(res => {
        this.ProductFeatureIdList = res.data;
        this.spinner.hide();
        for (const value of this.ProductFeatureIdList) {
          this.ProductFeatureIdListArray.push({
            label: value.description+"["+value.productFeatureId+"]",
            value: value.productFeatureId
          })
        }
      })
    }
    findProductFeatureIactnByIds(): void {
      this.spinner.show();
      let resData={
        "productFeatureIactnTypeId": "",
        "productFeatureId": this.findFeatureInteractionForm.value.productFeatureId?this.findFeatureInteractionForm.value.productFeatureId:'',
        "productFeatureIdTo": this.findFeatureInteractionForm.value.productFeatureIdTo?this.findFeatureInteractionForm.value.productFeatureIdTo:'',
        "productId": ""
      }
      this.accountsService.findProductFeatureIactnByIds(resData).subscribe((res: any) => {
        if (res.success) {
          this.productFeatureIactnAll=res.data.ProductFeatureIactn;
          this.spinner.hide();
        }
      }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        }
        else {
          this.toastr.error(err.error.message);
        }
      });
    }
    
    featureType(){
      this.router.navigate(['/facilities/catalog/feature-type']);
    }
    featureCategoryGroup(){
      this.router.navigate(['/facilities/catalog/feature-category']);
    }
    featureGroup(){
      this.router.navigate(['/facilities/catalog/feature-group']);
    }
  
    featureInteraction(){
      this.router.navigate(['/facilities/catalog/feature-interaction']);
    }

    toSummaryPage(id)
    {
      this.router.navigate(['/facilities/catalog/feature-interaction/new-feature-interaction'], { queryParams: { productFeatureId: id } });
    }
  
    // navigateToEdit(featureId: string): void {
    //   this.router.navigate(['/facilities/catalog/feature-category/new-feature-category'], { queryParams: { featureCategoryId: featureId } });
    // }
    customSort(event: SortEvent) {
      event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;
  
        if (value1 == null && value2 != null) {
          result = -1;
        }
        else if (value1 != null && value2 == null) {
          result = 1;
        }
        else if (value1 == null && value2 == null) {
          result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
          result = value1.localeCompare(value2);
        }
        else {
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
      });
    }
    mainOpen(){
      this.router.navigate(['/facilities/catalog/main-catalog']);
    }
    catalogOpen(){
      this.router.navigate(['/facilities/catalog/catalogs']);
    }
    categoriesOpen(){
      this.router.navigate(['/facilities/catalog/catagories']);
    }
    productsOpen(){
      this.router.navigate(['/facilities/catalog/products']);
    }
    featuresOpen(){
      this.router.navigate(['/facilities/catalog/feature-type']);
    }
    promosOpen(){
      this.router.navigate(['/facilities/catalog/promos']);
    }
    priceRulesOpen(){
      this.router.navigate(['/facilities/catalog/price-rules']);
    }
    storesOpen(){
      this.router.navigate(['/facilities/catalog/stores']);
    }
    productStoreGroupsOpen(){
      this.router.navigate(['/facilities/catalog/product-store-groups']);
    }
    thesaurusOpen(){
      this.router.navigate(['/facilities/catalog/thesaurus']);
    }
    reviewsOpen(){
      this.router.navigate(['/facilities/catalog/reviews']);
    }
    configurationsOpen(){
      this.router.navigate(['/facilities/catalog/configurations']);
    }
    subscriptionsOpen(){
      this.router.navigate(['/facilities/catalog/subscriptions']);
    }
    shippingOpen(){
      this.router.navigate(['/facilities/catalog/shipment']);
    }
    imageManagementOpen(){
      this.router.navigate(['/facilities/catalog/image-management']);
    }
   
}
