import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountingApService } from '../../../accounting-ap.service';

@Component({
    selector: 'app-send-credit-memo-mail',
    templateUrl: './send-credit-memo-mail.component.html'
})
export class SendCreditMemoMailComponent implements OnInit {
    invoiceId: string;
    sendEmailForm: FormGroup;
    constructor(
        readonly _FormBuilder: FormBuilder,
        readonly _AccountingApService: AccountingApService,
        readonly _ToastrService: ToastrService,
        readonly  _Router: Router,
        readonly spinner: NgxSpinnerService,
        readonly _ActivatedRoute: ActivatedRoute
    ) {
        this.invoiceId = '';
        this.sendEmailForm = this._FormBuilder.group({
            bodyText: [''],
            other: [''],
            sendCc: [''],
            sendFrom: [''],
            sendTo: [''],
            subject: ['']
        });
    }
    ngOnInit(): void {
        this._ActivatedRoute.queryParams.subscribe(data => {
            if (data) {
                this.invoiceId = data.invoiceId;
            }
        });
    }
    submit(): void {
        this.spinner.show();
        this._AccountingApService.sendEmail(this.invoiceId, this.sendEmailForm.value)
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Mail Sent');
                    this.spinner.hide();
                    this.sendEmailForm.reset();
                    this._Router.navigate(['/financial/payable/credit-memo/summary-credit-memo'], { queryParams: { invoiceId: this.invoiceId } });
                }
            });
          
    }
    navigateToInvoice(): void {
        this.sendEmailForm.reset();
        this._Router.navigate(['/financial/payable/credit-memo/summary-credit-memo'], { queryParams: { invoiceId: this.invoiceId } });
    }
}