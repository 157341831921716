import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';

@Component({
  selector: 'app-create-party-gl-account',
  templateUrl: './create-party-gl-account.component.html',
  styleUrls: ['./create-party-gl-account.component.css']
})
export class CreatePartyGlAccountComponent implements OnInit {
  createParty:FormGroup;
  activeCategory=2;
  partyId: any;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[]=[];
  glAccountType: any;
  glAccountTypeArray: any[]=[];
  roleId: any;
  partyIdData: any;
  roleIdArray: any;
  partyIdDataArray: any;
  glaccountid: any;
  accountype: any;
  party: any;
  role: any;
  show: boolean;
  personId: any;
  partyIdArray: any[]=[];
  id: string;
  allrole: any;
  allroleArray: any[]=[];
  glChartOfAccount: any;
  glChartOfAccountArray: any[]=[];
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly accountsService: AccountsService,
    readonly addSkillService: AddSkillService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createParty = this._FormBuilder.group({
        partyId: [''],
        roleType:[''],
        glAccountType: [''],
        glAccountId:['']
       
        
      });
    
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
      this.glaccountid = params["glaccountid"];
      this.accountype = params["accountype"];
      this.party = params["party"];
      this.role = params["role"];
    });
   this.getPartyId();
    this.getGlAccountInitialData();
    this.getInitialData();
    this.allPartyGlAccountFormData();
    this.getRoleAllType();
    this.getChartAc();
    if(this.party){
      this.show=true;
      setTimeout(() => {
        const formValue = this.createParty;
      formValue.patchValue({
        partyId: this.party,
        roleType: this.role,
        glAccountType:  this.accountype,
        glAccountId:this.glaccountid
      })
      }, 2000);
    }else{
      this.show=false;
    }
  }
  getChartAc() {
    this.spinner.show();
    this.accountsService.getOrganizationMainChartOfAccount(this.finSize,this.partyId).subscribe((res: any) => {
      this.glChartOfAccount = res.data;
      this.spinner.hide();
      for (const value of this.glChartOfAccount) {
        this.glChartOfAccountArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }
    })
  
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.assignGLPartyId = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.assignGLPartyId) {
        this.assignGLPartyIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }





    })
   
  }
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
  allPartyGlAccountFormData() {
    this.spinner.show();
    this.accountsService.allPartyGlAccountFormData().subscribe(res => {
      this.roleId = res.data.roleTypeIdWithPartyId;
      this.partyIdData = res.data.roleTypeIdWithPartyId;
     
      this.spinner.hide();
    
      for (const value of this.roleId) {
        this.roleIdArray.push({
          label: value.roleTypeId,
          value: value.roleTypeId
        })
      }
      for (const value of this.partyIdData) {
        this.partyIdDataArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  getInitialData() {
    this.spinner.show();
    this.accountsService.getInitialDataAccountTypeDefault().subscribe(res => {
      this.glAccountType = res.data.glAccountTypeList;
     
      this.spinner.hide();
    
      for (const value of this.glAccountType) {
        this.glAccountTypeArray.push({
          label: value.description,
          value: value.glAccountTypeId
        })
      }
    })
   
  }
  
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "glAccountId":this.createParty.value.glAccountId,
      "glAccountTypeId": this.createParty.value.glAccountType,
      "partyId": this.createParty.value.partyId,
      "roleTypeId": this.createParty.value.roleType,
      "organizationPartyId":  this.partyId,
    }
    this.accountsService.createPartyGlAccounts(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "glAccountId":this.createParty.value.glAccountId,
      "glAccountTypeId": this.createParty.value.glAccountType,
      "partyId": this.createParty.value.partyId,
      "roleTypeId": this.createParty.value.roleType,
      "organizationPartyId":  this.partyId,
    }
    this.accountsService.updatePartyGlAccounts(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  
  cancelSubmit(){
    this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
  }

}


