<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-grey titlePanels">Communications of party : {{this.commEventIdPartyId}}                </span>
                <span class="" style="margin-left :71%;">

                <button type="submit" (click)="homeButton();" class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass ">Back</button>
</span>
            </div>
            <div class=" bg-white color-grey">


                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Communications List
                                            </h4>
                                            <span style="margin-right: 1%;" class="edit-buttons">
                                            <a *ngIf="!this.Hidebtn" data-toggle="modal"
                                            data-target="#exampleModalCenter">New Communication</a></span>
                                        </div>
                                    </header>
                                    <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">

                                                <div class="card own-account-table">
                                                    <p-table [value]="getOverviewList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        Subject
                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                    <div style="color: white;"> Comm.Type ID
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                </th>

                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                    <div style="color: white;"> Party ID From
                                                                        <p-sortIcon field="quantity"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Party ID To
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Status ID
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Entry Date
                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Role Type Id
                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Action
                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                    </div>
                                                                </th>

                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'170px','text-decoration':'none'}" (click)="accountDetails(product.communicationEventId)">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                      <span class="account-button"> {{product.subject?product.subject:''}}</span>  
                                                                       
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.communicationEventTypeId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}"> 
                                                                    {{product.partyIdFrom}}</td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.partyIdToDesc}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.statusId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.entryDate|date:'yyyy-MM-dd'}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.roleTypeId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    <button type="button"
                                                                    class="btn btn-secondary submit-btn ml-2"
                                                                    (click)="communicationEvents(product)">Remove</button>
                                                                </td>

                                                        </ng-template>
                                                    </p-table>
                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">  Add New Communication Event
            </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp">
                    <div class=" bg-white color-grey" style="margin-top: -8px;">


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="container-fluid">
                                    <ul class="tabbing-section tabbing-accordians tabClass" style="    margin-left: -3%;
                                margin-right: -3%;">

                                        <li [ngClass]="activeCategory==1?'active':''"><a>
                                    Add Communication Event</a></li>



                                    </ul>
                                </div>
                                <div class="row">
                                    <form class="w-100" [formGroup]="createCommEvent">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
        
                                            Parent Comm Event ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="parentCommEventArray" optionlabel="label" placeholder="Parent Comm Event ID" filter="true" formControlName="parCommEventID">

                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
          
                                            Comm.Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Comm.Type ID" [options]="comtypeIdArray" optionlabel="label" filter="true" formControlName="commTypeId">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
        
                                        Status</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="stausIdArray" optionlabel="label" placeholder="Status" filter="true" formControlName="status">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
          
                                        Party From</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown (onChange)="contactEventMech($event.value)" [options]="partyIdArray" optionlabel="label" placeholder="Party From" filter="true" formControlName="partyFrom">

                                                        </p-dropdown>

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
        
                                    Party To</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown (onChange)="contactEventMechTo($event.value)" [options]="partyIdArray" optionlabel="label" placeholder="Party To" filter="true" formControlName="partyTo">

                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
          
                                    Contact Mech Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="contactMechTypeArray" optionlabel="label" placeholder="Contact Mech Type ID" filter="true" formControlName="conMechId">

                                                        </p-dropdown>


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
        
                                From Contact Mech</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="contactMechArray" optionlabel="label" placeholder="From Contact Mech" filter="true" formControlName="fromContactMechId">

                                                        </p-dropdown>


                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
          
                                To Contact Mech</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="contactMechToArray" optionlabel="label" placeholder="To Contact Mech" filter="true" formControlName="toContactMechId">

                                                        </p-dropdown>


                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
        
                            Role Type Id From</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="roleTypeIdArray" optionlabel="label" placeholder="Role Type Id From" filter="true" formControlName="roleTypeFrom">

                                                        </p-dropdown>


                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
          
                            Role Type Id To</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="roleTypeIdArray" optionlabel="label" placeholder="Role Type Id To" filter="true" formControlName="roleTypeTo">

                                                        </p-dropdown>


                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
        
                        Contact List ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="contactListIdArray" optionlabel="label" placeholder="Contact List ID" filter="true" formControlName="contactListId">

                                                        </p-dropdown>


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Start Date
          
                      </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Start Date" formControlName="startdate">


                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1"> Finish Date/Time
        
                  </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Finish Date/Time" formControlName="finishDate">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Subject
          
                  </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Subject" formControlName="subject">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
        
                  Reason Enum ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="reasonenumArray" optionlabel="label" placeholder="Reason Enum ID" filter="true" formControlName="reasonEnumId">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
        
                  Content Mime Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Content Mime Type ID" filter="true" [options]="contentMMArray" optionlabel="label" formControlName="contentMimeTypeId">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Content
        
                </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Content" formControlName="content">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Note
        
                </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Note" formControlName="note">
                                                    </div>


                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 88%;">
                                        <button (click)="onSubmit();" type="submit" class="btn btn-secondary submit-btn">Save</button>


                                    </div>
                                </div>
                                <br><br><br>

                            </div>

                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>