import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountsService } from '../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';


@Component({
  selector: 'app-customer-summary',
  templateUrl: './customer-summary.component.html',
  styleUrls: ['./customer-summary.component.css']
})
export class CustomerSummaryComponent implements OnInit {
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  FsmCustomerCommercialById: any;
  FsmCustomerBio: any;
  customerIdVal: any;

  constructor(
    readonly _Router: Router,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly accountsService: AccountsService,

  ) { }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(res =>{
      this.customerIdVal = res["id"];
    });
    this.getFsmCustomerCommercialById()
  }
  getFsmCustomerCommercialById() {
    this.spinner.show();
    this.accountsService.getFsmCustomerCommercialById(this.customerIdVal).subscribe(res => {
      this.FsmCustomerCommercialById = res.data[0].getFsmCustomerCommercialById;
      this.FsmCustomerBio = this.FsmCustomerCommercialById[0];
      this.spinner.hide();
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}
