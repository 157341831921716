import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from "@angular/forms";
import {MyContactsService} from "../../crm/contacts/my-contacts/my-contacts.service";
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.css']
})
export class ImportComponent implements OnInit {
  fileData: File;
  Editform: FormGroup;
  previewUrl: string | ArrayBuffer;

  constructor(readonly myContactsService:MyContactsService,
    readonly toastr:ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly router:Router ) { }

  ngOnInit(): void {
    this.Editform = new FormGroup({
      file: new FormControl('', [
        Validators.required
      ]),
    });
  }
  fileProgress(fileInput: any) {
   
    this.fileData = <File>fileInput.target.files[0];
  
  }
  preview() {
    
    const mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }
  }

  onSubmit(){
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);
    this.myContactsService.import(formData).subscribe((res:any)=>{
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.router.navigate(['/crm/my-contacts'])
      }     },(err)=>{
        this.toastr.error(err.message);     })
        this.spinner.hide();
  }
  
}
