import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-blogs-articles-detail-page',
  templateUrl: './blogs-articles-detail-page.component.html',
  styleUrls: ['./blogs-articles-detail-page.component.css']
})
export class BlogsArticlesDetailPageComponent implements OnInit {
  activeCategory= 2;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  createblogForm: FormGroup;
  createNewArticleForm: FormGroup;
  editArticlesForm: FormGroup;
  updateArticleForm: FormGroup;
  updateViewForm: FormGroup;

  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
  )
  {
    this.createblogForm = this._FormBuilder.group({
      blogName:"",
      blogDescription:"",
    });

    this.updateViewForm = this._FormBuilder.group({
      image:"",
      summary:"",
      article:"",
    });
    

    this.createNewArticleForm = this._FormBuilder.group({
      articleName:"",
      description:"",
      summary:"",
      image:"",
      template:"",
      status:"",
    });

    this.updateArticleForm=this._FormBuilder.group({
      blogEntryID:"",
      articleName:"",
      description:"",
      summary:"",
      article:"",
      image:"",
      template:"",
      status:"",
    })

  }

  ngOnInit(): void {
  }

  openList()
  {
    this._Router.navigate(['/content/blog'])
  }
  openEdit()
  {
    this._Router.navigate(['/content/blog/blog-detail-page'])

  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });

    
}

ngOnDestroy(): void {
  this.spinner.hide();
    }

changeactiveCategory(tab: number)
{
  this.activeCategory = tab;

}

}
