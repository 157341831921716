import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { FacilityGroupService } from '../facility-group/facility-group.service';

@Component({
  selector: 'app-detail-facility-group-summary',
  templateUrl: './detail-facility-group-summary.component.html',
  styleUrls: ['./detail-facility-group-summary.component.css']
})
export class DetailFacilityGroupSummaryComponent implements OnInit {
  activeCategory=1;
  id: any;
  groupid: any;
  parentId: any;
  fGroupName: any;
  des: any;
  editMode: boolean;
  facilityGroup:FormGroup;
  products3: any;
  @ViewChild('closebutton') closebutton;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  parentGroupListDropdown: any = [];
  facilityGroupList: any = [];
  facilityGroupTypeList: any = [];
  facilityId: any = [];
  facilityGroupName: any;
  success: any;
  primaryParentGroupId: any;
  facilityGroupId: any;
  facilityGroupTypeListArray: any[]=[];
  parentGroupListDropdownArray: any[]=[];
  facid: any;

  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly accountsService: AccountsService,
    readonly facilityGroupService: FacilityGroupService,
 
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder
  ) { 
    this.facilityGroup = this._FormBuilder.group({
      facilityGroupTypeId: [null, [Validators.required]],
      facilityGroupName:[null, [Validators.required]],
      facilityGroupId: null,
      primaryParentGroupId: null,
      description: null,
     
    });
    this.editMode = true;
  }
 
  ngOnInit(): void {
   
   
   
    this.getparentGroupList();
    this.getFacilityGroupList();
    this.getfacilityGroupTypeList();
    this.activatedRoute.queryParams.subscribe(params=> [
      this.facid = params["id"],
      this.groupid=params["groupid"],
      this.parentId=params["parentId"],
      this.fGroupName=params["fGroupName"],
      this.des=params["des"],
    ])
    
  }
  getparentGroupList() {
    this.spinner.show();
    this.facilityGroupService.getparentGroupList().subscribe((responce) => {
      this.parentGroupListDropdown = responce.data;
      this.spinner.hide();
      for(const value of this.parentGroupListDropdown) {
        this.parentGroupListDropdownArray.push({
          label:value.facilityGroupId,
          value:value.facilityGroupId
        })
      }
    
    }, (error) => {
      return;
    });
   
  }
 
  getFacilityGroupList() {
    this.spinner.show();
    this.facilityGroupList = [];
    this.facilityGroupService.getFacilityGroupList().subscribe((responce) => {
      this.spinner.hide();
      this.facilityGroupList = responce.data;
    }, (error) => {
      return;
    });
   
  }

  getfacilityGroupTypeList() {
    this.spinner.show();
    this.facilityGroupService.getFacilityGroupTypeList().subscribe((responce) => {
      this.spinner.hide();
      this.facilityGroupTypeList = responce.data;
      for(const value of this.facilityGroupTypeList) {
        this.facilityGroupTypeListArray.push({
          label:value.description,
          value:value.facilityGroupTypeId
        })
      }
    }, (error) => {

      return
    });
   
  }
  
  onSubmit(): void {
    this.spinner.show();
    if (this.editMode) {
      const id = this.facid;
      this.facilityGroupService.updateFacilityGroup(id,{...this.facilityGroup.value }).subscribe((res: any) => {
    
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.getFacilityGroupList();
          this.spinner.hide();
        
          this.facilityGroup.reset();
         
          this.closebutton.nativeElement.click();
          this.router.navigate(["facilities/facility-group"])
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.error) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
     
    } else {
      this.facilityGroupService.createFacilityGroup(this.facilityGroup.value).subscribe((res: any) => {
      
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.getFacilityGroupList();
          this.closebutton.nativeElement.click();
          this.facilityGroup.reset();
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    }
 
  }

  getData(){
        this.editMode = true;
        this.facilityGroup.patchValue({
          facilityGroupTypeId:  this.groupid,
          facilityGroupName: this.fGroupName,
          description: this.des,
          facilityGroupId: this.facid,
          primaryParentGroupId: this.parentId
        });
    
  }
  cancelSubmit(){
    this.router.navigate(["facilities/facility-group"])
  }

}

