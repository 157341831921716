import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-gl-update-custom-time-period',
  templateUrl: './gl-update-custom-time-period.component.html',
  styleUrls: ['./gl-update-custom-time-period.component.css']
})
export class GlUpdateCustomTimePeriodComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  show: boolean;
  activeCategory = 2;
  createTimePeriod: FormGroup;
  periodType: any;
  periodTypeArray: any[] = [];
  parentPeriodId: any;
  parentPeriodIdArray: any[] = [];
  actualfromdate: string;
  actualthroughdate: string;
  customTimePeriodId: any;
  getEstimateFrom: string;
  getEstimateTo: string;  
  editinfo: any;

  constructor(
    readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
  ) {
    this.createTimePeriod = this._FormBuilder.group({
      fromDate: ['',[Validators.required]],
      isClosed: [''],
      organizationPartyId: ['',[Validators.required]],
      parentPeriodId: [''],
      periodName: [''],
      periodNum: [''],
      periodTypeId: ['',[Validators.required]],
      thruDate: ['',[Validators.required]],

    });
   }
   closed = [{
    label: 'Y',
    value: 'Y',
  },
  {
    label: 'N',
    value: 'N',
  }]

  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params=> {
      this.customTimePeriodId = params["customTimePeriodId"];
     
    });
    if(this.customTimePeriodId){
      this.show=true;
      this.editCustomTimePeriod();
    
    }
    else{
      this.show=false;
    }
    this.getCustomTimePeriodDrop();
    
  }

  editCustomTimePeriod() {
    this.spinner.show();
    this.accountsService.getEditTimePeriod(this.customTimePeriodId).subscribe((res: any) => {
     this.editinfo=res.data;
      this.spinner.hide();
      setTimeout(() => {
        const fromdate= res.data.fromDate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const toEstdate= res.data.thruDate
        this.getEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
        const formValue = this.createTimePeriod;
        formValue.patchValue({
          fromDate:  this.getEstimateFrom,
          isClosed: res.data.isClosed,
          organizationPartyId: res.data.organizationPartyId,
          parentPeriodId:res.data.parentPeriodId,
          periodName:res.data.periodName,
          periodNum:res.data.periodNum,
          periodTypeId: res.data.periodTypeId,
          thruDate: this.getEstimateTo,
        })
      }, 3000);
    })
  
  }
  getCustomTimePeriodDrop() {
    this.spinner.show();
    this.accountsService.getCustomTimePeriodDropdownValue().subscribe((res: any) => {
      this.periodType = res.data.periodType;
      this.parentPeriodId = res.data.parentPeriodId;
      this.spinner.hide();
      for (const value of this.periodType) {
        this.periodTypeArray.push({
          label: value.description,
          value: value.periodTypeId
        })
      }
      for (const value of this.parentPeriodId) {
        this.parentPeriodIdArray.push({
          label:value.organizationPartyId + ':' + value.parentPeriodId + ' ' + value.periodTypeId +':'+ value.periodNum + ' [' + value.customTimePeriodId + ']',
          value: value.customTimePeriodId
        })
      }
  

    })
   
  }
  cancelSubmit() {
    this.router.navigate(["financial/gl-custom-time-period-list"])
  }
  
  onUpdate(){
    this.spinner.show();
    const fromActualdate = this.createTimePeriod.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const throughActualdate = this.createTimePeriod.get('thruDate').value;
    this.actualthroughdate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "currentCustomTimePeriodId": "",
      "customTimePeriodId": this.customTimePeriodId,"findOrganizationPartyId": "",
      "fromDate": this.actualfromdate ,
      "isClosed": this.createTimePeriod.value.isClosed,
      "organizationPartyId": this.createTimePeriod.value.organizationPartyId,
      "parentPeriodId": this.createTimePeriod.value.parentPeriodId,
      "periodName": this.createTimePeriod.value.periodName,
      "periodNum": this.createTimePeriod.value.periodNum,
      "periodTypeId": this.createTimePeriod.value.periodTypeId,
      "thruDate": this.actualthroughdate, "useValues": "true"
    }

    this.accountsService.updateCustomTimePeriod(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.createTimePeriod.reset();
        this.closebutton.nativeElement.click();
        this.router.navigate(["financial/gl-custom-time-period-list"])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }});
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }

}
