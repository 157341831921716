<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Calender Detail</span> <span></span>
               <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>


            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                Calender Information</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                 Exception Day</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                   Exception Week</a></li>



                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                <div *ngIf="activeCategory==1">
                                                    <div>
                                                        <div class="container-fluid">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs" style="margin-left: -15px;
                                  margin-right: -14px;">
                                                                    <h4 class=" common-styling h4Margin">
                                                                        Calender Information
                                                                    </h4>
                                                                    <span class="edit-buttons hCreate">
          
                                        <a   data-toggle="modal" (click)="edit();"
                                        data-toggle="modal"
                                        data-target="#exampleModalCenter">Update</a>
                                      </span>
                                                                </div>
                                                            </header>

                                                        </div>
                                                        <div class="create-new-leade mt-2">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100">
                                                                        <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                                                            <div class="w-100">
                                                                                <div class="panel-group">
                                                                                    <div *ngIf="activeCategory==1">

                                                                                        <div class="panel-body">
                                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                <div class="color-black container">
                                                                                                    <div class="row">
                                                                                                        <div class="article-container">
                                                                                                            <div class="article">
                                                                                                                <p class="col-lg-6 col-12">Calendar ID</p>
                                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.calendarId}}</span>
                                                                                                            </div>
                                                                                                            <div class="article">
                                                                                                                <p class="col-lg-6 col-12">Description </p>
                                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.description}}</span>

                                                                                                            </div>
                                                                                                            <div class="article">
                                                                                                                <p class="col-lg-6 col-12">Calendar Week ID </p>
                                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.calendarWeekId}}</span>

                                                                                                            </div>





                                                                                                        </div>




                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==2">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Edit Calendar Exception day for "Calendar used for Re-Order date calculation for bought product" [ID {{this.calendarId}}]

                                                        </h4>
                                                        <span class="edit-buttons">
                              <a  data-toggle="modal"
                              data-target="#exampleModalCenterException">Create New Exception Day</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="ExceptionDay" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'300px'}">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>




                                                                                        Date and Time of exception day
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Description
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name" [ngStyle]="{'width':'230px'}">
                                                                                    <div style="color: white;"> Manufacturing Calendar Capacity
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name" [ngStyle]="{'width':'200px'}">
                                                                                    <div style="color: white;"> Manufacturing Used Capacity
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                                <th pSortableColumn="category" [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;">Action
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'300px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{product.exceptionDateStartTime|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{product.description}} </td>
                                                                                <td [ngStyle]="{'width':'230px'}"> {{product.exceptionCapacity}} </td>
                                                                                <td [ngStyle]="{'width':'200px'}"> {{product.usedCapacity}} </td>

                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <svg class="hover" (click)="updateDay(product.exceptionDateStartTime,product.description,product.exceptionCapacity,product.usedCapacity);" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222"><g transform="translate(0 -0.004)"><g transform="translate(0 1.52)"><path d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z" transform="translate(0 -34.137)"/></g><g transform="translate(4.548 0.004)"><g transform="translate(0 0)"><path d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                               transform="translate(-102.409 -0.004)"/></g></g></g></svg>

                                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteDay(product.exceptionDateStartTime)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                               <defs>
                                                   <style>
                                                       .a {
                                                           fill: #f44336;
                                                       }
     
                                                       .b {
                                                           fill: #fafafa;
                                                           margin-left: 20px;
                                                       }
                                                   </style>
                                               </defs>
                                               <path class="a fill-color"
                                                   d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                               <path class="b"
                                                   d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                   transform="translate(-147.576 -147.576)" />
                                           </svg></td>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                  <h4 class="ml-2">No Record Found
                                                  </h4>
                                              </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Edit Calendar Exception week for "Calendar used for Re-Order date calculation for bought product" [ID {{this.calendarId}}]

                                                        </h4>
                                                        <span class="edit-buttons">
                                <a  data-toggle="modal"
                                data-target="#exampleModalCenterWeek">Create New Calendar Exception week</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="ExceptionWeek" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'300px'}">
                                                                                    <div class="checkbox-align" style="color: white;">

                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Calender Week Id


                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Description
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name" [ngStyle]="{'width':'230px'}">
                                                                                    <div style="color: white;"> Exception Date Start
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>


                                                                                <th pSortableColumn="category" [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;">Action
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'300px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{product.calendarWeekId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{product.description}} </td>

                                                                                <td [ngStyle]="{'width':'200px'}"> {{product.exceptionDateStart|date:'yyyy-MM-dd hh:mm:ss'}} </td>

                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <svg class="hover" (click)="updateWeek(product.exceptionDateStart,product.description,product.calendarWeekId);" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222"><g transform="translate(0 -0.004)"><g transform="translate(0 1.52)"><path d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z" transform="translate(0 -34.137)"/></g><g transform="translate(4.548 0.004)"><g transform="translate(0 0)"><path d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                 transform="translate(-102.409 -0.004)"/></g></g></g></svg>

                                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteWeek(product.exceptionDateStart)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                 <defs>
                                                     <style>
                                                         .a {
                                                             fill: #f44336;
                                                         }
       
                                                         .b {
                                                             fill: #fafafa;
                                                             margin-left: 20px;
                                                         }
                                                     </style>
                                                 </defs>
                                                 <path class="a fill-color"
                                                     d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                 <path class="b"
                                                     d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                     transform="translate(-147.576 -147.576)" />
                                             </svg>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                    <h4 class="ml-2">No Record Found
                                                    </h4>
                                                </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>

                                        </div>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="main-submit-button">
                    <button type="submit" class="btn btn-secondary submit-btn">Create Lead</button>
                </div> -->
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">  Update Calender</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''" *ngIf="!this.show"><a>
                            Add Calender
    
                        </a></li>
                                <li [ngClass]="activeCategory==1?'active':''" *ngIf="this.show"><a>
                            Update Calender
    
                        </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createCalender">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                        Calendar ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="!this.show">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Calendar ID" formControlName="CalendarID">

                                                    </div>
                                                    <div class="col-lg-3" *ngIf="this.show">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Calendar ID" formControlName="CalendarID" readonly>

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                        Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" formControlName="Description">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                        Calendar Week ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Calendar Week ID" filter="true" formControlName="CalendarWeekID" [options]="weekListArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2"></div>

                                                </div>
                                            </div>





                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -12% !important;">
                                        <button type="submit" *ngIf="!this.show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="this.show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenterException" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterException" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">  Add Calendar Exception Week</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebuttonA>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryMain==2?'active':''" *ngIf="!this.showException"><a>
                    Add Calendar Exception Week
                </a></li>
                                <li [ngClass]="activeCategoryMain==2?'active':''" *ngIf="this.showException"><a>
                    Update Calendar Exception Week
                </a></li>
                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createDay">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormSS">
                                                        <label for="exampleInputEmail1">
                                                Date and Time of exception day<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="!this.showException">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Mrp Name" formControlName="exceptionday">
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.showException">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Mrp Name" formControlName="exceptionday" readonly>

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                Manufacturing Calendar Capacity</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Manufacturing Calendar Capacity" formControlName="ManufacturingCalendarCapacity">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormSS">
                                                        <label for="exampleInputEmail1">
                                                Manufacturing Used Capacity</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Manufacturing Used Capacity" formControlName="ManufacturingUsedCapacity">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" formControlName="Description">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -18% !important;">


                                        <button type="submit" *ngIf="!this.showException" (click)="onSubmitException()" class="btn btn-secondary submit-btn">Save</button>

                                        <button type="submit" *ngIf="this.showException" (click)="onUpdateException()" class="btn btn-secondary submit-btn">Update</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenterWeek" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterWeek" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">  Add Calendar Exception Week</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebuttonB>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryMain==2?'active':''" *ngIf="!this.showWeek"><a>
                    Add Calendar Exception Week
                </a></li>

                                <li [ngClass]="activeCategoryMain==2?'active':''" *ngIf="this.showWeek"><a>
                    Update Calendar Exception Week
                </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createDayWeek">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormGF">
                                                        <label for="exampleInputEmail1">
                                                Start of Exception Date<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="!this.showWeek">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Mrp Name" formControlName="exceptionday">
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.showWeek">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Mrp Name" formControlName="exceptionday" readonly>

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                Calendar Week ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Calendar Week ID" filter="true" formControlName="CalendarWeekID" [options]="weekListArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormGF">
                                                        <label for="exampleInputEmail1">
                                                Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" formControlName="Description">
                                                    </div>
                                                    <div class="col-lg-2"></div>

                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -21% !important;">

                                        <button type="submit" *ngIf="!this.showWeek" (click)="onSubmitWeek()" class="btn btn-secondary submit-btn">Save</button>

                                        <button type="submit" *ngIf="this.showWeek" (click)="onUpdateWeek()" class="btn btn-secondary submit-btn">Update</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>