import { Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-company-information-import',
  templateUrl: './company-information-import.component.html',
  styleUrls: ['./company-information-import.component.css']
})
export class CompanyInformationImportComponent implements OnInit {
  @ViewChild('closebutton2') closebutton2;
  activeCategory=1;
  activeCategoryOne=1;
  activeCategoryTwo=1;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  userLoginIdArray:any[]=[];
  stateArray: any[]=[];
  state: any;
  roleTypeId: any;
  roleTypeIdArray: any[]=[];
  userLogin: any;
  findPartyArray: any[]=[];
  identification: any;
  identificationArray: any[]=[];
  fileData: File;
  updateUserForm: FormGroup;
  createPlan: FormGroup;
  tenantDetails:any;
  createSecondaryCompanyForm:FormGroup;
  email: string;
  companyData: any;
  userDetailsData: any;
  yesNoArray: { label: string; value: string; }[];
  constructor(readonly router: Router,
    readonly spinner:NgxSpinnerService,
    readonly accountsService: AccountsService,
    readonly toastr: ToastrService,
    readonly datePipe: DatePipe,

    readonly _FormBuilder: FormBuilder,   private _location: Location,
    
    ) {
    // this.createPlan = this._FormBuilder.group({
      
    //   firstname: ['', [Validators.required]],
    //   lastname: ['', [Validators.required]],
    //   phone: ['', [Validators.required]],
    //   mail: ['', [Validators.required]],
    //   companyName:['', [Validators.required]],

    // });
     this.createPlan = this._FormBuilder.group({
       noOfUsers: [''],
       additionalUsers: [''],
       ownerName: ['', [Validators.required]],
       tenantName: ['', [Validators.required]],
       addressLine1: [''],
       addressLine2: [''],
       addressLine3: [''],
       countryGeoId: [''],
       stateGeoId: [''],
       city: [''],
       zipcode: [''],
       irsTaxIdNo: ['', [Validators.required]],
       taxIdProofUrl: [''],
       typeOfBusiness: [''],
       sellOnline: [''],
       professionalWebsite: [''],
       noOfEmployees: [''],
       addressLine1Bill: ['', [Validators.required]],
       addressLine2Bill: [''],
       addressLine3Bill: [''],
       countryGeoIdBill: ['', [Validators.required]],
       stateGeoIdBill: ['', [Validators.required]],
       cityBill: ['', [Validators.required]],
       zipcodeBill: ['', [Validators.required]],
       title: ['', [Validators.required]],
       firstname: ['', [Validators.required]],
       lastname: ['', [Validators.required]],
       middlename: [''],
       phone: ['', [Validators.required]],
       mail: ['', [Validators.required]],
       titleAlt: [''],
       firstnameAlt: [''],
       lastnameAlt: [''],
       middlenameAlt: [''],
       phoneAlt: [''],
       mailAlt: [''],
       paymentMethod: [''],
       creditCardNo: [''],
       expDate: [''],
       expMonth: [''],
       nameOnCard: [''],
       Title:[''],
       additionalUser:[''],

     });

     this.createSecondaryCompanyForm = this._FormBuilder.group({
      noOfUsers: [''],
      additionalUsers: [''],
      ownerName: ['', [Validators.required]],
      tenantName: ['', [Validators.required]],
      addressLine1: [''],
      addressLine2: [''],
       expDate:[''],
       addressLine3: [''],
      countryGeoId: [''],
      stateGeoId: [''],
      city: [''],
      zipcode: [''],
      irsTaxIdNo: ['', [Validators.required]],
      taxIdProofUrl: [''],
      typeOfBusiness: [''],
      sellOnline: [''],
      professionalWebsite: [''],
      noOfEmployees: [''],
      addressLine1Bill: ['', [Validators.required]],
      addressLine2Bill: [''],
      addressLine3Bill: [''],
      countryGeoIdBill: ['', [Validators.required]],
      stateGeoIdBill: ['', [Validators.required]],
      cityBill: ['', [Validators.required]],
      zipcodeBill: ['', [Validators.required]],
      title: ['', [Validators.required]],
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      middlename: [''],
      phone: ['', [Validators.required]],
      mail: ['', [Validators.required]],
      titleAlt: [''],
      firstnameAlt: [''],
      lastnameAlt: [''],
      middlenameAlt: [''],
      phoneAlt: [''],
      mailAlt: [''],
      paymentMethod: [''],
      creditCardNo: [''],
      expMonth: [''],
      nameOnCard: [''],
      Title:[''],
      additionalUser:[''],

    });
    this.updateUserForm = this._FormBuilder.group({
      companyName: [''],
      contactNo: [''],
      createdDate:[''],
      emailId: [''],
      firstName: [''],
      id: [''],
      isActive: [''],
      isDeleted:[''],
      lastName: [''],
      password: [''],
      role: [''],

    });
    this.yesNoArray = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      },

    ];
   }

  ngOnInit(): void {
   this.email = localStorage.getItem('email');

   this.userDetailsSaas();
  }
  updateUserDetails(){
    this.spinner.show();
    const requestData = {

      "companyName": this.updateUserForm.value.companyName,
      "contactNo":  this.updateUserForm.value.contactNo,
      "createdDate": this.datePipe.transform(this.updateUserForm.value.createdDate, 'yyyy-MM-dd hh:mm:ss') ,
      "emailId": this.updateUserForm.value.emailId,
      "firstName":this.updateUserForm.value.firstName,
      "id": '',
      "isActive": this.updateUserForm.value.isActive,
      "isDeleted":this.updateUserForm.value.isDeleted,
      "lastName": this.updateUserForm.value.lastName,
      "password": this.updateUserForm.value.password,
      "role": "ROLE_USER"
    }

    this.accountsService.updateUserDetails(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.updateUserForm.reset();
        this.userDetailsSaas();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  userDetailsSaas() {
    this.spinner.show();
    this.accountsService.userDetailsSaas(this.email).subscribe(res => {
      this.tenantDetails = res.data.tenantDetails;
      this.userDetailsData = res.data.userDetails;
      this.spinner.hide();
    
    })

  }
  homeButton(){
    this.router.navigate(['/users/company-information-import'])
  
  }
  cancelSubmit(){
   this._location.back();
  }
  changeactiveCategoryTwo(tab: number) {
    this.activeCategoryTwo = tab;
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategoryOne = tab;
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }
  goToFiscalYear(){
    this.router.navigate(['/users/fiscal-year-import'])
  }
}
