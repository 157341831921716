import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProductionService } from '../production.service';

@Component({
  selector: 'app-create-new-week',
  templateUrl: './create-new-week.component.html',
  styleUrls: ['./create-new-week.component.css']
})
export class CreateNewWeekComponent implements OnInit {
  createWeek: FormGroup;
  activeCategory = 2;
  calendarWeekId: any;
  show: boolean;

  constructor(readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,

    readonly spinner: NgxSpinnerService,
    readonly productionService: ProductionService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) {
    this.createWeek = this._FormBuilder.group({
      CalendarWeekID: [''],
      Description: [''],
      MondayStartTime: [''],
      MondayCapacity: [''],
      TuesdayStartTime: [''],
      TuesdayCapacity: [''],
      WednesdayStartTime: [''],
      WednesdayCapacity: [''],
      ThursdayStartTime: [''],
      ThursdayCapacity: [''],
      FridayStartTime: [''],
      FridayCapacity: [''],
      SaturdayStartTime: [''],
      SaturdayCapacity: [''],
      SundayStartTime: [''],
      SundayCapacity: [''],

    });

  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.calendarWeekId = params["calendarWeekId"];

    });
    if(this.calendarWeekId){
      this.show=true;
      this.getProdWeekById();
    }else{
      this.show=false;
    }
  }
  getProdWeekById() {
    this.spinner.show();
    this.productionService.getProdWeekById(this.calendarWeekId).subscribe((res: any) => {
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.createWeek;
        formValue.patchValue({
          CalendarWeekID:res.data.calendarWeekId,
          Description: res.data.description,
          MondayStartTime: res.data.mondayStartTime,
          MondayCapacity: res.data.mondayCapacity,
          TuesdayStartTime: res.data.tuesdayStartTime,
          TuesdayCapacity: res.data.tuesdayCapacity,
          WednesdayStartTime: res.data.wednesdayStartTime,
          WednesdayCapacity: res.data.wednesdayCapacity,
          ThursdayStartTime: res.data.thursdayStartTime,
          ThursdayCapacity: res.data.thursdayCapacity,
          FridayStartTime: res.data.fridayStartTime,
          FridayCapacity: res.data.fridayCapacity,
          SaturdayStartTime: res.data.saturdayStartTime,
          SaturdayCapacity: res.data.saturdayCapacity,
          SundayStartTime: res.data.sundayStartTime,
          SundayCapacity: res.data.sundayCapacity,
        })
      
      }, 2000);
    })
  
  }
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "calendarWeekId":  this.createWeek.value.CalendarWeekID,
      "description":  this.createWeek.value.Description,
      "fridayCapacity": this.createWeek.value.FridayCapacity,
      "fridayStartTime":  this.createWeek.value.FridayStartTime,
      "mondayCapacity":  this.createWeek.value.MondayCapacity,
      "mondayStartTime":  this.createWeek.value.MondayStartTime,
      "saturdayCapacity":  this.createWeek.value.SaturdayCapacity,
      "saturdayStartTime":  this.createWeek.value.SaturdayStartTime,
      "sundayCapacity":  this.createWeek.value.SundayCapacity,
      "sundayStartTime":  this.createWeek.value.SundayStartTime,
      "thursdayCapacity":  this.createWeek.value.ThursdayCapacity,
      "thursdayStartTime":  this.createWeek.value.ThursdayStartTime,
      "tuesdayCapacity":  this.createWeek.value.TuesdayCapacity,
      "tuesdayStartTime":  this.createWeek.value.TuesdayStartTime,
      "wednesdayCapacity":  this.createWeek.value.WednesdayCapacity,
      "wednesdayStartTime":  this.createWeek.value.WednesdayStartTime,
    }
    this.productionService.postProdWeek(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this._Router.navigate(['/production/week-list']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    }); }
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "calendarWeekId":  this.createWeek.value.CalendarWeekID,
      "description":  this.createWeek.value.Description,
      "fridayCapacity": this.createWeek.value.FridayCapacity,
      "fridayStartTime":  this.createWeek.value.FridayStartTime,
      "mondayCapacity":  this.createWeek.value.MondayCapacity,
      "mondayStartTime":  this.createWeek.value.MondayStartTime,
      "saturdayCapacity":  this.createWeek.value.SaturdayCapacity,
      "saturdayStartTime":  this.createWeek.value.SaturdayStartTime,
      "sundayCapacity":  this.createWeek.value.SundayCapacity,
      "sundayStartTime":  this.createWeek.value.SundayStartTime,
      "thursdayCapacity":  this.createWeek.value.ThursdayCapacity,
      "thursdayStartTime":  this.createWeek.value.ThursdayStartTime,
      "tuesdayCapacity":  this.createWeek.value.TuesdayCapacity,
      "tuesdayStartTime":  this.createWeek.value.TuesdayStartTime,
      "wednesdayCapacity":  this.createWeek.value.WednesdayCapacity,
      "wednesdayStartTime":  this.createWeek.value.WednesdayStartTime,
    }
    this.productionService.updateProdWeek(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this._Router.navigate(['/production/week-list']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }  });}

  cancelSubmit() {
    this._Router.navigate(['/production/week-list']);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}


