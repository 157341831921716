import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
@Component({
  selector: 'app-create-gl-product-category-account',
  templateUrl: './create-gl-product-category-account.component.html',
  styleUrls: ['./create-gl-product-category-account.component.css']
})
export class CreateGlProductCategoryAccountComponent implements OnInit {
  createProduct:FormGroup;
  total=0;
  rowNumber=0;
  pageNo = 1;
  pageSize= 100;
  rows = 50;
 
  categorySize;

  search: {
    categoryId: string,
    categoryIdSearchType: string,
    categoryName: string,
    categoryNameSearchType: string
  };
  activeCategory=2;
  glAccountType: any;
  glAccountTypeArray: any[]=[];
  partyId: any;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[]=[];
  categoryList: any;
  categoryListArray: any[]=[];
  TypeId: any;
  productCatId: any;
  glAccountId: any;
  show: boolean;
  
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  glChartOfAccount: any;
  glChartOfAccountArray: any[]=[];
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _MainCatalogService: MainCatalogService,
    readonly spinner: NgxSpinnerService,
  
    readonly _ToastrService: ToastrService,) { 
      this.categorySize = {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      };
      this.search = {
        categoryId: '',
        categoryIdSearchType: 'Contains',
        categoryName: '',
        categoryNameSearchType: 'Contains'
      };
      this.createProduct = this._FormBuilder.group({
        accountType: [''],
        accountId:[''],
        productId:['']
       
        
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
      this.glAccountId = params["glAccountId"];

      this.productCatId = params["productCatId"];
      this.TypeId = params["TypeId"];
     
    });
    

    this.getCategoryList();
    this.getChartAc();
    this.getGlAccountInitialData();
    this.getInitialData();
    if(this.productCatId){
      this.show=true;
      setTimeout(() => {
        const formValue = this.createProduct;
      formValue.patchValue({
        accountType: this.TypeId,
        accountId:this.glAccountId,
        productId:this.productCatId
       
      })
      }, 2000);

    }else{
      this.show=false;
    }
  }
  getChartAc() {
    this.spinner.show();
    this.accountsService.getOrganizationMainChartOfAccount(this.finSize,this.partyId).subscribe((res: any) => {
      this.glChartOfAccount = res.data;
      this.spinner.hide();
      for (const value of this.glChartOfAccount) {
        this.glChartOfAccountArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }
    })
  
  }
  cancelSubmit(){
    this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
  }
  getCategoryList(): void {
    this.spinner.show();
    this._MainCatalogService.getCategories(this.categorySize, this.search)
      .then(data => {
        this.categoryList = data.data;
        for (const value of this.categoryList) {
          this.categoryListArray.push({
            label: value.productCategoryId,
            value: value.productCategoryId
          })
        }
        this.spinner.hide();
      });
     
  }
  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.assignGLPartyId = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.assignGLPartyId) {
        this.assignGLPartyIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }





    })
   
  }
  getInitialData() {
    this.spinner.show();
    this.accountsService.getInitialDataAccountTypeDefault().subscribe(res => {
      this.glAccountType = res.data.glAccountTypeList;
     
      this.spinner.hide();
    
      for (const value of this.glAccountType) {
        this.glAccountTypeArray.push({
          label: value.description,
          value: value.glAccountTypeId
        })
      }
    })
   
  }
  
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "glAccountId":this.createProduct.value.accountId,
      "glAccountTypeId": this.createProduct.value.accountType,
      "organizationPartyId":  this.partyId,
      "productCategoryId":this.createProduct.value.productId
        
    }
    this.accountsService.updateProductCategoryGlAccount(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  onSubmit() {
    this.spinner.show();
    const requestData = {   
      "glAccountId":this.createProduct.value.accountId,
      "glAccountTypeId": this.createProduct.value.accountType,
      "organizationPartyId":  this.partyId,
      "productCategoryId":this.createProduct.value.productId
    }
    this.accountsService.postProductCategoryGlAccount(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
