<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Timesheet ID: {{this.partyName}} {{"[" + this.partyId + "]"}} </span>
               <span class="">

                <button type="submit" (click)="homeButton();"
                 class=" btn btn-outline-secondary mr-2">Home</button>
     
               <button type="submit" (click)="cancelSubmit();" 
                 class="btn btn-danger buttonclass ">Back</button>
            </span>
         </div>
            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                        <li [ngClass]="activeCategory==1?'active':''" (click)="projectMain()">
                            <a>Main</a>
                        </li>
                        <li [ngClass]="activeCategory==2?'active':''" (click)="myTasks()">
                            <a>My Tasks</a>
                        </li>
                        <li [ngClass]="activeCategory==3?'active':''" (click)="myTime()">
                            <a>My Time</a>
                        </li>
                        <li [ngClass]="activeCategory==4?'active':''" (click)="projects()">
                            <a>Projects</a>
                        </li>
                        <li [ngClass]="activeCategory==5?'active':''" (click)="tasks()">
                            <a>Tasks</a>
                        </li>
                        <li [ngClass]="activeCategory==6?'active':''" (click)="resources()">
                            <a>Resources</a>
                        </li>
                        <li [ngClass]="activeCategory==7?'active':''" (click)="timesheet()">
                            <a>Timesheet</a>
                        </li>
                        <li [ngClass]="activeCategory==8?'active':''" (click)="skillType()">
                            <a>Skill Type</a>
                        </li>
                        <li [ngClass]="activeCategory==9?'active':''" (click)="requestList()">
                            <a>Request List</a>
                        </li>
                    </ul>
                </div>
                <div class=" bg-white color-grey">
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">

                                    <div class="w3-card-4 classCardView mt-2">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling ml-2">
                                                Timesheet Nbr
                                            </h4>
                                            <button type="submit" class="btn btn-secondary submit-btn mr-2" data-toggle="modal" data-target="#exampleModalCenter">New</button>


                                        </div>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="timeSheetLists" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>

                                                                            Task Name
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Rate Type ID
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'70px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Mon
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'70px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Tue
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'70px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Wen
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'70px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Thu
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'70px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Fri
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'70px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Sat
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'70px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Sun
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Actual Hours
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'120px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Planned Hours
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                                        <div style="color: white;">

                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>




                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span  class="account-button">
                                                                                {{product.taskName}}</span>

                                                                        </div>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.rateTypeID}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'70px'}">
                                                                        {{product.mon}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.tue}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'70px'}">
                                                                        {{product.wed}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'70px'}">
                                                                        {{product.thur}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'70px'}">
                                                                        {{product.fri}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'70px'}">
                                                                        {{product.sat}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'70px'}">
                                                                        {{product.sun}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'100px'}">
                                                                        {{product.hours}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'120px'}">

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                        <button type="submit" class="btn btn-secondary " data-toggle="modal" data-target="#exampleModalTitle">Update</button>
                                                                    </td>


                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                                <form class="w-100">


                                    <div class="w3-card-4 classCardView ">
                                        <header class="w3-container w3-blue">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling h4Margin">
                                                    Timesheet list of {{this.partyName}}

                                                </h4>
                                                <button type="submit" class="btn btn-secondary submit-btn mr-2" data-toggle="modal" data-target="#exampleModalCenter">New</button>


                                            </div>
                                        </header>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="timeSheetLists" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            Timesheet ID
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Status ID
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            From Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Through Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Hours
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Comments
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Status to 'complete'
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>



                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span >
                                                                                {{product.timeSheetId}}</span>

                                                                        </div>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.statusId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.hours}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.comments}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'250px'}">
                                                                        <button type="submit" class="btn btn-secondary " (click)="MyStatusToComplete(product.statusId,product.timeSheetId)">Status To Complete</button>
                                                                    </td>


                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Timesheet</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Timesheet</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="timesheetForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Party
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" [options]="PartyIdArray" placeholder="Enter Party" formControlName="Party">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Select Date

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="SelectDate" placeholder="Enter Select Date">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -72%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createWeeklyProTimesheet()">Add</button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalTitle" tabindex="-1" role="dialog" aria-labelledby="exampleModalTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Timesheet</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Timesheet</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateTimesheet">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Task Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" [options]="TasksNamesListArray" placeholder="Enter Task Name" formControlName="taskName">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Rate Type ID

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="rateTypesArray" placeholder="Enter Rate Type ID" formControlName="rateTypeID">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Mon

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="mon" placeholder="Enter Mon">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Tue

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="tue" placeholder="Enter Tue">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Wen

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="wed" placeholder="Enter Wen">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Thu

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="thu" placeholder="Enter Thu">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Fri

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="fri" placeholder="Enter Fri">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Sat

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="sat" placeholder="Enter Sat">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Sun

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="sun" placeholder="Enter Sun">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Actual Hours
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="actualHours" placeholder="Enter Actual Hours">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Planned Hours
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="planHrs" placeholder="Enter Planned Hours">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -69%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createAddNewWeeklyTimesheet()">Add</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>