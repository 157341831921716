import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { WarehouseService } from 'src/app/warehouse/warehouse/warehouse.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-allocation-plan',
  templateUrl: './allocation-plan.component.html',
  styleUrls: ['./allocation-plan.component.css']
})
export class AllocationPlanComponent implements OnInit {
  activeCategory = 2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  allocationPlanList: any;
  viewAllocationPlanList: any;
  planId: any;
  advanceSearch: { PlanID: string; PlanName: string; PlanTypeID: string; StatusID: string; ProductID: string; OrderID: string; };
  statusId: { label: string; value: string; }[];
  planTypeId: { label: string; value: string; }[];
  ProductId: any;
  ProductIdArray: any[]=[];
  orderIds: any[];
  allocationForm: any;
  constructor(public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,     
     readonly _WarehouseService: WarehouseService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router,
    private _location: Location,) { 
      this.advanceSearch = {
        PlanID: "",
        PlanName: "",
        PlanTypeID: "",
        StatusID: "",
        ProductID: "",
        OrderID: "",
       
      };
      this.statusId = [
       
        {
          label : 'Created',
          value : 'ALLOC_PLAN_CREATED'
        },
       
        {
          label : 'Approved',
          value : 'ALLOC_PLAN_APPROVED'
        },
       
        {
          label : 'Completed',
          value : 'ALLOC_PLAN_COMPLETED'
        },
        {
          label : 'Cancelled',
          value : 'ALLOC_PLAN_CANCELLED'
        },
       
      ]
      this.planTypeId = [
       
        {
          label : 'Sales Order Allocation Plan',
          value : 'SALES_ORD_ALLOCATION'
        },
       
      ]

    this.allocationForm = this._FormBuilder.group({
      ProductID : [''],
      PlanName: [''],
    });
    }

  ngOnInit(): void {
    this.getfindAllocationPlan();
  
    this.getProductId();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/order/main-order']);
  }
  deleteAllocationPlanItem(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "planId": product.planId ,
          "planItemSeqId": product.planItemSeqId ,
          "productId": product.productId ,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20"
        }
        this.myContactsService.deleteAllocationPlanItem(formData).subscribe((res: any) => {
          this.getfindAllocationPlan();

        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getOrderIds(): void {
    this.spinner.show();
    this._WarehouseService.getOrderIds()
        .then(data => {
            this.spinner.hide();
            this.orderIds = data.data.map(value => {
                return {
                    label: value.orderId,
                    value: value.orderId
                };
            });
        });
        
}
  getProductId() {
    this.spinner.show();
    this.accountsService.getProductId().subscribe(res => {
      this.ProductId = res.data[0].Product;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })
   
  }
  reset() {
    this.spinner.show();
    this.advanceSearch.PlanID = "";
    this.advanceSearch.PlanName = "";
    this.advanceSearch.PlanTypeID = "";
    this.advanceSearch.StatusID = "";
    this.advanceSearch.ProductID = "";
    this.advanceSearch.OrderID = "";
    const req = {
      "noConditionFind": "",
      "orderId": "",
      "planId": "",
      "planName": "",
      "planTypeId": "",
      "productId": "",
      "statusId": ""
    }
    this.myContactsService.getfindAllocationPlan(req).subscribe((res: any) => {
      this.allocationPlanList = res.data[0].dataNodes;
      this.spinner.hide();
    })
   
  }
  findAllocationPlan(): void {
    this.spinner.show();
    this.allocationPlanList = [];
    const req = {
      "noConditionFind":  '' ,
      "orderId":   this.advanceSearch.OrderID ?  this.advanceSearch.OrderID : '',
      "planId":  this.advanceSearch.PlanID ?  this.advanceSearch.PlanID : '',
      "planName":   this.advanceSearch.PlanName ?  this.advanceSearch.PlanName : '',
      "planTypeId":  this.advanceSearch.PlanTypeID ?  this.advanceSearch.PlanTypeID : '',
      "productId":   this.advanceSearch.ProductID ?  this.advanceSearch.ProductID : '',
      "statusId":  this.advanceSearch.StatusID ?  this.advanceSearch.StatusID : '',

    }
    this.myContactsService.findAllocationPlan(req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.allocationPlanList = resp.data[0].dataNodes;
        } 
      });
     
  }
  detailPage(id){
    this._Router.navigate(['order/detail-allocation-plan'],{ queryParams: { planId:id} })
  }
  getfindAllocationPlan() {
    this.spinner.show();
    let req = {
      "noConditionFind": "",
      "orderId": "",
      "planId": "",
      "planName": "",
      "planTypeId": "",
      "productId": "",
      "statusId": ""
    }
    this.myContactsService.getfindAllocationPlan(req).subscribe((res: any) => {
      this.allocationPlanList = res.data[0].dataNodes;
      this.spinner.hide();
    })
  }
 
 
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
