<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class=" bg-white color-grey create-new-table">
        <div style="margin: 4%;font-size: 11px;">
          <ng-wizard [config]="config">

            <ng-wizard-step [title]="'Step 1'" [description]="'Purchase Order'"> 
            </ng-wizard-step>

            <ng-wizard-step [title]="'Step 2'" [description]="' Enter Order Currency, Agreements, and Ship Dates '">  
            </ng-wizard-step>
           
          <ng-wizard-step [title]="'Step 3'" [description]="'Create Sales Order'">      
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 4'" [description]="'Shipping'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 5'" [description]="'Option'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 6'" [description]="'Order Term'">     
            </ng-wizard-step>
           
            <ng-wizard-step [title]="'Step 7'" [description]="'Parties'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 8'" [description]="'Review Order'">     
            </ng-wizard-step>
           
          
         
          </ng-wizard>
        </div>
        <div>
          <div class="container-fluid">
            <div class="container-fluid">
              <ul class="tabbing-section  tabbing-accordians" style="padding-left: 9px;">
                <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                  Purchase Order : Order Confirmation</a></li>
                 
                    <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                      Delivery Information </a></li>
                      <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategoryFunction(4)"><a>
                        Order Items </a></li>

              </ul>
            </div>
            <div class="row">
              <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                <div class="w-100">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                      <div *ngIf="activeCategory==1">
                        <div class="header-tabs">
                          <h4 class=" common-styling">
                            Purchase Order : Order Confirmation
                          </h4>
                          <a class="arrow-toggle toggle-arrow detail-created port-header tabs-header collapsed"
                          data-toggle="collapse" href="#Order">
                        </a>
                        <span style="margin-left: 29%!important" class="edit-buttons" routerLinkActive="active"
                          routerLink="/order/create-purchase-continue">Order Items</span>
                        <span class="edit-buttons">Shipping</span>
                        <span class="edit-buttons">Options</span>
                        <span class="edit-buttons">Order Terms</span>
                        <span class="edit-buttons">Payment</span>
                        <span class="edit-buttons"> Parties</span>
                        <span class="edit-buttons"> Review Order</span>
                        <span class="edit-buttons" routerLinkActive="active"
                          (click)="processOrder()">Create Order</span> 


                         

                         
                         
                        </div>
                        <div class="panel-body">
                          <div class="mt-2">
                            <div class="">
                              <div class="row">
                                <div class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                  style="padding-left:15px;">
                                  <div class="d-flex flex-column">
                                    <strong class="common-bold-properties" style="margin-right:10px;"> Order Name
                                    </strong>
                                    <p class="common-regular-properties">
                                      {{reviewOrderValue?.orderHeaderInfo.orderName}}
                                    </p>
                                  </div>
                                  <div class="d-flex flex-column">
                                    <strong class="common-bold-properties" style="margin-right:10px;"> Order Terms
                                    </strong>
                                    <div class="common-regular-properties">
                                      <table class="table">
                                        <thead>
                                          <tr>
                                            <th scope="col">Term Type</th>
                                            <th scope="col">Term Value</th>
                                            <th scope="col">Term Days</th>
                                            <th scope="col">Text Value</th>
                                            <th scope="col">Description</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <ng-template ngFor let-term [ngForOf]="reviewOrderValue?.orderTerms">
                                            <tr>
                                              <th scope="row">{{term.termTypeID}}</th>
                                              <td>{{term.termValue}}</td>
                                              <td>{{term.termDays}}</td>
                                              <td>{{term.textValue}}</td>
                                              <td>{{term.description}}</td>
                                            </tr>
                                          </ng-template>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <strong class="common-bold-properties" style="margin-right:10px;"> Splitting
                                    Preference </strong>
                                  <p class="common-regular-properties"> N/A
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div *ngIf="activeCategory==3">
                        <div class="header-tabs">
                          <h4 class=" common-styling">
                            Delivery Information 
                          </h4>
                          
                        </div>
                        <div class="panel-body a">
                          <div class="mt-2">
                            <div class="">
                              <div class="row m-0">
                                <div class="card confimation-table own-account-tables">
                                  <p-table [resizableColumns]="true" columnResizeMode="expand" scrollWidth="100%"
                                    [paginator]="false" [rows]="rows" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                    [lazy]="true" [totalRecords]="total"
                                    [value]="reviewOrderValue?.orderShippingInformation" [scrollable]="true"
                                    styleClass="tags-table  lm-table offerShowing-table offerShowingLine-table custom-dataTable table-striped">
                                    <ng-template pTemplate="colgroup" let-columns>
                                      <colgroup>
                                        <col *ngFor="let col of offerShowingLineColoumns">
                                      </colgroup>
                                    </ng-template>
                                    <ng-template pTemplate="header">
                                      <tr class="activity-rows">
                                        <th id="tableHead" *ngFor="let col of offerShowingLineColoumns">
                                          <ng-container>
                                            {{col.header}}
                                          </ng-container>
                                        </th>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-i="rowIndex" let-activity>
                                      <tr class="activity-data">
                                        <td *ngFor="let col of offerShowingLineColoumns">
                                          <ng-container *ngIf="col.field === 'Destination'">
                                            <span>{{activity.address.toName}},{{activity.address.address1}},{{activity.address.countryGeoId}},
                                              {{activity.address.postalCode}}</span>
                                          </ng-container>
                                          <ng-container *ngIf="col.field === 'Supplier'">
                                            <span class="d-flex red-border align-items-center">
                                              <div class="name_text">
                                                <a>
                                                  {{activity.supplier}}
                                                </a>
                                              </div>
                                            </span>
                                          </ng-container>
                                          <ng-container *ngIf="col.field === 'ShipmentMethod'">
                                            <span class="d-block align-items-center">
                                              {{activity.method.description}} {{activity.method.shipmentMethodTypeId}}
                                            </span>
                                          </ng-container>
                                          <ng-container *ngIf="col.field === 'Items'"><span>
                                              {{activity.shipItems[0].item}}</span>
                                          </ng-container>
                                          <ng-container *ngIf="col.field === 'Quantity'">
                                            <span>{{activity.shipItems[0].qty}}</span>
                                          </ng-container>
                                         
                                        </td>
                                      </tr>
                                    </ng-template>
                                  </p-table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="activeCategory==4">
                        <div class="header-tabs">
                          <h4 class=" common-styling">
                            Order Items
                          </h4>
                          
                        </div>
                        <div class="panel-body a">
                          <div class="mt-2">
                            <div class="">
                              <div class="row m-0">
                                <div class="card confimation-table own-account-tables">
                                  <p-table [resizableColumns]="true" columnResizeMode="expand" scrollWidth="100%"
                                    [paginator]="false" [rows]="rows" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                    [lazy]="true" [totalRecords]="total" [value]="reviewOrderValue?.orderItemsMap"
                                    [scrollable]="true"
                                    styleClass="tags-table  lm-table offerShowing-table offerShowingLine-table custom-dataTable table-striped">
                                    <ng-template pTemplate="colgroup" let-columns>
                                      <colgroup>
                                        <col *ngFor="let col of orderItemsColumns">
                                      </colgroup>
                                    </ng-template>
                                    <ng-template pTemplate="header">
                                      <tr class="activity-rows">
                                        <th id="tableHead" *ngFor="let col of orderItemsColumns">
                                          <ng-container>
                                            {{col.header}}
                                          </ng-container>
                                        </th>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-activity>
                                      <tr class="activity-data">
                                        <td *ngFor="let col of orderItemsColumns">
                                          <ng-container *ngIf="col.field === 'status'">
                                            <span>{{activity.itemDescription}}</span>
                                          </ng-container>
                                          <ng-container *ngIf="col.field === 'name'">
                                            <span class="d-flex red-border align-items-center">
                                              <div class="name_text">
                                                <a>
                                                  {{activity.quantity}}
                                                </a>
                                              </div>
                                            </span>
                                          </ng-container>
                                          <ng-container *ngIf="col.field === 'email'">
                                            <span class="d-block align-items-center">
                                              <div class="name_text">
                                                {{activity.unitPrice}}
                                              </div>
                                            </span>
                                          </ng-container>
                                          <ng-container *ngIf="col.field === 'phone'"><span>
                                              {{activity.orderItemAdjustment}}</span>
                                          </ng-container>
                                          <ng-container *ngIf="col.field === 'type'">
                                            <span>{{activity.orderItemSubTotal}}</span>
                                          </ng-container>
                                        </td>
                                      </tr>
                                    </ng-template>
                                  </p-table>
                                </div>

                                <div class="row w-100" style="padding-top:10px;">
                                  <div class="col-md-10 text-right">
                                    <strong class="common-bold-properties confirm-price-text d-block">Sub Total</strong>
                                    <strong
                                      class="common-bold-properties  confirm-price-text d-block">Promotion</strong>
                                    <strong class="common-bold-properties confirm-price-text  d-block">Shipping and
                                      Handling</strong>
                                    <strong class="common-bold-properties confirm-price-text  d-block">VAT</strong>
                                    <br>
                                    <strong class="common-bold-properties confirm-price-text d-block">Grand
                                      Total</strong>
                                  </div>
                                  <div class="col-md-2 text-right">
                                    <p class="common-regular-properties confirm-price-text  d-block">
                                      US${{reviewOrderValue?.orderHeaderInfo.commonTotal}}</p>
                                    <p class="common-regular-properties  confirm-price-text  d-block"> -US$0</p>
                                    <p class="common-regular-properties confirm-price-text d-block">
                                      US${{reviewOrderValue?.shippingAndHandling}}</p>
                                    <p class="common-regular-properties confirm-price-text d-block">
                                      US${{reviewOrderValue?.orderSalesTax}}</p>
                                    <br>
                                    <p class="common-regular-properties confirm-price-text d-block">
                                      US${{reviewOrderValue?.orderGrandTotal}}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>
      </div>
    </div>
   
  </div>
  <ngx-spinner></ngx-spinner> 