<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">
                </span>
                <span class="">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            
            </div>
            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">


                        <li [ngClass]="activeCategory==1?'active':''" (click)="TaskList()"><a>
                            Main </a></li>
                        <li [ngClass]="activeCategory==2?'active':''" (click)="TaskList()"><a>
                            Task List
                            </a></li>
                        <li [ngClass]="activeCategory==3?'active':''" (click)="Calendar()"><a>
                            Calendar</a></li>
                        <li [ngClass]="activeCategory==4?'active':''" (click)="MyTime()"><a>
                            My Time
                            </a></li>
                        <li [ngClass]="activeCategory==5?'active':''" (click)="RequestList()"><a>
                            Request List
                            </a></li>
                        <li [ngClass]="activeCategory==6?'active':''" (click)="WorkEffort()"><a>
                            Work Effort
                            </a></li>
                        <li [ngClass]="activeCategory==7?'active':''" (click)="Timesheet()"><a>
                            Timesheet
                            </a></li>
                        <li [ngClass]="activeCategory==8?'active':''" (click)="SubmittedJobs()"><a>
                            Submitted Jobs
                            </a></li>
                        <li [ngClass]="activeCategory==9?'active':''" (click)="iCalendar()"><a>
                            iCalendar
                        </a></li>


                    </ul>
                </div>
                <div class=" bg-white color-grey">
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="d-flex bd-highlight ml-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        Find iCalendars
                                        <button _ngcontent-cta-c128="" *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" style="width: 113px;" data-toggle="modal" data-target="#createiCalender">Create New iCalendars
                                        </button>

                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn ml-2" style="width: 113px;" data-toggle="modal" data-target="#exampleModalCenter">Help 
                                        </button>
                                    </div>

                                    <div class="w3-card-4 classCardView mt-2">

                                        <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="ICalenderdata" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>

                                                                            WorkEffort ID
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            iCalendar Url
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Calendar Name
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span (click)="detailedPage(product.gn.workEffortId) "  class="account-button">
                                                                                {{product.gn.workEffortId}}</span>

                                                                        </div>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.iCalendarUrl}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.gn.workEffortName}}
                                                                    </td>

                                                                </tr>
                                                            </ng-template>

                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                                        <h4
                                                                            class="ml-2">
                                                                            No Record
                                                                            Found</h4>
                                                                    </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createiCalender" tabindex="-1" role="dialog" aria-labelledby="createiCalender" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> iCalendar </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add iCalendar</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addiCalender">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Calendar Owner</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="calendarOwner" [options]="partyIdArray" optionlabel="label" placeholder="Enter Calendar Owner">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">Calendar Name <span style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="calendarName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter CalendarName">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Calendar Visibility <span style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="calendarVisibility" [options]="calendarvisibilityArray" optionlabel="label" placeholder="Enter Calendar Visibility">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">From</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control" formControlName="fromDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From">
                                                    </div>
                                                </div>
                                            </div>

                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">To</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control" formControlName="toDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter To">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-6 main-submit-button1" style="margin-left: 47%;">
                                            <button type="submit" (click)="createICalendar()" class="btn btn-secondary submit-btn">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>