import { Component, OnInit, Inject } from '@angular/core';
import { MergeContactsService } from './merge-contacts.service';
import { SortEvent } from 'primeng/api';
import { DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-merge-contacts',
  templateUrl: './merge-contacts.component.html',
  styleUrls: ['./merge-contacts.component.css']
})
export class MergeContactsComponent implements OnInit {
  pageNo = 1;
  pageSize = 200;
  search = ''
  partyId: any;
  total=0;
  rows = 50;
  activeCategory=1;
  partyIdArray: any = [];
  public mergeContacts: any[] = [];
  mergearray: any = [];
  public mergedata: any;
  addrContactMechId = '';
  addrContactMechIdTo = '';
  emailContactMechId= '';
  emailContactMechIdTo = '';
  phoneContactMechId = '';
  phoneContactMechIdTo = '';
  useAddress2= 'Y';
  useContactNum2= 'Y';
  useEmail2= 'Y';
  constructor(
    readonly mergeContactsService: MergeContactsService,
    @Inject(DOCUMENT) readonly document: Document,
    readonly spinner:NgxSpinnerService,
    readonly toastr: ToastrService,
    readonly router: Router,
    private _location: Location,

  ) { }
  public data = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
    search: this.search
  }
  public partyIdFrom: any;
  public partyIdTo: any;
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getPartyId();

  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/crm/main-overview']);
  }

  getPartyId() {
    this.spinner.show();
    this.mergeContactsService.getContacts(this.data).subscribe(res => {
      this.partyId = res.data;
      this.spinner.hide();
      for (const value of this.partyId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        });
      }
    });
  }
  partyIdFromData(event) {
    this.partyIdFrom = event;
   
  }
  partyIdToData(event) {
    this.partyIdTo = event;
  
  }
  onSubmit() {
    this.spinner.show();
    this.mergearray = [];
    this.mergeContactsService.getMergeContacts(this.partyIdFrom, this.partyIdTo).subscribe(res => {
      this.spinner.hide();
      this.mergeContacts = res.data.results;
      this.mergearray.push({
        data: this.mergeContacts
      });
     
      this.mergedata = this.mergearray[0].data;
     
    });
    
  }
  submitMergeContact() {
    this.spinner.show();
    const mergeContactFrom = {
      addrContactMechId: this.mergeContacts[0].addrContactMechId,
      addrContactMechIdTo: this.mergeContacts[1].addrContactMechIdTo,
      emailContactMechId: this.mergeContacts[0].emailContactMechId,
      emailContactMechIdTo: this.mergeContacts[1].emailContactMechIdTo,
      partyId: this.partyIdFrom,
      partyIdTo: this.partyIdTo,
      phoneContactMechId: this.mergeContacts[0].phoneContactMechId,
      phoneContactMechIdTo: this.mergeContacts[1].phoneContactMechIdTo,
      useAddress2: this.useAddress2,
      useContactNum2: this.useContactNum2,
      useEmail2: this.useEmail2,
    };
    this.mergeContactsService.submitMergeLead(mergeContactFrom).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
   

  }
  public checkAddress(event) {
    this.useAddress2 = event.target.checked ? 'Y' : 'N';
  }
  public checkEmail(event) {
    this.useEmail2 = event.target.checked ? 'Y' : 'N';
  }
  public checkContact(event) {
    this.useContactNum2 = event.target.checked ? 'Y' : 'N';
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategoryFunction(tab: number){
    this.activeCategory =tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
