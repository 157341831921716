import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ThesaurusService } from './thesaurus.service';
import { Location } from '@angular/common';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-thesaurus',
  templateUrl: './thesaurus.component.html',
  styleUrls: ['./thesaurus.component.css']
})
export class ThesaurusComponent implements OnInit {
  faTrash = faTrash;
  relationshipList: any[];
  activeCategory = 2;
  activeCat=10;
  thesaurusForm: FormGroup;
  array: { alternate: string, relation: string }[];
  editKeywords: any[];
  isShown=false;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly _ThesaurusService: ThesaurusService,    private _location: Location,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _ToastrService: ToastrService
  ) {
    this.relationshipList = [];
    this.thesaurusForm = this._FormBuilder.group({
      alternateKeyword: [''],
      enteredKeyword: [''],
      relationshipEnumId: ['KWTR_UF']
    });
    this.array = [];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
    this._ActivatedRoute.data.subscribe(data => {
      this.relationshipList = data.thesaurus.map(value => {
        return {
          label: value.description,
          value: value.enumId
        };
      })
    });
    this.editKeywordThesaurus('a');
  }

  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  editKeywordThesaurus(event: string): void {
    this.spinner.show();
    this._ThesaurusService.editKeywordThesaurus(event ? event : 'a')
      .then(async (data) => {
        if (data.success) {
          this.spinner.hide();
          data.data.keywordThesauruses.forEach(() => {
            this.array.push({ alternate: '', relation: 'KWTR_UF' });
          });
          this.editKeywords = await Promise.all(data.data.keywordThesauruses.map(async (enteredKeyword) => {
            return {
              ...enteredKeyword,
              keyword: await this._ThesaurusService.getKeywordByEnteredKeyword(enteredKeyword.enteredKeyword).then(keyword => keyword.data).then(value => value)
            };
          }));
        }
      });
    
  }
  submitThesaurusForm(): void {
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.spinner.show();
      this._ThesaurusService.createThesaurus(this.thesaurusForm.value)
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.thesaurusForm.reset();
          }
        });
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this._ToastrService.error("Permission Denied"); 
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this._ToastrService.error("Permission Denied");
    }


    
  }
  addFromList(enteredKeyword: string, index: number): void {
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.spinner.show();
      if (this.array[index].alternate) {
        this._ThesaurusService.createThesaurus({
          alternateKeyword: this.array[index].alternate,
          enteredKeyword,
          relationshipEnumId: this.array[index].relation
        })
          .then(data => {
            if (data.success) {
              this.spinner.hide();
              this._ToastrService.success('Added');
              this.array[index] = { alternate: '', relation: 'KWTR_UF' };
            }
          });
      } else {
        this.spinner.hide();
        this._ToastrService.error('Fill required fields.');
      }
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this._ToastrService.error("Permission Denied"); 
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this._ToastrService.error("Permission Denied");
    }

  }
  deleteAll(enteredKeyword: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._ThesaurusService.deleteAll(enteredKeyword).then(data => {
          if (data.success) {
            this.editKeywordThesaurus('a');
          }
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        ) 
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }})  }
  deleteAlternate(alternateKeyword: string, enteredKeyword: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._ThesaurusService.deleteAlternate(alternateKeyword, enteredKeyword)
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Deleted');
          }
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        ) }
    })
  }
  reset(): void {
    this.thesaurusForm.reset();
    this.thesaurusForm.patchValue({
      relationshipEnumId: 'KWTR_UF'
    });
  }
  feautureValue(){
    this.isShown = !this.isShown;
  }
  mainOpen(){
    this.router.navigate(['/facilities/catalog/main-catalog']);
  }
  catalogOpen(){
    this.router.navigate(['/facilities/catalog/catalogs']);
  }
  categoriesOpen(){
    this.router.navigate(['/facilities/catalog/catagories']);
  }
  productsOpen(){
    this.router.navigate(['/facilities/catalog/products']);
  }
  featuresOpen(){
    this.router.navigate(['/facilities/catalog/feature-type']);
  }
  promosOpen(){
    this.router.navigate(['/facilities/catalog/promos']);
  }
  priceRulesOpen(){
    this.router.navigate(['/facilities/catalog/price-rules']);
  }
  storesOpen(){
    this.router.navigate(['/facilities/catalog/stores']);
  }
  productStoreGroupsOpen(){
    this.router.navigate(['/facilities/catalog/product-store-groups']);
  }
  thesaurusOpen(){
    this.router.navigate(['/facilities/catalog/thesaurus']);
  }
  reviewsOpen(){
    this.router.navigate(['/facilities/catalog/reviews']);
  }
  configurationsOpen(){
    this.router.navigate(['/facilities/catalog/configurations']);
  }
  subscriptionsOpen(){
    this.router.navigate(['/facilities/catalog/subscriptions']);
  }
  shippingOpen(){
    this.router.navigate(['/facilities/catalog/shipment']);
  }
  imageManagementOpen(){
    this.router.navigate(['/facilities/catalog/image-management']);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
