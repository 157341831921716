<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">User Login
                </span>
                <span class="color-black pl-1" > >Create User Login
                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''"><a>
                        Create User Login
                    </a></li>
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createUser">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">
                                                    User Login ID<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="text"  formControlName="loginId"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter User Login ID"> 

                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Current Password<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text"  formControlName="currentpass"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Current Password">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">
                                                    Current Password Verify<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="text"  formControlName="verifypass"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Current Password Verify">

                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Password Hint</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text"  formControlName="passHint"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Password Hint">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">
                                                    Require Password Change</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" formControlName="passrequire"
                                              placeholder="Require Password Change"
                                              
                                              [options]="user"
                                              optionlabel="label">
                                          </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    External Auth ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text"  formControlName="extAuthId"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter External Auth ID">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                   
                                  
                                   
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 69%;">
                               
                                <button type="submit" (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>
                                
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div> -->


<!-- <div class="modal fade" id="userNameModalCenter" tabindex="-1" role="dialog" aria-labelledby="userModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
             
                <span class="color-black pl-1">User Login</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
    
                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                    Create User Login</a></li>
                            </ul>
                        </div>
						
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form  class="w-89" [formGroup]="createUser">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            User Login ID<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="loginId"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter User Login ID"> 
        
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Current Password<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="currentpass"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Current Password">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Current Password Verify<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="verifypass"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Current Password Verify">
        
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Password Hint</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="passHint"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Password Hint">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Require Password Change</label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="passrequire"
                                                      placeholder="Require Password Change"
                                                      
                                                      [options]="user"
                                                      optionlabel="label">
                                                  </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            External Auth ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="extAuthId"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter External Auth ID">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                           
                                          
                                           
                                        </div>
                                        
        
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 70%;">
                                       
                                        <button type="submit" (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>
                                        
                                        
                                      
                                    </div>
                                </div>
                            </div>
        
                        </div>
						
						</div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="userModalCenterTitle"
    aria-hidden="true" >
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
             
                <span class="color-black pl-1">User Login</span>
                <button type="button" class="close cssButtonModel" (click)="bsModalRef.hide()" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
    
                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                    Create User Login</a></li>
                            </ul>
                        </div>
						
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form  class="w-89" [formGroup]="createUser">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            User Login ID<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="loginId"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter User Login ID"> 
        
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Current Password<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="currentpass"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Current Password">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Current Password Verify<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="verifypass"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Current Password Verify">
        
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Password Hint</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="passHint"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Password Hint">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Require Password Change</label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="passrequire"
                                                      placeholder="Require Password Change"
                                                      
                                                      [options]="user"
                                                      optionlabel="label">
                                                  </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            External Auth ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="extAuthId"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter External Auth ID">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                           
                                          
                                           
                                        </div>
                                        
        
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 70%;">
                                       
                                        <button type="submit" (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>
                                        
                                        
                                      
                                    </div>
                                </div>
                            </div>
        
                        </div>
						
						</div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="userModalCenterTitle"
    aria-hidden="true" >
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
             
                <span class="color-black pl-1">User Login</span>
                <button type="button" class="close cssButtonModel" (click)="bsModalRef.hide()" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
    
                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                    Create User Login</a></li>
                            </ul>
                        </div>
						
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form  class="w-89" [formGroup]="createUser">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            User Login ID<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="loginId"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter User Login ID"> 
        
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Current Password<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="currentpass"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Current Password">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Current Password Verify<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="verifypass"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Current Password Verify">
        
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Password Hint</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="passHint"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Password Hint">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Require Password Change</label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="passrequire"
                                                      placeholder="Require Password Change"
                                                      
                                                      [options]="user"
                                                      optionlabel="label">
                                                  </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            External Auth ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="extAuthId"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter External Auth ID">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                           
                                          
                                           
                                        </div>
                                        
        
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 70%;">
                                       
                                        <button type="submit" (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>
                                        
                                        
                                      
                                    </div>
                                </div>
                            </div>
        
                        </div>
						
						</div>
                </div>
            </div>
        </div>
    </div>
</div> -->


<!-- <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="userModalCenterTitle"
    aria-hidden="true" >
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
             
                <span class="color-black pl-1">User Login</span>
                <button type="button" class="close cssButtonModel" (click)="bsModalRef.hide()" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
    
                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                    Create User Login</a></li>
                            </ul>
                        </div>
						
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form  class="w-89" [formGroup]="createUser">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            User Login ID<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="loginId"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter User Login ID"> 
        
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Current Password<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="currentpass"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Current Password">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Current Password Verify<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="verifypass"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Current Password Verify">
        
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Password Hint</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="passHint"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Password Hint">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Require Password Change</label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="passrequire"
                                                      placeholder="Require Password Change"
                                                      
                                                      [options]="user"
                                                      optionlabel="label">
                                                  </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            External Auth ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="extAuthId"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter External Auth ID">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                           
                                          
                                           
                                        </div>
                                        
        
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 70%;">
                                       
                                        <button type="submit" (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>
                                        
                                        
                                      
                                    </div>
                                </div>
                            </div>
        
                        </div>
						
						</div>
                </div>
            </div>
        </div>
    </div>
</div> -->


<!-- <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="userModalCenterTitle"
    aria-hidden="true" >
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
             
                <span class="color-black pl-1">User Login</span>
                <button type="button" class="close cssButtonModel" (click)="bsModalRef.hide()" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
    
                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                    Create User Login</a></li>
                            </ul>
                        </div>
						
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form  class="w-89" [formGroup]="createUser">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            User Login ID<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="loginId"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter User Login ID"> 
        
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Current Password<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="currentpass"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Current Password">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Current Password Verify<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="verifypass"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Current Password Verify">
        
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Password Hint</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="passHint"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Password Hint">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Require Password Change</label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="passrequire"
                                                      placeholder="Require Password Change"
                                                      
                                                      [options]="user"
                                                      optionlabel="label">
                                                  </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            External Auth ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="extAuthId"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter External Auth ID">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                           
                                          
                                           
                                        </div>
                                        
        
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 70%;">
                                       
                                        <button type="submit" (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>
                                        
                                        
                                      
                                    </div>
                                </div>
                            </div>
        
                        </div>
						
						</div>
                </div>
            </div>
        </div>
    </div>
</div> -->


<!-- <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="userModalCenterTitle"
    aria-hidden="true" >
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
             
                <span class="color-black pl-1">User Login</span>
                <button type="button" class="close cssButtonModel" (click)="bsModalRef.hide()" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
    
                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                    Create User Login</a></li>
                            </ul>
                        </div>
						
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form  class="w-89" [formGroup]="createUser">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            User Login ID<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="loginId"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter User Login ID"> 
        
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Current Password<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="currentpass"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Current Password">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Current Password Verify<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="verifypass"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Current Password Verify">
        
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Password Hint</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="passHint"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Password Hint">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Require Password Change</label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="passrequire"
                                                      placeholder="Require Password Change"
                                                      
                                                      [options]="user"
                                                      optionlabel="label">
                                                  </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            External Auth ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="extAuthId"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter External Auth ID">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                           
                                          
                                           
                                        </div>
                                        
        
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 70%;">
                                       
                                        <button type="submit" (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>
                                        
                                        
                                      
                                    </div>
                                </div>
                            </div>
        
                        </div>
						
						</div>
                </div>
            </div>
        </div>
    </div>
</div> -->


<!-- <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="userModalCenterTitle"
    aria-hidden="true" >
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
             
                <span class="color-black pl-1">User Login</span>
                <button type="button" class="close cssButtonModel" (click)="bsModalRef.hide()" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
    
                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                    Create User Login</a></li>
                            </ul>
                        </div>
						
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form  class="w-89" [formGroup]="createUser">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            User Login ID<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="loginId"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter User Login ID"> 
        
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Current Password<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="currentpass"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Current Password">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Current Password Verify<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="verifypass"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Current Password Verify">
        
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Password Hint</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="passHint"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Password Hint">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Require Password Change</label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="passrequire"
                                                      placeholder="Require Password Change"
                                                      
                                                      [options]="user"
                                                      optionlabel="label">
                                                  </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            External Auth ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="extAuthId"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter External Auth ID">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                           
                                          
                                           
                                        </div>
                                        
        
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 70%;">
                                       
                                        <button type="submit" (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>
                                        
                                        
                                      
                                    </div>
                                </div>
                            </div>
        
                        </div>
						
						</div>
                </div>
            </div>
        </div>
    </div>
</div> -->


<!-- <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="userModalCenterTitle"
    aria-hidden="true" >
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
             
                <span class="color-black pl-1">User Login</span>
                <button type="button" class="close cssButtonModel" (click)="bsModalRef.hide()" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
    
                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                    Create User Login</a></li>
                            </ul>
                        </div>
						
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form  class="w-89" [formGroup]="createUser">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            User Login ID<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="loginId"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter User Login ID"> 
        
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Current Password<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="currentpass"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Current Password">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Current Password Verify<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="verifypass"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Current Password Verify">
        
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Password Hint</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="passHint"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Password Hint">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Require Password Change</label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="passrequire"
                                                      placeholder="Require Password Change"
                                                      
                                                      [options]="user"
                                                      optionlabel="label">
                                                  </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            External Auth ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text"  formControlName="extAuthId"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter External Auth ID">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                           
                                          
                                           
                                        </div>
                                        
        
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 70%;">
                                       
                                        <button type="submit" (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>
                                        
                                        
                                      
                                    </div>
                                </div>
                            </div>
        
                        </div>
						
						</div>
                </div>
            </div>
        </div>
    </div>
</div> -->



    <div class="modal-header">
      <span class="color-black pl-1">User Login</span>
      <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close" (click)="bsModalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden" #closebutton >&times;</span>
      </button>
    </div>
    <div class="modal-body d-flex">
        <div class=" bg-white color-grey" style="width: 100%;">
            <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">

                        <li [ngClass]="activeCategory==2?'active':''"><a>
                            Create User Login</a></li>
                    </ul>
                </div>
                
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-89" [formGroup]="createUser">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-4 form-group classInput" >
                                                <label for="exampleInputEmail1">
                                                    User Login ID<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-3">
                                                <input type="text"  formControlName="loginId"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter User Login ID"> 

                                            </div>
                                            <div class="col-lg-2 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Current Password<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text"  formControlName="currentpass"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Current Password">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-4 form-group classInput" >
                                                <label for="exampleInputEmail1">
                                                    Current Password Verify<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-3">
                                                <input type="text"  formControlName="verifypass"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Current Password Verify">

                                            </div>
                                            <div class="col-lg-2 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Password Hint</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text"  formControlName="passHint"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Password Hint">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-4 form-group classInput" >
                                                <label for="exampleInputEmail1">
                                                    Require Password Change</label>
                                            </div>
                                         
                                            <div class="col-lg-3 custom-dropdown">
                                                <p-dropdown filter="true" formControlName="passrequire"
                                              placeholder="Require Password Change"
                                              
                                              [options]="user"
                                              optionlabel="label">
                                          </p-dropdown>
                                            </div>
                                            <div class="col-lg-2 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    External Auth ID</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text"  formControlName="extAuthId"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter External Auth ID">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                   
                                  
                                   
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 70%;">
                               
                                <button type="submit" (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>
                                
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                
                </div>
        </div>
    </div>

