import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-merge-accounts',
  templateUrl: './merge-accounts.component.html',
  styleUrls: ['./merge-accounts.component.css']
})
export class MergeAccountsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
