import { Component, OnInit,Inject } from '@angular/core';
import {CreateEventServices} from '../../crm/events/create-events/create-event.service';
import { SortEvent } from 'primeng/api';
import {AssignByMeService} from "../assign-by-me.service";
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';



@Component({
  selector: 'app-assign-by-me',
  templateUrl: './assign-by-me.component.html',
  styleUrls: ['./assign-by-me.component.css']
})
export class AssignByMeComponent implements OnInit {
  assignedByMeData: any;
  total=0;
  assignedToMeData: any;
  rows = 50;


  constructor(readonly createEventServices:CreateEventServices,
    readonly assignByMeService:AssignByMeService ,
    readonly toastr:ToastrService,
    readonly spinner: NgxSpinnerService,
    @Inject(DOCUMENT) readonly document: Document,
    readonly router: Router

    ) { }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.assignedToMe();
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }

  complete(id){
    this.spinner.show();
    this.assignByMeService.completeEvent(id).subscribe((res:any)=>{
      if(res.success) {
        this.toastr.success(res.data);
        this.document.location.reload();

      }
    })
    this.spinner.hide();
  }
  assignedToMe(){
    this.spinner.show();
   const searchWorkEffortsForm = {
      assignedByPartyId: "",
      assignedToPartyId: "",
      description: "",
      descriptionSearchType: "Contains",
      estimatedCompletionDateFrom: "",
      estimatedCompletionDateTo: "",
      estimatedStartDateFrom: "",
      estimatedStartDateTo: "",
      priority: "",
      purposeId: "",
      workEffortId: "",
      workEffortIdSearchType: "Contains",
      workEffortName: "",
      workEffortNameSearchType: "Contains"
    }
     this.createEventServices.assignedToMe(searchWorkEffortsForm).subscribe(res=> {
     
       this.assignedToMeData = res.data;
       this.spinner.hide();
     })
   
   }

   completeEvent(id) {
    this.spinner.show();
    this.createEventServices.completeEvent(id).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data);
        this.document.location.reload();
      }
    }, (err) => {
    
    })
    this.spinner.hide();
  }

  updateEventBy(id) {
    this.router.navigate(['/crm/events/create-events'], { queryParams: { updateEventId: id } })
  }
 
  eventDetail(id) {
 
    this.router.navigate(['/crm/events/details-event'], { queryParams: { eventId: id } });

  }


}
