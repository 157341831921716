import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { ProductionService } from '../production.service';
@Component({
  selector: 'app-create-task-deliverable-product',
  templateUrl: './create-task-deliverable-product.component.html',
  styleUrls: ['./create-task-deliverable-product.component.css']
})
export class CreateTaskDeliverableProductComponent implements OnInit {
  createDeliverable:FormGroup;
  activeCategory=2;
  routingTaskId: any;
  productId: any;
  productIdArray: any[]=[];
  startDate: string;
  fromDate: string;
  throughDate: string;
  productIdEdit: any;
  fromDateEdit: any;
  workEffortGoodStdTypeId: any;
  thruDateEdit: any;
  show: boolean;

  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,
   
    readonly spinner: NgxSpinnerService,
    readonly productionService:ProductionService,
  
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createDeliverable = this._FormBuilder.group({
        ProductID: [''],
        FromDate:[''],
        ThroughDate: [''],
      
       
       
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.routingTaskId = params["routingTaskId"];
      this.fromDateEdit = params["fromDate"];
      this.productIdEdit = params["productId"];
      this.thruDateEdit = params["thruDate"];
    });
  
    this.getProducts();
    if(this.productIdEdit) {
      this.show=true;
      setTimeout(() => {
        const formValue = this.createDeliverable;
      formValue.patchValue({
        ProductID:this.productIdEdit,
        FromDate:this.datePipe.transform(this.fromDateEdit,"yyyy-MM-dd"),
        ThroughDate:this.datePipe.transform(this.thruDateEdit,"yyyy-MM-dd")
       
      })
      }, 3000);
     
    }
    else {
      this.show = false;
    }
  
  }
  
  onSubmit() {
    this.spinner.show();
    const startDate = this.createDeliverable.get('ThroughDate').value;
    this.throughDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const completeDate = this.createDeliverable.get('FromDate').value;
    this.fromDate = this.datePipe.transform(completeDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate":  this.fromDate,
      "productId": this.createDeliverable.value.ProductID,
      "thruDate": this.throughDate
    }
    this.productionService.postRoutingTaskDelievarbleProduct(this.routingTaskId,requestData).subscribe((res: any) => { 
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this._Router.navigate(["production/detail-routing-task-summary"], { queryParams: {  routingTaskId: this.routingTaskId } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  onUpdate() {
    this.spinner.show();
    const startDate = this.createDeliverable.get('ThroughDate').value;
    this.throughDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate": this.fromDateEdit,
      "productId": this.createDeliverable.value.ProductID,
      "thruDate": this.throughDate
    }
    this.productionService.updateRoutingTaskDelievarbleProduct(this.routingTaskId,requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this._Router.navigate(["production/detail-routing-task-summary"], { queryParams: {  routingTaskId: this.routingTaskId } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getProducts(): void {
    this.spinner.show();
  
    this.productionService.getJobShopInitialData().subscribe((res) => {
      
      this.productId = res.data.productId;
      
      this.spinner.hide();
     
      for (const value of this.productId) {
        this.productIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    
    })
  
  }

  
  cancelSubmit(){
    this._Router.navigate(['/production/detail-routing-task-summary'],{ queryParams: { routingTaskId :this.routingTaskId}})
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}

