<div class="container-fluid main-container-wrapper">

    <div class=" bg-white color-grey">
        <div class="port-header-contact create-lead font-medium mb-0">
           <span class="color-black pl-1 titlePanels">Shipments
            </span>

            <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
        </div>
        <div class="w3-card-4 classCard">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left: 9px; width: calc(103% - 5px); flex-wrap: wrap; display: flex; margin-bottom: 8px !important;">
                    <li [ngClass]="activeCat==1?'active':''" (click)="shipmentGatewayOpen()">
                        <a>Shipment Gateway</a>
                    </li>
                    <li [ngClass]="activeCat==2?'active':''" (click)="shipmentsOpen()">
                        <a>Shipments</a>
                    </li>
                </ul>
            </div>
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100" [formGroup]="warehouseShipment">
                            <div class="w3-card-4 classCard">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">

                                    <button type="button" class="btn btn-outline-secondary active" routerLinkActive="active" data-toggle="modal" data-target="#exampleModalCenter">Create New Shipment</button>

                                </div>
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInputC">
                                                <label for="exampleInputEmail1">Shipment ID</label>
                                            </div>
                                            <div class="col-lg-2" style="display: none;">
                                                <p-dropdown [options]="opportunityIdArray" filter="true" formControlName="shipmentIdSearchType">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" name="partyId" style="width:100%;" formControlName="shipmentId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Shipment ID">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Shipment Type</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="ShipmentTypeIdList" optionLabel="shipmentTypeId" filter="true" placeholder="Select" formControlName="shipmentType">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInputC">
                                                <label for="exampleInputEmail1">Status</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="StatusId" optionLabel="description" placeholder="Select Status" filter="true" formControlName="statusId" (ngModelChange)="onChange($event)"></p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Origin Facility</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="facilities" placeholder="Select Origin Facility" filter="true" formControlName="originFacility"></p-dropdown>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInputC">
                                                <label for="exampleInputEmail1">Destination Facility</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="facilities" placeholder="Select Destination Facility" filter="true" formControlName="destinationFacility"></p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Estimated Ship Date From</label>
                                            </div>
                                            <div class="col-lg-2" style="display: none;">
                                                <p-dropdown [options]="estimatedShipDateFromSearchType" filter="true" formControlName="estimatedShipDateFromSearchType">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" id="exampleInputEmail1" formControlName="estimatedShipDateFrom" style="width:100%;" aria-describedby="emailHelp" >
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInputC">
                                                <label for="exampleInputEmail1">Estimated Ship Date to</label>
                                            </div>
                                            <div class="col-lg-2" style="display: none;">
                                                <p-dropdown [options]="estimatedShipDateToSearchType" filter="true" formControlName="estimatedShipDateToSearchType">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" id="exampleInputEmail1" style="width: 100%;" formControlName="estimatedShipDateTo" aria-describedby="emailHelp" >
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Entry Date From</label>
                                            </div>
                                            <div class="col-lg-2" style="display: none;">
                                                <p-dropdown [options]="entryDateFromSearchType" filter="true" formControlName="entryDateFromSearchType">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" id="exampleInputEmail1" style="width: 100%;" formControlName="entryDateFrom" aria-describedby="emailHelp" >
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInputC">
                                                <label for="exampleInputEmail1">Entry Date To</label>
                                            </div>
                                            <div class="col-lg-2" style="display: none;">
                                                <p-dropdown [options]="entryDateToSearchType" filter="true" formControlName="entryDateToSearchType">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" formControlName="entryDateTo" id="exampleInputEmail1" style="width: 100%;" aria-describedby="emailHelp" >

                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -24%;"><button _ngcontent-cta-c128="" type="submit" (click)="onSubmit()" class="btn btn-secondary submit-btn">Find</button></div>
                            </div><br>
                            <div class="w3-card-4 classCardA">
                                <header class="w3-container w3-blue">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling h4Margin">
                                            Shipments List
                                        </h4>

                                    </div>
                                </header>
                                <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select allUserClass">
                                    <div style="width: 100%;">
                                        <div class="form-group">


                                            <div class="card own-account-table shipment-table">
                                                <p-table [value]="FacilityShipmentsLists" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr style="background: #0d3769;">
                                                            <th pSortableColumn="code" [ngStyle]="{'width':'180px'}">
                                                                <div class="checkbox-align" style="color: white;">
                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined" value="undefined"></div>
                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>
                                                                    Shipment ID
                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                </div>
                                                            </th>
                                                            <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                <div style="color: white;"> Shipment Type
                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                </div>
                                                            </th>
                                                            <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                <div style="color: white;"> Status
                                                                    <p-sortIcon field="category"></p-sortIcon>
                                                                </div>
                                                            </th>
                                                            <th pSortableColumn="quantity" [ngStyle]="{'width':'180px'}">
                                                                <div style="color: white;"> Origin Facility
                                                                    <p-sortIcon field="quantity"></p-sortIcon>
                                                                </div>
                                                            </th>
                                                            <th pSortableColumn="price" [ngStyle]="{'width':'180px'}">
                                                                <div style="color: white;"> Destination Facility
                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                </div>
                                                            </th>
                                                            <th pSortableColumn="price" [ngStyle]="{'width':'180px'}">
                                                                <div style="color: white;"> Estimated Ship Date
                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                </div>
                                                            </th>
                                                            <th pSortableColumn="price" [ngStyle]="{'width':'180px'}">
                                                                <div style="color: white;"> Created At
                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                </div>
                                                            </th>

                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-product>
                                                        <tr>
                                                            <td [ngStyle]="{'width':'180px'}">
                                                                <div class="checkbox-align">
                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined" value="undefined"></div>
                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>
                                                                    <span (click)="summary(product.shipmentId,product.status)" class="account-button"> {{product.shipmentId}}</span>
                                                                </div>
                                                            </td>
                                                            <td [ngStyle]="{'width':'180px'}">{{product.shipmentType}}</td>
                                                            <td [ngStyle]="{'width':'180px'}">{{product.status}}</td>
                                                            <td [ngStyle]="{'width':'180px'}">{{product.originFacility}}</td>
                                                            <td [ngStyle]="{'width':'180px'}">{{product.destinationFacility}}</td>
                                                            <td [ngStyle]="{'width':'180px'}">{{product.estimatedShipDate | date:'yyyy-MM-dd hh:mm:ss'}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'180px'}">{{product.createdStamp | date:'yyyy-MM-dd hh:mm:ss'}}
                                                            </td>


                                                        </tr>
                                                    </ng-template>

                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                        <tr>
                                                            <td>
                                                                <span class="text-center">
                                                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                                                    </span>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>

                                                <p class="paginate_data">
                                                    View per page </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>


    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create New Shipment</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                            Create New Shipment</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="newWarehouseShipment">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Status ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="StatusIdList" optionLabel="description" placeholder="Select Status ID" filter="true" formControlName="statusId"></p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Primary Order ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="OrderIdsList" optionLabel="orderId" placeholder="Select Primary Order ID" filter="true" formControlName="primaryOrderId"></p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Primary Return ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Primary Return ID" formControlName="primaryReturnId">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Primary Ship Group Seq ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Primary Ship Group Seq ID" formControlName="primaryShipGroupSeqId">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Picklist Bin</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Picklist Bin" formControlName="picklistBinId">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Estimated Ready Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="estimatedReadyDate">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Ship Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="estimatedShipDate">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Estimated Arrival Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="estimatedArrivalDate">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Latest Cancel Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="latestCancelDate">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Estimated Ship Cost</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Estimated Ship Cost" formControlName="estimatedShipCost">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Currency Uom ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="getCurrencyList" optionLabel="uomId" placeholder="Select Currency" filter="true" formControlName="currencyUomId" placeholder="Currency Uom ID"></p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Handling Instructions</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Handling Instructions" formControlName="handlingInstructions">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Origin Facility</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="facilities" optionLabel="label" placeholder="Select Origin Facility" filter="true" formControlName="originFacilityId"></p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Destination Facility</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="facilities" optionLabel="label" placeholder="Select Destination Facility" filter="true" formControlName="originFacility"></p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Origin Postal Address ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Origin Postal Address ID" formControlName="adress">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Origin Phone Number ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Origin Phone Number ID" formControlName="originTelecomNumberId">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Destination Postal Address ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Destination Postal Address ID" formControlName="destinationFacilityId">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Destination Phone Number ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Destination Phone Number ID" formControlName="destinationTelecomNumberId">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">To Party</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="personsAndPartyGroupsList" optionLabel="partyId" filter="true" placeholder="Select To Party" formControlName="partyIdTo">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">From Party</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="personsAndPartyGroupsList" optionLabel="partyId" filter="true" placeholder="Select From Party" formControlName="partyIdFrom">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Additional Shipping Charge</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Additional Shipping Charge" formControlName="additionalShippingCharge">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Addtl Shipping Charge Desc</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Addtl Shipping Charge Desc" formControlName="addtlShippingChargeDesc">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Event Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="eventDate">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Shipment type ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="ShipmentTypeIdList" optionLabel="shipmentTypeId" filter="true" placeholder="Select Shipment type ID" formControlName="shipmentTypeId">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div class="main-submit-button" style="margin-right: 10%;">
                                            <button type="submit" (click)="submit()" class="btn btn-secondary submit-btn update-button">Submit</button>

                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>