<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Facilities </span>
                <span class="color-black pl-1"> > Contact Information</span>
                <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger" style="margin-left: 75%;"
                (click)="onBack()">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard card-border" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
            
                                <li Class="active"><a>
                                    Create Contact Information</a></li>
                            </ul>
                        </div>
            
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 text-white"
                                            data-toggle="collapse" href="#collapsetwo">
                                            <div class="w-100">
                                                <div class="panel-group" id="accordion" role="tablist"
                                                    aria-multiselectable="true">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <ng-container [ngSwitch]="contactInformationTab">
                                                            <div class="col-lg-12" *ngSwitchCase="'initial'">
                                                                <div class="row">
                                                                    
                                                                    <div class="col-lg-4 form-group" style="text-align: right!important;
                                                                    padding-right: 0px!important;
                                                                    margin-left: -6%;">
                                                                        <label for="contactType" class="mr-5">Select Contact
                                                                            Type</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <p-dropdown filter="true" [options]="contactTypes"
                                                                        [(ngModel)]="contactInformationValue"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        optionlabel="label"
                                                                        placeholder="Select Contact">
                                                                    </p-dropdown>
                                                                    
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        
                                                                    <button
                                                                    class="btn btn-secondary update-button cursor-pointer ml-4"
                                                                    style="height: 37px;"
                                                                    (click)="prepareContactForm()">Create</button>
                                                                    </div>
                                                                </div>
                                                            </div>
            
                                                        
                                                            <ng-container *ngSwitchCase="'ELECTRONIC_ADDRESS'">
                                                                <form [formGroup]="commonElectronicAddressForm">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                
                                                                                <div class="col-lg-6 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                margin-left: -9%;">
                                                                                    <label for="electronicAddress">
                                                                                        {{contactInformationValue==='IP_ADDRESS' ? 'Internet IP Address': contactInformationValue==='DOMAIN_NAME' ? 'Internet Domain Name':contactInformationValue==='WEB_ADDRESS' ? 'Web URL/Address' : contactInformationValue==='INTERNAL_PARTYID' ? 'Internal Note via partyId': contactInformationValue==='FTP_ADDRESS' ? 'Ftp server connection' : contactInformationValue==='LDAP_ADDRESS' ? 'LDAP URL': 'Electronic Address'}}</label>
                                                                                </div>
                                                                                <div class="col-lg-5">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="infoString">
                                                                                <small class="validate-field"
                                                                                    *ngIf="!!commonElectronicAddressForm.controls.infoString.invalid && !!commonElectronicAddressForm.controls.infoString.touched">
                                                                                    {{contactInformationValue==='IP_ADDRESS' ? 'Internet IP Address': contactInformationValue==='DOMAIN_NAME' ? 'Internet Domain Name':contactInformationValue==='WEB_ADDRESS' ? 'Web URL/Address' : contactInformationValue==='INTERNAL_PARTYID' ? 'Internal Note via partyId': contactInformationValue==='FTP_ADDRESS' ? 'Ftp server connection' : contactInformationValue==='LDAP_ADDRESS' ? 'LDAP URL': 'Electronic Address'}}
                                                                                    is required!</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </form>
                                                                <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -108%;
                                                                margin-top: 5%;">
                                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                                        (click)="submitCommonElectronicAddress()">Save</button>
                                                                    
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'EMAIL_ADDRESS'">
                                                                <form [formGroup]="emailAddressForm">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                
                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                margin-left: -1%;">
                                                                                    <label for="contactPurposes">Contact
                                                                                        Purposes
                                                                                        <span style="color:red">*</span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <p-dropdown filter="true"
                                                                                        [options]="contactPurposeIds"
                                                                                        optionlabel="label"
                                                                                        formControlName="contactMechPurposeTypeId"
                                                                                        placeholder="Select Contact">
                                                                                    </p-dropdown>
                                                                                    <small class="validate-field"
                                                                                        *ngIf="!!emailAddressForm.controls.contactMechPurposeTypeId.invalid && !!emailAddressForm.controls.contactMechPurposeTypeId.touched">
                                                                                        Contact Purposes is required!</small>
                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                ">
                                                                                    <label for="electronicAddress">Email
                                                                                        Address</label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="emailAddress">
                                                                                <small class="validate-field"
                                                                                    *ngIf="!!emailAddressForm.controls.emailAddress.invalid && !!emailAddressForm.controls.emailAddress.touched">
                                                                                    Email Address is required!</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        
                                                                    </div>
                                                                </form>
                                                                <div class="col-lg-12 col-12 main-submit-button "  style="    margin-left: -34%;">
                                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                                        (click)="submitEmailAddress()">Save</button>
                                                                    
            
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'TELECOM_NUMBER'">
                                                                <form [formGroup]="telecomNumberForm">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                
                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                margin-left: -10%;">
                                                                                    <label for="contactPurposes">Contact
                                                                                        Purposes<span
                                                                                            style="color:red">*</span></label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true"
                                                                                    [options]="contactPurposeIds"
                                                                                    optionlabel="label"
                                                                                    formControlName="contactMechPurposeTypeId"
                                                                                    placeholder="Select Contact">
                                                                                </p-dropdown>
                                                                                <small class="validate-field"
                                                                                    *ngIf="!!telecomNumberForm.controls.contactMechPurposeTypeId.invalid && !!telecomNumberForm.controls.contactMechPurposeTypeId.touched">
                                                                                    Contact Purposes is required!</small>
                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                ">
                                                                                    <label for="electronicAddress">Area Code<span
                                                                                        style="color:red">*</span></label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="areaCode">
                                                                                <small class="validate-field"
                                                                                    *ngIf="!!telecomNumberForm.controls.areaCode.invalid && !!telecomNumberForm.controls.areaCode.touched">
                                                                                    Area Code is required!</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                
                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                margin-left: -10%;">
                                                                                    <label for="electronicAddress">Country Code<span
                                                                                        style="color:red">*</span></label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="countryCode">
                                                                                <small class="validate-field"
                                                                                    *ngIf="!!telecomNumberForm.controls.countryCode.invalid && !!telecomNumberForm.controls.countryCode.touched">
                                                                                    Country Code is required!</small>
                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                ">
                                                                                    <label for="electronicAddress">Contact
                                                                                        Number<span
                                                                                            style="color:red">*</span></label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="contactNumber">
                                                                                <small class="validate-field"
                                                                                    *ngIf="!!telecomNumberForm.controls.contactNumber.invalid && !!telecomNumberForm.controls.contactNumber.touched">
                                                                                    Contact Number is required!</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                
                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                margin-left: -10%;">
                                                                                    <label for="electronicAddress">Extension<span
                                                                                        style="color:red">*</span></label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="extension">
                                                                                <small class="validate-field"
                                                                                    *ngIf="!!telecomNumberForm.controls.extension.invalid && !!telecomNumberForm.controls.extension.touched">
                                                                                    Extension is required!</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
            
                                                                    </div>
                                                                </form>
                                                                <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -26%;">
                                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                                        (click)="submitTelecomNumber()">Save</button>
                                                                    
            
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'POSTAL_ADDRESS'">
                                                                <form [formGroup]="postalAddressForm">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                
                                                                                <div class="col-lg-2 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                margin-left: -1%;">
                                                                                    <label for="contactPurposes">Contact
                                                                                        Purposes<span
                                                                                            style="color:red">*</span></label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true"
                                                                                    [options]="contactPurposeIds"
                                                                                    optionlabel="label"
                                                                                    formControlName="contactMechPurposeTypeId"
                                                                                    placeholder="Select Contact">
                                                                                </p-dropdown>
                                                                                
                                                                                </div>
                                                                                <div class="col-lg-2 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                ">
                                                                                    <label for="electronicAddress">To Name</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="toName">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <div class="col-lg-12" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -1%;">
                                                                            <div class="row">
                                                                                
                                                                                <div class="col-lg-2 form-group classInput">
                                                                                    <label for="electronicAddress">Attention
                                                                                        Name</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="attnName">
                                                                                </div>
                                                                                <div class="col-lg-2 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                ">
                                                                                    <label for="electronicAddress">Address Line
                                                                                        1<span style="color:red">*</span></label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="address1">
                                                                                <small class="validate-field"
                                                                                    *ngIf="!!postalAddressForm.controls.address1.invalid && !!postalAddressForm.controls.address1.touched">
                                                                                    Address Line 1 is required!</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                
                                                                                <div class="col-lg-2 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                margin-left: -1%;">
                                                                                    <label for="electronicAddress">Address Line
                                                                                        2</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="address2">
                                                                                </div>
                                                                                <div class="col-lg-2 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                ">
                                                                                    <label for="electronicAddress">City<span
                                                                                        style="color:red">*</span></label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="city">
                                                                                <small class="validate-field"
                                                                                    *ngIf="!!postalAddressForm.controls.city.invalid && !!postalAddressForm.controls.city.touched">
                                                                                    City is required!</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                
                                                                                <div class="col-lg-2 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                margin-left: -1%;">
                                                                                    <label for="electronicAddress">Zip/Postal
                                                                                        Code<span style="color:red">*</span></label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="postalCode">
                                                                                <small class="validate-field"
                                                                                    *ngIf="!!postalAddressForm.controls.postalCode.invalid && !!postalAddressForm.controls.postalCode.touched">
                                                                                    Zip/Postal Code is required!</small>
                                                                                </div>
                                                                                <div class="col-lg-2 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                ">
                                                                                    <label for="country">Country</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true"
                                                                                    [options]="countryList"
                                                                                    optionlabel="label"
                                                                                    formControlName="countryGeoId"
                                                                                    placeholder="Select Country">
                                                                                </p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                
                                                                                <div class="col-lg-2 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                margin-left: -1%;">
                                                                                    <label for="country">State</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="states"
                                                                                        optionlabel="label"
                                                                                        formControlName="stateProvinceGeoId"
                                                                                        placeholder="Select State">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>
            
                                                                        
                                                                    </div>
                                                                </form>
                                                                <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -35%;
                                                                ">
                                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                                        (click)="submitPostalAddress()">Save</button>
                                                                    
            
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
            
                    </div>
            </div>
        </div>
    </div>
</div>



<!-- <div class="modal fade" id="contactModalCenter" tabindex="-1" role="dialog" aria-labelledby="contactModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content cssModelLength" style="width: 129% !important;margin-left: -10% !important;">
            <div class="modal-header">
             
                <span class="color-black pl-1"> Create Contact Information</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCard card-border" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
    
                                <li Class="active"><a>
                                    Create Contact Information</a></li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 text-white"
                                            data-toggle="collapse" href="#collapsetwo">
                                            <div class="w-100">
                                                <div class="panel-group" id="accordion" role="tablist"
                                                    aria-multiselectable="true">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <ng-container [ngSwitch]="contactInformationTab">
                                                            <div class="col-lg-12" *ngSwitchCase="'initial'">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-4 form-group" style="text-align: right!important;
                                                                    padding-right: 0px!important;
                                                                    margin-left: -6%;">
                                                                        <label for="contactType" class="mr-5">Select Contact
                                                                            Type</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <p-dropdown filter="true" [options]="contactTypes"
                                                                        [(ngModel)]="contactInformationValue"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        optionlabel="label"
                                                                        placeholder="Select Contact">
                                                                    </p-dropdown>
                                                                   
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                      
                                                                    <button
                                                                    class="btn btn-secondary update-button cursor-pointer ml-4"
                                                                    style="height: 37px;"
                                                                    (click)="prepareContactForm()">Create</button>
                                                                    </div>
                                                                </div>
                                                            </div>
        
                                                        
                                                            <ng-container *ngSwitchCase="'ELECTRONIC_ADDRESS'">
                                                                <form [formGroup]="commonElectronicAddressForm">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                              
                                                                                <div class="col-lg-6 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                margin-left: -9%;">
                                                                                    <label for="electronicAddress">
                                                                                        {{contactInformationValue==='IP_ADDRESS' ? 'Internet IP Address': contactInformationValue==='DOMAIN_NAME' ? 'Internet Domain Name':contactInformationValue==='WEB_ADDRESS' ? 'Web URL/Address' : contactInformationValue==='INTERNAL_PARTYID' ? 'Internal Note via partyId': contactInformationValue==='FTP_ADDRESS' ? 'Ftp server connection' : contactInformationValue==='LDAP_ADDRESS' ? 'LDAP URL': 'Electronic Address'}}</label>
                                                                                </div>
                                                                                <div class="col-lg-5">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="infoString">
                                                                                <small class="validate-field"
                                                                                    *ngIf="!!commonElectronicAddressForm.controls.infoString.invalid && !!commonElectronicAddressForm.controls.infoString.touched">
                                                                                    {{contactInformationValue==='IP_ADDRESS' ? 'Internet IP Address': contactInformationValue==='DOMAIN_NAME' ? 'Internet Domain Name':contactInformationValue==='WEB_ADDRESS' ? 'Web URL/Address' : contactInformationValue==='INTERNAL_PARTYID' ? 'Internal Note via partyId': contactInformationValue==='FTP_ADDRESS' ? 'Ftp server connection' : contactInformationValue==='LDAP_ADDRESS' ? 'LDAP URL': 'Electronic Address'}}
                                                                                    is required!</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                      
                                                                    </div>
                                                                </form>
                                                                <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -108%;
                                                                margin-top: 5%;">
                                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                                        (click)="submitCommonElectronicAddress()">Save</button>
                                                                   
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'EMAIL_ADDRESS'">
                                                                <form [formGroup]="emailAddressForm">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                              
                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                margin-left: -1%;">
                                                                                    <label for="contactPurposes">Contact
                                                                                        Purposes
                                                                                        <span style="color:red">*</span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <p-dropdown filter="true"
                                                                                        [options]="contactPurposeIds"
                                                                                        optionlabel="label"
                                                                                        formControlName="contactMechPurposeTypeId"
                                                                                        placeholder="Select Contact">
                                                                                    </p-dropdown>
                                                                                    <small class="validate-field"
                                                                                        *ngIf="!!emailAddressForm.controls.contactMechPurposeTypeId.invalid && !!emailAddressForm.controls.contactMechPurposeTypeId.touched">
                                                                                        Contact Purposes is required!</small>
                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                               ">
                                                                                    <label for="electronicAddress">Email
                                                                                        Address</label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="emailAddress">
                                                                                <small class="validate-field"
                                                                                    *ngIf="!!emailAddressForm.controls.emailAddress.invalid && !!emailAddressForm.controls.emailAddress.touched">
                                                                                    Email Address is required!</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                     
                                                                      
                                                                    </div>
                                                                </form>
                                                                <div class="col-lg-12 col-12 main-submit-button "  style="    margin-left: -34%;">
                                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                                        (click)="submitEmailAddress()">Save</button>
                                                                   
        
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'TELECOM_NUMBER'">
                                                                <form [formGroup]="telecomNumberForm">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                              
                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                margin-left: -10%;">
                                                                                    <label for="contactPurposes">Contact
                                                                                        Purposes<span
                                                                                            style="color:red">*</span></label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true"
                                                                                    [options]="contactPurposeIds"
                                                                                    optionlabel="label"
                                                                                    formControlName="contactMechPurposeTypeId"
                                                                                    placeholder="Select Contact">
                                                                                </p-dropdown>
                                                                                <small class="validate-field"
                                                                                    *ngIf="!!telecomNumberForm.controls.contactMechPurposeTypeId.invalid && !!telecomNumberForm.controls.contactMechPurposeTypeId.touched">
                                                                                    Contact Purposes is required!</small>
                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                               ">
                                                                                    <label for="electronicAddress">Area Code<span
                                                                                        style="color:red">*</span></label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="areaCode">
                                                                                <small class="validate-field"
                                                                                    *ngIf="!!telecomNumberForm.controls.areaCode.invalid && !!telecomNumberForm.controls.areaCode.touched">
                                                                                    Area Code is required!</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                      
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                              
                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                margin-left: -10%;">
                                                                                    <label for="electronicAddress">Country Code<span
                                                                                        style="color:red">*</span></label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="countryCode">
                                                                                <small class="validate-field"
                                                                                    *ngIf="!!telecomNumberForm.controls.countryCode.invalid && !!telecomNumberForm.controls.countryCode.touched">
                                                                                    Country Code is required!</small>
                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                               ">
                                                                                    <label for="electronicAddress">Contact
                                                                                        Number<span
                                                                                            style="color:red">*</span></label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="contactNumber">
                                                                                <small class="validate-field"
                                                                                    *ngIf="!!telecomNumberForm.controls.contactNumber.invalid && !!telecomNumberForm.controls.contactNumber.touched">
                                                                                    Contact Number is required!</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                              
                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                margin-left: -10%;">
                                                                                    <label for="electronicAddress">Extension<span
                                                                                        style="color:red">*</span></label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="extension">
                                                                                <small class="validate-field"
                                                                                    *ngIf="!!telecomNumberForm.controls.extension.invalid && !!telecomNumberForm.controls.extension.touched">
                                                                                    Extension is required!</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
        
                                                                    </div>
                                                                </form>
                                                                <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -26%;">
                                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                                        (click)="submitTelecomNumber()">Save</button>
                                                                   
        
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'POSTAL_ADDRESS'">
                                                                <form [formGroup]="postalAddressForm">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                              
                                                                                <div class="col-lg-2 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                margin-left: -1%;">
                                                                                    <label for="contactPurposes">Contact
                                                                                        Purposes<span
                                                                                            style="color:red">*</span></label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <p-dropdown filter="true"
                                                                                    [options]="contactPurposeIds"
                                                                                    optionlabel="label"
                                                                                    formControlName="contactMechPurposeTypeId"
                                                                                    placeholder="Select Contact">
                                                                                </p-dropdown>
                                                                              
                                                                                </div>
                                                                                <div class="col-lg-2 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                              ">
                                                                                    <label for="electronicAddress">To Name</label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="toName">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-12" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -1%;">
                                                                            <div class="row">
                                                                              
                                                                                <div class="col-lg-2 form-group classInput">
                                                                                    <label for="electronicAddress">Attention
                                                                                        Name</label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="attnName">
                                                                                </div>
                                                                                <div class="col-lg-2 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                              ">
                                                                                    <label for="electronicAddress">Address Line
                                                                                        1<span style="color:red">*</span></label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="address1">
                                                                                <small class="validate-field"
                                                                                    *ngIf="!!postalAddressForm.controls.address1.invalid && !!postalAddressForm.controls.address1.touched">
                                                                                    Address Line 1 is required!</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                              
                                                                                <div class="col-lg-2 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                margin-left: -1%;">
                                                                                    <label for="electronicAddress">Address Line
                                                                                        2</label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="address2">
                                                                                </div>
                                                                                <div class="col-lg-2 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                               ">
                                                                                    <label for="electronicAddress">City<span
                                                                                        style="color:red">*</span></label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="city">
                                                                                <small class="validate-field"
                                                                                    *ngIf="!!postalAddressForm.controls.city.invalid && !!postalAddressForm.controls.city.touched">
                                                                                    City is required!</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                              
                                                                                <div class="col-lg-2 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                margin-left: -1%;">
                                                                                    <label for="electronicAddress">Zip/Postal
                                                                                        Code<span style="color:red">*</span></label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <input type="text" class="form-control"
                                                                                    formControlName="postalCode">
                                                                                <small class="validate-field"
                                                                                    *ngIf="!!postalAddressForm.controls.postalCode.invalid && !!postalAddressForm.controls.postalCode.touched">
                                                                                    Zip/Postal Code is required!</small>
                                                                                </div>
                                                                                <div class="col-lg-2 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                               ">
                                                                                    <label for="country">Country</label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <p-dropdown filter="true"
                                                                                    [options]="countryList"
                                                                                    optionlabel="label"
                                                                                    formControlName="countryGeoId"
                                                                                    placeholder="Select Country">
                                                                                </p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                              
                                                                                <div class="col-lg-2 form-group classInput" style="text-align: right!important;
                                                                                padding-right: 17px!important;
                                                                                margin-left: -1%;">
                                                                                    <label for="country">State</label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <p-dropdown filter="true" [options]="states"
                                                                                        optionlabel="label"
                                                                                        formControlName="stateProvinceGeoId"
                                                                                        placeholder="Select State">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>
        
                                                                     
                                                                    </div>
                                                                </form>
                                                                <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -16%;
                                                                ">
                                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                                        (click)="submitPostalAddress()">Save</button>
                                                                  
        
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                      
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<ngx-spinner></ngx-spinner> 