<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
          

            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Edit</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="openViewTree()"><a>
                                View Tree</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="openEdit()" ><a>
                                Edit</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="openApprovals()" ><a>
                                Approvals</a></li>
                            <li [ngClass]="activeCategory==4?'active':''"  (click)="openRevisions()"><a>
                                Revisions</a></li>
            
                            <li [ngClass]="activeCategory==5?'active':''" (click)="openCreateNewRoot()"><a>
                                Create New Root CompDoc Template</a></li>
            
                            <li [ngClass]="activeCategory==6?'active':''"  (click)="openApprovals()"><a>
                                View Waiting Approvals</a></li>
                                        
                        </ul>
                    </div>
                    <div class="create-new-leade ">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div *ngIf="activeCategory==2">

                                        <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                    style="justify-content: space-between;">
                                                    <p class="h-text">Summary</p>
                                                    <span>
                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"

                                                            data-target="#updateEditPopup" (click)="updateRootCompDocTemplatePatch()">Update</button>
                                                       
                                                            <button *ngIf="this.instanceBtn" type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" 
                                                            data-toggle="modal" data-target="#createNewInstancesTemplatePopup" (click)="restForm()">Create Instance Template</button> 
                                                        <!-- <button type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" 
                                                            data-toggle="modal" data-target="#createNewInstancesTemplatePopup">Create Instance Template</button>  -->

                                                    </span>
                                                </div>
                                                <div *ngIf="this.ViewCompDocTemplateTreeAll" class="all-users-infomation font-13 font-medium own-user-select">
                                                    
                                                    <div class="color-black container">
                                                        <div class="row">
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6" style="text-align: end;">Content ID	</p>
                                                                        <span class="color-grey pl-2 font-weight-bold">
                                                                            {{ViewCompDocTemplateTreeAll.ListContentRevisions.contentId}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6" style="text-align: end;">Content Name</p>
                                                                        <span class="color-grey pl-2 font-weight-bold">
                                                                        {{ViewCompDocTemplateTreeAll.ListContentRevisions.contentName}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="updateEditPopup" tabindex="-1" role="dialog" aria-labelledby="updateEditPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update CompDoc Template
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Update CompDoc Template </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateCompocTemlateForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Content ID	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="contentID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Content ID" [readonly]="surveyIdValue">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Content Name</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="contentName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Content Name">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"  (click)="updateRootCompDocTemplate()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 
<div class="modal fade" id="createNewInstancesTemplatePopup" tabindex="-1" role="dialog" aria-labelledby="createNewInstancesTemplatePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="this.show" class="color-black pl-1">Create New Instance Template
                </span>
                <span *ngIf="!this.show" class="color-black pl-1">Update Instance Template
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="this.show">
                                    <a>Create New Instance Template </a>
                                </li>
                                <li *ngIf="!this.show">
                                    <a>Update Instance Template </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createNewInstancesTemlateForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Content Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="contentName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Content Name">
                                                    
                                                        
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button *ngIf="this.show" type="submit" class="btn btn-secondary submit-btn"  (click)="genCompDocInstance()">Create</button>
                                                <button *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 

<ngx-spinner></ngx-spinner> 
