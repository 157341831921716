import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
    import { Location } from '@angular/common';
    import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { ProductionService } from '../production.service';
@Component({
  selector: 'app-add-quantity-produced',
  templateUrl: './add-quantity-produced.component.html',
  styleUrls: ['./add-quantity-produced.component.css']
})
export class AddQuantityProducedComponent implements OnInit {
  createQuantity: FormGroup;
  activeCategory = 2;
  products3: any;
  total = 0;
  rowNumber = 0;
  pageSize = 500;
  pageNo = 1;
  rows = 50;
  budgetItem: any[] = [];
  search = ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  workEffortId: any;
  workId: any;
  productListArray: any[]=[];
  productTask: any;

  constructor(readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
    
    readonly spinner: NgxSpinnerService,  private _location: Location,
    
    readonly productionService:ProductionService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) {
    this.createQuantity = this._FormBuilder.group({
      ProductID: [''],
      AddQtyProduced: [''],
      LotID: [''],


    });

  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params["workEffortId"];
      this.workId = params["workId"];
    
    });
    this.getProducts();
    this.getProductionRunTaskProduce();
  }
  
  getProductionRunTaskProduce(): void {
    this.spinner.show();
    this.productionService.getProductionRunTaskProduce(this.workId).subscribe((res) => {
      this.productTask = res.data;
      this.spinner.hide();
     

    });

  }
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "lotId": this.createQuantity.value.LotID,
      "productId": this.createQuantity.value.ProductID,
      "productionRunId":this.workEffortId,
      "quantity": this.createQuantity.value.AddQtyProduced,
      "workEffortId":this.workId
    }
    this.productionService.productionRunTaskProduce(requestData).subscribe((res: any) => {
      if (res.success) {  
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this._Router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId: this.workEffortId} })
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  getProducts(): void {
    this.spinner.show();
    const data = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains",
    };
    this.accountsService.getProductsList(data).subscribe((res) => {
      const productList = res.data;
      this.spinner.hide();
      this.productListArray = res.data.map(value => {
        return {
          label: value.productId,
          value: value.productId
        };
      });

    });

  }
  homeButton(){
    this._Router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  // cancelSubmit(){
  //   this._Router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId: this.workEffortId} })
  // }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }


  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
