import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from '../accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { Location } from '@angular/common';

@Component({
  selector: 'app-mail-reply',
  templateUrl: './mail-reply.component.html',
  styleUrls: ['./mail-reply.component.css']
})
export class MailReplyComponent implements OnInit {
  activeCategory=2;
  products3: any;
  rows = 50;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  search= '';
  RoleList:any[]=[]
  createComm:FormGroup;
  createCommRole:FormGroup;
  createContent:FormGroup
  constructor(
    readonly accountsService: AccountsService,
    private _location: Location,
  
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,

    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    
    readonly spinner: NgxSpinnerService,
  ) { 
    this.createComm = this._FormBuilder.group({
      partyId: [''],
      subject: [''],
      content: [''],
      emailAddress:[''],
      date:['']
 
    });
    this.createCommRole = this._FormBuilder.group({
      partyIdRole: [''],
      roleId: [''],
   
 
    });
    this.createContent = this._FormBuilder.group({
      existContent: [''],
      partContent: [''],
   
 
    });
  }

  ngOnInit(): void {
  }
  cancelSubmit(){
    this._location.back();
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
}


