<div class="container-fluid main-container-wrapper">
    <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-top: 1%;">
       <span class="color-black pl-1 titlePanels">Feature Category Summary </span>
       <span class="d-flex">
     
        <button type="submit" (click)="homeButton();"
            class=" btn btn-outline-secondary mr-2">Home</button>

        <button type="submit" (click)="cancelSubmit();"
            class="btn btn-danger buttonclass ">Back</button>
    </span>
    </div>
    <div class="create-new-leade mt-2">
        <div class="container-fluid">
            <div class="w-100">

                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                    <div class="w-100">
                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                            <div class="panel-group">
                                <div class="divA">
                                    <div class="w3-card-4 classCardView">
                                        <header class="w3-container w3-blue">
                                            <div class="header-tabs" >
                                                <h4 class=" common-styling h4Margin">
                                                    Feature Category Information
                                                </h4>
                                                <span *ngIf="!this.Hidebtn" class="edit-buttons hCreate">
                    
                                                  <a   data-toggle="modal" 
                                                  data-target="#exampleModalCenter">Update</a>
                                                </span>
                                            </div>
                                        </header>

                                        <div class="panel-body" *ngIf="this.featureCategories">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="color-black container">
                                                    <div class="row">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6 col-12"> Feature Category ID</p>
                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.featureCategories.productFeatureCategoryId}}</span>
                                                            </div>
                                                            <div class="article">
                                                                <p class="col-lg-6 col-12">Description </p>
                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.featureCategories.description}}</span>

                                                            </div>


                                                        </div>




                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <br>

                                </div>

                                <div class="divB">
                                    <div class="w3-card-4 classCardView">
                                        <div class="header-tabs">


                                            <h4 class=" common-styling hCard">
                                                Product Feature Maintenance
                                            </h4>
                                            <span *ngIf="!this.Hidebtn" class="edit-buttons hCreate" >
                                                <a  data-toggle="modal" 
                                                data-target="#addfeaturetype_Popup">Create New Product Feature Maintenance</a>
                                            </span>
                                        </div>
                                        <div>

                                        </div>
                                        <div class="panel-body">
                                            <div class="all-users-infomation font-13 font-medium own-user-select allUserCardDetail">
                                                <div class="card own-account-table">
                                                    <p-table [value]="featureMaintenance" [paginator]="false" [rows]="rows"   scrollHeight="150px"   scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                </div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                        </p-checkbox>
                                                                        Id
                                                                        <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                    <div style="color: white;"> Description
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                    <div style="color: white;"> Feature Type
                                                                        <p-sortIcon field="category"></p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                    <div style="color: white;"> Feature Category
                                                                        <p-sortIcon field="category"></p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                    <div style="color: white;"> Unit of Measure ID</div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                    <div style="color: white;"> Quantity</div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>

                                                                <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                    <div style="color: white;"> Amount</div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                    <div style="color: white;"> ID Seq Num</div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                    <div style="color: white;"> ID Code
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                    <div style="color: white;"> Abbrev</div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                    <div style="color: white;"> Action</div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
                                                            <tr>
                                                                <td routerLink="/facilities/catalog/feature-category/summary-page" [queryParams]="{featureMaintenanceId:product.productFeatureId}" [ngStyle]="{'width':'170px'}">
                                                                    <span   class="account-button">{{product.productFeatureId}}</span>
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.description }}</td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.productFeatureType}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.productFeatureCategory}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.uomId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.quantity}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.amount}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.IDSeqNum}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.idCode}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.abbrev}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'190px'}">
                                                                    <div *ngIf="!this.Hidebtn">
                                                                        <fa-icon (click)="edit(product.productFeatureId)"  class="account-button"  [icon]="faEdit"></fa-icon>

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <br>








                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Feature Category</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="    width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="this.editMode==false" [ngClass]="activeCategory==1?'active':''"><a>
                                Create Feature Category</a></li>
                                <li *ngIf="this.editMode==true" [ngClass]="activeCategory==1?'active':''"><a>
                                Update Feature Category</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="featureCategoryForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputG">
                                                        <label for="exampleInputEmail1">Description <span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <textarea class="form-control" id="exampleFormControlTextarea1" formControlName="description" rows="1" placeholder="Enter Description"></textarea>
                                                        <small class="text-danger" *ngIf="!!featureCategoryForm.controls.description.invalid && !!featureCategoryForm.controls.description.touched">Required</small>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA" *ngIf="!editMode">
                                                        <label for="exampleInputEmail1">Parent Category
                                        </label>
                                                    </div>

                                                    <div class="col-lg-3" *ngIf="!editMode">
                                                        <p-dropdown [options]="parentCategory" formControlName="parentCategoryId" placeholder="Parent Category">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                        <div class="main-submit-button" style="margin-right: 14%;">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="submit()">{{editMode ?'Update':'Create'}}</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="addfeaturetype_Popup" tabindex="-1" role="dialog" aria-labelledby="addfeaturetype_Title" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Feature Type
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebuttonN>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <br>


                <div class="w3-card-4 classCardPopUp" style="width: 100%;margin-top:0%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                                  background: #F5F7FA!important;">

                            <li class="aSize" [ngClass]="activeCategoryInv==2?'active':''">
                                Add Feature Type
</li>


                        </ul>
                    </div>
                    <div>
                        <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                            <div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100">

                                            <div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <form class="w-100" [formGroup]="productFeatureForm">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group" style="text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -6%;">
                                                                        <label for="featureType">Feature Type <span
                                                                            class="text-danger">*</span></label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="featureTypeIds" placeholder="Feature Type"
                                                                            filter="true" formControlName="productFeatureTypeId"></p-dropdown>
                                                                        <small class="text-danger"
                                                                            *ngIf="!!productFeatureForm.controls.productFeatureTypeId.invalid && !!productFeatureForm.controls.productFeatureTypeId.touched">Feature
                                                                            Type is required.</small>
                                                                </div>
                                                                <div class="col-lg-3 form-group " style="text-align: right!important;
                                                                padding-right: 17px!important;
                                                               ">
                                                                    <label for="featureCategory">Feature Category</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="featureCategories" placeholder="Feature Category"
                                                                        filter="true" formControlName="productFeatureCategoryId">
                                                                    </p-dropdown>
                                                            </div>
                                                            </div>
                                                            </div>
                                                        
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group" style="text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -6%;">
                                                                        <label for="description">Description <span
                                                                            class="text-danger">*</span></label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="text" class="form-control" formControlName="description"
                                                                        placeholder="Enter Description">
                                                                    <small class="text-danger"
                                                                        *ngIf="!!productFeatureForm.controls.description.invalid && !!productFeatureForm.controls.description.touched">Description
                                                                        is required.</small>
                                                                </div>
                                                                <div class="col-lg-3 form-group" style="text-align: right!important;
                                                                padding-right: 17px!important;
                                                               ">
                                                                    <label for="uomId">Unit of Measure ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="productUOMIds" formControlName="uomId"
                                                                        filter="true" placeholder="Unit Of Measure Id"></p-dropdown>
                                                                  
                                                            </div>
                                                            </div>
                                                            </div>
                                                         
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group" style="text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -6%;">
                                                                        <label for="quantity">Number / Quantity</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="text" class="form-control" formControlName="numberSpecified"
                                                                        placeholder="Enter Number / Quantity">
                                                                </div>
                                                                <div class="col-lg-3 form-group" style="text-align: right!important;
                                                                padding-right: 17px!important;
                                                                ">
                                                                    <label for="amount">Default Amount</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="text" class="form-control" formControlName="defaultAmount"
                                                                    placeholder="Enter Default Amount">
                                                            </div>
                                                            </div>
                                                            </div>
                                                            
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group" style="text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -6%;">
                                                                        <label for="sequence">Default Sequence Number</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="text" class="form-control" formControlName="defaultSequenceNum"
                                                                        placeholder="Enter Default Sequence Number">
                                                                </div>
                                                                <div class="col-lg-3 form-group" style="text-align: right!important;
                                                                padding-right: 17px!important;
                                                               ">
                                                                    <label for="abbr">Abbreviation</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="text" class="form-control" formControlName="abbrev"
                                                                    placeholder="Enter Abbreviation">
                                                            </div>
                                                            </div>
                                                            </div>
                                                         
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group" style="text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -6%;">
                                                                        <label for="IdCode">ID Code</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="text" class="form-control" formControlName="idCode"
                                                                        placeholder="Enter ID Code">
                                                                </div>
                                                            </div>
                                                            </div>
                                                          
                                                          
                                                          
                                                            <div class="col-lg-12 col-12 main-submit-button " style="
                                                            margin-left: -22%;
                                                        ">
                                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                                    (click)="submit1()">{{editMode ?'Update':'Add'}}</button>&nbsp;
                                                                <button class="btn btn-danger" (click)="reset()">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>


            </div>

        </div>
    </div>
</div>













<ngx-spinner></ngx-spinner>