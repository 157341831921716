import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { SortEvent } from 'primeng/api';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { BillingAccountService } from 'src/app/accounting/accounting-ap/billing-account/billing-account.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  total=0;
  @ViewChild('closebutton') closebutton;
  isShown = false; 
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  activeCategoriess =6;
  pageNo = 1;
  billingAccountForm: FormGroup;
  activeCategory = 2;
  currencyUomIds: any[];
  partyIds: any[];
  editMode: boolean;
  billingAccountId: string;
  billingAccount: any;
  contactMechIds: any[];
  opportunityIdArray: any = [
      {
          label: 'Equals',
          value: 'Equals'
      },
      {
          label: 'Contains',
          value: 'Contains'
      },
      {
          label: 'Is Empty',
          value: 'Is Empty'
      },
      {
          label: 'Begins With',
          value: 'Begins With'
      },
  ];
  searchBillingAccountForm: FormGroup;
  billingAccounts: any[];
  faEdit = faEdit;
  agreementType: string;
  constructor(
      readonly _FormBuilder: FormBuilder,
      readonly spinner: NgxSpinnerService,
      readonly _BillingAccountService: BillingAccountService,
      readonly _GlobalResourceService: GlobalResourceService,
    
      readonly _ToastrService: ToastrService,
      readonly  _Router: Router,
   
      readonly _ActivatedRoute: ActivatedRoute,
      private _location: Location,
  
  ) {
      this.currencyUomIds = [];



      this.partyIds = [];
      this.editMode = false;
      this.contactMechIds = [];
      this.billingAccountForm = this._FormBuilder.group({
        accountCurrencyUomId: [''],
        accountLimit: [''],
        availableBalance: [0],
        contactMechId: [''],
        description: ['', [Validators.required]],
        externalAccountId: [''],
        fromDate: [''],
        partyId: ['', [Validators.required]],
        thruDate: [''],
        accountType:[''],
        name:['']
      });
      this.billingAccountId = '';
      this.searchBillingAccountForm = this._FormBuilder.group({
          accountLimit: [''],
          billingAccountId: [''],
          billingAccountIdSearchType: ['Contains'],
          description: [''],
          descriptionSearchType: ['Contains'],
          fromDate: [''],
          thruDate: ['']
      });
      this.billingAccounts = [];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
      this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
      this.billingAccountId = this._ActivatedRoute.snapshot.queryParams.billingAccountId;
      if (this.billingAccountId) {
        this.editMode = true;
        this._BillingAccountService.getBillingAccountById(this.billingAccountId)
          .then(data => {
            this.billingAccount = data.data;
            this.billingAccountForm.patchValue({
              ...data.data, ...{
                fromDate: data.data.fromDate ? new Date(data.data.fromDate) : '',
                thruDate: data.data.thruDate ? new Date(data.data.thruDate) : ''
              }
            });
          });
      }
      this.getBillingAccounts();
      this.getContactMechId();
      this.getCurrency();
      this.getPartyIds();
  }
  mainPage()
  {
    this._Router.navigate(['/financial/main-accounting']);
  }
  accountingPage()
  {
    this._Router.navigate(['/financial/Accounting']);
  }
  invoicePage()
  {
    this._Router.navigate(['/financial/Invoices']);
  }
  paymentPage()
  {
    this._Router.navigate(['/financial/Payments']);
  }
  paymentGrpPage()
  {
    this._Router.navigate(['/financial/Payment-group']);
  }
  billingAccountPage()
  {
    this._Router.navigate(['/financial/Billing-Account']);
  }
  agreementPage()
  {
    this._Router.navigate(['/financial/Agreements']);
  }
  commisionReportPage()
  {
    this._Router.navigate(['/financial/Commission-Report']);
  }
  mainAgreementPage()
  {
    this._Router.navigate(['/financial/main-agreements']);
  } 
  mainBillingAccountPage()
  {
    this._Router.navigate(['/financial/main-Billing-Accounts']);
  }
  mainFixedAssetPage()
  {
    this._Router.navigate(['/financial/main-Fixed-Assets']);
  } 
  
  mainInvoicePage()
  {
    this._Router.navigate(['/financial/main-Invoices']);
  }
   mainPaymentPage()
  {
    this._Router.navigate(['/financial/main-Payment']);
  }


backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this._Router.navigate(['/financial/main']);
}
  resetForm(){
    this.searchBillingAccountForm.reset();
  }
  getContactMechId(): void {
      this.spinner.show();
      this._BillingAccountService.getBillingAccountContactMechIdList(this.billingAccountId)
        .then(data => {
          this.spinner.hide();
          this.contactMechIds = data.data.map(value => {
            return {
              label: value.description,
              value: value.contactMechId
            };
          });
        });
       
    }
    getPartyIds(): void {
      this.spinner.show();
      this._GlobalResourceService.getPartyGroups()
        .then(data => {
          this.spinner.hide();
          this.partyIds = data.data.PersonsGroups.map(value => {
            return {
              label: value.partyId,
              value: value.partyId
            };
          });
        });
       
    }
    getCurrency(): void {
      this.spinner.show();
      this._GlobalResourceService.getCurrency()
        .then(data => {
          this.spinner.hide();
          this.currencyUomIds = data.data.currencyList.map(value => {
            return {
              label: value.description,
              value: value.uomId
            };
          });
        });
      
    }
 
    submit(): void {
      this.spinner.show();
      if (this.editMode) {
       
      } else {
        console.log(this.billingAccountForm.value.accountType);
        console.log(this.billingAccountForm.value.name);

        if (this.billingAccountForm.valid) {
          this._BillingAccountService.createBillingAccount({
            ...this.billingAccountForm.value, ...{
              fromDate: this.billingAccountForm.value.fromDate ? moment(this.billingAccountForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
              thruDate: this.billingAccountForm.value.thruDate ? moment(this.billingAccountForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
            }
          })
            .then(data => {
              if (data.success) {
                this._ToastrService.success('Created');
                this.billingAccountForm.reset();
                this.closebutton.nativeElement.click();
                this.spinner.hide();
                this.getBillingAccounts();
               
              }
            });
        }
      }
    
    }
  invoiceType(){
      this.isShown = !this.isShown;
    }
  getBillingAccounts(): void {
      this.spinner.show();
      this._BillingAccountService.getBillingAccounts({ pageNo: 1, pageSize: 200 }, {
          ...this.searchBillingAccountForm.value, ...{
              fromDate: this.searchBillingAccountForm.value.fromDate ? moment(this.searchBillingAccountForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
              thruDate: this.searchBillingAccountForm.value.thruDate ? moment(this.searchBillingAccountForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
          }
      })
          .then(data => {
              if (data) {
                  this.billingAccounts = data.data;
                  this.spinner.hide();
              }
          });
         
  }
  customSort(event: SortEvent): void {
      event.data.sort((data1, data2) => {
          const value1 = data1[event.field];
          const value2 = data2[event.field];
          let result = null;

          if (value1 == null && value2 != null) {
              result = -1;
          }
          else if (value1 != null && value2 == null) {
              result = 1;
          }
          else if (value1 == null && value2 == null) {
              result = 0;
          }
          else if (typeof value1 === 'string' && typeof value2 === 'string') {
              result = value1.localeCompare(value2);
          }
          else {
              result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          }
          return (event.order * result);
      });
  }
  ngOnDestroy(): void {
      this.spinner.hide();
        }
        
        cancelSubmit(){
          this._Router.navigate(['/financial/main'])
        }
  
}
