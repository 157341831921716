import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';
import { AccountsService } from '../accounts/accounts.service';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
@Component({
  selector: 'app-main-detail-summary',
  templateUrl: './main-detail-summary.component.html',
  styleUrls: ['./main-detail-summary.component.css']
})
export class MainDetailSummaryComponent implements OnInit {
  @ViewChild('closebutton') closebutton;

  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  search = '';
  accountsList:any[]=[]
  relatedContact:any[]=[]
  rows = 50;
  activeCategory=2;
  activeCategoryValue=2;
  commEventId: any;
  overviewDetail: any;
  communicationEventRole: any;
  communicationEventContent: any;
  communicationEventChilds: any;
  customerRequestList: any;
  commEvntPartyId: string;
  createCommEvent: FormGroup;
  datetimeStarted: string;
  datetimeEnded: string;
  contactMechTypeArray: any[]=[];
  reasonenumArray: any[]=[];
  parentCommEventArray: any[]=[];
  stausIdArray: any[]=[];
  comtypeIdArray: any[]=[];
  contentMMArray: any[]=[];
  contactMech: any;
  contactMechArray: any[]=[];
  contactMechTo: any;
  contactMechToArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  roleTypeId: any;
  roleTypeIdArray: any[]=[];
  contactListId: any;
  contactListIdArray: any[]=[];
  userPermissions: any;
  Hidebtn: boolean=false;

  constructor(readonly accountsService: AccountsService,
    readonly router: Router,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly toastr:ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,
    readonly addSkillService: AddSkillService,

    ) { 
      this.createCommEvent = this._FormBuilder.group({
        commEventId: [''],
        parCommEventID: [''],
        commTypeId: [''],
        status:[''],
        partyFrom:[''],
        partyTo: [''],
        conMechId: [''],
        fromContactMechId: [''],
        toContactMechId:[''],
        roleTypeFrom:[''],
        roleTypeTo: [''],
        contactListId: [''],
        startdate: [''],
        finishDate:[''],
        subject:[''],
        reasonEnumId: [''],
        contentMimeTypeId: [''],
        content: [''],
        note:[''],
   
      });
    this.relatedContact=[{
      'product':'main'
    }]
  }
  ngAfterViewInit(): void { this.spinner.show(); } 
  ngOnInit(): void {
    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PARTYMGR_VIEW=="PARTYMGR_VIEW"){
      this.Hidebtn=true;
    }
    const partyId: string = localStorage.getItem('partyId');
    this.commEvntPartyId=partyId
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.commEventId = params['commEventId'];
     
    });
    this.getDropdownList();
    this.getPartyId();
    this.getRoleId();
    this.getContactList();
    this.getCrmOverViewDetail();
  }

  deleteCommunicationEventRoles(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "communicationEventId":product.communicationEventId,
          "contactMechId":product.contactMechId,
          "partyId":product.partyId,
          "roleTypeId":product.roleTypeId ,
          "statusId": product.statusId,
          
        }
        this.accountsService.deleteCommunicationEventRoles(req).subscribe((res: any) => {
          if (res.success) {
            this.getCrmOverViewDetail();
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
    communicationEventPatch() {

      const fromActualdate=this.overviewDetail.datetimeStarted;
      this.datetimeStarted=this.datePipe.transform(fromActualdate,"yyyy-MM-dd");
      const throughActualdate=this.overviewDetail.datetimeEnded;
      this.datetimeEnded=this.datePipe.transform(throughActualdate,"yyyy-MM-dd");
      this.spinner.show();
        const formValue = this.createCommEvent;
        formValue.patchValue({
          commEventId: this.overviewDetail.communicationEventId,
          parCommEventID: this.overviewDetail.parentCommEventId,
          commTypeId: this.overviewDetail.communicationEventTypeId,
          status: this.overviewDetail.statusId,
          partyFrom: this.overviewDetail.partyIdFrom,
          partyTo: this.overviewDetail.partyIdTo,
          conMechId: this.overviewDetail.contactMechTypeId,
          fromContactMechId: this.overviewDetail.contactMechIdFrom,
          toContactMechId: this.overviewDetail.contactMechIdTo,
          roleTypeFrom: this.overviewDetail.roleTypeIdFrom,
          roleTypeTo: this.overviewDetail.roleTypeIdTo,
          contactListId: this.overviewDetail.contactListId,
          startdate: this.overviewDetail.datetimeStarted,
          finishDate: this.overviewDetail.datetimeEnded,
          subject: this.overviewDetail.subject,
          reasonEnumId: this.overviewDetail.reasonEnumId,
          contentMimeTypeId: this.overviewDetail.contentMimeTypeId,
          content: this.overviewDetail.content,
          note: this.overviewDetail.note,
        
        })
  
      this.spinner.hide();
    }
  contactEventMech(id) {
    this.spinner.show();
    this.accountsService.contactEventMech(id).subscribe(res => {
      this.contactMech = res.data;
      this.spinner.hide();
      for (const value of this.contactMech) {
        this.contactMechArray.push({
          label: value.contactMechId,
          value: value.contactMechId
        })
      }
    })
   
  }
  contactEventMechTo(id) {
    this.spinner.show();
    this.accountsService.contactEventMech(id).subscribe(res => {
      this.contactMechTo = res.data;
      this.spinner.hide();
      for (const value of this.contactMechTo) {
        this.contactMechToArray.push({
          label: value.contactMechId,
          value: value.contactMechId
        })
      }
    })
  
  }
  getContactList() {
    this.spinner.show();
    this.accountsService.getContactList().subscribe(res => {
      this.contactListId = res.data;
      this.spinner.hide();
      for (const value of this.contactListId) {
        this.contactListIdArray.push({
          label: value.description,
          value: value.contactListId
        })
      }
    })
  
  }
  getRoleId() {
    this.spinner.show();
    this.accountsService.getParty().subscribe(res => {
      this.roleTypeId = res.data.roleType;
      this.spinner.hide();
      for (const value of this.roleTypeId) {
        this.roleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  getDropdownList() {
    this.spinner.show();
   
    this.accountsService.getCrmInitialCommData().subscribe((res: any) => {
    const stausId = res.data.commEventstatus;
    const comtypeId=res.data.commEventTypes;
    const contentMM=res.data.mimeType;
    const parentCommEvent=res.data.parentCommEvent;
    const reasonenum=res.data.reasonEnum;
    const contactMechType=res.data.contactMechType;
    this.spinner.hide();
    for (const value of contactMechType) {
      this.contactMechTypeArray.push({
        label: value.description,
        value: value.contactMechTypeId
      })
    } 
    for (const value of reasonenum) {
      this.reasonenumArray.push({
        label: value.description,
        value: value.enumId
      })
    } 
    for (const value of parentCommEvent) {
      this.parentCommEventArray.push({
        label: value.communicationEventId,
        value: value.communicationEventId
      })
    } 
      for (const value of stausId) {
        this.stausIdArray.push({
          label: value.description,
          value: value.statusId
        })
      } 
      for (const value of comtypeId) {
        this.comtypeIdArray.push({
          label: value.description,
          value: value.communicationEventTypeId
        })
      } 
      for (const value of contentMM) {
        this.contentMMArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      } 
    })
     

    
   
  }
  onSubmit() {
    this.spinner.show();
    const fromActualdate=this.createCommEvent.get('startdate').value;
    this.datetimeStarted=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createCommEvent.get('finishDate').value;
    this.datetimeEnded=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "communicationEventId": '',
      "communicationEventTypeId": this.createCommEvent.value.commTypeId,
      "contactListId": this.createCommEvent.value.contactListId,
      "contactMechIdFrom": this.createCommEvent.value.fromContactMechId,
      "contactMechIdTo": this.createCommEvent.value.toContactMechId,
      "contactMechTypeId": this.createCommEvent.value.conMechId,
      "content": this.createCommEvent.value.content,
      "contentMimeTypeId": this.createCommEvent.value.contentMimeTypeId,
      "datetimeEnded": this.datetimeEnded,
      "datetimeStarted": this.datetimeStarted,
      "messageId": '',
      "note": this.createCommEvent.value.note,
      "parentCommEventId": this.createCommEvent.value.parCommEventID,
      "partyIdFrom": this.createCommEvent.value.partyFrom,
      "partyIdTo": this.createCommEvent.value.partyTo,
      "reasonEnumId": this.createCommEvent.value.reasonEnumId,
      "roleTypeIdFrom": this.createCommEvent.value.roleTypeFrom,
      "roleTypeIdTo":this.createCommEvent.value.roleTypeTo,
      "statusId":this.createCommEvent.value.status,
      "subject": this.createCommEvent.value.subject,
    }

    this.accountsService.postCommunicationNew(requestData).subscribe((res: any) => {
   
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.createCommEvent.reset();
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getCrmOverViewDetail();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    
  }
  
  getCrmOverViewDetail() {

    this.spinner.show();
   
    this.accountsService.getCrmOverViewDetail(this.commEventId).subscribe((res: any) => {
     
      this.overviewDetail = res.data;
      this.spinner.hide();
      this.communicationEventRole=res.data.communicationEventRole;
      this.communicationEventContent=res.data.communicationEventContent;
      this.communicationEventChilds=res.data.communicationEventChilds;
      this.customerRequestList=res.data.customerRequestList;
     

    })
   
  }
  cancelSubmit(){
    this.router.navigate(['/crm/main-crm'])
  }
  deleteContent(id,cid,fromdate){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteCrmEventContentList(id,cid,fromdate).subscribe((res: any) => {
       
          this.getCrmOverViewDetail();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })}
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  newInternalNote(){
    this.router.navigate(['/crm/new-internal-note'], { queryParams: { commEventId:  this.commEventId} });
  }
  mainPage(){
    this.router.navigate(['/crm/main-crm']);
  }
  newMail(){
    this.router.navigate(['/crm/new-mail'], { queryParams: { commEventId:  this.commEventId} });
  }
  replyMail(){
    this.router.navigate(['/crm/mail-reply'], { queryParams: { commEventId:  this.commEventId} });
  }
  overviewPage(id){
    this.router.navigate(['/crm/crm-overview'], { queryParams: { commEventId: id} });
  }
  ngOnDestroy(): void {

    this.spinner.hide();
      } 
  
}
