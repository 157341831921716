<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <a><span class="color-black pl-1 titlePanels" (click)="back()">Upload Image </span></a>
               
                <button type="button" class="btn btn-danger buttonclass"  (click)="back()">Cancel</button> 
            </div>
            <div class="w3-card-4 classCard" >
            <div class=" bg-white color-grey">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Upload Image</a></li>
                     
                     
          
          
                    </ul>
                  </div>
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form [formGroup]="imageFile" class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Image</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input  (change)="fileProgress($event)" class="classFile" type="file" class="form-control small-input"  formControlName="image"  id="exampleFormControlTextarea1" placeholder="Enter Quote Name" rows="1">
                                            </div>
                                           
                                        </div>
                                    </div>
                                    
                                  
                                   
                                                              

                                </div>
                                <div class="main-submit-button" style="margin-right: 77%!important;">
                                    <button type="submit" (click)="onSubmit()" class="btn btn-secondary submit-btn">Upload</button>
                                   
                                </div>
                            </form>
                        </div>
                    </div>
                    
                </div>
          
            </div>
            </div>
            
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 