<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span class="color-black pl-1 titlePanels">MRP Log List</span>
                <span class="">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>

            </div>

            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                        <li [ngClass]="activeCategoryMain==1?'active':''" data-toggle="modal"
                            data-target="#exampleModalCenter"><a>
                                Run MRP</a></li>
                        <li [ngClass]="activeCategoryMain==2?'active':''" (click)="MRPLog()"><a>
                                MRP Log</a></li>


                    </ul>
                </div>
                <div class=" bg-white color-grey p-3">
                    <div class="w3-card-4 classCard">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                <li [ngClass]="activeCategoryA==1?'active':''" (click)="changeactiveCategoryMainA(1)">
                                    <a>
                                        Last Job
                                    </a></li>
                                <li [ngClass]="activeCategoryA==2?'active':''" (click)="changeactiveCategoryMainA(2)">
                                    <a>
                                        Scheduled or running Jobs
                                    </a></li>
                                <li [ngClass]="activeCategoryA==3?'active':''" (click)="changeactiveCategoryMainA(3)">
                                    <a>
                                        Find Inventory Event Planned
                                    </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" style="    padding: 15px 15px;">

                                        <div *ngIf="activeCategoryA==1">    
                                        <div class="w3-card-4  w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion">
                                                            <span class="headingAccords"> Last Job
                                                            </span>

                                                            <button type="button" class="accordion-button  collapsed"
                                                                data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                                style="margin-left: 86%!important; padding-top: 11px !important;
                                                            padding-bottom: 11px !important;">
                                                            </button>

                                                        </div>

                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse"
                                                        data-bs-parent="#myAccordion">
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select allUser"
                                                                style="display: block!important;">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="ProdMrp" [paginator]="true"
                                                                            [rows]="rows" scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>


                                                                                            Job ID
                                                                                            <p-sortIcon
                                                                                                field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;"> Job
                                                                                            Name
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;"> Run
                                                                                            Time
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Priority
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Status ID
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;"> Max
                                                                                            Retry
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Current Retry Count
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;"> Auth
                                                                                            User Login ID
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Temporal Expression
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Current Recurrence Count
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">Max
                                                                                            Recurrence Count
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Start Date Time
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Finish Date Time
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Cancel Date Time
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">Job
                                                                                            Result
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Recurrence Time Zone
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-activity>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>
                                                                                            {{activity.jobId}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{activity.jobName}} </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{activity.runTime|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}} </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{activity.priority}} </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{activity.statusId}} </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{activity.maxRetry}} </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{activity.currentRetryCount}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{activity.authUserLoginId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{activity.tempExprId}} </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{activity.currentRecurrenceCount}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{activity.maxRecurrenceCount}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{activity.startDateTime|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}} </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{activity.finishDateTime|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}} </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{activity.cancelDateTime|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}} </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{activity.jobResult}} </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{activity.recurrenceTimeZone}}
                                                                                    </td>


                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                        <!-- <div class="w3-card-4 classCard">
                                        <div class="header-tabs tabClass">
                                            <h4 class=" common-styling h4Margin">
                                                Last Job
                                            </h4>
                                        </div>

                                        <div class="panel-body">
                                            <div class="all-users-infomation font-13 font-medium own-user-select allUser" style="display: block!important;">
                                                <div class="form-group">
                                                    <div class="card own-account-table">
                                                        <p-table [value]="ProdMrp" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'180px'}">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                              
                                                                            </p-checkbox>


                                                                            Job ID
                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Job Name
                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Run Time
                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Priority
                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Status ID
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Max Retry
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Current Retry Count
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Auth User Login ID
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Temporal Expression
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;">Current Recurrence Count
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;">Max Recurrence Count
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Start Date Time
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Finish Date Time
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Cancel Date Time
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;">Job Result
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Recurrence Time Zone
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>

                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-activity>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                              
                                                                            </p-checkbox>
                                                                            {{activity.jobId}}
                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{activity.jobName}} </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{activity.runTime|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{activity.priority}} </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{activity.statusId}} </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{activity.maxRetry}} </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{activity.currentRetryCount}} </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{activity.authUserLoginId}} </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{activity.tempExprId}} </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{activity.currentRecurrenceCount}} </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{activity.maxRecurrenceCount}} </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{activity.startDateTime|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{activity.finishDateTime|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{activity.cancelDateTime|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{activity.jobResult}} </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{activity.recurrenceTimeZone}} </td>


                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                              <h4 class="ml-2">No Record Found
                                                              </h4>
                                                          </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                        <p class="paginate_data">
                                                            View per page

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                      

                                        <div *ngIf="activeCategoryA==2">    

                                        <div class="w3-card-4  w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion">
                                                            <span class="headingAccords">
                                                                Scheduled or running Jobs
                                                            </span>

                                                            <button type="button" class="accordion-button  collapsed"
                                                                data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                                style="margin-left: 76%!important; padding-top: 11px !important;
                                                            padding-bottom: 11px !important;">
                                                            </button>

                                                        </div>

                                                    </h2>
                                                    <div id="collapseTwo" class="accordion-collapse collapse"
                                                        data-bs-parent="#myAccordion">
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select allUser"
                                                                style="display: block!important;">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="financialList"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">

                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                            Job ID
                                                                                            <p-sortIcon
                                                                                                field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;"> Job
                                                                                            Name
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;"> Run
                                                                                            Time
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Priority
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Status ID
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;"> Max
                                                                                            Retry
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Current Retry Count
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;"> Auth
                                                                                            User Login ID
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Temporal Expression
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Current Recurrence Count
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">Max
                                                                                            Recurrence Count
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">Job
                                                                                            Result
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Recurrence Time Zone
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-activity>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>



                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                        <!-- <div class="w3-card-4 classCard">
                                        <div class="header-tabs tabClass">
                                            <h4 class=" common-styling h4Margin">
                                                Scheduled or running Jobs
                                            </h4>
                                        </div>

                                        <div class="panel-body">
                                            <div class="all-users-infomation font-13 font-medium own-user-select allUser" style="display: block!important;">
                                                <div class="form-group">
                                                    <div class="card own-account-table">
                                                        <p-table [value]="financialList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'180px'}">
                                                                        <div class="checkbox-align" style="color: white;">

                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                               
                                                                            </p-checkbox>

                                                                            Job ID
                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Job Name
                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Run Time
                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Priority
                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Status ID
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Max Retry
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Current Retry Count
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Auth User Login ID
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Temporal Expression
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;">Current Recurrence Count
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;">Max Recurrence Count
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>

                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;">Job Result
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Recurrence Time Zone
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>

                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-activity>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                               
                                                                            </p-checkbox>

                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> </td>



                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                          <h4 class="ml-2">No Record Found
                                                          </h4>
                                                      </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                        <p class="paginate_data">
                                                            View per page

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                       
                                        <div *ngIf="activeCategoryA==3">    

                                        <div class="w3-card-4  w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion">
                                                            <span class="headingAccords">
                                                                Find Inventory Event Planned
                                                            </span>

                                                            <button type="button" class="accordion-button  collapsed"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#collapseThree" style="margin-left: 76%!important; padding-top: 11px !important;
                                                            padding-bottom: 11px !important;">
                                                            </button>

                                                        </div>

                                                    </h2>
                                                    <div id="collapseThree" class="accordion-collapse collapse"
                                                        data-bs-parent="#myAccordion">
                                                        <div class="panel-body">
                                                            <div class="row">
                                                                <form class="w-100" [formGroup]="createRunMrp">
                                                                    <div
                                                                        class="all-users-infomation font-13 font-medium own-user-select allUser">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div
                                                                                    class="col-lg-3 form-group formRight">
                                                                                    <label for="exampleInputEmail1">
                                                                                        Product ID</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown
                                                                                        placeholder="Enter Product ID"
                                                                                        filter="true"
                                                                                        formControlName="ProductID"
                                                                                        [options]="productListArray"
                                                                                        optionlabel="label">

                                                                                    </p-dropdown>

                                                                                </div>
                                                                                <div
                                                                                    class="col-lg-3 form-group formRightA">
                                                                                    <label for="exampleInputEmail1">
                                                                                        From Date</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="date"
                                                                                        class="form-control"
                                                                                        id="exampleInputEmail1"
                                                                                        aria-describedby="emailHelp"
                                                                                        placeholder="Enter Default Years Offset"
                                                                                        formControlName="FromDate">
                                                                                </div>
                                                                            </div>
                                                                        </div>




                                                                    </div>


                                                                </form>
                                                                <div class="main-submit-button"
                                                                    style="margin-left: -33%;">

                                                                    <button type="submit" (click)="mrpFind()"
                                                                        class="btn btn-secondary submit-btn">Find</button>
                                                                    &nbsp; <button type="submit" (click)="reset()"
                                                                        class="btn btn-danger ml-2">Reset</button>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select allUser">
                                                                <div class="form-group" style="width: 100%!important;">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="mrpsearchList"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'230px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>


                                                                                            Type
                                                                                            <p-sortIcon
                                                                                                field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;"> Date
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Quantity
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Total Quantity
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>



                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-activity>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'230px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>
                                                                                            <span style="text-decoration: auto!important;
                                                            color: #383139 !important;"> {{activity.productId}}{{activity.producName}}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        <span> Facility:
                                                                                            {{activity.facilityId}}</span><br>
                                                                                        <span> Minimum Stock:
                                                                                        </span><br>
                                                                                        <span> Reorder Quantity:
                                                                                        </span><br>
                                                                                        <span> Days To Ship:
                                                                                            {{activity.daysToShip}}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>




                                                                                </tr>
                                                                                <tr
                                                                                    *ngFor="let data of activity.inventoryList">
                                                                                    <td [ngStyle]="{'width':'230px'}"
                                                                                        style="text-decoration: auto!important;;
                                                        color: #383139 !important;">
                                                                                        <div class="checkbox-align">

                                                                                            {{data.mrpEventTypeId}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{data.eventName}} </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{data.eventDate|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}} </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{data.quantity}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                        <!-- <div class="w3-card-4 classCard">
                                        <div class="header-tabs tabClass">
                                            <h4 class=" common-styling h4Margin">
                                                Find Inventory Event Planned
                                            </h4>
                                        </div>

                                        <div class="panel-body">
                                            <div class="row">
                                                <form class="w-100" [formGroup]="createRunMrp">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select allUser">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group formRight">
                                                                    <label for="exampleInputEmail1">
                                                    Product ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown placeholder="Enter Product ID" filter="true" formControlName="ProductID" [options]="productListArray" optionlabel="label">

                                                                    </p-dropdown>

                                                                </div>
                                                                <div class="col-lg-3 form-group formRightA">
                                                                    <label for="exampleInputEmail1">
                                                    From Date</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Default Years Offset" formControlName="FromDate">
                                                                </div>
                                                            </div>
                                                        </div>




                                                    </div>


                                                </form>
                                                <div class="main-submit-button" style="margin-left: -33%;">

                                                    <button type="submit" (click)="mrpFind()" class="btn btn-secondary submit-btn">Find</button> &nbsp; <button type="submit" (click)="reset()" class="btn btn-danger ml-2">Reset</button>
                                                </div>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select allUser">
                                                <div class="form-group" style="width: 100%!important;">
                                                    <div class="card own-account-table">
                                                        <p-table [value]="mrpsearchList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'230px'}">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                               
                                                                            </p-checkbox>


                                                                            Type
                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;">
                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Description
                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Date
                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Quantity
                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;"> Total Quantity
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>



                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-activity>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'230px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                               
                                                                            </p-checkbox>
                                                                            <span style="text-decoration: auto!important;
                                                color: #383139 !important;">  {{activity.productId}}{{activity.producName}}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span> Facility: {{activity.facilityId}}</span><br>
                                                                        <span>  Minimum Stock: </span><br>
                                                                        <span>  Reorder Quantity: </span><br>
                                                                        <span>  Days To Ship: {{activity.daysToShip}} </span> </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> </td>
                                                                    <td [ngStyle]="{'width':'180px'}"></td>
                                                                    <td [ngStyle]="{'width':'180px'}"> </td>




                                                                </tr>
                                                                <tr *ngFor="let data of activity.inventoryList">
                                                                    <td [ngStyle]="{'width':'230px'}" style="text-decoration: auto!important;;
                                            color: #383139 !important;">
                                                                        <div class="checkbox-align">

                                                                            {{data.mrpEventTypeId}}
                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{data.eventName}} </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{data.eventDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{data.quantity}}</td>
                                                                    <td [ngStyle]="{'width':'180px'}"> </td>




                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                      <h4 class="ml-2">No Record Found
                                                      </h4>
                                                  </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                        <p class="paginate_data">
                                                            View per page

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Run MRP</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">


                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        Add Run MRP

                                    </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createRunMrpForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                            Mrp Name</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Mrp Name"
                                                            formControlName="MrpName">

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Facility Group<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Facility Group" filter="true"
                                                            formControlName="FacilityGroup"
                                                            [options]="getFacilityGroupArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                            Facility<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Facility" filter="true"
                                                            formControlName="Facility" [options]="MrpFacilitiesArray"
                                                            optionlabel="label">

                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Default Years Offset</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Default Years Offset"
                                                            formControlName="DefaultYearsOffset">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -16%;">

                                        <button type="submit" (click)="onSubmit()"
                                            class="btn btn-secondary submit-btn">Save</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>