<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Assign GL Account</span>
                <span class="color-black pl-1" > >Assign New GL Account </span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                        Assign New GL Account</a></li>
                       
                    </ul>
                  </div>
              
              
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="assignGlAccount">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
        
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                    Gl Account ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Gl Account ID" filter="true"
                                            formControlName="glAccountId" 
                                            [options]="assignGLPartyIdArray" optionlabel="label">
                                                            
                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Company ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Company ID" filter="true"
                                            formControlName="organizationPartyId" 
                                            [options]="partyIdArray" optionlabel="label" >
                                                            
                                            </p-dropdown>
                                           
                                            </div>
        
                                           
        
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
        
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">From Date</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Account Name"
                                                formControlName="fromDate" >
                                            </div>
                                           
        
                                           
        
                                        </div>
                                    </div>
                                 
                                  
                                 
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 61%;">
                              
                                <button type="submit"    (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>
                                
                             
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
