<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">My Tasks
                </span>
                <span class="">

                    <button type="submit" (click)="homeButton();"
                     class=" btn btn-outline-secondary mr-2">Home</button>
         
                   <button type="submit" (click)="cancelSubmit();" 
                     class="btn btn-danger buttonclass ">Back</button>
                </span>
            </div>

            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="projectMain()">
                                <a>Main</a>
                            </li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="myTasks()">
                                <a>My Tasks</a>
                            </li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="myTime()">
                                <a>My Time</a>
                            </li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="projects()">
                                <a>Projects</a>
                            </li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="tasks()">
                                <a>Tasks</a>
                            </li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="resources()">
                                <a>Resources</a>
                            </li>
                            <li [ngClass]="activeCategory==7?'active':''" (click)="timesheet()">
                                <a>Timesheet</a>
                            </li>
                            <li [ngClass]="activeCategory==8?'active':''" (click)="skillType()">
                                <a>Skill Type</a>
                            </li>
                            <li [ngClass]="activeCategory==9?'active':''" (click)="requestList()">
                                <a>Request List</a>
                            </li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div class="w3-card-4 classCard w-100">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Open Tasks Assigned to me</p>
                                                <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#newTask">Add a new task to one of
                                                    my projects</button>
                                                <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#existingTask"> Assign an existing
                                                    Task to me</button>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table borderTable">
                                                            <p-table [value]="TaskNameList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>

                                                                                Task Name
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Phase Name
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Project Name</div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Priority</div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Estimated Start Date
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Estimated Completion Date
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Actual Start Date
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Actual Completion Date
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Planned Hours</div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Actual Hours</div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Status</div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Party ID</div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Role</div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'100px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Update</div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'100px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Remove
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'100px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Status to 'complete'
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span  class="account-button" (click)="detailMyTasks(product.workEffortId)">
                                                                                    {{product.taskName}}</span>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.phaseName}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.projectName}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.priority}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.estimatedStartDate| date:'yyyy-MM-d hh:mm:ss' }}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.estimatedCompletionDate| date:'yyyy-MM-d hh:mm:ss' }}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.actualStartDate| date:'yyyy-MM-d hh:mm:ss' }}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.actualCompletionDate| date:'yyyy-MM-d hh:mm:ss' }}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">

                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">

                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.currentStatusId }}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.partyId }}
                                                                        </td>

                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.roleTypeId }}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'100px'}">
                                                                            <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn" (click)="edit(product)" data-toggle="modal" data-target="#updatePopUp">
                                                                                Update</button>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'100px'}">
                                                                            <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn " (click)="deleteTask(product)">
                                                                                Delete</button>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'100px'}">
                                                                            <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn " (click)="statusToComplete(product)">
                                                                                Status To Complete</button>
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No
                                                                                    Record Found
                                                                                </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="newTask" tabindex="-1" role="dialog" aria-labelledby="newTask" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add a New Task to One of My Projects </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add a New Task to One of My Projects </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="newTaskProject">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Task Name<span
                                                                style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="taskName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Task Name">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputNew">
                                                        <label for="exampleInputEmail1">Parent Phase</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="parentPhase" [options]="ParentPhaseArray" optionlabel="label" placeholder="Enter Parent Phase">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Sequence Num</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="sequenceNum" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputNew">
                                                        <label for="exampleInputEmail1">My Role for This Week</label>
                                                    </div>

                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="roleWeek" [options]="RoleResourceListArray" optionlabel="label" placeholder="Enter Role for This Week">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Hours</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="estimatedHours" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Hours">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputNew">
                                                        <label for="exampleInputEmail1">Skill Type ID</label>
                                                    </div>

                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="skillTypeID" [options]="SkillTypeIdArray" optionlabel="label" placeholder="Enter Skill Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Priority</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="priority" [options]="PriorityListArray" optionlabel="label" placeholder="Enter Priority">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputNew">
                                                        <label for="exampleInputEmail1">Type</label>
                                                    </div>

                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="type" [options]="TypeListArray" optionlabel="label" placeholder="Enter Type">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputNew">
                                                        <label for="exampleInputEmail1">Scope</label>
                                                    </div>

                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="scope" [options]="ScopeArray" optionlabel="label" placeholder="Enter Scope">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Start Date</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control" formControlName="startDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Start Date">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputNew">
                                                        <label for="exampleInputEmail1">Estimated Completion
                                                            Date</label>
                                                    </div>

                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control" formControlName="endDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Completion Date">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="col-lg-6 col-6 main-submit-button" style="margin-left: 37%;">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="createNewTask()">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="existingTask" tabindex="-1" role="dialog" aria-labelledby="existingTask" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add an Existing Task to One of My Projects </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add an Existing Task to One of My Projects </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="existTaskProject">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Task Name</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group " >
                                                        <p-dropdown filter="true" formControlName="taskName" [options]="TasksNamesListArray" optionlabel="label" placeholder="Enter Task Name">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm1">
                                                        <label for="exampleInputEmail1">Role Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="roleTypeID" [options]="RoleResourceListArray" optionlabel="label" placeholder="Enter Role Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            

                                            <div class="col-lg-6 col-6 main-submit-button" style="margin-left: 37%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="addAssignAndExistingTask()">Add</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="updatePopUp" tabindex="-1" role="dialog" aria-labelledby="updatePopUp" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Edit My Task  </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Edit My Task  </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Party Id</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="partyId" [options]="PartyIdArray" optionlabel="label" placeholder="Enter Party Id">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Role </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="roleTypeID" [options]="RoleResourceListArray" optionlabel="label" placeholder="Enter Role Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-6 main-submit-button" style="margin-left: -12%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="updateMyTask(updateProduct)">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>