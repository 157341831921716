import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DetaledLeadService } from '../detaled-lead/detaled-lead.service';
import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { MyContactsService } from '../contacts/my-contacts/my-contacts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sales-notes',
  templateUrl: './sales-notes.component.html',
  styleUrls: ['./sales-notes.component.css']
})
export class SalesNotesComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  Editform: FormGroup; total = 0;
  rowNumber = 0;
  pageSize = 100;
  rows = 50;
  partyContentTypeId: any;
  partyContentData: any;
  partyContentArray: any[]=[];
  fileData: File;
  partyId: any;
  createNotesForm: FormGroup;
  activeCategory=1;
  activeCategories=12;
  noteList: any;
  productNote: any;
  personId: any;
  partyIdArray: any[]=[];
  show: boolean;

  constructor(
    readonly router: Router,
    readonly activatedRoute: ActivatedRoute,
    readonly detaledLeadService: DetaledLeadService,
    readonly addSkillService: AddSkillService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    private _location: Location,

  ) { 
  
    this.createNotesForm=this._FormBuilder.group({
      noteInfo:"",
      partyId:""
    })
  }

  ngOnInit(): void {
  
  this.getNotesAll();
  this.getPartyId();
  }

  deleteNotes(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "noteId": product.noteId,
          "partyId":  product.partyId,
        }
       
        this.myContactsService.deleteNotes(req).subscribe(res => {
          this.toastr.success("Delete Successfully");
          this.getNotesAll();

        }, (err) => {
          this.toastr.error(err.message);
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }


  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  editNotes(product){
    this.show = true;
    this.productNote = product;

    setTimeout(() => {
      const form = this.createNotesForm;
      form.patchValue({
        noteInfo: product.note,
        partyId: product.partyId,
      })
    }, 2000);
  
  }
  getNotesAll(){
    this.spinner.show();
    this.myContactsService.getNotesAll().subscribe(res=>{
      this.noteList = res.data[0].getNotesAll;
      this.spinner.hide();
    
    })
  }
  UpdateNote(){
    this.spinner.show();
   
    const requestData = {
      "note": this.createNotesForm.value.noteInfo,
      "noteId": this.productNote.noteId,
      "partyId": this.createNotesForm.value.partyId,
 
    }
    this.myContactsService.updateNotes(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.createNotesForm.reset();
        this.getNotesAll();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createNote(){
    this.spinner.show();
   
    const requestData = {
      "note": this.createNotesForm.value.noteInfo,
      "noteId": "",
      "partyId": this.createNotesForm.value.partyId,
    }
    this.myContactsService.createNotes(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.createNotesForm.reset();
        this.getNotesAll();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  changeactiveCategoryAction(tab: number) {
    this.activeCategories = tab;
  }
  toTasks(){
    this.router.navigate(['/crm/Tasks']);
  }
  toDocuments(){
    
    this.router.navigate(['/crm/sales-documents']);

  }
  toLead()
  {
    this.router.navigate(['/crm/leads']);

  }
  toAccount()
  {
    this.router.navigate(['/crm/accounts']);
  }
  toContact()
  {
    this.router.navigate(['/crm/my-contacts']);

  }
  toOpportunity()
  {
    this.router.navigate(['/crm/opportunities']);

  }
  toCalendar(){
    
    this.router.navigate(['/crm/sales-calendar']);

  }
  toForecast(){
    
    this.router.navigate(['/crm/sales-forecast']);

  }
  toEmails(){
    
    this.router.navigate(['/communication-overview/Dashboard']);

  }  
  toNotes(){
    this.router.navigate(['/crm/sales-notes']);
  }
  toCases(){
    this.router.navigate(['/crm/Cases']);
  }
  toActivities()
  {
    this.router.navigate(['/crm/events']);

  }
  toQuotes()
  {
    this.router.navigate(['/order/quotations']);

  }
  toSalesOrder()
  {
    this.router.navigate(['/order/create-sales-order']);

  }
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/crm/main-overview']);
  }
  onpartyContent(event) {
    this.partyContentTypeId = event;
  }
  getPartyContent() {
    this.spinner.show();
    this.detaledLeadService.getParty().subscribe(res => {
      this.partyContentData = res.data.contentPurposeType;
      this.spinner.hide();
      for (const value of this.partyContentData) {
        this.partyContentArray.push({
          label: value.description,
          value: value.partyContentTypeId
        })
      }
    })
    
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
 

 
}
