import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';
import Swal from 'sweetalert2'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-security',
  templateUrl: './detail-security.component.html',
  styleUrls: ['./detail-security.component.css']
})
export class DetailSecurityComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  groupId: any;
  rowNumber=0;
  pageSize= 500;
  pageNo = 1;
  rows = 50;
  search= ""
   finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  SecurityGroupProtectedViews: any;
  activeCategory=1;
  SecurityGroupUserLogins: any;
  getAllPermisionData: any;
  description: any;

  total=0;
  activeCategorymain = 2;
  public user = {
    pageNo  :this.pageNo,
    pageSize:this.pageSize,
    search  :this.search
  }
  partyId: any = [];
  public cols: any[];
  getContacts: any = [];
  createGroup: FormGroup;
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: ""
  }
  show: boolean;
  userPermissions: any;
  Hidebtn: boolean=false;
  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly router: Router,
    public addSkillService:AddSkillService, 
    readonly datePipe: DatePipe,
    readonly activatedRoute:ActivatedRoute,
    private _location: Location,
    ) {  this.createGroup = this._FormBuilder.group({
      description: [''],
      groupId: ['',[Validators.required]],
      groupName: [''],
      
      
    });
     
    }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    
 this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
 if(this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PARTYMGR_VIEW=="PARTYMGR_VIEW"){
      this.Hidebtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.groupId = params["groupId"];
      this.description=params["description"]
    });
    this.EditSecurityGroupProtectedViews();
    this.EditSecurityGroupUserLogins();
    this.getAllPermision();

    this.activatedRoute.queryParams.subscribe(params=> [
      this.groupId = params["groupId"],
      
    ])
    if(this.groupId) {
      this.show=true;
      this.getUserDetails()
    
     
    }
    else {
      this.show = false;
    }
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/hub/Dashboard']);
  }
  editSecurity(){
    this.router.navigate(["/security/create-new-security-group"],{queryParams:{groupId: this.groupId}})
  }
  getAllPermision(){
    this.spinner.show();
      this.accountsService.getSecurityGroupPermissionApi(this.finSize,this.groupId).subscribe((res:any)=> {
        this.getAllPermisionData = res.data;
        this.spinner.hide();
      
      })
     
     }
     deletePermision(fromdate,permsionid){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.accountsService.deleteSecurityGroupPermissionApi(fromdate,permsionid,this.groupId).subscribe((res:any)=> {
            this.getAllPermision();
        },(err) => {
          this.toastr.error(err.error.message);
       })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )} else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }}) }
    changeManaully(){
      this.router.navigate(['/users/add-permision-manaully'], { queryParams: { groupId:  this.groupId } });
     
    }
    changeList(){
      this.router.navigate(['/users/add-permision-list'], { queryParams: { groupId:  this.groupId } });
     
    }
  EditSecurityGroupUserLogins() {
    this.spinner.show();
    this.accountsService.EditSecurityGroupUserLogins(this.groupId).subscribe((res: any) => {
      this.SecurityGroupUserLogins = res.data;
      this.spinner.hide();
    })
  
  }
  EditSecurityGroupProtectedViews() {
    this.spinner.show();
    this.accountsService.EditSecurityGroupProtectedViews(this.groupId).subscribe((res: any) => {
      this.SecurityGroupProtectedViews = res.data;
      this.spinner.hide();
    })
  
  }
  
  expireUserLoginFromSecurityGroup(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req={
          "fromDate":product.fromDate,
          "groupId": this.groupId,
          "userLoginId":product.data.userLoginId,
        }
        this.accountsService.expireUserLoginFromSecurityGroup(req).subscribe((res: any) => {
          this.EditSecurityGroupUserLogins();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
      GoToQuickLink(){
        this.router.navigate(['/security/find-security-group']);
      } 
  removeProtectedViewFromSecurityGroup(gid,vid) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.removeProtectedViewFromSecurityGroup(gid,vid).subscribe((res: any) => {
          this.EditSecurityGroupProtectedViews();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
      editProtectedSecurity(eviewNameId,emaxHits,emaxHitsDuration,etarpitDuration) {
        this.router.navigate(["security/create-protected-view"],{ queryParams: { groupId : this.groupId,viewNameId:eviewNameId,maxHits:emaxHits,maxHitsDuration:emaxHitsDuration,tarpitDuration:etarpitDuration}})
    
      }
      createProtectedView() {
        this.router.navigate(["security/create-protected-view"],{ queryParams: { groupId : this.groupId}})
    
      }
      createPartyLogin() {
        this.router.navigate(["security/create-party-login"],{ queryParams: { groupId : this.groupId}})
    
      }
      updateUserLogin(product) {
        this.router.navigate(["security/create-party-login"],{ queryParams: { groupId : this.groupId,fromDate:product.fromDate,thruDate:product.thruDate,userLogin:product.data.userLoginId}})
    
      }
     
      changeactiveCategoryFunction(tab: number) {
        this.activeCategory = tab;
      }
      getUserDetails(){
        const req={
      "description": "",
      "descriptionSearchType": "Contains",
      "groupId": this.groupId,
      "groupIdSearchType": "Contains"
         
        }
        this.accountsService.getSecurityGroupList(this.user,req).subscribe((res:any)=> {
         setTimeout(() => {
          const formValue = this.createGroup;
        formValue.patchValue({
          description:res.data[0].description,
          groupId: res.data[0].groupId,
          groupName:res.data[0].groupName,
         
         
        })
        }, 1000); 
         
        })
       
       }
      submit(): void {
        this.spinner.show();
         const requestData ={
         
             "description": this.createGroup.value.description,
             "groupId": this.createGroup.value.groupId,
             "groupName":this.createGroup.value.groupName    
             
         }
         this.accountsService.saveSecurityGroup(requestData).subscribe((res: any) => {
          
           if (res.success) {
             this.toastr.success("Successfully Created");
             this.spinner.hide();
             this.router.navigate(['/security/find-security-group'],)
           }
         }, (err) => {
          this.spinner.hide();
           for (const value of err.error.errors) {
             this.toastr.error(value.fieldName + ' ' + value.fieldError);
           }
         });
        
       }
       update(): void {
        this.spinner.show();
          const requestData ={
          
              "description": this.createGroup.value.description,
              "groupId": this.createGroup.value.groupId,
              "groupName":this.createGroup.value.groupName    
              
          }
          this.accountsService.putSecurityGroup(requestData).subscribe((res: any) => {
           
            if (res.success) {
              this.toastr.success("Updated Successfully");
              this.spinner.hide();
         this.closebutton.nativeElement.click();

              this.router.navigate(['/security/find-security-group'],)
            }
          }, (err) => {
            this.spinner.hide();
            for (const value of err.error.errors) {
              this.toastr.error(value.fieldName + ' ' + value.fieldError);
            }
          });
         
        }
       cancel(){
         this.router.navigate(['/security/find-security-group'],)
       }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
