<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Allocation</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey w-100 mt-20">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100">

                            <div class="w3-card-4 mt-2 w-100">
                                <div class="accordion classCard" id="myAccordion">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                <span class="headingAccords"> Find Allocation Plan</span>
                                                

                                                <span>
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal"
                                                    data-target="#allocationPlanPopUp">New Allocation Plan
                                                </button>
                                                </span>
                                            
                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 65%!important; padding-top: 11px !important;
                                                        padding-bottom: 11px !important;">
                                                    </button>									

                                            </div>
        
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div class="card-body">
                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Plan ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                                [(ngModel)]="advanceSearch.PlanID"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                id="exampleInputEmail1" name="CustRequestID"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Cust Request ID">
                                                                        </div>
                
                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Plan Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                                [(ngModel)]="advanceSearch.PlanName"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                id="exampleInputEmail1" name="CustRequestID"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Cust Request ID">
                                                                        </div>
                
                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Plan Type ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" optionlabel="label"
                                                                                [(ngModel)]="advanceSearch.PlanTypeID"
                                                                                [ngModelOptions]="{standalone:true}" name="PlanTypeID"
                                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                                [options]="planTypeId" placeholder="Choose Filter">
                                                                            </p-dropdown>
                                                                        </div>
                
                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Status ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" optionlabel="label"
                                                                                [(ngModel)]="advanceSearch.StatusID"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="purchaseorders" id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp" [options]="statusId"
                                                                                placeholder="Choose Filter (purchase orders)">
                                                                            </p-dropdown>
                                                                        </div>
                
                
                                                                    </div>
                                                                </div>
                
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Product ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" optionlabel="label"
                                                                                [(ngModel)]="advanceSearch.ProductID"
                                                                                [ngModelOptions]="{standalone:true}" name="PlanTypeID"
                                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                                [options]="ProductIdArray" placeholder="Choose Filter">
                                                                            </p-dropdown>
                                                                        </div>
                
                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Order ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" optionlabel="label"
                                                                                [(ngModel)]="advanceSearch.OrderID"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="purchaseorders" id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp" [options]="orderIds"
                                                                                placeholder="Choose Filter (purchase orders)">
                                                                            </p-dropdown>
                                                                        </div>
                
                
                                                                    </div>
                                                                </div>
                
                                                                <div class="col-lg-12 col-12 main-submit-button"
                                                                    style="margin-left: -30%;">
                                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                                        (click)="findAllocationPlan()">Find</button>
                                                                    <button type="submit" class="btn btn-danger ml-2"
                                                                        (click)="reset()">Reset</button>
                                                                </div>
                                                            </div>
                
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>

                            <!-- <div class="w3-card-4 classCardView mt-2 w-100">
                                <div
                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    Find Allocation Plan

                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal"
                                    data-target="#allocationPlanPopUp">New Allocation Plan
                                </button>

                                </div>
                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Plan ID</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="advanceSearch.PlanID"
                                                                [ngModelOptions]="{standalone:true}"
                                                                id="exampleInputEmail1" name="CustRequestID"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Cust Request ID">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                            <label for="exampleInputEmail1">Plan Name</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="advanceSearch.PlanName"
                                                                [ngModelOptions]="{standalone:true}"
                                                                id="exampleInputEmail1" name="CustRequestID"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Cust Request ID">
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Plan Type ID</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" optionlabel="label"
                                                                [(ngModel)]="advanceSearch.PlanTypeID"
                                                                [ngModelOptions]="{standalone:true}" name="PlanTypeID"
                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                [options]="planTypeId" placeholder="Choose Filter">
                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                            <label for="exampleInputEmail1">Status ID</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" optionlabel="label"
                                                                [(ngModel)]="advanceSearch.StatusID"
                                                                [ngModelOptions]="{standalone:true}"
                                                                name="purchaseorders" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" [options]="statusId"
                                                                placeholder="Choose Filter (purchase orders)">
                                                            </p-dropdown>
                                                        </div>


                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Product ID</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" optionlabel="label"
                                                                [(ngModel)]="advanceSearch.ProductID"
                                                                [ngModelOptions]="{standalone:true}" name="PlanTypeID"
                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                [options]="ProductIdArray" placeholder="Choose Filter">
                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                            <label for="exampleInputEmail1">Order ID</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" optionlabel="label"
                                                                [(ngModel)]="advanceSearch.OrderID"
                                                                [ngModelOptions]="{standalone:true}"
                                                                name="purchaseorders" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" [options]="orderIds"
                                                                placeholder="Choose Filter (purchase orders)">
                                                            </p-dropdown>
                                                        </div>


                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-12 main-submit-button"
                                                    style="margin-left: -30%;">
                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                        (click)="findAllocationPlan()">Find</button>
                                                    <button type="submit" class="btn btn-danger ml-2"
                                                        (click)="reset()">Reset</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            
                            <div class="w3-card-4 classCardView mt-2 w-100">
                                <div
                                    class="d-flex bd-highlight port-header-contact-import  create-lead font-medium mb-0 suppliers-wrapper">
                                    Search Results


                                </div>
                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select pl-0">
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group">

                                            <form class="w-100">
                                                <div
                                                    class="port-header-contact create-lead font-medium mb-0 w-100 pl-0">
                                                    <div class="w3-card-4 classCard w-100">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Order List</p>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="allocationPlanList"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>

                                                                                            Plan ID
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Status ID
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Plan Type ID </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Product ID </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Order ID
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Order Item Seq ID
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Plan Method Enum ID
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Allocated Quantity
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Action</div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                 

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            <span   class="account-button"  
                                                                                            (click)="detailPage(product.planId)">
                                                                                                {{product.planId}}</span>

                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}"
                                                                                       >
                                                                                        <span
                                                                                           >
                                                                                            {{product.statusId}}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.planTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.productId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.orderId}}

                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.orderItemSeqId}}

                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.planMethodEnumId}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.allocatedQuantity}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <button  type="submit" class="btn btn-danger "
                                                                                        (click)="deleteAllocationPlanItem(product)">Remove</button>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No
                                                                                                Record Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="allocationPlanPopUp" tabindex="-1" role="dialog" aria-labelledby="allocationPlanPopUpTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Allocation Plan
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>
                                    Create Allocation Plan
                                    </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="allocationForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">
                                                            Product ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label"
                                                         [options]="ProductIdArray"
                                                          placeholder="Choose  Product ID" formControlName="ProductID">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">Plan Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email" class="form-control" id="exampleInputEmail1" 
                                                        placeholder="Enter Plan Name"
                                                         aria-describedby="emailHelp" formControlName="PlanName" >

                                                    </div>
                                                   
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -27%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                 >Next</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 

<ngx-spinner></ngx-spinner>