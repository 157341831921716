import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-cms-summary',
  templateUrl: './cms-summary.component.html',
  styleUrls: ['./cms-summary.component.css']
})
export class CmsSummaryComponent implements OnInit {
  @ViewChild('closebutton2') closebutton2;
  activeCategory=1;
  cmsContentForm: FormGroup;
  customerCategoryPopup=1;
  contentId: any;
  caContentIdTo: any;
  caContentAssocTypeId: any;
  editAddContentData: any;
  ContentAssocTypeData: any;
  ContentAssocTypeDataArray:  any[]=[];
  DataPredicateArray: any[]=[];
  DataPredicate: any;
  resourceTypeDROP: any;
  resourceTypeDROPArray: any[]=[];
  contentTypeIdData: any;
  contentTypeIdDataArray: any[]=[];
  mimeTypeIdData: any;
  mimeTypeIdDataArray: any[]=[];
  CharacterSetIdArray: any[]=[];
  CharacterSetId: any;
  fileData: File;
  uploadURL: any;
  CatalogContentId: any;
  CatalogContentIdArray: any[]=[];
  dataTemplateType: any;
  dataTemplateTypeArray: any[]=[];
  DataResourceId: any;
  DataResourceIdArray: any[]=[];
  constructor(
    readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    private _location: Location,
  ) 
  {

    this.cmsContentForm=this._FormBuilder.group({
      contentIDTo:"",
      mapKey:"",
      contentAssocTypeID:"",
      contentAssocPredicateID:"",
      fromDate:"",
      throughDate:"",
      contentID:"",
      templateDataResourceID:"",
      contentTypeID:"",
      ownerContentID:"",
      contentName:"",
      description:"",
      mimeTypeID:"",
      characterSetID:"",
      localeString:"",
      statusID:"",
      privilegeEnumID:"",
      dataResourceID:"",
      drDataResourceTypeID:"",
      filePath:"",
      drDataTemplateTypeID:"",
      drMimeTypeID:"",
      characterSetID1:"",
      localeString1:"",
      dataSource:"",
      dataCategory:"",
      textData:"",
      imageData:"",
      createdByUser:"",
      createdDate:"",
      lastModifiedByUser:"",
      lastModifiedDate:"",
    })
   
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => [
      this.caContentIdTo = params["caContentIdTo"],
      this.contentId = params["contentId"],
      this.caContentAssocTypeId = params["caContentAssocTypeId"],

    ])
    this.EditAddContent();
    this.update();
    this.ContentAssocType();
    this.metaDataPredicate();
    this.getCharacterSet();
    this.getMimeTypeIdData();
    this.DataResourceTypeDROP();
    this.contentTypeIds();
    this.getDataTemplateType();
    this.getCatalogContentId();
    this.getDataResourceId();
    
  }
  generatePDFCMS() {
    this.spinner.show();
    const req ={
      
        "caContentAssocTypeId": this.caContentAssocTypeId,
        "caContentIdTo": this.caContentIdTo,
        "contentId":this.contentId
      
    }
    
    this.myContactsService.generatePDFCMS(req).subscribe(res => {
      const blob = new Blob([res], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "CMS.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  } 
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  getDataResourceId() {
    this.spinner.show();
    this.accountsService.getDataResourceId().subscribe(res => {
      this.DataResourceId = res.data.getDataResourceId;
      this.spinner.hide();
      for (const value of this.DataResourceId) {
        this.DataResourceIdArray.push({
          label: value.dataResourceId,
          value: value.dataResourceId
        })
      }
    })
  }
  getDataTemplateType() {
    this.spinner.show();
    this.myContactsService.getDataTemplateType().subscribe(res => {
      this.dataTemplateType = res.data.getDataTemplateType;
      this.spinner.hide();
      for (const value of this.dataTemplateType) {
        this.dataTemplateTypeArray.push({
          label: value.description,
          value: value.dataTemplateTypeId
        })
      }
    })
  }
 
  getCatalogContentId() {
    this.spinner.show();
    this.accountsService.getCatalogContentId().subscribe(res => {
      this.CatalogContentId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.CatalogContentId) {
        this.CatalogContentIdArray.push({
          label: value.contentName,
          value: value.contentId
        })
      }
    })
  }
  // onUpload() {
  //   this.spinner.show();
  //   const formData = new FormData();
  //   formData.append("file", this.fileData);

  //   this.accountsService.attachFile(formData).subscribe((res: any) => {
  //     if (res.success) {
  //       this.spinner.hide();
  //       this.uploadURL = res.data.URL;
  //       this.persistContentStuff();
  //       this.closebutton2.nativeElement.click();
  //       this.toastr.success("Success");
  //       this.cmsContentForm.reset();

  //     }
  //   }, (err) => {
  //     this.toastr.error(err.message);
  //     this.spinner.hide();
  //   })

  // }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];

  }
  onUpload(): void {
    this.spinner.show();
    const requestData = {

      "_rowCount": "1",
      "caContentAssocPredicateId": this.cmsContentForm.value.contentAssocPredicateID,
      "caContentAssocTypeId": this.cmsContentForm.value.contentAssocTypeID,
      "caContentIdTo": this.cmsContentForm.value.contentIDTo,
      "caFromDate": this.datePipe.transform(this.cmsContentForm.value.fromDate, "yyyy-MM-dd hh:mm:ss"),
      "caMapKey": this.cmsContentForm.value.mapKey,
      "caThruDate": this.datePipe.transform(this.cmsContentForm.value.throughDate, "yyyy-MM-dd hh:mm:ss"),

      "characterSetId": this.cmsContentForm.value.characterSetID,
      "contentAssocTitle": "",
      "contentId": this.cmsContentForm.value.contentID,
      "contentName": this.cmsContentForm.value.contentName,
      "contentPurposeString": "ARTICLE",
      "contentTitle": "",
      "contentTypeId": this.cmsContentForm.value.contentTypeID,
      "createdByUserLogin": "admin",
      "createdDate": this.datePipe.transform(new Date(), "yyyy-MM-dd hh:mm:ss"),
      "dataResourceId": this.cmsContentForm.value.dataResourceID,
      "dataResourceTitle": "",
      "deactivateExisting": true,
      "description": this.cmsContentForm.value.description,

      "drCharacterSetId": this.cmsContentForm.value.characterSetID1,
      "drDataCategoryId": this.cmsContentForm.value.dataCategory,
      "drDataResourceTypeId": "",
      "drDataSourceId": this.cmsContentForm.value.dataSource,
      "drDataTemplateTypeId": this.cmsContentForm.value.drDataTemplateTypeID,
      "drLocaleString": this.cmsContentForm.value.localeString1,
      "drMimeTypeId": this.cmsContentForm.value.drMimeTypeID,
      "drObjectInfo": this.cmsContentForm.value.filePath,

      "imageData": "",
      "imageDataTitle": "",
      "lastModifiedByUserLogin": "admin",
      "lastModifiedDate": this.datePipe.transform(this.cmsContentForm.value.throughDate, "yyyy-MM-dd hh:mm:ss"),
      "localeString": this.cmsContentForm.value.localeString,
      "masterContentId": "",
      "master_caContentAssocTypeId": this.cmsContentForm.value.contentAssocTypeID,
      "master_caContentId": "",
      "master_caContentIdTo": this.cmsContentForm.value.contentIDTo,
      "master_caFromDate": this.datePipe.transform(this.cmsContentForm.value.fromDate, "yyyy-MM-dd hh:mm:ss"),
      "master_contentId": this.cmsContentForm.value.contentIDTo,
      "master_drDataResource": "",
      "mimeTypeId": this.cmsContentForm.value.mimeTypeID,
      "nodeTrailCsv": "",
      "ownerContentId": this.cmsContentForm.value.ownerContentID,
      "statusId": this.cmsContentForm.value.statusID,
      "targetOperationString": "CONTENT_UPDATE|CONTENT_CREATE|CONTENT_CREATE_SUB",
      "templateDataResourceId": this.cmsContentForm.value.templateDataResourceID,
      "textData": this.cmsContentForm.value.textData,
      "textDataTitle": "",
    }
    this.myContactsService.persistContentStuff(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Updated Successfully");
        this.closebutton2.nativeElement.click();
        this.cmsContentForm.reset();
       // this.getCMSContentFind();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getCharacterSet() {
    this.spinner.show();
    this.accountsService.getCharacterSet().subscribe(res => {
      this.CharacterSetId = res.data.getCharacterSet;
      this.spinner.hide();
      for (const value of this.CharacterSetId) {
        this.CharacterSetIdArray.push({
          label: value.description,
          value: value.characterSetId
        })
      }
    })
  }
  getMimeTypeIdData() {
    this.spinner.show();
    this.myContactsService.getMimeTypeIdData().subscribe(res => {
      this.mimeTypeIdData = res.data.mimeTypeId;
      this.spinner.hide();
      for (const value of this.mimeTypeIdData) {
        this.mimeTypeIdDataArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      }
    })
  }

  ContentAssocType() {
    this.spinner.show();
    this.myContactsService.ContentAssocType().subscribe(res => {
      this.ContentAssocTypeData = res.data.ContentAssocType;
      this.spinner.hide();
      for (const value of this.ContentAssocTypeData) {
        this.ContentAssocTypeDataArray.push({
          label: value.description,
          value: value.contentAssocTypeId
        })
      }
    })
  }
  DataResourceTypeDROP() {
    this.spinner.show();
    this.myContactsService.DataResourceTypeDROP().subscribe(res => {
      this.resourceTypeDROP = res.data.DataResourceType;
      this.spinner.hide();
      for (const value of this.resourceTypeDROP) {
        this.resourceTypeDROPArray.push({
          label: value.description,
          value: value.dataResourceTypeId
        })
      }
    })
  }
  contentTypeIds() {
    this.spinner.show();
    this.myContactsService.contentTypeIds().subscribe(res => {
      this.contentTypeIdData = res.data;
      this.spinner.hide();
      for (const value of this.contentTypeIdData) {
        this.contentTypeIdDataArray.push({
          label: value.description,
          value: value.contentTypeId
        })
      }
    })
  }
  metaDataPredicate() {
    this.spinner.show();
    this.myContactsService.metaDataPredicate().subscribe(res => {
      this.DataPredicate = res.data[0].metaDataPredicate;
      this.spinner.hide();
      for (const value of this.DataPredicate) {
        this.DataPredicateArray.push({
          label: value.description,
          value: value.metaDataPredicateId
        })
      }
    })
  }

  update() {
   
    setTimeout(() => {
      const form = this.cmsContentForm;
      form.patchValue({
        contentIDTo:  this.editAddContentData.caContentIdTo,
        mapKey: this.editAddContentData.caMapKey,
        contentAssocTypeID: this.editAddContentData.caContentAssocTypeId,
        contentAssocPredicateID: this.editAddContentData.caContentAssocPredicateId,
        fromDate: this.editAddContentData.caFromDate,
        throughDate: this.editAddContentData.caThruDate,
        contentID: this.editAddContentData.contentId,
        templateDataResourceID: this.editAddContentData.templateDataResourceId,
        contentTypeID: this.editAddContentData.contentTypeId,
        ownerContentID: this.editAddContentData.ownerContentId,
        contentName: this.editAddContentData.contentName,
        description: this.editAddContentData.description,
        mimeTypeID: this.editAddContentData.mimeTypeId,
        characterSetID: this.editAddContentData.characterSetId,
        localeString: this.editAddContentData.localeString,
        statusID: this.editAddContentData.statusId,
        privilegeEnumID: this.editAddContentData.privilegeEnumId,
        dataResourceID: this.editAddContentData.dataResourceId,
        drDataResourceTypeID: this.editAddContentData.drDataResourceTypeId,
        filePath: this.editAddContentData.drObjectInfo,
        drDataTemplateTypeID: this.editAddContentData.drDataTemplateTypeId,
        drMimeTypeID: this.editAddContentData.drMimeTypeId,
        characterSetID1: this.editAddContentData.drCharacterSetId,
        localeString1: this.editAddContentData.drLocaleString,
        dataSource: this.editAddContentData.drDataSourceId,
        dataCategory: this.editAddContentData.drDataCategoryId,
        textData: this.editAddContentData.textData,
        imageData: this.editAddContentData.imageData,
        createdByUser: this.editAddContentData.createdByUserLogin,
        createdDate: this.editAddContentData.createdDate,
        lastModifiedByUser: this.editAddContentData.lastModifiedByUserLogin,
        lastModifiedDate: this.editAddContentData.lastModifiedDate,
      })
    }, 2000);
  }
  EditAddContent() {
    this.spinner.show();
    const formData = {
      "caContentAssocTypeId": this.caContentAssocTypeId,
      "caContentIdTo": this.caContentIdTo,
      "contentId": this.contentId,
  }
    this.myContactsService.EditAddContent(formData).subscribe(res => {
      this.editAddContentData = res.data[0].CMSContentFind;
      this.spinner.hide();

    })
  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });


  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;

  }

  customerActiveCategoryFunction(tab: number)
    {
      this.customerCategoryPopup = tab;
    
    }

}
