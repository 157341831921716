
import { Injectable } from '@angular/core';
import { FormControl,Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CreateDepartmentModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        active:(data && data.active) ? data.active : null,
        partyId:(data && data.partyId) ? data.partyId : null,
        companyNameId:(data && data.companyNameId) ? data.companyNameId : null,
        departmentName:(data && data.departmentName) ? data.departmentName : null,
        parentDepartmentId:(data && data.parentDepartmentId) ? data.parentDepartmentId : null,
    });
    return this.getFormGroup(form_data);
  }
  getFormGroup(data:any): any {
   return ({
    active: new FormControl(data.active),
    companyNameId: new FormControl(data.companyNameId),
    departmentName: new FormControl(data.departmentName,[Validators.required]),
    parentDepartmentId: new FormControl(data.parentDepartmentId),
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
