import { Component, OnInit,Inject, ElementRef, ViewChild, HostBinding, HostListener, Input } from '@angular/core';
import { SortEvent } from 'primeng/api';
import{HeaderService} from '../../header/header.service';
import { Router } from '@angular/router';
import { AccountsService} from '../../crm/accounts/accounts.service';
import {Settings} from "../settings.service";
import {LeaveTypeService} from "./reason-type-service";
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-reason-type',
  templateUrl: './reason-type.component.html',
  styleUrls: ['./reason-type.component.css']
})
export class ReasonTypeComponent implements OnInit {
  products3: any;
  activeCategoryMain=2;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  reasonType: any;
  id: any;
  @Input() leaveCreate: boolean;
  
  @ViewChild('rmodal') rmodal: ElementRef;
  show: boolean = false;
  // @HostListener('document:click', ['$event'])
  //    handleClick(event: MouseEvent): void {
  //      if(this.show){
  //       this.updateFalse();
  //      }
         
  //    }
  constructor(readonly accountsService: AccountsService,
    readonly  HeaderService: HeaderService,
    readonly router: Router,
    readonly settings:Settings,
    public leaveTypeService:LeaveTypeService,
    readonly toastr:ToastrService,
    readonly spinner:NgxSpinnerService,
    @Inject(DOCUMENT) readonly document: Document

    ) {

     }
 
     ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.show=this.leaveCreate;
    this.leaveTypeService.reason_type__form=null;
    this.leaveTypeService.leaveTypeForm(this.leaveTypeService.reason_type__form);

    this.getLeaveReasonType();
    this.rmodal.nativeElement.modal({backdrop: 'static', keyboard: false});
   
  }
  getLeaveReasonType(){
    this.spinner.show();
    this.settings.getLeaveReasonType().subscribe(res=> {
      this.reasonType = res.data.leaveTypeId;
      this.spinner.hide();
    })
   this.cancelReason();
  }
  cancelReason(){
    this.show=false;
    this.leaveTypeService.reason_type__form.reset();
  }
  onSubmit(){
    this.spinner.show();
    const formData = {...this.leaveTypeService.reason_type__form.value};
    this.settings.createLeaveReason(formData).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
       this.getLeaveReasonType();
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
  
  }
 
  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
}

  onUpdate(emplLeaveReasonTypeId,description){
    
    this.id=emplLeaveReasonTypeId;
    const form = this.leaveTypeService.reason_type__form;
   form.patchValue({
    leaveTypeId:emplLeaveReasonTypeId,
    description:description
   })
    if(emplLeaveReasonTypeId){
      this.show=true;
    }
    else {
      this.show=false;
    }
  }
  updateReason(){
    this.spinner.show();
    const formData = {...this.leaveTypeService.reason_type__form.value};
    this.settings.updateLeaveReason(formData,this.id).subscribe((res:any)=> {
      if(res.success) {
        this.spinner.hide();
        this.toastr.success(res.data.successMessage);
        this.rmodal.nativeElement.click();
        this.getLeaveReasonType()
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
 
  }
  onDelete(id){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.settings.deleteReasonType(id).subscribe(res=> {
          if(res.success) {
            this.toastr.success(res.data.successMessage);
            this.rmodal.nativeElement.click();
           this.getLeaveReasonType()
          }
          else  {
            this.toastr.error("Error");  }    
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      } }) }
      updateFalse(){
        this.show = false;
        this.leaveTypeService.reason_type__form.reset();
      }


  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  detailLeaveTypePage(eleaveType,description) {
    this.router.navigate(["/hr/detail-reason-type"], { queryParams: { leaveType:eleaveType, des: description} })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
