<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Product: {{ this.productId }}</span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
        
            </div>

            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="w3-card-4 classCardA ">

                                            <div class="container-fluid justify-content-between">
                                                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                                    <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)"><a>
                                                        Product Backlog Item
                                                        </a></li>

                                                        <li>
                                                            <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Back</button>

                                                        </li>
                                                </ul>
                                            </div>
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                <div class="create-new-leade ">
                                                    <div class="container-fluid">


                                                        <div class="panel-group">
                                                            <div *ngIf="activeCategory==1">
                                                                <div class="container-fluid">
                                                                   
                                                                </div>
                                                                <div class="divA mt-2">
                                                                    <div class="w3-card-4 classCardView ">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Product Backlog Item Info
                                                                            </h4>
                                                                        </div>

                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="color-black container">
                                                                                    <div class="row">
                                                                                        <div class="article-container">
                                                                                            <div class="article">
                                                                                                <p class="col-lg-6 col-12">
                                                                                                    Product Name </p>
                                                                                                <span
                                                                                                    class="color-grey pl-20 col-lg-10 col-12 font-weight-bold"
                                                                                                    >{{this.SprintOverview.productName}}</span>
                                                                                            </div>
                                                                                            <div class="article">
                                                                                                <p class="col-lg-6 col-12">
                                                                                                    Company</p>
                                                                                                <span
                                                                                                    class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.Company}}
                                                                                                </span>
                                                                                            </div>
            
                                                                                            <div class="article">
                                                                                                <p class="col-lg-6 col-12">
                                                                                                    Product Owner</p>
                                                                                                <span
                                                                                                    class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.productOwner}}</span>
                                                                                            </div>
            
                                                                                            <div class="article">
                                                                                                <p class="col-lg-6 col-12">
                                                                                                    Request name </p>
                                                                                                <span
                                                                                                    class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.productOverviewDetails[0].parentCustRequestId}}</span>
                                                                                            </div>
                                                                                            <div class="article">
                                                                                                <p class="col-lg-6 col-12">
                                                                                                    Product Backlog Item </p>
                                                                                                <span
                                                                                                    class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.productOverviewDetails[0].description}}</span>
                                                                                            </div>
                                                                                            <div class="article">
                                                                                                <p class="col-lg-6 col-12">
                                                                                                    Plan Time </p>
                                                                                                <span
                                                                                                    class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.hours}}</span>
                                                                                            </div>
                                                                                            <div class="article">
                                                                                                <p class="col-lg-6 col-12">
                                                                                                    Actual Hours </p>
                                                                                                <span
                                                                                                    class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.ActualHours}}</span>
                                                                                            </div>
                                                                                            <div class="article">
                                                                                                <p class="col-lg-6 col-12">
                                                                                                    Cust Sequence Num </p>
                                                                                                <span
                                                                                                    class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.productOverviewDetails[0].custSequenceNum}}</span>
                                                                                            </div>
                                                                                            <div class="article">
                                                                                                <p class="col-lg-6 col-12">
                                                                                                    Billed </p>
                                                                                                <span
                                                                                                    class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.productOverviewDetails[0].billed}}</span>
                                                                                            </div>
                                                                                            <div class="article">
                                                                                                <p class="col-lg-6 col-12">
                                                                                                    Unplanned </p>
                                                                                                <span
                                                                                                    class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.Unplanned}}</span>
                                                                                            </div>
                                                                                            <div class="article">
                                                                                                <p class="col-lg-6 col-12">
                                                                                                    Status </p>
                                                                                                <span
                                                                                                    class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.productOverviewDetails[0].statusId}}</span>
                                                                                            </div>
                                                                                            <div class="article">
                                                                                                <p class="col-lg-6 col-12">
                                                                                                    Story</p>
                                                                                                <span
                                                                                                    class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.Story}}</span>
                                                                                            </div>
                                                                                            <div class="article">
                                                                                                <p class="col-lg-6 col-12">
                                                                                                    How to test </p>
                                                                                                <span
                                                                                                    class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.Howtotest}}</span>
                                                                                            </div>
            
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <br>
                                                                    <div class="w3-card-4 classCardView">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Backlogs Status Chart
                                                                            </h4>


                                                                        </div>
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table borderTable">
                                                                                        <div class="col-lg-12">
                                                                                            <div class="chartjs-container">
                                                                                                <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins" [colors]="pieChartColors" [legend]="pieChartLegend">
                                                                                                </canvas>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>

                                                                    <br>
                                                                    <div class="w3-card-4 classCardView">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Revisions
                                                                            </h4>
                                                                        </div>
                                                                        <div class="panel-body allStyleUser">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table borderTable">


                                                                                        <p-table [value]="" [rows]="rows"  scrollHeight="150px"  [paginator]="false">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769;">
                                                                                                    <th [ngStyle]="{'width':'120px'}" pSortableColumn="code">
                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
                                                                                                            Contact Type
                                                                                                            <p-sortIcon field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>

                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'120px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;">
                                                                                                            Contact Information
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>

                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'120px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;">
                                                                                                            Soliciting OK
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>

                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'110px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;">
                                                                                                            Action
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>

                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'120px'}">

                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'120px'}">
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'120px'}">
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'120px'}">
                                                                                                    </td>

                                                                                                </tr>


                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span class="text-center">
                                                                                                            <h4
                                                                                                                class="ml-2">
                                                                                                                No
                                                                                                                Record
                                                                                                                Found
                                                                                                            </h4>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>

                                                                                        <p class="paginate_data">

                                                                                        </p>


                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>

                                                                <div class="divB mt-2">
                                                                    <div class="w3-card-4 classCardView">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Product Backlog Item Status List
                                                                            </h4>
                                                                        </div>

                                                                        <div class="panel-body allStyleUser">
                                                                            <div class="card own-account-table borderTable">
                                                                                <p-table [value]="productBacklogItemStatusList"  scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>

                                                                                                    Status ID	
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Status Date	
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    Change By User Login ID</div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>




                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    {{product.list.statusId}}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.list.statusDate| date:'yyyy-MM-d hh:mm:ss' }}

                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.list.changeByUserLoginId}}
                                                                                            </td>


                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                                    <h4 class="ml-2">No
                                                                                                        Record Found
                                                                                                    </h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                                <p class="paginate_data">
                                                                                    View per page

                                                                                </p>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                    <div class="w3-card-4 classCardView mt-2">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Tasks

                                                                            </h4>

                                                                        </div>
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                                                <div class="card own-account-table borderTable">
                                                                                    <p-table [value]="sprintTaskList" [rows]="rows"  scrollHeight="150px"  [paginator]="false" scrollWidth="100%" [scrollable]="true" [customSort]="true">
                                                                                        <ng-template pTemplate="header">
                                                                                            <tr style="background: #0d3769;">
                                                                                                <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                                                    <div class="checkbox-align" style="color: white; ">
                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                </div>
                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <!---->
                                                                                                        </p-checkbox>
                                                                                                        Task			
                                                                                                    </div>

                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                    <div style="color: white;">Task Type	
                                                                                                    </div>
                    
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                                    <div style="color: white;">Status

                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">Assigned To
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">Created Date
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">Planned Hours	
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">Actual Hours	
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">Start Date

                                                                                                    </div>
                                                                                                </th>

                                                                                            </tr>

                                                                                         
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="body" let-product>
                                                                                            <tr>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    <div class="checkbox-align">
                                                                                                        {{product.timesheet.workEffortTypeId}}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    {{product.timesheet.workEffortTypeId}}
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    {{product.timesheet.currentStatusId}}
                                                                                                </td>
                                                                                                
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                </td>
                     
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                
                                                                                                    {{product.timesheet.actualStartDate | date:'yyyy-MM-dd hh:mm:ss' }}
                                                                                                </td>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="text-center">
                                                                                                        <h4
                                                                                                            class="ml-2">
                                                                                                            No Record
                                                                                                            Found</h4>
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                    </p-table>
                                                                                    <p class="paginate_dataa">
                                                                                        <!--  <ng-container *ngIf="openNoRecordFoundNote">
                                      <span style="margin-left: 450px;">No Record Found!....</span>
                                    </ng-container> -->
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br>
                                                                    <div class="w3-card-4 classCardView mt-2">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Emails
                                                                            </h4>

                                                                        </div>
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                                                <div class="card own-account-table borderTable">
                                                                                    <p-table [value]="EmailsProductBacklogs" [rows]="rows" scrollWidth="100%"  scrollHeight="150px"  [paginator]="false" [scrollable]="true" [customSort]="true">
                                                                                        <ng-template pTemplate="header">
                                                                                            <tr style="background: #0d3769;">
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                </div>
                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <!---->
                                                                                                        </p-checkbox>

                                                                                                    </div>
                                                                                                    Subject
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                    <div style="color: white;">
                                                                                                        Status ID
                                                                                                    </div>

                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                    <div style="color: white;">
                                                                                                        From
                                                                                                    </div>

                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                                    <div style="color: white;">
                                                                                                        To
                                                                                                    </div>
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">
                                                                                                        Created At

                                                                                                    </div>
                                                                                                </th>


                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="body" let-product>
                                                                                            <tr>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    <div class="checkbox-align">
                                                                                                        {{product.subject}}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    {{product.statusId}}
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    {{product.partyIdFrom}}

                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    {{product.partyIdTo}}

                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                    {{product.createdStamp | date:'yyyy-MM-d hh:mm:ss'}}
                                                                                                </td>

                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="text-center">
                                                                                                        <h4
                                                                                                            class="ml-2">
                                                                                                            No Record
                                                                                                            Found</h4>
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                    </p-table>
                                                                                    <p class="paginate_dataa">
                                                                                        <!--  <ng-container *ngIf="openNoRecordFoundNote">
                                      <span style="margin-left: 450px;">No Record Found!....</span>
                                    </ng-container> -->
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="w3-card-4 classCardView mt-2">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Backlog Item Content List
                                                                            </h4>

                                                                        </div>
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                                                <div class="card own-account-table borderTable">
                                                                                    <p-table
                                                                                    [value]="backlogItemContentList"
                                                                                     scrollHeight="150px"  [paginator]="false"
                                                                                    [rows]="rows"
                                                                                    scrollWidth="100%"
                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                    [totalRecords]="total"
                                                                                    [scrollable]="true"
                                                                                    (sortFunction)="customSort($event)"
                                                                                    [customSort]="true">
                                                                                    <ng-template
                                                                                        pTemplate="header">
                                                                                        <tr
                                                                                            style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                pSortableColumn="code">
                                                                                                <div class="checkbox-align"
                                                                                                    style="color: white;">
                                                                                                    <p-checkbox
                                                                                                        _ngcontent-egj-c329=""
                                                                                                        styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    Content Type ID	

                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Content Name	
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    From Date	
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Download
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>

                                                                                   



                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="body"
                                                                                        let-product>
                                                                                        <tr>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'150px'}">
                                                                                                <div
                                                                                                    class="checkbox-align">
                                                                                                    <p-checkbox
                                                                                                        _ngcontent-egj-c329=""
                                                                                                        styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    <span
                                                                                                         class="account-button"  >
                                                                                                        {{product.contentData.contentTypeId}}</span>

                                                                                                </div>
                                                                                            </td>

                                                                                            <td
                                                                                                [ngStyle]="{'width':'180px'}">
                                                                                                {{product.contentImage[0].objectInfo}}
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'180px'}">
                                                                                                {{product.data.fromDate | date:'yyyy-MM-dd HH:mm:ss'}}

                                                                                            </td>
                                                                                            <td
                                                                                            [ngStyle]="{'width':'180px'}">
                                                                                            <button (click)="EmailContentDownload(product.contentImage[0].objectInfo)"
                                                                                             type="submit" class="btn btn-secondary submit-btn ml-2">
                                                                                                Download
                                                                                            </button>
                                                                                        </td>
                                                                               
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage"
                                                                                        let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span
                                                                                                    class="text-center">
                                                                                                    <h4
                                                                                                        class="ml-2">
                                                                                                        No
                                                                                                        Record
                                                                                                        Found
                                                                                                    </h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>

                                                                                    <p class="paginate_dataa">
                                                                                        <!--  <ng-container *ngIf="openNoRecordFoundNote">
                                      <span style="margin-left: 450px;">No Record Found!....</span>
                                    </ng-container> -->
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="w3-card-4 classCardView mt-2">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Backlog Item Note List
                                                                            </h4>

                                                                        </div>
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                                                <div class="card own-account-table borderTable">
                                                                                    <p-table
                                                                                    [value]="BacklogItemNoteList"
                                                                                     scrollHeight="150px"  [paginator]="false"
                                                                                    [rows]="rows"
                                                                                    scrollWidth="100%"
                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                    [totalRecords]="total"
                                                                                    [scrollable]="true"
                                                                                    (sortFunction)="customSort($event)"
                                                                                    [customSort]="true">
                                                                                    <ng-template
                                                                                        pTemplate="header">
                                                                                        <tr
                                                                                            style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                pSortableColumn="code">
                                                                                                <div class="checkbox-align"
                                                                                                    style="color: white;">
                                                                                                    <p-checkbox
                                                                                                        _ngcontent-egj-c329=""
                                                                                                        styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    Note Name

                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Note Info
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Note Date Time
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Note Party
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>

                                                                                   



                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="body"
                                                                                        let-product>
                                                                                        <tr>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'150px'}">
                                                                                                <div
                                                                                                    class="checkbox-align">
                                                                                                    <p-checkbox
                                                                                                        _ngcontent-egj-c329=""
                                                                                                        styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    <span
                                                                                                         class="account-button"  >
                                                                                                        {{product.noteList[0].noteName}}</span>

                                                                                                </div>
                                                                                            </td>

                                                                                            <td
                                                                                                [ngStyle]="{'width':'180px'}">
                                                                                                {{product.noteList[0].noteInfo}}
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'180px'}">
                                                                                                {{product.noteList[0].noteDateTime
                                                                                                |date:'yyyy-MM-dd hh:mm:ss'}}

                                                                                            </td>
                                                                                            <td
                                                                                            [ngStyle]="{'width':'180px'}">
                                                                                            {{product.noteList[0].noteParty}}
                                                                                        </td>
                                                                               
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage"
                                                                                        let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span
                                                                                                    class="text-center">
                                                                                                    <h4
                                                                                                        class="ml-2">
                                                                                                        No
                                                                                                        Record
                                                                                                        Found
                                                                                                    </h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>

                                                                                    <p class="paginate_dataa">
                                                                                        <!--  <ng-container *ngIf="openNoRecordFoundNote">
                                      <span style="margin-left: 450px;">No Record Found!....</span>
                                    </ng-container> -->
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                   
                                                                    <br>
                                                                </div>
                                                            </div>
                                                       
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<ngx-spinner></ngx-spinner>