<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-grey breadcrumbb"  (click)="cancel()" >Role</span>
                <span class="color-black pl-1"> >  New Role Type</span>
                <button type="submit" (click)="cancel()"   class="btn btn-danger buttonclass">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard" >
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                       New Role Type</a></li>
                 
                    </ul>
                  </div>
              
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="createUser">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Role Type ID<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Role Type ID" formControlName="roleTypeId" >
                                                <small *ngIf="createUser.controls['roleTypeId'].invalid && (createUser.controls['roleTypeId'].dirty || createUser.controls['roleTypeId'].touched)">
                                                    <p class="validate-field" *ngIf="createUser.controls['roleTypeId'].errors.required">
                                                        Role Type ID is required!</p></small>
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Parent Type ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown placeholder="Enter Parent Type ID" formControlName="parentTypeId" filter="true" [options]="allroleArray" optionlabel="label">
                                                   
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Description<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" formControlName="description" >
                                                <small *ngIf="createUser.controls['description'].invalid && (createUser.controls['description'].dirty || createUser.controls['description'].touched)">
                                                    <p class="validate-field" *ngIf="createUser.controls['description'].errors.required">
                                                        Description is required!</p></small>
                                            </div>
                                            <div class="col-lg-2"></div>
                                           
                                          
                                        </div>
                                    </div>
                                  
                                   
                                   
                                
                                  
                                   
                                </div>
                            </form>
                            <div class="main-submit-button" style="margin: -24px 631px;">
                                <button type="submit" (click)="submit()" class="btn btn-secondary submit-btn update-button">Submit</button>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            </div>
        </div>
    </div>
</div> -->

<div class="modal fade" id="newRoleModalCenter" tabindex="-1" role="dialog" aria-labelledby="newRoleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
             
                <span class="color-black pl-1">New Role Type</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
    
                                <li Class="active"><a>
                                    New Role Type</a></li>
                            </ul>
                        </div>
						<div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createUser">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Role Type ID<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control ht30" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Role Type ID" formControlName="roleTypeId" >
                                                        <small *ngIf="createUser.controls['roleTypeId'].invalid && (createUser.controls['roleTypeId'].dirty || createUser.controls['roleTypeId'].touched)">
                                                            <p class="validate-field" *ngIf="createUser.controls['roleTypeId'].errors.required">
                                                                Role Type ID is required!</p></small>
                                                    </div>
                                                    <div class="col-lg-2 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Parent Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3 custom-dropdown">
                                                        <p-dropdown placeholder="Enter Parent Type ID" formControlName="parentTypeId" filter="true" [options]="allroleArray" optionlabel="label">
                                                           
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                         
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Description<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control ht30" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" formControlName="description" >
                                                        <small *ngIf="createUser.controls['description'].invalid && (createUser.controls['description'].dirty || createUser.controls['description'].touched)">
                                                            <p class="validate-field" *ngIf="createUser.controls['description'].errors.required">
                                                                Description is required!</p></small>
                                                    </div>
                                                    <div class="col-lg-2"></div>
                                                   
                                                  
                                                </div>
                                            </div>
                                          
                                           
                                           
                                        
                                          
                                           
                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin: -24px 540px;">
                                        <button type="submit" (click)="submit()" class="btn btn-secondary submit-btn update-button">Submit</button>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                
						
					</div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 