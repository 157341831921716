import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
  import { Location } from '@angular/common';
  import { ToastrService } from 'ngx-toastr';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { WarehouseService } from './warehouse.service';
@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.css']
})
export class WarehouseComponent implements OnInit {
  facilities: any[];
  activeCategory=1;
  activeCat = 1;
  @ViewChild('closebutton') closebutton;
  facilityId: string;
  facilityForm: FormGroup;
  inventoryData: any[];
  facilityType: any[];
  weightData: any[];
  areaData: any[];
  facilityIds: any[];
  partyIds: any[];
  facilityData: any;
  
  editMode: boolean;
  url: string;
  tabid: any;
  constructor(
 
    readonly _FormBuilder: FormBuilder,
  private _location: Location,
  readonly _WarehouseService: WarehouseService,
    readonly _ToastrService: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly router: Router,
    readonly _ActivatedRoute: ActivatedRoute
  ) {
    this.facilities = [];
    this.facilityId = '';

    this.url='/facilities/warehouse/'
    this.inventoryData = [];
    this.facilityType = [];
    this.weightData = [];
    this.areaData = [];
    this.facilityForm = this._FormBuilder.group({
      defaultDaysToShip: [''],
      defaultInventoryItemTypeId: [null],
      defaultWeightUomId: [null],
      description: [''],
      facilityName: ['', [Validators.required]],
      facilitySize: [''],
      facilitySizeUomId: [null],
      facilityTypeId: [null, [Validators.required]],
      ownerPartyId: [null, [Validators.required]],
      parentFacilityId: [null]
    });
    this.facilityIds = [];
    this.partyIds = [];
    this.facilityId = '';
    this.editMode = false;
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.tabid = params["id"];
    });
    console.log("this id=",this.tabid);
    if(this.tabid==undefined){
    }
    else{
      this._ToastrService.info('Please Select Facility ID to Continue');
    }


    this.getFacility();
    this.getFacilityType();
    this.getInventory();
    this.getWeight();
    this.getArea();
    this.getFacilityId();
    this.getPartyGroup();
  }
  getFacilityId(): void {
    this.spinner.show();
    this._WarehouseService.getFacility()
      .then(data => {
        this.facilityIds = data.data;
        this.spinner.hide();
      });
     
  }
  getPartyGroup(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        this.partyIds = data.data.PersonsGroups;
        this.spinner.hide();
      });
     
  }
  getFacilityType(): void {
    this.spinner.show();
    this._WarehouseService.getFacilityType()
      .then(data => {
        this.facilityType = data.data;
        this.spinner.hide();
      });
     
  }
  getInventory(): void {
    this.spinner.show();
    this._WarehouseService.defaultInventory()
      .then(data => {
        this.inventoryData = data.data;
        this.spinner.hide();
      });
     
  }
  getWeight(): void {
    this.spinner.show();
    this._WarehouseService.defaultWeight()
      .then(data => {
        this.weightData = data.data;
        this.spinner.hide();
      });
      
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  getArea(): void {
    this.spinner.show();
    this._WarehouseService.areaUnit()
      .then(data => {
        this.areaData = data.data;
        this.spinner.hide();
      });
     
  }
  update(): void {
    this.spinner.show();

    if (this.facilityForm.valid) {
      this._WarehouseService.updateFacility(this.facilityId, {
        ...this.facilityData, ...this.facilityForm.value
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.closebutton.nativeElement.click();
            this.spinner.hide();
            this.getFacility();
            this.editMode = false;
            this.facilityForm.reset();
          }
        });
    }
  }
getData(){

  if (this.facilityId) {
    this.editMode = true;
    this._WarehouseService.getFacilityById(this.facilityId).then(data =>{
      this.facilityData = data.data;
      this.facilityForm.patchValue(data.data);
      
  
    });
  }
}
  submit(): void {
    if (this.editMode) {
      this.update();
    } else {
  
        this.spinner.show();
        this._WarehouseService.createFacility(this.facilityForm.value)
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Created');
              this.spinner.hide();
              this.getFacility();
              this.facilityForm.reset();
              this.closebutton.nativeElement.click();
            }
          });
      
    }
 
  }
  dashboardOpen(){
    this.router.navigate(["/facilities/warehouse-dashborad-overview"])
  }
  ShippingOpen(){
    this.router.navigate(["/facilities/warehouse-shipment-config"])
  }
  Main(){
    this.router.navigate(["/facilities/warehouse"])
  }
  Facility(){
    this.router.navigate(["/facilities/facility-group"])
  }
  Inventory(){
    this.router.navigate(["/facilities/inventory-labels"])
  }
  Shipments(){
    this.router.navigate(["/facilities/warehouse-shipment"])
  }
  getFacility(): void {
    this.spinner.show();
    this._WarehouseService.getFacility()
      .then(data => {

        this.spinner.hide()
        this.facilities = data.data.map(value => {
          return {
            label: value.facilityName,
            value: value.facilityId
          };
        });
      });
     
  }
  mainOpen(){
    this.router.navigate(['/facilities/warehouse']);
  }

  facilityGroupOpen(){
    this.router.navigate(['/facilities/facility-group']);
  }

  inventoryItemOpen(){
    this.router.navigate(['/facilities/inventory-labels']);
  }
  createFacilities(): void {
    this.router.navigate([`/facilities/warehouse/create-new-facilities`]);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
