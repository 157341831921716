import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-flag-reasons',
  templateUrl: './flag-reasons.component.html',
  styleUrls: ['./flag-reasons.component.css']
})
export class FlagReasonsComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;

  activeCategory = 9;
  activeCategories = 2;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;

  addTeamMember: any;
  editTeam: any;
  TeamByPartyId: any;
  party: any;
  partyId: any;
  Role: any;
  RoleArray: any[] = [];
  show: boolean;
  TeamMemberByPartyId: any;
  updateTeamMem: any;
  Deactivated: boolean;
  personId: any;
  partyIdArray: any[] = [];
  teamMember: any;
  teamMemberArray: any[] = [];
  editFlagReasons: any;
  addFlagReasonsForm: FormGroup;
  editFlagReasonsForm: FormGroup;
  fsmFlagResponse: any;
  fsmFlagResponseById: any;
  
  constructor(
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) {
    {
      this.addFlagReasonsForm = this._FormBuilder.group({
        displayAs: "",
      });

      this.editFlagReasonsForm = this._FormBuilder.group({
        displayAs: "",
      });

    }
  }



  ngOnInit(): void {
    this.getFsmFlagResponse();

  }
  deleteFlagReasons(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "flagReasonsDisplayAs": product.flagReasonsDisplayAs,
          "flagReasonsId": product.flagReasonsId
        }
        this.myContactsService.deleteFlagReasons(req).subscribe(res => {
          this.getFsmFlagResponse();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getFsmFlagResponseById(product) {
    this.spinner.show();
    this.myContactsService.getFsmFlagResponseById(product.flagReasonsId).subscribe(res => {
      this.fsmFlagResponseById = res.data[0].getFsmFlagResponseById[0];
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.editFlagReasonsForm;
        formValue.patchValue({
          displayAs: this.fsmFlagResponseById.flagReasonsDisplayAs,
        })

      }, 2000);
    })
  }
  getFsmFlagResponse() {
    this.spinner.show();
    this.myContactsService.getFsmFlagResponse().subscribe(res => {
      this.fsmFlagResponse = res.data[0].getFsmFlagResponse;
      this.spinner.hide();
    })
  }

  cancelSubmit() {
    this._Router.navigate(["/fsm/fsmSetting/work-order"])
  }

  createFlagReasons() {
    this.spinner.show();
    const requestData = {
      "flagReasonsDisplayAs": this.addFlagReasonsForm.value.displayAs,
      "flagReasonsId": ""
    }

    this.myContactsService.createFlagReasons(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.addFlagReasonsForm.reset();
        this.getFsmFlagResponse();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateFlagReasons() {
    this.spinner.show();
    const requestData = {
      "flagReasonsDisplayAs": this.editFlagReasonsForm.value.displayAs,
      "flagReasonsId": this.fsmFlagResponseById.flagReasonsId
    }
    this.myContactsService.updateFlagReasons(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.editFlagReasonsForm.reset();
        this.getFsmFlagResponse();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }



  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }



  ngOnDestroy(): void {
    this.spinner.hide();
  }



}
