import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';

@Injectable()
export class AgreementSummaryItemResolver implements Resolve<any> {
    constructor(
        readonly _AccountingApService: AccountingApService
    ) { }



    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        return this._AccountingApService.getAgreementById(route.queryParams.agreementId).then(data => data.data.agreementTypeId === 'PURCHASE_AGREEMENT' ? true : false);
    }
}