<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Content</span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="openList()"><a>
                                List</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)" ><a>
                                View</a></li>

                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)" ><a>
                                Edit</a></li>

                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)" ><a>
                                Roles</a></li>
                            
                            
                            <button type="submit" class="btn btn-secondary submit-btn ml-2 mt-2" style="margin: 0;" data-toggle="modal" data-target="#createBlogArticlesPopup">Create New Article</button>

                        </ul>
                    </div>
                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                    <div *ngIf="activeCategory==2">
                                        <div class="w3-card-4 classCard w-100">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                style="justify-content: space-between;">
                                                <p class="h-text">Summary</p>
                                                <span>
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                        style="margin: 0;" data-toggle="modal"
                                                        data-target="#updateViewPopup">Update</button>
                                                    <!--<button type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#newpopup">New Product Promo</button> -->
                                                </span>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                
                                                <div class="color-black container">
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Image</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Summary</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Article	</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        
                                        
                                    </div>
                                        
                                    <div *ngIf="activeCategory==3">
                                        <div class="w3-card-4 classCard w-100">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                style="justify-content: space-between;">
                                                <p class="h-text">Summary</p>
                                                <span>
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                        style="margin: 0;" data-toggle="modal"
                                                        data-target="#updateBlogArticlesPopup">Update</button>
                                                    <!--<button type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#newpopup">New Product Promo</button> -->
                                                </span>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                
                                                <div class="color-black container">
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Blog entry ID</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Article Name</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Description	</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Summary		
                                                                    </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Article	</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Image		
                                                                    </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Template		
                                                                    </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Status			
                                                                    </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 
 <div class="modal fade" id="createNewBlogPopup" tabindex="-1" role="dialog" aria-labelledby="createNewBlogPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create New Blog
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create New Blog
                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createblogForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Blog Name<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="blogName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Blog Name">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Blog Description</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="blogDescription" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Blog Description">
                                               
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            

                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="updateViewPopup" tabindex="-1" role="dialog" aria-labelledby="updateViewPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update View
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Update View
                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateViewForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Image</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input
                                                        type="file"
                                                        class="form-control"
                                                        id="exampleInputEmail1"
                                                        formControlName="image"
                                                        aria-describedby="emailHelp"
                                                        >
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Summary</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="summary" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Summary">
                                               
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Article</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="article" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Article">
                                               
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            

                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createBlogArticlesPopup" tabindex="-1" role="dialog" aria-labelledby="createBlogArticlesPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create New Article
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create New Article
                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createNewArticleForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Article Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="articleName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Article Name">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="description" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Description">
                                               
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Summary	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="summary" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Summary">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Article</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="description" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Article	">
                                               
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Image</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input
                                                        type="file"
                                                        class="form-control"
                                                        id="exampleInputEmail1"
                                                        formControlName="image"
                                                        aria-describedby="emailHelp"
                                                        >
                                                        
                                                       
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Template</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                        optionlabel="label"
                                                        placeholder="Choose Template"
                                                        formControlName="template">
                                                        </p-dropdown>
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Status</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                        optionlabel="label"
                                                        placeholder="Choose Status"
                                                        formControlName="status">
                                                        </p-dropdown>
                                                        
                                                       
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="updateBlogArticlesPopup" tabindex="-1" role="dialog" aria-labelledby="updateBlogArticlesPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Article
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Update Article
                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateArticleForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="exampleInputEmail1">
                                                            Blog Entry ID</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Blog Entry ID"
                                                        formControlName="blogEntryID">
                                                    </div>
                                                    
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Article Name	
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Article Name"
                                                        formControlName="articleName">
                                                    </div>
                            
                                                    
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="accountLimit">Description</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Description"
                                                        formControlName="description">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Summary</label>
                                                    </div>
                                                    
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Summary"
                                                        formControlName="summary">
                                                    </div>
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="exampleInputEmail1">Article</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Article"
                                                        formControlName="article">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA" >
                                                        <label for="exampleInputEmail1">Image</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" 
                                                        formControlName="image">
                                                    </div>
                                                    
                                                    
                            
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="exampleInputEmail1">Template</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                        optionlabel="label"
                                                        placeholder="Choose Template"
                                                        formControlName="template">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA" >
                                                        <label for="exampleInputEmail1">Status</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                        optionlabel="label"
                                                        placeholder="Choose Status"
                                                        formControlName="status">
                                                        </p-dropdown>
                                                    </div>
                                                    
                                                    
                            
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                            
                                            
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner> 

