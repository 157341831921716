import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder ,FormGroup,Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';  import { Location } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MergeContactsService } from 'src/app/crm/contacts/merge-contacts/merge-contacts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { WarehouseService } from 'src/app/warehouse/warehouse/warehouse.service';


@Component({
  selector: 'app-find-requirements-detail',
  templateUrl: './find-requirements-detail.component.html',
  styleUrls: ['./find-requirements-detail.component.css']
})
export class FindRequirementsDetailComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton1') closebutton1;

  activeCategory = 7;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  editRequirementForm: FormGroup;
  createRequirementForm: FormGroup;
  requirementId: any;
  requirementPlanningById: any;
  requirementTypeIdArray: any[]=[];
  facilityArray: any[]=[];
  facility: any;
  requirementStatusId: any;
  requirementStatusIdArray: any[]=[];
  ProductId: any;
  ProductIdArray: any[]=[];
  suppliers: any[];
  requirementTypeId: any;
  fixedAssetId: any;
  fixedAssetIdArray: any[]=[];
  orderItemTypeId: any;
  orderItemTypeIdArray: any[]=[];
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly _WarehouseService: WarehouseService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly mergeContactsService: MergeContactsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,    private _location: Location,
    readonly spinner: NgxSpinnerService,
    readonly _Router: Router
  )
  {
    this.createRequirementForm=this._FormBuilder.group({
      requirementTypeId:"",
      facilityId:"",
      deliverableId:"",
      fixedAssetId:"",
      productId:"",
      statusId:"",
      groupName:"",
      description:"",
      requirementStartDate:"",
      requiredByDate:"",
      estimatedBudget:"",
      quantity:"",
      useCase:"",
      reason:"",
      orderItemTypeId:"",
      facilityIdTo:"",
      custRequestId:"",
      custRequestItemSeqId:"",
    })

  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=>{
      this.requirementId = params.requirementId;
    })
    this.getRequirementPlanningById();
    this.getFacilityId();
    this.getRequirementTypeId();
    this.getRequirementStatusId();
    this.getProductIds();
    this.getSuppliers();
    this.getfixedAssetID();
    this.orderItemType();
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  orderItemType() {
    this.spinner.show();
    this.myContactsService.orderItemType().subscribe(res => {
      this.orderItemTypeId = res.data[0].orderItemType;
      this.spinner.hide();
      for (const value of this.orderItemTypeId) {
        this.orderItemTypeIdArray.push({
          label: value.description,
          value: value.orderItemTypeId
        })
      }
    })
  }
  getfixedAssetID() {
    this.spinner.show();
    this.myContactsService.getFixedAssetIdRequirement().subscribe(res => {
      this.fixedAssetId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.fixedAssetId) {
        this.fixedAssetIdArray.push({
          label: value.fixedAssetId,
          value: value.fixedAssetId
        })
      }
    })
  }
  getProductIds() {
    this.spinner.show();
    this.myContactsService.getProductIds().subscribe(res => {
      this.ProductId = res.data[0].getProductId;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.internalName,
          value: value.productId
        })
      }
    })
  }
  getRequirementStatusId() {
    this.spinner.show();
    this.myContactsService.getRequirementStatusId().subscribe(res => {
      this.requirementStatusId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.requirementStatusId) {
        this.requirementStatusIdArray.push({
          label: value.description,
          value: value.statusValue
        })
      }
    })
   
  }
  getRequirementTypeId() {
    this.spinner.show();
    this.myContactsService.getRequirementTypeId().subscribe(res => {
      this.requirementTypeId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.requirementTypeId) {
        this.requirementTypeIdArray.push({
          label: value.description,
          value: value.requirementTypeId
        })
      }
    })
   
  }
  getFacilityId() {
    this.spinner.show();
    this.myContactsService.getFacilityId().subscribe(res => {
      this.facility = res.data[0].getFacilityId;
      this.spinner.hide();
      for (const value of this.facility) {
        this.facilityArray.push({
          label: value.facilityName,
          value: value.facilityId
        })
      }
    })
   
  }
  getSuppliers(): void {

    this.spinner.show();
    this._WarehouseService.getSuppliers()
        .then(data => {
            this.spinner.hide();
            this.suppliers = data.data.map(value => {
                return {
                    label: value.partyId,
                    value: value.partyId
                };
            });
        });
        
}
  getRequirementPlanningById() {
    this.spinner.show();
    this.mergeContactsService.getRequirementPlanningById(this.requirementId ).subscribe(res => {
      this.requirementPlanningById = res.data[0].getRequirementPlanningAll[0];
      this.spinner.hide();
      setTimeout(() => {
        const form = this.createRequirementForm;
      form.patchValue({
        requirementTypeId: this.requirementPlanningById.requirementTypeId  ,
        facilityId: this.requirementPlanningById.facilityId  ,
        deliverableId: this.requirementPlanningById.deliverableId  ,
        fixedAssetId: this.requirementPlanningById.fixedAssetId  ,
        productId: this.requirementPlanningById.productId  ,
        statusId: this.requirementPlanningById.statusId  ,
        description: this.requirementPlanningById.description  ,
        requirementStartDate:this.datePipe.transform( this.requirementPlanningById.requirementStartDate  ,"yyyy-MM-dd"),
        requiredByDate:this.datePipe.transform(this.requirementPlanningById.requiredByDate,"yyyy-MM-dd" ) ,
        estimatedBudget: this.requirementPlanningById.estimatedBudget  ,
        quantity: this.requirementPlanningById.quantity  ,
        useCase: this.requirementPlanningById.useCase  ,
        reason: this.requirementPlanningById.reason  ,
        orderItemTypeId: this.requirementPlanningById.orderItemTypeId  ,
        facilityIdTo: this.requirementPlanningById.facilityIdTo  ,
        custRequestId: this.requirementPlanningById.custRequestId  ,
        custRequestItemSeqId: this.requirementPlanningById.custRequestItemSeqId  ,
      })
      }, 2000);
    })
   
  }
  UpdateRequirementPlanning(): void {
    this.spinner.show();
    const requestData = {

      "custRequestId": this.createRequirementForm.value.custRequestId,
    "custRequestItemSeqId": this.createRequirementForm.value.custRequestItemSeqId,
    "deliverableId": this.createRequirementForm.value.deliverableId,
    "description": this.createRequirementForm.value.description,
    "estimatedBudget": this.createRequirementForm.value.estimatedBudget,
    "facilityId": this.createRequirementForm.value.facilityId,
    "facilityIdTo": this.createRequirementForm.value.facilityIdTo,
    "fixedAssetId": this.createRequirementForm.value.fixedAssetId,
    "groupName": this.createRequirementForm.value.groupName,
    "orderItemTypeId": this.createRequirementForm.value.orderItemTypeId,
    "productId": this.createRequirementForm.value.productId,
    "quantity": this.createRequirementForm.value.quantity,
    "reason": this.createRequirementForm.value.reason,
    "requiredByDate":this.datePipe.transform(this.createRequirementForm.value.requiredByDate,"yyyy-MM-dd hh:mm:ss") ,
    "requirementId": this.requirementId,
    "requirementStartDate": this.datePipe.transform(this.createRequirementForm.value.requirementStartDate,"yyyy-MM-dd hh:mm:ss") ,
    "requirementTypeId": this.createRequirementForm.value.requirementTypeId,
    "statusId": this.createRequirementForm.value.statusId,
    "useCase":this.createRequirementForm.value.useCase,

   

    };
    this.myContactsService.UpdateRequirementPlanning(requestData).subscribe((res: any) => {
  
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.getRequirementPlanningById();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  toMain()
  {
    this._Router.navigate(['/facilities/Planning/find-requirements'])
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}
