import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddEmployeePositionService } from 'src/app/human-resource/add-employee-position/add-employee-position.service';
import { FinancialService } from 'src/app/services/financial.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-employees-profile',
  templateUrl: './employees-profile.component.html',
  styleUrls: ['./employees-profile.component.css']
})
export class EmployeesProfileComponent implements OnInit {
  employees: any;
  activeCategory=4;

  constructor(

    readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly addEmployeePositionService: AddEmployeePositionService,
    readonly _MainCatalogService: MainCatalogService,
    readonly _FinancialService: FinancialService,
    private _location: Location,

    readonly _Router: Router,
    readonly _ToastrService: ToastrService,
  ) {


   }

  ngOnInit(): void {
    this.getEmployeeDetails();

  }


  findPaycheck()
  {
    this.router.navigate(['financial/main-find-paycheck']);
  }
  createPaychecks()
  {
    this.router.navigate(['/financial/main-create-paycheck']);

  }
  paycheckConfiguration()
  {
    this.router.navigate(['/financial/main-configuration']);

  }
  employeess()
  {
    this.router.navigate(['/financial/employees-profile']);

  }
  reports()
  {
    this.router.navigate(['/financial/main-reports']);

  }

  contractReferencees()
  {
    this.router.navigate(['/financial/contractor']);

  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }

  backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this._Router.navigate(['/financial/main']);
}


  getEmployeeDetails() {
    this.accountsService.getEmployeePageDetails().subscribe((res: any) => {
      this.spinner.show();
      this.employees = res.data;
      this.spinner.hide();
    })
  }

}
