import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ar-submenu',
  templateUrl: './ar-submenu.component.html',
  styleUrls: ['./ar-submenu.component.css']
})
export class ArSubmenuComponent implements OnInit {
  agreementType: string;

  constructor() { }

  ngOnInit(): void {
  
    this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
  }

}
