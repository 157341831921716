import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { Router } from '@angular/router';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import { DashboardService } from 'src/app/services/dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-catalog-dashboard',
  templateUrl: './catalog-dashboard.component.html',
  styleUrls: ['./catalog-dashboard.component.css']
})

export class CatalogDashboardComponent implements OnInit, AfterViewInit {
  chartValue: any[];
  isShown=false;
  bestSellingProducts: number;
  productsCount: number;
  bspBreakdown: any[];
  productBreakdown: any[];
  constructor(
    readonly _DashboardService: DashboardService,
    readonly spinner: NgxSpinnerService,
  ) {
    this.bspBreakdown = [];
    this.productBreakdown = [];
    this.chartValue = [];
    this.bestSellingProducts = 0;
    this.productsCount = 0;
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this._DashboardService.catalogData()
      .then(value => {
        this.bestSellingProducts = value.data.bestSellingProducts.reduce((accumulator, currentValue) => accumulator + currentValue.qtyOrdered);
        this.productsCount = value.data.newProductsMap.productsCount;

        this.chartValue = value.data.bestSellingProducts.map(product => {
          return {
            category: `[${product.productId}] ${product.productName}`,
            value: product.qtyOrdered,
            breakdown: [
              {
                category: `[${product.productId}] Ordered`,
                value: product.qtyOrdered
              },
              {
                category: `[${product.productId}] Refund`,
                value: product.qtyRefunded
              }
            ]
          };
        });

        this.bspBreakdown = value.data.bestSellingProducts.map(sell => {
          return {
            category: `[${sell.productId}] ${sell.productName}`,
            value: sell.qtyOrdered
          };
        });
        this.productBreakdown = value.data.newProductsMap.products.map(product => {
          return {
            category: `[${product.productId}] ${product.internalName}`,
            value: 1
          };
        });
      

        // Themes begin
        am4core.useTheme(am4themes_animated);
        // Themes end

        /**
         * Source data
         */


        /**
         * Chart container
         */

        // Create chart instance
        let chart = am4core.create("chartdiv", am4core.Container);
        chart.width = am4core.percent(100);
        chart.height = am4core.percent(100);
        chart.layout = "horizontal";


        /**
         * Column chart
         */

        // Create chart instance
        let columnChart = chart.createChild(am4charts.XYChart);

        // Create axes
        let categoryAxis = columnChart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.inversed = true;

        let valueAxis = columnChart.xAxes.push(new am4charts.ValueAxis());

        // Create series
        let columnSeries = columnChart.series.push(new am4charts.ColumnSeries());
        columnSeries.dataFields.valueX = "value";
        columnSeries.dataFields.categoryY = "category";
        columnSeries.columns.template.strokeWidth = 0;

        /**
         * Pie chart
         */

        // Create chart instance
        let pieChart = chart.createChild(am4charts.PieChart);
        pieChart.data = this.chartValue;
        pieChart.innerRadius = am4core.percent(50);

        // Add and configure Series
        let pieSeries = pieChart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "category";
        pieSeries.slices.template.propertyFields.fill = "color";
        pieSeries.labels.template.disabled = true;

        // Set up labels
        let label1 = pieChart.seriesContainer.createChild(am4core.Label);
        label1.text = "";
        label1.horizontalCenter = "middle";
        label1.fontSize = 35;
        label1.fontWeight = 600 as any;
        label1.dy = -30;

        let label2 = pieChart.seriesContainer.createChild(am4core.Label);
        label2.text = "";
        label2.horizontalCenter = "middle";
        label2.fontSize = 12;
        label2.dy = 20;

        // Auto-select first slice on load
        pieChart.events.on("ready", function (ev) {
          pieSeries.slices.getIndex(0).isActive = true;
        });

        // Set up toggling events
        pieSeries.slices.template.events.on("toggled", function (ev) {
          if (ev.target.isActive) {

            // Untoggle other slices
            pieSeries.slices.each(function (slice) {
              if (slice != ev.target) {
                slice.isActive = false;
              }
            });

            // Update column chart
            columnSeries.appeared = false;
            columnChart.data = ev.target.dataItem.dataContext['breakdown'];
            columnSeries.fill = ev.target.fill;
            columnSeries.reinit();

            // Update labels
            label1.text = pieChart.numberFormatter.format(ev.target.dataItem.values.value.percent, "#.'%'");
            label1.fill = ev.target.fill;

            label2.text = ev.target.dataItem['category'];
          }
        });

      });
  }
  feautureValue(){
    this.isShown = !this.isShown;
  }
}