import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-hardware-detail-page',
  templateUrl: './hardware-detail-page.component.html',
  styleUrls: ['./hardware-detail-page.component.css']
})
export class HardwareDetailPageComponent implements OnInit {
  activeCategory = 1;
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  updateHardwareForm: FormGroup;
  calendarArray: { label: string; value: string; }[];
  fixedAssetId: any;
  InstalledSoftwareList: any;
  parentFixedAssetId: any;
  parentFixedAssetIdArray: any[] = [];
  FacilityID: any;
  FacilityIDArray: any[] = [];
  FixedAssetIdListArray: any[] = [];
  personId: any;
  ProductIdArray: any[] = [];
  ProductId: any;
  hardwareAccessoriesData: any;
  ListInstalledSoftwareData: any;
  classEnumId: any;
  umoIdArray: any[] = [];
  umoId: any;
  classEnumIdArray: any[] = [];
  LocationSeqId: any;
  LocationSeqIdArray: any[] = [];
  InstalledSoftwareData: any;
  constructor(
    readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly activatedRoute: ActivatedRoute,
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    private _location: Location,
  ) {
    this.updateHardwareForm = this._FormBuilder.group({
      fixedAssetID: "",
      fixedAssetParentID: "",
      instanceOfProductID: "",
      classEnumID: "",
      fixedAssetName: "",
      acquireOrderID: "",
      acquireOrderItemSeqID: "",
      dateAcquired: "",
      dateLastServiced: "",
      dateNextService: "",
      expectedEndOfLife: "",
      actualEndOfLife: "",
      productionCapacity: "",
      UOMId: "",
      calendar: "",
      serialNumber: "",
      locatedAtFacilityID: "",
      locatedAtLocationSeqID: "",
      salvageValue: "",
      depreciation: "",
      purchaseCost: "",
      purchaseCostUomID: "",


    })
    this.calendarArray = [
      {
        label: "[DEFAULT] Default calendar used when no specific calendar is defined",
        value: "DEFAULT"
      },
      {
        label: "[DEMO_CALENDAR] Demo Workcenter Calendar",
        value: "DEMO_CALENDAR"
      },
      {
        label: "[SUPPLIER] Calendar used for Re-Order date calculation for bought product",
        value: "SUPPLIER"
      },
    ]
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.fixedAssetId = params["fixedAssetId"];

    });
    this.FixedAssetParentIDHardware();
    this.getFacilityID();
    this.LookupComputerHardwareClassEnumId();
    this.IsManagerSummary();
    this.getUmo();
    this.ListHardwareAccessories();
    this.getFacilityID();
    this.getProductId();
    this.ListInstalledSoftware();
    this.ListInstalledSoftware();
    this.getFixedAssetList();
  }

  backWindow(){
      this._location.back();
    }
    toDashboard()
  {
    this._Router.navigate(['/ismgr/ismgr-Dashboard']);
  }
  getFixedAssetList() {
    this.spinner.show();
    this.accountsService.getFixedAssetList().subscribe(res => {
      this.LocationSeqId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.LocationSeqId) {
        this.LocationSeqIdArray.push({
          label: value.locatedAtLocationSeqId,
          value: value.locatedAtLocationSeqId
        })
      }
    })
  }
  getUmo() {
    this.spinner.show();
    this.myContactsService.getUmo().subscribe(res => {
      this.umoId = res.data[0].FixedAssetParentIDHardware;
      this.spinner.hide();
      for (const value of this.umoId) {
        this.umoIdArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
  }
  LookupComputerHardwareClassEnumId() {
    this.spinner.show();
    this.myContactsService.LookupComputerHardwareClassEnumId().subscribe(res => {
      this.classEnumId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.classEnumId) {
        this.classEnumIdArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }
  ListHardwareAccessories() {
    this.spinner.show();
    this.myContactsService.ListHardwareAccessories(this.fixedAssetId).subscribe(res => {
      this.hardwareAccessoriesData = res.data[0].ListHardwareAccessories;
      this.spinner.hide();
    })
  }
  ListInstalledSoftware() {
    this.spinner.show();
    this.myContactsService.ListInstalledSoftware(this.fixedAssetId).subscribe(res => {
      this.ListInstalledSoftwareData = res.data;
      this.spinner.hide();
    })
  }
  getProductId() {
    this.spinner.show();
    this.accountsService.getProductId().subscribe(res => {
      this.ProductId = res.data[0].Product;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })

  }
  getFixedAssetIdList() {
    this.spinner.show();
    this.myContactsService.getFixedAssetIdList().subscribe(res => {
      this.personId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.personId) {
        this.FixedAssetIdListArray.push({
          label: value.fixedAssetName,
          value: value.fixedAssetId
        })
      }
    })
  }
  getFacilityID() {
    this.spinner.show();
    this.accountsService.getFacilityID().subscribe(res => {
      this.FacilityID = res.data[0].data;
      this.spinner.hide();
      for (const value of this.FacilityID) {
        this.FacilityIDArray.push({
          label: value.description,
          value: value.facilityId
        })
      }
    })
  }
  FixedAssetParentIDHardware() {
    this.spinner.show();
    this.myContactsService.getUmo().subscribe(res => {
      this.parentFixedAssetId = res.data[0].FixedAssetParentIDHardware;
      this.spinner.hide();
      for (const value of this.parentFixedAssetId) {
        this.parentFixedAssetIdArray.push({
          label: value.parentFixedAssetId,
          value: value.parentFixedAssetId
        })
      }
    })
  }
  IsManagerSummary() {
    this.spinner.show();
    this.myContactsService.IsManagerSummary(this.fixedAssetId).subscribe(res => {
      this.InstalledSoftwareList = res.data[0].IsManagerSummary;
      this.InstalledSoftwareData = res.data[0];
      this.spinner.hide();
      setTimeout(() => {
        const form = this.updateHardwareForm;
        form.patchValue({
          fixedAssetID: this.InstalledSoftwareList.fixedAssetId,
          fixedAssetParentID: this.InstalledSoftwareList.parentFixedAssetId,
          instanceOfProductID: this.InstalledSoftwareList.instanceOfProductId,
          classEnumID: this.InstalledSoftwareList.classEnumId,
          fixedAssetName: this.InstalledSoftwareList.fixedAssetName,
          acquireOrderID: this.InstalledSoftwareList.acquireOrderId,
          acquireOrderItemSeqID: this.InstalledSoftwareList.acquireOrderItemSeqId,
          dateAcquired: this.datePipe.transform(this.InstalledSoftwareData.dateAcquired, "yyyy-MM-dd"),
          dateLastServiced: this.datePipe.transform(this.InstalledSoftwareData.dateLastServiced, "yyyy-MM-dd"),
          dateNextService: this.datePipe.transform(this.InstalledSoftwareData.dateNextService, "yyyy-MM-dd"),
          expectedEndOfLife: this.datePipe.transform(this.InstalledSoftwareData.expectedEndOfLife, "yyyy-MM-dd"),
          actualEndOfLife: this.datePipe.transform(this.InstalledSoftwareData.actualEndOfLife, "yyyy-MM-dd"),
          productionCapacity: this.InstalledSoftwareList.productionCapacity,
          UOMId: this.InstalledSoftwareList.uomId,
          calendar: this.InstalledSoftwareList.calendarId,
          serialNumber: this.InstalledSoftwareList.serialNumber,
          locatedAtFacilityID: this.InstalledSoftwareList.locatedAtFacilityId,
          locatedAtLocationSeqID: this.InstalledSoftwareList.locatedAtLocationSeqId,
          salvageValue: this.InstalledSoftwareList.salvageValue,
          depreciation: this.InstalledSoftwareList.depreciation,
          purchaseCost: this.InstalledSoftwareList.purchaseCost,
          purchaseCostUomID: this.InstalledSoftwareList.purchaseCostUomId,
        })
      }, 2000);
    })
  }
  updateComputerHardware(): void {
    this.spinner.show();
    const requestData = {
      "dateAcquired": this.datePipe.transform(this.updateHardwareForm.value.dateAcquired, "yyyy-MM-dd hh:mm:ss"),
      "dateLastServiced": this.datePipe.transform(this.updateHardwareForm.value.dateLastServiced, "yyyy-MM-dd hh:mm:ss"),
      "dateNextService": this.datePipe.transform(this.updateHardwareForm.value.dateNextService, "yyyy-MM-dd hh:mm:ss"),
      "actualEndOfLif": this.datePipe.transform(this.updateHardwareForm.value.actualEndOfLife, "yyyy-MM-dd hh:mm:ss"),
      "expectedEndOfLife": this.datePipe.transform(this.updateHardwareForm.value.expectedEndOfLife, "yyyy-MM-dd hh:mm:ss"),
      "acquireOrderId": this.updateHardwareForm.value.acquireOrderID,
      "acquireOrderItemSeqId": this.updateHardwareForm.value.acquireOrderItemSeqID,
      "calendarId": this.updateHardwareForm.value.calendar,
      "classEnumId": this.updateHardwareForm.value.classEnumID,
      "depreciation": this.updateHardwareForm.value.depreciation,
      "fixedAssetId": this.updateHardwareForm.value.fixedAssetID,
      "fixedAssetName": this.updateHardwareForm.value.fixedAssetName,
      "fixedAssetTypeId": "COMPUTER_HARDWARE",
      "instanceOfProductId": this.updateHardwareForm.value.instanceOfProductID,
      "locatedAtFacilityId": this.updateHardwareForm.value.locatedAtFacilityID,
      "locatedAtLocationSeqId": this.updateHardwareForm.value.locatedAtLocationSeqID,
      "parentFixedAssetId": this.updateHardwareForm.value.fixedAssetParentID,
      "partyId": "",
      "productionCapacity": this.updateHardwareForm.value.productionCapacity,
      "purchaseCost": this.updateHardwareForm.value.purchaseCost,
      "purchaseCostUomId": this.updateHardwareForm.value.purchaseCostUomID,
      "roleTypeId": "",
      "salvageValue": this.updateHardwareForm.value.salvageValue,
      "serialNumber": this.updateHardwareForm.value.serialNumber,
      "uomId": this.updateHardwareForm.value.UOMId,
    }
    this.myContactsService.updateComputerHardware(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.updateHardwareForm.reset();
        this.closebutton2.nativeElement.click();
        this.IsManagerSummary();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });

  }

  openSoftware() {
    this._Router.navigate(['/ismgr/software']);
  }

  toBack()
  {
    this._Router.navigate(['/ismgr/hardware'])
  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;

  }



}
