<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="bg-white color-grey mt-20">
                <div class="w3-card-4 classCard">
                    <header class="w3-container w3-blue">
                        <div class="header-tabs">
                            <h4 class="common-styling h4Margin">
                                Data Source
                            </h4>
                            <div style="margin-right: 7px;">
                                <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                                <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                            </div>
                        </div>
                    </header>
                    <div class="create-new-leade">
                        <div class="container-fluid">
                            <div class="w3-card-4 classCardView mt-2">
                                <header class="w3-container w3-blue">
                                    <div class="header-tabs">
                                        <h4 class="common-styling h4Margin">
                                            Summary
                                        </h4>
                                        <span style="margin-right: 10px;">
                                                    <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#updatepopup"> 
                                                        Update</button>
                                                </span>
                                    </div>
                                </header>
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="color-black container">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="article-container">
                                                    <div class="article">
                                                        <p class="col-lg-6">Data Source Type ID</p>
                                                        <span class="color-grey pl-20 font-weight-bold" *ngIf="this.dataSourceTypeById">
                                                            {{this.dataSourceTypeById.dataSourceTypeId }}
                                                            
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="article-container">
                                                    <div class="article">
                                                        <p class="col-lg-6">Description </p>
                                                        <span class="color-grey pl-20 font-weight-bold" *ngIf="this.dataSourceTypeById">
                                                            {{this.dataSourceTypeById.description}}
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="updatepopup" tabindex="-1" role="dialog" aria-labelledby="updatepopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Data Source Type </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>

             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>
                                    Update Data Source Type</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="CreateDataSourceTypeForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Data Source Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="DataSourceTypeID" placeholder="Enter Data Source Type ID" readOnly>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Description" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button (click)="updateDataSourceType()" type="submit" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>