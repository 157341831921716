import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { URLS } from 'src/app/app.constant';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-my-time-effort',
  templateUrl: './my-time-effort.component.html',
  styleUrls: ['./my-time-effort.component.css']
})
export class MyTimeEffortComponent implements OnInit {

  @ViewChild('closebutton1') closebutton1;
  activeCategory = 4;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  createTimesheet: FormGroup;
  quickTimeEntry: FormGroup;
  statusArray: { label: string; value: string; }[];
  personId: any;
  partyIdArray: any[]=[];
  MyTimedata: any;
  WorkEffortRate: any;
  WorkEffortRateArray: any[]=[];
  MyRatesdata: any;
  findTimesheet: any;
  actualfromdate: any;
  actualthroughdate: any;
  MyTimesheets: any;
  timeSheetId: any;
  timesheetId: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  MyTimesheetId: any;

  constructor( public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly addSkillService: AddSkillService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    private _location: Location,
    
    readonly toastr: ToastrService,
    readonly _Router: Router) {

      this.statusArray = [

        {
          label: 'Approved',
          value: 'TIMESHEET_APPROVED'
        },
        {
          label: 'Completed',
          value: 'TIMESHEET_COMPLETED'
        },
        {
          label: 'In-Process',
          value: 'TIMESHEET_IN_PROCESS'
        },
      ]; 
      this.createTimesheet = this._FormBuilder.group({
        party: [''],
        clientPartyID: [''],
        fromDate: [''],
        throughDate: [''],
        status: [''],
        approvalByUserLoginID: [''],
        createComments: ['']
      });
      this.quickTimeEntry = this._FormBuilder.group({
        rateType: [''],
        workEffortID: [''],
        hours: [''],
        comments: [''],
      })
     }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    this.getWorkEffortRate();
    this.getPartyId();
    this.getMyTime();
    this.getMyRates();
    this.getMyTimesheets();
    
    
  }

  detailedPageTime(timesheetId) {
    this._Router.navigate(["/psa/work-effort/timesheet-view/EditTimesheet"],{ queryParams: { timesheetId: timesheetId} })
  }
  detailedPage(timesheetId) {
    this._Router.navigate(["/psa/work-effort/timesheet-view/EditTimesheet"],{ queryParams: { timesheetId: timesheetId} })
  }
  timeId(id){
    this.timeSheetId =id;
  }
  homeButton(){
     this._Router.navigate(['/psa/Dashboard'])
   
   }
   cancelSubmit(){
     this._location.back();
   }
   
  createQuickTimeEntry(): void {
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.spinner.show();
      const formData ={
    "comments": this.quickTimeEntry.value.comments,
    "hours": this.quickTimeEntry.value.hours,
    "partyId": "admin",
    "rateTypeId": this.quickTimeEntry.value.rateType,
    "timesheetId":this.timeSheetId,
    "workEffortId": this.quickTimeEntry.value.workEffortID  
      }
      this.myContactsService.createQuickTimeEntry(formData).subscribe((res: any) => {
       
        if (res.success) {
          this.toastr.success("Successfully Created");
          this.spinner.hide();
          this.quickTimeEntry.reset();
          this.createTimeSheetData();
          
         
        }
      }, (err) => {
       this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.toastr.error("Permission Denied"); 
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.toastr.error("Permission Denied");
    }
 
    
   }
   
  createTimeSheetData(): void {
    this.spinner.show();
    const fromActualdate=this.createTimesheet.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createTimesheet.get('throughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
     const requestData ={
   "approvedByUserLoginId": this.createTimesheet.value.approvedByUserLoginID,
  "clientPartyId": this.createTimesheet.value.clientPartyID,
  "comments":this.createTimesheet.value.comments,
  "fromDate":  this.actualfromdate,
  "partyId": this.createTimesheet.value.party,
  "statusId": this.createTimesheet.value.status,
  "thruDate":this.actualthroughdate
       
         
     }
     this.myContactsService.createTimeSheetData(requestData).subscribe((res: any) => {
      
       if (res.success) {
         this.toastr.success("Successfully Created");
         this.spinner.hide();
         this.createTimesheet.reset();
         this.closebutton1.nativeElement.click();
         this.timesheetId = res.data.timesheetId;
         this._Router.navigate(["/psa/work-effort/timesheet-view/EditTimesheet"],
         { queryParams: { timesheetId: this.timesheetId} })
        
       }
     }, (err) => {
      this.spinner.hide();
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
  }
  
  getMyTimesheets(){
    this.spinner.show();
    this.myContactsService.getMyTimesheets().subscribe((res:any)=> {
      this.MyTimesheets = res.data;
      this.MyTimesheetId = res.data[0].timesheet.timesheetId;
      this.spinner.hide();
    
    })   
   }
  getMyTime(){
    this.spinner.show();
    this.myContactsService.getMyTime().subscribe((res:any)=> {
      this.MyTimedata = res.data[0].mMyTime;
      this.spinner.hide();
    
    })   
   }
   getMyRates(){
    this.spinner.show();
    this.myContactsService.getMyRates().subscribe((res:any)=> {
      this.MyRatesdata = res.data[0].mMyRatess;
      this.spinner.hide();
    
    })   
   }
  TaskList(){
    this._Router.navigate(["/psa/work-effort/task-list"])
  }
  Calendar(){
    this._Router.navigate(["/psa/work-effort/calendar"])
  }
  MyTime(){
    this._Router.navigate(["/psa/work-effort/my-time-effort"])
  }
  RequestList(){
    this._Router.navigate(["/psa/work-effort/request-list-effort"])
  }
  WorkEffort(){
    this._Router.navigate(["/psa/work-effort/work-effort-effort"])
  }
  Timesheet(){
    this._Router.navigate(["/psa/work-effort/timesheet-effort"])
  }
  SubmittedJobs(){
    this._Router.navigate(["/psa/work-effort/submitted-jobs"])
  }
  iCalendar(){
    this._Router.navigate(["/psa/work-effort/icalendar"])
  }
 

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
  getWorkEffortRate() {
    this.spinner.show();
    this.addSkillService.getWorkEffortRate().subscribe(res => {
      this.WorkEffortRate = res.data[0].workEffortRate;
      this.spinner.hide();
      for (const value of this.WorkEffortRate) {
        this.WorkEffortRateArray.push({
          label: value.rateTypeId,
          value: value.periodTypeId

        })
      }
    })
  }
  
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   }
}
