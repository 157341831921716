<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Routing Task Association

                </span>
                <span class="color-black pl-1" > >Add New Routing Task Association

                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''"  ><a>
                        Add Routing Task Association

                    </a></li>
         
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createRouteAssoc">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group formRightSS"  >
                                                <label for="exampleInputEmail1">
                                                    Routing Task ID<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <p-dropdown  placeholder="Enter Routing Task ID" filter="true"
                                            formControlName="RoutingTaskID"
                                            [options]="taskListArray" optionlabel="label" >
                                                            
                                            </p-dropdown>    

                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    From Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Routing Name"
                                            formControlName="FromDate" >
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group formRightSS"  >
                                                <label for="exampleInputEmail1">
                                                    Through Date</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="date"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Description"
                                                formControlName="ThroughDate" >
                                                

                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    Sequence Num<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Sequence Num"
                                            formControlName="SequenceNum" >
                                          
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 34%;">
                               
                                <button type="submit"  (click)="onSubmitExisting()"     class="btn btn-secondary submit-btn">Add a existing Routing Task</button>
                               &nbsp; <button type="submit"  (click)="onSubmitCopy()"     class="btn btn-secondary submit-btn">Copy a Roting Task and add it</button>
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
