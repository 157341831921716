import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { ProductService } from '../new-products/product.service';

@Component({
  selector: 'app-create-supplier-product',
  templateUrl: './create-supplier-product.component.html',
  styleUrls: ['./create-supplier-product.component.css']
})
export class CreateSupplierProductComponent implements OnInit {
  show: boolean;
  activeCategory=2;
  product: any;
  supplierPrefOrder: any;
  supplierPrefOrderArray: any[] = [];
  drop: { label: string; value: string; }[];
  currencyArray: any[] = [];
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  roleTypeId: string;
  supplierArray: any[] = [];
  quantityUomIDArray: any[] = [];
  createSupplierForm: FormGroup;
  actualfromdate: string;
  actualTodate: string;
  availableFromDate: any;
  currencyUomId: any;
  minimumOrderQuantity: any;
  partyId: any;
  productId: any;
  getEstimateFrom: string;
  getEstimateTo: string;
  Products: any;
  ProductsArray: any[]=[];
  url: string;
  @Output() supplierModalClosed: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closebutton') closebutton;
  productIdGet: any;
  SupplierByProductId: any;

  constructor(readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly _ProductService: ProductService,
  ) {
    this.url="catalog/products/detail-product-summary"
    this.drop = [{
      label: 'Y',
      value: 'Y'
    },
    {
      label: 'N',
      value: 'N'
    }]
    this.createSupplierForm = this._FormBuilder.group({
      agreementId: [''],
      agreementItemSeqId: [''],
      availableFromDate: ['',[Validators.required]],
      availableThruDate: [''],
      canDropShip: [''],
      currencyUomId: ['',[Validators.required]],
      lastPrice: ['',[Validators.required]],
      minimumOrderQuantity: ['',[Validators.required]],
      orderQtyIncrements: [''],
      partyId: ['',[Validators.required]],
      quantityUomId: [''],
      shippingPrice: [''],
      standardLeadTimeDays: [''],
      supplierPrefOrderId: [''],
      supplierProductId: ['',[Validators.required]],
      supplierProductName: [''],
      supplierRatingTypeId: [''], unitsIncluded: [''], comments: ['']
    });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.data.subscribe(data => {
      console.log("dumbres",data);
     
      this.productIdGet = data.product;
      console.log("dunbid",this.productIdGet.productId);
    });
    
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.product = params["product"];
      this.availableFromDate = params["availableFromDate"];
      this.currencyUomId = params["currencyUomId"];
      this.minimumOrderQuantity = params["minimumOrderQuantity"];
      this.partyId = params["partyId"];
      this.productId = params["productId"];


    });
    if(this.product && this.partyId){
      this.show=true;
      this.editSupplierProductInitialData();
      
 
    }
    else{
      this.show=false;
    }
    this.getSupplierProductInitialData();
    this.getCurrency();
    this.getCustomerList();
    this.quantitySuppierUomId();
    this.getProducts();
  }
  getProducts() {
    this.spinner.show();
    const req = {
      "internalName": "",
      "internalNameSearchType": "Contains",
      "productId": "",
      "productIdSearchType": "Contains"
     

    }
    this.accountsService.getProducts(this.finSize, req).subscribe((res: any) => {

      this.Products = res.data;
      this.spinner.hide();
      for (const value of this.Products) {
        this.ProductsArray.push({
          label: value.productName,
          value: value.productId
        })
      }


    })
   
   
  }
  editSupplierProductInitialData() {
    this.spinner.show();
      const requestData = {

        "availableFromDate":  this.availableFromDate ,
        "currencyUomId": this.currencyUomId,
        "minimumOrderQuantity": this.minimumOrderQuantity ,
        "partyId":  this.partyId ,
        "productId": this.productId

      }

      this._ProductService.editSupplierProductInitialData(requestData).subscribe((res: any) => {
        setTimeout(() => {
          const fromdate=res.data.availableFromDate
          this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
          const toEstdate=res.data.availableThruDate
          this.getEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
          const formValue = this.createSupplierForm;
          formValue.patchValue({
            agreementId: res.data.agreementId,
            agreementItemSeqId: res.data.agreementItemSeqId,
            availableFromDate: this.getEstimateFrom,
            availableThruDate:this.getEstimateTo,
            canDropShip: res.data.canDropShip,
            currencyUomId: res.data.currencyUomId,
            lastPrice:res.data.lastPrice ,
            minimumOrderQuantity: res.data.minimumOrderQuantity,
            orderQtyIncrements:res.data.orderQtyIncrements,
            partyId:res.data.partyId,
            quantityUomId:res.data.quantityUomId ,
            shippingPrice:res.data.shippingPrice ,
            standardLeadTimeDays: res.data.standardLeadTimeDays,
            supplierPrefOrderId: res.data.supplierPrefOrderId,
            supplierProductId:res.data.supplierProductId ,
            supplierProductName: res.data.supplierProductName,
            supplierRatingTypeId:res.data.supplierRatingTypeId ,
            unitsIncluded:res.data.unitsIncluded ,
            comments:res.data.comments
          })
        }, 3000);
        this.spinner.hide();
      }, (err) => {
       
      });
     
    
  }
  quantitySuppierUomId() {
    this.spinner.show();
    this._ProductService.quantitySuppierUomId().subscribe(res => {
      const quantityUomID = res.data.quantityUomID;
      this.spinner.hide();
      for (const value of quantityUomID) {
        this.quantityUomIDArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
   
  }
  getCustomerList() {
    this.spinner.show();
    this.roleTypeId = "SUPPLIER"
    this._ProductService.getCustomerList(this.roleTypeId).subscribe(res => {
      const supplier = res.data;
      this.spinner.hide();
      for (const value of supplier) {
        this.supplierArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }

    })
   
  }
  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
   
  }
  getSupplierProductInitialData() {
    this.spinner.show();
    this._ProductService.getSupplierProductInitialData().subscribe((res: any) => {
      this.supplierPrefOrder = res.data.supplierPrefOrder;
      this.spinner.hide();
      for (const value of this.supplierPrefOrder) {
        this.supplierPrefOrderArray.push({
          label: value.description,
          value: value.supplierPrefOrderId
        })
      }
   
    })
   
  }

  onSubmit() {
    this.spinner.show();
    const fromActualdate = this.createSupplierForm.get('availableFromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const toActualdate = this.createSupplierForm.get('availableThruDate').value;
    this.actualTodate = this.datePipe.transform(toActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
    
      "agreementId": this.createSupplierForm.value.agreementId,
      "agreementItemSeqId":this.createSupplierForm.value.agreementItemSeqId,
      "availableFromDate": this.actualfromdate ,
      "availableThruDate": this.actualTodate ,
      "canDropShip": this.createSupplierForm.value.canDropShip,
      "currencyUomId": this.createSupplierForm.value.currencyUomId,
      "lastPrice": this.createSupplierForm.value.lastPrice,
      "minimumOrderQuantity": this.createSupplierForm.value.minimumOrderQuantity,
      "orderQtyIncrements":this.createSupplierForm.value.orderQtyIncrements,
      "partyId": this.createSupplierForm.value.partyId,
      "quantityUomId": this.createSupplierForm.value.quantityUomId,
      "shippingPrice": this.createSupplierForm.value.shippingPrice,
      "standardLeadTimeDays": this.createSupplierForm.value.standardLeadTimeDays,
      "supplierPrefOrderId": this.createSupplierForm.value.supplierPrefOrderId,
      "supplierProductId": this.createSupplierForm.value.supplierProductId,
      "supplierProductName": this.createSupplierForm.value.supplierProductName,
      "supplierRatingTypeId": this.createSupplierForm.value.supplierRatingTypeId,
      "unitsIncluded":this.createSupplierForm.value.unitsIncluded,
      "comments":this.createSupplierForm.value.comments

    }

    this._ProductService.postSupplierByProductId(this.productIdGet.productId, requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.modalClose();
        this.spinner.hide();
        this.router.navigate([this.url,this.product])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
   
  }
  onUpdate() {
    this.spinner.show();
    const fromActualdate = this.createSupplierForm.get('availableFromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const toActualdate = this.createSupplierForm.get('availableThruDate').value;
    this.actualTodate = this.datePipe.transform(toActualdate, "yyyy-MM-dd hh:mm:ss");
   
    const requestData = {
    
      "agreementId": this.createSupplierForm.value.agreementId,
      "agreementItemSeqId":this.createSupplierForm.value.agreementItemSeqId,
      "availableFromDate": this.actualfromdate ,
      "availableThruDate": this.actualTodate ,
      "canDropShip": this.createSupplierForm.value.canDropShip,
      "currencyUomId": this.createSupplierForm.value.currencyUomId,
      "lastPrice": this.createSupplierForm.value.lastPrice,
      "minimumOrderQuantity": this.createSupplierForm.value.minimumOrderQuantity,
      "orderQtyIncrements":this.createSupplierForm.value.orderQtyIncrements,
      "partyId": this.createSupplierForm.value.partyId,
      "quantityUomId": this.createSupplierForm.value.quantityUomId,
      "shippingPrice": this.createSupplierForm.value.shippingPrice,
      "standardLeadTimeDays": this.createSupplierForm.value.standardLeadTimeDays,
      "supplierPrefOrderId": this.createSupplierForm.value.supplierPrefOrderId,
      "supplierProductId": this.createSupplierForm.value.supplierProductId,
      "supplierProductName": this.createSupplierForm.value.supplierProductName,
      "supplierRatingTypeId": this.createSupplierForm.value.supplierRatingTypeId,
      "unitsIncluded":this.createSupplierForm.value.unitsIncluded,
      "comments":this.createSupplierForm.value.comments

    }

    this._ProductService.updateSupplierByProductId(this.product, requestData).subscribe((res: any) => {
    
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.modalClose();
        this.spinner.hide();
        this.router.navigate([this.url,this.product])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
   
  }

  modalClose(): void {
    this.closebutton.nativeElement.click();
    this.supplierModalClosed.emit();
  }

  ngOnDestroy(): void {
    this.spinner.hide();
      }
}

