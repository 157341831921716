import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PayablePaymentService } from 'src/app/accounting/accounting-ap/payables-payment/payables-payment.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-create-incoming-payment',
    templateUrl: './create-incoming-payment.component.html',
    styleUrls: ['./create-incoming-payment.component.css']
})
export class CreateIncomingPaymentComponent implements OnInit {
    activeCategory = 2;
    array = [
        {
            label: 'Your Company Name',
            value: 'Company'
        }
    ];
    finAccountIds: any[];
    partyIds: any[];
    paymentTypeList: any[];
    payablePaymentForm: FormGroup;
    editMode: boolean;
    paymentId: string;
    paymentMethods: any[];
    GIAccounts: any[];
    currencyUomID: any[];
    paymentValue: any;
    constructor(
        readonly _GlobalResourceService: GlobalResourceService,
        readonly spinner: NgxSpinnerService,
        readonly _PayablePaymentService: PayablePaymentService,
        readonly _FormBuilder: FormBuilder,
        readonly  _Router: Router,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _ToastrService: ToastrService
    ) {
        this.partyIds = [];
        this.paymentTypeList = [];
        this.payablePaymentForm = this._FormBuilder.group({
            amount: ['', [Validators.required]],
            comments: [''],
            overrideGlAccountId: [''],
            partyIdFrom: ['', [Validators.required]],
            partyIdTo: ['Company'],
            paymentMethodId: [''],
            paymentRefNum: [''],
            paymentTypeId: [''],
            actualCurrencyAmount: [''],
            actualCurrencyUomId: [''],
            currencyUomId: [''],
            effectiveDate: [''],
            finAccountTransId: [''],
            statusId: [''],
            finAccountId: ['']
        });
        this.finAccountIds = [];
        this.paymentMethods = [];
        this.GIAccounts = [];
        this.paymentId = '';
        this.editMode = false;
        this.currencyUomID = [];
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.paymentId = this._ActivatedRoute.snapshot.queryParams.paymentId;
        if (this.paymentId) {
            this.editMode = true;
            this._PayablePaymentService.getPaymentById(this.paymentId)
                .then(data => {
                    if (data.data.length) {
                        this.paymentValue = data.data[0];
                        this.payablePaymentForm.patchValue({
                            ...data.data[0], ...{
                                effectiveDate: new Date(data.data[0].effectiveDate),
                                finAccountTransId: data.data[0].financialAccountTransaction.finAccountTransId
                            }
                        });
                    }
                });
        }
        this.getPartyIds();
        this.getPaymentType();
        this.getGIAccountsAndPaymentMethodIds();
        this.getCurrency();
        this.getMethodIds();
    }
    getCurrency(): void {
        this.spinner.show();
        this._GlobalResourceService.getCurrency()
            .then(data => {
                this.spinner.hide();
                this.currencyUomID = data.data.currencyList.map(value => {
                    return {
                        label: value.description,
                        value: value.uomId
                    };
                });
            });
           
    }
    getMethodIds(): void {
        this.spinner.show();
        this._PayablePaymentService.getIncomingMethodType('Company')
            .then(data => {
                this.spinner.hide();
                this.paymentMethods = data.data.paymentMethodId.map(value => {
                    return {
                        label: value.description,
                        value: value.paymentMethodId
                    };
                });
            });
           
    }
    getGIAccountsAndPaymentMethodIds(): void {
        this.spinner.show();
        this._PayablePaymentService.getGIAccountAndPaymentMethod()
            .then(data => {
                if (data) {
                    this.spinner.hide();
                    this.GIAccounts = data.data.glAccounts.map(value => {
                        return {
                            label: value.glAccountId,
                            value: value.glAccountId
                        };
                    });
                }
            });
           
    }
    getPartyIds(): void {
        this.spinner.show();
        this._GlobalResourceService.getPartyGroups()
            .then(data => {
                this.spinner.hide();
                this.partyIds = data.data.PersonsGroups.map(value => {
                    return {
                        label: value.partyId,
                        value: value.partyId
                    };
                });
            });
           
    }
    getPaymentType(): void {
        this.spinner.show();
        this._PayablePaymentService.getIncomingPaymentType()
            .then(data => {
                if (data) {
                    this.spinner.hide();
                    this.paymentTypeList = data.data.paymentType.map(value => {
                        return {
                            label: value.description,
                            value: value.paymentTypeId
                        };
                    });
                    this.finAccountIds = data.data.finAccountId.map(value => {
                        return {
                            label: value.finAccountName,
                            value: value.finAccountId
                        };
                    });
                }
            });
           
    }
    reset(): void {
        this.payablePaymentForm.reset();
        this._Router.navigate(['/financial/receiveable/payments']);
    }
    update(): void {
        this.spinner.show();
        if (this.payablePaymentForm.valid) {
            const {
                actualCurrencyAmount,actualCurrencyUomId,amount,comments,currencyUomId,effectiveDate,finAccountTransId,
                overrideGlAccountId,partyIdFrom,partyIdTo,paymentMethodId,paymentRefNum,paymentTypeId,
                statusId
            } = this.paymentValue;
            this._PayablePaymentService.updatePayment(this.paymentId, {
                ...{
                    actualCurrencyAmount,actualCurrencyUomId,amount,comments,currencyUomId,effectiveDate,finAccountTransId,
                    overrideGlAccountId,partyIdFrom,partyIdTo,
                    paymentMethodId,paymentRefNum,paymentTypeId,
                    statusId
                }, ...this.payablePaymentForm.value, ...{
                    effectiveDate: moment(this.payablePaymentForm.value.effectiveDate).format('YYYY-MM-DD HH:mm:ss')
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Updated');
                        this.spinner.hide();
                        this.payablePaymentForm.reset();
                        this._Router.navigate(['/financial/receiveable/payments']);
                    }
                });
        }
      
    }
    submit(): void {
        this.spinner.show();
        if (this.editMode) {
            this.update();
        } else {
            if (this.payablePaymentForm.valid) {
                const { amount, comments,
                    finAccountId, overrideGlAccountId,
                    partyIdFrom, partyIdTo,
                    paymentMethodId, paymentRefNum,
                    paymentTypeId
                } = this.payablePaymentForm.value;
                this._PayablePaymentService.createIncomingPayment({
                    amount, comments, finAccountId,
                    overrideGlAccountId, partyIdFrom,
                    partyIdTo, paymentMethodId,
                    paymentRefNum, paymentTypeId
                })
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Created');
                            this.payablePaymentForm.reset();
                            this.spinner.hide();
                            this._Router.navigate(['/financial/receiveable/payments']);
                        }
                    });
            }
        }
     
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}