import { Component, OnInit } from '@angular/core';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import {TreeNode} from 'primeng/api';

@Component({
  selector: 'app-gl-account-summary',
  templateUrl: './gl-account-summary.component.html',
  styleUrls: ['./gl-account-summary.component.css']
})
export class GlAccountSummaryComponent implements OnInit {
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  glAccountClass: any;
  glAccountClassArray: any[] = [];
  glAccountType: any;
  glAccountTypeArray: any[] = [];
  glResourceType: any;
  glResourceTypeArray: any[] = [];
  glXbrlClass: any;
  glXbrlClassArray: any[] = [];
  parentGlAccount: any;
  parentGlAccountArray: any[] = [];
  search:string=""
  files1: TreeNode[];
  readonly employessSize={
    pageNo:this.pageNo,
    pageSize:this.pageSize,

  }
  activeCategory=2;
  navigateAccount: TreeNode[];
  giId: any;
  createGlobalSettingForm: FormGroup;
  formValue=false;
  giIdNew: any;
  glAccountId: any;
  ProductId: any;
  ProductIdArray: any[]=[];
  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
   
    readonly _FormBuilder: FormBuilder,
   ) {
      this.createGlobalSettingForm = this._FormBuilder.group({
        accountCode: [''],
        accountName: [''],
        description: [''],
        externalId: [''],
        glAccountClassId: [''],
        glAccountId: [''],
        glAccountTypeId: [''],
        glResourceTypeId: [''],
        glXbrlClassId: [''],
        parentGlAccountId: [''],
        productId: ['']
      });
    
    }
     
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.formValue=true
    this._ActivatedRoute.queryParams.subscribe(params=> [
      this.glAccountId = params["glAccountId"],
    ])
    this.getGlAccountSummaryNew();
    this.getNavigateAccount();
    this.getGlAccountInitialData();
  }
  getProductId() {
    this.spinner.show();
    this.accountsService.getProductId().subscribe(res => {
      this.ProductId = res.data[0].Product;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })
  }

  cancelSubmitA(){
    this.router.navigate(["/financial/global-gl-setting-list"])
  }  
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {
      this.spinner.hide();
      this.glAccountClass = res.data.glAccountClass;
      this.glAccountType = res.data.glAccountType;
      this.glResourceType = res.data.glResourceType;
      this.glXbrlClass = res.data.glXbrlClass;
      this.parentGlAccount = res.data.parentGlAccount;
      for (const value of this.glAccountClass) {
        this.glAccountClassArray.push({
          label: value.description,
          value: value.glAccountClassId
        })
      }
      for (const value of this.glAccountType) {
        this.glAccountTypeArray.push({
          label: value.description,
          value: value.glAccountTypeId
        })
      }
      for (const value of this.glResourceType) {
        this.glResourceTypeArray.push({
          label: value.description,
          value: value.glResourceTypeId
        })
      }
      for (const value of this.glXbrlClass) {
        this.glXbrlClassArray.push({
          label: value.description,
          value: value.glXbrlClassId
        })
      }
      for (const value of this.parentGlAccount) {
        this.parentGlAccountArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }


    })
   
  }
  getNavigateAccount() {
    this.spinner.show();
    this.accountsService.getNavigateAccount().subscribe(res => {
      this.navigateAccount = res.data;
      this.spinner.hide();
    })
   
  }
  nodeSelect(event){
    
    this.giId=event.node.data;
    this.giIdNew=event.node.data;
    this.formValue=true
    this.router.navigate(["/financial/gl-account-summary"],{queryParams:{ glAccountId: this.giIdNew }});
    setTimeout(() => {
      this.getGlAccountSummaryNew();      
    }, 1000);
  }
  getGlAccountSummaryNew() {
    this.spinner.show();
    this.accountsService.getGlAccountSummary(this.glAccountId).subscribe(res => {
      
      setTimeout(() => {
        const formValue = this.createGlobalSettingForm;
        formValue.patchValue({
          accountCode:  res.data.accountCode,
          accountName:  res.data.accountName,
          description:  res.data.description,
          externalId:   res.data.externalId,
          glAccountClassId:   res.data.glAccountClassId,
          glAccountId: this.giId?this.giId:this.glAccountId,
          glAccountTypeId:   res.data.glAccountTypeId,
          glResourceTypeId:  res.data.glResourceTypeId,
          glXbrlClassId:   res.data.glXbrlClassId,
          parentGlAccountId:  res.data.parentGlAccountId,
          productId:  res.data.productId
        })
      
      }, 3000);
      this.spinner.hide();
    
    })
   
  }
  
  getGlAccountSummary() {
    this.spinner.show();
    this.accountsService.getGlAccountSummary(this.giId).subscribe(res => {
      
      setTimeout(() => {
        const formValue = this.createGlobalSettingForm;
        formValue.patchValue({
          accountCode:  res.data.accountCode,
          accountName:  res.data.accountName,
          description:  res.data.description,
          externalId:   res.data.externalId,
          glAccountClassId:   res.data.glAccountClassId,
          glAccountId: this.giId,
          glAccountTypeId:   res.data.glAccountTypeId,
          glResourceTypeId:  res.data.glResourceTypeId,
          glXbrlClassId:   res.data.glXbrlClassId,
          parentGlAccountId:  res.data.parentGlAccountId,
          productId:  res.data.productId
        })
      
      }, 3000);
      this.spinner.hide();
    
    })
   
  }
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "accountCode": this.createGlobalSettingForm.value.accountCode,
      "accountName": this.createGlobalSettingForm.value.accountName,
      "description": this.createGlobalSettingForm.value.description,
      "externalId": this.createGlobalSettingForm.value.externalId,
      "glAccountClassId": this.createGlobalSettingForm.value.glAccountClassId,
      "glAccountId": this.giId?this.giId:this.glAccountId,
      "glAccountTypeId": this.createGlobalSettingForm.value.glAccountTypeId,
      "glResourceTypeId": this.createGlobalSettingForm.value.glResourceTypeId,
      "glXbrlClassId": this.createGlobalSettingForm.value.glXbrlClassId,
      "parentGlAccountId": this.createGlobalSettingForm.value.parentGlAccountId,
      "productId": this.createGlobalSettingForm.value.productId,
    }
    this.accountsService.updateChartAccountData(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.router.navigate(['/financial/global-gl-setting-list']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  cancelSubmit(){
    this.router.navigate(['/financial/global-gl-setting-list']);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
