import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
    selector: 'app-create-new-item-product',
    templateUrl: './create-new-item-product.component.html'
})
export class CreateNewItemProductComponent implements OnInit {
    agreementType: string;
    activeCategory=1;

    agreement: {
        agreementId: string,
        agreementItemSeqId: string
    };
    purchasedItemProductForm: FormGroup;
    salesItemProductForm: FormGroup;
    isPurchasedAgreement: boolean;
    productIds: any[];
    supplierRefOrderIds: any[];
    quantityUomIds: any[];

    product: any;
    array = [
        {
            label: 'Y',
            value: 'Y'
        },
        {
            label: 'N',
            value: 'N'
        }
    ];
    productId: string;
    editMode: boolean;
    constructor(
        readonly _MainCatalogService: MainCatalogService,readonly _ActivatedRoute: ActivatedRoute, readonly  _Router: Router,readonly spinner: NgxSpinnerService,
        readonly _FormBuilder: FormBuilder,readonly _AccountingApService: AccountingApService,readonly _ToastrService: ToastrService
    ) {
        this.agreementType = '';
        this.agreement = {
            agreementId: '',
            agreementItemSeqId: ''
        };
        this.productId = '';
        this.editMode = false;
        this.purchasedItemProductForm = this._FormBuilder.group({
            agreementId: [''],agreementItemSeqId: [''],availableFromDate: [''],availableThruDate: [''],
            canDropShip: ['Y'],comments: [''],currencyUomId: [''],lastPrice: [''],
            minimumOrderQuantity: [''],
            orderQtyIncrements: [''],
            partyId: [''],
            productId: [''],
            quantityUomId: [''],
            shippingPrice: [''],
            standardLeadTimeDays: [''],
            supplierPrefOrderId: [''],
            supplierProductId: [''],
            supplierProductName: [''],
            supplierRatingTypeId: [''],
            unitsIncluded: ['']
        });
        this.salesItemProductForm = this._FormBuilder.group({
            agreementId: [''],
            agreementItemSeqId: [''],
            price: [''],
            productId: ['', [Validators.required]]
        });
        this.isPurchasedAgreement = false;
        this.productIds = [];
        this.supplierRefOrderIds = [];
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
        this._ActivatedRoute.queryParams.subscribe(data => {
            this.agreement = {
                agreementId: data.agreementId,
                agreementItemSeqId: data.agreementItemSeqId};
            this.productId = data.productId;});
        if (this.productId) {
            this.editMode = true;
            if (this.isPurchasedAgreement) {
                const { availableFromDate, currencyUomId, minimumOrderQuantity, partyId } = JSON.parse(window.localStorage.getItem('itemValue'));
                this._AccountingApService.getItemProductPurchasedById({
                    ...this.agreement, ...{
                        availableFromDate, currencyUomId, minimumOrderQuantity, partyId, productId: this.productId
                    }})
                    .then(data => {
                        if (data) {
                            this.product = data.data;
                            this.purchasedItemProductForm.patchValue({
                                ...data.data, ...{
                                    availableFromDate: data.data.availableFromDate ? new Date(data.data.availableFromDate) : '',
                                    availableThruDate: data.data.availableThruDate ? new Date(data.data.availableThruDate) : '',
                                }}); }});} else {
                this._AccountingApService.getItemProductSaleById(this.agreement.agreementId, this.agreement.agreementItemSeqId, this.productId)
                    .then(data => {
                        this.product = data.data; this.salesItemProductForm.patchValue(data.data);
                    });} }
        this._ActivatedRoute.data.subscribe(data => {
            this.isPurchasedAgreement = data.agreement;});
        this.getProductIds();this.getQuantityUomIds();this.getSupplierRefOrderIds();
    }
    getProductIds(): void {
        this.spinner.show();
        this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 200 },
            { internalName: '', internalNameSearchType: 'Contains', productId: '', productIdSearchType: 'Contains' }
        )
            .then(data => {
                this.spinner.hide();
                this.productIds = data.data.map(value => {
                    return {
                        label: value.productId,
                        value: value.productId
                    };
                });
            });
            
    }
    getSupplierRefOrderIds(): void {
        this.spinner.show();
        this._AccountingApService.supplierPrefOrderId()
            .then(data => {
                this.spinner.hide();
                this.supplierRefOrderIds = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.supplierPrefOrderId
                    };
                });
            });
           
    }
    getQuantityUomIds(): void {
        this.spinner.show();
        this._AccountingApService.getQuantityUomId()
            .then(data => {
                this.spinner.hide();
                this.quantityUomIds = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.uomId
                    };
                });
            });
          
    }

    updateSale(): void {
        this.spinner.show();
        if (this.salesItemProductForm.valid) {
            this._AccountingApService.updateAgreementItemProductSale({
                ...this.product, ...this.salesItemProductForm.value, ...{
                    agreementId: this.agreement.agreementId,
                    agreementItemSeqId: this.agreement.agreementItemSeqId,
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Updated');
                        this.salesItemProductForm.reset();
                        this.spinner.hide();
                        this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/agreement-summary-item`], { queryParams: { agreementId: this.agreement.agreementId, agreementItemSeqId: this.agreement.agreementItemSeqId } });
                    }
                });
        }
    }
    submitSale(): void {
        this.spinner.show();
        if (this.editMode) {
            this.updateSale();
        } else {
            if (this.salesItemProductForm.valid) {
                this._AccountingApService.createAgreementItemProductSale({
                    ...this.salesItemProductForm.value, ...{
                        agreementId: this.agreement.agreementId,
                        agreementItemSeqId: this.agreement.agreementItemSeqId,
                    }
                })
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Created');
                            this.spinner.hide();
                            this.salesItemProductForm.reset();
                            this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/agreement-summary-item`], { queryParams: { agreementId: this.agreement.agreementId, agreementItemSeqId: this.agreement.agreementItemSeqId } });
                        }
                    });
            }
        }
      
    }
    updatePurchased(): void {
        this.spinner.show();
        this._AccountingApService.updateAgreementItemProductPurchased({
            ...this.product, ...this.purchasedItemProductForm.value, ...{

                agreementId: this.agreement.agreementId,
                agreementItemSeqId: this.agreement.agreementItemSeqId,
                availableFromDate: this.purchasedItemProductForm.value.availableFromDate ? moment(this.purchasedItemProductForm.value.availableFromDate).format('YYYY-MM-DD HH:mm:ss') : '',
                availableThruDate: this.purchasedItemProductForm.value.availableThruDate ? moment(this.purchasedItemProductForm.value.availableThruDate).format('YYYY-MM-DD HH:mm:ss') : '',
            }
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Created');
                    this.spinner.hide();
                    this.purchasedItemProductForm.reset();
                    this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/agreement-summary-item`], { queryParams: { agreementId: this.agreement.agreementId, agreementItemSeqId: this.agreement.agreementItemSeqId } });
                }
            });
        
    }


    submitPurchased(): void {
        this.spinner.show();
        if (this.editMode) {
            this.updatePurchased();
        } else {
            this._AccountingApService.createAgreementItemProductPurchased({
                ...this.purchasedItemProductForm.value, ...{
                    currencyUomId: window.localStorage.getItem('currency'),
                    partyId: window.localStorage.getItem('toPartyId'),
                    agreementId: this.agreement.agreementId,
                    agreementItemSeqId: this.agreement.agreementItemSeqId,
                    availableFromDate: this.purchasedItemProductForm.value.availableFromDate ? moment(this.purchasedItemProductForm.value.availableFromDate).format('YYYY-MM-DD HH:mm:ss') : '',
                    availableThruDate: this.purchasedItemProductForm.value.availableThruDate ? moment(this.purchasedItemProductForm.value.availableThruDate).format('YYYY-MM-DD HH:mm:ss') : '',
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.purchasedItemProductForm.reset();
                        this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/agreement-summary-item`], { queryParams: { agreementId: this.agreement.agreementId, agreementItemSeqId: this.agreement.agreementItemSeqId } });
                    }
                });
        }
     
    }
    reset(): void {
        this.purchasedItemProductForm.reset();
        this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/agreement-summary-item`], { queryParams: { agreementId: this.agreement.agreementId, agreementItemSeqId: this.agreement.agreementItemSeqId } });
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}