import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Location } from '@angular/common';

@Component({
  selector: 'app-dataresource-detail-page',
  templateUrl: './dataresource-detail-page.component.html',
  styleUrls: ['./dataresource-detail-page.component.css']
})
export class DataresourceDetailPageComponent implements OnInit {
  activeCategory = 1;
  activeCategoryMain = 1;
  name = 'Angular ' ;
  editor = ClassicEditor; 
  data: any;
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  fileData: File;

  addAttributeForm: FormGroup;
  productFeaturesForm: FormGroup;
  rolesForm: FormGroup;
  createContentDataResourceForm: FormGroup;
  electronicTextForm: FormGroup;
  htmlForm: FormGroup;
  ProductFeatureIdList: any;
  ProductFeatureIdListArray: any[] = [];
  ContentRoleTypeId: any;
  ContentRoleTypeIdArray: any[] = [];
  partyIdArray: any[] = [];
  personId: any;
  dataResourceId: any;
  fsmTechnicalById: any;
  addDataResourceAttributeAll: any;
  addDataResourceAttributeById: any;
  updateContentDataResourceForm: FormGroup;
  showAttri: boolean;
  productAttri: any;
  addDataResourceRoleAll: any;
  showRole: boolean;
  productRole: any;
  dataResourceRoleById: any;
  dataResourceById: any;
  dataResourceSummary: any;
  mimeTypeIdData: any;
  dataResourceStatusIdArray: any[]=[];
  dataResourceStatusId: any;
  mimeTypeIdDataArray: any[]=[];
  dataCategoryArray:any[]=[];
  dataCategory: any;
  DataResourceTypeArray:any[]=[];
  DataResourceType: any;
  dataTemplateType: any;
  dataTemplateTypeArray: any[]=[];
  localStringArray: any[]=[];
  localString: any;
  CharacterSetId: any;
  CharacterSetIdArray: any[]=[];
  yesNoArray: { label: string; value: string; }[];
  electronicTextById: any;
  dataResourceProductFeature: any;
  mimetypeId: any;
  uploadFileForm: FormGroup;

  
  mycontent: string;
  uploadImageData: any;
  dataResourceTypeID: any;
  showButton: boolean;
  constructor(
    readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    readonly addSkillService: AddSkillService,
    private _location: Location,
    
  ) {
    this.addAttributeForm = this._FormBuilder.group({
      dataResourceID: "",
      attrName: "",
      attrValue: "",
      attrDescription: "",
    });
    this.productFeaturesForm = this._FormBuilder.group({
      dataResourceID: "",
      productFeatureID: "",
    });
    this.electronicTextForm = this._FormBuilder.group({
      dataResourceID: "",
      textData: "",
    });
    this.uploadFileForm = this._FormBuilder.group({
      dataResourceID: "",
      uploadFile: "",
      uploadedFile:""
    });
    this.htmlForm = this._FormBuilder.group({
      dataResourceID: "",
      textData: "",
    });
    this.rolesForm = this._FormBuilder.group({
      dataResourceID: "",
      partyID: "",
      roleTypeID: "",
      fromDate: "",
      throughDate: "",
    });
    this.yesNoArray =[
      {
        label:"Y",
        value:"Y"
      },
      {
        label:"N",
        value:"N"
      }
    ]
    this.createContentDataResourceForm = this._FormBuilder.group({
      dataResourceID: "",
      dataResourceTypeID: "",
      dataTemplateTypeID: "",
      dataCategoryID: "",
      dataSourceID: "",
      dataResourceName: "",
      localeString: "",
      mimeTypeID: "",
      characterSetID: "",
      objectInfo: "",
      surveyID: "",
      surveyResponseID: "",
      relatedDetailID: "",
      isPublic: "",
      createdDate: "",
      createdByUser: "",
      lastModifiedDate: "",
      lastModifiedByUser: "",
    })
    this.updateContentDataResourceForm=this._FormBuilder.group({
      dataResourceID:"",
      dataResourceTypeID:"",
      dataTemplateTypeID:"",
      dataCategoryID:"",
      statusID:"",
      dataSourceID:"",
      dataResourceName:"",
      localeString:"",
      mimeTypeID:"",
      characterSetID:"",
      objectInfo:"",
      surveyID:"",
      surveyResponseID:"",
      relatedDetailID:"",
      isPublic:"",
      createdDate:"",
      createdByUser:"",
      lastModifiedDate:"",
      lastModifiedByUser:"",
    })
   }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.dataResourceId = params["dataResourceId"];
    });
    setTimeout(() => {
      const formValue = this.rolesForm;
      formValue.patchValue({
        dataResourceID: this.dataResourceId ,
      })
      const FeaturesformValue = this.productFeaturesForm;
      FeaturesformValue.patchValue({
        dataResourceID: this.dataResourceId ,
      })
      const electformValue = this.electronicTextForm;
      electformValue.patchValue({
        dataResourceID: this.dataResourceId ,
      })
      const  attformValue = this.addAttributeForm;
      attformValue.patchValue({
        dataResourceID: this.dataResourceId ,
      })
    }, 1000);
    this.getProductFeatureIdList()
    this.getContentRoleTypeId();
    this.getAddDataResourceAttributeById();
    this.getPartyId();
    this.getAddDataResourceAttributeAll();
    this.getPartyId();
    this.getDataResourceById();
    this.getAddDataResourceRoleById();
    this.getDataResourceByIdSummary();
    this.getMimeTypeIdData();
    this.getDataCategory();
    this.getDataResourceType();
    this.getDataResourceStatusId();
    this.getDataTemplateType();
    this.getCharacterSetData();
   // this.getLocalString();
    this.getElectronicTextById();
    this.getDataResourceProductFeature();
    this.getUploadImage();
  }
  generatePDFDataResource() {
    this.spinner.show();
 
    
    this.myContactsService.generatePDFDataResource(this.dataResourceId).subscribe(res => {
      const blob = new Blob([res], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "DATARESOURCE.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  } 
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  referTocontent(id){
    this._Router.navigate(['/content/content-page/content-detail-page'],{queryParams:{contentId:id}});
  }
  getUploadImage() {
    this.spinner.show();
    this.myContactsService.getUploadImage(this.dataResourceId).subscribe(res => {
      this.uploadImageData = res.data.getDataCategory[0];
      this.spinner.hide();
      setTimeout(() => {
        const formValue =  this.uploadFileForm;
        formValue.patchValue({
          dataResourceID: this.dataResourceId,
      uploadFile:  "",
      uploadedFile:this.uploadImageData.objectInfo,
        })
      }, 2000);
    })
  }



  fileProgressCom(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  uploadImage() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.myContactsService.uploadImage(formData,this.uploadFileForm.value.uploadedFile,this.dataResourceId, "IMAGE_OBJECT").subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.closebutton6.nativeElement.click();
        this.productFeaturesForm.reset();
        this.showButton = true;
        this.getDataResourceProductFeature();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }

  getProductFeatureIdList() {
    this.spinner.show();
    this.accountsService.getProductFeatureIdList().subscribe(res => {
      this.ProductFeatureIdList = res.data;
      this.spinner.hide();
      for (const value of this.ProductFeatureIdList) {
        this.ProductFeatureIdListArray.push({
          label: value.description,
          value: value.productFeatureId
        })
      }
    })
  }
  removeDataResourceProductFeature(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        const req = {
          "dataResourceId": product.dataResourceId,
          "productFeatureId":product.productFeatureId,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20"
        }
        this.myContactsService.removeDataResourceProductFeature(product).subscribe(res => {    
          this.getDataResourceProductFeature();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  createDataResourceProductFeature() {
    this.spinner.show();
    const requestData =
    {
      "dataResourceId": this.dataResourceId,
  "productFeatureId":this.productFeaturesForm.value.productFeatureID
    }
    this.myContactsService.createDataResourceProductFeature(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this.productFeaturesForm.reset();
        this.getDataResourceProductFeature();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
 
  getDataResourceProductFeature() {
    this.spinner.show();
    this.myContactsService.getDataResourceProductFeature(this.dataResourceId).subscribe(res => {
      this.dataResourceProductFeature = res.data.getDataResourceProductFeature;

      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.productFeaturesForm;
        formValue.patchValue({
          dataResourceID:    this.dataResourceId ,
          textData: this.electronicTextById.textData ,
        
        })
      }, 2000);
    })
  }

  getElectronicTextById() {
    this.spinner.show();
    this.myContactsService.getElectronicTextById(this.dataResourceId).subscribe(res => {
      this.electronicTextById = res.data.getElectronicTextById[0];

      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.electronicTextForm;
        formValue.patchValue({
          dataResourceID:    this.dataResourceId ,
          textData: this.electronicTextById.textData ,
        
        })
      }, 2000);
      setTimeout(() => {
        const form = this.htmlForm;
        form.patchValue({
          dataResourceID:    this.dataResourceId ,
          textData: this.electronicTextById.textData ,
        
        })
      }, 2000);
    })
  }
  updateHTMLText() {
    this.spinner.show();
    const requestData =
    {
      "dataResourceId": this.dataResourceId,
  "textData": this.htmlForm.value.textData

    }
    this.myContactsService.updateElectronicText(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.htmlForm.reset();
         this.getDataResourceByIdSummary();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateElectronicText() {
    this.spinner.show();
    const requestData =
    {
      "dataResourceId": this.dataResourceId,
  "textData": this.electronicTextForm.value.textData

    }
    this.myContactsService.updateElectronicText(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.electronicTextForm.reset();
         this.getDataResourceByIdSummary();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getCharacterSetData() {
    this.spinner.show();
    this.myContactsService.getCharacterSetData().subscribe(res => {
      this.CharacterSetId = res.data.getCharacterSet;
      this.spinner.hide();
      for (const value of this.CharacterSetId) {
        this.CharacterSetIdArray.push({
          label: value.description,
          value: value.characterSetId
        })
      }
    })
  }
  getLocalString() {
    this.spinner.show();
    this.myContactsService.getLocalString().subscribe(res => {
      this.localString = res.data[0].getLocalString;
      this.spinner.hide();
      for (const value of this.localString) {
        this.localStringArray.push({
          label: value.localeString,
          value: value.localeString
        })
      }
    })
  }
  getDataTemplateType() {
    this.spinner.show();
    this.myContactsService.getDataTemplateType().subscribe(res => {
      this.dataTemplateType = res.data.getDataTemplateType;
      this.spinner.hide();
      for (const value of this.dataTemplateType) {
        this.dataTemplateTypeArray.push({
          label: value.description,
          value: value.dataTemplateTypeId
        })
      }
    })
  }
  createDataResource() {
    this.spinner.show();
    const requestData =
    {
      "characterSetId": this.createContentDataResourceForm.value.characterSetID,
      "createdByUserLogin": this.createContentDataResourceForm.value.createdByUser,
      "createdDate": this.datePipe.transform(this.createContentDataResourceForm.value.createdDate, "yyyy-MM-dd HH:mm:ss"),
      "dataCategoryId": this.createContentDataResourceForm.value.dataCategoryID,
      "dataResourceId": this.createContentDataResourceForm.value.dataResourceID,
      "dataResourceName": this.createContentDataResourceForm.value.dataResourceName,
      "dataResourceTypeId": this.createContentDataResourceForm.value.dataResourceTypeID,
      "dataSourceId": this.createContentDataResourceForm.value.dataSourceID,
      "dataTemplateTypeId": this.createContentDataResourceForm.value.dataTemplateTypeID,
      "isPublic": this.createContentDataResourceForm.value.isPublic,
      "lastModifiedByUserLogin": this.createContentDataResourceForm.value.lastModifiedByUser,
      "lastModifiedDate": this.datePipe.transform(this.createContentDataResourceForm.value.lastModifiedDate, "yyyy-MM-dd HH:mm:ss"),
      "localeString": this.createContentDataResourceForm.value.localeString,
      "mimeTypeId": this.createContentDataResourceForm.value.mimeTypeID,
      "objectInfo": this.createContentDataResourceForm.value.objectInfo,
      "relatedDetailId": this.createContentDataResourceForm.value.relatedDetailID,
      "surveyId": this.createContentDataResourceForm.value.surveyID,
      "surveyResponseId": this.createContentDataResourceForm.value.surveyResponseID,
    }
    this.myContactsService.createDataResource(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.createContentDataResourceForm.reset();
        this._Router.navigate(["/content/data-resource/dataresource-detail-page"],
        {queryParams:{dataResourceId:this.dataResourceId}})
         this.getDataResourceByIdSummary();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getDataResourceType() {
    this.spinner.show();
    this.myContactsService.getDataResourceType().subscribe(res => {
      this.DataResourceType = res.data.getDataResourceType;
      this.spinner.hide();
      for (const value of this.DataResourceType) {
        this.DataResourceTypeArray.push({
          label: value.description,
          value: value.dataResourceTypeId
        })
      }
    })
  }
  getDataCategory() {
    this.spinner.show();
    this.myContactsService.getDataCategory().subscribe(res => {
      this.dataCategory = res.data.getDataCategory;
      this.spinner.hide();
      for (const value of this.dataCategory) {
        this.dataCategoryArray.push({
          label: value.categoryName,
          value: value.dataCategoryId
        })
      }
    })
  }
  getDataResourceStatusId() {
    this.spinner.show();
    this.myContactsService.getDataResourceStatusId().subscribe(res => {
      this.dataResourceStatusId = res.data.getDataResourceStatusId;
      this.spinner.hide();
      for (const value of this.dataResourceStatusId) {
        this.dataResourceStatusIdArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
  }
  getMimeTypeIdData() {
    this.spinner.show();
    this.myContactsService.getMimeTypeIdData().subscribe(res => {
      this.mimeTypeIdData = res.data.mimeTypeId;
      this.spinner.hide();
      for (const value of this.mimeTypeIdData) {
        this.mimeTypeIdDataArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      }
    })
  }
  getDataResourceByIdSummary() {
    this.spinner.show();
    this.myContactsService.getDataResourceByIdS(this.dataResourceId).subscribe(res => {
      this.dataResourceSummary = res.data[0];
      this.dataResourceTypeID = res.data[0].dataNode.dataResourceTypeId;
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.updateContentDataResourceForm;
        formValue.patchValue({
          dataResourceID: this.dataResourceSummary.dataNode.dataResourceId ,
          dataResourceTypeID: this.dataResourceSummary.dataNode.dataResourceTypeId ,
          dataTemplateTypeID: this.dataResourceSummary.dataNode.dataTemplateTypeId ,
          dataCategoryID: this.dataResourceSummary.dataNode.dataCategoryId ,
          statusID: this.dataResourceSummary.dataNode.statusId ,
          dataSourceID: this.dataResourceSummary.dataNode.dataSourceId ,
          dataResourceName: this.dataResourceSummary.dataNode.dataResourceName ,
          localeString: this.dataResourceSummary.dataNode.localeString ,
          mimeTypeID: this.dataResourceSummary.dataNode.mimeTypeId ,
          characterSetID: this.dataResourceSummary.dataNode.characterSetId ,
          objectInfo: this.dataResourceSummary.dataNode.objectInfo ,
          surveyID: this.dataResourceSummary.dataNode.surveyId ,
          surveyResponseID: this.dataResourceSummary.dataNode.surveyResponseId ,
          relatedDetailID: this.dataResourceSummary.dataNode.relatedDetailId ,
          isPublic: this.dataResourceSummary.dataNode.isPublic ,
         // createdDate: this.datePipe.transform(this.dataResourceSummary.dataNode.lastModifiedDate,"yyyy-MM-dd hh:mm:ss")  ,
          createdByUser: this.dataResourceSummary.dataNode.createdByUserLogin ,
         // lastModifiedDate: this.datePipe.transform(this.dataResourceSummary.dataNode.lastModifiedDate,"yyyy-MM-dd hh:mm:ss")  ,
          lastModifiedByUser: this.dataResourceSummary.dataNode.lastModifiedByUserLogin ,
        })
      }, 2000);
    })
  }
  getDataResourceById() {
    this.spinner.show();
    this.myContactsService.getDataResourceById(this.dataResourceId).subscribe(res => {
      this.dataResourceById = res.data;
      this.spinner.hide();
    })
  }
  updateDataResource() {
    this.spinner.show();
    const requestData =
    {
      "characterSetId": this.updateContentDataResourceForm.value.characterSetID,
      "createdByUserLogin": this.updateContentDataResourceForm.value.createdByUser,
      "createdDate": this.datePipe.transform(this.updateContentDataResourceForm.value.createdDate, "yyyy-MM-dd HH:mm:ss"),
      "dataCategoryId": this.updateContentDataResourceForm.value.dataCategoryID,
      "dataResourceId": this.updateContentDataResourceForm.value.dataResourceID,
      "dataResourceName": this.updateContentDataResourceForm.value.dataResourceName,
      "dataResourceTypeId": this.updateContentDataResourceForm.value.dataResourceTypeID,
      "dataSourceId": this.updateContentDataResourceForm.value.dataSourceID,
      "dataTemplateTypeId": this.updateContentDataResourceForm.value.dataTemplateTypeID,
      "isPublic": this.updateContentDataResourceForm.value.isPublic,
      "lastModifiedByUserLogin": this.updateContentDataResourceForm.value.lastModifiedByUser,
      "lastModifiedDate": this.datePipe.transform(this.updateContentDataResourceForm.value.lastModifiedDate, "yyyy-MM-dd HH:mm:ss"),
      "localeString": this.updateContentDataResourceForm.value.localeString,
      "mimeTypeId": this.updateContentDataResourceForm.value.mimeTypeID,
      "objectInfo": this.updateContentDataResourceForm.value.objectInfo,
      "relatedDetailId": this.updateContentDataResourceForm.value.relatedDetailID,
      "surveyId": this.updateContentDataResourceForm.value.surveyID,
      "statusId": this.updateContentDataResourceForm.value.statusID,
      "surveyResponseId": this.updateContentDataResourceForm.value.surveyResponseID,

    }
    this.myContactsService.updateDataResource(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
        this.updateContentDataResourceForm.reset();
         this.getDataResourceByIdSummary();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  removeDataResourceAttribute(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        const req = {
          "attrName": product.attrName ,
          "dataResourceId": product.dataResourceId,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20"
        }
        this.myContactsService.removeDataResourceAttribute(product).subscribe(res => {    
          this.getAddDataResourceAttributeById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  addDataResourceRole() {
    this.spinner.show();
    const requestData =
    {
      "dataResourceId": this.dataResourceId,
      "fromDate": this.datePipe.transform(this.rolesForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "partyId": this.rolesForm.value.partyID,
      "roleTypeId": this.rolesForm.value.roleTypeID,
      "thruDate":this.datePipe.transform(this.rolesForm.value.throughDate, "yyyy-MM-dd HH:mm:ss"),
    }
    this.myContactsService.addDataResourceRole(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.rolesForm.reset();
        this.getAddDataResourceRoleById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateAddDataResourceRole() {
    this.spinner.show();
    const requestData =
    {
      "dataResourceId": this.rolesForm.value.dataResourceID,
      "fromDate": this.productRole.fromDate,
      "partyId": this.rolesForm.value.partyID,
      "roleTypeId": this.rolesForm.value.roleTypeID,
      "thruDate":this.datePipe.transform(this.rolesForm.value.throughDate, "yyyy-MM-dd HH:mm:ss"),
    }
    this.myContactsService.updateAddDataResourceRole(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.rolesForm.reset();
        this.getAddDataResourceRoleById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getAddDataResourceRoleAll() {
    this.spinner.show();
    this.myContactsService.getAddDataResourceRoleAll().subscribe(res => {
      this.addDataResourceRoleAll = res.data.getAddDataResourceRoleAll;
      this.spinner.hide();
    })
  }
  
  updateRole(product) {
    this.showRole = true;
    this.productRole = product;
    console.log(this.productRole.fromDate ,"dateee")
    setTimeout(() => {
      const formValue = this.rolesForm;
      formValue.patchValue({
        dataResourceID: this.dataResourceId ,
        partyID: this.productRole.getAddDataResourceRoleById.partyId ,
        roleTypeID: this.productRole.getAddDataResourceRoleById.roleTypeId ,
        fromDate: this.productRole.fromDate  ,
        throughDate: this.datePipe.transform(this.productRole.thruDate ,"yyyy-MM-dd") 
      })

    }, 2000);
  }
  resetRole(){
    this.showRole = false ;
    this.rolesForm.reset();
    setTimeout(() => {
      const formValue = this.rolesForm;
      formValue.patchValue({
        dataResourceID: this.dataResourceId,
       
      })

    }, 1000);
  }
  getAddDataResourceRoleById() {
    this.spinner.show();
    this.myContactsService.getAddDataResourceRoleById(this.dataResourceId).subscribe(res => {
      this.dataResourceRoleById = res.data;
      this.spinner.hide();
    })
  }
  removeAddDataResourceRole(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        const req = {
          "dataResourceId": this.dataResourceId,
          "fromDate":product.fromDate  ,
          "partyId": product.getAddDataResourceRoleById.partyId,
          "roleTypeId": product.getAddDataResourceRoleById.roleTypeId,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20"
        }
        this.myContactsService.removeAddDataResourceRole(req).subscribe(res => {    
          this.getAddDataResourceRoleById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  AddDataResourceAttribute() {
    this.spinner.show();
    const requestData =
    {
      "attrDescription": this.addAttributeForm.value.attrDescription,
      "attrName": this.addAttributeForm.value.attrName,
      "attrValue": this.addAttributeForm.value.attrValue,
      "dataResourceId": this.dataResourceId
    }
    this.myContactsService.AddDataResourceAttribute(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addAttributeForm.reset();
        this.getAddDataResourceAttributeById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateDataResourceAttribute() {
    this.spinner.show();
    const requestData =
    {
      "attrDescription": this.addAttributeForm.value.attrDescription,
      "attrName": this.addAttributeForm.value.attrName,
      "attrValue": this.addAttributeForm.value.attrValue,
      "dataResourceId": this.dataResourceId
    }
    this.myContactsService.updateDataResourceAttribute(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addAttributeForm.reset();
        this.getAddDataResourceAttributeById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getAddDataResourceAttributeAll() {
    this.spinner.show();
    this.myContactsService.getAddDataResourceAttributeAll().subscribe(res => {
      this.addDataResourceAttributeAll = res.data;
      this.spinner.hide();
    })
  }
  updateAttribute(product) {
    this.showAttri = true;
    this.productAttri = product;
    setTimeout(() => {
      const formValue = this.addAttributeForm;
      formValue.patchValue({
        dataResourceID: product.dataResourceId,
        attrName: product.attrName,
        attrValue: product.attrValue,
        attrDescription: product.attrDescription,
      })

    }, 2000);
  }
  reset(){
    this.showAttri = false ;
    this.addAttributeForm.reset();
    setTimeout(() => {
      const formValue = this.addAttributeForm;
      formValue.patchValue({
        dataResourceID: this.dataResourceId,
       
      })

    }, 1000);
  }
  getAddDataResourceAttributeById() {
    this.spinner.show();
    this.myContactsService.getAddDataResourceAttributeById(this.dataResourceId).subscribe(res => {
      this.addDataResourceAttributeById = res.data.getAddDataResourceAttributeById;
      this.spinner.hide();
    })
  }
  getContentRoleTypeId() {
    this.spinner.show();
    this.accountsService.getContentRoleTypeId().subscribe(res => {
      this.ContentRoleTypeId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.ContentRoleTypeId) {
        this.ContentRoleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }

  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }



  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });


  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  changeactiveCategoryMain(tab: number) {
    this.activeCategoryMain = tab;

  }

  navigateOpen() {
    this._Router.navigate(['/content/data-resource/dataresource-navigate-page']);
  }

  findOpen() {
    this._Router.navigate(['/content/data-resource']);
  }


}
