import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';  import { Location } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';

@Component({
  selector: 'app-detail-shipment-time-estimate',
  templateUrl: './detail-shipment-time-estimate.component.html',
  styleUrls: ['./detail-shipment-time-estimate.component.css']
})
export class DetailShipmentTimeEstimateComponent implements OnInit {
  id: any;
  groupid: any;
  parentId: any;
  fGroupName: any;
  des: any;
  seq: any;
  activeCategory = 2;
  activeCategorytab = 2;
  products3: any;
  @ViewChild('closebutton') closebutton;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  ShipmentEstimatesForm: FormGroup;
  GeoId: any;
  GeoIdArray:any[]=[];
  LeadTimeUomIdArray: any[]=[];
  LeadTimeUomId: any;
  shipmentTimeList: any;
  shipmentMethodTypeId: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly spinner: NgxSpinnerService,    private _location: Location,
    readonly myContactsService: MyContactsService,
    readonly router: Router,
  
    readonly _FormBuilder: FormBuilder,

    readonly _ToastrService: ToastrService,
    readonly toastr: ToastrService
  ) {
    this.ShipmentEstimatesForm = this._FormBuilder.group({
      ShipmentMethodTypeID :[''],
      PartyID :[''],
      RoleTypeID :[''],
      GeoIdTo :[''],
      GeoIdFrom :[''],
      FromDate :[''],
      ThroughDate :[''],
      LeadTime :[''],
      LeadTimeUomID :[''],
    })
   }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
    
    this.activatedRoute.queryParams.subscribe(params=>{
      this.shipmentMethodTypeId = params.shipmentMethodTypeId;
    })
    this.getGeoId();
    this.getLeadTimeUomId();
    this.getLeadTimeUomId();
    this.getShipmentTimeEstimate();
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  getShipmentTimeEstimate() {
    this.spinner.show();
    this.myContactsService.getShipmentTimeEstimate(this.shipmentMethodTypeId).subscribe(res => {
      this.shipmentTimeList = res.data.getShipmentTimeEstimate;
      setTimeout(() => {
        const formValue = this.ShipmentEstimatesForm;
        formValue.patchValue({
          ShipmentMethodTypeID :this.shipmentTimeList.shipmentMethodTypeId ,
          PartyID :this.shipmentTimeList.partyId ,
          RoleTypeID :this.shipmentTimeList.roleTypeId ,
          GeoIdTo :this.shipmentTimeList.geoIdTo ,
          GeoIdFrom :this.shipmentTimeList.geoIdFrom ,
          FromDate :this.shipmentTimeList.fromDate ,
          ThroughDate :this.shipmentTimeList.thruDate ,
          LeadTime :this.shipmentTimeList.leadTime ,
          LeadTimeUomID :this.shipmentTimeList.leadTimeUomId ,
        })
      }, 2000);
      this.spinner.hide();
    })
  }
  updateShipmentTimeEstimate(){
    this.spinner.show();
    const requestData = {
      "fromDate": this.ShipmentEstimatesForm.value.FromDate,
      "geoIdFrom": this.ShipmentEstimatesForm.value.GeoIdFrom,
      "geoIdTo": this.ShipmentEstimatesForm.value.GeoIdTo,
      "isDelete": "",
      "leadTime": this.ShipmentEstimatesForm.value.LeadTime,
      "leadTimeUomId": this.ShipmentEstimatesForm.value.LeadTimeUomID,
      "partyId": this.ShipmentEstimatesForm.value.PartyID,
      "roleTypeId": this.ShipmentEstimatesForm.value.RoleTypeID,
      "shipmentMethodTypeId": this.shipmentMethodTypeId ,
      "thruDate":  this.ShipmentEstimatesForm.value.ThroughDate,

     
    }
    this.myContactsService.updateShipmentTimeEstimate(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.ShipmentEstimatesForm.reset();
        this.getShipmentTimeEstimate();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getLeadTimeUomId() {
    this.spinner.show();
    this.myContactsService.getLeadTimeUomId().subscribe(res => {
      this.LeadTimeUomId = res.data.getLeadTimeUomId;
      this.spinner.hide();
      for (const value of this.LeadTimeUomId) {
        this.LeadTimeUomIdArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
  }
  getGeoId() {
    this.spinner.show();
    this.myContactsService.getGeoIds().subscribe(res => {
      this.GeoId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.GeoId) {
        this.GeoIdArray.push({
          label: value.geoId,
          value: value.geoId
        })
      }
    })
  }
}
