<div class="container-fluid main-container-wrapper pad_0 ">
    <br>
   

    <div class=" bg-white color-grey">
        <div class="port-header-contact create-lead font-medium mb-0">
           <span class="color-black pl-1 titlePanels" *ngIf="this.agreementTypeValue=='payable'">Payables

            </span>
           <span class="color-black pl-1 titlePanels" *ngIf="this.agreementTypeValue=='receiveable'">Receivables

            </span>
        </div>
        <div class="w3-card-4 classCard classCardType border_wdth_1" >
        <div class="container-fluid" *ngIf="this.agreementTypeValue=='payable'">
            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                <li [ngClass]="activeCategory==1?'active':''" (click)="enterBills()"><a>
                    Enter Bills </a></li>
                <li [ngClass]="activeCategory==2?'active':''" (click)="payableMakePayment()"><a>
                    Make Payments</a></li>
                    <li [ngClass]="activeCategory==3?'active':''" (click)="payableMakeGroupPayment()"><a>
                        Make Group Payments</a></li>
            </ul>
        </div>
        <div class="container-fluid" *ngIf="this.agreementTypeValue=='receiveable'">
            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                <li [ngClass]="activeCategory==1?'active':''" (click)="salesInvoice()"><a>
                    Sales Invoice </a></li>
                <li [ngClass]="activeCategory==2?'active':''" (click)="receivePayment()"><a>
                    Receive Payments</a></li>
                    <li [ngClass]="activeCategory==3?'active':''" (click)="receiveGroupPayments()"><a>
                        Receive Group Payments</a></li>
            </ul>
        </div>

        <div class="create-new-leade mt-2">
            <div class="container-fluid">
              
                   
                                <div class="panel-group">
                                    <div *ngIf="activeCategory==1">
                                      
                                       
                                        <div class="panel-collapse">
                                            <br>
                                            <div class="w3-card-4 classCard classCardType" *ngIf="this.agreementTypeValue=='payable'">
                                            <div class="container-fluid" >
                                                <ul class="tabbing-section tabbing-accordians tabClass" >
                                      
                                                  <li [ngClass]="activeCategoryMain==2?'active':''" (click)="changeactiveCategoryFunctionPurchae()"><a>
                                                    Purchases Invoices</a></li>
                                                    <li [ngClass]="activeCategoryMain==4?'active':''" (click)="changeactiveCommission()"><a>
                                                        Commission Invoices</a></li>
                                                    
                                                    <li [ngClass]="activeCategoryMain==3?'active':''" (click)="changeactiveCategoryFunctionCredit()"><a>
                                                        Credit Memo</a></li>
                                               
                                      
                                                </ul>
                                            </div>
                                                <div class="create-new-leade mt-2">
                                                    <div class="container-fluid">
                                                        <div class="row" style="padding: 7px;">
                                                            <form class="w-100" [formGroup]="searchCommissionInvoiceForm">

                                                                <div class="w3-card-4  w-100">
                                                                    <div class="accordion classCard" id="myAccordion">
                                                                        <div class="accordion-item">
                                                                            <h2 class="accordion-header" id="headingOne">
                                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                                    <button type="button" class="btn btn-outline-secondary active marginHeader" routerLinkActive="active"
                                                                                        data-target="#newCommissionInovoice_Popup" data-toggle="modal" >Create New
                                                                                            Commission Invoice</button>
                                                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 71%!important; padding-top: 11px !important;
                                                                                        padding-bottom: 11px !important;">
                                                                                    </button>									
                                    
                                                                                </div>
                                            
                                                                            </h2>
                                                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                                <div class="card-body">
                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                          
                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Invoice ID</label>
                                                                                                </div>
                                                                                             
                                                                                                <div class="col-lg-2" style="display: none;">
                                                                                                    <p-dropdown filter="true" name="party"
                                                                                                    [options]="opportunityIdArray" optionlabel="label"
                                                                                                    formControlName="invoiceIdSearchType">
                                                                                                </p-dropdown>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                                    aria-describedby="emailHelp" formControlName="invoiceId"
                                                                                                    placeholder="Invoice ID">
                                                                                            </div>
                                                        
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">Description</label>
                                                                                                </div>
                                                                                                <div class="col-lg-2" style="display: none;">
                                                                                                    <p-dropdown filter="true" name="party"
                                                                                                            formControlName="descriptionSearchType"
                                                                                                            [options]="opportunityIdArray" optionlabel="label">
                                                                                                        </p-dropdown>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                                            formControlName="description" aria-describedby="emailHelp"
                                                                                                            placeholder="Description">
                                                                                               
                                                                                                </div>
                                                                          
                                                                                               
                                                                          
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                          
                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Status ID</label>
                                                                                                </div>
                                                                                             
                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown filter="true" name="party" [options]="statusIds"
                                                                                                    formControlName="statusId" optionlabel="label"
                                                                                                    placeholder="Select Status">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                        
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">Customer ID</label>
                                                                                                </div>
                                                                                               
                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown filter="true" name="party" [options]="partyIds"
                                                                                                    formControlName="fromPartyId" optionlabel="label"
                                                                                                    placeholder="Select PartyID">
                                                                                                </p-dropdown>
                                                                                               
                                                                                                </div>
                                                                          
                                                                                               
                                                                          
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                          
                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Supplier ID</label>
                                                                                                </div>
                                                                                             
                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown filter="true" name="party" [options]="array"
                                                                                                        formControlName="toPartyId" optionlabel="label"
                                                                                                        placeholder="Select PartyID">
                                                                                                    </p-dropdown>
                                                                                            </div>
                                                        
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                          
                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Invoice Date</label>
                                                                                                </div>
                                                                                             
                                                                                                <div class="col-lg-2" style="display: none;">
                                                                                                    <p-dropdown filter="true" name="party" [options]="greaterArray"
                                                                                                    formControlName="invoiceDateFromSearchType" optionlabel="label">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                        
                                                                                            <div class="col-lg-2" style="    margin-left: -2%;">
                                                                                                <p-calendar placeholder="Select Date" class="ml-2 col-lg-2"
                                                                                                formControlName="invoiceDateFrom"></p-calendar>
                                                                                        </div>
                                                                                        <div class="col-lg-2" style="display: none;">
                                                                                            <p-dropdown filter="true" class="ml-2" name="party"
                                                                                            formControlName="invoiceDateToSearchType" [options]="smallerArray"
                                                                                            optionlabel="label">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-calendar class="ml-2 col-lg-2" placeholder="Select Date"
                                                                                                        formControlName="invoiceDateTo"></p-calendar>
                                                                                </div>
                                                                                             
                                                                                            </div>
                                                                                        </div>
                                                    
                                                                                      
                                                                                    </div>

                                                                                    <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -29%;">  
                                                                                        <button _ngcontent-cta-c128=""
                                                                                            type="submit" class="btn btn-secondary submit-btn"
                                                                                            (click)="searchCommissionInvoices()">Find</button>&nbsp;
                                                                                        <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                                                    </div>
                                                                                    
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>

                                                                <!-- <div class="w3-card-4 classCard">
                                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                       
                                                                            <button type="button" class="btn btn-outline-secondary active" routerLinkActive="active"
                                                                               data-target="#newCommissionInovoice_Popup" data-toggle="modal" >Create New
                                                                                Commission Invoice</button>
                                                                    
                                                                    </div>
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                      
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Invoice ID</label>
                                                                            </div>
                                                                         
                                                                            <div class="col-lg-2" style="display: none;">
                                                                                <p-dropdown filter="true" name="party"
                                                                                [options]="opportunityIdArray" optionlabel="label"
                                                                                formControlName="invoiceIdSearchType">
                                                                            </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                aria-describedby="emailHelp" formControlName="invoiceId"
                                                                                placeholder="Invoice ID">
                                                                        </div>
                                    
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Description</label>
                                                                            </div>
                                                                            <div class="col-lg-2" style="display: none;">
                                                                                <p-dropdown filter="true" name="party"
                                                                                        formControlName="descriptionSearchType"
                                                                                        [options]="opportunityIdArray" optionlabel="label">
                                                                                    </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                        formControlName="description" aria-describedby="emailHelp"
                                                                                        placeholder="Description">
                                                                           
                                                                            </div>
                                                      
                                                                           
                                                      
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                      
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Status ID</label>
                                                                            </div>
                                                                         
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" name="party" [options]="statusIds"
                                                                                formControlName="statusId" optionlabel="label"
                                                                                placeholder="Select Status">
                                                                            </p-dropdown>
                                                                        </div>
                                    
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Customer ID</label>
                                                                            </div>
                                                                           
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" name="party" [options]="partyIds"
                                                                                formControlName="fromPartyId" optionlabel="label"
                                                                                placeholder="Select PartyID">
                                                                            </p-dropdown>
                                                                           
                                                                            </div>
                                                      
                                                                           
                                                      
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                      
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Supplier ID</label>
                                                                            </div>
                                                                         
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" name="party" [options]="array"
                                                                                    formControlName="toPartyId" optionlabel="label"
                                                                                    placeholder="Select PartyID">
                                                                                </p-dropdown>
                                                                        </div>
                                    
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                      
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Invoice Date</label>
                                                                            </div>
                                                                         
                                                                            <div class="col-lg-2" style="display: none;">
                                                                                <p-dropdown filter="true" name="party" [options]="greaterArray"
                                                                                formControlName="invoiceDateFromSearchType" optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>
                                    
                                                                        <div class="col-lg-2" style="    margin-left: -2%;">
                                                                            <p-calendar placeholder="Select Date" class="ml-2 col-lg-2"
                                                                            formControlName="invoiceDateFrom"></p-calendar>
                                                                    </div>
                                                                    <div class="col-lg-2" style="display: none;">
                                                                        <p-dropdown filter="true" class="ml-2" name="party"
                                                                        formControlName="invoiceDateToSearchType" [options]="smallerArray"
                                                                        optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-calendar class="ml-2 col-lg-2" placeholder="Select Date"
                                                                                    formControlName="invoiceDateTo"></p-calendar>
                                                            </div>
                                                                         
                                                                        </div>
                                                                    </div>
                                
                                                                  
                                                                </div>
                                                                <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -29%;">  
                                                                    <button _ngcontent-cta-c128=""
                                                                        type="submit" class="btn btn-secondary submit-btn"
                                                                        (click)="searchCommissionInvoices()">Find</button>&nbsp;
                                                                    <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                                </div>
                                                                </div> -->
                                                                
                                                                <br>
                                                                
                                                                <div class="w3-card-4 classCard">
                                                                    <header class="w3-container w3-blue">
                                                                      <div class="header-tabs">
                                                                        <h4 class=" common-styling h4Margin">
                                                                            Invoice List
                                                                        </h4>
                                                                     
                                                                      </div>
                                                                    </header>
                                                                <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                    <div class="col-lg-12 col-12">
                                                                        <div class="form-group">
                                                                           
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="commissionInvoices" [paginator]="true" [rows]="rows"
                                                                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                    [totalRecords]="total" [scrollable]="true"
                                                                                    (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'190px'}">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                                        styleclass="custom-checkbox" binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined"
                                                                                                                    value="undefined"></div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    Invoice ID <p-sortIcon field="code"></p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                                              <div style="color: white;">  Description<p-sortIcon field="name">
                                                                                                </p-sortIcon></div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                                pSortableColumn="category"><div style="color: white;"> Ref Num
                                                                                                <p-sortIcon field="category"></p-sortIcon></div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                <div style="color: white;"> Invoice Date <p-sortIcon field="price"></p-sortIcon></div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                <div style="color: white;">  Due Date<p-sortIcon field="price"></p-sortIcon></div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'120px'}">
                                                                                                <div style="color: white;">  Status</div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    style="visibility:hidden;" value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'120px'}">
                                                                                                <div style="color: white;">  Customer Id</div>
                                                                                                <input
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    style="visibility:hidden;" value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'120px'}">
                                                                                                <div style="color: white;">  Amount</div>
                                                                                                <input
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    style="visibility:hidden;" value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'120px'}">
                                                                                                <div style="color: white;">   Outstanding amount</div>
                                                                                                <input
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    style="visibility:hidden;" value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                <div style="color: white;">  Created Date</div>
                                                                                                <input
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    style="visibility:hidden;" value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                <div style="color: white;">  Action</div>
                                
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    style="visibility:hidden;" value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'200px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                                        styleclass="custom-checkbox" binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined"
                                                                                                                    value="undefined"></div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    <span
                                                                                                        routerLink="/financial/payable/commission-invoice/summary-commission-invoice"
                                                                                                        [queryParams]="{invoiceId:product.invoiceId}"
                                                                                                         class="account-button" >{{product.invoiceId}}</span>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'190px'}">{{product.description}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                {{product.referenceNumber}}</td>
                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                {{product.invoiceDate |date:'yyyy-MM-d hh:mm:ss'}}</td>
                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                {{product.dueDate  |date:'yyyy-MM-d hh:mm:ss'}}</td>
                                                                                            <td [ngStyle]="{'width':'190px'}">{{product.status}}</td>
                                                                                            <td [ngStyle]="{'width':'190px'}">{{product.partyFrom}}</td>
                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                {{product.total |currency :'USD'}}</td>
                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                {{product.outstanding |currency :'USD'}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                {{product.createdStamp |date:'yyyy-MM-d hh:mm:ss'}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                <fa-icon [icon]="faEdit"
                                                                                                    *ngIf="product.statusId==='INVOICE_IN_PROCESS'"
                                                                                                    routerLink="/financial/payable/commission-invoice/create-commission-invoice"
                                                                                                    [queryParams]="{invoiceId:product.invoiceId}"
                                                                                                     class="account-button" ></fa-icon>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                               
                                                                                <p class="paginate_data">
                                                                                    View per page </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </form>
                                
                                                        </div>
                                                    </div>
                                                </div>
                                             
                                            </div>
                                           
                                                <div class="container-fluid" *ngIf="this.agreementTypeValue=='receiveable'">
                                                 
                                                    <div class="create-new-leade mt-2">
                                                        <div class="container-fluid">
                                                            <div class="row">
                                                                <form class="w-100" [formGroup]="searchCommissionInvoiceForm">
                                                                    <div class="w3-card-4 classCard">
                                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                           
                                                                                <button type="button" class="btn btn-outline-secondary active" routerLinkActive="active"
                                                                                    routerLink="/financial/payable/commission-invoice/create-commission-invoice">Create
                                                                                    Invoice</button>
                                                                        
                                                                        </div>
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                          
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Invoice ID</label>
                                                                                </div>
                                                                             
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown filter="true" name="party"
                                                                                    [options]="opportunityIdArray" optionlabel="label"
                                                                                    formControlName="invoiceIdSearchType">
                                                                                </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                    aria-describedby="emailHelp" formControlName="invoiceId"
                                                                                    placeholder="Invoice ID">
                                                                            </div>
                                        
                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                    <label for="exampleInputEmail1">Description</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown filter="true" name="party"
                                                                                            formControlName="descriptionSearchType"
                                                                                            [options]="opportunityIdArray" optionlabel="label">
                                                                                        </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                            formControlName="description" aria-describedby="emailHelp"
                                                                                            placeholder="Description">
                                                                               
                                                                                </div>
                                                          
                                                                               
                                                          
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                          
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Status ID</label>
                                                                                </div>
                                                                             
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" name="party" [options]="statusIds"
                                                                                    formControlName="statusId" optionlabel="label"
                                                                                    placeholder="Select Status">
                                                                                </p-dropdown>
                                                                            </div>
                                        
                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                    <label for="exampleInputEmail1">From Party ID</label>
                                                                                </div>
                                                                               
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" name="party" [options]="partyIds"
                                                                                    formControlName="fromPartyId" optionlabel="label"
                                                                                    placeholder="Select PartyID">
                                                                                </p-dropdown>
                                                                               
                                                                                </div>
                                                          
                                                                               
                                                          
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                          
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">To Party ID</label>
                                                                                </div>
                                                                             
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" name="party" [options]="array"
                                                                                        formControlName="toPartyId" optionlabel="label"
                                                                                        placeholder="Select PartyID">
                                                                                    </p-dropdown>
                                                                            </div>
                                        
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                          
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Invoice Date</label>
                                                                                </div>
                                                                             
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown filter="true" name="party" [options]="greaterArray"
                                                                                    formControlName="invoiceDateFromSearchType" optionlabel="label">
                                                                                </p-dropdown>
                                                                            </div>
                                        
                                                                            <div class="col-lg-2" style="    margin-left: -2%;">
                                                                                <p-calendar placeholder="Select Date" class="ml-2 col-lg-2"
                                                                                formControlName="invoiceDateFrom"></p-calendar>
                                                                        </div>
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown filter="true" class="ml-2" name="party"
                                                                            formControlName="invoiceDateToSearchType" [options]="smallerArray"
                                                                            optionlabel="label">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-calendar class="ml-2 col-lg-2" placeholder="Select Date"
                                                                                        formControlName="invoiceDateTo"></p-calendar>
                                                                </div>
                                                                             
                                                                            </div>
                                                                        </div>
                                    
                                                                      
                                                                    </div>
                                                                    <div class="col-lg-12 col-12 main-submit-button " style="
                                                                    margin-left: -55%;
                                                                "><button _ngcontent-cta-c128=""
                                                                            type="submit" class="btn btn-secondary submit-btn"
                                                                            (click)="searchCommissionInvoices()">Find</button>&nbsp;
                                                                        <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                                    </div>
                                                                    </div><br>
                                                                    <div class="w3-card-4 classCard">
                                                                        <header class="w3-container w3-blue">
                                                                          <div class="header-tabs">
                                                                            <h4 class=" common-styling h4Margin">
                                                                                Invoice List
                                                                            </h4>
                                                                         
                                                                          </div>
                                                                        </header>
                                                                    <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                        <div class="col-lg-12 col-12">
                                                                            <div class="form-group">
                                                                               
                                                                                <div class="card own-account-table">
                                                                                    <p-table [value]="commissionInvoices" [paginator]="true" [rows]="rows"
                                                                                        scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                        [totalRecords]="total" [scrollable]="true"
                                                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                                                        <ng-template pTemplate="header">
                                                                                            <tr style="background: #0d3769;">
                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'190px'}">
                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                                                            styleclass="custom-checkbox" binary="true"
                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div
                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                    <input type="checkbox" name="undefined"
                                                                                                                        value="undefined"></div>
                                                                                                                <div role="checkbox"
                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span
                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <!---->
                                                                                                        </p-checkbox>
                                                                                                        Invoice ID <p-sortIcon field="code"></p-sortIcon>
                                                                                                    </div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value="" autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                                                  <div style="color: white;">  Description<p-sortIcon field="name">
                                                                                                    </p-sortIcon></div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value="" autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                                    pSortableColumn="category"><div style="color: white;"> Ref Num
                                                                                                    <p-sortIcon field="category"></p-sortIcon></div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value="" autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                    <div style="color: white;"> Invoice Date <p-sortIcon field="price"></p-sortIcon></div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value="" autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                    <div style="color: white;">  Due Date<p-sortIcon field="price"></p-sortIcon></div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value="" autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'120px'}">
                                                                                                    <div style="color: white;">  Status</div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        style="visibility:hidden;" value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'120px'}">
                                                                                                    <div style="color: white;">  From Party</div>
                                                                                                    <input
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        style="visibility:hidden;" value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'120px'}">
                                                                                                    <div style="color: white;">  Amount</div>
                                                                                                    <input
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        style="visibility:hidden;" value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'120px'}">
                                                                                                    <div style="color: white;">   Outstanding amount</div>
                                                                                                    <input
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        style="visibility:hidden;" value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                    <div style="color: white;">  Created Date</div>
                                                                                                    <input
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        style="visibility:hidden;" value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                    <div style="color: white;">  Action</div>
                                    
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        style="visibility:hidden;" value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="body" let-product>
                                                                                            <tr>
                                                                                                <td [ngStyle]="{'width':'200px'}">
                                                                                                    <div class="checkbox-align">
                                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                                                            styleclass="custom-checkbox" binary="true"
                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div
                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                    <input type="checkbox" name="undefined"
                                                                                                                        value="undefined"></div>
                                                                                                                <div role="checkbox"
                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span
                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <!---->
                                                                                                        </p-checkbox>
                                                                                                        <span
                                                                                                            routerLink="/financial/payable/commission-invoice/summary-commission-invoice"
                                                                                                            [queryParams]="{invoiceId:product.invoiceId}"
                                                                                                             class="account-button" >{{product.invoiceId}}</span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'190px'}">{{product.description}}
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    {{product.referenceNumber}}</td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    {{product.invoiceDate |date:'yyyy-MM-d hh:mm:ss'}}</td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    {{product.dueDate  |date:'yyyy-MM-d hh:mm:ss'}}</td>
                                                                                                <td [ngStyle]="{'width':'190px'}">{{product.status}}</td>
                                                                                                <td [ngStyle]="{'width':'190px'}">{{product.partyFrom}}</td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    {{product.total |currency :'USD'}}</td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    {{product.outstanding |currency :'USD'}}
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    {{product.createdStamp |date:'yyyy-MM-d hh:mm:ss'}}
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    <fa-icon [icon]="faEdit"
                                                                                                        *ngIf="product.statusId==='INVOICE_IN_PROCESS'"
                                                                                                        routerLink="/financial/payable/commission-invoice/create-commission-invoice"
                                                                                                        [queryParams]="{invoiceId:product.invoiceId}"
                                                                                                         class="account-button" ></fa-icon>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="text-center">
                                                                                                               <h4 class="ml-2">No Record Found</h4>
                                                                                                           </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                    </p-table>
                                                                                   
                                                                                    <p class="paginate_data">
                                                                                        View per page </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </form>
                                    
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                
                                        </div>
                                    </div>
                                 


                                </div>
                          
            
            </div>
        </div>
        </div>
    </div>
</div>

<div class="modal fade" id="newCommissionInovoice_Popup" tabindex="-1" role="dialog" aria-labelledby="newCommissionInovoice_Popup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Commission Invoice</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebuttonN>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <br>


                <div class="w3-card-4 classCardPopUp" style="width: 100%;margin-top: 0%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                                  background: #F5F7FA!important;">

                            <li class="aSize" [ngClass]="activeCategoryInv==2?'active':''">
                                Create Commission Invoice</li>


                        </ul>
                    </div>
                    <div>
                        <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                            <div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100">

                                            <div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <form class="w-100" [formGroup]="commissionInvoiceForm">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                            
                                                                    <div class="col-lg-3 form-group rightFormG">
                                                                        <label for="exampleInputEmail1">Customer ID</label>
                                                                    </div>
                                                                 
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" name="party" [options]="partyIds"
                                                                            formControlName="partyIdFrom" optionlabel="label"
                                                                            placeholder="Select PartyID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group rightFormH">
                                                                        <label for="exampleInputEmail1">Currency</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" name="party" [options]="currencyUomIds"
                                                                        formControlName="currencyUomId" optionlabel="label"
                                                                        placeholder="Select Currency">
                                                                    </p-dropdown>
                                                                   
                                                                    </div>
                                            
                                                                   
                                            
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                            
                                                                    <div class="col-lg-3 form-group rightFormG">
                                                                        <label for="exampleInputEmail1">Supplier ID</label>
                                                                    </div>
                                                                 
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" name="party" [options]="array"
                                                                        formControlName="partyId" optionlabel="label"
                                                                        placeholder="Select Party ID">
                                                                    </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group rightFormH">
                                                                        <label for="exampleInputEmail1">Invoice Date</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="date" class="form-control" formControlName="invoiceDate"
                                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                        placeholder="Select Date">
                                                                        <!-- <p-calendar placeholder="Select Date" formControlName="invoiceDate">
                                                                        </p-calendar> -->
                                                                    </div>
                                            
                                                                   
                                            
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                            
                                                                    <div class="col-lg-3 form-group rightFormG">
                                                                        <label for="exampleInputEmail1">Due Date</label>
                                                                    </div>
                                                                 
                                                                    <div class="col-lg-2">
                                                                        <input type="date" class="form-control" formControlName="dueDate"
                                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                        placeholder="Select Date">
                                                                        <!-- <p-calendar formControlName="dueDate" placeholder="Select Date">
                                                                        </p-calendar> -->
                                                                    </div>
                                                                    <div class="col-lg-3 form-group rightFormH">
                                                                        <label for="exampleInputEmail1">Ref Num</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control" formControlName="referenceNumber"
                                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                        placeholder="Ref Num">
                                                                    </div>
                                            
                                                                   
                                            
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                            
                                                                    <div class="col-lg-3 form-group rightFormG">
                                                                        <label for="exampleInputEmail1">Description</label>
                                                                    </div>
                                                                 
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="Description"
                                                                        formControlName="description">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group rightFormH">
                                                                        <label for="exampleInputEmail1">Message</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                                        formControlName="invoiceMessage" aria-describedby="emailHelp"
                                                                        placeholder="Message">
                                                                    </div>
                                            
                                                                   
                                            
                                                                </div>
                                                            </div>
                                                          
                                                          
                                                        </div>
                                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -17%;">
                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn "
                                                                (click)="submit()">{{editMode ? 'Update':'Create'}}</button>
                                                            
                                                        </div>
                                                    </form>
                                                </div>
                                              
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 