import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';
import Swal from 'sweetalert2'
import { Form, FormBuilder, FormGroup } from '@angular/forms';
import { ProductionService } from '../production.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { DatePipe } from '@angular/common';import { Location } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
@Component({
  selector: 'app-detail-jobshop-summary',
  templateUrl: './detail-jobshop-summary.component.html',
  styleUrls: ['./detail-jobshop-summary.component.css']
})
export class DetailJobshopSummaryComponent implements OnInit {
  ProductionproductionRunIdArray: any[]=[];
  ProductionproductionRunId: any;
  enumId: string;
  enumDataArrayList: any[]=[];
  
  createJob: FormGroup;
  activeCategory = 2;
  activeCategoryA = 2;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebuttonA') closebuttonA;
  @ViewChild('closebuttonB') closebuttonB;
  @ViewChild('closebuttonC') closebuttonC;
  @ViewChild('closebuttonD') closebuttonD;
  partyId: any;
  createJobParty:FormGroup;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[] = [];
  varianceReasonId: any;
  varianceReasonIdArray: any[] = [];
  glAccountid: any;
  varianceReasonIdData: any;
  show: boolean;
  budgetId: any;
  personId: any;
  partyIdArray: any[] = [];
  ReviewResultTypes: any;
  ReviewResultTypesArray: any[] = [];
  reviewDate: string;
  facilityId: any;
  routingId: any;
  productId: any;
  facilityIdArray: any[]=[];
  routingIdArray: any[]=[];
  productIdArray: any[]=[];
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  createDependent: { label: string; value: string; }[];
  startDate: string;
  workEffortId: any;
  getEstimateFrom: string;
  estimatedCompletionDate: string;
  statusId: any;
  completeDate: any;
  dateComplete: string;
  prodId: any;


  products3: any;
  contentForm:FormGroup;
  createInventory:FormGroup;
  paymentPartyGroup: any;
  downloading: boolean;
  total=0;
  ReportTax:any[]=[];
 
  FinancialTransaction:any[]=[];
  
  budgetHeader: any;
  budgetStatus: any;
  budgetRole: any;
  budgetItem: any;
  budgetReview=[{
    "product":"A"
  }];
  
  budgetItemList: any;
  budgetRoleList: any;
  budgetReviewList: any;
  status: string;
  
  id: string;
  Productiontasks: any;
  statuDescription: any;
  ProductionMaterial: any;
  ProductionFixedAsset: any;
  assignedParties: any;
  assignedTasks: any;
  precedingProductionRun: any;
  succeedingProductionRun: any;
  productionRunMaterialsData: any;
  productionRunRoutingTasksData: any;
  productionRunAssetsData: any;
  UnusedProductionRunMaterials: any;
  productionRunData: any;
  QuickClose: string;
  QuickComplete: string;
  QuickSchedule: string;
  productionJobContent: any;
  workEffortContentTypeId: any;
  workEffortContentTypeIdArray: any[]=[];
  fallAvaialbe=[{
    "label":"Yes",
    "value":"Y"
  },
  {
    "label":"No",
    "value":"N"
  }]
  locationIdSer:any[]=[]
  ProductionJobProductId: any;
  productionRunContentsSaveData: any;
  ProductionJobActualCosts: any;
  Inventory: { label: string; value: string; }[];
  productionRunDeclareAndProduce: any;
  contentSaveArray: any[]=[];
  actualMaterial: any;
  orderItems: any;
  failIfItemsAreNotAvailable: string;
  returnUnusedMaterialsToWarehouse: any;
  returnUnusedArray: any[]=[];
  materialsRequiredByTheRunningTask: any;
  materialsRequiredByTheRunningTaskArray: any[]=[];
  taskList: any;
  taskListArray: any[]=[];
  activeCategoryTask=2;
  showTask: boolean;
  createJobTaskForm:FormGroup;
  showMaterial: boolean;
  routingTaskList: any;
  routingTaskListArray: any[]=[];
  productListArray: any[]=[];
  createJobMaterailJob:FormGroup;
  createJobFixed:FormGroup;
  showFixed: boolean;
  FromDate: string;
  ThroughDate: string;
  updateFromDate: any;
  statusIdArray: any[]=[];
  getFixedAssets: any;
  getFixedAssetsArray: any[]=[];
  allrole: any;
  allroleArray: any[]=[];
  productionJobContents: any;
  roletypeId: any;
  productIdFind: any;
  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,

    private _location: Location,
    
    
    readonly  _Router: Router,
    readonly addSkillService: AddSkillService,
    readonly _ToastrService: ToastrService,
    readonly productionService:ProductionService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
   
    readonly datePipe: DatePipe,

   
    readonly router: Router) { 

      this.createJob = this._FormBuilder.group({
        ProductionRunID: [''],
        Type: [''],
  
      });

      this.createJobMaterailJob = this._FormBuilder.group({
        ProductID: [''],
        EstimatedQuantity: [''],
        RoutingTask: [''],
  
  
      });

      
      this.Inventory=[{
        "label":"Serialized",
        "value":"SERIALIZED_INV_ITEM"
      },
      {
        "label":"Non-Serialized",
        "value":"NON_SERIAL_INV_ITEM"
      }]
      this.contentForm = this._FormBuilder.group({
        ContentLocale:  ['en_US'],
      
        PartyID:  [''],
     
    
    });
    this.createInventory = this._FormBuilder.group({
      Stockin: [''],
      InventoryItemTypeID:[''],
      LotID:['']
   
    });
    this.createJobTaskForm = this._FormBuilder.group({
      routingtaskid: [''],
      SequenceNum:[''],
      RoutingTaskName:[''],
      Description: [''],
      ReservPersons:[''],
      CalculatedCompletionDate:[''],
      StartDate: [''],
      EstimatedSetupTime:[''],
      EstimatedUnitRunTime:['']
     
    });
    this.createJobFixed = this._FormBuilder.group({
      RoutingTask: [''],
      FixedAsset: [''],
      FromDate: [''],
      ThroughDate: [''],
      Availability: [''],
      AllocatedCost: [''],
      Comments: [''],


    });
    this.createJob = this._FormBuilder.group({
      productId: [''],
      Quantity: [''],
      startDate: [''],
      FacilityID: [''],
      RoutingID: [''],
      ProductionRunName: [''],
      Description: [''],
      dependent: [''],
      CalculatedCompletionDate:[''],
      ManufacturerID:[''],
      statusId:['']


    });
    this.createJobParty = this._FormBuilder.group({
      PartyID: [''],
      RoleTypeID: [''],
      WorkEffortID: [''],
      fromDate: [''],
      throughDate: [''],


    });
    this.createDependent=[{
      "label":"Yes",
      "value":"Y"
    },
    {
      "label":"No",
      "value":"N"
    }]
    }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {


    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params["workEffortId"];
      this.productIdFind=params['productId'];
   
    });

    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params["workEffortId"];
    });

    this.getProductionproductionRunId();
    this.getEenumByTypeData();

    this.show=true;
    this.showTask=false;
    this.showMaterial=false;
    this.showFixed=false;
    this.getProducts();
    this.getProductiontasks();
    this.getJobShopProductionList();
    this.getProductionMaterial();
    this.getProductionFixedAsset();
    this.getProductionParty();
    this.getProductionJobassoc();
    this.getProductionRunDetail();
    this.getPartyId();
    this.getProductionJobProductId();
    this.getProductionJobActualCosts();
    this.getProductionRunDeclareAndProduce();
    this.getActualMaterial();
    this.getPartyId();
    this.getroutingTaskList();
    this.getProductsList();
    this.getProductionMaterialRoutingTaskIds();
    this.getstatusByType();
    this.getProductionMaterialRoutingTaskIds();
    this.getFixedAssetsData();
    this.getRoleAllType();
    this.getProductionJobContent();
  }

  


  getEenumByTypeData(): void {
    this.spinner.show();
    this.enumId="WORKFLOW"
    this.accountsService.getEenumByType(this.enumId).subscribe((res) => {
      let enumDa = res.data;
      this.spinner.hide();
      for (const value of enumDa) {
        this.enumDataArrayList.push({
          label: value.description,
          value: value.enumId,
        });
      }
    });
   
  }
  
  onSubmit1() {
    this.spinner.show();
    this.productionService.postProductionJobassoc(this.workEffortId,this.createJob.value.ProductionRunID, this.createJob.value.Type).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getProductionproductionRunId(): void {
    this.spinner.show();
    this.productionService.getProductionproductionRunId().subscribe((res) => {
      this.ProductionproductionRunId = res.data; 
      this.spinner.hide();
      for (const value of this.ProductionproductionRunId) {
        this.ProductionproductionRunIdArray.push({
          label: value.workEffortName,
          value: value.workEffortId
        })
      }
      
    
    })
  
  }

  getProductionMaterialRoutingTaskIds() {
    this.spinner.show();  
    this.productionService.getProductionMaterialRoutingTaskIds(this.workEffortId).subscribe(res => {
      this.routingTaskList = res.data;
      this.spinner.hide();
      for (const value of this.routingTaskList) {
        this.routingTaskListArray.push({
          label: value.workEffortId,
          value: value.workEffortId
        })
      }
    })
    
  }
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
 
  
  onSubmitParty() {
    this.spinner.show();
    const FromDate = this.createJobParty.get('fromDate').value;
    this.FromDate = this.datePipe.transform(FromDate, "yyyy-MM-dd hh:mm:ss");
    const ThroughDate = this.createJobParty.get('throughDate').value;
    this.ThroughDate = this.datePipe.transform(ThroughDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate": this.FromDate,
      "partyId": this.createJobParty.value.PartyID,
      "roleTypeId": this.createJobParty.value.RoleTypeID,
      "thruDate": this.ThroughDate,
      "workEffortId":this.createJobParty.value.WorkEffortID,
    }
    this.productionService.postProductionParty(this.workEffortId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this.closebuttonD.nativeElement.click();
        this.getProductionParty();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getFixedAssetsData() {
    this.spinner.show();
    const req = {
      "assetType": "",
      "fixedAssetId": "",
      "fixedAssetIdSearchType": "Contains",
      "fixedAssetName": "",
      "fixedAssetNameSearchType": "Contains"


    }
    this.accountsService.searchFixedAssets(this.finSize, req).subscribe((res: any) => {
      this.getFixedAssets = res.data;
      this.spinner.hide();
      for (const value of this.getFixedAssets) {
        this.getFixedAssetsArray.push({
          label: value.fixedAssetName,
          value: value.fixedAssetId
        })
      }
    })
  
  }
  getstatusByType() {
    this.spinner.show();
    this.id="WEFA_AVAILABILITY"
    this.productionService.getstatusByType(this.id).subscribe(res => {
      this.statusId = res.data;
      this.spinner.hide();
      for (const value of this.statusId) {
        this.statusIdArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
    
  }

  homeButton(){
    this.router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  onSubmitJobFixed() {
    this.spinner.show();
    const FromDate = this.createJobFixed.get('FromDate').value;
    this.FromDate = this.datePipe.transform(FromDate, "yyyy-MM-dd hh:mm:ss");
    const ThroughDate = this.createJobFixed.get('ThroughDate').value;
    this.ThroughDate = this.datePipe.transform(ThroughDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "allocatedCost":  this.createJobFixed.value.AllocatedCost,
      "availabilityStatusId":  this.createJobFixed.value.Availability,
      "comments":  this.createJobFixed.value.Comments,
      "fixedAssetId": this.createJobFixed.value.FixedAsset,
      "fromDate": this.FromDate,
      "statusId":"",
      "thruDate":this.ThroughDate,
      "workEffortId":  this.createJobFixed.value.RoutingTask,
    }
    this.productionService.postProductionFixedAsset(this.workEffortId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this.closebuttonC.nativeElement.click();
        this.getProductionFixedAsset();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  onUpdateFixed() {
    this.spinner.show();
    
    const ThroughDate = this.createJobFixed.get('ThroughDate').value;
    this.ThroughDate = this.datePipe.transform(ThroughDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "allocatedCost":  this.createJobFixed.value.AllocatedCost,
      "availabilityStatusId":  this.createJob.value.Availability,
      "comments":  this.createJobFixed.value.Comments,
      "fixedAssetId": this.createJobFixed.value.FixedAsset,
      "fromDate":  this.updateFromDate,
      "statusId":"",
      "thruDate":this.ThroughDate,
      "workEffortId":  this.createJobFixed.value.RoutingTask,
    }
    this.productionService.updateProductionFixedAsset(this.workEffortId,requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this.closebuttonC.nativeElement.click();
        this.getProductionFixedAsset();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    }); }
 
 
  getProductsList(): void {
    this.spinner.show();
    const data = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains",
    };
    this.accountsService.getProductsList(data).subscribe((res) => {
      const productList = res.data;
      this.spinner.hide();
      this.productListArray = res.data.map(value => {
        return {
          label: value.productId,
          value: value.productId
        };
      });
   
    });
  
  }
  
  onSubmitMaterial() {
    const requestData = {
      "estimatedQuantity":  this.createJobMaterailJob.value.EstimatedQuantity,
      "productId":  this.createJobMaterailJob.value.ProductID,
      "workEffortId":  this.createJobMaterailJob.value.RoutingTask

    }

    this.productionService.postProductionMaterial(this.workEffortId,requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this.closebuttonB.nativeElement.click();
        this.getProductionMaterial();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  onUpdateMateril() {
    const requestData = {
      "estimatedQuantity": this.createJobMaterailJob.value.EstimatedQuantity,
      "productId": this.createJobMaterailJob.value.ProductID,
      "workEffortId": this.createJobMaterailJob.value.RoutingTask

    }

    this.productionService.updateProductionMaterial(this.workEffortId,requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this.closebuttonB.nativeElement.click();
        this.getProductionMaterial();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getroutingTaskList() {
    this.spinner.show();
    
    this.productionService.getroutingTaskList().subscribe(res => {
      this.taskList = res.data.lookupResult;
      this.spinner.hide();
      for (const value of this.taskList) {
        this.taskListArray.push({
          label: value.workEffortId,
          value: value.workEffortId
        })
      }
    })
    
  }
  onUpdateJobTask() {
    this.spinner.show();
    const startDate = this.createJobTaskForm.get('StartDate').value;
    this.startDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "description": this.createJobTaskForm.value.Description,
      "estimatedCompletionDate":  this.dateComplete ,
      "estimatedMilliSeconds": this.createJobTaskForm.value.EstimatedUnitRunTime,
      "estimatedSetupMillis": this.createJobTaskForm.value.EstimatedSetupTime,
      "estimatedStartDate":  this.startDate ,
      "priority": this.createJobTaskForm.value.SequenceNum,
      "reservPersons":this.createJobTaskForm.value.ReservPersons,
      "routingTaskId": this.createJobTaskForm.value.routingtaskid,
      "workEffortName": this.createJobTaskForm.value.RoutingTaskName,
    }

    this.productionService.updateProductiontasks(this.workEffortId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this.closebuttonA.nativeElement.click();
       this.getProductiontasks();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  onSubmit() {
    this.spinner.show();
    const startDate = this.createJobTaskForm.get('StartDate').value;
    this.startDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const completeDate = this.createJobTaskForm.get('CalculatedCompletionDate').value;
    this.completeDate = this.datePipe.transform(completeDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "description": this.createJobTaskForm.value.Description,
      "estimatedCompletionDate":  this.completeDate ,
      "estimatedMilliSeconds": this.createJobTaskForm.value.EstimatedUnitRunTime,
      "estimatedSetupMillis": this.createJobTaskForm.value.EstimatedSetupTime,
      "estimatedStartDate":  this.startDate ,
      "priority": this.createJobTaskForm.value.SequenceNum,
      "reservPersons":this.createJobTaskForm.value.ReservPersons,
      "routingTaskId": this.createJobTaskForm.value.routingtaskid,
      "workEffortName": this.createJobTaskForm.value.RoutingTaskName,
    }
    this.productionService.postProductiontasks(this.workEffortId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this.closebuttonA.nativeElement.click();
        this.getProductiontasks();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);}
    });
  }
  getJobShopProductionListData() {
    this.spinner.show();
    const req={
      "currentStatusId": [""],"estimatedStartDateFrom": "","estimatedStartDateFromType": "Contains","estimatedStartDateTo": "",
      "estimatedStartDateToType": "Contains", "facilityId": "","productId": "","workEffortId": this.workEffortId,
      "workEffortIdSearchType": "Contains","workEffortName": "","workEffortNameSearchType": "Contains"
    }
    this.productionService.getJobShopProductionList(this.finSize,req).subscribe((res: any) => {
      this.spinner.hide();
      this.completeDate=res.data[0].estimatedCompletionDate;
      setTimeout(() => {
       this.statusId=res.data[0].currentStatusId
        const fromdate=res.data[0].estimatedStartDate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const estimatedCompletionDate=res.data[0].estimatedCompletionDate
        this.estimatedCompletionDate=this.datePipe.transform(estimatedCompletionDate,"yyyy-MM-dd");
        const formValue = this.createJob;
        formValue.patchValue({
          productId: res.data[0].productId,
          Quantity:res.data[0].estimatedQuantity,
          startDate: this.getEstimateFrom,
          FacilityID: res.data[0].facilityId,
          ProductionRunName: res.data[0].workEffortName,
          Description:res.data[0].description,
          CalculatedCompletionDate: this.estimatedCompletionDate,
          statusId:res.data[0].statusDesc,
        })}, 2000);
    })
  }
  

  getProducts(): void {
    this.spinner.show();
    this.productionService.getJobShopInitialData().subscribe((res) => {
      this.facilityId = res.data.facilityId;
      this.routingId = res.data.routingId;
      this.productId = res.data.productId;
      
      this.spinner.hide();
      for (const value of this.facilityId) {
        this.facilityIdArray.push({
          label: value.facilityName,
          value: value.facilityId
        })
      }
      for (const value of this.routingId) {
        this.routingIdArray.push({
          label: value.workEffortId,
          value: value.workEffortId
        })
      }
      for (const value of this.productId) {
        this.productIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    }) }
  onUpdate() {
    this.spinner.show();
    const startDate = this.createJob.get('startDate').value;
    this.startDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const dateComplete =this.completeDate
    this.dateComplete = this.datePipe.transform(dateComplete, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "currentStatusId": this.statusId,
      "estimatedCompletionDate":this.dateComplete,
      "estimatedStartDate": this.startDate,
      "facilityId": this.createJob.value.FacilityID,
      "manufacturerId":"",
      "productId": this.createJob.value.productId,
      "productionRunId": this.workEffortId,
      "quantity":  this.createJob.value.Quantity,
      "workEffortName": this.createJob.value.ProductionRunName,
      "description":this.createJob.value.Description,
    }
    this.productionService.updateProductionRun(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this.closebutton.nativeElement.click();
        this.getProductionRunDetail();
      } }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
 
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  // cancelSubmit(){
  //   this.router.navigate(["production/jobshop-list"])
  // }
  getJobShopProductionList() {
    this.spinner.show();
    const req={
      "currentStatusId": [""],
      "estimatedStartDateFrom": "",
      "estimatedStartDateFromType": "Contains",
      "estimatedStartDateTo": "",
      "estimatedStartDateToType": "Contains",
      "facilityId": "",
      "productId": "",
      "workEffortId": this.workEffortId,
      "workEffortIdSearchType": "Contains",
      "workEffortName": "",
      "workEffortNameSearchType": "Contains"
    }
    this.productionService.getJobShopProductionList(this.finSize,req).subscribe((res: any) => {
      this.spinner.hide();
      this.statuDescription=res.data[0].statusDesc;
     })
  }
  
  deleteProductiontasks(workid) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) { 
        this.productionService.deleteProductiontasks(this.workEffortId,workid).subscribe((res: any) => {  
          this.getProductiontasks();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
       } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }})}
  deleteProductionMaterial(fromDate,productId,workEffortGoodStdTypeId,workEffortId){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.productionService.deleteProductionMaterial(this.workEffortId,fromDate,productId,workEffortGoodStdTypeId,workEffortId).subscribe((res: any) => {
          this.getProductionMaterial();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }})}
  deleteProductionFixedAsset(fixedAssetId,fromDate,workEffortId){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.productionService.deleteProductionFixedAsset(this.workEffortId,fixedAssetId,fromDate,workEffortId).subscribe((res: any) => { 
          this.getProductionFixedAsset();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }})}

  postproductionRunDeclareAndProduce() {
    const requestData = {
      "inventoryItemTypeId":this.createInventory.value.InventoryItemTypeID,
      "lotId": this.createInventory.value.LotID,
      "quantity": this.createInventory.value.Stockin,
      "workEffortId": this.workEffortId
    }
    this.productionService.postproductionRunDeclareAndProduce(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.getProductionRunDeclareAndProduce();
        this._ToastrService.success("Created Successfully");
       
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }  }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  getProductiontasks() {
    this.spinner.show();
  
    this.productionService.getProductiontasks(this.workEffortId,this.finSize).subscribe((res: any) => {
      this.Productiontasks = res.data;
      this.spinner.hide();
    })
  
  }
  getProductionMaterial() {
    this.spinner.show();
  
    this.productionService.getProductionMaterial(this.workEffortId,this.finSize).subscribe((res: any) => {
      this.ProductionMaterial = res.data;
      this.spinner.hide();
    })
  
  }
  getActualMaterial() {
    this.spinner.show();
  
    this.productionService.getActualMaterial(this.workEffortId).subscribe((res: any) => {
      this.actualMaterial = res.data;
      this.spinner.hide();
    })
  
  }
  
  getProductionFixedAsset() {
    this.spinner.show();
  
    this.productionService.getProductionFixedAsset(this.workEffortId,this.finSize).subscribe((res: any) => {
      this.ProductionFixedAsset = res.data;
      this.spinner.hide();
    })
  
  }
  getProductionJobActualCosts() {
    this.spinner.show();
  
    this.productionService.getProductionJobActualCosts(this.workEffortId,this.finSize).subscribe((res: any) => {
      this.ProductionJobActualCosts = res.data.taskInfo;
      this.spinner.hide();
    })
  
  }
  getProductionRunDeclareAndProduce() {
    this.spinner.show();
  
    this.productionService.getProductionRunDeclareAndProduce(this.workEffortId,this.finSize).subscribe((res: any) => {
      this.productionRunDeclareAndProduce = res.data;
      this.spinner.hide();
    })
  
  }
  
  
  getProductionParty() {
    this.spinner.show();
  
    this.productionService.getProductionParty(this.workEffortId,this.finSize).subscribe((res: any) => {
      this.assignedParties = res.data.assignedParties;
      this.assignedTasks=res.data.assignedTasks;
      this.spinner.hide();
    })
  
  }
  
  changeProductionStatusCancel() {
    this.spinner.show();
    this.productionService.changeProductionStatusCancel(this.workEffortId).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.getProductionRunDetail();
        this.getJobShopProductionList();
        this._ToastrService.success("Status Updated Successfully"); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }  }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  issueProductionRunRoutingTaskATP(id) {
    this.spinner.show();
    this.failIfItemsAreNotAvailable="Y" 
    this.productionService.issueProductionRunRoutingTask(this.workEffortId,id,this.failIfItemsAreNotAvailable).subscribe((res: any) => {  
      if (res.success) {
        this.spinner.hide();
        this.getProductionRunDetail();
        this.getJobShopProductionList();
        this._ToastrService.success("Updated Successfully");
        }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }  }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  issueProductionRunRoutingTask(id) {
    this.spinner.show();
    this.failIfItemsAreNotAvailable="N"
    this.productionService.issueProductionRunRoutingTask(this.workEffortId,id,this.failIfItemsAreNotAvailable).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.getProductionRunDetail();
        this.getJobShopProductionList();
        this._ToastrService.success("Updated Successfully");
        }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        } }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  changeProductionStatusQuickClose() {
    this.spinner.show();
    this.QuickClose="PRUN_CLOSED"
    this.productionService.changeProductionStatusQuick(this.workEffortId,this.QuickClose).subscribe((res: any) => { 
      if (res.success) {
        this.spinner.hide();
        this.getProductionRunDetail();
        this.getJobShopProductionList();
        this._ToastrService.success("Status Updated Successfully"); }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  changeProductionStatusQuickComplete() {
    this.spinner.show();
    this.QuickComplete="PRUN_COMPLETED"
    this.productionService.changeProductionStatusQuick(this.workEffortId,this.QuickComplete).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.getProductionRunDetail();
        this.getJobShopProductionList();
        this._ToastrService.success("Status Updated Successfully");
        }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  changeProductionStatusQuickSchedule() {
    this.spinner.show();
    this.QuickSchedule="PRUN_SCHEDULED"
    this.productionService.changeProductionStatusQuick(this.workEffortId,this.QuickSchedule).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.getProductionRunDetail();
        this.getJobShopProductionList();
        this._ToastrService.success("Status Updated Successfully");
       }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }  }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  changeProductionStatusConfirm() {
    this.spinner.show();
    this.productionService.changeProductionStatusConfirm(this.workEffortId).subscribe((res: any) => { 
      if (res.success) {
        this.spinner.hide();
        this.getProductionRunDetail();
        this.getJobShopProductionList();
        this._ToastrService.success("Status Updated Successfully");
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        } }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  startProduction(id) {
    this.spinner.show();
    this.productionService.startProductionRunTask(this.workEffortId,id).subscribe((res: any) => { 
      if (res.success) {
        this.spinner.hide();
        this.getProductionRunDetail();
        this.getJobShopProductionList();
        this._ToastrService.success("Updated Successfully");
       
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }}
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  quickStartAllProductionRunTasks() {
    this.spinner.show();
    this.productionService.quickStartAllProductionRunTasks(this.workEffortId).subscribe((res: any) => { 
      if (res.success) {
        this.spinner.hide();
        this.getProductionRunDetail();
        this.getJobShopProductionList();
        this._ToastrService.success("Status Updated Successfully");
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  
  getProductionJobassoc() {
    this.spinner.show();
  
    this.productionService.getProductionJobassoc(this.workEffortId,this.finSize).subscribe((res: any) => {
      this.precedingProductionRun = res.data.precedingProductionRun;
      this.succeedingProductionRun=res.data.succeedingProductionRun;
      this.spinner.hide();
    })
  
  }
  reset(){
    this.getProductionJobContent();
    this.contentForm.reset();
  }
  getProductionJobContent() {
    this.spinner.show();
  
    this.productionService.getProductionJobContent(this.workEffortId,this.finSize,this.contentForm.value.ContentLocale,this.contentForm.value.PartyID,this.ProductionJobProductId).subscribe((res: any) => {
      this.productionJobContent = res.data.contents;
      this.productionRunContentsSaveData=res.data.productionRunContents;
      this.workEffortContentTypeId = res.data.workEffortContentTypeId;
      this.spinner.hide();
      for (const value of this.workEffortContentTypeId) {
        this.workEffortContentTypeIdArray.push({
          label: value.description,
          value: value.workEffortContentTypeId,
        });
      }
     
   
    })
  
  }

  getproductionRunId() {
    this.spinner.show();
    this.roletypeId='CONTENT_USER';
        this.accountsService.getproductionRunId(this.workEffortId,this.contentForm.value.ContentLocale,this.pageNo,this.pageSize,this.roletypeId,this.productIdFind).subscribe((res: any) => {
          this.productionJobContents = res.data.contents;
          if (res.success) {
            this.spinner.hide();
            this._ToastrService.success("Sucess");
          }
        
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
          for (const value of err.error.errors) {
            this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
          }
          }
          else{
            this._ToastrService.error(err.error.message);
          }
      });
   }
  
  getProductionJobProductId() {
    this.spinner.show();
  
    this.productionService.getProductionJobProductId(this.workEffortId).subscribe((res: any) => {
      this.ProductionJobProductId=res.data.productId;
   
      this.getProductionJobContent();
      this.spinner.hide();
    })
  
  }
  getProductionRunDetail() {
    this.spinner.show();
  
    this.productionService.getProductionRunDetail(this.workEffortId).subscribe((res: any) => {   
      this.productionRunData=res.data.productionRunData;
      this.productionRunMaterialsData = res.data.productionRunMaterials;
      this.productionRunRoutingTasksData=res.data.productionRunRoutingTasks;
      this.productionRunAssetsData=res.data.productionRunAssetsData;
      this.UnusedProductionRunMaterials=res.data.productionRunMaterials;
      this.returnUnusedMaterialsToWarehouse=res.data.returnUnusedMaterialsToWarehouse;
      this.orderItems=res.data.orderItems;
      this.materialsRequiredByTheRunningTask=res.data.materialsRequiredByTheRunningTask;
    
      this.spinner.hide();
    })
  
  }
  deleteProductionJobContentProductId(contentId,fromDate,workEffortContentTypeId,workEffortId){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {   
        this.productionService.deleteProductionJobContentProductId(this.workEffortId,contentId,fromDate,workEffortContentTypeId,workEffortId).subscribe((res: any) => { 
          this.getProductionJobContent();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      } })}
  removeRoutingTaskAssoc(fromDate,workEffortAssocTypeId,workEffortId,workEffortIdFrom,workEffortIdTo) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "fromDate":fromDate,"workEffortAssocTypeId":workEffortAssocTypeId,"workEffortId":this.workEffortId,
          "workEffortIdFrom": workEffortIdFrom,"workEffortIdTo":workEffortIdTo  
        }
        this.productionService.removeRoutingTaskAssoc(req).subscribe((res: any) => {  
          this.getProductionJobassoc();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      } }) }
  
  
      

  
  getProductionPDF(): void {
    this.spinner.show();
    this.downloading = true;
    this.productionService.getProductionPDF(this.workEffortId)
        .subscribe(value => {
            const blob = new Blob([value], { type: 'application/application/pdf' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;
            }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;
            this.spinner.hide();
           link.download = `${this.workEffortId}.pdf`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            this.downloading = false;
        });
      
}

postProductionJobContent() {
  this.spinner.show();
  this.productionService.postProductionJobContent(this.workEffortId,this.contentSaveArray).subscribe((res: any) => {
    if (res.success) {
      this.getProductionJobContent();
      this.spinner.hide();
      this._ToastrService.success("Created Successfully");
     
    }
  }, (err) => {
    this.spinner.hide();
    if(err.error.errors){
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }  }
      else{
        this._ToastrService.error(err.error.message);
      }
  });

}
oneSelectContent(product) {
  var index = this.contentSaveArray.findIndex(x => x.contentId == product.contentId)
  if (index === -1) {
    const req={
      "contentId": product.contentId,
      "contentName":  product.contentName,
      "contentTypeId":  product.contentTypeId,
      "description":  product.description,
      "drDataResourceId":  product.drDataResourceId,
      "drMimeTypeId":  product.drMimeTypeId,
      "drObjectInfo": product.drObjectInfo,
      "localeString": product.localeString,
      "workEffortContentTypeId":product.workEffortContentTypeId,
      "workEffortId": this.workEffortId,
    }
    this.contentSaveArray.push(req);
  }
  else if (index === 0) {
    this.contentSaveArray.splice(index, 1);
  }
  else {
    this.contentSaveArray.splice(index, 1);
  }
}
markCheckJobAll(e){
  if (e.target.ariaChecked == "true") {
     for (let r of this.productionJobContent) {
      const req={
        "contentId": r.contentId,
        "contentName":  r.contentName,
        "contentTypeId":  r.contentTypeId,
        "description":  r.description,
        "drDataResourceId":  r.drDataResourceId,
        "drMimeTypeId":  r.drMimeTypeId,
        "drObjectInfo": r.drObjectInfo,
        "localeString": r.localeString,
        "workEffortContentTypeId":r.workEffortContentTypeId,
        "workEffortId": this.workEffortId,
      }
       this.contentSaveArray.push(req);
     }
   }
   else {
     this.contentSaveArray = []
   } 
 }
 oneSelectReturnUnused(product) {
  var index = this.returnUnusedArray.findIndex(x => x.workEffortId == product.workEffortId)
  if (index === -1) {
    const req={
      "fromDate":product.fromDate,
        "lotId": product.lotId,
        "productId":product.productId,
        "productionRunId":this.workEffortId,
        "quantity":product.quantity,
        "task":product.quantity,
        "workEffortId": product.workEffortId
  
    }
    this.returnUnusedArray.push(req);
  }
  else if (index === 0) {

    this.returnUnusedArray.splice(index, 1);
  }
  else {
    this.contentSaveArray.splice(index, 1);

  }
}
 markUnusedCheck(e){ 
  if (e.target.ariaChecked == "true") {
     for (let r of this.returnUnusedMaterialsToWarehouse) {
      const req={
        "fromDate":r.fromDate,
        "lotId": r.lotId,
        "productId":r.productId,
        "productionRunId":this.workEffortId,
        "quantity":r.quantity,
        "task":r.quantity,
        "workEffortId": r.workEffortId
    
      }
       this.returnUnusedArray.push(req);
     }
   }
   else {
     this.returnUnusedArray = []
   } 
 }
 
 submitUnusedMaterials() {
  this.spinner.show();
  this.materialsRequiredByTheRunningTaskArray=[];
  for (let r of this.materialsRequiredByTheRunningTask) {
    const req={
      "description":r.internalName,
    "failIfItemsAreNotAvailable":r.failIfItemsAreNotAvailable,
    "failIfItemsAreNotOnHand": '',
    "fromDate":r.fromDate,
    "locationSeqId":r.locationSeqId,
    "lotId":r.lotId,
    "productId":r.productId,
    "quantity":r.estimatedQuantity,
    "reasonEnumId":'',
    "secondaryLocationSeqId":r.secondaryLocationSeqId,
    "workEffortId":r.workEffortId
    }
     this.materialsRequiredByTheRunningTaskArray.push(req);
   }
  this.productionService.submitUnusedMaterials(this.materialsRequiredByTheRunningTaskArray).subscribe((res: any) => {
    if (res.success) {
      this.spinner.hide();
      this.getProductionRunDetail();
      this.getJobShopProductionList();
      this._ToastrService.success("Created Successfully");
     
    }
  }, (err) => {
    this.spinner.hide();
    if(err.error.errors){
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      } }
      else{
        this._ToastrService.error(err.error.message);
      }
  });

}
 productionRunTaskReturnMaterial() {
  this.spinner.show();
  this.productionService.productionRunTaskReturnMaterial(this.returnUnusedArray).subscribe((res: any) => {
    if (res.success) {
      this.spinner.hide();
      this.getProductionRunDetail();
      this.getJobShopProductionList();
      this._ToastrService.success("Created Successfully");
     
    }
  }, (err) => {
    this.spinner.hide();
    if(err.error.errors){
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this._ToastrService.error(err.error.message);
      }
  });

}
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  changeactiveCategoryFunctionA(tab: number) {
    this.activeCategoryA = tab;
  }
  
  createQuantity(eworkId) {
    this.router.navigate(["production/add-quantity-produced"],{ queryParams: { workEffortId : this.workEffortId,workId:eworkId}})

  }
  createProduction(eworkId) {
    this.router.navigate(["production/production-run-declaration"],{ queryParams: { workEffortId : this.workEffortId,workId:eworkId}})

  }
  createJobTask() {
    this.router.navigate(["production/create-jobshop-task"],{ queryParams: { workEffortId : this.workEffortId}})

  }
  updateTask(eid) {
    this.router.navigate(["production/create-jobshop-task"],{ queryParams: { workEffortId : this.workEffortId,taskId:eid}})

  }
   createJobAssoc() {
     this.router.navigate(["production/create-jobshop-assocs"],{ queryParams: { workEffortId : this.workEffortId}})

   }
  createActualMaterial() {
    this.router.navigate(["production/create-actual-material"],{ queryParams: { workEffortId : this.workEffortId}})

  }
  createJobMaterial() {
    this.router.navigate(["production/create-jobshop-material"],{ queryParams: { workEffortId : this.workEffortId}})

  }
  updateMaterial(eproductId,eestimatedQuantity,eworkId) {
    this.router.navigate(["production/create-jobshop-material"],{ queryParams: { workEffortId : this.workEffortId,productId:eproductId,estimatedQuantity:eestimatedQuantity,workId:eworkId}})

  }
 
  createJobFixedAsset() {
    this.router.navigate(["production/create-jobshop-fixedasset"],{ queryParams: { workEffortId : this.workEffortId}})

  }
  updateFixedAsset(efixedAssetId,efromDate,eworkId) {
    this.router.navigate(["production/create-jobshop-fixedasset"],{ queryParams: { workEffortId : this.workEffortId,fixedAssetId:efixedAssetId,fromDate:efromDate,workId:eworkId}})

  }
 
  createJobParties() {
    this.router.navigate(["production/create-jobshop-parties"],{ queryParams: { workEffortId : this.workEffortId}})

  }
  updateMaterialUnused() {
    this.router.navigate(["production/update-material"],{ queryParams: { workEffortId : this.workEffortId}})

  }
  
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
