<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Classification
                </span>
                <span class="color-black pl-1" > >Add Party Classification
                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show" ><a>
                        Add Party Classification
                    </a></li>
                    <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show" ><a>
                        Update Party Classification
                    </a></li>
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createClassification">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">
                                                    Party ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text"  formControlName="partyId"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Party Id" readonly>
                                            </div>


                                            <div class="col-lg-3 form-group rightFormA" *ngIf="!this.show">
                                                <label for="exampleInputEmail1">
                                                    Classification Group ID</label>
                                            </div>
                                            <div class="col-lg-2" *ngIf="!this.show">
                                                <p-dropdown [options]="ClassificationGroupIdArray" filter="true" formControlName="ClassificationGroupID"
                                                placeholder="Enter Classification Group ID"
                                                name="Parent Type ID">
                                            </p-dropdown>
                                            </div>

                                          
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">
                                                    From Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" *ngIf="!this.show"  formControlName="FromDate"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Resume Date">
                                                <input type="date" *ngIf="this.show" formControlName="FromDate"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Resume Date" readonly>
                                            </div>


                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">
                                                    Through Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date"  formControlName="ThroughDate"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Resume Text">
                                            </div>

                                          
                                        </div>
                                    </div>
                                  
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 57%;">
                               
                                <button type="submit" *ngIf="!this.show" (click)="onSubmit();"  class="btn btn-secondary submit-btn">Create</button>
                                 
                                <button type="submit" *ngIf="this.show" (click)="onUpdate();"  class="btn btn-secondary submit-btn">Update</button>
                                
                                
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div> -->

<div class="modal-header">
    <span class="color-black pl-1">Add Party Classification</span>
    <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden" #closebutton >&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex">
      <div class=" bg-white color-grey" style="width: 100%;">
          <div class="w3-card-4 classCardPopUp" style="width: 100%;">
              <div class="container-fluid">
                  <ul class="tabbing-section tabbing-accordians tabClass">

                    <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show" ><a>
                        Add Party Classification
                    </a></li>
                    <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show" ><a>
                        Update Party Classification
                    </a></li>
                  </ul>
              </div>
              
              <div class="create-new-leade mt-2">
                <div class="container-fluid">
                    <div class="row">
                        <form  class="w-100" [formGroup]="createClassification">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-4 form-group rightForm">
                                            <label for="exampleInputEmail1">
                                                Party ID</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text"  formControlName="partyId"  class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Party Id" readonly>
                                        </div>


                                        <div class="col-lg-2 form-group rightFormA" *ngIf="!this.show">
                                            <label for="exampleInputEmail1">
                                                Classification Group ID</label>
                                        </div>
                                        <div class="col-lg-3" *ngIf="!this.show">
                                            <p-dropdown [options]="ClassificationGroupIdArray" filter="true" formControlName="ClassificationGroupID"
                                            placeholder="Enter Classification Group ID"
                                            name="Parent Type ID">
                                        </p-dropdown>
                                        </div>

                                      
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-4 form-group rightForm">
                                            <label for="exampleInputEmail1">
                                                From Date</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="date" *ngIf="!this.show"  formControlName="FromDate"  class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Resume Date">
                                            <input type="date" *ngIf="this.show" formControlName="FromDate"  class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Resume Date" readonly>
                                        </div>


                                        <div class="col-lg-2 form-group rightFormA">
                                            <label for="exampleInputEmail1">
                                                Through Date</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="date"  formControlName="ThroughDate"  class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Resume Text">
                                        </div>

                                      
                                    </div>
                                </div>
                              
                            </div>
                            

                        </form>
                        <div class="main-submit-button" style="margin-left: 67%;">
                           
                            <button type="submit" *ngIf="!this.show" (click)="onSubmit();"  class="btn btn-secondary submit-btn">Create</button>
                             
                            <button type="submit" *ngIf="this.show" (click)="onUpdate();"  class="btn btn-secondary submit-btn">Update</button>
                            
                            
                            
                          
                        </div>
                    </div>
                </div>

            </div>
                

              
              
             </div>
      </div>
  </div>




