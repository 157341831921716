<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Party Content
                </span>
                <span class="color-black pl-1" > >Attach Content
                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''"><a>
                        Attach Content
                    </a></li>
                    
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="Editform">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12" *ngIf="show">
                                        <div class="row">
        
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                  Content Id</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                              <label>{{this.contentId}}</label>
                                            </div>
                                          
        
                                           
        
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
        
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                   Parent Content Type ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown *ngIf="!show"  filter="true" formControlName="partyContentTypeId"
                                               
                                                [options]="partyContentArray" placeholder="Parent Content Type ID"
                                                optionlabel="label">
                                                </p-dropdown>
                                                <input type="text" *ngIf="this.show"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp"   placeholder="Enter Parent ContentTypeId"
                                                formControlName="partyContentTypeId" readonly>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Content Type ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true"  
                                                placeholder="Enter Content Type ID"
                                                name="Parent Type ID"
                                                formControlName="ContentTypeID"
                                                [options]="PartyContentTypeIdListArray" optionlabel="label">
                                            </p-dropdown>
                                         
                                            </div>
        
                                           
        
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
        
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                    From Date</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date"  *ngIf="!this.show"    class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter From Date"
                                                formControlName="fromDate"> 
                                                <input type="date" *ngIf="this.show"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp"   placeholder="Enter Parent ContentTypeId"
                                                formControlName="fromDate" readonly>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Through</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date"    class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Through Date"
                                                formControlName="throughDate">
                                         
                                            </div>
        
                                           
        
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
        
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                    Status ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  filter="true" formControlName="StatusID"
                                              
                                                [options]="PartyStatusIdListArray" placeholder="Status ID"
                                                optionlabel="label">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Data Category ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true"  
                                                placeholder="Enter Data Category ID"
                                                name="Parent Type ID"
                                                formControlName="DataCategoryID"
                                                [options]="DataCategoryIdListArray" optionlabel="label">
                                            </p-dropdown>
                                         
                                            </div>
        
                                           
        
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
        
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                    Is Public</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  filter="true" formControlName="IsPublic"
                                               
                                                [options]="isPublic" placeholder="Is Public"
                                                optionlabel="label">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Attach Content</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input class="form-control" type="file" style="width: 100%!important;" id="exampleInputEmail1" formControlName="file"
                                                aria-describedby="emailHelp"  accept="" (change)="fileProgress($event)" style="line-height: 0.9!important;">
                                            </div>
        
                                           
        
                                        </div>
                                    </div>
                                  
                                  
                                 
                                   
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 66%;">
                               
                                <button type="submit" *ngIf="!show" (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>
                                
                                <button type="submit" *ngIf="show" (click)="onUpdate();" class="btn btn-secondary submit-btn">Update</button>
                                
                                
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div> -->

<div class="modal-header">
    <span class="color-black pl-1">Party Content</span>
    <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden" #closebutton >&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex">
      <div class=" bg-white color-grey" style="width: 100%;">
          <div class="w3-card-4 classCardPopUp" style="width: 100%;">
              <div class="container-fluid">
                  <ul class="tabbing-section tabbing-accordians tabClass">

                    <li [ngClass]="activeCategory==2?'active':''"><a>
                        Attach Content
                    </a></li>
                  </ul>
              </div>
              
            
              <div class="create-new-leade mt-2">
                <div class="container-fluid">
                    <div class="row">
                        <form  class="w-100" [formGroup]="Editform">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12" *ngIf="show">
                                    <div class="row">
    
                                        <div class="col-lg-4 form-group classInput">
                                            <label for="exampleInputEmail1">
                                              Content Id</label>
                                        </div>
                                     
                                        <div class="col-lg-3">
                                          <label>{{this.contentId}}</label>
                                        </div>
                                      
    
                                       
    
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
    
                                        <div class="col-lg-4 form-group classInput">
                                            <label for="exampleInputEmail1">
                                               Parent Content Type ID</label>
                                        </div>
                                     
                                        <div class="col-lg-3">
                                            <p-dropdown *ngIf="!show"  filter="true" formControlName="partyContentTypeId"
                                           
                                            [options]="partyContentArray" placeholder="Parent Content Type ID"
                                            optionlabel="label">
                                            </p-dropdown>
                                            <input type="text" *ngIf="this.show"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp"   placeholder="Enter Parent ContentTypeId"
                                            formControlName="partyContentTypeId" readonly>
                                        </div>
                                        <div class="col-lg-2 form-group classInputA">
                                            <label for="exampleInputEmail1">
                                                Content Type ID</label>
                                        </div>
                                     
                                        <div class="col-lg-3">
                                            <p-dropdown filter="true"  
                                            placeholder="Enter Content Type ID"
                                            name="Parent Type ID"
                                            formControlName="ContentTypeID"
                                            [options]="PartyContentTypeIdListArray" optionlabel="label">
                                        </p-dropdown>
                                     
                                        </div>
    
                                       
    
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
    
                                        <div class="col-lg-4 form-group classInput">
                                            <label for="exampleInputEmail1">
                                                From Date</label>
                                        </div>
                                     
                                        <div class="col-lg-3">
                                            <input type="date"  *ngIf="!this.show"    class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter From Date"
                                            formControlName="fromDate"> 
                                            <input type="date" *ngIf="this.show"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp"   placeholder="Enter Parent ContentTypeId"
                                            formControlName="fromDate" readonly>
                                        </div>
                                        <div class="col-lg-2 form-group classInputA">
                                            <label for="exampleInputEmail1">
                                                Through</label>
                                        </div>
                                     
                                        <div class="col-lg-3">
                                            <input type="date"    class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Through Date"
                                            formControlName="throughDate">
                                     
                                        </div>
    
                                       
    
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
    
                                        <div class="col-lg-4 form-group classInput">
                                            <label for="exampleInputEmail1">
                                                Status ID</label>
                                        </div>
                                     
                                        <div class="col-lg-3">
                                            <p-dropdown  filter="true" formControlName="StatusID"
                                          
                                            [options]="PartyStatusIdListArray" placeholder="Status ID"
                                            optionlabel="label">
                                            </p-dropdown>
                                        </div>
                                        <div class="col-lg-2 form-group classInputA">
                                            <label for="exampleInputEmail1">
                                                Data Category ID</label>
                                        </div>
                                     
                                        <div class="col-lg-3">
                                            <p-dropdown filter="true"  
                                            placeholder="Enter Data Category ID"
                                            name="Parent Type ID"
                                            formControlName="DataCategoryID"
                                            [options]="DataCategoryIdListArray" optionlabel="label">
                                        </p-dropdown>
                                     
                                        </div>
    
                                       
    
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
    
                                        <div class="col-lg-4 form-group classInput">
                                            <label for="exampleInputEmail1">
                                                Is Public</label>
                                        </div>
                                     
                                        <div class="col-lg-3">
                                            <p-dropdown  filter="true" formControlName="IsPublic"
                                           
                                            [options]="isPublic" placeholder="Is Public"
                                            optionlabel="label">
                                            </p-dropdown>
                                        </div>
                                        <div class="col-lg-2 form-group classInputA">
                                            <label for="exampleInputEmail1">Attach Content</label>
                                        </div>
                                     
                                        <div class="col-lg-3">
                                            <input class="form-control" type="file" style="width: 100%!important;" id="exampleInputEmail1" formControlName="file"
                                            aria-describedby="emailHelp"  accept="" (change)="fileProgress($event)" style="line-height: 0.9!important;">
                                        </div>
    
                                       
    
                                    </div>
                                </div>
                              
                              
                             
                               
                            </div>
                            

                        </form>
                        <div class="main-submit-button" style="margin-left: 76%;">
                           
                            <button type="submit" *ngIf="!show" (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>
                            
                            <button type="submit" *ngIf="show" (click)="onUpdate();" class="btn btn-secondary submit-btn">Update</button>
                            
                            
                            
                          
                        </div>
                    </div>
                </div>

            </div>

              
              
             </div>
      </div>
  </div>


