<div class="container" *ngIf="!token">
	<div class="d-flex justify-content-center own-login-container">
		<div class="card" style="border:none!important;height:500px!important">
			<div class="card-header" style="background-color: white!important; border:none!important;">
				<h1 class="text-center" style="color:black!important; font-size:30pxImportant">Reset Password</h1>
			</div>
			<div class="card-body" style="padding: 2.5rem!important;background-color: white!important;">
				<form>
					<div class="mb-4" style="margin-bottom: 3rem!important;">
						<div class="input-group form-group">
							<div class="input-group-prepend">
							</div>
							<div>
							<input type="email" style="width: 140%!important;border-radius: 0px !important;
							border-top: none!important;border-left: none!important;border-right: none!important;" required #email="ngModel" name="email" [(ngModel)]="resetEmail"
							class="form-control"
								 placeholder="Email-Id" pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$">
							<!-- { this.username} -->
						</div>
							<br>
							<div class="vaiidate-field" *ngIf="email.errors && ( email.touched || resetPasswordForm.submitted)">
								<p [hidden]="!email.errors.required"><span style="color:red!important">Email is required!</span></p>
								<p [hidden]="!email.errors.pattern"><span style="color:red!important">Please enter a valid email!</span></p>
							  </div>
						</div>
					</div>
					<div class="mb-4" style="margin-bottom: 2rem!important;">
						<div class="input-group form-group">
							<div class="input-group-prepend">
							</div>
							<input type="text" class="form-control" #tenant="ngModel"  name="tenant"
								[(ngModel)]="tenantId" placeholder="TenantId" style="width: 147%!important;border-radius: 0px !important;
								border-top: none!important;border-left: none!important;border-right: none!important;">
						</div>
					</div>
				
				
				
				
					
					<div class="form-group">
						<button type="submit"  value="Login" (click)="submit()" [ngClass]="resetEmail!='' ? 'btn-primary' : 'btn-secondary'"
							class="btn btnHover float-right" style="border-radius: 7px !important;
							height: 35px!important;width: 105px!important;font-size: 18px!important;">Request</button>
							<button type="submit"  value="Login" (click)="submit()"
							class="btn btn-danger" (click)="back();" style="border-radius: 7px !important;
							height: 35px!important;width: 105px!important;font-size: 18px!important;">Cancel</button>
					</div>
				</form>
			</div>
			<!-- <div class="card-footer">
				<div class="d-flex justify-content-center links text-white">
					Don't have an account?<a href="#">Sign Up</a>
				</div>
				<div class="d-flex justify-content-center">
					<a href="#">Login</a>
				</div>
			</div> -->
		</div>
	</div>
</div>

<div class="app-base-layout" *ngIf="token">
	<app-sidebar></app-sidebar>
	<div class="w-100 main-container-row">
		<app-header></app-header>
		<app-main-container></app-main-container>
		<app-footer></app-footer>
	</div>
</div>