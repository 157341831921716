<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12" *ngIf="timeSheetList">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Timesheet of {{this.timeSheetList.timesheetId}} </span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Back</button>
            </div>
            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                        <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                        Timesheet Information</a></li>
                        <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                        Role</a></li>


                    </ul>
                </div>
                <div class="bg-white color-grey" style="padding: 15px 15px;">
                    <div class="w3-card-4 classCard">
                        <div class="container-fluid">
                            <!-- <header class="w3-container w3-blue">
                    <div class="header-tabs">
                      <h4 (click)="changeactiveCategoryFunction(1)" class=" common-styling h4Margin">
                        Timesheet Information
                      </h4>
                      
                      
                      <span class="edit-buttons hCreate">

                          <a  data-toggle="modal" 
                          data-target="#exampleModalCenter">Update</a>
                        </span>
                    </div>
                  </header> -->
                            <!-- <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left: 9px;">
                    <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                      Timesheet Information </a></li>
                        <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                          Role </a></li>
                  </ul> -->
                        </div>
                        <div style="    margin-top: 1.5px;">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                            <div class="w-100">
                                                <div class="panel-group">
                                                    <div *ngIf="activeCategory==1">
                                                        <div class="header-tabs">
                                                            <h4 class="common-styling">
                                                                Timesheet Information
                                                            </h4>
                                                            <span class="edit-buttons hCreate">

                                              <a  data-toggle="modal" 
                                              data-target="#exampleModalCenter">Update</a>
                                            </span>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">

                                                                    <!-- *ngIf="employeeDetail" -->
                                                                    <div *ngIf="timeSheetList" class="row">
                                                                        <div class="article-container">
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Party ID</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.timeSheetList.partyId}} </span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Party Name</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.timeSheetList.partyName}} </span>
                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">From Date</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.timeSheetList.fromDate | date :'yyyy-MM-dd hh:mm:ss'}} </span>
                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">To Date</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.timeSheetList.thruDate | date :'yyyy-MM-dd hh:mm:ss'}} </span>
                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Status ID</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.timeSheetList.statusId}} </span>
                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Client ID</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.timeSheetList.clientPartyId}} </span>
                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Approved By User LoginId </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.timeSheetList.approvedByUserLoginId}} </span>
                                                                            </div>



                                                                        </div>



                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategory==2">
                                                        <div class="header-tabs">
                                                            <h4 class="common-styling">
                                                                Role
                                                            </h4>
                                                            <span class="edit-buttons" data-toggle="modal" data-target="#exampleModalCenterRole">Create New Role</span>
                                                        </div>

                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <!-- [value]="employeeDetailRelatedAccount" -->
                                                                        <p-table [paginator]="true" [rows]="rows" scrollWidth="100%" [value]="roleData" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code">


                                                                                        <div style="color: white;" class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox> Role
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>

                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">Party
                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Action</div>
                                                                                    </th>

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td >
                                                                                        {{product.roleTypeId}}


                                                                                    </td>
                                                                                    <td> {{product.partyName}}</td>
                                                                                    <td>
                                                                                            <button type="submit" (click)="onDelete(product.timesheetId,product.roleTypeId,product.partyId);"
                                                                                            class="btn btn-danger ">Delete</button>
                                                                                    </td>


                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>

                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Timesheet</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width:100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                Update Employee Timesheet</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="createTimesheetFormService.create_timesheet_form" [formGroup]="createTimesheetFormService.create_timesheet_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Employee ID <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" placeholder="Enter Employee ID" formControlName="partyId" [options]="partyIdArray" optionlabel="label"></p-dropdown>
                                                        <small class="text-danger" *ngIf="!!createTimesheetFormService.create_timesheet_form.controls.partyId.invalid && !!createTimesheetFormService.create_timesheet_form.controls.partyId.touched"> Employee Id   is required!</small>



                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Approver Party </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="clientPartyId" [options]="partyIdArray" optionlabel="label" placeholder="Enter Approver Party"></p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="From Date">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="thruDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Through Date">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Status <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="statusId" [options]="statusArray" optionlabel="label" placeholder="Enter Status"></p-dropdown>
                                                        <small class="text-danger" *ngIf="!!createTimesheetFormService.create_timesheet_form.controls.statusId.invalid && !!createTimesheetFormService.create_timesheet_form.controls.statusId.touched">Status  is required!</small>


                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Comments </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="comments" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Approved By User Login ID </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="approvedByUserLoginId" [options]="userLoginArray" optionlabel="label" placeholder="Approved by user login Id"></p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2"></div>

                                                </div>
                                            </div>


                                        </div>
                                        <div class="main-submit-button" style="    margin-right: 7%;">
                                            <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>
                                            <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenterRole" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterRole" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Role</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton1>&times;</span>
            </button>

            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard" style="width: 96%;
          margin-left: 2%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategoryMain==2?'active':''"><a>
                  Add New Role </a></li>


                        </ul>
                    </div>

                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form [formGroup]="fileUploadForm" class="w-100">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInputAb">
                                                    <label for="exampleInputEmail1">Role</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown formControlName="roleTypeId" [options]="roleArray" optionlabel="label" filter="true" placeholder="Select Role"></p-dropdown>
                                                </div>

                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Party</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" placeholder="Enter Party" formControlName="partyId" [options]="partyIdArray" optionlabel="label"></p-dropdown>


                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                    <div class="main-submit-button" style="margin-right: 37%;">
                                        <button type="submit" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>

                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
                <br>

            </div>

        </div>
    </div>
</div>
<!--code end-->

<ngx-spinner></ngx-spinner>