<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">

            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div> 
                    <span class="d-flex">

                        <button type="submit" (click)="homeButton();"
                            class=" btn btn-outline-secondary mr-2">Home</button>

                        <button type="submit" (click)="cancelSubmit();"
                            class="btn btn-danger buttonclass ">Back</button>
                    </span>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==1?'active':''"><a>
                                    Fiscal Year</a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" >
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">
                                                <div *ngIf="activeCategory==1">
                                                    <div class="panel-body">
                                                        <form [formGroup]="fiscalForm">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">Fiscal
                                                                                    Year
                                                                                    <span style="color: red;">*</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <input type="text" name="partyId"
                                                                                    class="form-control"
                                                                                    formControlName="fiscalYear"
                                                                                    id="partyId"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Fiscal Year">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2"></div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">First
                                                                                    Fiscal Month
                                                                                    <span style="color: red;">*</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <p-dropdown
                                                                                    [options]="fiscalMonth"
                                                                                    formControlName="firstFiscalMonth"
                                                                                    filter="true" optionlabel="label" 
                                                                                    placeholder="Enter First Fiscal Month">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2"></div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label
                                                                                    for="exampleInputEmail1">Numeration
                                                                                    Prefix</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <input type="text" name="externalId"
                                                                                    class="form-control"
                                                                                    formControlName="numerationPrefix"
                                                                                    id="externalId"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Numeration Prefix">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2"></div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">Base
                                                                                    Currency
                                                                                    <span style="color: red;">*</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <p-dropdown
                                                                                    [options]="currencyArray"
                                                                                    formControlName="baseCurrency"
                                                                                    filter="true" optionlabel="label"
                                                                                    placeholder="Enter Base Currency">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12 col-12 main-submit-button ">
                                                                <button _ngcontent-cta-c128="" type="submit"
                                                                    (click)="goToCompanyInfo()"
                                                                    class="btn btn-secondary submit-btn">Previous</button>&nbsp;&nbsp;
                                                                <button _ngcontent-cta-c128="" type="submit"
                                                                    (click)="goToEmailConfiguration()"
                                                                    class="btn btn-secondary submit-btn">Save &
                                                                    Next</button>&nbsp;&nbsp;
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>