import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { SortEvent } from 'primeng/api';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router,ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-credit-memo',
    templateUrl: './credit-memo.component.html',
    styleUrls: ['./credit-memo.component.css']
})
export class CreditMemoComponent implements OnInit {
    @ViewChild('closebuttonN') closebuttonN;

    activeCategoryInv = 2;
    createCreditMemoForm: FormGroup;
    currencyList: any[];
    invoiceId: string;
    editMode: boolean;
    creditMemo: any;
    postalAddress: any[];

    array = [
        {
            label: 'Your Company Name',
            value: 'Company'
        }
    ];
    
    
    total=0;
    activeCategory=1;
    activeCategoryMain=3;
    isShown= false; 
    rowNumber=0;
    pageSize = 10;
    rows = 50;
    creditMemoForm: FormGroup;
    opportunityIdArray = [
        {
            label: 'Contains',
            value: 'Contains'
        },
        {
            label: 'Equals',
            value: 'Equals'
        },
        {
            label: 'Is Empty',
            value: 'Is Empty'
        },
        {
            label: 'Begins With',
            value: 'Begins With'
        },
    ];
    greaterArray = [
        {
            label: 'Equals',
            value: 'Equals'
        },
        {
            label: 'Same Day',
            value: 'Same Day'
        },
        {
            label: 'Greater Than From Day Start',
            value: 'Greater Than From Day Start'
        },
        {
            label: 'Greater Than',
            value: 'Greater Than'
        }
    ];
    smallerArray = [
        {
            label: 'Less Than',
            value: 'Less Than'
        },
        {
            label: 'Up To Day',
            value: 'Up To Day'
        },
        {
            label: 'Up Through Day',
            value: 'Up Through Day'
        },
        {
            label: 'Is Empty',
            value: 'Is Empty'
        }
    ];
    creditMemos: any[];
    partyIds: any[];
    statusIds: any[];
    agreementType: string;
    constructor(
        readonly _ToastrService: ToastrService,
        readonly _AccountingApService: AccountingApService,
        readonly _FormBuilder: FormBuilder,
        readonly router: Router,
        readonly spinner: NgxSpinnerService,
        readonly  _Router: Router,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _GlobalResourceService: GlobalResourceService,
    ) {

        this.partyIds = [];

        this.createCreditMemoForm = this._FormBuilder.group({
            currencyUomId: ['', [Validators.required]],
            description: [''],
            dueDate: [new Date()],
            invoiceDate: [new Date()],
            invoiceMessage: [''],
            partyId: ['Company', [Validators.required]],
            partyIdFrom: [''],
            referenceNumber: [''],
            contactMechId: [''],
            shippingContactMechId: ['']
        });
        

        this.creditMemoForm = this._FormBuilder.group({

            currencyUomId: ['', [Validators.required]],
            dueDate: [new Date()],
            invoiceDate: [new Date()],
            partyId: ['Company', [Validators.required]],
            partyIdFrom: [''],
            contactMechId: [''],
            shippingContactMechId: [''],

            description: [''],
            descriptionSearchType: ['Contains'],
            dueDateFrom: [''],
            dueDateFromSearchType: ['Equals'],
            dueDateTo: [''],
            dueDateToSearchType: ['Equals'],
            fromPartyId: [''],
            invoiceDateFrom: [''],
            invoiceDateFromSearchType: ['Equals'],
            invoiceDateTo: [''],
            invoiceDateToSearchType: ['Equals'],
            invoiceId: [''],
            invoiceIdSearchType: ['Contains'],
            invoiceMessage: [''],
            invoiceMessageSearchType: ['Contains'],
            paidDateFrom: [''],
            paidDateFromSearchType: ['Equals'],
            paidDateTo: [''],
            paidDateToSearchType: ['Equals'],
            referenceNumber: [''],
            referenceNumberSearchType: ['Contains'],
            statusId: ['']
        });
        this.statusIds = [];
        this.creditMemos = [];
        this.partyIds = [];

        this.currencyList = [];
        this.invoiceId = '';
        this.editMode = false;
        this.postalAddress = [];
    }


    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {

        this.invoiceId = this._ActivatedRoute.snapshot.queryParams.invoiceId;
        if (this.invoiceId) {
            this.editMode = true;
            this.creditMemoSummary();
        }

        this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
        this.getPartyIds();
        this.getStatus();
        this.searchCreditMemo();

        this.getPartyIds1();

        this.getCurrencyList();
    }

    creditMemoSummary(): void {
        this.spinner.show();
        this._AccountingApService.creditMemoSummary(this.invoiceId)
            .then(data => {
                this.creditMemo = data.data;
                this.spinner.hide();
                this.createCreditMemoForm.patchValue({
                    ...data.data, ...{
                        dueDate: new Date(data.data.dueDate),
                        invoiceDate: new Date(data.data.dueDate)
                    }
                });
                this.getPostalAddress(data.data.partyId);
            });
           
    }
    getPostalAddress(partyId: string): void {
        this.spinner.show();
        this._AccountingApService.creditMemoPostalAddress(partyId)
            .then(data => {
                this.spinner.hide();
                this.postalAddress = data.data.map(value => {
                    return {
                        label: value.address1,
                        value: value.contactMechId
                    };
                });
              
            });
           
    }
    getPartyIds1(): void {
        this.spinner.show();
        this._GlobalResourceService.getSupplierList()
            .then(data => {
                this.spinner.hide();
                this.partyIds = data.data.map(value => {
                    return {
                        label: value.partyId,
                        value: value.partyId
                    };
                });
            });
           
    }
    getCurrencyList(): void {
        this.spinner.show();
        this._GlobalResourceService.getCurrency()
            .then(data => {
                this.spinner.hide();
                this.currencyList = data.data.currencyList.map(value => {
                    return {
                        label: value.uomId,
                        value: value.uomId
                    };
                });
            });
          
    }
    update(): void {
        this.spinner.show();
        this._AccountingApService.updateCreditMemo({
            ...this.creditMemo, ...this.createCreditMemoForm.value, ...{
                dueDate: moment(this.createCreditMemoForm.value.dueDate).format('YYYY-MM-DD HH:mm:ss'),
                invoiceDate: moment(this.createCreditMemoForm.value.invoiceDate).format('YYYY-MM-DD HH:mm:ss'),
                oldRefNum: this.createCreditMemoForm.value.referenceNumber,
                referenceNumber: this.createCreditMemoForm.value.referenceNumber
            }
        })
            .then(data => {
                if (data.sucess) {
                    this._ToastrService.success('Updated');
                    this.createCreditMemoForm.reset();
                    this.spinner.hide();
                    this.searchCreditMemo();
                    this._Router.navigate(['/financial/payable/credit-memo']);
                }
            });
           
    }
    resetForm(){
        this.creditMemoForm.reset();
    }

    submit(): void {
        this.spinner.show();
        if (this.editMode) {
            this.update();
        } else {
            delete this.createCreditMemoForm.value.contactMechId;
            delete this.createCreditMemoForm.value.shippingContactMechId;
            this._AccountingApService.createCreditMemo({
                ...this.createCreditMemoForm.value, ...{
                    dueDate: moment(this.createCreditMemoForm.value.dueDate).format('YYYY-MM-DD HH:mm:ss'),
                    invoiceDate: moment(this.createCreditMemoForm.value.invoiceDate).format('YYYY-MM-DD HH:mm:ss')
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.createCreditMemoForm.reset();
                        this.closebuttonN.nativeElement.click();
                        this.searchCreditMemo();
                        this._Router.navigate(['/financial/payable/credit-memo']);
                    }
                });
        }
        
    }

    invoiceType(){
        this.isShown = !this.isShown;
      }
      enterBills(){
        this.router.navigate(["/financial/payable/invoices"])
      }
      payableMakePayment(){
        this.router.navigate(["/financial/payable/payment-payables"])
      }
      payableMakeGroupPayment(){
        this.router.navigate(["/financial/payable/payment-group"])
      }
      salesInvoice(){
        this.router.navigate(["/financial/receiveable/invoices"])
      }
      receivePayment(){
        this.router.navigate(["/financial/receiveable/payments"])
      }
      receiveGroupPayments(){
        this.router.navigate(["/financial/receiveable/payment-group"])
      }
      changeactiveCategoryFunctionPurchae(){
        this.router.navigate([`/financial/payable/invoices`]);
      }
      changeactiveCategoryFunctionCredit(){
        this.router.navigate([`/financial/payable/credit-memo`]);
      }
      changeactiveCommission(){
        this.router.navigate([`/financial/payable/commission-invoice`]);
      }
    getPartyIds(): void {
        this.spinner.show();
        this._GlobalResourceService.getPartyGroups()
            .then(data => {
                this.partyIds = data.data.PersonsGroups.map(value => {
                    return {
                        label: value.partyId,
                        value: value.partyId
                    };
                });
                this.spinner.hide();
            });
    }
    getStatus(): void {
        this.spinner.show();
        this._AccountingApService.getCreditMemoStatus()
            .then(data => {
                this.statusIds = data.data.invoiceStatus.map(value => {
                    return {
                        label: value.description,
                        value: value.statusId
                    };
                });
                this.spinner.hide();
            });
    }
    reset(){
        
    this.creditMemoForm.controls['invoiceId'].reset();
    this.creditMemoForm.controls['description'].reset();
    this.creditMemoForm.controls['fromPartyId'].reset();
    this.creditMemoForm.controls['statusId'].reset();
    this.creditMemoForm.controls['invoiceDateFrom'].reset();
    this.creditMemoForm.controls['invoiceDateTo'].reset();
    this.creditMemoForm.controls['dueDateFrom'].reset();
    this.creditMemoForm.controls['dueDateTo'].reset();
    this.creditMemoForm.controls['paidDateFrom'].reset();
    this.creditMemoForm.controls['paidDateTo'].reset();
    this.creditMemoForm.controls['referenceNumber'].reset();
    this.creditMemoForm.controls['invoiceMessage'].reset();

    this.searchCreditMemo();
    }
    
    searchCreditMemo(): void {
        this.spinner.show();
        this._AccountingApService.getCreditMemoList({ pageNo: 1, pageSize: 200 }, {
            ...this.creditMemoForm.value, ...{
                dueDateFrom: this.creditMemoForm.value.dueDateFrom ? moment(this.creditMemoForm.value.dueDateFrom).format('YYYY-MM-DD HH:mm:ss') : '',
                dueDateTo: this.creditMemoForm.value.dueDateTo ? moment(this.creditMemoForm.value.dueDateTo).format('YYYY-MM-DD HH:mm:ss') : '',
                invoiceDateFrom: this.creditMemoForm.value.invoiceDateFrom ? moment(this.creditMemoForm.value.invoiceDateFrom).format('YYYY-MM-DD HH:mm:ss') : '',
                invoiceDateTo: this.creditMemoForm.value.invoiceDateTo ? moment(this.creditMemoForm.value.invoiceDateTo).format('YYYY-MM-DD HH:mm:ss') : '',
                paidDateFrom: this.creditMemoForm.value.paidDateFrom ? moment(this.creditMemoForm.value.paidDateFrom).format('YYYY-MM-DD HH:mm:ss') : '',
                paidDateTo: this.creditMemoForm.value.paidDateTo ? moment(this.creditMemoForm.value.paidDateTo).format('YYYY-MM-DD HH:mm:ss') : ''
            }
        })
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.creditMemos = data.data;
                }
            });
    }
    customSort(event: SortEvent): void {
        event.data.sort((data1, data2) => {
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            }
            else if (value1 != null && value2 == null) {
                result = 1;
            }
            else if (value1 == null && value2 == null) {
                result = 0;
            }
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            }
            else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (event.order * result);
        });
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
    
}
