<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Account Category Id {{this.glAccountCategoryId}} </span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Back</button>

            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                      GL Account Category Information</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                      GL Account Category Member
                        </a></li>




                        </ul>
                    </div>
                    <div>
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                <div *ngIf="activeCategory==1">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            GL Account Category Information

                                                        </h4>
                                                        <span class="edit-buttons ">
                                                  <a  data-toggle="modal" (click)="edit()"
                                                  data-target="#exampleModalCenter">Update</a></span>

                                                    </div>
                                                    <br>
                                                    <div>
                                                        <!--  <button type="button" style="width: 132px;"
                                                  class="btn btn-outline-secondary bg-brown ">Export as CSV</button>
                                              &nbsp; <button type="button" style="width: 132px;"
                                                  class="btn btn-danger" routerLinkActive="active">Export as PDF</button> -->
                                                    </div>
                                                    <div class="w3-container">
                                                        <div class="panel-body">
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="color-black container">
                                                                        <div class="article-container">
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">
                                                                                    GL AccountCategory Id</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{ this.glAccountCategoryId}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">GL Account Category Type</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{ this.glAccountCategoryTypeId}}</span>

                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Description</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{ this.description}}</span>
                                                                            </div>


                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>


                                                <div *ngIf="activeCategory==2">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            GL Account Category Member
                                                        </h4>
                                                        <span class="edit-buttons ">
                                <a (click)="createMember()">Create</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="AccountCategoryMemberSetting" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>

                                                                                        <div style="color: white;"> Gl Account ID
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        GL Account Category ID
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        From Date
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Through Date
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Amount Percentage
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-activity>
                                                                            <tr>
                                                                                <td>
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{activity.glAccountId}}-{{activity.glAccount}}
                                                                                    </div>
                                                                                </td>

                                                                                <td> {{activity.glAccountCategory}} </td>
                                                                                <td> {{activity.fromDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                <td> {{activity.thruDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                <td> {{activity.amountPercentage}} </td>
                                                                                <td>
                                                                                    <svg class="hover" (click)="onUpdateMember(activity.glAccountId,activity.fromDate,activity.thruDate,activity.amountPercentage)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222"><g transform="translate(0 -0.004)"><g transform="translate(0 1.52)"><path d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z" transform="translate(0 -34.137)"/></g><g transform="translate(4.548 0.004)"><g transform="translate(0 0)"><path d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z" transform="translate(-102.409 -0.004)"/></g></g></g></svg>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteglAccountCategoryMember(activity.glAccountId,activity.fromDate)" class="ml-20" width="16" height="16" viewBox="0 0 26 26"><defs><style>.a{fill:#f44336;}.b{fill:#fafafa; margin-left: 20px;}</style></defs><path class="a fill-color" d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0"/><path class="b" d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0" transform="translate(-147.576 -147.576)"/></svg></td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page
                                                                        <ng-container *ngIf="openNoRecordFoundRelatedAccount">
                                                                            <span style="margin-left: 400px;">No Record Found!....</span>
                                                                        </ng-container>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>




                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1" *ngIf="!show">Add Gl Account Category
           </span>
                <span class="color-black pl-1" *ngIf="show">Update Gl Account Category
           </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" #closebutton>&times;</span>
              </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''" *ngIf="!show"><a>
                       Add Gl Account Category</a></li>
                                <li [ngClass]="activeCategory==1?'active':''" *ngIf="show"><a>
                           Update Gl Account Category</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createAccountCategory">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">GL Account Category Type<span
                                                   style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter GL Account Category Type" filter="true" [options]="glAccountCategoryTypeArray" optionlabel="label" formControlName="glAccountCategoryTypeId">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Description
                                              
                                               </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" formControlName="description">

                                                    </div>



                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 86%;">
                                        <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>