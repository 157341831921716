import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
@Component({
  selector: 'app-tax-authority',
  templateUrl: './tax-authority.component.html',
  styleUrls: ['./tax-authority.component.css']
})
export class TaxAuthorityComponent implements OnInit {
  financialList=[{
    "product":"ddd"
  }];
  total=0;
  @ViewChild('closebutton') closebutton;
  activeCategory=2;
  createTax:FormGroup;
  exemption: { label: string; value: string; }[];
  personId: any;
  partyIdArray: any[]=[];
  getGeoTy: any;
  getGeoTyArray: any[]=[];
  party: any;
  geo: any;
  show: boolean;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  TaxAuthorities: any;
  constructor(
    readonly spinner: NgxSpinnerService,
    readonly accountsService: AccountsService,
    readonly _FormBuilder: FormBuilder,
    readonly router: Router,
    readonly addSkillService: AddSkillService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService
  ) { 
    this.createTax = this._FormBuilder.group({
      Geo: [''],
      Party:[''],
      exemption:[''],
      pattern:[''],
      price:['']
     
      
    });
    this.exemption=[{"label":"Y","value":"Y"},{"label":"N","value":"N"}]
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getTaxAuthorities();
    this.getPartyId();
    this.getgeoByType();
  }

  getgeoByType() {
    this.spinner.show();
    this.accountsService.getgeoByType().subscribe(res => {
      this.getGeoTy = res.data;
      this.spinner.hide();
      for (const value of this.getGeoTy) {
        this.getGeoTyArray.push({
          label: value.geoId,
          value: value.geoId
        })
      }
    })
    
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  

  onSubmit() {
    this.spinner.show();
    const requestData = {
      "includeTaxInPrice": this.createTax.value.price,
      "requireTaxIdForExemption":this.createTax.value.exemption,
      "taxAuthGeoId": this.createTax.value.Geo,
      "taxAuthPartyId":this.createTax.value.Party,
      "taxIdFormatPattern": this.createTax.value.pattern,
    }
    this.accountsService.postTaxAuthorities(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getTaxAuthorities();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  getTaxAuthorities() {
    this.spinner.show();
    
    this.accountsService.getTaxAuthorities(this.finSize).subscribe(res => {
      this.TaxAuthorities = res.data;
      this.spinner.hide();
    })
   
  }
  updateTax(egeo,eparty){
    this.router.navigate(['/financial/add-tax-auth-detail'],{ queryParams: { geo : egeo,party : eparty}});
  }
  addTax(){
    this.router.navigate(['/financial/add-tax-auth-detail']);

  }
  detailSumm(egeo,eparty){
    this.router.navigate(['/financial/detail-tax-authority'],{ queryParams: { geo : egeo,party : eparty}});
  }
  viewParty(eparty){
    this.router.navigate(['/financial/view-party-profile'],{ queryParams: {party : eparty}});
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
