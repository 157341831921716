<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Accounting
                    Transaction</span>
                <span class="color-black pl-1" *ngIf="!show"> >Quick Create an Accounting Transaction </span>
                <span class="color-black pl-1" *ngIf="show"> > Quick Update an Accounting Transaction</span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                                    Add Quick Accounting Transaction</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                                    Update Quick Accounting Transaction</a></li>

                        </ul>
                    </div>


                    <div class="">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" [formGroup]="accountTransaction">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Accounting Transaction Type<span
                                                        style="color:red">*</span></label>
                                                </div>
                                             
                                                <div class="col-lg-2" >
                                                    <p-dropdown placeholder="Enter Acctg Trans Type" filter="true"
                                                    formControlName="AcctgTransType"
                                                    [options]="accTransactionTypeArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Fiscal Gl Type<span
                                                        style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Fiscal Gl Type" filter="true"
                                                    formControlName="FiscalGlType"
                                                    [options]="glFiscalTypeArray" optionlabel="label">

                                                </p-dropdown>
                                               
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Party ID </label>
                                                </div>
                                             
                                                <div class="col-lg-2" >
                                                    <p-dropdown placeholder="Enter Party ID" filter="true"
                                                    formControlName="PartyID"
                                                    [options]="partyIdArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Role Type ID </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Role Type ID" filter="true"
                                                        formControlName="RoleTypeID"
                                                        [options]="allroleArray" optionlabel="label"
                                                        >

                                                    </p-dropdown>
                                               
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Invoice ID</label>
                                                </div>
                                             
                                                <div class="col-lg-2" >
                                                    <p-dropdown placeholder="Enter Invoice ID" filter="true"
                                                    formControlName="InvoiceID"
                                                    [options]="invoiceListArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Payment ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Payment ID" filter="true"
                                                    formControlName="PaymentID"
                                                    [options]="getPaymentsListArray" optionlabel="label">

                                                </p-dropdown>
                                               
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Product ID</label>
                                                </div>
                                             
                                                <div class="col-lg-2" >
                                                    <p-dropdown placeholder="Enter Product ID" filter="true"
                                                    formControlName="ProductID"
                                                    [options]="ProductsArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Work Effort ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Work Effort ID" filter="true"
                                                    formControlName="WorkEffortID"
                                                    [options]="workEffortArray" optionlabel="label">

                                                </p-dropdown>
                                               
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Shipment ID</label>
                                                </div>
                                             
                                                <div class="col-lg-2" >
                                                    <p-dropdown placeholder="Enter Shipment ID" filter="true"
                                                    formControlName="ShipmentID"
                                                    [options]="FacilityShipmentsArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Fixed Asset ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Fixed Asset ID" filter="true"
                                                    formControlName="FixedAssetID"
                                                    [options]="fixedAssetArray" optionlabel="label">

                                                </p-dropdown>
                                               
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Debit Gl Account ID<span
                                                        style="color:red">*</span></label>
                                                </div>
                                             
                                                <div class="col-lg-2" >
                                                    <p-dropdown placeholder="Enter Debit Gl Account ID" filter="true"
                                                    formControlName="DebitGlAccountID"
                                                    [options]="assignGLPartyIdArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Credit Gl Account ID<span
                                                        style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Credit Gl Account ID" filter="true"
                                                    formControlName="CreditGlAccountID"
                                                    [options]="assignGLPartyIdArray" optionlabel="label">

                                                </p-dropdown>
                                               
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Amount<span
                                                        style="color:red">*</span></label>
                                                </div>
                                             
                                                <div class="col-lg-2" >
                                                    <input type="email" formControlName="Amount" class="form-control"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Enter Amount">
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Transaction Date</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" formControlName="TransactionDate" class="form-control"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Enter Transaction Date">
                                               
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Description</label>
                                                </div>
                                             
                                                <div class="col-lg-2" >
                                                    <input type="email" formControlName="Description" class="form-control"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Enter Description">
                                                </div>
                                              
                        
                                            </div>
                                        </div>

                                       
                                       
                                    </div>


                                </form>
                                <div class="main-submit-button" style="margin-left: 70%;">
                                    <button type="submit" *ngIf="show"
                                        class="btn btn-secondary submit-btn">Update</button>
                                    <button type="submit" (click)="onSubmit()" *ngIf="!show"
                                        class="btn btn-secondary submit-btn">Submit</button>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>