import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';
import { AccountingApService } from '../accounting-ap.service';

@Component({
  selector: 'app-payables-reports',
  templateUrl: './payables-reports.component.html',
  styleUrls: ['./payables-reports.component.css']
}) 
export class PayablesReportsComponent implements OnInit {
  pastDueInvoices: any[];
  invoiceDueSoon: any[];
  isShown = false; 
  pastDueInvoiceTotal: string;
  activeCategory=1;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  invoiceDueSoonTotal: string;
  agreementType: string;
  activeCategoryMain=1;;
  constructor(
    readonly router: Router,
    readonly _AccountingApService: AccountingApService,
    readonly spinner: NgxSpinnerService,
  ) {
    this.invoiceDueSoon = [];
    this.pastDueInvoices = [];
    this.pastDueInvoiceTotal = '';
    this.invoiceDueSoonTotal = '';
  }
  ngOnInit(): void {
    this.activeCategoryMain=1;
    this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
    this._AccountingApService.getPayableReport('Company')
      .then(data => {
        if (data) {
          this.pastDueInvoiceTotal = data.data.pastDueInvoicestotalAmount;
          this.invoiceDueSoonTotal = data.data.invoicesDueSoonTotalAmount;
          this.pastDueInvoices = data.data.pastDueInvoices;
          this.invoiceDueSoon = data.data.invoicesDueSoon;
        }
      });

  }
  PayablesRepots(){
    this.router.navigate(["/financial/payable/payables-report"])
  }
  ReceivablesReports(){
    this.router.navigate(["/financial/receiveable/receive-reports"])
  }
  ogclReportList(){
    this.router.navigate(["/financial/ogcl-report-list"])
  }
  invoiceType(){
    this.isShown = !this.isShown;
  }
  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategoryFunction(tab: number){
    this.activeCategory =tab;
  }
}
