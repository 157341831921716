import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-work-order-types',
  templateUrl: './work-order-types.component.html',
  styleUrls: ['./work-order-types.component.css']
})
export class WorkOrderTypesComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;

  activeCategory = 9;
  activeCategories = 2;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;

  addTeamMember: any;
  editTeam: any;
  TeamByPartyId: any;
  party: any;
  partyId: any;
  Role: any;
  RoleArray: any[] = [];
  show: boolean;
  TeamMemberByPartyId: any;
  updateTeamMem: any;
  Deactivated: boolean;
  personId: any;
  partyIdArray: any[] = [];
  teamMember: any;
  teamMemberArray: any[] = [];
  editBasicWorkOrderTypeForm: FormGroup;
  editCustomWorkOrderTypeForm: FormGroup;
  addBasicWorkOrderTypeForm: FormGroup;
  addCustomWorkOrderTypeForm: FormGroup;
  displayAs: "";
  category: "";
  abbreviation: "";
  description: "";
  basicWorkOrderType: any;
  basicWorkOrderTypeById: any;
  customBasicWorkOrderTypeById: any;
  customWorkOrderType: any;

  constructor(
    readonly activatedRoute: ActivatedRoute,

    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) {
    {
      this.editBasicWorkOrderTypeForm = this._FormBuilder.group({
        displayAs: "",
        category: "",
        abbreviation: "",
        description: "",
      })
    }

    {
      this.editCustomWorkOrderTypeForm = this._FormBuilder.group({
        displayAs: "",
        category: "",
        abbreviation: "",
        description: "",
      })
    }

    {
      this.addBasicWorkOrderTypeForm = this._FormBuilder.group({
        displayAs: "",
        category: "",
        abbreviation: "",
        description: "",
      })
    }

    {
      this.addCustomWorkOrderTypeForm = this._FormBuilder.group({
        displayAs: "",
        category: "",
        abbreviation: "",
        description: "",
      })
    }
  }

  ngOnInit(): void {
    this.getBasicWorkOrderType();
    this.getCustomWorkOrderType();
  }
  createCustomWorkOrderType() {
    this.spinner.show();
    const requestData = {
      "abbreviation": this.addCustomWorkOrderTypeForm.value.abbreviation,
      "category": this.addCustomWorkOrderTypeForm.value.category,
      "customWorkOrderTypeDisplayAs": this.addCustomWorkOrderTypeForm.value.displayAs,
      "customWorkOrderTypeId": "",
      "description": this.addCustomWorkOrderTypeForm.value.description,
    }
    this.myContactsService.createCustomWorkOrderType(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this.addCustomWorkOrderTypeForm.reset();
        this.getCustomWorkOrderType();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateCustomWorkOrderType() {
    this.spinner.show();
    const requestData = {
      "abbreviation": this.editCustomWorkOrderTypeForm.value.abbreviation,
      "category": this.editCustomWorkOrderTypeForm.value.category,
      "customWorkOrderTypeDisplayAs": this.editCustomWorkOrderTypeForm.value.displayAs,
      "customWorkOrderTypeId": this.customBasicWorkOrderTypeById.customWorkOrderTypeId,
      "description": this.editCustomWorkOrderTypeForm.value.description
    }
    this.myContactsService.updateCustomWorkOrderType(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.editCustomWorkOrderTypeForm.reset();
        this.getCustomWorkOrderType();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deleteCustomWorkOrderType(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "customWorkOrderTypeId": product.customWorkOrderTypeId
        }
        this.myContactsService.deleteCustomWorkOrderType(req).subscribe(res => {
          this.getCustomWorkOrderType();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getCustomWorkOrderTypeById(product) {
    this.spinner.show();
    this.myContactsService.getCustomWorkOrderTypeById(product.customWorkOrderTypeId).subscribe(res => {
      this.customBasicWorkOrderTypeById = res.data[0].getCustomWorkOrderTypeById[0];
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.editCustomWorkOrderTypeForm;
        formValue.patchValue({
          displayAs: this.customBasicWorkOrderTypeById.customWorkOrderTypeDisplayAs,
          category: this.customBasicWorkOrderTypeById.category,
          abbreviation: this.customBasicWorkOrderTypeById.abbreviation,
          description: this.customBasicWorkOrderTypeById.description,
        })

      }, 1000);
    })
  }
  getCustomWorkOrderType() {
    this.spinner.show();
    this.myContactsService.getCustomWorkOrderType().subscribe(res => {
      this.customWorkOrderType = res.data[0].getCustomWorkOrderType;
      this.spinner.hide();
    })
  }
  deleteBasicWorkOrderType(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "basicWorkOrderTypeId": product.basicWorkOrderTypeId
        }
        this.myContactsService.deleteBasicWorkOrderType(req).subscribe(res => {
          this.getBasicWorkOrderType();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getBasicWorkOrderTypeById(product) {
    this.spinner.show();
    this.myContactsService.getBasicWorkOrderTypeById(product.basicWorkOrderTypeId).subscribe(res => {
      this.basicWorkOrderTypeById = res.data[0].getBasicWorkOrderTypeById[0];
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.editBasicWorkOrderTypeForm;
        formValue.patchValue({
          displayAs: this.basicWorkOrderTypeById.basicWorkOrderTypeDisplayAs,
          category: this.basicWorkOrderTypeById.category,
          abbreviation: this.basicWorkOrderTypeById.abbreviation,
          description: this.basicWorkOrderTypeById.description,
        })

      }, 1000);
    })
  }
  getBasicWorkOrderType() {
    this.spinner.show();
    this.myContactsService.getBasicWorkOrderType().subscribe(res => {
      this.basicWorkOrderType = res.data[0].getBasicWorkOrderType;
      this.spinner.hide();
    })
  }
  cancelSubmit() {
    this._Router.navigate(["/fsm/fsmSetting/work-order"])
  }
  createBasicWorkOrderType() {
    this.spinner.show();

    const requestData = {
      "abbreviation": this.addBasicWorkOrderTypeForm.value.abbreviation,
      "basicWorkOrderTypeDisplayAs": this.addBasicWorkOrderTypeForm.value.displayAs,
      "basicWorkOrderTypeId": "",
      "category": this.addBasicWorkOrderTypeForm.value.category,
      "description": this.addBasicWorkOrderTypeForm.value.description,
    }

    this.myContactsService.createBasicWorkOrderType(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.addBasicWorkOrderTypeForm.reset();
        this.getBasicWorkOrderType();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateBasicWorkOrderType() {
    this.spinner.show();
    const requestData = {
      "abbreviation": this.editBasicWorkOrderTypeForm.value.abbreviation,
      "basicWorkOrderTypeDisplayAs": this.editBasicWorkOrderTypeForm.value.displayAs,
      "basicWorkOrderTypeId": this.basicWorkOrderTypeById.basicWorkOrderTypeId,
      "category": this.editBasicWorkOrderTypeForm.value.category,
      "description": this.editBasicWorkOrderTypeForm.value.description,


    }
    this.myContactsService.updateBasicWorkOrderType(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.editBasicWorkOrderTypeForm.reset();
        this.getBasicWorkOrderType();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
