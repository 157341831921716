import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-purchasing-reports',
  templateUrl: './purchasing-reports.component.html',
  styleUrls: ['./purchasing-reports.component.css']
})
export class PurchasingReportsComponent implements OnInit {
 
  selectedCar2: string = null;
  cars: SelectItem[];
  currentDate = new Date();
 
  form = new FormGroup({
    dateYMD: new FormControl(new Date()),
    dateFull: new FormControl(new Date()),
    dateMDY: new FormControl(new Date()),
    dateRange: new FormControl([
      new Date(),
      new Date(this.currentDate.setDate(this.currentDate.getDate() + 7))
    ])
  });

  constructor() {
   }

  ngOnInit(): void {
    this.cars = [
      {label: 'Demo Suppliers 1', value: 'Demo Suppliers 1'},
      {label: 'Demo Suppliers 1', value: 'Demo Suppliers 1'},
      {label: 'Demo Suppliers 1', value: 'Demo Suppliers 1'},
    ];
  }

}
