
<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">

    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">
            
           <div class="col-md-12 p-0" >
               <div class="w3-card-4 classCard  w-100" style="height:1000px;">
                   
                    <div class="mt-3 d-flex justify-content-between" style="margin-bottom: 10px !important; ">
                       <div>
                            <span style="font-size: 15px; font-weight: bold; margin-left: 16px!important;">Edit Work Zone</span>

                       </div>
                       <div style="margin-right:12px !important;">
                            <button type="submit" class="btn btn-danger  ml-2">Delete</button>
                            <button type="submit" class="btn btn-secondary submit-btn ml-2">Cancel</button>

                            <button type="submit" class="btn btn-secondary submit-btn ml-2">Ok</button>

                       </div>

                    </div>                    

                    
                    

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Edit Work Zone Name</p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12" [formGroup]="WorkZoneNamesForm">
                                                <div class=" ">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group rightForm">
                                                                <label for="exampleInputEmail1"><b>1.</b>Work Zone Name* :</label>
                                                            </div>
                                                            <div class="col-lg-5 ml-4 leftpadding">
                                                                <input type="text" class="form-control" id="exampleInputEmail1" name=""
                                                                    aria-describedby="" 
                                                                    formControlName="workZoneName">
                                                                
                                                            </div>

                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Select Dispatch Center for Work Zone</p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100">
                                                <div class=" ">
                                                    <div class="col-lg-12">
                                                        <div class="row font-twelve">
                                                            <div class="col-lg-3 form-group rightForm font-12" >
                                                                <label for="exampleInputEmail1"><b>2.</b>Dispatch Center* :</label>
                                                            </div>
                                                            <div class="col-lg-5 ml-4 leftpadding">
                                                                <label>Defualt Dispatch Center</label>
                                                                
                                                            </div>


                                                            <div class="col-lg-2 ml-5 form-group rightFormA">
                                                                <button class="btn btn-secondary submit-btn">Change</button>
                                                            </div>

                                                        </div>

                                                        
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group rightForm">
                                                            </div>
                                                            
                                                            <div class="col-lg-5 ml-4 leftpadding">
                                                                <label class="font-twelve">Dispatchers:</label>
                                                                
                                                            </div>


                                                            <div class="col-lg-2 ml-5 form-group rightFormA">
                                                                <button class="btn btn-secondary submit-btn">Change</button>
                                                            </div>

                                                        </div>

                                                        
                                                    </div>

                                                    
                                                    
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                      Select Service Team for Work Zone  </p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 ">
                                                <div class=" ">
                                                    <div class="col-lg-12">
                                                        <div class="row font-twelve">
                                                            <div class="col-lg-3 form-group rightForm font-12">
                                                                <label for="exampleInputEmail1"><b>3.</b>Primary Service Team* :</label>
                                                            </div>
                                                            <div class="col-lg-5 ml-4 leftpadding">
                                                                <label>Defualt Service Team</label>
                                                                
                                                            </div>


                                                            <div class="col-lg-2 ml-5 form-group rightFormA">
                                                                <button class="btn btn-secondary submit-btn">Change</button>
                                                            </div>

                                                        </div>

                                                        
                                                    </div>

                                                    
                                                    
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Select Backup Team for Work Zone</p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100">
                                                <div class=" ">
                                                    <div class="col-lg-12">
                                                        <div class="row font-twelve">
                                                            <div class="col-lg-3 form-group rightForm font-12" >
                                                                <label for="exampleInputEmail1"><b>4.</b>Backup Service Team :</label>
                                                            </div>
                                                            <div class="col-lg-5 ml-4 leftpadding">
                                                                <label>Not yet set</label>
                                                                
                                                            </div>


                                                            <div class="col-lg-2 ml-5 form-group rightFormA">
                                                                <button class="btn btn-secondary submit-btn">Add</button>
                                                            </div>

                                                        </div>

                                                        
                                                    </div>

                                                   

                                                    
                                                    
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Define Work Order Numbering Format</p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100">
                                                <div class=" ">
                                                    <div class="col-lg-12">
                                                        <div class="row font-twelve">
                                                            <div class="col-lg-3 form-group rightForm font-12" >
                                                                <label for="exampleInputEmail1"><b>5.</b>Work Order Numbering* :</label>
                                                            </div>
                                                            <div class="col-lg-5 d-flex ml-4 leftpadding">
                                                                <input type="radio" class="font-twelve">&nbsp;
                                                                <label>Standard Numbering(Example. WO-001)</label>
                                                                
                                                            </div>


                                                            <div class="col-lg-2 ml-5 form-group rightFormA">
                                                                <button class="btn btn-secondary submit-btn">Change</button>
                                                            </div>

                                                        </div>

                                                        
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group rightForm">
                                                            </div>
                                                            
                                                            <div class="col-lg-5 d-flex ml-4 leftpadding">
                                                                <input type="radio" class="font-twelve">&nbsp;
                                                                <label class="font-twelve">Custom Numbering(Example. WO-002)</label>
                                                                
                                                            </div>


                                                            

                                                        </div>

                                                        
                                                    </div>

                                                    
                                                    
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Time zone for Work Zone</p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12" [formGroup]="timezoneForm">
                                                <div class=" ">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group rightForm">
                                                                <label for="exampleInputEmail1"><b>1.</b>Time Zone* :</label>
                                                            </div>
                                                            <div class="col-lg-5 ml-4 leftpadding">
                                                                <input type="text" class="form-control" id="exampleInputEmail1" name=""
                                                                    aria-describedby="" 
                                                                    formControlName="timeZone">
                                                                
                                                            </div>

                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px; height: 285px !important;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Work Zone service hours</p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12" [formGroup]="timezoneForm">
                                                <div class=" ">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group rightForm">
                                                                <label for="exampleInputEmail1"><b>7.</b>Service Hours :</label>
                                                            </div>
                                                            <div class="col-lg-2 ml-4 leftpadding">
                                                               <label>Days</label>
                                                                
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <label>Opens At</label>
                                                                 
                                                             </div>

                                                             <div class="col-lg-2">
                                                                <label>Closes At</label>
                                                                 
                                                             </div>

                                                             <div>
                                                                 <button class="btn btn-secondary submit-btn">Change</button>
                                                             </div>

                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                        </div>
                                                        <div class="col-lg-2 ml-4 leftpadding">
                                                           <label>Sunday</label>
                                                            
                                                        </div>

                                                        <div class="col-lg-2">
                                                            <label>Closed</label>
                                                             
                                                         </div>

                                                         <div class="col-lg-2">
                                                            <label>---</label>
                                                             
                                                         </div>

                                                         

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                        </div>
                                                        <div class="col-lg-2 ml-4 leftpadding">
                                                           <label>Monday</label>
                                                            
                                                        </div>

                                                        <div class="col-lg-2">
                                                            <label>08:00am</label>
                                                             
                                                         </div>

                                                         <div class="col-lg-2">
                                                            <label>05:00pm</label>
                                                             
                                                         </div>

                                                         

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                        </div>
                                                        <div class="col-lg-2 ml-4 leftpadding">
                                                           <label>Tuesday</label>
                                                            
                                                        </div>

                                                        <div class="col-lg-2">
                                                            <label>08:00am</label>
                                                             
                                                         </div>

                                                         <div class="col-lg-2">
                                                            <label>05:00pm</label>
                                                             
                                                         </div>

                                                         

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                        </div>
                                                        <div class="col-lg-2 ml-4 leftpadding">
                                                           <label>Wednesday</label>
                                                            
                                                        </div>

                                                        <div class="col-lg-2">
                                                            <label>08:00am</label>
                                                             
                                                         </div>

                                                         <div class="col-lg-2">
                                                            <label>05:00pm</label>
                                                             
                                                         </div>

                                                         

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                        </div>
                                                        <div class="col-lg-2 ml-4 leftpadding">
                                                           <label>Thursday</label>
                                                            
                                                        </div>

                                                        <div class="col-lg-2">
                                                            <label>08:00am</label>
                                                             
                                                         </div>

                                                         <div class="col-lg-2">
                                                            <label>05:00pm</label>
                                                             
                                                         </div>

                                                         

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                        </div>
                                                        <div class="col-lg-2 ml-4 leftpadding">
                                                           <label>Friday</label>
                                                            
                                                        </div>

                                                        <div class="col-lg-2">
                                                            <label>08:00am</label>
                                                             
                                                         </div>

                                                         <div class="col-lg-2">
                                                            <label>05:00pm</label>
                                                             
                                                         </div>

                                                         

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                        </div>
                                                        <div class="col-lg-2 ml-4 leftpadding">
                                                           <label>Sarturday</label>
                                                            
                                                        </div>

                                                        <div class="col-lg-2">
                                                            <label>Closed</label>
                                                             
                                                         </div>

                                                         <div class="col-lg-2">
                                                            <label>---</label>
                                                             
                                                         </div>

                                                         

                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    

            
                    

                   
               </div>
           </div>
                        
        
        
        
        
        </div>
    
    </div>
</div>


       

<!-- <ngx-spinner></ngx-spinner> -->






