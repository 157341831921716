<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-grey clickable" (click)="onCancel()">Facilities</span>
                <span class="color-black pl-1"> > Create New</span>
                <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger buttonclass" 
                    (click)="onCancel()">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">

                        <li [ngClass]="activeCategory==2?'active':''"><a>
                                Create New</a></li>

                    </ul>
                </div>
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="facilityForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12" *ngIf="editMode">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Facility Type ID <span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <ng-select [items]="facilityType" class="ng-select-width"
                                                bindLabel="description" bindValue="facilityTypeId"
                                                placeholder="Facility Type ID" formControlName="facilityTypeId">
                                            </ng-select>
                                            <small class="validate-field"
                                                *ngIf="!!facilityForm.controls.facilityTypeId.invalid && !!facilityForm.controls.facilityTypeId.touched">
                                                Facility Type ID is required!</small>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="facilityId">Facility Id:</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <span style="color: black;">{{facilityId}}</span>
                                            </div>

                                        </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Parent Facility ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <ng-select [items]="facilityIds" bindLabel="facilityName"
                                                placeholder="Parent Facility ID" bindValue="facilityId"
                                                formControlName="parentFacilityId"></ng-select>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Owner <span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <ng-select [items]="partyIds" bindLabel="groupName" bindValue="partyId"
                                                placeholder="Owner" formControlName="ownerPartyId"></ng-select>
                                            <small class="validate-field"
                                                *ngIf="!!facilityForm.controls.ownerPartyId.invalid && !!facilityForm.controls.ownerPartyId.touched">
                                                Owner is required!</small>
                                            </div>
                                        </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Default Weight Unit</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <ng-select [items]="weightData" class="ng-select-width"
                                                placeholder="Default Weight Unit" formControlName="defaultWeightUomId"
                                                bindLabel="description" bindValue="uomId">
                                            </ng-select>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Default Inventory Item Type</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <ng-select [items]="inventoryData" class="ng-select-width"
                                                placeholder="Default Inventory Item Type"
                                                formControlName="defaultInventoryItemTypeId" bindLabel="description"
                                                bindValue="inventoryItemTypeId">
                                            </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Name <span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                placeholder="Name" formControlName="facilityName"
                                                aria-describedby="emailHelp">
                                            <small class="validate-field"
                                                *ngIf="!!facilityForm.controls.facilityName.invalid && !!facilityForm.controls.facilityName.touched">
                                                Name is required!</small>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Area</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                formControlName="facilitySize" aria-describedby="emailHelp"
                                                placeholder="Area">
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Area Unit</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <ng-select [items]="areaData" class="ng-select-width"
                                                formControlName="facilitySizeUomId" bindLabel="description"
                                                bindValue="uomId" placeholder="Area Unit">
                                            </ng-select>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Product Description</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                formControlName="description" aria-describedby="emailHelp"
                                                placeholder="Product Description">
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Default Days To Ship</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                formControlName="defaultDaysToShip" aria-describedby="emailHelp"
                                                placeholder="Default Days To Ship">
                                            </div>
                                        </div>
                                    </div>

                                
                                </div>
                                <div class="flexDisplay"  style="margin-left: -199%;">
                                    <div class="col-md-11 mr12 main-submit-button"><button _ngcontent-cta-c128=""
                                            type="submit" class="btn btn-primary submit-btn update-button"
                                            (click)="submit()">{{editMode?'Update':'Create'}}</button></div>
                                    <div class="col-md-1 main-submit-button">
                                    </div>
                                </div>
                                

                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
          
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 