<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Identification Numbers
                </span>
                <span class="color-black pl-1" > >Create New Identification
                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                       Create Identification Number
                    </a></li>
                    <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                        Update Identification Number
                     </a></li>
                    
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createIdentification">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">
                                                    Identification Type</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true"  *ngIf="!this.show"
                                                placeholder="Enter Identification Type"
                                                formControlName="type" [options]="identificationArray" optionlabel="label"
                                                id="exampleInputEmail1">
                                            </p-dropdown> 
                                            <input type="email"   *ngIf="this.show" formControlName="type"  class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter ID Value" readonly>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    ID Value</label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="idvalue"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter ID Value">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                   
                                   
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 65%;">
                               
                                <button type="submit" *ngIf="!this.show" (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>
                                <button type="submit" *ngIf="this.show" (click)="onUpdate();" class="btn btn-secondary submit-btn">Update</button>
                                
                                
                                
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div> -->

<!-- <div class="modal fade" id="identificationModalCenter" tabindex="-1" role="dialog" aria-labelledby="identificationModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
             
                <span class="color-black pl-1">Create New Identification</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
    
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                                    Create Identification Number
                                 </a></li>
                                 <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                                     Update Identification Number
                                  </a></li>
                            </ul>
                        </div>
						
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form  class="w-100" [formGroup]="createIdentification">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Identification Type</label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true"  *ngIf="!this.show"
                                                        placeholder="Enter Identification Type"
                                                        formControlName="type" [options]="identificationArray" optionlabel="label"
                                                        id="exampleInputEmail1">
                                                    </p-dropdown> 
                                                    <input type="email"   *ngIf="this.show" formControlName="type"  class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter ID Value" readonly>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            ID Value</label>
                                                    </div>
                                                   
                                                    <div class="col-lg-3">
                                                        <input type="email"  formControlName="idvalue"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter ID Value">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                           
                                           
                                        </div>
                                        
        
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 75%;">
                                       
                                        <button type="submit" *ngIf="!this.show" (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="this.show" (click)="onUpdate();" class="btn btn-secondary submit-btn">Update</button>
                                        
                                        
                                        
                                        
                                      
                                    </div>
                                </div>
                            </div>
        
                        </div>
						
						</div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="modal fade" id="identificationModalCenter" tabindex="-1" role="dialog" aria-labelledby="identificationModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
             
                <span class="color-black pl-1">Create New Identification</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
    
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                                    Create Identification Number
                                 </a></li>
                                 <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                                     Update Identification Number
                                  </a></li>
                            </ul>
                        </div>
						
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form  class="w-100" [formGroup]="createIdentification">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Identification Type</label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true"  *ngIf="!this.show"
                                                        placeholder="Enter Identification Type"
                                                        formControlName="type" [options]="identificationArray" optionlabel="label"
                                                        id="exampleInputEmail1">
                                                    </p-dropdown> 
                                                    <input type="email"   *ngIf="this.show" formControlName="type"  class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter ID Value" readonly>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            ID Value</label>
                                                    </div>
                                                   
                                                    <div class="col-lg-3">
                                                        <input type="email"  formControlName="idvalue"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter ID Value">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                           
                                           
                                        </div>
                                        
        
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 75%;">
                                       
                                        <button type="submit" *ngIf="!this.show" (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="this.show" (click)="onUpdate();" class="btn btn-secondary submit-btn">Update</button>
                                        
                                        
                                        
                                        
                                      
                                    </div>
                                </div>
                            </div>
        
                        </div>
						
						</div>
                </div>
            </div>
        </div>
    </div>
</div> -->


<!-- <div class="modal fade" id="identificationModalCenter" tabindex="-1" role="dialog" aria-labelledby="identificationModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
             
                <span class="color-black pl-1">Create New Identification</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
    
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                                    Create Identification Number
                                 </a></li>
                                 <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                                     Update Identification Number
                                  </a></li>
                            </ul>
                        </div>
						
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form  class="w-100" [formGroup]="createIdentification">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-4 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Identification Type</label>
                                                    </div>
                                                 
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true"  *ngIf="!this.show"
                                                        placeholder="Enter Identification Type"
                                                        formControlName="type" [options]="identificationArray" optionlabel="label"
                                                        id="exampleInputEmail1">
                                                    </p-dropdown> 
                                                    <input type="email"   *ngIf="this.show" formControlName="type"  class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter ID Value" readonly>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            ID Value</label>
                                                    </div>
                                                   
                                                    <div class="col-lg-3">
                                                        <input type="email"  formControlName="idvalue"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter ID Value">
                                                    </div>
                            
                                                   
                            
                                                </div>
                                            </div>
                                           
                                           
                                        </div>
                                        
        
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 75%;">
                                       
                                        <button type="submit" *ngIf="!this.show" (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="this.show" (click)="onUpdate();" class="btn btn-secondary submit-btn">Update</button>
                                        
                                        
                                        
                                        
                                      
                                    </div>
                                </div>
                            </div>
        
                        </div>
						
						</div>
                </div>
            </div>
        </div>
    </div>
</div> -->


<div class="modal-header">
    <span class="color-black pl-1">Create New Identification</span>
    <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden" #closebutton >&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex">
      <div class=" bg-white color-grey" style="width: 100%;">
          <div class="w3-card-4 classCardPopUp" style="width: 100%;">
              <div class="container-fluid">
                  <ul class="tabbing-section tabbing-accordians tabClass">

                    <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                        Create Identification Number
                     </a></li>
                     <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                         Update Identification Number
                      </a></li>
                  </ul>
              </div>
              
              <div class="create-new-leade mt-2">
                <div class="container-fluid">
                    <div class="row">
                        <form  class="w-100" [formGroup]="createIdentification">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
                
                                        <div class="col-lg-4 form-group classInput" >
                                            <label for="exampleInputEmail1">
                                                Identification Type</label>
                                        </div>
                                     
                                        <div class="col-lg-3">
                                            <p-dropdown filter="true"  *ngIf="!this.show"
                                            placeholder="Enter Identification Type"
                                            formControlName="type" [options]="identificationArray" optionlabel="label"
                                            id="exampleInputEmail1">
                                        </p-dropdown> 
                                        <input type="email"   *ngIf="this.show" formControlName="type"  class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" placeholder="Enter ID Value" readonly>
                                        </div>
                                        <div class="col-lg-2 form-group classInputA">
                                            <label for="exampleInputEmail1">
                                                ID Value</label>
                                        </div>
                                       
                                        <div class="col-lg-3">
                                            <input type="email"  formControlName="idvalue"  class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter ID Value">
                                        </div>
                
                                       
                
                                    </div>
                                </div>
                               
                               
                            </div>
                            

                        </form>
                        <div class="main-submit-button" style="margin-left: 75%;">
                           
                            <button type="submit" *ngIf="!this.show" (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>
                            <button type="submit" *ngIf="this.show" (click)="onUpdate();" class="btn btn-secondary submit-btn">Update</button>
                            
                            
                            
                            
                          
                        </div>
                    </div>
                </div>

            </div>
                

              
              
             </div>
      </div>
  </div>


