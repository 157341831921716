import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DetailPositionService } from '../detailed-employee-position/detailed-employee-position.service';
import { EmployeePositionService } from '../employee-position/employee-position.service';
@Component({
  selector: 'app-emp-position-report-by',
  templateUrl: './emp-position-report-by.component.html',
  styleUrls: ['./emp-position-report-by.component.css']
})
export class EmpPositionReportByComponent implements OnInit {
  updatePartyID:any;
  flag:any;
  activeCategory = 2;
  partyIDArray: any[]=[];
  updateFromDate:any;
  throughFromDate:any;
  updateComment:any;
  partyIdForCreate: any;
  showUpdateBtn=false;
  showCreateBtn=false;
  updatePosition:any;
  partyId: any;
  positionID: any;
  contactPartyId: any;
  updateToDate: string;
  updateDateDate: string;
  updateRespId:any;
  updateDateDateForm: string;
  throughFromDateForm: string;
  employeePositionResponsibility: any;
  pDes: any;
  solicitation:any[]=[];
  pageSize= 100;
  pageNo:number = 1;
  rows = 50;
  search=""
  readonly employessSize={
    pageNo:this.pageNo,
    pageSize:this.pageSize,

  }
  employeePosition: any;
  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly router:Router,
    readonly spinner:NgxSpinnerService,
    readonly toastr:ToastrService,
    readonly employeePositionService: EmployeePositionService,
    readonly detailPositionService:DetailPositionService,
    readonly formBuilder: FormBuilder,
    readonly datePipe: DatePipe
  ) { 
    this.solicitation = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      }
     
    ];
  }
  report_employee_form: FormGroup;
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getEmployeePosition();
    let contactPartyIdLocal;
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyIdForCreate= params["positionId"];
      
      if(this.partyIdForCreate) 
      {
        contactPartyIdLocal=this.partyIdForCreate;
      }
     
      if(this.partyIdForCreate)
      {
        this.showCreateBtn=true;   
      }
      })
    this.report_employee_form = this.formBuilder.group({
      comments: [''],
      thruDate: [''],
      fromDate: [''],
      emplPositionIdManagedBy: [''],
      primaryFlag: [''],
      emplPositionId: ['']
     
    });
  }
  navigate(){
    this.router.navigate(["/hr/employee-position/employee-position-details"],{queryParams:{positionId:this.partyIdForCreate}});
  } 
  getEmployeePosition() {
    this.spinner.show();
    const emplPosition = {
      actualFromDateFrom: "",actualFromDateTo: "",
      actualThruDateFrom: "", actualThruDateTo: "", budgetId: "",budgetIdSearchType: "Contains",
      budgetItemSeqId: "",budgetItemSeqIdSearchType: "Contains",emplPositionId: "",emplPositionIdSearchType: "Contains",
      emplPositionTypeId: "",emplPositionTypeIdSearchType: "Contains",estimatedStartDateFrom: "",
      estimatedStartDateTo: "",estimatedThruDateFrom: "",estimatedThruDateTo: "",
      exemptFlag: "",
      fulltimeFlag: "",
      partyId: "",
      partyIdSearchType: "Contains",
      salaryFlag: "",
      statusId: "",
      statusIdSearchType: "Contains",
      temporaryFlag: ""
    }
    this.employeePositionService.getEmployeePosition(this.employessSize,emplPosition).subscribe((res:any)=> {
      this.employeePosition = res.data;
      this.spinner.hide();
      for(const value of this.employeePosition){
        this.partyIDArray.push({
          label:value.emplPositionId,
          value:value.emplPositionId
        })
      }
    })
   
  }
  onSubmit() {
    this.spinner.show();
     const formData = {
       comments : this.report_employee_form.value.comments,
       emplPositionId : this.partyIdForCreate,
       fromDate : this.report_employee_form.value.fromDate,
       emplPositionIdManagedBy : this.report_employee_form.value.emplPositionIdManagedBy,
       thruDate : this.report_employee_form.value.thruDate,
       primaryFlag:this.report_employee_form.value.primaryFlag
     }
    
     this.detailPositionService.createPositionReportStructureBy(formData).subscribe((res:any)=> {
       if(res.success) {
         this.toastr.success(res.data.responseMessage);
         this.spinner.hide();
         this.router.navigate(["/hr/employee-position/employee-position-details"],{queryParams:{positionId: this.partyIdForCreate}});
       }
       else if(res.success == false) {
         this.toastr.error(res.debugMessage);
       }
     },(err) => {
       for(const value of err.error.errors){
         this.toastr.error(value.fieldName + " " + value.fieldError);
         }
    })
    this.spinner.hide();
   }
   ngOnDestroy(): void {
    this.spinner.hide();
      }
}
