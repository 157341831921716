<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Reports</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey w-100 mt-20">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100">
                            <div class="w3-card-4 classCardView mt-2 w-100">
                                <div
                                    class="d-flex bd-highlight port-header-contact-import  create-lead font-medium mb-0 suppliers-wrapper">
                                    Reports
                                    <!-- Four Birt reports (Last 3 Months Sales, Sales, Sales Order Discount Code, Product
                                    Demand) require to feed the BI data base (note that the bi.properties file will need
                                    to be updated after September 2026). Then only new approved orders will be taken
                                    into account. -->
                                </div>
                                <div class="create-new-leade p-3">
                                    <div class="container-fluid" style="padding: 0;">
                                        <div
                                            class="">
                                            <div class="w3-card-4 classCardView">
                                                <div class="header-tabs" >
                                                    <h4 class="common-styling h4Margin">

                                                        Sales Report
                                                    </h4>
                                                </div>
                                                <form [formGroup]="reportForm">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">
                                                                        Choose Report Type 
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-3 form-group">
                                                                    <p-dropdown filter="true"  (onChange)="onChange($event.value)"
                                                                        formControlName="ProductStoreID"
                                                                        optionlabel="label" [options]="reportTypeArray"
                                                                        placeholder="Enter Product Store ID">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                                                 
                                                        <div class="col-lg-5 main-submit-button">
                                                            <button type="submit" *ngIf="this.SalesbyStoreReport"
                                                            data-toggle="modal"  data-target="#SalesbyStoreReportpopup" 
                                                            class="btn btn-secondary submit-btn">Open Report
                                                            </button>&nbsp;
                                                            <button type="submit" *ngIf="this.PurchasesbyProductReport"
                                                            data-toggle="modal"  data-target="#PurchasesbyProductReport" 
                                                            class="btn btn-secondary submit-btn">Open Report
                                                            </button>&nbsp;
                                                            <button type="submit" *ngIf="this.OpenOrderItemsReport"
                                                            data-toggle="modal"  data-target="#OpenOrderItemsReport" 
                                                            class="btn btn-secondary submit-btn">Open Report
                                                            </button>&nbsp;
                                                            <button type="submit" *ngIf="this.PurchasesbyPaymentMethodReport"
                                                            data-toggle="modal"  data-target="#PurchasesbyPaymentMethodReport" 
                                                            class="btn btn-secondary submit-btn">Open Report
                                                            </button>&nbsp;
                                                            <button type="submit" *ngIf="this.PurchasesbyOrganizationReport"
                                                            data-toggle="modal"  data-target="#PurchasesbyOrganizationReport" 
                                                            class="btn btn-secondary submit-btn">Open Report
                                                            </button>&nbsp;
                                                            <button type="submit" *ngIf="this.Last3MonthsSalesReport"
                                                            data-toggle="modal"  data-target="#Last3MonthsSalesReport" 
                                                            class="btn btn-secondary submit-btn">Open Report
                                                            </button>&nbsp;
                                                            <button type="submit" *ngIf="this.CouponSalesReport"
                                                            data-toggle="modal"  data-target="#CouponSalesReport" 
                                                            class="btn btn-secondary submit-btn">Open Report
                                                            </button>&nbsp;
                                                            <button type="submit" *ngIf="this.SaleOrdersByChannel"
                                                            data-toggle="modal"  data-target="#SaleOrdersByChannel" 
                                                            class="btn btn-secondary submit-btn">Open Report
                                                            </button>&nbsp;
                                                            <button type="submit" *ngIf="this.SalesReport"
                                                            data-toggle="modal"  data-target="#SalesReport" 
                                                            class="btn btn-secondary submit-btn">Open Report
                                                            </button>&nbsp;
                                                            <button type="submit" *ngIf="this.SalesOrderDiscountCode"
                                                            data-toggle="modal"  data-target="#SalesOrderDiscountCode" 
                                                            class="btn btn-secondary submit-btn">Open Report
                                                            </button>&nbsp;

                                                            <button type="submit" *ngIf="this.ProductDemandReport"
                                                            data-toggle="modal"  data-target="#ProductDemandReport" 
                                                            class="btn btn-secondary submit-btn">Open Report
                                                            </button>&nbsp;
                                                        </div>
                                                    </div>
                                                </form>
                         
                                               
                                            </div>
                                            <!-- <div style="width: 49%; float: left;">
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling h4Margin">

                                                            Sales Report
                                                        </h4>
                                                    </div>
                                                    <form [formGroup]="reportForm">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">
                                                                            Choose Report Type 
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group">
                                                                        <p-dropdown filter="true"
                                                                            formControlName="ProductStoreID"
                                                                            optionlabel="label" [options]="reportTypeArray"
                                                                            placeholder="Enter Product Store ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                     
                                                            <div class="col-lg-8 main-submit-button">
                                                                <button type="submit"
                                                                data-toggle="modal"  data-target="#SalesbyStoreReportpopup" 
                                                                class="btn btn-secondary submit-btn">Open Report
                                                                </button>&nbsp;
                                                            </div>
                                                        </div>
                                                    </form>
                             
                                                   
                                                </div>
                                                <div class="w3-card-4 classCardView mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling h4Margin">
                                                            Open Order Items Report
                                                        </h4>
                                                    </div>
                                                    <form [formGroup]="OpenOrderItemsReportForm">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">
                                                                            Product Store ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group">
                                                                        <p-dropdown filter="true"
                                                                            formControlName="ProductStoreID"
                                                                            optionlabel="label" [options]="productStoreIdReportArray"
                                                                            placeholder="Enter Product Store ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Order Type ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group">
                                                                        <p-dropdown filter="true"
                                                                            formControlName="OrderTypeID"
                                                                            optionlabel="label" [options]="OrderTypeIDArray"
                                                                            placeholder="Enter Order Type ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Order Status ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group">
                                                                        <p-dropdown filter="true"
                                                                            formControlName="OrderStatusID"
                                                                            optionlabel="label" [options]="statusOpenOrderArray"
                                                                            placeholder="Enter Order Status ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="productId">From Date (Order
                                                                            Date>=)</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="date" class="form-control"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            formControlName="FromDate"
                                                                            placeholder="Enter From Date">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="productId">Through Date (Order Date)
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="date" class="form-control"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            formControlName="ThroughDate"
                                                                            placeholder="Enter Through Date">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-8 main-submit-button">
                                                                <button type="submit"
                                                                  (click)="detailOpenOrderPage()"  
                                                                  class="btn btn-secondary submit-btn">Run
                                                                </button>&nbsp;
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div class="w3-card-4 classCardView mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling h4Margin">
                                                            Purchases by Organization Report
                                                        </h4>
                                                    </div>
                                                    <form [formGroup]="PurchasesbyOrganizationReportForm">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">From Party
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group">
                                                                        <p-dropdown filter="true"
                                                                            formControlName="FromParty"
                                                                            optionlabel="label" [options]="partyIdArray"
                                                                            placeholder="Enter From Party">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">To Party ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group">
                                                                        <p-dropdown filter="true"
                                                                            formControlName="ToPartyID"
                                                                            optionlabel="label" [options]="partyIdArray"
                                                                            placeholder="Enter To Party ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Order Status ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group">
                                                                        <p-dropdown filter="true"
                                                                            formControlName="OrderStatusID"
                                                                            optionlabel="label" [options]="StatusIdReportArray"
                                                                            placeholder="Enter Order Status ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="productId">From Date </label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="date" class="form-control"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            formControlName="FromDate"
                                                                            placeholder="Enter From Date">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="productId">Through Date </label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="date" class="form-control"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            formControlName="ThroughDate"
                                                                            placeholder="Enter Through Date">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-8 main-submit-button">
                                                                <button type="submit"
                                                                (click)="generatePurchasesByOrganizationReportPdf() "    class="btn btn-secondary submit-btn">Run
                                                                </button>&nbsp;
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div class="w3-card-4 classCardView mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling h4Margin">
                                                            Sale Orders By Channel
                                                        </h4>
                                                    </div>
                                                    <form [formGroup]="SaleOrdersByChannelForm">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">
                                                                            Sales Channel
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group">
                                                                        <p-dropdown filter="true"
                                                                            formControlName="SalesChannel"
                                                                            optionlabel="label" [options]="salesChannelArray"
                                                                            placeholder="Enter Sales Channel">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="col-lg-8 main-submit-button">
                                                                <button type="submit"
                                                                 (click)="generateSalesOrderByChannelPdf()"   class="btn btn-secondary submit-btn">Run
                                                                </button>&nbsp;
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div class="w3-card-4 classCardView mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling h4Margin">
                                                            Sales Order Discount Code
                                                        </h4>
                                                    </div>
                                                    <form [formGroup]="SalesOrderDiscountCodeForm">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-8 form-group classInput">
                                                                        <label>All order items with discount code
                                                                        </label>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div class="col-lg-8 main-submit-button">
                                                                <button type="submit"
                                                                 (click)="generateSalesOrderDiscountCode()"   class="btn btn-secondary submit-btn">Run
                                                                </button>&nbsp;
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div style="width: 49.8%; float: right;">
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling h4Margin">
                                                            Purchases by Product Report
                                                        </h4>
                                                    </div>
                                                    <form [formGroup]="PurchasesbyProductReportForm">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">
                                                                            Product Store ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group">
                                                                        <p-dropdown filter="true"
                                                                            formControlName="ProductStoreID"
                                                                            optionlabel="label" [options]="productStoreIdReportArray"
                                                                            placeholder="Enter Product Store ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">
                                                                            Origin Facility ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group">
                                                                        <p-dropdown filter="true"
                                                                            formControlName="OriginFacilityID"
                                                                            optionlabel="label" [options]="originFacilityIdReportArray"
                                                                            placeholder="Enter Origin Facility ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">
                                                                            Terminal ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group">
                                                                        <input type="email" class="form-control"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            formControlName="TerminalID"
                                                                            placeholder="Enter Terminal ID">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Status ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group">
                                                                        <p-dropdown filter="true"
                                                                            formControlName="OrderStatusID"
                                                                            optionlabel="label" [options]="StatusIdReportArray"
                                                                            placeholder="Enter Status ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="productId">From Date (Order
                                                                            Date>=)</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="date" class="form-control"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            formControlName="FromDate"
                                                                            placeholder="Enter From Date">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="productId">Through Date (Order Date)
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="date" class="form-control"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            formControlName="ThroughDate"
                                                                            placeholder="Enter Through Date">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-8 main-submit-button">
                                                                <button type="submit"
                                                                   (click)="generatePurchaseByProductReportPdf()" class="btn btn-secondary submit-btn">Run
                                                                </button>&nbsp;
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                              
                                                <div class="w3-card-4 classCardView mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling h4Margin">
                                                            Purchases by Payment Method Report
                                                        </h4>
                                                    </div>
                                                    <form [formGroup]="PurchasesbyPaymentMethodReportForm">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">
                                                                            Product Store ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group">
                                                                        <p-dropdown filter="true"
                                                                            formControlName="ProductStoreID"
                                                                            optionlabel="label" [options]="productStoreIdReportArray"
                                                                            placeholder="Enter Product Store ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">
                                                                            Origin Facility ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group">
                                                                        <p-dropdown filter="true"
                                                                            formControlName="OriginFacilityID"
                                                                            optionlabel="label" [options]="originFacilityIdReportArray"
                                                                            placeholder="Enter Origin Facility ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">
                                                                            Terminal ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group">
                                                                        <input type="email" class="form-control"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            formControlName="TerminalID"
                                                                            placeholder="Enter Terminal ID">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row"> 
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Status ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group">
                                                                        <p-dropdown filter="true"
                                                                            formControlName="OrderStatusID"
                                                                            optionlabel="label" [options]="StatusIdReportArray"
                                                                            placeholder="Enter Status ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Order Type ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group">
                                                                        <p-dropdown filter="true"
                                                                            formControlName="OrderTypeID"
                                                                            optionlabel="label" [options]="OrderTypeIDArray"
                                                                            placeholder="Enter Order Type ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="productId">From Date (Order
                                                                            Date>=)</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="date" class="form-control"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            formControlName="FromDate"
                                                                            placeholder="Enter From Date">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="productId">Through Date (Order Date)
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="date" class="form-control"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            formControlName="ThroughDate"
                                                                            placeholder="Enter Through Date">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-8 main-submit-button">
                                                                <button type="submit"
                                                                (click)="generatePurchasesByPaymentMethodReportPdf()"    
                                                                class="btn btn-secondary submit-btn">Run
                                                                </button>&nbsp;
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                
                                                
                                                <div class="w3-card-4 classCardView mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling h4Margin">
                                                            Last 3 Months Sales Report
                                                        </h4>
                                                    </div>
                                                    <form [formGroup]="">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                        
                                                            <div class="col-lg-8 main-submit-button">
                                                                <button type="submit"
                                                                   (click)="generateLastThreeMonthsSalesReport()" class="btn btn-secondary submit-btn">Run
                                                                </button>&nbsp;
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div class="w3-card-4 classCardView mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling h4Margin">
                                                            Coupon Sales Report
                                                        </h4>
                                                    </div>
                                                    <form [formGroup]="">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                        
                                                            <div class="col-lg-8 main-submit-button">
                                                                <button type="submit"
                                                               (click)="generateCouponSalesReport()"     class="btn btn-secondary submit-btn">Run
                                                                </button>&nbsp;
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div class="w3-card-4 classCardView mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling h4Margin">
                                                            Sales Report
                                                        </h4>
                                                    </div>
                                                    <form [formGroup]="SalesReportForm">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label>From Date </label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="date" class="form-control"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            formControlName="FromDate"
                                                                            placeholder="Enter From Date">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Report By
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group">
                                                                        <p-dropdown filter="true"
                                                                            formControlName="ReportBy"
                                                                            optionlabel="label" [options]="ReportByArray"
                                                                            placeholder="Enter Report By">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Format Type
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group">
                                                                        <p-dropdown filter="true"
                                                                            formControlName="FormatType"
                                                                            optionlabel="label" [options]="FormatTypeArray"
                                                                            placeholder="Enter Format Type">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                          
                                                            <div class="col-lg-8 main-submit-button">
                                                                <button type="submit"
                                                              (click)=" generateSalesReport()"      class="btn btn-secondary submit-btn">Run
                                                                </button>&nbsp;
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div class="w3-card-4 classCardView mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling h4Margin">
                                                            Product Demand Report
                                                        </h4>
                                                    </div>
                                                    <form [formGroup]="ProductDemandReportForm">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label>Product Store ID</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <p-dropdown filter="true"
                                                                        formControlName="ProductStoreID"
                                                                        optionlabel="label" [options]="productStoreIdReportArray"
                                                                        placeholder="Enter Product Store ID">
                                                                    </p-dropdown>
                                                                      
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-8 main-submit-button">
                                                                <button type="submit"
                                                                (click)=" generateProductDemandReport()"  
                                                                class="btn btn-secondary submit-btn">Run
                                                                </button>&nbsp;
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="SalesbyStoreReportpopup" tabindex="-1" role="dialog" aria-labelledby="SalesbyStoreReportpopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Sales by Store Report
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonA>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Sales by Store Report</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="SalesbyStoreReportForm">
                                        <div
                                            class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                            Product Store ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true"
                                                            formControlName="ProductStoreID"
                                                            optionlabel="label" [options]="productStoreIdReportArray"
                                                            placeholder="Enter Product Store ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">To Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true"
                                                            formControlName="ToPartyID"
                                                            optionlabel="label" [options]="partyIdArray"
                                                            placeholder="Enter To Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                         
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Order Status ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true"
                                                            formControlName="OrderStatusID"
                                                            optionlabel="label" [options]="StatusIdReportArray"
                                                            placeholder="Enter Order Status ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">From Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control"
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="FromDate"
                                                            placeholder="Enter From Date">
                                                    </div>
                                                </div>
                                            </div>
                                         
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control"
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="ThroughDate"
                                                            placeholder="Enter Through Date">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-8 main-submit-button">
                                                <button type="submit"
                                                (click)="onSalesByStoreReportPdf()"    class="btn btn-secondary submit-btn">Run
                                                </button>&nbsp;
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="OpenOrderItemsReport" tabindex="-1" role="dialog" aria-labelledby="OpenOrderItemsReport"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Open Order Items Report

                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonB>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Open Order Items Report</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="OpenOrderItemsReportForm">
                                        <div
                                            class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                            Product Store ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true"
                                                            formControlName="ProductStoreID"
                                                            optionlabel="label" [options]="productStoreIdReportArray"
                                                            placeholder="Enter Product Store ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Order Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true"
                                                            formControlName="OrderTypeID"
                                                            optionlabel="label" [options]="OrderTypeIDArray"
                                                            placeholder="Enter Order Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Order Status ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true"
                                                            formControlName="OrderStatusID"
                                                            optionlabel="label" [options]="statusOpenOrderArray"
                                                            placeholder="Enter Order Status ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">From Date (Order
                                                            Date>=)</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control"
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="FromDate"
                                                            placeholder="Enter From Date">
                                                    </div>
                                                </div>
                                            </div>
                                       
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Through Date (Order Date)
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control"
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="ThroughDate"
                                                            placeholder="Enter Through Date">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-8 main-submit-button">
                                                <button type="submit"
                                                  (click)="detailOpenOrderPage()"  
                                                  class="btn btn-secondary submit-btn">Run
                                                </button>&nbsp;
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="PurchasesbyOrganizationReport" tabindex="-1" role="dialog" aria-labelledby="PurchasesbyOrganizationReport"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Purchases by Organization Report


                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonC>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Purchases by Organization Report
                                </a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="PurchasesbyOrganizationReportForm">
                                        <div
                                            class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Party
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true"
                                                            formControlName="FromParty"
                                                            optionlabel="label" [options]="partyIdArray"
                                                            placeholder="Enter From Party">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">To Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true"
                                                            formControlName="ToPartyID"
                                                            optionlabel="label" [options]="partyIdArray"
                                                            placeholder="Enter To Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Order Status ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true"
                                                            formControlName="OrderStatusID"
                                                            optionlabel="label" [options]="StatusIdReportArray"
                                                            placeholder="Enter Order Status ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">From Date </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control"
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="FromDate"
                                                            placeholder="Enter From Date">
                                                    </div>
                                                </div>
                                            </div>
                                         
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Through Date </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control"
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="ThroughDate"
                                                            placeholder="Enter Through Date">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-8 main-submit-button">
                                                <button type="submit"
                                                (click)="generatePurchasesByOrganizationReportPdf() "    class="btn btn-secondary submit-btn">Run
                                                </button>&nbsp;
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="SaleOrdersByChannel" tabindex="-1" role="dialog" aria-labelledby="SaleOrdersByChannel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Sale Orders By Channel
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonD>&times;</span>
                </button>
            </div>
            <div class="modal-body ">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Sale Orders By Channel

                                </a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="SaleOrdersByChannelForm">
                                        <div
                                            class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                            Sales Channel
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true"
                                                            formControlName="SalesChannel"
                                                            optionlabel="label" [options]="salesChannelArray"
                                                            placeholder="Enter Sales Channel">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-8 main-submit-button">
                                                <button type="submit"
                                                 (click)="generateSalesOrderByChannelPdf()"   class="btn btn-secondary submit-btn">Run
                                                </button>&nbsp;
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="SalesOrderDiscountCode" tabindex="-1" role="dialog" aria-labelledby="SalesOrderDiscountCode"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Sales Order Discount Code
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonE>&times;</span>
                </button>
            </div>
            <div class="modal-body  ">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Sales Order Discount Code

                                </a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="SalesOrderDiscountCodeForm">
                                        <div
                                            class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-8 form-group classInput">
                                                        <label>All order items with discount code
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-8 main-submit-button">
                                                <button type="submit"
                                                 (click)="generateSalesOrderDiscountCode()"   class="btn btn-secondary submit-btn">Run
                                                </button>&nbsp;
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="PurchasesbyProductReport" tabindex="-1" role="dialog" aria-labelledby="PurchasesbyProductReport"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Purchases by Product Report
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonF>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a>Purchases by Product Report
                                </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="PurchasesbyProductReportForm">
                                        <div
                                            class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                            Product Store ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true"
                                                            formControlName="ProductStoreID"
                                                            optionlabel="label" [options]="productStoreIdReportArray"
                                                            placeholder="Enter Product Store ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                            Origin Facility ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true"
                                                            formControlName="OriginFacilityID"
                                                            optionlabel="label" [options]="originFacilityIdReportArray"
                                                            placeholder="Enter Origin Facility ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                            Terminal ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="email" class="form-control"
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="TerminalID"
                                                            placeholder="Enter Terminal ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Status ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true"
                                                            formControlName="OrderStatusID"
                                                            optionlabel="label" [options]="StatusIdReportArray"
                                                            placeholder="Enter Status ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                         
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">From Date (Order
                                                            Date>=)</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control"
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="FromDate"
                                                            placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Through Date (Order Date)
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control"
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="ThroughDate"
                                                            placeholder="Enter Through Date">
                                                    </div>
                                                </div>
                                            </div>
                                       

                                            <div class="col-lg-8 main-submit-button">
                                                <button type="submit"
                                                   (click)="generatePurchaseByProductReportPdf()" class="btn btn-secondary submit-btn">Run
                                                </button>&nbsp;
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="PurchasesbyPaymentMethodReport" tabindex="-1" role="dialog" aria-labelledby="PurchasesbyPaymentMethodReport"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Purchases by Payment Method Report

                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonG>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a>Purchases by Payment Method Report

                                </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="PurchasesbyPaymentMethodReportForm">
                                        <div
                                            class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                            Product Store ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true"
                                                            formControlName="ProductStoreID"
                                                            optionlabel="label" [options]="productStoreIdReportArray"
                                                            placeholder="Enter Product Store ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                            Origin Facility ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true"
                                                            formControlName="OriginFacilityID"
                                                            optionlabel="label" [options]="originFacilityIdReportArray"
                                                            placeholder="Enter Origin Facility ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                         
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                            Terminal ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="email" class="form-control"
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="TerminalID"
                                                            placeholder="Enter Terminal ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Status ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true"
                                                            formControlName="OrderStatusID"
                                                            optionlabel="label" [options]="StatusIdReportArray"
                                                            placeholder="Enter Status ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                       
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Order Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true"
                                                            formControlName="OrderTypeID"
                                                            optionlabel="label" [options]="OrderTypeIDArray"
                                                            placeholder="Enter Order Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">From Date (Order
                                                            Date>=)</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control"
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="FromDate"
                                                            placeholder="Enter From Date">
                                                    </div>
                                                </div>
                                            </div>
                                         
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Through Date (Order Date)
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control"
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="ThroughDate"
                                                            placeholder="Enter Through Date">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-8 main-submit-button">
                                                <button type="submit"
                                                (click)="generatePurchasesByPaymentMethodReportPdf()"    
                                                class="btn btn-secondary submit-btn">Run
                                                </button>&nbsp;
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="Last3MonthsSalesReport" tabindex="-1" role="dialog" aria-labelledby="Last3MonthsSalesReport"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Last 3 Months Sales Report

                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonH>&times;</span>
                </button>
            </div>
            <div class="modal-body ">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a>Last 3 Months Sales Report

                                </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="">
                                        <div
                                            class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Download Report
                                                        </label>
                                                    </div>
                                                 
                                                </div>
                                            </div>
                                        
                                            <div class="col-lg-3 main-submit-button">
                                                <button type="submit"
                                                   (click)="generateLastThreeMonthsSalesReport()" class="btn btn-secondary submit-btn">Run
                                                </button>&nbsp;
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="CouponSalesReport" tabindex="-1" role="dialog" aria-labelledby="CouponSalesReport"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Coupon Sales Report
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonI>&times;</span>
                </button>
            </div>
            <div class="modal-body ">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Coupon Sales Report
                                </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="">
                                        <div
                                            class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Download Report
                                                        </label>
                                                    </div>
                                                 
                                                </div>
                                            </div>
                                        
                                            <div class="col-lg-3 main-submit-button">
                                                <button type="submit"
                                               (click)="generateCouponSalesReport()"     class="btn btn-secondary submit-btn">Run
                                                </button>&nbsp;
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="SalesReport" tabindex="-1" role="dialog" aria-labelledby="SalesReport"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">  Sales Report
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonJ>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a>  Sales Report
                                </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="SalesReportForm">
                                        <div
                                            class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label>From Date </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control"
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="FromDate"
                                                            placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Report By
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true"
                                                            formControlName="ReportBy"
                                                            optionlabel="label" [options]="ReportByArray"
                                                            placeholder="Enter Report By">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Format Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true"
                                                            formControlName="FormatType"
                                                            optionlabel="label" [options]="FormatTypeArray"
                                                            placeholder="Enter Format Type">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-8 main-submit-button">
                                                <button type="submit"
                                              (click)=" generateSalesReport()"      class="btn btn-secondary submit-btn">Run
                                                </button>&nbsp;
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="ProductDemandReport" tabindex="-1" role="dialog" aria-labelledby="ProductDemandReport"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Product Demand Report

                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonK>&times;</span>
                </button>
            </div>
            <div class="modal-body  ">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a>  Product Demand Report

                                </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="ProductDemandReportForm">
                                        <div
                                            class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label>Product Store ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true"
                                                        formControlName="ProductStoreID"
                                                        optionlabel="label" [options]="productStoreIdReportArray"
                                                        placeholder="Enter Product Store ID">
                                                    </p-dropdown>
                                                      
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-5 main-submit-button">
                                                <button type="submit"
                                                (click)=" generateProductDemandReport()"  
                                                class="btn btn-secondary submit-btn">Run
                                                </button>&nbsp;
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>