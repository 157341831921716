<div class="container-fluid main-container-wrapper">
    <br>


    <div class=" bg-white color-grey">
        <div class="port-header-contact create-lead font-medium mb-0">
           <span class="color-black pl-1 titlePanels">General Ledger Setting

            </span>
        </div>
        <div class="w3-card-4 classCard classCardType">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                    <li [ngClass]="activeCategory==1?'active':''" (click)="chartAccount()"><a>
                    Charts of Account</a></li>
                    <li [ngClass]="activeCategory==2?'active':''" (click)="timePeriod()"><a>
                    Custom Time Period</a></li>
                    <li [ngClass]="activeCategory==3?'active':''" (click)="cost()"><a>
                        Cost</a></li>
                    <li [ngClass]="activeCategory==4?'active':''" (click)="paymentMethod()"><a>
                        Payment Method Type</a></li>
                    <li [ngClass]="activeCategory==5?'active':''" (click)="invoiceMethod()"><a>
                            Invoice Item Type</a></li>
                    <li [ngClass]="activeCategory==6?'active':''" (click)="rate()"><a>
                            Rates</a></li>
                    <li [ngClass]="activeCategory==7?'active':''" (click)="foreignExc()"><a>
                                Foreign Exchange Rates</a></li>
                    <li [ngClass]="activeCategory==8?'active':''" (click)="accountCat()"><a>
                                Gl Account Category</a></li>
                    <li [ngClass]="activeCategory==9?'active':''" (click)="costCenter()"><a>
                                    Cost Center</a></li>

                </ul>
            </div>

            <div class="create-new-leade mt-2">
                <div class="container-fluid">


                    <div class="panel-group">
                        <div *ngIf="activeCategory==7">


                            <div class="panel-collapse">
                                <br>

                                <div class="create-new-leade mt-2">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <form class="w-100">
                                                <div class="w3-card-4 classCard">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <!--new code-->
                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" data-toggle="modal" data-target="#exampleModalCenter">Create New Foreign Exchange Rate</button>
                                                        <!--new code end-->
                                                        <!-- <button type="button" class="btn btn-outline-secondary" routerLinkActive="active"
                                                                    (click)="createForeignExchange()">Create New Foreign Exchange Rate</button> -->

                                                    </div><br>
                                                    <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <label for="exampleInputEmail1">Foreign Exchange Rates List</label>

                                                                <div class="card own-account-table">
                                                                    <p-table [value]="foreignExchangeRates" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>



                                                                                        From Currency
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> To Currency
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>

                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Purpose
                                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Rate
                                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> From Date
                                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Through Date
                                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                                    </div>
                                                                                </th>



                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{product.uomId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.uomIdTo}}
                                                                                </td>

                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.purpose}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.conversionFactor}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                </td>


                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>



                    </div>


                </div>
            </div>
        </div>
    </div>
</div>

<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1" *ngIf="!show">Add Foreign Exchange Rates</span>
                <span class="color-black pl-1" *ngIf="show">Update Foreign Exchange Rates</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                   <span aria-hidden="true" #closebutton>&times;</span>
               </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==7?'active':''" *ngIf="!show"><a>
                        Add Foreign Exchange Rates</a></li>
                                <li [ngClass]="activeCategory==7?'active':''" *ngIf="show"><a>
                            Update Foreign Exchange Rates</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createRate">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                    From Currency<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter From Currency" filter="true" formControlName="uomId" [options]="uomArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">To Currency<span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter To Currency" filter="true" formControlName="uomIdTo" [options]="uomArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Purpose</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Purpose" filter="true" formControlName="purposeEnumId" [options]="exchangePurposeArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Rate <span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Rate" formControlName="conversionFactor">

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date 
                                               
                                                </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Rate" formControlName="fromDate">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Through Date 
                                               
                                                </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Rate" formControlName="thruDate">

                                                    </div>



                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 78%;">
                                        <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->
<ngx-spinner></ngx-spinner>