import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-create-gl-rates',
  templateUrl: './create-gl-rates.component.html',
  styleUrls: ['./create-gl-rates.component.css']
})
export class CreateGlRatesComponent implements OnInit {
  show: boolean;
  createRate: FormGroup;
  emplPositionType: any;
  periodTypes: any;
  activeCategory = 2;
  rateTypes: any;
  workEffortId: any;
  emplPositionTypeArray: any[] = [];
  periodTypesArray: any[] = [];
  rateTypesArray: any[] = [];
  workEffortIdArray: any[] = [];
  personId: any;
  partyIdArray: any[] = [];
  currencyArray: any[] = [];
  uom: any;
  uomArray: any[]=[];
  constructor(readonly spinner: NgxSpinnerService,
   
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
   
    readonly _FormBuilder: FormBuilder,
    readonly addSkillService: AddSkillService,) {
    this.createRate = this._FormBuilder.group({
      emplPositionTypeId: [''],
      partyId: [''],
      periodTypeId: ['',[Validators.required]],
      rateAmount: ['',[Validators.required]],
      rateCurrencyUomId: ['',[Validators.required]],
      rateTypeId: ['',[Validators.required]],
      workEffortId: [''],


    });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.show = false;
    this.getRatesInitialData();
    this.getInitialDataCost();
    this.getCurrency();
    this.getPartyId();
  }
  getInitialDataCost() {
    this.spinner.show();
    this.accountsService.getInitialDataCost().subscribe(res => {
      
      this.uom = res.data.uom;
      this.spinner.hide();
      for (const value of this.uom) {
        this.uomArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
   
  }
  getRatesInitialData() {
    this.spinner.show();
    this.accountsService.getRatesInitialData().subscribe(res => {
      this.emplPositionType = res.data.emplPositionType;
      this.periodTypes = res.data.periodTypes;
      this.rateTypes = res.data.rateTypes;
      this.workEffortId = res.data.workEffortId;
      this.spinner.hide();
      for (const value of this.emplPositionType) {
        this.emplPositionTypeArray.push({
          label: value.description,
          value: value.emplPositionTypeId
        })
      }
      for (const value of this.periodTypes) {
        this.periodTypesArray.push({
          label: value.description,
          value: value.periodTypeId
        })
      }
      for (const value of this.rateTypes) {
        this.rateTypesArray.push({
          label: value.description,
          value: value.rateTypeId
        })
      }
      for (const value of this.workEffortId) {
        this.workEffortIdArray.push({
          label: value.workEffortName,
          value: value.workEffortId
        }) }}) }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
   
  }
  cancelSubmit() {
    this.router.navigate(["financial/global-gl-rate-list"])
  }

  onSubmit() {
    this.spinner.show();
    const requestData = {
      "emplPositionTypeId": this.createRate.value.emplPositionTypeId,
      "partyId": this.createRate.value.partyId,
      "periodTypeId": this.createRate.value.periodTypeId,
      "rateAmount":this.createRate.value.rateAmount,
      "rateCurrencyUomId": this.createRate.value.rateCurrencyUomId,
      "rateTypeId": this.createRate.value.rateTypeId,
      "workEffortId": this.createRate.value.workEffortId



    }

    this.accountsService.postGlSetingsRateList(requestData).subscribe((res: any) => {
   
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(["financial/global-gl-rate-list"])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
