<div class="container-fluid main-container-wrapper" style="margin-top: 7%;">
    <div class="row">
        <div class="col-12">
           
            <div class="w3-card-4 classCard" >
                <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                   <span class="color-black pl-1 titlePanels">Merge </span>
                    <span class="color-black pl-1"> > MergeLeads</span>
                    <button class="btn btn-danger text-white buttonclass"  routerLink="/crm/leads"
                                routerLinkActive="active">Cancel</button>
                </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">
                        <button type="button" class="btn btn-outline-secondary active">Merge</button>
                    </div>
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12" >
                                        <div class="row">
                                            <div class="col-lg-2 form-group rightForm">
                                                <label for="exampleInputEmail1">From Lead</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" [(ngModel)]="partyIdFrom" placeholder="From Lead"
                                                [ngModelOptions]="{standalone: true}" [options]="partyIdArray"
                                                optionlabel="label"></p-dropdown>
                                            </div>
                                           
                                            
                                           
                                        </div>
                                      </div>
                                      <div class="col-lg-12" >
                                        <div class="row">
                                            <div class="col-lg-2 form-group rightForm">
                                                <label for="exampleInputEmail1">To Lead</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [(ngModel)]="partyIdTo" placeholder="To Lead"
                                                    [ngModelOptions]="{standalone: true}" [options]="partyIdArray"
                                                    filter="true" optionlabel="label">
                                                </p-dropdown>
                                            </div>
                                           
                                            
                                           
                                        </div>
                                      </div>
                                  
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <br><br>
                                            <label for="exampleInputEmail1"></label>
                                            <div class="merge-user-section merge-small-area">
                                              
                                              
                                                    <button type="button"
                                                        class="btn btn-outline-secondary active">Merge</button>
                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <br>
    <div class="w3-card-4 classCard" >
    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
        <div class="panel panel-default">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians" style="padding-left:9px">
                  <li ><a>
                    Merge Lead List</a></li>
                </ul>
              </div>
            <div >
                <div class="panel-body" *ngIf="mergeContacts && mergeContacts.length > 0">
                    <div class="all-users-infomation font-13 font-medium own-user-select">
                        <div class="color-black container">
                            <div class="row color" >
                                <table  class="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>First Contact
                                            </th>
                                            <th>Second Contact
                                            </th>
                                            <th>Select
                                            </th>
                                        </tr>
                                    </thead>
                                    <!-- *ngFor="let data of " -->
                                    <tbody>
                                        <tr>
                                            <td>First name</td>
                                            <td>{{mergeContacts[0].firstName}}</td>
                                            <td>{{mergeContacts[1].firstName}}</td>
                                            <td rowspan="2"><label class="address">
                                                    <input type="checkbox" [(ngModel)]="useContactNumTo"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (change)="checkContact($event)" class="form-check-input"
                                                        value="">
                                                </label></td>
                                        </tr>
                                        <tr>
                                            <td>Last Name</td>
                                            <td>{{mergeContacts[0].lastName}}</td>
                                            <td>{{mergeContacts[1].lastName}}</td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td>General Correspondence Address </td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td>Address Line 1 </td>
                                            <td>{{mergeContacts[0].address1}}</td>
                                            <td>{{mergeContacts[1].address1}}</td>
                                            <td rowspan="5"> <label class="form-check-label">
                                                    <input type="checkbox" [(ngModel)]="useAddressTo"
                                                        [ngModelOptions]="{standalone: true}" id="hash"
                                                        (change)="checkAddress($event)" class="form-check-input"
                                                        value="">
                                                </label>
                                            </td>

                                        </tr>
                                      
                                        <tr>
                                            <td>Address Line 2 </td>
                                            <td>{{mergeContacts[0].address2}}</td>
                                            <td>{{mergeContacts[1].address2}}</td>
                                            <td></td>


                                        </tr>
                                        <tr>
                                            <td>City </td>
                                            <td>{{mergeContacts[0].city}}</td>
                                            <td>{{mergeContacts[1].city}}</td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td>State/Province </td>
                                            <td>{{mergeContacts[0].state}}</td>
                                            <td>{{mergeContacts[1].state}}</td>
                                            <td></td>


                                        </tr>
                                        <tr>
                                            <td>Country </td>
                                            <td>{{mergeContacts[0].country}}</td>
                                            <td>{{mergeContacts[1].country}}</td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td>Primary Phone Number
                                            </td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td>Area Code </td>
                                            <td>{{mergeContacts[0].areaCode}}</td>
                                            <td>{{mergeContacts[1].areaCode}}</td>
                                            <td></td>


                                        </tr>
                                        <tr>
                                            <td>Phone Number </td>
                                            <td>{{mergeContacts[0].contactNumber}}</td>
                                            <td>{{mergeContacts[1].contactNumber}}</td>
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td>E-Mail Address </td>
                                            <td>{{mergeContacts[0].primaryEmail}}</td>
                                            <td>{{mergeContacts[1].primaryEmail}}</td>
                                            <td rowspan="5"> <label class="forms">
                                                    <input type="checkbox" [(ngModel)]="useEmailTo" id="class"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (change)="checkEmail($event)" class="form-check-input" value="">
                                                </label></td>

                                        </tr>

                                    </tbody>
                                
                                    <tr>
                                        <td> </td>
                                        <td></td>
                                        <td></td>

                                    </tr>
                                    
                                </table>
                                <button type="button" (click)="submitMergeLeads()" style="margin-left: 85%;"
                                    class="btn btn-secondary">Submit</button>
                              <br><br>  <br><br>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 