<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">

    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">
            
           <div class="col-md-12 p-0" >
               <div class="w3-card-4 classCard  w-100">
                   
                    <div class="mt-1" style="margin-bottom: 12px !important; ">
                        <span style="font-size: 15px; font-weight: bold; margin-left: 7px!important;">Work Flow Settings</span>
                    </div>                    
                    <div class="row mb-2" style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 4px 15px; margin-top: -0.5px;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Repair Category and Codes</p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <form class="w-100" [formGroup]="repairCategoryCodesForm">
                                                <div
                                                    class="all-users-infomation font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row ml-2 d-flex ">
                                                            
                                                                <div class="checkbox-align" style="color: black; font-size: 12px; font-weight: bold;">
                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined" value="undefined" formControlName="repairWorkStep">
                                                                            </div>
                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>
                                        
                                                                    &nbsp; Include Repair Category and Repair Codes in Work Step
                                                                </div>

                                                                
                                                            
                                    
                                                            
                                    
                                                            
                                                        </div>
                                                    </div>

                                                    <div class="row font-tweleve" style="margin-left: 5%;">
                                                        <label>Checking this adds Repair Category and Repair Codes fields
                                                            in the Work Done Page
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row ml-2 font-tweleve d-flex ">
                                                            
                                                                <div class="checkbox-align" style="color: black; font-size: 12px; font-weight: bold;">
                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined" value="undefined" formControlName="repairRequireFields">
                                                                            </div>
                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>
                                        
                                                                    &nbsp; Make Repair Category and Repari Codes as required fields
                                                                </div>

                                                                
                                                            
                                    
                                                            
                                    
                                                            
                                                        </div>
                                                    </div>

                                                    <div class="row font-tweleve" style="margin-left: 5%;">
                                                        <label>Checking this makes Repair Category and Repair Codes fields
                                                            required
                                                        </label>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>
                        
                    </div>

                   <div class="d-flex justify-content-end mb-2" style="margin-right: 1%;">
                        <button type="button" class="btn btn-secondary submit-btn ml-2">
                            Save
                        </button>
                   </div>



                   
               </div>
           </div>
                        
        
        
        
        
        </div>
    
    </div>
</div>


        <div class="modal fade" id="TeamMemberPopup" tabindex="-1" role="dialog" aria-labelledby="TeamMemberPopup" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content cssModelLength">
                    <div class="modal-header">
                        <span class="color-black pl-1">Add Team Members</span>
                        <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" #closebutton2>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body d-flex">
                        <div class=" bg-white color-grey w-100">
                            <div class="w3-card-4 classCardPopUp">
                                <div class="container-fluid">
                                    <ul class="tabbing-section tabbing-accordians tabClass">
                                        <li>
                                            <a>Add Team Members</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="create-new-leade mt-2">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <form class="w-100" [formGroup]="addTeamMember">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group rightForm">
                                                                <label for="exampleInputEmail1">New Team Member</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                formControlName="newTeamMember"  [options]="teamMemberArray"
                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                placeholder="Enter New Team Member">
                                                                </p-dropdown>
                                                            </div>

                                                            <div class="col-lg-3 form-group rightFormA">
                                                                <label for="exampleInputEmail1">Role</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label" [options]="RoleArray" formControlName="role" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Role">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-lg-5 main-submit-button" style="margin-left: 52%;">
                                                        <button type="submit" class="btn btn-secondary submit-btn" *ngIf="!this.show" (click)="addTeamMemberInviewTeam()">Create</button>
                                                        <button type="submit" class="btn btn-secondary submit-btn" *ngIf="this.show" (click)="updateTeamMemberInviewTeam()">Update</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<!-- <ngx-spinner></ngx-spinner> -->






