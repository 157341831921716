import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { FinancialService } from 'src/app/services/financial.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { SortEvent } from 'primeng/api';

@Component({
  selector: 'app-edit-account-reconcilation',
  templateUrl: './edit-account-reconcilation.component.html',
  styleUrls: ['./edit-account-reconcilation.component.css']
})
export class EditAccountReconcilationComponent implements OnInit {
  createAccountForm:FormGroup;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  activeCategory=2;
  partyId: any;
  glReconciliationId: any;
  getEstimateFrom: any;
  getEstimateTo: any;
  reconciledDate: any;
  status: { label: string; value: string; }[];
  assignGLPartyId: any;
  assignGLPartyIdArray: any[]=[];
  LastModifiedDate: string;
  CreatedDate: string;
  ReconciledDate: string;
  detailRec: any;
  constructor(
    
    readonly  _Router: Router,
    readonly datePipe: DatePipe,
    readonly _ToastrService: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly accountsService: AccountsService,
    readonly _FormBuilder: FormBuilder,
    readonly _ActivatedRoute: ActivatedRoute,
  ) {
    this.status=[{
      "label":"Created",
      "value":"GLREC_CREATED"
    },
    {
      "label":"Reconciled",
      "value":"GLREC_RECONCILED"
    }]
    this.createAccountForm = this._FormBuilder.group({
      glAccountReconId: [''],
      glReconciliationName: [''],
      Description: [''],
      CreatedDate: [''],
      LastModifiedDate: [''],
      GlAccountID: [''],
      Status: [''],
      OrganizationPartyID: [''],
      ReconciledBalance: [''],
      OpeningBalance: [''],
      ReconciledDate: ['']
    });
  }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
      this.glReconciliationId=params["glReconciliationId"];
    
    
     
    });
    this.getGlAccountInitialData();
    this.getreconcialationDetail();
  }
  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.assignGLPartyId = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.assignGLPartyId) {
        this.assignGLPartyIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }
    })
   
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  getreconcialationDetail(){
    this.spinner.show();
    this.accountsService.getreconcialationDetail(this.partyId,this.glReconciliationId).subscribe((res:any)=> {
      this.detailRec=res.data.glReconciliationEntries
      setTimeout(() => {
        const fromdate=res.data.createdDate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const toEstdate=res.data.lastModifiedDate
        this.getEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
        
        this.reconciledDate=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
       const formValue = this.createAccountForm;
        formValue.patchValue({
          glAccountReconId: res.data.glReconciliationId,
          glReconciliationName: res.data.glReconciliationName,
          Description: res.data.description,
          CreatedDate:  this.getEstimateFrom,
          LastModifiedDate:  this.getEstimateTo,
          GlAccountID: res.data.glAccountId,
          Status: res.data.statusId,
          OrganizationPartyID:res.data.organizationPartyId,
          ReconciledBalance: res.data.reconciledBalance,
          OpeningBalance:res.data.openingBalance,
          ReconciledDate: this.reconciledDate
        })
      }, 3000);
      this.spinner.hide();
    })
   }
   
   createAccount() {
    const ReconciledDate=this.createAccountForm.get('ReconciledDate').value;
    this.ReconciledDate=this.datePipe.transform(ReconciledDate,"yyyy-MM-dd hh:mm:ss");
    const CreatedDate=this.createAccountForm.get('CreatedDate').value;
    this.CreatedDate=this.datePipe.transform(CreatedDate,"yyyy-MM-dd hh:mm:ss");
    const LastModifiedDate=this.createAccountForm.get('LastModifiedDate').value;
    this.LastModifiedDate=this.datePipe.transform(LastModifiedDate,"yyyy-MM-dd hh:mm:ss");
    this.spinner.show();
    const req={
    "createdDate": this.CreatedDate,
    "description": this.createAccountForm.value.Description==null?"":this.createAccountForm.value.Description,
    "glAccountId": this.createAccountForm.value.GlAccountID==null?"":this.createAccountForm.value.GlAccountID,
    "glReconciliationId": this.createAccountForm.value.glAccountReconId,
    "glReconciliationName":this.createAccountForm.value.glReconciliationName,
    "lastModifiedDate":this.LastModifiedDate,
    "openingBalance": this.createAccountForm.value.OpeningBalance==null?"": this.createAccountForm.value.OpeningBalance,
    "reconciledBalance": this.createAccountForm.value.ReconciledBalance,
    "reconciledDate": this.ReconciledDate,
    "statusId": this.createAccountForm.value.Status,
   }
    this.accountsService.updatereconcialation(this.partyId,req).subscribe((res: any) => { 
      if (res.success) {
        this.glReconciliationId=res.data.glReconciliationId
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-accounting-summary'], { queryParams: { partyId: this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  
  cancelSubmit(){
    this._Router.navigate(['/financial/detail-accounting-summary'], { queryParams: { partyId: this.partyId } });
  }
}
