import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmpEmailPhoneModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        partyId:(data && data.partyId) ? data.partyId : null,
       
        contactMechId:(data && data.contactMechId) ? data.contactMechId : null,
      
        allowSolicitation:(data && data.allowSolicitation) ? data.allowSolicitation : null,
        emailAddress:(data && data.emailAddress) ? data.emailAddress : null,
    });
    return this.getFormGroup(form_data);
  }
  getFormGroup(data:any): any {
   return ({
   
    partyId: new FormControl(data.partyId),
  
    contactMechId: new FormControl(data.contactMechId),
 
    emailAddress: new FormControl(data.emailAddress),
    allowSolicitation: new FormControl(data.allowSolicitation),
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}

