<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span class="color-black pl-1 titlePanels">MRP Log List</span>
                <span class="">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>

            </div>
            <div class=" bg-white color-grey">
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                         
                        <div class="w3-card-4 classCard" style="width: 98%;">
                            <div class="header-tabs tabClass">
                                <h4 class=" common-styling h4Margin">
                                   Report
                                </h4>
                            </div>
                              
                              <div class="panel-body">
                                <div class="row">
                                    <form  class="w-100" [formGroup]="createReport">
                                        <div class="all-users-infomation font-13 font-medium own-user-select allUser">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group rigntForm">
                                                        <label for="exampleInputEmail1">
                                                            Mrp Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"   class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Mrp Name"
                                                        formControlName="MrpName" >
                                                    </div>
                                                  
                                                    <div class="col-lg-2"></div>
                                                   
                                                </div>
                                            </div>
                                          
                                       
                                           
                                        </div>
                                        
        
                                    </form>
                                    <div class="main-submit-button" style="margin-left: -73% !important;">
                                       
                                        <button type="submit"  (click)="submit();"   class="btn btn-secondary submit-btn">Submit</button>
                                     
                                    </div>
                                </div>
                                <div *ngIf="mrpRep && mrpRep.length"
                                class="all-users-infomation font-13 font-medium own-user-select allUser">
                                  <div class="form-group" *ngFor="let r of mrpRep">
                                <p>Mrp Name {{r.mrpName}}</p>
                                  </div>
                                </div>
                              </div>
                    </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
          
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 