import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
@Component({
  selector: 'app-create-gl-account-fixed-assest',
  templateUrl: './create-gl-account-fixed-assest.component.html',
  styleUrls: ['./create-gl-account-fixed-assest.component.css']
})
export class CreateGlAccountFixedAssestComponent implements OnInit {
  createFixedAssest:FormGroup;
  activeCategory=2;
  depGlAccountId: any;
  fixedAssetTypeId: any;
  profitGlAccountId: any;
  fixedAssetId: any;
  lossGlAccountId: any;
  accDepGlAccountId: any;
  assetGlAccountId: any;
  depGlAccountIdArray: any[]=[];
  fixedAssetTypeIdArray: any[]=[];
  profitGlAccountIdArray: any[]=[];
  fixedAssetIdArray: any[]=[];
  lossGlAccountIdArray: any[]=[];
  accDepGlAccountIdArray: any[]=[];
  assetGlAccountIdArray: any[]=[];
  partyId: any;
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createFixedAssest = this._FormBuilder.group({
        assestGlAccount: [''],
        accumulated:[''],
        depreciation: [''],
        profit:[''],
        loss:[''],
        fixedAssestTypeId: [''],
        asssestId:['']
       
        
      });
    
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
    });
    this.getInitialDataFixedData();
  }
    
  getInitialDataFixedData() {
    this.spinner.show();
    this.accountsService.getInitialDataFixedData(this.partyId).subscribe((res: any) => {
      this.depGlAccountId = res.data.depGlAccountId;this.fixedAssetTypeId = res.data.fixedAssetTypeId;
      this.profitGlAccountId = res.data.profitGlAccountId;
      this.fixedAssetId = res.data.fixedAssetId;
      this.lossGlAccountId=res.data.lossGlAccountId;
      this.accDepGlAccountId=res.data.accDepGlAccountId;
      this.assetGlAccountId=res.data.assetGlAccountId;
     
      this.spinner.hide();
      for (const value of this.depGlAccountId) {
        this.depGlAccountIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }
      for (const value of this.fixedAssetTypeId) {
        this.fixedAssetTypeIdArray.push({
          label: value.description,
          value: value.fixedAssetTypeId
        })
      }
      for (const value of this.profitGlAccountId) {
        this.profitGlAccountIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }
      for (const value of this.fixedAssetId) {
        this.fixedAssetIdArray.push({
          label: value.fixedAssetName,
          value: value.fixedAssetId
        })
      }
      for (const value of this.lossGlAccountId) {
        this.lossGlAccountIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }
      for (const value of this.accDepGlAccountId) {
        this.accDepGlAccountIdArray.push({
          label: value.description,
          value: value.enumId
        })
      }
      for (const value of this.assetGlAccountId) {
        this.assetGlAccountIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }
    })
  }
  
  cancelSubmit(){
    this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
  }
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "accDepGlAccountId": this.createFixedAssest.value.accumulated,
      "assetGlAccountId": this.createFixedAssest.value.assestGlAccount,
      "depGlAccountId": this.createFixedAssest.value.depreciation,
      "fixedAssetId": this.createFixedAssest.value.asssestId,
      "fixedAssetTypeId": this.createFixedAssest.value.fixedAssestTypeId,
      "lossGlAccountId": this.createFixedAssest.value.loss,
      "profitGlAccountId": this.createFixedAssest.value.profit,
      "organizationPartyId":  this.partyId,
    }
    this.accountsService.createFixedAssetTypeGlAccounts(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }}
        else{
          this._ToastrService.error(err.error.message);
        }});
  }

}


