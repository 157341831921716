import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import Swal from 'sweetalert2'
import { R3Identifiers } from '@angular/compiler';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-communication-email-overview',
  templateUrl: './communication-email-overview.component.html',
  styleUrls: ['./communication-email-overview.component.css']
})
export class CommunicationEmailOverviewComponent implements OnInit {
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  search= '';
  accountsList:any[]=[]
  relatedContact:any[]=[]
  rows = 3;
  activeCategory=2;
  readonly req = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  activeCategoryValue=2;
  createCommEvent:FormGroup;
  opportunityIdArray: { label: string; value: string; }[];
  commEventId: any;
  overviewDetail: any;
  communicationEventRole: any;
  communicationEventContent: any;
  communicationEventChilds: any;
  customerRequestList: any;
  eventPurpose: any;
  eventRole: any;
  stausIdArray: any[]=[];
  comtypeIdArray: any[]=[];
  contentMMArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  advanceSearchOrderByReturn :{
    communicationEventId: string,
    communicationEventIdSearchType: string,
    communicationEventTypeId:string,
    partyId:string,
    partyIdFrom: string,
    partyIdTo: string,
    returnId: string,
    returnIdSearchType: string,
    statusId: string
  }
  advanceSearchOrderBy: {
    communicationEventId:string,
    communicationEventIdSearchType:string,
    communicationEventTypeId: string,
    partyId: string,
    partyIdFrom: string,
    partyIdTo: string,
    orderId: string,
    orderIdSearchType:string,
    statusId: string
   


  };
  evenTByOrder: any;
  commByOrder: any;
  getInitailData: any;
  
  roleTypeId: any;
  roleTypeIdArray: any[]=[];
  contactListId: any;
  contactListIdArray: any[]=[];
  datetimeEnded: string;
  datetimeStarted: string;
  parentCommEventArray: any[]=[];
  reasonenumArray: any[]=[];
  contactMech: any;
  contactMechArray: any[]=[];
  getEstimateFrom: string;
  getEstimateTo: string;
  contactMechTypeArray: any[]=[];
  eventProductList: any;
  contactMechTo: any;
  contactMechToArray: any[]=[];
  eventReturnList: any;
  eventOrderList: any;
  eventContentList: any;
  UnknownPartyComms: any;
  commEventIdPartyId: string;
  eventPurposeArray: any[]=[];
  createEventPurpose1:FormGroup
  roleId: any;
  roleIdArray: any[]=[];
  createEventPurposeNew:FormGroup;
  createEventContent1:FormGroup;
  createProduct1:FormGroup;
  newCommunicationEventID:any;
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  Products: any;
  ProductsArray: any[]=[];
  createReturn1:FormGroup;
  return: any;
  returnArray: any[]=[];
  communctionEventOrderForm: FormGroup;
  ReasonEnumIds: any;
  getOrderIDArray: any[]=[];
  OrderID: any;
  communicationEventId: any;
  CommunicationEventOrders: any;
  reasonenumArrays: any[]=[];
  purposeTypeArrays: any[]=[];
  purposeTypes:any;
  listCommContent: any;
  parentContentIdListsArray: any[]=[];
  parentContentIdList:any;
  eventPurposeArrays: any[]=[];
  findCommunicationReturn: any;
  fileData: File;
  uploadFile:FormData




  constructor(
    readonly router: Router,
    readonly datePipe: DatePipe,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly addSkillService: AddSkillService,
    readonly _FormBuilder: FormBuilder, private _location: Location,

    
    readonly spinner: NgxSpinnerService,
  ) { 
    this.createReturn1 = this._FormBuilder.group({
      returnId: ['']
     
    });
    this.createProduct1 = this._FormBuilder.group({
      productId: ['']
    
    });
    this.createEventContent1 = this._FormBuilder.group({
      extContentId: [''],
      parentContentId:[''],
      uploadFile:['']
     
 
    });
    this.createEventPurposeNew = this._FormBuilder.group({
      partyId: [''],
      roleTypeId:['']
     
 
    });
    this.createEventPurpose1 = this._FormBuilder.group({
      eventPurposeId: [''],
     
 
    });

    this.communctionEventOrderForm = this._FormBuilder.group({
      orderId: [''],
     });

    

    this.advanceSearchOrderByReturn = {
      communicationEventId: "",
      communicationEventIdSearchType: "Contains",
      communicationEventTypeId: "",
      partyId: "",
      partyIdFrom: "",
      partyIdTo: "",
      returnId: "",
      returnIdSearchType: "Contains",
      statusId: ""
     


    };
    this.advanceSearchOrderBy = {
      communicationEventId: "",
      communicationEventIdSearchType: "Contains",
      communicationEventTypeId: "",
      partyId: "",
      partyIdFrom: "",
      partyIdTo: "",
      orderId: "",
      orderIdSearchType: "Contains",
      statusId: ""
     


    };
  this.createCommEvent = this._FormBuilder.group({
    commEventId: [''],
    parCommEventID: [''],
    commTypeId: [''],
    status:[''],
    partyFrom:[''],
    partyTo: [''],
    conMechId: [''],
    fromContactMechId: [''],
    toContactMechId:[''],
    roleTypeFrom:[''],
    roleTypeTo: [''],
    contactListId: [''],
    startdate: [''],
    finishDate:[''],
    subject:[''],
    reasonEnumId: [''],
    contentMimeTypeId: [''],
    content: [''],
    note:[''],

  });

  this.relatedContact=[{
    'product':'main'
  }]
  this.opportunityIdArray = [
    {
      label: 'Contains',
      value: 'Contains'
    },
    {
      label: 'Equals',
      value: 'Equals'
    },
    {
      label: 'Is Empty',
      value: 'Is Empty'
    },
    {
      label: 'Begins With',
      value: 'Begins With'
    },
  ];
  }

  ngOnInit(): void {

    const partyId: string = localStorage.getItem('partyId');
    this.commEventIdPartyId=partyId;
    this.getUnknownPartyComms();

  }
  homeButton(){
    this.router.navigate(['/communication-overview/Dashboard'])

  }
  cancelSubmit(){
    this._location.back();
  }
  
  
   


  getUnknownPartyComms() {
    this.spinner.show();
    this.accountsService.getUnknownPartyComms().subscribe((res: any) => {
      this.UnknownPartyComms = res.data;
      this.spinner.hide();
    })
   
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}
