<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels"  style="cursor: pointer;">Party Attribute
                </span>
                <span class="color-black pl-1" *ngIf="!show"> >Add Party Attribute
                </span>
                <span class="color-black pl-1" *ngIf="show"> >Update Party Attribute
                    </span>
                <button type="submit"  class="btn btn-danger" style="margin-left: 54%;" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians">
          
                      <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                        Add Party Attribute
                    </a></li>
                        <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                            Update Party Attribute
                        </a></li>
                   
          
                    </ul>
                  </div>
              
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100"  [formGroup]="createPartyAttribute">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                   
                                
                   
                                   
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Id Value
                                               
                                            </label>
                                            <div class=""  >
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Id Value"
                                                formControlName="idValue" >
                                            </div>
                                           
                                        </div>
                                       
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Id Value
                                               
                                            </label>
                                            <div class=""  >
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Id Value"
                                                formControlName="idValue" >
                                            </div>
                                           
                                        </div>
                                       
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Id Value
                                               
                                            </label>
                                            <div class=""  >
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Id Value"
                                                formControlName="idValue" >
                                            </div>
                                           
                                        </div>
                                       
                                    </div>
                                   
                                   
                                
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 80%;">
                                <button type="submit" *ngIf="show"  class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit"  *ngIf="!show"   class="btn btn-secondary submit-btn">Submit</button>
                                
                              
                            </div>
                        </div>
                    </div>

                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
