import { QuotesService } from '../../quotes.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-quote-email',
    templateUrl: './quote-email.component.html'
})
export class QuoteEmailComponent implements OnInit {
    mailForm: FormGroup;
    constructor(
        readonly _FormBuilder: FormBuilder,
        readonly  _Router: Router,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _ToastrService: ToastrService,
        readonly spinner:NgxSpinnerService,
        readonly _QuotesService: QuotesService
    ) {
        this.mailForm = this._FormBuilder.group({
            emailType: ['PRDS_QUO_CONFIRM'],
            note: [''],
            quoteId: [''],
            sendCc: [''],
            sendTo: ['', [Validators.required]]
        });
    }
    ngOnInit(): void {

        this.mailForm.patchValue({
            quoteId: this._ActivatedRoute.snapshot.queryParams.quoteId
        });
    }
    createMail(): void {
        this.spinner.show();
        if (this.mailForm.valid) {
            this._QuotesService.quoteEmail(this.mailForm.value)
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Sent');
                        this.spinner.hide();
                        this.cancelSubmit();
                    }
                })
                .catch(error => {
                    this.spinner.hide();
                    if (error.errors) {
                        error.errors.forEach(value => {
                            this._ToastrService.success(value.fieldName);
                        });
                    }
                });
        }
        else{
            this._ToastrService.error('Mail Id not valid');
            this.spinner.hide();
        }
        this.spinner.hide();

       
    }
    cancelSubmit(): void {
        this.mailForm.reset();
        this._Router.navigate(['/order/quote-details'], { queryParams: { quotoId: this._ActivatedRoute.snapshot.queryParams.quoteId } });
    }
}