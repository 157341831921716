
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EmployeeApplicationModel } from '../create-employee-application/create-employee-application-model';

@Injectable({
  providedIn: 'root'
})


export class CreateEventFormService {
  employee_application_form: FormGroup;


  constructor(public _formBuilder: FormBuilder,
    public employeeApplicationModel: EmployeeApplicationModel
  ) { }

  employeeApplicationForm(data: any): void {
    if (!this.employee_application_form) {
      this.employee_application_form = this._formBuilder.group(this.employeeApplicationModel.mapDataToModel(data));

    }

  }

}







