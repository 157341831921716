<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" style="cursor: pointer;">Main
                </span>
                <span class="color-black pl-1"> >Create Public Message
                </span>
                <button type="submit" class="btn btn-danger buttonclass" (click)="GoToMyPortal()">Cancel</button>&nbsp;
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==1?'active':''"><a>
                                    Add Public Message to PartyId: {{this.partyId}}
                                </a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100"  [formGroup]="createPage">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">
                                                <div *ngIf="activeCategory==1">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">
                                                                        Note Party</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Note Party"
                                                                    formControlName="noteParty">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">More Info Url</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter More Info Url"
                                                                    formControlName="moreInfoUrl">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Note Info</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Note Info"
                                                                    formControlName="noteInfo">
                                                                </div>
                                                               
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="main-submit-button" style="margin-right: 34%;">
                                                        <button type="submit" (click)="onSubmit()"
                                                            class="btn btn-secondary submit-btn">Save</button>&nbsp;&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>