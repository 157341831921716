import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NewJobRequistionModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
      age:(data && data.age) ? data.age : null,
      durationMonths:(data && data.durationMonths) ? data.durationMonths : null,
      examTypeEnumId:(data && data.examTypeEnumId) ? data.examTypeEnumId : null,
      experienceInMonths:(data && data.experienceInMonths) ? data.experienceInMonths : null,
      experienceInYears:(data && data.experienceInYears) ? data.experienceInYears : null,
      gender:(data && data.gender) ? data.gender : null,
      jobLocation:(data && data.jobLocation) ? data.jobLocation : null,
      noOfResources:(data && data.noOfResources) ? data.noOfResources : null,
      qualification:(data && data.qualification) ? data.qualification : null,
      skillTypeId:(data && data.skillTypeId) ? data.skillTypeId : null,

    });
    return this.getFormGroup(form_data);
  }
  getFormGroup(data:any): any {
   return ({
    age: new FormControl(data.age),
    durationMonths: new FormControl(data.durationMonths),
    examTypeEnumId: new FormControl(data.examTypeEnumId),
    experienceInMonths: new FormControl(data.experienceInMonths,[Validators.required]),
    experienceInYears: new FormControl(data.experienceInYears,[Validators.required]),
    gender: new FormControl(data.gender,[Validators.required]),
    jobLocation: new FormControl(data.jobLocation,[Validators.required]),
    noOfResources: new FormControl(data.noOfResources,[Validators.required]),
    qualification: new FormControl(data.qualification,[Validators.required]),
    skillTypeId: new FormControl(data.skillTypeId,[Validators.required]),
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
