<div class="container-fluid main-container-wrapper">
    <div style="margin-top: 7%!important;" class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
       <span class="color-black pl-1 titlePanels">Company</span>
    </div>
  
    <div class="row">
     
      <div class="col-12">
        <div style="cursor: pointer;" class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
         <span class="color-black pl-1 titlePanels" (click)="companyOverView()">The Profile of Your Company Name Here [Company] </span>
         
        </div>
      
      </div></div>   
  </div>