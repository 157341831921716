import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-tax-codes',
  templateUrl: './sales-tax-codes.component.html',
  styleUrls: ['./sales-tax-codes.component.css']
})
export class SalesTaxCodesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
