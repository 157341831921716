<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <a><span class="color-black pl-1 titlePanels" (click)="navigate()">Quote </span></a>
                <span class="color-black pl-1"> > New</span>
                <button type="button" class="btn btn-danger buttonclass" (click)="cancelQuote()">Cancel</button>
            </div>
            <div class="w3-card-4 classCard">
                <div class=" bg-white color-grey">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)"><a>
                                    Create Quote</a></li>
                            <li *ngIf="showNotes" [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)"><a>
                                    Notes</a></li>



                        </ul>
                    </div>

                    <div *ngIf="activeCategory==2">
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="createQuetoFormService.create_quetos_form"
                                        [formGroup]="createQuetoFormService.create_quetos_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Quote Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control small-input"
                                                            formControlName="quoteName" id="exampleFormControlTextarea1"
                                                            placeholder="Enter Quote Name" rows="1">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Quote Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown styleClass="small-dropdown" filter="true"
                                                            [options]="quoteTypesArray" formControlName="quoteTypeId"
                                                            optionlabel="label" placeholder="Select Quote Type ID">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12" *ngIf="show">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1" *ngIf="show">Quote Id</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="quoteId"
                                                            class="form-control small-input" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Quote Id"
                                                            readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Party ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown styleClass="small-dropdown" filter="true"
                                                            formControlName="partyId" [options]="partyarray"
                                                            placeholder="Select PartyId" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Quote Issue Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input class="small-input" type="date"
                                                            formControlName="quoteIssueDate">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Status<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown styleClass="small-dropdown" filter="true"
                                                            [options]="statusArrays" placeholder="Select Status"
                                                            formControlName="statusId" optionlabel="label">
                                                        </p-dropdown>
                                                        <ng-container
                                                            *ngIf="createQuetoFormService.create_quetos_form.controls['statusId'].invalid && (createQuetoFormService.create_quetos_form.controls['statusId'].dirty || createQuetoFormService.create_quetos_form.controls['statusId'].touched)">
                                                            <p class="validate-field"
                                                                *ngIf="createQuetoFormService.create_quetos_form.controls['statusId'].errors.required">
                                                                Status is required!</p>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Currency<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown styleClass="small-dropdown" filter="true"
                                                            appendTo="body" [options]="currencyArray"
                                                            optionlabel="label" formControlName="currencyId"
                                                            placeholder="Select Currency">
                                                        </p-dropdown>

                                                        <ng-container
                                                            *ngIf="createQuetoFormService.create_quetos_form.controls['currencyId'].invalid && (createQuetoFormService.create_quetos_form.controls['currencyId'].dirty || createQuetoFormService.create_quetos_form.controls['currencyId'].touched)">
                                                            <p class="validate-field"
                                                                *ngIf="createQuetoFormService.create_quetos_form.controls['currencyId'].errors.required">
                                                                Currency is required!</p>
                                                        </ng-container>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Product Store<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown styleClass="small-dropdown" filter="true"
                                                            [options]="productArray" formControlName="productStoreId"
                                                            placeholder="Enter product store" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Sales Channel</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown styleClass="small-dropdown" filter="true"
                                                            appendTo="body" [options]="salesChannelArray"
                                                            formControlName="salesId" placeholder="Enter sales channel"
                                                            optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Valid From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input class="small-input" type="date"
                                                            formControlName="validFrom">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Valid Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input class="small-input" type="date"
                                                            formControlName="validThrough">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <textarea style="font-size: 11px;"
                                                            class="form-control small-input"
                                                            placeholder="Enter Description"
                                                            formControlName="description"
                                                            id="exampleFormControlTextarea1" rows="1"></textarea>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Item</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input class="form-control" type="email"
                                                            placeholder="Enter Item" formControlName="Item">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Product</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input class="form-control" type="email"
                                                            placeholder="Enter Product" formControlName="Product">
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Price</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input class="form-control" type="email"
                                                            placeholder="Enter Price" formControlName="Price">
                                                    </div>


                                                </div>
                                            </div>

                                        </div>
                                        <div class="main-submit-button" style="margin-right: 27%!important;">
                                            <button type="submit" *ngIf="!show" (click)="onSubmit()"
                                                class="btn btn-secondary submit-btn">Submit</button>
                                            <button type="submit" *ngIf="show" (click)="onUpdate()"
                                                class="btn btn-secondary submit-btn">Update</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div *ngIf="activeCategory==3">

                        <div class="w3-card-4 classCard" style="margin: 1%;margin-top: 40px;">
                            <div class="header-tabs">
                                <h4 class=" common-styling hleft">
                                    Notes
                                </h4>

                            </div>
                            <br>
                            <div class="panel-body" style="margin: 20px 20px;">

                                <div class="form-group table-responsive" style="margin-top: -24px;">
                                    <table class="table align-middle">
                                        <thead>
                                            <tr style="background: #0d3769;">
                                                <th
                                                    style="color: white;font-size: 12px; padding: 14px 20px !important;">
                                                    S.No
                                                </th>
                                             
                                                <th
                                                    style="color: white;font-size: 12px; padding: 14px 20px !important;">
                                                    Note Name
                                                </th>
                                                <th
                                                    style="color: white;font-size: 12px; padding: 14px 20px !important;">
                                                    Note
                                                </th>
                                                <th
                                                    style="color: white;font-size: 12px; padding: 14px 20px !important;">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="align-bottom" *ngFor="let product of quoteNotes;let i = index;">
                                                <td style="    color: black;
                              font-weight: 100;
                              font-size: 12px;
                            ">
                                                    {{i+1}}
                                                </td>
                                
                                                <td style="    color: black;
                                font-weight: 100;
                                font-size: 12px;
                               ">
                                                    {{product.noteName}}
                                                </td>
                                                <td style="    color: black;
                                font-weight: 100;
                                font-size: 12px;
                                ">
                                                    {{product.noteInfo}}
                                                </td>
                                                <td style="    color: black;
                                    font-weight: 100;
                                    font-size: 12px;
                                  ">
                                                    <fa-icon [icon]="faEdit" (click)="edit(product.noteId)"
                                                        class="text-primary cursor-pointer mr-2"></fa-icon>
                                                    <fa-icon (click)="deleteNote(product.noteId)" [icon]="faTrash"
                                                        class="text-danger cursor-pointer"></fa-icon>


                                                </td>

                                            </tr>

                                            <tr class="align-bottom" [formGroup]="fileUploadForm">
                                                <td>
                                                </td>
                                        
                                                <td>

                                                    <input type="email" formControlName="noteName" class="form-control"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        placeholder="Note Name">


                                                </td>
                                                <td>

                                                    <textarea style="height: 65%!important;padding: 11px 46px 22px 9px;"
                                                        id="w3review" formControlName="noteInfo" rows="3" cols="30"
                                                        placeholder="Note">
                                      </textarea>



                                                </td>



                                                <td>

                                                    <button type="submit" (click)="onSubmitNote()"
                                                        class="btn btn-secondary submit-btn">{{editMode
                                                        ?'Update':'Save'}}</button>


                                                </td>

                                            </tr>


                                        </tbody>
                                    </table>

                                </div>
                            </div>


                        </div>
                    </div>
                    <br>

                </div>

            </div>
            <br>
            <br><br><br><br>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>