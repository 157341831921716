<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span class="color-black pl-1 titlePanels">
                </span>

                <span class="">

                    <button type="submit" (click)="homeButton();" class=" btn btn-outline-secondary mr-2">Home</button>

                    <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass ">Back</button>
                </span>

            </div>
            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">


                        <li [ngClass]="activeCategory==1?'active':''" (click)="TaskList()"><a>
                                Main </a></li>
                        <li [ngClass]="activeCategory==2?'active':''" (click)="TaskList()"><a>
                                Task List
                            </a></li>
                        <li [ngClass]="activeCategory==3?'active':''" (click)="Calendar()"><a>
                                Calendar</a></li>
                        <li [ngClass]="activeCategory==4?'active':''" (click)="MyTime()"><a>
                                My Time
                            </a></li>
                        <li [ngClass]="activeCategory==5?'active':''" (click)="RequestList()"><a>
                                Request List
                            </a></li>
                        <li [ngClass]="activeCategory==6?'active':''" (click)="WorkEffort()"><a>
                                Work Effort
                            </a></li>
                        <li [ngClass]="activeCategory==7?'active':''" (click)="Timesheet()"><a>
                                Timesheet
                            </a></li>
                        <li [ngClass]="activeCategory==8?'active':''" (click)="SubmittedJobs()"><a>
                                Submitted Jobs
                            </a></li>
                        <li [ngClass]="activeCategory==9?'active':''" (click)="iCalendar()"><a>
                                iCalendar
                            </a></li>


                    </ul>
                </div>


                <div class=" bg-white color-grey">
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">

                                    <div class="w3-card-4 classCard">
                                        <div
                                            class="d-flex bd-highlight port-header-contact-import create-lead font-medium mb-0 suppliers-wrapper">
                                            Find Timesheet
                                            <!--new code-->
                                            <button *ngIf="!this.Hidebtn" _ngcontent-cta-c128="" type="submit"
                                                class="btn btn-secondary submit-btn ml-2" style="width: 113px;"
                                                data-toggle="modal" data-target="#createTimesheet">Create New
                                                Timesheet</button>

                                        </div>
                                        <br>
                                        <div class="all-users-infomation font-13 font-medium  own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                            Timesheet ID</label>
                                                    </div>


                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control"
                                                            [(ngModel)]="advanceSearch.TimesheetID" name="TimesheetID"
                                                            [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Timesheet ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Party </label>
                                                    </div>


                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [(ngModel)]="advanceSearch.PartyID"
                                                            [options]="partyIdArray" [ngModelOptions]="{standlone:true}"
                                                            name="PartyID" optionlabel="label"
                                                            placeholder="Enter Party ID">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Client Party ID</label>
                                                    </div>

                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control"
                                                            [(ngModel)]="advanceSearch.ClientPartyID"
                                                            name="ClientPartyID" [ngModelOptions]="{standlone:true}"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Client Party ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Status ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control"
                                                            [(ngModel)]="advanceSearch.StatusID" name="StatusID"
                                                            [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Status ID">
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control"
                                                            [(ngModel)]="advanceSearch.FromDate" name="FromDate"
                                                            [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>

                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control"
                                                            [(ngModel)]="advanceSearch.fromDateTo" name="FromDate"
                                                            [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control"
                                                            [(ngModel)]="advanceSearch.ThroughDate" name="ThroughDate"
                                                            [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Through Date">
                                                    </div>

                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control"
                                                            [(ngModel)]="advanceSearch.ThroughDateTo" name="ThroughDate"
                                                            [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Through Date">
                                                    </div>



                                                </div>
                                            </div>



                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Approved By User Login
                                                            ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control"
                                                            [(ngModel)]="advanceSearch.Approved" name="Approved"
                                                            [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter User Login ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Comments</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control"
                                                            [(ngModel)]="advanceSearch.Comments" name="Comments"
                                                            [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -62%;">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="advanceSearchTimesheetValue()">Find</button>&nbsp;
                                            <button type="button" class="btn btn-danger ml-2"
                                                (click)="resetData()">Reset</button>
                                        </div>
                                    </div>

                                    <div class="w3-card-4 classCardView mt-2">
                                        <header class="w3-container w3-blue">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling h4Margin">
                                                    Find Timesheet
                                                </h4>

                                            </div>
                                        </header>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="TimesheetDataValue" [paginator]="true"
                                                            [rows]="rows" scrollWidth="100%"
                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                            [totalRecords]="total" [scrollable]="true"
                                                            (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                        pSortableColumn="code">
                                                                        <div class="checkbox-align"
                                                                            style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox"
                                                                                binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            Timesheet ID

                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Party
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Client Party ID
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            From Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Through Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Status ID
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Approved By User Login ID
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Comments
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>




                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox"
                                                                                binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span
                                                                                (click)="detailedPage(product.timesheets.timesheetId)"
                                                                                 class="account-button">
                                                                                {{product.timesheets.timesheetId}}</span>

                                                                        </div>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.timesheets.partyId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.timesheets.clientPartyId}}
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.timesheets.fromDate|date:'yyyy-MM-dd
                                                                        hh:mm:ss'}}
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.timesheets.thruDate|date:'yyyy-MM-dd
                                                                        hh:mm:ss'}}
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.timesheets.statusId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.timesheets.approvedByUserLoginId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.timesheets.comments}}
                                                                    </td>
                                                                    <!-- <td [ngStyle]="{'width':'180px'}">

                                                                    <svg 
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        class="ml-20" width="16"
                                                                        height="16" viewBox="0 0 26 26">
                                                                        <defs>
                                                                            <style>
                                                                                .a {
                                                                                    fill: #f44336;
                                                                                }

                                                                                .b {
                                                                                    fill: #fafafa;
                                                                                    margin-left: 20px;
                                                                                }
                                                                            </style>
                                                                        </defs>
                                                                        <path class="a fill-color"
                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                        <path class="b"
                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                            transform="translate(-147.576 -147.576)" />
                                                                    </svg>
                                                                </td> -->
                                                                </tr>
                                                            </ng-template>

                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                                            <h4 class="ml-2">
                                                                                No Record
                                                                                Found</h4>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createTimesheet" tabindex="-1" role="dialog" aria-labelledby="createTimesheet"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Timesheet </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add Timesheet</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addTimesheet">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Party <span
                                                                style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="party"
                                                            [options]="partyIdArray" optionlabel="label"
                                                            placeholder="Enter Party">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">Client Party ID</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="clientPartyID"
                                                            [options]="partyIdArray" optionlabel="label"
                                                            placeholder="Enter Client Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control"
                                                            formControlName="fromDate" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control"
                                                            formControlName="throughDate" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Through Date">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Status <span
                                                                style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="status"
                                                            [options]="statusArray" optionlabel="label"
                                                            placeholder="Enter Status">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">Approved By User Login
                                                            ID</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="approvedByUserLoginID"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Approved By User Login ID">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Comments</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="comments" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-6 main-submit-button1" style="margin-left: 45%;">
                                            <button type="submit" (click)="createTimeSheetData()"
                                                class="btn btn-secondary submit-btn">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>