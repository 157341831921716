<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
          
            <div class=" bg-white color-grey" style="    width: 103%!important;
            margin-left: -1.5%!important;">
               
                <div style="min-height: calc(63vh - 209px);
                overflow-y: auto;margin-top: 1.5rem!important;">
                    <div class="container-fluid">
                        <br>
                        <div class="row">
                            <form class="w-100">
                               
                                <div *ngIf="this.agreementType=='payable'">
                                    <button _ngcontent-cta-c128="" style="font-size: 90%!important;"
                                        type="submit" class="btn btn-secondary submit-btn" style="width: 170px!important"
                                        routerLinkActive="active" routerLink="/financial/payable/customer-agreeement">Commission Agreement</button>&nbsp;
                                        <button _ngcontent-cta-c128="" style="font-size: 90%!important;"
                                        type="submit" class="btn btn-secondary submit-btn" style="width: 170px!important"
                                        routerLinkActive="active" routerLink="/financial/payable/commission-invoice">Commission Invoices</button>&nbsp;
                                        <button _ngcontent-cta-c128="" style="font-size: 90%!important;"
                                        type="submit" class="btn btn-secondary submit-btn"
                                        >Invoices </button>&nbsp;
                                        <button _ngcontent-cta-c128="" style="font-size: 90%!important;"
                                        type="submit" class="btn btn-secondary submit-btn"
                                        routerLinkActive="active" routerLink="/financial/payable/payment-payables">Payments</button>&nbsp;
                                        <button _ngcontent-cta-c128="" style="font-size: 90%!important;"
                                        type="submit" class="btn btn-secondary submit-btn"
                                        routerLinkActive="active" routerLink="/financial/payable/payment-group">AP Payment Groups</button>&nbsp;
                                        
                                    <button _ngcontent-cta-c128="" style="font-size: 90%!important;"
                                     type="submit" class="btn btn-secondary submit-btn"
                                     routerLinkActive="active" routerLink="/financial/payable/list-vendor" >Vendor</button>&nbsp;
                                        <button _ngcontent-cta-c128="" style="font-size: 90%!important;"
                                        type="submit" class="btn btn-secondary submit-btn" style="width: 100px!important"
                                        routerLinkActive="active" routerLink="/financial/payable/payables-report">Report</button>&nbsp;
                                        <button _ngcontent-cta-c128="" style="font-size: 90%!important;"
                                        type="submit" class="btn btn-secondary submit-btn"
                                        routerLinkActive="active" routerLink="/financial/payable/billing-account">Billing Account</button>&nbsp;</div>
                              
                                        <div *ngIf="this.agreementType=='receiveable'">
                                            <button _ngcontent-cta-c128="" style="font-size: 90%!important;"
                                                type="submit" class="btn btn-secondary submit-btn"
                                                routerLinkActive="active" routerLink="/financial/receiveable/customer-agreeement">Agreements</button>&nbsp;
                                                <button _ngcontent-cta-c128="" style="font-size: 90%!important;"
                                                type="submit" class="btn btn-secondary submit-btn"
                                                routerLinkActive="active" routerLink="/financial/receiveable/invoices">Sales Invoice</button>&nbsp;
                                               
                                                <button _ngcontent-cta-c128="" style="font-size: 90%!important;"
                                                type="submit" class="btn btn-secondary submit-btn"
                                                routerLinkActive="active"  routerLink="/financial/receiveable/payments">Payments</button>&nbsp;
                                                <button _ngcontent-cta-c128="" style="font-size: 90%!important;"
                                                type="submit" class="btn btn-secondary submit-btn"
                                                routerLinkActive="active" routerLink="/financial/receiveable/payment-group">AP Payment Groups</button>&nbsp;
                                                
                                            <button _ngcontent-cta-c128="" style="font-size: 90%!important;"
                                             type="submit" class="btn btn-secondary submit-btn"
                                             routerLinkActive="active" routerLink="/financial/receiveable/receive-reports" >Report</button>&nbsp;
                                               
                                            </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
          
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 