import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-view-data-source-type',
  templateUrl: './view-data-source-type.component.html',
  styleUrls: ['./view-data-source-type.component.css']
})
export class ViewDataSourceTypeComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  CreateDataSourceTypeForm: any;
  DataSourceTypeValue: any;
  dataSourceTypeId: any;
  dataSourceTypeById: any;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) { 
    this.CreateDataSourceTypeForm = this._FormBuilder.group({
      DataSourceTypeID: [''],
      Description: [''],
    });
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.dataSourceTypeId = params.dataSourceTypeID;
    })
    this.getDataSourceTypeValue();
    this.getDataSourceTypeById();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }
  backBtn(){
    this._Router.navigate(['/marketing/data-source-marketing'])
  }
  updateDataSourceType(): void {
    this.spinner.show();

    const requestData ={
  
  "dataSourceTypeId": this.CreateDataSourceTypeForm.value.DataSourceTypeID,
  "description": this.CreateDataSourceTypeForm.value.Description,
   
      }
     
    this.accountsService.updateDataSourceType(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getDataSourceTypeValue(); 
        this.getDataSourceTypeById();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }

    getDataSourceTypeById() {
      this.spinner.show();
      this.myContactsService.getDataSourceTypeById(this.dataSourceTypeId).subscribe(res => {
        this.dataSourceTypeById = res.data[0].node[0];
        setTimeout(() => {
        
          const formValue = this.CreateDataSourceTypeForm;
          formValue.patchValue({
            DataSourceTypeID: this.dataSourceTypeById.dataSourceTypeId ,
            Description:this.dataSourceTypeById.description,
          })
        
        }, 2000);
        this.spinner.hide();
      })
    }
    getDataSourceTypeValue() {
      this.spinner.show();
      this.accountsService.getDataSourceTypeValue().subscribe(res => {
        this.DataSourceTypeValue = res.data[0].data;
        this.spinner.hide();
   
      })
    }
  ngOnDestroy(): void {
    this.spinner.hide();
   
  }
}
