import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';

import { DatePipe, Location } from '@angular/common';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { CreateContactsService } from 'src/app/crm/contacts/create-contacts/create-contacts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-create-tax-info',
  templateUrl: './create-tax-info.component.html',
  styleUrls: ['./create-tax-info.component.css']
})
export class CreateTaxInfoComponent implements OnInit {
  createContact:FormGroup;
  activeCategory=2;
  partyId: any;

  getGeoTy: any;
  personId: any;
  getGeoTyArray: any[]=[];
  partyIdArray: any[]=[];
  exempt=[{
    "label":"Y",
    "value":"Y"
  },
  {
    "label":"N",
    "value":"N"
  }]
  party: any;
  show: boolean;
  efromDate: any;
  getEstimateFrom: string;
  ethruDate: any;
  getthruDate: string;
  roleTypeId: any;
  fromDate: string;
  thruDate: string;
  taxAuthGeoId: any;
  taxAuthPartyId: any;
  partyTaxId: any;
  isExempt: any;
  isNexus: any;
  @ViewChild('closebutton') closebutton;

  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,private _location: Location,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly addSkillService: AddSkillService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    public createCont: CreateContactsService,
    readonly accountsService: AccountsService,
    public bsModalRef: BsModalRef) { 
   
      this.createContact = this._FormBuilder.group({
        IsNexus: [''],
        IsExempt: [''],
        FromDate:[''],
        ThroughDate:[''],
        taxId:[''],
        TaxAuthorityParty:[''],
        TaxAuthGeoID:['']

      
      
        
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
this.getgeoByType();
this.getPartyId();

    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      this.taxAuthGeoId = params["taxAuthGeoId"];
      this.taxAuthPartyId = params["taxAuthPartyId"];
      this.efromDate = params["fromDate"];
      this.ethruDate = params["thruDate"];
      this.partyTaxId = params["partyTaxId"];
      this.isExempt = params["isExempt"];
      this.isNexus = params["isNexus"];
     
      
    });
  
    if(this.taxAuthGeoId) {
      this.show=true;
      const fromdate=this.efromDate
      this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
      const ethruDate=this.ethruDate
      this.getthruDate=this.datePipe.transform(ethruDate,"yyyy-MM-dd");
      setTimeout(() => {
        const formValue = this.createContact;
      formValue.patchValue({
        IsNexus: this.isNexus,
        IsExempt: this.isExempt,
      
        taxId: this.partyTaxId,
        TaxAuthorityParty: this.taxAuthPartyId,
        TaxAuthGeoID:this.taxAuthGeoId,
        FromDate: this.getEstimateFrom,
        ThroughDate: this.getthruDate,
       
      })
      }, 3000); 
    }
    else {
      
      this.show = false;
    }
 
  }
  getgeoByType() {
    this.spinner.show();
    this.accountsService.getgeoByType().subscribe(res => {
      this.getGeoTy = res.data;
      this.spinner.hide();
      for (const value of this.getGeoTy) {
        this.getGeoTyArray.push({
          label: value.geoId,
          value: value.geoId
        })
      }
    })
    
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }

  onSubmit() {
    this.spinner.show();
    const fromDate=this.createContact.get('FromDate').value;
    this.fromDate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const thruDate=this.createContact.get('ThroughDate').value;
    this.thruDate=this.datePipe.transform(thruDate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate":  this.fromDate,
      "isExempt":this.createContact.value.IsExempt,
      "isNexus": this.createContact.value.IsNexus,
      "partyId": this.party,
      "partyTaxId": this.createContact.value.taxId,
      "taxAuthGeoId": this.createContact.value.TaxAuthGeoID,
      "taxAuthPartyId":this.createContact.value.TaxAuthorityParty,
      "thruDate": this.thruDate,

     
    }
    this.accountsService.createTaxInfo(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.modalClose();
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
   onUpdate() {
    this.spinner.show();
    const thruDate=this.createContact.get('ThroughDate').value;
    this.thruDate=this.datePipe.transform(thruDate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate":  this.efromDate,
      "isExempt":this.createContact.value.IsExempt,
      "isNexus": this.createContact.value.IsNexus,
      "partyId": this.party,
      "partyTaxId": this.createContact.value.taxId,
      "taxAuthGeoId": this.createContact.value.TaxAuthGeoID,
      "taxAuthPartyId":this.createContact.value.TaxAuthorityParty,
      "thruDate": this.thruDate,
    }
    this.accountsService.updateTaxInfo(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.modalClose();
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }   
  modalClose(): void {
    this.closebutton.nativeElement.click();
    this.bsModalRef.hide();
  }
   
  cancelSubmit(){
    this._location.back();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }}


