<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Order List</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button _ngcontent-jra-c762="" type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey w-100 mt-20">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100">

                            <div class="w3-card-4 mt-2 w-100">
                                <div class="accordion classCard" id="myAccordion">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                <span class="headingAccords"> Lookup Order(s)

                                                </span>
                                                
                                                
                                                <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 82%!important; padding-top: 11px !important;
                                                    padding-bottom: 11px !important;">
                                                </button>									

                                            </div>
        
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                            <div class="card-body">
                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Status</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" optionlabel="label"
                                                                                [(ngModel)]="advanceOrder.Status"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                [options]="statusId" name="Status"
                                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                                placeholder="Choose Status">
                                                                            </p-dropdown>
                                                                        </div>
                
                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Type</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" optionlabel="label"
                                                                                [(ngModel)]="advanceOrder.Type"
                                                                                [ngModelOptions]="{standalone:true}" name="Type"
                                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                                [options]="typeId" placeholder="Choose Type">
                                                                            </p-dropdown>
                                                                        </div>
                
                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Filter</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" optionlabel="label"
                                                                                [(ngModel)]="advanceOrder.Filter"
                                                                                [ngModelOptions]="{standalone:true}" name="Filter"
                                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                                [options]="problemsArray" placeholder="Choose Filter">
                                                                            </p-dropdown>
                                                                        </div>
                
                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Filter (purchase
                                                                                orders)</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" optionlabel="label"
                                                                                [(ngModel)]="advanceOrder.purchaseorders"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="purchaseorders" id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp" [options]="purchaseOrder"
                                                                                placeholder="Choose Filter (purchase orders)">
                                                                            </p-dropdown>
                                                                        </div>
                
                
                                                                    </div>
                                                                </div>
                
                                                                <div class="col-lg-12 col-12 main-submit-button"
                                                                    style="margin-left: -30%;">
                                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                                        (click)="findOrderList()">Find</button>
                                                                    <button type="submit" class="btn btn-danger ml-2"
                                                                        (click)="reset()">Reset</button>
                                                                </div>
                                                            </div>
                
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                            <!-- <div class="w3-card-4 classCardView mt-2 w-100">
                                <div
                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    Lookup Order(s)

                                </div>
                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Status</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" optionlabel="label"
                                                                [(ngModel)]="advanceOrder.Status"
                                                                [ngModelOptions]="{standalone:true}"
                                                                [options]="statusId" name="Status"
                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                placeholder="Choose Status">
                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                            <label for="exampleInputEmail1">Type</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" optionlabel="label"
                                                                [(ngModel)]="advanceOrder.Type"
                                                                [ngModelOptions]="{standalone:true}" name="Type"
                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                [options]="typeId" placeholder="Choose Type">
                                                            </p-dropdown>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Filter</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" optionlabel="label"
                                                                [(ngModel)]="advanceOrder.Filter"
                                                                [ngModelOptions]="{standalone:true}" name="Filter"
                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                [options]="problemsArray" placeholder="Choose Filter">
                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                            <label for="exampleInputEmail1">Filter (purchase
                                                                orders)</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" optionlabel="label"
                                                                [(ngModel)]="advanceOrder.purchaseorders"
                                                                [ngModelOptions]="{standalone:true}"
                                                                name="purchaseorders" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" [options]="purchaseOrder"
                                                                placeholder="Choose Filter (purchase orders)">
                                                            </p-dropdown>
                                                        </div>


                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-12 main-submit-button"
                                                    style="margin-left: -30%;">
                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                        (click)="findOrderList()">Find</button>
                                                    <button type="submit" class="btn btn-danger ml-2"
                                                        (click)="reset()">Reset</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <div class="w3-card-4 classCard w-100 mt-2">
                                <div
                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    <p class="h-text">Order List</p>
                                </div>
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group">
                                            <div class="card own-account-table">
                                                <p-table [value]="OrderList" [paginator]="true" [rows]="rows"
                                                    scrollWidth="100%"
                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                    [totalRecords]="total" [scrollable]="true"
                                                    (sortFunction)="customSort($event)" [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr style="background: #0d3769;">
                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="code">
                                                                <div class="checkbox-align"
                                                                    style="color: white;">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox"
                                                                        binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div
                                                                                class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox"
                                                                                    name="undefined"
                                                                                    value="undefined">
                                                                            </div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>

                                                                    Date
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="name">
                                                                <div style="color: white;">
                                                                    Order Nbr
                                                                </div>
                                                                <div>
                                                                    <input
                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="category">
                                                                <div style="color: white;">
                                                                    Order Name </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="category">
                                                                <div style="color: white;">
                                                                    Order Type</div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="category">
                                                                <div style="color: white;">
                                                                    Bill From Party
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="category">
                                                                <div style="color: white;">
                                                                    Bill To Party
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="category">
                                                                <div style="color: white;">
                                                                    Product Store
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="category">
                                                                <div style="color: white;">
                                                                    Amount
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="category">
                                                                <div style="color: white;">
                                                                    Tracking Code</div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="category">
                                                                <div style="color: white;">
                                                                    Status</div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>

                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-product>
                                                        <tr>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                <div class="checkbox-align">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox"
                                                                        binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div
                                                                                class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox"
                                                                                    name="undefined"
                                                                                    value="undefined">
                                                                            </div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                    </p-checkbox>
                                                                    <span>
                                                                        {{product.date}}</span>

                                                                </div>
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}"
                                                                (click)="findOrderPage(product.orderHeader.orderId)">
                                                                <span
                                                                     class="account-button"  >
                                                                    {{product.orderHeader.orderId}}
                                                                </span>
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.orderHeader.orderName}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.orderType[0].description}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">

                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">

                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.productStore[0].storeName}}
                                                            </td>

                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.orderHeader.grandTotal}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.statusItem[0].description}}
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                        <tr>
                                                            <td>
                                                                <span class="text-center">
                                                                    <h4 class="ml-2">No
                                                                        Record Found
                                                                    </h4>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                                <p class="paginate_data">
                                                    View per page

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>