import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr'; import { Location } from '@angular/common';
import { FeaturesService } from '../features.service';

@Component({
  selector: 'app-new-feature-type',
  templateUrl: './new-feature-type.component.html',
  styleUrls: ['./new-feature-type.component.css']
})
export class NewFeatureTypeComponent implements OnInit {
  featureTypeForm: FormGroup;
  featureType: any;
  activeCategory = 2;
  featureTypeId: string;
  editMode: boolean;
  parentType: any[];
  url: string;
  constructor(
    readonly _FormBuilder: FormBuilder,
    readonly _FeaturesService: FeaturesService,
      private _location: Location,
      readonly _ActivatedRoute: ActivatedRoute,
    readonly  _Router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _ToastrService: ToastrService
  ) {
    this.url='/facilities/catalog/feature-type'
    this.featureTypeForm = this._FormBuilder.group({
      description: [null, [Validators.required]],
      parentTypeId: [''],
      productFeatureTypeId: ['']
    });
    this.featureTypeId = '';
    this.editMode = false;
    this.parentType = [];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.featureTypeId = this._ActivatedRoute.snapshot.queryParams.featureTypeId;
    if (this.featureTypeId) {
      this.editMode = true;
      this._FeaturesService.getFeatureTypeById(this.featureTypeId)
        .then(data => {
          this.featureType = data.data;
          this.featureTypeForm.patchValue(this.featureType);
          this.featureTypeForm.controls.productFeatureTypeId.disable();
          this.featureTypeForm.controls.productFeatureTypeId.updateValueAndValidity();
        });
    }
    this.geParentType();
  }
  geParentType(): void {
    this.spinner.show();
    this._FeaturesService.getProductParentType()
      .then(data => {
        this.spinner.hide();
        this.parentType = data.data.parentTypes.map(value => {
          return {
            label: value.description,
            value: value.productFeatureTypeId
          };
        });
      });
     
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  reset(): void {
    this.featureTypeForm.reset();

  }
  submit(): void {
    this.spinner.show();
    if (this.editMode) {
      this._FeaturesService.updateFeatureType({
        ...this.featureType, ...this.featureTypeForm.value
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.featureTypeForm.reset();
            this.featureTypeForm.controls.productFeatureTypeId.enable();
            this.featureTypeForm.controls.productFeatureTypeId.updateValueAndValidity();
            this._Router.navigate([this.url]);
          }
        });
    } else {
      this._FeaturesService.createFeatureType(this.featureTypeForm.value)
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.featureTypeForm.reset();
            this._Router.navigate([this.url]);
          }
        });
    }
    
  }
  cancel() {
    this._Router.navigate([this.url]);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
