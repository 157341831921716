<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">

            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Marketing</span>
                <span>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2"
                        (click)="toDashboard()">Home</button>
                    <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                </span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard" style="margin-left:-2%!important;">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategoriess==9?'active':''" (click)="toLead()"><a>
                                    Leads </a></li>
                            <!-- <li [ngClass]="activeCategoriess==10?'active':''" (click)="openMarketingCampaign()"><a>
                                    Convert Lead</a></li> -->
                            <li [ngClass]="activeCategoriess==11?'active':''" (click)="toContact()"><a>
                                    Contacts </a></li>
                            <li [ngClass]="activeCategoriess==12?'active':''" (click)="toAccount()"><a>
                                    Accounts</a></li>
                            <li [ngClass]="activeCategoriess==1?'active':''" (click)="openDataResource()"><a>
                                    Data Resource </a></li>
                            <li [ngClass]="activeCategoriess==2?'active':''" (click)="openMarketingCampaign()"><a>
                                    Marketing Campaign</a></li>
                            <li [ngClass]="activeCategoriess==3?'active':''" (click)="openTracking()"><a>
                                    Tracking</a></li>
                            <li [ngClass]="activeCategoriess==4?'active':''" (click)="openSegment()"><a>
                                    Segment</a></li>
                            <li [ngClass]="activeCategoriess==5?'active':''" (click)="openContactList()"><a>
                                    Contact List</a></li>
                            <li [ngClass]="activeCategoriess==6?'active':''" (click)="createcontent()"><a>
                                    Create Content</a></li> 
                            <li [ngClass]="activeCategoriess==13?'active':''" (click)="toOpportunity()"><a>
                                    Opportunities</a></li>
                            <li [ngClass]="activeCategoriess==14?'active':''" (click)="toTasks()"><a>
                                    Tasks</a></li>
                            <li [ngClass]="activeCategoriess==15?'active':''" (click)="toCases()"><a>
                                    Cases</a></li>
                            <li [ngClass]="activeCategoriess==16?'active':''" (click)="toForecast()"><a>
                                    Forecast</a></li>
                            <li [ngClass]="activeCategoriess==17?'active':''" (click)="toDocuments()"><a>
                                    Documents</a></li>
                            <li [ngClass]="activeCategoriess==18?'active':''" (click)="toNotes()"><a>
                                    Notes</a></li>
                            <li [ngClass]="activeCategoriess==19?'active':''" (click)="toCalendar()"><a>
                                    Calendar</a></li>
                            <li [ngClass]="activeCategoriess==20?'active':''" (click)="openReports()"><a>
                                    Reports</a></li>
                            <li [ngClass]="activeCategoriess==21?'active':''" (click)="toEmail()"><a>
                                    Emails marketing</a></li>
                                    <li [ngClass]="activeCategoriess==22?'active':''" (click)="MobileMessaging()"><a>
                                        Mobile Messaging</a></li>
                                <li [ngClass]="activeCategoriess==23?'active':''" (click)="SocialMedia()"><a>
                                        Social Media Marketing </a></li>

                            <!-- <li [ngClass]="activeCategoriess==7?'active':''" (click)="openCustomer()"><a>
                                    Customer </a></li>
                            <li [ngClass]="activeCategoriess==8?'active':''" (click)="openActivities()"><a>
                                    Activities</a></li> -->
                        </ul>
                    </div>
                    <div class="create-new-leade ">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div *ngIf="activeCategoriess==1">
                                            <div class="create-new-leade">
                                                <div class="container-fluid">
                                                    <div class="w3-card-4 classCard">
                                                        <div class="container-fluid">
                                                            <ul class="tabbing-section tabbing-accordians tabClass"
                                                                style="padding-left:9px">
                                                                <li [ngClass]="activeCategory==1?'active':''"
                                                                    (click)="changeactiveCategory(1)">
                                                                    <a>Data Source</a>
                                                                </li>
                                                                <li [ngClass]="activeCategory==2?'active':''"
                                                                    (click)="changeactiveCategory(2)">
                                                                    <a>Data Source Type</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="panel-group" id="accordion" role="tablist"
                                                            aria-multiselectable="true">

                                                            <div class="create-new-leade">
                                                                <div class="container-fluid">

                                                                    <div *ngIf="activeCategory==1">
                                                                        <div class="panel-group">


                                                                            <div class="w3-card-4 classCardView mt-2">
                                                                                <header class="w3-container w3-blue">
                                                                                    <div class="header-tabs"
                                                                                        style="background:#F5F7FA!important;">
                                                                                        <h4
                                                                                            class="common-styling h4Margin">
                                                                                            List Data Source
                                                                                        </h4>
                                                                                        <span
                                                                                            style="margin-right: 10px;">
                                                                                            <button
                                                                                                (click)="addEffortEventReminder()"
                                                                                                type="submit"
                                                                                                class="btn btn-secondary submit-btn ml-2"
                                                                                                data-toggle="modal"
                                                                                                data-target="#exampleCreateDataSource">
                                                                                                Create New Data
                                                                                                Source</button>
                                                                                        </span>
                                                                                    </div>
                                                                                </header>

                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group">

                                                                                            <div
                                                                                                class="card own-account-table">
                                                                                                <p-table
                                                                                                    [value]="DataSourceValue"
                                                                                                    [paginator]="true"
                                                                                                    [rows]="rows"
                                                                                                    scrollWidth="100%"
                                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                    [totalRecords]="total"
                                                                                                    [scrollable]="true"
                                                                                                    (sortFunction)="customSort($event)"
                                                                                                    [customSort]="true">
                                                                                                    <ng-template
                                                                                                        pTemplate="header">
                                                                                                        <tr
                                                                                                            style="background: #0d3769;">
                                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                                pSortableColumn="code">
                                                                                                                <div class="checkbox-align"
                                                                                                                    style="color: white;">
                                                                                                                    <p-checkbox
                                                                                                                        _ngcontent-egj-c329=""
                                                                                                                        styleclass="custom-checkbox"
                                                                                                                        binary="true"
                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div
                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div
                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                <input
                                                                                                                                    type="checkbox"
                                                                                                                                    name="undefined"
                                                                                                                                    value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox"
                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span
                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>

                                                                                                                    Data
                                                                                                                    Source
                                                                                                                    ID
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Description
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Data
                                                                                                                    Source
                                                                                                                    Type
                                                                                                                    ID
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Action
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>

                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                        pTemplate="body"
                                                                                                        let-product>
                                                                                                        <tr>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'150px'}">
                                                                                                                <div
                                                                                                                    class="checkbox-align">
                                                                                                                    <p-checkbox
                                                                                                                        _ngcontent-egj-c329=""
                                                                                                                        styleclass="custom-checkbox"
                                                                                                                        binary="true"
                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div
                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div
                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                <input
                                                                                                                                    type="checkbox"
                                                                                                                                    name="undefined"
                                                                                                                                    value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox"
                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span
                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>
                                                                                                                    <span
                                                                                                                        (click)="dataSourceDetails(product.dataSourceId)"
                                                                                                                        data-toggle="modal"
                                                                                                                        data-target="#exampleCreateDataSource"
                                                                                                                         class="account-button"  >
                                                                                                                        {{product.dataSourceId}}</span>

                                                                                                                </div>
                                                                                                            </td>

                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.description}}
                                                                                                            </td>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.node2.description}}
                                                                                                            </td>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'180px'}">
                                                                                                                <span
                                                                                                                     class="account-button"  >
                                                                                                                    <a (click)="updateEvent(product)"
                                                                                                                        style="
                                                                                                                    margin-right: 12px;"
                                                                                                                         class="account-button" 
                                                                                                                        data-toggle="modal"
                                                                                                                        data-target="#exampleCreateDataSource">Update</a>&nbsp;
                                                                                                                    <a (click)="deleteDataSource(product)"
                                                                                                                        style=""
                                                                                                                         class="account-button" >Remove</a>
                                                                                                                </span>
                                                                                                            </td>

                                                                                                        </tr>
                                                                                                    </ng-template>

                                                                                                    <ng-template
                                                                                                        pTemplate="emptymessage"
                                                                                                        let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span
                                                                                                                    class="text-center">
                                                                                                                    <h4
                                                                                                                        class="ml-2">
                                                                                                                        No
                                                                                                                        Record
                                                                                                                        Found
                                                                                                                    </h4>
                                                                                                                </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>

                                                                                                <p
                                                                                                    class="paginate_data">
                                                                                                    View per page </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>






                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="activeCategory==2">
                                                                        <div class="panel-group">


                                                                            <div class="w3-card-4 classCardView mt-2">
                                                                                <header class="w3-container w3-blue">
                                                                                    <div class="header-tabs"
                                                                                        style="background:#F5F7FA!important;">
                                                                                        <h4
                                                                                            class="common-styling h4Margin">
                                                                                            List Data Source Type
                                                                                        </h4>
                                                                                        <span
                                                                                            style="margin-right: 10px;">

                                                                                            <button
                                                                                                (click)="addReminder()"
                                                                                                type="submit"
                                                                                                class="btn btn-secondary submit-btn ml-2"
                                                                                                data-toggle="modal"
                                                                                                data-target="#exampleCreateDataSourceType">
                                                                                                Create New Data Source
                                                                                                Type</button>
                                                                                        </span>
                                                                                    </div>
                                                                                </header>

                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group">

                                                                                            <div
                                                                                                class="card own-account-table">
                                                                                                <p-table
                                                                                                    [value]="DataSourceTypeValue"
                                                                                                    [paginator]="true"
                                                                                                    [rows]="rows"
                                                                                                    scrollWidth="100%"
                                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                    [totalRecords]="total"
                                                                                                    [scrollable]="true"
                                                                                                    (sortFunction)="customSort($event)"
                                                                                                    [customSort]="true">
                                                                                                    <ng-template
                                                                                                        pTemplate="header">
                                                                                                        <tr
                                                                                                            style="background: #0d3769;">
                                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                                pSortableColumn="code">
                                                                                                                <div class="checkbox-align"
                                                                                                                    style="color: white;">
                                                                                                                    <p-checkbox
                                                                                                                        _ngcontent-egj-c329=""
                                                                                                                        styleclass="custom-checkbox"
                                                                                                                        binary="true"
                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div
                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div
                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                <input
                                                                                                                                    type="checkbox"
                                                                                                                                    name="undefined"
                                                                                                                                    value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox"
                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span
                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>

                                                                                                                    Data
                                                                                                                    Source
                                                                                                                    Type
                                                                                                                    ID
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Description
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Action
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>


                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                        pTemplate="body"
                                                                                                        let-product>
                                                                                                        <tr>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'150px'}">
                                                                                                                <div
                                                                                                                    class="checkbox-align">
                                                                                                                    <p-checkbox
                                                                                                                        _ngcontent-egj-c329=""
                                                                                                                        styleclass="custom-checkbox"
                                                                                                                        binary="true"
                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div
                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div
                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                <input
                                                                                                                                    type="checkbox"
                                                                                                                                    name="undefined"
                                                                                                                                    value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox"
                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span
                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>
                                                                                                                    <span
                                                                                                                        (click)="dataSourceTypeDetails(product.dataSourceTypeId)"
                                                                                                                        data-toggle="modal"
                                                                                                                        data-target="#exampleCreateDataSourceType"
                                                                                                                         class="account-button"  >
                                                                                                                        {{product.dataSourceTypeId}}</span>

                                                                                                                </div>
                                                                                                            </td>

                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'180px'}">
                                                                                                                <span>
                                                                                                                    {{product.description}}
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'180px'}">
                                                                                                                <span
                                                                                                                     class="account-button"  >
                                                                                                                    <a (click)="updateEventType(product)"
                                                                                                                        style="
                                                                                                                    margin-right: 12px;"
                                                                                                                         class="account-button" 
                                                                                                                        data-toggle="modal"
                                                                                                                        data-target="#exampleCreateDataSourceType">Update</a>&nbsp;
                                                                                                                    <a (click)="deleteDataSourceType(product)"
                                                                                                                        style=""
                                                                                                                         class="account-button" >Remove</a>
                                                                                                                </span>
                                                                                                            </td>

                                                                                                        </tr>
                                                                                                    </ng-template>

                                                                                                    <ng-template
                                                                                                        pTemplate="emptymessage"
                                                                                                        let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span
                                                                                                                    class="text-center">
                                                                                                                    <h4
                                                                                                                        class="ml-2">
                                                                                                                        No
                                                                                                                        Record
                                                                                                                        Found
                                                                                                                    </h4>
                                                                                                                </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>

                                                                                                <p
                                                                                                    class="paginate_data">
                                                                                                    View per page </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="bg-white color-grey mt-20">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)">
                                <a>Data Source</a>
                            </li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)">
                                <a>Data Source Type</a>
                            </li>
                        </ul>
                    </div>
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                        <div class="create-new-leade">
                            <div class="container-fluid">

                                <div *ngIf="activeCategory==1">
                                    <div class="panel-group">


                                        <div class="w3-card-4 classCardView mt-2">
                                            <header class="w3-container w3-blue">
                                                <div class="header-tabs">
                                                    <h4 class="common-styling h4Margin">
                                                        List Data Source
                                                    </h4>
                                                    <span style="margin-right: 10px;">
                                                    <button (click)="addEffortEventReminder()" type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleCreateDataSource"> 
                                                        Create Data Source</button>
                                                </span>
                                                </div>
                                            </header>

                                            <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">

                                                        <div class="card own-account-table">
                                                            <p-table [value]="DataSourceValue" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>

                                                                                Data Source ID
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Description
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Data Source Type ID
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Action
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span (click)="dataSourceDetails(product.dataSourceId)" data-toggle="modal" data-target="#exampleCreateDataSource"  class="account-button"  >
                                                                                {{product.dataSourceId}}</span>

                                                                            </div>
                                                                        </td>

                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.description}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.node2.description}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            <span  class="account-button"  >
                                                                                <a (click)="updateEvent(product)" style="
                                                                                margin-right: 12px;"  class="account-button"  data-toggle="modal" data-target="#exampleCreateDataSource">Update</a>&nbsp;
                                                                                <a (click)="deleteDataSource(product)" style=""  class="account-button" >Remove</a>
                                                                        </span>
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>

                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                        <h4
                                                                            class="ml-2">
                                                                            No Record
                                                                            Found</h4>
                                                                    </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>

                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>






                                    </div>
                                </div>
                                <div *ngIf="activeCategory==2">
                                    <div class="panel-group">


                                        <div class="w3-card-4 classCardView mt-2">
                                            <header class="w3-container w3-blue">
                                                <div class="header-tabs">
                                                    <h4 class="common-styling h4Margin">
                                                        List Data Source Type
                                                    </h4>
                                                    <span style="margin-right: 10px;">
                                                   
                                                        <button (click)="addReminder()" type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleCreateDataSourceType"> 
                                                            Create Data Source Type</button>
                                                </span>
                                                </div>
                                            </header>

                                            <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">

                                                        <div class="card own-account-table">
                                                            <p-table [value]="DataSourceTypeValue" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>

                                                                                Data Source Type ID
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Description
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Action
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span (click)="dataSourceTypeDetails(product.dataSourceTypeId)" 
                                                                                data-toggle="modal" 
                                                                                data-target="#exampleCreateDataSourceType"
                                                                                  class="account-button"  >
                                                                                {{product.dataSourceTypeId}}</span>

                                                                            </div>
                                                                        </td>

                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            <span>
                                                                           {{product.description}}
                                                                        </span>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            <span  class="account-button"  >
                                                                                <a (click)="updateEventType(product)" style="
                                                                                margin-right: 12px;"  class="account-button"  data-toggle="modal" data-target="#exampleCreateDataSourceType">Update</a>&nbsp;
                                                                                <a (click)="deleteDataSourceType(product)" style=""  class="account-button" >Remove</a>
                                                                        </span>
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>

                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                        <h4
                                                                            class="ml-2">
                                                                            No Record
                                                                            Found</h4>
                                                                    </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>

                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->


<div class="modal fade" id="exampleCreateDataSource" tabindex="-1" role="dialog"
    aria-labelledby="exampleCreateDataSourceTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Edit New Data Source </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>

            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>
                                        Edit New Data Source</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="CreateDataSourceForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Data Source ID
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="DataSourceID"
                                                            placeholder="Enter Data Source ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Data Source Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="DataSourceTypeIDValue"
                                                            [options]="DataSourceTypeIdArray"
                                                            placeholder="Enter Data Source Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="Description" aria-describedby="emailHelp"
                                                            placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button *ngIf="!this.show" (click)="createDataSource()" type="submit"
                                                class="btn btn-secondary submit-btn">Add</button>
                                            <button *ngIf="this.show" (click)="updateDataSource()" type="submit"
                                                class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleCreateDataSourceType" tabindex="-1" role="dialog"
    aria-labelledby="exampleCreateDataSourceTypeTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Edit New Data Source Type </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonA>&times;</span>
                </button>
            </div>

            <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>
                                        Edit New Data Source Type</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="CreateDataSourceTypeForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Data Source Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="DataSourceTypeID"
                                                            placeholder="Enter Data Source Type ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="Description" aria-describedby="emailHelp"
                                                            placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button *ngIf="!this.showValue" (click)="createDataSourceType()"
                                                type="submit" class="btn btn-secondary submit-btn">Add</button>
                                            <button *ngIf="this.showValue" (click)="updateDataSourceType()"
                                                type="submit" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>


        </div>
    </div>
</div>



<ngx-spinner></ngx-spinner>