import { query } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr'; import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';

@Component({
  selector: 'app-image-management-catalog',
  templateUrl: './image-management-catalog.component.html',
  styleUrls: ['./image-management-catalog.component.css']
})
export class ImageManagementCatalogComponent implements OnInit {
  activeCategory = 2;
  resData: any;
  activeCat=15;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  imageMgmtApp: any;
  galleyProduct: any;
  manageProduct: any;
  replaceProduct: any;
  resizeProduct: any;
  uploadProduct: any;
  fileData: string | Blob;
  ProductId: any;
  ProductIdArray: any[]=[];
  ImageUploadList: any;
  ProID: any;
  resizeArray: { label: string; value: string; }[];
  ImageUploadListMain: any;
  ImageUploadListGallery: any;
  imgApproveList: any;
  imgRejectedList: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
      private _location: Location,
      readonly router: Router,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router
  ) { 
    this.imageMgmtApp = this._FormBuilder.group({
      productID: [''],
    });
    this.galleyProduct = this._FormBuilder.group({
      productID: [''],
    });
    this.galleyProduct = this._FormBuilder.group({
      productID: [''],
      resize: [''],
      file: [''],
    });
    this.manageProduct = this._FormBuilder.group({
      productID: [''],
    });
    this.replaceProduct = this._FormBuilder.group({
      productID: [''],
    });
    this.resizeProduct = this._FormBuilder.group({
      resizeOption: [''],
      productID: [''],
      imageWidth: [''],
    });
    this.uploadProduct = this._FormBuilder.group({
      productID: [''],
      resize: [''],
      file: [''],
    });
    this.resizeArray = [
      {
        label : 'Do not resize',
        value : '',
       
      },
      {
        label : '100 X 75 (avatar)',
        value : '100x75'
      },
      {
        label : '150 X 112 (thumbnail)',
        value : '150x112'
      },
      {
        label : '320 X 240 (for websites and email)',
        value : '320x240'
      },
      {
        label : '640 X 480 (for message boards)',
        value : '640x480'
      },
      {
        label : '800 X 600 (15-inch monitor)',
        value : '800x600'
      },
      {
        label : '1024 X 768 (17-inch monitor)',
        value : '1024x768'
      },
      {
        label : '1280 X 1024 (19-inch monitor)',
        value : '1280x1024'
      },
      {
        label : '1600 X 1200 (21-inch monitor)',
        value : '1600x1200'
      },
      {
        label : 'Azsdf',
        value : ''
      },
    ]
    
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
    this.getProductIds();
    this.ImageApproveList();
    this.ImageRejectedList();
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }

  ImageApproveList() {
    this.spinner.show();
    this.myContactsService.ImageApproveList().subscribe(res => {
      this.imgApproveList = res.data[0].data;
      this.spinner.hide();
    })
  }
  ImageRejectedList() {
    this.spinner.show();
    this.myContactsService.ImageRejectedList().subscribe(res => {
      this.imgRejectedList = res.data[0].data;
      this.spinner.hide();
    })
  }
  detailRecentlyApprovePage(id) {
    this.router.navigate(["/facilities/catalog/image-management/management-recently-approved-detail"],
     { queryParams: { productId: id } })
  }
  detailManagePage(id) {
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.router.navigate(["/facilities/catalog/image-management/image-management-manage-detail"],
      { queryParams: { productId: id } })
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.toastr.error("Permission Denied"); 
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.toastr.error("Permission Denied");
    }

  }
  detailReplacePage(id) {
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.router.navigate(["/facilities/catalog/image-management/image-management-replace-detail"], 
      { queryParams: { productId: id } })
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.toastr.error("Permission Denied"); 
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.toastr.error("Permission Denied");
    }

  }
  
  detailMain(id) {
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.router.navigate(["/facilities/catalog/image-management/image-management-main-detail"],
      { queryParams: { productId: id } })
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.toastr.error("Permission Denied"); 
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.toastr.error("Permission Denied");
    }

  }
  
  getImageUploadListMain() {
    this.spinner.show();
    this.myContactsService.getImageUploadList(this.imageMgmtApp.value.productID).subscribe(res => {
      this.ImageUploadListMain = res.data[0].data;
      this.spinner.hide();
    })
  }
  getImageUploadListGallery() {
    this.spinner.show();
    this.myContactsService.getImageUploadList(this.galleyProduct.value.productID).subscribe(res => {
      this.ImageUploadListGallery = res.data[0].data;
      this.spinner.hide();
    })
  }
  getProductIds() {
    this.spinner.show();
    this.myContactsService.getProductIds().subscribe(res => {
      this.ProductId = res.data[0].getProductId;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.internalName,
          value: value.productId
        })
      }
    })
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    
  }
  addToUploadImageCustom() {
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.spinner.show();
      const formData = new FormData();
      formData.append("file",this.fileData);
   
      this.myContactsService.addToUploadImageCustom(formData,this.uploadProduct.value.resize,
        this.uploadProduct.value.productID).subscribe((res: any) => {
  
        if (res.success) {
          this.toastr.success("Created Successfully");
          this.spinner.hide();
          this.uploadProduct.reset();
          this.ProID = res.productId ;
  
          this.myContactsService.getImageUploadList(res.productId ).subscribe(res => {
            this.ImageUploadList = res.data[0].data;
            this.spinner.hide();
          })
  
        }
      }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        }
        else {
          this.toastr.error(err.error.message);
        }
      });
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.toastr.error("Permission Denied"); 
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.toastr.error("Permission Denied");
    }
 

  }
  getImageUploadList() {
    this.spinner.show();
    this.myContactsService.getImageUploadList(this.ProID).subscribe(res => {
      this.ImageUploadList = res.data[0].data;
      this.spinner.hide();
    })
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  mainOpen(){
    this.router.navigate(['/facilities/catalog/main-catalog']);
  }
  catalogOpen(){
    this.router.navigate(['/facilities/catalog/catalogs']);
  }
  categoriesOpen(){
    this.router.navigate(['/facilities/catalog/catagories']);
  }
  productsOpen(){
    this.router.navigate(['/facilities/catalog/products']);
  }
  featuresOpen(){
    this.router.navigate(['/facilities/catalog/feature-type']);
  }
  promosOpen(){
    this.router.navigate(['/facilities/catalog/promos']);
  }
  priceRulesOpen(){
    this.router.navigate(['/facilities/catalog/price-rules']);
  }
  storesOpen(){
    this.router.navigate(['/facilities/catalog/stores']);
  }
  productStoreGroupsOpen(){
    this.router.navigate(['/facilities/catalog/product-store-groups']);
  }
  thesaurusOpen(){
    this.router.navigate(['/facilities/catalog/thesaurus']);
  }
  reviewsOpen(){
    this.router.navigate(['/facilities/catalog/reviews']);
  }
  configurationsOpen(){
    this.router.navigate(['/facilities/catalog/configurations']);
  }
  subscriptionsOpen(){
    this.router.navigate(['/facilities/catalog/subscriptions']);
  }
  shippingOpen(){
    this.router.navigate(['/facilities/catalog/shipment']);
  }
  imageManagementOpen(){
    this.router.navigate(['/facilities/catalog/image-management']);
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
