import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Injectable({
    providedIn: 'root'
})
export class ShippingService {
    constructor(
        readonly _HttpClient: HttpClient,
        readonly _ApiService: ApiService
    ) { }
    // shipment method type



    getShipmentMethodType(pagination: { pageNo: number, pageSize: number }): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/shipmentMethodType?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`);
    }
    createShipmentMethodType(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/shipmentMethodType`, formData);
    }
    updateShipmentMethodType(formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/catalog/shipmentMethodType`, formData);
    }
    deleteShipmentMethodType(shipmentMethodTypeId: string): Promise<any> {
        return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/catalog/shipmentMethodType/${shipmentMethodTypeId}`);
    }
    // Quantity Breaks 

    getQuantityBreaks(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/quantityBreaks?pageNo=1&pageSize=100`);
    }
    createQuantityBreak(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/quantityBreaks`, formData);
    }
    updateQuantityBreak(formData: any, quantityBreakId: string): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/catalog/quantityBreaks/${quantityBreakId}`, formData);
    }
    deleteQuantityBreak(quantityBreakId: string): Promise<any> {
        return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/catalog/quantityBreaks/${quantityBreakId}`);
    }
    getQuantityBreakType(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/quantityBreaks/quantityBreakType`);
    }
    // carrier shipment 
    getCarrierShipment(pagination: { pageNo: number, pageSize: number }): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/carrierShipmentMethod?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`);
    }
    createCarrierShipment(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/catalog/carrierShipmentMethod`, formData);
    }
    updateCarrierShipment(formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/catalog/carrierShipmentMethod`, formData);
    }
    deleteCarrierShipment(partyId: string, roleId: string, shipmentId: string): Promise<any> {
        return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/catalog/carrierShipmentMethod/${shipmentId}/${partyId}/${roleId}`);
    }
    getRoleType(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/carrierShipmentMethod/roleType`);
    }
    shipmentMethodType(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/carrierShipmentMethod/shipmentMethodType`);
    }
    getPartyId(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/resources/personsPartyGroups`);
    }
}