import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DetailedEmployeeSummary } from '../detailed-employee-summary.service';

@Component({
    selector: 'app-employee-emergency-contact',
    templateUrl: './employee-emergency-contact.component.html'
})
export class EmployeeEmergencyContactComponent implements OnInit {
    emergencyContactForm: FormGroup;
    partyId: string;

    array = [
        {
            label: 'Primary',
            value: 'PRIMARY_CONTACT'
        },
        {
            label: 'Secondary',
            value: 'SECONDARY_CONTACT'
        }
    ];

    editMode: boolean=false;
    updateValue: {
        personName: string,
        relation: string,
        contactNumber: string,
        contactTypeId:string
    };
    constructor(
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _FormBuilder: FormBuilder,
        readonly _ToastrService: ToastrService,
        readonly  _Router: Router,
        readonly spinner:NgxSpinnerService,
        readonly _DetailedEmployeeSummary: DetailedEmployeeSummary
    ) {
      
        this.partyId = '';
        this.emergencyContactForm = this._FormBuilder.group({
            contactNumber: [''],
            contactTypeId: [''],
            personName: [''],
            relation: ['']
        });
        this.updateValue = {
            personName: '',
            relation: '',
            contactNumber: '',
            contactTypeId:''
        };
    }
    ngOnInit(): void {
    
        this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
        this._ActivatedRoute.queryParams.subscribe(data => {
           
           this.updateValue.personName = data.personName;
           this.updateValue.relation = data.relation;
           this.updateValue.contactNumber = data.contactNumber;
           this.updateValue.contactTypeId = data.contactTypeId;
            if (data.personName) {
                this.emergencyContactForm.patchValue(data);
                this.editMode = true;
            }
            else{
                this.editMode = false;
            }
        });

    }
    update(): void {
        this.spinner.show();
        if (this.emergencyContactForm.valid) {
            this._DetailedEmployeeSummary.updateEmergencyContact(this.partyId, {
                ...this.updateValue, ...this.emergencyContactForm.value
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Updated');
                        this.spinner.hide();
                        this.cancelSubmit();
                    }
                });
        }
        this.spinner.hide();
    }
    submit(): void {
        this.spinner.show();
        if (this.emergencyContactForm.valid) {
            this._DetailedEmployeeSummary.createEmergencyContact(this.partyId, this.emergencyContactForm.value)
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.cancelSubmit();
                    }
                });
        }
        this.spinner.hide();
    }
    cancelSubmit(): void {
        this.emergencyContactForm.reset();
        this._Router.navigate(['/hr/employees/employee-details'], { queryParams: { employeeDetailId: this.partyId } });
    }
}