<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="reset()">Feature Maintenance </span>
                <span class="color-black pl-1"> > {{editMode ? 'Update ':'Create'}} Feature Type</span>


                <span class="d-flex">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            </div>
            <div class=" bg-white color-gey mt-5">
                <div *ngIf="this.editMode==true" class="port-header-contact create-lead font-medium mb-0">
                    Edit Feature Type
                </div>
                <div *ngIf="this.editMode==false" class="port-header-contact create-lead font-medium mb-0">
                    Add Feature Type
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="productFeatureForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group" style="text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -6%;">
                                                <label for="featureType">Feature Type <span
                                                    class="text-danger">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="featureTypeIds" placeholder="Feature Type"
                                                    filter="true" formControlName="productFeatureTypeId"></p-dropdown>
                                                <small class="text-danger"
                                                    *ngIf="!!productFeatureForm.controls.productFeatureTypeId.invalid && !!productFeatureForm.controls.productFeatureTypeId.touched">Feature
                                                    Type is required.</small>
                                        </div>
                                        <div class="col-lg-3 form-group " style="text-align: right!important;
                                        padding-right: 17px!important;
                                       ">
                                            <label for="featureCategory">Feature Category</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <p-dropdown [options]="featureCategories" placeholder="Feature Category"
                                                filter="true" formControlName="productFeatureCategoryId">
                                            </p-dropdown>
                                    </div>
                                    </div>
                                    </div>
                                
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group" style="text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -6%;">
                                                <label for="description">Description <span
                                                    class="text-danger">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" formControlName="description"
                                                placeholder="Enter Description">
                                            <small class="text-danger"
                                                *ngIf="!!productFeatureForm.controls.description.invalid && !!productFeatureForm.controls.description.touched">Description
                                                is required.</small>
                                        </div>
                                        <div class="col-lg-3 form-group" style="text-align: right!important;
                                        padding-right: 17px!important;
                                       ">
                                            <label for="uomId">Unit of Measure ID</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <p-dropdown [options]="productUOMIds" formControlName="uomId"
                                                filter="true" placeholder="Unit Of Measure Id"></p-dropdown>
                                          
                                    </div>
                                    </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group" style="text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -6%;">
                                                <label for="quantity">Number / Quantity</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" formControlName="numberSpecified"
                                                placeholder="Enter Number / Quantity">
                                        </div>
                                        <div class="col-lg-3 form-group" style="text-align: right!important;
                                        padding-right: 17px!important;
                                        ">
                                            <label for="amount">Default Amount</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="text" class="form-control" formControlName="defaultAmount"
                                            placeholder="Enter Default Amount">
                                    </div>
                                    </div>
                                    </div>
                                    
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group" style="text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -6%;">
                                                <label for="sequence">Default Sequence Number</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" formControlName="defaultSequenceNum"
                                                placeholder="Enter Default Sequence Number">
                                        </div>
                                        <div class="col-lg-3 form-group" style="text-align: right!important;
                                        padding-right: 17px!important;
                                       ">
                                            <label for="abbr">Abbreviation</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="text" class="form-control" formControlName="abbrev"
                                            placeholder="Enter Abbreviation">
                                    </div>
                                    </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group" style="text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -6%;">
                                                <label for="IdCode">ID Code</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" formControlName="idCode"
                                                placeholder="Enter ID Code">
                                        </div>
                                    </div>
                                    </div>
                                  
                                  
                                  
                                    <div class="col-lg-12 col-12 main-submit-button " style="
                                    margin-left: -22%;
                                ">
                                        <button type="submit" class="btn btn-secondary submit-btn"
                                            (click)="submit()">{{editMode ?'Update':'Add'}}</button>&nbsp;
                                        <button class="btn btn-danger" (click)="reset()">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>