<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Security Overview</span>
                <span>
                    <!-- <button type="submit" class="btn btn-secondary submit-btn mr-2"
                        (click)="toDashboard()">Home</button>
                    <button _ngcontent-jra-c762="" type="submit" class="btn btn-danger buttonclass"
                        (click)="backWindow()">Back</button> -->
                </span>
            </div>

            <div class=" bg-white color-grey">
                <div class="container-fluid">

                </div>

                <div>
                    <div class="container-fluid">
                        <div class="row" >
                            <form class="w-100">

                                <div class="w-100">
                                    <div class="panel-group">
                                        <div>
                                            <div style="width: 49.7%; float:left">
                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Find User Login

                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <p style="cursor: pointer;    color: #0000FF;"
                                                                        class="color-grey pl-20 col-lg-10 col-12"
                                                                        routerLink="/security/find-user-login">
                                                                        Find User Login
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="w3-card-4 cardEffect mt-2">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Visits

                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/security/visits">
                                                                                    Visit List
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                

                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Find Security Group
                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/security/find-security-group">
                                                                                    Find Security Group
                                                                                </p>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>

                                                
                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Cert

                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <p style="cursor: pointer;    color: #0000FF;"
                                                                        class="color-grey pl-20 col-lg-10 col-12"
                                                                        routerLink="/security/cert-issuers">
                                                                        Cert Issuers
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                

                                            </div>
                                            <div style="width: 49.5%; float:right">

                                                <div class="w3-card-4 cardEffect mt-2">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Logged-In User List

                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <p routerLink="/security/logged-in-users" style="cursor: pointer;  
                                                    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12">Logged-In
                                                                            User List

                                                                        </p>



                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                
                                                <div class="w3-card-4 cardEffect mt-2">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Classification

                                                            </h4>

                                                        </div>
                                                    </header>
                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/security/classifications">
                                                                                    Classification Groups
                                                                                </p>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Address Match Map

                                                            </h4>
                                                        </div>
                                                    </header>
                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/security/address-match-map">
                                                                                    Address Match Map

                                                                                </p>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>