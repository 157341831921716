

<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">

    <div class="create-new-leade mt-3">
        <div class="container-fluid" style="padding: 0px 0px;">
            
           <div class="col-md-12 p-0" >
               <div class="w3-card-4 classCard  w-100 " style="height: 510px;">
                   
                    <div class="col-lg-12">
                        <div class="row d-flex justify-content-between px-2 mt-2">
                            <div>
                                <label>Work Flow Settings</label>
                            </div>

                            <div class="">
                                <button class="btn btn-secondary ml-2 submit-btn">Cancel</button>
                                <button class="btn btn-secondary ml-2 submit-btn">Ok</button>

                            </div>

                        </div>

                    </div>

                    

            
                    

                    <div class="row " style="width: 100%; margin-top: 1%; margin-bottom: 1%; padding-left: 8px;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px; margin-bottom: 1%; height: 376px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color: black; font-weight: bold !important;">
                                        Time Sheet Model </p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12">
                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mb-2 modals">
                                                            <div class="col-lg-3">
                                                                <label>Pic a Time Sheet model </label>

                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row d-flex">
                                                            <div class="col-lg-4 d-flex font-12">
                                                                <input type="radio" class="ml-1">
                                                                <label>Punch In/Out</label>



                                                            </div>
                                                            <div class="col-lg-8 blue-font">
                                                                <label>This type will allow user to punch in and out 
                                                                    of shifts and breaks.
                                                                </label>
                                                            </div>
                                                            
                                                        </div>

                                                    </div>

                                                    

                                                    <div class="col-lg-12">
                                                        <div class="row d-flex modals">
                                                            <div class=" col-lg-1 classInput float-left" >
                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                        <div class="ui-helper-hidden-accessible">
                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                        </div>
                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                        </div>
                                                                    </div>
                                                                
                                                                </p-checkbox>
                                                                   
                                                            </div>

                                                            <div class="col-lg-4 leftalign">
                                                                <label> Flag Over Time</label>

                                                            </div>

                                                            <div class="col-lg-7">
                                                                <label>Any Time Sheet with Over Time will be flagged with this setting</label>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row d-flex modals">
                                                            <div class=" col-lg-1 classInput float-left" >
                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                        <div class="ui-helper-hidden-accessible">
                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                        </div>
                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                        </div>
                                                                    </div>
                                                                
                                                                </p-checkbox>
                                                                   
                                                            </div>

                                                            <div class="col-lg-4 leftalign">
                                                                <label> Flag Double Time</label>

                                                            </div>

                                                            <div class="col-lg-7">
                                                                <label>Any Time Sheet with Double  Time will be flagged with this setting</label>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    

                                                    <div class="col-lg-12">
                                                        <div class="row d-flex modals">
                                                            <div class=" col-lg-1 classInput float-left" >
                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                        <div class="ui-helper-hidden-accessible">
                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                        </div>
                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                        </div>
                                                                    </div>
                                                                
                                                                </p-checkbox>
                                                                   
                                                            </div>

                                                            <div class="col-lg-4 leftalign">
                                                                <label> Flag Long Time Records</label>

                                                            </div>

                                                            <div class="col-lg-7">
                                                                <label>Any Time Sheet with more hours than defined here will be flagged 
                                                                    with this setting. This includes Regular,Over Time and Double Time
                                                                </label>
                                                            </div>

                                                        </div>
                                                    </div>


                                                    <div class="col-lg-12">
                                                        <div class="row ml-2 modals d-flex lefalign1">
                                                            
                                                            <div class="">
                                                                <label>Long Time Record is more than</label> &nbsp;&nbsp;
                                    
                                                            </div>
                                                            <div class="col-lg-1 ">
                                                                <input type="text" class="form-control"
                                                                formControlName="">
                                                            </div>&nbsp; &nbsp;
                                    
                                                            <div class=""> 
                                                                <label>  hours</label>
                                                            </div>
                                    
                                                            
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row d-flex modals">
                                                            <div class=" col-lg-1 classInput float-left" >
                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                        <div class="ui-helper-hidden-accessible">
                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                        </div>
                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                        </div>
                                                                    </div>
                                                                
                                                                </p-checkbox>
                                                                   
                                                            </div>

                                                            <div class="col-lg-4 leftalign">
                                                                <label> Flag Short Time Records</label>

                                                            </div>

                                                            <div class="col-lg-7">
                                                                <label>Any Time Sheet with less hours than defined here will be flagged
                                                                </label>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    

                                                    <div class="col-lg-12">
                                                        <div class="row ml-2 modals d-flex lefalign1">
                                                            
                                                            <div class="">
                                                                <label>Short Time Record is less than</label> &nbsp;&nbsp;
                                    
                                                            </div>
                                                            <div class="col-lg-1 ">
                                                                <input type="text" class="form-control"
                                                                formControlName="">
                                                            </div>&nbsp; &nbsp;
                                    
                                                            <div class=""> 
                                                                <label>  hours</label>
                                                            </div>
                                    
                                                            
                                                        </div>
                                                    </div>

                                                    <!-- <div class="col-lg-12">
                                                        <div class="row d-flex modals">
                                                            <div class=" col-lg-5 classInput float-left" >
                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                        <div class="ui-helper-hidden-accessible">
                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                        </div>
                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                        </div>
                                                                    </div>
                                                                
                                                                </p-checkbox>
                                                                <label> Flag Time Sheet if Work Order Time is less than </label>
                                                                <input type="text" class=" col-lg-1" formControlName="">
                                                                <label>of Shift time.</label>

                                                                   
                                                            </div>


                                                            

                                                            <div class="col-lg-6">
                                                                <label>Any Time Sheet with less Work Order Times than hours defined here will
                                                                    flagged
                                                                </label>
                                                            </div>

                                                        </div>
                                                    </div> -->

                                                    <div class="col-lg-12">
                                                        <div class="row d-flex modals">
                                                            <div class=" col-lg-1 classInput float-left" >
                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                        <div class="ui-helper-hidden-accessible">
                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                        </div>
                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                        </div>
                                                                    </div>
                                                                
                                                                </p-checkbox>
                                                                   
                                                            </div>

                                                            <div class="col-lg-4 leftalign">
                                                                <label> Flag All incomplete Time Sheet</label>

                                                            </div>

                                                            <div class="col-lg-7">
                                                                <label>Any Time Sheet that doesnot have a matching punch out for 
                                                                    each punch in will be flagged
                                                                </label>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row d-flex modals">
                                                            <div class=" col-lg-1 classInput float-left" >
                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                        <div class="ui-helper-hidden-accessible">
                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                        </div>
                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                        </div>
                                                                    </div>
                                                                
                                                                </p-checkbox>
                                                                   
                                                            </div>

                                                            <div class="col-lg-4 leftalign">
                                                                <label> Flag All overlapping Time Records</label>

                                                            </div>

                                                            <div class="col-lg-7">
                                                                <label>Any Time Record that overlaps with another Time Record will be flagged
                                                                </label>
                                                            </div>

                                                        </div>
                                                    </div>


                                                    

                                                    
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    

                    <div class="d-flex justify-content-end  mb-1" style="margin-right: 1%;">
                        <button class="btn btn-secondary ml-2 submit-btn">Cancel</button>
                        <button class="btn btn-secondary ml-2 submit-btn">Ok</button>
                    </div>

                   
               </div>
           </div>
                        
        
        
        
        
        </div>
    
    </div>
</div>


        <div class="modal fade" id="TeamMemberPopup" tabindex="-1" role="dialog" aria-labelledby="TeamMemberPopup" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content cssModelLength">
                    <div class="modal-header">
                        <span class="color-black pl-1">Add Team Members</span>
                        <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" #closebutton2>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body d-flex">
                        <div class=" bg-black color-grey w-100">
                            <div class="w3-card-4 classCardPopUp">
                                <div class="container-fluid">
                                    <ul class="tabbing-section tabbing-accordians tabClass">
                                        <li>
                                            <a>Add Team Members</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="create-new-leade mt-2">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <form class="w-100" [formGroup]="addTeamMember">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group rightForm">
                                                                <label for="exampleInputEmail1">New Team Member</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                formControlName="newTeamMember"  [options]="teamMemberArray"
                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                placeholder="Enter New Team Member">
                                                                </p-dropdown>
                                                            </div>

                                                            <div class="col-lg-3 form-group rightFormA">
                                                                <label for="exampleInputEmail1">Role</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label" [options]="RoleArray" formControlName="role" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Role">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-lg-5 main-submit-button" style="margin-left: 52%;">
                                                        <button type="submit" class="btn btn-secondary submit-btn" *ngIf="!this.show" (click)="addTeamMemberInviewTeam()">Create</button>
                                                        <button type="submit" class="btn btn-secondary submit-btn" *ngIf="this.show" (click)="updateTeamMemberInviewTeam()">Update</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<!-- <ngx-spinner></ngx-spinner> -->









