import { Component, OnInit } from '@angular/core';
import { HeaderService } from './header.service';
import { from } from 'rxjs';
import { Router } from '@angular/router';
import { AccountsService } from '../crm/accounts/accounts.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userPermissions: any;
  isAccount: boolean;
  userProfile: any;
  adminSecurity: string;
  fmsRole: string;
  showFSM: boolean;
  showFSMTechnician: boolean;
  showFSMtechnician: boolean;
  partyId: string;
  checkPOSToken: string;
  productStoreIdNew: string;
  posTerminalIdNew: string;
  constructor(readonly headerService: HeaderService,
    public router: Router, readonly accountsService: AccountsService) {
  
    this.isAccount = false;
  }


  ngOnInit(): void {
    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    const security: string = localStorage.getItem('adminSecurity');
    this.fmsRole = localStorage.getItem('fmsRole');
    this.partyId = localStorage.getItem('partyId');
    this.adminSecurity=security;
    this.getUserProfile();

 
  }
 /*  eComm(){
    window.location.href='http://34.196.47.126:8082/';
  } */
  getUserProfile() {

    this.accountsService.getUserProfile().subscribe((res: any) => {
      this.userProfile = res.data[0];


    })
  }
  psaMyProfile(){
    this.router.navigate(["/psa/my-profile"],
    {queryParams:{ taxAuthPartyId :  this.partyId}})

  }
  
  companyProfile(){
    this.router.navigate(["/users/company-profile"])
  }
 
  logoutPOS() {
    this.checkPOSToken = localStorage.getItem('posToken');
    if(this.checkPOSToken){
      this.productStoreIdNew = localStorage.getItem('productStoreId');
      this.posTerminalIdNew = localStorage.getItem('posTerminalId');
      this.router.navigate(["/webpos/control/main"],{queryParams:{ posTerminalId :  this.posTerminalIdNew, productStoreId:this.productStoreIdNew}});
    }
    else{
      localStorage.removeItem("token");
      localStorage.removeItem('posToken');
      localStorage.removeItem('productStoreId');
      localStorage.removeItem('posTerminalId');
      localStorage.removeItem('userLoginId');
      this.router.navigate(["/login"],{queryParams:{ webPos :  "webPos"}});
    }
   

  }
  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("currency");
    localStorage.removeItem("toPartyId");
    localStorage.removeItem("itemValue");
    localStorage.removeItem("tenantId");
    localStorage.removeItem("partyId");
    localStorage.removeItem("adminSecurity");
    localStorage.removeItem("email");
    localStorage.removeItem("findParty");
    localStorage.removeItem("userLoginId");
    localStorage.removeItem('productStoreId');
    localStorage.removeItem('posTerminalId');
    localStorage.removeItem('posToken');
    this.router.navigate(["/login"]);
  }

  toMyPortal()
  {
    this.router.navigate(['/myPortal/my-portal-my-profile']);
  }

}
