import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { FinancialService } from 'src/app/services/financial.service';

import { WarehouseService } from 'src/app/warehouse/warehouse/warehouse.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-technician-reset',
  templateUrl: './technician-reset.component.html',
  styleUrls: ['./technician-reset.component.css']
})
export class TechnicianResetComponent implements OnInit {
  financialList=[{
    "product":"ddd"
  }];
  total=0;
  createTaskList:FormGroup;
  @ViewChild('closebutton') closebutton;
  activeCategory=2;
  createTax:FormGroup;
  exemption: { label: string; value: string; }[];
  personId: any;
  partyIdArray: any[]=[];
  getGeoTy: any;
  getGeoTyArray: any[]=[];
  party: any;
  geo: any;
  show: boolean;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  TaxAuthorities: any;
  AssignedTaskList: any;
  StatusList: any;
  StatusListArray: any[]=[];
  MaintenanceType: any;
  MaintenanceTypeArray: any[]=[];
  MaintenanceTemplateId: any;
  MaintenanceTemplateIdArray: any[]=[];
  intervalMeterType: any;
  intervalMeterTypeArray: any[]=[];
  PurchaseOrderId: any;
  PurchaseOrderIdArray: any[]=[];
  IntervalUom: any;
  IntervalUomArray: any[]=[];
  ActualCompletionDate: any;
  ActualStartDate: any;
  EstimatedCompletionDate: any;
  EstimatedStartDate: any;

  products3: any;
 
  advanceSearch: {

    fixedAssetId: string,
    MainHistSeqID: string,
    MaintenanceType: string,
    Status: string,
    startDate: string


  };
 
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  fixedAssetsInitialDataArray: any[] = [];
  getFixedAssets: any;
 
  createFixAssetForm: FormGroup;
  fixedAssetTypes: any[];
  classEnumId: any[];
  calendar: any[];
  partyIds: any[];
  
  productIds: any[];
  fixAssetIds: any[];
  uomIds: any[];
  roleTypeIds: any[];
  facilityId: any[];
  facilityLocation: any[];
  fixAssetId: string;
  fixAsset: any;
  editMode: boolean;

  FixedAssetMaints: any;

  constructor(readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _MainCatalogService: MainCatalogService,
    readonly _FinancialService: FinancialService,

    readonly  _Router: Router,
    readonly _ToastrService: ToastrService,
   
    readonly _AccountingApService: AccountingApService,
    readonly _WarehouseService: WarehouseService,
    readonly addSkillService: AddSkillService,
    private _location: Location,
    ) {
      this.createTaskList = this._FormBuilder.group({
        EstimatedStartDate: [''],
        EstimatedCompletionDate:[''],
        Status:[''],
        MaintenanceType:[''],
        ProductMaintenance:[''],
        IntervalMeterType: [''],
        MaintenanceTemplate:[''],
        PurchaseOrderID:[''],
        IntervalQuantity:[''],
        PartyID:[''],
        IntervalUom:[''],
        ActualCompletionDate:[''],
        ActualStartDate:['']
       
        
      });
      this.advanceSearch = {
        fixedAssetId: "",
        MainHistSeqID: "",
        MaintenanceType: "",
        Status: "",
        startDate: ""
      };
    }

  ngOnInit(): void {
    this.findFixedAssetMaints();
    this.getMaintenanceType();
    this.assetMaintenanceStatus();
    this.getAssignedTaskList();
    this.assetMaintenanceStatus();
    this.getMaintenanceType();
    this.getMaintenanceTemplateId();
    this.getIntervalMeterType();
    this.getPurchaseOrderId();
    this.getPartyId();
    this.getIntervalUom();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/fsm/Overview']);
  }
  getAssignedTaskList() {
    this.spinner.show();
    this.accountsService.getAssignedTaskList().subscribe(res => {
      this.AssignedTaskList = res.data;
      this.spinner.hide();
  
    })
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  getPurchaseOrderId() {
    this.spinner.show();
    this.accountsService.getPurchaseOrderId().subscribe(res => {
      this.PurchaseOrderId = res.data[0].PurchaseOrderData;
      this.spinner.hide();
      for (const value of this.PurchaseOrderId) {
        this.PurchaseOrderIdArray.push({
          label: value.orderId,
          value: value.orderId
        })
      }
    })
  }
  
  getIntervalUom() {
    this.spinner.show();
    this.accountsService.getIntervalUom().subscribe(res => {
      this.IntervalUom = res.data;
      this.spinner.hide();
      for (const value of this.IntervalUom) {
        this.IntervalUomArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
  }
  getIntervalMeterType() {
    this.spinner.show();
    this.accountsService.getIntervalMeterType().subscribe(res => {
      this.intervalMeterType = res.data[0].intervalMeterType;
      this.spinner.hide();
      for (const value of this.intervalMeterType) {
        this.intervalMeterTypeArray.push({
          label: value.productMeterTypeId,
          value: value.productMeterTypeId
        })
      }
    })
  }
  getMaintenanceTemplateId() {
    this.spinner.show();
    this.accountsService.getMaintenanceTemplateId().subscribe(res => {
      this.MaintenanceTemplateId = res.data[0].maintenanceTemplateData;
      this.spinner.hide();
      for (const value of this.MaintenanceTemplateId) {
        this.MaintenanceTemplateIdArray.push({
          label: value.workEffortId,
          value: value.workEffortId
        })
      }
    })
  }
 
 
  createFixedAssetMaintUpdateWorkEffort(): void {
    this.spinner.show();
    const ActualCompletionDate=this.createTaskList.get('ActualCompletionDate').value;
    this.ActualCompletionDate=this.datePipe.transform(ActualCompletionDate,"yyyy-MM-dd hh:mm:ss");
    const ActualStartDate=this.createTaskList.get('ActualStartDate').value;
    this.ActualStartDate=this.datePipe.transform(ActualStartDate,"yyyy-MM-dd hh:mm:ss");
    const EstimatedCompletionDate=this.createTaskList.get('EstimatedCompletionDate').value;
    this.EstimatedCompletionDate=this.datePipe.transform(EstimatedCompletionDate,"yyyy-MM-dd hh:mm:ss");
    const EstimatedStartDate=this.createTaskList.get('EstimatedStartDate').value;
    this.EstimatedStartDate=this.datePipe.transform(EstimatedStartDate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "actualCompletionDate":this.ActualCompletionDate,
      "actualStartDate": this.ActualStartDate,
      "estimatedCompletionDate":  this.EstimatedCompletionDate,
      "estimatedStartDate":this.EstimatedStartDate,
      "fixedAssetId":'',
      "intervalMeterTypeId": this.createTaskList.value.IntervalMeterType,
      "intervalQuantity":this.createTaskList.value.IntervalQuantity,
      "intervalUomId": this.createTaskList.value.IntervalUom,
      "maintTemplateWorkEffortId": this.createTaskList.value.MaintenanceTemplate,
      "productMaintSeqId":this.createTaskList.value.ProductMaintenance,
      "productMaintTypeId": this.createTaskList.value.MaintenanceType,
      "purchaseOrderId": this.createTaskList.value.PurchaseOrderID,
      "quickAssignPartyId": this.createTaskList.value.PartyID,
      "scheduleWorkEffortId":'',
      "statusId": this.createTaskList.value.Status,

   
      }
     
    this.accountsService.createFixedAssetMaintUpdateWorkEffort(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.reset(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this._ToastrService.error(err.error.message);
      }
    }); }

  assetMaintenanceStatus() {
    this.spinner.show();
    this.accountsService.assetMaintenanceStatus().subscribe(res => {
      this.StatusList = res.data[0].statusData;
      this.spinner.hide();
      for (const value of this.StatusList) {
        this.StatusListArray.push({
          label: value.statusCode,
          value: value.statusId
        })
      }
    })
  }
  findFixedAssetMaints() {
    this.spinner.show();
    let req = {
      "actualStartDate": "",
      "fixedAssetID": "",
      "maintHistSeqID": "",
      "maintenanceType": "",
      "status": ""
    }
    this.accountsService.findFixedAssetMaints(req).subscribe(res => {
      this.FixedAssetMaints = res.data;
      this.spinner.hide();
    })
   
  }
  getMaintenanceType() {
    this.spinner.show();
    this.accountsService.getMaintenanceType().subscribe(res => {
      this.MaintenanceType = res.data[0].maintenanceTypeData;
      this.spinner.hide();
      for (const value of this.MaintenanceType) {
        this.MaintenanceTypeArray.push({
          label: value.description,
          value: value.productMaintTypeId
        })
      }
    })
  }
  advanceFixedMaints() {
    this.spinner.show();

    let req = {
      "actualStartDate": this.advanceSearch.startDate ? this.advanceSearch.startDate : '',
      "fixedAssetID":this.advanceSearch.fixedAssetId ? this.advanceSearch.fixedAssetId : '',
      "maintHistSeqID":this.advanceSearch.MainHistSeqID ? this.advanceSearch.MainHistSeqID : '',
      "maintenanceType":this.advanceSearch.MaintenanceType ? this.advanceSearch.MaintenanceType : '',
      "status": this.advanceSearch.Status ? this.advanceSearch.Status : '',
    }
    this.accountsService.findFixedAssetMaints(req).subscribe(res => {
      this.FixedAssetMaints = res.data;
      this.spinner.hide();
    })
   
  }
  reset() {
    this.spinner.show();
 
      this.advanceSearch.fixedAssetId= "",
      this.advanceSearch.MainHistSeqID=  "",
      this.advanceSearch.MaintenanceType=  "",
      this.advanceSearch.Status= "",
      this.advanceSearch.startDate=  ""
    let req = {
      "actualStartDate": "",
      "fixedAssetID": "",
      "maintHistSeqID": "",
      "maintenanceType": "",
      "status": ""
    }
    this.accountsService.findFixedAssetMaints(req).subscribe(res => {
      this.FixedAssetMaints = res.data;
      this.spinner.hide();
    })
   
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  accountDetails(id,maintainSequence,scheduleWorkEffortId,instanceOfProductId) {
    this._Router.navigate(["fsm/fixed-asset/edit-fixed-asset-maint"], { queryParams: { fixedAssetId: id,maintainSequence:maintainSequence,scheduleWorkEffortId:scheduleWorkEffortId,instanceOfProductId:instanceOfProductId } })
  }
}
