import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BlockableUI, SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { Color, Label } from 'ng2-charts';
import { ChartType } from 'chart.js';
import { ChartDataSets, ChartOptions } from 'chart.js';
import Swal from 'sweetalert2';
      
import { Location } from '@angular/common';

import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';

@Component({
  selector: 'app-product-overview',
  templateUrl: './product-overview.component.html',
  styleUrls: ['./product-overview.component.css']
})
export class ProductOverviewComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton10') closebutton10;
  activeCategory = 1;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  productId: any;
  ProductMembersList: any;
  ProductInformation: any;
  ProductContentList: any;
  ownerCompany: any;
  EmailsProductBacklogs: any;
  ProjectAndSprintList: any;
  productUpdateForm: FormGroup;
  CompanyandProductOwner: any;
  CompanyandProductOwnerArray: any[] = [];
  statusArray: { label: string; value: string; }[];
  CompanyandProductOwnerForm: FormGroup;
  PersonArray: { label: string; value: string; }[];
  RoleType: any;
  RoleTypeArray: any[] = [];
  addProductMemberForm: FormGroup;
  backlogStatus: { label: string; value: string; }[];
  yesNOArray: { label: string; value: string; }[];
  fromDate: string;
  TotalBacklogList: any;
  advanceSearch: { ProductBacklogID: string; RequesterName: string; ProductBacklogsName: string; BacklogItemCategory: string; RequestDate: string; Billed: string; Planned: string; Status: string; };
  EditBacklogsProductItemList: any;
  editBacklogForm: FormGroup;
  parentCustRequest = "";
  internalName: any;
  billFromParty: any;
  custRequestId: any;
  billingList: any;
  billingForm: FormGroup;
  fromdate: string;
  thruDate: string;
  advanceSearchA: { ThroughDate: string; FromDate: string; IncludeMeeting: string; };
  CompanyandProduct: any;
  CompanyandProductArray: any[] = [];
  partyIdArray: any[] = [];
  partyId: any;
  roleTypeId: any;
  roleTypeIdArray: any[] = [];
  PartyId: any;
  BacklogItemCategoryArray: any[] = [];
  BacklogItemCategory: any;
  editDetailForm: FormGroup;
  EditDetailBacklogs: any;
  productList: any;
  billingInvoiceList: any;
  custRequestIdEditBacklog: any;
  priority = "";
  DefaultTask: any;
  DefaultTaskArray: any[] = [];
  defaultTaskEditForm: FormGroup;
  defaultTaskCreateForm: FormGroup;
  AssignTo: any;
  AssignToArray: any[] = [];
  TaskList: any[];
  RequesterNameArray: any[] = [];
  RequesterName: any;
  workEffortId: any;
  Status: any;
  description: any;
  categoryCreateForm: FormGroup;
  CategoryList: any;
  categoryUpdateForm: FormGroup;
  MimeTypeId: any;
  mimeTypeIdArray: any[] = [];
  contentTypeId: any;
  contentTypeIdArray: any[] = [];
  contentForm: FormGroup;
  ContentList: any;
  fileData: File;
  contentID: any;
  newProjectForm: FormGroup;
  CommunicationList: any;
  UploadedFileForm: FormGroup;
  NewEmailForm: FormGroup;
  partyIdFrom = "";
  communicationEventId: any;
  ContentDataList: any;
  NewProjectProductName: any;
  ProductUpdateEmail: any;
  communicationEventIds: any;
  datetimeStarted: any;
  statusId: any;
  partyIdTo: any;
  dateTimeStart: string;
  tooString: any;
  fromString: any;
  CommunicationOverviewEvent: any;
  ChildCommunicationOverviewEvent: any;
  CommunicationOverviewEventRoles: any;
  TaskworkEffortId: any;
  statusValue: string;
  CompanyandProductOwnerValue: any;
  showUnplanned  = false;
  UnplanBacklogList: any;
  addNewTaskForm: FormGroup;
  UpdateProductBacklogForm: FormGroup;
  RoleTypeList: any;
  showUnplannedTab =false;
  activeFlag: any;

  chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
  pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: function (tooltipItems, data) {
          return data.datasets[0].data[tooltipItems.index] + ' %';
        }
      }
    },
  };


  pieChartLabels: Label[] = [''];

  pieChartData: number[] = [];

  pieChartType: ChartType = 'pie';

  pieChartLegend = true;

  pieChartPlugins = [];

  pieChartColors = [
    {
      backgroundColor: ['#007bff', '#007bff', '#007bff'],
    },
  ];

  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,private _location: Location,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router
  ) {
    this.productUpdateForm = this._FormBuilder.group({
      CompanyandProductOwner: [''],
      ProductName: [''],
      Status: [''],
      Description: [''],

    });
    this.CompanyandProductOwnerForm = this._FormBuilder.group({
      CompanyandPerson: [''],
      partyId: ['']
    });
    this.addProductMemberForm = this._FormBuilder.group({
      Person: [''],
      RoleType: [''],
    });

    this.editBacklogForm = this._FormBuilder.group({
      ProductBacklogItem: [''],
      BacklogItemCategory: [''],
      PlanTime: [''],
      SequenceNum: [''],
      Billed: [''],
      Unplanned: [''],
      Story: [''],
      Howtotest: [''],
    });

    this.billingForm = this._FormBuilder.group({
      BillFromParty: [''],
      BillToParty: [''],
      Fromdate: [''],
      Throughdate: [''],
      IncludeMeeting: [''],

    });
    this.editDetailForm = this._FormBuilder.group({
      Reason: [''],
    });
    this.defaultTaskEditForm = this._FormBuilder.group({
      TaskName: [''],
      Product: [''],
      Type: [''],
      InitialPlannedHours: [''],
      Description: [''],
      AssignedTo: [''],


    });
    this.defaultTaskCreateForm = this._FormBuilder.group({
      TaskName: [''],
      Product: [''],
      Type: [''],
      InitialPlannedHours: [''],
      Description: [''],
      AssignedTo: [''],
    });
    this.categoryCreateForm = this._FormBuilder.group({
      Category: [''],

    });
    this.categoryUpdateForm = this._FormBuilder.group({
      Category: [''],


    });
    this.contentForm = this._FormBuilder.group({
      ContentTypeID: [''],
      MimeTypeID: [''],
      Upload: [''],

    });

    this.newProjectForm = this._FormBuilder.group({
      ProductName: [''],
      ProjectName: [''],
      Billed: [''],
      ProjectGoal: [''],

    });

    this.UploadedFileForm = this._FormBuilder.group({
      UploadedFile: [''],
    });

    this.NewEmailForm = this._FormBuilder.group({
      From: [''],
      To: [''],
      Subject: [''],
      Content: [''],
    });

    this.statusArray = [

      {
        label: 'Any',
        value: 'Any'
      },
      {
        label: 'Active',
        value: 'PRODUCT_ACTIVE'
      },
      {
        label: 'Closed',
        value: 'PRODUCT_CLOSED'
      },


    ];
    this.PersonArray = [

      {
        label: '- Select One -',
        value: ' '
      },
      {
        label: 'Scrum Demo Stakeholder ',
        value: 'DemoStakeholder'
      },
      {
        label: 'Scrum Scrum Master ',
        value: 'SCRUMASTER'
      },


    ];
    this.yesNOArray = [

      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];

    this.backlogStatus = [

      {
        label: 'Accepted',
        value: 'CRQ_ACCEPTED'
      },
      {
        label: 'Reviewed',
        value: 'CRQ_REVIEWED'
      },
      {
        label: 'Completed',
        value: 'CRQ_COMPLETED'
      },
      {
        label: 'Reopened',
        value: 'CRQ_REOPENED'
      },
      {
        label: 'Cancelled',
        value: 'CRQ_CANCELLED'
      },


    ];
    this.advanceSearch = {

      ProductBacklogID: "",
      RequesterName: "",
      ProductBacklogsName: "",
      BacklogItemCategory: "",
      RequestDate: "",
      Billed: "",
      Planned: "",
      Status: "",

    };
    this.advanceSearchA = {

      ThroughDate: "",
      FromDate: "",
      IncludeMeeting: "",


    };

    this.yesNOArray = [
      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];
    this.UpdateProductBacklogForm = this._FormBuilder.group({
      ProductBacklogItem: [''],
      Company: [''],
      ProductOwner: [''],
      BacklogItemCategory: [''],
      PlanTime: [''],
      SequenceNum: [''],
      Billed: [''],
      Status: [''],
      Unplanned: [''],
      Requestname: [''],
      Story: [''],
      Howtotest: [''],
    });

    this.addNewTaskForm = this._FormBuilder.group({
      ProjectID: [''],
      TaskType: [''],
      TaskName: [''],
      SprintID: [''],
      SprintBacklog: [''],
      PlanHours: [''],
      Uploaded: [''],
      Description: [''],
      AssignTo: [''],
    });
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.SCRUM_ADMIN=="SCRUM_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.SCRUM_BILLING_ADMIN=="SCRUM_BILLING_ADMIN"){
      this.Hidebtn=true;
    }
    this.activatedRoute.queryParams.subscribe(params => {
      this.productId = params.productId;
      this.internalName = params.internalName;
      this.billFromParty = params.billFromParty + "[" + params.partyId + "]";
      this.partyId = params.partyId;
      this.Status = params.Status;
      this.description = params.longDescription;
      this.workEffortId = params.workEffortId;
      this.custRequestId = params.custRequestId;
      this.communicationEventId = params.communicationEventId;
      this.activeFlag = params.activeFlag;

    })
    this.getProductOverviewMemberList();
    this.getProductOverviewProductInfo();
    this.getProductOverviewProductContent();
    this.getEmailsProductBacklogs();
    this.getProjectAndSprintList();
    this.getCompanyAndProductOwnerList();
    this.getTotalBacklog();
    this.getEditBacklogsProductItemList();
    this.getFindBillingList();
    this.getCompanyAndProductList();
    this.getProductMemberParty();
    this.getProductMemberRole();
    this.getBacklogItemCategory();
    this.getSprintOverview();
    this.getForBillingPage();
    this.getDefaultTaskTypeList();
    this.getAssignToList();
    this.getTaskList();
    this.getFindProduct();
    this.getFromPartyIdRequesterName();
    this.getCategoryListData();
    this.getMimeTypeId();
    this.getContentTypeId();
    this.getScrumProductContentListData();
    this.getCommunicationList();
    this.getEmailContentData();
    this.getProductUpdateEmail();
    this.getNewProjectProductName();
    this.getCommunicationOverviewEventRoles();
    this.getChildCommunicationOverviewEvent();
    this.getCommunicationOverviewEvent();
    this.getFromPartyIdRequesterName();
    this.GetUnplanBacklogList();
    this.getRoleTypeList();
    this.getAssignToList();
    this.getBacklogTypeChartSecond();
    this.getBacklogTypeChartPie();
    
    if(this.activeFlag){
      this.showUnplannedTab = true;
      this.activeCategory=16; 
    }

  }

  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  getBacklogTypeChartPie() {
    this.spinner.show();
    this.myContactsService.getBacklogTypePieChart(this.productId).subscribe(res => {
      this.pieChartLabels = res.data[1];
      this.pieChartData =   res.data[0];
      this.spinner.hide();
    })
  }
  getBacklogTypeChartSecond() {
    this.myContactsService.getBacklogTypeChartSecond(this.productId).subscribe((res: any) => {
      this.spinner.show();
      this.pieChartLabels = res.data[1];
      this.pieChartData =   res.data[0];
      this.spinner.hide();
    })
  }


  getRoleTypeList() {
    this.spinner.show();
    this.accountsService.getRoleTypeList().subscribe(res => {
      this.RoleTypeList = res.data.data;
      this.spinner.hide();
      for (const value of this.RoleTypeList) {
        this.RoleTypeArray.push({
          label: value.roleTypeDesc,
          value: value.roleTypeValue
        })
      }
    })
  }
  setCompleteFromUnplanBacklog(product) {
    this.spinner.show();

    const formData = {
      "productId": this.productId,
      "view_INDEX_1": "0",
      "view_SIZE_1": "20",
      "workEffortId": product.tasks.workEffortId
    }

    this.accountsService.setCompleteFromUnplanBacklog(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();



      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    this.spinner.hide();

  }

  addTask() {
    this.spinner.show();

    const formData = {
      "actualHours": "",
      "contentTypeId": "DOCUMENT",
      "currentStatusId": "STS_CREATED",
      "custRequestId": this.custRequestId,
      "description": this.addNewTaskForm.value.Description,
      "mimeTypeId": "application/octet-stream",
      "partyId": this.addNewTaskForm.value.AssignTo,
      "planHours": this.addNewTaskForm.value.PlanHours,
      "resourceStatusId": "CTNT_PUBLISHED",
      "uploadedFile": this.addNewTaskForm.value.Uploaded,
      "workEffortContentTypeId": "CREATED_MEDIA",
      "workEffortId": "",
      "workEffortName": this.addNewTaskForm.value.TaskName,
      "workEffortTypeId": this.addNewTaskForm.value.TaskType,
    }

    this.accountsService.addTask(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    this.spinner.hide();

  }

  DeleteUnplanBacklog(product) {
    Swal.fire({ title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "custRequestId": product.SprintBacklog[0].custRequestId,
          "productId": this.productId,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20",
          "workEffortId": product.tasks.workEffortId }
        this.accountsService.DeleteUnplanBacklog(formData).subscribe((res: any) => {
          this.spinner.hide();
          this.GetUnplanBacklogList();}, (err) => {
          this.toastr.error(err.error.message); })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'  )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error' ) }})
  }

  GetUnplanBacklogList() {
    this.spinner.show();
    this.accountsService.GetUnplanBacklogList().subscribe(res => {
      this.UnplanBacklogList = res.data;
      this.spinner.hide();
    })
  }



  editPage(partyId, workEffortName, workEffortId, custRequestId) {
    this._Router.navigate(["/psa/detail-tasks"], { queryParams: { partyId, workEffortName, workEffortId: workEffortId, custRequestId: custRequestId , } })
  }

  //////////////============NEW PROJECT =============/////////////////

  createScrumProject() {
    this.spinner.show();

    const formData = {
      "billed": this.newProjectForm.value.Billed,
      "currentStatusId": "SPJ_ACTIVE",
      "description": this.newProjectForm.value.ProjectGoal,
      "partyId": "admin",
      "productId": this.productId,
      "productOwnerId": "",
      "projectId": "",
      "workEffortId": "",
      "workEffortName": this.newProjectForm.value.ProjectName,
      "workEffortTypeId": "SCRUM_PROJECT"

    }

    this.accountsService.createScrumProject(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this._Router.navigate(["/psa/detail-sprint-project"],
          //{ queryParams: {productId :this.productId} })
          { queryParams: { productId: this.productId } })
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    this.spinner.hide();

  }


  closeProduct() {
    this.spinner.show();

    const formData = {
      "companyId": this.billFromParty,
      "isCloseBtn": "Y",
      "partyId": this.partyId,
      "productId": this.productId,
      "statusId": "PRODUCT_CLOSED"

    }

    this.accountsService.closeProduct(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.activeCategory = 1;


      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    this.spinner.hide();

  }


  ////////////////////------==-----COMMUNICATION-------==------////////////////////////

  getCommunicationOverviewEvent() {
    this.spinner.show();
    this.accountsService.getCommunicationOverviewEvent(this.productId, this.communicationEventId).subscribe(res => {
      this.CommunicationOverviewEvent = res.data[0];
      this.spinner.hide();
    })
  }
  getChildCommunicationOverviewEvent() {
    this.spinner.show();
    this.accountsService.getChildCommunicationOverviewEvent(this.productId, this.communicationEventId).subscribe(res => {
      this.ChildCommunicationOverviewEvent = res.data;
      this.spinner.hide();
    })
  }

  getCommunicationOverviewEventRoles() {
    this.spinner.show();
    this.accountsService.getCommunicationOverviewEventRoles(this.productId, this.communicationEventId).subscribe(res => {
      this.CommunicationOverviewEventRoles = res.data;
      this.spinner.hide();
    })
  }

  getNewProjectProductName() {
    this.spinner.show();
    this.accountsService.getNewProjectProductName(this.productId).subscribe(res => {
      this.NewProjectProductName = res.data[0];
      this.spinner.hide();
    })
  }
  getProductUpdateEmail() {
    this.spinner.show();
    this.accountsService.getProductUpdateEmail(this.productId).subscribe(res => {
      this.ProductUpdateEmail = res.data[0];
      this.communicationEventIds = res.data[0].ItemList[0].communicationEventId;
      this.datetimeStarted = res.data[0].ItemList[0].datetimeStarted;
      this.statusId = res.data[0].ItemList[0].statusId;
      this.tooString = res.data[0].ItemList[0].toString;
      this.fromString = res.data[0].ItemList[0].fromString;
      this.spinner.hide();
    })
  }

  getEmailContentData() {
    this.spinner.show();
    this.accountsService.getEmailContentData(this.productId).subscribe(res => {
      this.ContentDataList = res.data.data;
      this.spinner.hide();
    })
  }

  EmailContentDownload(imageUrl) {
    this.spinner.show();
    this.accountsService.getEmailContentDownload(imageUrl).subscribe(res => {
      const blob = new Blob([res], { type: 'document/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = 'CONTENT';
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    })

  }





  fileProgressCom(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  uploadEmailContent() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.accountsService.uploadEmailContent(formData, this.productId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.getEmailContentData();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }


  updateEmailForProduct() {
    this.spinner.show();
    // const dateTime = this.datetimeStarted.get(this.datetimeStarted).value;
    const dateTimeStart = this.datePipe.transform(this.datetimeStarted, "yyyy-MM-dd hh:mm:ss");

    const requestData = {

      "communicationEventId": this.communicationEventIds,
      "contactMechPurposeTypeIdFrom": "SUPPORT_EMAIL",
      "content": this.NewEmailForm.value.Content,
      "contentMimeTypeId": "text/plain",
      "datetimeStarted": dateTimeStart,
      "dummy": "",
      "form": "list",
      "partyIdFrom": this.fromString,
      "partyIdTo": this.tooString,
      "productId": this.productId,
      "productIdIn": this.productId,
      "statusId": this.statusId,
      "subject": this.NewEmailForm.value.Subject,

    }
    this.accountsService.updateEmailForProduct(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.activeCategory = 10;
        this.getCommunicationList();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }

  getCommunicationList() {
    this.spinner.show();
    this.accountsService.getCommunicationList(this.productId).subscribe(res => {
      this.CommunicationList = res.data.filteringData;
      this.spinner.hide();
    })
  }
  newEmailPage() {
    this.activeCategory = 15;
  }

  detailedCommunicationPage(productId, communicationEventId) {
    this.activeCategory = 14;
    this._Router.navigate(["/psa/product-overview"],
      { queryParams: { productId: productId, communicationEventId: communicationEventId } })

  }

  moveToUnplannedSprint() {
    this.showUnplannedTab=true;
    this.activeCategory=16;
  }

  ////////////////////-----------CONTENT-------------////////////////////////

  DownloadImageProductContent(imageURL) {
    this.spinner.show();
    this.accountsService.getDownloadImageProductContent(imageURL).subscribe(res => {
      const blob = new Blob([res], { type: 'document/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = 'DEMO-CONTENT';
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    })

  }

  deleteScrumProductContent(contentID) {


    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        this.accountsService.deleteScrumProductContent(contentID).subscribe((res: any) => {
          this.getScrumProductContentListData();

        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }


  getScrumProductContentListData() {
    this.spinner.show();
    this.accountsService.getScrumProductContentListData(this.productId).subscribe(res => {
      this.ContentList = res.data.data;
      this.contentID = res.data.data.contentId;
      this.spinner.hide();
    })
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  oncreateScrumProductContent() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.accountsService.createScrumProductContent(formData, this.contentForm.value.ContentTypeID, this.contentForm.value.MimeTypeID, this.productId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.getScrumProductContentListData();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
      this.spinner.hide();

    });

  }

  getContentTypeId() {
    this.spinner.show();
    this.accountsService.getContentTypeId().subscribe(res => {
      this.contentTypeId = res.data.contentTypeId;
      this.spinner.hide();
      for (const value of this.contentTypeId) {
        this.contentTypeIdArray.push({
          label: value.description,
          value: value.contentTypeId
        })
      }
    })
  }

  getMimeTypeId() {
    this.spinner.show();
    this.accountsService.getMimeTypeId().subscribe(res => {
      this.MimeTypeId = res.data.mimeTypeId;
      this.spinner.hide();
      for (const value of this.MimeTypeId) {
        this.mimeTypeIdArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      }
    })
  }

  ///////////=====CATEGORY=====//////////////////

  detailCategoryEditPage(custRequestId, productId) {
    this.activeCategory = 13;
    this._Router.navigate(["/psa/product-overview"], { queryParams: { custRequestId: custRequestId, productId: productId } })

  }



  onUpdateCategory() {
    this.spinner.show();
    const requestData = {
      "custRequestId": this.custRequestId,
      "custRequestName": this.categoryUpdateForm.value.Category,
      "productId": this.productId
    }
    this.accountsService.updateDefaultTask(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.activeCategory = 8;
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }

  getCategoryListData() {
    this.spinner.show();
    this.accountsService.getCategoryListData(this.productId).subscribe(res => {
      this.CategoryList = res.data.filteringData;
      this.spinner.hide();
    })
  }

  onCreateCategory() {

    this.spinner.show();
    const formData = {

      "custRequestId": "",
      "custRequestName": this.categoryCreateForm.value.Category,
      "productId": this.productId

    }
    this.accountsService.createCategory_productBacklog(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.getCategoryListData();



      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
      this.spinner.hide();

    });

  }

  //////////----------DEFAULT TASKS----------/////////////////////

  detailEditPage(workEffortId, productId) {
    this.activeCategory = 12;
    this._Router.navigate(["/psa/product-overview"], { queryParams: { workEffortId: workEffortId, productId: productId } })

  }

  onUpdateDefaultTask() {
    this.spinner.show();
    const requestData = {
      "currentStatusId": "STS_CREATED",
      "description": this.defaultTaskEditForm.value.Description,
      "partyId": this.defaultTaskEditForm.value.AssignedTo,
      "plannedHours": this.defaultTaskEditForm.value.InitialPlannedHours,
      "productId": this.productId,
      "workEffortId": this.workEffortId,
      "workEffortName": this.defaultTaskEditForm.value.TaskName,
      "workEffortPurposeTypeId": "SCRUM_DEFAULT_TASK",
      "workEffortTypeId": this.defaultTaskEditForm.value.Type,


    }
    this.accountsService.updateDefaultTask(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.activeCategory = 7;
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }

  deleteDefaultTask() {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {

          "productId": this.productId,
          "view_INDEX_1": "",
          "view_SIZE_1": "",
          "workEffortId": this.TaskworkEffortId


        }
        this.accountsService.deleteDefaultTask(formData).subscribe((res: any) => {
          this.getTaskList();

        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  getFromPartyIdRequesterName() {
    this.spinner.show();
    this.accountsService.getFromPartyIdRequesterName().subscribe(res => {
      this.RequesterName = res.data[0].requesterName;
      this.spinner.hide();
      for (const value of this.RequesterName) {
        this.RequesterNameArray.push({
          label: value.fromPartyId,
          value: value.fromPartyId
        })
      }
    })
  }

  getAssignToList() {
    this.spinner.show();
    this.accountsService.getAssignToList().subscribe(res => {
      this.AssignTo = res.data.data;
      this.spinner.hide();
      for (const value of this.AssignTo) {
        this.AssignToArray.push({
          label: value.scrumTeamValue,
          value: value.scrumTeamName
        })
      }
    })
  }

  createDefaultTask() {

    this.spinner.show();
    const formData = {

      "currentStatusId": "STS_CREATED",
      "description": this.defaultTaskCreateForm.value.Description,
      "partyId": this.defaultTaskCreateForm.value.AssignedTo,
      "plannedHours": this.defaultTaskCreateForm.value.InitialPlannedHours,
      "productId": this.productId,
      "workEffortId": "",
      "workEffortName": this.defaultTaskCreateForm.value.TaskName,
      "workEffortPurposeTypeId": "SCRUM_DEFAULT_TASK",
      "workEffortTypeId": this.defaultTaskCreateForm.value.Type,



    }
    this.accountsService.createDefaultTask(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getTaskList();


      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
      this.spinner.hide();

    });

  }

  getDefaultTaskTypeList() {
    this.spinner.show();
    this.accountsService.getDefaultTaskTypeList().subscribe(res => {
      this.DefaultTask = res.data.list;
      this.spinner.hide();
      for (const value of this.DefaultTask) {
        this.DefaultTaskArray.push({
          label: value.description,
          value: value.workEffortTypeId
        })
      }
    })
  }


  getTaskList() {
    this.spinner.show();
    this.accountsService.getTaskList(this.productId).subscribe(res => {
      this.TaskList = res.data[0].task;
      this.TaskworkEffortId = res.data[0].task.workEffortId;
      this.spinner.hide();
    })
  }




  ////////////////---BILLING BACKLOG----/////////////////


  getForBillingPage() {
    this.spinner.show();
    this.accountsService.getForBillingPage(this.productId).subscribe(res => {
      this.billingInvoiceList = res.data[0];
      this.spinner.hide();
    })
  }

  resetBilling() {

    this.spinner.show();
    this.advanceSearchA.ThroughDate = "";
    this.advanceSearchA.FromDate = "";
    this.advanceSearchA.IncludeMeeting = "";

    this.accountsService.getFindBillingList().subscribe((res: any) => {
      this.billingList = res.data;
      this.spinner.hide();
    })
  }

  updateNotBilledBacklog() {
    this.spinner.show();
    const fromActualdate = this.billingForm.get('Fromdate').value;
    this.fromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");

    const throughDate = this.billingForm.get('Throughdate').value;
    this.thruDate = this.datePipe.transform(throughDate, "yyyy-MM-dd hh:mm:ss");

    const formdata = {

      "fromDate": "string",
      "includeMeeting": "string",
      "productId": "string",
      "thruDate": "string",
      "view_INDEX_1": "",
      "view_SIZE_1": ""

    }

    this.accountsService.updateNotBilledBacklog(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.getEditBacklogsProductItemList();

      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }

  addProductTimeToNewInvoice() {
    this.spinner.show();
    const fromActualdate = this.billingForm.get('Fromdate').value;
    this.fromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");

    const throughDate = this.billingForm.get('Throughdate').value;
    this.thruDate = this.datePipe.transform(throughDate, "yyyy-MM-dd hh:mm:ss");

    const formdata = {

      "fromDate": this.fromdate,
      "includeMeeting": this.billingForm.value.IncludeMeeting,
      "invoiceId": "",
      "partyId": this.billingForm.value.BillToParty,
      "partyIdFrom": this.billingForm.value.BillFromParty,
      "productId": this.productId,
      "reCreate": "",
      "thruDate": this.thruDate

    }

    this.accountsService.addProductTimeToNewInvoice(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.getFindBillingList();

      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }
  getFindBillingList() {
    this.spinner.show();
    this.accountsService.getFindBillingList().subscribe(res => {
      this.billingList = res.data;
      this.spinner.hide();

    })
  }

  /////////////////////---------------BILLING---------------///////


  getFindBilling() {

    this.spinner.show();
    const req = {
      "fromDate": this.advanceSearchA.FromDate ? this.advanceSearchA.FromDate : '',
      "includeMeeting": this.advanceSearchA.IncludeMeeting ? this.advanceSearchA.IncludeMeeting : '',
      "thruDate": this.advanceSearchA.ThroughDate ? this.advanceSearchA.ThroughDate : '',

    }
    this.accountsService.getFindBilling(req.fromDate, req.includeMeeting, req.thruDate).subscribe((res: any) => {
      this.billingList = res.data;
      this.spinner.hide();
    })
    this.spinner.hide();
  }

  ondeleteProductBackLogsItem(custRequestId, productId): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteProductBackLogsItem(custRequestId, productId, "CRQ_ACCEPTED").subscribe(res => {
          if (res['data'].responseMessage === 'success') {
          }
          this.getEditBacklogsProductItemList();
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }


  addNewProductBacklogsItem() {

    this.spinner.show();
    const formData = {

      "backStatusId": "CRQ_ACCEPTED",
      "billed": this.editBacklogForm.value.Billed,
      "custEstimatedMilliSeconds": this.editBacklogForm.value.PlanTime,
      "custRequestTypeId": "",
      "custSequenceNum": this.editBacklogForm.value.SequenceNum,
      "description": this.editBacklogForm.value.ProductBacklogItem,
      "noteId": "",
      "noteInfo": this.editBacklogForm.value.Howtotest,
      "parentCustRequestId": this.parentCustRequest,
      "partyId": "userLogin.partyId",
      "productId": this.productId,
      "sequence": "Y",
      "story": this.editBacklogForm.value.Story,
      "unplannedFlag": this.editBacklogForm.value.Unplanned,

    }
    this.accountsService.addNewProductBacklogsItem(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getEditBacklogsProductItemList();
        this.closebutton10.nativeElement.click();

        if (this.editBacklogForm.value.Unplanned == "Y") {
          this.showUnplanned = true;
        }
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
      this.spinner.hide();

    });

  }

  ////////--EDIT BACKLOG-----//////

  productBackLogsItemSubmit(pro) {
    this.spinner.show();

    const formdata = {
      "custRequestCategoryGroupId": "",
      "description": pro.description,
      "estimatedHours": pro.custEstimatedMilliSeconds,
      "parentCustRequestId": "",
      "postWorkHours": "",
      "priority": "",
      "showPosition1": ""
    }

    this.accountsService.productBackLogsItemSubmit(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }

  getBacklogItemCategory() {
    this.spinner.show();
    this.accountsService.getBacklogItemCategory().subscribe(res => {
      this.BacklogItemCategory = res.data;
      this.spinner.hide();
      for (const value of this.BacklogItemCategory) {
        this.BacklogItemCategoryArray.push({
          label: value.backlogItemCategoryName.custRequestName,
          value: value.backlogItemCategoryName.custRequestId
        })
      }
    })
  }
  detailEditBacklogPage(custRequestId, productId) {
    this.activeCategory = 11;
    this._Router.navigate(["/psa/product-overview"], { queryParams: { custRequestId: custRequestId, productId: productId } })
    this.getSprintOverview();
  }


  deleteBacklogItem() {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {

          "custRequestId": this.custRequestId,
          "productId": this.productId,
          "reason": this.editDetailForm.value.Reason


        }
        this.accountsService.deleteBacklogItem(formData).subscribe((res: any) => {
          this.getEditBacklogsProductItemList();

        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  getEditBacklogsProductItemList() {
    this.spinner.show();
    this.accountsService.getEditBacklogsProductItemList(this.productId).subscribe(res => {
      this.EditBacklogsProductItemList = res.data.data;
      this.custRequestIdEditBacklog = res.data.data.custRequestId;
      this.spinner.hide();
    })
  }

  getSprintOverview() {
    this.spinner.show();
    this.accountsService.getSprintOverview(this.productId, this.custRequestId).subscribe(res => {
      this.EditDetailBacklogs = res.data;
      this.spinner.hide();
    })
  }

  ////////////////---TOTAL BACKLOG----/////////////////

  reset() {
    this.spinner.show();
    this.advanceSearch.ProductBacklogID = "";
    this.advanceSearch.RequesterName = "";
    this.advanceSearch.ProductBacklogsName = "";
    this.advanceSearch.BacklogItemCategory = "";
    this.advanceSearch.RequestDate = "";
    this.advanceSearch.Billed = "";
    this.advanceSearch.Planned = "";
    this.advanceSearch.Status = "";


    this.accountsService.getTotalBacklog().subscribe((res: any) => {
      this.TotalBacklogList = res.data;
      this.spinner.hide();


    })
  }

  getTotalBacklog() {

    this.spinner.show();
    this.accountsService.getTotalBacklog().subscribe(res => {
      this.TotalBacklogList = res.data;
      this.spinner.hide();
    })
  }
  getFindTotalBacklog() {

    this.spinner.show();
    const req = {
      "billed": this.advanceSearch.Billed ? this.advanceSearch.Billed : '',
      "custEstimatedMilliSeconds": this.advanceSearch.Planned ? this.advanceSearch.Planned : '',
      "custRequestDate": this.advanceSearch.RequestDate ? this.advanceSearch.RequestDate : '',
      "custRequestId": this.advanceSearch.ProductBacklogID ? this.advanceSearch.ProductBacklogID : '',
      "description": this.advanceSearch.ProductBacklogsName ? this.advanceSearch.ProductBacklogsName : '',
      "fromPartyId": this.advanceSearch.RequesterName ? this.advanceSearch.RequesterName : '',
      "parentCustRequestId": this.advanceSearch.BacklogItemCategory ? this.advanceSearch.BacklogItemCategory : '',
      "statusId": this.advanceSearch.Status ? this.advanceSearch.Status : '',

    }
    this.accountsService.getFindTotalBacklog(req.billed, req.custEstimatedMilliSeconds, req.custRequestDate,
      req.custRequestId, req.description, req.fromPartyId, req.parentCustRequestId, req.statusId).subscribe((res: any) => {
        this.TotalBacklogList = res.data;
        this.spinner.hide();

      })
    this.spinner.hide();
  }

  ///////-----------PRODUCT MEMBER----------------/////////


  removeProductMemberListBySingle(product) {

    const fromDate = this.datePipe.transform(product.fromDate, 'yyyy-MM-dd hh:mm:ss')

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "fromDate": fromDate,
          "partyId": product.partyId,
          "productId": this.productId,
          "roleTypeId": product.roleTypeId,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20"


        }
        this.accountsService.removeProductMemberListBySingle(req).subscribe((res: any) => {
          this.getProductOverviewMemberList();

        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }


  deleteProductMember(): void {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteProductMember(this.productId).subscribe(res => {
          if (res['data'].responseMessage === 'success') {
            this.getProductOverviewMemberList();
          }
        }


        );
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'

        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  addProductMember() {
    this.spinner.show();

    const formdata = {
      "partyId": this.addProductMemberForm.value.Person,
      "productId": this.productId,
      "roleTypeId": this.addProductMemberForm.value.RoleType
    }

    this.accountsService.addProductMember(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.getProductOverviewMemberList();

        // this.closebutton.nativeElement.click();


      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }

  addCompanyAndProductOwner() {
    this.spinner.show();
    const formdata = {
      "internalName": this.internalName,
      "longDescription": "",
      "partyCompany": this.CompanyandProductOwnerForm.value.CompanyandPerson,
      "partyId": this.CompanyandProductOwnerForm.value.partyId,
      "productId": this.productId,
      "productTypeId": "SCRUM_ITEM",
      "statusId": ""
    }

    this.accountsService.addCompanyAndProductOwner(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.getProductOverviewMemberList();
        // this.closebutton.nativeElement.click();


      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }
  getCompanyAndProductOwnerList() {
    this.spinner.show();
    this.accountsService.getCompanyAndProductOwnerList().subscribe(res => {
      this.CompanyandProductOwner = res.data;
      this.spinner.hide();
      for (const value of this.CompanyandProductOwner) {
        this.CompanyandProductOwnerArray.push({
          label: value.partyIdDemo,
          value: value.partyIdDemo
        })
      }
    })
  }

  getProductMemberRole() {
    this.spinner.show();
    this.accountsService.getProductMemberRole().subscribe(res => {
      this.roleTypeId = res.data[0].roleTypeID;
      this.spinner.hide();
      for (const value of this.roleTypeId) {
        this.roleTypeIdArray.push({
          label: value.roleTypeId,
          value: value.roleTypeId
        })
      }
    })
  }

  getProductMemberParty() {
    this.spinner.show();
    this.accountsService.getProductMemberParty().subscribe(res => {
      this.PartyId = res.data[0].partyId;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }

  getCompanyAndProductList() {
    this.spinner.show();
    this.accountsService.getCompanyAndProductList().subscribe(res => {
      this.CompanyandProduct = res.data.data;
      this.spinner.hide();
      for (const value of this.CompanyandProduct) {
        this.CompanyandProductArray.push({
          label: value.customerDesc,
          value: value.customerDesc
        })
      }
    })
  }
  /////////////////======PRODUCT UPDATE =============//////////////////

  changeactiveCategoryUpdate() {
  //  this.activeCategory = 2;
    for (const r of this.statusArray) {
      if (r.label == this.Status) {
        this.statusValue = r.value;
      }
    }
    for (const a of this.billFromParty) {
      if (a.label == this.CompanyandProductOwner) {
        this.CompanyandProductOwnerValue = a.value; 
      }
    }
    this.activeCategory = 2;

    setTimeout(() => {
      const formValue = this.productUpdateForm;
      formValue.patchValue({

        CompanyandProductOwner: this.CompanyandProductOwnerValue,
        ProductName: this.internalName,
        Status: this.statusValue,
        Description: this.description

      })
    }, 2000);
  }

  getSummary(){
    
  }

  productUpdate() {
    this.spinner.show();

    const formdata = {
      "internalName": this.productUpdateForm.value.ProductName,
      "longDescription": this.productUpdateForm.value.Description,
      "partyCompany": this.productUpdateForm.value.CompanyandProductOwner,
      "partyId": "admin",
      "productId": this.productId,
      "productTypeId": "SCRUM_ITEM",
      "statusId": this.productUpdateForm.value.Status,
      "workingScheduleId": ""
    }

    this.accountsService.productUpdate(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this._Router.navigate(["/psa/product-overview"], { queryParams: { productId: this.productId, internalName: this.productUpdateForm.value.ProductName, billFromParty: this.productUpdateForm.value.CompanyandProductOwner, Status: this.productUpdateForm.value.Status, description: this.productUpdateForm.value.Description } })
        this.spinner.hide();
        this.getFindProduct();
        this.activeCategory = 1;

      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }


  getEmailsProductBacklogs() {

    this.spinner.show();
    this.accountsService.getEmailsProductBacklogs().subscribe(res => {
      this.EmailsProductBacklogs = res.data.data;
      this.spinner.hide();
    })
  }
  getProductOverviewMemberList() {

    this.spinner.show();
    this.accountsService.getProductOverviewMemberList(this.productId).subscribe(res => {
      this.ProductMembersList = res.data[0].MemberList;
      this.spinner.hide();
    })
  }
  getProductOverviewProductInfo() {

    this.spinner.show();
    this.accountsService.getProductOverviewProductInfo(this.productId).subscribe(res => {
      this.ProductInformation = res.data[0];
      this.spinner.hide();
    })
  }
  getProductOverviewProductContent() {

    this.spinner.show();
    this.accountsService.getProductOverviewProductContent(this.productId).subscribe(res => {
      this.ProductContentList = res.data;
      this.spinner.hide();
    })
  }
  getProjectAndSprintList() {

    this.spinner.show();
    this.accountsService.getProjectAndSprintList().subscribe(res => {
      this.ProjectAndSprintList = res.data.scrumSprint;
      this.spinner.hide();
    })
  }

  getFindProduct() {
    this.spinner.show();
    const req = {
      "productId": this.productId,
      "internalName": "",
      "statusID":  "",
    }
    this.accountsService.getFindProduct(req.productId, req.internalName, req.statusID).subscribe((res: any) => {
      this.productList = res.data[0];
      this.spinner.hide();
    })
    setTimeout(() => {
      const formValue = this.productUpdateForm;
      formValue.patchValue({
        CompanyandProductOwner: this.productList.productRolePartyIds.partyId ,
        ProductName: this.productList.ProductNames.internalName,
        Status: this.productList.Status,
        Description: this.productList.ProductNames.longDescription

      })
    }, 2000);
  }

  // getProductList() {

  //   this.spinner.show();
  //   this.accountsService.getProductList().subscribe(res => {
  //     this.productList = res.data;
  //     this.spinner.hide();
  //   })
    
  // }
  unplannedSprint(productId, billFromParty) {
    this._Router.navigate(["/psa/unplanned-sprint"], { queryParams: { productId: productId, billFromParty: billFromParty } })
  }

  ProductOverview() {
    this._Router.navigate(["/psa/product-overview"])
  }
  ProductUpdate() {
    this._Router.navigate(["/psa/product-update"])
  }
  ProductMember() {
    this._Router.navigate(["/psa/product-member"])
  }
  TotalBacklog() {
    this._Router.navigate(["/psa/product-total-backlog"])
  }
  EditBacklog() {
    this._Router.navigate(["/psa/product-edit-backlog"])
  }
  Billing() {
    this._Router.navigate(["/psa/product-billing"])
  }
  DefaultTasks() {
    this._Router.navigate(["/psa/product-default-tasks"])
  }
  Categories() {
    this._Router.navigate(["/psa/product-categories"])
  }
  Content() {
    this._Router.navigate(["/psa/product-content"])
  }
  Communications() {
    this._Router.navigate(["/psa/product-communications"])
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
