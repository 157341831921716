import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-receive-payment',
  templateUrl: './receive-payment.component.html',
  styleUrls: ['./receive-payment.component.css']
})
export class ReceivePaymentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
