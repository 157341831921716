import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { ProductionService } from '../production.service';
@Component({
  selector: 'app-create-jobshop-parties',
  templateUrl: './create-jobshop-parties.component.html',
  styleUrls: ['./create-jobshop-parties.component.css']
})
export class CreateJobshopPartiesComponent implements OnInit {
  createJob: FormGroup;
  activeCategory = 2;
  personId: any;
  partyIdArray: any[]=[];
  id: string;
  allrole: any;
  allroleArray: any[]=[];
  workEffortId: any;
  routingTaskList: any;
  routingTaskListArray: any[]=[];
  FromDate: string;
  ThroughDate: string;

  constructor(readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,
    readonly addSkillService: AddSkillService,
    readonly spinner: NgxSpinnerService,
    readonly productionService:ProductionService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) {
    this.createJob = this._FormBuilder.group({
      PartyID: [''],
      RoleTypeID: [''],
      WorkEffortID: [''],
      fromDate: [''],
      throughDate: [''],


    });

  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
 
  this._ActivatedRoute.queryParams.subscribe(params => {
    this.workEffortId = params["workEffortId"];

  });
  this.getPartyId();
  this.getRoleAllType();
  this.getProductionMaterialRoutingTaskIds();
  }
  getProductionMaterialRoutingTaskIds() {
    this.spinner.show();  
    this.productionService.getProductionMaterialRoutingTaskIds(this.workEffortId).subscribe(res => {
      this.routingTaskList = res.data;
      this.spinner.hide();
      for (const value of this.routingTaskList) {
        this.routingTaskListArray.push({
          label: value.workEffortId,
          value: value.workEffortId
        })
      }
    })
    
  }
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  
  onSubmit() {
    this.spinner.show();
    const FromDate = this.createJob.get('fromDate').value;
    this.FromDate = this.datePipe.transform(FromDate, "yyyy-MM-dd hh:mm:ss");
    const ThroughDate = this.createJob.get('throughDate').value;
    this.ThroughDate = this.datePipe.transform(ThroughDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate": this.FromDate,
      "partyId": this.createJob.value.PartyID,
      "roleTypeId": this.createJob.value.RoleTypeID,
      "thruDate": this.ThroughDate,
      "workEffortId":this.createJob.value.WorkEffortID,
    }
    this.productionService.postProductionParty(this.workEffortId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this._Router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId:this.workEffortId } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }

  cancelSubmit(){
    this._Router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId: this.workEffortId} })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}

