import { Component, OnInit } from '@angular/core';
declare var $: any;
import { AddEmployeeFormService } from './add-new-employment-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmployeeService } from '../employees/employess.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-add-new-employee',
  templateUrl: './add-new-employee.component.html',
  styleUrls: ['./add-new-employee.component.css']
})
export class AddNewEmployeeComponent implements OnInit {
  updateId: any;
  activeCategory = 2;
  show = false;
  countryId: any;
  stateData: any;
  countryArray: any[];
  maritalStatus: any[];
  status: any[];
  stateArray: any = [];
  internalOrganization: any;
  organizationArray: any = [];
  employeeForm: FormGroup;
  employeeDetails: any;
  editMode: boolean;
  array = [
    {
      label: 'Y',
      value: 'Y'
    },

    {
      label: 'N',
      value: 'N'
    }];
  gender = [
    {
      label: 'Male',
      value: 'M'
    },
    {
      label: 'Female',
      value: 'F'
    },
    {
      label: 'Transgender',
      value: 'T'
    }];
  id: any;
  constructor(
    readonly _FormBuilder: FormBuilder,
    public addEmployeeFormService: AddEmployeeFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly toastr: ToastrService,
    readonly employeeService: EmployeeService) {
    this.employeeForm = this._FormBuilder.group({
      accountNo: [''],
      address1: ['', [Validators.required]],
      address2: [''],
      areaCode: [''],
      billingRateLevel: [''],
      birthDate: [''],
      city: ['', [Validators.required]],
      contactNumber: [''],
      countryCode: [''],
      countryGeoId: [''],
      description: [''],
      emailAddress: [''],
      ethnicity: [''],
      extension: [''],
      firstName: ['', [Validators.required]],
      fromDate: [''],
      gender: [''],
      internalOrganization: [''],
      lastName: ['', [Validators.required]],
      maritalStatusEnumId: [''],
      middleInitial: [''],
      postalCode: ['', [Validators.required]],
      socialSecurityNumber: [''],
      stateProvinceGeoId: [''],
      statusId: [''],
      title: [''],
      usCitizen: ['']
    });
    this.status = [];
    this.countryArray = [];
    this.maritalStatus = [];
    this.editMode = false;
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getCountryList();
    this.getInternalOrganization();
    this.activatedRoute.queryParams.subscribe(params => [
      this.updateId = params['updateId']
    ])
    if (this.updateId) {
      this.show = true;
    }
    else {
      this.show = false;
    }
   
    if (this.updateId) {
      this.getEmployeeDetail();
    }
    this.addEmployeeFormService.add_employee_form = null;
    this.addEmployeeFormService.addEmployeeForm(this.addEmployeeFormService.add_employee_form);
    $('.right-arrow').click(function () {
      $(this).toggleClass('rotate-up');
    });
    this.getPartyStatus();
    this.employeeForm.controls.countryGeoId.valueChanges.subscribe(value => {
      if (value) {
        this.getStateList(value);
      }
    });
    this.getMaritalStatus();
  }
  getMaritalStatus(): void {
    this.spinner.show();
    this.employeeService.getMaritalStatus()
      .subscribe(data => {
        this.spinner.hide();
        this.maritalStatus = data.data.map(value => {
          return {
            label: value.description,
            value: value.enumId
          }
        });

      });
      
  }
  getPartyStatus(): void {
    this.spinner.show();
    this.employeeService.getPartyStatus()
      .subscribe(data => {
        this.spinner.hide();
        if (data.success) {
          this.status = data.data.map(value => {
            return {
              label: value.description,
              value: value.statusCode
            }
          });
        }
      });
     
  }

  getEmployeeDetail() {
    this.spinner.show();
    this.employeeService.getEmployeeById(this.updateId)
      .then(data => {
        if (data.success) {
          this.employeeDetails = data.data;
          this.editMode = true;
          this.spinner.hide();
          this.employeeForm.patchValue({
            ...data.data, ...data.data.address, ...data.data.telenumber, ...{
              birthDate: data.data.birthDate ? moment(data.data.birthDate).format('YYYY-MM-DD') : '',
              emailAddress: data.data.email.infoString
            }
          });
        }
      });
     
  }

  onUpdate() {
  }


  getCountryList() {
    this.spinner.show();
    this.employeeService.getCountry().subscribe(res => {
  
      var country = res.data.countryList;
      this.spinner.hide();
      this.spinner.hide();
      country.forEach(element => {
        this.countryArray.push({
          label: element.split(':')[0].trim(),
          value: element.split(':').pop().trim()
        });

      })

    })

  }


  getStateList(countryId: string) {
    this.spinner.show();
    this.employeeService.getState(countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        var splitState = stateLists.split(':');
        var stateIndex = splitState[0];
       
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(':').pop().trim()
        })
      })
    })

  }
  getInternalOrganization() {
    this.spinner.show();
    this.employeeService.internalOrganization().subscribe(res => {
      this.internalOrganization = res.data;
      this.spinner.hide();
      for (const value of this.internalOrganization) {
        this.organizationArray.push({
          label: value.groupName,
          value: value.partyId
        })
      }
    })
  }
  cancelSubmit() {
    this.router.navigate(['/hr/employees'])
  }
  onSubmit(): void {

    if (this.employeeForm.valid) {
      this.spinner.show();
      this.employeeService.createEmployeeData({
        ...this.employeeForm.value, ...{
          birthDate: this.employeeForm.value.birthDate ? moment(this.employeeForm.value.birthDate).format('YYYY-MM-DD HH:mm:ss') : ''
        }
      })
        .then(data => {
          if (data.success) {
            this.id=data.data.partyId
            this.toastr.success('Created');
            this.spinner.hide();
            this.employeeForm.reset();
          
              this.router.navigate(['/hr/employees/employee-details'], { queryParams: { employeeDetailId: this.id } })
           
          
            //this.cancelSubmit();
          }
        });
    }
  }
  legalName(fname,lname){
    if(fname && lname){
      this.activeCategory=3;
    }
    else{
      this.toastr.error("First Name and Last Name is required");
    }
  }
  selectStatus(status){
    if(status){
      this.activeCategory=4;
    }
    else{
      this.toastr.error("Status is required");
    }
  
  }
  selectAddress(address,city,postal){
    if(address && city && postal){
      this.activeCategory=5;
    }
    else{
      this.toastr.error("Address,City and Postal is required");
    }
  }
  selectContact(contact){
    if(contact){
      this.activeCategory=6;
    }
    else{
      this.toastr.error("Contact Number is required");
    }
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  statusInfo(fname,lname){
    if(fname && lname){
      this.activeCategory=3;
    }
    else{
      this.toastr.error("Fill Legal Name.");
    }
  }
  addressValid(status){
    if(status){
      this.activeCategory=4;
    }
    else{
      this.toastr.error("Fill Status Form.");
    }
  
  }
  contcatValid(address,city,postal){
    if(address && city && postal){
      this.activeCategory=5;
    }
    else{
      this.toastr.error("Fill Address Form.");
    }
  }
  additionalValida(contact){
    if(contact){
      this.activeCategory=6;
    }
    else{
      this.toastr.error("Fill Contact Form.");
    }
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}


