import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
    import { Location } from '@angular/common';
    import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'
import { ProductionService } from '../production.service';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-calender-list',
  templateUrl: './calender-list.component.html',
  styleUrls: ['./calender-list.component.css']
})
export class CalenderListComponent implements OnInit {
  activeCategoryMain=1;
  @ViewChild('closebutton') closebutton;
  createCalender:FormGroup;
  activeCategory=2;
  weekListArray: any[]=[];
  weekList: any;
  calendarWeekId: any;
  calendarId: any;
  description: any;
  show: boolean;
  products3: any;
  total = 0;
  rowNumber = 0;
  pageSize = 500;
  pageNo = 1;
  rows = 50;
  search = ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  financialList = [{
    "product": "1"
  }];
  FinAccountTypeList: any;
  FinAccountTypeListArray: any[] = [];
  smallerArray: { label: string; value: string; }[];
  greaterArray: { label: string; value: string; }[];
  ProdCalender: any;
  constructor(readonly spinner: NgxSpinnerService,
    readonly productionService:ProductionService,
    readonly toastr: ToastrService,  private _location: Location,
    readonly router: Router,
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
 
  
    readonly _ActivatedRoute: ActivatedRoute,
   
    readonly _ToastrService: ToastrService) {
      this.createCalender = this._FormBuilder.group({
        CalendarWeekID: [''],
        CalendarID:[''],
        Description:[''],
  
      });

  }

  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.show=false;
     this.getProdCalender();
     this.getProdCalenderWeekId();
  }
  

  getProdCalenderWeekId() {
    this.spinner.show();
   
    this.productionService.getProdCalenderWeekId().subscribe(res => {
      this.weekList = res.data;
    
      this.spinner.hide();
      for (const value of this.weekList) {
        this.weekListArray.push({
          label: value.description,
          value: value.calendarWeekId
        })
      }
    
    })
    
  }
  
  onSubmit() {
    this.spinner.show();
    
    const requestData = {
      "calendarId":  this.createCalender.value.CalendarID,
      "calendarWeekId":  this.createCalender.value.CalendarWeekID,
      "description":  this.createCalender.value.Description,

    }

    this.productionService.postProdCalender(requestData).subscribe((res: any) => {
     
      if (res.success) {
      
        this._ToastrService.success("Created Successfully");
        this.closebutton.nativeElement.click();
        this.getProdCalender();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  deleteProdCalender(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {  
        this.productionService.deleteProdCalender(id).subscribe((res: any) => {
          this.getProdCalender();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }})
  }
  getProdCalender() {
    this.spinner.show();
    this.productionService.getProdCalender(this.finSize).subscribe((res: any) => {
      this.ProdCalender = res.data;
      this.spinner.hide();
    })
  
  }
  Calenders() {
    this.router.navigate(["/production/calender-list"])

  }
  Week() {
    this.router.navigate(["/production/week-list"])

  }
 /*  createCalender() {
    this.router.navigate(["production/create-new-calender"])

  } */
  detailCalender(ecalendarId,description,calendarWeekId) {
    this.router.navigate(["production/detail-calender"],{ queryParams: { calendarId : ecalendarId,description:description,calendarWeekId:calendarWeekId}})

  }
  homeButton(){
    this.router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  updateCalender(ecalendarId,edescription,ecalendarWeekId) {
    this.router.navigate(["production/create-new-calender"],{ queryParams: { calendarId : ecalendarId,description:edescription,calendarWeekId:ecalendarWeekId}})

  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }



}


