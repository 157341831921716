import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/app.constant';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class InternalJobService {

  constructor(private http:HttpClient) { }

 getInternalJobList(job,jobList) {
   const getInternalJobList = URLS.getInternalJobPosting + "?pageSize=" + job.pageSize +"&pageNo=" + job.pageNo + "&search=" + job.search;
   return this.http.post(getInternalJobList,jobList);
 }
 getStatus() :Observable<any>{
  const getInternalJobList = URLS.statusJob;
  return this.http.get(getInternalJobList);
}
 deleteInternalJob(id) {
   const deleteInternalJob = URLS.deleteInternalJobPost.replace(":applicationId",id);
   return this.http.delete(deleteInternalJob);
  
 }





  
}
