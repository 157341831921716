<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Shipment Gateway Config</span>

               <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>

            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left: 9px; width: calc(103% - 5px); flex-wrap: wrap; display: flex; margin-bottom: 8px !important;">
                        <li [ngClass]="activeCat==1?'active':''" (click)="shipmentGatewayOpen()">
                            <a>Shipment Gateway</a>
                        </li>
                        <li [ngClass]="activeCat==2?'active':''" (click)="shipmentsOpen()">
                            <a>Shipments</a>
                        </li>
                    </ul>
                </div>
                <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                    <div class="w3-card-4 classCard">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: calc(103% - 4px) !important;
                                flex-wrap: wrap !important;
                                display: flex !important;">
                                <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)"><a>
                                        Shipment Gateway Config
                                    </a>
                                </li>
                                <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)"><a>
                                        Shipment Gateway Config Types</a>
                                </li>
                            </ul>
                        </div>
                        <div class=" bg-white color-grey">
                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100">
                                            <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                                <div class="w-100">
                                                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                        <div *ngIf="activeCategory==2">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling">
                                                                    Shipment Gateway Config
                                                                </h4>
                                                            </div>
                                                            <br>
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Shipment Gateway
                                                                                Config
                                                                                ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">

                                                                            <input type="email" class="form-control" id="exampleInputEmail1" name="shipmentConfigID" aria-describedby="emailHelp" [(ngModel)]="advanceSearch.shipmentConfigID" placeholder="Enter Shipment Gateway Config ID">
                                                                        </div>


                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Shipment Gateway
                                                                                Config
                                                                                Description</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" class="form-control" id="exampleInputEmail1" name="description" aria-describedby="emailHelp" [(ngModel)]="advanceSearch.description" placeholder="Enter description">
                                                                        </div>



                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Shipment Gateway
                                                                                Config Type
                                                                                ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" name="ConfigTypeID" optionlabel="label" [options]="ConfigTypeIDArray" [(ngModel)]="advanceSearch.ConfigTypeID" placeholder="Enter Shipment Gateway Config Type ID">
                                                                            </p-dropdown>
                                                                        </div>



                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div class="col-lg-12  main-submit-button" style="
                                                                           margin-left: -19%;">
                                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="FindShipmentGatewayConfig()">Find</button>&nbsp;

                                                                <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>

                                                            </div>

                                                            <div class="w3-card-4 classCard">
                                                                <header class="w3-container w3-blue">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling h4Margin">
                                                                            Shipment Gateway Config List
                                                                        </h4>

                                                                    </div>
                                                                </header>
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12 col-12">
                                                                        <div class="form-group">

                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="shipmentConfig" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    Shipment Gateway Config Type ID
                                                                                                    <p-sortIcon field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Shipment Gateway Config ID
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Shipment Gateway Config Description
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    <span >
                                                                                                        {{product.FindShipmentGatewayConfig.shipmentGatewayConfTypeId}}</span>

                                                                                                </div>
                                                                                            </td>

                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                {{product.FindShipmentGatewayConfig.shipmentGatewayConfigId}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}" (click)="detailPageShipment(product.FindShipmentGatewayConfig.shipmentGatewayConfTypeId,
                                                                                          product.FindShipmentGatewayConfig.shipmentGatewayConfigId,  product.FindShipmentGatewayConfig.description)"  class="account-button"  >
                                                                                                {{product.FindShipmentGatewayConfig.description}}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>

                                                                                <p class="paginate_data">
                                                                                    View per page </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>

                                                    <div *ngIf="activeCategory==3">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                Shipment Gateway Config Types
                                                            </h4>
                                                        </div>
                                                        <br>
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Shipment Gateway
                                                                            Config Type ID
        
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">

                                                                        <input type="email" class="form-control" id="exampleInputEmail" name="ShipmentGatewayConfigType" aria-describedby="emailHelp" [(ngModel)]="advanceSearchA.ShipmentGatewayConfigType" placeholder="Enter Shipment Gateway Config Type ID" [ngModelOptions]="{standlone:true}">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                        <label for="exampleInputEmail1">Shipment Gateway
                                                                            Config Description
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" name="ShipmentGatewayConfigTypeDescription" class="form-control" id="exampleInputEmail" [(ngModel)]="advanceSearchA.ShipmentGatewayConfigTypeDescription" aria-describedby="emailHelp" [ngModelOptions]="{standlone:true}" placeholder="Enter description">
                                                                    </div>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-12 main-submit-button" style="
                                                                           margin-left: -19%;">
                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="FindShipmentGatewayConfigTypes()">Find</button>&nbsp;

                                                            <button type="button" class="btn btn-danger ml-2" (click)="resetType()">Reset</button>

                                                        </div>

                                                        <div class="w3-card-4 classCard">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling h4Margin">
                                                                        Shipment Gateway Config Types List
                                                                    </h4>

                                                                </div>
                                                            </header>
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="shipmentConfigType" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                Parent Type ID
                                                                                                <p-sortIcon field="code">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'270px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Has Table
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'270px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Shipment Gateway Config Type ID

                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>


                                                                                        <th [ngStyle]="{'width':'270px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Shipment Gateway Config Type Description
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>



                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                <span  class="account-button"  >
                                                                                                    {{product.ShipmentGatewayConfigType.parentTypeId}}</span>

                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}"> {{product.ShipmentGatewayConfigType.hasTable}}
                                                                                        </td>

                                                                                        <td [ngStyle]="{'width':'180px'}"> {{product.ShipmentGatewayConfigType.shipmentGatewayConfTypeId}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'160px'}" (click)="detailShipmentTypes(product.ShipmentGatewayConfigType.parentTypeId,product.ShipmentGatewayConfigType.shipmentGatewayConfTypeId,
                                                                                        product.ShipmentGatewayConfigType.hasTable,
                                                                                        product.ShipmentGatewayConfigType.description)"> {{product.ShipmentGatewayConfigType.description}}
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br>


                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>