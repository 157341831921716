import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';


@Component({
  selector: 'app-content-permission',
  templateUrl: './content-permission.component.html',
  styleUrls: ['./content-permission.component.css']
})
export class ContentPermissionComponent implements OnInit {
  activeCategory=8;

  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  permissionForm: FormGroup;

  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    private _location: Location,
  )
  {
    this.permissionForm=this._FormBuilder.group({
      refresh:"",
    })
   }

  ngOnInit(): void {
    setTimeout(() => {
     const form = this.permissionForm;
     form.patchValue({
      refresh :"OFBIZDOCROOT"
     })
    }, 1000);
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  
  moveToCMS()
  {
    this._Router.navigate(['/content/cms/subsites']);

  }
  toCmsSubsites()
  {
    this._Router.navigate(['/content/cms/subsites']);

  }

  openPurposeOperation()
   {
    this._Router.navigate(['/content/content-setup/edit-content-purpose-operation']);
    
   }

   openContentOperation()
   {
    this._Router.navigate(['/content/content-setup/edit-content-operation']);

   }

   openAssociationPredicate()
   {
    this._Router.navigate(['/content/content-setup/edit-content-assoication-predicate']);

   }

   openTypeAttribute()
   {
    this._Router.navigate(['/content/content-setup/edit-content-type-attribute']);

   }

   openPurposeType()
   {
    this._Router.navigate(['/content/content-setup/edit-content-purpose-type']);

   }

   openAssociationType()
   {
    this._Router.navigate(['/content/content-setup/edit-content-association-type']);

   }

   openPermission()
   {
    this._Router.navigate(['/content/content-setup/permission']);

   }

   openContentType()
   {
    this._Router.navigate(['/content/content-setup']);

   }



  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });

    
}

ngOnDestroy(): void {
  this.spinner.hide();
    }

  changeactiveCategory(tab: number)
  {
    this.activeCategory = tab;
  
  }


}
