import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { AccountsService } from '../accounts/accounts.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-new-communication-crm',
  templateUrl: './new-communication-crm.component.html',
  styleUrls: ['./new-communication-crm.component.css']
})
export class NewCommunicationCrmComponent implements OnInit {
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  search= '';
  accountsList:any[]=[]
  relatedContact:any[]=[]
  rows = 50;
  activeCategory=2;
  activeCategoryValue=2;
  createCommEvent:FormGroup;
  opportunityIdArray: { label: string; value: string; }[];
  getInitailData: any;
  stausIdArray: any[]=[];
  comtypeIdArray: any[]=[];
  contentMMArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  roleTypeId: any;
  roleTypeIdArray: any[]=[];
  contactListId: any;
  contactListIdArray: any[]=[];
  datetimeEnded: string;
  datetimeStarted: string;
  parentCommEventArray: any[]=[];
  reasonenumArray: any[]=[];
  contactMech: any;
  contactMechArray: any[]=[];
  contactMechTypeArray: any[]=[];
  contactMechTo: any;
  contactMechToArray: any[]=[];
 
  constructor(readonly router: Router,
    readonly datePipe: DatePipe,
    private _location: Location,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly addSkillService: AddSkillService,
    readonly spinner: NgxSpinnerService,) { 
    this.createCommEvent = this._FormBuilder.group({
      commEventId: [''],
      parCommEventID: [''],
      commTypeId: [''],
      status:[''],
      partyFrom:[''],
      partyTo: [''],
      conMechId: [''],
      fromContactMechId: [''],
      toContactMechId:[''],
      roleTypeFrom:[''],
      roleTypeTo: [''],
      contactListId: [''],
      startdate: [''],
      finishDate:[''],
      subject:[''],
      reasonEnumId: [''],
      contentMimeTypeId: [''],
      content: [''],
      note:[''],
 
    });}
  ngOnInit(): void {
    this.getDropdownList();
    this.getPartyId();
    this.getRoleId();
    this.getContactList();
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  getDropdownList() {
    this.spinner.show();
   
    this.accountsService.getCrmInitialCommData().subscribe((res: any) => {
    const stausId = res.data.commEventstatus;
    const comtypeId=res.data.commEventTypes;
    const contentMM=res.data.mimeType;
    const parentCommEvent=res.data.parentCommEvent;
    const reasonenum=res.data.reasonEnum;
    const contactMechType=res.data.contactMechType;
    this.spinner.hide();
    for (const value of contactMechType) {
      this.contactMechTypeArray.push({
        label: value.description,
        value: value.contactMechTypeId
      })
    } 
    for (const value of reasonenum) {
      this.reasonenumArray.push({
        label: value.description,
        value: value.enumId
      })
    } 
    for (const value of parentCommEvent) {
      this.parentCommEventArray.push({
        label: value.communicationEventId,
        value: value.communicationEventId
      })
    } 
      for (const value of stausId) {
        this.stausIdArray.push({
          label: value.description,
          value: value.statusId
        })
      } 
      for (const value of comtypeId) {
        this.comtypeIdArray.push({
          label: value.description,
          value: value.communicationEventTypeId
        })
      } 
      for (const value of contentMM) {
        this.contentMMArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      } 
    })
     

    
   
  }
  
  getRoleId() {
    this.spinner.show();
    this.accountsService.getParty().subscribe(res => {
      this.roleTypeId = res.data.roleType;
      this.spinner.hide();
      for (const value of this.roleTypeId) {
        this.roleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  
  }
  contactEventMech(id) {
    this.spinner.show();
    this.accountsService.contactEventMech(id).subscribe(res => {
      this.contactMech = res.data;
      this.spinner.hide();
      for (const value of this.contactMech) {
        this.contactMechArray.push({
          label: value.contactMechId,
          value: value.contactMechId
        })
      }
    })
   
  }
  contactEventMechTo(id) {
    this.spinner.show();
    this.accountsService.contactEventMech(id).subscribe(res => {
      this.contactMechTo = res.data;
      this.spinner.hide();
      for (const value of this.contactMechTo) {
        this.contactMechToArray.push({
          label: value.contactMechId,
          value: value.contactMechId
        })
      }
    })
  
  }
  
  getContactList() {
    this.spinner.show();
    this.accountsService.getContactList().subscribe(res => {
      this.contactListId = res.data;
      this.spinner.hide();
      for (const value of this.contactListId) {
        this.contactListIdArray.push({
          label: value.description,
          value: value.contactListId
        })
      }
    })
  
  }
  
  onSubmit() {
    this.spinner.show();
    const fromActualdate=this.createCommEvent.get('startdate').value;
    this.datetimeStarted=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createCommEvent.get('finishDate').value;
    this.datetimeEnded=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "communicationEventId": '',
      "communicationEventTypeId": this.createCommEvent.value.commTypeId,
      "contactListId": this.createCommEvent.value.contactListId,
      "contactMechIdFrom": this.createCommEvent.value.fromContactMechId,
      "contactMechIdTo": this.createCommEvent.value.toContactMechId,
      "contactMechTypeId": this.createCommEvent.value.conMechId,
      "content": this.createCommEvent.value.content,
      "contentMimeTypeId": this.createCommEvent.value.contentMimeTypeId,
      "datetimeEnded": this.datetimeEnded,
      "datetimeStarted": this.datetimeStarted,
      "messageId": '',
      "note": this.createCommEvent.value.note,
      "parentCommEventId": this.createCommEvent.value.parCommEventID,
      "partyIdFrom": this.createCommEvent.value.partyFrom,
      "partyIdTo": this.createCommEvent.value.partyTo,
      "reasonEnumId": this.createCommEvent.value.reasonEnumId,
      "roleTypeIdFrom": this.createCommEvent.value.roleTypeFrom,
      "roleTypeIdTo":this.createCommEvent.value.roleTypeTo,
      "statusId":this.createCommEvent.value.status,
      "subject": this.createCommEvent.value.subject,
    }

    this.accountsService.postCommunicationNew(requestData).subscribe((res: any) => {
   
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    
  }
  
  
  cancelSubmit(){
    this._location.back();
  }
}
