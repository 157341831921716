import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2'; 
    import { Location } from '@angular/common';


@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 8;
  activeCategoryA = 3;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  roleForm: FormGroup;
  AddTaskForm: FormGroup;
  projectForm: FormGroup;
  ratesForm: FormGroup;
  updateProfile: FormGroup;
  createUserLogIn: FormGroup;
  addRelatedAccount: FormGroup;
  createNewContactInformation: FormGroup;
  partyAttributes: FormGroup;
  addCheckAccount: FormGroup;
  createBillingAccount: FormGroup;
  addNewCreditCard: FormGroup;
  createGiftCard: FormGroup;
  createEFTAcc: FormGroup;
  roleList: any;
  partyId: any;
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  createUser: FormGroup;
  PartyRole: any;
  RoleList: any;
  RoleListArray: any[]=[];
  Person: any;
  taxAuthPartyId: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  HidebtnRoleView: boolean=false;
  HidebtnTaskCrud: boolean=false;
  HidebtnTimeCurd: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly myContactsService: MyContactsService,
        private _location: Location,
        readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router
  ) {

   
    this.roleForm = this._FormBuilder.group({
      RoleTypeID: [''],
    });

  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.PROJECTMGR_ADMIN=="PROJECTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PROJECTMGR_VIEW=="PROJECTMGR_VIEW"){
      this.Hidebtn=true;
    }
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params["taxAuthPartyId"];
      this.taxAuthPartyId = params["taxAuthPartyId"];
    });

    this.viewroles();
    this.getProfileRoleList();
    this.getPerson();
  }

  getProfileRoleList() {
    this.spinner.show();
    this.myContactsService.getProfileRoleList().subscribe(res => {
      this.RoleList = res.data.node;
      this.spinner.hide();
      for (const value of this.RoleList) {
        this.RoleListArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
  viewroles() {
    this.myContactsService.viewroles( this.partyId).subscribe((res: any) => {
      this.roleList = res.data;
    })
  }
  getPerson() {
    this.spinner.show();
    this.accountsService.getPerson(this.partyId).subscribe(res => {
      this.Person = res.data;
      this.spinner.hide();
    })

  }
  deleteRole(roleTypeId) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
        if (result.value) {
          let req = {
            "partyId": this.partyId,
            "roleTypeId":roleTypeId,
            "view_INDEX_1": "0",
            "view_SIZE_1": "20"
          }
        this.accountsService.deleteprofileRole(req).subscribe((res: any) => {
          this.toastr.success("Delete Successfully");
          this.viewroles();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  submit(): void {
    this.spinner.show();
    const requestData = {

      "partyId": this.partyId,
      "roleTypeId": this.roleForm.value.RoleTypeID,

    }
   
        
    this.myContactsService.addRole(requestData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.viewroles();
        this.closebutton.nativeElement.click();
        this.roleForm.reset();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }


  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

  MyProfile() {
    this._Router.navigate(["/psa/my-profile"], { queryParams: { taxAuthPartyId: this.partyId } })
  }
  Preferences() {
    this._Router.navigate(["/psa/preferences-my-profile"], { queryParams: { taxAuthPartyId: this.partyId } })
  }
  Roles() {
    this._Router.navigate(["/psa/roles"], { queryParams: { taxAuthPartyId: this.partyId } })
  }
  Project() {
    this._Router.navigate(["/psa/project-my-profile"], { queryParams: { taxAuthPartyId: this.partyId } })
  }
  TasksProfile() {
    this._Router.navigate(["/psa/tasks-my-profile"], { queryParams: { taxAuthPartyId: this.partyId } })
  }
  Rates() {
    this._Router.navigate(["/psa/rates-my-profile"], { queryParams: { taxAuthPartyId: this.partyId } })
  }
  Revisions() {
    this._Router.navigate(["/psa/revisions-my-profile"], { queryParams: { taxAuthPartyId: this.partyId } })
  }


  Backlog(){
    this._Router.navigate(["/psa/product-backlog"])
  }
  Sprint(){
    this._Router.navigate(["/psa/sprints"])
  }
  MyWork(){
    this._Router.navigate(["/psa/my-work"])
  }
  Tasks(){
    this._Router.navigate(["/psa/tasks"])
  }
  OpenTest(){
    this._Router.navigate(["/psa/open-test"])
  }
  Revision(){
    this._Router.navigate(["/psa/find-task-revision"])
  }

}
