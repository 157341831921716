import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoginService } from './login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginReactiveService } from './login-service'
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';

import { NgxSpinnerService } from 'ngx-spinner';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { FormBuilder, FormGroup } from '@angular/forms';






@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginUser = {
    username: '',
    password: '',
    userTenantId: '',
  };
  value: any;
  public token: string;
  public userLoginId: string;
  webPos: any;
  posToken: any;
  productStoreId: any;
  posTerminalArray: { label: string; value: string; }[];
  posTerminalIdNew: any;
  posTerminalId: any;
  webPosloginForm: FormGroup;
  checkPOSToken: string;
  productStoreIdNew: string;
  posDataRecord: any;
  posUserId: any;
  userName: string;

  constructor(readonly loginService: LoginService,
    public loginReactiveService: LoginReactiveService, 
    readonly router: Router, 
    readonly toastr: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly accountsService: AccountsService,
    readonly _FormBuilder: FormBuilder,

    ) 
    { 
      this.posTerminalArray = 
    [
      {
        label: 'Pos Terminal 1',
        value: 'pos-1'
      },
      {
        label: 'Pos Terminal 2',
        value: 'pos-2'
      },
      {
        label: 'Pos Terminal 3',
        value: 'pos-3'
      },
    ];
      this.webPosloginForm = this._FormBuilder.group({
        username: [''],
        password: [''],
        webposTerminalId: ['']
      })
    }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(res =>{
      console.log("rescheck",res)
      this.webPos = res["webPos"];
    });
    this.loginReactiveService.login_form = null;
    this.loginReactiveService.loginForm(this.loginReactiveService.login_form);
  }

  



  loginuser() {
    this.loginService.loginDetails(this.loginUser).subscribe((res) => {
      this.userName=this.loginUser.username;
      this.loginUser = res;
      this.value = res.data.token;
      if (res) {
        console.clear();
        console.log('res.data.userPermission', res.data.permissions);
        console.log('res', res);

        localStorage.setItem('userPermissions', JSON.stringify(res.data.permissions));
        localStorage.setItem('token', (this.value));
        localStorage.setItem('tenantId', (res.data.userTenantId ? res.data.userTenantId : null));
        localStorage.setItem('partyId', (res.data.userLogin.partyId));
        localStorage.setItem('email', (res.data.email));
        localStorage.setItem('adminSecurity', (res.data.securityGroups[0]));
        localStorage.setItem('userLoginId', (res.data.userLogin.userLoginId));
        localStorage.setItem('fmsRole', (res.data.userLogin.fmsRole ? res.data.userLogin.fmsRole : null));

        this.toastr.success("Login Successfully");
        this.token = localStorage.getItem('token');
        //this.router.navigate(['./myPortal']);

         if(res.data.userLogin.fmsRole=="FMS_ADMIN"){
          this.router.navigate(['./myPortal']);
         }
         else if(res.data.userLogin.fmsRole=="FMS_TECHNICIAN"){
          this.router.navigate(['./fsm/fsm-technician']);
         }
         else if(res.data.userLogin.partyId=="admin"){
          this.router.navigate(['./myPortal']);
         }
         else if(res.data.userLogin.fmsRole=="FMS_CUSTOMER_COM"||"FMS_CUSTOMER_RES"){
          this.router.navigate(['./fsm/fsm-customer']);
         }
         else
         {
          this.router.navigate(['./myPortal']);
         }
      }
      else if (res.success == false) {
        this.toastr.error("Login failed, display error to user");
      }
    }, (err) => {
      this.toastr.error(err.error.message);
    }
    );
  }
  getPermission() {
    this.spinner.show();
  
    this.accountsService.getPermission(this.userName).subscribe(res => {
      this.spinner.hide();
      if(res.success){
        localStorage.setItem('userPermissions', JSON.stringify(res.data));
      }

     
    })
  
  }

 
  LoginWebPos(): void {
    this.spinner.show();
    const formData = {
      "password": this.webPosloginForm.value.password,
      "posTerminalId": this.webPosloginForm.value.webposTerminalId,
      "username": this.webPosloginForm.value.username,
    }
    this.accountsService.LoginWebPos(formData).subscribe((res: any) => { 
      console.log("webposloginresponse",res);
      if (res.success) {
        this.toastr.success("POS Login Successfully");
        this.posToken = res.data.token;
        this.userLoginId = res.data.userLogin.userLoginId;
        this.posUserId=res.data.userLogin.userLoginId
        this.posTerminalId = this.webPosloginForm.value.webposTerminalId
        this.productStoreId = res.data.productStoreId[0].productStoreId;
        localStorage.setItem('posToken', (this.posToken));
        localStorage.setItem('token', (this.posToken));
        localStorage.setItem('posUserId', (this.posUserId));
        localStorage.setItem('posTerminalId', this.posTerminalId);
        localStorage.setItem('productStoreId', this.productStoreId);
        localStorage.setItem('userLoginId', (this.userLoginId));
        this.router.navigate(["/webpos/control/main"],{queryParams:{ posTerminalId :  this.posTerminalId, productStoreId:this.productStoreId}});
        this.spinner.hide();
        this.setIntrvl();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }

  setIntrvl(){
    setInterval(() => 
    this.getVerifyToken(),1500000);
  }

  getVerifyToken(): void {
    const requestData ={
      "authToken": this.posToken,
      "userLoginId": this.userLoginId
      }
    this.accountsService.getVerifyToken(requestData).subscribe((res: any) => { 
      console.log("posverfifytoken",res);
      this.posDataRecord = res.data[0];
      localStorage.setItem('posDataRecord', (this.posDataRecord))
      if (res.data[0].VerifyToken == "Token is expired") {
        this.router.navigate(["/login"],{queryParams:{ webPos :  "webPos"}});
        this.toastr.success("POS Token is expired");
        localStorage.removeItem('token');
        localStorage.removeItem('posToken');
        localStorage.removeItem('productStoreId');
        localStorage.removeItem('posTerminalId');
      }
    }, (err) => {
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }


  ngOnDestroy(): void {

  }
}
