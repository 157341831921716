<div class="container-fluid main-container-wrapper">
    
    <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
        <span class="color-black pl-1 titlePanels">Receivables</span>
        <span>
             <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
             <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
         </span> 
     </div>

    <div class=" bg-white color-grey">
     
        <div class="w3-card-4 classCard classCardType" >
            
       
        <div class="container-fluid">
            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                <li [ngClass]="activeCategory==4?'active':''" (click)="mainReceive()"><a>
                    Receivables Summary</a></li>
                <li [ngClass]="activeCategory==1?'active':''" (click)="salesInvoice()"><a>
                    Sales Invoice </a></li>
                <li [ngClass]="activeCategory==2?'active':''" (click)="receivePayment()"><a>
                    Receive Payments</a></li>
                    <li [ngClass]="activeCategory==3?'active':''" (click)="receiveGroupPayments()"><a>
                        Receive Group Payments</a></li>
            </ul>
        </div>

        <div class="create-new-leade mt-2">
            <div class="container-fluid">
              
                   
                                <div class="panel-group">
                                    <div *ngIf="activeCategory==4">
                                        <div class="w3-card-4 classCard">
                                            <header class="w3-container w3-blue">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling h4Margin">
                                                        Past Due Invoices: ({{pastDueInvoicestotalAmount}})
                                                    </h4>
            
                                                </div>
                                            </header>
                                            <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
            
                                                        <div class="card own-account-table">
                                                            <p-table [value]="getApAccount" [paginator]="true" [rows]="rows"
                                                                scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
            
                                                                                Invoice ID <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">	Invoice Type<p-sortIcon
                                                                                    field="name"></p-sortIcon>
                                                                            </div>
                                                                        </th>
            
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="quantity">
                                                                            <div style="color: white;">Invoice Date <p-sortIcon
                                                                                    field="quantity"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Status
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Description<p-sortIcon
                                                                                    field="price"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> From Party ID	<p-sortIcon
                                                                                    field="price"></p-sortIcon>
                                                                            </div>
            
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                            <div style="color: white;">To Party ID	<p-sortIcon
                                                                                    field="price"></p-sortIcon>
                                                                            </div>
            
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                            <div style="color: white;">Due Date	
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                            <div style="color: white;">Total	
                                                                                <p-sortIcon
                                                                                    field="price"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                            <div style="color: white;">Outstanding amount
                                                                                <p-sortIcon
                                                                                    field="price"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}"
                                                                           >
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                <span class="account-button">{{product.invoiceId}}</span>
                                                                                
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.invoiceType}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">{{product.invoiceDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.status}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.description}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.fromParty}}   {{product.partyIdFrom}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.toParty}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.dueDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                        </td>
                                                                      
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.total}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.outstandingAmount}}
                                                                        </td>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
            
                                        <div class="w3-card-4 classCard mt-5">
                                            <header class="w3-container w3-blue">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling h4Margin">
                                                        Invoices Due Soon: ()
                                                    </h4>
            
                                                </div>
                                            </header>
                                            <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="invoicesDueSoon" [paginator]="true" [rows]="rows"
                                                                scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
            
                                                                                Invoice ID <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">	Invoice Type<p-sortIcon
                                                                                    field="name"></p-sortIcon>
                                                                            </div>
                                                                        </th>
            
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="quantity">
                                                                            <div style="color: white;">Invoice Date <p-sortIcon
                                                                                    field="quantity"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Status
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Description<p-sortIcon
                                                                                    field="price"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> From Party ID	<p-sortIcon
                                                                                    field="price"></p-sortIcon>
                                                                            </div>
            
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                            <div style="color: white;">To Party ID	<p-sortIcon
                                                                                    field="price"></p-sortIcon>
                                                                            </div>
            
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                            <div style="color: white;">Due Date	
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                            <div style="color: white;">Total
                                                                                <p-sortIcon
                                                                                    field="price"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                            <div style="color: white;">Outstanding amount
                                                                                <p-sortIcon
                                                                                    field="price"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}"
                                                                           >
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                <span class="account-button">{{product.invoiceId}}</span>
                                                                                
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.invoiceType}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">{{product.invoiceDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.status}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.description}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.fromParty}}   {{product.partyIdFrom}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.toParty}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.dueDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                        </td>
                                                                      
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.total}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.outstandingAmount}}
                                                                        </td>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                 


                                </div>
                          
            
            </div>
        </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 