<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Sales</span>
                <span>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2"
                        (click)="toDashboard()">Home</button>
                    <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                </span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                           <!-- <li [ngClass]="activeCategories==8?'active':''" (click)="toLead()"><a>
                                    Leads</a></li>
                            <li [ngClass]="activeCategories==2?'active':''" (click)="toContact()"><a>
                                    Contact</a></li> -->
                            <li [ngClass]="activeCategories==1?'active':''" (click)="toAccount()"><a>
                                    Account</a></li>
                            <!-- <li [ngClass]="activeCategories==3?'active':''" (click)="toOpportunity()"><a>
                                    Opportunity</a></li>
                            <li [ngClass]="activeCategories==9?'active':''" (click)="toTasks()"><a>
                                    Tasks</a></li>
                            <li [ngClass]="activeCategories==7?'active':''" (click)="toCases()"><a>
                                    Cases</a></li>



                            <li [ngClass]="activeCategories==4?'active':''" (click)="toActivities()"><a>
                                    Activities Campaign</a></li>

                            <li [ngClass]="activeCategories==10?'active':''" (click)="toForecast()"><a>
                                    Forecast </a></li>
                            <li [ngClass]="activeCategories==11?'active':''" (click)="toDocuments()"><a>
                                    Documents </a></li>
                            <li [ngClass]="activeCategories==12?'active':''" (click)="toNotes()"><a>
                                    Notes </a></li>
                            <li [ngClass]="activeCategories==13?'active':''" (click)="toCalendar()"><a>
                                    Calendar </a></li>
                                    <li [ngClass]="activeCategories==14?'active':''" (click)="toEmails()"><a>
                                        Emails </a></li>

                            <li [ngClass]="activeCategories==5?'active':''" (click)="toQuotes()"><a>
                                    Quotes </a></li>

                            <li [ngClass]="activeCategories==6?'active':''" (click)="toSalesOrder()"><a>
                                    Sales order</a></li>  -->




                        </ul>
                    </div>



                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                    <div>

                                        <div class="w3-card-4 w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                            style="background:#F5F7FA!important; padding: 4px !important;white-space: nowrap;">
                                                            <span style="margin-left: 12px!important;"> Find
                                                                Customer</span>
                                                            <button type="button"
                                                                class="btn btn-outline-secondary submit-btn ml-2"
                                                                data-toggle="modal" data-target="#exampleModalCenter"
                                                                style="margin-left: 1% !important">Create New
                                                                Customer</button>

                                                            <button type="button"
                                                                routerLink="/crm/accounts/importAccount"
                                                                class="btn btn-outline-secondary bg-brown "
                                                                style="width: 100px;margin-left: 51%;">Import</button>
                                                            <button type="button" (click)="exportAccount()"
                                                                class="btn btn-danger "
                                                                style="width: 100px;">Export</button>
                                                            <button type="button" class="accordion-button  collapsed"
                                                                data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                                style="margin-left: 0%!important; padding-top: 11px !important;
                                                        padding-bottom: 11px !important;"></button>

                                                        </div>

                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse"
                                                        data-bs-parent="#myAccordion">
                                                        <div class="card-body">

                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Customer
                                                                                Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown styleClass="small-dropdown"
                                                                                filter="true"
                                                                                (ngModelChange)="onGroupName(groupNameSearchType)"
                                                                                [(ngModel)]="groupNameSearchType"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                [options]="opportunityIdArray"
                                                                                optionlabel="label">

                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" [(ngModel)]="groupName"
                                                                                name="groupName"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                class="form-control small-input"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Customer Name">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Customer
                                                                                ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown styleClass="small-dropdown"
                                                                                filter="true"
                                                                                (ngModelChange)="onPartyId(partyIdSearchType)"
                                                                                [(ngModel)]="partyIdSearchType"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                [options]="opportunityIdArray"
                                                                                optionlabel="label">

                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"
                                                                                [(ngModel)]="partyIdData"
                                                                                class="form-control small-input"
                                                                                name="partyId"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Customer ID">
                                                                        </div>

                                                                    </div>
                                                                </div>


                                                            </div>

                                                            <div class="col-lg-12 col-12 main-submit-button"
                                                                style="margin-left: -38%;"><button
                                                                    _ngcontent-cta-c128="" type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="getAccountList()">Find</button>
                                                                <button type="submit" class="btn btn-danger ml-2"
                                                                    (click)="resetFilter()">Reset</button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <!-- <div class="w3-card-4 classCard">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text" style="width: 11%!important;">Find Customer</p>
                                                <button type="button" class="btn btn-outline-secondary " class="btn btn-secondary submit-btn" data-toggle="modal" data-target="#exampleModalCenter" style="margin-left: -2% !important">Create New Customer</button>
        
                                                <button type="button" routerLink="/crm/accounts/importAccount" class="btn btn-outline-secondary bg-brown " style="width: 100px;margin-left: 57%;">Import</button>
                                                <button type="button" (click)="exportAccount()" class="btn btn-danger " style="width: 100px;">Export</button>
                                            
                                            </div>
                                            <br>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                            <label for="exampleInputEmail1">Customer Name</label>
                                                        </div>
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown styleClass="small-dropdown" filter="true" (ngModelChange)="onGroupName(groupNameSearchType)" [(ngModel)]="groupNameSearchType" [ngModelOptions]="{standalone:true}" [options]="opportunityIdArray" optionlabel="label">
        
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" [(ngModel)]="groupName" name="groupName" [ngModelOptions]="{standalone:true}" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Customer Name">
                                                        </div>
                                                        <div class="col-lg-3 form-group rightFormA">
                                                            <label for="exampleInputEmail1">Customer ID</label>
                                                        </div>
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown styleClass="small-dropdown" filter="true" (ngModelChange)="onPartyId(partyIdSearchType)" [(ngModel)]="partyIdSearchType" [ngModelOptions]="{standalone:true}" [options]="opportunityIdArray" optionlabel="label">
        
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" [(ngModel)]="partyIdData" class="form-control small-input" name="partyId" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Customer ID">
                                                        </div>
        
                                                    </div>
                                                </div>
        
        
                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -38%;"><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="getAccountList()">Find</button>
                                                <button type="submit" class="btn btn-danger ml-2" (click)="resetFilter()">Reset</button>
                                            </div>
                                        </div> -->
                                        <br>

                                        <div class="w3-card-4 classCard">
                                            <header class="w3-container w3-blue">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling h4Margin">
                                                        Customers List
                                                    </h4>

                                                </div>
                                            </header>
                                            <div
                                                class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">

                                                        <div class="card own-account-table">
                                                            <p-table [value]="accountsList" [paginator]="true"
                                                                [rows]="rows" scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                Customer ID
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">Email Address
                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                            </div>
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="quantity">
                                                                            <div style="color: white;">Customer Name
                                                                                <p-sortIcon field="quantity">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> No. of Employess
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Phone Number
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> City
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>

                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">Country
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>

                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">Related Contact
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">Created At
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}"
                                                                            (click)="accountDetails(product.partyId)">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span class="account-button">
                                                                                    {{product.partyId}}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.email
                                                                            !==null?product.email?.infoString:""}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.groupName}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.numEmployees
                                                                            !==null?product.numEmployees:""}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.telenumber
                                                                            !==null?product.telenumber?.countryCode:""}}
                                                                            {{product.telenumber
                                                                            !==null?product.telenumber?.areaCode:""}}
                                                                            {{product.telenumber
                                                                            !==null?product.telenumber?.contactNumber:""}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.address
                                                                            !==null?product.address.city:""}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.address
                                                                            !==null?product.address.countryGeoId:""}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div
                                                                                *ngFor="let data of product.relatedContacts; first as isFirst">
                                                                                <span *ngIf="isFirst">
                                                                                    {{data.partyIdTo}}
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.createdStamp | date:'yyyy-MM-dd
                                                                            HH:mm:ss'}}
                                                                        </td>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Customers </span>

            </div>
            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="w3-card-4 classCard">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <p class="h-text" style="width: 10%!important;">Find Customer</p>
                                        <button type="button" class="btn btn-outline-secondary " class="btn btn-secondary submit-btn" data-toggle="modal" data-target="#exampleModalCenter">Create New Customer</button>

                                        <button type="button" routerLink="/crm/accounts/importAccount" class="btn btn-outline-secondary bg-brown " style="width: 100px;margin-left: 59%;">Import</button>
                                        <button type="button" (click)="exportAccount()" class="btn btn-danger " style="width: 100px;">Export</button>
                                    </div>
                                    <br>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Customer Name</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown styleClass="small-dropdown" filter="true" (ngModelChange)="onGroupName(groupNameSearchType)" [(ngModel)]="groupNameSearchType" [ngModelOptions]="{standalone:true}" [options]="opportunityIdArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" [(ngModel)]="groupName" name="groupName" [ngModelOptions]="{standalone:true}" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Customer Name">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Customer ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown styleClass="small-dropdown" filter="true" (ngModelChange)="onPartyId(partyIdSearchType)" [(ngModel)]="partyIdSearchType" [ngModelOptions]="{standalone:true}" [options]="opportunityIdArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" [(ngModel)]="partyIdData" class="form-control small-input" name="partyId" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Customer ID">
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -38%;"><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="getAccountList()">Find</button>
                                        <button type="submit" class="btn btn-danger ml-2" (click)="resetFilter()">Reset</button>
                                    </div>
                                </div><br>
                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Customers List
                                            </h4>

                                        </div>
                                    </header>
                                    <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">

                                                <div class="card own-account-table">
                                                    <p-table [value]="accountsList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                </div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                        </p-checkbox>
                                                                        Customer ID
                                                                        <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                    <div style="color: white;">Email Address
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                </th>

                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                    <div style="color: white;">Customer Name
                                                                        <p-sortIcon field="quantity"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> No. of Employess
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Phone Number
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> City
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;">Country
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;">Related Contact
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;">Created At
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'170px'}" (click)="accountDetails(product.partyId)">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                </div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                        </p-checkbox>
                                                                        {{product.partyId}}
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.email !==null?product.email?.infoString:""}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">{{product.groupName}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.numEmployees !==null?product.numEmployees:""}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.telenumber !==null?product.telenumber?.countryCode:""}} {{product.telenumber !==null?product.telenumber?.areaCode:""}} {{product.telenumber !==null?product.telenumber?.contactNumber:""}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.address !==null?product.address.city:""}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.address !==null?product.address.countryGeoId:""}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    <div *ngFor="let data of product.relatedContacts; first as isFirst">
                                                                        <span *ngIf="isFirst">
                                                                            {{data.partyIdTo}}
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.createdStamp | date:'yyyy-MM-dd HH:mm:ss'}}
                                                                </td>
                                                        </ng-template>
                                                    </p-table>
                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div> -->

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span *ngIf="!this.show" class="color-black pl-1"> Create-Customer</span>
                <span *ngIf="this.show" class="color-black pl-1"> Update-Customer</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        Name & Address</a></li>
                                <li [ngClass]="activeCategory==3?'active':''"><a>
                                        Additional Info</a></li>
                                <li [ngClass]="activeCategory==4?'active':''"><a>
                                        Job Info</a></li>
                            </ul>
                        </div>

                        <div *ngIf="activeCategory==2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="accountForm" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="city">Customer Type<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3 dropdowns">
                                                        <p-dropdown styleClass="small-dropdown" [options]="customerType"
                                                            placeholder="Customer Type" filter="true"
                                                            formControlName="customerType">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormAA" *ngIf="show">
                                                        <label for="partyId">Party Id</label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="show">
                                                        <input type="text" class="form-control small-input"
                                                            id="exampleInputEmail1" [value]="partyId" readonly>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Company Name<span
                                                                style="color:red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input formControlName="groupName" type="email"
                                                            class="form-control small-input" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Company Name">

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="salutation">Salutation</label>
                                                    </div>
                                                    <div class="col-lg-3 dropdowns">
                                                        <p-dropdown styleClass="small-dropdown"
                                                            [options]="salutationArray" placeholder="Salutation"
                                                            filter="true" formControlName="title">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="salutation">First Name</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            id="exampleInputEmail1" placeholder="First Name"
                                                            formControlName="firstName">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="salutation">Last Name</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            id="exampleInputEmail1" placeholder="Last Name"
                                                            formControlName="lastName">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="countryCode">Country Code</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            id="exampleInputEmail1" placeholder="Country Code"
                                                            formControlName="countryCode">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="salutation">Area Code</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            id="exampleInputEmail1" placeholder="Area Code"
                                                            formControlName="areaCode">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12" *ngIf="!show">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="salutation">Contact</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            id="exampleInputEmail1" placeholder="Contact"
                                                            formControlName="contactNumber">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="salutation">Extension</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            id="exampleInputEmail1" placeholder="Extension"
                                                            formControlName="extension">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label style="font-size: 10px;" for="exampleInputEmail1">Number
                                                            of
                                                            Employees</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="numEmployees"
                                                            class="form-control small-input" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter No of Employess">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Office Site Name</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="officeSiteName"
                                                            class="form-control small-input" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Office Site Name">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="emailAddress">Email Address</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            id="exampleInputEmail1" placeholder="Email Address"
                                                            formControlName="emailAddress">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="address1">Address 1<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            id="exampleInputEmail1" placeholder="Address 1"
                                                            formControlName="address1">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12" *ngIf="!show">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="address1">Address 2<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            id="exampleInputEmail1" placeholder="Address 2"
                                                            formControlName="address2">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="city">City<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            id="exampleInputEmail1" placeholder="city"
                                                            formControlName="city">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12" *ngIf="!show">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="city">Country<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3 dropdowns">
                                                        <p-dropdown styleClass="small-dropdown" [options]="array"
                                                            placeholder="Select Country" formControlName="countryGeoId"
                                                            filter="true">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="city">State<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3 dropdowns">
                                                        <p-dropdown styleClass="small-dropdown" [options]="stateArray"
                                                            placeholder="State" filter="true"
                                                            formControlName="stateProvinceGeoId">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="pinCode">Pin Code<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            id="exampleInputEmail1" placeholder="Pin Code"
                                                            formControlName="postalCode">
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12" *ngIf="show">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="revenue">Annual Revenue</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            placeholder="Annual Revenue" id="exampleInputEmail1"
                                                            formControlName="annualRevenue">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA ">
                                                        <label for="desc">Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            id="exampleInputEmail1" placeholder="Description"
                                                            formControlName="description">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12" *ngIf="show">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="externalID">External Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            id="exampleInputEmail1" placeholder="External Id"
                                                            formControlName="externalId">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="logoImage">Logo Image Url</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            id="exampleInputEmail1" placeholder="Logo Image Url"
                                                            formControlName="logoImageUrl">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12" *ngIf="show">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="currency">Preferred Currency Uom ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown styleClass="small-dropdown" [options]="currencyList"
                                                            placeholder="Preferred Currency Uom ID"
                                                            formControlName="preferredCurrencyUomId"></p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="statusId">Status ID</label>
                                                    </div>
                                                    <div class="col-lg-3 dropdowns">
                                                        <p-dropdown styleClass="small-dropdown" [options]="statusList"
                                                            placeholder="Status ID" formControlName="statusId">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>

                                        <div class="main-submit-button" style="margin-right: 13%;">
                                            <button type="submit" (click)="createAccount()" *ngIf="!show"
                                                class="btn btn-secondary submit-btn">Continue</button>
                                            <button type="submit" (click)="updateAccount()" *ngIf="show"
                                                class="btn btn-secondary submit-btn">Update</button>



                                        </div>

                                    </form>
                                </div>

                            </div>

                        </div>
                        <div *ngIf="activeCategory==3">
                            <div class="container-fluid">
                                <div class="row">

                                    <form class="w-100" [formGroup]="additionalDetailForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormD">
                                                        <label for="exampleInputEmail1">Type</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Type"
                                                            id="exampleInputEmail1" formControlName="infoType">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Terms</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Terms"
                                                            id="exampleInputEmail1" formControlName="terms">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormD">
                                                        <label for="exampleInputEmail1">Rep. </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Rep"
                                                            id="exampleInputEmail1" formControlName="rep">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Price Level </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            placeholder="Price Level" formControlName="priceLevel">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormD">
                                                        <label for="exampleInputEmail1">Preferred Send Method
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            placeholder="Preferred Send Method"
                                                            formControlName="prefeferredSendMethod">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Resale No. </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Resale No."
                                                            id="exampleInputEmail1" formControlName="resaleNo">
                                                    </div>



                                                </div>
                                            </div>


                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: -4%;">
                                        <!-- <button type="submit" *ngIf="editMode" (click)="updateAdd()" class="btn btn-secondary submit-btn">Update</button> -->
                                        <button type="submit" *ngIf="!editMode" (click)="submitAdd()"
                                            class="btn btn-secondary submit-btn">Continue</button>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="activeCategory==4">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="jobInfoForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Job Status</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Job Status"
                                                            id="exampleInputEmail1" formControlName="jobStatus">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Start Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" placeholder="Start Date"
                                                            id="exampleInputEmail1" formControlName="startDate">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Projected End </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            placeholder="Projected End"
                                                            formControlName="projectedEndDate">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">End Date </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" placeholder="End Date"
                                                            id="exampleInputEmail1" formControlName="endDate">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            placeholder="Description" formControlName="description">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Job Type </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Job Type"
                                                            id="exampleInputEmail1" formControlName="jobType">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Product Type </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            placeholder="Product Type" formControlName="productType">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Service Type </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            placeholder="Service Type" formControlName="serviceType">
                                                    </div>


                                                </div>
                                            </div>


                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 71%;">
                                        <button type="submit" *ngIf="editMode" (click)="updateJob()"
                                            class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!editMode" (click)="submit()"
                                            class="btn btn-secondary submit-btn">Submit</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <br> <br> <br> <br>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>