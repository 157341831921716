import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
    import { Location } from '@angular/common';
    import { ToastrService } from 'ngx-toastr';
import { ProductionService } from '../production.service';
@Component({
  selector: 'app-create-exception-day',
  templateUrl: './create-exception-day.component.html',
  styleUrls: ['./create-exception-day.component.css']
})
export class CreateExceptionDayComponent implements OnInit {
  createDay:FormGroup;
  activeCategory=2;
  calendarId: any;
  exceptionday: string;
  exceptionDateStartTime: any;
  description: any;
  exceptionCapacity: any;
  usedCapacity: any;
  show: boolean;

  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,  private _location: Location,
    readonly productionService:ProductionService,
    readonly spinner: NgxSpinnerService,
  
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createDay = this._FormBuilder.group({
        exceptionday: [''],
        ManufacturingCalendarCapacity:[''],
        ManufacturingUsedCapacity: [''],
        Description:['']
      
       
       
      });
    
  }
 
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.calendarId = params["calendarId"];
      this.exceptionDateStartTime = params["exceptionDateStartTime"];
      this.description = params["description"];
      this.exceptionCapacity = params["exceptionCapacity"];
      this.usedCapacity = params["usedCapacity"];
    });
    if(this.exceptionDateStartTime) {
      this.show=true;
      setTimeout(() => {
        const formValue = this.createDay;
      formValue.patchValue({
        exceptionday:this.datePipe.transform(this.exceptionDateStartTime,"yyyy-MM-dd"),
        ManufacturingCalendarCapacity:this.exceptionCapacity,
        ManufacturingUsedCapacity:  this.usedCapacity,
        Description:this.description

      })
      }, 3000);
     
    }
    else {
      this.show = false;
    }
  }

  onSubmit() {
    this.spinner.show();
  const completeDate = this.createDay.get('exceptionday').value;
    this.exceptionday = this.datePipe.transform(completeDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "description": this.createDay.value.Description,
      "exceptionCapacity": this.createDay.value.ManufacturingCalendarCapacity,
      "exceptionDateStartTime": this.exceptionday,
      "usedCapacity": this.createDay.value.ManufacturingUsedCapacity,
    }
    this.productionService.postExceptionDay(this.calendarId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this._Router.navigate(["production/detail-calender"],{ queryParams: { calendarId : this.calendarId}})
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  homeButton(){
    this._Router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "description": this.createDay.value.Description,
      "exceptionCapacity": this.createDay.value.ManufacturingCalendarCapacity,
      "exceptionDateStartTime":  this.exceptionDateStartTime,
      "usedCapacity": this.createDay.value.ManufacturingUsedCapacity,
    }
    this.productionService.updateExceptionDay(this.calendarId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this._Router.navigate(["production/detail-calender"],{ queryParams: { calendarId : this.calendarId}})
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
 // cancelSubmit(){
 //   this._Router.navigate(["production/detail-calender"],{ queryParams: { calendarId : this.calendarId}})
 // }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}


