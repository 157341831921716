import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CreateOppourtunityModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        accountPartyId:(data && data.accountPartyId) ? data.accountPartyId : null,
        closeDate:(data && data.closeDate) ? data.closeDate : null,
        currencyId:(data && data.currencyId) ? data.currencyId : null,
        description:(data && data.description) ? data.description : null,
        estimatedamount:(data && data.estimatedamount) ? data.estimatedamount : null,
        initialStage:(data && data.initialStage) ? data.initialStage : null,
        leadPartyId:(data && data.leadPartyId) ? data.leadPartyId : null,
        marketing_campaign:(data && data.marketing_campaign) ? data.marketing_campaign : null,
        nextStep:(data && data.nextStep) ? data.nextStep : null,  
        nextStepDate:(data && data.nextStepDate) ? data.nextStepDate : null,
        opportunityName:(data && data.opportunityName) ? data.opportunityName : null,
        oppurtunityId:(data && data.oppurtunityId) ? data.oppurtunityId : null,
        probablity:(data && data.probablity) ? data.probablity : null,
        source:(data && data.source) ? data.source : null,
        type:(data && data.type) ? data.type : null,
     
    });
   
   
    return this.getFormGroup(form_data);
  }

  
  getFormGroup(data:any): any {
   return ({
    accountPartyId: new FormControl(data.accountPartyId),
    closeDate: new FormControl(data.closeDate),
    currencyId: new FormControl(data.currencyId, [Validators.required]),
    description: new FormControl(data.description),
    estimatedamount: new FormControl(data.estimatedamount),
    initialStage: new FormControl(data.initialStage, [Validators.required]),
    leadPartyId: new FormControl(data.leadPartyId),
    marketing_campaign: new FormControl(data.marketing_campaign),
    nextStep: new FormControl(data.nextStep),
    nextStepDate: new FormControl(data.nextStepDate),
    opportunityName: new FormControl(data.opportunityName),
    oppurtunityId: new FormControl(data.oppurtunityId),
    probablity: new FormControl(data.probablity, [Validators.required]),
    source: new FormControl(data.source),
    type: new FormControl(data.type),
    
   
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
