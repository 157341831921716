import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
@Component({
  selector: 'app-request-list-effort',
  templateUrl: './request-list-effort.component.html',
  styleUrls: ['./request-list-effort.component.css']
})
export class RequestListEffortComponent implements OnInit {

  @ViewChild('closebutton') closebutton;
  activeCategory = 5;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  RequestListsdata: any;
  constructor( public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    private _location: Location,
    
    readonly myContactsService: MyContactsService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router) { }

  ngOnInit(): void {
    this.getRequestLists();
    
  }
  getRequestLists(){
    this.spinner.show();
    this.myContactsService.getRequestLists().subscribe((res:any)=> {
      this.RequestListsdata = res.data[0].requestList;
      this.spinner.hide();
    
    })   
   }
  TaskList(){
    this._Router.navigate(["/psa/work-effort/task-list"])
  }
  Calendar(){
    this._Router.navigate(["/psa/work-effort/calendar"])
  }
  MyTime(){
    this._Router.navigate(["/psa/work-effort/my-time-effort"])
  }
  RequestList(){
    this._Router.navigate(["/psa/work-effort/request-list-effort"])
  }
  WorkEffort(){
    this._Router.navigate(["/psa/work-effort/work-effort-effort"])
  }
  Timesheet(){
    this._Router.navigate(["/psa/work-effort/timesheet-effort"])
  }
  SubmittedJobs(){
    this._Router.navigate(["/psa/work-effort/submitted-jobs"])
  }
  iCalendar(){
    this._Router.navigate(["/psa/work-effort/icalendar"])
  }
 

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  homeButton(){
     this._Router.navigate(['/psa/Dashboard'])
   
   }
   cancelSubmit(){
     this._location.back();
   }
   
  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
