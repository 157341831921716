import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { SortEvent } from 'primeng/api';
import { AddEmployeePositionService } from 'src/app/human-resource/add-employee-position/add-employee-position.service';

@Component({
  selector: 'app-detailed-paycheck',
  templateUrl: './detailed-paycheck.component.html',
  styleUrls: ['./detailed-paycheck.component.css']
})
export class DetailedPaycheckComponent implements OnInit {
  activeCategory: any;
  orderItems: any[];
  PaycheckExpensesTable: any;
  PaycheckAndWithHoldingsTable: any;
  paycheckType: any;
  paymentId: any;
  PaycheckExpenses: any;
  amount: any;
  currencyArray: any[] = [];
  PaytoParty: any;
  PaytoPartyArray: any[] = [];
  show: any;
  PayCheck: any;
  showStatus: boolean = true ;
  statusId: any;
  statusID: any;
  PaycheckStatusSendTable: any;
  serialNos="";
  serialNoWithHoldings: any;
  serial="";
  serialNo: any;
  fileData: File;
  constructor(
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly addSkillService: AddSkillService,
    readonly addEmployeePositionService: AddEmployeePositionService,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly activatedRoute: ActivatedRoute,
    readonly router: Router
  ) {
    this.orderItems = [];

  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.paymentId = params["paymentId"];
     // this.show = params["show"];
      this.showStatus = true;

    });
    this.getConfigPagePaycheck();
   // this.getPaycheckAndWithHoldings();
  //  this.getPaycheckExpenses();
    this.getCurrency();
    this.getPartyId();
  }

  createPage(paycheckType) {
    this.router.navigate(['/financial/main-create-paycheck'], { queryParams: { paycheckType: paycheckType } })
  }

  editData(paymentId) {
    this.router.navigate(['/financial/edit-pay-check-form'], { queryParams: { paymentId: paymentId } })
  }

  onSubmitPdf( ) {
    
    this.spinner.show();
    this.accountsService.HTMLDATANEW_TESTING_NEW(this.PayCheck,this.PaycheckExpenses.PayChecks.paycheckType,this.paymentId).subscribe(res => {
      const blob = new Blob([res], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "printPDF";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  }
  


  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const GrossAmount = res.data.currencyList;
      for (const value of GrossAmount) {
        this.currencyArray.push({
          label: value.uomId,
          value: value.uomId
        })
      }
    })
    this.spinner.hide();
  }

  StatusToSend() {
    this.spinner.show();

    const formdata = {
      "paymentId": this.paymentId ,
      "statusId": "PMNT_SENT"

    }

    this.accountsService.StatusToSend(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.getConfigPagePaycheck();
        

      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }
  voidPayment() {
    this.spinner.show();

    const formdata = {
      "paymentId": this.paymentId ,
      "statusId": "PMNT_VOID"

    }

    this.accountsService.StatusToSend(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.getConfigPagePaycheck();
        

      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }
  cancelPayment() {
    this.spinner.show();

    const formdata = {
      "paymentId": this.paymentId ,
      "statusId": "PMNT_CANCELLED"

    }

    this.accountsService.StatusToSend(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
       this.getConfigPagePaycheck();
        

      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }
  getStatusSendDetails() {
    this.accountsService.getStatusSendDetails(this.PaycheckExpenses.PayChecks.paycheckType , this.paymentId).subscribe((res: any) => {
      this.spinner.show();
      this.PaycheckStatusSendTable = res.data;
      this.spinner.hide();
    })
  }
  updatePayCheck(PaycheckExpenses) {
    this.spinner.show();
    //this.resumeDate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");

    const formdata = {
      "amount": PaycheckExpenses.PayChecks.amount,
      "currencyUomId": PaycheckExpenses.PayChecks.currencyUomId,
      "paymentId": this.paymentId
    }

    this.accountsService.updatePayCheck(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.getConfigPagePaycheck();

      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }
  updatePaycheckExpenses(product) {
    this.spinner.show();
    //this.resumeDate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");

    const formdata = {

      "amount": product.payCheckExpense.amount,
      "partyIdTo": product.payCheckExpense.partyIdTo,
      "serialNo": product.payCheckExpense.serialNo,

    }

    this.accountsService.updatePaycheckExpenses(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
       // this.getPaycheckExpenses();

      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }
  updatePaycheckHoldings(product) {


    this.spinner.show();
    //this.resumeDate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");

    const formdata = {

      "amount": parseInt(product.amount),
      "partyIdTo": product.partyIdTo,
      "serialNo":product.serialNo

    }

    this.accountsService.updatePaycheckHolding(formdata, this.PaycheckExpenses.PayChecks.amount).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
     //   this.getConfigPagePaycheck();
        this.PayCheck = res.data.netTotal;

      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }
 
  getPaycheckExpenses() {
    this.accountsService.getPaycheckExpenses(this.PaycheckExpenses.PayChecks.paycheckType).subscribe((res: any) => {
      this.spinner.show();
      this.PaycheckExpensesTable = res.data;
      this.serialNo = res.data.payCheckExpense.serialNo;
      this.spinner.hide();
    })
  }
  getPartyId() {
    this.spinner.show();
    this.addEmployeePositionService.getPartyId().subscribe(res => {
      this.PaytoParty = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.PaytoParty) {
        this.PaytoPartyArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }

  getPaycheckAndWithHoldings() {
    this.accountsService.getPaycheckAndWithHoldings(this.PaycheckExpenses.PayChecks.paycheckType).subscribe((res: any) => {
      this.spinner.show();
      this.PaycheckAndWithHoldingsTable = res.data;
      this.serialNoWithHoldings = res.data.serialNo;
      this.spinner.hide();


    })
  }
  getConfigPagePaycheck() {
    this.accountsService.getConfigPagePaycheck(this.paymentId).subscribe((res: any) => {
      this.spinner.show();
      this.PaycheckExpenses = res.data[0];
      this.PayCheck = res.data[0].amount;
      this.statusID = res.data[0].StatusItem[0].statusId;
      
      

      if(this.statusID=='PMNT_SENT'){
        this.showStatus = false;
      }
      else if(this.statusID=='PMNT_CANCELLED'){
        this.showStatus = false;

      }
      else if(this.statusID=='PMNT_VOID'){
        this.showStatus = false;

      }
      else{
        this.showStatus = true;
      }


      this.spinner.hide();
      this.getPaycheckAndWithHoldings();
      this.getPaycheckExpenses() ; 

    })
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}

