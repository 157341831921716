import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2';
import { SortEvent } from 'primeng/api';
@Component({
  selector: 'app-work-effort-requirements',
  templateUrl: './work-effort-requirements.component.html',
  styleUrls: ['./work-effort-requirements.component.css']
})
export class WorkEffortRequirementsComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 9;
  addRequirements: FormGroup;
  RequirementList: any;
  RequirementListArray: any[]=[];
  WorkReqFulfTypeId: any;
  WorkReqFulfTypeIdArray: any[]=[];
  RequirementTypeList: any;
  RequirementTypeListArray: any[]=[];
  FacilityIdList: any;
  FacilityIdListArray: any[]=[];
  FixedAssetList: any;
  FixedAssetListArray: any[]=[];
  ProductId: any;
  ProductIdArray: any[]=[];
  statusidArray: { label: string; value: string; }[];
  workEffortId: any;
  WorkEffortRequestByIddata: any;
  CreatedDate: string;
  LastModifiedDate: string;
  RequiredByDate: string;
  RequirementStartDate: string;
  total=0;
  rowNumber=0;
  pageSize= 500;
  rows = 50;
  pageNo=1;
  search="";
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly createEventServices: CreateEventServices,
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly toastr: ToastrService,
    private _location: Location,
    
    readonly datePipe: DatePipe,
    readonly myContactsService: MyContactsService,
    readonly addSkillService: AddSkillService,
    readonly _FormBuilder: FormBuilder,
    private _Router : Router

  ) {
    this.statusidArray = [

      {
        label: 'Proposed',
        value: 'REQ_PROPOSED'
      },
      {
        label: 'Created',
        value: 'REQ_CREATED'
      },
      {
        label: 'Approved',
        value: 'REQ_APPROVED'
      },
      {
        label: 'Ordered',
        value: 'REQ_ORDERED'
      },
      {
        label: 'Rejected',
        value: 'REQ_REJECTED'
      },
    ];
    this.addRequirements = this._FormBuilder.group({
      requirementID: [''],
      fulfTypeID: [''],
      requirementTypeID: [''],
      facilityID: [''],
      deliverableID: [''],
      fixedAssetID: [''],
      productID: [''],
      status: [''],
      description: [''],
      requirementStartDate: [''],
      requiredByDate: [''],
      estimatedBudget: [''],
      quantity: [''],
      useCase: [''],
      reason: [''],
      createdDate: [''],
      createdByUser: [''],
      lastModifiedDate: [''],
      lastModifiedByUser: [''],
      facilityIDTo: [''],
      custRequirementID: [''],
      custRequestItemseqID: [''],
    })
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params["workEffortId"];
    });
    this.getRequirementList();
    this.getWorkReqFulfTypeId();
    this.getRequirementTypeList();
    this.getFacilityIdList();
    this.getFixedAssetList();
    this.getProductId();
    this.getWorkEffortRequestById();
  }
  homeButton(){
     this._Router.navigate(['/psa/Dashboard'])
   
   }
   cancelSubmit(){
     this._location.back();
   }
   
  deleteWorkEffortRequirement(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "requirementId": product.node1.requirementId,
          "workEffortId": this.workEffortId
          
        }
        this.myContactsService.deleteWorkEffortRequirement(req).subscribe(res => {
          this.getWorkEffortRequestById(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  createWorkEffortRequirement(): void {
    this.spinner.show();
    const createdDate=this.addRequirements.get('createdDate').value;
    this.CreatedDate=this.datePipe.transform(createdDate,"yyyy-MM-dd hh:mm:ss");
    const lastModifiedDate=this.addRequirements.get('lastModifiedDate').value;
    this.LastModifiedDate=this.datePipe.transform(lastModifiedDate,"yyyy-MM-dd hh:mm:ss");
    const requiredByDate=this.addRequirements.get('requiredByDate').value;
    this.RequiredByDate=this.datePipe.transform(requiredByDate,"yyyy-MM-dd hh:mm:ss");
    const requirementStartDate=this.addRequirements.get('requirementStartDate').value;
    this.RequirementStartDate=this.datePipe.transform(requirementStartDate,"yyyy-MM-dd hh:mm:ss");
     const formData ={
   
  "createdByUserLogin": this.addRequirements.value.createdByUser,
  "createdDate": this.CreatedDate,
  "custRequestId": this.addRequirements.value.custRequirementID,
  "custRequestItemSeqId": this.addRequirements.value.custRequestItemseqID,
  "deliverableId": this.addRequirements.value.deliverableID,
  "description": this.addRequirements.value.description,
  "estimatedBudget": this.addRequirements.value.estimatedBudget,
  "facilityId": this.addRequirements.value.facilityID,
  "facilityIdTo": this.addRequirements.value.facilityIDTo,
  "fixedAssetId": this.addRequirements.value.fixedAssetID,
  "lastModifiedByUserLogin": this.addRequirements.value.lastModifiedByUser,
  "lastModifiedDate": this.LastModifiedDate,
  "productId": this.addRequirements.value.productID,
  "quantity": this.addRequirements.value.quantity,
  "reason": this.addRequirements.value.reason,
  "requiredByDate": this.RequiredByDate,
  "requirementId": this.addRequirements.value.requirementID,
  "requirementStartDate": this.RequirementStartDate,
  "requirementTypeId": this.addRequirements.value.requirementTypeID,
  "statusId": this.addRequirements.value.status,
  "useCase": this.addRequirements.value.useCase,
  "workEffortId": this.workEffortId,
  "workReqFulfTypeId": this.addRequirements.value.fulfTypeID
  
       
         
     }
     this.myContactsService.createWorkEffortRequirement(formData).subscribe((res: any) => {
      
       if (res.success) {
         this.toastr.success("Successfully Created");
         this.spinner.hide();
         this.closebutton.nativeElement.click();
         this.addRequirements.reset();
         this.getWorkEffortRequestById();
         
        
       }
     }, (err) => {
      this.spinner.hide();
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
    
   }
  getWorkEffortRequestById(){
    this.spinner.show();
    this.myContactsService.getWorkEffortRequestById(this.workEffortId).subscribe((res:any)=> {
      this.WorkEffortRequestByIddata = res.data;
      this.spinner.hide();
    
    })   
   }
  addWorkEffort(){
    this._Router.navigate(["/psa/work-effort/add-work-effort"])
  }
  editWorkEffort1(){
    this._Router.navigate(["/psa/work-effort/task-list-view/editWorkEffort"]),{ queryParams: { workEffortId: this.workEffortId} }
  }
  summary(){
    this._Router.navigate(["/psa/work-effort/task-list-view/summary"],{ queryParams: { workEffortId: this.workEffortId} })
  }

  childWorkEfforts(){
    this._Router.navigate(["/psa/work-effort/task-list-view/childWorkEfforts"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  partyAssigns(){
    this._Router.navigate(["/psa/work-effort/task-list-view/PartyAssigns"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  rates(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Rates"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  commEvents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/CommEvents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  shopLists(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ShopLists"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requests(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requests"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requirements(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requirements"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  quotes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Quotes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  orderHeaders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/OrderHeaders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  timeEntries(){
    this._Router.navigate(["/psa/work-effort/task-list-view/TimeEntries"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  notes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Notes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Contents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  products(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Products"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  reviews(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Reviews"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  keywords(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Keywords"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contactMechs(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ContactMechs"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  agreementApplics(){
    this._Router.navigate(["/psa/work-effort/task-list-view/AgreementApplics"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  fixedAssets(){
    this._Router.navigate(["/psa/work-effort/task-list-view/FixedAssets"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  attributes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Attributes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  eventReminders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/EventReminders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  getRequirementList() {
    this.spinner.show();
    this.addSkillService.getRequirementList().subscribe(res => {
      this.RequirementList = res.data[0].list;
      this.spinner.hide();
      for (const value of this.RequirementList) {
        this.RequirementListArray.push({
          label: value.requirementId,
          value: value.description
        })
      }
    })
  }
  getWorkReqFulfTypeId() {
    this.spinner.show();
    this.addSkillService.getWorkReqFulfTypeId().subscribe(res => {
      this.WorkReqFulfTypeId = res.data[0].list;
      this.spinner.hide();
      for (const value of this.WorkReqFulfTypeId) {
        this.WorkReqFulfTypeIdArray.push({
          label: value.description,
          value: value.workReqFulfTypeId
        })
      }
    })
  }
  getRequirementTypeList() {
    this.spinner.show();
    this.addSkillService.getRequirementTypeList().subscribe(res => {
      this.RequirementTypeList = res.data[0].list;
      this.spinner.hide();
      for (const value of this.RequirementTypeList) {
        this.RequirementTypeListArray.push({
          label: value.description,
          value: value.requirementTypeId
        })
      }
    })
  }
  getFacilityIdList() {
    this.spinner.show();
    this.addSkillService.getFacilityIdList().subscribe(res => {
      this.FacilityIdList = res.data;
      this.spinner.hide();
      for (const value of this.FacilityIdList) {
        this.FacilityIdListArray.push({
          label: value.node1.description,
          value: value.node1.facilityId
        })
      }
    })
  }
  getFixedAssetList() {
    this.spinner.show();
    this.addSkillService.getFixedAssetList().subscribe(res => {
      this.FixedAssetList = res.data[0].node;
      this.spinner.hide();
      for (const value of this.FixedAssetList) {
        this.FixedAssetListArray.push({
          label: value.fixedAssetName,
          value: value.fixedAssetId 
        })
      }
    })
  }
  getProductId() {
    this.spinner.show();
    this.addSkillService.getProductId().subscribe(res => {
      this.ProductId = res.data[0].Product;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.internalName,
          value: value.productId 
        })
      }
    })
  }
}
