import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class AssignByMeService {

  constructor(readonly http: HttpClient) { }
  completeEvent(id):Observable<any>{
    const completeEvent = URLS.completeEvent.replace(":workEffortId",id);
    return this.http.put(completeEvent,"");
  }

  
}
