import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/app.constant';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';
@Injectable({
  providedIn: 'root'
})
export class AddEmployeePositionService {
  getPayGradeID() {
    throw new Error('Method not implemented.');
  }

  constructor(readonly http: HttpClient, readonly _ApiService: ApiService) { }

  createEmployeePosition(formData): Observable<any> {
    const addEmpPosition = URLS.createEmpPosition;
    return this.http.post(addEmpPosition, formData)
  }
  getEmployeePosition(lists, emplPosition) {
    const getEmployeePosition = URLS.getEmplPositions + "?pageNo=" + lists.pageNo + "&pageSize=" + lists.pageSize;
    return this.http.post(getEmployeePosition, emplPosition);
  }

  getPartyId(): Observable<any> {
    const getPartyId = URLS.getPersonsPartyId;
    return this.http.get(getPartyId);
  }
  getInternalOrganization(): Observable<any> {
    const getInternalOrganization = URLS.internalOrganization;
    return this.http.get(getInternalOrganization);
  }
  getStatus(): Observable<any> {
    const getStatus = URLS.getStatus;
    return this.http.get(getStatus);
  }
  getPositionType(): Observable<any> {
    const getPositionType = URLS.getPositionTypes;
    return this.http.get(getPositionType);
  }
  updateEmployeePosition(formData, id): Observable<any> {
    const updateApi = URLS.updateEmployeePosition.replace(":empPositionId", id);
    return this.http.put(updateApi, formData);
  }
  getEmployeePositionById(positionId: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/hr/empPosition/${positionId}`);
  }
  emplPosition():Observable<any>{
    const emplPosition = URLS.emplPosition;
    return this.http.get(emplPosition);
  }
}
