<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Current Project: </span>
               
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 120px;">
                <div class="w3-card-4 classCard border_wdth_1">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)">
                                <a>Catalog</a>
                            </li>
                            <li  [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)">
                                <a>Stores</a>
                            </li>

                            <li  [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)">
                                <a>Parties</a>
                            </li>

                            <li  [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)">
                                <a>Categories</a>
                            </li>

                            <button *ngIf="!this.Hidebtn" type="submit" style="margin-left:39% !important" data-toggle="modal" data-target="#exampleModalCenter"
                            class="btn btn-secondary buttonmargin submit-btn ml-2" (click)="resetForm()">
                                New Product Catalog
                            </button>

                            <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary buttonmargin submit-btn ml-2" data-toggle="modal" data-target="#exampleModalCenter" (click)="resetForm()">
                                Create SEO category/products
                            </button>

                            
                        </ul>
                    </div>

                     <div class="create-new-leade">
                        <div class="">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div *ngIf="activeCategory==1" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                    style="justify-content: space-between;">
                                                    <p class="h-text">Summary</p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal" data-target="#exampleModalCenter" (click)="updateProductCatalogNewPatch()">Update</button>
                                                        <!--<button type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#newpopup">New Product Promo</button> -->
                                                    </span>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    
                                                    <div *ngIf="this.productCatalogAll" class="color-black container">
                                                        <div class="row">
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Catalog [ID] :</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.productCatalogAll.prodCatalogId}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Catalog Name :</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.productCatalogAll.catalogName}}

                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Use Quick Add :</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.productCatalogAll.useQuickAdd}}
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Style Sheet :</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.productCatalogAll.styleSheet}}

                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Header Logo :</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.productCatalogAll.headerLogo}}
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Content Path Prefix :</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.productCatalogAll.contentPathPrefix}}
                                                                       
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Template Path Prefix :</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.productCatalogAll.templatePathPrefix}}
                                                                       
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Permission required to add product to "ViewAllow" category :</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.productCatalogAll.viewAllowPermReqd}}
                                                                       
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Permission required to add product to "PurchaseAllow" category :</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.productCatalogAll.purchaseAllowPermReqd}}
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="activeCategory==2" class="w-100">
                                            <div class="w3-card-4 classCard w-100 mt-2 " style="width: 100% !important;">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Edit Product Catalog Stores
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0; "
                                                            data-toggle="modal" data-target="#addProductCatalogStoresPopup" (click)="resetForm()">Add Product Catalog Stores
                                                            </button>
                                                    </p>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="allProdCatalogStores" [paginator]="true" [rows]="rows"
                                                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width': '180px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                    </p-checkbox>
                                                                                   
                                                                                    Store ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt12212:j_idt1228:j_idt1231:filter"
                                                                                    name="j_idt12212:j_idt1228:j_idt1231:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt12212:j_idt1228:j_idt1231:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                            
                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date Time
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt12212:j_idt1228:j_idt1231:filter"
                                                                                    name="j_idt12212:j_idt1228:j_idt1231:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt12212:j_idt1228:j_idt1231:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date Time
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt12212:j_idt1228:j_idt1231:filter"
                                                                                    name="j_idt12212:j_idt1228:j_idt1231:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt12212:j_idt1228:j_idt1231:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                            
                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Sequence Num
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt12212:j_idt1228:j_idt1231:filter"
                                                                                    name="j_idt12212:j_idt1228:j_idt1231:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt12212:j_idt1228:j_idt1231:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                            
                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt12212:j_idt1228:j_idt1231:filter"
                                                                                    name="j_idt12212:j_idt1228:j_idt1231:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt12212:j_idt1228:j_idt1231:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span>
                                                                                        {{product.productStoreId}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.fromDate | date}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.thruDate | date}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.sequenceNum}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" 
                                                                                data-toggle="modal" data-target="#addProductCatalogStoresPopup" (click)="updateProdCatalogStorePatch(product)">Update
                                                                                </button>

                                                                                <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" (click)="deleteProdCatalogStore(product)" >Delete
                                                                               </button>

                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>                                                                                    
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No Record Found
                                                                                    </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                            
                                                                <p class="paginate_data">
                                                                    View per page
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div *ngIf="activeCategory==3" class="w-100">
                                            <div class="w3-card-4 classCard w-100 mt-2 " style="width: 100% !important;">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Edit Product Catalog Parties
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0; "
                                                            data-toggle="modal" data-target="#addProductCatalogPartiesPopup" (click)="resetForm()">Add Product Catalog Parties
                                                            </button>
                                                    </p>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="ProdCatalogToPartyById" [paginator]="true" [rows]="rows"
                                                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width': '180px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                    </p-checkbox>
                                                                                   
                                                                                    Party
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt12212:j_idt1228:j_idt1231:filter"
                                                                                    name="j_idt12212:j_idt1228:j_idt1231:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt12212:j_idt1228:j_idt1231:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Role
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt12212:j_idt1228:j_idt1231:filter"
                                                                                    name="j_idt12212:j_idt1228:j_idt1231:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt12212:j_idt1228:j_idt1231:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                            
                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date Time
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt12212:j_idt1228:j_idt1231:filter"
                                                                                    name="j_idt12212:j_idt1228:j_idt1231:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt12212:j_idt1228:j_idt1231:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date Time
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt12212:j_idt1228:j_idt1231:filter"
                                                                                    name="j_idt12212:j_idt1228:j_idt1231:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt12212:j_idt1228:j_idt1231:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                            
                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Sequence Num
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt12212:j_idt1228:j_idt1231:filter"
                                                                                    name="j_idt12212:j_idt1228:j_idt1231:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt12212:j_idt1228:j_idt1231:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                            
                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt12212:j_idt1228:j_idt1231:filter"
                                                                                    name="j_idt12212:j_idt1228:j_idt1231:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt12212:j_idt1228:j_idt1231:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span>
                                                                                        {{product.partyId}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.roleTypeId}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.fromDate|date}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.thruDate|date}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.sequenceNum}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addProductCatalogPartiesPopup" (click)="updateProdCatalogToPartyPatch(product)">Update
                                                                                </button>

                                                                                <button *ngIf="!this.Hidebtn" type="button" class="btn btn-danger ml-2" (click)="deleteProdCatalogToParty(product)">
                                                                                    Delete
                                                                               </button>

                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No Record Found
                                                                                    </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                            
                                                                <p class="paginate_data">
                                                                    View per page
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                        <div *ngIf="activeCategory==4" class="w-100">
                                            <div class="w3-card-4 classCard w-100 mt-2 " style="width: 100% !important;">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Edit Product Catalog Categories
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0; "
                                                            data-toggle="modal" data-target="#editProductCatalogCategoriesPopup" (click)="resetForm()">Add Product Catalog Categories
                                                            </button>
                                                    </p>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="ProductCategoryToProdCatalogById" [paginator]="true" [rows]="rows"
                                                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width': '180px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                    </p-checkbox>
                                                                                   
                                                                                    Category Id
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt12212:j_idt1228:j_idt1231:filter"
                                                                                    name="j_idt12212:j_idt1228:j_idt1231:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt12212:j_idt1228:j_idt1231:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Product Catalog Category Type
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt12212:j_idt1228:j_idt1231:filter"
                                                                                    name="j_idt12212:j_idt1228:j_idt1231:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt12212:j_idt1228:j_idt1231:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                            
                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date Time
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt12212:j_idt1228:j_idt1231:filter"
                                                                                    name="j_idt12212:j_idt1228:j_idt1231:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt12212:j_idt1228:j_idt1231:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date Time
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt12212:j_idt1228:j_idt1231:filter"
                                                                                    name="j_idt12212:j_idt1228:j_idt1231:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt12212:j_idt1228:j_idt1231:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                            
                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Sequence Num
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt12212:j_idt1228:j_idt1231:filter"
                                                                                    name="j_idt12212:j_idt1228:j_idt1231:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt12212:j_idt1228:j_idt1231:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                            
                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                   Action
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt12212:j_idt1228:j_idt1231:filter"
                                                                                    name="j_idt12212:j_idt1228:j_idt1231:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt12212:j_idt1228:j_idt1231:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span style="color: #0d3769;
                                                                                    cursor: pointer;
                                                                                    text-decoration: underline;
                                                                                    font-weight: 500;" (click)="referToDetailPage(product.productCategoryId)">
                                                                                        {{product.productCategoryId}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.prodCatalogCategoryTypeId}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.fromDate|date}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.thruDate|date}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.sequenceNum}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>
                                                                                    <small *ngIf="!this.Hidebtn" style="color: #0d3769 !important;font-weight: 600;cursor:pointer; " data-toggle="modal" data-target="#editProductCatalogCategoriesPopup" (click)="updateProductCategoryToProdCatalogPatch(product)">Update</small>
                                                                                    <small *ngIf="!this.Hidebtn" style="color: #0d3769 !important;font-weight: 600;cursor: pointer; margin-left: 11px;margin-right:11px " (click)="removeProductCategoryToProdCatalog(product)" >Delete</small>
                                                                                    <small style="color: #0d3769 !important;font-weight: 600;cursor: pointer; " (click)="referTocatagories(product.productCategoryId)"> Make Top</small>

                                                                                </span>
                         

                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No Record Found
                                                                                    </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                            
                                                                <p class="paginate_data">
                                                                    View per page
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</div>
 <div class="modal fade" id="createProductCatalogPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"> -->
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Product Catalog</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                        New Product Catalog</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="catalogForm" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12 mb-20">If you don't populate a Catalog with Categories, it will not be shown in the Browse Catalogs/Categories tree</div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Catalog [ID]</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="prodCatalogId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter CatalogID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Catalog Name <span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="catalogName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Catalog Name">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Use Quick Add
                                              
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true"  formControlName="" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Style Sheet
                                              
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="styleSheet" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enetr Style Sheet">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Header Logo </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="headerLogo" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Header Logo">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Content Path Prefix </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="contentPathPrefix" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enetr Content Path Prefix ">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Template Path Prefix</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="templatePathPrefix" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Template Path Prefix">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Add product to "ViewAllow" category</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true"  formControlName="viewAllowPermReqd" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Add product to "PurchaseAllow"
                                                        category</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true"  formControlName="purchaseAllowPermReqd" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>


                                        <div class="main-submit-button" style="margin-right: 5%;">
                                            <button type="submit"  class="btn btn-secondary submit-btn">Add</button>

                                        </div>
                                    </form>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>



<div class="modal fade" id="addProductCatalogStoresPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span *ngIf="this.show" class="color-black pl-1"> Add Product Catalog Stores</span>
                <span *ngIf="!this.show" class="color-black pl-1"> Update Product Catalog Stores</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton2>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                             <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="this.show">
                                    <a>
                                    Add Product Catalog Stores
                                    </a>
                                </li>
                                <li *ngIf="!this.show">
                                    <a>
                                    Update Product Catalog Stores
                                    </a>
                                </li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="addProductCatalogStoresForm" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12 mb-20"></div>

                                            

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Store</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" placeholder="Choose Store"  
                                                        formControlName="store" optionlabel="label" [readonly]="readOnlyCode" [options]="storeIdArray">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">From Date <span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" [readonly]="readOnlyCode">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Through Date
                                              
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="thorughDate" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" >

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Sequence Num
                                              
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="sequenceNum" class="form-control" 
                                                        id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Number">
                                                    </div>
                                                </div>
                                            </div>

                                           


                                        </div>


                                        <div class="main-submit-button" style="margin-right: 12%;">
                                            <button *ngIf="this.show" type="submit"  class="btn btn-secondary submit-btn" (click)="createProdCatalogStore()">Add</button>
                                            <button *ngIf="!this.show" type="submit"  class="btn btn-secondary submit-btn" (click)="updateProdCatalogStore()">Update</button>


                                        </div>
                                    </form>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>


<div class="modal fade" id="addProductCatalogPartiesPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span *ngIf="this.show" class="color-black pl-1"> Add Product Catalog Parties</span>
                <span *ngIf="!this.show" class="color-black pl-1"> Update Product Catalog Parties</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton3>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                             <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="this.show">
                                    <a>
                                    Add Product Catalog Parties
                                    </a>
                                </li>
                                <li *ngIf="!this.show">
                                    <a>
                                    Update Product Catalog Parties
                                    </a>
                                </li>

                            </ul>
                        </div>

                       

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="addProductCatalogPartiesForm" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12 mb-20"></div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Party ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" placeholder="Enter Party Id"  
                                                        formControlName="partyId" optionlabel="label" [readonly]="readOnlyCode" [options]="partyIdArray">
                                                        </p-dropdown>
                                                       

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Role 
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" placeholder="Enter Role"  
                                                        formControlName="role" optionlabel="label" [readonly]="readOnlyCode" [options]="RoleTypeListNewArray">
                                                        </p-dropdown>
                                                      
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate" class="form-control" 
                                                        id="exampleInputEmail1" aria-describedby="emailHelp" [readonly]="readOnlyCode">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Through Date <span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="thorughDate" class="form-control" 
                                                        id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Sequence Num
                                              
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="sequenceNum" class="form-control" 
                                                        id="exampleInputEmail1"
                                                         aria-describedby="emailHelp" placeholder="Enter Sequence Num">

                                                    </div>

                                                    
                                                </div>
                                            </div>

                                           


                                        </div>


                                        <div class="main-submit-button" style="margin-right: 12%;">
                                            <button type="submit" *ngIf="this.show" class="btn btn-secondary submit-btn" (click)="addProdCatalogToParty()">Add</button>
                                            <button type="submit" *ngIf="!this.show" class="btn btn-secondary submit-btn" (click)="updateProdCatalogToParty()">Update</button>

                                        </div>
                                    </form>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="editProductCatalogPartiesPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Edit Product Catalog Parties</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                             <ul class="tabbing-section tabbing-accordians tabClass">

                                <li ><a>
                                    Edit Product Catalog Parties</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="editProductCatalogPartiesForm" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12 mb-20"></div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Party ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="partyId" class="form-control"
                                                         id="exampleInputEmail1" aria-describedby="emailHelp"  placeholder="Enter Party ID">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Role <span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="role" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Role">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate" class="form-control" 
                                                        id="exampleInputEmail1" aria-describedby="emailHelp" >

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Through Date <span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="thorughDate" class="form-control" 
                                                        id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Sequence Num
                                              
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="sequenceNum" class="form-control" id="exampleInputEmail1"
                                                         aria-describedby="emailHelp" placeholder="Enter Sequence Num">

                                                    </div>

                                                    
                                                </div>
                                            </div>

                                           


                                        </div>


                                        <div class="main-submit-button" style="margin-right: 12%;">
                                            <button type="submit"  class="btn btn-secondary submit-btn">Add</button>

                                        </div>
                                    </form>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>


<div class="modal fade" id="addProductCatalogCategoriesPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Catalog Categories</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                             <ul class="tabbing-section tabbing-accordians tabClass">

                                <li ><a>
                                    Add Product Catalog Categories</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="addProductCatalogCategoriesForm" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12 mb-20"></div>
                                            

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Category ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="categoryId" class="form-control"
                                                         id="exampleInputEmail1" aria-describedby="emailHelp"  placeholder="Enter Category Id">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Product Catalog Category Type
                                                       </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true"
                                                        placeholder="Select Product Catalog Category Type"
                                                        formControlName="productCatalogCateogry">
                                                    </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">From Date Time</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Through Date Time 
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="thorughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Sequence Num
                                              
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="sequenceNum" class="form-control" id="exampleInputEmail1"
                                                         aria-describedby="emailHelp" placeholder="Enter Sequence Num">

                                                    </div>

                                                    
                                                </div>
                                            </div>

                                           


                                        </div>


                                        <div class="main-submit-button" style="margin-right: 12%;">
                                            <button type="submit"  class="btn btn-secondary submit-btn">Add</button>

                                        </div>
                                    </form>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="editProductCatalogCategoriesPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span *ngIf="this.show" class="color-black pl-1">Add Product Catalog Categories</span>
                <span *ngIf="!this.show" class="color-black pl-1"> Edit Product Catalog Categories</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton4>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                             <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="this.show"><a>
                                    Add Product Catalog Categories</a></li>
                                <li *ngIf="!this.show"><a>
                                    Edit Product Catalog Categories</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="editProductCatalogCategoriesForm" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12 mb-20"></div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Category ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true"
                                                        placeholder="Enter Category Id"
                                                        formControlName="categoryId" [options]="allCategoryIdArray" [readonly]="readOnlyCode">
                                                    </p-dropdown>
                                                     
                                                       

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Product Catalog Category Type
                                                       </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true"
                                                        placeholder="Select Product Catalog Category Type"
                                                        formControlName="productCatalogCateogry" [options]="ProductCategoryTypeListArray" [readonly]="readOnlyCode">
                                                    </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">From Date Time</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" [readonly]="readOnlyCode">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Through Date Time 
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="thorughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Sequence Num
                                              
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="sequenceNum" class="form-control" id="exampleInputEmail1"
                                                         aria-describedby="emailHelp" placeholder="Enter Sequence Num">

                                                    </div>

                                                    
                                                </div>
                                            </div>

                                           


                                        </div>


                                        <div class="main-submit-button" style="margin-right: 12%;">
                                            <button type="submit" *ngIf="this.show" class="btn btn-secondary submit-btn" (click)="createProductCategoryToProdCatalog()">Add</button>
                                            <button type="submit" *ngIf="!this.show" class="btn btn-secondary submit-btn" (click)="updateProductCategoryToProdCatalog()">Update</button>

                                        </div>
                                    </form>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span  *ngIf="this.show" class="color-black pl-1"> Create Product Catalog</span>
                <span  *ngIf="!this.show" class="color-black pl-1"> Update Product Catalog</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton15>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="this.show"><a>
                                    New Product Catalog</a></li>
                                <li  *ngIf="!this.show"><a>
                                    Update Product Catalog</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="catalogForm" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12 mb-20">If you don't populate a Catalog with Categories, it will not be shown in the Browse Catalogs/Categories tree</div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Catalog [ID]</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="prodCatalogId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter CatalogID" [readonly]="readOnlyCode">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Catalog Name 
                                                            <span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="catalogName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Catalog Name">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Use Quick Add
                                              
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="YesNoArray" formControlName="useQuickAdd" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Style Sheet
                                              
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="styleSheet" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enetr Style Sheet">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Header Logo </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="headerLogo" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Header Logo">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Content Path Prefix </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="contentPathPrefix" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enetr Content Path Prefix ">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Template Path Prefix</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="templatePathPrefix" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Template Path Prefix">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Add product to "ViewAllow" category</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="YesNoArray" formControlName="viewAllowPermReqd" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Add product to "PurchaseAllow"
                                                        category</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="YesNoArray" formControlName="purchaseAllowPermReqd" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>


                                        <div class="main-submit-button" style="margin-right: 7%;">
                                            <button *ngIf="this.show" type="submit" class="btn btn-secondary submit-btn" (click)="createProductCatalogNew()">Add</button>
                                            <button *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn"  (click)="updateProductCatalogNew()">Update</button>


                                        </div>
                                    </form>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>






