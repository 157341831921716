import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-website-main',
  templateUrl: './website-main.component.html',
  styleUrls: ['./website-main.component.css']
})
export class WebsiteMainComponent implements OnInit {
  @ViewChild('closebutton1')closebutton1;
  @ViewChild('closebutton2')closebutton2;
  activeCategory = 2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;

  webSiteList:any;
  createWebSiteForm: FormGroup;
  findWebSiteAll: any;
  yesNoArray: { label: string; value: string; }[];
  VisualSetArray: { label: string; value: string; }[];
  ProductStoreIDArray: { label: string; value: string; }[];
  website: any;
  ProductStoreList: any;
  ProductStoreListArray: any;
  showPop: boolean;
  userPermissions: any;
  Hidebtn: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) 
  { 
    this.createWebSiteForm = this._FormBuilder.group({
      websiteID:"",
      siteName:"",
      httpHost:"",
      httpPort:"",
      httpsHost:"",
      httpsPort:"",
      yesNo:"",
      webAppPath:"",
      standardContentPrefix:"",
      secureContentPrefix:"",
      domainCookie:"",
      visualThemeSet:"",
      productStoreID:"",
      allowProductStoreChange:"",
      hostedPathAlias:"",
      isDefault:"",
      displayMaintenancPage:"",
    });
    this.yesNoArray = [
      {
        label: "Y",
        value: "Y"
      },
      {
        label: "N",
        value: "N"
      }
    ]
    this.VisualSetArray = [
      {
        label: "Themes to be used for backoffice applicationsY",
        value: "BACKOFFICE"
      },
      {
        label: "Themes to be used for ECommerce applications",
        value: "ECOMMERCE"
      }
    ]
    this.ProductStoreIDArray = [
      {
        label: "OFBiz E-Commerce Store",
        value: "9000"
      },
      {
        label: "OFBiz Physical Retail Store",
        value: "9100"
      },
      {
        label: "Open Travel system Demo Site",
        value: "RentalStore"
      }
    ]
  }



  ngOnInit(): void {
    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
  if(this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN" || this.userPermissions.CONTENTMGR_ADMIN=="CONTENTMGR_ADMIN"){
      this.Hidebtn=false;
    }
  
    // this._ActivatedRoute.queryParams.subscribe(params=>{
    //   this.showPop = params.showPop;
    // })
    // if(this.showPop==true){
    //   this.closebutton1.nativeElement.click();
    

    // }
    this.getProductStoreList();
    this.FindWebSiteAll();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
 
  getProductStoreList() {
    this.spinner.show();
    this.accountsService.getProductStoreList().subscribe(res => {
      this.ProductStoreList = res.data[0].productStoreList;
      this.spinner.hide();
      for (const value of this.ProductStoreList) {
        this.ProductStoreListArray.push({
          label: value.storeName,
          value: value.productStoreId
        })
      }
    })

  }

  detailPage(id){
    this._Router.navigate(["content/main/website-detail-page"],
    {queryParams : {webSiteId :id}})
  }
  createWebSite() {
    this.spinner.show();
    const requestData =
    {
      "allowProductStoreChange": this.createWebSiteForm.value.allowProductStoreChange,
      "cookieDomain": this.createWebSiteForm.value.domainCookie,
      "displayMaintenancePage": this.createWebSiteForm.value.displayMaintenancPage,
      "enableHttps": this.createWebSiteForm.value.yesNo,
      "hostedPathAlias": this.createWebSiteForm.value.hostedPathAlias,
      "httpHost": this.createWebSiteForm.value.httpHost,
      "httpPort": this.createWebSiteForm.value.httpPort,
      "httpsHost": this.createWebSiteForm.value.httpsHost,
      "httpsPort": this.createWebSiteForm.value.httpsPort,
      "isDefault": this.createWebSiteForm.value.isDefault,
      "productStoreId": this.createWebSiteForm.value.productStoreID,
      "secureContentPrefix": this.createWebSiteForm.value.secureContentPrefix,
      "siteName": this.createWebSiteForm.value.siteName,
      "standardContentPrefix": this.createWebSiteForm.value.standardContentPrefix,
      "visualThemeSetId": this.createWebSiteForm.value.visualThemeSet,
      "webSiteId": this.createWebSiteForm.value.websiteID,
      "webappPath": this.createWebSiteForm.value.webAppPath,
    }
    this.myContactsService.createWebSite(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.createWebSiteForm.reset();
        this.website = res.data.webSiteId;
        this._Router.navigate(["content/main/website-detail-page"],
        {queryParams : {webSiteId :this.website }})
        this.FindWebSiteAll();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  FindWebSiteAll() {
    this.spinner.show();
    this.myContactsService.FindWebSiteAll().subscribe(res => {
      this.findWebSiteAll = res.data.FindWebSite;
      this.spinner.hide();

    })
  }

  toHub()
  {
    this._Router.navigate(['/hub'])
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
