import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';

    import { Location } from '@angular/common';


@Component({
  selector: 'app-revisions-my-profile',
  templateUrl: './revisions-my-profile.component.html',
  styleUrls: ['./revisions-my-profile.component.css']
})
export class RevisionsMyProfileComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 8;
  activeCategoryA = 7;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  sprintTable:any;
  roleForm:FormGroup;
  AddTaskForm: FormGroup;
  projectForm: FormGroup;
  ratesForm: FormGroup;
  updateProfile: FormGroup;
  createUserLogIn: FormGroup;
  addRelatedAccount: FormGroup;
  createNewContactInformation: FormGroup;
  partyAttributes: FormGroup;
  addCheckAccount: FormGroup;
  createBillingAccount: FormGroup;
  addNewCreditCard: FormGroup;
  createGiftCard: FormGroup;
  createEFTAcc: FormGroup;
  partyId:any
  taxAuthPartyId: any;
  Person: any;
  findPartyRevisionList: any;
  advanceSearch: { Revision: string; Message: string; };
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
      private _location: Location,
      readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly myContactsService: MyContactsService,
    readonly _FormBuilder: FormBuilder,
        readonly toastr: ToastrService,
    readonly _Router: Router
  ) {
  
    this.advanceSearch = {
      Revision: "",
      Message: "",
    };
   }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.taxAuthPartyId = params["taxAuthPartyId"];
    });
    this.getPerson();
    this.getfindPartyRevisionList();


  }
  reset() {
    this.spinner.show();
    this.advanceSearch.Revision = "";
    this.advanceSearch.Message = "";

    this.myContactsService.getfindPartyRevisionList().subscribe((res: any) => {
      this.findPartyRevisionList = res.data;
      this.spinner.hide();
    })
  }
  getfindPartyRevisionList() {
    this.spinner.show();
    this.myContactsService.getfindPartyRevisionList().subscribe(res => {
      this.findPartyRevisionList = res.data;
      this.spinner.hide();
    })
  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
      
  findPartyRevision() {
    this.spinner.show();

    if(this.advanceSearch.Revision||this.advanceSearch.Message){
      const req = {
        "contentName": this.advanceSearch.Revision ? this.advanceSearch.Revision : '',
        "description": this.advanceSearch.Message ? this.advanceSearch.Message : '',
      }
      this.myContactsService.findPartyRevision(req.contentName, req.description).subscribe((res: any) => {
        this.findPartyRevisionList = res.data;
        this.spinner.hide();
      })}
      else{
        this.getfindPartyRevisionList();
      }
    this.spinner.hide();
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  getPerson() {
    this.spinner.show();
    this.accountsService.getPerson(this.taxAuthPartyId).subscribe(res => {
      this.Person = res.data;
      this.spinner.hide();
    })

  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
  MyProfile() {
    this._Router.navigate(["/psa/my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  Preferences() {
    this._Router.navigate(["/psa/preferences-my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  Roles() {
    this._Router.navigate(["/psa/roles"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  Project() {
    this._Router.navigate(["/psa/project-my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  TasksProfile() {
    this._Router.navigate(["/psa/tasks-my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  Rates() {
    this._Router.navigate(["/psa/rates-my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  Revisions() {
    this._Router.navigate(["/psa/revisions-my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }

  Backlog(){
    this._Router.navigate(["/psa/product-backlog"])
  }
  Sprint(){
    this._Router.navigate(["/psa/sprints"])
  }
  MyWork(){
    this._Router.navigate(["/psa/my-work"])
  }
  Tasks(){
    this._Router.navigate(["/psa/tasks"])
  }
  OpenTest(){
    this._Router.navigate(["/psa/open-test"])
  }
  Revision(){
    this._Router.navigate(["/psa/find-task-revision"])
  }

}
