import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';

import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddEmployeeFormService } from '../add-new-employee/add-new-employment-service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { EmployeeService } from '../employees/employess.service';
import { AddEmployeePositionService } from '../add-employee-position/add-employee-position.service';
import { DatePipe } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-hrm-employeement',
  templateUrl: './hrm-employeement.component.html',
  styleUrls: ['./hrm-employeement.component.css']
})
export class HrmEmployeementComponent implements OnInit {
  products3: any;
  activeCategory=2;
  total=0;
  personId: any;
  partyIdArray: any[]=[];
  InternalOrganization: any;
  InternalOrganizationArray: any[]=[];
  rowNumber=0;
  pageSize= 100;
  employeeList:any;
  advanceSearch: {

    internalOrganization: string;
    employePartyId: string;
    firstName: string;
    lastName: string;
    fromdateFrom: string;
    fromDateTo: string;
    throughDateFrom: string,
    throughDateTo: string,
    terminationReasonID: string,
    TerminationTypeID: string,
  };
  pageNo = 1;
  rows = 50;
  search= ""
  private employessSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
    search: this.search

  }
  show: boolean;
  createEmployement:FormGroup;
  PeriodTypeId: any;
  PeriodTypeIdArray: any[]=[];
  TerminationReasonID: any;
  TerminationReasonIDArray: any[]=[];
  TerminationTypeID: any;
  TerminationTypeIDArray: any[]=[];
  actualfromdate: string;
  @ViewChild('closebutton') closebutton;
  SalaryStepSequenceIDArray: any[]=[];
  PayGradeIDArray: any[]=[];
  PayGradeID: any[]=[];
  SalaryStepSequenceID: any[]=[];
  fromActualdate: any;

  
  constructor(readonly accountsService: AccountsService,
    readonly router: Router,  
    readonly addEmployeePositionService: AddEmployeePositionService,
    readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly employeeService: EmployeeService,
    readonly _FormBuilder: FormBuilder,   private _location: Location,
    
    
    public addEmployeeFormService: AddEmployeeFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly toastr: ToastrService,
    readonly myContactsService: MyContactsService,

    ) { 
      this.createEmployement = this._FormBuilder.group({
        InternalOrganization: [''],
        EmployeePartyID: ['',[Validators.required]],
        fromDate: ['',[Validators.required]],
        Amount: ['',[Validators.required]],
        Comments: [''],
        PayGradeID: [''],
        SalaryStepSequenceID: [''],
        PeriodTypeID: [''],
      
      });
      this.advanceSearch={

        internalOrganization: '',
        employePartyId: '',
        firstName: '',
        lastName: '',
        fromdateFrom: '',
        fromDateTo: '',
        throughDateFrom: '',
        throughDateTo: '',
        terminationReasonID: '',
        TerminationTypeID: ''
      };
    }

  ngOnInit(): void {
 

    this.show=false;
    this.getPartyId();
    this.getInternalOrganization();
    this.getPeriodTypeId();
    this.getTerminationReasonID();
    this.getTerminationTypeID();
    this.getSalaryStepSequenceID();
    this.getPayGradeID();
    this.pageLoad();

  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }

  reset(){
    this.spinner.show();
    this.advanceSearch.internalOrganization="";
    this.advanceSearch.employePartyId="";
    this.advanceSearch.firstName="";
    this.advanceSearch.lastName="";
    this.advanceSearch.fromdateFrom="";
    this.advanceSearch.fromDateTo="";
    this.advanceSearch.throughDateFrom="",
    this.advanceSearch.throughDateTo="",
    this.advanceSearch.terminationReasonID="",
    this.advanceSearch.TerminationTypeID=""

   
      this.accountsService.getFindEmploymentList().subscribe((res:any)=> {
        this.employeeList = res.data;
        this.spinner.hide();
      
      
      }) 
  }
  pageLoad(){
    this.spinner.show();
    this.advanceSearch.internalOrganization="";
    this.advanceSearch.employePartyId="";
    this.advanceSearch.firstName="";
    this.advanceSearch.lastName="";
    this.advanceSearch.fromdateFrom="";
    this.advanceSearch.fromDateTo="";
    this.advanceSearch.throughDateFrom="",
    this.advanceSearch.throughDateTo="",
    this.advanceSearch.terminationReasonID="",
    this.advanceSearch.TerminationTypeID=""
   
      this.accountsService.getFindEmploymentList().subscribe((res:any)=> {
        this.employeeList = res.data;
        // res.data.forEach((element,ind) => {
        //   this.employeeList[ind] = element.Employments
        //   this.employeeList[ind].firstName = element.FindEmplist.firstName;
        //   this.employeeList[ind].lastName = element.FindEmplist.lastName;
        //   this.employeeList[ind].partyId = element.FindEmplist.partyId;
        // });
        this.spinner.hide();
      
      
      }) 
      console.log("this.employeeList",this.employeeList)
  }
  getFindEmployment(){
     this.spinner.show();
     if( this.advanceSearch.firstName||this.advanceSearch.fromdateFrom||this.advanceSearch.lastName||this.advanceSearch.employePartyId
      ||this.advanceSearch.internalOrganization||this.advanceSearch.terminationReasonID||this.advanceSearch.TerminationTypeID||this.advanceSearch.throughDateFrom){
      const req = {
        "firstName": this.advanceSearch.firstName ? this.advanceSearch.firstName : "",
         "fromDate":this.datePipe.transform(this.advanceSearch.fromdateFrom, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.fromdateFrom, "yyyy-MM-dd hh:mm:ss") : "",
        "lastName": this.advanceSearch.lastName ? this.advanceSearch.lastName :"" ,
        "partyIdTo": this.advanceSearch.employePartyId ? this.advanceSearch.employePartyId : "",
        "roleTypeIdFrom": this.advanceSearch.internalOrganization ? this.advanceSearch.internalOrganization :"",
        "terminationReasonId": this.advanceSearch.terminationReasonID ? this.advanceSearch.terminationReasonID : "",
        "terminationTypeId": this.advanceSearch.TerminationTypeID ? this.advanceSearch.TerminationTypeID :"",
         "thruDate":this.datePipe.transform(this.advanceSearch.throughDateFrom, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.throughDateFrom, "yyyy-MM-dd hh:mm:ss") : "",
      }
    
        this.accountsService.getFindEmployment(req.firstName,req.fromDate,req.lastName,
         req.roleTypeIdFrom,req.partyIdTo,req.terminationReasonId,req.terminationTypeId,req.thruDate).subscribe((res:any)=> {
          this.employeeList = res.data;
          this.spinner.hide();
       
       
        })
     }
     else{
      this.pageLoad();
     }
     this.spinner.hide();
     
      }
 

  
  onSubmit() {
    this.spinner.show();
   
    let fromDat = this.createEmployement.get('fromDate').value;
    this.fromActualdate = fromDat
    this.actualfromdate = this.datePipe.transform(fromDat, "yyyy-MM-dd hh:mm:ss");
  
    let formData = {
  
      "amount":this.createEmployement.value.Amount,
      "comments": this.createEmployement.value.Comments,
      "fromDate": this.actualfromdate,
      "fromDate_i18n": "",
      "partyIdFrom": this.createEmployement.value.InternalOrganization,
      "partyIdTo": this.createEmployement.value.EmployeePartyID,
      "payGradeId": this.createEmployement.value.PayGradeID,
      "periodTypeId":this.createEmployement.value.PeriodTypeID,
      "roleTypeIdFrom":'INTERNAL_ORGANIZATIO',
      "roleTypeIdTo":'EMPLOYEE',
      "salaryStepSeqId": this.createEmployement.value.SalaryStepSequenceID,
    }

  
    this.accountsService.createEmployment(formData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Created Successfully");
       
        
        this.closebutton.nativeElement.click();
        this.router.navigate(["/hr/detail-employement-summary"], { queryParams: { partyID:this.createEmployement.value.EmployeePartyID,
          fromdate:this.actualfromdate ,partyIdFrom:this.createEmployement.value.InternalOrganization,roleTypeIdFrom:"INTERNAL_ORGANIZATIO",roleTypeIdTo:"EMPLOYEE"} })
     
      }

      else if (res.success == false) {
        this.toastr.error(res.message);
        this.spinner.hide();
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
      this.spinner.hide();
    })
  }

  getTerminationTypeID() {
    this.spinner.show();
    this.accountsService.getTerminationTypeID().subscribe(res => {
      this.TerminationTypeID = res.data;
      this.spinner.hide();
      for (const value of this.TerminationTypeID) {
        this.TerminationTypeIDArray.push({
          label: value.description,
          value: value.terminationReasonId
        })
      }
    })
  }
  getTerminationReasonID() {
    this.spinner.show();
    this.accountsService.getTerminationReasonID().subscribe(res => {
      this.TerminationReasonID = res.data;
      this.spinner.hide();
      for (const value of this.TerminationReasonID) {
        this.TerminationReasonIDArray.push({
          label: value.description,
          value: value.terminationReasonId
        })
      }
    })
  }
  getPeriodTypeId() {
    this.spinner.show();
    this.accountsService.getPeriodTypeId().subscribe(res => {
      this.PeriodTypeId = res.data;
      this.spinner.hide();
      for (const value of this.PeriodTypeId) {
        this.PeriodTypeIdArray.push({
          label: value.description,
          value: value.periodTypeId
        })
      }
    })
  }
  getInternalOrganization() {
    this.spinner.show();
    this.accountsService.getInternalOrganization().subscribe(res => {
      this.InternalOrganization = res.data;
      this.spinner.hide();
      for (const value of this.InternalOrganization) {
        this.InternalOrganizationArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  getPartyId() {
    this.spinner.show();
    this.addEmployeePositionService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  getPayGradeID() {
    this.spinner.show();
    this.accountsService.getPayGradeID().subscribe(res => {
      this.SalaryStepSequenceID = res.data;
      this.spinner.hide();
      for (const value of this.SalaryStepSequenceID) {
        this.PayGradeIDArray.push({
          label: value.salaryStepSeqId,
          value: value.salaryStepSeqId
        })
      }
    })
  }
  
  
  getSalaryStepSequenceID() {
    this.spinner.show();
    this.accountsService.getSalaryStepSequenceID().subscribe(res => {
      this.PayGradeID = res.data;
      this.spinner.hide();
      for (const value of this.PayGradeID) {
        this.SalaryStepSequenceIDArray.push({
          label: value.payGradeId,
          value: value.payGradeId
        })
      }
    })
  }

  employementDetail(partyIdFrom,partyID,fromDate,roleTypeIdFrom,roleTypeIdTo) {
    this.router.navigate(['/hr/detail-employement-summary'],{ queryParams: { partyIdFrom:partyIdFrom ,
      partyID:partyID, fromdate: fromDate,roleTypeIdFrom:roleTypeIdFrom,roleTypeIdTo:roleTypeIdTo } })
  }
  employeeProfile(partyId){
    this.router.navigate(['/hr/hrm-main'],{queryParams:{partyId:partyId}})
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}
