<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">
                </span>
            </div>
            <div class="w3-card-4 classCard">



                <div class=" bg-white color-grey">
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">

                                    <div class="w3-card-4 classCard">
                                        <header class="w3-container w3-blue">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling h4Margin">
                                                    Edit Tracking Code Type
                                                </h4>
                                                <span style="margin-right: 10px;">
                                                <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleCreateTrackingCode"> 
                                                    Create Tracking Code</button>
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleCreateTrackingCodeType"> 
                                                        Create Tracking Code Type</button>
                                            </span>
                                            </div>
                                        </header>
                                        <div class="all-users-infomation font-13 font-medium  own-user-select" style="margin-left: -9%;">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Tracking Code Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2" style="display: none;">

                                                    </div>

                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control" name="TrackingCodeTypeID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tracking Code Type ID">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2" style="display: none;">

                                                    </div>

                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control" name="Description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -67%;">
                                            <button type="submit" class="btn btn-secondary submit-btn">Save</button>&nbsp;

                                        </div>
                                    </div>


                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleCreateTrackingCode" tabindex="-1" role="dialog" aria-labelledby="exampleCreateTrackingCodeTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">


                <span class="color-black pl-1">Add New Tracking Code</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>
                                    Add New Tracking Code</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="CreateTrackingCodeForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-left: -5%;">



                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Tracking Code ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="TrackingCodeID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tracking Code ID">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="Description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Comments
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="Comments" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Tracking Code Type ID
                                                            
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="TrackingCodeTypeID" optionlabel="label" [options]="TrackingCodeTypesIdsArray" placeholder="Enter Tracking Code Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Marketing Campaign ID
                                                            
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="MarketingCampaignID" optionlabel="label" [options]="MarketingCampaignsIdsListsArray" placeholder="Enter Marketing Campaign ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Default Product Catalog ID
                                                            
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="DefaultProductCatalogID" optionlabel="label" [options]="ProductCatalogsIdsArray" placeholder="Enter Default Product Catalog ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control" formControlName="FromDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control" formControlName="ThroughDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Redirect URL		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="RedirectURL" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Redirect URL">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Override Logo URL	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="OverrideLogoURL" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Override Logo URL">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Override CSS URL	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="OverrideCSSURL" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Override CSS URL">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Trackable Lifetime	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="TrackableLifetime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Trackable Lifetime">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Billable Lifetime	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="BillableLifetime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Billable Lifetime">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Group ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="GroupID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Group ID">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Sub Group ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="SubGroupID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sub Group ID">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-6 main-submit-button" style="margin-left: -6%;">
                                            <button type="submit" class="btn btn-secondary submit-btn">Save</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleCreateTrackingCodeType" tabindex="-1" role="dialog" aria-labelledby="exampleCreateTrackingCodeTypeTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">


                <span class="color-black pl-1">Add New Tracking Code Type</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>
                                    Add New Tracking Code Type</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="CreateTrackingCodeTypeForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-left: -20%;">



                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-4 form-group classInput">
                                                        <label for="exampleInputEmail1">Tracking Code Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="TrackingCodeTypeID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tracking Code Type ID">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-4 form-group classInput">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="Description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-6 col-6 main-submit-button" style="margin-left: -4%;">
                                            <button type="submit" class="btn btn-secondary submit-btn">Save</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>