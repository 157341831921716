import { Component, OnInit } from '@angular/core';
//import {  Router } from '@angular/router';
//import { Location } from '@angular/common';
@Component({
  selector: 'app-dashboard-security',
  templateUrl: './dashboard-security.component.html',
  styleUrls: ['./dashboard-security.component.css']
})
export class DashboardSecurityComponent implements OnInit {

  constructor(
  //  readonly router: Router,
   // private _location: Location,
  ) { }

  ngOnInit(): void {
  }
//   backWindow(){
//     this._location.back();
//   }
//     toDashboard()
// {
//   this.router.navigate(['/security/security-dashboard']);
// }
}
