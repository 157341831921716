<div class="container-fluid main-container-wrapper">
    <br>


    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Interview Details </span>
            </div>
            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                        <li [ngClass]="activeCategoryMain==1?'active':''" (click)="JobRequisition()"><a>
                            Job Requisition</a></li>
                        <li [ngClass]="activeCategoryMain==2?'active':''" (click)="InternalJobPostingApplication()"><a>
                            Internal Job Posting Application</a></li>



                    </ul>
                </div>
                <div class="w3-card-4 classCard" style="margin: 11px">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                            <li [ngClass]="activeCateg==1?'active':''" (click)="jobInterview()"><a>
                                Job Interview</a></li>
                            <li [ngClass]="activeCateg==2?'active':''" (click)="approval()"><a>
                                Approval</a></li>
                            <li [ngClass]="activeCateg==3?'active':''" (click)="Relocation()"><a>
                                    Relocation</a></li>



                        </ul>
                    </div>
                    <div class=" bg-white color-grey">

                        <div>
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" style="padding: 15px 15px;">

                                        <div class="w3-card-4  w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                            <span class="headingAccords">Search</span>
                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalCenter">Create New Job Interview</button>

                                                            <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 69%!important; padding-top: 11px !important;
                                                                padding-bottom: 11px !important;">
                                                            </button>	
                                                        </div>
                    
                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                        <div class="card-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Job Interview ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown filter="true" [(ngModel)]="advanceSearch.jobInterviewIdSearchType" [ngModelOptions]="{standlone:true}" name="jobInterviewIdSearchType" [options]="opportunityIdArray" optionlabel="label">
                
                                                                            </p-dropdown>
                                                                        </div>
                
                                                                        <div class="col-lg-2 form-group">
                                                                            <p-dropdown filter="true" [options]="jIDdArray" [(ngModel)]="advanceSearch.jobInterviewId" name="jobInterviewId" [ngModelOptions]="{standlone:true}" aria-describedby="emailHelp" optionlabel="label" placeholder="Enter Job Interview ID">
                                                                            </p-dropdown>
                                                                        </div>
                
                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Job Interviewee Party ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [(ngModel)]="advanceSearch.jobIntervieweePartyId" name="jobIntervieweePartyId" [ngModelOptions]="{standlone:true}" [options]="partyIdArray" filter="true" optionlabel="label" placeholder="Enter Job Interviewee Party ID">
                                                                            </p-dropdown>
                                                                        </div>
                
                                                                    </div>
                                                                </div>
                
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Job Requisition ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [(ngModel)]="advanceSearch.jobRequisitionId" name="jobRequisitionId" [ngModelOptions]="{standlone:true}" [options]="getJobRequistionArray" filter="true" optionlabel="label" placeholder="Enter Job Requisition ID">
                                                                            </p-dropdown>
                                                                        </div>
                
                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Job Interviewer Party ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [(ngModel)]="advanceSearch.jobInterviewerPartyId" name="jobInterviewerPartyId" [ngModelOptions]="{standlone:true}" [options]="partyIdArray" filter="true" optionlabel="label" placeholder="Enter Job Interviewer Party ID">
                                                                            </p-dropdown>
                                                                        </div>
                
                
                
                                                                    </div>
                                                                </div>
                
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Job Interview Type ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" [options]="jobArray" [(ngModel)]="advanceSearch.jobInterviewTypeId" name="jobInterviewTypeId" [ngModelOptions]="{standlone:true}" optionlabel="label" placeholder="Enter Job Interview Type ID ">
                
                                                                            </p-dropdown>
                                                                        </div>
                
                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1"> Grade Secured Enum ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" [options]="enumArray" [(ngModel)]="advanceSearch.gradeSecuredEnumId" name="gradeSecuredEnumId" [ngModelOptions]="{standlone:true}" optionlabel="label" placeholder="Enter Grade Secured Enum ID ">
                
                                                                            </p-dropdown>
                                                                        </div>
                
                
                
                                                                    </div>
                                                                </div>
                
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Job Interview Result</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" [options]="resultStatus" [(ngModel)]="advanceSearch.jobInterviewResult" name="jobInterviewResult" [ngModelOptions]="{standlone:true}" optionlabel="label" placeholder="Enter Job Interview Result">
                
                                                                            </p-dropdown>
                                                                        </div>
                
                
                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Job Interview Date</label>
                                                                        </div>
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown [(ngModel)]="advanceSearch.jobInterviewDateFromSearch" [ngModelOptions]="{standlone:true}" filter="true" name="jobInterviewDateFromSearch" [options]="dateIdArray" optionlabel="label" checked>
                
                                                                            </p-dropdown>
                                                                        </div>
                
                                                                        <div class="col-lg-2 form-group">
                                                                            <input type="date" [(ngModel)]="advanceSearch.jobInterviewDateFrom" name="jobInterviewDateFrom" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                                        </div>
                                                                        <div class="col-lg-2 form-group">
                                                                            <input type="date" [(ngModel)]="advanceSearch.jobInterviewDateTo" name="jobInterviewDateTo" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                                        </div>
                
                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12" style="display: none;">
                                                                    <div class="row">
                                                                        <div class="col-lg-2 form-group classInput">
                                                                            <label for="exampleInputEmail1"></label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [(ngModel)]="advanceSearch.jobInterviewDateToSearch" [ngModelOptions]="{standlone:true}" filter="true" name="jobInterviewDateToSearch" [options]="dateIdArray" optionlabel="label" checked>
                
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-2"></div>
                
                
                
                                                                    </div>
                                                                </div>
                
                
                
                                                            </div>
        
                                                            <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -26%;"><button _ngcontent-cta-c128="" type="submit" (click)="advanceSearchContacts(false)" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                                <button type="button" (click)="reset();" class="btn btn-danger ml-2">Reset</button>
                                                            </div>
        
        
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <!-- <div class="w3-card-4 classCard">
                                            <div class="port-header-contact create-lead font-medium mb-0">
                                                <div class="suppliers-wrapper">
                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" data-toggle="modal" data-target="#exampleModalCenter">Create New Job Interview</button>
                                                    
                                                </div>
                                            </div>
                                            <br>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Job Interview ID</label>
                                                        </div>
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown filter="true" [(ngModel)]="advanceSearch.jobInterviewIdSearchType" [ngModelOptions]="{standlone:true}" name="jobInterviewIdSearchType" [options]="opportunityIdArray" optionlabel="label">

                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-2 form-group">
                                                            <p-dropdown filter="true" [options]="jIDdArray" [(ngModel)]="advanceSearch.jobInterviewId" name="jobInterviewId" [ngModelOptions]="{standlone:true}" aria-describedby="emailHelp" optionlabel="label" placeholder="Enter Job Interview ID">
                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                            <label for="exampleInputEmail1">Job Interviewee Party ID</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown [(ngModel)]="advanceSearch.jobIntervieweePartyId" name="jobIntervieweePartyId" [ngModelOptions]="{standlone:true}" [options]="partyIdArray" filter="true" optionlabel="label" placeholder="Enter Job Interviewee Party ID">
                                                            </p-dropdown>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Job Requisition ID</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown [(ngModel)]="advanceSearch.jobRequisitionId" name="jobRequisitionId" [ngModelOptions]="{standlone:true}" [options]="getJobRequistionArray" filter="true" optionlabel="label" placeholder="Enter Job Requisition ID">
                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                            <label for="exampleInputEmail1">Job Interviewer Party ID</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown [(ngModel)]="advanceSearch.jobInterviewerPartyId" name="jobInterviewerPartyId" [ngModelOptions]="{standlone:true}" [options]="partyIdArray" filter="true" optionlabel="label" placeholder="Enter Job Interviewer Party ID">
                                                            </p-dropdown>
                                                        </div>



                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Job Interview Type ID</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" [options]="jobArray" [(ngModel)]="advanceSearch.jobInterviewTypeId" name="jobInterviewTypeId" [ngModelOptions]="{standlone:true}" optionlabel="label" placeholder="Enter Job Interview Type ID ">

                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                            <label for="exampleInputEmail1"> Grade Secured Enum ID</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" [options]="enumArray" [(ngModel)]="advanceSearch.gradeSecuredEnumId" name="gradeSecuredEnumId" [ngModelOptions]="{standlone:true}" optionlabel="label" placeholder="Enter Grade Secured Enum ID ">

                                                            </p-dropdown>
                                                        </div>



                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Job Interview Result</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" [options]="resultStatus" [(ngModel)]="advanceSearch.jobInterviewResult" name="jobInterviewResult" [ngModelOptions]="{standlone:true}" optionlabel="label" placeholder="Enter Job Interview Result">

                                                            </p-dropdown>
                                                        </div>



                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Job Interview Date</label>
                                                        </div>
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown [(ngModel)]="advanceSearch.jobInterviewDateFromSearch" [ngModelOptions]="{standlone:true}" filter="true" name="jobInterviewDateFromSearch" [options]="dateIdArray" optionlabel="label" checked>

                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-2 form-group">
                                                            <input type="date" [(ngModel)]="advanceSearch.jobInterviewDateFrom" name="jobInterviewDateFrom" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <input type="date" [(ngModel)]="advanceSearch.jobInterviewDateTo" name="jobInterviewDateTo" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12" style="display: none;">
                                                    <div class="row">
                                                        <div class="col-lg-2 form-group classInput">
                                                            <label for="exampleInputEmail1"></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown [(ngModel)]="advanceSearch.jobInterviewDateToSearch" [ngModelOptions]="{standlone:true}" filter="true" name="jobInterviewDateToSearch" [options]="dateIdArray" optionlabel="label" checked>

                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-2"></div>



                                                    </div>
                                                </div>



                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -26%;"><button _ngcontent-cta-c128="" type="submit" (click)="advanceSearchContacts(false)" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                <button type="button" (click)="reset();" class="btn btn-danger ml-2">Reset</button>
                                            </div>
                                        </div> -->
                                        
                                        <br>
                                        <div class="w3-card-4 classCard">
                                            <header class="w3-container w3-blue">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling h4Margin">
                                                        Job Interview List
                                                    </h4>

                                                </div>
                                            </header>
                                            <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">

                                                        <div class="card own-account-table">
                                                            <p-table [value]="interviewId" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                Interview ID
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                            <div style="color: white;"> Job Interviewee Party ID
                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Requisition ID
                                                                                <p-sortIcon field="category"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="quantity">
                                                                            <div style="color: white;"> Interviewer Party ID
                                                                                <p-sortIcon field="quantity"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Interview Type ID
                                                                                <p-sortIcon field="price">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Enum ID
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Interview Date
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Result
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Created Date
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Action</div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                <span class="account-button"
                                                                                 (click)="detailPage(product.jobInterviewId)">
                                                                        {{product.jobInterviewId}}</span>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.jobIntervieweePartyId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.jobRequisitionId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.jobInterviewerPartyId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.jobInterviewTypeId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.gradeSecuredEnumId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.jobInterviewDate | date:'yyyy-MM-dd HH:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.jobInterviewResult}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.createdStamp |date:'yyyy-MM-dd HH:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            <button type="submit"  (click)="onDelete(product.jobInterviewId)"  class="btn btn-danger ">Delete</button>
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>

                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">New Job Interview</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid" style="width: 100%;">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!this.show" [ngClass]="activeCategory==2?'active':''"><a>
                                Add new Job interview </a></li>
                                <li *ngIf="this.show" [ngClass]="activeCategory==2?'active':''"><a>
                                    Update Job interview </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" *ngIf="jobInterviewFormService.job_interview_form" [formGroup]="jobInterviewFormService.job_interview_form">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Job Interviewee Party ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="intervieweePartyId" [options]="partyIdArray" filter="true" optionlabel="label" placeholder="Enter Job Interviewee Party ID">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!jobInterviewFormService.job_interview_form.controls.intervieweePartyId.invalid && !!jobInterviewFormService.job_interview_form.controls.intervieweePartyId.touched">  Job Interviewee Party ID   is required!</small>
                                                    </div>


                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Job Requisition ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="jobRequisitionId" [options]="getJobRequistionArray" filter="true" optionlabel="label" placeholder="Enter Job Requisition ID">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!jobInterviewFormService.job_interview_form.controls.jobRequisitionId.invalid && !!jobInterviewFormService.job_interview_form.controls.jobRequisitionId.touched">  Job Requisition ID  is required!
                                                        </small>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Job Interviewer Party ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="interviewerPartyId" [options]="partyIdArray" filter="true" optionlabel="label" placeholder="Enter Job Interviewer Party ID">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!jobInterviewFormService.job_interview_form.controls.interviewerPartyId.invalid && !!jobInterviewFormService.job_interview_form.controls.interviewerPartyId.touched">   Job Interviewer Party ID is required!</small>

                                                    </div>


                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Job Interview Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="jobArray" formControlName="interviewTypeId" optionlabel="label" placeholder="Enter Job Interview Type ID ">

                                                        </p-dropdown>


                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Grade Secured Enum ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="enumArray" formControlName="gradeSecuredEnum" optionlabel="label" placeholder="Enter Grade Secured Enum ID ">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Job Interview Result</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="resultStatus" formControlName="interviewResult" optionlabel="label" placeholder="Enter Job Interview Result">

                                                        </p-dropdown>


                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Job Interview Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="interviewDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Last name">
                                                    </div>
                                                    <div class="col-lg-2"></div>

                                                </div>
                                            </div>



                                        </div>
                                        <div class="main-submit-button" style="    margin-right: 7%;">
                                            <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn mr-2">Create</button>
                                            <!-- <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn mr-2">Update</button> -->


                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->
<ngx-spinner></ngx-spinner>