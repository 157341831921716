<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Contacts</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey " style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <!-- <li [ngClass]="activeCategories==1?'active':''" (click)="toAccount()"><a>
                                Account</a></li> -->
                            <li [ngClass]="activeCategories==2?'active':''" (click)="toContact()" ><a>
                                Contact</a></li>
                            
                                <!-- <li [ngClass]="activeCategories==3?'active':''" (click)="toCases()" ><a>
                                    Cases</a></li> -->
                            
                            <!-- <li [ngClass]="activeCategories==3?'active':''" (click)="toOpportunity()" ><a>
                                Opportunity</a></li> -->
<!-- 
                            <li [ngClass]="activeCategories==4?'active':''" (click)="toActivities()" ><a>
                                Activities</a></li> -->
<!-- 
                                <li [ngClass]="activeCategories==5?'active':''" (click)="toQuotes()"><a>
                                    Quotes </a></li>

                            <li [ngClass]="activeCategories==6?'active':''" (click)="toSalesOrder()"><a>
                                    Sales order</a></li> -->

                            
                            
                            </ul>
                    </div>

                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                    <div *ngIf="activeCategories==2">

                                        <div class="w3-card-4 w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="background:#F5F7FA!important; padding: 4px !important;white-space: nowrap;">
                                                        <span style="margin-left: 12px!important;"> Find Contacts</span>
                                                        <button class="btn btn-outline-secondary ml-2 " routerLinkActive="active" routerLink="/crm/my-contacts">All Contacts</button>
                                                        <button routerLink="/crm/my-contacts/contact" type="button" class="btn btn-outline-secondary">
                                                            My Contacts</button>
                                                        <button type="button" class="btn btn-outline-secondary" data-toggle="modal" data-target="#exampleModalCenter">Create New Contact</button>
                                                        <button type="button" routerLink="/crm/my-contacts/import-contact" [queryParams]="{through:'AllContact'}" style="width: 100px;margin-left: 22%;" class="btn btn-outline-secondary bg-brown ">Import</button>
                                                        <button type="button" (click)="export()" style="width: 100px;" class="btn btn-danger" routerLinkActive="active">Export</button>
                                                        <button type="button" class="btn btn-outline-secondary" data-toggle="modal"
                                                        data-target="#exampleModal">+ More Filters</button>
                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 0%!important; padding-top: 11px !important;
                                                        padding-bottom: 11px !important;"></button>									

                                                    </div>

                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                        <div class="card-body">
                                                            
                                                            <div class="all-users-infomation font-13 font-medium  own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Contact ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown styleClass="small-dropdown" filter="true" [(ngModel)]="advanceSearch.partyIdSearchType" [ngModelOptions]="{standalone:true}" name="party" [options]="opportunityIdArray" optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input class="form-control" type="email" [(ngModel)]="advanceSearch.partyId" name="partyId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Contact ID">
                                                                        </div>
                        
                                                                        <div class="col-lg-3 form-group rightFormA ">
                                                                            <label for="exampleInputEmail1">First Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown styleClass="small-dropdown" filter="true" [(ngModel)]="advanceSearch.firstNameSearchType" [ngModelOptions]="{standlone:true}" name="firstNameFilter" [options]="opportunityIdArray" optionlabel="label">
                        
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input class="form-control" type="email" [(ngModel)]="advanceSearch.firstName" name="firstName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"  aria-describedby="emailHelp" placeholder="Enter First Name">
                                                                        </div>
                        
                                                                    </div>
                                                                </div>
                        
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Last Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown styleClass="small-dropdown" [(ngModel)]="advanceSearch.lastNameSearchType" name="lastName" [ngModelOptions]="{standlone:true}" filter="true" name="lastNameFilter" [options]="opportunityIdArray" optionlabel="label" checked>
                        
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input class="form-control" type="email" [(ngModel)]="advanceSearch.lastName" name="lastName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name">
                                                                        </div>
                        
                        
                                                                    </div>
                                                                </div>
                        
                        
                                                            </div>

                                                            <div class="col-lg-6 col-12 main-submit-button " style="margin-left: 17%;"><button (click)="advanceSearchContacts(false)" type="submit" class="btn btn-secondary submit-btn">Find</button>
                                                                <button type="submit" class="btn btn-danger ml-2" (click)="resetFilter()">Reset</button>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        </div>


                                        <!-- <div class="w3-card-4 classCard">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text" style="width: 10%;">Find Contacts</p>
                                                <button class="btn btn-outline-secondary " routerLinkActive="active" routerLink="/crm/my-contacts">All Contacts</button>
                                                <button routerLink="/crm/my-contacts/contact" type="button" class="btn btn-outline-secondary">
                                                    My Contacts</button>
                                                <button type="button" class="btn btn-outline-secondary" data-toggle="modal" data-target="#exampleModalCenter">Create New Contact</button>
                                                <button type="button" routerLink="/crm/my-contacts/import-contact" [queryParams]="{through:'AllContact'}" style="width: 100px;margin-left: 28%;" class="btn btn-outline-secondary bg-brown ">Import</button>
                                                <button type="button" (click)="export()" style="width: 100px;" class="btn btn-danger" routerLinkActive="active">Export</button>
                                                <button type="button" class="btn btn-outline-secondary" data-toggle="modal"
                                                data-target="#exampleModal">+ More Filters</button>
                                            </div>
                                            <br>
                                            <div class="all-users-infomation font-13 font-medium  own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                            <label for="exampleInputEmail1">Contact ID</label>
                                                        </div>
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown styleClass="small-dropdown" filter="true" [(ngModel)]="advanceSearch.partyIdSearchType" [ngModelOptions]="{standalone:true}" name="party" [options]="opportunityIdArray" optionlabel="label">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input class="form-control" type="email" [(ngModel)]="advanceSearch.partyId" name="partyId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Contact ID">
                                                        </div>
        
                                                        <div class="col-lg-3 form-group rightFormA ">
                                                            <label for="exampleInputEmail1">First Name</label>
                                                        </div>
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown styleClass="small-dropdown" filter="true" [(ngModel)]="advanceSearch.firstNameSearchType" [ngModelOptions]="{standlone:true}" name="firstNameFilter" [options]="opportunityIdArray" optionlabel="label">
        
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input class="form-control" type="email" [(ngModel)]="advanceSearch.firstName" name="firstName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"  aria-describedby="emailHelp" placeholder="Enter First Name">
                                                        </div>
        
                                                    </div>
                                                </div>
        
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                            <label for="exampleInputEmail1">Last Name</label>
                                                        </div>
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown styleClass="small-dropdown" [(ngModel)]="advanceSearch.lastNameSearchType" name="lastName" [ngModelOptions]="{standlone:true}" filter="true" name="lastNameFilter" [options]="opportunityIdArray" optionlabel="label" checked>
        
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input class="form-control" type="email" [(ngModel)]="advanceSearch.lastName" name="lastName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name">
                                                        </div>
        
        
                                                    </div>
                                                </div>
        
        
                                            </div>
                                            <div class="col-lg-6 col-12 main-submit-button " style="margin-left: 17%;"><button (click)="advanceSearchContacts(false)" type="submit" class="btn btn-secondary submit-btn">Find</button>
                                                <button type="submit" class="btn btn-danger ml-2" (click)="resetFilter()">Reset</button>
                                            </div>
                                        </div> -->
                                        <br>
                                        <div class="w3-card-4 classCard">
                                            <header class="w3-container w3-blue">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling h4Margin">
                                                        Contact List
                                                    </h4>
        
                                                </div>
                                            </header>
                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <!--  <label for="exampleInputEmail1">Contact List</label> -->
                                                        <!-- own-account-table -->
                                                        <div class="card own-account-table">
                                                            <p-table [responsive]=true [value]="getContacts" (onLazyLoad)="loadAllEmployees($event)" [paginator]="true" [rows]="rows" scrollWidth="100%" [resizableColumns]="true" columnResizeMode="expand" [rowsPerPageOptions]="[5,25,50,60,80,100]" [totalRecords]="total"
                                                                [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                Contact ID
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
        
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">First Name
                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                            </div>
        
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">Last Name
                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                            </div>
        
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'250px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Email
                                                                                <p-sortIcon field="category"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'180px'}" pSortableColumn="quantity">
                                                                            <div style="color: white;">Phone Number
                                                                                <p-sortIcon field="quantity">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                            <div style="color: white;"> City
                                                                                <p-sortIcon field="quantity"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Country
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Related Customer
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Created Date
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                            </div>
        
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}" (click)="contactsDetail(product.partyId)">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                              <span  class="account-button"> {{product.partyId}}</span> 
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">{{product.firstName}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">{{product.lastName}}
                                                                        </td>
        
                                                                        <td [ngStyle]="{'width':'250px'}">
                                                                            {{product.email?.infoString}}</td>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.telenumber?.countryCode}} {{product.telenumber?.areaCode}} {{product.telenumber?.contactNumber}} </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.address?.city}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.address?.countryGeoId}}</td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div *ngFor="let data of product.relatedCompanies; first as isFirst">
                                                                                <span *ngIf="isFirst">
                                                                                    {{data.partyIdFrom}}
                                                                                </span>
                                                                            </div>
        
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.createdStamp | date:'yyyy-MM-dd HH:mm:ss'}}
                                                                        </td>
        
        
        
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
        
                                                            <div class="pagen">
                                                                <p class="paginate_data">
                                                                    View per page
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Contacts </span>
                <span class="color-grey search-icons">
                    <div class="suppliers-wrapper">
                      
                    </div>
                </span>
            </div>
            <div class=" bg-white color-grey">

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="w3-card-4 classCard">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <p class="h-text" style="width: 10%;">Find Contacts</p>
                                        <button class="btn btn-outline-secondary " routerLinkActive="active" routerLink="/crm/my-contacts">All Contacts</button>
                                        <button routerLink="/crm/my-contacts/contact" type="button" class="btn btn-outline-secondary">
                                            My Contacts</button>
                                        <button type="button" class="btn btn-outline-secondary" data-toggle="modal" data-target="#exampleModalCenter">Create New Contact</button>
                                        <button type="button" routerLink="/crm/my-contacts/import-contact" [queryParams]="{through:'AllContact'}" style="width: 100px;margin-left: 30%;" class="btn btn-outline-secondary bg-brown ">Import</button>
                                        <button type="button" (click)="export()" style="width: 100px;" class="btn btn-danger" routerLinkActive="active">Export</button>
                                        <button type="button" class="btn btn-outline-secondary" data-toggle="modal"
                                        data-target="#exampleModal">+ More Filters</button>
                                    </div>
                                    <br>
                                    <div class="all-users-infomation font-13 font-medium  own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Contact ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown styleClass="small-dropdown" filter="true" [(ngModel)]="advanceSearch.partyIdSearchType" [ngModelOptions]="{standalone:true}" name="party" [options]="opportunityIdArray" optionlabel="label">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input class="form-control" type="email" [(ngModel)]="advanceSearch.partyId" name="partyId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Contact ID">
                                                </div>

                                                <div class="col-lg-3 form-group rightFormA ">
                                                    <label for="exampleInputEmail1">First Name</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown styleClass="small-dropdown" filter="true" [(ngModel)]="advanceSearch.firstNameSearchType" [ngModelOptions]="{standlone:true}" name="firstNameFilter" [options]="opportunityIdArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input class="form-control" type="email" [(ngModel)]="advanceSearch.firstName" name="firstName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"  aria-describedby="emailHelp" placeholder="Enter First Name">
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Last Name</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown styleClass="small-dropdown" [(ngModel)]="advanceSearch.lastNameSearchType" name="lastName" [ngModelOptions]="{standlone:true}" filter="true" name="lastNameFilter" [options]="opportunityIdArray" optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input class="form-control" type="email" [(ngModel)]="advanceSearch.lastName" name="lastName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name">
                                                </div>


                                            </div>
                                        </div>


                                    </div>
                                    <div class="col-lg-6 col-12 main-submit-button " style="margin-left: 17%;"><button (click)="advanceSearchContacts(false)" type="submit" class="btn btn-secondary submit-btn">Find</button>
                                        <button type="submit" class="btn btn-danger ml-2" (click)="resetFilter()">Reset</button>
                                    </div>
                                </div>
                                <br>
                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Contact List
                                            </h4>

                                        </div>
                                    </header>
                                    <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">
                                                  <label for="exampleInputEmail1">Contact List</label> -->
                                                <!-- own-account-table 
                                                <div class="card own-account-table">
                                                    <p-table [responsive]=true [value]="getContacts" (onLazyLoad)="loadAllEmployees($event)" [paginator]="true" [rows]="rows" scrollWidth="100%" [resizableColumns]="true" columnResizeMode="expand" [rowsPerPageOptions]="[5,25,50,60,80,100]" [totalRecords]="total"
                                                        [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                </div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                        </p-checkbox>
                                                                        Contact ID
                                                                        <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                    <div style="color: white;">First Name
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                    <div style="color: white;">Last Name
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>

                                                                </th>
                                                                <th [ngStyle]="{'width':'250px'}" pSortableColumn="category">
                                                                    <div style="color: white;"> Email
                                                                        <p-sortIcon field="category"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'180px'}" pSortableColumn="quantity">
                                                                    <div style="color: white;">Phone Number
                                                                        <p-sortIcon field="quantity">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                    <div style="color: white;"> City
                                                                        <p-sortIcon field="quantity"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Country
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Related Customer
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Created Date
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                    </div>

                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'170px'}" (click)="contactsDetail(product.partyId)">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                </div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                        </p-checkbox>
                                                                        {{product.partyId}}
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">{{product.firstName}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">{{product.lastName}}
                                                                </td>

                                                                <td [ngStyle]="{'width':'250px'}">
                                                                    {{product.email?.infoString}}</td>
                                                                <td [ngStyle]="{'width':'180px'}">
                                                                    {{product.telenumber?.countryCode}} {{product.telenumber?.areaCode}} {{product.telenumber?.contactNumber}} </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.address?.city}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.address?.countryGeoId}}</td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    <div *ngFor="let data of product.relatedCompanies; first as isFirst">
                                                                        <span *ngIf="isFirst">
                                                                            {{data.partyIdFrom}}
                                                                        </span>
                                                                    </div>

                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.createdStamp | date:'yyyy-MM-dd HH:mm:ss'}}
                                                                </td>



                                                            </tr>
                                                        </ng-template>
                                                    </p-table>

                                                    <div class="pagen">
                                                        <p class="paginate_data">
                                                            View per page
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div> -->
<div class="container demo">
    <div class="modal left fade" id="exampleModal" tabindex="" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <div>More Filters</div>
                    <div class="close-modal"><button type="button" class="btn" data-dismiss="modal">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20.487" height="20.488"
                                viewBox="0 0 20.487 20.488">
                                <g transform="translate(306.243 -227.756)">
                                    <g transform="translate(-306.243 227.757)">
                                        <path
                                            d="M11.375,10.244l8.877-8.877A.8.8,0,0,0,19.121.235L10.243,9.113,1.366.235A.8.8,0,0,0,.234,1.367l8.877,8.877L.234,19.122a.8.8,0,0,0,1.132,1.132l8.877-8.877,8.877,8.877a.8.8,0,0,0,1.132-1.132Z"
                                            transform="translate(0 0)" />
                                    </g>
                                </g>
                            </svg>
                        </button></div>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="all-users-infomation font-13 font-medium own-user-select">
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-3 form-group rightFormT">
                                        <label for="exampleInputEmail1">Contact ID</label>
                                    </div>
                                    <!--   <div class="col-lg-4">
                                        <p-dropdown filter="true" (ngModelChange)="onStatusChange($event)"
                                                [options]="opportunityIdArray" optionlabel="label"
                                                [(ngModel)]="advanceSearch.partyIdSearchType"
                                                [ngModelOptions]="{standlone:true}">

                                            </p-dropdown>
                                    </div> -->
                                    <div class="col-lg-4">
                                        <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="advanceSearch.partyId" [ngModelOptions]="{standlone:true}" aria-describedby="emailHelp" placeholder="Enter Contact Id">
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-3 form-group rightFormT">
                                        <label for="exampleInputEmail1">First Name</label>
                                    </div>
                                    <!--   <div class="col-lg-4">
                                        <p-dropdown [(ngModel)]="advanceSearch.firstNameSearchType"
                                        (ngModelChange)="onNameChange(firstNameSearchType)"
                                        [ngModelOptions]="{standlone:true}" filter="true"
                                        [options]="opportunityIdArray" optionlabel="label">

                                       </p-dropdown>
                                       </div> -->
                                    <div class="col-lg-4">
                                        <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="advanceSearch.firstName" [ngModelOptions]="{standlone:true}" aria-describedby="emailHelp" placeholder="Enter First Name">
                                    </div>

                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-3 form-group rightFormT">
                                        <label for="exampleInputEmail1">Last Name</label>
                                    </div>
                                    <!--  <div class="col-lg-4">
                                        <p-dropdown (ngModelChange)="onLastNameChange(lastNameSearchType)"
                                        filter="true" [options]="opportunityIdArray" optionlabel="label"
                                        [(ngModel)]="advanceSearch.lastNameSearchType"
                                        [ngModelOptions]="{standlone:true}" optionLabel="label">

                                    </p-dropdown>
                                    </div> -->
                                    <div class="col-lg-4">
                                        <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="advanceSearch.lastName" [ngModelOptions]="{standlone:true}" aria-describedby="emailHelp" placeholder="Enter Last Name">
                                    </div>


                                </div>
                            </div>



                        </div>
                    </div>
                </div>
                <div class="suppliers-wrapper modal-footer">
                    <button type="button" (click)="advanceSearchContacts(true)" class="btn btn-outline-secondary active">Submit</button>
                    <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-danger ml-2" (click)="resetFilter()">Reset</button>
                </div>
            </div>

        </div>
    </div>
</div>


<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span *ngIf="!this.show" class="color-black pl-1"> Create-Contact</span>
                <span *ngIf="this.show" class="color-black pl-1"> Update-Contact</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)">
                                    <a>
                                        General Information</a>
                                </li>
                                <li [ngClass]="activeCategory==3?'active':''" (click)="generalInfo(createContactService.create_contact_form.value.firstName,createContactService.create_contact_form.value.lastName)">
                                    <a>
                                        Address</a>
                                </li>

                                <li [ngClass]="activeCategory==4?'active':''" (click)="addressInformation(createContactService.create_contact_form.value.address1,createContactService.create_contact_form.value.countryGeoId,
                          createContactService.create_contact_form.value.stateProvinceGeoId,createContactService.create_contact_form.value.city
                          ,createContactService.create_contact_form.value.postalCode)"><a>
                                        Phone information </a></li>
                                <li [ngClass]="activeCategory==5?'active':''" (click)="phoneInfo(createContactService.create_contact_form.value.contactNumber)">
                                    <a>
                                        E-Mail Address Information </a>
                                </li>
                                <li [ngClass]="activeCategory==8?'active':''" (click)="emailInformationAdded(createContactService.create_contact_form.value.emailAddress)">
                                    <a>
                                        Contact List </a>
                                </li>



                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="createContactService.create_contact_form" [formGroup]="createContactService.create_contact_form" class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                            <div class="w-100">
                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                    <div *ngIf="activeCategory==2">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                General Information
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">First name
                                                                                <span style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" formControlName="firstName" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="First name">


                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Last Name
                                                                                <span style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" formControlName="lastName" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Last name">


                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Suffix</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" formControlName="suffix" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Suffix">
                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Gender</label>
                                                                        </div>
                                                                        <div class="col-lg-3 dropdowns" >
                                                                            <p-dropdown styleClass="small-dropdown" filter="true" formControlName="gender" [options]="gender" optionlabel="label" placeholder="Select Gender">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12" *ngIf="show">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Comments</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" formControlName="comments" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">External
                                                                                Id</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" formControlName="externalId" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter External Id">
                                                                        </div>


                                                                    </div>
                                                                </div>


                                                                <div class="col-lg-12" *ngIf="show">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Status
                                                                                Id</label>
                                                                        </div>
                                                                        <div class="col-lg-3 dropdowns" >
                                                                            <p-dropdown styleClass="small-dropdown" *ngIf="show" filter="true" formControlName="statusId" [options]="crmArray" optionlabel="label" placeholder="Select Status Id">
                                                                            </p-dropdown>
                                                                        </div>


                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div class="main-submit-button" style="margin-right: 19%;">
                                                                <button type="submit" (click)="generalInformation(createContactService.create_contact_form.value.firstName,createContactService.create_contact_form.value.lastName)" class="btn btn-secondary submit-btn">Continue</button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div *ngIf="activeCategory==3">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                Address
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Address 1
                                                                                <span style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" formControlName="address1" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Address 1">


                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Address
                                                                                2</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" formControlName="address2" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Address 2">
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Country
                                                                                <span style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-3 dropdowns">
                                                                            <p-dropdown styleClass="small-dropdown" filter="true" [options]="array" formControlName="countryGeoId" (ngModelChange)="onCountryChange($event)">
                                                                            </p-dropdown>

                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">State <span
                                                                                    style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-3 dropdowns">
                                                                            <p-dropdown styleClass="small-dropdown" filter="true" formControlName="stateProvinceGeoId" [options]="stateArray" (ngModelChange)="onStateChange($event)" placeholder="Select State" optionlabel="label">
                                                                            </p-dropdown>


                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">City <span
                                                                                    style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" formControlName="city" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter City">


                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Zip/Postal
                                                                                Code
                                                                                <span style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" formControlName="postalCode" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Zip Code">


                                                                        </div>


                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div class="main-submit-button" style="    margin-right: 19%;">
                                                                <button type="submit" (click)="changeactiveCategoryFunction(2)" class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                <button type="submit" (click)="addressInfo(createContactService.create_contact_form.value.address1,createContactService.create_contact_form.value.countryGeoId,
                                                    createContactService.create_contact_form.value.stateProvinceGeoId,createContactService.create_contact_form.value.city
                                                    ,createContactService.create_contact_form.value.postalCode)" class="btn btn-secondary submit-btn">Continue</button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div *ngIf="activeCategory==4">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                Phone Information
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormD">
                                                                            <label for="exampleInputEmail1">Country
                                                                                Code</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" formControlName="countryCode" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter country Code">
                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Area
                                                                                Code</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" formControlName="areaCode" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Area Code">
                                                                        </div>


                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormD">
                                                                            <label for="exampleInputEmail1">Phone Number
                                                                                <span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" formControlName="contactNumber" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Phone Number">


                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Extension</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" formControlName="extension" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Extension">
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                            </div>
                                                            <div class="main-submit-button" style="    margin-right: 17%;">
                                                                <button type="submit" (click)="changeactiveCategoryFunction(3)" class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                <button type="submit" (click)="phoneInformation(createContactService.create_contact_form.value.contactNumber)" class="btn btn-secondary submit-btn">Continue</button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div *ngIf="activeCategory==5">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                E-Mail Address Information
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormAB">
                                                                            <label for="exampleInputEmail1">Email <span
                                                                                    style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" formControlName="emailAddress" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Email Address">


                                                                        </div>


                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div class="main-submit-button" style="margin-right: 68%;">
                                                                <button type="submit" (click)="changeactiveCategoryFunction(4)" class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                <button type="submit" (click)="emailInformation(createContactService.create_contact_form.value.emailAddress)" class="btn btn-secondary submit-btn">Continue</button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div *ngIf="activeCategory==8">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                Contact List
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Contact
                                                                                List</label>

                                                                        </div>
                                                                        <div class="col-lg-3 dropdowns">
                                                                            <p-dropdown styleClass="small-dropdown" filter="true" formControlName="contactListId" [options]="contactListArray" optionlabel="label"></p-dropdown>
                                                                        </div>


                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div class="main-submit-button" style="margin-right: 62%;">
                                                                <button type="submit" (click)="changeactiveCategoryFunction(5)" class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>
                                                                <button *ngIf="show" type="submit" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>

                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>








                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<ngx-spinner></ngx-spinner>