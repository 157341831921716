import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
 import {URLS} from '../../../app.constant';
@Injectable({
  providedIn: 'root'
})
export class EmployeeLeaveService {

  constructor(readonly http: HttpClient){

  }
  getLeaveApproval(size,searchEmployeeLeaveForm):Observable<any>{
    const leaveApproval = URLS.EmployeeLeaveApprovals + "?pageSize=" + size.pageSize + "&pageNo=" + size.pageNo;
    return this.http.post(leaveApproval,searchEmployeeLeaveForm);
  }
  deleteLeave(partyId,leaveTypeId,fromDate):Observable<any> {

	  const deleteLeave = URLS.deleteLeave.replace(":partyId",partyId).replace(":leaveTypeId",leaveTypeId).replace(":fromDate",fromDate);
    return this.http.delete(deleteLeave);
  }

 employeeLeaveList(request,searchEmployrrLeaveForm):Observable<any> {
	 const getEmployeeList = URLS.getEmployeeLeave + "?pageNo=" + request.pageNo + "&pageSize=" + request.pageSize;
	 return this.http.post(getEmployeeList,searchEmployrrLeaveForm);
 } 
 getLeaveReasonType():Observable<any>{
    const leaveReasonType = URLS.leaveTypeId;
    return this.http.get(leaveReasonType)
  }
  getLeaveStatus():Observable<any>{
    const getLeaveStatus = URLS.getLeaveStatus;
    return this.http.get(getLeaveStatus)
  }
  


}
