import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { SkillService } from "./skill.service";
import { ToastrService } from 'ngx-toastr';
import { AddSkillsService } from '../add-skills/add-skill-service';
import { AddSkillService } from '../add-skills/add-skill.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
import { Location } from '@angular/common';

declare var $: any;
@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})
export class SkillsComponent implements OnInit {
  show: boolean;
  @ViewChild('closebutton') closebutton;
  products3: any;
  total=0;
  rowNumber=0;
  pageNo = 1;
  pageSize= 100;
  rows = 50;
  skillList: any;
  opportunityIdArray: any = [];
  getSkillLists: any;
  skillArray: any = [];
  personId: any;
  partyIdArray: any = [];
  dateIdArray: any = []
  advanceSearch: {

    partyId: string,
    skillTypeId: string,
    searchExperience: string,
    yearsExperience: string,

    searchRating: string,
    rating: string,
    searchSkillLevel: string,
    skillLevel: string,
    estimatedThroughDate: string,
    estimatedThruDateTo: string,
    estimatedThruDateFrom: string,
    estimatedThroughDateStart: string


  };
  activeCategory=2;
  constructor(readonly accountsService: AccountsService,
private _location: Location,
readonly router: Router,
    readonly toastr: ToastrService,
    readonly skillService: SkillService,
    readonly spinner:NgxSpinnerService,
    public addSkillService: AddSkillService,
    public addSkillsService:AddSkillsService,
    ) {
    this.advanceSearch = {

      partyId: "",
      skillTypeId: "",

      searchExperience: "Contains",
      yearsExperience: "",
      searchRating: "Contains",
      rating: "",
      searchSkillLevel: "Contains",
      skillLevel: "",

      estimatedThruDateFrom: "",
      estimatedThruDateTo: "",
      estimatedThroughDateStart: "Equals",


      estimatedThroughDate: "Equals"

    };
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
    this.dateIdArray = [
      {
        label: 'Less Than',
        value: 'LessThan'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },

      {
        label: 'Greater Than',
        value: 'GreaterThan'
      },
      {
        label: 'Greater Than Equal To',
        value: 'GreaterThanEqualTo'
      },
      {
        label: 'Less Than Equal To',
        value: 'LessThanEqualTo'
      },
      {
        label: 'Is Empty',
        value: 'IsEmpty'
      }

    ];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.addSkillsService.add_skill_form = null;
    this.addSkillsService.addSkillForm(this.addSkillsService.add_skill_form);
    this.getSkillList();
    this.getSkillListtype();
    $('.right-arrow').click(function () {
      $(this).toggleClass('rotate-up');
    });
    // this.getSkillList();
    this.getPartyId();
    this.getSkillListView();
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])

  }
  cancelSubmit(){
    this._location.back();
  }
  
   

  getSkillListtype() {
    this.spinner.show();
    this.addSkillService.getSkillTypeList().subscribe(res=> {
      this.getSkillLists = res.data;
      this.spinner.hide();
      for(const value of this.getSkillLists) {
        this.skillArray.push({
          label:value.description,
          value:value.skillTypeId
        })
      }
     
    })
   
  }

  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res=> {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for(const value of this.personId) {
        this.partyIdArray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
    })
   
  }


  getSkillListView() {
    this.spinner.show();
    this.addSkillService.getSkillTypeList().subscribe(res => {
      this.getSkillLists = res.data;
      this.spinner.hide();
      for (const value of this.getSkillLists) {
        this.skillArray.push({
          label: value.description,
          value: value.skillTypeId
        })
      }
      
    })
   
  }

  // getPartyId() {
  //   this.spinner.show();
  //   this.addSkillService.getPartyId().subscribe(res => {
  //     this.personId = res.data.PersonsGroups;
  //     this.spinner.hide();
  //     for (const value of this.personId) {
  //       this.partyIdArray.push({
  //         label: value.partyId,
  //         value: value.partyId
  //       })
  //     }
  //   })
   
  // }
  onUpdate(id, skid, rating, skill, year) {
    this.router.navigate(["/hr/skills/add-skills"], { queryParams: { updateId: id, skid: skid, rate: rating, skill: skill, year: year } })
  }
  detailPage(id, skid, rating, skill, year) {
    this.router.navigate(["/hr/skills/skill-summary"], { queryParams: { updateId: id, skid: skid, rate: rating, skill: skill, year: year } })
  }
  onDelete(partyId, skillTypeId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.skillService.deleteSkills(partyId, skillTypeId).subscribe(res => {
         
          this.resetFilter();
          this.getSkillList();
          this.router.navigate(["/hr/skills"])
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )  } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    }) }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  getSkillList() {
    this.spinner.show();
    const req = {
      "pageNo": this.pageNo,
      "pageSize": this.pageSize
    }
    let skillList = {
      partyId: "",
      rating: "",
      skillLevel: "",
      skillTypeId: "",
      yearsExperience: ""
    }


    this.skillService.getSkillList(req, skillList).subscribe(res => {
      this.skillList = res.data;
      this.spinner.hide();
    })
   
  }

  advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    this.skillList = [];
    const res = {
      "pageNo": this.pageNo,
      "pageSize": this.pageSize
    }
    let req = {

      'partyId': this.advanceSearch.partyId ? this.advanceSearch.partyId : '',
      'rating': this.advanceSearch.rating ? this.advanceSearch.rating : '',
      'skillLevel': this.advanceSearch.skillLevel ? this.advanceSearch.skillLevel : '',
      'skillTypeId': this.advanceSearch.skillTypeId ? this.advanceSearch.skillTypeId : '',
      'yearsExperience': this.advanceSearch.yearsExperience ? this.advanceSearch.yearsExperience : '',

    }
    this.skillService.getSkillList(res, req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.skillList = resp.data;

        } else {
       
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          
        }
      });
  }
  resetFilter() {
    this.spinner.show();
    this.advanceSearch.partyId = "";
    this.advanceSearch.rating = "";
    this.advanceSearch.skillTypeId = ""
    this.advanceSearch.yearsExperience = ""
    this.advanceSearch.skillLevel = ""
    const req = {
      "pageNo": this.pageNo,
      "pageSize": this.pageSize
    }
    let skillList = {
      partyId: "",
      rating: "",
      skillLevel: "",
      skillTypeId: "",
      yearsExperience: ""
    }


    this.skillService.getSkillList(req, skillList).subscribe(res => {
      this.skillList = res.data;
      this.spinner.hide();
    })
  
  }

  onSubmit() {
    this.spinner.show();
    const formData = {...this.addSkillsService.add_skill_form.value};
    this.addSkillService.createSkills(formData).subscribe((res:any)=> {
      if(res.success) {
        this.spinner.hide();
        this.toastr.success("Skill Subbmitted Successfully");
        this.addSkillsService.add_skill_form.reset();
        this.getSkillList();
        this.closebutton.nativeElement.click();
        // this.router.navigate(['/hr/skills'])
      }

      else if(res.success == false) {
        this.toastr.error(res.message);
        this.spinner.hide();
      }
    },(err) => {
      for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }
        this.spinner.hide();
   })

  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
