<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Employment</span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>

            </div>
            <div class=" bg-white color-grey">


                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">

                                <div class="w3-card-4  w-100">
                                    <div class="accordion classCard" id="myAccordion">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                    <p class="h-text">Find Employment</p>
                                                    <!--new code-->
                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" style="width: 113px;" data-toggle="modal" data-target="#exampleModalCenter">Create Employment</button>
                                                    
                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 65%!important; padding-top: 11px !important;
                                                        padding-bottom: 11px !important;">
                                                    </button>

                                                </div>
            
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div class="card-body">
                                                    <div class="all-users-infomation font-13 font-medium  own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Internal Organization</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" placeholder="Enter Internal Organization" [(ngModel)]="advanceSearch.internalOrganization" [options]="InternalOrganizationArray" optionlabel="label" [ngModelOptions]="{standalone:true}" name="internalOrganization">
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Employee Party ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.employePartyId" [options]="partyIdArray" optionlabel="label" [ngModelOptions]="{standlone:true}" name="employePartyId" placeholder="Enter Employee Party Id">
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                            </div>
                                                        </div>
                
                                                        <!-- <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">First Name</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="email" [(ngModel)]="advanceSearch.firstName" name="firstName"
                                                                     [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" 
                                                                     aria-describedby="emailHelp" placeholder="Enter First Name" class="in-rad pl-2">
                                                                </div>
                
                
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Last Name</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="email" [(ngModel)]="advanceSearch.lastName" name="lastName" 
                                                                    [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" 
                                                                    aria-describedby="emailHelp" placeholder="Enter Last Name" class="in-rad pl-2">
                                                                </div>
                
                                                            </div>
                                                        </div> -->
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">From Date</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.fromdateFrom" name="firstName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name">
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.fromDateTo" name="firstName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name">
                                                                </div>
                
                
                
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Through Date</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input class="form-control " type="date" class="form-control" [(ngModel)]="advanceSearch.throughDateFrom" name="lastName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name">
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="date" [(ngModel)]="advanceSearch.throughDateTo" name="lastName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Termination Reason ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.terminationReasonID" [options]="TerminationReasonIDArray" optionlabel="label" [ngModelOptions]="{standalone:true}" name="terminationReasonID" placeholder="Enter Termination Reason ID">
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Termination Type ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.TerminationTypeID" [options]="TerminationTypeIDArray" optionlabel="label" [ngModelOptions]="{standlone:true}" name="TerminationTypeID" placeholder="Enter Termination Type ID">
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                            </div>
                                                        </div>
                
                
                
                
                                                    </div>

                                                    <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -24%;"><button type="submit" (click)="getFindEmployment()" class="btn btn-secondary submit-btn"> Find</button>&nbsp;
                                                        <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                                <!-- <div class="w3-card-4 classCard">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <p class="h-text">Find Employment</p>
                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" style="width: 113px;" data-toggle="modal" data-target="#exampleModalCenter">Create Employment</button>

                                    </div>
                                    <br>
                                    <div class="all-users-infomation font-13 font-medium  own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Internal Organization</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">

                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" placeholder="Enter Internal Organization" [(ngModel)]="advanceSearch.internalOrganization" [options]="InternalOrganizationArray" optionlabel="label" [ngModelOptions]="{standalone:true}" name="internalOrganization">
                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Employee Party ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">

                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.employePartyId" [options]="partyIdArray" optionlabel="label" [ngModelOptions]="{standlone:true}" name="employePartyId" placeholder="Enter Employee Party Id">

                                                    </p-dropdown>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">First Name</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">

                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input type="email" [(ngModel)]="advanceSearch.firstName" name="firstName"
                                                     [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" 
                                                     aria-describedby="emailHelp" placeholder="Enter First Name" class="in-rad pl-2">
                                                </div>


                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Last Name</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">

                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input type="email" [(ngModel)]="advanceSearch.lastName" name="lastName" 
                                                    [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Last Name" class="in-rad pl-2">
                                                </div>

                                            </div>
                                        </div> 

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">From Date</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.fromdateFrom" name="firstName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name">
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.fromDateTo" name="firstName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name">
                                                </div>



                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Through Date</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input class="form-control " type="date" class="form-control" [(ngModel)]="advanceSearch.throughDateFrom" name="lastName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name">
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input type="date" [(ngModel)]="advanceSearch.throughDateTo" name="lastName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Termination Reason ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">

                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.terminationReasonID" [options]="TerminationReasonIDArray" optionlabel="label" [ngModelOptions]="{standalone:true}" name="terminationReasonID" placeholder="Enter Termination Reason ID">
                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Termination Type ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">

                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.TerminationTypeID" [options]="TerminationTypeIDArray" optionlabel="label" [ngModelOptions]="{standlone:true}" name="TerminationTypeID" placeholder="Enter Termination Type ID">

                                                    </p-dropdown>
                                                </div>

                                            </div>
                                        </div>




                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -24%;"><button type="submit" (click)="getFindEmployment()" class="btn btn-secondary submit-btn"> Find</button>&nbsp;
                                        <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                    </div>
                                </div> -->
                                <br>
                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Employment List
                                            </h4>

                                        </div>
                                    </header>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">


                                                <div class="card own-account-table">
                                                    <p-table [value]="employeeList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th pSortableColumn="code" [ngStyle]="{'width':'190px'}">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        Internal Organization
                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="name" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;"> Employee Party ID
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="category" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;">First Name
                                                                        <p-sortIcon field="category"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="category" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;">Last Name
                                                                        <p-sortIcon field="category"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="category" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;">From Date
                                                                        <p-sortIcon field="category"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="category" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;">Through Date
                                                                        <p-sortIcon field="category"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="category" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;">Termination Reason ID
                                                                        <p-sortIcon field="category"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="category" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;">Termination Type ID
                                                                        <p-sortIcon field="category"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="quantity" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;"> Action
                                                                        <p-sortIcon field="quantity"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'190px','color':'black','text-decoration': 'none'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        <!-- <span  class="account-button" 
                                                                       (click)="employementDetail(product.Employments.partyIdFrom,product.Employments.partyIdTo,product.fromDate)">{{product.Employments.partyIdFrom}}
                                                                       
                                                                    </span> -->
                                                                        <span style="color:#393036;text-decoration:none;">{{product.Employments.partyIdFrom || ''}}
                                                                       
                                                                    </span>
                                                                    </div>
                                                                </td>
                                                                <!-- <td [ngStyle]="{'width':'190px'}">{{product.data.PartyIDFirstLastName.partyId}}</td>
                                                            <td [ngStyle]="{'width':'190px'}">{{product.firstName}}</td>
                                                            <td [ngStyle]="{'width':'190px'}">{{product.data.PartyIDFirstLastName.lastName}}</td>
                                                            <td [ngStyle]="{'width':'190px'}">{{product.data.DateTermination.fromDate}}</td>
                                                            <td [ngStyle]="{'width':'190px'}">{{product.DateTermination.thruDate}}</td>
                                                            <td [ngStyle]="{'width':'190px'}">{{product.data.DateTermination.terminationReasonId}}</td>
                                                            <td [ngStyle]="{'width':'190px'}">{{product.data.DateTermination.terminationTypeId}}</td> -->

                                                                <td [ngStyle]="{'width':'190px'}">
                                                                    <span class="account-button"
                                                                     *ngIf="product.Employments" (click)="employeeProfile(product.Employments.partyIdTo)">
                                                                {{product.Employments.partyIdTo }}
                                                                </span>
                                                                </td>
                                                                <td [ngStyle]="{'width':'190px'}">
                                                                   <span *ngIf="product.FindEmplist">{{product.FindEmplist[0].firstName }}</span> </td>
                                                                <td [ngStyle]="{'width':'190px'}" >
                                                                    <span *ngIf="product.FindEmplist"> {{product.FindEmplist[0].lastName }}</span>      
                                                                   </td>
                                                                <td [ngStyle]="{'width':'190px'}">{{product.fromDate }}</td>
                                                                <td [ngStyle]="{'width':'190px'}">{{product.thruDate }}</td>
                                                                <td [ngStyle]="{'width':'190px'}"><span *ngIf="product.Employments">{{product.Employments.terminationReasonId }}</span>  </td>
                                                                <td [ngStyle]="{'width':'190px'}"><span *ngIf="product.Employments">{{product.Employments.terminationTypeId }}</span> </td>

                                                                <td [ngStyle]="{'width':'190px'}">
                                                                    <button type="submit" (click)="employementDetail(product.Employments.partyIdFrom,product.Employments.partyIdTo,product.fromDate,product.Employments.roleTypeIdFrom,product.Employments.roleTypeIdTo)" 
                                                                    class="btn btn-secondary submit-btn">Update</button>
                                                              
                                                                </td>

                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">{{show?'Update':'Create'}} New Employment</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                                Create New Employment </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                    Update New Employment </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="createEmployement" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Internal Organization</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Internal Organization" [options]="InternalOrganizationArray" optionlabel="label" formControlName="InternalOrganization" filter="true">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Employee Party ID <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Employee Party ID" [options]="partyIdArray" optionlabel="label" formControlName="EmployeePartyID" filter="true">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">From Date<span
                                                            style="color:red">*</span>
                                                           </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Budget ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Amount<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="Amount" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Amount">
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Comments</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="Comments" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Pay GradeID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Pay GradeID" [options]="SalaryStepSequenceIDArray" optionlabel="label" formControlName="PayGradeID" filter="true">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Salary Step Sequence ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Salary Step Sequence ID" [options]="PayGradeIDArray" optionlabel="label" formControlName="SalaryStepSequenceID" filter="true">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Period Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Period Type ID" [options]="PeriodTypeIdArray" optionlabel="label" formControlName="PeriodTypeID" filter="true">

                                                        </p-dropdown>

                                                    </div>

                                                </div>
                                            </div>




                                        </div>

                                        <div class="main-submit-button" style="margin-right: 7%;">
                                            <button type="submit" *ngIf="show" class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" (click)="onSubmit();" *ngIf="!show" class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->
<ngx-spinner></ngx-spinner>