import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-payment-processing',
  templateUrl: './payment-processing.component.html',
  styleUrls: ['./payment-processing.component.css']
})
export class PaymentProcessingComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;

  activeCategory=4;
  activeCategories=2;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;

  paymentMethods:any;
  
  addNewPaymentMethodForm: FormGroup;
  paymentProcessSettingById: any;
  show: boolean;
  paymentProcessSetting: any;

 

  constructor(
    readonly activatedRoute: ActivatedRoute,

    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) 
  {
    this.addNewPaymentMethodForm = this._FormBuilder.group({
      paymentMethod:"",
      displayAs:"",
    });

   
  }

  ngOnInit(): void {
    this.getPaymentProcessSetting();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  

  reset(){
    this.show = false;
    this.addNewPaymentMethodForm.reset();
  }
  getPaymentProcessSettingById(product) {
    this.show = true;
    this.spinner.show();
    this.myContactsService.getPaymentProcessSettingById(product.priceListCategoryId).subscribe(res => {
      this.paymentProcessSettingById = res.data[0].getPaymentProcessSettingById[0];
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.addNewPaymentMethodForm ;
        formValue.patchValue({
          paymentMethod: this.paymentProcessSettingById.paymentType ,
          displayAs: this.paymentProcessSettingById.displayAs
        })
      }, 2000);
    })
  }
  updatePaymentProcessSetting() {
    this.spinner.show();
    const requestData = {
      "displayAs": this.addNewPaymentMethodForm.value.displayAs,
      "paymentProcessId": this.paymentProcessSettingById.paymentProcessId,
      "paymentType": this.addNewPaymentMethodForm.value.paymentMethod,
    }
    this.myContactsService.updatePaymentProcessSetting(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addNewPaymentMethodForm.reset();
        this.getPaymentProcessSetting();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createPaymentProcessSetting() {
    this.spinner.show();
    const requestData = 
    {
      "displayAs": this.addNewPaymentMethodForm.value.displayAs,
      "paymentProcessId": "",
      "paymentType": this.addNewPaymentMethodForm.value.paymentMethod,
    }
    this.myContactsService.createPaymentProcessSetting(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addNewPaymentMethodForm.reset();
        this.getPaymentProcessSetting();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getPaymentProcessSetting() {
    this.spinner.show();
    this.myContactsService.getPaymentProcessSetting().subscribe(res => {
      this.paymentProcessSetting = res.data[0].getPaymentProcessSetting;
      this.spinner.hide();
    })
  }
  deletePaymentProcessSetting(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        const req = {
          paymentProcessId : product.paymentProcessId
        }
        this.myContactsService.deletePaymentProcessSetting(product).subscribe(res => {
          this.getPaymentProcessSetting();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  

  ngOnDestroy(): void {
    this.spinner.hide();
  }


}
