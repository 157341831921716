
<div class="container-fluid main-container-wrapper">
  <div class="row">
      <div class="col-12">
        <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
          <span class="color-black pl-1 titlePanels">Leads > Convert Lead</span>
          <span>
               <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
               <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
           </span> 
       </div>
      
        <div class="panel-group">
          <div *ngIf="activeCategory==1">
            <div class="w3-card-4 classCard" >
            <div class="header-tabs" >
             <h4 class=" common-styling" style="margin-left: 10px;">
              Convert Lead 
             </h4> 
           </div>
                <div class="panel-body">
                    <form style="background: white;">
                  <div class="all-users-infomation font-13 font-medium own-user-select">
                    <div class="col-lg-12" >
                      <div class="row">
                          <div class="col-lg-3 form-group rightForm">
                            <label for="exampleInputEmail1">Create contact for lead</label>
                          </div>
                          <div class="col-lg-2">
                            <input class="form-control" type="text" class="shift" [(ngModel)]="partyId" [ngModelOptions]="{standalone: true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder= " ">
                          </div>
                          <div class="col-lg-3 form-group rightFormA">
                            <label for="exampleInputEmail1">Create Account For Lead	</label>
                          </div>
                          <div class="col-lg-2">
                            <input class="form-control" type="text" id="w3review" [(ngModel)]="partyGroupId" [ngModelOptions]="{standalone: true}" class="shift"   rows="8" cols="50">

                          </div>
                          
                         
                      </div>
                  </div>
                   
                  </div>
                  <div class="proper" style="margin-left: 63%;">
                  <button type="submit" (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>
                  <button type="submit" (click)="navigate()"  class="btn btn-danger">Cancel</button>
                  </div>
                  <br><br>
              </form>
              </div>
              </div>
            </div>
            </div>

        </div>
        </div>
</div>
<ngx-spinner></ngx-spinner>
          