import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { HeaderService } from 'src/app/header/header.service';
import { SortEvent } from 'primeng/api';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-detail-accounting-transaction',
  templateUrl: './detail-accounting-transaction.component.html',
  styleUrls: ['./detail-accounting-transaction.component.css']
})
export class DetailAccountingTransactionComponent implements OnInit {
  partyId: any;
  activeCategory = 3;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  roleList: any[]=[];
  acctran: any;
  dataView: any;
  accountTransaction: any;
  downloading: boolean;
  constructor(readonly accountsService: AccountsService,
  
    readonly router: Router,  readonly toastr:ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    
    readonly spinner: NgxSpinnerService,
   ) { }

    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
      this.acctran=params["acctrans"];
    });
    this.findTransactionDetails();
    this.findAccountTransactionsEntries();
  }
  
  deletetransactionEntries(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deletetransactionEntries(id,this.acctran,this.partyId).subscribe((res: any) => { 
          this.findAccountTransactionsEntries();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  
  downloadPDF(): void {
    this.spinner.show();
    this.downloading = true;
    
    this.accountsService.generateTransactionDetailsPdf(this.partyId,this.acctran)
        .subscribe(value => {
            const blob = new Blob([value], { type: 'application/application/pdf' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;
            }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;
            this.spinner.hide();
           link.download = `${this.acctran}.pdf`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            this.downloading = false;
        });
      
}
  findAccountTransactionsEntries() {
    this.spinner.show();
  
    const req={
      "acctgTransId": this.acctran,
      "acctgTransTypeId": "",
      "fromDate": "",
      "glFiscalTypeId": "",
      "glJournalId": "",
      "invoiceId": "",
      "isPosted": "",
      "organizationPartyId": this.partyId,
      "paymentId": "",
      "productId": "",
      "shipmentId": "",
      "thruDate": "",
      "workEffortId": ""
    }
    this.accountsService.findAccountTransactionsEntries(this.finSize,req).subscribe((res: any) => {
     this.accountTransaction=res.data
      this.spinner.hide();
    })
  
  }
  findTransactionDetails() {
    this.spinner.show();
  
    const req={
      "acctgTransId":this.acctran,
      "acctgTransTypeId": "",
      "fromDate": "",
      "glFiscalTypeId": "",
      "glJournalId": "",
      "invoiceId": "",
      "isPosted": "",
      "organizationPartyId":this.partyId,
      "paymentId": "",
      "productId": "",
      "shipmentId": "",
      "thruDate": "",
      "workEffortId": ""
    }
    this.accountsService.findTransactionDetails(this.finSize,req).subscribe((res: any) => {
     this.dataView=res.data[0]
      this.spinner.hide();
    })
  
  }
  createAccTrans(){
    this.router.navigate(['/financial/create-accounting-transaction'],{ queryParams: { partyId:  this.partyId } });
  
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  redirectPage(){
    this.router.navigate(["financial/create-trans-entries"], { queryParams: { partyId: this.partyId,acctrans:this.acctran } });
  }
  updateTransaction(id){
    this.router.navigate(["financial/create-trans-entries"], { queryParams: { partyId: this.partyId,acctrans:this.acctran ,accseqId:id} });
  }
  setup(){
    this.router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:this.partyId } });
  }
  accounting(){
    this.router.navigate(['/financial/detail-accounting-summary'], { queryParams: { partyId:this.partyId } });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
