<div class="modal-header">
    <span class="color-black pl-1">Employment Application</span>
    <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden" #closebutton >&times;</span>
    </button>
</div>

<div class="row p-15">
    <div class="col-12">
        <!-- <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
           <span class="color-black pl-1 titlePanels" style="cursor: pointer;" (click)="cancelSubmit()">Employment Application</span>
            <span class="color-black pl-1"> > New</span>

            <button type="submit" (click)="cancelSubmit()" class="btn btn-danger buttonclass" >Cancel</button>

        </div> -->
        <div class="w3-card-4 classCard" >
        <div class=" bg-white color-grey">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass">
        
                    <li [ngClass]="activeCategory==2?'active':''" ><a>
                    New Employment Application</a></li>
                    
                    
                </ul>
                </div>
            
            <div class="create-new-leade mt-2">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100" *ngIf="createEventFormService.employee_application_form" [formGroup]="createEventFormService.employee_application_form">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12" >
                                    <div class="row">
                                        <div class="col-lg-4 form-group rightForm">
                                            <label for="exampleInputEmail1">Application ID</label>
                                                
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="email"  formControlName="applicationId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  placeholder="Enter Application Id">
                                        
                                        </div>
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Empl Position Id</label>
                                                
                                        </div>
                                        <div class="col-lg-3">
                                                
                                            <p-dropdown filter="true" placeholder="Empl Position name" formControlName="emplPositionId" [options]="positionArray" optionlabel="label">
                                            </p-dropdown>
                                        </div>
                                        
                                    </div>
                                    </div>
                                    <div class="col-lg-12" >
                                    <div class="row">
                                        <div class="col-lg-4 form-group rightForm">
                                            <label for="exampleInputEmail1">Status Id<span style="color:red">*</span> </label>
                                                
                                        </div>
                                        <div class="col-lg-3">
                                            <p-dropdown filter="true" placeholder="Status Id" formControlName="statusId" [options]="statusArray" optionlabel="label">
                                            </p-dropdown>
                                        
                                        </div>
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Employment App Source<span style="color:red">*</span></label>
                                                
                                        </div>
                                        <div class="col-lg-3">
                                            <p-dropdown class="lists" placeholder="Employment App Source Type Id" filter="true" formControlName="employmentAppSourceTypeId" [options]="appSourceArray" optionlabel="label">
                                            </p-dropdown>
                                        </div>
                                        
                                    </div>
                                    </div>
                                    <div class="col-lg-12" >
                                    <div class="row">
                                        <div class="col-lg-4 form-group rightForm">
                                            <label for="exampleInputEmail1">Applying Party Id<span style="color:red">*</span></label>
                                                
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text"  [(ngModel)]="this.party" [ngModelOptions]="{standalone:true}"
                                            class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >

                                            <!-- <label>{{this.party}}</label> -->
                                        
                                        </div>
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Application Date<span style="color:red">*</span></label>
                                                
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="date" formControlName="applicationDate"  class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    
                                        </div>
                                        
                                    </div>
                                    </div>
                                    <div class="col-lg-12" *ngIf="show">
                                    <div class="row">
                                        <div class="col-lg-4 form-group rightForm">
                                            <label  for="exampleInputEmail1">Referred By Party ID</label>
                                                
                                        </div>
                                        <div class="col-lg-3">
                                            <p-dropdown placeholder="Refered By Party Id" class="lists" filter="true" formControlName="referredByPartyId" [options]="partyIdArray" optionlabel="label">
                                            </p-dropdown>
                                        
                                        </div>
                                        
                                        
                                    </div>
                                    </div>
                                
                                

                            </div>
                            <div class="main-submit-button" style="margin-right: 10%;">
                                <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>
                                <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                
                            </div>
                        </form>
                    </div>
                </div>

            </div>

        </div>
        </div>
        
    </div>
</div>







