<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="bg-white color-grey w-100">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="w3-card-4 classCardView mt-2 w-100">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    Marketing Manager Application
                                    <button type="submit" class="btn btn-secondary submit-btn" (click)="toCRM()" style="margin-left:72% !important;">Home</button>
                                </div>
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <p>Welcome to the Marketing Manager!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>