import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
    selector: 'app-create-new-agreement-role',
    templateUrl: './create-new-agreement-content.component.html'
})
export class CreateNewAgreementContentComponent implements OnInit {
    statusIds: any[];
    file: FormData;
    activeCategory = 2;
    createAgreementContentForm: FormGroup;
    agreementId: string;
    agreementType: string;
    contentTypeIds: any[];
    contentIds: any[];
    itemSeqIds: any[];
    constructor(
        readonly _FormBuilder: FormBuilder,
        readonly  _Router: Router,
        readonly spinner: NgxSpinnerService,
        readonly _ToastrService: ToastrService,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _AccountingApService: AccountingApService
    ) {
        this.createAgreementContentForm = this._FormBuilder.group({
            agreementContentTypeId: [''],
            agreementItemSeqId: [''],
            contentTypeId: [''],
            fromDate: [new Date()],
            thruDate: [new Date()],
            statusId: [''],
            file: ['']
        });
        this.file = new FormData();
        this.statusIds = [];
        this.agreementId = '';
        this.agreementType = '';
        this.contentTypeIds = [];
        this.contentIds = [];
        this.itemSeqIds = [];
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
        this.agreementId = this._ActivatedRoute.snapshot.queryParams.agreementId;
        this.getAgreementSeqIds();
        this.getStatusIds();
        this.getAgreementContentTypeIds();
        this.getContentIds();
    }
    getAgreementSeqIds(): void {
        this.spinner.show();
        this._AccountingApService.getAgreementItemList(this.agreementId, { pageNo: 1, pageSize: 200 })
            .then(data => {
                this.spinner.hide();
                this.itemSeqIds = data.data.map(value => {
                    return {
                        label: value.agreementItemSeqId,
                        value: value.agreementItemSeqId
                    };
                });
            });
          
    }
    getStatusIds(): void {
        this.spinner.show();
        this._AccountingApService.getContentStatusId()
            .then(data => {
                this.spinner.hide();
                this.statusIds = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.statusId
                    };
                });
            });
           
    }
    getAgreementContentTypeIds(): void {
        this.spinner.show();
        this._AccountingApService.getAgreementContentTypeIds()
            .then(data => {
                this.spinner.hide();
                this.contentTypeIds = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.agreementContentTypeId
                    };
                });
            });
           
    }
    getContentIds(): void {
        this.spinner.show();
        this._AccountingApService.getContentIds()
            .then(data => {
                this.spinner.hide();
                this.contentIds = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.contentTypeId
                    };
                });
            });
           
    }
    setFile(event: any): void {
        this.file.append('file', event.target.files[0] as File);
    }
    reset(): void {
        this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/summary-customer-agreeement`], { queryParams: { agreementID: this.agreementId } });
    }
    submit(): void {
        this.spinner.show();
        if (this.file) {
            const { agreementId, agreementContentTypeId, agreementItemSeqId, contentTypeId, fromDate, statusId, thruDate } = this.createAgreementContentForm.value;
            this._AccountingApService.createAgreementContent(this.file, this.agreementId, agreementContentTypeId, agreementItemSeqId, contentTypeId, moment(fromDate).format('YYYY-MM-DD HH:mm:ss'), statusId, moment(thruDate).format('YYYY-MM-DD HH:mm:ss'))
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/summary-customer-agreeement`], { queryParams: { agreementID: this.agreementId } });
                    }
                });
        } else {
            this.spinner.hide();
            this._ToastrService.error('Please select file');
        }
      
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}