<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span class="color-black pl-1 titlePanels">Find Production Run </span>

                <span class="">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>

            </div>
            <div class=" bg-white color-grey">

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">

                                <div class="w3-card-4  w-100">
                                    <div class="accordion classCard" id="myAccordion">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion">
                                                    <span>
                                                        <button type="button"
                                                            class="btn btn-outline-secondary marginHeader"
                                                            data-toggle="modal" data-target="#exampleModalCenter">Create
                                                            New JobShop</button>

                                                    </span>
                                                    <span>
                                                        <button type="button" class="accordion-button  collapsed"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                            style="margin-left: 77%!important; padding-top: 11px !important;
                                                        padding-bottom: 11px !important;">
                                                        </button>
                                                    </span>

                                                </div>

                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse"
                                                data-bs-parent="#myAccordion">
                                                <div class="card-body">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Production Run
                                                                        ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true"
                                                                        [(ngModel)]="advanceSearch.productionIdSearchType"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        name="productionIdSearchType"
                                                                        [options]="opportunityIdArray"
                                                                        optionlabel="label"></p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" name="productionId"
                                                                        class="form-control"
                                                                        [(ngModel)]="advanceSearch.productionId"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Production Run ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Status</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-multiSelect [options]="statusIdArray"
                                                                        name="Status" [(ngModel)]="advanceSearch.Status"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        (onChange)="onStatusChange($event)"
                                                                        placeholder="Select Status">
                                                                    </p-multiSelect>
                                                                </div>



                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Product ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true"
                                                                        [(ngModel)]="advanceSearch.ProductID"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        name="ProductID" [options]="productIdArray"
                                                                        optionlabel="label"
                                                                        placeholder="Enter Product ID">

                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Production Run
                                                                        Name</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true"
                                                                        [(ngModel)]="advanceSearch.productionNameSearchType"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        name="productionNameSearchType"
                                                                        [options]="opportunityIdArray"
                                                                        optionlabel="label"></p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" name="productionName"
                                                                        class="form-control"
                                                                        [(ngModel)]="advanceSearch.productionName"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Production Run Name">
                                                                </div>



                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Start Date</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true"
                                                                        [(ngModel)]="advanceSearch.fromdateSearchType"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        name="fromdateSearchType"
                                                                        [options]="greaterArray" optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" name="fromDate"
                                                                        class="form-control"
                                                                        [(ngModel)]="advanceSearch.fromDate"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Production Run Name">
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" name="throughDate"
                                                                        class="form-control"
                                                                        [(ngModel)]="advanceSearch.throughDate"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Production Run Name">
                                                                </div>
                                                                <div class="col-lg-2"></div>

                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12" style="display: none;">
                                                            <div class="row">
                                                                <div class="col-lg-2 form-group">
                                                                    <label for="exampleInputEmail1"></label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true"
                                                                        [(ngModel)]="advanceSearch.ToDateSearchType"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        name="ToDateSearchType" [options]="smallerArray"
                                                                        optionlabel="label"></p-dropdown>
                                                                </div>

                                                                <div class="col-lg-2"></div>

                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Facility ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true"
                                                                        [(ngModel)]="advanceSearch.facilityId"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        name="facilityId" [options]="facilityIdArray"
                                                                        optionlabel="label"
                                                                        placeholder="Enter Facility ID">

                                                                    </p-dropdown>
                                                                </div>

                                                                <div class="col-lg-2"></div>

                                                            </div>
                                                        </div>



                                                    </div>

                                                    <div class="col-lg-12 col-12 main-submit-button"
                                                        style="margin-left: -27%;"><button _ngcontent-cta-c128=""
                                                            type="submit" class="btn btn-secondary submit-btn"
                                                            (click)="advanceSearchContacts(false)">Find</button>&nbsp;
                                                        <button _ngcontent-cta-c128="" type="submit"
                                                            class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <!-- 
                                <div class="w3-card-4 classCard">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <button type="button" class="btn btn-outline-secondary" data-toggle="modal" data-target="#exampleModalCenter">Create New JobShop</button>

                                    </div>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Production Run ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.productionIdSearchType" [ngModelOptions]="{standalone:true}" name="productionIdSearchType" [options]="opportunityIdArray" optionlabel="label"></p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" name="productionId" class="form-control" [(ngModel)]="advanceSearch.productionId" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Production Run ID">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Status</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-multiSelect [options]="statusIdArray" name="Status" [(ngModel)]="advanceSearch.Status" [ngModelOptions]="{standalone:true}" (onChange)="onStatusChange($event)" placeholder="Select Status">
                                                    </p-multiSelect>
                                                </div>



                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Product ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.ProductID" [ngModelOptions]="{standalone:true}" name="ProductID" [options]="productIdArray" optionlabel="label" placeholder="Enter Product ID">

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Production Run Name</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.productionNameSearchType" [ngModelOptions]="{standalone:true}" name="productionNameSearchType" [options]="opportunityIdArray" optionlabel="label"></p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" name="productionName" class="form-control" [(ngModel)]="advanceSearch.productionName" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Production Run Name">
                                                </div>



                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Start Date</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.fromdateSearchType" [ngModelOptions]="{standalone:true}" name="fromdateSearchType" [options]="greaterArray" optionlabel="label"></p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" name="fromDate" class="form-control" [(ngModel)]="advanceSearch.fromDate" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Production Run Name">
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" name="throughDate" class="form-control" [(ngModel)]="advanceSearch.throughDate" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Production Run Name">
                                                </div>
                                                <div class="col-lg-2"></div>

                                            </div>
                                        </div>
                                        <div class="col-lg-12" style="display: none;">
                                            <div class="row">
                                                <div class="col-lg-2 form-group">
                                                    <label for="exampleInputEmail1"></label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.ToDateSearchType" [ngModelOptions]="{standalone:true}" name="ToDateSearchType" [options]="smallerArray" optionlabel="label"></p-dropdown>
                                                </div>

                                                <div class="col-lg-2"></div>

                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Facility ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.facilityId" [ngModelOptions]="{standalone:true}" name="facilityId" [options]="facilityIdArray" optionlabel="label" placeholder="Enter Facility ID">

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2"></div>

                                            </div>
                                        </div>



                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -27%;"><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="advanceSearchContacts(false)">Find</button>&nbsp;
                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger ml-2" (click)="reset()">Reset</button></div>
                                
                                    </div> -->
                                <br>

                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Job shop List
                                            </h4>

                                        </div>
                                    </header>
                                    <div
                                        class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">

                                                <div class="card own-account-table">
                                                    <p-table [value]="JobShopProductionList" [paginator]="true"
                                                        [rows]="rows" scrollWidth="100%"
                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                        [totalRecords]="total" [scrollable]="true"
                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'200px'}"
                                                                    pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>

                                                                        Id
                                                                        <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;"> Production Run Name
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;"> Product ID
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                </th>

                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="quantity">
                                                                    <div style="color: white;"> Quantity
                                                                        <p-sortIcon field="quantity"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="price">
                                                                    <div style="color: white;"> Quantity Uom
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="price">
                                                                    <div style="color: white;"> Status
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="price">
                                                                    <div style="color: white;"> Start Date
                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="price">
                                                                    <div style="color: white;"> Description
                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="price">
                                                                    <div style="color: white;"> Facility ID
                                                                        <p-sortIcon field="price">
                                                                        </p-sortIcon>

                                                                    </div>
                                                                </th>



                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'200px'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        <span
                                                                            (click)="jobDetails(product.workEffortId,product.productId)" class="account-button">{{product.workEffortId}}</span>
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.workEffortName}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">{{product.productId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.estimatedQuantity}}

                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">{{product.moneyUomId}}

                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.statusDesc}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.estimatedStartDate}}

                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.description}}


                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">{{product.facilityId}}


                                                                </td>



                                                        </ng-template>
                                                    </p-table>
                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create JobShop</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                                        Add JobShop

                                    </a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                                        Update JobShop

                                    </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createJob">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                            Product ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="!this.show">
                                                        <p-dropdown placeholder="Enter Product ID" filter="true"
                                                            formControlName="productId" [options]="productIdArray"
                                                            optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="this.show">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                            formControlName="productId" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Quantity<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                            formControlName="Quantity">
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight" *ngIf="this.show">
                                                        <label for="exampleInputEmail1">
                                                            Manufacturer ID</label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="this.show">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                            formControlName="ManufacturerID" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA" *ngIf="this.show">
                                                        <label for="exampleInputEmail1">
                                                            Current Status ID</label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="this.show">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                            formControlName="statusId" readonly>


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                            Start Date<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                            formControlName="startDate">
                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA" *ngIf="this.show">
                                                        <label for="exampleInputEmail1">
                                                            Calculated Completion Date</label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="this.show">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                            formControlName="CalculatedCompletionDate" readonly>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                            Facility ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Facility ID" filter="true"
                                                            formControlName="FacilityID" [options]="facilityIdArray"
                                                            optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA" *ngIf="!this.show">
                                                        <label for="exampleInputEmail1">
                                                            Routing ID</label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="!this.show">
                                                        <p-dropdown placeholder="Enter Routing ID" filter="true"
                                                            formControlName="RoutingID" [options]="routingIdArray"
                                                            optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                            Production Run Name</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Production Run Name"
                                                            formControlName="ProductionRunName">

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Description"
                                                            formControlName="Description">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight" *ngIf="!this.show">
                                                        <label for="exampleInputEmail1">
                                                            Create Dependent Production Runs</label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="!this.show">
                                                        <p-dropdown placeholder="Enter Create Dependent Production Runs"
                                                            filter="true" formControlName="dependent"
                                                            [options]="createDependent" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2"></div>

                                                </div>
                                            </div>




                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -5%;">

                                        <button type="submit" *ngIf="!this.show" (click)="onSubmit()"
                                            class="btn btn-secondary submit-btn">Save</button>

                                        <button type="submit" *ngIf="this.show" (click)="onUpdate()"
                                            class="btn btn-secondary submit-btn">Update</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>