import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';

import { ProductionService } from '../production.service';
@Component({
  selector: 'app-create-jobshop-fixedasset',
  templateUrl: './create-jobshop-fixedasset.component.html',
  styleUrls: ['./create-jobshop-fixedasset.component.css']
})
export class CreateJobshopFixedassetComponent implements OnInit {
  createJob: FormGroup;
  activeCategory = 2;
  workEffortId: any;
  routingTaskList: any;
  routingTaskListArray: any[]=[];
  statusId: any;
  statusIdArray: any[]=[];
  id: string;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  getFixedAssets: any;
  getFixedAssetsArray: any[]=[];
  FromDate: string;
  ThroughDate: string;
  fixedAssetId: any;
  fromDate: any;
  workId: any;
  show: boolean;
  updateFromDate: any;
  getEstimateFrom: string;
  estimatedCompletionDate: string;
  constructor(readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,
   
    readonly spinner: NgxSpinnerService,
    readonly productionService:ProductionService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) {
    this.createJob = this._FormBuilder.group({
      RoutingTask: [''],
      FixedAsset: [''],
      FromDate: [''],
      ThroughDate: [''],
      Availability: [''],
      AllocatedCost: [''],
      Comments: [''],


    });

  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params["workEffortId"];
      this.fixedAssetId = params["fixedAssetId"];
      this.fromDate = params["fromDate"];
      this.workId = params["workId"];
   
    });
   
    this.getstatusByType();
    this.getProductionMaterialRoutingTaskIds();
    this.getFixedAssetsData();
    if(this.fixedAssetId){
      this.show=true;
      this.getProductionFixedAssetById();
    }else{
      this.show=false;
    }
  }
  getProductionFixedAssetById() {
    this.spinner.show();
    this.productionService.getProductionFixedAssetById(this.fixedAssetId,this.fromDate,this.workId).subscribe((res: any) => {
      this.spinner.hide();
      this.updateFromDate=res.data.fromDate;
      setTimeout(() => {
        const fromdate=res.data.fromDate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const estimatedCompletionDate=res.data.thruDate
        this.estimatedCompletionDate=this.datePipe.transform(estimatedCompletionDate,"yyyy-MM-dd");
        const formValue = this.createJob;
        formValue.patchValue({
      RoutingTask: res.data.workEffortId,
      FixedAsset:res.data.fixedAssetId,
      FromDate:  this.getEstimateFrom,
      ThroughDate: this.estimatedCompletionDate,
      Availability:res.data.availabilityStatusId,
      AllocatedCost: res.data.allocatedCost,
      Comments: res.data.comments,
        })  }, 2000);
    })}
  getFixedAssetsData() {
    this.spinner.show();
    const req = {
      "assetType": "",
      "fixedAssetId": "",
      "fixedAssetIdSearchType": "Contains",
      "fixedAssetName": "",
      "fixedAssetNameSearchType": "Contains"


    }
    this.accountsService.searchFixedAssets(this.finSize, req).subscribe((res: any) => {
      this.getFixedAssets = res.data;
      this.spinner.hide();
      for (const value of this.getFixedAssets) {
        this.getFixedAssetsArray.push({
          label: value.fixedAssetName,
          value: value.fixedAssetId
        })
      }
    })
  
  }
  getstatusByType() {
    this.spinner.show();
    this.id="WEFA_AVAILABILITY"
    this.productionService.getstatusByType(this.id).subscribe(res => {
      this.statusId = res.data;
      this.spinner.hide();
      for (const value of this.statusId) {
        this.statusIdArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
    
  }
  getProductionMaterialRoutingTaskIds() {
    this.spinner.show();
    
    this.productionService.getProductionMaterialRoutingTaskIds(this.workEffortId).subscribe(res => {
      this.routingTaskList = res.data;
      this.spinner.hide();
      for (const value of this.routingTaskList) {
        this.routingTaskListArray.push({
          label: value.workEffortId,
          value: value.workEffortId
        })
      }
    })
    
  }
  
  onSubmit() {
    this.spinner.show();
    const FromDate = this.createJob.get('FromDate').value;
    this.FromDate = this.datePipe.transform(FromDate, "yyyy-MM-dd hh:mm:ss");
    const ThroughDate = this.createJob.get('ThroughDate').value;
    this.ThroughDate = this.datePipe.transform(ThroughDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "allocatedCost":  this.createJob.value.AllocatedCost,
      "availabilityStatusId":  this.createJob.value.Availability,
      "comments":  this.createJob.value.Comments,
      "fixedAssetId": this.createJob.value.FixedAsset,
      "fromDate": this.FromDate,
      "statusId":"",
      "thruDate":this.ThroughDate,
      "workEffortId":  this.createJob.value.RoutingTask,
    }
    this.productionService.postProductionFixedAsset(this.workEffortId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this._Router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId:this.workEffortId } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  onUpdate() {
    this.spinner.show();
    
    const ThroughDate = this.createJob.get('ThroughDate').value;
    this.ThroughDate = this.datePipe.transform(ThroughDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "allocatedCost":  this.createJob.value.AllocatedCost,
      "availabilityStatusId":  this.createJob.value.Availability,
      "comments":  this.createJob.value.Comments,
      "fixedAssetId": this.createJob.value.FixedAsset,
      "fromDate":  this.updateFromDate,
      "statusId":"",
      "thruDate":this.ThroughDate,
      "workEffortId":  this.createJob.value.RoutingTask,
    }
    this.productionService.updateProductionFixedAsset(this.workEffortId,requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this._Router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId:this.workEffortId } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    }); }

  cancelSubmit(){
    this._Router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId: this.workEffortId} })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}

