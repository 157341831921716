<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Import/Export</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="w3-container">
                        <div class="header-tabs px-2" style="background-color: #cce0e95e !important;">
                            <h4 class=" common-styling">
                                Party Import
                            </h4>
                        </div>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" [formGroup]="Editform">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">
                                                <div *ngIf="activeCategory==1">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Import
                                                        </h4>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12" >
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Upload File</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="file" formControlName="file" (change)="fileProgress($event)" class="classFile"
                                                                        id="exampleInputEmail1" aria-describedby="emailHelp"  accept="">
                                                                    </div>

                                                                    <div class="col-lg-5">
                                                                       
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <button _ngcontent-cta-c128="" type="submit"
                                                                        (click)="onSubmit()"  class="btn btn-secondary submit-btn">Upload</button>&nbsp;&nbsp;
                                                                    </div>
                                                                   
                                                                </div>
                                                              </div>
                                                          
                                                        </div>
                                                        <!-- <div class="col-lg-12 col-12 main-submit-button ">
                                                            <button _ngcontent-cta-c128="" type="submit"
                                                            (click)="onSubmit()"  class="btn btn-secondary submit-btn">Upload</button>&nbsp;&nbsp;
                                                        </div> -->
                                                    </div>
                                                   
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <form class="w-100" [formGroup]="ExportForm">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">
                                                <div *ngIf="activeCategory==1">
                                                    
                                                    
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Export
                                                        </h4>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12" >
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Party ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown placeholder="Enter Party From" filter="true"
                                                                        [options]="partyIdArray"  optionlabel="label"
                                                                        formControlName="partyFrom">
                                                                        </p-dropdown>

                                                                    </div>
                                                                    <div class="col-lg-5">
                                                                       
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <button _ngcontent-cta-c128="" type="submit" (click)="getExportPartyId()"
                                                                        class="btn btn-secondary submit-btn">Submit</button>&nbsp;&nbsp;
                                                                    </div>
                                                                    
                                                                   
                                                                </div>
                                                              </div>
                                                          
                                                        </div>
                                                        <!-- <div class="col-lg-12 col-12 main-submit-button ">
                                                            <button _ngcontent-cta-c128="" type="submit" (click)="getExportPartyId()"
                                                                class="btn btn-secondary submit-btn">Submit</button>&nbsp;&nbsp;
                                                        </div> -->
                                                    </div>

                                                    <div class="w3-card-4 classCard mt-3">
                                                        <div
                                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                       List of Uploaded Documents
                                                    </div>
                                                        <div
                                                            class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">

                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="contactsData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [responsive]="true"
                                                                            [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            styleClass="p-datatable-customers"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            First Name
                                                                                            <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Last Name<p-sortIcon
                                                                                                field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Email Address


                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Address 1



                                                                                        <p-sortIcon
                                                                                            field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value=""
                                                                                        autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Address 2


                                                                                    <p-sortIcon
                                                                                        field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                City


                                                                                <p-sortIcon
                                                                                    field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox"
                                                                                aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                        pSortableColumn="category">
                                                                        <div style="color: white;">
                                                                            Postal Code



                                                                            <p-sortIcon
                                                                                field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input
                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value=""
                                                                            autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox"
                                                                            aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                    pSortableColumn="category">
                                                                    <div style="color: white;">
                                                                        Phone Number



                                                                        <p-sortIcon
                                                                            field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input
                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox"
                                                                        aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>



                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body"
                                                                                let-product>
                                                                                <tr>
                                                                                    <td
                                                                                        [ngStyle]="{'width':'200px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            <span class="account-button" >{{product.firstName}}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        {{product.lastName}}
                                                                                    </td>
                                                                                    <td
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div>
                                                                                            <span *ngIf="product.email.infoString"> {{product.email.infoString}}</span>
                                                                                      
                                                                                        </div>
                                                                                      
                                                                                    </td>
                                                                                    <td
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div>
                                                                                            <span *ngIf="product.address.address1"> {{product.address.address1}}</span>
                                                                                        </div>
                                                                                       

                                                                                    </td>
                                                                                    <td
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div>
                                                                                            <span *ngIf="product.address.address2"> {{product.address.address2}}</span>
                                                                                        </div>
                                                                                       
                                                                                    </td>
                                                                                    <td
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                       
                                                                                        <div>
                                                                                            <span *ngIf="product.address.city"> {{product.address.city}}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div> 
                                                                                            <span *ngIf="product.address.postalCode">  {{product.address.postalCode}}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td
                                                                                        [ngStyle]="{'width':'190px'}"> 
                                                                                        <div>
                                                                                            <span *ngIf="product.telenumber.contactNumber">  {{product.telenumber.contactNumber}}</span>
                                                                                       </div>
                                                                                     </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No
                                                                                                Record Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>

                                                                        <p class="paginate_data">
                                                                            View per page </p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>