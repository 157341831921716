<div class="container-fluid main-container-wrapper">
    <br>
    <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
       <span class="color-black pl-1 titlePanels">Relocation</span>

       <span class="d-flex">

        <button type="submit" (click)="homeButton();"
            class=" btn btn-outline-secondary mr-2">Home</button>

        <button type="submit" (click)="cancelSubmit();"
            class="btn btn-danger buttonclass ">Back</button>
    </span>

    </div>
    <div class="w3-card-4 classCard">
        <div class="container-fluid">
            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                <li [ngClass]="activeCategoryMain==1?'active':''" (click)="JobRequisition()"><a>
                        Job Requisition</a></li>
                <li [ngClass]="activeCategoryMain==2?'active':''" (click)="InternalJobPostingApplication()"><a>
                        Internal Job Posting Application</a></li>



            </ul>
        </div>
        <div class="w3-card-4 classCard" style="margin: 11px">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                    <li [ngClass]="activeCateg==1?'active':''" (click)="jobInterview()"><a>
                            Job Interview</a></li>
                    <li [ngClass]="activeCateg==2?'active':''" (click)="approval()"><a>
                            Approval</a></li>
                    <li [ngClass]="activeCateg==3?'active':''" (click)="Relocation()"><a>
                            Relocation</a></li>



                </ul>
            </div>
            <div class="all-users-infomation border-top-grey font-13 font-medium own-user-select">

                <div>
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" style="padding: 15px 15px;">

                                <div class="w3-card-4  w-100" style="width:85% !important;">
                                    <div class="accordion classCard" id="myAccordion">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                    <span class="headingAccords">Search</span>

                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 87%!important; padding-top: 11px !important;
                                                        padding-bottom: 11px !important;">
                                                    </button>	
                                                </div>
            
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div class="card-body">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Employee Party ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown name="partyId" [ngModelOptions]="{standlone:true}"
                                                                        [options]="partyIdArray" filter="true"
                                                                        [(ngModel)]="advanceSearch.partyId" optionlabel="label"
                                                                        placeholder="Enter Employee Party ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Empl Position ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown name="emplPositionId"
                                                                        [ngModelOptions]="{standlone:true}" [options]="positionArray"
                                                                        filter="true" [(ngModel)]="advanceSearch.emplPositionId"
                                                                        optionlabel="label" placeholder="Enter Empl Position ID">
                                                                    </p-dropdown>
                                                                </div>
                
                
                                                            </div>
                                                        </div>
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Empl Position Id Reporting
                                                                        To</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown name="emplPositionIdReportingTo"
                                                                        [ngModelOptions]="{standlone:true}" [options]="positionArray"
                                                                        filter="true"
                                                                        [(ngModel)]="advanceSearch.emplPositionIdReportingTo"
                                                                        optionlabel="label"
                                                                        placeholder="Enter Empl Position Id Reporting To">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Internal Organisation</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown name="internalOrganisation"
                                                                        [ngModelOptions]="{standlone:true}" [options]="internalArray"
                                                                        filter="true" [(ngModel)]="advanceSearch.internalOrganisation"
                                                                        optionlabel="label" placeholder="Enter Internal Organisation">
                                                                    </p-dropdown>
                                                                </div>
                
                
                                                            </div>
                                                        </div>
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Reporting Date</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.reportingDateFromSearchType"
                                                                        [ngModelOptions]="{standlone:true}" filter="true"
                                                                        name="reportingDateFromSearchType" [options]="dateIdArray"
                                                                        optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="date" name="reportingDateFrom"
                                                                        [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        [(ngModel)]="advanceSearch.reportingDateFrom" >
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="date" name="reportingDateTo"
                                                                        [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        [(ngModel)]="advanceSearch.reportingDateTo" >
                                                                </div>
                
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12" style="display: none;">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1"></label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [ngModelOptions]="{standlone:true}" filter="true"
                                                                        name="reportingDateToSearchType" [options]="dateIdArray"
                                                                        [(ngModel)]="advanceSearch.reportingDateToSearchType"
                                                                        optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2"></div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="date" name="reportingDateTo"
                                                                        [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        [(ngModel)]="advanceSearch.reportingDateTo" >
                                                                </div>
                
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Location</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [ngModelOptions]="{standlone:true}"
                                                                        [(ngModel)]="advanceSearch.locationSearch" filter="true"
                                                                        name="locationSearch" [options]="opportunityIdArray"
                                                                        optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input style="width: 100%;" type="email"
                                                                        [(ngModel)]="advanceSearch.location" name="location"
                                                                        [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="Enter Location">
                                                                </div>
                
                                                            </div>
                                                        </div>
                
                
                
                
                
                                                    </div>

                                                    <div class="col-lg-12 col-12 main-submit-button " style="
                                                            margin-left: -17%;
                                                        "><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn"
                                                                                    (click)="advanceSearchContacts(false)">Find</button>&nbsp;
                                                        <button type="button" class="btn btn-danger ml-2"
                                                            (click)="reset()">Reset</button>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>


                                <!-- <div class="w3-card-4 classCard" style="width: 95%;">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Employee Party ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown name="partyId" [ngModelOptions]="{standlone:true}"
                                                        [options]="partyIdArray" filter="true"
                                                        [(ngModel)]="advanceSearch.partyId" optionlabel="label"
                                                        placeholder="Enter Employee Party ID">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Empl Position ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown name="emplPositionId"
                                                        [ngModelOptions]="{standlone:true}" [options]="positionArray"
                                                        filter="true" [(ngModel)]="advanceSearch.emplPositionId"
                                                        optionlabel="label" placeholder="Enter Empl Position ID">
                                                    </p-dropdown>
                                                </div>


                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Empl Position Id Reporting
                                                        To</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown name="emplPositionIdReportingTo"
                                                        [ngModelOptions]="{standlone:true}" [options]="positionArray"
                                                        filter="true"
                                                        [(ngModel)]="advanceSearch.emplPositionIdReportingTo"
                                                        optionlabel="label"
                                                        placeholder="Enter Empl Position Id Reporting To">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Internal Organisation</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown name="internalOrganisation"
                                                        [ngModelOptions]="{standlone:true}" [options]="internalArray"
                                                        filter="true" [(ngModel)]="advanceSearch.internalOrganisation"
                                                        optionlabel="label" placeholder="Enter Internal Organisation">
                                                    </p-dropdown>
                                                </div>


                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Reporting Date</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.reportingDateFromSearchType"
                                                        [ngModelOptions]="{standlone:true}" filter="true"
                                                        name="reportingDateFromSearchType" [options]="dateIdArray"
                                                        optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input type="date" name="reportingDateFrom"
                                                        [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp"
                                                        [(ngModel)]="advanceSearch.reportingDateFrom" >
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <input type="date" name="reportingDateTo"
                                                        [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp"
                                                        [(ngModel)]="advanceSearch.reportingDateTo" >
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-lg-12" style="display: none;">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1"></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [ngModelOptions]="{standlone:true}" filter="true"
                                                        name="reportingDateToSearchType" [options]="dateIdArray"
                                                        [(ngModel)]="advanceSearch.reportingDateToSearchType"
                                                        optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2"></div>
                                                <div class="col-lg-2 form-group">
                                                    <input type="date" name="reportingDateTo"
                                                        [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp"
                                                        [(ngModel)]="advanceSearch.reportingDateTo" >
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Location</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [ngModelOptions]="{standlone:true}"
                                                        [(ngModel)]="advanceSearch.locationSearch" filter="true"
                                                        name="locationSearch" [options]="opportunityIdArray"
                                                        optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input style="width: 100%;" type="email"
                                                        [(ngModel)]="advanceSearch.location" name="location"
                                                        [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Location">
                                                </div>

                                            </div>
                                        </div>





                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button " style="
                    margin-left: -17%;
                "><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn"
                                            (click)="advanceSearchContacts(false)">Find</button>&nbsp;
                                        <button type="button" class="btn btn-danger ml-2"
                                            (click)="reset()">Reset</button>
                                    </div>
                                </div> -->
                                
                                <br>
                                <div class="w3-card-4 classCard" style="width: 85% !important;">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Relocation List
                                            </h4>

                                        </div>
                                    </header>
                                    <div
                                        class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">

                                                <div class="card own-account-table">
                                                    <p-table [value]="reLocationsList" [paginator]="true" [rows]="rows"
                                                        scrollWidth="100%"
                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                        [totalRecords]="total" [scrollable]="true"
                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'190px'}"
                                                                    pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined"></div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>

                                                                        Employee Position Id <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="name"
                                                                    style="width: 12%;">
                                                                    <div style="color: white;">Empl Position Id
                                                                        Reporting To <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}"
                                                                    pSortableColumn="category">
                                                                    <div style="color: white;">Internal
                                                                        Organisation<p-sortIcon field="category">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}"
                                                                    pSortableColumn="quantity">
                                                                    <div style="color: white;">Party Id
                                                                        <p-sortIcon field="quantity">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}"
                                                                    pSortableColumn="quantity">
                                                                    <div style="color: white;">Party Name
                                                                        <p-sortIcon field="quantity">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'190px'}"
                                                                    pSortableColumn="price">
                                                                    <div style="color: white;">Reporting Date
                                                                        <p-sortIcon field="price">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>

                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'190px'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined"></div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        <span
                                                                           class="account-button"
                                                                            (click)="detailPage(product.emplPositionId)">
                                                                            {{product.emplPositionId}}</span>

                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'190px'}">
                                                                    {{product.emplPositionIdReportingTo}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'190px'}">
                                                                    {{product.internalOrganisation}}</td>

                                                                <td [ngStyle]="{'width':'190px'}">{{product.partyId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'190px'}">{{product.partyName}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'190px'}">
                                                                    {{product.reportingDate | date :'yyyy-MM-dd HH:mm:ss'}}</td>


                                                            </tr>
                                                        </ng-template>
                                                    </p-table>

                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>