import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { Validators } from '@angular/forms';
import { request } from 'http';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { FinancialService } from 'src/app/services/financial.service';
import {TreeNode} from 'primeng/api';
import { WarehouseService } from 'src/app/warehouse/warehouse/warehouse.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import * as moment from 'moment';
import {
  ChangeDetectionStrategy,
  TemplateRef,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';

import { FullCalendarComponent, CalendarOptions } from '@fullcalendar/angular';

import dayGridPlugin from '@fullcalendar/daygrid';
import { TrainingApprovalService } from 'src/app/human-resource/training/training-approvals/training-approval.service';


declare const $: any;

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
  options: any;
  pageSize= 100;
  pageNo = 1;
  public trainingSize = {
    "pageNo": this.pageNo,
    "pageSize": this.pageSize
  };
 

  trainingApprovalForm: FormGroup;
  dateIdArray = [
    {
      label: 'Less Than',
      value: 'LessThan'
    },
    {
      label: 'Equals',
      value: 'Equals'
    },

    {
      label: 'Greater Than',
      value: 'GreaterThan'
    },
    {
      label: 'Greater Than Equal To',
      value: 'GreaterThanEqualTo'
    },
    {
      label: 'Less Than Equal To',
      value: 'LessThanEqualTo'
    },
    {
      label: 'Is Empty',
      value: 'IsEmpty'
    }

  ];
  opportunityIdArray = [
    {
      label: 'Contains',
      value: 'Contains'
    },
    {
      label: 'Equals',
      value: 'Equals'
    },
    {
      label: 'Is Empty',
      value: 'Is Empty'
    },
    {
      label: 'Begins With',
      value: 'Begins With'
    },
  ];
  calendarValue: any;
  trainingApproval: any;
  trainingApprovalList: any[];
  trainingClassTypes: any[];
  isForm: boolean;
  isButton: boolean;
  isUpdate: boolean;
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };
  fixedAssetId:any;
  calendarEventForm: FormGroup;
  partyIds: any[];
  workEffortId: string;
  partyId: string;
  date: {
    startDate: string,
    endDate: string
  };
  ParticipantsList:any;
  assignList: any[];
  CalStatusList: any;
  CalStatusListArray: any[]=[];
  calendarEventForm1: FormGroup;
  ScopeListArray :any[]=[]
  ScopeList:any;
  estStart: string;
  estCompletion: string;
  ParticipantsForm: FormGroup;
  PartyId: any;
  PartyIdArray: any[] = [];
  CalenderRoleArray: any[]=[];
  CalenderRole: any;

  @ViewChild('closebutton') closebutton;
  activeCategory = 3;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  Facility: any;
  FacilityArray: any[]=[];
  FixedAssetList: any;
  FixedAssetListArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  calendartypeArray: { label: string; value: string; }[];
  EventType: any;
  EventTypeArray: any[]=[];
  findCalendar: FormGroup;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;

  
  constructor( public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    private _location: Location,
    
    readonly accountsService: AccountsService,
    readonly trainingApprovalService: TrainingApprovalService,
    readonly headerService: HeaderService,
    readonly addSkillService: AddSkillService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly myContactsService: MyContactsService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router) {
      this.calendarEventForm1 = this._FormBuilder.group({
        eventName: [''],
        desc: [''],
        eventType: [''],
        status: [''],
        scope: [''],
        startDate: [''],
        compDate: [''],
      });
      this.ParticipantsForm = this._FormBuilder.group({
        PartyId: [''],
        RoleTypeID: [''],
      });
      this.findCalendar = this._FormBuilder.group({
        CalendarType: [''],
        partyId: [''],
        EventType: [''],
        FacilityId: [''],
        FixedAsset: [''],
      });
      this.calendartypeArray = [

        {
          label: 'Personal Calendar',
          value: 'CAL_PERSONAL'
        },
        {
          label: 'Manufacturing Calendar',
          value: 'CAL_MANUFACTURING'
        },
        {
          label: 'None',
          value: 'CAL_NONE'
        },
      ]; 
     }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    this.isButton = true;

    this.getFacility();
    this.getFixedAssetList();
    this.getPartyId();
    this.getEventType();
    this.getEvents();
    this.getScopeList();
    this.getCalenderRole();
    this.getCalStatusList();
    this.getCalStatusList();
    
  }
  
 homeButton(){
  this._Router.navigate(['/psa/Dashboard'])

}
cancelSubmit(){
  this._location.back();
}

  getCalenderRole() {
    this.spinner.show();
    this.myContactsService.getCalenderRole().subscribe(res => {
      this.CalenderRole = res.data[0].data;
      this.spinner.hide();
      for (const value of this.CalenderRole) {
        this.CalenderRoleArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
  deleteMyPortalAParticipants(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "fromDate": this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss'),
          "partyId": product.partyId,
          "roleTypeId": product.roleTypeId,
          "statusId": product.statusId,
          "workEffortId": this.workEffortId
        }
        this.myContactsService.deleteMyPortalAParticipants(req).subscribe(res => {
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  createMyPortalParticipants() {
    this.spinner.show();
    const formData = {
      "partyId": this.ParticipantsForm.value.PartyId,
      "roleTypeId": this.ParticipantsForm.value.RoleTypeID,
      "statusId": "PRTYASGN_ASSIGNED",
      "workEffortId": this.workEffortId
    }
    this.myContactsService.createMyPortalParticipants(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.ParticipantsForm.reset();
        this.getParticipants();
        this.isForm = false;
        this.isUpdate = false;
        this.isButton= true;
        this.activeCategory = 10;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  reset(){
    this.findCalendar.reset();
    this.getEvents();

  }
  getFindEvents(): void {
    this.spinner.show();
    
    switch (this.view) {
      case 'month': this.date = {
        startDate: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')
      };
        break;
      case 'day': this.date = {
        startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      };
        break;
      case 'week': this.date = {
        startDate: moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
      };
        break;
    }
   
    const req = {
      "calendarType": this.findCalendar.value.CalendarType ? this.findCalendar.value.CalendarType : "",
      "facilityId": this.findCalendar.value.FacilityId ? this.findCalendar.value.FacilityId : "",
      "fixedAssetId": this.findCalendar.value.FixedAsset ? this.findCalendar.value.FixedAsset : "",
      "partyId": this.findCalendar.value.partyId ? this.findCalendar.value.partyId : "",
      "workEffortTypeId": this.findCalendar.value.EventType ? this.findCalendar.value.EventType : ""
    }
  
    this.trainingApprovalService.getWorkEffortCalender(req)
      .then(data => {
        if (data.success) {
          
          this.spinner.hide();
          this.events = data.data.map(value => {
            console.log("events", this.events)
            console.log("value", value)
            return {
              ...value, ...{
                start: new Date(value.estimatedStartDate),
                end: new Date(value.estimatedCompletionDate),
                title: value.workEfforts.workEffortName,
                color: colors.red,
                actions: this.actions,
                allDay: false,
                resizable: {
                  beforeStart: true,
                  afterEnd: true,
                },
                draggable: true,
              }
            }
          });
        }
      });
     
  }
  getEvents(): void {
    this.spinner.show();
    
    switch (this.view) {
      case 'month': this.date = {
        startDate: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')
      };
        break;
      case 'day': this.date = {
        startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      };
        break;
      case 'week': this.date = {
        startDate: moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
      };
        break;
    }
    const req = {
      "calendarType": "",
      "facilityId": "",
      "fixedAssetId": "",
      "partyId": "",
      "workEffortTypeId": ""
    }
  
    this.trainingApprovalService.getWorkEffortCalender(req)
      .then(data => {
        if (data.success) {
          
          this.spinner.hide();
          this.events = data.data.map(value => {
            console.log("events", this.events)
            console.log("value", value)
            return {
              ...value, ...{
                start: new Date(value.estimatedStartDate),
                end: new Date(value.estimatedCompletionDate),
                title: value.workEfforts.workEffortName,
                color: colors.red,
                actions: this.actions,
                allDay: false,
                resizable: {
                  beforeStart: true,
                  afterEnd: true,
                },
                draggable: true,
              }
            }
          });
        }
      });
     
  }




 
  cancel(): void {
    this.calendarEventForm.reset();
    this.isForm = false;
    this.isUpdate = false;
  }



  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt" (click)="update()"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    }
   
  ];

  refresh: Subject<any> = new Subject();
  events: CalendarEvent[];
  
  

  activeDayIsOpen: boolean = true;


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: any): void {
    

    this.workEffortId = event.workEfforts.workEffortId
    this.calendarValue = {
      eventName: event.workEfforts.workEffortName,
      // desc: "",
      /// eventType:  "",
      // status:  event.,
      // scope: event.,
       startDate:  event.estimatedStartDate? moment(event.estimatedStartDate).format('yyyy-MM-dd') : '',
       compDate:  event.estimatedCompletionDate? moment(event.estimatedCompletionDate).format('yyyy-MM-dd') : '',
    };
    this.isForm = true;
    this.isUpdate = true;
   
    this.calendarEventForm1.patchValue({
      eventName: event.workEfforts.workEffortName,
     // desc: "",
     /// eventType:  "",
     // status:  event.,
     // scope: event.,
      startDate:  event.estimatedStartDate? moment(event.estimatedStartDate).format('yyyy-MM-DDTHH:mm') : '',
      compDate:  event.estimatedCompletionDate? moment(event.estimatedCompletionDate).format('yyyy-MM-DDTHH:mm') : '',

    
    });
    this.getParticipants();
   
  }

getParticipants() {
  this.spinner.show();
  this.myContactsService.getParticipants(this.workEffortId).subscribe(res => {
    this.ParticipantsList = res.data[0].data;
    this.spinner.hide();

  })
}
  addEvent(): void {
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.isForm = true;
      this.isButton = false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.toastr.error("Permission Denied"); 
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.toastr.error("Permission Denied");
    }

  }
  addNew(): void {
    this.isForm = true;
    this.isButton = false;
    this.isUpdate = false;
  }
  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
 
  addNewLeaves(): void {
    this._Router.navigateByUrl('/add-new-leaves');
  }
  cancelCalenderEvent(): void {
    this.spinner.show();
   
    const requestData ={
      
        "currentStatusId": "CAL_CANCELLED",
        "workEffortId": this.workEffortId
      
      }
     
    this.myContactsService.cancelCalenderEvent(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
      
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }

    getCalStatusList() {
      this.spinner.show();
      this.accountsService.getCalStatusList().subscribe(res => {
        this.CalStatusList = res.data[0].StatusItem;
        this.spinner.hide();
        for (const value of this.CalStatusList) {
          this.CalStatusListArray.push({
            label: value.transitionName,
            value: value.statusIdTo
          })
        }
      })
    
    }

    getScopeList() {
      this.spinner.show();
      this.myContactsService.getScopeList().subscribe(res => {
        this.ScopeList = res.data;
        this.spinner.hide();
        for (const value of this.ScopeList) {
          this.ScopeListArray.push({
            label: value.scopeData.description,
            value: value.scopeData.enumId
          })
        }
      })
    }
   

    submit(): void {
      this.spinner.show();
      if (this.isUpdate) {
        this.updateMyPortalCalendarEvent();
  
      } else {
        this.createMyPortalCalendarEvent();
      }
    }
    updateMyPortalCalendarEvent(): void {
      this.spinner.show();
      const fromActualdate=this.calendarEventForm1.get('startDate').value;
      this.estStart=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
      const thruActualdate=this.calendarEventForm1.get('compDate').value;
      this.estCompletion=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
      const requestData ={
        "currentStatusId": this.calendarEventForm1.value.status,
        "description": this.calendarEventForm1.value.desc ,
        "estimatedCompletionDate": this.datePipe.transform(this.calendarEventForm1.value.compDate,"yyyy-MM-dd"),
        "estimatedCompletionDate_c_compositeType": "Timestamp",
        "estimatedCompletionDate_c_hour": this.datePipe.transform(this.calendarEventForm1.value.compDate,"hh"),
        "estimatedCompletionDate_c_minutes": this.datePipe.transform(this.calendarEventForm1.value.compDate,"mm"),
        "estimatedStartDate": this.datePipe.transform(this.calendarEventForm1.value.startDate,"yyyy-MM-dd"),
        "estimatedStartDate_c_compositeType": "Timestamp",
        "estimatedStartDate_c_hour": this.datePipe.transform(this.calendarEventForm1.value.startDate,"hh"),
        "estimatedStartDate_c_minutes": this.datePipe.transform(this.calendarEventForm1.value.startDate,"mm"),
        "fixedAssetId": this.fixedAssetId,
        "partyId": "admin",
        "roleTypeId": "CAL_OWNER",
        "scopeEnumId":this.calendarEventForm1.value.scope,
        "start": "",
        "statusId": "PRTYASGN_ASSIGNED",
        "workEffortName":this.calendarEventForm1.value.eventName ,
        "workEffortTypeId":this.calendarEventForm1.value.eventType ,
    
        "actualCompletionDate": this.datePipe.transform(this.calendarEventForm1.value.actualCompletionDate,"yyyy-MM-dd"),
        "actualCompletionDate_c_compositeType": "Timestamp",
        "actualCompletionDate_c_hour": this.datePipe.transform(this.calendarEventForm1.value.actualCompletionDate,"hh"),
        "actualCompletionDate_c_minutes": this.datePipe.transform(this.calendarEventForm1.value.actualCompletionDate,"mm"),
        "actualStartDate": this.datePipe.transform(this.calendarEventForm1.value.actualStartDate,"yyyy-MM-dd"),
        "actualStartDate_c_compositeType": "Timestamp",
        "actualStartDate_c_hour": this.datePipe.transform(this.calendarEventForm1.value.actualStartDate,"hh"),
        "actualStartDate_c_minutes": this.datePipe.transform(this.calendarEventForm1.value.actualStartDate,"mm"),
        "workEffortId": this.workEffortId,
    
        }
       
      this.myContactsService.updateMyPortalCalendarEvent(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Updated Successfully");
          this.spinner.hide();
          this.calendarEventForm1.reset();
          this.getEvents();
         this.isUpdate = false;
          this.isForm = false;
          this.activeCategory=10;
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); }
     createMyPortalCalendarEvent(): void {
      this.spinner.show();
      const fromActualdate=this.calendarEventForm1.get('startDate').value;
      this.estStart=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
      const thruActualdate=this.calendarEventForm1.get('compDate').value;
      this.estCompletion=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
      
      const requestData ={
    
        "currentStatusId": this.calendarEventForm1.value.status,
        "description": this.calendarEventForm1.value.desc ,
        "estimatedCompletionDate": this.datePipe.transform(this.calendarEventForm1.value.compDate,"yyyy-MM-dd"),
        "estimatedCompletionDate_c_compositeType": "Timestamp",
        "estimatedCompletionDate_c_hour": this.datePipe.transform(this.calendarEventForm1.value.compDate,"hh"),
        "estimatedCompletionDate_c_minutes": this.datePipe.transform(this.calendarEventForm1.value.compDate,"mm"),
        "estimatedStartDate": this.datePipe.transform(this.calendarEventForm1.value.startDate,"yyyy-MM-dd"),
        "estimatedStartDate_c_compositeType": "Timestamp",
        "estimatedStartDate_c_hour": this.datePipe.transform(this.calendarEventForm1.value.startDate,"hh"),
        "estimatedStartDate_c_minutes": this.datePipe.transform(this.calendarEventForm1.value.startDate,"mm"),
        "fixedAssetId": this.fixedAssetId,
        "partyId": "admin",
        "roleTypeId": "CAL_OWNER",
        "scopeEnumId":this.calendarEventForm1.value.scope,
        "start": "",
        "statusId": "PRTYASGN_ASSIGNED",
        "workEffortName":this.calendarEventForm1.value.eventName ,
        "workEffortTypeId":this.calendarEventForm1.value.eventType ,
    
        }
       
      this.myContactsService.createMyPortalCalendarEvent(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Created Successfully");
          this.spinner.hide();
          this.calendarEventForm1.reset();
          this.getEvents();
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); }
  TaskList(){
    this._Router.navigate(["/psa/work-effort/task-list"])
  }
  Calendar(){
    this._Router.navigate(["/psa/work-effort/calendar"])
  }
  MyTime(){
    this._Router.navigate(["/psa/work-effort/my-time-effort"])
  }
  RequestList(){
    this._Router.navigate(["/psa/work-effort/request-list-effort"])
  }
  WorkEffort(){
    this._Router.navigate(["/psa/work-effort/work-effort-effort"])
  }
  Timesheet(){
    this._Router.navigate(["/psa/work-effort/timesheet-effort"])
  }
  SubmittedJobs(){
    this._Router.navigate(["/psa/work-effort/submitted-jobs"])
  }
  iCalendar(){
    this._Router.navigate(["/psa/work-effort/icalendar"])
  }
 

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
  getFacility() {
    this.spinner.show();
    this.addSkillService.getFacility().subscribe(res => {
      this.Facility = res.data[0].Facility;
      this.spinner.hide();
      for (const value of this.Facility) {
        this.FacilityArray.push({
          label: value.facilityName,
          value: value.description
        })
      }
    })
  }
  getEventType() {
    this.spinner.show();
    this.addSkillService.getEventType().subscribe(res => {
      this.EventType = res.data[0].data;
      this.spinner.hide();
      for (const value of this.EventType) {
        this.EventTypeArray.push({
          label: value.description,
          value: value.workEffortTypeId
        })
      }
    })
  }
  getFixedAssetList() {
    this.spinner.show();
    this.addSkillService.getFixedAssetList().subscribe(res => {
      this.FixedAssetList = res.data[0].node;
      this.spinner.hide();
      for (const value of this.FixedAssetList) {
        this.FixedAssetListArray.push({
          label: value.fixedAssetName,
          value: value.fixedAssetId
        })
      }
    })
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   }
}






