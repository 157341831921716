import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { PayablePaymentService } from '../payables-payment.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-create-payable-payment',
    templateUrl: './create-payable-payment.component.html',
    styleUrls: ['./create-payable-payment.component.css']
})
export class CreatePayablePaymentComponent implements OnInit {
    activeCategory = 2;
    array = [
        {
            label: 'Your Company Name',
            value: 'Company'
        }
    ];
    partyIds: any[];
    paymentTypeList: any[];
    payablePaymentForm: FormGroup;
    editMode: boolean;
    paymentId: string;
    paymentMethods: any[];
    GIAccounts: any[];
    currencyUomID: any[];
    paymentValue: any;
    constructor(
        readonly _GlobalResourceService: GlobalResourceService,
        readonly _PayablePaymentService: PayablePaymentService,
        readonly spinner: NgxSpinnerService,
        readonly _FormBuilder: FormBuilder,
        readonly  _Router: Router,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _ToastrService: ToastrService
    ) {
        this.partyIds = [];
        this.paymentTypeList = [];
        this.payablePaymentForm = this._FormBuilder.group({
            amount: ['', [Validators.required]],
            comments: [''],
            overrideGlAccountId: [''],
            partyIdFrom: ['Company'],
            partyIdTo: ['', [Validators.required]],
            paymentMethodId: [''],
            paymentRefNum: [''],
            paymentTypeId: [''],
            actualCurrencyAmount: [''],
            actualCurrencyUomId: [''],
            currencyUomId: [''],
            effectiveDate: [''],
            finAccountTransId: [''],
            statusId: ['']
        });
        this.paymentMethods = [];
        this.GIAccounts = [];
        this.paymentId = '';
        this.editMode = false;
        this.currencyUomID = [];
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.paymentId = this._ActivatedRoute.snapshot.queryParams.paymentId;
        if (this.paymentId) {
            this.editMode = true;
            this._PayablePaymentService.getPaymentById(this.paymentId)
                .then(data => {
                    if (data.data.length) {
                        this.paymentValue = data.data[0];
                        this.payablePaymentForm.patchValue({
                            ...data.data[0], ...{
                                effectiveDate: new Date(data.data[0].effectiveDate),
                                finAccountTransId: data.data[0].financialAccountTransaction.finAccountTransId
                            }
                        });
                    }
                });
        }
        this.getPartyIds();
        this.getPaymentType();
        this.getGIAccountsAndPaymentMethodIds();
        this.getCurrency();
    }
    getCurrency(): void {
        this.spinner.show();
        this._GlobalResourceService.getCurrency()
            .then(data => {
                this.spinner.hide();
                this.currencyUomID = data.data.currencyList.map(value => {
                    return {
                        label: value.description,
                        value: value.uomId
                    };
                });
            });
           
    }
    getGIAccountsAndPaymentMethodIds(): void {
        this.spinner.show();
        this._PayablePaymentService.getGIAccountAndPaymentMethod()
            .then(data => {
                if (data) {
                    this.spinner.hide();
                    this.paymentMethods = data.data.paymentMethodId.map(value => {
                        return {
                            label: value.description,
                            value: value.paymentMethodId
                        };
                    });
                    this.GIAccounts = data.data.glAccounts.map(value => {
                        return {
                            label: value.glAccountId,
                            value: value.glAccountId
                        };
                    });
                }
            });
           
    }
    getPartyIds(): void {
        this.spinner.show();
        this._GlobalResourceService.getPartyGroups()
            .then(data => {
                this.spinner.hide();
                this.partyIds = data.data.PersonsGroups.map(value => {
                    return {
                        label: value.partyId,
                        value: value.partyId
                    };
                });
            });
          
    }
    getPaymentType(): void {
        this.spinner.show();
        this._PayablePaymentService.getPaymentType()
            .then(data => {
                if (data) {
                    this.spinner.hide();
                    this.paymentTypeList = data.data.map(value => {
                        return {
                            label: value.description,
                            value: value.paymentTypeId
                        };
                    });
                }
            });
           
    }
    reset(): void {
        this.payablePaymentForm.reset();
        this._Router.navigate(['/financial/payable/payment-payables']);
    }
    update(): void {
        this.spinner.show();
        if (this.payablePaymentForm.valid) {
            const {
                actualCurrencyAmount,actualCurrencyUomId,amount,comments,currencyUomId,effectiveDate,finAccountTransId,
                overrideGlAccountId,partyIdFrom,partyIdTo,paymentMethodId,paymentRefNum,paymentTypeId,
                statusId
            } = this.paymentValue;
            this._PayablePaymentService.updatePayment(this.paymentId, {
                ...{
                    actualCurrencyAmount,actualCurrencyUomId,amount,comments,currencyUomId,effectiveDate,
                    finAccountTransId,overrideGlAccountId,partyIdFrom,partyIdTo,paymentMethodId,paymentRefNum,
                    paymentTypeId,statusId
                }, ...this.payablePaymentForm.value, ...{
                    effectiveDate: moment(this.payablePaymentForm.value.effectiveDate).format('YYYY-MM-DD HH:mm:ss')
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Updated');
                        this.spinner.hide();
                        this.payablePaymentForm.reset();
                        this._Router.navigate(['/financial/payable/payment-payables']);
                    }
                });
        }
      
    }
    submit(): void {
        this.spinner.show();
        if (this.editMode) {
            this.update();
        } else {
            if (this.payablePaymentForm.valid) {
                const {
                    amount, comments,
                    overrideGlAccountId, partyIdFrom,
                    partyIdTo, paymentMethodId,
                    paymentRefNum, paymentTypeId
                } = this.payablePaymentForm.value;
                this._PayablePaymentService.createOutgoingPayment({
                    amount, comments,
                    overrideGlAccountId, partyIdFrom,
                    partyIdTo, paymentMethodId,
                    paymentRefNum, paymentTypeId
                })
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Created');
                            this.spinner.hide();
                            this.payablePaymentForm.reset();
                            this._Router.navigate(['/financial/payable/payment-payables']);
                        }
                    });

            }
        }
       
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}