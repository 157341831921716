import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

import { Location } from '@angular/common';

import Swal from 'sweetalert2'
import { DatePipe } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { AddEmployeePositionService } from '../add-employee-position/add-employee-position.service';
import { ReallocationsService } from '../reallocation/reallocations.service';
@Component({
  selector: 'app-detail-performce-review',
  templateUrl: './detail-performce-review.component.html',
  styleUrls: ['./detail-performce-review.component.css']
})
export class DetailPerformceReviewComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  activeCategory=0;
  products3: any;
  paymentPartyGroup: any;
  createReview:FormGroup;
  createPrefItem:FormGroup;
  downloading: boolean;
  total = 0;
  activeCategoryMain=2;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  show: boolean;
  showItem: boolean;
  PerfItemTypeIDList: any;
  PerfItemTypeIDListArray: any[]=[];
  PerfItemType: any;
  PerfItemTypeArray: any[]=[];
 
  rows = 50;
  search= ""
  private employessSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
    search: this.search

  }
  
  id: string;
  allrole: any;
  allroleArray: any[]=[];
  positionType: any;
  positionArray: any[]=[];
  PerfReview: any;
  PerfReviewArray: any[]=[];
  actualfromdate: string;
  actualthroughdate: string;
  personId: any;
  partyIdArray: any[]=[];
  PerfReviewData: any;
  previewId: any;
  ItemPreview: any;
  getEstimateFrom: string;
  getEstimateTo: string;
  PaymentID: any;
  PaymentIDArray: any[]=[];
  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
      private _location: Location,
      readonly datePipe: DatePipe,
    readonly reallocationsService: ReallocationsService,
    readonly accountsService: AccountsService,
    readonly addEmployeePositionService: AddEmployeePositionService,
    readonly addSkillService: AddSkillService,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly activatedRoute: ActivatedRoute,
    readonly router: Router) {  
      this.createReview = this._FormBuilder.group({
      EmployeePartyID: [''],
      PerfReviewID: [''],
      ManagerPartyID: [''],
      ManagerRoleTypeID: [''],
      PaymentID: [''],
      EmplPositionID: [''],
      fromDate: [''],
      ThroughDate: [''],
      Comments:['']
    
    });
    this.createPrefItem = this._FormBuilder.group({
      PerfReviewItemTypeID: [''],
      PerfRatingTypeID: [''],
      Comments: [''],
   
    });
  }

  ngOnInit(): void {
    this.show=true;
    this.showItem=false;
    this.activatedRoute.queryParams.subscribe(params => {
      this.previewId = params["previewId"];
    })
    
    this.getPerfRatingTypeIDList();
    this.getPerfItemTypeIDList();
    this.getPartyId();
    this.getRoleAllType();
    this.getPositionType();
    this.getPerfReview();
    this.findItemPreview();
    this.findPerfReviewData();
    this.getPaymentID();
   
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  editPay(product){

    setTimeout(() => {
      const formValue = this.createPrefItem;
    formValue.patchValue({
    
      PerfReviewItemTypeID : product.data.perfReviewItemTypeId,
      PerfRatingTypeID : product.data.perfRatingTypeId

    })
    }, 2000);

  }
 

  edit(){
    this.spinner.show();
    const req = {
      "comments": '',
      "employeePartyId": '',
      "managerPartyId":'',
      "paymentId": '',
      "perfReviewId":this.previewId,
      "thruDate":'',
      "fromDate":'',
    }
      this.accountsService.findPerfReviewData(req.comments,req.employeePartyId,req.fromDate,req.managerPartyId,req.paymentId,req.perfReviewId,req.thruDate).subscribe((res:any)=> {
        this.PerfReviewData = res.data[0];
        this.spinner.hide();
        setTimeout(() => {
          let fromdate = this.PerfReviewData.fromDate
          this.getEstimateFrom = this.datePipe.transform(fromdate, "yyyy-MM-dd");
          let toEstdate = this.PerfReviewData.thruDate
          this.getEstimateTo = this.datePipe.transform(toEstdate, "yyyy-MM-dd");
        
          const formValue = this.createReview;
          formValue.patchValue({
            EmployeePartyID:  this.PerfReviewData.data.employeePartyId,
      PerfReviewID: this.PerfReviewData.data.perfReviewId,
      ManagerPartyID: this.PerfReviewData.data.managerPartyId,
    
      PaymentID: this.PerfReviewData.data.paymentId,
      EmplPositionID: this.PerfReviewData.data.emplPositionId,
      fromDate: this.getEstimateFrom,
      ThroughDate:  this.getEstimateTo,
      Comments:this.PerfReviewData.data.comments
          })

        }, 3000);
      
      
      })
     
  }
  findPerfReviewData(){
    this.spinner.show();
    const req = {
      "comments": '',
      "employeePartyId": '',
      "managerPartyId":'',
      "paymentId": '',
      "perfReviewId":this.previewId,
      "thruDate":'',
      "fromDate":'',
    }
      this.accountsService.findPerfReviewData(req.comments,req.employeePartyId,req.fromDate,req.managerPartyId,req.paymentId,req.perfReviewId,req.thruDate).subscribe((res:any)=> {
        this.PerfReviewData = res.data[0];
        this.spinner.hide();
      
      
      })
     
     }

     updatePerfReview(){
      this.spinner.show();
      let fromActualdate = this.createReview.get('fromDate').value;
      let fdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    
    const formdata={
      "emplPositionId": this.createReview.value.EmplPositionID,
      "employeePartyId":this.createReview.value.EmployeePartyID,
      "employeeRoleTypeId":"EMPLOYEE",
      "fromDate":fdate,
      "managerPartyId": this.createReview.value.ManagerPartyID,
      "managerRoleTypeId": this.createReview.value.ManagerRoleTypeID,
      "paymentId": this.createReview.value.PaymentID,
      "perfReviewId": this.previewId,
      "submitButton": "Y",
    }
    
      this.accountsService.updatePerfReview(formdata).subscribe((res:any)=> {
        if(res.success) {
          this.toastr.success(res.data.responseMessage);
          this.spinner.hide();
          this.closebutton.nativeElement.click();
          this.router.navigate(["/hr/hrm-performance-review"]  )
          this.getPerfReview();

         }      }
        ,(err) => {
         for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
         }}
      )
       this.spinner.hide();
    }


  getPositionType() {
    this.spinner.show();
    let emplPosition = {
      actualFromDateFrom: "",
      actualFromDateTo: "",
      actualThruDateFrom: "",
      actualThruDateTo: "",
      budgetId: "",
      budgetIdSearchType: "Contains",
      budgetItemSeqId: "",
      budgetItemSeqIdSearchType: "Contains",
      emplPositionId: "",
      emplPositionIdSearchType: "Contains",
      emplPositionTypeId: "",
      emplPositionTypeIdSearchType: "Contains",
      estimatedStartDateFrom: "",
      estimatedStartDateTo: "",
      estimatedThruDateFrom: "",
      estimatedThruDateTo: "",
      exemptFlag: "",
      fulltimeFlag: "",
      partyId: "",
      partyIdSearchType: "Contains",
      salaryFlag: "",
      statusId: "",
      statusIdSearchType: "Contains",
      temporaryFlag: ""
    }
    this.reallocationsService.getEmployeePosition(this.employessSize, emplPosition).subscribe(res => {

      this.positionType = res.data;
      this.spinner.hide();
      for (const value of this.positionType) {
        this.positionArray.push({
          label: value.emplPositionId,
          value: value.emplPositionId
        })
      }
    })
   
  }
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
    
     
     
     getPerfReview() {
      this.spinner.show();
      this.accountsService.getPerfReview().subscribe(res => {
        this.PerfReview = res.data;
        this.spinner.hide();
        for (const value of this.PerfReview) {
          this.PerfReviewArray.push({
            label: value.perfReviewId,
            value: value.perfReviewId
          })
        }
      })
    }
    
  getPartyId() {
    this.spinner.show();
    this.addEmployeePositionService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }

  getPaymentID() {
    this.spinner.show();
    this.accountsService.getPaymentID().subscribe(res => {
      this.PaymentID = res.data;
      this.spinner.hide();
      
      for (const value of this.PaymentID) {
        this.PaymentIDArray.push({
          label: value.data.paymentId,
          value: value.data.paymentId
        })
      }
    })
  }
  
  getPerfItemTypeIDList() {
    this.spinner.show();
    this.accountsService.getPerfItemTypeIDList().subscribe(res => {
      this.PerfItemType = res.data;
      this.spinner.hide();
      for (const value of this.PerfItemType) {
        this.PerfItemTypeArray.push({
          label: value.description,
          value: value.perfReviewItemTypeId
        })
      }
    })
  }
  findItemPreview() {
    this.spinner.show();
    this.accountsService.findItemPreview(this.previewId).subscribe(res => {
      this.ItemPreview = res.data;
      this.spinner.hide();
     
    })
  }
  
  removebudgetReview(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req={
          "employeePartyId":product.data.employeePartyId,
          "employeeRoleTypeId": "EMPLOYEE",
          "perfReviewId":product.data.perfReviewId,
          "perfReviewItemSeqId": product.data.perfReviewItemSeqId,
        }
        this.accountsService.deleteFindItemPreview(req).subscribe((res: any) => {
          this.findItemPreview();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )}
    })
  }
 
  onSubmitItem() { 
    this.spinner.show();
   
    const requestData = {
  "comments":  this.createPrefItem.value.Comments,
  "employeePartyId": this.PerfReviewData.data.employeePartyId,
  "employeeRoleTypeId":'EMPLOYEE',
  "perfRatingTypeId":this.createPrefItem.value.PerfRatingTypeID,
  "perfReviewId": this.PerfReviewData.data.perfReviewId,
  "perfReviewItemSeqId": "123",
  "perfReviewItemTypeId": this.createPrefItem.value.PerfReviewItemTypeID

    }
    this.accountsService.createPerfReviewItem(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.closebutton2.nativeElement.click();
        this.spinner.hide();
        this.findItemPreview();
       
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
    });

  }
  getPerfRatingTypeIDList() {
    this.spinner.show();
    this.accountsService.getPerfRatingTypeIDList().subscribe(res => {
      this.PerfItemTypeIDList = res.data;
      this.spinner.hide();
      for (const value of this.PerfItemTypeIDList) {
        this.PerfItemTypeIDListArray.push({
          label: value.description,
          value: value.perfRatingTypeId
        })
      }
    })
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
}
