<div class="container-fluid main-container-wrapper">
    <div class="port-header-contact justify-content-between bg-white mt-2 mb-20 font-medium" style="    margin-top: 6%;">
       <span class="color-black pl-1 titlePanels">Catalog Summary </span>
      
       <span class="d-flex">

        <button type="submit" (click)="homeButton();"
            class=" btn btn-outline-secondary mr-2">Home</button>

        <button type="submit" (click)="cancelSubmit();"
            class="btn btn-danger buttonclass ">Back</button>
    </span>

    </div>
    <div class="create-new-leade mt-2">
        <div class="container-fluid">
            <div class="w-100">
                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                    <div class="w-100">
                        <div>


                            <div class="divA">
                                <div class="w3-card-4 classCardView">
                                    <div class="header-tabs">

                                        <h4 class=" common-styling hCard">
                                            Catalog Information
                                        </h4>
                                        <span class="edit-buttons hCreate">

                                        <a   data-toggle="modal" (click)="getCatalogList();"
                                        data-target="#exampleModalCenter">Update</a>
                                      </span>
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                                <div *ngIf="detail" class="row">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6 col-12">Catalog ID</p>
                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{detail.prodCatalogId ? detail.prodCatalogId:'N/A'}}</span>
                                                        </div>
                                                        <div class="article">
                                                            <p class="col-lg-6 col-12">Catalog Name</p>
                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{detail.catalogName ? detail.catalogName:'N/A'}}</span>

                                                        </div>

                                                        <div class="article">
                                                            <p class="col-lg-6 col-12">Use Quick Add</p>
                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{detail.useQuickAdd ? detail.useQuickAdd:'N/A'}}</span>
                                                        </div>

                                                        <div class="article">
                                                            <p class="col-lg-6 col-12">Style Sheet</p>
                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{detail.styleSheet ? detail.styleSheet:'N/A'}}</span>
                                                        </div>

                                                        <div class="article">
                                                            <p class="col-lg-6 col-12">Header Logo</p>
                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{detail.headerLogo  ? detail.headerLogo :'N/A'}}</span>
                                                        </div>

                                                        <div class="article">
                                                            <p class="col-lg-6 col-12">Content Path Prefix</p>
                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{detail.contentPathPrefix ? detail.contentPathPrefix:'N/A'}}</span>
                                                        </div>

                                                        <div class="article">
                                                            <p class="col-lg-6 col-12">Template Path Prefix</p>
                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{detail.templatePathPrefix  ? detail.templatePathPrefix:'N/A'}}</span>
                                                        </div>

                                                        <div class="article">
                                                            <p class="col-lg-6 col-12">Permission required to add product to "ViewAllow" category</p>
                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{detail.viewAllowPermReqd ? detail.viewAllowPermReqd:'N/A'}}</span>

                                                        </div>

                                                        <div class="article">
                                                            <p class="col-lg-6 col-12">Permission required to add product to "PurchaseAllow" category</p>
                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{detail.purchaseAllowPermReqd ? detail.purchaseAllowPermReqd:'N/A'}}</span>
                                                        </div>



                                                    </div>





                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <br>

                            </div>





                        </div>
                        <div class="divB">
                            <div class="w3-card-4 classCardView">
                                <div class="header-tabs">


                                    <h4 class=" common-styling hCard">
                                        Stores
                                    </h4>


                                </div>
                                <div class="panel-body">
                                    <form [formGroup]="storyForm" class="borderTable">
                                        <div class="panel-body">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1"> Stores <span
                                                                style="color:red">*</span></label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <p-dropdown filter=true [options]="storeArray" placeholder="Stores" formControlName="productStoreId" optionlabel="label">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">From Date</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <p-calendar formControlName="fromDate"></p-calendar>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Through Date</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <p-calendar formControlName="thruDate"></p-calendar>
                                                        </div>
                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Sequence Num</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="text" formControlName="sequenceNum" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Sequence Num">
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button " style=" margin-left: -2%;">
                                                <button class="btn btn-secondary submit-btn" style="margin-right: 2%;" (click)="onSubmit()">{{editMode?'Update':'Submit'}}</button>

                                                <button type="submit" class="btn btn-danger " (click)="reset()">Cancel</button>
                                            </div>
                                        </div>
                                    </form><br>

                                    <div class="all-users-infomation border-top-grey font-13 font-medium own-user-select allUserCardDetail">
                                        <div class="form-group" style="width: 100%;">
                                            <div class="card own-account-table borderTable">
                                                <p-table [value]="storeList" [paginator]="false" [rows]="rows"   scrollHeight="150px"   scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr style="background: #0d3769;">
                                                            <th pSortableColumn="code" [ngStyle]="{'width':'190px'}">
                                                                <div class="checkbox-align" style="color: white;">
                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>
                                                                    Store ID
                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                            </th>

                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="quantity">
                                                                <div style="color: white;"> From Date Time
                                                                    <p-sortIcon field="quantity"></p-sortIcon>
                                                                </div>
                                                                <div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                <div style="color: white;"> Through Date Time
                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                </div>
                                                                <div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                <div style="color: white;"> Sequence Num
                                                                    <p-sortIcon field="price">
                                                                    </p-sortIcon>
                                                                </div>
                                                                <div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                <div style="color: white;"> Action</div>
                                                                <div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-product>
                                                        <tr>
                                                            <td [ngStyle]="{'width':'190px'}">
                                                                <div class="checkbox-align">
                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>
                                                                    {{product.storeName}} {{product.productStoreId}}
                                                                </div>
                                                            </td>

                                                            <td [ngStyle]="{'width':'190px'}">{{product.fromDate | date:'yyyy-MM-dd'}}</td>
                                                            <td [ngStyle]="{'width':'190px'}">{{product.thruDate | date:'yyyy-MM-dd'}}</td>
                                                            <td [ngStyle]="{'width':'190px'}">{{product.sequenceNum}}</td>
                                                            <td [ngStyle]="{'width':'190px'}">
                                                                <div>
                                                                    <svg (click)="onUpdate(product.productStoreId,product.fromDate,product.thruDate,product.sequenceNum)" class="hover" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                                        <g transform="translate(0 -0.004)">
                                                                            <g transform="translate(0 1.52)">
                                                                                <path
                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                    transform="translate(0 -34.137)" />
                                                                            </g>
                                                                            <g transform="translate(4.548 0.004)">
                                                                                <g transform="translate(0 0)">
                                                                                    <path
                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                    <svg (click)="onDelete(product.productStoreId,product.fromDate)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                        <defs>
                                                                            <style>
                                                                                .a {
                                                                                    fill: #f44336;
                                                                                }
        
                                                                                .b {
                                                                                    fill: #fafafa;
                                                                                    margin-left: 20px;
                                                                                }
                                                                            </style>
                                                                        </defs>
                                                                        <path class="a fill-color"
                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                        <path class="b"
                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                            transform="translate(-147.576 -147.576)" />
                                                                    </svg>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    </ng-template>
                                                </p-table>

                                                <p class="paginate_data">
                                                    </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <br>








                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Product Catalog</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                        Update Product Catalog</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="catalogForm" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12 mb-20">If you don't populate a Catalog with Categories, it will not be shown in the Browse Catalogs/Categories tree</div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Catalog [ID]</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="prodCatalogId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter CatalogID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Catalog Name <span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="catalogName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Catalog Name">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Use Quick Add
                                              
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="catalogArray" formControlName="useQuickAdd" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Style Sheet
                                              
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="styleSheet" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enetr Style Sheet">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Header Logo </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="headerLogo" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Header Logo">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Content Path Prefix </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="contentPathPrefix" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enetr Content Path Prefix ">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Template Path Prefix</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="templatePathPrefix" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Template Path Prefix">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Add product to "ViewAllow" category</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="catalogArray" formControlName="viewAllowPermReqd" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Add product to "PurchaseAllow"
                                                        category</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="catalogArray" formControlName="purchaseAllowPermReqd" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>


                                        <div class="main-submit-button" style="margin-right: 7%;">
                                            <button type="submit" (click)="onSubmitData()" class="btn btn-secondary submit-btn">{{editMode? 'Update':'Submit'}}</button>

                                        </div>
                                    </form>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>