<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
          <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
            <span class="color-black pl-1 titlePanels">EDI Overview</span>
           
             <span>
                <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
            </span> 
         </div>
         
            <div class=" bg-white color-grey">
                <div class="container-fluid">

                </div>

                <div>
                    <div class="container-fluid">
                        <div class="row" >
                          <form class="w-100">
                            
                              <div class="w-100">
                                <div class="panel-group">
                                  <div *ngIf="activeCategoryValue==2">
                                    <div style="width: 49%; float:left">

                                     
                                    <div class="w3-card-4 cardEffect" style="border:1px solid;
                                    border-color:#0d3769 !important;">
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Import/Export
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/edi/import-export">Party Import/Export</p>
                                                  </div>
                                               
                  
                
        
        
        
                                                  
                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div> 
                                    <br>
                                    <div class="w3-card-4 cardEffect" style="border:1px solid;
                                    border-color: #0d3769 !important;">
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs" >
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Accounting
                                          </h4>
                                        
                                      </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <div  class="row">
                                                    <div class="article-container">
                                                      <div class="article">
                                                        <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/edi/balance-sheet-headings-import">Balance Sheet Heading</p>
                                                        <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/edi/balance-sheet-import">Balance Sheet</span>
                                                      </div>
                                                      <div class="article">
                                                        <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/edi/trial-balance-import">Trial Balance Sheet Heading</p>
                                                        <span style="    color: #0000FF;" routerLink="/edi/trial-balance-only-import" class="color-grey pl-20 col-lg-10 col-12">Trial Balance</span>
                                                      </div>
                                                      <div class="article">
                                                        <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/edi/cash-flow-import">Cash Flows</p>
                                                        <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/edi/gl-accounts-import">Gl Accounts</span>
                                                      </div>
                                                      <div class="article">
                                                        <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/edi/open-customers-invoices-import">Open Customers Invoices</p>
                                                        <span style="    color: #0000FF;" routerLink="/edi/open-vendors-invoices-import" class="color-grey pl-20 col-lg-10 col-12">Open Vendors Invoices</span>
                                                      </div>
    
                                                    </div>
                                                  
                                                    
                                                </div>
                                              
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    
                                  
                                    
                                    </div>
                                    <br>
                                    <div class="w3-card-4 cardEffect" style="border:1px solid;
                                    border-color: #0d3769 !important;">
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Vendors
                                          </h4>
                                        
                                      </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12"
                                                 routerLink="/edi/vendors-only-import">Vendors </p>
                                                      
                                              
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    
                                  
                                    
                                    </div>
                                   
                                    <br>
                                    <div class="w3-card-4 cardEffect" style="border:1px solid;
                                    border-color: #0d3769 !important;">
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Inventory
                                          </h4>
                                        
                                      </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12"
                                                 routerLink="/edi/inventory-count-import">Inventory Count </p>
                                                      
                                              
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    
                                  
                                    
                                    </div>
                                   
                                    <br>
                                    <div class="w3-card-4 cardEffect" style="border:1px solid;
                                    border-color: #0d3769 !important;">
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Shipments
                                          </h4>
                                        
                                      </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12"
                                                 routerLink="/edi/vendors-only-import">Shipments
                                                </p>
                                                      
                                              
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    
                                  
                                    
                                    </div>
                                   
                                    <br>
                                   </div>
                                   
                                   <div style="width: 49.5%; float:right">
                                    <div class="w3-card-4 cardEffect" style="border:1px solid;
                                    border-color:#0d3769 !important;">
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Users
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/edi/issue-created-import">Setup users</p>
                                                    <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/edi/security-group-import">Security Group</span>
                                                  </div>
                                                

                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    <br>
                                    <div class="w3-card-4 cardEffect" style="border:1px solid;
                                    border-color:#0d3769 !important;">
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Email Configuration Setup
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/edi/email-configuration-import">Email Configuration Setup</p>
                                                  </div>

                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    <br>
                                    <div class="w3-card-4 cardEffect" style="border:1px solid;
                                    border-color:#0d3769 !important;
                                    ">
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                         CRM
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                    <div class="article">
                                                      <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/edi/customers-only-import">Customers</p>
                                                      <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/edi/customers-sites-import">Customer Sites</span>

                                                    </div>
  
                                                  </div>
                                             
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    <br>
                                 
                                    <div class="w3-card-4 cardEffect" style="border:1px solid;
                                      border-color:#0d3769 !important;
                                      ">
                                        <header class="w3-container w3-blue">
                                          <div class="header-tabs">
                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                              Warehouses
                                            </h4>
                                         
                                          </div>
                                        </header>
                                    
                                        <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <div  class="row">
                                                  <div class="article-container">
                                                      <div class="article">
                                                        <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/edi/warehouses-only-import">Warehouses</p>
                                                        <span style="    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/edi/warehouses-location-import">Warehouses Locations</span>
  
                                                      </div>
    
                                                    </div>
                                               
                                                  
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    
                                       
                                    </div>
                                  
                                   
                                  
                                  
                                  </div>
                                   
                                   
                                  
                                
    
                              </div>
                              </div>
                            </div>
    
                          </form>
                        </div>
                      </div>
                  

                </div>

            </div>

        </div>
    </div>
</div>

