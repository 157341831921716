<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Profile of [{{this.costComponentCalcId}}]</span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Back</button>

            </div>
            <div class="w3-card-4 classCard">
                <div class=" bg-white color-grey">
                    <div class="header-tabs">
                        <h4 class=" common-styling GRight ml-2">
                            Cost Information
                        </h4>
                        <span class="edit-buttons  hCreate mr-2">
                            <a data-toggle="modal" data-target="#exampleModalCenter" (click)="getGlCostById()">Update</a>
                        </span>

                    </div>

                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group">
                                                <div *ngIf="activeCategory==2">

                                                    <div class="panel-body" *ngIf="this.costdata">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div *ngIf="costdata" class="color-black container">
                                                                <div class="row">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Description</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{costdata.description
                                                                                ? costdata.description:
                                                                                'NA'}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Cost Gl Account Type ID
                                                                            </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{costdata.costGlAccountTypeId
                                                                                ? costdata.costGlAccountTypeId :
                                                                                'NA'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Offsetting Gl Account Type ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{costdata.offsettingGlAccountTypeId
                                                                                ?
                                                                                costdata.offsettingGlAccountTypeId
                                                                                : 'NA'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Fixed Cost</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{costdata.fixedCost
                                                                                ? costdata.fixedCost :
                                                                                'NA'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Variable Cost

                                                                            </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{costdata.variableCost
                                                                                ? costdata.variableCost :
                                                                                'NA'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Per Milli Second
                                                                            </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{costdata.perMilliSecond? costdata.perMilliSecond :
                                                                                    'NA'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12"> Currency</p>

                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{costdata.currencyUomId
                                                                                ? costdata.currencyUomId :
                                                                                'NA'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Cost Custom Method ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{costdata.costCustomMethodId
                                                                                ? costdata.costCustomMethodId :
                                                                                'NA'}}</span>
                                                                        </div>



                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update-New-Cost-Component-Calc</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="container-fluid main-container-dialog">
                    <div class="row">
                        <div class="col-12">
                            <div class=" bg-white color-grey" style="width: 100%;">
                                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                                    <div class="container-fluid">
                                        <ul class="tabbing-section tabbing-accordians tabClass">

                                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                                                    Add New Cost Component Calc</a></li>
                                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                                                    Update New Cost Component Calc</a></li>


                                        </ul>
                                    </div>

                                    <div class="create-new-leade mt-2">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <form class="w-100" [formGroup]="createCost">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Description
                
                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" formControlName="description">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Cost Gl Account Type ID<span
                                                                            style="color:red">*</span> </label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown placeholder="Enter Cost Gl Account Type ID" filter="true" formControlName="costGlAccountTypeId" [options]="glAccountTypeArray" optionlabel="label">

                                                                    </p-dropdown>
                                                                </div>



                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Offsetting Gl Account Type
                                                                        ID</label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <p-dropdown placeholder="Enter Offsetting Gl Account Type ID" filter="true" formControlName="offsettingGlAccountTypeId" [options]="glAccountTypeArray" optionlabel="label">

                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Fixed Cost
                
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fixed Cost" formControlName="fixedCost">
                                                                </div>



                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Variable Cost</label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Variable Cost" formControlName="variableCost">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Per Milli Second </label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Per Milli Second	" formControlName="perMilliSecond">
                                                                </div>



                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Currency<span
                                                                            style="color:red">*</span> </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <p-dropdown placeholder="Enter Currency" filter="true" formControlName="currencyUomId" [options]="uomArray" optionlabel="label">

                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Cost Custom Method ID</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown placeholder="Enter Cost Custom Method ID" filter="true" formControlName="costCustomMethodId" [options]="costCustomMethodIDArray" optionlabel="label">

                                                                    </p-dropdown>

                                                                </div>



                                                            </div>
                                                        </div>


                                                    </div>


                                                </form>
                                                <div class="main-submit-button" style="margin-left: 88%;">
                                                    <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                                    <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>


                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>