<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Vendor
                </span>
                <span class="color-black pl-1" *ngIf="!show"> >Add Vendor
                </span>
                <span class="color-black pl-1" *ngIf="show"> >Update Vendor
                </span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                        <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                            General</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                                Contact</a></li>
                                <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategoryFunction(4)"><a>
                                    Address</a></li>
                                    <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategoryFunction(5)"><a>
                                        Financial</a></li>
                                      
                   
          
                    </ul>
                  </div>
              
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createVendor">
                                <div *ngIf="activeCategory==2">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Vendor ID<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2" *ngIf="!show">
                                                <p-dropdown   formControlName="partyId" (onChange)="getVendorPartyIdListById($event.value)"
                                            placeholder="Enter Party ID" filter="true"
                                            [options]="listVendorsIntialArray" optionlabel="label">
                                                            
                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-2" *ngIf="show">
                                                <input type="email"  formControlName="partyId"
                                                class="form-control" id="exampleInputEmail1"
                                               aria-describedby="emailHelp" disabled
                                                >
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Vendor Type
                                                
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" name="vendorType"
                                                class="form-control" >
                                            </div>
                                      
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Type of Product or Service Offered
                                                
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" name="typeProduct"  class="form-control" >
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Vendor Name
                                                
                                                </label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="email" name="VendorName"  class="form-control">
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Vendor Phone
                                                
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" name="VendorPhone"  class="form-control">
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Vendor Fax
                                                
                                                </label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="email" name="VendorFax"  class="form-control">
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Vendor Email
                                                
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" name="VendorEmail"  class="form-control" >
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Company Website
                                                
                                                </label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="email" name="CompanyWebsite"  class="form-control">
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Vendor is Active
                                                
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" name="VendorisActive"  class="form-control"  >
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Date Created
                                                
                                                </label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="date" name="DateCreated"  class="form-control"> 
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Vendor Forms
                                                
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" name="VendorForms"  class="form-control" >
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Map
                                                
                                                </label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="email" name="Map"  class="form-control">
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Last Name
                                                
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email"  class="form-control" 
                                                 >
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Phone
                                                
                                                </label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="email"  class="form-control">
                                            </div>


                                        </div>
                                    </div>

                                  
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Manifest Company Title
                                                
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="manifestCompanyTitle"
                                                class="form-control" id="exampleInputEmail1"
                                               aria-describedby="emailHelp" placeholder="Enter Manifest Company Title"
                                                >
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Manifest Policies
                                                
                                                </label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="manifestPolicies"
                                                class="form-control" id="exampleInputEmail1"
                                              aria-describedby="emailHelp" placeholder="Enter Manifest Policies"
                                               >
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Manifest Company Name
                                                
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="manifestCompanyName"
                                                class="form-control" id="exampleInputEmail1"
                                               aria-describedby="emailHelp" placeholder="Enter Manifest Company Name"
                                                >
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Manifest Logo Url
                                                
                                                </label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="manifestLogoUrl"
                                                class="form-control" id="exampleInputEmail1"
                                               aria-describedby="emailHelp" placeholder="Enter Manifest Logo Url"
                                                >
                                            </div>


                                        </div>
                                    </div>
                                   
                                
                                </div>
                                <div class="main-submit-button" style="margin-right: 19%;">
                                    <button type="submit"
                                    (click)="changeactiveCategoryFunction(3)"
                                        class="btn btn-secondary submit-btn">Continue</button>

                                </div>
                            </div>
                            <div *ngIf="activeCategory==3">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                  
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInputB">
                                                <label for="exampleInputEmail1">Title
                                                
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" name="Title"  class="form-control" >
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">First Name
                                                
                                                </label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="email" name="FirstName"  class="form-control">
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInputB">
                                                <label for="exampleInputEmail1">Middle initials
                                                
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" name="Middleinitials"  class="form-control">
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Last Name	
                                                
                                                </label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="email" name="LastName"  class="form-control">
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInputB">
                                                <label for="exampleInputEmail1">Contact Phone
                                                
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" name="ContactPhone"  class="form-control">
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Contact Email
                                                
                                                </label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="email" name="ContactEmail"  class="form-control">
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInputB">
                                                <label for="exampleInputEmail1">Cc
                                                
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" name="Cc"  class="form-control">
                                            </div>

                           

                                        </div>
                                    </div>
                                   
                                
                                </div>
                                <div class="main-submit-button" style="margin-right: 31%;">
                                    <button type="submit"
                                    (click)="changeactiveCategoryFunction(4)"
                                        class="btn btn-secondary submit-btn">Continue</button>

                                </div>
                            </div>
                            <div *ngIf="activeCategory==4">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInputD">
                                                <label for="exampleInputEmail1">Address
                                                
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" disabled 
                                               
                                                [(ngModel)]="this.addressVendor"
                                               
                                                [ngModelOptions]="{standalone:true}"
                                                  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" 
                                                 >
                                            </div>

                                          


                                        </div>
                                    </div>
                                 
                                   
                                   
                                   
                                   
                                
                                </div>
                                <div class="main-submit-button" style="margin-right: 34%;">
                                    <button type="submit"
                                    (click)="changeactiveCategoryFunction(5)"
                                        class="btn btn-secondary submit-btn">Continue</button>

                                </div>
                            </div>
                            <div *ngIf="activeCategory==5">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                  
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInputE">
                                                <label for="exampleInputEmail1">Credit Limit
                                                
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" name="CreditLimit"  class="form-control">
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Default Expense Account
                                                
                                                </label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="email" name="DefaultExpenseAccount" class="form-control">
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInputE">
                                                <label for="exampleInputEmail1">Default Payables Account
                                                
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                
                                                <input type="email" name="DefaultPayablesAccount" class="form-control">
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Company Tax ID
                                                
                                                </label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="email" name="CompanyTaxID" class="form-control">
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInputE">
                                                <label for="exampleInputEmail1">Vendor Eligible for 1099
                                                
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" name="1099" class="form-control">
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Terms of Agreements  
                                                
                                                </label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="email" name="agreement" class="form-control">
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInputE">
                                                <label for="exampleInputEmail1">Contract
                                                
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" name="Contract" class="form-control">
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Rates / Labor cost Per product 
                                                
                                                </label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="email" name="rates" class="form-control">
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInputE">
                                                <label for="exampleInputEmail1">Opening Balance
                                                
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" name="OpeningBalance" class="form-control">
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Work Calendar

                                                
                                                </label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="email" name="calender" class="form-control">
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInputE">
                                                <label for="exampleInputEmail1">Currency
                                                
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" name="Currency" class="form-control">
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Print Check As

                                                
                                                </label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="email" name="PrintCheckAs" class="form-control">
                                            </div>


                                        </div>
                                    </div>
                                   
                                   
                                
                                </div>
                                <div class="main-submit-button" style="margin-right: 24%;">
                                    <button type="submit"
                                    (click)="changeactiveCategoryFunction(6)"
                                        class="btn btn-secondary submit-btn">Save</button>

                                </div>
                            </div>
                          
                                

                            </form>
                           
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>