import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';

@Component({
  selector: 'app-fsm-company',
  templateUrl: './fsm-company.component.html',
  styleUrls: ['./fsm-company.component.css']
})
export class FsmCompanyComponent implements OnInit {

  @ViewChild('closebutton') closebutton;
  activeCategory = 9;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;

  constructor(
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) { }

  ngOnInit(): void {
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  toCustomFileds()
  {
    this._Router.navigate(['/fsm/fsmSetting/custom-fields'])
  }

  toTerminology()
  {
    this._Router.navigate(['/fsm/fsmSetting/terminology'])

  }

  toSubscriptionDetails()
  {
    this._Router.navigate(['/fsm/fsmSetting/subscription-details'])

  }

  toUploadLogo()
  {
      this._Router.navigate(['/fsm/fsmSetting/upload-logo'])

  }

  toDocumentType()
  {
    this._Router.navigate(['/fsm/fsmSetting/document-types'])

  }

  toAppearance()
  {
    this._Router.navigate(['/fsm/fsmSetting/appearance'])

  }

  toLandmark()
  {

    this._Router.navigate(['/fsm/fsmSetting/landmarks'])

  }


  toIntegration()
  {
    this._Router.navigate(['/fsm/fsmSetting/integration'])

  }

  toClassSettings()
  {
    this._Router.navigate(['/fsm/fsmSetting/class-settings'])

  }

  toNewWorkOrder()
  {
    this._Router.navigate(['fsm/newWorkOrder'])
  }

  toCustomer()
  {
    this._Router.navigate(['fsm/customer'])
  }

  toReports()
  {
    this._Router.navigate(['/fsm/reports'])
  }

  toInvoices()
  {
    this._Router.navigate(['/fsm/invoices'])

  }

  toWorkOrder()
  {
    this._Router.navigate(['/fsm/main'])
  }

  toPeople()
  {
    this._Router.navigate(['/fsm/people'])
  }

  toTimeSheet()
  {
    this._Router.navigate(['/fsm/timesheet'])
  }

  toSetting()
  {
    this._Router.navigate(['/fsm/fsmSetting/work-order'])
  }

  


  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
