<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" style="cursor: pointer;">Address Match Map
                </span>
                <span class="color-black pl-1"> > Import Address Match Map
                </span>
                <button type="submit" class="btn btn-danger buttonclass" (click)="GoToQuickLink()">Cancel</button>&nbsp;
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                                   Import Address Match Map
                                </a></li>
                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                                    Update New Address Match Map
                                </a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                               
                                <form class="w-100"  [formGroup]="fileUpload">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group ml-5">
                                              
                                                <input type="file" formControlName="file" (change)="fileProgress($event)" class="classFile" id="exampleInputEmail1" aria-describedby="emailHelp"  accept="">
                                
                                            </div>
                                        </div>
                                       
                                      
                                    </div>
                                </form>
                                <div class="main-submit-button" style="margin-left: -69%;">
                                    <button type="submit" 
                                        class="btn btn-secondary submit-btn" (click)="onUpload()">Upload CSV</button>&nbsp;
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>