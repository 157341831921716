import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { ProductionService } from '../production.service';
@Component({
  selector: 'app-create-jobshop-task',
  templateUrl: './create-jobshop-task.component.html',
  styleUrls: ['./create-jobshop-task.component.css']
})
export class CreateJobshopTaskComponent implements OnInit {
  createJob:FormGroup;
  activeCategory=2;
  workEffortId: any;
  taskList: any;
  taskListArray: any[]=[];
  startDate: string;
  completeDate: string;
  taskId: any;
  show: boolean;
  getEstimateFrom: string;
  estimatedCompletionDate: string;
  dateComplete: any;

  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,
   
    readonly spinner: NgxSpinnerService,
    readonly productionService:ProductionService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createJob = this._FormBuilder.group({
        routingtaskid: [''],
        SequenceNum:[''],
        RoutingTaskName:[''],
        Description: [''],
        ReservPersons:[''],
        CalculatedCompletionDate:[''],
        StartDate: [''],
        EstimatedSetupTime:[''],
        EstimatedUnitRunTime:['']
       
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params["workEffortId"];
      this.taskId=params["taskId"];

    });
    this.getroutingTaskList();
    if(this.taskId){
      this.show=true;
      this.getProductiontasksEditById();
    }else{
      this.show=false;
    }
  }
  getProductiontasksEditById() {
    this.spinner.show();
    this.productionService.getProductiontasksEditById(this.workEffortId,this.taskId).subscribe((res: any) => {
      this.spinner.hide();
      this.completeDate=res.data.estimatedCompletionDate;
      setTimeout(() => {
       this.dateComplete=res.data.estimatedStartDate
        const fromdate=res.data.estimatedStartDate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const estimatedCompletionDate=res.data.estimatedCompletionDate
        this.estimatedCompletionDate=this.datePipe.transform(estimatedCompletionDate,"yyyy-MM-dd");
        const formValue = this.createJob;
        formValue.patchValue({
          routingtaskid: res.data.workEffortId,
          SequenceNum:res.data.sequenceNum,
          RoutingTaskName:res.data.workEffortName,
          Description:res.data.description,
          ReservPersons:res.data.reservPersons,
          CalculatedCompletionDate: this.estimatedCompletionDate,
          StartDate:  this.getEstimateFrom,
          EstimatedSetupTime:res.data.estimatedSetupMillis,
          EstimatedUnitRunTime:res.data.estimatedTotalMilliSeconds,
        })
      
      }, 2000);
    })
  
  }
  
  getroutingTaskList() {
    this.spinner.show();
    
    this.productionService.getroutingTaskList().subscribe(res => {
      this.taskList = res.data.lookupResult;
      this.spinner.hide();
      for (const value of this.taskList) {
        this.taskListArray.push({
          label: value.workEffortId,
          value: value.workEffortId
        })
      }
    })
    
  }
  onUpdate() {
    this.spinner.show();
    const startDate = this.createJob.get('StartDate').value;
    this.startDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "description": this.createJob.value.Description,
      "estimatedCompletionDate":  this.dateComplete ,
      "estimatedMilliSeconds": this.createJob.value.EstimatedUnitRunTime,
      "estimatedSetupMillis": this.createJob.value.EstimatedSetupTime,
      "estimatedStartDate":  this.startDate ,
      "priority": this.createJob.value.SequenceNum,
      "reservPersons":this.createJob.value.ReservPersons,
      "routingTaskId": this.createJob.value.routingtaskid,
      "workEffortName": this.createJob.value.RoutingTaskName,
    }

    this.productionService.updateProductiontasks(this.workEffortId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this._Router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId:this.workEffortId } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  onSubmit() {
    this.spinner.show();
    const startDate = this.createJob.get('StartDate').value;
    this.startDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const completeDate = this.createJob.get('CalculatedCompletionDate').value;
    this.completeDate = this.datePipe.transform(completeDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "description": this.createJob.value.Description,
      "estimatedCompletionDate":  this.completeDate ,
      "estimatedMilliSeconds": this.createJob.value.EstimatedUnitRunTime,
      "estimatedSetupMillis": this.createJob.value.EstimatedSetupTime,
      "estimatedStartDate":  this.startDate ,
      "priority": this.createJob.value.SequenceNum,
      "reservPersons":this.createJob.value.ReservPersons,
      "routingTaskId": this.createJob.value.routingtaskid,
      "workEffortName": this.createJob.value.RoutingTaskName,
    }
    this.productionService.postProductiontasks(this.workEffortId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this._Router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId:this.workEffortId } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);}
    });
  }

 

  
  cancelSubmit(){
    this._Router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId: this.workEffortId} })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
