import { DatePipe } from '@angular/common';
import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from '../accounts/accounts.service';
import { MyContactsService } from '../contacts/my-contacts/my-contacts.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-tasks-crm',
  templateUrl: './tasks-crm.component.html',
  styleUrls: ['./tasks-crm.component.css']
})
export class TasksCrmComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory=2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  hours: { label: string; value: string; }[];
  amPM: { label: string; value: string; }[];
  minute: { label: string; value: string; }[];
  addTask: any;
  taskForm: any;
  securityArray: { label: string; value: string; }[];
  personId: any;
  SalesOpportunity: any;
  SalesOpportunityArray:any []=[];
  Cases: any;
  CasesArray: any[]=[];
  AvailabilityArray: { label: string; value: string; }[];
  PurposeArray: { label: string; value: string; }[];
  OutboundArray: { label: string; value: string; }[];
  yesNoArray: { label: string; value: string; }[];
  partyIdArray: any[]=[];
  pendingActivityTaskCrmAll: any;
  activeCategoriesA=4;
  activeCategoies1=2;
  activeCategories=9;
  constructor(
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly _Router: Router,
    readonly router: Router,
    private _location: Location,
  ) {
    this.taskForm = this._FormBuilder.group({
      Subject: [''] ,
      Security: [''] ,
      RelatedParty: [''] ,
      Opportunity: [''] ,
      Case: [''] ,
      Purpose: [''] ,
      EndDateandTime: [''] ,
      Duration: [''] ,
      StartDateandTime: [''] ,
      Description: [''] ,
      Availability: [''] ,
      IgnoreSchedulingConflicts: [''] ,
      CompletionDateandTime: [''] ,
      CompletionDateHour: [''] ,
      CompletionAmPm: [''] ,
      CompletionDateMinutes: [''] ,
      StartDateAmPm: [''] ,
      StartDateandHour: [''] ,
   
      StartDateMinutes: [''] ,
    });
    this.hours = [
      {
        label: '1',
        value: '1'
      },
      {
        label: '2',
        value: '2'
      },
      {
        label: '3',
        value: '3'
      },
      {
        label: '4',
        value: '4'
      },
      {
        label: '5',
        value: '5'
      },
      {
        label: '6',
        value: '6'
      },
      {
        label: '7',
        value: '7'
      },
      {
        label: '8',
        value: '8'
      },
      {
        label: '9',
        value: '9'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },

    ];
    this.minute = [
      {
        label: '00',
        value: '00'
      },
      {
        label: '01',
        value: '01'
      },
      {
        label: '02',
        value: '02'
      },
      {
        label: '03',
        value: '03'
      },
      {
        label: '04',
        value: '04'
      },
      {
        label: '05',
        value: '05'
      },
      {
        label: '06',
        value: '06'
      },
      {
        label: '07',
        value: '07'
      },
      {
        label: '08',
        value: '08'
      },
      {
        label: '09',
        value: '09'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },
      {
        label: '13',
        value: '13'
      },
      {
        label: '14',
        value: '14'
      },
      {
        label: '15',
        value: '15'
      },
      {
        label: '16',
        value: '16'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '19',
        value: '19'
      },
      {
        label: '20',
        value: '20'
      },
      {
        label: '21',
        value: '21'
      },
      {
        label: '22',
        value: '22'
      },
      {
        label: '23',
        value: '23'
      },
      {
        label: '24',
        value: '24'
      },
      {
        label: '25',
        value: '25'
      },
      {
        label: '26',
        value: '26'
      },
      {
        label: '27',
        value: '27'
      },
      {
        label: '28',
        value: '28'
      },
      {
        label: '29',
        value: '29'
      },
      {
        label: '30',
        value: '30'
      },
      {
        label: '31',
        value: '31'
      },
      {
        label: '32',
        value: '32'
      },
      {
        label: '33',
        value: '33'
      }, {
        label: '34',
        value: '34'
      },
      {
        label: '35',
        value: '35'
      }, {
        label: '36',
        value: '36'
      },
      {
        label: '37',
        value: '37'
      }, {
        label: '38',
        value: '38'
      },
      {
        label: '39',
        value: '39'
      },
      {
        label: '40',
        value: '40'
      }, {
        label: '41',
        value: '41'
      },
      {
        label: '42',
        value: '42'
      },
      {
        label: '43',
        value: '43'
      }, {
        label: '44',
        value: '44'
      },
      {
        label: '45',
        value: '45'
      },
      {
        label: '46',
        value: '46'
      }, {
        label: '47',
        value: '47'
      },
      {
        label: '48',
        value: '48'
      },
      {
        label: '49',
        value: '49'
      }, {
        label: '50',
        value: '50'
      },
      {
        label: '51',
        value: '51'
      },
      {
        label: '52',
        value: '52'
      },
      {
        label: '53',
        value: '53'
      },{
        label: '54',
        value: '54'
      },{
        label: '55',
        value: '55'
      },{
        label: '56',
        value: '56'
      },{
        label: '57',
        value: '57'
      },
      {
        label: '58',
        value: '58'
      },
      {
        label: '59',
        value: '59'
      }


    ];
    this.amPM = [
      {
        label: 'AM',
        value: 'AM'
      },
      {
        label: 'PM',
        value: 'PM'
      },
    ];
    this.securityArray =[
      {
        label: 'General, public access',
        value: 'WES_PUBLIC'
      },
      {
        label: 'Very restricted, confidential access',
        value: 'WES_CONFIDENTIAL'
      },
    
    ];
    this.yesNoArray = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      },

    ];
    this.OutboundArray = [
      {
        label: 'Outbound',
        value: 'Y'
      },
      {
        label: 'Inbound',
        value: 'N'
      },

    ];
    this.PurposeArray = [
      {
        label: 'Email',
        value: 'WEPT_TASK_EMAIL'
      },
      {
        label: 'Phone Call',
        value: 'WEPT_TASK_PHONE_CALL'
      },

    ];
    this.AvailabilityArray =[
    {
      label: 'Available',
      value: 'WEPA_AV_AVAILABLE'
    },
    {
      label: 'Busy',
      value: 'WEPA_AV_BUSY'
    },
    {
      label: 'Away',
      value: 'WEPA_AV_AWAY'
    },
  ];
   }

  ngOnInit(): void {
    this.getPendingActivityTaskCrmAll();
    this.getSalesOpportunity();
    this.getCases();
    this.getPartyId();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }
  getPendingActivityTaskCrmAll() {
    this.spinner.show();
    this.myContactsService.getPendingActivityTaskCrmAll().subscribe(res => {
      this.pendingActivityTaskCrmAll = res.data[0].getPendingActivityTaskCrm;
      this.spinner.hide();
    })
  }
  getSalesOpportunity(){
    this.spinner.show();
    this.myContactsService.getSalesOpportunity().subscribe(res=>{
      this.SalesOpportunity = res.data[0].getSalesOpportunity;
      this.spinner.hide();
      for (const value of this.SalesOpportunity) {
        this.SalesOpportunityArray.push({
          label: value.opportunityName,
          value: value.salesOpportunityId
        })
      }
    })
  }
  getCases(){
    this.spinner.show();
    this.myContactsService.getCases().subscribe(res=>{
      this.Cases = res.data[0].getCasesById;
      this.spinner.hide();
      for (const value of this.Cases) {
        this.CasesArray.push({
          label: value.custRequestName,
          value: value.custRequestId
        })
      }
    })
  }
  getPartyId() {
    this.spinner.show();
    this.myContactsService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  createPendingActivityTask(){
    this.spinner.show();
    const requestData = {
      "availability":this.taskForm.value.Availability,
      "currentStatusId": "TASK_SCHEDULED"  ,
      "custRequestId": this.taskForm.value.Case,
      "description": this.taskForm.value.Description,
      "duration": this.taskForm.value.Duration,
      "estimatedCompletionCompositeType":"Timestamp",
      "estimatedCompletionDate": this.datePipe.transform(this.taskForm.value.CompletionDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedCompletionDateAmPm": this.taskForm.value.CompletionAmPm,
      "estimatedCompletionDateHour": this.taskForm.value.CompletionDateHour,
      "estimatedCompletionDateMinutes": this.taskForm.value.CompletionDateMinutes,
      "estimatedStartDateAmPm": this.taskForm.value.StartDateAmPm,
      "estimatedStartDateCompositeType": "Timestamp",
      "estimatedStartDateDate":this.datePipe.transform(this.taskForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedStartDateHour": this.taskForm.value.StartDateandHour,
      "estimatedStartDateMinutes": this.taskForm.value.StartDateMinutes,
      "ignoreSchedulingConflicts": this.taskForm.value.IgnoreSchedulingConflicts,
      "lookupCase": "",
      "lookupOpportunity": this.taskForm.value.Opportunity,
      "pendingActivityTaskId": "",
      "relatedPartyId": this.taskForm.value.RelatedParty,
      "scopeEnumId": this.taskForm.value.Security,
      "type": "",
      "workEffortName": this.taskForm.value.Subject,
      "workEffortPurposeTypeId": this.taskForm.value.Purpose,
      "workEffortTypeId": "TASK"
    }

    this.myContactsService.createPendingActivityTask(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("PendingActivityTask created successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.taskForm.reset();
        this.getPendingActivityTaskCrmAll();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  openTasks() {
    this._Router.navigate(["/crm/Tasks"])
  }
  openEvents() {
    this._Router.navigate(["/crm/events"])
  }
  toCalendar(){
    
    this.router.navigate(['crm/sales-calendar']);

  }
  toNotes(){
    this.router.navigate(['/crm/sales-notes']);
  }
  toAccount()
  {
    this.router.navigate(['/crm/accounts']);
  }
  toContact()
  {
    this.router.navigate(['/crm/my-contacts']);

  }
  toOpportunity()
  {
    this.router.navigate(['/crm/opportunities']);

  }
  toActivities()
  {
    this.router.navigate(['/crm/events']);

  }
  toDocuments(){
    
    this.router.navigate(['crm/sales-documents']);

  }
  toForecast(){
    
    this.router.navigate(['/crm/sales-forecast']);

  }
  toEmails(){
    
    this.router.navigate(['/communication-overview/Dashboard']);

  }  

  toQuotes()
  {
    this.router.navigate(['/order/quotations']);

  }
  toSalesOrder()
  {
    this.router.navigate(['/order/create-sales-order']);

  }
  toCases(){
    this.router.navigate(['/crm/Cases']);
  }
  
  toTasks(){
    this.router.navigate(['/crm/Tasks']);
  }
  toLead()
  {
    this.router.navigate(['/crm/leads']);

  }

changeactiveCategoryAction(tab: number)
{
  this.activeCategories = tab;

}

changeactiveCategoryAction1(tab: number)
{
  this.activeCategoies1 = tab;

}


  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
}
