import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-fsm-time-sheet',
  templateUrl: './fsm-time-sheet.component.html',
  styleUrls: ['./fsm-time-sheet.component.css']
})
export class FsmTimeSheetComponent implements OnInit {
  @ViewChild('closebutton2') closebutton2;
  activeCategory = 9;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  timeSheetForm: FormGroup;
  show: boolean;
  fsmTimeCard: any;
  fsmTimeCardById: any;
  fsmTechnicalById: any;
  technicianId: any;
  technicalAll: any;
  technicalAllArray: any[] = [];
  product: any;

  constructor(
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) {
    this.timeSheetForm = this._FormBuilder.group({
      timeCardId: "",
      technicianId: "",
      technicianName: "",
      checkInDateTime: "",
      checkOutDateTime: "",

    });
  }

  ngOnInit(): void {
    this.getFsmTimeCard();
    this.getFsmTechnicalAll();
  }


  backWindow() {
    this._location.back();
  }
  toDashboard() {
    this._Router.navigate(['/hub/Dashboard']);
  }
  toStaffing() {
    this._Router.navigate(['fsm/staffing']);
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  reset() {
    this.show = false;
    this.timeSheetForm.reset();
  }
  onChangeTechId(event) {

    this.technicianId = event;
    if (event) {
      this.getFsmTechnicalById();
    }
  }
  getFsmTechnicalAll() {
    this.spinner.show();
    this.myContactsService.getFsmTechnicalAll().subscribe(res => {
      this.technicalAll = res.data[0].getFsmTechnicalById;
      this.spinner.hide();
      for (const value of this.technicalAll) {
        this.technicalAllArray.push({
          label: value.technicianId,
          value: value.technicianId
        })
      }
    })
  }

  getFsmTechnicalById() {
    this.spinner.show();
    this.myContactsService.getFsmTechnicalById(this.technicianId).subscribe(res => {
      this.fsmTechnicalById = res.data[0].getFsmTechnicalById[0];
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.timeSheetForm;
        formValue.patchValue({
          technicianName: this.fsmTechnicalById.technicianName,
        })
      }, 1000);
    })
  }
  getFsmTimeCardById(product) {
    this.show = true;
    this.product = product;
    this.spinner.show();
    this.myContactsService.getFsmTimeCardById(product.timeCardId).subscribe(res => {
      this.fsmTimeCardById = res.data[0].getFsmTimeCardById[0];
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.timeSheetForm;
        formValue.patchValue({
          technicianId: product.technicianId,
          technicianName: product.technicianName,
          checkInDateTime: product.checkIn,
          checkOutDateTime: product.checkOut,
        })
      }, 2000);
    })
  }

  updateFsmTimeCard() {
    this.spinner.show();
    const requestData =
    {
      "checkIn": "false",
      "checkOut": this.timeSheetForm.value.checkOutDateTime,
      "technicianId": this.timeSheetForm.value.technicianId,
      "technicianName": this.timeSheetForm.value.technicianName,
      "timeCardId": this.fsmTimeCardById.timeCardId,
      "checkInDateTime": this.datePipe.transform(this.product.checkInDateTime, "yyyy-MM-dd hh:mm:ss") , 
      "checkOutDateTime":this.datePipe.transform(new Date(), "yyyy-MM-dd hh:mm:ss") ,
    }
    this.myContactsService.updateFsmTimeCard(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.timeSheetForm.reset();
        this.getFsmTimeCard();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createFsmTimeCard() {
    this.spinner.show();
    const requestData =
    {
      "checkIn": this.timeSheetForm.value.checkInDateTime ,
      "checkOut": "false",
      "technicianId": this.timeSheetForm.value.technicianId,
      "technicianName": this.timeSheetForm.value.technicianName,
      "timeCardId": "",
      "checkInDateTime":this.datePipe.transform(new Date(), "yyyy-MM-dd hh:mm:ss") ,
      "checkOutDateTime": "",
    }
    this.myContactsService.createFsmTimeCard(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.timeSheetForm.reset();
        this.getFsmTimeCard();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getFsmTimeCard() {
    this.spinner.show();
    this.myContactsService.getFsmTimeCard().subscribe(res => {
      this.fsmTimeCard = res.data[0].getFsmTimeCard;
      this.spinner.hide();
    })
  }

  deleteFsmTimeCard(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        const req = {
          timeCardId: product.timeCardId
        }
        this.myContactsService.deleteFsmTimeCard(product).subscribe(res => {
          this.getFsmTimeCard();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  toNewWorkOrder() {
    this._Router.navigate(['fsm/newWorkOrder'])
  }

  toCustomer() {
    this._Router.navigate(['fsm/customer'])
  }

  toReports() {
    this._Router.navigate(['/fsm/reports'])
  }

  toInvoices() {
    this._Router.navigate(['/fsm/invoices'])

  }

  toWorkOrder() {
    this._Router.navigate(['/fsm/main'])
  }

  toPeople() {
    this._Router.navigate(['/fsm/people'])
  }

  toTimeSheet() {
    this._Router.navigate(['/fsm/timesheet'])
  }

  toSetting() {
    this._Router.navigate(['/fsm/fsmSetting'])
  }
  referToSummary(id) {
    this._Router.navigate(['/fsm/timesheet/summary'], { queryParams: { timesheetid: id } })
  }


  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
