import { Component, OnInit,Inject, ViewChild, ElementRef, TemplateRef, HostListener } from '@angular/core';
import { SortEvent } from 'primeng/api';
import{HeaderService} from '../header/header.service';
import { Router } from '@angular/router';
import { AccountsService} from '../crm/accounts/accounts.service';
import {Settings} from "./settings.service";
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import {SettingFormService} from "./setting-service";
import {InterviewTypeService} from "./interviewType-service";
import {LeaveTypeService} from "./leaveType-service";
import {TrainingTypeService} from "./training-type-service";
import {PositionTypeService} from "./positionType-service";
import {PublicHolidayService} from "./public-holiday-service";
import { DatePipe } from '@angular/common';
import {ResponsibilitiesTypeService} from "./responsibilities-type-service";
import { $ } from 'protractor';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
import { FormBuilder, FormGroup } from '@angular/forms';
import { appSvgIcons } from 'src/app/app-svg-icons';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { Location } from '@angular/common';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css'],
  providers:[DatePipe]
})
export class SettingComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  @ViewChild('closebutton9') closebutton9;
  @ViewChild('closebuttona') closebuttona;
  @ViewChild('closebutton') closebutton;
  @ViewChild('reasonId') reasonId;
 // this.closebutton.nativeElement.click();

  products3: any;
  activeCategoryMain=2;
  activeCategory=1;
  total=0;
  rowNumber=0;
  pageNo=1;
  pageSize= 100;
  rows = 50;
  skillTypeList: any;
  public settingData = {
    pageNo:this.pageNo,
    pageSize:this.pageSize
  }
  interviewType: any;
  show: boolean=false;
  id: any;
  jobTypeId: any;
  interviewButton=false;
  leaveType: any;
  leaveTypeId: any;
  leaveIdButton=false;
  leaveTypeIdSubmit: any;
  reasonType: any;
  trainingData: any;
  trainingTypeArray: any=[];
  trainingButton=false;
  updateTrainingId: any;
  positionData: any;
  hashTable: any=[];
  positionUpdate: any;
  positionIdButton=false;
  publicHolidaya: any;
  holidayId: any;
  holidayButton=false;
  responsibilitytype: any;
  responsibilityId: any;
  updateResponsibility=false;
  positionIf=true;
  leaveToggles=true;
  responsibilityArray: any=[];
  updateefromdate: string;
  updateefromdateStatus: string;
  showA: false;
  showP: false;
  terminationTypeList:any[];
  terminationFormService:FormGroup;

  payGradeFormService:FormGroup;
  payGradeTypeList:any[];
  payGradeTable: any=[];
  newPayForm: FormGroup;
  terminationReasonList: any[];
  terminationReason: FormGroup;
  updateType=false;
  updateReason=false;
  payType: boolean = false;
  modalRef?: BsModalRef;
  settingModal = false;
  leaveCreate: boolean;

  // @HostListener('document:click', ['$event'])
  //    handleClick(event: MouseEvent): void {
  //      if(this.settingModal){
  //       this.updateFalse();
  //       this.settingModal = false;
  //      }
         
  //    }
  constructor(readonly accountsService: AccountsService,
    readonly  HeaderService: HeaderService,
    readonly router: Router,
    public publicHolidayService:PublicHolidayService,
    public responsibilitiesTypeService:ResponsibilitiesTypeService,
    readonly toastr:ToastrService,
    public trainingTypeService:TrainingTypeService,
    readonly settings:Settings,
    public settingFormService:SettingFormService,
      private _location: Location,
      public interviewTypeService:InterviewTypeService,
    public leaveTypeService:LeaveTypeService,
    public positionTypeService:PositionTypeService,
    readonly datePipe:DatePipe,
    readonly spinner:NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
    public modalService: BsModalService,

    @Inject(DOCUMENT) readonly document: Document
    ) {
    this.terminationFormService = this._FormBuilder.group({
    terminationTypeId: [''],
    description: [''],
    });

    this.payGradeFormService = this._FormBuilder.group({
      payGradeTypeId: [''],
      payGrade: [''],
      payComment: ['']
    });

    this.newPayForm = this._FormBuilder.group({
        payGradeID: [''],
        PayGradeName: [''],
        Comments: ['']
    });

    this.terminationReason = this._FormBuilder.group({
      terminationReasonId: [''],
      description: ['']
    })


     }
     ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getPublicHolidays();
    this.getPositionType();
    this.getTrainingTypeList();
    this.getResponsibilityType();
    this.getLeaveType();
    this.getJobInterviewType();
    this.getTerminationType();
    this.getTerminationReason();
    this.getPayGrade();
    this.responsibilitiesTypeService.responsibility_type__form=null;
    this.responsibilitiesTypeService.responsibilitiesTypeForm(this.responsibilitiesTypeService.responsibility_type__form);
    this.publicHolidayService.public_holiday_form=null;
    this.publicHolidayService.publicHolidayForm(this.publicHolidayService.public_holiday_form);
    this.positionTypeService.position_type__form=null;
    this.positionTypeService.positionTypeForm(this.positionTypeService.position_type__form);
    this.trainingTypeService.training_type__form=null;
    this.trainingTypeService.trainingTypeForm( this.trainingTypeService.training_type__form);
    this.leaveTypeService.leave_type__form = null;
    this.leaveTypeService.leaveTypeForm(this.leaveTypeService.leave_type__form);
    this.interviewTypeService.interview_type__form = null;
    this.interviewTypeService.interviewTypeForm(this.interviewTypeService.interview_type__form);
    this.settingFormService.setting_form = null;
    this.settingFormService.settingForm(this.settingFormService.setting_form);
    //this.terminationFormService = null;
    //this.settingFormService.settingForm(this.terminationFormService);
   this.getSkillTypeList();
   this.hashTable.push({
     "label":"Y",
     "value":"Y"
   }, {
    "label":"N",
    "value":"N"
   })

   this.payGradeTable.push({
    "label":"Equals",
    "value":"Equals"
  }, {
   "label":"Begins With",
   "value":"Begins With"
  },
  {
    "label":"Contains",
    "value":"Contains"
   },
   {
    "label":"Is Empty",
    "value":"Is Empty"
   },
   {
    "label":"Not Equal",
    "value":"Not Equal"
   })

  }
  // openModal(template: TemplateRef<any>) {
  //   this.modalRef = this.modalService.show(template);
  // }
   
  position(){
      this.positionIf = true;
  }
  responsi(){
    this.positionIf = false;
  }
  leaveToggle(){
   this.leaveToggles=true;
  }
  leaveReasonToggle(){
    this.leaveToggles=false;
    this.leaveCreate = false;
  }
  // terminatioT(){
  //   this.terminationToggle  = 
  // }
  updateHoliday(holidayName,description,fromDate,holidayId){
    this.spinner.show();
      this.holidayId=holidayId;
      const holidayForm = this.publicHolidayService.public_holiday_form;
      holidayForm.patchValue({
        descriptionHoliday:description,
        holidayName:holidayName,
        fromDate:this.datePipe.transform(fromDate,"yyyy-MM-dd")
      })
      this.spinner.hide();
      if(this.holidayId){
        this.holidayButton = true;
        //this.settingModal = true;
      }
      else {
        this.holidayButton = false;
      }
  }
  updateHolidays(){
    this.spinner.show();
    let fromdate=this.publicHolidayService.public_holiday_form.get('fromDate').value;
    this.updateefromdateStatus=this.datePipe.transform(fromdate,"yyyy-MM-dd hh:mm:ss");
    let formValue={
      
        "description": this.publicHolidayService.public_holiday_form.value.descriptionHoliday,
        "fromDate": this.updateefromdateStatus,
        "holidayName": this.publicHolidayService.public_holiday_form.value.holidayName
        
      
    }
    this.settings.updatePublicHoliday(formValue,this.holidayId).subscribe((res:any)=>{
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.closebuttona.nativeElement.click();
        this.spinner.hide();
        this.getPublicHolidays()
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   }) }
   homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  deleteHoliday(holidayNumber){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.settings.deleteHoliday(holidayNumber).subscribe((res:any)=> {
          if(res.success) { 
            this.document.location.reload();
          }
          else if(res.success == false) {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )  }
    }) }
  getResponsibilityType(){
    this.spinner.show();
    this.responsibilitytype=[]
    this.settings.getResponsibility().subscribe(res=>{
      this.spinner.hide();
      this.responsibilitytype = res.data.leaveTypeId;
      for(const value of  this.responsibilitytype){
        this.responsibilityArray.push({
          label:value.description,
          value:value.responsibilityTypeId
        })
      }
    })
   
  }
  onResponsi(){
    this.spinner.show();
    const formData = {...this.responsibilitiesTypeService.responsibility_type__form.value};
    this.settings.createResponsibility(formData).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.closebutton3.nativeElement.click();
        this.spinner.hide();
      this.getResponsibilityType();
      }
    
    else if(res.success == false) {
      this.toastr.error("Error");
      this.spinner.hide();
    }
  },(err) => {
    this.spinner.hide();
    for(const data of err.error.errors){
      this.toastr.error(data.fieldName + ' ' + data.fieldError)
    }
 })

  }
  
  onUpdateRes(responsibilityTypeId,description){
    this.spinner.show();
     this.responsibilityId = responsibilityTypeId;
    const form = this.responsibilitiesTypeService.responsibility_type__form;
     form.patchValue({
      responsibilityTypeId:responsibilityTypeId,
      description:description
     });
     this.spinner.hide();
     if(responsibilityTypeId){
       this.updateResponsibility = true;
       //this.settingModal = true;
     }
     else {
       this.updateResponsibility=false;
     }
  }
  onDelResponse(id){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.settings.deleteResponsibilitytype(id).subscribe((res:any)=> {
          if(res.success) {
            this.getResponsibilityType(); 
          }
          else if(res.success == false) {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )}
    })}
  updateRespon(){
    this.spinner.show();
    const form = {...this.responsibilitiesTypeService.responsibility_type__form.value};
      this.settings.updateResponsibility(form,this.responsibilityId).subscribe((res:any)=> {
        if(res.success) {
          this.toastr.success(res.data.successMessage);
          this.closebutton3.nativeElement.click();
          this.spinner.hide();
          this.getResponsibilityType();        }
        else if(res.success == false) {
          this.toastr.error("Error");
          this.spinner.hide();
        }
      })
     
  }
  UpdateType(product){
    this.spinner.show();
     let terminationTypeId = product.terminationTypeId;
    const form = this.terminationFormService;
     form.patchValue({
      terminationTypeId:terminationTypeId,
      description:product.description
     });
     this.spinner.hide();
     if(terminationTypeId){
       this.updateType = true;
       //this.settingModal = true;
     }
     else {
       this.updateType=false;
     }
  }
  updateTerminationType(){
    this.spinner.show();
    const form = {...this.terminationFormService.value};
    let terminationType = {...this.terminationFormService.value};
    let req = {
      "terminationTypeId": this.terminationFormService.value.terminationTypeId,
      "description": this.terminationFormService.value.description
    }
    let terminatioTypeId = this.terminationFormService.value.terminatioTypeId;
      this.settings.updateTerminationType(req).subscribe((res:any)=> {
        if(res.success) {
          this.toastr.success(res.data.successMessage);
          this.closebutton6.nativeElement.click();
          this.spinner.hide();
          this.getTerminationType();        }
        else if(res.success == false) {
          this.toastr.error("Error");
          this.spinner.hide();
        }
      })
  }
  UpdateReason(product){
    this.spinner.show();
     let terminationReasonId = product.terminationReasonId;
    const form = this.terminationReason;
     form.patchValue({
      terminationReasonId:terminationReasonId,
      description:product.description
     });
     this.spinner.hide();
     if(terminationReasonId){
       this.updateReason = true;
       //this.settingModal = true;
     }
     else {
       this.updateReason=false;
     }
  }
  onUpdateReason(){
    this.spinner.show();
    const form = {...this.terminationReason.value};
    let terminationType = {...this.terminationReason.value};
    let req = {
      "terminationReasonId": this.terminationReason.value.terminationReasonId,
      "description": this.terminationReason.value.description
    }
      this.settings.updateTerminationReason(req).subscribe((res:any)=> {
        if(res.success) {
          this.toastr.success(res.data.successMessage);
          this.closebutton4.nativeElement.click();
          this.spinner.hide();
          this.getTerminationReason();        }
        else if(res.success == false) {
          this.toastr.error("Error");
          this.spinner.hide();
        }
      })
  }
  cancelTerminationReason(){
    this.updateReason = false;
    this.terminationReason.reset();
    this.closebutton.nativeElement.click();
    
  }
  submitPublicHolidays(){
    this.spinner.show();
    let fromdate=this.publicHolidayService.public_holiday_form.get('fromDate').value;
    this.updateefromdate=this.datePipe.transform(fromdate,"yyyy-MM-dd hh:mm:ss");
    let formValue={
      
        "description": this.publicHolidayService.public_holiday_form.value.descriptionHoliday,
        "fromDate": this.updateefromdate,
        "holidayName": this.publicHolidayService.public_holiday_form.value.holidayName
           
    }
    this.settings.createPublicHolidays(formValue).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.successResponse);
        this.closebuttona.nativeElement.click();
        this.spinner.hide();
       this.getPublicHolidays()
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
  
  }
  positionTypeUpdate(emplPositionTypeId,description){
    this.spinner.show();
    this.positionUpdate = emplPositionTypeId;
    const positionTypeIds =  this.positionTypeService.position_type__form;
    positionTypeIds.patchValue({
            description:description,
            emplPositionTypeId:emplPositionTypeId
          })
          this.spinner.hide();
    if(emplPositionTypeId){
      this.positionIdButton=true;
      //this.settingModal = true;
    }
    else {
      this.positionIdButton=false;
    }
  }
  positionTypeUpdates(){
    this.spinner.show();
    const formData = {...this.positionTypeService.position_type__form.value};
    this.settings.updatePositionType(formData,this.positionUpdate).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.closebutton5.nativeElement.click();
        this.spinner.hide();
        this.getPositionType();
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
  

  }
   getPublicHolidays(){
    this.spinner.show();
     this.settings.getPublicHolidays().subscribe((res:any)=> {
         this.publicHolidaya = res.data;
         this.spinner.hide();
     })
    
   }
  createPositionType(){
    this.spinner.show();
    const positionType = {...this.positionTypeService.position_type__form.value};
    this.settings.createPositionType(positionType).subscribe((res:any)=> {  
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.closebutton5.nativeElement.click();
        this.spinner.hide();
        this.getPositionType();
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
  
  }
  positionTypeDel(id){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.settings.deletePosition(id).subscribe((res:any)=> {
          if(res.success) {
           this.getPositionType()
          }
          else  {
            this.toastr.error("Error");
          }     })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    }) }

  onSubmit() {
    this.spinner.show();
     const formData = {...this.settingFormService.setting_form.value}
     this.settings.createSkillType(formData).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.closebutton2.nativeElement.click();
        this.spinner.hide();
        this.getSkillTypeList();
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
  
  }
  leaveTypeSubmit() {
    this.spinner.show();
    const leaveTypeForm = {...this.leaveTypeService.leave_type__form.value};
    this.settings.leaveTypeSubmit(leaveTypeForm).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.closebutton8.nativeElement.click();
        this.spinner.hide();
        this.document.location.reload();
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
  
  }
 
  updateLeaveTypeId(leaveTypeId,description) {
    this.spinner.show();
    this.leaveTypeIdSubmit = leaveTypeId;
    const leaveTypeIds =  this.leaveTypeService.leave_type__form; 
    leaveTypeIds.patchValue({
            description:description,
            leaveTypeId:leaveTypeId
          })
          this.spinner.hide();
    if(leaveTypeId){
      this.leaveIdButton=true;
      //this.settingModal = true;
    }
    else {
      this.leaveIdButton=false;
    }
  }
  onDeleteLeave(id){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.settings.deleteTrainingType(id).subscribe((res:any)=>{
          if(res.success) {  
            this.getTrainingTypeList();
          }
          else  {
            this.toastr.error("Error");
          }    
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
  updateLeaveType(){
    this.spinner.show();
    const formData = {...this.leaveTypeService.leave_type__form.value};
    this.settings.leaveTypeUpdate(formData,this.leaveTypeIdSubmit).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.closebutton8.nativeElement.click();
        this.spinner.hide();
        this.document.location.reload();
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
  

  }
  deleteLeaveType(id){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.settings.deleteLeaveType(id).subscribe((res:any)=> {
          if(res.success) { 
            this.document.location.reload();
          }
          else  {
            this.toastr.error("Error");
          }    
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )}
    })}


  interviewTypeForm() {
    this.spinner.show();
    const interviewTypeForm = {...this.interviewTypeService.interview_type__form.value};
    this.settings.createInterviewType(interviewTypeForm).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.closebutton7.nativeElement.click();
        this.spinner.hide();
        this.getJobInterviewType();
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
 
  }
  submitTrainingType(){
    this.spinner.show();
    const trainingTypeForm = {...this.trainingTypeService.training_type__form.value};
    this.settings.createTrainingType(trainingTypeForm).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.closebutton9.nativeElement.click();
        this.spinner.hide();
        this.getTrainingTypeList();
        // this.document.location.reload();
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }    
    },(err) => {
      this.spinner.hide();
      for(const value of err.error.errors){
      this.toastr.error(value.fieldName + " " + value.fieldError);
      }
   })
  
  }
  updateSkills(skillTypeId,description) {
    this.spinner.show();
    this.id = skillTypeId;
    const skillForm = this.settingFormService.setting_form;
          skillForm.patchValue({
            description:description,
            skillTypeId:skillTypeId
          })
          this.spinner.hide();
    if(skillTypeId){
      this.show=true;
      //this.settingModal = true;
    }
    else {
      this.show=false;
    }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
}
  updateInterview(jobInterviewTypeId,description) {
this.spinner.show();
    this.jobTypeId = jobInterviewTypeId;
    const jobInterviewForm = this.interviewTypeService.interview_type__form;
    jobInterviewForm.patchValue({
      jobInterviewTypeId:jobInterviewTypeId,
      description:description
    })
    this.spinner.hide();
    if(jobInterviewTypeId) {
      this.interviewButton = true;
      //this.settingModal = true;
    }
    else {
      this.interviewButton = false;
      
    }
  }
  updateJobId() {
    this.spinner.show();
    const interviewTypeForm = {...this.interviewTypeService.interview_type__form.value};
    this.settings.updateInterviewType(interviewTypeForm,this.jobTypeId).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.closebutton7.nativeElement.click();
        this.spinner.hide();
         this.getJobInterviewType();
        }
        else if(res.success == false) {
          this.toastr.error("Error");
          this.spinner.hide();
        }
      },(err) => {
        this.spinner.hide();
        for(const data of err.error.errors){
          this.toastr.error(data.fieldName + ' ' + data.fieldError)
        }
     })
    
  
  }
  onUpdate() {
    this.spinner.show();
    const interviewTypeForm = {...this.settingFormService.setting_form.value};
    this.settings.updateSkillType(interviewTypeForm,this.id).subscribe((res:any)=>{
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.closebutton2.nativeElement.click();
        this.spinner.hide();
        this.getSkillTypeList();
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   }) }
  getLeaveType() {
    this.spinner.show();
    this.settings.getLeaveType().subscribe(res=> {
      this.leaveType = res.data.leaveTypeId;
      this.spinner.hide();
    })
   
  }
  onDeleteId(id){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.settings.deleteInterviewId(id).subscribe((res:any)=> {
          if(res.success) {  
           this.getJobInterviewType()
          }
          else  {
            this.toastr.error("Error");
          }    
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
 
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  cancelSkills(){
    this.show=false;
    this.settingFormService.setting_form.reset();
    this.closebutton.nativeElement.click();
  }
  cancelResponsibility(){
    this.updateResponsibility=false;
    this.responsibilitiesTypeService.responsibility_type__form.reset();
    this.closebutton.nativeElement.click();
  }
  cancelType(){
    this.updateType = false;
    this.terminationFormService.reset();
    this.closebutton.nativeElement.click();
  }
  cancelPosition(){
    this.positionIdButton=false;
    this.positionTypeService.position_type__form.reset();
    this.closebutton.nativeElement.click();
  }
  cancelInterview(){
    this.interviewButton=false;
    this.interviewTypeService.interview_type__form.reset();
    this.closebutton.nativeElement.click();
  }
  employeeCancel(){
    this.leaveIdButton=false;
    this.leaveTypeService.leave_type__form.reset();
    this.closebutton.nativeElement.click();
  }
  cancelHoliday(){
    this.holidayButton=false;
    this.publicHolidayService.public_holiday_form.reset();
    this.closebutton.nativeElement.click();
  }
  updateTraining(trainingClassTypeId,description,pid){
    this.spinner.show();
    this.updateTrainingId=trainingClassTypeId
    const trainingType =  this.trainingTypeService.training_type__form; 
    trainingType.patchValue({
            description:description,
            trainingClassTypeId:trainingClassTypeId,
            parentTypeId:pid

          })
          this.spinner.hide();
    if(trainingClassTypeId){
      this.trainingButton=true;
    }
    else {
      this.trainingButton=false
    }
  }

  cancelTrainingType(){
    this.trainingTypeService.training_type__form.reset();
    this.trainingButton = false;
    this.closebutton.nativeElement.click();
  }
  updateTrainingType(){
    this.spinner.show();
    const updateTraining = {...this.trainingTypeService.training_type__form.value};
     this.settings.updateTrainingType(updateTraining,this.updateTrainingId).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.closebutton9.nativeElement.click();
        this.spinner.hide();
        this.getTrainingTypeList();
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
  
  }
  getTrainingTypeList(){
    this.spinner.show();
    this.settings.getTrainingType(this.settingData).subscribe(res=> {
      this.trainingData = res.data;
      this.spinner.hide();
      for(const value of this.trainingData){
        this.trainingTypeArray.push({
          label:value.description,
          value:value.trainingClassTypeId
        })
      }
    })
   
  }
  getSkillTypeList() {
    this.spinner.show();
    this.skillTypeList=[]
  this.settings.getSkills().subscribe((res:any)=> {
   this.skillTypeList = res.data;
   this.spinner.hide();
  })
 
  }
  getPositionType(){
    this.spinner.show();
    this.positionData=[]
    this.settings.getPositionType().subscribe((res:any)=>{
      this.positionData = res.data.leaveTypeId;
      this.spinner.hide();
    })
   
  }
  onDelete(product) {
    let skillTypeId = product.skillTypeId;
    console.log("product",product)
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.settings.deleteSkillTypePost(skillTypeId).subscribe((res:any)=> {
          if(res.success) { 
            this.getSkillTypeList();
          }
          else if(res.success == false){
            this.toastr.success(res.message);
          }
          else  {
            this.toastr.error(res.message);
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )}
    })  }
  getJobInterviewType() {
    this.spinner.show();
    this.interviewType=[]
    this.settings.getJobInterviewList(this.settingData).subscribe((res:any)=> {
      this.interviewType = res.data;
      this.spinner.hide();
    })
   
  }
  getTerminationType(){
    this.spinner.show();
    this.terminationTypeList=[]
    this.settings.getTerminationTypeList().subscribe((res:any)=>{
      this.terminationTypeList = res.data;
      console.log("getTerminationType",res)
      this.spinner.hide();
    })
   
  }
  createTerminationType(){
    let terminationType = {...this.terminationFormService.value};
    console.warn("terminationType",terminationType);
    this.spinner.show();
    this.settings.createTerminationType(terminationType).subscribe((res:any)=> {  
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.closebutton6.nativeElement.click();
        this.getTerminationType()
        this.spinner.hide();
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      console.warn("error",err);
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
    
  }

  deleteTerminationType(product){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "terminationTypeId": product.terminationTypeId,
          "description": product.description
        }
        this.settings.deleteTerminationType(req).subscribe((res:any)=> {
          if(res.success) {
           this.getTerminationType()
          }
          else  {
            this.toastr.error("Error");
          }     })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    }) 
  }

  getTerminationReason(){
    this.spinner.show();
    this.terminationReasonList=[]
    this.settings.getTerminationReasonList().subscribe((res:any)=>{
      this.terminationReasonList = res.data;
      this.spinner.hide();
    })
   
  }
  createTerminationReason(){
    let terminationReason = {...this.terminationReason.value};
    this.spinner.show();
    this.settings.createTerminationReason(terminationReason).subscribe((res:any)=> {  
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.closebutton4.nativeElement.click();
        this.getTerminationReason();
        this.spinner.hide();
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      console.warn("error",err);
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
    
  }

  deleteTerminationReason(product){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "terminationReasonId": product.terminationReasonId,
          "description": product.description
        }
        this.settings.deleteTerminationReason(req).subscribe((res:any)=> {
          if(res.success) {
           this.getTerminationReason()
          }
          else  {
            this.toastr.error("Error");
          }     })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    }) 
  }

  getPayGrade(){
    this.spinner.show();
    this.payGradeTypeList=[]
    this.settings.getPayGrade().subscribe((res:any)=>{
      if(res.data){
        this.payGradeTypeList = res.data;

        res.data.forEach((ele,ind) => {
        this.payGradeTypeList[ind] = ele.FindPayGrades
      });
      }
      
      this.spinner.hide();
    })
   
  }
  findPayGrade(){
    let payGradeId = this.payGradeFormService.value.payGradeTypeId;
    let payGradeName = this.payGradeFormService.value.payGrade;
    let comments = this.payGradeFormService.value.payComment;
    console.log(payGradeId,payGradeName,comments)
    this.settings.getFindPayGrade(payGradeId,payGradeName,comments).subscribe((res:any)=> {
      this.payGradeTypeList = res.data;
      res.data.forEach((ele,ind) => {
        this.payGradeTypeList[ind] = ele.data
      });
      this.spinner.hide();
    })
  }

  onDeletePayGrade(product){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "comments": product.comments,
          "payGradeId": product.payGradeId,
          "payGradeName": product.payGradeName,
        }
        this.settings.deletePayGrade(req).subscribe((res:any)=> {
          if(res.success) {
           this.getPayGrade()
          }
          else  {
            this.toastr.error("Error");
          }     })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    }) 
  }

  onCreatePayGrade(){
    let payGradeData = { ...this.payGradeFormService.value };
    let req = {
      "comments": this.payGradeFormService.value.payComment,
      "payGradeId": this.payGradeFormService.value.payGradeTypeId,
      "payGradeName": this.payGradeFormService.value.payGrade
    }
    this.spinner.show();
    this.settings.createPayGrade(req).subscribe((res:any)=> {  
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.closebutton1.nativeElement.click();
        this.getPayGrade()
        this.spinner.hide();
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      console.warn("error",err);
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
    
  }

  updatePayGradeForm(product){
    this.spinner.show();
     let payGradeId = product.payGradeId;
    const form = this.payGradeFormService;
     form.patchValue({
      payGradeTypeId: product.payGradeId,
      payGrade: product.payGradeName,
      payComment: product.comments
     });
     this.spinner.hide();
     if(payGradeId){
       this.payType = true;
       //this.settingModal = true;
     }
     else {
       this.payType=false;
     } 

  }
  updatePayGrade(){
    this.spinner.show();
    const form = {...this.payGradeFormService.value};
  
    let req = {
      "comments": this.payGradeFormService.value.payComment,
      "payGradeId": this.payGradeFormService.value.payGradeTypeId,
      "payGradeName": this.payGradeFormService.value.payGrade,
    }
    
      this.settings.updatePayGrade(req).subscribe((res:any)=> {
        if(res.success) {
          this.toastr.success(res.data.successMessage);
          this.closebutton1.nativeElement.click();
          this.spinner.hide();
          this.getPayGrade();        }
        else if(res.success == false) {
          this.toastr.error("Error");
          this.spinner.hide();
        }
      })
  }

  cancelPayGrade(){
    this.payType = false
    this.payGradeFormService.reset();
    this.closebutton.nativeElement.click();
    
  }
  updateFalse(){
    
    this.payType = false;
    this.updateResponsibility = false;
    this.show = false;
    this.updateReason = false;
    this.positionIdButton = false;
    this.updateType = false;
    this.interviewButton = false;
    this.leaveIdButton = false;
    this.trainingButton = false;
    
  }
  cancelPaygradeModal(){
    this.newPayForm.reset();
  }

  changeactiveCategoryFunction(tab: number){
    this.spinner.show();
    this.activeCategory =tab;
    this.spinner.hide();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
      detailPage(skillType,description) {
        this.router.navigate(["/hr/detail-skill-type"], { queryParams: { SkillType: skillType, des: description} })
      }
      detailResPage(skillType,description) {
        this.router.navigate(["/hr/detail-responsibility-type"], { queryParams: { ResType: skillType, des: description} })
      }
      detailPositionTypePage(eposType,description) {
        this.router.navigate(["/hr/detail-position-type"], { queryParams: { PosType: eposType, des: description} })
      }
      detailHolPage(holidayName,description,fromDate){
        this.router.navigate(["/hr/detail-holiday-summary"], { queryParams: { holiday: holidayName, des: description,date:fromDate} })
      }
      detailJobTypePage(ejobType,description) {
        this.router.navigate(["/hr/detail-job-summary"], { queryParams: { jobType:ejobType, des: description} })
      }
      detailLeaveTypePage(eleaveType,description) {
        this.router.navigate(["/hr/detail-leave-summary"], { queryParams: { leaveType:eleaveType, des: description} })
      }
      detailTrainingClassPage(etId,description,epId) {
        this.router.navigate(["/hr/detail-training-class-type"], { queryParams: { tId: etId, des: description,pId:epId} })
      }
      detailpayGrade(product){
        this.router.navigate(["/hr/detail-skill-type"], { queryParams: { payGradeId: product.payGradeId, payGradeName: product.payGradeName, comments:product.comments} })
      }
}
