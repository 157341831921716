import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-work-zones',
  templateUrl: './work-zones.component.html',
  styleUrls: ['./work-zones.component.css']
})
export class WorkZonesComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  activeCategory = 9;
  activeCategories=2;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  addNewWorkZoneForm:FormGroup;
  editNewWorkZoneForm:FormGroup;
  workOrderNumArray: { label: string; value: string; }[];
  fsmWorkZoneList: any;
  timeZoneId: any;
  timeZoneIdArray: any[]=[];
  fsmWorkZoneById: any;
  constructor(
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) 
    {
      this.addNewWorkZoneForm = this._FormBuilder.group({
        workZone:"",
        dispatchCenter:"",
        primaryServiceTeam:"",
        timeZone:"",
        workOrderNumbering:"",
        serviceHours:"",
        backupServiceTeam:"",
       
      });
      this.editNewWorkZoneForm = this._FormBuilder.group({
        workZone:"",
        dispatchCenter:"",
        primaryServiceTeam:"",
        timeZone:"",
        workOrderNumbering:"",
        serviceHours:"",
        backupServiceTeam:"",
       
      });
     this.workOrderNumArray =[
       {
         label : 'Standard Numbering' ,
         value : 'Standard Numbering'
       },
       {
        label : 'Custom Numbering' ,
        value : 'Custom Numbering'
      }
     ]
    }
   
    ngOnInit(): void {
      this.getFsmWorkZone();
      this.getTimeZone();
      
    }
    getFsmWorkZone() {
      this.spinner.show();
      this.myContactsService.getFsmWorkZone().subscribe(res => {
        this.fsmWorkZoneList = res.data[0].getFsmWorkZone;
        this.spinner.hide();
      })
    }
    getFsmWorkZoneById(product) {
      this.spinner.show();
      this.myContactsService.getFsmWorkZoneById(product.workZoneId).subscribe(res => {
        this.fsmWorkZoneById = res.data[0].getFsmWorkZoneById[0];
        this.spinner.hide();
        setTimeout(() => {
          const formValue = this.editNewWorkZoneForm;
          formValue.patchValue({
            workZone: this.fsmWorkZoneById.workZone  ,
            dispatchCenter: this.fsmWorkZoneById.dispatchCentre  ,
            primaryServiceTeam: this.fsmWorkZoneById.primaryServiceTeam  ,
            timeZone: this.fsmWorkZoneById.time  ,
            workOrderNumbering: this.fsmWorkZoneById.workOrderNumbering  ,
            serviceHours: this.fsmWorkZoneById.serviceHours  ,
            backupServiceTeam: this.fsmWorkZoneById.backupServiceTeam  ,
          })
    
        }, 2000);
      })
    }
    getTimeZone() {
      this.spinner.show();
      this.myContactsService.getTimeZone().subscribe(res => {
        this.timeZoneId = res.data;
        this.spinner.hide();
        for (const value of this.timeZoneId) {
          this.timeZoneIdArray.push({
            label: value.data,
            value: value.data
          })
        }
      })
     
    }
    createFsmWorkZone() {
      this.spinner.show();
      const requestData = {
        "backupServiceTeam": this.addNewWorkZoneForm.value.backupServiceTeam,
        "dispatchCentre":  this.addNewWorkZoneForm.value.dispatchCenter,
        "primaryServiceTeam":  this.addNewWorkZoneForm.value.primaryServiceTeam,
        "serviceHours": this.addNewWorkZoneForm.value.serviceHours ,
        "time": this.addNewWorkZoneForm.value.timeZone,
        "workOrderNumbering": this.addNewWorkZoneForm.value.workOrderNumbering ,
        "workZone": this.addNewWorkZoneForm.value.workZone ,
        "workZoneId": ""
      }
      this.myContactsService.createFsmWorkZone(requestData).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success("Created Successfully");
          this.spinner.hide();
          this.closebutton1.nativeElement.click();
          this.addNewWorkZoneForm.reset();
          this.getFsmWorkZone();
        }
      }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        }
        else {
          this.toastr.error(err.error.message);
        }
      });
    }
    updateFsmWorkZone() {
      this.spinner.show();
      const requestData = {
        "backupServiceTeam": this.editNewWorkZoneForm.value.backupServiceTeam,
        "dispatchCentre":  this.editNewWorkZoneForm.value.dispatchCenter,
        "primaryServiceTeam":  this.editNewWorkZoneForm.value.primaryServiceTeam,
        "serviceHours": this.editNewWorkZoneForm.value.serviceHours ,
        "time": this.editNewWorkZoneForm.value.timeZone,
        "workOrderNumbering": this.editNewWorkZoneForm.value.workOrderNumbering ,
        "workZone": this.editNewWorkZoneForm.value.workZone ,
        "workZoneId": ""
      }
      this.myContactsService.updateFsmWorkZone(requestData).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success("Updated Successfully");
          this.spinner.hide();
          this.closebutton2.nativeElement.click();
          this.editNewWorkZoneForm.reset();
          this.getFsmWorkZone();
        }
      }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        }
        else {
          this.toastr.error(err.error.message);
        }
      });
    }
    deleteFsmWorkZone(product) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          const req = {
            "workZoneId": product.workZoneId,
          }
          this.myContactsService.deleteFsmWorkZone(req).subscribe(res => {
            //this.getFsmWorkZoneService();
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
  
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
  
    customSort(event: SortEvent) {
      event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;
  
        if (value1 == null && value2 != null) {
          result = -1;
        }
        else if (value1 != null && value2 == null) {
          result = 1;
        }
        else if (value1 == null && value2 == null) {
          result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
          result = value1.localeCompare(value2);
        }
        else {
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
      });
    }
  
    
  
    ngOnDestroy(): void {
      this.spinner.hide();
    }
  
  
  

}
