<div class="container-fluid main-container-wrapper" style="padding: 0;">

    <div class=" bg-white color-grey">
        <div class="port-header-contact create-lead font-medium mb-0">
           <span class="color-black pl-1 titlePanels">Facility
            </span>

            <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
        </div>
        <div class="w3-card-4 classCard" style="border-left-width: 0;">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left: 9px; width: calc(103% - 5px); flex-wrap: wrap; display: flex; margin-bottom: 8px !important;">
                    <li [ngClass]="activeCat==0?'active':''" (click)="dashboardOpen()">
                        <a>Warehouse Dashboard</a>
                    </li>
                    <li [ngClass]="activeCat==1?'active':''" (click)="mainOpen()">
                        <a>Facility</a>
                    </li>
                    <li [ngClass]="activeCat==2?'active':''" (click)="facilityGroupOpen()">
                        <a>Facility Group</a>
                    </li>
                    <li [ngClass]="activeCat==3?'active':''" (click)="inventoryItemOpen()">
                        <a>Inventory Item</a>
                    </li>
                    <li [ngClass]="activeCat==4?'active':''" (click)="ShippingOpen()">
                        <a>Shipping</a>
                    </li>
                </ul>
            </div>
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <div class="w3-card-4 classCard classCardType w-100">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding: 4px 7px;">
                            <button type="button" class="btn btn-outline-secondary active" data-toggle="modal" data-target="#exampleModalCenter" style="margin-top: 4px;">Create New Facility</button>
                        </ul>
                    </div>

                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">

                            <div class="panel-group">
                                <div *ngIf="activeCategory==1">
                                    <div class="panel-collapse">
                                        <div class=" bg-white color-grey">
                                            <div class="create-new-leade mt-2">
                                                <div class="container-fluid">
                                                    <div class="row">
                                                        <form class="w-100">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">

                                                                        <div class="col-lg-2 form-group classInput">
                                                                            <label for="exampleInputEmail1">Facility ID
                                                                                        </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" [options]="facilities" optionlabel="label" [(ngModel)]="facilityId" [ngModelOptions]="{standalone:true}" placeholder="Select Facility">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-4">
                                                                            <button type="button" (click)="getData()" data-toggle="modal" data-target="#exampleModalCenter" class="btn btn-outline-secondary active">Select Facility ID to edit</button>&nbsp;

                                                                            <button class="btn btn-outline-secondary active" type="button" routerLink="/facilities/warehouse/detailed-summary-facilities" [queryParams]="{facilityId:facilityId,tabId:tabid}">Select Facility ID to view</button>
                                                                        </div>
                                                                        <div class="col-lg-2">

                                                                        </div>
                                                                    </div>
                                                                </div>



                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> {{editMode?'Update':'Create'}} Facilities</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                            {{editMode?'Update':'Create'}}</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="facilityForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputG">
                                                        <label for="exampleInputEmail1">Facility Type ID <span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <ng-select [items]="facilityType" class="ng-select-width" bindLabel="description" bindValue="facilityTypeId" placeholder="Facility Type ID" formControlName="facilityTypeId">
                                                        </ng-select>
                                                        <small class="validate-field" *ngIf="!!facilityForm.controls.facilityTypeId.invalid && !!facilityForm.controls.facilityTypeId.touched">
                                                Facility Type ID is required!</small>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA" *ngIf="editMode">
                                                        <label for="facilityId">Facility Id:</label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="editMode">
                                                        <span style="color: black;">{{facilityId}}</span>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputG">
                                                        <label for="exampleInputEmail1">Parent Facility ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <ng-select [items]="facilityIds" bindLabel="facilityName" placeholder="Parent Facility ID" bindValue="facilityId" formControlName="parentFacilityId"></ng-select>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Owner <span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <ng-select [items]="partyIds" bindLabel="groupName" bindValue="partyId" placeholder="Owner" formControlName="ownerPartyId"></ng-select>
                                                        <small class="validate-field" *ngIf="!!facilityForm.controls.ownerPartyId.invalid && !!facilityForm.controls.ownerPartyId.touched">
                                                Owner is required!</small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputG">
                                                        <label for="exampleInputEmail1">Default Weight Unit</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <ng-select [items]="weightData" class="ng-select-width" placeholder="Default Weight Unit" formControlName="defaultWeightUomId" bindLabel="description" bindValue="uomId">
                                                        </ng-select>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Default Inventory Item Type</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <ng-select [items]="inventoryData" class="ng-select-width" placeholder="Default Inventory Item Type" formControlName="defaultInventoryItemTypeId" bindLabel="description" bindValue="inventoryItemTypeId">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputG">
                                                        <label for="exampleInputEmail1">Name <span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Name" formControlName="facilityName" aria-describedby="emailHelp">
                                                        <small class="validate-field" *ngIf="!!facilityForm.controls.facilityName.invalid && !!facilityForm.controls.facilityName.touched">
                                                Name is required!</small>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Area</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="facilitySize" aria-describedby="emailHelp" placeholder="Area">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputG">
                                                        <label for="exampleInputEmail1">Area Unit</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <ng-select [items]="areaData" class="ng-select-width" formControlName="facilitySizeUomId" bindLabel="description" bindValue="uomId" placeholder="Area Unit">
                                                        </ng-select>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Product Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="description" aria-describedby="emailHelp" placeholder="Product Description">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputG">
                                                        <label for="exampleInputEmail1">Default Days To Ship</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="defaultDaysToShip" aria-describedby="emailHelp" placeholder="Default Days To Ship">
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div class="flexDisplay" style="margin-left: -16%;">
                                            <div class="col-md-11 mr12 main-submit-button"><button _ngcontent-cta-c128="" type="submit" class="btn btn-primary submit-btn update-button" (click)="submit()">{{editMode?'Update':'Create'}}</button></div>
                                            <div class="col-md-1 main-submit-button">
                                            </div>
                                        </div>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>