import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dispatch-board-settings',
  templateUrl: './dispatch-board-settings.component.html',
  styleUrls: ['./dispatch-board-settings.component.css']
})
export class DispatchBoardSettingsComponent implements OnInit {

  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;

  activeCategory = 9;
  activeCategories = 2;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;

  addTeamMember: any;
  editTeam: any;
  TeamByPartyId: any;
  party: any;
  partyId: any;
  Role: any;
  RoleArray: any[] = [];
  TeamMemberByPartyId: any;
  updateTeamMem: any;
  Deactivated: boolean;
  personId: any;
  partyIdArray: any[] = [];
  teamMember: any;
  teamMemberArray: any[] = [];
  dispatchBoardSettings: any;
  yesNoArray: { label: string; value: string; }[];
  editDispatchBoardForm: FormGroup;
  dispatchBoardSettingsById: any;
  dispatchBoardSettingsByYes: any;
  listViewFieldForm: FormGroup;

  constructor(
    readonly activatedRoute: ActivatedRoute,

    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) {
    this.listViewFieldForm = this._FormBuilder.group({
      field: [''],
      width: [''],
      displayAs: [''],
      show: [''],
    });

    this.editDispatchBoardForm = this._FormBuilder.group({
      field: "",
      width: "",
      displayAs: "",
      show: "",
    });
    {
      this.editDispatchBoardForm = this._FormBuilder.group({
        yesNo: [''],
      });
    }
    this.yesNoArray = [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },

    ];
  }

  ngOnInit(): void {
    this.getDispatchBoardSettings();
  }
  getDispatchBoardSettingsById(product) {
    this.spinner.show();
    this.myContactsService.getDispatchBoardSettingsById(product.dispatchBoardId).subscribe(res => {
      this.dispatchBoardSettingsById = res.data[0].getDispatchBoardSettings[0];
      this.spinner.hide();
    })
  }
  updateDispatchBoardSettings() {
    this.spinner.show();

    const requestData = {
      "show": this.editDispatchBoardForm.value.yesNo,
      "dispatchBoardId": this.dispatchBoardSettingsById.dispatchBoardId
    }

    this.myContactsService.updateDispatchBoardSettings(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.editDispatchBoardForm.reset();
        this.getDispatchBoardSettings();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getDispatchBoardSettings() {
    this.spinner.show();
    this.myContactsService.getDispatchBoardSettings().subscribe(res => {
      this.dispatchBoardSettings = res.data[0].getDispatchBoardSettings;
      this.spinner.hide();
    })
  }
  createDispatchBoardSettings() {
    this.spinner.show();

    const requestData = {
      "dispatchBoardId": "",
      "displayAs": this.listViewFieldForm.value.displayAs,
      "field": this.listViewFieldForm.value.field,
      "rank": "",
      "show": this.listViewFieldForm.value.show,
      "width": this.listViewFieldForm.value.width,
    }

    this.myContactsService.createDispatchBoardSettings(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.listViewFieldForm.reset();
        this.getDispatchBoardSettings();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getDispatchBoardSettingsByYes() {
    this.spinner.show();
    this.myContactsService.getDispatchBoardSettingsByYes().subscribe(res => {
      this.dispatchBoardSettingsByYes = res.data[0].getDispatchBoardSettings;
      this.spinner.hide();
    })
  }
  deleteDispatchBoardSettings(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "dispatchBoardId": product.dispatchBoardId
        }
        this.myContactsService.deleteDispatchBoardSettings(req).subscribe(res => {
          this.getDispatchBoardSettings();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }



  ngOnDestroy(): void {
    this.spinner.hide();
  }


}
