import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'
import { Location } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductionService } from '../production.service';
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  createReport:FormGroup;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  
  ProdWeek: any;
  mrpRep: any;
  constructor(readonly spinner: NgxSpinnerService,
    readonly productionService:ProductionService, readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder, private _location: Location,
    readonly router: Router) {
      this.createReport = this._FormBuilder.group({
        MrpName: [''],
      
      });
  
  }
 
  ngOnInit(): void {
   
  }
  
  submit() {
    this.spinner.show();
  
    this.productionService.mrpReport(this.createReport.value.MrpName).subscribe((res: any) => {
      this.mrpRep = res.data;
     
      this.spinner.hide();
    })
  
  }
  homeButton(){
    this.router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
 
}