import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';

import { DatePipe, Location } from '@angular/common';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
@Component({
  selector: 'app-create-group-classification',
  templateUrl: './create-group-classification.component.html',
  styleUrls: ['./create-group-classification.component.css']
})
export class CreateGroupClassificationComponent implements OnInit {
  createClassification:FormGroup;
  activeCategory=2;
  partyId: any;
  jid: any;
  jname: any;
  show=false;
  party: any;
  identification: any;
  identificationArray: any[]=[];
  partyValue: any;
  partyTypeId: any;
  resumeDate: string;
  resumeId: any;
  contentId: any;
  eresumeDate: any;
  resumeText: any;
  getEstimateFrom: string;
  contentIds: any[];
  ClassificationGroupId: any;
  ClassificationGroupIdArray: any[]=[];
  fromDate: string;
  thruDate: string;
  partyClassId: any;
  efromDate: any;
  ethruDate: any;
  getthruDate: string;
  ClassificationGroup: any;
  ClassificationGroupTypeId: any;
  ClassificationGroupTypeIdArray: any[]=[];
  classId: any;
  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,private _location: Location,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,) { 
      this.contentIds = [];
      this.createClassification = this._FormBuilder.group({
        Description: [''],
        ParentGroupID: [''],
        PartyClassificationTypeID:['']
       
        
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.classId = params["classId"];
    });
   this.getClassificationGroup();
   this.getClassificationGroupTypeId();
   if(this.classId){
     this.show=true;
     this.getPartyClassificationGroupById();
   }
   else{
     this.show=false;
   }

 
  }
  getPartyClassificationGroupById(){
    this.spinner.show();
   
    this.accountsService.getPartyClassificationGroupById(this.classId).subscribe((res:any)=> {
      setTimeout(() => {
     
        const formValue = this.createClassification;
        formValue.patchValue({
          Description: res.data[0].partyClassificationGroupData.description,
        ParentGroupID:res.data[0].partyClassificationGroupData.parentGroupId,
        PartyClassificationTypeID:res.data[0].partyClassificationGroupData.partyClassificationTypeId,
        })
      
      }, 3000);
      this.spinner.hide();
    })  
   }
  
  getClassificationGroupTypeId(){
    this.spinner.show();
   
      this.accountsService.getClassificationGroupTypeId().subscribe((res:any)=> {
        this.ClassificationGroupTypeId = res.data;
        this.spinner.hide();
        for(const value of this.ClassificationGroupTypeId){
          this.ClassificationGroupTypeIdArray.push({
            label:value.partyClassificationTypeId.description,
            value:value.partyClassificationTypeId.partyClassificationTypeId
          })
        }
      
      })
     
     }
  getClassificationGroup(){
    this.spinner.show();
   
      this.accountsService.getClassificationGroup().subscribe((res:any)=> {
        this.ClassificationGroup = res.data;
        this.spinner.hide();
        for(const value of this.ClassificationGroup){
          this.ClassificationGroupIdArray.push({
            label:value.description.description,
            value:value.partyClassificationGroupData.partyClassificationGroupId
          })
        }
      
      })
     
     }
     
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "description": this.createClassification.value.Description,
      "parentGroupId": this.createClassification.value.ParentGroupID,
      "partyClassificationTypeId": this.createClassification.value.PartyClassificationTypeID,

     
    }
    this.accountsService.createPartyClassificationGroup(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "description": this.createClassification.value.Description,
      "parentGroupId": this.createClassification.value.ParentGroupID,
      "partyClassificationTypeId": this.createClassification.value.PartyClassificationTypeID,
      "partyClassificationGroupId":this.classId,
    
     
    }
    this.accountsService.updatePartyClassificationTypeId(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
       this._Router.navigate(["/partyQuickLink/classifications"]);
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
 
   
  cancelSubmit(){
    this._location.back();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }}
