import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  public token: string;
  resetEmail: string;
  tenantId:string;
  constructor( public router: Router,
    readonly loginService: LoginService, readonly toastr:ToastrService) { }

  ngOnInit(): void {
  }
login(){
  this.router.navigate(["/login"]);
}
submit(){
  
  this.loginService.resetPassword(this.resetEmail,this.tenantId).subscribe((res) =>{
   
   if(res.success ){
    this.toastr.success(res.data);
    window.location.href = '#';

   }
 else if(res.success == false) {
     this.toastr.error("Reset Password failed");
  }
 },(err) => {
   this.toastr.error("Invalid User Data");
}
);
 }
 back(){
  window.location.href = '#';
 }
  
}

