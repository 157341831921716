import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-warehouse-shipment-config',
  templateUrl: './warehouse-shipment-config.component.html',
  styleUrls: ['./warehouse-shipment-config.component.css']
})
export class WarehouseShipmentConfigComponent implements OnInit {

  activeCategory = 2 ;
  activeCat = 4;
  activeCate = 1;
  total = 0;
  rowNumber = 0;
  pageSize = 10;
  rows = 50;
  shipmentConfig: any[] = []
  shipmentConfigType: any[] = []
  ConfigTypeIDArray: any[]=[];
  ConfigTypeID: any;
  advanceSearch: {
    shipmentConfigID: string; 
    ConfigTypeID: string; 
     description: string; 


     };
  advanceSearchA: { 
    ShipmentGatewayConfigType: string; 
    ShipmentGatewayConfigTypeDescription: string; };


  constructor(
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe, 
    private _location: Location,

    readonly accountsService: AccountsService,
    readonly addSkillService: AddSkillService,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,
  ) {

    this.advanceSearch = {

      shipmentConfigID: "",
      ConfigTypeID: "",
      description: "",
    
    };

    this.advanceSearchA = {

      ShipmentGatewayConfigType: "",
      ShipmentGatewayConfigTypeDescription: "",
    
    };
   }

  ngOnInit(): void {

    this.getShipmentGatewayConfigTypeIdList();
    this.getFindShipmentGatewayConfig();
    this.getFindShipmentGatewayConfigTypes();
  }
    homeButton(){
      this.router.navigate(['/facilities/Dashboard'])
    
    }
    cancelSubmit(){
      this._location.back();
    }
  mainOpen(){
    this.router.navigate(['/facilities/warehouse']);
  }
  inventoryItemOpen(){
    this.router.navigate(['/facilities/inventory-labels']);
  }
  facilityGroupOpen(){
    this.router.navigate(['/facilities/facility-group']);
  }
  dashboardOpen(){
    this.router.navigate(["/facilities/warehouse-dashborad-overview"])
  }
  ShippingOpen(){
    this.router.navigate(["/facilities/warehouse-shipment-config"])
  }
  Main(){
    this.router.navigate(["/facilities/warehouse"])
  }
  Facility(){
    this.router.navigate(["/facilities/facility-group"])
  }
  Inventory(){
    this.router.navigate(["/facilities/inventory-labels"])
  }

  
 
  reset(){
    this.spinner.show();
    this.advanceSearch.shipmentConfigID="";
    this.advanceSearch.ConfigTypeID="";
    this.advanceSearch.description="";
 
   
      this.accountsService.getFindShipmentGatewayConfig().subscribe((res:any)=> {
        this.shipmentConfig = res.data;
        this.spinner.hide();
      
      
      }) 
  }
  resetType(){
    this.spinner.show();
    this.advanceSearchA.ShipmentGatewayConfigType="";
    this.advanceSearchA.ShipmentGatewayConfigTypeDescription="";
 
   
      this.accountsService.getFindShipmentGatewayConfigTypes().subscribe((res:any)=> {
        this.shipmentConfigType = res.data;
        this.spinner.hide();
      
      
      }) 
  }
  pageLoad(){
    this.spinner.show();
    this.advanceSearch.shipmentConfigID="";
    this.advanceSearch.ConfigTypeID="";
    this.advanceSearch.description="";
   
      this.accountsService.getFindShipmentGatewayConfig().subscribe((res:any)=> {
        this.shipmentConfig = res.data;
        this.spinner.hide();
      
      
      }) 
  }
  pageLoadType(){
    this.spinner.show();
    this.advanceSearchA.ShipmentGatewayConfigType="";
    this.advanceSearchA.ShipmentGatewayConfigTypeDescription="";
   
      this.accountsService.getFindShipmentGatewayConfigTypes().subscribe((res:any)=> {
        this.shipmentConfigType = res.data;
        this.spinner.hide();
      
      
      }) 
  }

  getFindShipmentGatewayConfig() {
    this.spinner.show();
    this.accountsService.getFindShipmentGatewayConfig().subscribe(res => {
      this.shipmentConfig = res.data;
      this.spinner.hide();

    })
  }
  
getFindShipmentGatewayConfigTypes() {
    this.spinner.show();
    this.accountsService.getFindShipmentGatewayConfigTypes().subscribe(res => {
      this.shipmentConfigType = res.data;
      this.spinner.hide();

    })
  }
  FindShipmentGatewayConfigTypes(){

    this.spinner.show();
    const req = {
      "shipmentGatewayConfTypeId": this.advanceSearchA.ShipmentGatewayConfigType ? this.advanceSearchA.ShipmentGatewayConfigType : '',
      "description": this.advanceSearchA.ShipmentGatewayConfigTypeDescription ? this.advanceSearchA.ShipmentGatewayConfigTypeDescription : '',
     
    }

    
      this.accountsService.FindShipmentGatewayConfigTypes(req.shipmentGatewayConfTypeId,
        req.description,).subscribe((res:any)=> {
        this.shipmentConfigType = res.data;
        this.spinner.hide();
      
      
      })
     
     }
  FindShipmentGatewayConfig(){
    this.spinner.show();
    const req = {
      "shipmentGatewayConfigId": this.advanceSearch.shipmentConfigID ? this.advanceSearch.shipmentConfigID : '',
      "shipmentGatewayConfTypeId": this.advanceSearch.ConfigTypeID ? this.advanceSearch.ConfigTypeID : '',
      "description": this.advanceSearch.description ? this.advanceSearch.description : '',
    }
      this.accountsService.FindShipmentGatewayConfig(req.shipmentGatewayConfigId,req.shipmentGatewayConfTypeId,req.description,).subscribe((res:any)=> {
        this.shipmentConfig = res.data;
        this.spinner.hide();
      
      
      })
     
     }

  getShipmentGatewayConfigTypeIdList() {
    this.spinner.show();
    this.accountsService.getShipmentGatewayConfigTypeIdList().subscribe(res => {
      this.ConfigTypeID = res.data;
      this.spinner.hide();
      for (const value of this.ConfigTypeID) {
        this.ConfigTypeIDArray.push({
          label: value.shipmentGatewayConfTypeId,
          value: value.shipmentGatewayConfTypeId
        })
      }
    })
  }
  detailPageShipment(shipmentGatewayConfTypeId,shipmentGatewayConfigId,description ) {
    this.router.navigate(['/facilities/detail-shipment-config'],{ queryParams: { shipmentGatewayConfTypeId:shipmentGatewayConfTypeId, 
    shipmentGatewayConfigId:shipmentGatewayConfigId,  description:description ,
      } })
  }

  detailShipmentTypes(parentTypeId,shipmentGatewayConfTypeId,hasTable,description ) {
    this.router.navigate(['/facilities/detail-shipment-config-types'],{ queryParams: { parentTypeId:parentTypeId, shipmentGatewayConfTypeId:shipmentGatewayConfTypeId, 
      hasTable:hasTable, description:description ,
      } })
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  shipmentGatewayOpen(){
    this.router.navigate(['/facilities/warehouse-shipment-config']);
  }

  shipmentsOpen(){
    this.router.navigate(['/facilities/warehouse-shipment']);
  }
}

