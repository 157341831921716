import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SortEvent } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { HeaderService } from 'src/app/header/header.service';
import { ToastrService } from 'ngx-toastr';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';

@Component({
  selector: 'app-parties-timesheet',
  templateUrl: './parties-timesheet.component.html',
  styleUrls: ['./parties-timesheet.component.css']
})
export class PartiesTimesheetComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 2;
  addTimeSheetRoles: any;
  addTimesheet: FormGroup;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  timesheetId: any;
  statusArray: { label: string; value: string; }[];
  personId: any;
  partyIdArray: any[]=[];
  RoleTypeId: any;
  RoleTypeIdArray: any[]=[];
  timesheetRoledata: any;
  actualfromdate: string;
  actualthroughdate: string;
  ;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly createEventServices: CreateEventServices,
    readonly spinner: NgxSpinnerService,
    private _location: Location,
    
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly toastr: ToastrService,
    readonly addSkillService: AddSkillService,
    readonly myContactsService: MyContactsService,
    
        readonly _FormBuilder: FormBuilder, private _Router : Router
  ) {
    this.statusArray = [

      {
        label: 'Approved',
        value: 'TIMESHEET_APPROVED'
      },
      {
        label: 'Completed',
        value: 'TIMESHEET_COMPLETED'
      },
      {
        label: 'In-Process',
        value: 'TIMESHEET_IN_PROCESS'
      },
      
    ];
    this.addTimeSheetRoles = this._FormBuilder.group({
      party: "",
      role: "",
    })

    this.addTimesheet = this._FormBuilder.group({
      party: "",
      fromDate: "",
      throughDate: "",
      clientPartyID: "",
      approvedByUserLoginID: "",
      comments: "",
      status: "",
    })
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.timesheetId = params['timesheetId'];

    });
    this.timesheetRole();
this.getPartyId();
this.getRoleTypeId();
  }
  deleteTimesheetRoleNew(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "partyId": product.partyId,
  "roleTypeId": product.roleTypeId,
  "timesheetId": this.timesheetId
  
        }
        this.myContactsService.deleteTimesheetRoleNew(req).subscribe(res => {
          this.timesheetRole(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  homeButton(){
     this._Router.navigate(['/psa/Dashboard'])
   
   }
   cancelSubmit(){
     this._location.back();
   }
   
  createTimesheetEntry(): void {
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.spinner.show();
      const formData ={
       "partyId": this.addTimeSheetRoles.value.party,
       "roleTypeId": this.addTimeSheetRoles.value.role,
       "timesheetId": this.timesheetId
      }
      this.myContactsService.createTimesheetEntry(formData).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success("Successfully Created");
          this.spinner.hide();
          this.closebutton.nativeElement.click();
          this.addTimeSheetRoles.reset();
          this.timesheetRole();
        }
      }, (err) => {
       this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.toastr.error("Permission Denied"); 
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.toastr.error("Permission Denied");
    }

    
   }
   timesheetRole(){
    this.spinner.show();
    this.myContactsService.timesheetRole(this.timesheetId).subscribe((res:any)=> {
      this.timesheetRoledata = res.data;
      this.spinner.hide();
    
    })   
   }
  createTimeSheetData(): void {
    this.spinner.show();
    const fromActualdate=this.addTimesheet.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.addTimesheet.get('throughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
     const requestData ={
   "approvedByUserLoginId": this.addTimesheet.value.approvedByUserLoginID,
  "clientPartyId": this.addTimesheet.value.clientPartyID,
  "comments":this.addTimesheet.value.comments,
  "fromDate":  this.actualfromdate,
  "partyId": this.addTimesheet.value.party,
  "statusId": this.addTimesheet.value.status,
  "thruDate":this.actualthroughdate
       
         
     }
     this.myContactsService.createTimeSheetData(requestData).subscribe((res: any) => {
      
       if (res.success) {
         this.toastr.success("Successfully Created");
         this.spinner.hide();
         this.addTimesheet.reset();
         this.closebutton.nativeElement.click();
      
       }
     }, (err) => {
      this.spinner.hide();
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
    
   }
   openTimesheet(){
    this._Router.navigate(["/psa/work-effort/timesheet-view/EditTimesheet"],{ queryParams: { timesheetId: this.timesheetId} })
  }
  openParties(){
    this._Router.navigate(["/psa/work-effort/timesheet-view/EditTimesheetRoles"],{ queryParams: { timesheetId: this.timesheetId} })
  }
  
  openEntries(){
    this._Router.navigate(["/psa/work-effort/timesheet-view/EditTimesheetEntries"],{ queryParams: { timesheetId: this.timesheetId} })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   }
   getRoleTypeId() {
    this.spinner.show();
    this.addSkillService.getRoleTypeId().subscribe(res => {
      this.RoleTypeId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.RoleTypeId) {
        this.RoleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
}
