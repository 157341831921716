<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;"> Routing Task


                </span>
                <span class="color-black pl-1"> >Add New Routing Task



                </span>

                <span class="">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>

            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''"  *ngIf="!this.show"><a>
                                    Add Routing Task



                                </a></li>
                                <li [ngClass]="activeCategory==2?'active':''"  *ngIf="this.show"><a>
                                    Update Routing Task



                                </a></li>



                        </ul>
                    </div>


                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" [formGroup]="createroute">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group formRight">
                                                    <label for="exampleInputEmail1">
                                                        Routing Task Name<span
                                                        style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Routing Task Name"
                                                    formControlName="RoutingTaskName">
                                                </div>
                                                <div class="col-lg-3 form-group formRightA">
                                                    <label for="exampleInputEmail1">
                                                        Task Type</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Task Type" filter="true"
                                                    formControlName="TaskType"
                                                    [options]="routingTaskTypeArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                       
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group formRight">
                                                    <label for="exampleInputEmail1">
                                                        Description</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Description"
                                                    formControlName="Description">
                                                </div>
                                                <div class="col-lg-3 form-group formRightA">
                                                    <label for="exampleInputEmail1">
                                                        Fixed Asset ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Fixed Asset ID" filter="true"
                                                    formControlName="FixedAssetID"
                                                    [options]="fixedAssetIdArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                      
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group formRight">
                                                    <label for="exampleInputEmail1">
                                                        Estimated Setup Time</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Enter Estimated Setup Time"
                                                    formControlName="EstimatedSetupTime">
                                                </div>
                                                <div class="col-lg-3 form-group formRightA">
                                                    <label for="exampleInputEmail1">
                                                        Estimated Unit Run Time</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Enter Estimated Unit Run Time"
                                                    formControlName="EstimatedUnitRunTime">
                                                </div>
                                            </div>
                                        </div>
                                       
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group formRight">
                                                    <label for="exampleInputEmail1">
                                                        Estimate Calc Method</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Estimate Calc Method" filter="true"
                                                    formControlName="EstimateCalcMethod"
                                                    [options]="estimateCalcMethodArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group formRightA">
                                                    <label for="exampleInputEmail1">
                                                        Reserv Persons</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Reserv Persons"
                                                    formControlName="ReservPersons">
                                                </div>
                                            </div>
                                        </div>
                                   

                                    </div>


                                </form>
                                <div class="main-submit-button" style="margin-left: 66%;">

                                    <button type="submit"  *ngIf="!this.show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>
                                    <button type="submit"  *ngIf="this.show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>