<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" routerLink="/financial/payable/billing-account">Billing Account </span>
                <span class="color-black pl-1"> > {{editMode ?'Update':'Create'}} Billing Account</span>
                <button class="btn btn-danger buttonclass" (click)="reset()">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''"><a>
                                    {{editMode ?'Update':'Create'}} Billing Account</a></li>

                        </ul>
                    </div>

                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" [formGroup]="billingAccountForm">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">


                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Account Limit</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" formControlName="accountLimit"
                                                        placeholder="Account Limit">
                                                </div>

                                                <div class="col-lg-3 form-group classInputA" *ngIf="editMode">
                                                    <label for="billingAccountId">Billing Account Id :
                                                      </label>
                                                </div>
                                                <div class="col-lg-2" *ngIf="editMode">
                                                    <span>{{billingAccountId}}</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Account Currency Uom ID <span
                                                            class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" [options]="currencyUomIds"
                                                        formControlName="accountCurrencyUomId" optionlabel="label"
                                                        placeholder="Select Currency">
                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Contact Mech ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" [options]="contactMechIds"
                                                        formControlName="contactMechId" optionlabel="label"
                                                        placeholder="Contact Mech Id"></p-dropdown>
                                                </div>


                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">From Date <span
                                                            class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-calendar formControlName="fromDate" placeholder="Select Date">
                                                    </p-calendar>
                                                </div>

                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Through Date</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-calendar formControlName="thruDate" placeholder="Select Date">
                                                    </p-calendar>
                                                </div>


                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Description
                                                        <span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Description"
                                                        formControlName="description">
                                                    <small class="text-danger"
                                                        *ngIf="!!billingAccountForm.controls.description.invalid && !!billingAccountForm.controls.description.touched">Description
                                                        is required</small>
                                                </div>

                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">External Account ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        formControlName="externalAccountId" aria-describedby="emailHelp"
                                                        placeholder="External Account Id">
                                                </div>


                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Party Billed To <span
                                                            class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" [options]="partyIds" optionlabel="label"
                                                        formControlName="partyId" placeholder="Select Party">
                                                    </p-dropdown>
                                                    <small class="text-danger"
                                                        *ngIf="!!billingAccountForm.controls.partyId.invalid && !!billingAccountForm.controls.partyId.touched">Party
                                                        is required</small>
                                                </div>

                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Available Balance</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        formControlName="availableBalance" aria-describedby="emailHelp"
                                                        placeholder="Available Balance">
                                                </div>


                                            </div>
                                        </div>


                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -21%;"><button _ngcontent-cta-c128=""
                                            type="submit" class="btn btn-secondary submit-btn"
                                            (click)="submit()">{{editMode ?'Update':'Create'}}</button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>