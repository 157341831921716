import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-internal-job-posting',
  templateUrl: './new-internal-job-posting.component.html',
  styleUrls: ['./new-internal-job-posting.component.css']
})
export class NewInternalJobPostingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
