<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-grey breadcrumbb" (click)="back()">Shipment </span>
                <span class="color-black pl-1"> > Update</span>
               
                <span class="">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">

                        <li [ngClass]="activeCategory==2?'active':''"><a>
                                Update Shipment</a></li>


                    </ul>
                </div>

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="updateWarehouseShipment">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Shipment ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" 
                                                formControlName="shipmentId" readonly>
                                        </div>

                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Status ID</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <p-dropdown [options]="StatusIdList" placeholder="Select Status ID"
                                                filter="true" formControlName="statusId"></p-dropdown>
                                    </div>
                                    </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Primary Order ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="OrderIdsList"
                                                    placeholder="Select Primary Order ID" filter="true"
                                                    formControlName="primaryOrderId"></p-dropdown>
                                        </div>
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Primary Return ID</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="text" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" 
                                            formControlName="primaryReturnId">
                                    </div>
                                    </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Primary Ship Group Seq ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" 
                                                formControlName="primaryShipGroupSeqId">
                                        </div>
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Picklist Bin</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="text" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" 
                                            formControlName="picklistBinId">
                                    </div>
                                    </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Estimated Ready Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" 
                                                formControlName="estimatedReadyDate">
                                        </div>
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Estimated Ship Date</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="date" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" 
                                            formControlName="estimatedShipDate">
                                    </div>
                                    </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Estimated Arrival Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" 
                                                formControlName="estimatedArrivalDate">
                                        </div>
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Latest Cancel Date</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="date" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" 
                                            formControlName="latestCancelDate">
                                    </div>
                                    </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Estimated Ship Cost</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" 
                                                formControlName="estimatedShipCost">
                                        </div>

                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Currency Uom ID</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <p-dropdown [options]="getCurrencyList" placeholder="Select Currency"
                                                filter="true" formControlName="currencyUomId"></p-dropdown>
                                    </div>
                                    </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Handling Instructions</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" 
                                                formControlName="handlingInstructions">
                                        </div>
                                          
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Origin Facility</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <p-dropdown [options]="facilities" placeholder="Select Origin Facility"
                                                filter="true" formControlName="originFacilityId"></p-dropdown>
                                    </div>
                                    </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Destination Facility</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="facilities"
                                                    placeholder="Select Destination Facility" filter="true"
                                                    formControlName="originFacility"></p-dropdown>
                                        </div>
                                          
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Origin Postal Address ID</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="text" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp"  formControlName="adress">
                                    </div>
                                    </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Origin Phone Number ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" 
                                                formControlName="originTelecomNumberId">
                                        </div>
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Destination Postal Address ID</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="text" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" 
                                            formControlName="destinationFacilityId">
                                    </div>
                                    </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Destination Phone Number ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" 
                                                formControlName="destinationTelecomNumberId">
                                        </div>
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">To Party</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <p-dropdown [options]="PersonsAndPartyGroupsList" filter="true"
                                            placeholder="Select" formControlName="partyIdTo">
                                        </p-dropdown>
                                    </div>
                                    </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">From Party</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="PersonsAndPartyGroupsList" filter="true"
                                                placeholder="Select" formControlName="partyIdFrom">
                                            </p-dropdown>
                                        </div>
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Additional Shipping Charge</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" 
                                            formControlName="additionalShippingCharge">
                                    </div>
                                    </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Addtl Shipping Charge Desc</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" 
                                                formControlName="addtlShippingChargeDesc">
                                        </div>
                                         
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Event Date</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="date" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp"  formControlName="eventDate">
                                    </div>
                                    </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Shipment type ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="ShipmentTypeIdList" filter="true"
                                                    placeholder="Select" formControlName="shipmentTypeId">
                                                </p-dropdown>
                                        </div>
                                    </div>
                                    </div>
                                 
                                 
                                </div>
                            </form>
                            <div class="main-submit-button" style="margin-right: 21%;">
                                <button type="submit" (click)="submit()"
                                    class="btn btn-secondary submit-btn update-button">Update</button>
                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 