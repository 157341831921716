import { DatePipe } from '@angular/common';
import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';

@Component({
  selector: 'app-subscription-resource-catalog',
  templateUrl: './subscription-resource-catalog.component.html',
  styleUrls: ['./subscription-resource-catalog.component.css']
})
export class SubscriptionResourceCatalogComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  addSubResource: any;
  ContentId: any;
  ContentIdArray: any[]=[];
  subscriptionResourceList: any;
  subscriptionResourceListArray: any[]=[];
  webSiteId: any;
  webSiteIdArray:any[]=[];
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,    private _location: Location,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) { 
    this.addSubResource = this._FormBuilder.group({
      description: [''],
      contentID: [''],
      websiteID: [''],
      sericeNameExpiry: [''],
  });
}

  ngOnInit(): void {
    this.getWebSiteId();
    this.getSubscriptionResourceList();
    this.getContentIdResource();
  }

  detailPage(subscriptionResourceId){
    this._Router.navigate(["/facilities/catalog/subscriptions/detail-subscription-resource"],{queryParams:{subscriptionResourceId:subscriptionResourceId}})
  }
  createSubscriptionResource(): void {
    this.spinner.show();
    const requestData = {
      "contentId":  this.addSubResource.value.contentID,
      "description":  this.addSubResource.value.description,
      "serviceNameOnExpiry": this.addSubResource.value.sericeNameExpiry,
      "webSiteId":  this.addSubResource.value.websiteID,
     
    }
    this.myContactsService.createSubscriptionResource(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.addSubResource.reset();
        this.getSubscriptionResourceList();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });
  }
  getSubscriptionResourceList() {
    this.spinner.show();
    this.myContactsService.getSubscriptionResourceList().subscribe(res => {
      this.subscriptionResourceList = res.data[0].data;
      this.spinner.hide();
    })
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  getContentIdResource() {
    this.spinner.show();
    this.myContactsService.getContentIdResource().subscribe(res => {
      this.ContentId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ContentId) {
        this.ContentIdArray.push({
          label: value.contentId,
          value: value.contentId
        })
      }
    })
  }
  
  getWebSiteId() {
    this.spinner.show();
    this.myContactsService.getWebSiteId().subscribe(res => {
      this.webSiteId = res.data[0].getWebSiteId;
      this.spinner.hide();
      for (const value of this.webSiteId) {
        this.webSiteIdArray.push({
          label: value.siteName,
          value: value.webSiteId
        })
      }
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
