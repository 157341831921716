import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-marketing-campaign-id',
  templateUrl: './marketing-campaign-id.component.html',
  styleUrls: ['./marketing-campaign-id.component.css']
})
export class MarketingCampaignIdComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  activeCategory = 1;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  roleForm: FormGroup;
  AddTaskForm: FormGroup;
  projectForm: FormGroup;
  ratesForm: FormGroup;
  updateProfile: FormGroup;
  createUserLogIn: FormGroup;
  addRelatedAccount: FormGroup;
  createNewContactInformation: FormGroup;
  partyAttributes: FormGroup;
  addCheckAccount: FormGroup;
  createBillingAccount: FormGroup;
  addNewCreditCard: FormGroup;
  createGiftCard: FormGroup;
  createEFTAcc: FormGroup;
  CreateDataSourceForm: FormGroup;
  CreateDataSourceTypeForm: FormGroup;
  CreateMarketingCampaignForm: FormGroup;
  CreateMarketingCampaignRoleForm: FormGroup;
  MarketCampaignIds: any;
  MarketCampaignIdsArray: any[]=[];
  StatusListss: any;
  StatusListssArray: any[]=[];
  CurrencyLists: any;
  CurrencyListsArray: any[]=[];
  yesNOArray: { label: string; value: string; }[];
  RoleTypeId: any;
  RoleTypeIdArray: any[]=[];
  marketId: any;
  editValue: any;
  getEstimateTo: string;
  getEstimateFrom: string;
  startDateValue: any;
  thrufromdate: string;
  actualfromdate: string;
  StartDate: string;
  show: boolean;
  fromDate: string;
  thruDate: string;
  MarketingCampaignRole: any;
  personId: any;
  partyIdArray: any[]=[];

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly addSkillService: AddSkillService,
    readonly _Router: Router,
    private _location: Location,
  ) {
    this.yesNOArray = [

      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];
    this.CreateMarketingCampaignForm = this._FormBuilder.group({
      ParentCampaignID: [''],
      Status: [''],
      CampaignName: [''],
      CampaignSummary: [''],
      BudgetedCost: [''],
      ActualCost: [''],
      Estimatedcost: [''],
      Currency: [''],
      FromDate: [''],
      ThroughDate: [''],
      IsActive: [''],
      ConvertedLeads: [''],
      ExpectedResponsePercent: [''],
      ExpectedRevenue: [''],
      NumSent: [''],
      StartDate: [''],
      CreatedByUser: [''],
      LastModifiedByUser: [''],
      
    });
    this.CreateMarketingCampaignRoleForm = this._FormBuilder.group({
      MarketingCampaignID: [''],
      PartyID: [''],
      RoleTypeID: [''],
      FromDate: [''],
      ThroughDate: [''],
      
      });
   }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params=> {
      this.marketId = params["marketId"];
    });
    setTimeout(() => {
  
      const formValue = this.CreateMarketingCampaignRoleForm;
      formValue.patchValue({
        MarketingCampaignID: this.marketId,

      })
    
    }, 1000);
    this.getMarketCampaignIds();
    this.getStatusListss();
    this.getCurrencyLists();
    this.getRoleTypeId();
    this.getPartyId();
    this.getMarketingCampaignById();
    this.getMarketingCampaignRole();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
 
  expireMarketingCampaignRole(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "marketingCampaignId":product.datas.marketingCampaignId,
          "partyId": product.datas.partyId,
          "roleTypeId":product.datas.roleTypeId,
         
        }
        this.accountsService.expireMarketingCampaignRole(req).subscribe(res => {
          this.getMarketingCampaignRole();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getMarketingCampaignRole() {
    this.spinner.show();
    this.accountsService.getMarketingCampaignRole(this.marketId).subscribe(res => {
      this.MarketingCampaignRole = res.data;
      this.spinner.hide();
     
    })
  }
 
  createMarketingCampaignRole(): void {
    this.spinner.show();
    const fromDate=this.CreateMarketingCampaignRoleForm.get('FromDate').value;
    this.fromDate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const thruDate=this.CreateMarketingCampaignRoleForm.get('ThroughDate').value;
    this.thruDate=this.datePipe.transform(thruDate,"yyyy-MM-dd hh:mm:ss");
   
    const requestData ={
      "fromDate":  this.fromDate,
      "partyId":  this.CreateMarketingCampaignRoleForm.value.PartyID,
       "roleTypeId": this.CreateMarketingCampaignRoleForm.value.RoleTypeID,
      "thruDate":this.thruDate,
      "marketingCampaignId":this.marketId
      }
     
    this.accountsService.createMarketingCampaignRole(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getMarketingCampaignRole();
        this.CreateMarketingCampaignRoleForm.reset();
        this.closebutton.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }


  createMarketingCampaign(): void {
    this.spinner.show();
    const fromActualdate=this.CreateMarketingCampaignForm.get('FromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const thruActualdate=this.CreateMarketingCampaignForm.get('ThroughDate').value;
    this.thrufromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
    const StartDate=this.CreateMarketingCampaignForm.get('StartDate').value;
    this.StartDate=this.datePipe.transform(StartDate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "actualCost": this.CreateMarketingCampaignForm.value.ActualCost,
      "budgetedCost": this.CreateMarketingCampaignForm.value.BudgetedCost,
      "campaignName": this.CreateMarketingCampaignForm.value.CampaignName,
      "campaignSummary": this.CreateMarketingCampaignForm.value.CampaignSummary,
      "convertedLeads": this.CreateMarketingCampaignForm.value.ConvertedLeads,
      "createdByUserLogin": this.CreateMarketingCampaignForm.value.CreatedByUser,
      "currencyUomId":this.CreateMarketingCampaignForm.value.Currency,
      "done_PAGE": this.CreateMarketingCampaignForm.value.DataSourceID,
      "estimatedCost":this.CreateMarketingCampaignForm.value.Estimatedcost,
      "expectedResponsePercent":this.CreateMarketingCampaignForm.value.ExpectedResponsePercent,
      "expectedRevenue": this.CreateMarketingCampaignForm.value.ExpectedRevenue,
      "fromDate":this.actualfromdate,
      "isActive": this.CreateMarketingCampaignForm.value.IsActive,
      "lastModifiedByUserLogin": this.CreateMarketingCampaignForm.value.LastModifiedByUser,
      "numSent": this.CreateMarketingCampaignForm.value.NumSent,
      "parentCampaignId": this.CreateMarketingCampaignForm.value.ParentCampaignID,
      "startDate": this.StartDate,
      "statusId": this.CreateMarketingCampaignForm.value.Status,
      "thruDate": this.thrufromdate,
      "marketingCampaignId":this.marketId

  
   
      }
     
    this.accountsService.updateMarketingCampaign(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.getMarketingCampaignById();
        // this._Router.navigate(["/marketing/marketing-campaign"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  getMarketingCampaignById(){
    this.spinner.show();
  
    this.accountsService.getMarketingCampaignById(this.marketId).subscribe((res:any)=> {
      this.editValue=res.data[0].node[0];
      setTimeout(() => {
        const fromdate=this.editValue.fromDate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const toEstdate=this.editValue.thruDate
        this.getEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
        const startDate=this.editValue.startDate
        this.startDateValue=this.datePipe.transform(startDate,"yyyy-MM-dd");
        const formValue = this.CreateMarketingCampaignForm;
        formValue.patchValue({
          ParentCampaignID: this.editValue.parentCampaignId,
      Status: this.editValue.statusId,
      CampaignName: this.editValue.campaignName,
      CampaignSummary: this.editValue.campaignSummary,
      BudgetedCost:this.editValue.budgetedCost,
      ActualCost: this.editValue.actualCost,
      Estimatedcost:this.editValue.estimatedCost,
      Currency: this.editValue.currencyUomId,
      FromDate: this.getEstimateFrom,
      ThroughDate:  this.getEstimateTo,
      IsActive: this.editValue.isActive,
      ConvertedLeads:this.editValue.convertedLeads,
      ExpectedResponsePercent: this.editValue.expectedResponsePercent,
      ExpectedRevenue:this.editValue.expectedRevenue,
      NumSent: this.editValue.numSent,
      StartDate: this.startDateValue,
      CreatedByUser:this.editValue.createdByUserLogin,
      LastModifiedByUser:this.editValue.lastModifiedByUserLogin,
        })
      
      }, 2000);
      this.spinner.hide();
    })  
   }
  MarketingCampaign() {
    this._Router.navigate(["/marketing/marketing-campaign-id"])
  }
  Roles() {
    this._Router.navigate(["/marketing/marketing-campaign-id"])
  }
  back(){
    this._Router.navigate(["/marketing/marketing-campaign"])
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
  getMarketCampaignIds() {
    this.spinner.show();
    this.myContactsService.getMarketCampaignIds().subscribe(res => {
      this.MarketCampaignIds = res.data[1].data;
      this.spinner.hide();
      for (const value of this.MarketCampaignIds) {
        this.MarketCampaignIdsArray.push({
          label: value.campaignName,
          value: value.MarketCampaignId
        })
      }
    })
  }
  getStatusListss() {
    this.spinner.show();
    this.myContactsService.getStatusListss().subscribe(res => {
      this.StatusListss = res.data;
      this.spinner.hide();
      for (const value of this.StatusListss) {
        this.StatusListssArray.push({
          label: value.node[0].transitionName,
          value: value.node[0].statusIdTo
        })
      }
    })
  }
  getCurrencyLists() {
    this.spinner.show();
    this.myContactsService.getCurrencyLists().subscribe(res => {
      this.CurrencyLists = res.data[0].node;
      this.spinner.hide();
      for (const value of this.CurrencyLists) {
        this.CurrencyListsArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
  }
  getRoleTypeId() {
    this.spinner.show();
    this.myContactsService.getRoleTypeId().subscribe(res => {
      this.RoleTypeId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.RoleTypeId) {
        this.RoleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
  cancelSubmit(){
    this._Router.navigate(['/marketing/marketing-campaign'])
  }

  addEffortEventReminder(){
    this.show=false;
    this.CreateMarketingCampaignRoleForm.reset();
  }
}
