import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';

@Component({
  selector: 'app-detail-open-order-item-report',
  templateUrl: './detail-open-order-item-report.component.html',
  styleUrls: ['./detail-open-order-item-report.component.css']
})
export class DetailOpenOrderItemReportComponent implements OnInit {
  activeCategory = 2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  openOrderItemsReport: any;
  constructor(public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router) { }

  ngOnInit(): void {
    this.getOpenOrderItemsReport();

  }
  findOrderPage(id){
    this._Router.navigate(['order/sales-order-view-page'],{ queryParams: { orderId:id} })
  }
  getOpenOrderItemsReport() {
    this.spinner.show();
    this.myContactsService.getOpenOrderItemsReport().subscribe(res => {
      this.openOrderItemsReport = res.data[0].data;
      this.spinner.hide();
    })
  }
  getOdpenOrderItemsReport() {
    this.spinner.show();
    this.myContactsService.getOpenOrderItemsReport().subscribe(res => {
      this.openOrderItemsReport = res.data;
      this.spinner.hide();
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
