<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span class="color-black pl-1 titlePanels"> 
                 </span>
                 <span class="">
 
                     <button type="submit" (click)="homeButton();"
                         class=" btn btn-outline-secondary mr-2">Home</button>
             
                     <button type="submit" (click)="cancelSubmit();"
                         class="btn btn-danger buttonclass ">Back</button>
                 </span>
             
             </div>
            <div class="w3-card-4 classCard mt-20">
                <div class=" bg-white color-grey">
                    <div class="create-new-leade">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 8px !important;">
                                <li [ngClass]="activeCategory==1?'active':''" (click)="openiCalendar()"><a> iCalendar </a></li>
                                <li [ngClass]="activeCategory==2?'active':''" (click)="openChildren()"><a> Children </a></li>
                                <li [ngClass]="activeCategory==3?'active':''" (click)="openParties()"><a> Parties </a></li>
                                <li [ngClass]="activeCategory==4?'active':''" (click)="openFixedAssets()"><a> Fixed Assets </a></li>
                                <li [ngClass]="activeCategory==5?'active':''" (click)="openData()"><a> Data </a></li>
                                <li [ngClass]="activeCategory==6?'active':''" (click)="openHelp()"><a> Help </a></li>
                            </ul>
                        </div>
                        <div class="container-fluid">
                            <div class="row">
                                <div class="space10"></div>
                                <div class="col-lg-12" style="color:#0d3769 ;font-size: 14px;font-weight: 600;">Help iCalendar - iCalendar Publish Demonstration
                                    <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#createiCalendar"> Create </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createiCalendar" tabindex="-1" role="dialog" aria-labelledby="createiCalender" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> iCalendar </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add iCalendar</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addiCalender">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Calendar Owner</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="calendarOwner" [options]="partyIdArray" optionlabel="label" placeholder="Enter Calendar Owner">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Calendar Name <span style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="calendarName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter CalendarName">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Calendar Visibility <span style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="calendarVisibility" [options]="CalenderVisibilityArray" optionlabel="label" placeholder="Enter Calendar Visibility">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">From</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="fromDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">To</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="toDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter To">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-6 main-submit-button1" style="margin-left: -19%;">
                                            <button type="submit" (click)="createICalendar()" class="btn btn-secondary submit-btn">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>