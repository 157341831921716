import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';

@Component({
  selector: 'app-create-journal',
  templateUrl: './create-journal.component.html',
  styleUrls: ['./create-journal.component.css']
})
export class CreateJournalComponent implements OnInit {
  createJournal:FormGroup;
  activeCategory=2;
  partyId: any;
  jid: any;
  jname: any;
  show=false;
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
  
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,) { 
      this.createJournal = this._FormBuilder.group({
        journalName: [''],
       
        
      });
    
  }
 
  ngOnInit(): void {
    
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
      this.jname = params["jname"];
      this.jid = params["jid"];
      
    });
    if(this.jid){
      this.show=true;
      setTimeout(() => {
        const formValue = this.createJournal;
      formValue.patchValue({
        journalName:this.jname,
       
       
      })
      }, 1000);

    }else{
      this.show=false;
    }
  }
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "glJournalId": "",
      "glJournalName": this.createJournal.value.journalName,
      "organizationPartyId":  this.partyId,
      
    }
    this.accountsService.postJournal(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "glJournalId":  this.jid,
      "glJournalName": this.createJournal.value.journalName,
      "organizationPartyId":  this.partyId,
    }
    this.accountsService.updateJournal(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  
  cancelSubmit(){
    this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
  }

}

