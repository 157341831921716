<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-gry cursor-pointer" routerLink="/facilities/warehouse">Facilities </span>
                <span class="color-black pl-1"> > Facility Summary</span>
                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Back</button>
            </div>
            <div class="w3-card-4 classCard">
            <div class="container-fluid scrolling-container">
                <ul class="tabbing-section scolling-tabss tabbing-accordians tabClass" style="padding-left: 9px;">
                    <li class="litab" [ngClass]="activeCategory==1?'active':''"
                        (click)="changeactiveCategoryFunction(1)"><a>
                            Inventory Item </a></li>
                    <li class="litab" [ngClass]="activeCategory==2?'active':''"
                        (click)="changeactiveCategoryFunction(2)"><a>
                            Inventory Details</a></li>
                    <li class="litab" [ngClass]="activeCategory==3?'active':''"
                        (click)="changeactiveCategoryFunction(3)"><a>
                            Label Applicability</a></li>
                    
                </ul>
            </div>
            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group">
                                            <div *ngIf="activeCategory==1">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Summary Inventory Item

                                                    </h4>
                                                    <!-- <span class="edit-buttons" 
                                                        routerLink="/facilities/warehouse/create-new-contact-information"
                                                        [queryParams]="{facilityId:facilityId}"
                                                        data-toggle="modal"
                                                        data-target="#exampleModalCenter"
                                                        >Create</span> -->
                                                    <!-- <span class="edit-buttons"
                                                    data-toggle="modal"
                                                    data-target="#updateInventoryItemPopup" (click)="updateInventorySummaryPage()">Update</span>
                                                 -->
                                                </div>
                                                <div class="panel-body">

                                                    <div class="panel-body">

                                                        
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">
                                                                <div class="row">
                                                                            <div class="col-lg-6">
                                                                                <div class="article-container">
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6">Inventory Item ID	</p>
                                                                                        <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.inventoryItemId}}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-lg-6">
                                                                                <div class="article-container">
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6">Inventory Item Type ID	</p>
                                                                                        <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.inventoryItemTypeId}}</span>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-lg-6">
                                                                                <div class="article-container">
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6">Product ID	
                                                                                        </p>
                                                                                        <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.productId}}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                    
                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 ">Party ID	
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.partyId}}</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">

                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Owner Party ID	
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.ownerPartyId}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">

                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Status ID		
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.statusId}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">

                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Date Time Received		
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.datetimeReceived}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">

                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Date Time Manufactured		
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.datetimeManufactured}}</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">

                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Expire Date		
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.expireDate}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">

                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Facility ID		
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.facilityId}}</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">

                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Container ID		
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.containerId}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    
                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">

                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Lot ID		
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.lotId}}</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">

                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Uom ID		
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.uomId}}</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">

                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Bin Number		
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.binNumber}}</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">

                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Facility Location	
            
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.locationSeqId}}</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">

                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Comments		
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.comments}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
            

                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Accounting Quantity Total		
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.accountingQuantityTotal}}</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">

                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Serial Number		
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.serialNumber}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">



                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Soft Identifier	
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.softIdentifier}}</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">

                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Activation Number		
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.activationNumber}}</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">


                                                                                <div class="article">
                                                                                    <p class="col-lg-6 ">Activation Valid Through		
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.activationValidThru}}</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 ">Unit Cost		
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.unitCost}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">

                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Currency		
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.currencyUomId}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">

                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Fixed Asset ID		
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.fixedAssetId}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 ">Available To Promise / Quantity On Hand		
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{inventorySummaryPage.quantityOnHandTotal}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==2">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Inventory Details
                                                    </h4>
                                                 
                                                </div>
                                                <div class="panel-body">

                                                    <ng-container >
                                                        <div class="card own-account-table mt-2">
                                                            <p-table [value]="inventoryItemDetail" [paginator]="true"
                                                                [rows]="rows" scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox" binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined"
                                                                                            value="undefined"></div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            Inventory Item Detail Seq ID <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;"> Effective Date <p-sortIcon
                                                                                    field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price"><div style="color: white;">  Quantity On Hand Diff</div>

                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price"><div style="color: white;">Available To Promise Diff</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">   Accounting Quantity Diff</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">Unit Cost</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Order ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Order Item Seq ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Ship Group Seq ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Shipment ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                        pSortableColumn="price">
                                                                        <div style="color: white;"> Shipment Item Seq ID</div>
                                                                        <input
                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            style="visibility:hidden;" value=""
                                                                            autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                    pSortableColumn="price">
                                                                    <div style="color: white;"> Return ID</div>
                                                                    <input
                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        style="visibility:hidden;" value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                    </th>
                                                                <th [ngStyle]="{'width':'190px'}"
                                                                pSortableColumn="price">
                                                                <div style="color: white;"> Return Item Seq ID</div>
                                                                <input
                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    style="visibility:hidden;" value=""
                                                                    autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'190px'}"
                                                            pSortableColumn="price">
                                                            <div style="color: white;"> Work Effort ID</div>
                                                            <input
                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                style="visibility:hidden;" value=""
                                                                autocomplete="off"
                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false"
                                                                aria-readonly="false">
                                                        </th>
                                                        <th [ngStyle]="{'width':'190px'}"
                                                        pSortableColumn="price">
                                                        <div style="color: white;"> Fixed Asset ID</div>
                                                        <input
                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                            style="visibility:hidden;" value=""
                                                            autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false"
                                                            aria-readonly="false">
                                                    </th>
                                                        <th [ngStyle]="{'width':'190px'}"
                                                        pSortableColumn="price">
                                                        <div style="color: white;"> Maint Hist Seq ID</div>
                                                        <input
                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                            style="visibility:hidden;" value=""
                                                            autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false"
                                                            aria-readonly="false">
                                                    </th>

                                                    <th [ngStyle]="{'width':'190px'}"
                                                    pSortableColumn="price">
                                                    <div style="color: white;"> Item Issuance ID</div>
                                                    <input
                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        style="visibility:hidden;" value=""
                                                        autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false"
                                                        aria-readonly="false">
                                                </th>

                                                    <th [ngStyle]="{'width':'190px'}"
                                                    pSortableColumn="price">
                                                    <div style="color: white;"> Receipt ID</div>
                                                    <input
                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        style="visibility:hidden;" value=""
                                                        autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false"
                                                        aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'190px'}"
                                                pSortableColumn="price">
                                                <div style="color: white;"> Physical Inventory ID</div>
                                                <input
                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                    style="visibility:hidden;" value=""
                                                    autocomplete="off"
                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false"
                                                    aria-readonly="false">
                                            </th>

                                            <th [ngStyle]="{'width':'190px'}"
                                            pSortableColumn="price">
                                            <div style="color: white;"> Reason Enum ID</div>
                                            <input
                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                style="visibility:hidden;" value=""
                                                autocomplete="off"
                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                role="textbox" aria-disabled="false"
                                                aria-readonly="false">
                                        </th>

                                        <th [ngStyle]="{'width':'190px'}"
                                        pSortableColumn="price">
                                        <div style="color: white;"> Description</div>
                                        <input
                                            id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value=""
                                            autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                            role="textbox" aria-disabled="false"
                                            aria-readonly="false">
                                    </th>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox" binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined"
                                                                                            value="undefined"></div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                                <span
                                                                                     class="account-button" >{{product.inventoryItemDetailSeqId}}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.effectiveDate | date:'yyyy-MM-d hh:mm:ss'}}
                                                                        </td>
                                                                        

                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.quantityOnHandDiff}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.availableToPromiseDiff}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.accountingQuantityDiff}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.unitCost}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.orderId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.orderItemSeqId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.orderItemSeqId}}
                                                                        </td>

                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.shipGroupSeqId}}
                                                                        </td>

                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.shipmentId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.shipmentItemSeqId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.returnId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.returnItemSeqId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.workEffortId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.fixedAssetId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.maintHistSeqId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.itemIssuanceId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.receiptId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.physicalInventoryId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.reasonEnumId}}
                                                                        </td>

                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.description}}
                                                                        </td>
                                                                        

                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <!-- <p class="paginate_data">
                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                            <p class="paginate_data">
                                                                View per page </p>

                                                        </div>
                                                    </ng-container>

                                                    <ng-container >
                                                        <div class="card own-account-table mt-2">
                                                            <p-table [value]="inventoryItemDetailSecond" [paginator]="true"
                                                                [rows]="rows" scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox" binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined"
                                                                                            value="undefined"></div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            Receipt ID <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;"> Product ID<p-sortIcon
                                                                                    field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price"><div style="color: white;">Shipment ID</div>

                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price"><div style="color: white;">Shipment Item Seq ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">Shipment Package Seq ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">Order ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">Order Item Seq ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Return ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">Return Item Seq ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">Rejection ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                        pSortableColumn="price">
                                                                        <div style="color: white;"> Received By User Login ID</div>
                                                                        <input
                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            style="visibility:hidden;" value=""
                                                                            autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                    pSortableColumn="price">
                                                                    <div style="color: white;">Date Time Received</div>
                                                                    <input
                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        style="visibility:hidden;" value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                    </th>
                                                                <th [ngStyle]="{'width':'190px'}"
                                                                pSortableColumn="price">
                                                                <div style="color: white;">Item Description</div>
                                                                <input
                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    style="visibility:hidden;" value=""
                                                                    autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'190px'}"
                                                            pSortableColumn="price">
                                                            <div style="color: white;">Quantity Accepted</div>
                                                            <input
                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                style="visibility:hidden;" value=""
                                                                autocomplete="off"
                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false"
                                                                aria-readonly="false">
                                                        </th>
                                                        <th [ngStyle]="{'width':'190px'}"
                                                        pSortableColumn="price">
                                                        <div style="color: white;">Quantity Rejected</div>
                                                        <input
                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                            style="visibility:hidden;" value=""
                                                            autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false"
                                                            aria-readonly="false">
                                                    </th>
                                                       
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox" binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined"
                                                                                            value="undefined"></div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                                <span
                                                                                     class="account-button" >{{product.receiptId}}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.productId}}
                                                                        </td>
                                                                        

                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.shipmentId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.shipmentItemSeqId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.shipmentPackageSeqId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.orderId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.orderItemSeqId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.returnId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.returnItemSeqId}}
                                                                        </td>

                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.rejectionId}}
                                                                        </td>

                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.receivedByUserLoginId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.datetimeReceived}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.itemDescription}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.quantityAccepted}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.quantityRejected}}
                                                                        </td>
                                                                        

                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <!-- <p class="paginate_data">
                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                            <p class="paginate_data">
                                                                View per page </p>

                                                        </div>
                                                    </ng-container>
                                                </div>
                                                
                                            </div>
                                            <div *ngIf="activeCategory==3">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Inventory
                                                    </h4>
                                                </div>
                                                <br>
                                                <div class="panel-body">
                                                    
                                                    <ng-container >
                                                        <ng-container >
                                                            <form [formGroup]="inventoryItemLablesForm">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInputT">
                                                                                <label for="exampleInputEmail1">Inventory Item Label ID	
                                                                                    </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [options]="inventoryItemLabelIdArray"
                                                                                    optionlabel="label"
                                                                                    formControlName="inventoryItemLabelID"
                                                                                    placeholder="Select Inventory Item Label ID">
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInputBA">
                                                                                <label for="exampleInputEmail1">Sequence Num	
                                                                                    </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                formControlName="sequenceNum"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Sequence Num">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                 
                                                                </div>
                                                            </form>
                                                            <div class="col-lg-12 col-12 main-submit-button " style="
                                                            margin-left: -20%; margin-bottom: 6%!important;">
                                                                <button type="submit" (click)="createInventoryItemLabelApplFromItem()"
                                                                    class="btn btn-secondary submit-btn"
                                                                    >Create</button>
                                                            </div>
                                                            <ng-container>
                                                                <div class="card own-account-table mt-2">
                                                                    <p-table [value]="getinventoryItemLabel" [paginator]="true"
                                                                        [rows]="rows" scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                                Inventory Item Label Type ID	
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;"> </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                              
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">  	Sequence Num </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Action</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'190px'}"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox" binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined"
                                                                                                    value="undefined"></div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                        <span
                                                                                             class="account-button" 
                                                                                            >{{product.inventoryItemLabelTypeId}} [{{product.inventoryItemLabelTypeId}}]</span>
                                                                                        <!-- (click)="scroll('inventoryItem',true,'collapseFive')" -->
                                                                                    </div>
                                                                                </td>

                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.inventoryItemLabelId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.sequenceNum}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    <button type="submit"   data-toggle="modal" data-target="#updateLabelApplicabilityPopup"
                                                                                    (click)="updateInventoryItemLabelPatch(product)"  class="btn btn-secondary submit-btn"
                                                                                        >Update
                                                                                </button>

                                                                                    <button type="submit" (click)="deleteInventoryItemLabelApplFromItem(product)"
                                                                                    class="btn btn-danger ml-3"
                                                                                    >Delete
                                                                                </button>
                                                                                </td>

                                                                                
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <!-- <p class="paginate_data">
                                                                                                                                                                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                    <p class="paginate_data">
                                                                        View per page </p>

                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                        
                                                    </ng-container>
                                                </div>
                                            </div>
                                         
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <!-- <div class="main-submit-button">
                <button type="submit" class="btn btn-secondary submit-btn">Create Lead</button>
            </div> -->
        </div>
    </div>
</div>

<div class="modal fade" id="updateLabelApplicabilityPopup" tabindex="-1" role="dialog" aria-labelledby="updateLabelApplicabilityPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Label Applicability


                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Update Label Applicability
                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateLableAppllicablityForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                            Inventory Item Label Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" readonly formControlName="inventoryItemLabelID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Inventory Item Label Type ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Sequence Num</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="sequenceNum" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                               
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            

                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="updateInventoryItemLabelApplFromItem()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="updateInventoryItemPopup" tabindex="-1" role="dialog" aria-labelledby="updateInventoryItemPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Inventory Item


                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Update Inventory Item
                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <form [formGroup]="inventoryItemForm">
                                                <div class="row">

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                            margin-left: -7%;
                                                        ">
                                                                <label for="exampleInputEmail1">Inventory Item ID	
                                                                    </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control"
                                                                id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                formControlName="inventoryItemID" placeholder="Enter Inventory Item ID">
                                                            </div>
                                                           
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                            margin-left: -7%;
                                                        ">
                                                                <label for="exampleInputEmail1">Inventory Item Type
                                                                    ID</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <ng-select class="ng-select-width"
                                                                [items]="inventoryItemTypeIds"
                                                                formControlName="inventoryItemTypeId"
                                                                bindLabel="description"
                                                                placeholder="Select Inventory Item Type"
                                                                bindValue="inventoryItemTypeId">
                                                            </ng-select>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                           
                                                        ">
                                                                <label for="exampleInputEmail1">Product ID <span
                                                                    class="text-danger">*</span></label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <ng-select class="ng-select-width"
                                                                [items]="products" formControlName="productId"
                                                                bindLabel="productId" bindValue="productId"
                                                                placeholder="Select Product ID">
                                                            </ng-select>
                                                            <small class="validate-field"
                                                                *ngIf="!!inventoryItemForm.controls.productId.invalid && !!inventoryItemForm.controls.productId.touched">
                                                                Product ID is required!</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                 
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                            margin-left: -7%;
                                                        ">
                                                                <label for="exampleInputEmail1">Party ID</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <ng-select class="ng-select-width"
                                                                [items]="partyIds" formControlName="partyId"
                                                                bindLabel="partyId" bindValue="partyId"
                                                                placeholder="Select Party ID">
                                                            </ng-select>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                           
                                                        ">
                                                                <label for="exampleInputEmail1">Owner Party ID
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <ng-select class="ng-select-width"
                                                                [items]="partyIds"
                                                                formControlName="ownerPartyId"
                                                                bindLabel="partyId" bindValue="partyId"
                                                                placeholder="Select Owner Party ID">
                                                            </ng-select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                  
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                            margin-left: -7%;
                                                        ">
                                                                <label for="exampleInputEmail1">Status ID
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <ng-select class="ng-select-width"
                                                                [items]="statusIds" formControlName="statusId"
                                                                bindLabel="description" bindValue="statusId"
                                                                placeholder="Select Status Id">
                                                            </ng-select>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                          
                                                        ">
                                                                <label for="exampleInputEmail1">Date Time Received
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-calendar formControlName="datetimeReceived">
                                                                </p-calendar>
                                                            </div>
                                                        </div>
                                                    </div>
                                                  
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                            margin-left: -7%;
                                                        ">
                                                                <label for="exampleInputEmail1">Date Time
                                                                    Manufactured
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-calendar formControlName="datetimeManufactured">
                                                                </p-calendar>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                           
                                                        "> 
                                                                <label for="exampleInputEmail1">Expire Date
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-calendar formControlName="expireDate">
                                                                </p-calendar>
                                                            </div>
                                                        </div>
                                                    </div>
                                                  
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                            margin-left: -7%;
                                                        ">
                                                                <label for="exampleInputEmail1">Facility ID
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <ng-select class="ng-select-width"
                                                                [items]="facilityIds"
                                                                formControlName="facilityId"
                                                                bindLabel="facilityId" [(ngModel)]="facilityId"
                                                                bindValue="facilityId" placeholder="Select Facility ID">
                                                            </ng-select>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                          
                                                        ">
                                                                <label for="exampleInputEmail1">Container ID
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control"
                                                                id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                formControlName="containerId" placeholder="Container ID">
                                                            </div>
                                                        </div>
                                                    </div>
                                                  
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                            margin-left: -7%;
                                                        ">
                                                                <label for="exampleInputEmail1">LOT ID
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control"
                                                                formControlName="lotId" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" placeholder="LOT ID">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                          
                                                        ">
                                                                <label for="exampleInputEmail1">UOM ID
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control"
                                                                formControlName="uomId" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" placeholder="UOM ID">
                                                            </div>
                                                        </div>
                                                    </div>
                                                 
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                            margin-left: -7%;
                                                        ">
                                                                <label for="exampleInputEmail1">Bin Number
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control"
                                                                formControlName="binNumber"
                                                                id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" placeholder="Bin Number">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                          
                                                        ">
                                                                <label for="exampleInputEmail1">Facility Location
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <ng-select class="ng-select-width"
                                                                [items]="facilityLocations"
                                                                formControlName="locationSeqId"
                                                                bindLabel="locationSeqId"
                                                                placeholder="Facility Location"
                                                                bindValue="locationSeqId">
                                                            </ng-select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                 
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                            margin-left: -7%;
                                                        ">
                                                                <label for="exampleInputEmail1">Comments
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control"
                                                                formControlName="comments"
                                                                id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" placeholder="Comments">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                           
                                                        "> 
                                                                <label for="exampleInputEmail1">Accounting Quantity
                                                                    Total
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control"
                                                                formControlName="accountingQuantityTotal"
                                                                id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" placeholder="Accounting Quantity
                                                                Total">
                                                            </div>
                                                        </div>
                                                    </div>
                                                  
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                            margin-left: -7%;
                                                        ">
                                                                <label for="exampleInputEmail1">Serial Number
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control"
                                                                formControlName="serialNumber"
                                                                id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" placeholder="Serial Number">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                          
                                                        ">
                                                                <label for="exampleInputEmail1">Soft Identifier
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control"
                                                                formControlName="softIdentifier"
                                                                id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" placeholder="Soft Identifier">
                                                            </div>
                                                        </div>
                                                    </div>
                                                 
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                            margin-left: -7%;
                                                        ">
                                                                <label for="exampleInputEmail1">Activation Number
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control"
                                                                formControlName="activationNumber"
                                                                id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" placeholder="Activation Number">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInputA" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                         
                                                        ">
                                                                <label for="exampleInputEmail1">Activation Valid
                                                                    Through
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-calendar formControlName="activationValidThru">
                                                                </p-calendar>
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3  form-group classInput" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                            margin-left: -7%;
                                                        ">
                                                                <label for="exampleInputEmail1">Unit Cost
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control"
                                                                formControlName="unitCost"
                                                                id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" placeholder="Unit Cost">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                          
                                                        ">
                                                                <label for="exampleInputEmail1">Currency
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <ng-select class="ng-select-width"
                                                                [items]="currencyIds"
                                                                formControlName="currencyUomId"
                                                                placeholder="Currency"
                                                                bindLabel="uomId" bindValue="uomId">
                                                            </ng-select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                 
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                            margin-left: -7%;
                                                        ">
                                                                <label for="exampleInputEmail1">Fixed Asset ID
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control"
                                                                formControlName="fixedAssetId"
                                                                id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" placeholder="Fixed Asset ID">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12"  *ngIf="editMode">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                            margin-left: -7%;
                                                        ">
                                                                <label for="availableToPromise">Available To
                                                                    Promise</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control" placeholder="Available To Promise"
                                                                [value]="inventoryItem?.availableToPromiseTotal"
                                                                readOnly>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA" style="    text-align: right!important;
                                                            padding-right: 17px!important;
                                                         
                                                        ">
                                                                <label for="quantityOnHand">Quantity On
                                                                    Hand</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                [value]="inventoryItem?.accountingQuantityTotal"
                                                                placeholder="Quantity On
                                                                Hand"
                                                                readOnly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                 

                                                </div>
                                            </form>
                                            <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -22%;">
                                                <button type="submit" 
                                                    class="btn btn-secondary submit-btn update-button">Update</button>

                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
