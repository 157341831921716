<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12 pad_0" style="margin-left: -1%!important;">
            
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Partners</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="mt-2" style="padding-bottom: 70px;">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100">
                            <div class="w3-card-4 classCard w-100 border_wdth_1">
                                <div
                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    <p class="h-text color-grey">Find Partners</p>
                                    <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal"
                                        data-target="#createPartnerPopup">Create New Partner</button>
                                </div>
                                <div class="bg-white color-grey">
                                    <div class="create-new-leade">
                                        <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians tabClass"
                                                        style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 8px !important;">
                                                        <li [ngClass]="activeCategory==1?'active':''"
                                                            (click)="changeactiveCategory(1)">
                                                            <a>Name and ID</a>
                                                        </li>
                                                        <li [ngClass]="activeCategory==2?'active':''"
                                                            (click)="changeactiveCategory(2)">
                                                            <a>Phone</a>
                                                        </li>
                                                        <li [ngClass]="activeCategory==3?'active':''"
                                                            (click)="changeactiveCategory(3)">
                                                            <a>E-mail</a>
                                                        </li>
                                                        <li [ngClass]="activeCategory==4?'active':''"
                                                            (click)="changeactiveCategory(4)">
                                                            <a>Advanced</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                                    <div *ngIf="activeCategory==1" class="w-100">

                                                        <div class="w3-card-4">
                                                            <div class="accordion classCard" id="myAccordion">
                                                                <div class="accordion-item">
                                                                    <h2 class="accordion-header" id="headingOne">
                                                                        <div
                                                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="background:#F5F7FA!important; padding: 4px !important;">
                                                                        <span style="margin-left: 12px!important;"> Name and ID</span>
                                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 82%!important; padding-top: 11px !important;
                                                                        padding-bottom: 11px !important;"></button>									
        
                                                                    </div>
        
                                                                    </h2>
                                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                        <div class="card-body">
                                                                            
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Partner
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control"
                                                                                                [(ngModel)]="findByAdvanced.partnerID"
                                                                                                [ngModelOptions]="{standalone:true}"
                                                                                                id="exampleInputEmail1"
                                                                                                name="partnerID"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Partner ID">
                                                                                        </div>
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Partner
                                                                                                Name</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control"
                                                                                                [(ngModel)]="findByAdvanced.partnerName"
                                                                                                [ngModelOptions]="{standalone:true}"
                                                                                                id="exampleInputEmail1"
                                                                                                name="partnerName"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Partner Name">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-lg-8 main-submit-button">
                                                                                    <button type="submit"
                                                                                        class="btn btn-secondary submit-btn"
                                                                                        (click)="findByAdvancePartner()">Find</button>
                                                                                    <button type="submit"
                                                                                        class="btn btn-danger ml-2"
                                                                                        (click)="reset()">Reset</button>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            
                                                            </div>
                                                        </div>
                                                        <!-- <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA !important;">
                                                                <h4 class=" common-styling hleft">
                                                                    Name and ID
                                                                </h4>
                                                            </div>
                                                            <form>
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label for="exampleInputEmail1">Partner
                                                                                    ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="findByAdvanced.partnerID"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1"
                                                                                    name="partnerID"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Partner ID">
                                                                            </div>
                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label for="exampleInputEmail1">Partner
                                                                                    Name</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="findByAdvanced.partnerName"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1"
                                                                                    name="partnerName"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Partner Name">
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-8 main-submit-button">
                                                                        <button type="submit"
                                                                            class="btn btn-secondary submit-btn"
                                                                            (click)="findByAdvancePartner()">Find</button>
                                                                        <button type="submit"
                                                                            class="btn btn-danger ml-2"
                                                                            (click)="reset()">Reset</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div> -->
                                                    </div>
                                                    <div *ngIf="activeCategory==2" class="w-100">
                                                        
                                                        <div class="w3-card-4">
                                                            <div class="accordion classCard" id="myAccordion">
                                                                <div class="accordion-item">
                                                                    <h2 class="accordion-header" id="headingOne">
                                                                        <div
                                                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="background:#F5F7FA!important; padding: 4px !important;">
                                                                        <span style="margin-left: 12px!important;"> Phone</span>
                                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 88%!important; padding-top: 11px !important;
                                                                        padding-bottom: 11px !important;"></button>									
        
                                                                    </div>
        
                                                                    </h2>
                                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                        <div class="card-body">
                                                                            
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Phone
                                                                                                Number</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control"
                                                                                                [(ngModel)]="findByAdvanced.phone1"
                                                                                                placeholder="Enter Phone Number"
                                                                                                [ngModelOptions]="{standalone:true}"
                                                                                                id="exampleInputEmail1"
                                                                                                name="phone1"
                                                                                                aria-describedby="emailHelp">
                                                                                        </div>
                                                                                        <!-- <div class="col-lg-1">
                                                                                            <input type="text" class="form-control" [(ngModel)]="findByPhone.phone2" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="phone2" aria-describedby="emailHelp" >
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control" [(ngModel)]="findByPhone.phone3" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="phone3" aria-describedby="emailHelp" >
                                                                                        </div> -->
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 main-submit-button">
                                                                                    <button type="submit"
                                                                                        class="btn btn-secondary submit-btn"
                                                                                        (click)="findByAdvancePartner()">Find</button>
                                                                                    <button type="submit"
                                                                                        class="btn btn-danger ml-2"
                                                                                        (click)="reset()">Reset</button>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            
                                                            </div>
                                                        </div>

                                                        <!-- <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA !important;">
                                                                <h4 class=" common-styling hleft">
                                                                    Phone
                                                                </h4>
                                                            </div>
                                                            <form>
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label for="exampleInputEmail1">Phone
                                                                                    Number</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="findByAdvanced.phone1"
                                                                                    placeholder="Enter Phone Number"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1"
                                                                                    name="phone1"
                                                                                    aria-describedby="emailHelp">
                                                                            </div>
                                                                            <div class="col-lg-1">
                                                                                <input type="text" class="form-control" [(ngModel)]="findByPhone.phone2" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="phone2" aria-describedby="emailHelp" >
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control" [(ngModel)]="findByPhone.phone3" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="phone3" aria-describedby="emailHelp" >
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 main-submit-button">
                                                                        <button type="submit"
                                                                            class="btn btn-secondary submit-btn"
                                                                            (click)="findByAdvancePartner()">Find</button>
                                                                        <button type="submit"
                                                                            class="btn btn-danger ml-2"
                                                                            (click)="reset()">Reset</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div> -->
                                                    </div>
                                                    <div *ngIf="activeCategory==3" class="w-100">

                                                        <div class="w3-card-4">
                                                            <div class="accordion classCard" id="myAccordion">
                                                                <div class="accordion-item">
                                                                    <h2 class="accordion-header" id="headingOne">
                                                                        <div
                                                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="background:#F5F7FA!important; padding: 4px !important;">
                                                                        <span style="margin-left: 12px!important;"> E-mail</span>
                                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 86%!important; padding-top: 11px !important;
                                                                        padding-bottom: 11px !important;"></button>									
        
                                                                    </div>
        
                                                                    </h2>
                                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                        <div class="card-body">
                                                                            
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">E-mail</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control"
                                                                                                [(ngModel)]="findByAdvanced.email"
                                                                                                [ngModelOptions]="{standalone:true}"
                                                                                                id="exampleInputEmail1" name="email"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter E-mail ID">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 main-submit-button">
                                                                                    <button type="submit"
                                                                                        class="btn btn-secondary submit-btn"
                                                                                        (click)="findByAdvancePartner()">Find</button>
                                                                                    <button type="submit"
                                                                                        class="btn btn-danger ml-2"
                                                                                        (click)="reset()">Reset</button>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            
                                                            </div>
                                                        </div>

                                                        <!-- <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA !important;">
                                                                <h4 class=" common-styling hleft">
                                                                    E-mail
                                                                </h4>
                                                            </div>
                                                            <form>
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label
                                                                                    for="exampleInputEmail1">E-mail</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="findByAdvanced.email"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="email"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter E-mail ID">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 main-submit-button">
                                                                        <button type="submit"
                                                                            class="btn btn-secondary submit-btn"
                                                                            (click)="findByAdvancePartner()">Find</button>
                                                                        <button type="submit"
                                                                            class="btn btn-danger ml-2"
                                                                            (click)="reset()">Reset</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div> -->
                                                    </div>
                                                    <div *ngIf="activeCategory==4" class="w-100">
                                                        
                                                        <div class="w3-card-4">
                                                            <div class="accordion classCard" id="myAccordion">
                                                                <div class="accordion-item">
                                                                    <h2 class="accordion-header" id="headingOne">
                                                                        <div
                                                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="background:#F5F7FA!important; padding: 4px !important;">
                                                                        <span style="margin-left: 12px!important;"> Advanced</span>
                                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 86%!important; padding-top: 11px !important;
                                                                        padding-bottom: 11px !important;"></button>									
        
                                                                    </div>
        
                                                                    </h2>
                                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                        <div class="card-body">
                                                                            
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Classification</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2 dropdowns">
                                                                                            <p-dropdown filter="true"
                                                                                                optionlabel="label"
                                                                                                [(ngModel)]="findByAdvanced.classification"
                                                                                                [ngModelOptions]="{standalone:true}"
                                                                                                name="classification"
                                                                                                [options]="classificationBookmarkPartnerArray"
                                                                                                placeholder="Enter Classification">
                                                                                            </p-dropdown>
                                                                                        </div>
            
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label for="exampleInputEmail1">To
                                                                                                Name</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control"
                                                                                                [(ngModel)]="findByAdvanced.toName"
                                                                                                [ngModelOptions]="{standalone:true}"
                                                                                                id="exampleInputEmail1"
                                                                                                name="toName"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter To Name">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
            
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Attention
                                                                                                Name</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control"
                                                                                                [(ngModel)]="findByAdvanced.attentionName"
                                                                                                [ngModelOptions]="{standalone:true}"
                                                                                                id="exampleInputEmail1"
                                                                                                name="attentionName"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Attention Name">
                                                                                        </div>
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Address
                                                                                                Line 1</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control"
                                                                                                [(ngModel)]="findByAdvanced.addressLine1"
                                                                                                [ngModelOptions]="{standalone:true}"
                                                                                                id="exampleInputEmail1"
                                                                                                name="partnerID"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Address Line 1">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">City</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control"
                                                                                                [(ngModel)]="findByAdvanced.city"
                                                                                                [ngModelOptions]="{standalone:true}"
                                                                                                id="exampleInputEmail1" name="city"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter City">
                                                                                        </div>
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Country</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2 dropdowns">
            
            
                                                                                            <p-dropdown filter="true"
                                                                                                optionlabel="label"
                                                                                                [(ngModel)]="findByAdvanced.country"
                                                                                                (ngModelChange)="onCountryChange($event)"
                                                                                                [options]="array"
                                                                                                [ngModelOptions]="{standalone:true}"
                                                                                                name="country"
                                                                                                placeholder="Enter Country">
                                                                                            </p-dropdown>
            
            
            
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
            
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">State/Province</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2 dropdowns">
                                                                                            <p-dropdown filter="true"
                                                                                                optionlabel="label"
                                                                                                [options]="stateArray"
                                                                                                (ngModelChange)="onStateChange($event)"
                                                                                                [(ngModel)]="findByAdvanced.state"
                                                                                                [ngModelOptions]="{standalone:true}"
                                                                                                name="state"
                                                                                                placeholder="Enter State/Province">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Postal
                                                                                                Code</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control"
                                                                                                [(ngModel)]="findByAdvanced.postalCode"
                                                                                                [ngModelOptions]="{standalone:true}"
                                                                                                id="exampleInputEmail1"
                                                                                                name="postalCode"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Postal Code">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
            
                                                                                <div class="col-lg-8 main-submit-button">
                                                                                    <button type="submit"
                                                                                        class="btn btn-secondary submit-btn"
                                                                                        (click)="findByAdvancePartner()">Find</button>
                                                                                    <button type="submit"
                                                                                        class="btn btn-danger ml-2"
                                                                                        (click)="reset()">Reset</button>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            
                                                            </div>
                                                        </div>

                                                        <!-- <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA !important;">
                                                                <h4 class=" common-styling hleft">
                                                                    Advanced
                                                                </h4>
                                                            </div>
                                                            <form>
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label
                                                                                    for="exampleInputEmail1">Classification</label>
                                                                            </div>
                                                                            <div class="col-lg-2 dropdowns">
                                                                                <p-dropdown filter="true"
                                                                                    optionlabel="label"
                                                                                    [(ngModel)]="findByAdvanced.classification"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    name="classification"
                                                                                    [options]="classificationBookmarkPartnerArray"
                                                                                    placeholder="Enter Classification">
                                                                                </p-dropdown>
                                                                            </div>

                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label for="exampleInputEmail1">To
                                                                                    Name</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="findByAdvanced.toName"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1"
                                                                                    name="toName"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter To Name">
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label
                                                                                    for="exampleInputEmail1">Attention
                                                                                    Name</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="findByAdvanced.attentionName"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1"
                                                                                    name="attentionName"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Attention Name">
                                                                            </div>
                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label for="exampleInputEmail1">Address
                                                                                    Line 1</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="findByAdvanced.addressLine1"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1"
                                                                                    name="partnerID"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Address Line 1">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label
                                                                                    for="exampleInputEmail1">City</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="findByAdvanced.city"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="city"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter City">
                                                                            </div>
                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label
                                                                                    for="exampleInputEmail1">Country</label>
                                                                            </div>
                                                                            <div class="col-lg-2 dropdowns">


                                                                                <p-dropdown filter="true"
                                                                                    optionlabel="label"
                                                                                    [(ngModel)]="findByAdvanced.country"
                                                                                    (ngModelChange)="onCountryChange($event)"
                                                                                    [options]="array"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    name="country"
                                                                                    placeholder="Enter Country">
                                                                                </p-dropdown>



                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label
                                                                                    for="exampleInputEmail1">State/Province</label>
                                                                            </div>
                                                                            <div class="col-lg-2 dropdowns">
                                                                                <p-dropdown filter="true"
                                                                                    optionlabel="label"
                                                                                    [options]="stateArray"
                                                                                    (ngModelChange)="onStateChange($event)"
                                                                                    [(ngModel)]="findByAdvanced.state"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    name="state"
                                                                                    placeholder="Enter State/Province">
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label for="exampleInputEmail1">Postal
                                                                                    Code</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="findByAdvanced.postalCode"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1"
                                                                                    name="postalCode"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Postal Code">
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-8 main-submit-button">
                                                                        <button type="submit"
                                                                            class="btn btn-secondary submit-btn"
                                                                            (click)="findByAdvancePartner()">Find</button>
                                                                        <button type="submit"
                                                                            class="btn btn-danger ml-2"
                                                                            (click)="reset()">Reset</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="header-tabs" >
                                                    <h4 class=" common-styling h4Margin">
                                                        Partner List
                                                    </h4>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="AdvancePartnerList" [paginator]="true"
                                                                    [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Partner ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Partner Name
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    City
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    State/Province
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Phone Number
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    E-Mail Address
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <div
                                                                                        *ngIf="product.partnerData">
                                                                                        <span
                                                                                        class="account-button"
                                                                                            (click)="detailPage(product.partnerData[0].partnerId)">
                                                                                            {{product.partnerData[0].partnerId}}
                                                                                        </span>
                                                                                    </div>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}"
                                                                                *ngIf="product.partnerData">
                                                                                {{product.partnerData[0].partnerName}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}"
                                                                                *ngIf="product.partnerAddress">
                                                                                {{product.partnerAddress[0].city}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}"
                                                                                *ngIf="product.partnerAddress">
                                                                                {{product.partnerAddress[0].stateProvinceGeoId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}"
                                                                                *ngIf="product.partnerPhoneNumber">
                                                                                {{product.partnerPhoneNumber[0].contactNumber}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}"
                                                                                *ngIf="product.partnerEmail">
                                                                                {{ product.partnerEmail[0].emailAddress}}
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No
                                                                                        Record
                                                                                        Found
                                                                                    </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="createPartnerPopup" tabindex="-1" role="dialog" aria-labelledby="createPartnerPopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Create Partner</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Partner</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createPartner">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Partner Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="partnerName"
                                                            placeholder="Enter Partner Name">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Local Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="localName"
                                                            placeholder="Enter Local Name">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Site Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="siteName"
                                                            placeholder="Enter Site Name">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Annual Revenue</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="annualRevenue"
                                                            placeholder="Enter Annual Revenue">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Preferred Currency</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="preferredCurrency" [options]="currencyList"
                                                            placeholder="Enter Preferred Currency">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Industry</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="industry" [options]="industryListArray"
                                                            placeholder="Enter Industry">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Number of Employees</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="numberOfEmployees"
                                                            placeholder="Enter Number of Employees">
                                                    </div>

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Ownership</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="ownership" [options]="ownerShipArray"
                                                            placeholder="Enter Ownership">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">SIC Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="sitCode"
                                                            placeholder="Enter SIT Code">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Ticker Symbol</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="tickerSymbol"
                                                            placeholder="Enter Ticker Symbol">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Country Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="countryCode"
                                                            placeholder="Enter Country Code">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Area Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="areaCode"
                                                            placeholder="Enter Area Code">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Phone Number</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="phoneNumber"
                                                            placeholder="Enter Phone Number">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Extension</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="extension"
                                                            placeholder="Enter Extension">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Person to Ask For
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="personToAskFor"
                                                            placeholder="Enter Person To Ask For">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">E-mail Address
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="emailAddress"
                                                            placeholder="Enter E-mail Address">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Web URL
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="webURL"
                                                            placeholder="Enter Web URL">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">To Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="toName"
                                                            placeholder="Enter To Name">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Attention Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="attentionName"
                                                            placeholder="Enter Attention Name">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Address Line 1
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="addressLine1"
                                                            placeholder="Enter Address Line 1">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Address Line 2
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="addressLine2"
                                                            placeholder="Enter Address Line 2">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">City
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="city"
                                                            placeholder="Enter City">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">State/Province
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="stateArray" formControlName="state"
                                                            placeholder="Enter State/Province">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Zip/Postal Code
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="zipCode"
                                                            placeholder="Enter Zip Code">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Country
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="array"
                                                            formControlName="country" placeholder="Enter Country">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Zip/Postal Code Extension
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="zipExtension"
                                                            placeholder="Enter Zip/Postal Code Extension">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-8  main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="createPartners()">Create</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>