import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-agreement-item',
  templateUrl: './create-agreement-item.component.html',
  styleUrls: ['./create-agreement-item.component.css']
})
export class CreateAgreementItemComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  additionalItems(){
    this.router.navigateByUrl('/additional-items');
  }
}
