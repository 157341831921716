
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CreateEventModel } from './create-event-model';

@Injectable({
  providedIn: 'root'
})


export class CreateEventFormService {
  create_event_form: FormGroup;


  constructor(public _formBuilder: FormBuilder,
    public createEventModel: CreateEventModel
  ) { }

  createEventForm(data: any): void {
    if (!this.create_event_form) {
      this.create_event_form = this._formBuilder.group(this.createEventModel.mapDataToModel(data));

    }

  }

}


