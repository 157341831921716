import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';   import { Location } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css']
})
export class TasksComponent implements OnInit {
  activeCategory = 4;
  total = 0;
  rowNumber = 0;
  pageSize = 10;
  rows = 50;
  addNewTaskForm: any;
  AssignTo: any;
  AssignToArray: any[] = [];
  RoleTypeList: any;
  UnplannedListArray: any[] = [];
  UnplannedList: any;
  StatusListArray: any[] = [];
  StatusList: any;
  RoleTypeArray: any[] = [];
  ProjectIDArray: any[] = [];
  ProjectID: any;
  uploadRes:any;
  SprintBacklogList: any;
  SprintBacklogListArray: any[] = [];
  advanceSearch: { TaskID: string; TaskName: string; TaskType: string; Unplanned: string; Status: string; AssignedTo: string; SprintName: string; SprintID: string; ProjectID: string; ProjectName: string; };
  findTaskTable: any;
  findTaskList: any;
  ProjectMemberList: any;
  partyId: any;
  StatusArray: { label: string; value: string; }[];
  taskStatus: any;
  PartyTimesheetId: any;
  PartyTimesheetIdArray: any[]=[];
  partyIdLength: string;
  custRequestIdLength: any;
  SCRUM_TASK_ERROR: boolean;
  SCRUM_TASK_IMPL: boolean;
  SCRUM_TASK_INST: boolean;
  SCRUM_TASK_TEST: boolean;
  fileData: File;
  constructor(
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly myContactsService: MyContactsService,
    readonly accountsService: AccountsService,
    readonly addSkillService: AddSkillService,
    readonly _Router: Router,    
    private _location: Location,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,
  ) {


    this.addNewTaskForm = this._FormBuilder.group({
      ProjectID: [''],
      TaskType: [''],
      TaskName: [''],
      SprintID: [''],
      SprintBacklog: [''],
      PlanHours: [''],
      Uploaded: [''],
      Description: [''],
      AssignTo: [''],
    });
    this.advanceSearch = {

      TaskID: "",
      TaskName: "",
      TaskType: "",
      Unplanned: "",
      Status: "",
      AssignedTo: "",
      SprintName: "",
      SprintID: "",
      ProjectID: "",
      ProjectName: "",

    };
    this.StatusArray = [

      {
        label: 'Created',
        value: 'STS_CREATED'
      },
      {
        label: 'Completed',
        value: 'STS_COMPLETED'
      },
      {
        label: 'On Hold',
        value: 'STS_ON_HOLD'
      },
      {
        label: 'Cancelled',
        value: 'STS_CANCELLED'
      },
    ];
   
  }

  ngOnInit(): void {
    this.getRoleTypeList();
    this.getUnplannedList();
    this.getStatusTaskList();
    this.getProjectID();
    this.getSprintBacklogList();
    this.getAssignToList();
    this.findTask();
    this.getPartyTimesheet();
  }
  detailedOverviewPage( internalName, productId) {
    this._Router.navigate(["/psa/detail-sprint-project"], { queryParams: { internalName:internalName, productId: productId } })

  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  detailTaskOverviewPage(workEffortName,partyId, workEffortId, custRequestId,activeCategory) {
    if(partyId.length==0){
      this.partyIdLength = "";
    }
    else{
      this.partyIdLength=partyId[0].partyId ;
    }

    if(custRequestId.length==0){ 
      this.custRequestIdLength = ""; 
    }
    else{ 
      this.custRequestIdLength = custRequestId[0].custRequestId ; 
    } 
    
    this._Router.navigate(["/psa/detail-tasks"],
      { queryParams: { workEffortName:workEffortName, partyId : this.partyIdLength ,workEffortId: workEffortId, custRequestId: this.custRequestIdLength,activeCategory:"2"} })
  }

  detailTaskPage(workEffortName,partyId, workEffortId, custRequestId ,activeCategory) {
    if(partyId.length==0){
      this.partyIdLength = "";
    }
    else{
      this.partyIdLength=partyId[0].partyId ;
    }

    if(custRequestId.length==0){ 
      this.custRequestIdLength = ""; 
    }
    else{ 
      this.custRequestIdLength = custRequestId[0].custRequestId ; 
    } 
    
    this._Router.navigate(["/psa/detail-tasks"],
      { queryParams: { workEffortName:workEffortName, partyId : this.partyIdLength ,workEffortId: workEffortId, custRequestId: this.custRequestIdLength,activeCategory:"2"} })
  }
  
  getPartyTimesheet() {
    this.spinner.show();
    this.accountsService.getPartyTimesheet().subscribe(res => {
      this.PartyTimesheetId = res.data[0].Party;
      this.spinner.hide();
      for (const value of this.PartyTimesheetId) {
        this.PartyTimesheetIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
 
  reset() {
    this.spinner.show();
    this.advanceSearch.TaskID = "";
    this.advanceSearch.TaskName = "";
    this.advanceSearch.TaskType = "";
    this.advanceSearch.Unplanned = "";
    this.advanceSearch.Status = "";
    this.advanceSearch.AssignedTo = "";
    this.advanceSearch.SprintName = "";
    this.advanceSearch.SprintID = "";
    this.advanceSearch.ProjectID = "";
    this.advanceSearch.ProjectName = "";
    this.findTaskList = []

    const req = {
      "assignedTo": "",
      "projectId": "",
      "projectName": "",
      "sprintId": "",
      "sprintName": "",
      "status": "",
      "taskId": "",
      "taskName": "",
      "taskType": "",
      "unplanned": ""
    }
    this.accountsService.findTask(req).subscribe((res: any) => {
      this.findTaskList = res.data;
      if(this.advanceSearch.TaskType==""){
        this.SCRUM_TASK_ERROR = true;
        this.SCRUM_TASK_IMPL = true;
        this.SCRUM_TASK_INST = true;
        this.SCRUM_TASK_TEST = true;
      }
      this.spinner.hide();
    })
    this.spinner.hide();
  }

  TaskFind() {
    this.spinner.show();
    this.findTaskList = [];
    const req = {
      "status": this.advanceSearch.Status ? this.advanceSearch.Status : '',
      "taskId": this.advanceSearch.TaskID ? this.advanceSearch.TaskID : '',
      "projectName": this.advanceSearch.ProjectName ? this.advanceSearch.ProjectName : '',
      "taskType": this.advanceSearch.TaskType ? this.advanceSearch.TaskType : '',
      "assignedTo": this.advanceSearch.AssignedTo ? this.advanceSearch.AssignedTo : '',
      "sprintId": this.advanceSearch.SprintID ? this.advanceSearch.SprintID : '',
      "sprintName": this.advanceSearch.SprintName ? this.advanceSearch.SprintName : '',
      "taskName": this.advanceSearch.TaskName ? this.advanceSearch.TaskName : '',
      "projectId": this.advanceSearch.ProjectID ? this.advanceSearch.ProjectID : '',
      "unplanned": this.advanceSearch.Unplanned ? this.advanceSearch.Unplanned : '',

    }
    this.accountsService.findTask(req).subscribe((res: any) => {
      this.findTaskList = res.data;
      if(this.advanceSearch.TaskType=="SCRUM_TASK_ERROR"){
        this.SCRUM_TASK_ERROR = true;
        this.SCRUM_TASK_IMPL = false;
        this.SCRUM_TASK_INST = false;
        this.SCRUM_TASK_TEST = false;
      }
      else if(this.advanceSearch.TaskType=="SCRUM_TASK_IMPL"){
        this.SCRUM_TASK_ERROR = false;
        this.SCRUM_TASK_IMPL = true;
        this.SCRUM_TASK_INST = false;
        this.SCRUM_TASK_TEST = false;
      }
      else if(this.advanceSearch.TaskType=="SCRUM_TASK_INST"){
        this.SCRUM_TASK_ERROR = false;
        this.SCRUM_TASK_IMPL = false;
        this.SCRUM_TASK_INST = true;
        this.SCRUM_TASK_TEST = false;
      }
      else if(this.advanceSearch.TaskType=="SCRUM_TASK_TEST"){
        this.SCRUM_TASK_ERROR = false;
        this.SCRUM_TASK_IMPL = false;
        this.SCRUM_TASK_INST = false;
        this.SCRUM_TASK_TEST = true;
      }
      else{
        this.SCRUM_TASK_ERROR = true;
        this.SCRUM_TASK_IMPL = true;
        this.SCRUM_TASK_INST = true;
        this.SCRUM_TASK_TEST = true;
      }
       this.taskStatus = res.data.tasks.workEffortTypeId;
       this.spinner.hide();
    })
    this.spinner.hide();
  }

  findTask() {
    this.spinner.show();
    const req = {

      "assignedTo": "",
      "projectId": "",
      "projectName": "",
      "sprintId": "",
      "sprintName": "",
      "status": "",
      "taskId": "",
      "taskName": "",
      "taskType": "",
      "unplanned": ""
    }
    this.accountsService.findTask(req).subscribe((res: any) => {
      this.findTaskList = res.data;
      this.spinner.hide();

    })
  }
  getAssignToList() {
    this.spinner.show();
    this.accountsService.getAssignToList().subscribe(res => {
      this.AssignTo = res.data.data;
      this.spinner.hide();
      for (const value of this.AssignTo) {
        this.AssignToArray.push({
          label: value.scrumTeamValue,
          value: value.scrumTeamName
        })
      }
    })
  }
  getProjectID() {
    this.spinner.show();
    this.accountsService.getProjectID().subscribe(res => {
      this.ProjectID = res.data;
      this.spinner.hide();
      for (const value of this.ProjectID) {
        this.ProjectIDArray.push({
          label: value.ProjectID.workEffortName,
          value: value.ProjectID.workEffortName
        })
      }
    })
  }

  getSprintBacklogList() {
    this.spinner.show();
    this.accountsService.getSprintBacklogList().subscribe(res => {
      this.SprintBacklogList = res.data;
      this.spinner.hide();
      for (const value of this.SprintBacklogList) {
        this.SprintBacklogListArray.push({
          label: value.description,
          value: value.custRequestId
        })
      }
    })
  }


  getUnplannedList() {
    this.spinner.show();
    this.accountsService.getUnplannedList().subscribe(res => {
      this.UnplannedList = res.data;
      this.spinner.hide();
      {
        this.UnplannedListArray.push({
          label: this.UnplannedList.Y,
          value: this.UnplannedList.Y
        })

        this.UnplannedListArray.push({
          label: this.UnplannedList.N,
          value: this.UnplannedList.N
        })

      }
    })
  }


 

  getStatusTaskList() {
    this.spinner.show();
    this.accountsService.getStatusTaskList().subscribe(res => {
      this.StatusList = res.data.data;
      this.spinner.hide();
      for (const value of this.StatusList) {
        this.StatusListArray.push({
          label: value.statusDesc,
          value: value.statusValue
        })
      }
    })
  }

  getRoleTypeList() {
    this.spinner.show();
    this.accountsService.getRoleTypeList().subscribe(res => {
      this.RoleTypeList = res.data.data;
      this.spinner.hide();
      for (const value of this.RoleTypeList) {
        this.RoleTypeArray.push({
          label: value.roleTypeDesc,
          value: value.roleTypeValue
        })
      }
    })
  }

  

  onSubmit() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);
    this.accountsService.attachFile(formData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
       // this.toastr.success(res.data.responseMessage);
        this.uploadRes = res.data.URL;
      }
    }, (err) => {
      this.toastr.error(err.message);
      this.spinner.hide();
    })
  }

 fileProgress(fileInput: any) {
  this.fileData = <File>fileInput.target.files[0];
  console.log("file-data_recoed",this.fileData);
}
submit(){
  this.onSubmit();
  if(this.uploadRes){
    setTimeout(() => {
      this.addTask();
     }, 2000);
  }


}
  addTask() {
    this.spinner.show();
    const requestData = {
      "actualHours": "",
      "contentTypeId": "DOCUMENT",
      "currentStatusId": "STS_CREATED",
      "custRequestId": "",
      "description": this.addNewTaskForm.value.Description,
      "mimeTypeId": "application/octet-stream",
      "partyId":  this.addNewTaskForm.value.AssignTo,
      "planHours": this.addNewTaskForm.value.PlanHours,
      "resourceStatusId": "CTNT_PUBLISHED",
      "uploadedFile": this.uploadRes,
      "workEffortContentTypeId": "CREATED_MEDIA",
      "workEffortId": "",
      "workEffortName":this.addNewTaskForm.value.TaskName,
      "workEffortTypeId": this.addNewTaskForm.value.TaskType,
    }

    this.accountsService.createTaskForBacklog(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }

  Backlog() {
    this._Router.navigate(["/psa/product-backlog"])
  }
  Sprint() {
    this._Router.navigate(["/psa/sprints"])
  }
  MyWork() {
    this._Router.navigate(["/psa/my-work"])
  }
  Tasks() {
    this._Router.navigate(["/psa/tasks"])
  }
  OpenTest() {
    this._Router.navigate(["/psa/open-test"])
  }
  Revision() {
    this._Router.navigate(["/psa/find-task-revision"])
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
