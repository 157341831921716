<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Profile of Mr. THE PRIVILEGED ADMINISTRATOR
                    [admin]</span>
                    <span class="">

                        <button type="submit" (click)="homeButton();"
                            class=" btn btn-outline-secondary mr-2">Home</button>
                
                        <button type="submit" (click)="cancelSubmit();" 
                            class="btn btn-danger buttonclass ">Back</button>
                    </span>
                
            </div>

            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="projectMain()">
                                <a>Main</a>
                            </li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="myTasks()">
                                <a>My Tasks</a>
                            </li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="myTime()">
                                <a>My Time</a>
                            </li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="projects()">
                                <a>Projects</a>
                            </li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="tasks()">
                                <a>Tasks</a>
                            </li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="resources()">
                                <a>Resources</a>
                            </li>
                            <li [ngClass]="activeCategory==7?'active':''" (click)="timesheet()">
                                <a>Timesheet</a>
                            </li>
                            <li [ngClass]="activeCategory==8?'active':''" (click)="skillType()">
                                <a>Skill Type</a>
                            </li>
                            <li [ngClass]="activeCategory==9?'active':''" (click)="requestList()">
                                <a>Request List</a>
                            </li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="">
                                        
                                        <div class="w3-card-4  w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                            <span class="headingAccords"> Find Project</span>
                                                           
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalCenter">Create New Project</button>

                                                            <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 74%!important; padding-top: 11px !important;
                                                                padding-bottom: 11px !important;">
                                                            </button>									
                
                                                        </div>
                    
                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                        <div class="card-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormG">
                                                                            <label for="exampleInputEmail1">Project ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control" [(ngModel)]="advanceSearch.ProjectID" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="ProjectID" aria-describedby="emailHelp" placeholder=" Enter Project ID">
                                                                        </div>
            
                                                                        <div class="col-lg-3 form-group rightFormH">
                                                                            <label for="exampleInputEmail1">Project Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control" [(ngModel)]="advanceSearch.ProjectName" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="ProjectName" aria-describedby="emailHelp" placeholder="Enter Project Name">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormG">
                                                                            <label for="exampleInputEmail1">From Date</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" class="form-control" [(ngModel)]="advanceSearch.fromDate" name="fromDate" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                                        </div>
            
                                                                        <!-- <div class="col-lg-3 form-group rightFormH">
                                                                            <label for="exampleInputEmail1">Through Date</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" class="form-control" [(ngModel)]="advanceSearch.throughDate" name="throughDate" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                                        </div> -->
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="col-lg-6  main-submit-button" style="margin-left: 34%;">
                                                                    <button type="submit" (click)="getFindProject()" class="btn btn-secondary submit-btn">Find</button>
                                                                    <button type="submit" (click)="resetFindProject()" class="btn btn-danger ml-2">Reset</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        
                                        <!-- <div class="w3-card-4 classCard w-100">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Find Project</p>
                                                <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalCenter"> New Project</button>
                                            </div>
                                            <form>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group rightFormG">
                                                                <label for="exampleInputEmail1">Project ID</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control" [(ngModel)]="advanceSearch.ProjectID" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="ProjectID" aria-describedby="emailHelp" placeholder=" Enter Project ID">
                                                            </div>

                                                            <div class="col-lg-3 form-group rightFormH">
                                                                <label for="exampleInputEmail1">Project Name</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control" [(ngModel)]="advanceSearch.ProjectName" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="ProjectName" aria-describedby="emailHelp" placeholder="Enter Project Name">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group rightFormG">
                                                                <label for="exampleInputEmail1">From Date</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="date" class="form-control" [(ngModel)]="advanceSearch.fromDate" name="fromDate" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                            </div>

                                                            <div class="col-lg-3 form-group rightFormH">
                                                                <label for="exampleInputEmail1">Through Date</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="date" class="form-control" [(ngModel)]="advanceSearch.throughDate" name="throughDate" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                            </div> 
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-lg-6  main-submit-button" style="margin-left: 34%;">
                                                        <button type="submit" (click)="getFindProject()" class="btn btn-secondary submit-btn">Find</button>
                                                        <button type="submit" (click)="resetFindProject()" class="btn btn-danger ml-2">Reset</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div> -->
                                    </div>
                                    <div>
                                        <div class="w3-card-4 classCard w-100 mt-2 mb-2">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Find Project Results</p>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="projectListData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>

                                                                                </p-checkbox>

                                                                                Project Name
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Status
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Estimated Start Date
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Estimated Completion Date
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Actual Start Date
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Actual Completion Date
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Planned Hours
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Actual Hours
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Created Date
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span  class="account-button"
                                                                                (click)="detailPage(product.workEffortName)">
                                                                                    {{product.workEffortName}}
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.currentStatusId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.estimatedStartDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.estimatedCompletionDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.actualStartDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.actualCompletionDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">

                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">

                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.createdDate|date:'yyyy-MM-dd hh:mm:ss'}}

                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No Record Found </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Edit Project </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Edit Project </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="editProject">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Template ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="TemplateIdArray"
                                                         formControlName="projTempid" placeholder="Choose Template ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputNew">
                                                        <label for="exampleInputEmail1">Project Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" formControlName="projectName" 
                                                        placeholder="Enter Project Name">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                         aria-describedby="emailHelp" formControlName="description" placeholder="Enter Description">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputNew">
                                                        <label for="exampleInputEmail1">Client Billing Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="partyIdArray" optionlabel="label" 
                                                        formControlName="clientBillingID"  placeholder="Choose Client Billing Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Organization Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="OrganizationFindEmploymentArray" 
                                                        optionlabel="label" formControlName="organizationID"  placeholder="Choose Organization Party ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputNew">
                                                        <label for="exampleInputEmail1">Priority
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="PriorityListArray" optionlabel="label" 
                                                        placeholder="Choose Priority" formControlName="priority">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Scope
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="ScopeListArray" optionlabel="label"
                                                        placeholder="Choose Scope"  formControlName="scope">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputNew">
                                                        <label for="exampleInputEmail1">Parent Project Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="ParentProjectNameArray" 
                                                        placeholder="Choose Parent Project Name"   optionlabel="label" 
                                                        formControlName="parentProjectName">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Start Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" formControlName="estimatedStartDate" 
                                                        id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Start Date">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputNew">
                                                        <label for="exampleInputEmail1">Estimated Completion Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" 
                                                        formControlName="estimatedCompletionDate"
                                                         id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                         placeholder="Enter Estimated Completion Date">

                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Mailing List E-mail
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" formControlName="mailingEmail" 
                                                        placeholder="Enter Mailing List E-mail">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6  main-submit-button" style="margin-left: 38%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createProject()">Add</button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>