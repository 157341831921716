import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ProductionService } from '../production.service';
@Component({
  selector: 'app-update-material-used',
  templateUrl: './update-material-used.component.html',
  styleUrls: ['./update-material-used.component.css']
})
export class UpdateMaterialUsedComponent implements OnInit {
  updateMaterial:FormGroup;
  activeCategory=2;
  workEffortId: any;
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly productionService:ProductionService,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    private _location: Location,
    readonly _ToastrService: ToastrService,) { 
      this.updateMaterial = this._FormBuilder.group({
        TaskId: [''],
        productName:[''],
        Quantity: [''],
        available:[''],
        LocationSequenceId:[''],
        SecondaryLocationSequenceId:[''],
        LotId:['']

      
       
       
      });
    
  }
 
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params["workEffortId"];
  
    });
  }
  homeButton(){
    this.router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  // cancelSubmit(){
  //   this._Router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId: this.workEffortId} })
  // }
}
