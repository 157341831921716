import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  baseUrl;
  constructor(
    readonly router: Router,
  ) {
    this.baseUrl = environment.host_api_url;
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req['url'].includes('/api/v1/saas/subscriptionDetail')) {

    }
    else {
      req = req.clone({ url: this.baseUrl + req.url });
      let ok: string;
      const token: string = localStorage.getItem('token');
      const tenantId: string = localStorage.getItem('tenantId');
      if (token) {
        req = req.clone({
          headers: req.headers.set('Authorization', 'Bearer' + ' ' + token)
        });
      }
      req = req.clone({ headers: req.headers.set('Access-Control-Allow-Origin', '*') });
      if (tenantId) {
        req = req.clone({ headers: req.headers.set('tenantId', tenantId) });
      }
    }
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      // if ((event instanceof HttpResponse) && (event.body.httpStatus != 401 && event.status && (event.status === 200 || 201))) {
      //   setTimeout(() => {
      //     // this.appService.showLoader(false);
      //   }, 1000);
      // }
      // else if ((event instanceof HttpResponse) && (event.body.httpStatus === 401)) {
      //   setTimeout(() => {
      //     // this.appService.showLoader(false);
      //     //  localStorage.clear();
      //     //this.router.navigate(["/login"]);
      //   }, 1000);
      // }
    }, (error) => {
      //  this.appService.showLoader(false);
      // if (error.status === 401) {
      //   // localStorage.clear();
      //   // this.router.navigate(["/login"]);
      // } else {
      //   //  return throwError(error);
      // }
    }));
  }
}