<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
                <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                   <span class="color-black pl-1 titlePanels" *ngIf="Person">The Profile of {{Person.personInformation.personalTitle}}
                        {{Person.personInformation.firstName}}
                        {{Person.personInformation.middleName}}
                        {{Person.personInformation.lastName}}
                        {{"[" + this.taxAuthPartyId + "]"}}
                    </span>

 <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
        
            </div>
            <div class="" >
               
                <div class=" bg-white color-grey">
                    <div class="create-new-leade">
                        <div class="container-fluid m-0 p-0">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="w3-card-4 classCardA ">

                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">


                                                        <li [ngClass]="activeCategoryA==1?'active':''" (click)="MyProfile()"><a>
                                                            Profile

                                                        </a></li>
                                                        <li [ngClass]="activeCategoryA==2?'active':''" (click)="Preferences()">
                                                            <a>
                                                            Preferences
                                                        </a>
                                                        </li>
                                                        <li [ngClass]="activeCategoryA==3?'active':''" (click)="Roles()">
                                                            <a>
                                                            Role(s)</a>
                                                        </li>
                                                        <li [ngClass]="activeCategoryA==4?'active':''" (click)="Project()">
                                                            <a>
                                                            Projects
                                                        </a>
                                                        </li>
                                                        <li [ngClass]="activeCategoryA==5?'active':''" (click)="TasksProfile()">
                                                            <a>
                                                            Tasks
                                                        </a>
                                                        </li>
                                                        <li [ngClass]="activeCategoryA==6?'active':''" (click)="Rates()">
                                                            <a>
                                                            Rates
                                                        </a>
                                                        </li>
                                                        <li [ngClass]="activeCategoryA==7?'active':''" (click)="Revisions()">
                                                            <a>
                                                            Revisions
                                                        </a>
                                                        </li>


                                                    </ul>
                                                </div>
                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                    <div class="create-new-leade ">
                                                        <div class="container-fluid">


                                                            <div class="panel-group">


                                                                <div>
                                                                    <div class=" bg-white color-grey">
                                                                        <div class="create-new-leade">
                                                                            <div class="">
                                                                                <div class="row">
                                                                                    <form class="w-100">


                                                                                        <br>
                                                                                        <div class="create-new-leade ">
                                                                                            <div class="container-fluid">
                                                                                                <div class="row">

                                                                                                    <form class="w-100">

                                                                                                        <div>
                                                                                                            <div class="w3-card-4 classCard">
                                                                                                                <div class="container-fluid">
                                                                                                                    <ul class="tabbing-section tabbing-accordians tabClass"
                                                                                                                     style="width: 103% !important;
                                                                                                flex-wrap: wrap !important;
                                                                                                display: flex !important;">
                                                                                                                        <li><a>
                                                                                                                            Add
                                                                                                                            Project



                                                                                                                        </a>
                                                                                                                        </li>

                                                                                                                    </ul>
                                                                                                                </div>
                                                                                                                <form [formGroup]="ratesForm">


                                                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">

                                                                                                                        <div class="col-lg-12">
                                                                                                                            <div class="row">
                                                                                                                                <div class="col-lg-3 form-group classInputC">
                                                                                                                                    <label for="exampleInputEmail1">
                                                                                                                                    Role
                                                                                                                                    Type


                                                                                                                                </label>
                                                                                                                                </div>
                                                                                                                                <div class="col-lg-2">
                                                                                                                                    <p-dropdown filter="true" formControlName="RoleType" [options]="RoleTypeList_profileArray" optionlabel="label" placeholder="Enter 
                                                                                                                                    Role Type ">
                                                                                                                                    </p-dropdown>



                                                                                                                                </div>
                                                                                                                                <div class="col-lg-3 form-group classInputC">
                                                                                                                                    <label for="productId">Through
                                                                                                                                    Date

                                                                                                                                </label>
                                                                                                                                </div>
                                                                                                                                <div class="col-lg-2">
                                                                                                                                    <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="ThroughDate" placeholder="Enter Through Date  ">

                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div class="col-lg-12">
                                                                                                                            <div class="row">
                                                                                                                                <div class="col-lg-3 form-group classInputC">
                                                                                                                                    <label for="productId">From
                                                                                                                                    Date

                                                                                                                                </label>
                                                                                                                                </div>
                                                                                                                                <div class="col-lg-2">
                                                                                                                                    <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="FromDate" placeholder="Enter From Date">

                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                       


                                                                                                                    </div>
                                                                                                                    <div class="col-lg-12  main-submit-button" style="
                                                                                                                                           margin-left: -30%;">
                                                                                                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="addWorkEffortPartyAssign()">Add</button>&nbsp;


                                                                                                                    </div>
                                                                                                                </form>

                                                                                                            </div>

                                                                                                            <div class="w3-card-4 classCard mt-2">
                                                                                                                <header class="w3-container w3-blue">
                                                                                                                    <div class="header-tabs">
                                                                                                                        <h4 class=" common-styling h4Margin">
                                                                                                                            Project List


                                                                                                                        </h4>

                                                                                                                    </div>
                                                                                                                </header>
                                                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                                    <div class="col-lg-12 col-12">
                                                                                                                        <div class="form-group">

                                                                                                                            <div class="card own-account-table">
                                                                                                                                <p-table [value]="listResourcesProjectList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                                                    <ng-template pTemplate="header">
                                                                                                                                        <tr style="background: #0d3769;">
                                                                                                                                            <th [ngStyle]="{'width':'300px'}" pSortableColumn="code">
                                                                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                                            </div>
                                                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                        <!---->
                                                                                                                                                    </p-checkbox>
                                                                                                                                                    Name
                                                                                                                                                </div>
                                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                            </th>
                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                                <div style="color: white;">
                                                                                                                                                    Role Type ID
                                                                                                                                                </div>
                                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                            </th>
                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                                <div style="color: white;">
                                                                                                                                                    Status
                                                                                                                                                </div>
                                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                            </th>
                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                                <div style="color: white;">
                                                                                                                                                    Priority
                                                                                                                                                </div>
                                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                            </th>

                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                                <div style="color: white;">
                                                                                                                                                    Estimated Start Date
                                                                                                                                                </div>
                                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                            </th>

                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                                <div style="color: white;">
                                                                                                                                                    Estimated Completion Date
                                                                                                                                                </div>
                                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                            </th>

                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                                <div style="color: white;">
                                                                                                                                                    Actual Start Date
                                                                                                                                                </div>
                                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                            </th>

                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                                <div style="color: white;">
                                                                                                                                                    Actual Completion Date
                                                                                                                                                </div>
                                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                            </th>

                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                                <div style="color: white;">
                                                                                                                                                    Planned Hours
                                                                                                                                                </div>
                                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                            </th>
                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                                <div style="color: white;">
                                                                                                                                                    Actual Hours
                                                                                                                                                </div>
                                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                            </th>
                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                                <div style="color: white;">
                                                                                                                                                    Created Stamp
                                                                                                                                                </div>
                                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                            </th>
                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                                <div style="color: white;">
                                                                                                                                                    Action
                                                                                                                                                </div>
                                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                            </th>


                                                                                                                                        </tr>

                                                                                                                                    </ng-template>
                                                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                                                        <tr>
                                                                                                                                            <td [ngStyle]="{'width':'300px'}">
                                                                                                                                                <div class="checkbox-align">
                                                                                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                                            </div>
                                                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                        <!---->
                                                                                                                                                    </p-checkbox>
                                                                                                                                                    <span *ngIf="product.name" >{{product.name}}</span>

                                                                                                                                                </div>
                                                                                                                                            </td>
                                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                                <span *ngIf="product.workeffortas.roleTypeId">{{product.workeffortas.roleTypeId}}</span>

                                                                                                                                            </td>
                                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                                <span *ngIf="product.workeffort[0].roleTypeId">{{product.workeffort[0].currentStatusId}}</span>


                                                                                                                                            </td>
                                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                                <span *ngIf="product.workeffort[0].priority">{{product.workeffort[0].priority}}</span>

                                                                                                                                            </td>
                                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                                <span *ngIf="product.workeffort[0].estimatedStartDate">{{product.workeffort[0].estimatedStartDate
                                                                                                                                                    |date:'yyyy-MM-dd hh:mm:ss'}}</span>

                                                                                                                                            </td>
                                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                                <span *ngIf="product.workeffort[0].estimatedCompletionDate">{{product.workeffort[0].estimatedCompletionDate|
                                                                                                                                                    date:'yyyy-MM-dd hh:mm:ss'}}</span>

                                                                                                                                            </td>
                                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                                <span *ngIf="product.workeffort[0].actualStartDate">{{product.workeffort[0].actualStartDate
                                                                                                                                                    |date:'yyyy-MM-dd hh:mm:ss'}}</span>

                                                                                                                                            </td>
                                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                                <span *ngIf="product.workeffort[0].actualCompletionDate">{{product.workeffort[0].actualCompletionDate
                                                                                                                                                    |date:'yyyy-MM-dd hh:mm:ss'}}</span>

                                                                                                                                            </td>

                                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                                <span *ngIf="product.workeffort.createdStamp">{{product.workeffort[0].createdStamp|
                                                                                                                                                    date:'yyyy-MM-dd hh:mm:ss'}}</span>


                                                                                                                                            </td>
                                                                                                                                            <td [ngStyle]="{'width':'170px'}">

                                                                                                                                            </td>

                                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                                <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn" 
                                                                                                                                                (click)="deleteProjectPartyAssign(product)">Remove</button>&nbsp;

                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                    </ng-template>
                                                                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                                        <tr>
                                                                                                                                            <td>
                                                                                                                                                <span class="text-center">
                                                                                                                            <h4
                                                                                                                                class="ml-2">
                                                                                                                                No
                                                                                                                                Record
                                                                                                                                Found
                                                                                                                            </h4>
                                                                                                                        </span>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                    </ng-template>
                                                                                                                                </p-table>

                                                                                                                                <p class="paginate_data">
                                                                                                                                    View per page
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>


                                                                                                        </div>
                                                                                                    </form>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </form>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>







<ngx-spinner></ngx-spinner>