<div class="container-fluid main-container-wrapper">


    <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
        style="padding-top: 5px !important;padding-bottom: 5px !important;">
        <span class="color-black pl-1 titlePanels">Accounting Manager Main Page
        </span>
        <span>
            <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
            <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
        </span>
    </div>

    <div class=" bg-white color-grey">
        <div class="w3-card-4 classCard">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass mt-0"
                    style="padding-left:9px; width: 102%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                    <li [ngClass]="activeCategoriess==1?'active':''" (click)="mainPage()">
                        <a>
                            Main </a>
                    </li>

                    <li [ngClass]="activeCategoriess==2?'active':''" (click)="accountingPage()">
                        <a>
                            Accounting </a>
                    </li>
                    <li [ngClass]="activeCategoriess==3?'active':''" (click)="invoicePage()">
                        <a>
                            Invoices</a>
                    </li>
                    <li [ngClass]="activeCategoriess==4?'active':''" (click)="paymentPage()">
                        <a>
                            Payments </a>
                    </li>
                    <li [ngClass]="activeCategoriess==5?'active':''" (click)="paymentGrpPage()">
                        <a>
                            Payment group</a>
                    </li>
                    <li [ngClass]="activeCategoriess==6?'active':''" (click)="billingAccountPage()">
                        <a>
                            Billing Account
                        </a>
                    </li>
                    <li [ngClass]="activeCategoriess==7?'active':''" (click)="agreementPage()">
                        <a>
                            Agreements</a>
                    </li>
                    <li [ngClass]="activeCategoriess==8?'active':''" (click)="commisionReportPage()">
                        <a>
                            Commission Report</a>
                    </li>
                </ul>
            </div>

            <div class="create-new-leade mt-2">
                <div class="">
                    <div>

                        <div class="panel-body">
                            <div class="w3-card-4 classCard mx-2">
                                <div class="container-fluid">
                                    <ul class="tabbing-section tabbing-accordians tabClass"
                                        style="padding-left:9px">
                                        <li [ngClass]="activeCategoryMain==1?'active':''"
                                            (click)="AccountingInfo()"><a>
                                                Available Internal Accounting
                                                Organizations</a></li>
                                        <li>
                                            <button type="button"
                                                class="btn btn-outline-secondary"
                                                data-toggle="modal"
                                                data-target="#exampleModalCenter">Create new
                                                accounting company</button>
                                        </li>


                                    </ul>
                                </div>

                                <div
                                    class="all-users-infomation font-13 font-medium own-user-select p-0">
                                    <div class="w3-card-4 classCard" style="margin: 1%;">
                                        <div class="container-fluid">
                                            <ul class="tabbing-section tabbing-accordians tabClass"
                                                style="padding-left:9px">
                                                <li
                                                    [ngClass]="activeCategoryMain==1?'active':''">
                                                    <a>
                                                        Company</a>
                                                </li>



                                            </ul>
                                        </div>
                                        <div class="form-group" style="padding: 20px 20px;">
                                            <div class="card own-account-table">
                                                <p-table [value]="getMainOrg"
                                                    [paginator]="true" [rows]="rows"
                                                    scrollWidth="100%"
                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                    [totalRecords]="total"
                                                    [scrollable]="true"
                                                    (sortFunction)="customSort($event)"
                                                    [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr style="background: #0d3769;">
                                                            <th [ngStyle]="{'width':'150px'}"
                                                                pSortableColumn="code">
                                                                <div class="checkbox-align"
                                                                    style="color: white;">
                                                                    <p-checkbox
                                                                        _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox"
                                                                        binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div
                                                                                class="ui-helper-hidden-accessible">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name="undefined"
                                                                                    value="undefined">
                                                                            </div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </p-checkbox>
                                                                    Companies
                                                                    <p-sortIcon
                                                                        field="code"></p-sortIcon>
                                                                </div>
                                                                <input
                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value=""
                                                                    autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox"
                                                                    aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'150px'}"
                                                                pSortableColumn="name">
                                                                <div style="color: white;">
                                                                    <p-sortIcon
                                                                        field="name">
                                                                    </p-sortIcon>
                                                                </div>
                                                                <input
                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value=""
                                                                    autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox"
                                                                    aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'150px'}"
                                                                pSortableColumn="name">
                                                                <div style="color: white;">
                                                                    <p-sortIcon
                                                                        field="name">
                                                                    </p-sortIcon>
                                                                </div>
                                                                <input
                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value=""
                                                                    autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox"
                                                                    aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>


                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body"
                                                        let-activity>
                                                        <tr>
                                                            <td
                                                                [ngStyle]="{'width':'150px'}">
                                                                <div class="checkbox-align">
                                                                    <p-checkbox
                                                                        _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox"
                                                                        binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div
                                                                                class="ui-helper-hidden-accessible">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name="undefined"
                                                                                    value="undefined">
                                                                            </div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </p-checkbox>
                                                                    <span>
                                                                        {{activity.partyDesc}}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <!-- <td [ngStyle]="{'width':'150px'}"(click)="glOrganizationSummary(activity.partyId)" style="cursor:pointer;text-decoration: underline;color: #007bff!important;"> Configure </td> -->
                                                            <td [ngStyle]="{'width':'150px'}"
                                                                (click)="glOrganizationAccountingSummary(activity.partyId)"
                                                                style="cursor:pointer;text-decoration: underline;color: #007bff!important;">
                                                                Account Detail </td>


                                                        </tr>
                                                    </ng-template>


                                                </p-table>
                                                <p class="paginate_data">
                                                    View per page
                                                    <!-- <ng-container >
                                                        <span style="margin-left: 400px;">No Record Found!....</span>
                                                    </ng-container> -->
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create new accounting company</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">

                            <li [ngClass]="activeCategory==2?'active':''"><a>
                                    Create new accounting company
                                </a></li>


                        </ul>
                    </div>

                    <div class="all-users-infomation font-13 font-medium own-user-select">
                        <div class="form-group">
                            <div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100">

                                            <div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium own-user-select allUserPadding">
                                                    <form [formGroup]="createOrganization">
                                                        <div class="row" style="margin-left: 6%;">
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="exampleInputEmail1"
                                                                            style="color: black!important;">
                                                                            Organization Party ID
                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-4">
                                                                        <p-dropdown filter="true"
                                                                            placeholder="Select Organization Party ID"
                                                                            formControlName="organizationPartyId"
                                                                            [options]="orgIdArray" optionlabel="label">
                                                                        </p-dropdown>
                                                                    </div>

                                                                    <div class="col-lg-12" style="margin-left: 46%;">
                                                                        <button _ngcontent-cta-c128=""
                                                                            (click)="onSubmit()" type="submit"
                                                                            class="btn btn-secondary submit-btn">Create</button>
                                                                    </div>



                                                                </div>
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>