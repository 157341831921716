import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService } from '../../../crm/accounts/accounts.service'
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { TimeSheetService } from "../timesheet.service"
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateTimesheetFormService } from '../create-timesheet/create-timesheet-service';
import { ToastrService } from 'ngx-toastr';

import { Location } from '@angular/common';

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.css']
})
export class TimesheetComponent implements OnInit {
  activeCategory=2;
  show:boolean;
  @ViewChild('closebutton') closebutton;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  public timeSheet = {
    pageSize: this.pageSize,
    pageNo: this.pageNo
  }
  advanceSearch: {
    approvedByUserId: string,
    approvedByUserIdSearchType: string,
    clientPartyId: string,
    clientPartyIdSearchType: string,
    comments: string,
    commentsSearchType: string,
    fromDateFrom: string,
    fromDateFromSearchType: string,
    fromDateTo: string,
    fromDateToSearchType: string,
    partyId: string,
    statusId: string,
    statusIdSearchType: string,
    throughDateFrom: string,
    throughDateTo: string,
    thruDateFromSearchType: string,
    thruDateToSearchType: string,
    timesheetId: string,
    timesheetIdSearchType: string,
    partyIdSearchType: string
  }
  timeSheetList: any;
  dateIdArray: { label: string; value: string; }[];
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  userLoginArray: any[]=[];
  updateefromdate: string;
  updateetodate: string;
  statusList: any;
  statusArray: any[]=[];
  partyId: any;
  partyIdArray: any[]=[];
  constructor(
    public createTimesheetFormService:CreateTimesheetFormService,
    readonly toastr:ToastrService,
    readonly activatedRoute:ActivatedRoute,
      private _location: Location,
      readonly accountsService: AccountsService,
    public datePipe: DatePipe,
    readonly spinner:NgxSpinnerService,
    readonly router: Router,
    readonly timeSheetService: TimeSheetService) {
    this.advanceSearch = {
      approvedByUserId: "",
      approvedByUserIdSearchType: "Contains",
      clientPartyId: "",
      clientPartyIdSearchType: "Contains",
      comments: "",
      commentsSearchType: "Contains",
      fromDateFrom: "",
      fromDateFromSearchType: "Equals",
      fromDateTo: "",
      fromDateToSearchType: "Equals",
      partyId: "",
      statusId: "",
      partyIdSearchType: "Contains",
      statusIdSearchType: "Contains",
      throughDateFrom: "",
      throughDateTo: "",
      thruDateFromSearchType: "Equals",
      thruDateToSearchType: "Equals",
      timesheetId: "",
      timesheetIdSearchType: "Contains"
    }
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
    this.dateIdArray = [
      {
        label: 'Less Than',
        value: 'LessThan'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },

      {
        label: 'Greater Than',
        value: 'GreaterThan'
      },
      {
        label: 'Greater Than Equal To',
        value: 'GreaterThanEqualTo'
      },
      {
        label: 'Less Than Equal To',
        value: 'LessThanEqualTo'
      },
      {
        label: 'Is Empty',
        value: 'IsEmpty'
      }

    ];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getTimesheetList();

    this.getPartyId();
    this.getStatusList();
    this.getUserLoginId();
    this.createTimesheetFormService.create_timesheet_form=null;
    this.createTimesheetFormService.createTimesheetForm(this.createTimesheetFormService.create_timesheet_form);
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  getUserLoginId(): void {
    this.spinner.show();
    this.timeSheetService.getUserLogin().subscribe((res) => {
      let userLogin = res.data;
      this.spinner.hide();
      for (const value of userLogin) {
        if (value["groupName"] === null) {
          value["groupName"] = "";
        }
        if (value["firstName"] === null) {
          value["firstName"] = "";
        }
        if (value["middleName"] === null) {
          value["middleName"] = "";
        }
        if (value["lastName"] === null) {
          value["lastName"] = "";
        }
        let name =
          value["userLoginId"] +
          " " +
          value["groupName"] +
          " [" +
          value["firstName"] +
          " " +
          value["middleName"] +
          " " +
          value["lastName"] +
          "]";
        this.userLoginArray.push({
          label: name,
          value: value.userLoginId,
        });
      }
    }); 
  
  }
  onSubmit() {
    this.spinner.show();
    let fromdate=this.createTimesheetFormService.create_timesheet_form.get('fromDate').value;
    this.updateefromdate=this.datePipe.transform(fromdate,"yyyy-MM-dd hh:mm:ss");

    let todate=this.createTimesheetFormService.create_timesheet_form.get('thruDate').value;
    this.updateetodate=this.datePipe.transform(todate,"yyyy-MM-dd hh:mm:ss");
    let formData={
      approvedByUserLoginId: this.createTimesheetFormService.create_timesheet_form.value.approvedByUserLoginId,
      clientPartyId:this.createTimesheetFormService.create_timesheet_form.value.clientPartyId,
      comments: this.createTimesheetFormService.create_timesheet_form.value.comments,
      fromDate:  this.updateefromdate,
      partyId: this.createTimesheetFormService.create_timesheet_form.value.partyId,
      statusId: this.createTimesheetFormService.create_timesheet_form.value.statusId,
      thruDate: this.updateetodate,
    }
 
    this.timeSheetService.createTimeSheet(formData).subscribe((res:any)=>{
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.getTimesheetList();
        this.createTimesheetFormService.create_timesheet_form.reset();
        this.closebutton.nativeElement.click();
        // this.router.navigate(['/hr/timesheets'])
      }
      else if(res.success == false) {
        this.spinner.hide();
        this.toastr.error("Error");
      }
    },(err) => {
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
  
  }
  getStatusList(){
    this.spinner.show();
    this.timeSheetService.getStatusList().subscribe(res=> {
      this.statusList = res.data;
      this.spinner.hide();
      for(const data of this.statusList) {
        this.statusArray.push({
          label:data.description,
          value:data.statusId
        })
      }
    })

  }
  getPartyId() {
    this.spinner.show();
    this.timeSheetService.getPartyId().subscribe(res=> {
      this.partyId=res.data.PersonsGroups;
      this.spinner.hide();
      for(const value of this.partyId) {
        this.partyIdArray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
    })
   
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  getTimesheetList() {
    this.spinner.show();
    let timesheet = {
      approvedByUserId: "",
      approvedByUserIdSearchType: "Contains",
      clientPartyId: "",
      clientPartyIdSearchType: "Contains",
      comments: "",
      commentsSearchType: "Contains",
      fromDateFrom: "",
      fromDateFromSearchType: "Contains",
      fromDateTo: "",
      fromDateToSearchType: "Contains",
      partyId: "",
      statusId: "",
      statusIdSearchType: "Contains",
      throughDateFrom: "",
      throughDateTo: "",
      thruDateFromSearchType: "Contains",
      thruDateToSearchType: "Contains",
      timesheetId: "",
      timesheetIdSearchType: "Contains"
    }
    this.timeSheetService.getApplicationTimesheetList(this.timeSheet, timesheet).subscribe(res => {
      this.timeSheetList = res.data;
      this.spinner.hide();
    })
   
  }
  onUpdate(timesheetId) {
    this.router.navigate(["/hr/timesheets/create-timesheet"], { queryParams: { timesheetId: timesheetId } })
  }
  advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    let timesheet = {
      approvedByUserId: this.advanceSearch.approvedByUserId ? this.advanceSearch.approvedByUserId : '',
      approvedByUserIdSearchType: this.advanceSearch.approvedByUserIdSearchType ? this.advanceSearch.approvedByUserIdSearchType : '',
      clientPartyId: this.advanceSearch.clientPartyId ? this.advanceSearch.clientPartyId : '',
      clientPartyIdSearchType: this.advanceSearch.clientPartyIdSearchType ? this.advanceSearch.clientPartyIdSearchType : '',
      comments: this.advanceSearch.comments ? this.advanceSearch.comments : '',
      commentsSearchType: this.advanceSearch.commentsSearchType ? this.advanceSearch.commentsSearchType : '',
      fromDateFrom: this.datePipe.transform(this.advanceSearch.fromDateFrom, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.fromDateFrom, "yyyy-MM-dd hh:mm:ss") : '',
      fromDateFromSearchType: this.advanceSearch.fromDateFromSearchType ? this.advanceSearch.fromDateFromSearchType : '',
      fromDateTo: this.datePipe.transform(this.advanceSearch.fromDateTo, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.fromDateTo, "yyyy-MM-dd hh:mm:ss") : '',
      fromDateToSearchType: this.advanceSearch.fromDateToSearchType ? this.advanceSearch.fromDateToSearchType : '',
      partyId: this.advanceSearch.partyId ? this.advanceSearch.partyId : '',
      statusId: this.advanceSearch.statusId ? this.advanceSearch.statusId : '',
      statusIdSearchType: this.advanceSearch.statusIdSearchType ? this.advanceSearch.statusIdSearchType : '',
      throughDateFrom: this.datePipe.transform(this.advanceSearch.throughDateFrom, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.throughDateFrom, "yyyy-MM-dd hh:mm:ss") : '',
      throughDateTo: this.datePipe.transform(this.advanceSearch.throughDateTo, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.throughDateTo, "yyyy-MM-dd hh:mm:ss") : '',
      thruDateFromSearchType: this.advanceSearch.thruDateFromSearchType ? this.advanceSearch.thruDateFromSearchType : '',
      thruDateToSearchType: this.advanceSearch.thruDateToSearchType ? this.advanceSearch.thruDateToSearchType : '',
      timesheetId: this.advanceSearch.timesheetId ? this.advanceSearch.timesheetId : '',
      timesheetIdSearchType: this.advanceSearch.timesheetIdSearchType ? this.advanceSearch.timesheetIdSearchType : '',
    }
    this.timeSheetService.getApplicationTimesheetList(this.timeSheet, timesheet).subscribe(res => {
      this.timeSheetList = res.data;
      this.spinner.hide();
    })
  
  }
  reset() {
    this.spinner.show();
    this.advanceSearch.approvedByUserId = "";
    this.advanceSearch.clientPartyId = "";
    this.advanceSearch.comments = "";
    this.advanceSearch.fromDateFrom = "";
    this.advanceSearch.fromDateTo = "";
    this.advanceSearch.partyId = "";
    this.advanceSearch.statusId = "";
    this.advanceSearch.throughDateFrom = "";
    this.advanceSearch.throughDateTo = "";
    this.advanceSearch.timesheetId = "";

    let timesheet = {
      approvedByUserId: "",
      approvedByUserIdSearchType: "Contains",
      clientPartyId: "",
      clientPartyIdSearchType: "Contains",
      comments: "",
      commentsSearchType: "Contains",
      fromDateFrom: "",
      fromDateFromSearchType: "Contains",
      fromDateTo: "",
      fromDateToSearchType: "Contains",
      partyId: "",
      statusId: "",
      statusIdSearchType: "Contains",
      throughDateFrom: "",
      throughDateTo: "",
      thruDateFromSearchType: "Contains",
      thruDateToSearchType: "Contains",
      timesheetId: "",
      timesheetIdSearchType: "Contains"
    }
    this.timeSheetService.getApplicationTimesheetList(this.timeSheet, timesheet).subscribe(res => {
      this.timeSheetList = res.data;
      this.spinner.hide();
    })
   
  }
  employeeDetail(id) {
    this.router.navigate(['/hr/timesheets/detail-timesheet-summary'], { queryParams: { timesheetid: id } })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
