import { Component, OnInit } from '@angular/core';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { AccountsService} from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { HeaderComponent } from 'src/app/header/header.component';
import { HeaderService } from 'src/app/header/header.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2'
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DatePipe } from '@angular/common'; 
import { Location } from '@angular/common';
@Component({
  selector: 'app-add-work-effort',
  templateUrl: './add-work-effort.component.html',
  styleUrls: ['./add-work-effort.component.css']
})
export class AddWorkEffortComponent implements OnInit {
  addWorkEffort: FormGroup;
  duplicateWorkEffort: any;
  personId: any;
  partyIdArray: any[]=[];
  Type: any;
  TypeArray: any[]=[];
  Purpose: any;
  PurposeArray: any[]=[];
  yesNOArray: { label: string; value: string; }[];
  AddSkillService: any;
  CurrencyList: any;
  CurrencyListArray: any[]=[];
  TemporalExpression: any;
  TemporalExpressionArray: any[]=[];
  StatusNewWorkEffort: any;
  StatusNewWorkEffortArray: any[]=[];
  CommunicationEventId: any;
  CommunicationEventIdArray: any[]=[];
  PriorityArray: { label: string; value: string; }[];
  scopeArray: { label: string; value: string; }[];
  FacilityIdList: any;
  FacilityIdListArray: any[]=[];
  RequirementList: any;
  RequirementListArray: any[]=[];
  statusArray: { label: string; value: string; }[];
  closebutton: any;
  TaskListData: any;
  actualcompletiondate: string;
  actualStartDate: string;
  estimatedCompletionDate: string;
  estimatedStartDate: string;
  duplicate: boolean;
  workEffortId:any;
  newWorkEffortID: any;

  constructor(
    private accountsService: AccountsService,
    private headerService : HeaderService,
    private toastr: ToastrService,
    readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly addSkillService: AddSkillService,
    readonly router: Router,
    readonly _FormBuilder: FormBuilder,
    private _location: Location,
    
    readonly myContactsService: MyContactsService,
    public createEventFormService: CreateEventFormService,
    readonly createEventServices: CreateEventServices,
  ) {
    this.yesNOArray = [

      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];
    this.statusArray = [

      {
        label: 'Any',
        value: 'Any'
      },
      {
        label: 'Active',
        value: 'PRODUCT_ACTIVE'
      },
      {
        label: 'Closed',
        value: 'PRODUCT_CLOSED'
      },


    ]; 
    this.scopeArray = [

      {
        label: 'General, public access',
        value: 'WES_PUBLIC'
      },
      {
        label: 'Restricted, private access',
        value: 'WES_PRIVATE'
      },
      {
        label: 'Very restricted, confidential access',
        value: 'WES_CONFIDENTIAL'
      },
    ]; 
    this.PriorityArray = [

      {
        label: '1 (High)',
        value: '1'
      },
      {
        label: '2',
        value: '2'
      },
      {
        label: '3',
        value: '3'
      },
      {
        label: '4',
        value: '4'
      },
      {
        label: '5',
        value: '5'
      },
      {
        label: '6',
        value: '6'
      },
      {
        label: '7',
        value: '7'
      },
      {
        label: '8',
        value: '8'
      },
      {
        label: '9 (Low)',
        value: '9'
      },

    ];
    this.addWorkEffort = this._FormBuilder.group({
      quickAssignPartyID: [''],
      name: [''],
      description: [''],
      type: [''],
      purpose: [''],
      status: [''],
      percentComplete: [''],
      priority: [''],
      scope: [''],
      estimatedStartDate: [''],
      estimatedCompletionDate: [''],
      actualStartDate: [''],
      actualCompletionDate: [''],
      communicationEventID: [''],
      reason: [''],
      webSiteID: [''],
      workEffortParentID: [''],
      showAsEnumID: [''],
      sendNotificationEmail: [''],
      locationDesc: [''],
      estimatedMilliSeconds: [''],
      estimatedSetupMilliSeconds: [''],
      estimatedCalcMethod: [''],
      actualMilliSeconds: [''],
      actualSetupMilliSeconds: [''],
      totalMilliSecondsAllowed: [''],
      totalMoneyAllowed: [''],
      specialTerms: [''],
      timeTransparency: [''],
      universalID: [''],
      sourceReferenceID: [''],
      fixedAssetID: [''],
      facilityID: [''],
      infoURL: [''],
      temporalExpression: [''],
      serviceLoaderName: [''],
      quantityToProduce: [''],
      quantityProduced: [''],
      quantityRejected: [''],
      reservPersons: [''],
      reserv2ndPerc: [''],
      reservNthPerc: [''],
      accommodationMapID: [''],
      accommodationSpotID: [''],
      sequenceNum: [''],
      requirementID: [''],
    });
    this.duplicateWorkEffort = this._FormBuilder.group({
      newWorkEffortID: [''],
      StatusID: [''],
      duplicateWorkEffortAssignmentRates: [''],
      duplicateWorkEffortAssocs: [''],
      duplicateWorkEffortContents: [''],
      duplicateWorkEffortNotes: [''],
    })
   }

  ngOnInit(): void {
    this.getPartyId();
    this.getType();
    this.getPurpose();
    this.getCurrencyList();
    this.getTemporalExpression();
    this.getStatusNewWorkEffort();
    this.getCommunicationEventId();
    this.getFacilityIdList();
    this.getRequirementList();
    
  }
  homeButton(){
     this.router.navigate(['/psa/Dashboard'])
   
   }
   cancelSubmit(){
     this._location.back();
   }
   
  getTaskListData(){
    this.spinner.show();
   
    this.accountsService.getTaskListData().subscribe((res:any)=> {
      this.TaskListData = res.data[0].ParentProjectNameList;
      this.spinner.hide();
    
    })   
   }
  createWorkEffort(): void {
    this.spinner.show();
    const completiondate=this.addWorkEffort.get('actualCompletionDate').value;
    this.actualcompletiondate=this.datePipe.transform(completiondate,"yyyy-MM-dd hh:mm:ss");
    const StartDate=this.addWorkEffort.get('actualStartDate').value;
    this.actualStartDate=this.datePipe.transform(StartDate,"yyyy-MM-dd hh:mm:ss");
    const estCompletionDate=this.addWorkEffort.get('estimatedCompletionDate').value;
    this.estimatedCompletionDate=this.datePipe.transform(estCompletionDate,"yyyy-MM-dd hh:mm:ss");
    const estStartDate=this.addWorkEffort.get('estimatedStartDate').value;
    this.estimatedStartDate=this.datePipe.transform(estStartDate,"yyyy-MM-dd hh:mm:ss");
     const formData ={
   
  "accommodationMapId": this.addWorkEffort.value.accommodationMapID,
  "accommodationSpotId": this.addWorkEffort.value.accommodationSpotID,
  "actualCompletionDate": this.actualcompletiondate,
  "actualMilliSeconds": this.addWorkEffort.value.actualMilliSeconds,
  "actualSetupMillis": this.addWorkEffort.value.actualSetupMilliSeconds,
  "actualStartDate": this.actualStartDate,
  "communicationEventId": this.addWorkEffort.value.communicationEventID,
  "currentStatusId": this.addWorkEffort.value.status,
  "description": this.addWorkEffort.value.description,
  "estimateCalcMethod": this.addWorkEffort.value.estimatedCalcMethod,
  "estimatedCompletionDate": this.estimatedCompletionDate,
  "estimatedMilliSeconds": this.addWorkEffort.value.estimatedMilliSeconds,
  "estimatedSetupMillis": this.addWorkEffort.value.estimatedSetupMilliSeconds,
  "estimatedStartDate": this.estimatedStartDate,
  "facilityId": this.addWorkEffort.value.facilityID,
  "fixedAssetId": this.addWorkEffort.value.fixedAssetID,
  "infoUrl": this.addWorkEffort.value.infoURL,
  "locationDesc": this.addWorkEffort.value.locationDesc,
  "moneyUomId": this.addWorkEffort.value.currency,
  "percentComplete": this.addWorkEffort.value.percentComplete,
  "priority": this.addWorkEffort.value.priority,
  "quantityProduced": this.addWorkEffort.value.quantityProduced,
  "quantityRejected": this.addWorkEffort.value.quantityRejected,
  "quantityToProduce": this.addWorkEffort.value.quantityToProduce,
  "quickAssignPartyId": this.addWorkEffort.value.quickAssignPartyID,
  "reason": this.addWorkEffort.value.reason,
  "requirementId": this.addWorkEffort.value.requirementID,
  "reserv2ndPPPerc": this.addWorkEffort.value.reserv2ndPerc,
  "reservNthPPPerc": this.addWorkEffort.value.reservNthPerc,
  "reservPersons": this.addWorkEffort.value.reservPersons,
  "scopeEnumId": this.addWorkEffort.value.scope,
  "sendNotificationEmail": this.addWorkEffort.value.sendNotificationEmail,
  "sequenceNum": this.addWorkEffort.value.sequenceNum,
  "serviceLoaderName": this.addWorkEffort.value.serviceLoaderName,
  "showAsEnumId": this.addWorkEffort.value.showAsEnumID,
  "sourceReferenceId": this.addWorkEffort.value.sourceReferenceID,
  "specialTerms": this.addWorkEffort.value.specialTerms,
  "tempExprId": this.addWorkEffort.value.temporalExpression,
  "timeTransparency": this.addWorkEffort.value.timeTransparency,
  "totalMilliSecondsAllowed": this.addWorkEffort.value.totalMilliSecondsAllowed,
  "totalMoneyAllowed": this.addWorkEffort.value.totalMoneyAllowed,
  "universalId": this.addWorkEffort.value.universalID,
  "webSiteId": this.addWorkEffort.value.webSiteID,
  "workEffortName": this.addWorkEffort.value.name,
  "workEffortParentId": this.addWorkEffort.value.workEffortParentID,
  "workEffortPurposeTypeId": this.addWorkEffort.value.purpose,
  "workEffortTypeId": this.addWorkEffort.value.type
       
         
     }
     this.myContactsService.createWorkEffort(formData).subscribe((res: any) => {
      
       if (res.success) {
         this.toastr.success("Successfully Created");
         this.spinner.hide();
         this.workEffortId = res.data.workEffortId;
    this.router.navigate(["/psa/work-effort/task-list-view/editWorkEffort"], 
    { queryParams: { workEffortId: this.workEffortId } })

        
         this.addWorkEffort.reset();
         this.getTaskListData();
        
       }
     }, (err) => {
      this.spinner.hide();
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
    
   }
  
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   }
  getType() {
    this.spinner.show();
    this.addSkillService.getType().subscribe(res => {
      this.Type = res.data[0].type;
      this.spinner.hide();
      for (const value of this.Type) {
        this.TypeArray.push({
          label: value.description,
          value: value.workEffortTypeId
        })
      }
    })
   }
   getPurpose() {
    this.spinner.show();
    this.addSkillService.getPurpose().subscribe(res => {
      this.Purpose = res.data[0].Purpose;
      this.spinner.hide();
      for (const value of this.Purpose) {
        this.PurposeArray.push({
          label: value.description,
          value: value.workEffortPurposeTypeId
        })
      }
    })
   }
   Reset(){
    this.duplicateWorkEffort.reset();
   }
    duplicateWorkEffortFunction(): void {
    this.spinner.show();
  
     const formData ={
  "duplicateTitle": '',
  "duplicateWorkEffortAssignmentRates":  this.duplicateWorkEffort.value.duplicateWorkEffortAssignmentRates==true?'Y':'N',
  "duplicateWorkEffortAssocs": this.duplicateWorkEffort.value.duplicateWorkEffortAssocs==true?'Y':'N',
  "duplicateWorkEffortContents":  this.duplicateWorkEffort.value.duplicateWorkEffortContents==true?'Y':'N',
  "duplicateWorkEffortNotes": this.duplicateWorkEffort.value.duplicateWorkEffortNotes==true?'Y':'N',
  "oldWorkEffortId": "",
  "removeTitle": '',
  "statusId":  this.duplicateWorkEffort.value.StatusID,
  "workEffortId":  this.duplicateWorkEffort.value.newWorkEffortID
         
     }
     this.myContactsService.duplicateWorkEffort(formData).subscribe((res: any) => {
      
       if (res.success) {
         this.toastr.success("Successfully Created");
         this.spinner.hide();
          this.duplicate=true;
          this.newWorkEffortID = res.data.workEffortId;
          this.router.navigate(["/psa/work-effort/task-list-view/editWorkEffort"], 
          { queryParams: { workEffortId:this.newWorkEffortID } })

        
         
         setTimeout(() => {
         
          const formValue = this.addWorkEffort;
          formValue.patchValue({
            quickAssignPartyID: this.duplicateWorkEffort.value.newWorkEffortID,
        
    
    
          })
        
        }, 10);
        
         
        
       }
     }, (err) => {
      this.spinner.hide();
      setTimeout(() => {
         
        const formValue = this.addWorkEffort;
        formValue.patchValue({
          quickAssignPartyID: this.duplicateWorkEffort.value.newWorkEffortID,
      
  
  
        })
      
      }, 10);
      this.duplicate=true;
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
    
   }
   getCurrencyList() {
    this.spinner.show();
    this.addSkillService.getCurrencyList().subscribe(res => {
      this.CurrencyList = res.data[0].node;
      this.spinner.hide();
      for (const value of this.CurrencyList) {
        this.CurrencyListArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
  }
  getTemporalExpression() {
    this.spinner.show();
    this.addSkillService.getTemporalExpression().subscribe(res => {
      this.TemporalExpression = res.data[0].temporalExpression;
      this.spinner.hide();
      for (const value of this.TemporalExpression) {
        this.TemporalExpressionArray.push({
          label: value.tempExprId,
          value: value.tempExprId,
        })
      }
    })
  }
  getStatusNewWorkEffort() {
    this.spinner.show();
    this.addSkillService.getStatusNewWorkEffort().subscribe(res => {
      this.StatusNewWorkEffort = res.data[0].data;
      this.spinner.hide();
      for (const value of this.StatusNewWorkEffort) {
        this.StatusNewWorkEffortArray.push({
          label: value.description,
          value: value.statusValue,
        })
      }
    })
  }
  getCommunicationEventId() {
    this.spinner.show();
    this.addSkillService.getCommunicationEventId().subscribe(res => {
      this.CommunicationEventId = res.data[0].communicationEventId;
      this.spinner.hide();
      for (const value of this.CommunicationEventId) {
        this.CommunicationEventIdArray.push({
          label: value.communicationEventId,
          value: value.communicationEventId
        })
      }
    })
  }
  getFacilityIdList() {
    this.spinner.show();
    this.addSkillService.getFacilityIdList().subscribe(res => {
      this.FacilityIdList = res.data;
      this.spinner.hide();
      for (const value of this.FacilityIdList) {
        this.FacilityIdListArray.push({
          label: value.node1.facilityName,
          value: value.node1.facilityId

        })
      }
    })
  }
  getRequirementList() {
    this.spinner.show();
    this.addSkillService.getRequirementList().subscribe(res => {
      this.RequirementList = res.data[0].list;
      this.spinner.hide();
      for (const value of this.RequirementList) {
        this.RequirementListArray.push({
          label: value.description,
          value: value.requirementId,
        })
      }
    })
  }
 
}
